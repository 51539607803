import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  contacts: undefined,
  isLoading: false,
  errMsg: null,
  isLimitReached: false,
  pageNumber: 1,
};
export const getContactList = createAsyncThunk("contactList/getContactList", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/users/open-all?pageSize=9&pageNumber=${data.pageNumber}&search=${data.search}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { allUsers: resp.data.data, pageNumber: data.pageNumber };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
const contactList = createSlice({
  name: "contactList",
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber += 1;
    },
    saveNewContact: (state, action) => {
      let newContacts = [...state.contacts];
      newContacts.unshift(action.payload);
      state.contacts = newContacts;
    },
  },
  extraReducers: {
    [getContactList.pending]: (state) => {
      state.isLoading = true;
      state.isLimitReached = false;
    },
    [getContactList.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload?.pageNumber > 1) {
        if (action.payload.allUsers.length === 0) {
          state.isLimitReached = true;
        } else {
          state.contacts = [...state.contacts, ...action.payload.allUsers].sort((a, b) =>
            a.full_name > b.full_name ? 1 : b.full_name > a.full_name ? -1 : 0
          );
        }
      } else {
        state.contacts = action.payload.allUsers.sort((a, b) => (a.full_name > b.full_name ? 1 : b.full_name > a.full_name ? -1 : 0));
      }
    },
    [getContactList.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});
export const { setPageNumber, saveNewContact } = contactList.actions;
export default contactList.reducer;
