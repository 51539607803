import React from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import momentTZ from "moment-timezone";
import Linkify from "react-linkify";
import { useNavigate } from "react-router-dom";
import { StyledBadge } from "./index";
import Avatar from "../uiElements/avatar/index";
import { updateData } from "../../utils/updateData";
function ShortMessageWidget(props) {
  let { classes, setOpenShortMessage, messageDetail, adminAuth } = props;

  const navigate = useNavigate();

  const handleRedirect = (messageDetail) => {
    if (messageDetail?.message_id) {
      navigate(`/leads/inbox`, {
        state: {
          message_id: messageDetail?.message_id,
          messageDetail: messageDetail?.text_message,
        },
      });
    }
    setOpenShortMessage(false);
  };
  const handleArchive = (id) => {
    updateData(
      `${process.env.REACT_APP_BASE_URL}api/recruitment/textMessages/archiveChat?userId=${adminAuth?.adminDetail?.id}&lead_id=${id}`,
      {}
    );
  };

  return (
    <Box sx={{ width: "340px" }}>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              paddingRight: "4px",
              "&:hover": {
                "& .closeActionItem": { display: "block !important" },
              },
            }}
          >
            <ListItemAvatar
              onClick={() => handleRedirect(messageDetail)}
              sx={{ minWidth: "40px" }}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                  color: "#2E7D32",
                }}
                variant={"dot"}
                color={"success"}
                customColor={
                  messageDetail?.text_message?.lead?.lastActivity
                    ? momentTZ().diff(
                        momentTZ(
                          messageDetail?.text_message?.lead?.lastActivity
                        ),
                        "minutes"
                      ) < 5
                      ? "#2E7D32"
                      : "transparent"
                    : "transparent"
                }
                disableShadow={
                  messageDetail?.text_message?.lead?.lastActivity
                    ? momentTZ().diff(
                        momentTZ(
                          messageDetail?.text_message?.lead?.lastActivity
                        ),
                        "minutes"
                      ) < 5
                      ? false
                      : true
                    : true
                }
              >
                <Avatar sx={{ fontSize: "13px" }}>
                  {messageDetail.text_message?.lead?.name
                    ? `${messageDetail.text_message?.lead?.firstName?.slice(
                        0,
                        1
                      )}${messageDetail.text_message?.lead?.lastName?.slice(
                        0,
                        1
                      )}`
                    : messageDetail.text_message?.realtor_agent?.MemberFullName
                    ? `${messageDetail.text_message?.realtor_agent?.MemberFirstName?.slice(
                        0,
                        1
                      )}${messageDetail.text_message?.realtor_agent?.MemberLastName?.slice(
                        0,
                        1
                      )}`
                    : messageDetail.text_message?.realtor_agent?.name
                    ? `${messageDetail.text_message?.realtor_agent?.name?.slice(
                        0,
                        1
                      )}${messageDetail.text_message?.realtor_agent?.name?.slice(
                        0,
                        1
                      )}`
                    : `${messageDetail.text_message?.firstName?.slice(
                        0,
                        1
                      )}${messageDetail.text_message?.lastName?.slice(0, 1)}`}
                </Avatar>
              </StyledBadge>
            </ListItemAvatar>
            <ListItemText
              sx={{ width: "80%" }}
              onClick={() => handleRedirect(messageDetail)}
              classes={{ secondary: classes.textMessagesListItemContent }}
              primary={
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  {" "}
                  <Typography className={classes.textMessagesListItemHeading}>
                    {messageDetail.text_message?.lead?.name
                      ? messageDetail.text_message?.lead?.name
                      : messageDetail.text_message?.realtor_agent
                          ?.MemberFullName ||
                        messageDetail.text_message?.realtor_agent?.name
                      ? messageDetail.text_message?.realtor_agent
                          ?.MemberFullName ||
                        messageDetail.text_message?.realtor_agent?.name
                      : messageDetail.text_message?.firstName +
                        " " +
                        messageDetail.text_message?.lastName}
                  </Typography>{" "}
                  {/* This is chip area for shoing the chip  */}
                  <Chip
                    label={
                      messageDetail.text_message?.realtor_agent?.MemberFullName
                        ? "Recruitment"
                        : messageDetail.type || "Lead"
                    }
                    className={classes.textMessagesListItemChip}
                    size="small"
                    variant="outlined"
                  />{" "}
                  <Typography className={classes.textMessagesListItemTime}>
                    {momentTZ(messageDetail.updatedAt).fromNow()}
                  </Typography>
                </Stack>
              }
              secondary={
                <Linkify
                  properties={{ target: "_blank" }}
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {messageDetail.body}
                </Linkify>
              }
            />
            <ListItemSecondaryAction
              className="closeActionItem"
              sx={{ mr: "-10px", display: "none" }}
            >
              <IconButton
                onClick={() =>
                  handleArchive(messageDetail.text_message?.lead_id)
                }
                size="small"
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ShortMessageWidgetStyle" })(
  ShortMessageWidget
);
