import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import Avatar from "../../../../components/uiElements/avatar/index";
import momentTZ from "moment-timezone";
import { StyledBadge } from "../../../../components/topNavBar";
// Name Cell Component
export const NameCard = ({ name, market, avatar, lastActivity }) => {
  const nameFnc = (name) => {
    const nameSplit = name.split(" ");
    const firstLetter = nameSplit.map((ii) => ii[0]);
    return firstLetter.slice(0, 2).join("").toUpperCase();
  };
  const stringAvatar = () => {
    if (avatar) {
      return {
        src: `${avatar}`,
      };
    } else if (name) {
      return {
        children: nameFnc(name),
      };
    } else {
      return {
        src: "",
      };
    }
  };
  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar sx={{ minWidth: "44px" }}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
              color: "#2E7D32",
            }}
            variant={"dot"}
            color={"success"}
            disableShadow={lastActivity ? (momentTZ().diff(momentTZ(lastActivity), "minutes") < 5 ? false : true) : true}
            customColor={
              lastActivity ? (momentTZ().diff(momentTZ(lastActivity), "minutes") < 5 ? "#2E7D32" : "transparent") : "transparent"
            }
          >
            <Avatar sx={{ fontSize: "15px", fontWeight: "400", width: "35px", height: "35px" }} {...stringAvatar()} />
          </StyledBadge>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={<React.Fragment>{market}</React.Fragment>} />
      </ListItem>
    </List>
  );
};

export const PhoneCell = ({ number }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: "5px",
      }}
    >
      <LocalPhoneIcon /> {number}
    </div>
  );
};

export const StageCell = ({ name }) => {
  return (
    <span
      style={{
        backgroundColor: "#000",
        borderRadius: "12px",
        padding: "3px 8px",
        color: "white",
      }}
    >
      {name}
    </span>
  );
};
export const PriceCell = ({ price }) => {
  return (
    <span>
      {
        parseInt(price)
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .split(".")[0]
      }
    </span>
  );
};
