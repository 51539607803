import React, { useState, useEffect, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCategory,
  updateCategory,
} from "../../../../redux/knowledge/categories";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";

function AddNewCategory(props) {
  const dispatch = useDispatch();
  let { open, setOpen, classes, editable, setEditable } = props;
  const [title, setTitle] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const addCategory = useSelector(
    (state) => state.knowledgeBase.categories.addCategory
  );
  const updateCategoryState = useSelector(
    (state) => state.knowledgeBase.categories.update
  );

  useEffect(() => {
    if (editable?.id) {
      setTitle(editable.title);
    }
  }, [editable]);

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully created the category for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleUpdateSuccess = () => {
    setErrorAlert({
      errorMsg:
        "You have successfully updated  the category for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const CreateCategory = () => {
    dispatch(createNewCategory({ title, handleSuccess, handleError }));
  };

  const UpdateCategory = () => {
    dispatch(
      updateCategory({
        id: editable.id,
        title,
        handleError,
        handleUpdateSuccess,
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
    setTitle("");
    setEditable(false);
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <Grid container direction="row" spacing={1}>
          <Grid item lg={12} xs={12}>
            <DialogTitle
              className={classes.addItemModalHeading}
              id="alert-dialog-title"
            >
              {editable.id ? "Update" : "Create"} Category
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent sx={{ width: "600px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={3}>
            <Grid item lg={12} xs={12} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Title</h4>
                <TextField
                  onChange={handleChange}
                  value={title}
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  placeholder="Search or create tags"
                  variant="outlined"
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose(false)}
            sx={{
              color: "#000000",
            }}
            className={classes.btns}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => {
              if (!editable.id) {
                CreateCategory();
              } else {
                UpdateCategory();
              }
            }}
            classes={{ root: classes.AddInventoryButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addCategory.isLoading || updateCategoryState?.isLoading}
          >
            {editable.id ? "Update" : "Save"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNewCategoryStyle" })(
  AddNewCategory
);
