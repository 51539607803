import { NumberFormat } from "./numberFormat";

export const minPriceList = [
  {
    title: "any",
    value: "*",
  },
  {
    title: "$50,000+",
    value: "50000",
  },
  {
    title: "$60,000+",
    value: "60000",
  },
  {
    title: "$70,000+",
    value: "70000",
  },
  {
    title: "$80,000+",
    value: "80000",
  },
  {
    title: "$90,000+",
    value: "90000",
  },
  {
    title: "$100,000+",
    value: "100000",
  },
  {
    title: "$125,000+",
    value: "125000",
  },
];
export const priceMaxList = [
  {
    title: "any",
    value: "*",
  },
  {
    title: "$200,000",
    value: "200000",
  },
  {
    title: "$225,000",
    value: "225000",
  },
  {
    title: "$250,000",
    value: "250000",
  },
  {
    title: "$275,000",
    value: "275000",
  },
  {
    title: "$300,000",
    value: "300000",
  },
  {
    title: "$325,000",
    value: "325000",
  },
  {
    title: "$350,000",
    value: "350000",
  },
  {
    title: "$400,000",
    value: "400000",
  },
  {
    title: "$425,000",
    value: "425000",
  },
  {
    title: "$475,000",
    value: "475000",
  },
  {
    title: "$500,000",
    value: "500000",
  },
  {
    title: "$525,000",
    value: "525000",
  },
  {
    title: "$550,000",
    value: "550000",
  },
  {
    title: "$575,000",
    value: "575000",
  },
  {
    title: "$600,000",
    value: "600000",
  },
  {
    title: "$650,000",
    value: "650000",
  },
  {
    title: "$700,000",
    value: "700000",
  },
  {
    title: "$750,000",
    value: "750000",
  },
  {
    title: "$800,000",
    value: "800000",
  },
  {
    title: "$850,000",
    value: "850000",
  },
  {
    title: "$900,000",
    value: "900000",
  },
  {
    title: "$950,000",
    value: "950000",
  },
  {
    title: "$1,000,000",
    value: "1000000",
  },
  {
    title: "$1,100,000",
    value: "1100000",
  },
  {
    title: "$1,200,000",
    value: "1200000",
  },
  {
    title: "$1,300,000",
    value: "1300000",
  },
  {
    title: "$1,400,000",
    value: "1400000",
  },
  {
    title: "$1,500,000",
    value: "1500000",
  },
  {
    title: "$1,600,000",
    value: "1600000",
  },
  {
    title: "$1,700,000",
    value: "1700000",
  },
  {
    title: "$1,800,000",
    value: "1800000",
  },
  {
    title: "$1,900,000",
    value: "1900000",
  },
  {
    title: "$2,000,000",
    value: "2000000",
  },
];
export const SqftList = [
  {
    title: "any",
    value: "*",
  },
  {
    title: "100+",
    value: "100",
  },
  {
    title: "200+",
    value: "200",
  },
  {
    title: "250+",
    value: "250",
  },
  {
    title: "300+",
    value: "300",
  },
  {
    title: "400+",
    value: "400",
  },
  {
    title: "500+",
    value: "500",
  },
  {
    title: "600+",
    value: "600",
  },
  {
    title: "700+",
    value: "700",
  },
  {
    title: "750+",
    value: "750",
  },
  {
    title: "800+",
    value: "800",
  },
  {
    title: "900+",
    value: "900",
  },
  {
    title: "1000+",
    value: "1000",
  },
  {
    title: "1100+",
    value: "1100",
  },
  {
    title: "1200+",
    value: "1200",
  },
  {
    title: "1250+",
    value: "1250",
  },
  {
    title: "1300+",
    value: "1300",
  },
  {
    title: "1400+",
    value: "1400",
  },
  {
    title: "1500+",
    value: "1500",
  },
  {
    title: "1600+",
    value: "1600",
  },
  {
    title: "1700+",
    value: "1700",
  },
  {
    title: "1750+",
    value: "1750",
  },
  {
    title: "1800+",
    value: "1800",
  },
  {
    title: "1900+",
    value: "1900",
  },
  {
    title: "2000+",
    value: "2000",
  },
  {
    title: "2100+",
    value: "2100",
  },
  {
    title: "2200+",
    value: "2200",
  },
  {
    title: "2250+",
    value: "2250",
  },
  {
    title: "2300+",
    value: "2300",
  },
  {
    title: "2400+",
    value: "2400",
  },
  {
    title: "2500+",
    value: "2500",
  },
  {
    title: "2600+",
    value: "2600",
  },
  {
    title: "2700+",
    value: "2700",
  },
  {
    title: "2800+",
    value: "2800",
  },
  {
    title: "2900+",
    value: "2900",
  },
  {
    title: "3000+",
    value: "3000",
  },
  {
    title: "3250+",
    value: "3250",
  },
  {
    title: "3500+",
    value: "3500",
  },
  {
    title: "3750+",
    value: "3750",
  },
  {
    title: "4000+",
    value: "4000",
  },
  {
    title: "4250+",
    value: "4250",
  },
  {
    title: "4500+",
    value: "4500",
  },
  {
    title: "4750+",
    value: "4750",
  },
  {
    title: "5000+",
    value: "5000",
  },
  {
    title: "5500+",
    value: "5500",
  },
  {
    title: "6000+",
    value: "6000",
  },
  {
    title: "6500+",
    value: "6500",
  },
  {
    title: "7000+",
    value: "7000",
  },
  {
    title: "8000+",
    value: "8000",
  },
  {
    title: "9000+",
    value: "9000",
  },
  {
    title: "10000+",
    value: "10000",
  },
  {
    title: "11000+",
    value: 11000,
  },
  {
    title: "12000+",
    value: 12000,
  },
  {
    title: "13000+",
    value: 13000,
  },
  {
    title: "14000+",
    value: 14000,
  },
  {
    title: "15000+",
    value: 15000,
  },
];

export const LostSizeList = [
  {
    title: "any",
    value: "*",
  },
  {
    title: "0.1+",
    value: "0.1",
  },
  {
    title: "0.15+",
    value: "0.15",
  },
  {
    title: "0.2+",
    value: "0.2",
  },
  {
    title: "0.25+",
    value: "0.25",
  },
  {
    title: "0.30+",
    value: "0.30",
  },
  {
    title: "0.35+",
    value: "0.35",
  },
  {
    title: "0.40+",
    value: "0.40",
  },
  {
    title: "0.45+",
    value: "0.45",
  },
  {
    title: "0.5+",
    value: "0.5",
  },
  {
    title: "0.75+",
    value: "0.75",
  },
  {
    title: "1+",
    value: "1",
  },
  {
    title: "1.5+",
    value: "1.5",
  },
  {
    title: "2+",
    value: "2",
  },
  {
    title: "2.5+",
    value: "2.5",
  },
  {
    title: "3+",
    value: "3",
  },
  {
    title: "4+",
    value: "4",
  },
  {
    title: "5+",
    value: "5",
  },
  {
    title: "10+",
    value: "10",
  },
  {
    title: "20+",
    value: "20",
  },
  {
    title: "30+",
    value: "30",
  },
  {
    title: "40+",
    value: "40",
  },
  {
    title: "50+",
    value: "50",
  },
  {
    title: "60+",
    value: "60",
  },
  {
    title: "100+",
    value: "100",
  },
  {
    title: "200+",
    value: "200",
  },
  {
    title: "500+",
    value: "500",
  },
];

export const bedroomsList = [
  {
    title: "any",
    value: "*",
  },
  {
    title: "1+",
    value: "1",
  },
  {
    title: "2+",
    value: "2",
  },
  {
    title: "3+",
    value: "3",
  },
  {
    title: "4+",
    value: "4",
  },
  {
    title: "5+",
    value: "5",
  },
  {
    title: "6+",
    value: "6",
  },
];
let year = new Date().getFullYear();
const currentYearsList = [];
const pastYearsList = [];
Array.from(Array(year - 2000).keys()).map((item, index) => {
  currentYearsList.push({
    title: `${year - index}+`,
    value: `${year - index}`,
  });
});
Array.from(Array(11).keys()).map((item, index) => {
  currentYearsList.push({
    title: `${2000 - index * 10}+`,
    value: `${2000 - index * 10}`,
  });
});
export const yearBuiltList = [
  {
    title: "any",
    value: "*",
  },
]
  .concat(currentYearsList)
  .concat(pastYearsList);
export const propertyRangListData = {
  BuildingAreaTotal: SqftList,
  LotSizeAcres: LostSizeList,
  BedroomsTotal: bedroomsList,
  YearBuilt: yearBuiltList,
  BathroomsTotalInteger: bedroomsList,
  GarageSpaces: bedroomsList,
};

export const searchKeys = [
  "City",
  "HighSchool",
  "MiddleOrJuniorSchool",
  "ElementarySchool",
  "SubdivisionName",
  "PostalCode",
  "CountyOrParish",
];

export const interiorFeatures = [
  {
    title: "Bedroom Features",
    value: "RoomBedroomFeatures",
  },
  {
    title: "Laundry Features",
    value: "LaundryFeatures",
  },
  {
    title: "Basement Description",
    value: "Basement",
  },
  {
    title: "Dining Room Features",
    value: "RoomDiningRoomFeatures",
  },
  {
    title: "Common Walls",
    value: "CommonWalls",
  },
  {
    title: "Kitchen Features",
    value: "RoomKitchenFeatures",
  },
];
export const exteriorFeatures = [
  {
    title: "Architectural Style",
    value: "ArchitecturalStyle",
  },
  {
    title: "Construction Materials",
    value: "ConstructionMaterials",
  },
  {
    title: "Stories",
    value: "Levels",
  },
  {
    title: "Lot Features",
    value: "LotFeatures",
  },
  {
    title: "Parking Features",
    value: "ParkingFeatures",
  },
];

export const communityAndOtherFeatures = [
  {
    title: "Community Features",
    value: "CommunityFeatures",
  },
  {
    title: "Property Condition",
    value: "PropertyCondition",
  },
  {
    title: "Special Listing Conditions",
    value: "SpecialListingConditions",
  },
  {
    title: "Special Circumstances",
    value: "FMLS_SpecialCircumstances",
  },
  {
    title: "HOPA",
    value: "SeniorCommunityYN",
  },
];
export const ownershipAndOtherFeatures = [
  {
    title: "Ownership",
    value: "Ownership",
  },
  {
    title: "Pool Features",
    value: "PoolFeatures",
  },
  {
    title: "Fireplace Features",
    value: "FireplaceFeatures",
  },
  {
    title: "Lake Names",
    value: "WaterBodyName",
  },
];

const PropertyType = [
  { label: "For Sale", value: "Residential" },
  { label: "For Rent", value: "Residential Lease" },
  { label: "Land", value: "Land" },
  { label: "Income", value: "Residential Income" },
];
const PropertySubType = [{ label: "For Sale", value: "Residential" }];

const propertyCheckboxOptions = {
  PropertySubType,
  PropertySubType,
};

export const returnKeyExistsInObject = (object, keys) => {
  for (let i = 0; i < keys.length; i++) {
    if (object.hasOwnProperty(keys[i])) {
      return keys[i];
    }
  }
  return "";
};

export const returnKeyMultipleExistsInObject = (object, keys) => {
  let newKeys = [];
  for (let i = 0; i < keys.length; i++) {
    if (object.hasOwnProperty(keys[i])) {
      newKeys.push(keys[i]);
    }
  }
  return newKeys;
};

export const decodeRangeValue = (value, type, notation) => {
  if (value) {
    let newValue = value
      ?.replaceAll("[", "")
      ?.replaceAll(" ", "")
      ?.replaceAll("]", "")
      ?.split("TO");
    if (type === "min") {
      return notation && newValue[0] !== "*"
        ? NumberFormat({
            number: newValue[0] || null,
            notation: notation || "compact",
          })
        : newValue[0];
    }
    if (type === "max") {
      return notation && newValue[1] !== "*"
        ? NumberFormat({
            number: newValue[1] || null,
            notation: notation || "compact",
          })
        : newValue[1];
    }
  }
};
export const decodeRangeValueWithoutFormat = (value, type) => {
  if (value) {
    let newValue = value
      ?.replaceAll("[", "")
      ?.replaceAll(" ", "")
      ?.replaceAll("]", "")
      ?.split("TO");
    if (type === "min") {
      return newValue[0] !== "*" ? newValue[0] : null;
    }
    if (type === "max") {
      return newValue[1] !== "*" ? newValue[1] : null;
    }
  }
};
export const decodeRangeValueWithFormat = (value, type, notation) => {
  if (value) {
    let newValue = value
      ?.replaceAll("[", "")
      ?.replaceAll(" ", "")
      ?.replaceAll("]", "")
      ?.split("TO");
    if (type === "min") {
      return notation && newValue[0] !== "*"
        ? NumberFormat({
            number: newValue[0] || null,
            notation: notation || "compact",
          })
        : null;
    }
    if (type === "max") {
      return notation && newValue[1] !== "*"
        ? NumberFormat({
            number: newValue[1] || null,
            notation: notation || "compact",
          })
        : null;
    }
  }
};
export const decodeMultipleValue = (newValue) => {
  if (newValue?.split("OR")?.length > 1) {
    let filterValues = newValue
      ?.split(" OR ")
      ?.map((item) => {
        if (item.includes(" ") && !item?.includes(`"`)) {
          return `"${item}"`;
        } else {
          return item;
        }
      })
      ?.join(" OR ");
    return `(${filterValues})`;
  } else {
    return newValue?.includes(" ") && !newValue?.includes(`"`)
      ? `"${newValue}"`
      : newValue;
  }
};
export const splitMultipleValue = (newValue) => {
  if (newValue?.split("OR")?.length > 1) {
    return newValue
      ?.replaceAll("(", "")
      ?.replaceAll(")", "")
      ?.replaceAll(`"`, "")
      ?.split(" OR ");
  } else {
    return newValue;
  }
};

export const isChecked = (value, type) => {
  let newValue = value
    ?.replaceAll(")", "")
    ?.replaceAll("(", "")
    ?.replaceAll(`"`, "")
    ?.split(" OR ");
  return newValue?.includes(type);
};

export const generateValue = (data, isQuotes) => {
  if (data?.value?.length > 1) {
    let newValue = data?.value?.map((it) => {
      if (it?.includes(" ") && isQuotes) {
        return `"${it
          ?.replaceAll(")", "")
          ?.replaceAll("(", "")
          ?.replaceAll(`"`, "")}"`;
      } else {
        return it
          ?.replaceAll(")", "")
          ?.replaceAll("(", "")
          ?.replaceAll(`"`, "");
      }
    });
    return newValue?.join(" OR ");
  } else {
    return data?.value?.[0]?.includes(" ") && isQuotes
      ? `"${data?.value?.[0]
          ?.replaceAll(")", "")
          ?.replaceAll("(", "")
          ?.replaceAll(`"`, "")}"`
      : data?.value?.[0]
          ?.replaceAll(")", "")
          ?.replaceAll("(", "")
          ?.replaceAll(`"`, "");
  }
};

export const formateKeys = {
  ListPrice: "Price",
  MlsStatus: "Status",
  BedroomsTotal: "Beds",
  BathroomsTotalDecimal: "Baths",
  LotSizeAcres: "Lot Size",
  BuildingAreaTotal: "SqFt",
  YearBuilt: "Year Built",
  PropertySubType: "Property Sub Type",
};

export const queryParsing = (queryString) => {
  if (queryString) {
    // const regex = /(\w+:\([^)]+\)|\w+:\[[^\]]+\])|(\w+:[^ ]+)/g;
    const regex =
      /(\w+:\([^)]+\)|\w+:\[[^\]]+\])|(\w+:"(?:\\"|[^"])+")|(\w+:[^ ]+)/g;
    const matches = queryString.match(regex);

    const parsedResult = {};

    if (matches) {
      matches.forEach((match) => {
        const [key, value] = match.split(":");
        if (value?.includes(")") && !value?.includes("(")) {
          parsedResult[key] = value ? value.trim()?.replaceAll(")", "") : "";
        } else {
          parsedResult[key] = value ? value.trim() : "";
        }
      });
    }
    return parsedResult;
  } else {
    return {};
  }
};

export const XAccessToken =
  "S3BjiOX1mRg=qVEd61N04NI3mOUfu0w/SvYtznOtcz8L7PzMeG3YdgMu4=rkvgImNF9z62FHM7UuhCb2=h20R=z1PrmOeWKz1WRuySS6FrIW1/zCVc35rP=LGwm=ByxD";

export const accounts = [
  {
    _id: "650638a246269c564e5359cc",
    account_name: "nicole",
    secret_token:
      "zzr3KjCYIFD0PS98SjL8z3FdgGmiJemnrUiVJbrRkEWrmwDEm4R1m3PCAtZGa8DmxhGJX20npcHLmGyWMh1w9KEY8OzckfWlr4xaMuq0vXcFe3Av6ER3LWCfgcnOYSOj",
  },
  {
    _id: "64cc3fead487ba2d5989c899",
    account_name: "method",
    secret_token:
      "FxLL4y8leQ8mJ9BdtyYbhqVaDVs7uPb1Qp7dNwNZmTAnl9nmMLdxjq0s6VEpUEfHKiPG77zh6CYaVAJXz7MG7f85yek1LTPkJlH52K6WJHjeRQDfb1iKki9j0lgGoJKK",
  },
  {
    _id: "65665105c7b2341e47aff9e9",
    account_name: "nhg",
    secret_token:
      "IOEvvlxb63ahyKQht8L2f8VEpTe6tMmN01OHk9vypC5gWS6CJ9iaKUPJK51DgLaZ3MzWm76Z6M7Rybq8GIxdEZhv3TFOtB8dfKYXJGelM1pvkvs3GisADOfBPiH0aBb9",
  },
  {
    _id: "656cac225611c0042b12ff31",
    account_name: "methodprod",
    secret_token:
      "bbrVzF23clm7GUN617BmhrBBbaKYIs9PS25Ng81v782zyPdobh1q3dTzRMVl0GlUyta3UswXG0ktZnAcqYULxbv21mb0JiEkhH7qkz5S9hImeq3cT0Fsg7HS99RcdiQS",
  },
];

export const capitalizeParagraph = (paragraph) => {
  // Split the paragraph into an array of words
  let words = paragraph?.toLowerCase().split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
  );

  // Join the capitalized words back into a paragraph
  const capitalizedParagraph = capitalizedWords.join(" ");

  return capitalizedParagraph;
};

export const formatAddress = (listingDetail) => {
  let StreetNumber =
    listingDetail?.StreetNumber || listingDetail?.AddressNumber || "";
  let StreetName = listingDetail?.StreetName || "";
  let StreetSuffix = listingDetail?.StreetSuffix
    ? ` ${listingDetail?.StreetSuffix}`
    : "";
  let StreetDirPrefix = listingDetail?.StreetDirPrefix
    ? ` ${listingDetail?.StreetDirPrefix}`
    : "";
  let StreetDirSuffix = listingDetail?.StreetDirSuffix
    ? ` ${listingDetail?.StreetDirSuffix}`
    : "";
  let UnitNumber = listingDetail?.UnitNumber
    ? ` Unit ${listingDetail?.UnitNumber}`
    : "";
  let City = listingDetail?.City || "";
  let StateOrProvince = listingDetail?.StateOrProvince || "";
  let PostalCode = listingDetail?.PostalCode || "";

  if (listingDetail?.isFullAddressNeeded) {
    return capitalizeParagraph(
      `${StreetNumber} ${StreetDirPrefix} ${StreetName}${StreetSuffix}${StreetDirSuffix}${UnitNumber} ${City}, ${StateOrProvince} ${PostalCode}`
    );
  } else {
    return capitalizeParagraph(
      `${StreetNumber} ${StreetDirPrefix} ${StreetName} ${StreetSuffix} ${StreetDirSuffix} ${UnitNumber}`
    );
  }
};

export const getAcres = (data) => {
  if (data?.LotSizeAcres && data?.LotSizeAcres > 0) {
    return data?.LotSizeAcres;
  } else if (data?.LotSizeSquareFeet && data?.LotSizeSquareFeet > 0) {
    return (data?.LotSizeSquareFeet / 43560).toFixed(1);
  } else {
    return 0;
  }
};

export const getOptimizedImageUrl = (url, resolution) => {
  if (url?.startsWith("//")) {
    url = "https:" + url;
  }
  if (resolution === "165x165") {
    return `https://d2bvtii6wos6sc.cloudfront.net/w_165,h_165,c_fill/${encodeURIComponent(
      url
    )}`;
  } else if (resolution === "416x276") {
    return `https://d2bvtii6wos6sc.cloudfront.net/w_416/${encodeURIComponent(
      url
    )}`;
  } else if (resolution === "640x480") {
    return `https://d2bvtii6wos6sc.cloudfront.net/w_640/${encodeURIComponent(
      url
    )}`;
  } else if (resolution === "2048x1536") {
    return `https://d2bvtii6wos6sc.cloudfront.net/w_2048/${encodeURIComponent(
      url
    )}`;
  } else if (resolution === "240x180") {
    return `https://d2bvtii6wos6sc.cloudfront.net/w_240/${encodeURIComponent(
      url
    )}`;
  } else {
    return `https://d2bvtii6wos6sc.cloudfront.net/${encodeURIComponent(url)}`;
  }
};

export const getImageUrlWithHeightAndWidth = (url, height, width) => {
  if (url?.startsWith("//")) {
    url = "https:" + url;
  }
  if (height && width) {
    return `https://d2bvtii6wos6sc.cloudfront.net/w_${width},h_${height}/${encodeURIComponent(
      url
    )}`;
  } else if (height) {
    return `https://d2bvtii6wos6sc.cloudfront.net/h_${height}/${encodeURIComponent(
      url
    )}`;
  } else if (width) {
    return `https://d2bvtii6wos6sc.cloudfront.net/w_${width}/${encodeURIComponent(
      url
    )}`;
  } else {
    return `https://d2bvtii6wos6sc.cloudfront.net/${encodeURIComponent(url)}`;
  }
};

export const samplePropertyIMage =
  "https://method-idx.s3.amazonaws.com/midx-assets/defaultPropertyNoImage.png";
