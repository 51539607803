import React from "react";
import { Box, Skeleton } from "@mui/material";
function DosPageSkelton() {
  return (
    <Box>
      {" "}
      <Skeleton variant="rectangular" animation="wave" height={"1188px"} width="100%" />
    </Box>
  );
}
export default DosPageSkelton;
