import axios from "../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");

export const addForm = createAsyncThunk(
  "docsAddForm/addForm",
  async (newdata, thunkAPI) => {
    const {id,data}=newdata;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/docs/folder/${id}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "put",
        data: data,
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            console.log("res", res);
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

