import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const addGroupRouting = createAsyncThunk("LeadRoutingGroups/addGroupRouting", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/lead-routing/groups`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
