import React from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Grid, Container, Paper,Typography } from "@mui/material";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import CircleIcon from "@mui/icons-material/Circle";
import TextareaAutosize from '@mui/material/TextareaAutosize';

const options = ['June 5, 2022 (0:43)', 'Dec 20, 2021 (0:40)'];
const optionsRing = ['Ringtone 1', 'Ringtone 2'];
const optionsMusic = ['Music 1', 'Music 2'];

function PreferencePage(props) {
  let { classes } = props;

  const [open, setOpen] = React.useState(false);
  const [ringOpen, setRingOpen] = React.useState(false);
  const [musicOpen, setMusicOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRefRing = React.useRef(null);
  const anchorRefMusic= React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedIndex1, setSelectedIndex1] = React.useState(1);
  const [selectedIndex2, setSelectedIndex2] = React.useState(1);

  const handleMenuItemClick = (event, index,type) => {
    if(type==="voiceMail"){
      setSelectedIndex(index);
      setOpen(false);
    }else if(type==="ringTone"){
      setSelectedIndex1(index);
      setRingOpen(false);
    }
    else if(type==="music"){
      setSelectedIndex2(index);
      setMusicOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };


  const handleToggle1 = () => {
    setRingOpen((prevOpen) => !prevOpen);
  };

  const handleClose1 = (event) => {
    if (anchorRefRing.current && anchorRefRing.current.contains(event.target)) {
      return;
    }
    setRingOpen(false);
  };

  const handleToggle2 = () => {
    setMusicOpen((prevOpen) => !prevOpen);
  };

  const handleClose2 = (event) => {
    if (anchorRefMusic.current && anchorRefMusic.current.contains(event.target)) {
      return;
    }
    setMusicOpen(false);
  };
  

  
  return (
    <Container className={classes.preferenceRoot}>
      <Paper
        sx={{ padding: "20px" }}
        elevation={3}
        classes={{ root: classes.preferenceRootPaper }}
      >
        {" "}
        <Typography classes={{root:classes.mainHeading}} variant="h5">   My Preferences</Typography>
        <Grid flex-wrap={'wrap'} container spacing={2} sx={{padding:'0px 15px'}}>
        <Grid item xs={12} md={5}>
        <Typography variant="h6" classes={{root:classes.subHeading}}>  Voicemail</Typography>
        <Stack direction="row" spacing={2}>
        <ButtonGroup  variant="contained" ref={anchorRef} aria-label="split button">
        <Button variant="outlined" sx={{border: "1px solid rgba(33, 33, 33, 0.4)"}} >{options[selectedIndex]}</Button>
        <Button
          size="small"
          variant="outlined"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Select voicemail"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <ButtonGroup  variant="contained" aria-label="split button">
      <Button
          variant="outlined"
          classes={{root:classes.customButton}}
        >
          <PlayArrowRoundedIcon sx={{fontSize:"25px",color:"rgba(0, 0, 0, 0.54)"}} />
        </Button>
        <Button
          variant="outlined"
          classes={{root:classes.customButton}}
        >
          <CircleIcon sx={{fontSize:"17px",color:"rgba(0, 0, 0, 0.54)"}} color="rgba(0, 0, 0, 0.54)" />
        </Button>
      </ButtonGroup>
      </Stack>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              minWidth:'205px',
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index,"voiceMail")}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Typography variant="h6"  classes={{root:classes.subHeading}}>  Ringtone</Typography>
        <Stack direction="row" spacing={2}>
        <ButtonGroup  variant="contained" ref={anchorRefRing} aria-label="split button">
        <Button variant="outlined" sx={{border: "1px solid rgba(33, 33, 33, 0.4)",width:'150px'}} >{optionsRing[selectedIndex1]}</Button>
        <Button
          size="small"
          variant="outlined"
          aria-controls={ringOpen ? 'split-button-menu' : undefined}
          aria-expanded={ringOpen ? 'true' : undefined}
          aria-label="Select voicemail"
          aria-haspopup="menu"
          onClick={handleToggle1}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <ButtonGroup  variant="contained" aria-label="split button">
      <Button
          variant="outlined"
          classes={{root:classes.customButton}}
        >
          <PlayArrowRoundedIcon sx={{fontSize:"25px",color:"rgba(0, 0, 0, 0.54)"}} />
        </Button>
      </ButtonGroup>
      </Stack>
      <Popper
        open={ringOpen}
        anchorEl={anchorRefRing.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              minWidth:'190px',
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose1}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {optionsRing.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex1}
                      onClick={(event) => handleMenuItemClick(event, index,"ringTone")}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography variant="h6"  classes={{root:classes.subHeading}}>  Hold Music</Typography>
        <Stack direction="row" spacing={2}>
        <ButtonGroup  variant="contained" ref={anchorRefMusic} aria-label="split button">
        <Button variant="outlined" sx={{border: "1px solid rgba(33, 33, 33, 0.4)",width:'150px'}} >{optionsMusic[selectedIndex2]}</Button>
        <Button
          size="small"
          variant="outlined"
          aria-controls={musicOpen ? 'split-button-menu' : undefined}
          aria-expanded={musicOpen ? 'true' : undefined}
          aria-label="Select voicemail"
          aria-haspopup="menu"
          onClick={handleToggle2}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <ButtonGroup  variant="contained" aria-label="split button">
      <Button
          variant="outlined"
          classes={{root:classes.customButton}}
        >
          <PlayArrowRoundedIcon sx={{fontSize:"25px",color:"rgba(0, 0, 0, 0.54)"}} />
        </Button>
      </ButtonGroup>
      </Stack>
      <Popper
        open={musicOpen}
        anchorEl={anchorRefMusic.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              minWidth:'190px',
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose2}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {optionsMusic.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex1}
                      onClick={(event) => handleMenuItemClick(event, index,"music")}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
        </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ButtonGroup  variant="contained" aria-label="split button">
      <Button
          variant="outlined"
          classes={{root:classes.customButton}}
          sx={{backgroundColor:"rgba(33, 33, 33, 0.04)"}}
        >
        Option 1
        </Button>
        <Button
          variant="outlined"
          classes={{root:classes.customButton}}
        >
             Option 2
        </Button>
        <Button
          variant="outlined"
          classes={{root:classes.customButton}}
        >
             Option 3
        </Button>
      </ButtonGroup>
      <br />
      <br />
      <TextareaAutosize
      aria-label="empty textarea"
      placeholder="Email Signature Preview"
      style={{ width: "100%",padding:'10px',color:"rgba(0, 0, 0, 0.6)",borderRadius:'4px',border: '1px solid rgba(0, 0, 0, 0.23)' }}
      minRows={15}
    />
          <br />
      <br />
      <br />
      </Paper>
    </Container>
  );
}
export default withStyles(styleSheet, { name: "PreferencePageStyle" })(
  PreferencePage
);
