import * as React from "react";
import { Card, CardHeader, Grid, Skeleton, Typography } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";

function CheckOrACHStats(props) {
  let { classes, loading, data } = props;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Grid container direction="row" spacing={3} sx={{ marginTop: "50px" }}>
      <Grid item md={3} lg={3} xl={3}>
        <Card
          sx={
            location.pathname === "/finance/closings/checks-to-print" ||
            location.pathname === "/finance/closings/checks-to-print/"
              ? { backgroundColor: "black", color: "white", cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={(e) => {
            navigate("/finance/closings/checks-to-print");
          }}
        >
          <CardHeader
            classes={{
              title: classes.cardHeader,
            }}
            avatar={
              loading ? (
                <Skeleton width={30} height={40} variant="text" />
              ) : (
                <Typography variant="h3">{data.Check}</Typography>
              )
            }
            // title={allTransactions.isLoading ? <Skeleton width={60} variant="text" /> : allTransactions.data.count}
            title="Checks To Print"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={3} xl={3}>
        <Card
          sx={
            location.pathname === "/finance/closings/ach-to-send" ||
            location.pathname === "/finance/closings/ach-to-send/"
              ? { backgroundColor: "black", color: "white", cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={(e) => {
            navigate("/finance/closings/ach-to-send");
          }}
        >
          <CardHeader
            classes={{
              title: classes.cardHeader,
            }}
            avatar={
              loading ? (
                <Skeleton width={30} height={40} variant="text" />
              ) : (
                <Typography variant="h3">{data.ACH}</Typography>
              )
            }
            title="ACH To send"
          />
        </Card>
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "CheckOrACHStatsStyle" })(
  CheckOrACHStats
);
