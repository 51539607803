import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  Stack,
  Typography,
  Box,
  IconButton,
  Card,
  CardHeader,
  InputLabel,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { MultipleDropsdownSelection } from "../../../components/shared/multipleDropdownSelection";
import { otherItemArray } from "./addEvent";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { handleAddAlert, handleUpdateAlert, handleDeleteAlert } from "../../../redux/eventsAndAlerts/alerts";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { handleGetParseData } from "../alerts/index";
import CircularProgress from "@mui/material/CircularProgress";
import blackExclamation from "../../../assets/images/alertIcons/black-exclamation.png";
import bug from "../../../assets/images/alertIcons/bug.png";
import communication from "../../../assets/images/alertIcons/communication.png";
import message from "../../../assets/images/alertIcons/message.png";
import octoExclamation from "../../../assets/images/alertIcons/octo-exclamation.png";
import roundExclamatin from "../../../assets/images/alertIcons/round-exclamatin.png";
import star from "../../../assets/images/alertIcons/star.png";
import whiteExclamation from "../../../assets/images/alertIcons/white-exclamation.png";
export const icons = [
  {
    icon: blackExclamation,
    value: "black-exclamation",
  },
  {
    icon: whiteExclamation,
    value: "white-exclamation",
  },
  {
    icon: bug,
    value: "bug",
  },
  {
    icon: roundExclamatin,
    value: "round-exclamatin",
  },
  {
    icon: octoExclamation,
    value: "octo-exclamation",
  },
  {
    icon: star,
    value: "star",
  },
  {
    icon: message,
    value: "message",
  },
  {
    icon: communication,
    value: "communication",
  },
];
const moment = require("moment-timezone");
function AddAlertModal(props) {
  let { classes, open, setOpen, alertData } = props;
  const alertTitle = useRef();
  const alertBody = useRef();
  const [actionType, setActionType] = useState("");
  const [isNoIcon, setIsNoIcon] = useState(false);
  const [alertDetail, setAlertDetail] = useState({
    expiresAt: "",
    expireTime: null,
    startsAt: null,
    startTime: null,
    who_to_alert: [],
    icon: null,
    user_ids: [],
    background_color: "#ffffff",
    text_color: "#000000",
    notify_user: false,
    schedule: false,
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const usersList = useSelector((item) => item.users?.storedListOfUsers?.list);
  const addAlert = useSelector((item) => item.eventAlerts.alerts.addAlert);
  const updateAlert = useSelector((item) => item.eventAlerts.alerts.updateAlert);
  const templateList = useSelector((item) => item.eventAlerts.templates.templateList);
  const deleteAlert = useSelector((item) => item.eventAlerts.alerts.deleteAlert);
  useEffect(() => {
    if (open === "update") {
      let newArrayData = [];
      if (alertData.who_to_alert && typeof alertData.who_to_alert === "string") {
        let othersData = handleGetParseData(alertData.who_to_alert);
        otherItemArray.map((item) => {
          othersData.map((item1) => {
            if (item.id === item1) {
              newArrayData.push({ ...item, filterType: "others" });
            }
          });
        });
        usersList.map((item) => {
          othersData.map((item1) => {
            if (item.full_name === item1 || item.name === item1) {
              newArrayData.push({ ...item, name: item.full_name || item.name, filterType: "agent" });
            }
          });
        });
      }
      setAlertDetail({
        ...alertData,
        user_ids: newArrayData,
        schedule: alertData.startTime && alertData.startsAt ? true : false,
      });
      if (alertData.icon) {
        setIsNoIcon(false);
      }else{
        setIsNoIcon(true);
      }
    }
  }, [open]);

  useEffect(() => {
    if (addAlert.success && !addAlert.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully created the alert",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!addAlert.isLoading && addAlert.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addAlert.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addAlert.isLoading]);
  useEffect(() => {
    if (deleteAlert.success && !deleteAlert.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully deleted the alert",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!deleteAlert.isLoading && deleteAlert.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(deleteAlert.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deleteAlert.isLoading]);

  useEffect(() => {
    if (updateAlert.success && !updateAlert.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully updated the alert",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!updateAlert.isLoading && updateAlert.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateAlert.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateAlert.isLoading]);

  const handleClose = () => {
    setOpen(false);
    setErrMsg({});
    setActionType("");
    setAlertDetail({
      expiresAt: "",
      expireTime: null,
      startsAt: null,
      startTime: null,
      who_to_alert: [],
      icon: null,
      user_ids: [],
      background_color: "#ffffff",
      text_color: "#000000",
      notify_user: false,
      schedule: false,
    });
    setIsNoIcon(false);
  };
  const handleUpdateDetail = (field, value) => {
    setAlertDetail({
      ...alertDetail,
      [field]: value,
    });
  };
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    return [...agents];
  };
  const handleGetOptions = () => {
    let users = [];
    if (usersList?.length) {
      usersList.map((item) => {
        let obj = {
          group: item?.role?.name,
          ...item,
          name: item.full_name || item.name,
          picture: item?.profile_images?.profile_img || "",
          filterType: "agent",
        };
        users.push(obj);
      });
    }

    return [...otherItemArray, ...CUstomSortUsers(users)];
  };
  const handleUpdateErrMsg = (field, value) => {
    if (errMsg[field] && value) {
      let errors = { ...errMsg };
      delete errors[field];
      setErrMsg(errors);
    }
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!alertTitle?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the alert title";
      errorMsg["alertTitle"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!alertDetail.expiresAt) {
      isFormValid = false;
      let msg = "Please select the expire date";
      errorMsg["expiresAt"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!alertDetail.expireTime) {
      isFormValid = false;
      let msg = "Please select the expire time";
      errorMsg["expireTime"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!alertBody?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the alert description";
      errorMsg["alertBody"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (alertDetail.schedule && !alertDetail.startsAt) {
      isFormValid = false;
      let msg = "Please select the post date";
      errorMsg["startsAt"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (alertDetail.schedule && !alertDetail.startTime) {
      isFormValid = false;
      let msg = "Please select the post time";
      errorMsg["startTime"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleCreateAlert = () => {
    if (handleValidate()) {
      let obj = {
        ...alertDetail,
        expiresAt: moment(new Date(alertDetail?.expiresAt)).tz("America/New_York").format()?.slice(0, 10),
        startsAt: alertDetail?.startsAt ? moment(new Date(alertDetail?.startsAt)).tz("America/New_York").format()?.slice(0, 10) : undefined,
        startTime: alertDetail?.startTime,
        expireTime: alertDetail?.expireTime,
        title: alertTitle.current.value,
        body: alertBody.current.value,
        user_ids: alertDetail.user_ids.filter((item) => item.filterType === "agent").map((item) => item.id),
        who_to_alert: alertDetail.user_ids
          .filter((item) => item.filterType === "others")
          .map((item) => item.id)
          .concat(alertDetail.user_ids.filter((item) => item.filterType === "agent").map((item) => item.name)),
        event_id: open && open !== "update" && open !== true ? open : undefined,
      };
      dispatch(handleAddAlert(obj));
    }
  };
  const handelUpdateAlert = () => {
    if (handleValidate()) {
      let obj = {
        ...alertDetail,
        id: alertData.id,
        expiresAt: moment(new Date(alertDetail?.expiresAt)).tz("America/New_York").format()?.slice(0, 10),
        startsAt: alertDetail?.startsAt ? moment(new Date(alertDetail?.startsAt)).tz("America/New_York").format()?.slice(0, 10) : undefined,
        startTime: alertDetail?.startTime,
        expireTime: alertDetail?.expireTime,
        title: alertTitle.current.value,
        body: alertBody.current.value,
        user_ids: alertDetail.user_ids.filter((item) => item.filterType === "agent").map((item) => item.id),
        who_to_alert: alertDetail.user_ids
          .filter((item) => item.filterType === "others")
          .map((item) => item.id)
          .concat(alertDetail.user_ids.filter((item) => item.filterType === "agent").map((item) => item.name)),
      };
      dispatch(handleUpdateAlert(obj));
    }
  };
  const handleUpdateTemplate = (value) => {
    let templateData = templateList?.data?.alertTemplates.find((item) => item.id === value);
    alertTitle.current.value = templateData.title;
    alertBody.current.value = templateData.body;
    handleUpdateDetail("template", value);
  };
  const handleDelete = () => {
    dispatch(handleDeleteAlert({ id: alertData?.id, schedule: alertData.startTime && alertData.startsAt ? true : false }));
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={Boolean(open)}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" }, "& .MuiDialog-container": { height: "95vh", mt: "50px" } }}
      >
        <DialogTitle className={classes.modalHeading} id="alert-dialog-title">
          <Box> {open === "update" ? "Alert Detail" : "Add Alert"}</Box>
          {open === "update" ? (
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {deleteAlert.isLoading ? <CircularProgress size={25} /> : ""}
              <ButtonGroups
                id="Manage-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="36px"
                size="medium"
                color="white"
                minWidth="140px"
                options={[
                  {
                    title: "Edit Details",
                    value: "edit",
                    icon: <BorderColorIcon sx={{ color: "black" }} fontSize="small" />,
                  },
                  { title: "Delete Alert", value: "delete", icon: <DeleteIcon sx={{ color: "black" }} fontSize="small" /> },
                ]}
                onChangeMenu={(value) => {
                  setActionType(value);
                  if (value === "delete") {
                    handleDelete();
                  }
                }}
                value={actionType}
                placeholder={"Manage"}
                {...props}
              />
            </Stack>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Box sx={{ pointerEvents: open === "update" && actionType !== "edit" ? "none" : "", paddingTop: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container direction={"row"} spacing={3}>
                {open === "update" && actionType === "edit" ? (
                  <Grid sm={5} item>
                    <TextField
                      value={alertDetail.template}
                      onChange={(e) => handleUpdateTemplate(e.target.value)}
                      fullWidth
                      select
                      variant="standard"
                      helperText="Select Alert Template"
                    >
                      {templateList?.data?.alertTemplates?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ) : null}

                <Grid sm={5} item>
                  <TextField
                    inputRef={alertTitle}
                    onBlur={() => handleUpdateErrMsg("alertTitle", alertTitle.current.value)}
                    fullWidth
                    variant="standard"
                    helperText="Alert Title"
                    placeholder="FMLS - MATRIX IS DOWN"
                    error={errMsg.alertTitle}
                    defaultValue={alertData.title}
                  />
                </Grid>
                <Grid sm={4} item>
                  <DatePicker
                    views={["day"]}
                    disablePast
                    value={alertDetail.expiresAt}
                    onChange={(newValue) => handleUpdateDetail("expiresAt", newValue)}
                    renderInput={(params) => <TextField fullWidth {...params} variant="standard" error={false} helperText={"Expire Date"} />}
                  />
                </Grid>
                <Grid sm={4} item>

                <TextField
                    fullWidth
                    type="time"
                    variant="standard"
                    error={errMsg.expireTime}
                    value={alertDetail.expireTime}
                    onChange={(e) => handleUpdateDetail("expireTime", `${e.target.value}:00`)}
                    helperText={"Expire Time"}
                    sx={{
                      '& input[type="time"]::-webkit-calendar-picker-indicator': {
                        fontSize: "22px",
                        color: "rgba(0, 0, 0, 0.54) !important",
                        cursor: "pointer",
                      },
                    }}
                  />
                </Grid>
                <Grid sm={12} item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    rows={4}
                    multiline
                    size="small"
                    placeholder="Alert Description"
                    inputRef={alertBody}
                    onBlur={() => handleUpdateErrMsg("alertBody", alertBody.current.value)}
                    error={errMsg.alertBody}
                    defaultValue={alertData.body}
                  />
                </Grid>
                <Grid sm={12} item /* sx={{ pointerEvents: open === "update" ? "none" : "" }} */>
                  <MultipleDropsdownSelection
                    placeholder="Add Invitees"
                    label=""
                    detail={alertDetail}
                    setDetail={setAlertDetail}
                    field="user_ids"
                    options={handleGetOptions()}
                    disableEmailCheck
                    updateFlag={open === "update" ? true : false}
                    {...props}
                  />
                </Grid>
                <Grid sm={6} item>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography sx={{ fontSize: "14px" }}>Select Alert Icon:</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleUpdateDetail("icon", "");
                            }
                            setIsNoIcon(e.target.checked);
                          }}
                          checked={isNoIcon}
                        />
                      }
                      label="No Icon"
                    />
                  </Stack>
                  <Stack direction={"row"} sx={{ pointerEvents: isNoIcon ? "none" : "" }} alignItems={"center"} spacing={0}>
                    {icons.map((item, index) => (
                      <IconButton
                        sx={{
                          borderRadius: "2px",
                          border: item.value === alertDetail?.icon ? "1px solid rgba(0, 0, 0, 0.5)" : "1px solid  white",
                          background: item.value === alertDetail?.icon ? "rgba(151, 151, 151, 0.2)" : "",
                          padding: "6px",
                          "&:hover": { border: "1px solid  rgba(0, 0, 0, 0.04)" },
                        }}
                        onClick={() => handleUpdateDetail("icon", item.value)}
                      >
                        <img src={item.icon} width="26px" alt={item.icon} />
                      </IconButton>
                    ))}
                  </Stack>
                </Grid>
                <Grid sm={6} item>
                  <Stack direction="row" spacing={4}>
                    <Box sx={{ width: "100px" }}>
                      <InputLabel sx={{ mb: "10px" }}>BG Color:</InputLabel>
                      <TextField
                        type="color"
                        onChange={(e) => handleUpdateDetail("background_color", e.target.value)}
                        value={alertDetail.background_color}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      <InputLabel sx={{ mb: "10px" }}>Text Color:</InputLabel>
                      <TextField
                        type="color"
                        onChange={(e) => handleUpdateDetail("text_color", e.target.value)}
                        value={alertDetail.text_color}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid sm={3} item>
                  <FormControlLabel
                    control={<Switch checked={alertDetail.notify_user} onChange={(e) => handleUpdateDetail("notify_user", e.target.checked)} />}
                    label="Push Notification"
                  />
                </Grid>
                <Grid sm={3} item sx={{ pointerEvents: open === "update" ? "none" : "" }}>
                  <FormControlLabel
                    control={<Switch checked={alertDetail.schedule} onChange={(e) => handleUpdateDetail("schedule", e.target.checked)} />}
                    label="Schedule Alert"
                  />
                </Grid>
                {alertDetail.schedule ? (
                  <Grid sm={3} item>
                    <DatePicker
                      views={["day"]}
                      value={alertDetail.startsAt}
                      onChange={(newValue) => handleUpdateDetail("startsAt", newValue)}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} variant="standard" error={errMsg.startsAt} helperText={"Post Date"} />
                      )}
                    />
                  </Grid>
                ) : null}
                {alertDetail.schedule ? (
                  <Grid sm={3} item>
                   <TextField
                    fullWidth
                    type="time"
                    variant="standard"
                    error={errMsg.startTime}
                    value={alertDetail.startTime}
                    onChange={(e) => handleUpdateDetail("startTime", `${e.target.value}:00`)}
                    helperText={"Post Time"}
                    sx={{
                      '& input[type="time"]::-webkit-calendar-picker-indicator': {
                        fontSize: "22px",
                        color: "rgba(0, 0, 0, 0.54) !important",
                        cursor: "pointer",
                      },
                    }}
                  />
                  </Grid>
                ) : null}
                {alertTitle?.current?.value && alertBody?.current?.value ? (
                  <Grid sm={12} item>
                    {" "}
                    <Typography>Preview:</Typography>
                  </Grid>
                ) : null}
                {alertTitle?.current?.value && alertBody?.current?.value ? (
                  <Grid sm={7} item>
                    <Card sx={{ backgroundColor: alertDetail?.background_color, color: alertDetail?.text_color }}>
                      <CardHeader
                        classes={{
                          title: classes.CardHeaderTitle,
                          subheader: classes.CardHeaderDes,
                        }}
                        avatar={
                          <ReportProblemIcon
                            sx={{
                              color: "inherit",
                              fontSize: "40px",
                            }}
                          />
                        }
                        title={alertTitle?.current?.value}
                        subheader={alertBody?.current?.value}
                      />
                    </Card>
                  </Grid>
                ) : null}
              </Grid>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            color="inherit"
            variant="outlined"
          >
            Cancel
          </Button>
          {open !== "update" ? (
            <LoadingButton
              onClick={handleCreateAlert}
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={addAlert.isLoading}
            >
              Create
            </LoadingButton>
          ) : null}
          {actionType === "edit" ? (
            <LoadingButton
              onClick={handelUpdateAlert}
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={updateAlert.isLoading}
            >
              Update
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddAlertModalStyle" })(AddAlertModal);
