import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Box,
  Typography,
  Card,
  Button,
  Stack,
  Divider,
  Checkbox,
  Skeleton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import UserSelectionsForStats from "../../../../components/uiElements/userSelectionDropdown";
import DatePickersForStats from "../../../../components/uiElements/datePicker";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ButtonDropdown from "../../../../components/uiElements/buttonDropdownGeneral";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AgentActivityChart from "./activityChart.js";
import ActivityStatsSkelton from "../../skelton/activityStats.js";
import AgentActivityCardChart from "./smallCardChart.js";
import {
  getAgentReportingStats,
  getAgentReportingChartsData,
} from "../../../../redux/reporting/agentActivities";
import ActivityRecordsTable from "./ActivityTable.js";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import * as XLSX from "xlsx/xlsx.mjs";
const moment = require("moment-timezone");
const menuOptions = [
  { title: "Nothing", value: "nothing" },
  { title: "Email", value: "emails" },
  { title: "Notes", value: "notes" },
  { title: "Text", value: "textMessages" },
  { title: "Calls", value: "calls" },
  { title: "Talk Time", value: "talkTime" },
];
export  const granularityOptions = [
  {
    title: "Hourly",
    value: "hourly",
  },
  {
    title: "Daily",
    value: "daily",
  },
  {
    title: "Weekly",
    value: "weekly",
  },
  {
    title: "Monthly",
    value: "monthly",
  },
];
function AgentActivities(props) {
  let { classes } = props;
  const [filters, setFilters] = useState({
    fromDate: moment().tz("America/New_York").startOf("month").format(),
    lastDate: moment().tz("America/New_York").endOf("month").format(),
    userId: "",
    granularity: { title: "Daily", value: "daily" },
  });
  const [previousPeriod, setPreviousPeriod] = useState({
    isChecked: false,
    fromDate: moment()
      .tz("America/New_York")
      .subtract(1, "month")
      .startOf("month")
      .format(),
    lastDate: moment()
      .tz("America/New_York")
      .subtract(1, "month")
      .endOf("month")
      .format(),
  });
  const [chartType1, setChartType1] = useState({
    title: "Calls",
    value: "calls",
  });
  const [chartType2, setChartType2] = useState({
    title: "Emails",
    value: "emails",
  });
  const dispatch = useDispatch();
  const activityStats = useSelector(
    (state) => state.reporting.agents.activityStats
  );
  const chartData = useSelector((state) => state.reporting.agents.chartData);

  useEffect(() => {
    if (previousPeriod.isChecked) {
      dispatch(
        getAgentReportingStats({
          ...filters,
          granularity: filters?.granularity?.value,
          fromDate: previousPeriod.fromDate.slice(0, 10) + "T00:00:01",
          lastDate: previousPeriod.lastDate.slice(0, 10) + "T23:59:59",
        })
      );
      dispatch(
        getAgentReportingChartsData({
          ...filters,
          granularity: filters?.granularity?.value,
          timeSeries: true,
          fromDate: previousPeriod.fromDate.slice(0, 10) + "T00:00:01",
          lastDate: previousPeriod.lastDate.slice(0, 10) + "T23:59:59",
        })
      );
    } else {
      dispatch(
        getAgentReportingStats({
          ...filters,
          granularity: filters?.granularity?.value,
          fromDate: filters.fromDate.slice(0, 10) + "T00:00:01",
          lastDate: filters.lastDate.slice(0, 10) + "T23:59:59",
        })
      );
      dispatch(
        getAgentReportingChartsData({
          ...filters,
          granularity: filters?.granularity?.value,
          timeSeries: true,
          fromDate: filters.fromDate.slice(0, 10) + "T00:00:01",
          lastDate: filters.lastDate.slice(0, 10) + "T23:59:59",
        })
      );
    }
  }, [filters, previousPeriod.isChecked]);

  const handleUpdateLastPeriod = (data) => {
    let days = getDaysDifference(data);
    if (days === 30) {
      setPreviousPeriod({
        isChecked: false,
        fromDate: moment(data.fromDate)
          .tz("America/New_York")
          .subtract(1, "months")
          .format(),
        lastDate: moment(data.lastDate)
          .tz("America/New_York")
          .subtract(1, "months")
          .format(),
      });
    } else {
      setPreviousPeriod({
        isChecked: false,
        fromDate: moment(data.fromDate)
          .tz("America/New_York")
          .subtract(days + 1, "days")
          .format(),
        lastDate: moment(data.lastDate)
          .tz("America/New_York")
          .subtract(days + 1, "days")
          .format(),
      });
    }
  };

  const handleGetDates = (data) => {
    let days = getDaysDifference(data);
    let granularity = filters.granularity;
    if (days <= 1) {
      granularity = { title: "Hourly", value: "hourly" };
    } else if (days > 1 && days <= 30) {
      granularity = { title: "Daily", value: "daily" };
    } else if (days > 30 && days < 364) {
      granularity = { title: "Weekly", value: "weekly" };
    } else if (days >= 364) {
      granularity = { title: "Monthly", value: "monthly" };
    }
    setFilters((filter) => {
      return {
        ...filter,
        fromDate: data.fromDate,
        lastDate: data.lastDate,
        granularity: granularity,
      };
    });
    handleUpdateLastPeriod(data);
  };

  const handleGetUserDetail = (user) => {
    if (user === "everyOne") {
      setFilters({
        ...filters,
        userId: "",
      });
    } else {
      setFilters({
        ...filters,
        userId: user.id,
      });
    }
  };
  const getDaysDifference = (data) => {
    let a = moment(data.fromDate);
    let b = moment(data.lastDate);
    return b.diff(a, "days");
  };
  const handleSelectChart1 = (value) => {
    setChartType1(value);
  };
  const handleSelectChart2 = (value) => {
    setChartType2(value);
  };
  const handleSelectGranularity = (value) => {
    setFilters({
      ...filters,
      granularity: value,
    });
  };
  const handleFilterGranularityOptions = useCallback(() => {
    let day = getDaysDifference(filters);
    if (day <= 1) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "weekly" && item.value !== "monthly"
      );
      return filteredOptions;
    } else if (day > 1 && day <= 7) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "hourly" && item.value !== "monthly"
      );
      return filteredOptions;
    } else if (day > 7 && day <= 30) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "hourly" && item.value !== "monthly"
      );
      return filteredOptions;
    } else if (day > 30 && day <= 364) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "daily" && item.value !== "hourly"
      );
      return filteredOptions;
    } else if (day > 364) {
      let filteredOptions = granularityOptions.filter(
        (item) =>
          item.value !== "daily" &&
          item.value !== "hourly" &&
          item.value !== "monthly"
      );
      return filteredOptions;
    }
  }, [filters.fromDate, filters.lastDate]);

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    let date = [];
    while (moment(startDate) <= moment(endDate)) {
      date.push(startDate);
      startDate = moment(startDate).add(1, "days").format();
    }
    return date;
  };

  const handleMakeChartData = useCallback(() => {
    let activityData = chartData?.data?.agentActivity;
    let datesBTW = enumerateDaysBetweenDates(
      filters.fromDate,
      filters.lastDate
    );
    let labels = [];
    let datasets = [];
    if (filters.granularity?.value === "daily" && datesBTW?.length) {
      labels = datesBTW.map((item) =>
        moment(item).tz("America/New_York").format("LL")
      );
    } else if (filters.granularity?.value === "hourly") {
      let timeLable = [
        "12:00AM",
        "1:00AM",
        "2:00AM",
        "3:00AM",
        "4:00AM",
        "5:00AM",
        "6:00AM",
        "7:00AM",
        "8:00AM",
        "9:00AM",
        "10:00AM",
        "11:00AM",
        "12:00PM",
        "1:00PM",
        "2:00PM",
        "3:00PM",
        "4:00PM",
        "5:00PM",
        "6:00PM",
        "7:00PM",
        "8:00PM",
        "9:00PM",
        "10:00PM",
        "11:00PM",
      ];
      labels = timeLable.map(
        (item) =>
          `${moment(filters.fromDate)
            .tz("America/New_York")
            .format("LL")} ${item}`
      );
    } else if (filters.granularity?.value === "weekly") {
      let weeksData = [];
      if (chartType1.value !== "nothing") {
        weeksData = activityData
          ? activityData[chartType1.value].map(
              (item, index) =>
                `Week of ${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "weeks")
                  .format("MMM Do YY")}`
            )
          : [];
      } else if (chartType2.value !== "nothing") {
        weeksData = activityData
          ? activityData[chartType1.value].map(
              (item, index) =>
                `Week of ${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "weeks")
                  .format("MMM Do YY")}`
            )
          : [];
      }
      labels = weeksData;
    } else if (filters.granularity?.value === "monthly") {
      let monData = [];
      if (chartType1.value !== "nothing") {
        monData = activityData
          ? activityData[chartType1.value].map(
              (item, index) =>
                `${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "months")
                  .format("LL")}`
            )
          : [];
      } else if (chartType2.value !== "nothing") {
        monData = activityData
          ? activityData[chartType2.value].map(
              (item, index) =>
                `${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "months")
                  .format("LL")}`
            )
          : [];
      }
      labels = monData;
    }
    if (chartType1.value !== "nothing") {
      let obj = {
        label: chartType1.title,
        data: activityData
          ? activityData[chartType1.value]?.length === 1 &&
            filters.granularity?.value === "hourly"
            ? Array.from(Array(24).keys()).map(() => "0")
            : chartType1.value === "talkTime"
            ? activityData[chartType1.value].map((valueTalk) =>
                (Number(valueTalk || 0) / 60).toFixed(0)
              )
            : activityData[chartType1.value]
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(255, 99, 132, 0.5)",
        },
      };
      datasets.push(obj);
    }
    if (chartType2.value !== "nothing") {
      let obj = {
        label: chartType2.title,
        data: activityData
          ? activityData[chartType2.value]?.length === 1 &&
            filters.granularity?.value === "hourly"
            ? Array.from(Array(24).keys()).map(() => "0")
            : chartType2.value === "talkTime"
            ? activityData[chartType2.value].map((valueTalk) =>
                (Number(valueTalk || 0) / 60).toFixed(0)
              )
            : activityData[chartType2.value]
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(53, 162, 235, 0.5)",
        },
      };
      datasets.push(obj);
    }
    let obj = {
      labels: labels,
      datasets,
    };
    return obj;
  }, [
    filters.granularity,
    chartData?.data?.agentActivity,
    chartType1.value,
    chartType2.value,
  ]);

  const handleMakeChartCardData = useCallback(
    (type) => {
      let activityData = chartData?.data?.agentActivity;
      let datesBTW = enumerateDaysBetweenDates(
        filters.fromDate,
        filters.lastDate
      );
      let labels = [];
      let datasets = [];
      if (filters.granularity?.value === "daily" && datesBTW?.length) {
        labels = datesBTW.map((item) =>
          moment(item).tz("America/New_York").format("LL")
        );
      } else if (filters.granularity?.value === "hourly") {
        let timeLable = [
          "12:00AM",
          "1:00AM",
          "2:00AM",
          "3:00AM",
          "4:00AM",
          "5:00AM",
          "6:00AM",
          "7:00AM",
          "8:00AM",
          "9:00AM",
          "10:00AM",
          "11:00AM",
          "12:00PM",
          "1:00PM",
          "2:00PM",
          "3:00PM",
          "4:00PM",
          "5:00PM",
          "6:00PM",
          "7:00PM",
          "8:00PM",
          "9:00PM",
          "10:00PM",
          "11:00PM",
        ];
        labels = timeLable.map(
          (item) =>
            `${moment(filters.fromDate)
              .tz("America/New_York")
              .format("LL")} ${item}`
        );
      } else if (filters.granularity?.value === "weekly") {
        let weeksData = [];
        if (chartType1.value !== "nothing") {
          weeksData = activityData
            ? activityData[chartType1.value].map(
                (item, index) =>
                  `Week of ${moment(filters.fromDate)
                    .tz("America/New_York")
                    .add(index, "weeks")
                    .format("MMM Do YY")}`
              )
            : [];
        } else if (chartType2.value !== "nothing") {
          weeksData = activityData
            ? activityData[chartType1.value].map(
                (item, index) =>
                  `Week of ${moment(filters.fromDate)
                    .tz("America/New_York")
                    .add(index, "weeks")
                    .format("MMM Do YY")}`
              )
            : [];
        }
        labels = weeksData;
      } else if (filters.granularity?.value === "monthly") {
        let monData = [];
        if (chartType1.value !== "nothing") {
          monData = activityData
            ? activityData[chartType1.value].map(
                (item, index) =>
                  `${moment(filters.fromDate)
                    .tz("America/New_York")
                    .add(index, "months")
                    .format("LL")}`
              )
            : [];
        } else if (chartType2.value !== "nothing") {
          monData = activityData
            ? activityData[chartType2.value].map(
                (item, index) =>
                  `${moment(filters.fromDate)
                    .tz("America/New_York")
                    .add(index, "months")
                    .format("LL")}`
              )
            : [];
        }
        labels = monData;
      }
      let obj = {
        label: "",
        data: activityData
          ? activityData[type]?.length === 1 &&
            filters.granularity?.value === "hourly"
            ? Array.from(Array(24).keys()).map(() => "0")
            : type === "talkTime"
            ? activityData[type].map((valueTalk) =>
                (Number(valueTalk || 0) / 60).toFixed(0)
              )
            : activityData[type]
          : [],
        borderColor: "#BBC8D2",
        backgroundColor: "#FAFAFA",
        borderWidth: 2,
        fill: {
          target: "origin", // 3. Set the fill options
          above: "#FAFAFA",
        },
      };
      datasets.push(obj);
      let object = {
        labels: labels,
        datasets,
      };
      return object;
    },
    [filters.granularity, chartData?.data?.agentActivity]
  );
  const handleExport = () => {
    let usersData = activityStats.data?.agentRecords || [];
    let users = [["Name", "Calls", "Texts", "Notes", "Emails"]];
    usersData.forEach((item) => {
      let userArray = [
        item.name,
        item.calls,
        item.textMessages,
        item.notes,
        item.emails,
      ];
      users.push(userArray);
    });
    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(users);
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users");
    XLSX.writeFile(wb, `agent-activity-export-${Date()}.csv`);
  };
  const handleSetChartType1 = (data) => {
    if (data.value !== chartType1.value && data.value !== chartType2.value) {
      setChartType1(data);
      setChartType2({ title: "Nothing", value: "nothing" });
    }
  };
  const handleCalculatePreviousLabel = useCallback(() => {
    let days = getDaysDifference(filters);
    if (days === 0) {
      return `${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("MMM Do YY")} 12AM - ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("MMM Do YY")} 11PM`;
    } else if (days === 6) {
      return `Week of ${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")} - Week of ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")}`;
    } else {
      return `${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")} - ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")}`;
    }
  }, [previousPeriod, filters]);
  return (
    <Box className={classes.root}>
      <Box className={classes.agentActivitiesScreenTopArea}>
        <Typography
          className={classes.agentActivitiesReportHeading}
          variant="h4"
        >
          Agent Activity Report
        </Typography>
        <Box className={classes.agentActivitiesScreenTopFilter}>
          <Button
            onClick={handleExport}
            disableElevation
            className={classes.exportButton}
            variant="contained"
          >
            <DownloadRoundedIcon fontSize="small" />
          </Button>
          <Box sx={{ mr: "15px" }}>
            <UserSelectionsForStats
              defaultValue={"Everyone"}
              handleGetUserDetail={handleGetUserDetail}
              {...props}
            />
          </Box>
          <DatePickersForStats
            defaultValue="This Month"
            handleGetDates={handleGetDates}
            {...props}
          />
        </Box>
      </Box>
      <Card variant="outlined" className={classes.agentActivitiesChartGraph}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ mb: "15px" }}
          spacing={2}
        >
          <ButtonDropdown
            placeHolder="Select"
            options={menuOptions.filter(
              (item) =>
                item.value !== chartType2.value &&
                item.value !== chartType1.value
            )}
            value={chartType1}
            handleSelect={handleSelectChart1}
            startIcon={
              <SquareRoundedIcon
                fontSize="small"
                sx={{ color: "rgba(255, 99, 132, 0.5)" }}
              />
            }
          />
          <Typography>vs</Typography>
          <ButtonDropdown
            placeHolder="Select"
            options={menuOptions.filter(
              (item) =>
                item.value !== chartType2.value &&
                item.value !== chartType1.value
            )}
            value={chartType2}
            handleSelect={handleSelectChart2}
            startIcon={
              <SquareRoundedIcon
                fontSize="small"
                sx={{ color: "rgba(53, 162, 235, 0.5)" }}
              />
            }
          />
          <Divider orientation="vertical" flexItem />
          <ButtonDropdown
            placeHolder="Select"
            options={handleFilterGranularityOptions(granularityOptions)}
            value={filters?.granularity}
            handleSelect={handleSelectGranularity}
          />
          <Divider orientation="vertical" flexItem />
          <ListItem className={classes.listItemForSelection} disablePadding>
            <ListItemAvatar sx={{ minWidth: "40px", ml: "-13px" }}>
              <Checkbox
                checked={previousPeriod.isChecked}
                onChange={(e) =>
                  setPreviousPeriod({
                    ...previousPeriod,
                    isChecked: e.target.checked,
                  })
                }
              />
            </ListItemAvatar>
            <ListItemText
              primary="Compare to previous period:"
              secondary={handleCalculatePreviousLabel()}
            />
          </ListItem>
        </Stack>
        <Divider />
        {chartData.isLoading ? (
          <Box sx={{ height: "220px" }}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height="100%"
            />
          </Box>
        ) : (
          <Box>
            <AgentActivityChart data={handleMakeChartData()} {...props} />
            <Box className={classes.graphBottomDates}>
              <Typography>
                {moment(
                  previousPeriod.isChecked
                    ? previousPeriod.fromDate
                    : filters.fromDate
                )
                  .tz("America/New_York")
                  .format("ll")}
              </Typography>
              <Typography>
                {moment(
                  previousPeriod.isChecked
                    ? previousPeriod.lastDate
                    : filters.lastDate
                )
                  .tz("America/New_York")
                  .format("ll")}
              </Typography>
            </Box>
          </Box>
        )}
      </Card>
      {activityStats.isLoading ? (
        <ActivityStatsSkelton />
      ) : (
        <Box className={classes.agentActivitiesStatsArea}>
          <Card
            onClick={() =>
              handleSetChartType1({ title: "Calls", value: "calls" })
            }
            variant="outlined"
            className={classes.agentActivitiesStatsItem}
          >
            <Box
              sx={{
                paddingBottom:
                  activityStats?.data?.total?.calls === 0 ? "51.8px" : "",
              }}
              className={classes.agentActivitiesStatsItemTop}
            >
              <Box className={classes.agentActivitiesStatContent}>Calls</Box>
              <Box className={classes.agentActivitiesStatValue}>
                {activityStats?.data?.total?.calls || "0"}
              </Box>
            </Box>
            <Box sx={{ ml: "-1px", mr: "-2px" }}>
              <AgentActivityCardChart
                data={handleMakeChartCardData("calls")}
                {...props}
              />
            </Box>
          </Card>
          <Card
            key={"0002"}
            onClick={() =>
              handleSetChartType1({ title: "Emails", value: "emails" })
            }
            variant="outlined"
            className={classes.agentActivitiesStatsItem}
          >
            {" "}
            <Box
              sx={{
                paddingBottom:
                  chartData?.data?.total?.emails === 0 ? "51.8px" : "",
              }}
              className={classes.agentActivitiesStatsItemTop}
            >
              <Box className={classes.agentActivitiesStatContent}>Emails</Box>
              <Box className={classes.agentActivitiesStatValue}>
                {chartData?.data?.total?.emails || "0"}
              </Box>
            </Box>
            <Box sx={{ ml: "-1px", mr: "-2px" }}>
              <AgentActivityCardChart
                data={handleMakeChartCardData("emails")}
                {...props}
              />
            </Box>
          </Card>
          <Card
            key={"0003"}
            onClick={() =>
              handleSetChartType1({ title: "Texts", value: "textMessages" })
            }
            variant="outlined"
            className={classes.agentActivitiesStatsItem}
          >
            <Box
              sx={{
                paddingBottom:
                  activityStats?.data?.total?.textMessages === 0
                    ? "51.8px"
                    : "",
              }}
              className={classes.agentActivitiesStatsItemTop}
            >
              <Box className={classes.agentActivitiesStatContent}>Texts</Box>
              <Box className={classes.agentActivitiesStatValue}>
                {activityStats?.data?.total?.textMessages || "0"}
              </Box>
            </Box>
            <Box sx={{ ml: "-1px", mr: "-2px" }}>
              <AgentActivityCardChart
                data={handleMakeChartCardData("textMessages")}
                {...props}
              />
            </Box>
          </Card>
          <Card
            onClick={() =>
              handleSetChartType1({ title: "Notes", value: "notes" })
            }
            variant="outlined"
            className={classes.agentActivitiesStatsItem}
          >
            {" "}
            <Box
              sx={{
                paddingBottom:
                  activityStats?.data?.total?.notes === 0 ? "51.8px" : "",
              }}
              className={classes.agentActivitiesStatsItemTop}
            >
              <Box className={classes.agentActivitiesStatContent}>Notes</Box>
              <Box className={classes.agentActivitiesStatValue}>
                {activityStats?.data?.total?.notes || "0"}
              </Box>
            </Box>
            <Box sx={{ ml: "-1px", mr: "-2px" }}>
              <AgentActivityCardChart
                data={handleMakeChartCardData("notes")}
                {...props}
              />
            </Box>
          </Card>
          <Card
            onClick={() =>
              handleSetChartType1({ title: "Talk Time", value: "talkTime" })
            }
            variant="outlined"
            className={classes.agentActivitiesStatsItem}
          >
            {" "}
            <Box
              sx={{
                paddingBottom:
                  chartData?.data?.total?.talkTime === 0 ? "51.8px" : "",
              }}
              className={classes.agentActivitiesStatsItemTop}
            >
              <Box className={classes.agentActivitiesStatContent}>
                TALK TIME
              </Box>
              <Box className={classes.agentActivitiesStatValue}>
                {(Number(chartData?.data?.total?.talkTime || 0) / 60).toFixed(
                  0
                )}
              </Box>
            </Box>
            <Box sx={{ ml: "-1px", mr: "-2px" }}>
              <AgentActivityCardChart
                data={handleMakeChartCardData("talkTime")}
                {...props}
              />
            </Box>
          </Card>
        </Box>
      )}
      <br />
      <ActivityRecordsTable
        loading={activityStats.isLoading}
        data={activityStats?.data?.agentRecords || []}
        {...props}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "AgentActivitiesStyle" })(
  AgentActivities
);
