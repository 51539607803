import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  pendingPayments: {
    ACH: 0,
    Check: 0,
    Wire: 0,
    remainingProperties: [],
  },
  errMsg: null,
};

export const getPendingPayments = createAsyncThunk("finance/getPendingPayments", async (data, thunkAPI) => {
  try {
    const url = "/api/finance/pending-payments";
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      method: "get",
      url,
    };

    return new Promise((resolve, reject) => {
      axios(options)
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          return err;
        });
    });
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const pendingPayment = createSlice({
  name: "pendingPayments",
  initialState,
  extraReducers: {
    [getPendingPayments.pending]: (state) => {
      state.loading = true;
    },
    [getPendingPayments.fulfilled]: (state, action) => {
      state.loading = false;
      state.pendingPayments = action.payload.data;
    },
    [getPendingPayments.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default pendingPayment.reducer;
