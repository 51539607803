import { createSlice } from "@reduxjs/toolkit";
import { sendEmail } from "./sendEmail";
import { getEmails } from "./getEmails";
import { uploadAttachments } from "./uploadAttachments";
import { getEmailTemplate } from "./getTemplate";
import { template } from "lodash";
const initialState = {
  emailSend: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  emailsList: {
    success: false,
    data: [],
    count: 0,
    isLoading: false,
    errMsg: null,
  },
  openedEmail: null,
  upload: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  template: null,
};
const SendEmailAs = createSlice({
  name: "SendEmailAs",
  initialState,
  reducers: {
    handleOpenEmail: (state, action) => {
      state.openedEmail = action.payload;
    },
  },
  extraReducers: {
    [sendEmail.pending]: (state) => {
      state.emailSend.isLoading = true;
      state.emailSend.errMsg = null;
      state.emailSend.success = false;
    },
    [sendEmail.fulfilled]: (state, action) => {
      state.emailSend.success = true;
      state.emailSend.isLoading = false;
      state.emailSend.data = action.payload;
      let newList =
        state.emailsList.data && state.emailsList.data?.length
          ? [...state.emailsList.data]
          : [];
      newList.unshift(action.payload);
      state.emailsList.data = newList;
    },
    [sendEmail.rejected]: (state, action) => {
      state.emailSend.isLoading = false;
      state.emailSend.success = false;
      state.emailSend.errMsg = action.payload;
    },
    [getEmails.pending]: (state, action) => {
      state.emailsList.isLoading = true;
      state.emailsList.errMsg = null;
      state.emailsList.success = false;
      if (action.meta?.arg?.reset) {
        state.emailsList.data = [];
      }
    },
    [getEmails.fulfilled]: (state, action) => {
      state.emailsList.success = true;
      state.emailsList.isLoading = false;
      state.emailsList.count = action.payload.count;
      if (state.emailsList?.data && action.meta?.arg?.pageNumber > 1) {
        state.emailsList.data = [
          ...state.emailsList.data,
          ...action.payload.data,
        ];
      } else {
        state.emailsList.data = action.payload.data;
        state.openedEmail = action.payload?.data?.[0];
      }
    },
    [getEmails.rejected]: (state, action) => {
      state.emailsList.isLoading = false;
      state.emailsList.success = false;
      state.emailsList.errMsg = action.payload;
    },
    // update inventory
    [uploadAttachments.pending]: (state) => {
      state.upload.isLoading = true;
      state.upload.errMsg = null;
      state.upload.success = false;
    },
    [uploadAttachments.fulfilled]: (state, action) => {
      state.upload.success = true;
      state.upload.isLoading = false;
      state.upload.data = action.payload;
    },
    [uploadAttachments.rejected]: (state, action) => {
      state.upload.isLoading = false;
      state.upload.success = false;
      state.upload.errMsg = action.payload;
    },
    // get email template
    [getEmailTemplate.fulfilled]: (state, action) => {
      state.template = action.payload.template;
    },
  },
});

export const { handleOpenEmail } = SendEmailAs.actions;
export default SendEmailAs.reducer;
export * from "./sendEmail";
export * from "./getEmails";
export * from "./uploadAttachments";
export * from "./deleteAttachment";
export * from "./getTemplate";
