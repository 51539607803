import { withStyles } from "@mui/styles";
import React from "react";
import { TextField } from "@mui/material";
import { styleSheet } from "./style";

const Input = ({ classes, size, label, placeholder, value, ...other }) => {
  return (
    <div className={classes.root}>
      {label ? <label className={classes.labelRoot}> {label}</label> : null}
      <TextField
        classes={{ root: classes.inputRoot }}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        sx={{
          "& input ": {
            padding: size === "small" ? "11px 15px" : "15px",
          },
        }}
        {...other}
      />
    </div>
  );
};

export default withStyles(styleSheet, { name: "inputStyle" })(Input);
