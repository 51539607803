import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addLeadAppointmentAPI = createAsyncThunk("lead/addLeadAppointmentAPI", async (data, thunkAPI) => {
  const { schema, handleSuccess, handleError } = data;
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/leads/appointments`, schema, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Context-Type": "application/json",
      },
    });
    if (handleSuccess) {
      handleSuccess();
    }
    return resp?.data;
  } catch (error) {
    if (error.message) {
      if (handleError) {
        handleError(error.message.data.message);
      }
      return thunkAPI.rejectWithValue(error.message.data.message);
    } else {
      if (handleError) {
        handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
