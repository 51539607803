import { createSlice } from "@reduxjs/toolkit";
import { getAllCategories } from "./getAllCategories";
import { getCategoryTemplates } from "./getCategoryTemplate";
import { getTemplateDetail } from "./getTemplate";
import { submitTemplate } from "./submitTemplate";
const initialState = {
  categoriesList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  templates: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  templateDetail: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  templateSubmit: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
};
const MarketingCenterDigital = createSlice({
  name: "MarketingCenterDigital",
  initialState,
  // reducers: {
  //   updateCheckoutState: (state, action) => {},
  // },
  extraReducers: {
    [getAllCategories.pending]: (state) => {
      state.categoriesList.isLoading = true;
      state.categoriesList.errMsg = null;
      state.categoriesList.success = false;
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.categoriesList.success = true;
      state.categoriesList.isLoading = false;
      state.categoriesList.data = action.payload?.reverse() || [];
    },
    [getAllCategories.rejected]: (state, action) => {
      state.categoriesList.isLoading = false;
      state.categoriesList.success = false;
      state.categoriesList.errMsg = action.payload;
    },
    // get sub categories
    [getCategoryTemplates.pending]: (state) => {
      state.templates.isLoading = true;
      state.templates.errMsg = null;
      state.templates.success = false;
    },
    [getCategoryTemplates.fulfilled]: (state, action) => {
      state.templates.success = true;
      state.templates.isLoading = false;
      state.templates.data = action.payload;
    },
    [getCategoryTemplates.rejected]: (state, action) => {
      state.templates.isLoading = false;
      state.templates.success = false;
      state.templates.errMsg = action.payload;
    },
    // get template detail
    [getTemplateDetail.pending]: (state) => {
      state.templateDetail.isLoading = true;
      state.templateDetail.errMsg = null;
      state.templateDetail.success = false;
    },
    [getTemplateDetail.fulfilled]: (state, action) => {
      state.templateDetail.success = true;
      state.templateDetail.isLoading = false;
      state.templateDetail.data = action.payload;
    },
    [getTemplateDetail.rejected]: (state, action) => {
      state.templateDetail.isLoading = false;
      state.templateDetail.success = false;
      state.templateDetail.errMsg = action.payload;
    },
    // submit template
    [submitTemplate.pending]: (state) => {
      state.templateSubmit.isLoading = true;
      state.templateSubmit.errMsg = null;
      state.templateSubmit.success = false;
    },
    [submitTemplate.fulfilled]: (state, action) => {
      state.templateSubmit.success = true;
      state.templateSubmit.isLoading = false;
      state.templateSubmit.data = action.payload;
    },
    [submitTemplate.rejected]: (state, action) => {
      state.templateSubmit.isLoading = false;
      state.templateSubmit.success = false;
      state.templateSubmit.errMsg = action.payload;
    },
  },
});

export default MarketingCenterDigital.reducer;
export * from "./getAllCategories";
export * from "./getCategoryTemplate";
export * from "./getTemplate";
export * from "./submitTemplate";
