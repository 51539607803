import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Stack, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { createMakeover } from "../../../redux/listings/makeover";
import { useParams } from "react-router-dom";

function AddMakeOverProject(props) {
  let { open, setOpen, classes, listingDetail } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const [projectDetail, setProjectDetail] = useState({
    mortgage_balance: "",
    amount_requested: "",
    seller0_first_name: "",
    seller0_last_name: "",
    seller1_first_name: "",
    seller1_last_name: "",
  });
  const dispatch = useDispatch();
  const addMakeover = useSelector((state) => state.listings.makeover.addMakeover);
  useEffect(()=>{
    if(open){
      console.log(listingDetail?.agent?.market_id,"listingDetail?.agent?.market_id")
    }
  },[open])
  useEffect(() => {
    if (addMakeover.success && !addMakeover.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully created the makeover project",
        errorType: "success",
        isOpen: true,
      });
      setProjectDetail({
        mortgage_balance: "",
        amount_requested: "",
        seller0_first_name: "",
        seller0_last_name: "",
        seller1_first_name: "",
        seller1_last_name: "",
      });
      setOpen(false);
    } else if (!addMakeover.isLoading && addMakeover.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addMakeover.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addMakeover.isLoading]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!projectDetail.amount_requested) {
      isFormValid = false;
      errorMsg["amount_requested"] = "Please enter the requested amount";
      setErrorAlert({
        errorMsg: "Please enter the requested amount",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!projectDetail.mortgage_balance) {
      isFormValid = false;
      errorMsg["mortgage_balance"] = "Please enter the mortgage balance";
      setErrorAlert({
        errorMsg: "Please enter the mortgage balance",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!projectDetail.seller0_first_name) {
      isFormValid = false;
      errorMsg["seller0_first_name"] = "Please enter seller first name";
      setErrorAlert({
        errorMsg: "Please enter seller first name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!projectDetail.seller0_last_name) {
      isFormValid = false;
      errorMsg["seller0_last_name"] = "Please enter seller last name";
      setErrorAlert({
        errorMsg: "Please enter seller last name",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleCreateMakeover = () => {
    if (handleValidate()) {
      dispatch(
        createMakeover({
          ...projectDetail,
          amount_requested: Number(projectDetail.amount_requested),
          mortgage_balance: Number(projectDetail.mortgage_balance),
          market_id: listingDetail?.agent?.market_id || "1",
          listing_id:Number(id)
        })
      );
    }
  };

  const handleUpdateValue = (type, value) => {
    setProjectDetail({
      ...projectDetail,
      [type]: value,
    });
    setErrMsg({});
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogTitle DialogActions={<Box>Close</Box>} className={classes.CloseContractHeading} id="alert-dialog-titlesd">
          Create Makeover Project
        </DialogTitle>
        <DialogContent sx={{ width: "800px", mb: "15px" }}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={3}>
              <TextField
                value={projectDetail.amount_requested}
                onChange={(e) => handleUpdateValue("amount_requested", e.target.value)}
                fullWidth
                required
                variant="standard"
                helperText="Amount Requested"
                error={errMsg["amount_requested"]}
                type="number"
                id="amount_requested"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={projectDetail.mortgage_balance}
                onChange={(e) => handleUpdateValue("mortgage_balance", e.target.value)}
                fullWidth
                required
                variant="standard"
                helperText="Estimated Mortgage Balance"
                error={errMsg["mortgage_balance"]}
                type="number"
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <TextField
                value={projectDetail.seller0_first_name}
                onChange={(e) => handleUpdateValue("seller0_first_name", e.target.value)}
                fullWidth
                variant="standard"
                helperText="Seller First Name"
                name="seller0_first_name"
                error={errMsg["seller0_first_name"]}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={projectDetail.seller0_last_name}
                onChange={(e) => handleUpdateValue("seller0_last_name", e.target.value)}
                fullWidth
                variant="standard"
                helperText="Seller Last Name"
                error={errMsg["seller0_last_name"]}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={projectDetail.seller1_first_name}
                onChange={(e) => handleUpdateValue("seller1_first_name", e.target.value)}
                fullWidth
                variant="standard"
                helperText="Seller 2 First Name  (if applicable)"
                error={errMsg["seller1_first_name"]}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={projectDetail.seller1_last_name}
                onChange={(e) => handleUpdateValue("seller1_last_name", e.target.value)}
                fullWidth
                variant="standard"
                helperText="Seller 2 Last Name  (if applicable)"
                error={errMsg["seller1_last_name"]}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={projectDetail.description}
                onChange={(e) => handleUpdateValue("description", e.target.value)}
                fullWidth
                multiline
                minRows={3}
                placeholder="Description of work to be completed."
                error={errMsg["description"]}
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2} justifyContent={"flex-end"} sx={{ height: "100%" }} alignItems={"flex-end"}>
                <Button
                  onClick={() => setOpen(false)}
                  sx={{
                    color: "#000000",
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={addMakeover.isLoading}
                  className={classes.saveModalButton}
                  color="inherit"
                  onClick={() => handleCreateMakeover()}
                  autoFocus
                  loadingPosition="start"
                >
                  Create
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddMakeOverProjectStyle" })(AddMakeOverProject);
