import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const uploadAttachments = createAsyncThunk(
  "SendEmailAs/uploadAttachments",
  async (data, thunkAPI) => {
    let { handleSuccess, handleError, schema } = data;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/users/send-email-as/attachments`,
        schema,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (handleSuccess && resp.data) {
        handleSuccess(resp.data.attachments);
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
