export const styleSheet = () => ({
  root: {
    minHeight: "100vh",
    padding: "20px 0px",
    marginTop: "20px",
  },
  callScreenTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  callScreenTopFilter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  callReportHeading: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
  },
  callStatContent: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "uppercase",
  },
  callStatValue: {
    fontSize: "22px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    lineHeight: "30px",
  },
  exportButton: {
    border: "1px solid rgb(215, 222, 226) !important",
    borderRadius: "4px",
    background: "linear-gradient(rgb(255, 255, 255), rgb(232, 236, 240))",
    color: "rgb(109, 130, 145) !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize  !important",
    marginRight: "15px !important",
    padding: "6px 10px !important",
    minWidth: "40px !important",
  },
  graphBottomDates: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "12px !important",
      fontWeight: "500 !important",
      fontFamily: "AvenirNext  !important",
    },
  },
  agentActivitiesChartGraph: {
    background: "white",
    margin: "25px 0px",
    minHeight: "250px",
    padding: "15px",
  },
});
