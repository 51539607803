export const styleSheet = () => ({
  newSearchPropertyDetail: {
    marginTop: "30px",
    "& .MuiFormControlLabel-root":{
      marginLeft:"0px !important"
    }
  },
  propertySubType: {
    padding: "15px 7px",
  },
  propertySubTypeHead: {
    fontWeight: "600 !important",
    fontSize: "16px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontFamily: "AvenirNext !important",
    marginBottom:"5px !important"
  },
  newSearchMiddleArea:{
    padding:"15px "
  },
  resultActiveButton:{
    backgroundColor:'black !important',
    color:"white !important",
  },
  resultDefaultButton:{
    fontWeight: "500 !important",
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
  },
  searchInputLabel:{
    fontWeight: "400 !important",
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.87)  !important"
  },
  searchRightBlock:{
    padding:'15px 10px 20px 10px'
  },
  loadingArea: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  transactionsStatsArea:{
    marginTop:'0px'
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  listingAreaRightStatTop: {
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "center",
  },
  listingAreaRightStatBottom: {
    fontSize: "13px",
    fontWeight: "400",
  },
});
