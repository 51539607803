import React, { useState } from "react";
import { Container, Grid, Box, Card, CardHeader, Button,AvatarGroup,Avatar  } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { styleSheet } from "./style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddPhoneQueue from "../modals/addQueue";
import { getAllQueue } from "../../../redux/phoneConfiguration/queue";
import {getQueueDetail} from "../../../redux/phoneConfiguration/queue/getParticularQueue"
import UpdatePhoneQueue from "../modals/updatePhoneQueue"
import { getAllPhoneAudios } from "../../../redux/phoneConfiguration/audios";
import { getAllPhoneExtensions } from "../../../redux/phoneConfiguration/extensions";
import {CustomTooltip} from "../../../components/shared/tooltip"
import TableSkeleton from "../../../components/loader/tableLoader";  

const PhoneQueues = (props) => {
  let { classes, search } = props;
  const dispatch = useDispatch();
  const [openAddQueue, setOpenAddQueue] = useState(false);
  const [openUpdateQueue,setOpenUpdateQueue]=useState(false)
  const queueList = useSelector((item) => item.phoneConfiguration.PhoneQueues.queueList);
  const audioList = useSelector((item) => item.phoneConfiguration.PhoneAudios.audioList);
  const extensionsList = useSelector((item) => item.phoneConfiguration.PhoneExtensions.extensionsList);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const { isLoading } = queueList;
  useEffect(() => {
      if (queueList?.data?.length === 0 && !queueList.isLoading) {
      dispatch(getAllQueue({}));
      dispatch(getQueueDetail({}))
      }
      if (extensionsList?.data?.length === 0 && !extensionsList.isLoading) {
        dispatch(getAllPhoneExtensions({}));
      }
  }, []);

  useEffect(() => {
    if (!audioList.isLoading) {
      dispatch(getAllPhoneAudios({category:"voicemail greet"}));
    }
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Queue Name",
      renderCell: ({ row }) => {
        return <Box>{row?.name}</Box>;
      },
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "strategy",
      headerName: "Strategy",
      renderCell: ({ row }) => {
        return <Box>{row?.strategy}</Box>;
      },
      width: 220,
      editable: false,
      sortable: true,
    },

    {
      field: "call_time_limit",
      headerName: "Timeout",
      renderCell: ({ row }) => {
        return <Box>{row.call_time_limit}</Box>
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "voicemail_id",
      headerName: "Voicemail",
      renderCell: ({ row }) => {
        return <Box>{row.voicemail_id}</Box>
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "Members",
      headerName: "Members",
      renderCell: ({ row }) => {
        return row.twilio_queue_extensions?.length? <AvatarGroup max={4} sx={{"& .MuiAvatarGroup-avatar":{width:"25px",height:"25px",fontSize:"14px"}}} variant="rounded" >
        {
          row.twilio_queue_extensions.map((item,index)=>(
            <CustomTooltip title={agentRosterList?.list.find((ite) => ite.id === item?.twilio_call_extension?.user_id)?.full_name}>
            <Avatar sx={{width:"25px",height:"25px",fontSize:"14px",cursor:"pointer"}} variant="rounded" alt={agentRosterList?.list.find((ite) => ite.id === item?.twilio_call_extension?.user_id)?.full_name} src={agentRosterList?.list.find((ite) => ite.id === item?.twilio_call_extension?.user_id)?.profile_images?.profile_img} />
            </CustomTooltip>
          ))
        }
       </AvatarGroup>:""
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "Action",
      headerName: "Action",
      type:"actions",
      renderCell: ({ row }) => {
        return (
          <Button
            variant="contained"
            size="small"
            color="inherit"
            sx={{
              backgroundColor: "white",
              color: "#000000",
              fontWeight: "400",
            }}
            onClick={()=>setOpenUpdateQueue(row)}
          >
          Edit queue
          </Button>
        );
      },
      width: 250,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2?.name),
      editable: false,
      sortable: true,
    },
  ];
  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.pageRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card>
                <CardHeader
                  classes={{ title: classes.pageHeading }}
                  title={
                    <Box className={classes.pageHeading}>
                     Queues{" "}
                      <Button onClick={() => setOpenAddQueue(true)} classes={{ root: classes.addPhoneButton }}>
                      Add Queue
                      </Button>
                    </Box>
                  }
                />
                <Box className={classes.tableRoot}>
                  <DataGridPro
                    columns={columns}
                    rows={queueList.data ? queueList.data : []}
                    loading={isLoading}
                    rowHeight={56}
                    components={{
                      LoadingOverlay: TableSkeleton
                    }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <UpdatePhoneQueue  open={openUpdateQueue}   audioList={audioList.data}setOpen={setOpenUpdateQueue}/>
      <AddPhoneQueue open={openAddQueue}  audioList={audioList.data} setOpen={setOpenAddQueue} />
    </>
  );
};

export default withStyles(styleSheet, { name: "PhoneQueuesStyle" })(PhoneQueues);
