import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getProjectList, setProjectCurrentPageNo } from "../projects";

/**
 *
 * Project / Task Common Actions
 *
 *
 */
export const changeKanbanView = createAsyncThunk(
  "view/changeKanbanView",
  async (data, thunkAPI) => {
    localStorage.setItem("projectKanbanView", data);
    return data;
  }
);

// for resetting all lists
export const resetList = createAsyncThunk(
  "list/resetList",
  async (data, thunkAPI) => {
    return [];
  }
);

// for setting selected tab index
export const setSelectedTab = createAsyncThunk(
  "tabIndex/setSelectedTab",
  async (data, thunkAPI) => {
    return data;
  }
);

export const setSelectedOpenHouseTab = createAsyncThunk(
  "tabIndex/setSelectedOpenHouseTab",
  async (data, thunkAPI) => {
    return data;
  }
);

export const setSelectedDepartmentID = createAsyncThunk(
  "departmentID/setSelectedDepartmentID",
  async (data, thunkAPI) => {
    return data;
  }
);

// for setting up search text
export const setSearchTextField = createAsyncThunk(
  "searchText/setSearchTextField",
  async (data, thunkAPI) => {
    return data;
  }
);

// for saving project or task public notes
export const saveProjectTaskPublicComments = createAsyncThunk(
  "projectTaskPublicComments/saveProjectTaskPublicComments",
  async (data, thunkAPI) => {
    try {
      const { id, value, type, noteType } = data; // type is project or task
      let d = JSON.stringify({
        [noteType]: value,
      });

      let projectOrTask = type === "project" ? "projects" : "projects/tasks";

      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}api/${projectOrTask}/${id}`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: d,
      };

      const resp = await axios(config);
      // await thunkAPI.dispatch(setProjectCurrentPageNo(1));
      // setTimeout(async () => {
      //   await thunkAPI.dispatch(getProjectList());
      // }, 500);
      return data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for creating staff comment on Project/Task
export const saveProjectTaskMessages = createAsyncThunk(
  "projectTaskMessages/saveProjectTaskMessages",
  async (data, thunkAPI) => {
    try {
      const { id, value, type, userIds } = data; // type is project or task

      let d = {
        comment: value,
        userIds,
      };

      if (type === "project") {
        d.project_id = id;
      } else {
        d.task_id = id;
      }

      let projectOrTask = type === "project" ? "projects" : "projects/tasks";

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}api/${projectOrTask}/create-comment`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(d),
      };

      const resp = await axios(config);
      await thunkAPI.dispatch(setProjectCurrentPageNo(1));
      setTimeout(async () => {
        await thunkAPI.dispatch(getProjectList());
      }, 500);
      return { data: resp.data, type };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting all department list
export const getDepartmentList = createAsyncThunk(
  "department/getDepartmentList",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/projects/departments`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting all department list
export const getDepartmentProjectList = createAsyncThunk(
  "department/getDepartmentProjectList",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/projects/task-types/${data}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
