import React from "react";
import ConstructionIcon from '@mui/icons-material/Construction';
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
function ComingSoon({classes}) {
  return (
    <div className={classes.pageRoot}>
      <div>
        <center>
            <ConstructionIcon sx={{fontSize:"120px"}} fontSize='large' />
        </center>
        <h1 className={classes.mainHeading}>Coming Soon</h1>
        <p className={classes.mainContent}>This page is under Construction</p>
      </div>
    </div>
  );
}

export default withStyles(styleSheet, { name: "ComingSoonStyle" })(
  ComingSoon
);
