export const styleSheet = () => ({
  mTitle: {
    fontWeight: "500 !important",
    fontSize: "28px !important",
    fontFamily: "AvenirNext !important",
    padding:"0px 0px 10px 0px !important",
    marginTop:"-15px !important"
  },
  mBorder: {
    borderRadius: "4px",
    border:"1px solid #D5D9E2",
    padding:"10px",
    height:"57vh",
    overflow:"auto"

  },
  mCard: {
    backgroundColor: "#F1F1F1 !important",
    marginBottom: "10px !important",
  },
  mCardContent: {
    padding:"0px  !important"
  },
  mImgStyle: {
    padding: "0 12px 0 8px !important",
    width:"45px"
  },
  mIconStyle: {
    color: "#6F6F6F !important",
    marginLeft: "10px !important",
    cursor:"pointer"
  },
  mJustifyContent:{
    display:"flex !important",
    justifyContent:"flex-end !important",
  },
  mCreateButton:{
    color: "#fff !important",
    borderRadius:"4px !important",
    backgroundColor:"#000000 !important",
  },
  mCancel:{
    color: "#000000 !important",
    marginRight: "20px !important",
  },
})






// .printerpage .printer_title {
//   margin-top: 100px !important;
//   font-weight: 400;
//   font-size: 34px;
//   line-height: 123.5%;
//   letter-spacing: 0.25px;
//   color: rgba(0, 0, 0, 0.87);
//   margin-bottom: 30px;
// }
// .printerpage {
//   padding: 0 30px 30px 30px;
//   .print_btn {
//     display: flex;
//     justify-content: end;
//     max-width: 600px;
//     margin-top: 40px;

//     button {
//       text-transform: uppercase;
//       font-size: 15px;
//       border-radius: 4px;
//       &:hover {
//         box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
//           0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
//       }
//     }
//   }
// }
// .printerpage .printer_row {
//   border-radius: 4px;
//   box-shadow: 0px 0px 0px 1px #e0e0e0;
//   padding: 13px;
// }
// .printer_row li {
//   background: #f1f1f1;
//   box-shadow: 0px 0px 0px 1px #e0e0e0;
//   border-radius: 4px;
//   margin-bottom: 15px;
//   padding: 20px;
//   .print_text {
//     span {
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 150%;
//       letter-spacing: 0.15px;
//       color: rgba(0, 0, 0, 0.87);
//     }
//     p {
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 143%;
//       letter-spacing: 0.17px;
//       color: rgba(0, 0, 0, 0.6);
//     }
//   }

//   svg {
//     margin-right: 15px;
//   }
//   img {
//     margin-right: 15px;
//     width: 20px;
//     height: 20px;
//   }
// }

// .clear_btn path {
//   fill: rgba(0, 0, 0, 0.54);
// }
