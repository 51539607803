import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Box, MenuItem } from "@mui/material";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { addPhoneNumber } from "../../../redux/phoneConfiguration/phoneNumbers";
import NumberFormat from "react-number-format";

function AddPhoneNumber(props) {
  let { open, setOpen, classes, extensionsList, queueList } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [isFocused, setIsFocused] = useState(false);
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const [phoneDetail, setPhoneDetail] = useState({
    number: "",
    prefix_label: "",
    queue_id: "",
    extension_id: "",
    queue_name: "",
  });
  const addPhone = useSelector(
    (item) => item.phoneConfiguration.PhoneNumbers.addPhone
  );

  const handleUpdateDetail = (field, value, field1, value1) => {
    if (field1) {
      setPhoneDetail({ ...phoneDetail, [field]: value, [field1]: value1 });
    } else {
      setPhoneDetail({ ...phoneDetail, [field]: value });
    }
    setErrMsg({});
  };

  useEffect(() => {
    if (addPhone.success && !addPhone.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully add the phone number",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setPhoneDetail({
        number: "",
        prefix_label: "",
        queue_id: "",
        extension_id: "",
        queue_name: "",
      });
    } else if (!addPhone.isLoading && addPhone.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addPhone.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addPhone.success]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!phoneDetail.number) {
      isFormValid = false;
      errorMsg["number"] = "Please enter the number";
      setErrorAlert({
        errorMsg: "Please enter the number",
        errorType: "warning",
        isOpen: true,
      });
    } else if (phoneDetail.number && phoneDetail.number.indexOf("_") > -1) {
      isFormValid = false;
      errorMsg["number"] = "Please enter the valid number";
      setErrorAlert({
        errorMsg: "Please enter the valid number",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!phoneDetail.prefix_label) {
      errorMsg["prefix_label"] = "Please enter prefix label";
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter prefix label",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!phoneDetail.queue_id && !phoneDetail.extension_id) {
      isFormValid = false;
      errorMsg["queue_id"] = "Please select the queue";
      setErrorAlert({
        errorMsg: "Please select the queue",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!phoneDetail.extension_id && !phoneDetail.queue_id) {
      isFormValid = false;
      errorMsg["extension_id"] = "Please select the extension";
      setErrorAlert({
        errorMsg: "Please select the extension",
        errorType: "warning",
        isOpen: true,
      });
    }

    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSavePhone = () => {
    if (handleValidate()) {
      dispatch(addPhoneNumber(phoneDetail));
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          className={classes.AddPhoneHeading}
          sx={{ mt: "10px", mb: "15px" }}
          id="alert-dialog-title"
        >
          <Box> Add Phone Number</Box>
        </DialogTitle>
        <DialogContent sx={{ width: "600px", overflow: "visible" }}>
          <Grid
            container
            direction="row"
            spacing={6}
            sx={{ overflow: "visible" }}
          >
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <NumberFormat
                format={"###-###-####"}
                mask="_"
                className="phone-number-input-panel-field"
                value={phoneDetail.number}
                onChange={(e) => handleUpdateDetail("number", e.target.value)}
                displayType="input"
                onBlur={() => {
                  if (isFocused) {
                    setIsFocused(false);
                  }
                }}
                onFocus={() => {
                  if (!isFocused) {
                    setIsFocused(true);
                  }
                }}
                autoFocus={isFocused}
                customInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      fullWidth
                      classes={{ root: classes.detailInput }}
                      placeholder={"Enter  number"}
                      variant="standard"
                      size="small"
                      error={errMsg.number}
                      helperText={errMsg.number}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <TextField
                value={phoneDetail.prefix_label}
                onChange={(e) =>
                  handleUpdateDetail("prefix_label", e.target.value)
                }
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Prefix Label"}
                variant="standard"
                size="small"
                error={errMsg.prefix_label}
                helperText={errMsg.prefix_label}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Select Queue
              </InputLabel>
              <TextField
                value={phoneDetail.queue_id}
                onChange={(e) =>
                  handleUpdateDetail(
                    "queue_id",
                    e.target.value,
                    "extension_id",
                    ""
                  )
                }
                fullWidth
                classes={{ root: classes.detailInput }}
                variant="standard"
                size="small"
                select
                error={errMsg.queue_id}
                name="phone"
                autoComplete={false}
                id="user-phone"
                helperText={errMsg.queue_id}
              >
                {queueList?.length &&
                  queueList.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Select Extension
              </InputLabel>
              <TextField
                value={phoneDetail.extension_id}
                onChange={(e) =>
                  handleUpdateDetail(
                    "extension_id",
                    e.target.value,
                    "queue_id",
                    ""
                  )
                }
                fullWidth
                classes={{ root: classes.detailInput }}
                variant="standard"
                size="small"
                select
                error={errMsg.extension_id}
                name="phone"
                autoComplete={false}
                id="user-phone"
                helperText={errMsg.extension_id}
              >
                {extensionsList?.length &&
                  extensionsList.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: "30px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSavePhone()}
            classes={{ root: classes.AddPhoneButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addPhone.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddPhoneNumberStyle" })(
  AddPhoneNumber
);
