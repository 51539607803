import React from "react";
import { Box, Typography, MenuItem, FormControl, TextField, Grid, Card } from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
function ContractTYpe(props) {
  let { classes, updateContractDetail, contractDetail, errMsg } = props;
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  return (
    <Grid>
      <Grid item xs={9}>
        <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible" }}>
          <Box className={classes.FormContractTypeBox}>
            <Typography classes={{ root: classes.transactionFormCardHeading }}>Contract Type</Typography>
            <Box className={classes.FormContractTypeBoxRight}>
              <FormControl sx={{ position: "relative !important" }}>
                <ButtonGroups
                  id="category-button-menu"
                  bgColor="#0B0909"
                  bgColorHover="#0B0909"
                  placement={"bottom"}
                  color="white"
                  width="160px"
                  minWidth="160px"
                  fontSize="12.5px"
                  value={contractDetail.contract_type}
                  options={[
                    { title: "Buyer Contract", value: "buyer" },
                    { title: "Seller Contract", value: "seller" },
                    { title: "Tenant Contract", value: "tenant" },
                    { title: "Landlord Contract", value: "landlord" },
                    { title: "Referral Contract", value: "referral" },
                  ]}
                  onChangeMenu={(value) => updateContractDetail("contract_type", value)}
                  placeholder="Select Type"
                  {...props}
                />
                <Box sx={{ color: "red" }}>{errMsg?.contract_type}</Box>
              </FormControl>
              <FormControl
                className="selectA"
                style={{
                  minWidth: "256px",
                }}
              >
                <TextField
                  id="contract_type"
                  onChange={(e) => {
                    updateContractDetail(
                      "agent_id",
                      `${e.target.value}`,
                      "agent_name",
                      agentRosterList?.list.find((item) => item.id === e.target.value)?.full_name
                    );
                  }}
                  value={contractDetail.agent_id}
                  select
                  variant="standard"
                  label="   Select Agent"
                  required
                  size="small"
                >
                  <MenuItem disabled value={""}>
                    Start Typing
                  </MenuItem>
                  {agentRosterList?.list &&
                    agentRosterList?.list?.filter((it) => it.is_agent).length > 0 &&
                    agentRosterList?.list
                      ?.filter((it) => it.is_agent).sort((a, b) => a.last_name.localeCompare(b.last_name))
                      ?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.first_name} {item.last_name}
                        </MenuItem>
                      ))}
                </TextField>
              </FormControl>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "ContractTYpeStyle" })(ContractTYpe);
