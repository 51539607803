export const styleSheet = () => ({
  pageRoot: {
    padding: "30px !important",
    backgroundColor: "#f5f5f5 !important",
    minHeight: "100vh !important",
  },
  trainingIssueButton: {
    background: "#FFFFFF !important",
    color: "#000000 !important",
    borderColor: "#B8B8B8 !important",
    borderRadius: "30px !important",
    "&:hover": { borderColor: "#000000 !important" },
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    whiteSpace: "nowrap !important",
    marginTop: "3px  !important",
    marginBottom: "3px  !important",
    display: "flex !important",
    alignItems: "centner !important",
    paddingTop: "4.5px !important",
    paddingBottom: "3px !important",
    borderWidth: "1.5px !important",
  },
  RecordConversationButton: {
    borderRadius: "30px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    whiteSpace: "nowrap !important",
    marginTop: "3px  !important",
    marginBottom: "3px  !important",
    backgroundColor: "white  !important",
    display: "flex !important",
    alignItems: "centner !important",
    paddingTop: "4.5px !important",
    paddingBottom: "3px !important",
    borderWidth: "1.5px !important",
  },
  isaWaitButton: {
    background: "#0B0909 !important",
    boxShadow: "none !important",
    "&:hover": { bgcolor: "#0B0909", boxShadow: "none !important" },
    borderRadius: "30px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    whiteSpace: "nowrap !important",
    marginTop: "3px  !important",
    marginBottom: "3px  !important",
    display: "flex !important",
    alignItems: "centner !important",
    paddingTop: "4.5px !important",
    paddingBottom: "3px !important",
    borderWidth: "1.5px !important",
  },
  activitiesCard: {
    padding: "15px",
    marginBottom: "8px",
    marginTop: "5px",
  },
  activitiesCardItem: {
    position: "relative",
    textAlign: "center !important",
    height: "8vh !important",
    borderRadius: "10px !important",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "10px !important",
    },
    "& .hoveredBlock": {
      display: "none",
      position: "absolute",
      height: "8vh !important",
      padding: "6px",
    },
    "&:hover": {
      "& .hoveredBlock": {
        display: "block",
        width: "100%",
        borderRadius: "10px !important",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
      },
    },
  },
  hoveredBlockTopContent: {
    fontSize: "10px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    color: "white  !important",
    textAlign: "left  !important",
  },
  activitiesPriceItem: {
    fontSize: "13px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    color: "white  !important",
    paddingLeft: "6px",
    paddingBottom: "1px",
  },
  activitiesStateItem: {
    fontSize: "12px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    color: "white  !important",
  },
  hoveredBlockState: {
    position: "absolute",
    bottom: "0px",
    left: "3px",
    right: "3px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  activitiesCardTitle: {
    fontSize: "18px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    display: "flex  !important",
    justifyContent: "center  !important",
    alignItems: "center  !important",
  },
  activitiesViewButton: {
    borderRadius: "20px  !important",
    border: "1px solid rgba(33, 33, 33, 0.4)  !important",
    color: "#0B0909 !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext-400  !important",
    display: "flex  !important",
    justifyContent: "center  !important",
    alignItems: "center  !important",
  },
  activitiesEditButton: {
    borderRadius: "20px  !important",
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext-400  !important",
    display: "flex  !important",
    justifyContent: "center  !important",
    alignItems: "center  !important",
  },
  dropzoneAreaInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18.8px",
    color: "black",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "11.5px",
    left: "0px",
    background: "rgba(255,255,255,.6)",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  recentActivitiesArea: {},
  recentActivitiesHeading: {
    color: "#93A5B2   !important",
    fontFamily: "AvenirNext   !important",
    fontSize: "11px   !important",
    fontWeight: "600 !important",
    marginBottom: "6px !important",
    "& span": {
      cursor: "pointer !important",
      color: "#4eaddd   !important",
      fontSize: "11px   !important",
      fontWeight: "500 !important",
    },
  },
  recentActivitiesContent: {
    color: "#4eaddd   !important",
    fontFamily: "AvenirNext   !important",
    fontSize: "14px   !important",
    fontWeight: "500 !important",
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  recentActivitiesAreaItem: {
    cursor: "pointer  !important",
    alignItems: "center !important",
    height: "24px",
  },
  recentActivitiesNotFound: {
    color: "#9c9c9c   !important",
    fontFamily: "AvenirNext-400   !important",
    fontSize: "14px   !important",
  },
});
