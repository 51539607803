import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const mergeEmailTemplateAgentRecruitment = createAsyncThunk(
  "login/mergeEmailTemplateAgentRecruitment",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/templates/merge`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
