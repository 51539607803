import React, { useRef, useEffect } from "react";
import {  Box, Typography } from "@mui/material";
import Avatar from "../../../components/uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import LeadsInboxSkelton from "./components/leadsInboxSkelton";
import moment from "moment";

function LeadsChatHistoryArea(props) {
  let messagesEnd = useRef(null);
  let { classes, sendMessage } = props;
  const chatList = useSelector((state) => state.leads.inbox.chatList);
  const adminAuth = useSelector((state) => state.adminAuth);

  useEffect(() => {
    if (chatList?.data?.textMessages?.rows) {
      messagesEnd.current.scrollIntoView();
    }
  }, [chatList?.data?.textMessages?.rows]);
   
  return (
    <Box className={classes.chatBodyContent}>
      {chatList.isLoading ? (
        <LeadsInboxSkelton count={15} />
      ) : (
        chatList?.data?.textMessages?.rows?.map((data) => (
          <Box className={classes.chatWrapper}>
            {data.isIncoming ? (
              <Box className={classes.recieverData}>
                <Box className={classes.recieverList}>
                  <Typography sx={{mr:"10px"}} className={classes.dateWrap}>
                    {" "}
                    {moment(moment(new Date(data?.created).toString())).from(moment(new Date()))}
                  </Typography>
                  <Box className={classes.iconAvatar}>
                    <Typography>{data.message}</Typography>
                    <Avatar sx={{fontSize:"16px"}} src={data?.profile_img}>
                      {`${data.firstName?.slice(0, 1)}${data.lastName?.slice(0, 1)}`}
                    </Avatar>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className={classes.senderData}>
                <Box className={classes.senderList}>
                  <Box className={classes.iconAvatar}>
                    <Avatar sx={{fontSize:"16px"}} src={adminAuth?.adminDetail?.profile_img}>
                      {adminAuth?.adminDetail?.first_name
                        ? `${adminAuth?.adminDetail?.first_name.slice(0, 1)}${adminAuth?.adminDetail?.last_name.slice(0, 1)}`
                        : "NA"}
                    </Avatar>
                    <Typography>{data.message}</Typography>
                  </Box>
                  <Typography sx={{ml:"10px"}} className={classes.dateWrap}>{moment(moment(new Date(data?.sent).toString())).from(moment(new Date()))}</Typography>
                </Box>
              </Box>
            )}
          </Box>
        ))
      )}
      <div ref={messagesEnd} />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "LeadsChatHistoryAreaStyle" })(LeadsChatHistoryArea);
