import React, { useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import {
  DateFiltersOptions,
  IncludeWithEmptyFiltersOptions,
  IncludeWithoutEmptyFiltersOptions,
  PhoneFiltersOptions,
  PriceFiltersOptions,
  TextFiltersOptions,
} from "./LeadsCustomFilters";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box } from "@mui/system";
import FilterListIcon from "@mui/icons-material/FilterList";
// Name Cell Component
export const SidebarAllFilter = ({ filters, columns, apiRef, apiCallApplyFilter }) => {
  const [columnsObj, setColumnsObj] = useState(
    columns.reduce((p, n) => {
      p[n.field] = n;
      return p;
    }, {})
  );

  const applyFilter = (item, index) => {
    let newItems = [...filters];
    newItems[index] = item;
    apiRef.current.upsertFilterItems(newItems);
    apiCallApplyFilter(newItems);
  };

  const clearFilter = (index) => {
    let newItems = [...filters];
    newItems.splice(index, 1);

    apiRef.current.upsertFilterItems(newItems);
    apiCallApplyFilter(newItems);
  };
  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white", minHeight: 300, padding: "9px" }}>
      <Grid container direction={"row"} justifyContent={"flex-start"} sx={{ marginBottom: "10px" }}>
        <Typography>Filters </Typography>
        <FilterAltIcon sx={{ fontSize: "25px", opacity: "0.7" }} />
      </Grid>
      {/* <FormControl sx={{ width: "100%", marginBottom: "20px" }} size="small">
        <InputLabel id="demo-select-small">Filter</InputLabel>
        <Select labelId="add-filter-small" id="add-filter-select-small" value={""} label="Filter" onChange={(e) => {}}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl> */}

      {filters.map((f, i) => {
        let Icon = columnsObj[f.columnField].props.icon;
        return (
          <Accordion disableGutters={true} defaultExpanded={i === 0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="all-filter-content"
              id="all-filter-content"
              sx={{
                "&.MuiAccordionSummary-root": {
                  minHeight: "15px",
                },
                "&.MuiAccordionSummary-content": {
                  margin: "0px",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Icon sx={{ marginRight: "5px" }} />
                  <Typography variant="subtitle1" fontSize={14}>
                    {columnsObj[f.columnField].headerName}
                  </Typography>
                </Box>
                <IconButton
                  aria-label="delete filter"
                  sx={{ color: "black" }}
                  component="span"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    clearFilter(i);
                    // addNewFilter({ columnField: field });
                  }}
                >
                  <ClearIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {columnsObj[f.columnField].type === "text" ? (
                <TextFiltersOptions applyFilter={(e) => applyFilter(e, i)} vals={f} />
              ) : columnsObj[f.columnField].type === "phone" ? (
                <PhoneFiltersOptions applyFilter={(e) => applyFilter(e, i)} vals={f} />
              ) : columnsObj[f.columnField].type === "include/exclude/empty" ? (
                <IncludeWithEmptyFiltersOptions applyFilter={(e) => applyFilter(e, i)} vals={f} data={columnsObj[f.columnField].props.data} />
              ) : columnsObj[f.columnField].type === "include/exclude" ? (
                <IncludeWithoutEmptyFiltersOptions applyFilter={(e) => applyFilter(e, i)} vals={f} data={columnsObj[f.columnField].props.data} />
              ) : columnsObj[f.columnField].type === "date" ? (
                <DateFiltersOptions applyFilter={(e) => applyFilter(e, i)} vals={f} />
              ) : (
                <PriceFiltersOptions applyFilter={(e) => applyFilter(e, i)} vals={f} />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
      {!filters ||
        (filters.length === 0 && (
          <Box display="flex" height={"200px"} alignItems="center" justifyContent="center">
            <Box textAlign={"center"}>
              <FilterListIcon sx={{ fontSize: "50px", opacity: "0.7" }} />
              <Typography sx={{ opacity: "0.7" }}> No Filters added yet!</Typography>
            </Box>
          </Box>
        ))}
    </Paper>
  );
};
