import React from "react";
import { Card, CardHeader, Box, Grid, TableContainer, Table, TableRow, TableCell, Skeleton } from "@mui/material";
function IssueDetailSkelton() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12} md={7}>
          <TableContainer>
            <Table sx={{ minWidth: 350 }}  size="small" aria-label="simple table">
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell colSpan={2} sx={{ width: "260px" }}>
                  <Card sx={{ mb: "15px", boxShadow: "none" }}>
                    <CardHeader
                      avatar={<Skeleton animation="wave" variant="circular" sx={{ height: 40, width: 40 }} />}
                      title={<Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />}
                      subheader={<Skeleton animation="wave" height={15} width="50%" />}
                    />
                  </Card>
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell sx={{ width: "120px" }}>
                  <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell sx={{ width: "120px" }}>
                  <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell sx={{ width: "120px" }}>
                  <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell sx={{ width: "120px" }}>
                  <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell sx={{ width: "120px" }}>
                  <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell sx={{ width: "120px" }}>
                  <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell colSpan={2} sx={{ width: "250px" }}>
                  <Skeleton animation="wave" height={50} width="250px" sx={{ padding: "0px" }} />
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell colSpan={2} sx={{ width: "250px" }}>
                  <Skeleton animation="wave" height={50} width="250px" sx={{ padding: "0px" }} />
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell colSpan={2} sx={{ width: "250px" }}>
                  <Skeleton animation="wave" height={50} width="250px" sx={{ padding: "0px" }} />
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "100px" }}>
                  <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell colSpan={2} sx={{ width: "250px" }}>
                  <Skeleton animation="wave" height={50} width="250px" sx={{ padding: "0px" }} />
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={12} md={5}>
          <Skeleton animation="wave" height={600} width="100%" sx={{ padding: "0px", mt: "-120px", mb: "-120px" }} />
          <Skeleton animation="wave" height={80} width="100%" sx={{ padding: "0px" }} />
        </Grid>
      </Grid>
    </Box>
  );
}
export default IssueDetailSkelton;
