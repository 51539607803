import React from "react";
import { Box, Card, Skeleton, Grid, CardHeader } from "@mui/material";
function RVCPSkelton() {
  return (
    <Box>
      <Grid container direction={"row"} spacing={2} sx={{ mt: "0px" }}>
        <Grid sm={3} item>
          <Card>
            <CardHeader
              title={<Skeleton variant="rounded" animation="wave" width="70%" height="20px" sx={{ mb: "10px" }} />}
              subheader={<Skeleton variant="rounded" animation="wave" width="40%" height="15px" />}
              sx={{ padding: "10px" }}
            />
          </Card>
        </Grid>
        <Grid sm={3} item>
          <Card>
            <CardHeader
              title={<Skeleton variant="rounded" animation="wave" width="70%" height="20px" sx={{ mb: "10px" }} />}
              subheader={<Skeleton variant="rounded" animation="wave" width="40%" height="15px" />}
              sx={{ padding: "10px" }}
            />
          </Card>
        </Grid>
        <Grid sm={3} item>
          <Card>
            <CardHeader
              title={<Skeleton variant="rounded" animation="wave" width="70%" height="20px" sx={{ mb: "6px" }} />}
              subheader={<Skeleton variant="rounded" animation="wave" width="40%" height="15px" />}
              sx={{ padding: "10px" }}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid sx={{ mt: "20px", mb: "30px" }} container spacing={2}>
        <Grid item sm={6}>
          <Skeleton variant="rounded" animation="wave" width="40%" height="25px" sx={{ mb: "6px" }} />
          <Card variant="outlined" sx={{ padding: "10px", mt: "15px" }}>
            <Skeleton variant="rounded" animation="wave" width="100%" height="260px" />
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Skeleton variant="rounded" animation="wave" width="40%" height="25px" sx={{ mb: "10px" }} />
          <Card variant="outlined" sx={{ padding: "10px", mt: "15px" }}>
            <Skeleton variant="rounded" animation="wave" width="100%" height="260px" />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
export default RVCPSkelton;
