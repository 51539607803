export const styleSheet = (theme) => ({
  leaderBoardLeftTop: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  leaderBoardLeftTopHeading: {
    fontSize: "27px !important",
    fontFamily: "AvenirNext-400  !important",
  },
  leaderBoardDaysLeft: {
    fontSize: "12px !important",
  },
  leaderBoardYears: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  leaderBoardLeftCard: {
    marginBottom: "16px",
    backgroundColor: "white",
    padding: "15px",
    marginTop: "10px",
    borderRadius: "15px  !important",
  },
  leaderBoardStatsCard: {
    display: "inline !important",
    background: "#F9F9F9 !important",
    width: "30px !important",
    fontWeight: "500 !important",
    color: "gba(0, 0, 0, 0.87) !important",
    padding: "0px 8px !important",
    fontSize: "15px !important",
  },
  leaderBoardStatsHeading: {
    position: "absolute !important",
    left: "25px",
    top: "4px",
  },
  leaderBoardPointsKeyHeading: {
    position: "absolute !important",
    left: "10px",
    top: "-12px",
  },
  pointsTopRightShields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& img": {
      height: "37px",
      width: "37px",
    },
  },
  pointsTopRightShieldValue: {
    position: "absolute",
    left: "50%",
    top: "42%",
    transform: "translateY(-50%) translateX(-50%)",
    color: "white",
    fontSize: "16px  !important",
  },
  leaderBoardListItem: {
    background: "#F2F2F2 !important",
    borderRadius: "13px !important",
    padding: "9px 15px 8px 15px !important",
    marginBottom: "12px !important",
    marginRight: "8px !important",
  },
  leaderBoardList: {
    height: "650px",
    overflow: "auto",
    "&::-webkit-scrollbar-thumb": {
      background: "#5F5F5F",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "8px",
    },
  },
  userInfoCard: {
    background: "#E7E7E7 !important",
    borderRadius: "10px !important",
  },
  userInfoCardHeading: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  userInfoCardDes: {
    fontSize: "10px !important",
    fontWeight: "400 !important",
  },
  userInfoCardDate: {
    fontSize: "10px !important",
    fontWeight: "400 !important",
    marginTop: "25px !important",
    marginRight: "6px !important",
  },
  currentStatCard: {
    borderRadius: "10px !important",
    background: "#F4F4F4  !important",
    marginTop: "15px !important",
  },
  currentStatCardHeading: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  currentStatCardHeader: {
    background: "#E7E7E7 !important",
    borderRadius: "10px 10px 0px 0px  !important",
    padding: "12px 16px !important",
  },
  currentStatCardContent: {
    background: "#F4F4F4  !important",
    minHeight: "40px !important",
  },
  pointsTopRightCard: {
    padding: "15px !important",
    width: "136px !important",
    height: "136px !important",
    background: "#E8E8E8 !important",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25) !important",
    borderRadius: "6px !important",
    fontWeight: "500  !important",
    fontSize: "25px  !important",
  },
  leaderBoardCurrentRankText: {
    padding: "15px !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    paddingTop: "0px  !important",
    whiteSpace:"nowrap !important",
    paddingBottom: "6px  !important",
  },
  leaderBoardCurrentRank: {},
  pointsTopRightCardHeading: {
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    textAlign: "center !important",
    marginBottom: "5px !important",
  },
  pointsTopRightCardLink: {
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    textAlign: "center !important",
    textDecoration: "underline !important",
    cursor: "pointer !important",
    textTransform: "uppercase !important",
    color: "#000000 !important",
    marginTop: "10px !important",
  },
  leaderBoardRightShields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& img": {
      height: "65px",
      width: "65px",
    },
  },
  leaderBoardRightShieldValue: {
    position: "absolute",
    left: "50%",
    top: "42%",
    transform: "translateY(-50%) translateX(-50%)",
    color: "white",
    fontSize: "22px  !important",
  },
  pointsTopLeftCard1: {
    padding: "15px !important",
    width: "172px !important",
    height: "77px !important",
    background: "white !important",
    boxShadow: "none !important",
    borderRadius: "7px !important",
    fontWeight: "500  !important",
    fontSize: "25px  !important",
  },
  currentStatCardContentArea: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "flex-start !important",
    "@media screen and (max-width: 1300px)": {
      flexWrap: "wrap",
    },
    gap: "1rem !important",
  },
  "@media screen and (min-width: 1310px)": {
     leaderBoardStatsRightArea:{
      maxWidth: "405px !important" 
    }
  },
  viewHistoryPoints: {
    fontSize: "10px  !important",
    display: "flex !important",
    justifyContent: "flex-start!important",
    alignItems: "center !important",
    gap: "5px  !important",
    textTransform: "uppercase !important",
    marginTop: "15px !important",
    cursor: "pointer !important",
  },
  previousRankingCard: {
    borderRadius: "10px !important",
    background: "#F4F4F4  !important",
    marginTop: "15px !important",
  },
  previousIconViewButton: {
    color: "#03A9F4  !important",
    fontSize: "11px !important",
  },
  previousIconViewArea: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "flex-start !important",
    marginTop: "10px !important",
  },
  keyPointsHeadings: {
    background: "#F2F2F2  !important",
    borderRadius: "10px !important",
  },
  keyPointsItem: {
    borderRadius: "10px !important",
    marginBottom: "12px  !important",
    overflow: "hidden !important",
    "& .MuiAccordionSummary-root": {
      minHeight: "40px !important",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "10px 0px !important",
    },
    "& .MuiAccordionSummary-content": {
      margin: "10px 0px !important",
    },
  },
  keyPointsHeadingsText: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    gap: "10px",
  },
  keyPointsItemText: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  keyPointsItemTitleMain: {
    whiteSpace: "nowrap  !important",
    textOverflow: "ellipsis  !important",
    overflow: "hidden  !important",
  },
});
