import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Autocomplete, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import { makeStyles } from "@mui/styles";
import { addRecruitAgent } from "../../../redux/agents/addRecruitAgent";
import { successAddAgentRecruit } from "../../../redux/agents/getAgentList";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "AvenirNext !important",
    fontWeight: "600",
  },
  createButton: {
    width: "120px",
    backgroundColor: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600",
    color: "#fff!important",
  },
  closeButton: {
    borderColor: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600",
    color: "#0B0909!important",
  },
}));

function AddRecruit(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { open, setOpen } = props;

  const [responseError, setResponseError] = React.useState({
    isError: false,
    msg: "",
  });
  const [formData, setFormData] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});

  const { addRecruit, markets } = useSelector((state) => state.agentList);

  const handleAddRecruit = () => {
    let errs = {};
    if (!formData.MemberFirstName || formData.MemberFirstName === "") {
      errs.MemberFirstName = true;
    }
    if (!formData.MemberLastName || formData.MemberLastName === "") {
      errs.MemberLastName = true;
    }
    if (!formData.Market || formData.Market === "") {
      errs.Market = true;
    }
    if (!formData.source || formData.source === "") {
      errs.source = true;
    }

    if (Object.keys(errs).length > 0) {
      setFormErrors(errs);
    } else {
      dispatch(addRecruitAgent(formData));
    }
  };

  const handleClose = () => {
    dispatch(successAddAgentRecruit());
    setFormData({});
    setFormErrors({});
    setResponseError({
      isError: false,
      msg: "",
    });
    setOpen(false);
  };

  React.useEffect(() => {
    if (addRecruit.success === false) {
      setResponseError({
        isError: true,
        msg: addRecruit.errMsg,
      });
      dispatch(successAddAgentRecruit());
    } else if (addRecruit.success === true) {
      handleClose();
    }
  }, [addRecruit]);

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          Create Recruit
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={4} alignItems="center">
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="First Name"
                variant="standard"
                value={formData.MemberFirstName}
                error={formErrors.MemberFirstName}
                helperText="*required"
                onChange={(e) => {
                  setFormData((prevForm) => ({ ...prevForm, MemberFirstName: e.target.value }));
                  setFormErrors((err) => ({ ...err, MemberFirstName: false }));
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Last Name"
                variant="standard"
                value={formData.MemberLastName}
                error={formErrors.MemberLastName}
                helperText="*required"
                onChange={(e) => {
                  setFormData((prevForm) => ({ ...prevForm, MemberLastName: e.target.value }));
                  setFormErrors((err) => ({ ...err, MemberLastName: false }));
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Email Address"
                variant="standard"
                value={formData.email}
                error={formErrors.email}
                onChange={(e) => {
                  setFormData((prevForm) => ({ ...prevForm, email: e.target.value }));
                  setFormErrors((err) => ({ ...err, email: false }));
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="standard"
                value={formData.phone}
                sx={{ marginBottom: "35px" }}
                onChange={(e) => {
                  setFormData((prevForm) => ({ ...prevForm, phone: e.target.value }));
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                label="License Number"
                variant="standard"
                value={formData.MemberStateLicense}
                sx={{ marginBottom: "35px" }}
                onChange={(e) => {
                  setFormData((prevForm) => ({ ...prevForm, MemberStateLicense: e.target.value }));
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                label="Select Market"
                variant="standard"
                value={formData.Market}
                options={markets ? markets.map((m) => m?.name) : []}
                renderInput={(params) => (
                  <TextField {...params} error={formErrors.Market} helperText="*required" variant="standard" placeholder="Select Market" />
                )}
                onChange={(e, val) => {
                  setFormData((prevForm) => ({ ...prevForm, Market: val }));
                  setFormErrors((err) => ({ ...err, Market: false }));
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                label="Select Source"
                variant="standard"
                value={formData.source}
                options={["Indeed", "Wizehire", "Referral", "Other"]}
                renderInput={(params) => (
                  <TextField {...params} error={formErrors.source} helperText="*required" variant="standard" placeholder="Select Source" />
                )}
                onChange={(e, val) => {
                  setFormData((prevForm) => ({ ...prevForm, source: val }));
                  setFormErrors((err) => ({ ...err, source: false }));
                }}
              />
            </Grid>
          </Grid>

          {responseError.isError && (
            <Alert sx={{ marginTop: "30px" }} severity="error">
              {responseError.msg}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button onClick={handleClose} className={classes.closeButton} variant="outlined">
            Cancel
          </Button>
          <LoadingButton onClick={() => handleAddRecruit()} className={classes.createButton} loading={addRecruit.loading} loadingPosition="start">
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNoteModalStyle" })(AddRecruit);
