import React, { useState } from "react";
import { Grid, Box, Typography, InputLabel, MenuItem, FormControl, Select, Card, TextField } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";

const ClientInfo = (props) => {
  let { classes, updateContractDetail, contractDetail, errMsg } = props;
  const handleReturnField = (index, field) => {
    if (contractDetail.contract_type === "buyer" || contractDetail.contract_type === "tenant") {
      return `buyer${index}_${field}`;
    } else {
      return `seller${index}_${field}`;
    }
  };
  return (
    <Card variant="outlined" className={classes.transactionFormCard}>
      <Typography classes={{ root: classes.transactionFormCardHeading }}>Client Information</Typography>
      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={2}>
          <TextField
            disabled={!contractDetail.contract_type}
            value={contractDetail[handleReturnField(0, "type")] || ""}
            onChange={(e) => updateContractDetail(handleReturnField(0, "type"), e.target.value)}
            fullWidth
            select
            variant="standard"
            helperText="Type"
          >
            <MenuItem value="entity">Entity</MenuItem>
            <MenuItem value="person">Person</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={10}>
          {(contractDetail[handleReturnField(0, "type")] === "person" || !contractDetail[handleReturnField(0, "type")]) && (
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(0, "first_name")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "first_name"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client First Name"
                  name="firstName"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={contractDetail[handleReturnField(0, "middle_initial")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "middle_initial"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Middle Initial"
                  name="MiddleName"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(0, "last_name")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "last_name"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client Last Name"
                  name="LastName"
                />
              </Grid>
            </Grid>
          )}
          {contractDetail[handleReturnField(0, "type")] === "entity" && (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  value={contractDetail[handleReturnField(0, "entity_name")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "entity_name"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client Entity Name"
                />
              </Grid>
            </Grid>
          )}
          {(contractDetail[handleReturnField(0, "type")] === "person" || !contractDetail[handleReturnField(0, "type")]) && (
            <Grid container spacing={3} sx={{ mt: "10px" }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <TextField
                  value={contractDetail[handleReturnField(0, "client_phone")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "client_phone"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client Phone Number"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(0, "client_email")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "client_email"), e.target.value)}
                  fullWidth
                  type="email"
                  name="email"
                  variant="standard"
                  helperText="Client Email Address"
                />
              </Grid>
            </Grid>
          )}
          {contractDetail[handleReturnField(0, "type")] === "entity" && (
            <Grid container spacing={3} sx={{ mt: "10px" }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <TextField
                  value={contractDetail[handleReturnField(0, "entity_phone")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "entity_phone"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client Phone Number"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(0, "entity_email")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(0, "entity_email"), e.target.value)}
                  fullWidth
                  type="email"
                  name="email"
                  variant="standard"
                  helperText="Client Email Address"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={2}>
          <TextField
            disabled={!contractDetail.contract_type}
            value={contractDetail[handleReturnField(1, "type")] || ""}
            onChange={(e) => updateContractDetail(handleReturnField(1, "type"), e.target.value)}
            fullWidth
            select
            variant="standard"
            helperText="Type"
          >
            <MenuItem value="entity">Entity</MenuItem>
            <MenuItem value="person">Person</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={3}>
            {(contractDetail[handleReturnField(1, "type")] === "person" || !contractDetail[handleReturnField(1, "type")]) && (
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(1, "first_name")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "first_name"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client First Name"
                />
              </Grid>
            )}
            {(contractDetail[handleReturnField(1, "type")] === "person" || !contractDetail[handleReturnField(1, "type")]) && (
              <Grid item xs={2}>
                <TextField
                  value={contractDetail[handleReturnField(1, "middle_initial")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "middle_initial"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Middle Initial"
                />
              </Grid>
            )}
            {(contractDetail[handleReturnField(1, "type")] === "person" || !contractDetail[handleReturnField(1, "type")]) && (
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(1, "last_name")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "last_name"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client Last Name"
                />
              </Grid>
            )}
            {contractDetail[handleReturnField(1, "type")] === "entity" && (
              <Grid item xs={6}>
                <TextField
                  value={contractDetail[handleReturnField(1, "entity_name")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "entity_name"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client Entity Name"
                />
              </Grid>
            )}
          </Grid>
          {(contractDetail[handleReturnField(1, "type")] === "person" || !contractDetail[handleReturnField(1, "type")]) && (
            <Grid container spacing={3} sx={{ mt: "10px" }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <TextField
                  value={contractDetail[handleReturnField(1, "client_phone")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "client_phone"), e.target.value)}
                  fullWidth
                  variant="standard"
                  name="phone"
                  helperText="Client Phone Number"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(1, "client_email")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "client_email"), e.target.value)}
                  fullWidth
                  variant="standard"
                  type="email"
                  name="email"
                  helperText="Client Email Address"
                />
              </Grid>
            </Grid>
          )}
          {contractDetail[handleReturnField(1, "type")] === "entity" && (
            <Grid container spacing={3} sx={{ mt: "10px" }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <TextField
                  value={contractDetail[handleReturnField(1, "entity_phone")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "entity_phone"), e.target.value)}
                  fullWidth
                  variant="standard"
                  helperText="Client Phone Number"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={contractDetail[handleReturnField(1, "entity_email")] || ""}
                  onChange={(e) => updateContractDetail(handleReturnField(1, "entity_email"), e.target.value)}
                  fullWidth
                  type="email"
                  name="email"
                  variant="standard"
                  helperText="Client Email Address"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
export default withStyles(styleSheet, { name: "ClientInfoStyle" })(ClientInfo);
