import React from "react";
import { Container, Skeleton, Grid } from "@mui/material";
function LoadingSkelton(props) {
  return (
    <Container sx={{ minHeight: "100vh" }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height="60px"
        width="100%"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ mt: "10px" }}
            height="35px"
            width="100%"
          />{" "}
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ mt: "10px" }}
            height="500px"
            width="100%"
          />{" "}
        </Grid>
        <Grid item xs={12} md={4}>
          {Array.from(Array(8).keys()).map((_, index) => (
            <Skeleton
              key={index}
              animation="wave"
              variant="rectangular"
              sx={{ mt: index === 0 ? "60px" : "5px" }}
              height="35px"
              width="100%"
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          {" "}
          {Array.from(Array(15).keys()).map((item, index) => (
            <Skeleton
              key={index}
              animation="wave"
              variant="rectangular"
              sx={{ mt: "5px" }}
              height="30px"
              width="100%"
            />
          ))}
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ mt: "30px" }}
            height="40px"
            width="60%"
          />{" "}
          <Grid container spacing={3} sx={{ mt: "30px" }}>
            {Array.from(Array(15).keys()).map((_, index) => (
              <Grid key={index} item xs={6} md={6}>
                <Skeleton
                  key={index}
                  animation="wave"
                  variant="rectangular"
                  sx={{ mt: "0px" }}
                  height="35px"
                  width="100%"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
export default LoadingSkelton;
