import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  Snackbar,
  Typography,
} from "@mui/material";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTextTemplatesAgentsRecruitment } from "../../../../redux/agents/agentRecruitment/getTextTemplatesAgentRecruitment";
import { mergeTextTemplateAgentRecruitment } from "../../../../redux/agents/agentRecruitment/mergeTextTemplateAgentRecruitment";

export const GetTextTemplatesModal = ({
  closeModal,
  state,
  selectedTemplate,
  id,
}) => {
  const {
    loading: actionLoading,
    success,
    errMsg,
  } = useSelector((item) => item.agentProfile.agentProfile.modalActions);

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success, errMsg]);

  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" } }}
      dialogHead={<DialogHead />}
      hideSaveButton
      dialogBody={
        <DialogBody
          allAgent={[]}
          notificationManager={[]}
          getAllListing={[]}
          closeModal={closeModal}
          selectedTemplate={selectedTemplate}
        />
      }
      state={state}
      closeModal={closeModal}
      maxWidth="md"
      loading={actionLoading}
      fullWidth={true}
    />
  );
};

// Components
const DialogHead = () => (
  <DialogTitle>
    <Typography component="h5" variant="h5">
      Text Templates
    </Typography>
  </DialogTitle>
);

const DialogBody = ({ closeModal, selectedTemplate }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [template, setTemplate] = useState("");
  const [templates, setTemplates] = useState([]);
  const [routeId, setRouteId] = useState(null);
  const [open, setOpen] = useState({
    state: false,
    type: "",
    desc: "",
  });

  const templateList = useSelector((item) => item.textTemplates);

  const setListData = async () => {
    dispatch(getTextTemplatesAgentsRecruitment({}));
    setTemplates(templateList?.textTemplates || []);
  };

  useEffect(() => {
    setListData();
    setRouteId(Number(params?.id));
  }, []);

  useEffect(() => {
    if (templateList) {
      setTemplates(templateList?.textTemplates || []);
    }
  }, [templateList]);

  const fillTemp = async (payload) => {
    const resp = await dispatch(mergeTextTemplateAgentRecruitment(payload));
    if (resp?.payload) {
      selectedTemplate(resp.payload);
      closeModal();
    }
  };

  const selectTemplate = (param) => () => {
    const payload = {
      templateId: param?.id,
      personId: routeId,
    };
    fillTemp(payload);
  };

  useEffect(() => {
    if (templateList?.mergeText?.errMsg) {
      setOpen({
        state: true,
        type: "error",
        desc: templateList?.mergeText?.errMsg,
      });
    }
  }, [templateList]);

  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open?.state}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={open?.type}
          sx={{ width: "100%" }}
        >
          {open?.desc}
        </Alert>
      </Snackbar>

      <Input
        id="textTemplate"
        placeholder="Search Text Templates"
        value={template}
        onChange={(e) => setTemplate(e.target.value)}
        required
      />

      {templateList?.loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <br />
          <br />
          <CircularProgress />
        </Box>
      ) : (
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            border: "1px solid #e0e0e0",
            marginTop: "20px",
            borderRadius: "3px",
            padding: 0,
          }}
        >
          {templates && templates.length ? (
            templates.map((temp, index) => (
              <>
                <ListItemButton
                  sx={{
                    "&:hover": {
                      "& .templatePlainText": { display: "none" },
                      "& .templateBody": { display: "block" },
                    },
                  }}
                  key={index}
                  onClick={selectTemplate(temp)}
                >
                  <Box sx={{ display: "block" }}>
                    <Typography
                      component="h6"
                      variant="h6"
                      sx={{ fontSize: "16px" }}
                    >
                      {temp?.name}
                    </Typography>
                    <Typography
                      className={"templatePlainText"}
                      component="p"
                      variant="subtitle1"
                    >
                      {temp.message.length > 80
                        ? `${temp.message.substring(0, 120)}...`
                        : temp.message}
                    </Typography>
                    <Typography
                      sx={{ display: "none" }}
                      className={"templateBody"}
                    >
                      <pre
                        style={{
                          whiteSpace: "initial",
                          fontFamily: "AvenirNext !important",
                          color: "rgba(0, 0, 0, 0.6) !important",
                        }}
                      >
                        {temp.message}
                      </pre>
                    </Typography>
                  </Box>
                </ListItemButton>
                {templates.length === index + 1 ? null : <Divider />}
              </>
            ))
          ) : (
            <Typography>No Templates found</Typography>
          )}
        </List>
      )}
    </DialogContent>
  );
};
