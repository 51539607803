export const styleSheet = () => ({
  pageRoot: {
    marginTop: "15px !important",
    minHeight: "100vh",
    "& .rbc-event, .rbc-day-slot .rbc-background-event": {
      color: "black",
      border: "1px solid lightgray",
      background: "white",
      width: "100%",
      textAlign: "left",
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& .rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event": {
      color: "white !important",
      background:"black !important"
    },
    "&  .rbc-toolbar-label": {
      textAlign: "left !important",
    },
    "& .rbc-toolbar button": {
      cursor: "pointer",
      "&:first-child": {
        marginRight: "15px  !important",
        "&:after": {
          content: "< !important",
        },
      },
    },
  },
  topAreaEvents: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topAreaEventsHeading: {
    fontWeight: "600  !important",
    fontSize: "16px !important",
    fontFamily: "AvenirNext  !important",
  },
  listItemTextHeading: {
    fontSize: "14px !important",
  },
  listItemTextContent: {
    fontSize: "12.5px !important",
  },
  barColor: {
    color: "grey !important",
  },
  descriptionMultiLines: {
    lineHeight: "21px !important'",
    maxHeight: "48px !important'",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    display: "-webkit-box !important",
    WebkitLineClamp: "2 !important",
    WebkitBoxOrient: "vertical !important",
    whiteSpace: "pre-line  !important",
  },
});
