import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import CircularProgress from "@mui/material/CircularProgress";
import { AvatarGroup, Button, Chip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import DataGridComponent from '../../DataGridComponent';
import AvatarComponent from '../../AvatarComponent';
import { getProjectList } from "../../../../redux/projects/projects";
import { calculateDueIn, capitalize, statusColor } from '../../helpers';
import ProjectDetails from './AgentProjectDetails';

var columnWidth = 0; // Agent Column Resizing based on agent name

const useStyles = makeStyles((theme) => ({
  gridBody: {
    position: 'absolute!important',
    top: '140px',
    left: '0px', 
    padding: '40px', 
    width: '100%' 
  },
  detailCardSection: {
    justifyContent: "center!important",
    display: "flex!important",
    width: "100%!important",
    marginBottom: "40px!important",
  },
  detailCard: {
    display: "flex!important",
    justifyContent: "center!important",
    alignItems: "center!important",
    height: "100px!important",
    width: "220px!important",
  },
  title: {
    fontSize: "30px!important",
    fontWeight: "400 !important",
  },
  desc: {
    fontSize: "14px!important",
    fontWeight: "400 !important",
    color: 'rgba(0, 0, 0, 0.6)!important',
    lineHeight: '143%!important'
  },
  avatar: {
    background: '#BDBDBD!important',
    color: '#fff!important',
    marginRight: '10px!important'
  },
  openButton: {
    width: "100%!important",
    border: "1px solid #0B0909!important",
    borderColor: "#0B0909!important",
    color: '#0B0909!important',
    '&:hover': {
      borderColor: "#0B0909!important",
    }
  }
}));

const xgriColumns = [
  {
    field: "project",
    headerName: "Project",
    sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    renderCell: (params) => {
      return (
        <div>
          <span style={{ fontWeight: '700', display: 'block', fontSize: '14px', lineHeight: '20.02px', letterSpacing: '0.5px' }}>{params.value.name}</span>
          <span style={{ fontWeight: '400', display: 'block', fontSize: '14px', lineHeight: '20.02px' }}>{params.value.address}</span>
        </div>
      );
    },
    width: 280,
    sortable: true,
  },
  {
    field: "agent",
    headerName: "Agent",
    sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    renderCell: (params) => {
      return (
        <>
          <AvatarComponent 
            sx={{ marginRight: '10px', borderRadius: '4px' }} 
            src={params.value.image} 
            variant="square" 
            nameInitial={params.value.name}
          />
          {params.value.name}
        </>
      );
    },
    width: columnWidth*20+150,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      return (
        <Chip
          sx={{
            "& .MuiChip-label ": {
              color: "#fff",
              fontSize: "15px",
              width: '180px',
              textAlign: 'center'
            },
            background: `${statusColor(params.value ? capitalize(params.value) : "")}`,
          }}
          label={params.value ? capitalize(params.value) : ""}
        />
      );
    },
    width: 150,
    sortable: true,
  },
  {
    field: "progress",
    headerName: "Completion Progress",
    sortComparator: (v1, v2) => v1.percentage.localeCompare(v2.percentage),
    renderCell: (params) => {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress sx={{ color: '#000' }} variant="determinate" value={params.value.percentage} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(parseInt(params.value.percentage))}%`}
            </Typography>
          </Box>
        </Box>
      );
    },
    width: 180,
    sortable: true,
  },
  {
    field: "dueIn",
    headerName: "Due In",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    width: 130,
    sortable: true,
  },
  {
    field: "teamMembers",
    headerName: "Team Members",
    sortable: false,
    renderCell: (params) => {
      return (
        <AvatarGroup 
          sx={{
            '& .MuiAvatarGroup-root': {
              margin: '0px !important',
            },
            '& .MuiAvatarGroup-avatar': {
              margin: '0px !important',
              marginLeft: '-8px !important'
            },
            '& .MuiAvatar-circular': {
              margin: '-3px -12px!important',
            },
          }} 
          max={3}>
          {params.value.map(image => (
            <AvatarComponent src={image.src} title={image.title} 
          />
          ))}
        </AvatarGroup>
      );
    },
    width: 150,
  },
];

export default function AgentProjects() {
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  let { projectID } = useParams();
    
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([...xgriColumns]);
  const [openProjectDetails, setOpenProjectDetails] = React.useState(false);
  const [project, setProject] = React.useState(null); // selected project to see details
  const [dataGridRowHeight, setDataGridRowHeight] = React.useState(55);
  const [pageNo, setPageNo] = React.useState(0);

  const loggedInUser = useSelector((state) => state.adminAuth.adminDetail);
  const projects = useSelector((state) => state.project.projects);
  const totalProjects = useSelector((state) => state.project.totalProjects);
  const projectsLoading = useSelector((state) => state.project.projectsLoading);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
   const handleOpenProject = (id) => {
    setProject(id);
    setOpenProjectDetails(true);
  };
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */

  React.useEffect(() => {
    if (projectID) {
      handleOpenProject(projectID);
    }
  }, [projectID]);
  React.useEffect(() => {
    const p = [];
    let percentageArray = ['20', '90', '45', '50'];
    projects?.map((d, i) => {
      let dueIn = calculateDueIn(d.due_date);
      let teamMembers = [];

      d.project_tasks.map((task) => {
        if (task.team_member) {
          teamMembers.push({
            src: task.team_member?.profile_images?.profile_img_thumbnail || null,
            title: task.team_member.last_name
          });
        }
      });

      // saving agent value length to calculate agent column width
      columnWidth = d?.agent?.last_name?.length > columnWidth? d?.agent?.last_name?.length: columnWidth;
      let project = {
        id: d.id,
        status: d.status,
        project: {
          name: d?.project_name,
          address: d?.title,
        }, 
        agent: {
          image: d?.agent?.profile_images?.profile_img_thumbnail, 
          name: d?.agent?.last_name
        },
        progress: { // TODO: from api
          percentage: percentageArray[Math.floor(Math.random()*percentageArray.length)], 
          text: '6 of 8 Tasks Complete'
        },
        dueIn, 
        teamMembers
      }

      p.push(project);
    });
    setRows(p);

    // Adding action colum to grid only when projects are available in the state
    if (columns.length === 6 & projects.length > 0) {
      let cols = [...columns];

      // dynamic agent column width
      cols.map(col => {
        if (col.field === 'agent') {
          col.width = columnWidth*12+20;
        }

        return col;
      });

      cols.push(
        {
          field: 'id',
          headerName: 'Action',
          sortable: false,
          renderCell: (params) => {
            return (
              <Button 
                className={classes.openButton} onClick={() => handleOpenProject(params.value)}
              >Open</Button>
            );
          },
          width: 150,
        }
      );
      setColumns(cols);
    }
  }, [projects]);

  React.useEffect(() => {
    setPageNo(1);
    dispatch(getProjectList({ pageNo: 1, agent_id: loggedInUser.id }));
  }, []);
  /**
   * End Region: useEffect Hooks
   */
  return (
    <div className={classes.gridBody}>
      { project && (
        <ProjectDetails projectID={project} open={openProjectDetails} setOpen={setOpenProjectDetails} />
      )}
      <Box sx={{ height: 500, width: '100%' }}>
        <DataGridComponent
          rows={rows}
          rowHeight={dataGridRowHeight}
          columns={columns}
          loading={projectsLoading}
          rowCount={totalProjects}
          hideFooter
          onRowsScrollEnd={() => { 
            if (rows.length <= totalProjects) {
              let updatedPageNo = pageNo + 1;
              setPageNo(updatedPageNo);
              dispatch(getProjectList({ pageNo: updatedPageNo, agent_id: loggedInUser.id }));
            }
          }}
          onCellClick={(row) => handleOpenProject(row.id)}
        />
        </Box>
    </div>
  )
}
