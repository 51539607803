export const styleSheet = () => ({
    pageRoot: {
      marginTop: "60px",
      padding: "20px",
    },
    InventoryHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    InventoryAddButton: {
      background: "#0B0909 !important",
      color: "white",
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
      borderRadius: "9px  !important",
    },
    tableImageArea: {
      width: "40px",
      display: "flex",
      justifyContent: "center",
      padding: "10px",
    },
    addItemModalHeading: {
      fontSize: "22px !important",
      fontWeight: "400 !important",
      fontFamily: "AvenirNext  !important",
      textTransform: "capitalize !important",
    },
    AddInventoryButton: {
      backgroundColor: "#0B0909 !important",
      color: "white !important",
      fontWeight: "400 !important",
      marginRight: "10px !important",
      paddingLeft: "30px !important",
      paddingRight: "30px !important",
    },
    addInventoryLabel: {
      fontWeight: "500 !important",
      color: "rgba(0, 0, 0, 0.87) !important",
      fontSize: "14px !important",
      marginBottom:'10px !important'
    },
    InventoryIconBox:{
      padding:"10px",
      paddingLeft:'0px',
      backgroundColor:'white'
    },
    InventoryIconBoxImageSelected:{
      position:'absolute',
      top:'0px',
      right:'-10px'
    },
    loadingArea:{
      height:"50vh",
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    },
    tableRoot: {
      height: "calc(100vh - 120px)",
      width: "100%",
      backgroundColor: "white",
    },
    InventoryIconBoxImage:{
      width:'50px',
      height:'60px',
      position:'relative',
      display:"flex",justifyContent:'center',alignItems:'center',
      "&:hover":{
      backgroundColor:"lightgrey",
      borderRadius:'6px',
      },
      "& img":{
        width:'32px',
        cursor:'pointer'
      }
    },
    TopTransactionsBarArea: {
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      padding:"5px 20px",
      height:'50px'
    },
    TopTransactionsBarAreaLeft:{
      display:'flex',
      justifyContent:'flex-start',
      alignItems:'center',
    },
    "@media (max-width: 1600px)": {
      TransactionsTabsUI: {
        maxWidth:'500px'
      }
    },
    "@media (max-width: 1286px)": {
      TransactionsTabsUI: {
        maxWidth:'260px'
      }
    },
  
    TransactionsTabsUI: {
  
      "& .Mui-selected": {
        color: "#0B0909 !important",
      },
      "& .MuiTab-root":{
        fontSize:'13px !important',
        minHeight:'40px !important',
      },
      "& .MuiTabs-root":{
        minHeight:'40px  !important'
      },
      "& .MuiTabs-scroller":{
        height:'36px  !important'
      }
    },
    addTransactionButton: {
      flexShrink: "0 !important",
      background: "#0B0909 !important",
      fontWeight:'400  !important',
      fontSize:'12.5px !important',
      color: "white",
      height:'34px !important',
      marginRight:'15px !important',
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
      borderRadius: "9px  !important",
    },
    listTitleInventory:{
      fontWeight:'600  !important',
      fontSize:'12px !important',
      fontFamily: "AvenirNext  !important",
    },
    listDesInventory:{
      fontWeight:'400  !important',
      fontSize:'12px !important',
      fontFamily: "AvenirNext  !important",
    }
  });
  