import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAgentTrainingProgress = createAsyncThunk("AgentTrainingProgress/getAgentTrainingProgress", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/users/progress-records/${data.id}?pageNumber=${data.pageNumber || 1}&pageSize=${data.pageSize || 4}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { ...resp.data?.data, pageNumber: data.pageNumber };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
const initialState = {
  success: false,
  data: undefined,
  isLoading: false,
  errMsg: null,
};
const AgentTrainingProgress = createSlice({
  name: "AgentTrainingProgress",
  initialState,
  extraReducers: {
    [getAgentTrainingProgress.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getAgentTrainingProgress.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      if (action.payload?.pageNumber > 1) {
        state.data = { ...state.data, viewedEvents: state.data.viewedEvents.concat(action.payload.viewedEvents) };
      } else {
        state.data = action.payload;
      }
    },
    [getAgentTrainingProgress.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
  },
});
export default AgentTrainingProgress.reducer;
