import React, { useState, useEffect } from "react";

export const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const toggle = () => setPlaying(!playing);

  const stop = () => audio.pause();

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  const updateCurrentTime = (time) => {
    audio.currentTime = time;
    setCurrentTime(time);
  };
  const handleTimeUpdate = () => {
    setCurrentTime(audio.currentTime);
  };
  const handleLoadedMetadata = () => {
    setDuration(audio.duration);
  };

  useEffect(() => {
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle, stop, currentTime, duration, updateCurrentTime];
};
