import { createSlice } from "@reduxjs/toolkit";
import { getListDetail } from "./getListDetail";
import { GetPropertyFmlsSearch } from "./getNewSearch";
import {getSearchSuggestionData} from "./getSearchSuggestionData"

const initialState = {
  listDetail: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  newSearchDetail: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  suggestions: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
};
const FMLSListings = createSlice({
  name: "FMLSListings",
  initialState,
  extraReducers: {
    [getListDetail.pending]: (state, action) => {
      state.listDetail.isLoading = true;
      state.listDetail.errMsg = null;
    },
    [getListDetail.fulfilled]: (state, action) => {
      state.listDetail.success = true;
      state.listDetail.isLoading = false;
      state.listDetail.data = action.payload;
    },
    [getListDetail.rejected]: (state, action) => {
      state.listDetail.isLoading = false;
      state.listDetail.success = false;
      state.listDetail.errMsg = action.payload;
    },

    [GetPropertyFmlsSearch.pending]: (state, action) => {
      state.newSearchDetail.isLoading = true;
      state.newSearchDetail.errMsg = null;
    },
    [GetPropertyFmlsSearch.fulfilled]: (state, action) => {
      state.newSearchDetail.success = true;
      state.newSearchDetail.isLoading = false;
      state.newSearchDetail.data = action.payload;
    },
    [GetPropertyFmlsSearch.rejected]: (state, action) => {
      state.newSearchDetail.isLoading = false;
      state.newSearchDetail.success = false;
      state.newSearchDetail.errMsg = action.payload;
    },

    [getSearchSuggestionData.pending]: (state, action) => {
      state.suggestions.isLoading = true;
      state.suggestions.errMsg = null;
    },
    [getSearchSuggestionData.fulfilled]: (state, action) => {
      state.suggestions.success = true;
      state.suggestions.isLoading = false;
      state.suggestions.data = action.payload;
    },
    [getSearchSuggestionData.rejected]: (state, action) => {
      state.suggestions.isLoading = false;
      state.suggestions.success = false;
      state.suggestions.errMsg = action.payload;
    },
  },
});
export default FMLSListings.reducer;
export * from "./getListDetail";
export * from "./getNewSearch";
export * from "./getSearchSuggestionData"
