import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Tabs,
  Tab,
  Card,
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  List,
  ListItemText,
} from "@mui/material";
import { TabContext } from "@mui/lab";
import Dropzone from "react-dropzone";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import { Link, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Container } from "@mui/system";
import { Route, Routes, useParams } from "react-router-dom";
import Documents from "./documents";
import TransactionsDetailOverview from "./details";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import TransactionDetailMakeOver from "./makeOver";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import ListingHeaderCard from "./cards/ListingHeaderCard";
import CloseContract from "../modals/closeContract";
import HouseIcon from "@mui/icons-material/House";
import TerminateContract from "../modals/terminateContract";
import DeleteLIsting from "../modals/deleteListing";
import AddTask from "../modals/addTask";
import { useDispatch, useSelector } from "react-redux";
import { getParticularListing } from "../../../redux/listings/listingDetail/getParticularListing";
import { getCommissionPlans } from "../../../redux/finance/commissionPlans";
import { getTransactionTasks } from "../../../redux/transactions/getTasks";
import UploadPdfSplit from "../modals/splitpdfDocument";
import ResponseAlert from "../../../components/responseAlert";
import { CustomTooltip } from "../../../components/shared/tooltip";
import { postData } from "../../../utils/postData";
import DeleteIcon from "@mui/icons-material/Delete";
import ListingDetailSkelton from "./skelton";
import AddMakeOverProject from "../modals/addMakeOverProject";
import TransactionsDetailData from "./data";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function ListingDetail(props) {
  let { classes } = props;
  const [closeContract, setCloseContract] = React.useState(false);
  const [terminateContract, setTerminateContract] = React.useState(false);
  const [addTask, setAddTask] = React.useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [file, setFile] = React.useState("");
  const [openSplitPdf, setOpenSplitPdf] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  let { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const uploadBUttonRef = useRef();
  const dragRef = useRef();
  const ListingData = useSelector(
    (state) => state.listings.listingDetail.ListingData
  );
  const projectDetail = useSelector((state) => state.listings.makeover.detail);
  useEffect(() => {
    if (!ListingData.isLoading) {
      dispatch(getParticularListing({ id }));
    }
    dispatch(getCommissionPlans());
    dispatch(getTransactionTasks({ id }));
  }, []);

  useEffect(() => {
    if (ListingData?.data?.id) {
      let obj = {
        source: "Test",
        type: "Viewed Property",
        property: {
          street: ListingData?.data?.street,
          city: ListingData?.data?.city,
          state: ListingData?.data?.state,
          mlsNumber: ListingData?.data.mls_id || ListingData?.data.fmls_number,
          price: ListingData?.data?.price,
          bedrooms: ListingData?.data?.bedrooms,
          bathrooms: ListingData?.data?.baths,
          area: ListingData?.data?.sq_feet,
        },
      };
      handleCallViewEvent(obj);
    }
  }, [ListingData?.data]);

  const handleCallViewEvent = async (obj) => {
    await postData(`${process.env.REACT_APP_BASE_URL}api/users/events`, obj);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileChange = (Files) => {
    if (Files[0]?.type?.split("/")[1] === "pdf") {
      setFile(Files[0]);
      setOpenSplitPdf(true);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleValidate = async () => {
    let checkedDocuments =
      await ListingData?.data?.transaction_documents?.filter(
        (item) => item.status === "approved" || item.status === "exempt"
      );
    if (
      checkedDocuments.length ===
      ListingData?.data?.transaction_documents?.length
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleCheckForCloseContract = async () => {
    let checkFlag = await handleValidate();
    if (checkFlag) {
      setCloseContract(true);
    } else {
      setErrorAlert({
        errorMsg: `All documents should be approved then you can close the contract (if document not approved then it can exempted)`,
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleDragEnter = () => {
    uploadBUttonRef.current.style.backgroundColor = "#0B0909";
    uploadBUttonRef.current.style.color = "white";
    dragRef.current.style.display = "flex";
  };
  const handleDragLeave = () => {
    uploadBUttonRef.current.style.backgroundColor = "";
    uploadBUttonRef.current.style.color = "";
    dragRef.current.style.display = "none";
  };

  return (
    <React.Fragment>
      <Dropzone
        accept={".pdf"}
        noClick={true}
        noKeyboard={true}
        onDragEnter={() => handleDragEnter()}
        onDragLeave={() => handleDragLeave()}
        onDrop={(acceptedFiles) => {
          handleFileChange(acceptedFiles);
          handleDragLeave();
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps()}
            sx={{ position: "sticky", top: "64px", zIndex: "100" }}
          >
            <Card variant="outlined">
              {" "}
              <Box className={classes.TopTransactionsBarArea}>
                <Box className={classes.TopTransactionsBarAreaLeft}>
                  {" "}
                  <Box>
                    {" "}
                    <TabContext>
                      <Box className={classes.TransactionsTabsUI}>
                        <Tabs
                          value={location.pathname}
                          TabIndicatorProps={{
                            sx: { background: "black", color: "black" },
                          }}
                          variant="scrollable"
                          aria-label="transactions-tabs"
                          sx={{ justifyContent: "space-around" }}
                        >
                          <Tab
                            label="Detail"
                            value={`/listings/listing-detail/${id}/detail-overview`}
                            to={`/listings/listing-detail/${id}/detail-overview`}
                            component={Link}
                            sx={{ padding: "0px 10px" }}
                          />{" "}
                          <Tab
                            label="Data"
                            value={`/listings/listing-detail/${id}/data`}
                            to={`/listings/listing-detail/${id}/data`}
                            component={Link}
                            sx={{ padding: "0px 10px" }}
                          />{" "}
                          <Tab
                            label="Documents"
                            value={`/listings/listing-detail/${id}/documents`}
                            to={`/listings/listing-detail/${id}/documents`}
                            component={Link}
                            sx={{ padding: "0px 10px" }}
                          />
                          {projectDetail && !ListingData.isLoading ? (
                            <Tab
                              label="Makeover"
                              value={`/listings/listing-detail/${id}/makeover`}
                              to={`/listings/listing-detail/${id}/makeover`}
                              component={Link}
                              sx={{ padding: "0px 10px" }}
                            />
                          ) : null}
                        </Tabs>
                      </Box>
                    </TabContext>{" "}
                  </Box>{" "}
                  <Box>
                    {" "}
                    <Button
                      onClick={(e) => setAnchorEl(e.target)}
                      sx={{ marginLeft: "30px" }}
                      className={classes.uploadTransactionButton}
                      variant="outlined"
                      startIcon={<GavelRoundedIcon />}
                      color="inherit"
                    >
                      actions
                    </Button>{" "}
                  </Box>{" "}
                </Box>
                <Box className={classes.TopTransactionsBarAreaLeft}>
                  {" "}
                  <CustomTooltip title="Drag and drop file here or simple upload by click">
                    <Button
                      className={classes.uploadTransactionButton}
                      variant="outlined"
                      startIcon={<UploadFileIcon />}
                      color="inherit"
                      component={"label"}
                      ref={uploadBUttonRef}
                    >
                      <input
                        onChange={(e) => handleFileChange(e.target.files)}
                        hidden
                        accept=".pdf"
                        multiple
                        type="file"
                      />{" "}
                      <section>
                        <div>
                          <input {...getInputProps()} /> Upload
                        </div>
                      </section>
                    </Button>
                  </CustomTooltip>
                  {/* <Button
              onClick={() => setAddTask(true)}
              className={classes.addTransactionButton}
              variant="contained"
              startIcon={<CreateNewFolderRoundedIcon />}
            >
              New Task
            </Button>{" "} */}
                  <Search>
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                </Box>
              </Box>
            </Card>
          </Box>
        )}
      </Dropzone>
      {ListingData.isLoading ? (
        <ListingDetailSkelton />
      ) : (
        <Container className={classes.transactionsStatsArea} maxWidth="lg">
          <Dropzone
            accept={".pdf"}
            noClick={true}
            noKeyboard={true}
            onDragEnter={() => handleDragEnter()}
            onDragLeave={() => handleDragLeave()}
            onDrop={(acceptedFiles) => {
              handleFileChange(acceptedFiles);
              handleDragLeave();
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()}>
                <ListingHeaderCard ListingData={ListingData.data} {...props} />
              </Box>
            )}
          </Dropzone>
          <Routes>
            <Route
              path="/documents"
              element={<Documents ListingData={ListingData.data} />}
            />
            <Route
              path="/detail-overview"
              element={
                <TransactionsDetailOverview
                  id={id}
                  ListingData={ListingData.data}
                />
              }
            />
            <Route
              path="/data"
              element={
                <TransactionsDetailData
                  id={id}
                  ListingData={ListingData.data}
                />
              }
            />
            <Route
              path="/makeover"
              element={
                <TransactionDetailMakeOver
                  id={id}
                  listingDetail={ListingData.data}
                />
              }
            />
          </Routes>
        </Container>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={"listing-detail-actions"}
        keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            minWidth: "220px",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            paddingLeft: "0px",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <List disablePadding sx={{ width: "100%", maxWidth: 360 }}>
          {projectDetail ||
          ListingData?.data?.status === "closed" ||
          ListingData?.data?.status === "terminated" ? null : (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setOpenAdd(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                  <HouseIcon sx={{ color: "#7a7a7a" }} />
                </ListItemIcon>
                <ListItemText primary="Create Makeover" />
              </ListItemButton>
              <Divider component="li" />
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setTerminateContract(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <MonetizationOnRoundedIcon sx={{ color: "#EF5350" }} />
              </ListItemIcon>
              <ListItemText primary="Withdraw Listing" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() =>
                navigate(`/transaction/contracts/add-contract`, {
                  state: ListingData.data,
                })
              }
            >
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <MonetizationOnRoundedIcon sx={{ color: "#FFB400" }} />
              </ListItemIcon>
              <ListItemText primary="Place Under Contract" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setOpenDelete(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete Listing" />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <CloseContract open={closeContract} id={id} setOpen={setCloseContract} />
      <TerminateContract
        open={terminateContract}
        id={id}
        setOpen={setTerminateContract}
      />
      <DeleteLIsting open={openDelete} id={id} setOpen={setOpenDelete} />
      <AddTask open={addTask} id={id} setOpen={setAddTask} />
      <UploadPdfSplit
        ListingData={ListingData}
        open={openSplitPdf}
        id={id}
        file={file}
        setOpen={setOpenSplitPdf}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <AddMakeOverProject
        open={openAdd}
        setOpen={setOpenAdd}
        listingDetail={ListingData.data}
        {...props}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "ListingDetailStyles" })(
  ListingDetail
);
