/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { IconButton, Stack, Box, Typography, CircularProgress } from "@mui/material";
import { Close } from "@mui/icons-material";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import TableSkeleton from "../../../../components/loader/tableLoader";
import trainingIcon from "../../../../assets/images/agents/trainingIcon.png";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getAgentTrainingProgress } from "../../../../redux/agents/agentRosterDetail/trainingProgress";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
function VideoViewedHistory(props) {
  let { open, setOpen, classes, id } = props;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if(open){
        dispatch(getAgentTrainingProgress({ ...filters, id }));
    }
  }, [open]);

  const AgentTrainingProgress = useSelector((state) => state.agentRoster.AgentTrainingProgress);
  const columns = [
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      renderCell: ({ row }) => {
        return <img src={row.post?.thumbnail ? row.post?.thumbnail : trainingIcon} alt={"Training Icon"} style={{ maxWidth: "100px" }} />;
      },
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "Title",
      headerName: "Title",
      renderCell: ({ row }) => {
        return row?.post?.title ? <Typography sx={{ color: "rgba(0, 0, 0, 0.87)" }}>{row?.post?.title}</Typography> : "--";
      },
      width: 220,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "Viewed On",
      headerName: "Viewed On",
      renderCell: ({ row }) => {
        return row.updatedAt ? (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
            {moment(row.updatedAt || new Date()).format("L")} {moment(row.updatedAt || new Date()).format("LT")}
          </Typography>
        ) : (
          "--"
        );
      },
      width: 120,
      editable: false,
      sortable: true,
      flex: 0.5,
    },
    {
      field: "progress",
      headerName: "Percent Viewed",
      renderCell: ({ row }) => {
        return (
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              size={45}
              sx={{
                boxShadow: "inset 0 0 0px 4px #EEEEEE",
                backgroundColor: "transparent",
                borderRadius: "100%",
              }}
              value={row.progress}
              color="inherit"
              variant="determinate"
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${row.progress}%`}
              </Typography>
            </Box>
          </Box>
        );
      },
      valueGetter: ({ row }) => row?.team?.name,
      editable: false,
      sortable: false,
      flex: 0.4,
    },
  ];
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.generateModelHeader} id="alert-dialog-title">
          <Stack direction={"row"} spacing={"2"} alignItems={"center"}>
            <OndemandVideoRoundedIcon fontSize="large" sx={{ color: "rgba(0, 0, 0, 0.54)", mr: "12px" }} />
            Video View History
          </Stack>

          <IconButton onClick={() => setOpen(false)} className={classes.closeButton}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "30px !important" }}>
          <Box className={classes.tableRoot}>
            <DataGridPro
              columns={columns}
              loading={AgentTrainingProgress.isLoading}
              rows={
                AgentTrainingProgress?.data?.viewedEvents?.length
                  ? AgentTrainingProgress?.data?.viewedEvents?.map((item, index) => ({ ...item, id: index }))
                  : []
              }
              onRowsScrollEnd={(e) => {
                if (AgentTrainingProgress?.data?.count > e.virtualRowsCount && !AgentTrainingProgress.isLoading) {
                  dispatch(
                    getAgentTrainingProgress({
                      pageNumber: Number(e.virtualRowsCount / 15) + 1,
                      pageSize: 15,
                      id: id,
                    })
                  );
                }
              }}
              rowHeight={76}
              hideFooter
              components={{
                LoadingOverlay: TableSkeleton,
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "VideoViewedHistoryStyle" })(VideoViewedHistory);
