import * as React from "react";
import { Typography, Grid, Card, TextField } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";

const InterestedParties = (props) => {
  let { classes, updateContractDetail, contractDetail,errMsg } = props;
  return (
    <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible", paddingBottom: "70px" }}>
      <Typography classes={{ root: classes.transactionFormCardHeading }}>Other Interested Parties</Typography>
      <Grid container spacing={2} className={classes.transactionFormArea}>
        <Grid item xs={12} md={6}>
          <TextField
            value={contractDetail.opposite_party_name}
            onChange={(e) => updateContractDetail("opposite_party_name", e.target.value)}
            fullWidth
            name="oppositParty"
            variant="standard"
            helperText=" Opposite Party(ies)"
            error={errMsg["opposite_party_name"]}
            id="opposite_party_name"
          />
        </Grid>
      </Grid>
    </Card>
  );
};
export default withStyles(styleSheet, { name: "InterestedPartiesStyle" })(InterestedParties);
