import React, { useState } from "react";
import { Dialog, Box, IconButton } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import EmailTab from "../../../container/leads/common/Tabs/TabContent/EmailTab";
import ResponseAlert from "../../responseAlert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import { handleAddMinimizeTab } from "../../../redux/widgets/index";
import { handleUpdateModalState } from "../../../redux/widgets";
import { uid } from "uid";
function SendEmailModal() {
  const [minimizeFlag, setMinimizeFlag] = useState(false);
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const leadProfile = useSelector((item) => item.leadProfile.list);
  const open = useSelector((state) => state.widgets.sendEmailOpen);
  const maximizedTab = useSelector((state) => state.widgets.maximizedTab);
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const handleClose = () => {
    dispatch(handleUpdateModalState({ type: "sendEmailOpen", action: false }));
  };
  const handleAddMinimize = (data) => {
    dispatch(
      handleAddMinimizeTab({
        title: data?.subject
          ? data?.subject
          : data?.toEmailList?.length
          ? `${data?.toEmailList[0].email} ${data?.toEmailList?.length > 1 ? ` + ${data?.toEmailList?.length}More` : ""}`
          : "New Email",
        type: "email",
        id: `${uid()}email`,
        modalType: "sendEmailOpen",
        action: false,
        tabDetail: { ...data },
      })
    );
    setMinimizeFlag(false);
  };
  // set the right position of widgets model

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        // onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          sx: {
            position: "fixed",
            right:
              rightPositions?.length && rightPositions.find((item) => item.type === "sendEmailOpen")
                ? rightPositions.find((item) => item.type === "sendEmailOpen").right
                : -20,
            bottom: -15,
            borderRadius: "6px",
            zIndex: 100000,
          },
        }}
        hideBackdrop
        disableEnforceFocus
        sx={{ position: "initial" }}
        disableBackdropClick
      >
        <DialogContent sx={{ width: "700px" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "-20px" }}>
            <IconButton onClick={() => setMinimizeFlag(true)}>
              {" "}
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={() => handleClose()}>
              {" "}
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <EmailTab
            btnText={"Send Email"}
            handleAddMinimize={handleAddMinimize}
            minimizeFlag={minimizeFlag}
            IsPopup
            btnStyle={"btn-send-email"}
            leadProfile={leadProfile}
            maximizedTab={open ? maximizedTab : null}
            disableFrom
            handleClosePopup={handleClose}
          />
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "SendEmailModalStyle" })(SendEmailModal);
