import React, { useEffect, useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box, Typography, FormControlLabel, Switch, Paper, Stack, ListItemText, ListItem, CircularProgress, Button, IconButton } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEventModal from "../modals/addEvent";
import { handleGetEvents } from "../../../redux/eventsAndAlerts/events";
import TableSkeleton from "../../../components/loader/tableLoader.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EventDetail from "../modals/eventDetail";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { CustomTooltip } from "../../../components/shared/tooltip";
import { icons } from "../modals/addEvent";
const moment = require("moment-timezone");
const localizer = momentLocalizer(moment);
function EventPage(props) {
  let { classes, isCalendarView, openAddEvent, setOpenAddEvent, search } = props;
  const [eventData, setEventData] = useState({});
  const [openEventDetail, setOpenEventDetail] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    view_past_events: false,
    search: "",
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const eventList = useSelector((item) => item.eventAlerts.events.eventList);
  const columns = [
    {
      field: "title",
      headerName: "Event",
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            {row.icon ? (
              <Box className={classes.tableImageArea}>
                <img width="26px" src={icons.find((item) => item.value === row.icon)?.icon} alt="" />
              </Box>
            ) : (
              <Box className={classes.tableImageArea}></Box>
            )}
            <Box sx={{ ml: "10px" }}> {row.title}</Box>
          </Stack>
        );
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "event_date",
      headerName: "Date",
      renderCell: ({ row }) => {
        return <Box>{moment(row.event_date).tz("America/New_York").format("L")}</Box>;
      },
      width: 100,
      editable: false,
      sortable: true,
    },
    {
      field: "body",
      headerName: "Description",
      width: 220,
      editable: false,
      sortable: true,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ whiteSpace: "pre-line  !important" }} className={classes.descriptionMultiLines}>
            {row?.body}
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "time",
      headerName: "Time",
      renderCell: ({ row }) => {
        return (
          <Box>
            {moment(row.start_time, "HH:mm:ss").format("hh:mm A")}-{moment(row.end_time, "HH:mm:ss").format("hh:mm A")}
          </Box>
        );
      },
      width: 140,
      editable: false,
      sortable: true,
    },
    {
      field: "location",
      headerName: "Location",
      renderCell: ({ row }) => {
        return (
          <ListItemText
            classes={{ primary: classes.listItemTextHeading, secondary: classes.listItemTextContent }}
            primary={row.location_title || "NA"}
            secondary={row.location}
          />
        );
      },
      flex: 0.4,
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "list",
      headerName: "RSVP List",
      flex: 0.7,
      renderCell: ({ row }) => {
        return !location?.state?.dashboardView ? (
          row?.rsvps ? (
            <ListItem
              sx={{ paddingLeft: "0px" }}
              secondaryAction={
                row.total_invitees && row.spot_left ? (
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      sx={{
                        boxShadow: "inset 0 0 0px 4px #EEEEEE",
                        backgroundColor: "transparent",
                        borderRadius: "100%",
                      }}
                      color="inherit"
                      variant="determinate"
                      value={((Number(row.total_invitees) - Number(row.spot_left)) / Number(row.total_invitees)) * 100}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="caption" component="div" color="text.secondary">{`${(
                        ((Number(row.total_invitees) - Number(row.spot_left)) / Number(row.total_invitees)) *
                        100
                      ).toFixed(1)}%`}</Typography>
                    </Box>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <ListItemText
                classes={{ primary: classes.listItemTextHeading, secondary: classes.listItemTextContent }}
                primary={`Total RSVPs: ${row.total_invitees || ""}`}
                secondary={`${row.spot_left || ""} Spots Left`}
              />
            </ListItem>
          ) : null
        ) : row?.rsvps ? (
          <Stack direction={"row"} spacing={1}>
            <ErrorOutlineIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} fontSize="small" />
            <Box>RSVP Required</Box>
          </Stack>
        ) : null;
      },
      width: 300,
      editable: false,
      sortable: true,
    },
  ];
  useEffect(() => {
    dispatch(handleGetEvents({ ...filters, path: location?.state?.dashboardView ? "user-events" : "events" }));
  }, [filters, location?.state?.dashboardView]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setFilters({ ...filters, search }), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const handleOpenDetail = (data) => {
    if (location?.state?.dashboardView) {
      setOpenEventDetail(true);
    } else {
      setOpenAddEvent("update");
    }
    setEventData(data);
  };
  const handleSelectEvent = (data) => {
    let eventDetail = eventList.data?.data.find((item) => item.id === data.id);
    handleOpenDetail(eventDetail);
  };

  return (
    <Box className={classes.pageRoot}>
      {isCalendarView ? (
        <Box>
          <Calendar
            localizer={localizer}
            events={
              eventList.data?.data?.length
                ? eventList?.data?.data.map((item) => ({
                    id: item.id,
                    title: `${moment(item.start_time, "HH:mm:ss").format("HH:mm A")}  ${item.title}`,
                    start: new Date(item.event_date),
                    end: new Date(item.event_date),
                    resourceId: item.id,
                  }))
                : []
            }
            components={{
              toolbar: CustomToolbar,
            }}
            onSelectEvent={(data) => handleSelectEvent(data)}
            views={{ month: true }}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 800 }}
          />
        </Box>
      ) : (
        <React.Fragment>
          <Box className={classes.topAreaEvents}>
            <Typography className={classes.topAreaEventsHeading}>
              {!location?.state?.dashboardView ? eventList.data?.count || "" : ""}
              {!location?.state?.dashboardView ? " Events Scheduled" : " Upcoming Events & Training"}
            </Typography>
            <FormControlLabel
              sx={{ textTransform: "uppercase", "& 	.MuiFormControlLabel-label": { fontSize: "14px !important", color: "black" } }}
              control={<Switch onChange={(e) => setFilters({ ...filters, view_past_events: e.target.checked })} checked={filters.view_past_events} />}
              label="See PAST EVENTS"
            />
          </Box>
          <Paper className={classes.tableRoot}>
            <DataGridPro
              rows={eventList.data.data ? eventList.data.data : []}
              columns={columns}
              loading={eventList.isLoading}
              rowHeight={56}
              components={{
                LoadingOverlay: TableSkeleton,
              }}
              onRowClick={(data) => handleOpenDetail(data.row)}
              onRowsScrollEnd={(e) => {
                if (eventList.data?.count > e.virtualRowsCount && !eventList.isLoading) {
                  setFilters({
                    ...filters,
                    pageNumber: Number(e.virtualRowsCount / 25) + 1,
                    pageSize: 25,
                  });
                }
              }}
            />
          </Paper>
        </React.Fragment>
      )}
      <AddEventModal open={openAddEvent} eventData={openAddEvent === "update" ? eventData : {}} setOpen={setOpenAddEvent} {...props} />
      <EventDetail open={openEventDetail} setOpen={setOpenEventDetail} eventData={eventData} />
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "EventPageStyle",
})(EventPage);

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate("next");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
  };

  const label = () => {
    const date = moment(toolbar.date);
    return `${date.format("MMMM")} ${date.format("YYYY")}`;
  };

  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ mb: "15px" }}>
      <Button sx={{ border: "1px solid rgba(33, 33, 33, 0.4)" }} onClick={goToCurrent} color="inherit" size="small" variant="outlined">
        Today
      </Button>
      <CustomTooltip title="Previous Month">
        <IconButton>
          {" "}
          <ArrowBackIosNewRoundedIcon fontSize="small" onClick={goToBack} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip title="Next Month">
        <IconButton sx={{ ml: "0px !important" }}>
          <ArrowForwardIosRoundedIcon fontSize="small" onClick={goToNext} />
        </IconButton>
      </CustomTooltip>
      <Typography sx={{ fontWeight: "400  !important", fontSize: "18px !important", fontFamily: "AvenirNext  !important", color: "#0B0909" }}>
        {label()}
      </Typography>
    </Stack>
  );
};
