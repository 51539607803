import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const markReadNotificationsAll = createAsyncThunk(
  "MessagesAndAlerts/markReadNotificationsAll",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/notifications/mark-as-read`,
        { markAll: true },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
