import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import { Avatar, Table, Grid, Card, Chip, Stack, Box, IconButton } from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import TrainingRequirementsSelect from "./trainingReq";
import ResponseAlert from "../../../../components/responseAlert";
import AlarmRoundedIcon from "@mui/icons-material/AlarmRounded";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  AddTrainingIssue,
  getTrainingIssueDetail,
  AddTrainingComment,
  updateTrainingIssue,
  deleteAppointment,
} from "../../../../redux/agents/agentRosterDetail/trainingIssue";
import IssueDetailSkelton from "../../skeltons/issueDetail";
import moment from "moment";

function IssuesDetailModal(props) {
  let { open, setOpen, classes, setOpenAppointment, issueType, issueDetails, appointments, setAppointments } = props;
  const [trainingReq, setTrainingReq] = React.useState([]);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [openReq, setOpenReq] = useState(false);
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [issueDetail, setIssueDetail] = useState({
    userId: "",
    teamLeaderId: "",
    note: "",
    issueType: "",
    issueCategory: "",
    issueSubCategory: "",
    resolution: "",
  });
  const dispatch = useDispatch();
  const data = useSelector((item) => item.agentRoster.agentRosterProfile);
  const adminAuth = useSelector((state) => state.adminAuth);
  const addTrainingIssue = useSelector((item) => item.agentRoster.AgentTrainingIssues.addTrainingIssue);
  const updateIssue = useSelector((item) => item.agentRoster.AgentTrainingIssues.updateIssue);
  const trainingIssueDetail = useSelector((item) => item.agentRoster.AgentTrainingIssues.trainingIssueDetail);
  const { profile } = data;
  // console.log(trainingIssueDetail, "trainingIssueDetail");
  const handleRemoveReq = (data) => {
    let requirements = [...trainingReq];
    let requirementsFiltered = requirements.filter((item) => item.id !== data.id);
    setTrainingReq(requirementsFiltered);
  };
  const handleDeleteAppointment = (data) => {
    let appointmentsDet = [...appointments];
    let appointmentsFiltered = appointmentsDet.filter((item) => item.id !== data.id);
    setAppointments(appointmentsFiltered);
    dispatch(deleteAppointment({ id: data.id }));
  };
  const handleUpdateDetail = (field, value) => {
    setIssueDetail({ ...issueDetail, [field]: value });
    setErrMsg({});
  };
  useEffect(() => {
    if (addTrainingIssue.success && !addTrainingIssue.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the issue",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setIssueDetail({
        userId: "",
        teamLeaderId: "",
        note: "",
        issueType: "",
        issueCategory: "",
        issueSubCategory: "",
      });
      setTrainingReq([]);
    } else if (!addTrainingIssue.isLoading && addTrainingIssue.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addTrainingIssue.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addTrainingIssue.isLoading]);

  useEffect(() => {
    if (updateIssue.success && !updateIssue.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully updated the issue",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setIssueDetail({
        userId: "",
        teamLeaderId: "",
        note: "",
        issueType: "",
        issueCategory: "",
        issueSubCategory: "",
      });
      setTrainingReq([]);
    } else if (!updateIssue.isLoading && updateIssue.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateIssue.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateIssue.isLoading]);

  useEffect(() => {
    let issue = trainingIssueDetail.data;
    if (issue.id) {
      setIssueDetail({
        userId: "",
        teamLeaderId: "",
        note: issue.note,
        issueType: issue.issueType,
        issueCategory: issue.issueCategory,
        issueSubCategory: issue.issueSubCategory,
        resolution: issue.resolution,
      });
    }
    if (issue.required_training_videos && issue.required_training_videos?.length > 0) {
      setTrainingReq(issue.required_training_videos.map((item) => item.knowledgebase_post));
    } else {
      setTrainingReq([]);
    }
    if (issue.comments && issue.comments?.length > 0) {
      setComments(issue.comments);
    } else {
      setComments([]);
    }
    if (issue.appointments && issue.appointments?.length > 0) {
      setAppointments(issue.appointments);
    } else {
      setAppointments([]);
    }
  }, [trainingIssueDetail.success]);

  useEffect(() => {
    if (issueType !== "add" && issueDetails.id && open) {
      dispatch(getTrainingIssueDetail({ id: issueDetails.id }));
    } else {
      setIssueDetail({ userId: "", teamLeaderId: "", note: "", issueType: "", issueCategory: "", issueSubCategory: "", resolution: "" });
      setTrainingReq([]);
      setAppointments([]);
    }
  }, [issueDetails.id, open]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!issueDetail.issueType) {
      isFormValid = false;
      errorMsg["issueType"] = "Please select the issue type";
      setErrorAlert({
        errorMsg: "Please select the issue type",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!issueDetail.issueCategory) {
      isFormValid = false;
      errorMsg["issueCategory"] = "Please select the issue category";
      setErrorAlert({
        errorMsg: "Please select the issue category",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!issueDetail.issueSubCategory) {
      isFormValid = false;
      errorMsg["issueSubCategory"] = "Please select the issue sub category";
      setErrorAlert({
        errorMsg: "Please select the issue sub category",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleSaveIssue = () => {
    if (handleValidate()) {
      let obj = {
        ...issueDetail,
        userId: profile?.id,
        teamLeaderId: profile?.team?.team_leader_id,
        required_trainings: trainingReq.map((item) => item.id),
      };
      if (issueType === "add") {
        dispatch(AddTrainingIssue(obj));
      } else {
        dispatch(updateTrainingIssue({ ...obj, id: issueDetails.id }));
      }
    }
  };
  const handleAddComment = () => {
    let obj = {
      comment: commentText,
      id: issueDetails.id,
    };
    dispatch(AddTrainingComment(obj));
    let commentObj = {
      comment: commentText,
      createdAt: new Date().toISOString(),
      staff_user: { full_name: adminAuth?.adminDetail?.full_name },
    };
    let commentDetail = [...comments];
    commentDetail.push(commentObj);
    setComments(commentDetail);
    setCommentText("");
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle className={classes.paymentModalHeading} id="alert-dialog-title">
          Issue Details{" "}
          {trainingIssueDetail.data?.status === "outstanding" ? (
            <Chip
              sx={{ background: "#FFA726", minWidth: "70px" }}
              icon={<AlarmRoundedIcon fontSize="small" />}
              label={trainingIssueDetail.data?.status || "Outstanding"}
              color="success"
            />
          ) : trainingIssueDetail.data?.status === "overdue" ? (
            <Chip
              sx={{ background: "#E57373", minWidth: "70px" }}
              icon={<AlarmRoundedIcon fontSize="small" />}
              label={trainingIssueDetail.data?.status || "Overdue"}
              color="success"
            />
          ) : trainingIssueDetail.data?.status === "resolved" ? (
            <Chip
              sx={{ background: "#000000", minWidth: "70px" }}
              icon={<CheckCircleOutlineOutlinedIcon fontSize="small" />}
              label={trainingIssueDetail.data?.status || "Resolved"}
              color="success"
            />
          ) : (
            <Chip
              sx={{ background: "#4CAF50", minWidth: "70px" }}
              icon={<NewReleasesRoundedIcon fontSize="small" />}
              label={trainingIssueDetail.data?.status || "New"}
              color="success"
            />
          )}
        </DialogTitle>
        <DialogContent className={classes.customScrollBar} sx={{ width: issueType === "add" ? "600px" : "880px" }}>
          {trainingIssueDetail.isLoading && <IssueDetailSkelton />}
          {!trainingIssueDetail.isLoading && (
            <Grid container direction="row" spacing={2} sx={{ marginTop: "-15px" }}>
              <Grid item lg={issueType === "add" ? 12 : 7} xs={12} sx={{ zIndex: "1" }}>
                <Table size="small">
                  <TableRow>
                    <TableCell sx={{ width: "70px" }}>
                      <b>User</b>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "47px" }}>
                      <Avatar src={profile?.profile_images?.slack_profile_img_thumbnail} sx={{ width: "35px", height: "35px", fontSize: "14px" }}>
                        {profile?.first_name?.slice(0, 1)}
                        {profile?.last_name?.slice(0, 1)}
                      </Avatar>
                    </TableCell>
                    <TableCell align="left" sx={{ width: "300px", fontSize: "13px" }}>
                      {profile?.full_name} <br />{" "}
                      <Chip
                        size="small"
                        sx={{
                          background: "#CE93D8",
                          minWidth: "70px",
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                        label={profile?.team?.name}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
                {issueType !== "add" && (
                  <Table size="small">
                    <TableRow>
                      <TableCell sx={{ width: "111px", fontSize: "13px" }}>
                        <b>Last Updated</b>
                      </TableCell>
                      <TableCell align="left" sx={{ minWidth: "195px", fontSize: "13px" }}>
                        {moment(trainingIssueDetail.data?.updated).format("LL")} (
                        {moment(moment(new Date(trainingIssueDetail.data?.updated).toString())).from(moment(new Date()))})
                      </TableCell>
                      <TableCell align="left" sx={{ minWidth: "150px", fontSize: "13px" }}>
                        By {trainingIssueDetail.data?.issue_updated_by?.first_name} {trainingIssueDetail.data?.issue_updated_by?.last_name}.
                      </TableCell>
                    </TableRow>
                  </Table>
                )}
                {issueType !== "add" && (
                  <Table size="small">
                    <TableRow>
                      <TableCell sx={{ width: "111px", fontSize: "13px" }}>
                        <b>Date Created</b>
                      </TableCell>
                      <TableCell align="left" sx={{ minWidth: "195px", fontSize: "13px" }}>
                        {moment(trainingIssueDetail.data?.created).format("LL")} (
                        {moment(moment(new Date(trainingIssueDetail.data?.created).toString())).from(moment(new Date()))})
                      </TableCell>
                      <TableCell align="left" sx={{ minWidth: "150px", fontSize: "13px" }}>
                        By {trainingIssueDetail.data?.issue_created_by?.first_name} {trainingIssueDetail.data?.issue_created_by?.last_name}.
                      </TableCell>
                    </TableRow>
                  </Table>
                )}
                <Table>
                  <TableRow>
                    <TableCell sx={{ width: "120px", fontSize: "13px", paddingRight: "0px" }}>
                      <b>Issue Type</b>
                    </TableCell>
                    <TableCell sx={{ minWidth: "111px", fontSize: "13px" }}>
                      <ButtonGroups
                        minWidth="90px"
                        size="small"
                        borderColor="lightgrey"
                        bgColor="white"
                        bgColor1="rgba(33, 33, 33, 0.04)"
                        bgColorHover="rgba(33, 33, 33, 0.04)"
                        color="#0B0909"
                        fontSize="11px"
                        placeholder={"DEFICIENCY"}
                        value={issueDetail.issueType}
                        options={[
                          { title: "Initial Training", value: "initial_training" },
                          { title: "Deficiency", value: "deficency" },
                          { title: "Other", value: "other" },
                        ]}
                        onChangeMenu={(value) => handleUpdateDetail("issueType", value)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "50px",
                        fontSize: "13px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      Category
                    </TableCell>
                    <TableCell sx={{ minWidth: "111px", fontSize: "13px", zIndex: 10 }}>
                      <ButtonGroups
                        minWidth="90px"
                        size="small"
                        borderColor="lightgrey"
                        fontSize="11px"
                        bgColor="white"
                        bgColor1="rgba(33, 33, 33, 0.04)"
                        bgColorHover="rgba(33, 33, 33, 0.04)"
                        color="#0B0909"
                        placeholder={"Select"}
                        value={issueDetail.issueCategory}
                        options={[
                          { title: "Initial Handling", value: "initial_handling" },
                          { title: "Long Followup", value: "long_followup" },
                          { title: "Offscript", value: "offscript" },
                        ]}
                        onChangeMenu={(value) => handleUpdateDetail("issueCategory", value)}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
                <Table>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100px",
                        fontSize: "13px",
                        paddingRight: "0px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <b>Sub-Category</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "111px",
                        fontSize: "13px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <ButtonGroups
                        minWidth="300px"
                        size="small"
                        borderColor="lightgrey"
                        bgColor="white"
                        bgColor1="rgba(33, 33, 33, 0.04)"
                        bgColorHover="rgba(33, 33, 33, 0.04)"
                        color="#0B0909"
                        fontSize="11px"
                        placeholder={"After Hours handling"}
                        value={issueDetail.issueSubCategory}
                        options={[
                          { title: "Issue Handling Subcategory", value: "Issue Handling Subcategory" },
                          { title: "Issue Handling Subcategory 1", value: "Issue Handling Subcategory 1" },
                          { title: "Issue Handling Subcategory 2", value: "Issue Handling Subcategory 2" },
                        ]}
                        onChangeMenu={(value) => handleUpdateDetail("issueSubCategory", value)}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
                <Table>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100px",
                        fontSize: "13px",
                        paddingRight: "0px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <b>Notes</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <TextField
                        className={classes.customerTextField}
                        multiline
                        rows={2}
                        placeholder="This lead is not being test placeholder text that rolls over into the second line and could possiblty trunc..."
                        fullWidth
                        size="small"
                        value={issueDetail.note}
                        onChange={(e) => handleUpdateDetail("note", e.target.value)}
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                </Table>
                <Table>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100px",
                        fontSize: "13px",
                        paddingRight: "0px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <b>Req. Training</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "111px",
                        fontSize: "13px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Stack direction="row" justifyContent="flex-start" alignItems="center" flexWrap="wrap" spacing={1}>
                        {trainingReq.map((item) => (
                          <Chip size="small" label={item.title} onDelete={() => handleRemoveReq(item)} sx={{ marginBottom: "5px" }} />
                        ))}
                        <IconButton onClick={() => setOpenReq(true)}>
                          <AddCircleIcon sx={{ color: " rgba(0, 0, 0, 0.54)" }} />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </Table>
                {issueType !== "add" && (
                  <Table>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: "100px",
                          fontSize: "13px",
                          paddingRight: "0px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <b>Appointments</b>
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "111px",
                          fontSize: "13px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" flexWrap="wrap" spacing={1}>
                          {appointments.map((item, index) => (
                            <Chip
                              key={index}
                              size="small"
                              onDelete={() => handleDeleteAppointment(item)}
                              label={`${item.start_date} @ ${item.start_time}`}
                              sx={{ marginBottom: "5px" }}
                            />
                          ))}
                          <IconButton>
                            <AddCircleIcon onClick={() => setOpenAppointment(true)} sx={{ color: " rgba(0, 0, 0, 0.54)" }} />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </Table>
                )}
                <Table>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100px",
                        fontSize: "13px",
                        paddingRight: "0px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <b>Resolution</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "111px",
                        fontSize: "13px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <TextField
                        className={classes.customerTextField}
                        multiline
                        rows={2}
                        placeholder=" Here the team leader will put notes as they are closing the ticket."
                        fullWidth
                        size="small"
                        value={issueDetail.resolution}
                        onChange={(e) => handleUpdateDetail("resolution", e.target.value)}
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
              <Grid hidden={issueType === "add"} item lg={5} xs={12} sx={{ zIndex: "2" }}>
                <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.commentBox}>
                  <Box className={classes.commentBoxCommentsArea}>
                    {comments.map((item) => (
                      <Box>
                        <Table size="small" sx={{ marginTop: "20px" }}>
                          <TableRow sx={{ verticalAlign: "top" }}>
                            <TableCell sx={{ width: "40px" }} className={classes.commentBoxCommentCell}>
                              <Avatar sx={{ width: "25px", height: "25px" }} />
                            </TableCell>
                            <TableCell className={classes.commentBoxCommentCell}>{item.staff_user?.full_name}:</TableCell>
                            <TableCell align="left" className={classes.commentBoxCommentCell}>
                              {item.comment}
                            </TableCell>
                            <TableCell className={classes.commentBoxCommentCell} sx={{ width: "67px" }}>
                              {moment(moment(new Date(item.createdAt).toString())).from(moment(new Date()))}
                              {/* {moment(item.createdAt).format("MMM Do")} */}
                            </TableCell>
                          </TableRow>
                        </Table>
                        {/* <Table>
                            <TableRow sx={{ verticalAlign: "top" }}>
                              <TableCell className={classes.commentBoxCommentCell}></TableCell>
                              <TableCell sx={{ minWidth: "150px", color: "rgba(0, 0, 0, 0.38)" }} className={classes.commentBoxCommentCell}>
                                Status changed to Resolved by Staffuser L.
                              </TableCell>
                              <TableCell className={classes.commentBoxCommentCell}></TableCell>
                              <TableCell className={classes.commentBoxCommentCell} sx={{ whiteSpace: "nowrap" }}>
                                25 May
                              </TableCell>
                            </TableRow>
                          </Table> */}
                      </Box>
                    ))}
                  </Box>

                  <TextField
                    id="outlined-basic"
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && commentText.length > 0) {
                        handleAddComment();
                      }
                    }}
                    onChange={(e) => setCommentText(e.target.value)}
                    value={commentText}
                    placeholder="Add Comment"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Card>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSaveIssue()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addTrainingIssue.isLoading || updateIssue.isLoading}
          >
            {issueType === "add" ? " Save" : "Update"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <TrainingRequirementsSelect trainingReq={trainingReq} setTrainingReq={setTrainingReq} open={openReq} setOpen={setOpenReq} />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "IssuesDetailModalStyle" })(IssuesDetailModal);
