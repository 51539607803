import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { updateTransactionContract } from "../../../redux/transactions/updateTransactionContract";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "25px!important",
    margin: "5px 0px!important",
  },
  field: {
    border: "none!important",
    borderBottom: "1px solid #000000DE!important",
  },
  autocompleteOption: {
    padding: "15px",
    margin: "10px",
    fontFamily: "AvenirNext !important",
  },
  optionHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px",
  },
  optionHeaderSpan: {
    fontFamily: "AvenirNext !important",
    fontSize: "14px",
  },
  optionSubHeaderLeft: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
  optionSubHeaderRight: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
}));

function EditAddressModal(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const muiClasses = useStyles();

  let { open, classes, ContractData, setOpen, handleMenuClose } = props;

  const [errorAlert, setErrorAlert] = React.useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [formData, setFormData] = React.useState({});
  const updateContract = useSelector((state) => state.transactions.TransactionsContract.updateContract);

  React.useEffect(() => {
    return () => {
      setFormData({});
    };
  }, []);

  React.useEffect(() => {
    setFormData({
      property_address: ContractData.property_address,
      street: ContractData.street,
      property_type: ContractData.property_type,
      unit_number: ContractData.unit_number,
      lot_number: ContractData.lot_number,
      city: ContractData.city,
      state: ContractData.state,
      zipcode: ContractData.zipcode,
    });
  }, [ContractData]);

  React.useEffect(() => {
    if (updateContract?.success && !updateContract.isLoading && open) {
      setErrorAlert({ errorMsg: "You have successfully updated the address", errorType: "success", isOpen: true });
      setOpen(false);
    }
  }, [updateContract]);

  const handleUpdateDate = () => {
    dispatch(
      updateTransactionContract({
        id,
        contractData: {
          ...ContractData,
          ...formData,
          property_address: `${formData.street} ${formData.city} ${formData.state}, ${formData.zipcode}`,
        },
      })
    );
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="edit-important-date-dialog-title"
        aria-describedby="edit-important-date-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={muiClasses.modalHeader} id="edit-important-date-dialog">
          Edit Address
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={4} alignItems="center" justifyContent="center">
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Street"
                variant="standard"
                value={formData.street}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, street: e.target.value }))}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                label="City"
                variant="standard"
                value={formData.city}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, city: e.target.value }))}
              />
            </Grid>

            {formData.property_type === "condo" && (
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Unit Number"
                  variant="standard"
                  value={formData.unit_number}
                  onChange={(e) => setFormData((prevForm) => ({ ...prevForm, unit_number: e.target.value }))}
                />
              </Grid>
            )}

            {formData.property_type === "lot" && (
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Lot Number"
                  variant="standard"
                  value={formData.lot_number}
                  onChange={(e) => setFormData((prevForm) => ({ ...prevForm, lot_number: e.target.value }))}
                />
              </Grid>
            )}

            <Grid item xs={2.5}>
              <TextField
                fullWidth
                label="State"
                variant="standard"
                value={formData.state}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, state: e.target.value }))}
              />
            </Grid>

            <Grid item xs={2.5}>
              <TextField
                fullWidth
                label="Zip"
                variant="standard"
                value={formData.zipcode}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, zipcode: e.target.value }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleUpdateDate()}
            classes={{ root: classes.saveModalButton }}
            color="inherit"
            autoFocus
            loading={updateContract.isLoading}
            loadingPosition="start"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNoteModalStyle" })(EditAddressModal);
