import { createSlice } from "@reduxjs/toolkit";
import { addTask } from "./addTask";
import { getTransactionTasks } from "./getTasks";
import { updateTaskStatus } from "./updateTask";
import { archiveAndUnarchiveTask } from "./archiveAndUnarchiveTask";
import { addTaskComment } from "./storeTaskComment";
import { getTransactionTaskDetail } from "./getTransactionTaskDetail";
import { addTaskAttachment } from "./addTaskAttachment";
const initialState = {
  TasksData: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  addTask: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateTask: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateArchive: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addComment: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  taskDetail: { success: false, data: {}, isLoading: false, errMsg: null },
  taskAttachment: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const Tasks = createSlice({
  name: "Tasks",
  initialState,
  reducers: {
    handleUpdateAttachments: (state, action) => {
      let newAttachments = state.taskDetail?.data?.attachments;
      state.taskDetail.data.attachments = newAttachments?.filter(
        (item) => !action.payload.deleteAbleIds?.includes(item.id)
      );
    },
    handleUpdateAttachmentOrder: (state, action) => {
      state.taskDetail.data.attachments = action.payload.attachments;
    },
  },
  extraReducers: {
    [addTask.pending]: (state) => {
      state.addTask.isLoading = true;
      state.addTask.errMsg = null;
      state.addTask.success = false;
    },
    [addTask.fulfilled]: (state, action) => {
      state.addTask.success = true;
      state.addTask.isLoading = false;
      state.addTask.data = action.payload;
      let tasksDatas = state.TasksData.data.tasks
        ? [...state.TasksData.data.tasks]
        : [];
      state.TasksData.data.tasks = [...tasksDatas, action.payload];
    },
    [addTask.rejected]: (state, action) => {
      state.addTask.isLoading = false;
      state.addTask.success = false;
      state.addTask.errMsg = action.payload;
    },

    [getTransactionTasks.pending]: (state) => {
      state.TasksData.isLoading = true;
      state.TasksData.errMsg = null;
      state.TasksData.success = false;
    },
    [getTransactionTasks.fulfilled]: (state, action) => {
      state.TasksData.success = true;
      state.TasksData.isLoading = false;
      state.TasksData.data = action.payload;
    },
    [getTransactionTasks.rejected]: (state, action) => {
      state.TasksData.isLoading = false;
      state.TasksData.success = false;
      state.TasksData.errMsg = action.payload;
    },

    [updateTaskStatus.pending]: (state) => {
      state.updateTask.isLoading = true;
      state.updateTask.errMsg = null;
      state.updateTask.success = false;
    },
    [updateTaskStatus.fulfilled]: (state, action) => {
      state.updateTask.success = true;
      state.updateTask.isLoading = false;
      state.updateTask.data = action.payload;
      let index = state.TasksData.data.tasks?.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index > -1) {
        state.TasksData.data.tasks[index] = {
          ...state.TasksData.data.tasks[index],
          status: "completed",
          ...action.payload,
        };
      }
    },
    [updateTaskStatus.rejected]: (state, action) => {
      state.updateTask.isLoading = false;
      state.updateTask.success = false;
      state.updateTask.errMsg = action.payload;
    },

    [archiveAndUnarchiveTask.pending]: (state) => {
      state.updateArchive.isLoading = true;
      state.updateArchive.errMsg = null;
      state.updateArchive.success = false;
    },
    [archiveAndUnarchiveTask.fulfilled]: (state, action) => {
      state.updateArchive.success = true;
      state.updateArchive.isLoading = false;
      state.updateArchive.data = action.payload;
      let index = state.TasksData.data.tasks?.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index > -1) {
        state.TasksData.data.tasks[index].archived = action.payload.archived;
      }
    },
    [archiveAndUnarchiveTask.rejected]: (state, action) => {
      state.updateArchive.isLoading = false;
      state.updateArchive.success = false;
      state.updateArchive.errMsg = action.payload;
    },
    /*  add task comment */
    [addTaskComment.pending]: (state, action) => {
      state.addComment.isLoading = true;
      state.addComment.errMsg = null;
      state.addComment.success = false;
      if (action.meta?.arg) {
        let newComments = [...state.taskDetail.data.comments];
        newComments.push({
          ...action.meta?.arg,
          created_by: action.meta?.arg?.user?.id,
          createdAt: new Date().toISOString(),
        });
        state.taskDetail.data.comments = newComments;
        let newTasks = [...state.TasksData.data.tasks];
        let index = newTasks?.findIndex(
          (item) => item.id === action.meta.arg?.taskId
        );
        newTasks[index].comments = [
          {
            ...action.meta?.arg,
            created_by: action.meta?.arg?.user?.id,
            createdAt: new Date().toISOString(),
          },
        ];
        state.TasksData.tasks = newTasks;
      }
    },
    [addTaskComment.fulfilled]: (state, action) => {
      state.addComment.success = true;
      state.addComment.isLoading = false;
      state.addComment.data = action.payload;
    },
    [addTaskComment.rejected]: (state, action) => {
      state.addComment.isLoading = false;
      state.addComment.success = false;
      state.addComment.errMsg = action.payload;
    },
    /*  add task detail */
    [getTransactionTaskDetail.pending]: (state) => {
      state.taskDetail.isLoading = true;
      state.taskDetail.errMsg = null;
      state.taskDetail.success = false;
      state.taskDetail.data = {};
    },
    [getTransactionTaskDetail.fulfilled]: (state, action) => {
      state.taskDetail.success = true;
      state.taskDetail.isLoading = false;
      /*      newAttachments.sort((a, b) => a.order - b.order); */
      let newAttachments = [...action.payload.attachments];
      newAttachments.sort((a, b) => a.order - b.order);
      state.taskDetail.data = {
        ...action.payload,
        attachments: newAttachments,
      };
    },
    [getTransactionTaskDetail.rejected]: (state, action) => {
      state.taskDetail.isLoading = false;
      state.taskDetail.success = false;
      state.taskDetail.errMsg = action.payload;
    },
    /* upload task attachment */
    [addTaskAttachment.pending]: (state) => {
      state.taskAttachment.isLoading = true;
      state.taskAttachment.errMsg = null;
      state.taskAttachment.success = false;
    },
    [addTaskAttachment.fulfilled]: (state, action) => {
      state.taskAttachment.success = true;
      state.taskAttachment.isLoading = false;
      state.taskAttachment.data = action.payload;
      let newAttachments = [...state.taskDetail.data.attachments];
      newAttachments.push(action.payload.data);
      state.taskDetail.data.attachments = newAttachments;
    },
    [addTaskAttachment.rejected]: (state, action) => {
      state.taskAttachment.isLoading = false;
      state.taskAttachment.success = false;
      state.taskAttachment.errMsg = action.payload;
    },
  },
});
export const { handleUpdateAttachments, handleUpdateAttachmentOrder } =
  Tasks.actions;
export default Tasks.reducer;
