import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const updateLeadFlow = createAsyncThunk(
  "LeadRoutingOverView/updateLeadFlow",
  async (data, thunkAPI) => {
    let leadId = data.id;
    const groupData = data.group;
    const listGroupId = data.listGroupId;
    delete data.id;
    delete data.group;
    delete data.listGroupId;
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/lead-routing/${leadId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return { ...data, groupData: groupData, listGroupId };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
