import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import StreetviewOutlinedIcon from "@mui/icons-material/StreetviewOutlined";
import FullScreenDialog from "./fullScreenDialog/index";
import {
  getOptimizedImageUrl,
  samplePropertyIMage,
} from "../../../../../../utils/propertyData";
const sliderArrowStyle = {
  cursor: "pointer",
  position: "absolute",
  zIndex: "2",
  "@media (max-width: 575px)": {
    fontSize: "26px",
  },
  "@media (min-width: 575px)": {
    fontSize: "35px !important",
    padding: "6px",
  },
  color: "#000000",
  background: "#d6d6d6",
  borderRadius: "50%",
  top: "43%",
};
const multiSliderArrowStyle = {
  cursor: "pointer",
  position: "absolute",
  zIndex: "2",
  fontSize: "35px",
  color: "#fff",
  background: "#433F3F",
  height: "77.5px",
  top: "2.5%",
};
function SliderView(props) {
  let { classes } = props;
  const [activeSlide, setActiveSlide] = useState(0);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeView, setActiveView] = useState("photos");
  const isSmallScreen = useMediaQuery("(max-width: 575px)");
  const listDetail = useSelector((state) => state.allCmas.listingDetail);
  const ArrowLeft = ({ onClick }) => (
    <NavigateBefore
      sx={{ ...sliderArrowStyle, left: "10px" }}
      onClick={onClick}
    />
  );

  const ArrowRight = ({ onClick }) => (
    <NavigateNext
      sx={{ ...sliderArrowStyle, right: "10px" }}
      onClick={onClick}
    />
  );

  const MultiSliderArrowLeft = ({ onClick }) => (
    <NavigateBefore
      sx={{ ...multiSliderArrowStyle, left: "-30px" }}
      onClick={onClick}
    />
  );

  const MultiSliderArrowRight = ({ onClick }) => (
    <NavigateNext
      sx={{ ...multiSliderArrowStyle, right: "-30px" }}
      onClick={onClick}
    />
  );

  const handleIsAllPhotoShow = () => {
    if (
      listDetail?.data?.StandardStatus === "Closed" ||
      listDetail?.data?.StandardStatus === "Expired" ||
      listDetail?.data?.StandardStatus === "Withdrawn"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const settings = {
    dots: false,
    beforeChange: (oldIndex, newIndex) => {
      setActiveSlide(newIndex);
    },
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    // adaptiveHeight: true,
    slidesToShow: 1,
    initialSlide: 0,
    slidesToScroll: 1,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    style: {
      backgroundColor: isSmallScreen ? "white" : "black",
    },
  };

  const handleReturnImages = (images, images1) => {
    if (handleIsAllPhotoShow()) {
      let MediaOrder = listDetail?.data?.MediaOrder;
      if (images1 && images1?.[0] !== "null") {
        let newImages =
          typeof images1?.[0] === "string" ? JSON.parse(images1?.[0]) : images1;
        if (MediaOrder?.length) {
          let newOrderedImages = [];
          let startOrder = newImages?.find((it) => it.Order === 0) ? 0 : 1;
          MediaOrder?.map((order, index) => {
            let newFind = newImages?.find(
              (it) => it?.Order == index + startOrder
            );
            if (newFind) {
              newOrderedImages.push(newFind?.MediaURL);
            }
          });
          return newOrderedImages?.length
            ? newOrderedImages?.filter((it) => it !== undefined)
            : [samplePropertyIMage];
        } else {
          return newImages?.filter((item) => item !== "null" && item !== "[]")
            ?.length
            ? newImages
                ?.filter((item) => item !== "null" && item !== "[]")
                ?.map((item) => item.MediaURL)
            : [samplePropertyIMage];
        }
      } else if (images) {
        return images?.filter((item) => item !== "null" && item !== "[]")
          ?.length
          ? images?.filter((item) => item !== "null" && item !== "[]")
          : [samplePropertyIMage];
      } else {
        return [samplePropertyIMage];
      }
    } else {
      if (images1) {
        let newImages =
          typeof images1?.[0] === "string" ? JSON.parse(images1?.[0]) : images1;
        return newImages?.filter((item) => item !== "null" && item !== "[]")
          ?.length > 0
          ? newImages
              ?.filter((item) => item !== "null" && item !== "[]")
              ?.map((item) => item.MediaURL)
              ?.slice(0, 1)
          : [samplePropertyIMage];
      } else if (images) {
        return images?.filter((item) => item !== "null" && item !== "[]")
          ?.length > 0
          ? images
              ?.filter((item) => item !== "null" && item !== "[]")
              ?.slice(0, 1)
          : [samplePropertyIMage];
      } else {
        return [samplePropertyIMage];
      }
    }
  };

  const handleLength = () => {
    let images = listDetail?.data?.MediaURL;
    let images1 = listDetail?.data?.Media;
    if (images) {
      return images?.filter((item) => item !== "null" && item !== "[]")?.length;
    } else if (images1 && images1?.[0] !== "null") {
      let newImages =
        typeof images1?.[0] === "string" ? JSON.parse(images1?.[0]) : images1;
      return newImages?.filter((item) => item !== "null" && item !== "[]")
        ?.length;
    } else {
      return 0;
    }
  };

  const multiSetting = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    initialSlide: 0,
    slidesToScroll: 6,
    arrows: true,
    focusOnSelect: true,
    beforeChange: (oldIndex, newIndex) => {
      setActiveSlide(newIndex);
    },
    responsive: !handleIsAllPhotoShow()
      ? []
      : [
          {
            breakpoint: 7000,
            settings: {
              slidesToShow: Math.min(handleLength(), 4),
              slidesToScroll: 1,
              infinite: handleLength() > 4 ? true : false,
              dots: false,
            },
          },
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: Math.min(handleLength(), 3),
              slidesToScroll: 1,
              infinite: handleLength() > 3 ? true : false,
              dots: false,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: Math.min(handleLength(), 2),
              slidesToScroll: 1,
              infinite: handleLength() > 2 ? true : false,
              dots: false,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: Math.min(handleLength(), 2),
              slidesToScroll: 1,
              infinite: handleLength() > 2 ? true : false,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: handleLength() > 1 ? true : false,
              dots: false,
              initialSlide: 1,
            },
          },
        ],
    nextArrow: <MultiSliderArrowRight />,
    prevArrow: <MultiSliderArrowLeft />,
  };

  return (
    <Box className={classes.SliderArea}>
      <Slider {...settings} asNavFor={nav1} ref={(slider2) => setNav2(slider2)}>
        {handleReturnImages(
          listDetail?.data?.MediaURL,
          listDetail?.data?.Media
        )?.map((item, index, self) => (
          <Box
            onClick={() => {
              if (handleIsAllPhotoShow() && handleLength() > 0) {
                setOpen(true);
                setActiveView("photos");
              }
            }}
            key={item?.ListingId || index}
            className={classes.imageContainer}
          >
            <img
              sizes="100vw"
              width={500}
              height={300}
              className={classes.responsiveImage}
              alt="MediaURL"
              style={{
                objectFit: item?.includes("defaultPropertyNoImage")
                  ? "inherit"
                  : "cover",
              }}
              blurDataURL={getOptimizedImageUrl(
                item,
                isSmallScreen ? "416x276" : "2048x1536"
              )}
              src={getOptimizedImageUrl(
                item,
                isSmallScreen ? "416x276" : "2048x1536"
              )}
            />
          </Box>
        ))}
      </Slider>
      {isSmallScreen ? null : (
        <Box
          sx={{
            display:
              handleIsAllPhotoShow() &&
              handleReturnImages(
                listDetail?.data?.MediaURL,
                listDetail?.data?.Media
              )?.length > 1
                ? ""
                : "none !important",
          }}
          className={classes.sliderSettingArea}
        >
          <Box
            className={classes.sliderSettingSlider}
            sx={{ "& .slick-slide": { maxWidth: "110px" } }}
          >
            <Slider
              {...multiSetting}
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
              swipeToSlide={true}
              focusOnSelect={true}
            >
              {handleReturnImages(
                listDetail?.data?.MediaURL,
                listDetail?.data?.Media
              )?.map((item, index, self) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: "80px",
                    maxWidth: "110px",
                    position: "relative",
                    mt: "3px",
                  }}
                >
                  <img
                    sizes="100vw"
                    style={{
                      zIndex: "5",
                      width: "100%",
                      height: "100%",
                      opacity: activeSlide == index ? "1" : ".6",
                      cursor: "pointer",
                      borderLeft: "1.5px solid white",
                      borderRight: "1.5px solid white",
                      objectFit: "cover",
                    }}
                    priority
                    width={500}
                    height={300}
                    src={getOptimizedImageUrl(item, "165x165")}
                    alt="MediaURL"
                  />
                </Box>
              ))}
            </Slider>
          </Box>

          <Box className={classes.sliderSettingButtonsArea}>
            <Box
              onClick={() => {
                setOpen(true);
                setActiveView("photos");
              }}
              className={classes.sliderSettingButton}
            >
              <Box className={classes.sliderSettingButtonInside}>
                <GridViewIcon />
                <Typography className={classes.sliderSettingButtonInsideDes}>
                  View All
                </Typography>
              </Box>
            </Box>
            <Box
              onClick={() => {
                setOpen(true);
                setActiveView("map");
              }}
              className={classes.sliderSettingButton}
            >
              {" "}
              <Box className={classes.sliderSettingButtonInside}>
                <MapOutlinedIcon />
                <Typography className={classes.sliderSettingButtonInsideDes}>
                  Map
                </Typography>
              </Box>
            </Box>
            <Box
              onClick={() => {
                setOpen(true);
                setActiveView("streetView");
              }}
              className={classes.sliderSettingButton}
            >
              {" "}
              <Box className={classes.sliderSettingButtonInside}>
                <StreetviewOutlinedIcon />
                <Typography className={classes.sliderSettingButtonInsideDes}>
                  Street View
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <FullScreenDialog
        open={open}
        activeView={activeView}
        setActiveView={setActiveView}
        setOpen={setOpen}
      />
    </Box>
  );
}

export default SliderView;
