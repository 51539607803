import React, { useState } from "react";
import { Card, CardHeader, IconButton, Box, Skeleton } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import Linkify from "react-linkify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ResponseAlert from "../../../../components/responseAlert";

function TransactionsDetailIdx(props) {
  let { classes, publicDataDetail, isLoading } = props;

  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const handleCopy = () => {
    navigator.clipboard.writeText(publicDataDetail.idxURL);
    setErrorAlert({
      errorMsg: `Copied successfully!`,
      errorType: "success",
      isOpen: true,
    });
  };

  return (
    <React.Fragment>
      <Card className={classes.overViewTabCardMain}>
        <CardHeader
          classes={{ title: classes.overViewTabCardHeading }}
          action={
            publicDataDetail.idxURL ? (
              <IconButton onClick={handleCopy}>
                <ContentCopyIcon size="small" />
              </IconButton>
            ) : null
          }
          sx={{ paddingLeft: "0px", paddingRight: "0px", mb: "12px" }}
          title="SPW Url Link"
        />
        {isLoading ? (
          <Box>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : (
          <Box className={classes.MatterportLink}>
            {publicDataDetail.idxURL ? (
              <Linkify
                properties={{ target: "_blank" }}
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {publicDataDetail.idxURL}
              </Linkify>
            ) : (
              <span style={{ color: "red" }}>
                UPLOAD AND APPROVE AN ACTIVE LISTING AGREEMENT TO GENERATE SPW
                URL
              </span>
            )}
          </Box>
        )}
      </Card>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailIdxStyle" })(
  TransactionsDetailIdx
);
