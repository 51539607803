import  React from "react";
import { Container } from "@mui/material";
import ListingsStats from "./stats";
import ListingsTable from './listingTable'
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";


function TransactionsActiveContracts(props) {
  let {classes,setFilters,filters}=props;

  return (
    <Container className={classes.transactionsStatsArea} maxWidth="xl">
      <ListingsStats {...props} />
      <ListingsTable setFilters={setFilters} filters={filters} {...props} />
    </Container>
  );
}
export default withStyles(styleSheet, { name: "TransactionsActiveContractsStyle" })(TransactionsActiveContracts)
