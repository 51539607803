import React, { useState, useEffect } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
  TextField,
  FormLabel,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
import { addCommissionPlans, getCommissionPlans, getCommissionPlansDetails, updateCommissionPlans } from "../../../redux/finance/commissionPlans";
import Dialog from "../../../components/uiElements/modal";
const defaultRow = {
  value: "",
  type: "%",
  of: "gci",
  to: "",
  acctCode: "referralFee",
};
export const CreateAndUpdateCommissionModal = ({ closeModal, state, id, classes, initialValue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [grossCommissionArr, setGrossCommissionArr] = useState([
    {
      value: "",
      type: "%",
      of: "gci",
      to: "",
      acctCode: "referralFee",
    },
  ]);
  const [agentSplitArr, setAgentSplitArr] = useState([
    {
      value: "",
      type: "%",
      of: "gci",
      to: "",
      acctCode: "referralFee",
    },
  ]);
  const [companyDollarArr, setCompanyDollarArr] = useState([
    {
      value: "",
      type: "%",
      of: "gci",
      to: "",
      acctCode: "referralFee",
    },
  ]);
  const [agentPer, setAgentPer] = useState("");
  const [planName, setPlanName] = useState("");
  const [grossCalc, setGrossCalc] = useState({
    gci: 10000,
    agent: 0,
    company: 10000,
  });
  const [netCalc, setNetCalc] = useState({
    gci: 10000,
    agent: 0,
    company: 10000,
  });
  const [deductions, setDeductions] = useState({
    gci: [],
    agent: [],
    company: [],
  });
  const { vendorList: usersList } = useSelector((item) => item.users.storedListOfUsers);
  const {
    commissionPlanDetails: { isLoading },
    actionLoading,
  } = useSelector((item) => item.finance.commissionPlans);

  //----- calculation for sidebar
  useEffect(() => {
    let remaining = 10000,
      agentRemaining = grossCalc.agent,
      companyRemaining = grossCalc.company,
      contractPriceRemaining = 300000,
      netCalcTemp = netCalc,
      grossCalcTemp = grossCalc;
    for (let [index, gci] of grossCommissionArr.entries()) {
      if (gci.value) {
        let amountDeduct;
        if (gci.of === "gci") {
          amountDeduct = gci.type === "%" ? (remaining * parseFloat(gci.value)) / 100 : parseFloat(gci.value);
          remaining = remaining - amountDeduct;
        } else if (gci.of === "contractPrice") {
          amountDeduct = gci.type === "%" ? (contractPriceRemaining * parseFloat(gci.value)) / 100 : parseFloat(gci.value);
          contractPriceRemaining = contractPriceRemaining - amountDeduct;
          remaining = remaining - amountDeduct;
        } else {
          amountDeduct = parseFloat(gci.value);
          remaining = remaining - amountDeduct;
        }
        let ded = deductions.gci;
        if (ded[index]) {
          ded[index] = amountDeduct;
        } else {
          ded.push(amountDeduct);
        }
        setDeductions({ ...deductions, gci: ded });
      } else {
        let ded = deductions.gci;
        if (ded[index]) {
          ded.splice(index, 1);
        }
        setDeductions({ ...deductions, gci: ded });
      }
    }
    netCalcTemp["gci"] = remaining;
    agentRemaining = agentPer ? (remaining * parseFloat(agentPer)) / 100 : 0;
    companyRemaining = remaining - agentRemaining;
    grossCalcTemp["agent"] = agentRemaining;
    grossCalcTemp["company"] = companyRemaining;

    for (let [index, agent] of agentSplitArr.entries()) {
      if (agent.value) {
        let amountDeduct;
        if (agent.of === "gci") {
          amountDeduct = agent.type === "%" ? (agentRemaining * parseFloat(agent.value)) / 100 : parseFloat(agent.value);
          agentRemaining = agentRemaining - amountDeduct;
        } else if (agent.of === "contractPrice") {
          amountDeduct = agent.type === "%" ? (contractPriceRemaining * parseFloat(agent.value)) / 100 : parseFloat(agent.value);
          contractPriceRemaining = contractPriceRemaining - amountDeduct;
          agentRemaining = agentRemaining - amountDeduct;
        } else {
          amountDeduct = parseFloat(agent.value);
          agentRemaining = agentRemaining - amountDeduct;
        }
        let ded = deductions.agent;
        if (ded[index] || ded[index] == 0) {
          ded[index] = amountDeduct;
        } else {
          ded.push(amountDeduct);
        }
        setDeductions({ ...deductions, agent: ded });
      } else {
        let ded = deductions.agent;
        if (ded[index]) {
          ded.splice(index, 1);
        }
        setDeductions({ ...deductions, agent: ded });
      }
    }

    netCalcTemp["agent"] = agentRemaining;

    for (let [index, company] of companyDollarArr.entries()) {
      if (company.value) {
        let amountDeduct;
        if (company.of === "gci") {
          amountDeduct = company.type === "%" ? (companyRemaining * parseFloat(company.value)) / 100 : parseFloat(company.value);
          companyRemaining = companyRemaining - amountDeduct;
        } else if (company.of === "contractPrice") {
          amountDeduct = company.type === "%" ? (contractPriceRemaining * parseFloat(company.value)) / 100 : parseFloat(company.value);
          contractPriceRemaining = contractPriceRemaining - amountDeduct;
          companyRemaining = companyRemaining - amountDeduct;
        } else {
          amountDeduct = parseFloat(company.value);
          companyRemaining = companyRemaining - amountDeduct;
        }
        let ded = deductions.company;
        if (ded[index] || ded[index] == 0) {
          ded[index] = amountDeduct;
        } else {
          ded.push(amountDeduct);
        }
        setDeductions({ ...deductions, company: ded });
      } else {
        let ded = deductions.company;
        if (ded[index]) {
          ded.splice(index, 1);
        }
        setDeductions({ ...deductions, company: ded });
      }
    }
    netCalcTemp["company"] = companyRemaining;
    setNetCalc({ ...netCalcTemp });
    setGrossCalc({ ...grossCalc });
  }, [agentPer, companyDollarArr, agentSplitArr, grossCommissionArr]);
  useEffect(() => {
    if (id && initialValue) {
      setAgentPer(initialValue.agentPer);
      setPlanName(initialValue.name);
      let agentSplit = [],
        gciSplit = [],
        companyDollar = [];
      for (let cond of initialValue.conditions) {
        console.log(cond);
        if (cond.type === "gci") {
          gciSplit.push({
            value: parseFloat(cond.value),
            type: cond.paymentType,
            of: cond.percentageOf,
            to: cond.paidTo,
            acctCode: cond.acctCode,
          });
        }
        if (cond.type === "agent_split") {
          agentSplit.push({
            value: parseFloat(cond.value),
            type: cond.paymentType,
            of: cond.percentageOf,
            to: cond.paidTo,
            acctCode: cond.acctCode,
          });
        }
        if (cond.type === "company_dollar") {
          companyDollar.push({
            value: parseFloat(cond.value),
            type: cond.paymentType,
            of: cond.percentageOf,
            to: cond.paidTo,
            acctCode: cond.acctCode,
          });
        }
      }
      if (gciSplit.length) {
        setGrossCommissionArr(gciSplit);
      }
      if (agentSplit.length) {
        setAgentSplitArr(agentSplit);
      }
      if (companyDollar.length) {
        setCompanyDollarArr(companyDollar);
      }
    }
  }, [id, initialValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let grossCommissionSection = [];
    let agentSplitSection = [];
    let companyDollarSection = [];
    grossCommissionArr.map((item) => {
      if ((item.value && item.to && item.type && item.of && item.acctCode) || (item.type === "$" && item.value && item.to && item.acctCode)) {
        grossCommissionSection.push({
          value: item.value,
          type: item.type,
          of: item.type === "$" ? null : item.of,
          to: item.to,
          acctCode: item.acctCode,
        });
      }
    });
    agentSplitArr.map((item) => {
      if ((item.value && item.to && item.type && item.of && item.acctCode) || (item.type === "$" && item.value && item.to && item.acctCode)) {
        agentSplitSection.push({
          value: item.value,
          type: item.type,
          of: item.type === "$" ? null : item.of,
          to: item.to,
          acctCode: item.acctCode,
        });
      }
    });
    companyDollarArr.map((item) => {
      if ((item.value && item.to && item.type && item.of && item.acctCode) || (item.type === "$" && item.value && item.to && item.acctCode)) {
        companyDollarSection.push({
          value: item.value,
          type: item.type,
          of: item.type === "$" ? null : item.of,
          to: item.to,
          acctCode: item.acctCode,
        });
      }
    });
    const formData = {
      name: planName,
      agentPer: agentPer ? agentPer : 0,
      grossCommissionSection: grossCommissionSection,
      agentSplitSection: agentSplitSection,
      companyDollarSection: companyDollarSection,
      id: id,
    };
    if (id) {
      dispatch(updateCommissionPlans(formData)).then((result) => {
        closeModal();
        dispatch(getCommissionPlans({ pageNumber: 1, pageSize: 25, reset: true }));
      });
    } else {
      dispatch(addCommissionPlans(formData)).then((result) => {
        dispatch(getCommissionPlans({ pageNumber: 1, pageSize: 25, reset: true }));
        closeModal();
      });
    }
  };

  return (
    <Dialog
      disablePortal
      sx={{ position: "absolute","& .MuiDialog-container": { mt: "30px" } }}
      loading={actionLoading}
      dialogHead={<DialogHead type={id ? "update" : "create"} />}
      onSave={handleSubmit}
      dialogBody={
        <DialogBody
          isLoading={isLoading}
          actionLoading={actionLoading}
          deductions={deductions}
          netCalc={netCalc}
          usersList={usersList}
          planName={planName}
          setPlanName={setPlanName}
          agentPer={agentPer}
          setAgentPer={setAgentPer}
          companyDollarArr={companyDollarArr}
          setCompanyDollarArr={setCompanyDollarArr}
          grossCommissionArr={grossCommissionArr}
          setGrossCommissionArr={setGrossCommissionArr}
          agentSplitArr={agentSplitArr}
          setAgentSplitArr={setAgentSplitArr}
          id={id}
          classes={classes}
          grossCalc={grossCalc}
        />
      }
      dialogFooter={<DialogFooter />}
      state={state}
      closeModal={closeModal}
      maxWidth="lg"
      fullWidth={true}
      type={id ? "update" : "create"}
      // onDelete={deleteAppointmentFnc}
    />
  );
};

const DialogHead = ({ type }) => (
  <DialogTitle>
    <Typography component="h4" variant="h4">
      {type === "update" ? "Update Plan" : "Create Plan"}
    </Typography>
  </DialogTitle>
);

const DialogBody = (props) => (
  <DialogContent>
    <CommissionPlanBody {...props} />
    <br />
  </DialogContent>
);

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);

const CommissionPlanBody = ({
  isLoading,
  actionLoading,
  deductions,
  netCalc,
  usersList,
  planName,
  setPlanName,
  agentPer,
  setAgentPer,
  companyDollarArr,
  setCompanyDollarArr,
  grossCommissionArr,
  setGrossCommissionArr,
  agentSplitArr,
  setAgentSplitArr,
  id,
  classes,
  grossCalc,
}) => {
  // Components
  const generateGridTitle = (title, extraLabel = false, rowData, setRowData) => {
    const changehandler = (key, val, i) => {
      let newArr = [...rowData],
        obj = {
          ...rowData[i],
          [key]: val,
        };
      newArr[i] = obj;
      setRowData(newArr);
    };
    return (
      <Box className={classes.planFormGroup}>
        <Box>
          {extraLabel && (
            <TextField
              id="plan-label"
              type={"number"}
              label="Value"
              value={agentPer}
              sx={{ width: "100px", div: { width: "100%" } }}
              onChange={(e) => setAgentPer(e.target.value)}
            />
          )}
          <FormLabel className={classes.planFormLabel}>{title}</FormLabel>
        </Box>
        {rowData.map((r, i) => {
          return (
            <Box component="form" key={i}>
              {/* <Typography variant="h3">Gross Commission Deductions</Typography> */}
              <TextField
                id="plan-label"
                type={"number"}
                label="Value"
                sx={{ width: "77px", div: { width: "100%" } }}
                value={r.value}
                onChange={(e) => changehandler("value", e.target.value, i)}
              />
              <TextField
                id="plan-percentage"
                select
                label=""
                sx={{ width: "7.5%", div: { width: "100%" } }}
                value={r.type}
                onChange={(e) => changehandler("type", e.target.value, i)}
              >
                {["%", "$"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              {r.type === "%" && (
                <TextField
                  id="plan-percentage"
                  select
                  label="of"
                  sx={{ width: "24.5%", div: { width: "100%" } }}
                  value={r.of}
                  onChange={(e) => changehandler("of", e.target.value, i)}
                >
                  {[
                    { value: "gci", label: "GCI" },
                    { value: "contractPrice", label: "Contract Price" },
                  ].map((pln, i) => {
                    return (
                      <MenuItem key={i} value={pln.value}>
                        {pln.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}

              <TextField
                sx={{ width: "25%", div: { width: "100%" } }}
                id="plan-percentage"
                select
                label="To"
                value={r.to}
                onChange={(e) => changehandler("to", e.target.value, i)}
              >
                {usersList.map((usr, i) => {
                  return (
                    <MenuItem key={i} value={usr.id}>
                      {usr.name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                sx={{ width: "21.5%", div: { width: "100%" } }}
                id="plan-percentage"
                select
                label="Code"
                value={r.acctCode}
                onChange={(e) => changehandler("acctCode", e.target.value, i)}
              >
                {[
                  { value: "referralFee", label: "Referral Fee" },
                  { value: "mlsFee", label: "Mls Fee" },
                  { value: "teamLeaderComm", label: "Team Leader Comm" },
                  { value: "other", label: "Other" },
                ].map((pln, i) => {
                  return (
                    <MenuItem key={i} value={pln.value}>
                      {pln.label}
                    </MenuItem>
                  );
                })}
              </TextField>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  if (i === 0 && rowData.length === 1) {
                    setRowData([defaultRow]);
                  } else {
                    console.log("acdscs", i, rowData);
                    let newData = [...rowData];
                    newData.splice(i, 1);
                    setRowData([...newData]);
                  }
                }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          );
        })}

        <Button
          variant="contained"
          className="add-deduc"
          sx={{
            backgroundColor: "black !important",
            color: "white !important",
            transition: "0.5s all ease-in-out",
          }}
          onClick={(e) => {
            setRowData([...rowData, defaultRow]);
          }}
        >
          Add Deduction
        </Button>
      </Box>
    );
  };

  const generateGridCalc = (title, key) => {
    return (
      <>
        <Typography variant="h6">
          <strong>{title}: </strong>
          {grossCalc[key]
            .toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
            .slice(0, -3)}
        </Typography>
        {deductions[key].map((r, i) => {
          return (
            <Typography variant="h6" className="has-a-line" key={i}>
              {r
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .slice(0, -3)}
            </Typography>
          );
        })}

        <Typography variant="h6">
          <strong>
            Net {title}:{" "}
            {netCalc[key]
              .toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
              .slice(0, -3)}
          </strong>
        </Typography>
      </>
    );
  };
  return (
    <Box className={classes.createCommissionPlan + " " + classes.commissionPlans}>
      {isLoading ? (
        <Box className={classes.planFormColumn + " " + classes.boxShadowStyle}>
          <CircularProgress size={"40px"} sx={{ margin: "auto" }} />
        </Box>
      ) : (
        <Box className={classes.planFormColumn + " " + classes.boxShadowStyle}>
          <Box sx={{ textAlign: "center", marginBottom: "30px", marginTop: "20px" }}>
            <TextField
              id="comm-plan-name"
              label="Commission Plan Name"
              variant="standard"
              className={classes.commissionPlanName}
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
            />
          </Box>
          {generateGridTitle("Gross Commission Deductions", false, grossCommissionArr, setGrossCommissionArr)}
          {generateGridTitle("Gross split to Agent, less deductions:", true, agentSplitArr, setAgentSplitArr)}
          {generateGridTitle(
            `${agentPer ? parseInt(100 - parseFloat(agentPer)) : 100}% Company Dollar`,
            false,
            companyDollarArr,
            setCompanyDollarArr
          )}
          {/* <Box className={classes.commissionButtons}>
            {actionLoading ? (
              <CircularProgress size={"20px"} />
            ) : (
              <Box className={classes.customButtonGroup}>
                <Button
                  variant="text"
                  onClick={(e) => {
                    navigate("/finance/commission-plans");
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  Approve
                </Button>
              </Box>
            )}
          </Box> */}
        </Box>
      )}

      <Grid container className={classes.planFormColumn + " " + classes.boxShadowStyle + " " + classes.createCommissionRight}>
        <Grid item xs={0.8}>
          <Typography variant="h4">Example Commission</Typography>
          <Typography variant="h5">$10,000 GCI / $300k Vol</Typography>
        </Grid>
        <Grid item xs={1.9} className="alignStart">
          {generateGridCalc("Gross Commission", "gci")}
        </Grid>
        <Grid item xs={1.9} className="alignStart">
          {generateGridCalc("Agent Gross", "agent")}
        </Grid>
        <Grid item xs={1.9} className="alignStart">
          {generateGridCalc("Company Dollar", "company")}
        </Grid>
      </Grid>
    </Box>
  );
};
