import { withStyles } from "@mui/styles";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Grid, IconButton, Typography, Skeleton, Stack } from "@mui/material";
import { styleSheet } from "./style";

const Pagination = ({ next, prev, data, currentIndex,statsLoading }) => {
  console.log(currentIndex);
  if (currentIndex) {
    return (
      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={1}>
          <IconButton onClick={prev} aria-label="next">
            <ArrowBackIosIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" component="p">
              {currentIndex} of people {data}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={next} aria-label="prev">
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  } else {
    return (
      statsLoading? <Stack direction={"row"} justifyContent="space-between" spacing={2}>
       <Skeleton animation="wave" height={50} width="20px" />
       <Skeleton animation="wave" height={50} width="160px" />
       <Skeleton animation="wave" height={50} width="20px" />
      </Stack>:null
    );
  }
};
export default withStyles(styleSheet, { name: "paginationStyle" })(Pagination);
