import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const setInfoAgentRecruitment = createAsyncThunk(
  "agentRecruitment/setInfoAgentRecruitment",
  async (data, thunkAPI) => {
    const { agentId, schema,handleSuccess,handleError } = data;
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/${agentId}`,
        schema,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if(handleSuccess){
        handleSuccess()
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        if(handleError){
          handleError(error.response.data.message)
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if(handleError){
          handleError(error.message)
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
