import React from "react";
import { Box, Tabs, Tab, Card } from "@mui/material";
import { TabContext } from "@mui/lab";
import { Link, useLocation } from "react-router-dom";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useParams } from "react-router-dom";

function SubHeader(props) {
  let { classes } = props;
  let { id } = useParams();
  const location = useLocation();
  return (
    <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
      {" "}
      <Box className={classes.TopTemplatesBarArea}>
        <Box className={classes.TopTemplatesBarAreaLeft}>
          {" "}
          <Box>
            {" "}
            <TabContext>
              <Box className={classes.TemplatesTabsUI}>
                <Tabs
                  value={`${location.pathname}`}
                  TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                  variant="scrollable"
                  aria-label="Templates-tabs"
                  sx={{ justifyContent: "space-around" }}
                >
                  <Tab
                    label="Overview"
                    value={`/lead_routing`}
                    to={`/lead_routing`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                    selected
                  />
                  <Tab
                    label="Groups"
                    value={`/lead_routing/groups`}
                    to={`/lead_routing/groups`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                  />
                </Tabs>
              </Box>
            </TabContext>{" "}
          </Box>{" "}
        </Box>
      </Box>
    </Card>
  );
}
export default withStyles(styleSheet, { name: "SubHeaderListingStyles" })(SubHeader);
