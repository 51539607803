import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'

import { DEFAULT_CONVERTER, converters } from '../transformers'
import PickerDialog from './PickerDialog'
import { Box, InputAdornment } from '@mui/material'

const ColorPicker = ({
  // ColorPicker
  onChange,
  convert,

  // Text field
  name,
  id,
  hintText,
  label,
  value,

  ...custom
}) => {
  
  const [showPicker, setShowPicker] = React.useState(false);
  const [internalValue, setValue] = React.useState(false);

  const color = {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
    background: value || '#ddd',
    cursor: 'pointer'
  };

  return (
    <>
      <TextField
        name={name}
        id={id}
        value={value}
        label={label}
        onClick={() => setShowPicker(prevShowPicker => !prevShowPicker)}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        InputProps={{
          startAdornment:
            <InputAdornment position="start">
              <div style={ color } />
            </InputAdornment>
          ,
        }}
        {...custom}
      />
      {showPicker && (
        <PickerDialog
          value={value === undefined ? internalValue : value}
          onClick={() => setShowPicker(prevShowPicker => !prevShowPicker)}
          onChange={c => {
            const newValue = converters[convert](c);
            onChange(newValue);
          }}
        />
      )}
    </>
  );
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  convert: PropTypes.oneOf(Object.keys(converters)),
  name: PropTypes.string,
  id: PropTypes.string,
  hintText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  floatingLabelText: PropTypes.string,
  TextFieldProps: PropTypes.shape(TextField.propTypes),
  showPicker: PropTypes.bool,
  setShowPicker: PropTypes.func,
  internalValue: PropTypes.string,
  setValue: PropTypes.func
}

ColorPicker.defaultProps = {
  convert: DEFAULT_CONVERTER
}

export default ColorPicker
