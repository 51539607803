export const styleSheet = () => ({
  pageRoot: {
    marginTop: "70px !important",
    minHeight:"100vh"
  },
  loadingArea:{
    height:"80vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  }
});
