export const styleSheet = () => ({
  TopTransactionsBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopTransactionsBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  TopTransactionsBarAreaRight: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  loadingArea: {
    height: "80vh",
    width:'100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  TransactionsTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  addTransactionButton: {
    background: "#0B0909 !important",
    fontWeight: "400  !important",
    fontSize: "12.5px !important",
    color: "white",
    height: "34px !important",
    marginRight: "15px !important",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
  },
  uploadTransactionButton:{
    fontWeight: "500  !important",
    fontSize: "12.5px !important",
    color: "white",
    height: "34px !important",
    marginRight: "15px !important",
     borderRadius: "4px  !important",
     border: "1px solid rgba(33, 33, 33, 0.4) !important"
  },
  transactionsStatsArea: {
    marginTop: "35px",
    minHeight:"100vh"
  },
  transactionCardDetail: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  transactionCardHeader: {
    fontSize: "30px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  TransactionMenuIcon: {
    minWidth: "40px  !important",
    marginLeft: "10px  !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  dropzoneArea:{
    position:"absolute",
    top:"-85px",
    left:"0px",
    border:"3px dotted black",
    background:"rgba(0,0,0,.4)",
    width:"100%",
    height:'135%',
    borderRadius:"8px",
    zIndex:100000000,
    display:"none",
    justifyContent:"center",
    alignItems:"center"
  },
  dropzoneAreaInner:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontSize:"22px",
    color:"white",
    fontFamily: "AvenirNext",
    fontWeight:"600"
  }
});
