import { createSlice } from "@reduxjs/toolkit";
import { storeAgentAchievements } from "./storeAchievements";
import { getAgentAchievements } from "./getAchievements";
import { deleteAchievement } from "./deleteAchievement";
import { updateAchievement } from "./updateAchievement";

const initialState = {
  addAchievement: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  achievementsData: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  deleteAchieve: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  updateAchieve: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
};
const AgentAchievement = createSlice({
  name: "AgentAchievement",
  initialState,
  extraReducers: {
    [storeAgentAchievements.pending]: (state) => {
      state.addAchievement.isLoading = true;
      state.addAchievement.errMsg = null;
      state.addAchievement.success = false;
    },
    [storeAgentAchievements.fulfilled]: (state, action) => {
      state.addAchievement.isLoading = false;
      state.addAchievement.data = action.payload;
    },
    [storeAgentAchievements.rejected]: (state, action) => {
      state.addAchievement.isLoading = false;
      state.addAchievement.success = false;
      state.addAchievement.errMsg = action.payload;
    },
    [getAgentAchievements.pending]: (state) => {
      state.achievementsData.isLoading = true;
      state.achievementsData.errMsg = null;
      state.achievementsData.success = false;
    },
    [getAgentAchievements.fulfilled]: (state, action) => {
      state.achievementsData.isLoading = false;
      state.achievementsData.success = true;
      state.achievementsData.data = action.payload;
    },
    [getAgentAchievements.rejected]: (state, action) => {
      state.achievementsData.isLoading = false;
      state.achievementsData.success = false;
      state.achievementsData.errMsg = action.payload;
    },
    // delete agent tab
    [deleteAchievement.pending]: (state) => {
      state.deleteAchieve.isLoading = true;
      state.deleteAchieve.errMsg = null;
      state.deleteAchieve.success = false;
    },
    [deleteAchievement.fulfilled]: (state, action) => {
      if (action.payload?.addExpenseRecord) {
        let usersTabs = [...state.tabsData.data.usersTabs];
        usersTabs = usersTabs.filter(
          (item) => item.id !== action.payload.deletedId
        );
        if (
          action.payload?.addExpenseRecord?.dataValues &&
          action.payload?.addExpenseRecord?.attachments
        ) {
          usersTabs.unshift({
            ...action.payload?.addExpenseRecored?.dataValues,
            attachments: action.payload?.addExpenseRecord?.attachments,
          });
        } else {
          usersTabs.unshift(action.payload?.addExpenseRecord);
        }
        state.tabsData.data.usersTabs = usersTabs;
        state.deleteAchieve.success = "expense";
      }
      state.deleteAchieve.isLoading = false;
      state.deleteAchieve.data = action.payload;
      if (action.payload.found?.balance) {
        state.tabsData.data.user.balance = action.payload.found?.balance;
      }
      if (action.payload.found?.marketing_balance) {
        state.tabsData.data.user.marketing_balance =
          action.payload.found?.marketing_balance;
      }
    },
    [deleteAchievement.rejected]: (state, action) => {
      state.deleteAchieve.isLoading = false;
      state.deleteAchieve.success = false;
      state.deleteAchieve.errMsg = action.payload;
    },
    // update agent tab
    [updateAchievement.pending]: (state) => {
      state.updateAchieve.isLoading = true;
      state.updateAchieve.errMsg = null;
      state.updateAchieve.success = false;
    },
    [updateAchievement.fulfilled]: (state, action) => {
      state.updateAchieve.isLoading = false;
      state.updateAchieve.data = action.payload;
    },
    [updateAchievement.rejected]: (state, action) => {
      state.updateAchieve.isLoading = false;
      state.updateAchieve.success = false;
      state.updateAchieve.errMsg = action.payload;
    },
  },
});
export default AgentAchievement.reducer;
export * from "./getAchievements";
export * from "./storeAchievements";
export * from "./deleteAchievement";
export * from "./updateAchievement";
