import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { addTransactionDocument } from "./addTransactionDocument";

export const updateTransactionContract = createAsyncThunk(
  "TransactionsContractUpdate/updateTransactionContract",
  async (data, thunkAPI) => {
    try {
      const { id, contractData, requireAmendment, documentName } = data;

      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}api/transactions/contracts/${id}`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(contractData),
      };
      const resp = await axios(config);
  
      setTimeout(() => {
        if (requireAmendment) {
          const data = new FormData();
          data.append("transactionid", contractData.id);
          data.append("type", "listing");
          data.append("from", "transaction_contracts");
          data.append("is_rental", contractData?.contract_type==="landlord"?true:false);
          data.append("address", contractData.property_address || '');
          data.append("comment", contractData.comment || '');
          data.append("name", documentName);
          thunkAPI.dispatch(addTransactionDocument(data));
        }
      }, 5000);

      return resp.data.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

