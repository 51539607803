import React, { useEffect, useState, Fragment } from "react";
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
  InputLabel,
} from "@mui/material";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import { addAgentRecruitmentTasks } from "../../../../redux/agents/agentRecruitment/addAgentRecruitmentTasks";
import { useDispatch, useSelector } from "react-redux";
import { deleteAgentRecruitmentTask } from "../../../../redux/agents/agentRecruitment/deleteAgentRecruitmentTask";
import { updateAgentRecruitmentTask } from "../../../../redux/agents/agentRecruitment/updateAgentRecruitmentTask";
import { dateFormat } from "../../../../utils/dateFormat";
import { stringAvatar } from "../../../../utils/avatarInitial";
import ResponseAlert from "../../../../components/responseAlert";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const AddTasksModal = ({
  closeModal,
  usersList,
  state,
  id,
  initialValue,
  type,
  ...props
}) => {
  const [newTask, setNewTask] = useState({
    name: "",
    type: "",
    assignedUserId: "",
    AssignedTo: "",
    dueDate: new Date(),
    dueDateTime: new Date(),
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (item) => item.agentProfile.agentProfile.modalActions
  );

  const setNewTaskFunc = (param) => {
    setNewTask((prev) => [...prev, param]);
  };

  useEffect(() => {
    if (type === "Update" && state) {
      setNewTask(initialValue);
    }
  }, [state]);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: `You have successfully ${
        type === "Update" ? "updated" : "created"
      } the task`,
      errorType: "success",
      isOpen: true,
    });
    closeModal();
    setNewTask({
      name: "",
      type: "",
      assignedUserId: "",
      AssignedTo: "",
      dueDate: new Date(),
      dueDateTime: new Date(),
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const saveTasks = () => {
    let obj = {
      schema: { ...newTask, agentRecruitmentId: id },
      handleSuccess,
      handleError,
    };
    if (type === "Update") {
      dispatch(updateAgentRecruitmentTask(obj));
    } else {
      dispatch(addAgentRecruitmentTasks(obj));
    }
  };

  const handleSuccessDelete = () => {
    setErrorAlert({
      errorMsg: `You have successfully deleted the task`,
      errorType: "success",
      isOpen: true,
    });
    closeModal();
    setNewTask({
      name: "",
      type: "",
      assignedUserId: "",
      AssignedTo: "",
      dueDate: new Date(),
      dueDateTime: new Date(),
    });
  };
  const deleteTaskFnc = () => {
    if (type === "Update") {
      const { id } = newTask;
      console.log(newTask, "newTask", id, "id");
      dispatch(deleteAgentRecruitmentTask({ id, handleSuccessDelete }));
    }
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        dialogHead={<DialogHead type={type} />}
        loading={loading}
        onSave={saveTasks}
        onDelete={deleteTaskFnc}
        dialogBody={
          <DialogBody
            newTask={newTask}
            setNewTaskFunc={setNewTaskFunc}
            setNewTask={setNewTask}
            usersList={usersList}
          />
        }
        dialogFooter={<DialogFooter />}
        state={state}
        closeModal={() => {
          closeModal();
          setNewTask({
            name: "",
            type: "",
            assignedUserId: "",
            AssignedTo: "",
            dueDate: new Date(),
            dueDateTime: new Date(),
          });
        }}
        maxWidth="sm"
        type={type}
        buttonWidth="100px"
        fullWidth={true}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
};

// Components
const AddTaskForm = ({ mb, newTask, usersList, setNewTask }) => {
  const changeDateFn = (newValue) => {
    const newData = { ...newTask };
    newData.dueDate = newValue;
    newData.dueDateTime = newValue;
    setNewTask(newData);
  };

  const changeNameFunc = (event) => {
    const newData = { ...newTask };
    newData.name = event.target.value;
    setNewTask(newData);
  };
  const changeFollowUPFunc = (value) => {
    const newData = { ...newTask };
    newData.type = value;
    setNewTask(newData);
  };
  const changeUserFunc = (event) => {
    const newData = { ...newTask };
    newData.assignedUserId = event.target.value;
    const user = usersList.filter((el) => el.id == event.target.value);
    newData.AssignedTo = user[0].full_name || "";
    setNewTask(newData);
  };

  return (
    <>
      <Box sx={{ display: "block", marginBottom: "10px" }}></Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ flexGrow: 1, marginBottom: mb || 0 }}
      >
        <Grid item sm={12}>
          <Input
            onChange={(e) => changeNameFunc(e)}
            value={newTask.name}
            placeholder="Title"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ flexGrow: 1, marginBottom: mb || 0 }}
      >
        <Grid item sm={4}>
          <Box sx={{ mt: 3 }}>
            <InputLabel sx={{ color: "rgba(0, 0, 0, 0.87)" }}>Type</InputLabel>
            <ButtonGroups
              id="appointment-type-menu"
              bgColor="#0B0909"
              bgColorHover="#0B0909"
              placement={"bottom"}
              height="38.5px"
              minWidth="140px"
              size="medium"
              color="white"
              fontSize="13px"
              options={[
                { title: "Follow Up", value: "Follow Up" },
                { title: "Call", value: "call" },
                { title: "Email", value: "email" },
              ]}
              value={newTask.type}
              onChangeMenu={(value) => changeFollowUPFunc(value)}
              placeholder="Task Type"
            />
          </Box>
        </Grid>
        <Grid item sm={5}>
          <Box sx={{ mt: 3, ml: "20px" }}>
            <InputLabel sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
              Assigned to
            </InputLabel>
            <Select
              value={newTask.assignedUserId}
              onChange={changeUserFunc}
              displayEmpty
              size="small"
              sx={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                border: "none",
              }}
              fullWidth
            >
              <MenuItem value="" disabled>
                Select{" "}
              </MenuItem>
              {usersList &&
                usersList.length &&
                usersList.map((user, i) => {
                  return (
                    <MenuItem value={user.id} key={i}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "23px",
                            height: "23px",
                            fontSize: "13px",
                          }}
                          {...stringAvatar(
                            user?.full_name,
                            user?.profile_images?.profile_img
                          )}
                        ></Avatar>
                        <Typography component="subtitle" variant="subtitle">
                          {user.full_name}
                        </Typography>
                      </Box>{" "}
                    </MenuItem>
                  );
                })}
            </Select>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ flexGrow: 1, mt: 4 }}
      >
        <Grid item sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              minutesStep={15}
              fullWidth
              value={dateFormat(newTask?.dueDate)}
              onChange={changeDateFn}
              label={
                <Typography
                  sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                  component="p"
                  variant="subtitle1"
                >
                  Due by
                </Typography>
              }
              renderInput={(params) => (
                <Input size="small" fullWidth {...params} error={false} />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
};

const DialogHead = ({ type }) => (
  <DialogTitle>
    <Typography
      sx={{ fontSize: "24px", fontFamily: "AvenirNext-400", mt: "10px" }}
      component="h5"
      variant="h5"
    >
      {type === "Update" ? "Update Task" : "Create Task"}
    </Typography>
  </DialogTitle>
);

const DialogBody = ({ newTask, setNewTask, usersList }) => (
  <DialogContent>
    <AddTaskForm
      setNewTask={setNewTask}
      newTask={newTask}
      usersList={usersList}
    />
    <br />
  </DialogContent>
);

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);
