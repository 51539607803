
export const styleSheet = () => ({
  SubHeaderBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  SubHeaderBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  "@media (max-width: 1270px)": {
    TransactionsTabsUI: {
      maxWidth: "420px",
    },
  },
  "@media (max-width: 1050px)": {
    TransactionsTabsUI: {
      maxWidth: "300px",
    },
  },
  TransactionsTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  tableRoot: {
    height: "calc(100vh - 160px)",
    width: "100%",
    backgroundColor: "white",
    position: "relative",
  },
  openHouseListArea: {
    marginTop: "30px",
    minHeight: "",
  },
  openHouseListAreaTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  openHouseListHeading: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textTransform:"capitalize"
  },
  tableRootFooterItem1: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  openHouseTopStat: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "230px",
    backgroundColor: "#e7e7e7 !important",
    borderRadius: "6px  !important",
    padding: "5.5px 12px  !important",
  },
  detailCardSection: {
    paddingTop: '10px', 
    justifyContent: "center !important",
    display: "flex !important",
    width: "100% !important",
    marginBottom: "30px!important",
  },
  detailCard: {
      display: "flex!important",
      justifyContent: "unset!important",
      alignItems: "center!important",
      padding: '0px 19px!important',
      gap: '10px !important',
      height: "100px!important",
      marginRight:'20px',
      width: "240px!important",
  },
  title: {
    fontSize: "30px!important",
    fontWeight: "400 !important",
  },
  desc: {
    fontSize: "14px!important",
    fontWeight: "400 !important",
    color: "rgba(0, 0, 0, 0.6)!important",
    lineHeight: "143%!important",
  },
  avatar: {
    background: "#BDBDBD!important",
    color: "#fff!important",
    marginRight: "10px!important",
  },
  container: {
    width: '100%', 
    height: '100vh', 
  },
  editProjectButton: {
    color: "#0B0909!important",
  },
  cancelProjectButton: {
    color: "#0B0909!important",
    "&:hover": {
      color: "red !important"
    }
  },
});

