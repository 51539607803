import { createSlice } from "@reduxjs/toolkit";
import { getTextHistory } from "./getTextHistory";
import { getInboxLeads } from "./getInboxLeads";

const initialState = {
  chatList: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  leadsList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const LeadsInbox = createSlice({
  name: "LeadsInbox",
  initialState,
  reducers: {
    saveNewSms: (state, action) => {
      let message = state.chatList.data?.textMessages?.rows
        ? [...state.chatList.data?.textMessages?.rows]
        : [];
      message.push(action.payload);
      if (state.chatList.data?.textMessages?.rows) {
        state.chatList.data.textMessages.rows = message;
      }
      let newChatUsers = [...state.leadsList.data.chatUsers];
      let index = newChatUsers.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index > -1) {
        newChatUsers[index].message = action.payload.message;
        newChatUsers[index].created = action.payload.sent;
        let chatUser = newChatUsers[index];
        newChatUsers.splice(index, 1);
        newChatUsers.unshift(chatUser);
        state.leadsList.data.chatUsers = newChatUsers;
      }
    },
    ArchiveChat: (state, action) => {
      let newChatUsers = [...state.leadsList.data.chatUsers];
      let index = newChatUsers.findIndex(
        (item) => item.id === action.payload.id
      );
      newChatUsers[index].archived = true;
      let chatUser = newChatUsers[index];
      newChatUsers.splice(index, 1);
      newChatUsers.unshift(chatUser);
      state.leadsList.data.chatUsers = newChatUsers;
    },
    updateAssignedTo: (state, action) => {
      let newChatUsers = [...state.leadsList.data.chatUsers];
      let index = newChatUsers.findIndex(
        (item) => item.id === action.payload.id
      );
      if (action.payload.type === "agent") {
        newChatUsers[index].realtor_agent.assignedTo =
          action.payload.assignedTo;
        state.leadsList.data.chatUsers = newChatUsers;
      }
      if (action.payload.type === "lead") {
        newChatUsers[index].lead.assignedTo = action.payload.assignedTo;
        state.leadsList.data.chatUsers = newChatUsers;
      }
    },
    updateStage: (state, action) => {
      let newChatUsers = [...state.leadsList.data.chatUsers];
      let index = newChatUsers.findIndex(
        (item) => item.id === action.payload.id
      );
      if (action.payload.type === "agent") {
        newChatUsers[index].realtor_agent.stageId = action.payload.stageId;
        state.leadsList.data.chatUsers = newChatUsers;
      }
      if (action.payload.type === "lead") {
        newChatUsers[index].lead.stageId = action.payload.stageId;
        state.leadsList.data.chatUsers = newChatUsers;
      }
    },
  },
  extraReducers: {
    [getTextHistory.pending]: (state) => {
      state.chatList.isLoading = true;
      state.chatList.errMsg = null;
      state.chatList.success = false;
    },
    [getTextHistory.fulfilled]: (state, action) => {
      state.chatList.success = true;
      state.chatList.isLoading = false;
      state.chatList.data = {
        ...action.payload,
        rows: action.payload?.textMessages?.rows?.reverse(),
      };
    },
    [getTextHistory.rejected]: (state, action) => {
      state.chatList.isLoading = false;
      state.chatList.success = false;
      state.chatList.errMsg = action.payload;
    },
    [getInboxLeads.pending]: (state) => {
      state.leadsList.isLoading = true;
      state.leadsList.errMsg = null;
      state.leadsList.success = false;
    },
    [getInboxLeads.fulfilled]: (state, action) => {
      state.leadsList.success = true;
      state.leadsList.isLoading = false;
      state.leadsList.data = action.payload;
    },
    [getInboxLeads.rejected]: (state, action) => {
      state.leadsList.isLoading = false;
      state.leadsList.success = false;
      state.leadsList.errMsg = action.payload;
    },
  },
});
export const { saveNewSms, ArchiveChat, updateStage, updateAssignedTo } =
  LeadsInbox.actions;
export default LeadsInbox.reducer;
export * from "./getTextHistory";
export * from "./getInboxLeads";
