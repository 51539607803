export const styleSheet = (theme) => ({
  TransactionSalesRoot: {
    marginBottom: "16px",
    padding: "5px",
  },
  pageHeading: {
    fontSize: "26px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  loadingArea: {
    height: "50vh  !important",
    minWidth: "100%  !important",
    display: "flex  !important",
    alignItems: "center  !important",
    justifyContent: "center  !important",
  },
  documentTableButton: {
    fontSize: "12px  !important",
    borderRadius: "9px  !important",
    minWidth: "80px !important",
  },
  templateSelectionArea: {
    padding: "15px !important",
    marginBottom: "15px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "15px !important",
    "& .MuiInputBase-root": { height: "35px", minWidth: "120px" },
  },
  confirmButton: {
    backgroundColor: "black !important",
    color: "white !important",
    minWidth: "74px !important",
    fontSize: "12px !important",
  },
  dateArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "8px !important",
  },
  completedTaskButton: {
    color: "#81B987 !important",
    fontSize: "14px !important",
    textTransform: "capitalize !important",
    borderColor: "#81B987 !important",
    fontWeight: "400 !important",
    borderRadius: "8px !important",
  },
  pendingTaskButton: {
    fontSize: "14px !important",
    textTransform: "capitalize !important",
  },
  tableSection: {
    height: "800px",
    overflow: "auto",
    "&::-webkit-scrollbar-thumb": {
      background: "#A5A5A5",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
  },
  attachmentCardSection: {
    marginTop: "10px !important",
  },
  attachmentHeading: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    textTransform: "uppercase !important",
  },
  actionButtons: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  actionButtonItem: {
    minWidth: "36px !important",
    padding: "3px 6px !important",
    color: "rgba(0, 0, 0, 0.50) !important",
    borderColor: "rgba(0, 0, 0, 0.50) !important",
  },
  uploadAttachmentArea: {
    height: "144px !important",
    width: "100% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    position: "relative !important",
  },
  uploadAttachmentArea01: {
    height: "90% !important",
    width: "100% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  uploadAttachmentButton: {
    backgroundColor: "#E7E7E7 !important",
    borderRadius: "8px !important",
    textTransform: "capitalize !important",
    color: "black !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
    marginTop: "-12px !important",
    minWidth: "200px !important",
  },
  commentContentText: {
    fontSize: "12.5px !important",
    wordBreak: "break-word  !important",
    display: "-webkit-box !important",
    "-webkit-line-clamp": "2 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  dropzoneAreaInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18.8px",
    color: "black",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "11.5px",
    left: "0px",
    background: "rgba(255,255,255,.6)",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  taskImg: {
    width: "100px",
    height: "130px",
    border: "1px solid #000",
  },
  TaskCheckbox: {
    position: "absolute !important",
    top: "-2% !important",
    right: "-2% !important",
    color: "#7E7E7E !important",
  },
  deliverableFileName: {
    position: "absolute!important",
    bottom: "8px!important",
    color: "#fff!important",
    background: "#0B0909",
    padding: "2px 5px",
    fontSize: "14px",
    width: "98%",
    left: "1px",
  },
  tasksArea: {
    padding: "5px 20px !important",
    display: "flex  !important",
    width: "100%  !important",
    flexDirection: "row  !important",
    flexWrap: "wrap  !important",
    gap: "10px  !important",
  },
  latestCommentSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  latestCommentSectionLeft: {
    backgroundColor: "#f4f4f4 !important",
    padding: "8px !important",
    borderRadius: "8px",
    maxWidth: "270px !important",
  },
});
