export const styleSheet = () => ({
  TopTransactionsBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopTransactionsBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  loadingArea: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width: 1600px)": {
    TransactionsTabsUI: {
      maxWidth: "500px",
    },
  },
  "@media (max-width: 1286px)": {
    TransactionsTabsUI: {
      maxWidth: "260px",
    },
  },

  TransactionsTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  addTransactionButton: {
    background: "#0B0909 !important",
    fontWeight: "400  !important",
    fontSize: "12.5px !important",
    color: "white",
    height: "34px !important",
    marginRight: "15px !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
  },
  transactionsStatsArea: {
    marginTop: "35px",
  },
  transactionCardDetail: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  transactionCardHeader: {
    fontSize: "26px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
});
