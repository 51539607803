import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const updateContactNote = createAsyncThunk("TransactionsContract/updateContactNote", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/transactions/contracts/notes/${data.noteId}`, data.noteDetail, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (data?.handleSuccess) {
      data.handleSuccess("update");
    }
    return {...resp.data,noteId:data.noteId};
  } catch (error) {
    if (error.response) {
      data.handleError(error.response.data.errorMessage || error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      data.handleError(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
