import { createSlice } from "@reduxjs/toolkit";
import { addInventoryItem } from "./addInventory";
import { getAllInventories } from "./getAllInvetory";
import { updateCheckoutInventory } from "./checkout";
import { updateInventoryItem } from "./updateInventory";
import { markAsLost } from "./markAsLost";
const initialState = {
  addInventory: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  InventoryList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  checkout: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  updateInventory: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  lostMark: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const InventoryList = createSlice({
  name: "InventoryList",
  initialState,
  reducers: {
    updateCheckoutState: (state, action) => {
      state.checkout = {
        success: false,
        data: undefined,
        isLoading: false,
        errMsg: null,
      };
    },
    updateAssignUser: (state, action) => {
      let inventoryItems = state.InventoryList.data?.rows
        ? [...state.InventoryList.data?.rows]
        : [];
      let index = inventoryItems.findIndex(
        (item) => item.id === action.payload.InventoryId
      );
      if (
        inventoryItems[index]["check-out"] &&
        inventoryItems[index]["check-out"]?.length
      ) {
        inventoryItems[index] = {
          ...inventoryItems[index],
          AssetStatus: "not_available",
          "check-out": [
            {
              ...inventoryItems[index]["check-out"][0],
              users_v2: action.payload.users_v2,
            },
          ],
        };
      } else {
        inventoryItems[index] = {
          ...inventoryItems[index],
          AssetStatus: "not_available",
          "check-out": [
            {
              id: action.payload.checkoutId,
              users_v2: action.payload.users_v2,
            },
          ],
        };
      }
      state.InventoryList.data.rows = inventoryItems;
    },
    updateMarkAsLost: (state, action) => {
      let inventoryItems = state.InventoryList.data?.rows
        ? [...state.InventoryList.data?.rows]
        : [];
      let index = inventoryItems.findIndex(
        (item) => item.id === action.payload.InventoryId
      );
      inventoryItems[index] = {
        ...inventoryItems[index],
        AssetStatus: "lost",
        "check-out": [
          {
            ...inventoryItems[index]["check-out"][0],
            transaction_listings_v2: null,
          },
        ],
      };
      state.InventoryList.data.rows = inventoryItems;
    },
    updateSuperWebsiteStatus: (state, action) => {
      let inventoryItems = state.InventoryList.data?.rows
        ? [...state.InventoryList.data?.rows]
        : [];
      let index = inventoryItems.findIndex(
        (item) => item.id === action.payload.id
      );
      inventoryItems[index] = {
        ...inventoryItems[index],
        "check-out": action.payload.defaultData,
      };

      state.InventoryList.data.rows = inventoryItems;
    },
    attachInventoryListing: (state, action) => {
      let inventoryItems = state.InventoryList.data?.rows
        ? [...state.InventoryList.data?.rows]
        : [];
      if (inventoryItems?.length) {
        let index = inventoryItems.findIndex(
          (item) => item.id === action.payload.InventoryId
        );
        if (
          inventoryItems[index] &&
          inventoryItems[index]["check-out"] &&
          inventoryItems[index]["check-out"]?.length
        ) {
          inventoryItems[index] = {
            ...inventoryItems[index],
            AssetStatus: "not_available",
            "check-out": [
              {
                ...inventoryItems[index]["check-out"][0],
                transaction_listings_v2: action.payload.transaction_listings_v2,
              },
            ],
          };
        } else {
          inventoryItems[index] = {
            ...inventoryItems[index],
            AssetStatus: "not_available",
            "check-out": [
              {
                id: action.payload.checkoutId,
                transaction_listings_v2: action.payload.transaction_listings_v2,
              },
            ],
          };
        }
        state.InventoryList.data.rows = inventoryItems;
      }
    },
    updateListAfterCheckIn: (state, action) => {
      let inventoryItems = state.InventoryList.data?.rows
        ? [...state.InventoryList.data?.rows]
        : [];
      let index = inventoryItems.findIndex(
        (item) => item.id === action.payload.InventoryId
      );
      if (inventoryItems[index]) {
        inventoryItems[index]["check-out"] = null;
        inventoryItems[index].AssetStatus = "available";
        state.InventoryList.data.rows = inventoryItems;
      }
    },
  },
  extraReducers: {
    [addInventoryItem.pending]: (state) => {
      state.addInventory.isLoading = true;
      state.addInventory.errMsg = null;
      state.addInventory.success = false;
    },
    [addInventoryItem.fulfilled]: (state, action) => {
      state.addInventory.success = true;
      state.addInventory.isLoading = false;
      state.addInventory.data = action.payload;
      let inventoryD = state.InventoryList.data?.rows
        ? [...state.InventoryList.data?.rows]
        : [];
      inventoryD.unshift(action.payload);
      state.InventoryList.data.rows = inventoryD;
    },
    [addInventoryItem.rejected]: (state, action) => {
      state.addInventory.isLoading = false;
      state.addInventory.success = false;
      state.addInventory.errMsg = action.payload;
    },
    [getAllInventories.pending]: (state) => {
      state.InventoryList.isLoading = true;
      state.InventoryList.errMsg = null;
      state.InventoryList.success = false;
      // if(state.InventoryList?.data?.rows){
      //   state.InventoryList.data.rows=[]
      // }
    },
    [getAllInventories.fulfilled]: (state, action) => {
      state.InventoryList.success = true;
      state.InventoryList.isLoading = false;
      if (state.InventoryList?.data?.rows && action.meta?.arg?.pageNumber > 1) {
        state.InventoryList.data.rows = [
          ...state.InventoryList.data.rows,
          ...action.payload.rows,
        ];
      } else {
        state.InventoryList.data = action.payload;
      }
    },
    [getAllInventories.rejected]: (state, action) => {
      state.InventoryList.isLoading = false;
      state.InventoryList.success = false;
      state.InventoryList.errMsg = action.payload;
    },
    // checkout inventory
    [updateCheckoutInventory.pending]: (state) => {
      state.checkout.isLoading = true;
      state.checkout.errMsg = null;
      state.checkout.success = false;
    },
    [updateCheckoutInventory.fulfilled]: (state, action) => {
      state.checkout.success = true;
      state.checkout.isLoading = false;
      state.checkout.data = action.payload;
      if (!action.payload?.detach) {
        let inventoryItems = state.InventoryList.data?.rows
          ? [...state.InventoryList.data?.rows]
          : [];
        let index = inventoryItems.findIndex(
          (item) => item.id === action.payload.InventoryId
        );
        if (inventoryItems[index] && inventoryItems[index]["check-out"]) {
          inventoryItems[index] = {
            ...inventoryItems[index],
            "check-out": {
              ...inventoryItems[index]["check-out"],
              transaction_listings_v2: action.payload.transaction_listings_v2,
            },
          };
        } else if (inventoryItems[index]) {
          inventoryItems[index] = {
            ...inventoryItems[index],
            "check-out": {
              id: action.payload.checkoutId,
              transaction_listings_v2: action.payload.transaction_listings_v2,
            },
          };
        }
        state.InventoryList.data.rows = inventoryItems;
      }
    },
    [updateCheckoutInventory.rejected]: (state, action) => {
      state.checkout.isLoading = false;
      state.checkout.success = false;
      state.checkout.errMsg = action.payload;
    },
    // update inventory
    [updateInventoryItem.pending]: (state) => {
      state.updateInventory.isLoading = true;
      state.updateInventory.errMsg = null;
      state.updateInventory.success = false;
    },
    [updateInventoryItem.fulfilled]: (state, action) => {
      state.updateInventory.success = true;
      state.updateInventory.isLoading = false;
      state.updateInventory.data = action.payload;
      let inventoryD = state.InventoryList.data?.rows
        ? [...state.InventoryList.data?.rows]
        : [];
      let index = inventoryD.findIndex((item) => item.id === action.payload.id);
      let obj = {
        ...inventoryD[index],
        ...action.payload,
      };
      inventoryD.splice(index, 1, obj);
      state.InventoryList.data.rows = inventoryD;
    },
    [updateInventoryItem.rejected]: (state, action) => {
      state.updateInventory.isLoading = false;
      state.updateInventory.success = false;
      state.updateInventory.errMsg = action.payload;
    },
    // mark as lost
    [markAsLost.pending]: (state) => {
      state.lostMark.isLoading = true;
      state.lostMark.errMsg = null;
      state.lostMark.success = false;
    },
    [markAsLost.fulfilled]: (state, action) => {
      state.lostMark.success = true;
      state.lostMark.isLoading = false;
      state.lostMark.data = action.payload.data;
    },
    [markAsLost.rejected]: (state, action) => {
      state.lostMark.isLoading = false;
      state.lostMark.success = false;
      state.lostMark.errMsg = action.payload;
    },
  },
});

export const {
  updateAssignUser,
  attachInventoryListing,
  updateCheckoutState,
  updateListAfterCheckIn,
  updateMarkAsLost,
  updateSuperWebsiteStatus,
} = InventoryList.actions;
export default InventoryList.reducer;
export * from "./addInventory";
export * from "./getAllInvetory";
export * from "./checkout";
export * from "./updateInventory";
export * from "./markAsLost";
