import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import { getData } from "../../../../utils/getData";
import Loading from "../../../../components/shared/loading";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import TransactionHeaderCard from "../../transactionsDetail/cards/TransactionHeaderCard";
import TransactionDocPanel from "../../transactionsDetail/documents/documentPanel";
import ListingDetailSkelton from "../../../listings/listingDetail/skelton";
function NeedApprovalContracts(props) {
  let { classes } = props;
  const [loading, setLoading] = useState(false);
  const [approvalData, setApprovalData] = useState([]);

  const handleGetNeedApproval = async () => {
    setLoading(true);
    const result = await getData(`${process.env.REACT_APP_BASE_URL}api/transactions/contracts/need-approval`);
    if (result.status === 200) {
      setApprovalData(result.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNeedApproval();
  }, []);

  const handleRemoveDoc = (document_id,contractIndex) => {
    let documentData = approvalData[contractIndex]?.transaction_documents ? [...approvalData[contractIndex].transaction_documents] : [];
    let index = documentData.findIndex((item) => item.document_id === document_id);
    if (index > -1) {
      documentData.splice(index, 1);
      approvalData[contractIndex].transaction_documents = documentData;
    }
  };

  return (
    <Box>
      {loading && (
        <ListingDetailSkelton  disableRemarks count={3} />
      )}
      {!loading &&
        approvalData?.length ?
        approvalData.map((item, index) => (
          <Box key={index} sx={{ mb: "50px" }}>
            <TransactionHeaderCard
              ContractData={item}
              disableMLs
              innerHeight="0px"
              outerHeight="190px"
              avatarWidth="200px"
              avatarHeight="150px"
              hideBed={true}
              hideBath={true}
              hideSqFt={true}
              showClosingDate={true}
              isAllowRedirectDocs
              {...props}
            />
            <TransactionDocPanel
              title="Documents"
              hideAdd
              handleRemoveDoc={handleRemoveDoc}
              status="submitted"
              ContractData={item}
              contractIndex={index}
              type=""
              {...props}
            />
          </Box>
        )):null}
      {!loading && approvalData?.length === 0 ? (
        <Box className={classes.loadingArea}>
          <DataNotFound
            color="#BDBDBD"
            fontSize="20px"
            title="No approval needed contract exist"
            icon={<AssignmentLateIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default withStyles(styleSheet, { name: "NeedApprovalContractsStyle" })(NeedApprovalContracts);
