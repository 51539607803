import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

export default function UserDetailSkelton(props) {
  return (
    <Box sx={{ width: "100%" }}>
      <Card variant="outlined">
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />}
          subheader={<Skeleton animation="wave" height={12} width="40%" />}
        />
      </Card>
      <Card variant="outlined" sx={{ mt: "5px" }}>
        <CardHeader
          sx={{ padding: "10px 16px" }}
          avatar={<Skeleton animation="wave" width={30} height={40} />}
          title={<Skeleton animation="wave" height={20} width="80%" />}
        />
        <CardHeader
          sx={{ padding: "10px 16px" }}
          avatar={<Skeleton animation="wave" width={30} height={40} />}
          title={<Skeleton animation="wave" height={20} width="80%" />}
        />
        <CardHeader
          sx={{ padding: "10px 16px" }}
          avatar={<Skeleton animation="wave" width={30} height={40} />}
          title={<Skeleton animation="wave" height={20} width="80%" />}
        />
      </Card>
      <Card variant="outlined" sx={{ mt: "5px" }}>
        <CardHeader
          sx={{ padding: "10px 16px" }}
          avatar={<Skeleton animation="wave" width={30} height={40} />}
          title={<Skeleton animation="wave" height={20} width="40%" />}
        />
        <CardHeader
          sx={{ padding: "10px 16px" }}
          avatar={<Skeleton animation="wave" width={30} height={40} />}
          title={<Skeleton animation="wave" height={20} width="50%" />}
        />
        <CardHeader
          sx={{ padding: "10px 16px" }}
          avatar={<Skeleton animation="wave" width={30} height={40} />}
          title={<Skeleton animation="wave" height={20} width="80%" />}
        />
        <CardHeader
          sx={{ padding: "10px 16px" }}
          avatar={<Skeleton animation="wave" width={30} height={40} />}
          title={<Skeleton animation="wave" height={20} width="80%" />}
        />
      </Card>
      <Card variant="outlined" sx={{ mt: "5px" }}>
        <CardHeader sx={{ padding: "10px 16px" }} title={<Skeleton variant="rectangular" animation="wave" height={20} width="60%" />} />
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px", flexWrap: "wrap", padding: "15px" }}>
          <Skeleton animation="wave" variant="rectangular" width={96} height={125} />
          <Skeleton animation="wave" variant="rectangular" width={96} height={125} />
          <Skeleton animation="wave" variant="rectangular" width={96} height={125} />
        </Box>
      </Card>
      <Card variant="outlined" sx={{ mt: "5px" }}>
        <CardHeader sx={{ padding: "10px 16px" }} title={<Skeleton variant="rectangular" animation="wave" height={20} width="60%" />} />
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px", flexWrap: "wrap", padding: "15px" }}>
          <Skeleton animation="wave" variant="rectangular" width={"100%"} height={80} />
        </Box>
      </Card>
    </Box>
  );
}
