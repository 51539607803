export const styleSheet = () => ({
  PhoneCallNotAllowArea: {
    display: "flex",
    justifyContent: "center !important",
    alignItems: "center",
    fontSize: "18px",
    fontFamily: "AvenirNext",
    fontWeight: "600 !important",
    color: "rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  outsideCallBox: {
    width: "110px",
    height: "80px",
    display:"flex",
    justifyContent: "center !important",
    alignItems: "center",
    border:"2px solid black",
    borderRadius:'5px',
    cursor:'pointer'
  },
  "@global": {
    ".call-panel-area": {
      color: "black",
      height: "487px",
      padding: "15px",
    },
    "@media (max-width: 575px)": {
      ".call-panel-area": {
        width: "270px",
      },
    },
    "@media (min-width: 575px)": {
      ".call-panel-area": {
        width: "314px",
      },
      ".call-panel-area .MuiButtonBase-root": {
        padding: "12px 14px",
      },
    },
    ".call-panel-area .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    ".call-panel-area .MuiTabs-indicator": {
      backgroundColor: "#0B0909 !important",
    },
    ".call-panel-area .MuiTab-textColorInherit": {
      textTransform: "capitalize !important",
      fontWeight: "400",
      color: "#0B0909",
    },
    ".recent-call-list-area-item  .recent-all-action-chip": {
      display: "block !important",
      maxWidth: "70px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      height:"20px"
    },
    ".recent-call-list-area-item:hover .recent-all-action-chip": {
      display: "none !important",
    },
    ".recent-call-list-area-item  .recent-all-action-button": {
      display: "none !important",
    },
    ".recent-call-list-area-item:hover .recent-all-action-button": {
      display: "block !important",
    },
    ".recent-call-list-area": {
      height: "400px",
      overflowY: "auto",
    },
    ".recent-call-list-area .MuiListItem-root": {
      paddingTop: "4px",
      paddingBottom: "4px",
      borderBottom: "1px solid lightgray",
    },
    ".recent-call-list-area .MuiTypography-overline": {
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "2",
      color: "rgba(0, 0, 0, 0.87)",
    },
    ".recent-call-list-area .recent-call-loading-area": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    ".recent-call-list-area .MuiListItemAvatar-root": {
      minWidth: "45px !important",
    },
    ".recent-call-list-area .MuiListItemIcon-root": {
      minWidth: "40px !important",
    },
    ".recent-call-list-area .MuiAvatar-root": {
      width: "40px !important",
      fontSize: "16px",
      height: "40px !important",
    },
    ".recent-call-list-area .call-date-area": {
      fontSize: "10px",
      textAlign: "right",
      lineHeight: "5px",
      marginTop: "5px",
    },
    ".recent-call-list-area::placeholder": {
      color: "#ccc !important",
    },
    ".recent-call-list-area::-webkit-scrollbar": {
      width: "4px",
    },
    ".recent-call-list-area::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    ".recent-call-list-area::-webkit-scrollbar-thumb": {
      background: "#0B0909",
    },
    ".recent-call-list-area::-webkit-scrollbar-thumb:hover": {
      background: "#0B0909",
    },
    ".call-contracts-list": {
      padding: "15px",
      paddingBottom: "0px",
    },
    ".call-contracts-list .call-contracts-list-box": {
      padding: "5px",
      paddingRight: "0px",
      height: "317px",
      overflowY: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
    ".call-contracts-list .call-contracts-list-box .call-contracts-list-box-item": {
      width: "63px !important",
      cursor: "pointer",
      minHeight: "95px",
      margin: "0px 15px 8px 0px",
    },
    ".call-contracts-list .call-contracts-list-box .call-contracts-list-box-item .MuiAvatar-root": {
      width: "63px !important",
      height: "60px !important",
      borderRadius: "4px",
    },
    ".call-contracts-list .call-contracts-list-box .call-contracts-list-box-item .MuiTypography-root": {
      textAlign: "left",
      fontSize: "10px",
      fontFamily: "'Roboto'",
      fontWeight: "500",
    },
    ".call-contracts-list .call-contracts-list-box::placeholder": {
      color: "#ccc !important",
    },
    ".call-contracts-list .call-contracts-list-box::-webkit-scrollbar": {
      width: "4px",
    },
    ".call-contracts-list .call-contracts-list-box::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    ".call-contracts-list .call-contracts-list-box::-webkit-scrollbar-thumb": {
      background: "#0B0909",
    },
    ".call-contracts-list .call-contracts-list-box::-webkit-scrollbar-thumb:hover": {
      background: "#0B0909",
    },
    ".live-chat_keypad-panel": {
      padding: "15px",
      paddingBottom: "0px",
    },
    ".live-chat_keypad-panel .phonenumber-input-panel": {
      padding: "6.5px 0 5px 0",
      display: "flex",
      justifyContent: "flex-start",
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
    ".live-chat_keypad-panel .phonenumber-input-panel .phone-number-input-panel-field": {
      border: "none",
      width: "100%",
      outline: "none",
      fontSize: "34px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.6)",
      textAlign: "center",
    },
    ".live-chat_keypad-panel .phonenumber-input-panel .MuiButtonBase-root": {
      height: "44px",
      width: "49px",
      marginRight: "-15px",
      backgroundColor: "#4CAF50",
    },
    ".live-call-keypad": {
      marginTop: "20px",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      flexWrap: "wrap",
    },
    ".live-call-keypad .live-call-keypad-button": {
      cursor: "pointer",
      boxSizing: "border-box",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 22px",
      width: "66px",
      height: "64px",
      border: "1px solid #000000",
      boxShadow: "0px 2px 4px -5px rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      margin: "0px 10px 15px 0px",
    },
    ".live-call-keypad .live-call-keypad-button:hover": {
      color: "#ffffff",
      backgroundColor: "#000000",
    },
    ".live-call-keypad .live-call-keypad-button .keypad-dial-number": {
      textAlign: "center",
      fontSize: "24px",
      fontFamily: "'AvenirNext'",
      lineHeight: "20px",
      fontWeight: "600",
    },
    ".live-call-keypad .live-call-keypad-button .keypad-dial-text": {
      textAlign: "center",
      fontSize: "16px",
      fontFamily: "'AvenirNext'",
    },
    ".no-more-exists": {
      height: "25px",
      width: "100%",
      backgroundColor: "orange",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "2px",
    },
    ".live-call-panel .transfer-call-area": {
      marginTop: "10px",
      marginLeft: "2px",
      padding: "8px",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      flexWrap: "wrap",
    },
    ".live-call-panel .transfer-call-area .transfer-call-button": {
      cursor: "pointer",
      boxSizing: "border-box",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 22px",
      width: "74px",
      height: "66px",
      border: "1px solid #000000",
      color: "#0B0909",
      boxShadow: "0px 2px 4px -5px rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      margin: "0px 13px 10px 0px",
    },
    ".live-call-panel .transfer-call-area .transfer-call-button:hover": {
      color: "#ffffff!important",
      backgroundColor: "#000000!important",
    },
    ".live-call-panel .transfer-call-area .transfer-call-button.active": {
      color: "#ffffff!important",
      backgroundColor: "#000000!important",
    },
    ".live-call-panel .transfer-call-area .transfer-call-button .transfer-call-title": {
      textAlign: "center",
      fontSize: "14px",
      fontFamily: "'AvenirNext'",
      lineHeight: "20px",
      fontWeight: "600",
    },
    ".live-call-panel .transfer-call-area .transfer-call-button .transfer-call-text": {
      textAlign: "center",
      fontSize: "12px",
      fontFamily: "'AvenirNext'",
      fontWeight: "400",
    },
    ".live-call-panel .btn-on-hold-wrapper": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    ".live-call-panel .btn-on-hold-wrapper .btn-on-hold": {
      width: "70%",
      cursor: "pointer",
      border: "1px solid #000000",
      color: "#0B0909",
      boxShadow: "0px 2px 4px -5px rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      background: "white",
      lineHeight: "18px",
      padding: "8px 0px",
    },
    ".live-call-panel .btn-on-hold-wrapper .btn-on-hold:hover": {
      backgroundColor: "#000000",
      color: "#ffffff",
    },
    ".live-call-panel .btn-on-hold-wrapper .btn-on-hold.active": {
      backgroundColor: "#000000!important",
      color: "#ffffff!important",
    },
    ".live-call-panel .already-transfer-call-area .already-transfer-call-area-detail": {
      padding: "30px 43px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    ".live-call-panel .already-transfer-call-area .already-transfer-call-area-detail .transfer-user-profile .MuiAvatar-root": {
      width: "83px",
      height: "83px",
      border: "0.25px solid #000000",
      boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
      filter: "drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12))",
      borderRadius: "4px",
    },
    ".live-call-panel .already-transfer-call-area .already-transfer-call-area-detail .transfer-user-profile p": {
      fontWeight: "600",
      fontFamily: "'AvenirNext'",
      fontSize: "14px",
      margin: "0px",
    },
    ".live-call-panel .already-transfer-call-area .already-transfer-call-area-detail .transfer-timer": {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "'AvenirNext'",
    },
    ".live-call-panel .already-transfer-call-area .transfer-button-actions": {
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    ".live-call-panel .already-transfer-call-area .transfer-button-actions .transfer-call-button": {
      cursor: "pointer",
      boxSizing: "border-box",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 28px",
      minWidth: "74px",
      height: "66px",
      border: "1px solid #000000",
      color: "#0B0909",
      boxShadow: "0px 2px 4px -5px rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
    },
    ".live-call-panel .already-transfer-call-area .transfer-button-actions .transfer-call-button:hover": {
      color: "#ffffff",
      backgroundColor: "#000000",
    },
    ".live-call-panel .already-transfer-call-area .transfer-call-button .active": {
      color: "#ffffff!important",
      backgroundColor: "#000000!important",
    },
    ".live-call-panel .already-transfer-call-area .transfer-button-actions .transfer-call-button .transfer-call-title": {
      textAlign: "center",
      fontSize: "18px",
      fontFamily: "'AvenirNext'",
      lineHeight: "20px",
      fontWeight: "600",
    },
    ".live-call-panel .already-transfer-call-area .transfer-button-actions .transfer-call-button .transfer-call-text": {
      textAlign: "center",
      fontSize: "12px",
      fontFamily: "'AvenirNext'",
      fontWeight: "400",
    },
    ".live-call-panel .already-transfer-call-area .transfer-button-actions .transfer-call-button.abort": {
      color: "#F00909",
      borderColor: "#F00909",
    },
    ".live-call-panel .already-transfer-call-area .transfer-button-actions .transfer-call-button.abort:hover": {
      color: "#ffffff",
      backgroundColor: "#000000",
    },
  },
});
