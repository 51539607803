import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const handleUpdateTemplate = createAsyncThunk("AlertTemplates/handleUpdateTemplate", async (data, thunkAPI) => {
    let templateId=data.id;
    delete data.id
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/alert-templates/${templateId}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return {...data,id:templateId};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
