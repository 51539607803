import  React,{useState,useEffect} from "react";
import { Container } from "@mui/material";
import TransactionsContractsStats from "./stats";
import ContractTable from './contractTable'
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";


function TransactionsActiveContracts(props) {
  let {classes,setFilters,filters}=props;
  return (
    <Container className={classes.transactionsStatsArea} maxWidth="xl">
      <TransactionsContractsStats {...props} />
      <ContractTable setFilters={setFilters} filters={filters} {...props} />
    </Container>
  );
}
export default withStyles(styleSheet, { name: "TransactionsActiveContractsStyle" })(TransactionsActiveContracts)
