export const styleSheet = () => ({
  TopBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: "0",
    flexShrink: "0",
    flexBasis: "60%",
  },
  dataGridButtonsCheck: {
    width: "100%",
    right: 0,
    backgroundColor: "#fff",
    minHeight: "80px",
    display: "flex",
    justifyContent: "flex-end",
    "& >.MuiBox-root": {
      minWidth: "52%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2px 16px",
    },
  },
  dataGridButtonsACH: {
    width: "100%",
    right: 0,
    backgroundColor: "#fff",
    minHeight: "80px",
    display: "flex",
    justifyContent: "flex-end",
    "& >.MuiBox-root": {
      minWidth: "52%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2px 16px",
    },
  },
  selectedChecksBox: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-root": {
      width: "118px",
      height: "40px",
      marginLeft: "15px",
    },
    "& .MuiFormLabel-root": {
      left: "6px",
      top: "-7px",
    },
  },

  TabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
  },
  addCommissionButton: {
    background: "#0B0909 !important",
    fontWeight: "400  !important",
    fontSize: "12.5px !important",
    color: "white",
    height: "34px !important",
    marginRight: "15px !important",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
  },
  StatsArea: {
    marginTop: "35px",
  },
  tableRoot: {
    height: "calc(100vh - 320px)",
    width: "100%",
    backgroundColor: "white",
  },
  commTableRoot: {
    height: "calc(100vh - 220px)",
    width: "100%",
    backgroundColor: "white",
  },
  cardHeader: {
    fontSize: "20px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    textAlign: "center",
  },
  financeFile: {
    marginTop: "45px",
    width: "85%",
    minWidth: "900px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  mainCommission: {
    justifyContent: "space-between",
    "& h3": {
      fontSize: "34px",
      fontFamily: "AvenirNext",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& h4": {
      fontSize: "24px",
      fontFamily: "AvenirNext",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& h5": {
      fontSize: "20px",
      fontFamily: "AvenirNext",
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "500",
      lineHeight: "32px",
    },
    "& span": {
      fontFamily: "Roboto",
    },
  },
  mainSectionContent: {
    paddingLeft: "40px",
  },
  commissionDeductMessage: {
    width: "76%",
    display: "flex",
    alignItems: "center",
    height: "72px",
    minWidth: "530px",
    margin: "13px auto 28px auto",
    background: `linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #d32f2f`,
    boxShadow: ` 0px 0px 0px 1px #e0e0e0`,
    borderRadius: "4px",
    padding: "0 10px",
    "& button": {
      color: "rgba(11, 9, 9, 1)",
      "&:first-of-type": {
        marginLeft: "23px",
        marginRight: "16px",
        border: `1px solid rgba(33, 33, 33, 0.4)`,
      },
    },
    "& svg": {
      fontSize: "43px",
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  mainSectionTitle: {
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #000000",
    marginBottom: "10px",
  },
  commissionBold: {
    border: "none",
    position: "relative",
    "&:after ": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: " 70%",
      background: "rgba(255, 255, 255, 0.002)",
      borderBottom: `1px solid #000000`,
    },
    "& h3, h4": {
      fontWeight: "600",
    },
  },
  mainCommissionLeft: {
    backgroundColor: "#fff",
    marginRight: "15px",
    padding: "30px 24px 190px 40px",
    position: "relative",
  },
  mainCommissionButtons: {
    position: "absolute",
    bottom: "40px",
    right: "38px",
    "& button:first-child ": {
      marginRight: "30px",
    },
  },
  mainCommissionRight: {
    backgroundColor: "#fff",
    padding: "25px",
    overflow: "auto",
    maxHeight: " 1200px",
  },
  closingReportArea: {
    padding: "25px",
    marginTop: "30px",
  },
  closingReportAreaHeading: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2",
    "& .MuiTypography-root": {
      fontSize: "22px",
      fontFamily: "AvenirNext",
      color: "#000000",
      fontWeight: "600",
      marginRight: "25px",
    },
  },
  grossCommissionArea: {
    marginTop: "20px",
  },
  grossCommissionInputsArea: {
    padding: "8px 0px",
  },
  grossCommissionTopLeft: {
    borderBottom: "1px solid #000000",
    width: "fit-content",
  },
  grossCommissionTopLeftHeading: {
    fontSize: "22px !important",
    fontFamily: "AvenirNext  !important",
    color: "#000000  !important",
    fontWeight: "600  !important",
    textAlign: "right !important",
  },
  grossCommissionTopLeftHeadingBorder: {
    fontSize: "22px !important",
    fontFamily: "AvenirNext  !important",
    color: "#000000  !important",
    fontWeight: "600  !important",
    textAlign: "right !important",
    borderTop: "1px solid black !important",
  },
  grossCommissionBottomLeftHeadingBorder: {
    fontSize: "22px !important",
    fontFamily: "AvenirNext  !important",
    color: "#000000  !important",
    fontWeight: "600  !important",
    textAlign: "right !important",
    borderBottom: "1px solid black !important",
  },
  grossCommissionTopContent: {
    fontSize: "18px  !important",
    fontFamily: "AvenirNext  !important",
    color: "#000000  !important",
    fontWeight: "500  !important",
  },
  closingReportInput: {
    padding: "7px 9px !important",
    fontSize: "14px !important",
  },
  commissionDocument: {
    width: " 100%",
    marginTop: "20px",

    textAlign: "center",
    "& p": {
      fontSize: "16px",
    },
  },
  headerCommission: {
    backgroundColor: "#fff",
    margin: 0,
    marginBottom: " 30px",
    width: "100%",
    padding: "20px",
    position: "relative",
    "& .MuiGrid-item": {
      padding: 0,
      width: "100%",
    },
    "& img": {
      width: "90%",
      borderRadius: "5px",
    },
    "& p": {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& h3": {
      fontSize: "24px",
      fontFamily: "AvenirNext",
      fontWeight: "700",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& h4, h5,h6": {
      fontSize: "25px",
      fontFamily: "AvenirNext",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& h5": {
      fontWeight: "400",
    },
    "& h6": {
      fontSize: "24px",
      padding: "0 5px",
    },
  },
  headerIcons: {
    position: "absolute",
    right: "23px",
    top: "11px",
    "& svg": {
      color: "rgba(0, 0, 0, 1)",
      fontSize: "16px",
      width: "38px",
      height: "38px",
      padding: "10px",
      border: `0.5px solid #000000`,
      borderRadius: "5px",
      marginLeft: "10px",
    },
  },
  headerCommissionDetails: {
    padding: "11px 0 5px 22px,",
  },
  headerCommissionFooter: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
    "& p": {
      textAlign: "center",
    },
  },
  commissionButtons: {
    position: "absolute",
    bottom: "40px",
    right: "38px",
  },
  createCommissionPlan: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",

    "& h2": {
      fontSize: "24px",
      fontFamily: "AvenirNext",
      color: "#212121",
      marginBottom: "35px",
      lineHeight: "32px",
    },

    "& .MuiButton-root": {
      height: "30px",
      fontSize: "13px",
    },
  },
  planFormLabel: {
    fontSize: "24px !important",
    color: "#212121 !important",
  },
  planFormColumn: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",

    "&:first-child": {
      width: "74%",
      position: "relative",
    },
    "&:last-child": {
      width: "25%",
    },
    "& .MuiTextField-root": {
      minWidth: "60px",
      marginRight: "16px",
      "&:last-child": {
        marginRight: 0,
      },
      "& .MuiInputBase-root": {
        height: "40px",
      },
      "& label": {
        lineHeight: "8px",
        padding: "2px 0 5px 0",
        color: " rgba(0, 0, 0, 0.87)",
        fontSize: "16px",
      },
    },
  },
  commissionButtons: {
    position: "absolute",
    bottom: "40px",
    right: "38px",
  },
  closingDocumentHeading: {
    fontSize: "22px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext !important",
  },
  createCommissionRight: {
    alignItems: "center",
    width: "20.1%",
    flexDirection: "column !important",
    "& .MuiGrid-item": {
      maxWidth: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column !important",
      justifyContent: "center",
      padding: "40px 6px 20px 6px",
      borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
      " &:first-of-type": {
        border: "none",
        padding: "6px",
      },
      "&:last-child": {
        marginBottom: 0,
      },
      "&.alignStart": {
        justifyContent: "start",
      },
    },
    "& h4,h5,h6": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& h4": {
      fontSize: "20px",
      fontWeight: "500",
      fontFamily: "AvenirNext",
      lineHeight: "32px",
    },
    "& h5": {
      fontSize: "14px",
      lineHeight: "20px",
    },
    "& h6": {
      fontSize: "16px",
      paddingLeft: "20px",
      marginBottom: "16px",
      position: "relative",

      "& strong": {
        fontWeight: "700",
      },
      " &.has-a-line": {
        paddingLeft: "28px",
        width: "120px",
        alignSelf: "center",
        "&::before": {
          content: '""',
          width: "24px",
          height: "3px",
          backgroundColor: "#000",
          position: "absolute",
          top: "calc(50%)",
          transform: "translate(0, -50%)",
          left: 0,
        },
      },
    },
  },
  viaCheckButton: {
    color: "black !important",
    borderColor: "black !important",
    fontSize: "12px !important",
    textTransform: "capitalize !important",
    whiteSpace:"nowrap !important"
  },
  addDeductionButton: {
    fontSize: "12px !important",
    backgroundColor: "black !important",
    mt: "40px !important",
    textTransform: "capitalize !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "black !important",
    },
  },
  commissionPlanName: {
    width: "50%",

    "& .MuiInputBase-root ": {
      height: "24px !important",
    },
    "& label": {
      lineHeight: "12px !important",
      padding: " 0  !important",
      color: "rgba(0, 0, 0, 0.6) !important",
      marginTop: "-4px",
    },
  },
  TopAreaRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  planFormGroup: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    marginBottom: "60px",
    "&last-child": {
      marginBottom: 0,
    },
    "& form": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "13px",
    },
    "& .MuiButtonBase-root": {
      marginTop: "0px",
    },
    "& .add-deduc": {
      marginTop: "24px",
    },
  },
});
