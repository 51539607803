import React, { useState, useEffect, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import {
  createCourse,
  updateCourse,
} from "../../../../redux/knowledge/courses";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AddKnowledgeBaseCourse(props) {
  const dispatch = useDispatch();
  let { open, setOpen, classes, postData, editable } = props;
  const [posts, setPost] = useState([]);
  const [courseDetail, setCourseDetail] = useState({
    audience: "",
    title: "",
    summary: "",
    visibility: "",
  });

  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const addCourse = useSelector(
    (state) => state.knowledgeBase.courses.addCourse
  );
  const updateCourseState = useSelector(
    (state) => state.knowledgeBase.courses.update
  );

  useEffect(() => {
    if (editable) {
      setCourseDetail({
        audience: editable.audience,
        title: editable.title,
        summary: editable.summary,
        visibility: editable.visibility,
      });
    }
  }, [editable]);

  const handleUpdateDetail = (field, value) => {
    setCourseDetail({ ...courseDetail, [field]: value });
  };

  const handleChangePost = (e, newValue) => {
    setPost(newValue);
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully created the course for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleUpdateSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the course for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};

    if (!courseDetail.audience) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the audience type",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!courseDetail.visibility) {
      isFormValid = false;
      errorMsg["visibility"] = "Please select the visibility first";
      setErrorAlert({
        errorMsg: "Please select the visibility first",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!courseDetail.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter the course title ";
      setErrorAlert({
        errorMsg: "Please enter the course title",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!courseDetail.summary) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the course summary",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!courseDetail.visibility) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the visibility type ",
        errorType: "warning",
        isOpen: true,
      });
    }
    return isFormValid;
  };

  const CreateNewCourse = () => {
    if (handleValidate()) {
      const postsIds = posts.map((p) => p.id);
      let obj = {
        ...courseDetail,
        postsIds,
        handleSuccess,
        handleError,
      };
      dispatch(createCourse(obj));
    }
  };

  const UpdateCourse = () => {
    if (handleValidate()) {
      const postsIds = posts.map((p) => p.id);
      let obj = {
        ...courseDetail,
        postsIds,
        courseId: editable.id,
        handleUpdateSuccess,
        handleError,
      };
      dispatch(updateCourse(obj));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPost([]);
    setCourseDetail({
      audience: "",
      title: "",
      summary: "",
      visibility: "",
    });
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Grid container direction="row" spacing={1}>
          <Grid item md={4} xs={12}>
            <DialogTitle
              className={classes.addItemModalHeading}
              id="alert-dialog-title"
            >
              {editable.id ? "Update Course" : "Create Course"}
            </DialogTitle>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth className={classes.dropdowns}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={courseDetail.audience || "Audience"}
                onChange={(e) => handleUpdateDetail("audience", e.target.value)}
                size="small"
                className={classes.audience}
              >
                <MenuItem disabled value={"Audience"}>
                  Audience
                </MenuItem>
                <MenuItem value={"All Users"}>All Users</MenuItem>
                  <MenuItem value={"Agents"}>Agents</MenuItem>
                  <MenuItem value={"Admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth className={classes.dropdowns}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={courseDetail?.visibility || "visibility"}
                onChange={(e) =>
                  handleUpdateDetail("visibility", e.target.value)
                }
                size="small"
                className={classes.audience}
              >
                <MenuItem disabled value="visibility">
                  VISIBILITY
                </MenuItem>
                <MenuItem value={"Published"}>Published</MenuItem>
                <MenuItem value={"Draft"}>Draft</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <DialogContent sx={{ width: "600px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={3}>
            <Grid item lg={12} xs={12} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Title</h4>
                <TextField
                  fullWidth
                  value={courseDetail?.title}
                  onChange={(e) => handleUpdateDetail("title", e.target.value)}
                  classes={{ root: classes.detailInput }}
                  placeholder="Search or create tags"
                  variant="outlined"
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} xs={12} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Summary</h4>
                <TextField
                  value={courseDetail?.summary}
                  onChange={(e) =>
                    handleUpdateDetail("summary", e.target.value)
                  }
                  id="outlined-multiline-static"
                  placeholder="Search or create tags"
                  multiline
                  rows={5}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} xs={12} sx={{ zIndex: "1" }}>
              <FormControl sx={{ mt: 5, minWidth: "100%" }}>
                <Typography component="p" variant="subtitle1">
                  Select Post
                </Typography>
                <TagsInput
                  fullWidth
                  variant="outlined"
                  name="tags"
                  handleChangePost={handleChangePost}
                  postsList={postData}
                  posts={posts}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            className={classes.btns}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => {
              if (!editable.id) {
                CreateNewCourse();
              } else {
                UpdateCourse();
              }
            }}
            classes={{ root: classes.AddInventoryButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addCourse.isLoading || updateCourseState?.isLoading}
          >
            {editable.id ? "Update" : "Save"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddKnowledgeBaseCourseStyle" })(
  AddKnowledgeBaseCourse
);

const TagsInput = ({ postsList, posts, handleChangePost, ...props }) => {
  return (
    <React.Fragment>
      <Autocomplete
        multiple
        id="Posts"
        options={postsList || []}
        value={posts || []}
        disableCloseOnSelect
        getOptionLabel={(option) => (
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            {/* <Avatar sx={{ fontSize: "1rem", width: "25px", height: "25px" }}>{option?.title || "no"}</Avatar> */}
            <Typography component="subtitle" variant="subtitle">
              {option?.title || "no"}
            </Typography>
          </Box>
        )}
        onChange={handleChangePost}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected || false}
            />
            <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <Avatar>{option?.title[0] || "no"}</Avatar>
              <Typography component="subtitle" variant="subtitle">
                {option?.title || "no"}
              </Typography>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Posts" size="small" />
        )}
      />
    </React.Fragment>
  );
};
