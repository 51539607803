import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {List, ListItem, ListItemAvatar, ListItemText ,Box,Chip,Typography} from "@mui/material";
import moment from 'moment'
import Avatar from "../../../../components/uiElements/avatar/index";
// Name Cell Component
export const NameCard = ({ first_name, last_name, src }) => {
  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          {src ? (
            <Avatar alt="Cindy Baker" variant="rounded" src={src} />
          ) : (
            <Avatar alt={`${first_name} ${last_name}`} sx={{textTransform:'uppercase',fontSize:'15px'}} variant="rounded">
              {`${first_name[0]}${last_name[0]}`}
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText primary={first_name} secondary={<React.Fragment>{last_name}</React.Fragment>} />
      </ListItem>
    </List>
  );
};

export const PhoneCell = ({ number }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: "5px",
      }}
    >
      <LocalPhoneIcon /> {number}
    </div>
  );
};

export const LicenseCell = ({ name, expire }) => {
  return (
    <Box>
      <Chip size="small" label={name} style={{ background: "#A7D4FF", color: "white" }} />
      <Typography marginLeft={1} fontSize={12}>
        {expire ? moment(expire).format("[Expires ] MMM  'YY") : "--"}
      </Typography>
    </Box>
  );
};

export const TeamCell = ({ name, color, join_date }) => {
  return (
    <Box>
      <Chip size="small" label={name} style={{ background: color, color: "white" }} />
      <Typography marginLeft={1} fontSize={12}>
        {join_date ? moment(join_date).format("[Since ] MMM  'YY") : "--"}
      </Typography>
    </Box>
  );
};
