import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  allChecks: [],
  errMsg: null,
};

export const approveUnprocessedClosings = createAsyncThunk(
  "finance/approveUnprocessedClosings",
  async (details, thunkAPI) => {
    try {
      const url = "/api/finance/approve-for-payment/" + details.id;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "put",
        url,
        data: details.data,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const sendForPreviewUnprocessedClosings = createAsyncThunk(
  "finance/sendForPreviewUnprocessedClosings",
  async (details, thunkAPI) => {
    try {
      const url = "/api/finance/preview-and-send-notifications/" + details.id;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "put",
        url,
        data: details.data,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
