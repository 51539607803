import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  allClosings: [],
  errMsg: null,
  totalCount: 0,
};

export const getUnprocessedClosings = createAsyncThunk("finance/getUnprocessedClosings", async (data, thunkAPI) => {
  try {
    let headers = {
      Authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}api/finance/unprocessed-closings`, { headers, params: data })
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          return err;
        });
    });
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const unprocessedClosings = createSlice({
  name: "unprocessedClosings",
  initialState,
  extraReducers: {
    [getUnprocessedClosings.pending]: (state) => {
      state.loading = true;
    },
    [getUnprocessedClosings.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.meta.arg.reset) {
        state.allClosings = [...action.payload.data.data];
      } else {
        state.allClosings = [...state.allClosings.concat(action.payload.data.data)];
      }
      state.totalCount = action.payload.data.count;
    },
    [getUnprocessedClosings.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default unprocessedClosings.reducer;
