import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const saveCallLog = createAsyncThunk(
  "Leads/saveCallLog",
  async (data, thunkAPI) => {
    let { schema, handleSuccess, handleError } = data;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/leads/calls`,
        { ...schema },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (resp.data) {
        handleSuccess();
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        handleError(error.response.data.message);
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        handleError(error.message);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
