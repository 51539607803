export const styleSheet = (theme) => ({
  saveModalButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  CloseContractHeading: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customDialog: {
    "&.MuiDialog-paper": {
      overflowY: "visible !important",
    },
  },
});
