import React from "react";
import { Grid, Card, CardHeader, CardContent } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import TransactionsDetailImportantNotes from "./importantNotes";
import LockBoxes from "./LockBoxes";

function TransactionsDetailOverview(props) {
  let { classes, ListingData } = props;
  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={8}>
        <Card className={classes.overViewTabCardMain}>
          <CardHeader classes={{ title: classes.overViewTabCardHeading }} sx={{ paddingLeft: "0px" }} title="Private Remarks" />
          {ListingData?.private_remark ? (
            <CardContent  className={classes.privateRemarksContent}>{ListingData?.private_remark || ""}</CardContent>
          ) : null}
        </Card>

        <TransactionsDetailImportantNotes {...props} />
      </Grid>

      <Grid item xs={4}>
        <LockBoxes {...props} />
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailOverviewStyle" })(TransactionsDetailOverview);
