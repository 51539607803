export const styleSheet = () => ({
    NotFoundRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
        width:' 100%',
    },
    NotFoundContent:{
        textAlign:'center'
    },
    UnauthorizedPage:{

    },
  });