import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./common/NavBar";
import UnprocessedClosings from "./closings";
import FinanceFile from "./closings/FinanceFile";
import CommissionPlans from "./closings/CommissionPlans";

const Finance = () => {
  const [commissionModal, setCommissionModal] = useState(false);

  return (
    <Routes>
      <Route path="/closings" element={<NavBar setCommissionModal={setCommissionModal} />}>
        <Route path="/closings/unprocessed" element={<UnprocessedClosings />} />
        <Route path="/closings/processed" element={<UnprocessedClosings />} />
        <Route path="/closings/checks-to-print" element={<UnprocessedClosings />} />
        <Route path="/closings/ach-to-send" element={<UnprocessedClosings />} />
        <Route path="/closings/unprocessed/:id" element={<FinanceFile />} />
        <Route path="/closings/processed/:id" element={<FinanceFile />} />
        <Route
          path="/closings/commission-plans"
          element={<CommissionPlans commissionModal={commissionModal} setCommissionModal={setCommissionModal} />}
        />
      </Route>
    </Routes>
  );
};

export default Finance;
