import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTasks = createAsyncThunk("agentTasks/getTasks", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/recruitment/tasks?due=${data}&isCompleted=false`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { data: resp.data?.tasks, type: data }
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const updateTask = createAsyncThunk("agentTasks/updateTask", async (data, thunkAPI) => {
  try {
    const { id, val } = data;
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}api/recruitment/tasks/${id}`,
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      data: { isCompleted: val }
    };

    const resp = await axios(config);
    return resp?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});


const initialState = {
  success: false,
  tasks: [],
  isLoading: false,
  errMsg: null,
};

const teams = createSlice({
  name: "agentTasks",
  initialState,
  reducers: {
  },
  extraReducers: {
    [getTasks.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getTasks.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.type = action.payload.type;
      state.tasks = action.payload.data;
    },
    [getTasks.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
  },
});

export const { updateAttachedPlan } = teams.actions;
export default teams.reducer;
