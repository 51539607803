import React from "react";
import { Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import SubHeader from "./subHeader";
import NewSearchProperty from "./newSearch";
import PropertyDetailFMLS from "./propertyDetail";
import { Route, Routes } from "react-router-dom";

function ListingDatasetDetailPage(props) {
  return (
    <Box>
      <SubHeader {...props} />
        <Routes>
          <Route path="/" element={<PropertyDetailFMLS />} />
          <Route path="/new-search" element={<NewSearchProperty />} />
        </Routes>
      <br />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ListingDatasetDetailPageStyles" })(ListingDatasetDetailPage);
