import React from "react";
import {  Container, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styleSheet } from "./style";
import { useNavigate } from "react-router-dom";
import VendorBasicDetails from './vendorBasicDetail'

function VendorsDetail(props) {
  let { classes } = props;
  const navigate=useNavigate()
  return <Container maxWidth="xl" className={classes.pageRoot}>
    <Button onClick={()=>navigate('/vendors')} variant="outlined" color="inherit" startIcon={<ArrowBackIcon />}>
    ALL VENDORS
      </Button>
      <VendorBasicDetails {...props} />
  </Container>;
}
export default withStyles(styleSheet, { name: "VendorsDetailStyle" })(VendorsDetail);
