import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getLeadsListAPI = createAsyncThunk("leads/getLeadsListAPI", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/leads/people`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      params: {
        ...data,
        fields:
          "name,stage,price,source,lastEmail,lastReceivedEmail,lastSentBatchEmail,lastSentEmail,emailsSent,emailsReceived,lastCall,lastIncomingCall,lastOutgoingCall,callsIncoming,callsOutgoing,lastText,lastSentText,lastReceivedText,textsSent,textsReceived,assignedTo,lastActivity,created,claimedAt",
      },
    });
    return { ...resp.data, resetList: data?.resetList, search: data?.search };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
