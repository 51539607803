import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px !important",
    border: "1px solid rgba(0,0,0,0.5)",
  },
  headerSection: {
    [theme.breakpoints.down("xsm")]: {
      height: "150px !important",
      padding: "0 !important",
    },
    [theme.breakpoints.down("md")]: {
      height: "272 !important",
      padding: "10px !important",
    },
    display: "flex",
    flexDirection: "column",
    height: "240px !important",
    boxSizing: "border-box",
  },
  propertyImage: {
    [theme.breakpoints.down("xsm")]: {
      height: "100px !important",
    },
    height: "158px !important",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  fontFamily: {
    textTransform: "uppercase",
    fontFamily: '"Work Sans", sans-serif !important',
    letterSpacing: "2.5px !important",
  },
  addressContainer: {
    [theme.breakpoints.up("md")]: {
      background: "#000",
      color: "#FFF",
      padding: "10px !important",
    },
    marginTop: "5px !important",
  },
  address1: {
    [theme.breakpoints.down("xsm")]: {
      fontSize: "12px !important",
      lineHeight: "17px !important",
      fontWeight: "600 !important",
    },
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "29px !important",
  },
  address2: {
    [theme.breakpoints.down("xsm")]: {
      fontSize: "12px !important",
      lineHeight: "17px !important",
    },
    fontWeight: "500 !important",
    fontSize: "11px !important",
    lineHeight: "15px !important",
  },
  tableCell: {
    [theme.breakpoints.down("xsm")]: {
      fontSize: "10px !important",
    },
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "1 !important",
    textOverflow: "ellipsis",
  },
  alignRight: {
    textAlign: "right",
    fontWeight: "700 !important",
  },
  positive: {
    color: "#04BF00",
  },
  negative: {
    color: "#FF0000",
  },
  tableContainer: {},
  rows: {
    [theme.breakpoints.down("xsm")]: {
      padding: "0px 10px",
    },
    padding: "0px 15px",
    height: "38px",
    alignContent: "center",
  },
  bottomContainer: {
    [theme.breakpoints.up("md")]: {
      border: "1px solid #00000033",
    },
    height: "120px",
    background: "#000",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down("xsm")]: {
      padding: "0 10px 0 0 ",
    },
  },
  totalContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "5px",
  },
  bottomValue: {
    [theme.breakpoints.down("xsm")]: {
      fontSize: "10px !important",
    },
    fontSize: "14px !important",
  },
  adjustedValue: {
    [theme.breakpoints.down("xsm")]: {
      fontSize: "18px !important",
      lineHeight: "38px !important",
    },
    fontSize: "26px !important",
    fontWeight: "700 !important",
    lineHeight: "54px !important",
    textAlign: "right",
  },
  "@global": {
    ".alternateDiv > div:nth-child(odd)": {
      background: " #f2f2f2",
    },
    ".alternateDiv > div:nth-child(even)": {
      background: "#fff",
    },
  },
}));
