import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Grid, Container, Alert, IconButton, Checkbox } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Comparables from "./Comparables";
import Adjustments from "./adjustments/index.jsx";
import Pricing from "./Pricing";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import {
  getSingleCmaV2,
  getCmaPrice,
  getadjustmentData,
  addCustomField,
  updateCustomField,
  deleteCustomField,
  getCmaComparableRecordById,
  getAvailableConfigPackagesSingleCMA,
  updateCma,
  updateSingleCMA,
} from "../../../redux/tools/cmas";
import Review from "./Review.js";
import PeopleIcon from "@mui/icons-material/People";
import AttachClient from "./AttachClient.js";
import CMASDetailSkelton from "../skelton/cmasDetail";
import OutsideClickHandler from "react-outside-click-handler";
import { RemoveCircle } from "@mui/icons-material";
import Configure from "./Configure.js";
import ImgsViewer from "react-images-viewer";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { FadeLoader, PuffLoader } from "react-spinners";
import { styled } from "@mui/styles";
import { Delete } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { toast } from "react-toastify";
import { getMarkets } from "../../../redux/agents/addRecruitAgent.js";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData.js";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  boxShadow: theme.palette.mode === "dark" ? "0 0 0 1px rgb(16 22 26 / 60%)" : "inset 0 0 0 1px #0B0909, inset 0 -1px 0 #0B0909",
  backgroundColor: "#fff",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3, height: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CUSTOM_FIELD = { name: "", value: "" };

function Subject(props) {
  let { classes } = props;
  const location = useParams();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [openAttachClient, setOpenAttachClient] = React.useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [countFoot, setCountFoot] = useState(null);
  const [countTolerance, setCountTolerance] = useState(30);
  const [countTime, setCountTime] = useState(8);
  const [countDistance, setCountDistance] = useState(0.5);
  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");

  const [mediaUrls, setMediaUrls] = useState([]);
  const [displayCheckbox, setDisplayCheckbox] = React.useState({});
  const [selectedMediaUrls, setSelectedMediaUrls] = React.useState({});

  const [uploadingMediaUrls, setUploadingMediaUrls] = useState(false);
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [imageViewerStartingImage, setImageViewerStartingImage] = React.useState(0);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsChanged, setCustomFieldsChanged] = useState([]);
  const cmaDataRef = useRef();
  const cmaDataChanged = useRef(false);
  const [cmaData, setCmadata] = useState({
    StreetNumber: "",
    StreetName: "",
    StreetSuffix: "",
    UnitNumber: "",
    City: "",
    StateOrProvince: "",
    PostalCode: "",
    SubdivisionName: "",
    BedroomsTotal: "",
    BathroomsFull: "",
    BathroomsHalf: "",
    BuildingAreaTotal: "",
    YearBuilt: "",
    ParkingTotal: "",
    GarageSpaces: "",
    Basement: "",
    ConstructionMaterials: "",
    ArchitecturalStyle: "",
    FireplacesTotal: "",
    Levels: "",
    LotSizeAcres: "",
    TaxAnnualAmount: "",
    PropertyType: "",
    CountyOrParish: "",
  });
  const [switchValue, setSwitchValue] = useState(null);

  const { singleCma, customFieldsErrMsg, customFieldLoading, singleCmaLoading } = useSelector((state) => state.allCmas);
  const agents = useSelector((state) => state.users.storedListOfUsers.list.filter((user) => user.is_agent === true));
  const markets = useSelector((state) => state.agentList.markets);

  const [agent, market] = React.useMemo(() => {
    if (agents.length > 0 && markets.length > 0) {
      const agent = agents.find((agent) => agent.id === Number(singleCma?.agent_id));
      const market = markets.find((market) => market.id === agent?.market_id);
      return [agent, market];
    }
    return [null, null];
  }, [singleCma?.agent_id, agents, markets]);

  const [showSwitch] = React.useMemo(() => {
    if (agent && market) {
      if (market?.datasets?.length === 2) {
        setSwitchValue(market?.datasets[0]);
        return [true];
      } else {
        setSwitchValue(market?.datasets?.length === 1 ? market.datasets[0] : null);
        return [false];
      }
    } else {
      setSwitchValue(null);
      return [false, null];
    }
  }, [agent, market]);

  const onDrop = (files) => {
    upload(files, mediaUrls);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const addPhoto = (e) => {
    upload([...e.target.files], mediaUrls);
  };

  const upload = (files, oldFiles = []) => {
    setUploadingMediaUrls(true);
    const token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}api/cma/add-images/${location.id}`;

    const formData = new FormData();
    // Add the files to the `property_img[]` field
    files.forEach((file) => {
      formData.append("property_img[]", file);
    });

    // Make the Axios POST request
    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        const updatedMedias = [...oldFiles, ...response.data.cmaMediaUrl];
        setMediaUrls([...updatedMedias]);
        dispatch(updateSingleCMA({ ...cmaData, cma_media_urls: [...updatedMedias] }));
        setUploadingMediaUrls(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setUploadingMediaUrls(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await dispatch(getMarkets());
  }, []);

  useEffect(() => {
    if (singleCma) {
      setCmadata(singleCma);
      setCustomFields(singleCma?.cma_custom_fields?.length > 0 ? singleCma?.cma_custom_fields : [CUSTOM_FIELD]);

      if (singleCma?.cma_media_urls) {
        setMediaUrls(singleCma?.cma_media_urls || []);
      }

      let l = singleCma?.cma_custom_fields?.map((f) => (f = false));
      setCustomFieldsChanged(l);
    }
  }, [singleCma]);

  useEffect(() => {
    // fetching all tabs data here
    if (location.id) {
      fetchAllTabsData();
    }
    return () => {
      handleSaveSubjectData();
    };
  }, [location.id]);

  const fetchAllTabsData = () => {
    dispatch(getadjustmentData({ id: location.id }));
    dispatch(getSingleCmaV2({ id: location.id }));
    dispatch(getCmaPrice({ id: location.id }));
    dispatch(getAvailableConfigPackagesSingleCMA({ id: location.id }));
  };

  useEffect(() => {
    if (switchValue !== null) {
      fetchComparable();
    }
  }, [switchValue]);

  const fetchComparable = () => {
    dispatch(
      getCmaComparableRecordById({
        id: location.id,
        sqft_tolerance: null,
        vintage_tolerance: 30,
        time_frame: 8,
        distance: 0.5,
        listingType: switchValue,
        startPrice: "",
        endPrice: "",
      })
    );
  };

  // Handle tab change
  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      handleSaveSubjectData();
    }
    setValue(newValue);
  };

  const onChangeInput = (evt) => {
    let updatedCMAData = {
      ...cmaData,
      [evt.target.name]: evt.target.value,
    };
    setCmadata({ ...updatedCMAData });
    cmaDataRef.current = updatedCMAData;
    cmaDataChanged.current = true;
  };

  // onBur saving Subject form data
  const handleSaveSubjectData = async () => {
    if (cmaDataChanged.current) {
      await dispatch(
        updateCma({
          fetchAdjustment: true,
          cmaData: {
            StreetNumber: cmaDataRef.current.StreetNumber,
            StreetName: cmaDataRef.current.StreetName,
            StreetSuffix: cmaDataRef.current.StreetSuffix,
            UnitNumber: cmaDataRef.current.UnitNumber,
            City: cmaDataRef.current.City,
            StateOrProvince: cmaDataRef.current.StateOrProvince,
            PostalCode: cmaDataRef.current.PostalCode,
            SubdivisionName: cmaDataRef.current.SubdivisionName,
            BedroomsTotal: cmaDataRef.current.BedroomsTotal,
            BathroomsFull: cmaDataRef.current.BathroomsFull,
            BathroomsHalf: cmaDataRef.current.BathroomsHalf,
            BuildingAreaTotal: cmaDataRef.current.BuildingAreaTotal,
            YearBuilt: cmaDataRef.current.YearBuilt,
            ParkingTotal: cmaDataRef.current.ParkingTotal,
            GarageSpaces: cmaDataRef.current.GarageSpaces,
            Basement: cmaDataRef.current.Basement,
            ConstructionMaterials: cmaDataRef.current.ConstructionMaterials,
            ArchitecturalStyle: cmaDataRef.current.ArchitecturalStyle,
            FireplacesTotal: cmaDataRef.current.FireplacesTotal,
            Levels: cmaDataRef.current.Levels,
            LotSizeAcres: cmaDataRef.current.LotSizeAcres,
            TaxAnnualAmount: cmaDataRef.current.TaxAnnualAmount,
            PropertyType: cmaDataRef.current.PropertyType,
            CountyOrParish: cmaDataRef.current.CountyOrParish,
            cmaId: location.id,
          },
        })
      );
      await dispatch(
        getCmaComparableRecordById({
          id: location.id,
          sqft_tolerance: null,
          vintage_tolerance: 30,
          time_frame: 8,
          distance: 0.5,
          listingType: "fmls",
          startPrice: "",
          endPrice: "",
        })
      );
    }
    cmaDataChanged.current = false;
  };

  const handleSliderImageClick = (imageIndex) => {
    setImageViewerOpen(true);
    setImageViewerStartingImage(imageIndex);
  };

  const isClientAvailable = () => {
    if (
      singleCma?.client0_firstName ||
      singleCma?.client0_lastName ||
      singleCma?.client0_email ||
      singleCma?.client1_firstName ||
      singleCma?.client1_lastName ||
      singleCma?.client1_email
    ) {
      return true;
    }
    return false;
  };

  // Saving custom fields
  const saveCustomFields = () => {
    customFields.map((custom, i) => {
      if (custom.name.length !== 0 && custom.value.length !== 0 && customFieldsChanged[i]) {
        handleSaveSubjectData();

        if (custom.id) {
          dispatch(updateCustomField({ cmaId: location.id, custom }));
        } else {
          dispatch(addCustomField({ cmaId: location.id, custom }));
        }

        setCustomFieldsChanged((l) => {
          l[i] = false;
          return l;
        });
      }
    });
  };

  const addNewCustomField = () => {
    let l = JSON.parse(JSON.stringify([...customFields]));
    l.push(CUSTOM_FIELD);
    setCustomFields(l);
  };

  const deleteTheCustomField = (id) => {
    let updatedCustomFields = JSON.parse(JSON.stringify([...customFields]));
    updatedCustomFields = updatedCustomFields.filter((customfield) => customfield.id !== id);
    setCustomFields(updatedCustomFields);

    handleSaveSubjectData();
    dispatch(deleteCustomField({ cmaId: location.id, id, updatedCustomFields }));
  };

  const selectAllMedia = (selectAll) => {
    if (selectAll) {
      let medias = {};
      mediaUrls.map((media) => {
        medias = { ...medias, [media.id]: true };
      });
      setSelectedMediaUrls(medias);
    } else {
      setSelectedMediaUrls({});
    }
  };

  const deleteMediaAPI = async (mediaIds) => {
    const token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}api/cma/delete-images/${location.id}`;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      method: "delete",
      url,
      data: JSON.stringify({ media_ids: [...mediaIds] }),
    };
    await axios(options);
  };

  const deleteMedia = async () => {
    const dArray = [];
    Object.keys(selectedMediaUrls).map((id) => {
      if (selectedMediaUrls[id]) {
        dArray.push(parseInt(id));
      }
    });
    dArray.map((mediaID) => delete selectedMediaUrls[mediaID]);
    if (dArray.length === 0) return;

    toast.promise(deleteMediaAPI(dArray), {
      pending: "Deleting deliverable(s) is in progress",
      success: "Successfully Deleted",
      error: "Something went wrong while deleting deliverables",
    });

    const updatedMedias = mediaUrls.filter((d) => !dArray.includes(d.id));
    dispatch(updateSingleCMA({ ...cmaData, cma_media_urls: [...updatedMedias] }));
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {singleCmaLoading && <CMASDetailSkelton />}

      {!singleCmaLoading && (
        <Box sx={{ mt: "20px" }}>
          <Box
            sx={{
              background: "#fff",
              borderBottom: 1,
              borderColor: "divider",
              padding: "0px 43px",
              position: "sticky",
              top: "64.5px",
              zIndex: "100",
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={6} sx={{ pt: "0px !important" }}>
                <Box className={classes.dashboardTabSelect}>
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      sx: { background: "black", color: "black" },
                    }}
                    sx={{
                      "& .Mui-selected": {
                        color: "#0B0909 !important",
                      },
                      "& .MuiTab-root": {
                        fontSize: "13px !important",
                        minHeight: "40px !important",
                      },
                      "& .MuiTabs-root": {
                        minHeight: "40px  !important",
                      },
                      "& .MuiTabs-scroller": {
                        height: "36px  !important",
                      },
                      "& .MuiTabs-scrollButtons": {
                        mb: "0.5rem",
                      },
                    }}
                    aria-label="cmas tabs"
                  >
                    <Tab label="SUBJECT" {...a11yProps(0)} />
                    <Tab label="COMPARABLES" {...a11yProps(1)} />
                    <Tab label="ADJUSTMENTS" {...a11yProps(2)} />
                    <Tab label="PRICING" {...a11yProps(3)} />
                    <Tab label="CONFIGURE" {...a11yProps(4)} />
                    <Tab label="REVIEW" {...a11yProps(5)} />
                  </Tabs>
                </Box>
              </Grid>
              <Grid item lg={6} container justifyContent="flex-end" alignItems="center" sx={{ pt: "0px !important" }} gap={"10px"}>
                {isClientAvailable() && (
                  <Box className={classes.clientBox}>
                    <Typography className={classes.clientTitle}>Client</Typography>

                    <Box className={classes.clientDetails}>
                      <Typography className={classes.clientDetailsText}>{singleCma?.client0_firstName}</Typography>
                      <Typography className={classes.clientDetailsText}>{singleCma?.client0_lastName}</Typography>
                    </Box>

                    <Box className={classes.clientDetails}>
                      <Typography className={classes.clientDetailsText}>{singleCma?.client1_firstName}</Typography>
                      <Typography className={classes.clientDetailsText}>{singleCma?.client1_lastName}</Typography>
                    </Box>
                  </Box>
                )}

                <Button
                  className={classes.tabsHeaderBtn}
                  onClick={() => setOpenAttachClient(true)}
                  sx={{
                    color: "#ffffff",
                    background: "#000000",
                  }}
                  variant="outlined"
                >
                  <PeopleIcon /> {isClientAvailable() ? "Edit Client" : "Attach Client"}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <TabPanel value={value} index={0}>
            <Container maxWidth="xl">
              <Paper elevation={2} className={classes.dashboardActivitiesBox}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <h2 className={classes.dashboardActivitiesBoxS}>Subject Details</h2>
                  </Grid>
                  <Grid item md={6} container justifyContent="flex-end">
                    <div>
                      <IconButton onClick={() => setIsEditable(!isEditable)} aria-label="locked">
                        {isEditable ? (
                          <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                        ) : (
                          <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                        )}
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.dashboardActivitiesBoxs}>
                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      name="StreetNumber"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.StreetNumber || ""}
                      onChange={onChangeInput}
                      label="Street Number"
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.StreetName || ""}
                      name="StreetName"
                      onChange={onChangeInput}
                      label="Street Name"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.StreetSuffix || ""}
                      name="StreetSuffix"
                      onChange={onChangeInput}
                      label="Street Suffix"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.UnitNumber || ""}
                      name="UnitNumber"
                      onChange={onChangeInput}
                      label="Unit Number"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      onChange={onChangeInput}
                      value={cmaData.City || ""}
                      name="City"
                      label="City"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.StateOrProvince || ""}
                      name="StateOrProvince"
                      onChange={onChangeInput}
                      label="State"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.PostalCode || ""}
                      name="PostalCode"
                      onChange={onChangeInput}
                      label="Zip Code"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.dashboardActivitiesBoxs}>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.SubdivisionName || ""}
                      name="SubdivisionName"
                      onChange={onChangeInput}
                      label="Subdivision/Blog"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.BedroomsTotal || ""}
                      name="BedroomsTotal"
                      onChange={onChangeInput}
                      label="Bedrooms"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.BathroomsFull || ""}
                      name="BathroomsFull"
                      onChange={onChangeInput}
                      label="Bathrooms"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.BathroomsHalf || ""}
                      name="BathroomsHalf"
                      onChange={onChangeInput}
                      label="Half Baths"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.BuildingAreaTotal || ""}
                      name="BuildingAreaTotal"
                      onChange={onChangeInput}
                      label="Square Feet"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.YearBuilt || ""}
                      name="YearBuilt"
                      onChange={onChangeInput}
                      label="Year Built"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.dashboardActivitiesBoxs}>
                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.ParkingTotal || ""}
                      name="ParkingTotal"
                      onChange={onChangeInput}
                      label="Parking"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.GarageSpaces || ""}
                      name="GarageSpaces"
                      onChange={onChangeInput}
                      label="Spaces"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.Basement || ""}
                      name="Basement"
                      onChange={onChangeInput}
                      label="Basement"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.ConstructionMaterials || ""}
                      name="ConstructionMaterials"
                      onChange={onChangeInput}
                      label="Construction"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.dashboardActivitiesBoxs}>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.ArchitecturalStyle || ""}
                      name="ArchitecturalStyle"
                      onChange={onChangeInput}
                      label="Style"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={1}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.FireplacesTotal || ""}
                      name="FireplacesTotal"
                      onChange={onChangeInput}
                      label="Fireplaces"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      label="Stories"
                      name="Levels"
                      variant="standard"
                      onChange={onChangeInput}
                      value={cmaData.Levels || ""}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.LotSizeAcres || ""}
                      name="LotSizeAcres"
                      onChange={onChangeInput}
                      label="Acres"
                      variant="standard"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.dashboardActivitiesBoxs}>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.TaxAnnualAmount || ""}
                      name="TaxAnnualAmount"
                      onChange={onChangeInput}
                      label="Tax Annual Amount"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      value={cmaData.PropertyType || ""}
                      name="PropertyType"
                      onChange={onChangeInput}
                      label="Property Type"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      inputProps={{ readOnly: isEditable ? false : true }}
                      label="County"
                      name="CountyOrParish"
                      variant="standard"
                      onChange={onChangeInput}
                      value={cmaData.CountyOrParish || ""}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper elevation={2} className={classes.dashboardActivitiesBox}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <h2 className={classes.dashboardActivitiesBoxS}>Custom Fields</h2>
                  </Grid>
                  <Grid item md={6} container justifyContent="flex-end"></Grid>
                </Grid>

                {customFieldsErrMsg && !customFieldLoading && (
                  <Grid container item justifyContent="center" alignItems="center" ml={1.5}>
                    <Alert severity="warning">{customFieldsErrMsg}</Alert>
                  </Grid>
                )}

                {customFieldLoading && (
                  <Grid container item justifyContent="center" alignItems="center" mb={2}>
                    Updating Custom Fields... <PuffLoader color="rgba(0, 0, 0, 0.5)" size={"25"} />
                  </Grid>
                )}

                {customFields.map((custom, i) => {
                  return (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        saveCustomFields();
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        className={classes.dashboardActivitiesBoxs}
                        justifyContent="center"
                        alignItems="center"
                        gap="30px"
                      >
                        <Grid item xs={4}>
                          <TextField
                            id="standard-basic"
                            value={customFields[i].name || ""}
                            onChange={(e) => {
                              let l = JSON.parse(JSON.stringify([...customFields]));
                              if (l[i].name !== e.target.value) {
                                l[i].name = e.target.value;
                                setCustomFields([...l]);
                                setCustomFieldsChanged((l) => {
                                  l[i] = true;
                                  return l;
                                });
                              }
                            }}
                            name="name"
                            label="Custom Field Name"
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="standard-basic"
                            value={customFields[i].value || ""}
                            name="value"
                            onChange={(e) => {
                              let l = JSON.parse(JSON.stringify([...customFields]));
                              if (l[i].value !== e.target.value) {
                                l[i].value = e.target.value;
                                setCustomFields([...l]);
                                setCustomFieldsChanged((l) => {
                                  l[i] = true;
                                  return l;
                                });
                              }
                            }}
                            label="Custom Field Value"
                            variant="standard"
                          />
                        </Grid>
                        <Grid item>
                          <Button onClick={() => deleteTheCustomField(custom.id)}>
                            <RemoveCircle sx={{ color: "#0B0909" }} />
                          </Button>
                        </Grid>
                      </Grid>
                    </OutsideClickHandler>
                  );
                })}

                <Grid container item xs={11.25} justifyContent="center" alignItems="center" mt="10px">
                  <Button onClick={addNewCustomField}>
                    <AddCircleIcon sx={{ color: "#0B0909" }} />
                  </Button>
                </Grid>
              </Paper>

              <Paper elevation={2} className={classes.dashboardActivitiesBox}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <h2 className={classes.dashboardActivitiesBoxS}>Photos</h2>
                  </Grid>
                  <Grid item md={6}>
                    {mediaUrls?.length > 0 &&
                      Object.keys(selectedMediaUrls).filter((media) => selectedMediaUrls[media] === true).length > 0 && (
                        <Box display={"flex"} gap={"2px"} justifyContent={"flex-end"}>
                          {Object.keys(selectedMediaUrls).length === mediaUrls?.length && (
                            <IconButton onClick={() => selectAllMedia(false)}>
                              <RemoveCircleIcon />
                            </IconButton>
                          )}
                          {Object.keys(selectedMediaUrls).length !== mediaUrls?.length && (
                            <IconButton onClick={() => selectAllMedia(true)}>
                              <CheckCircleIcon />
                            </IconButton>
                          )}
                          <IconButton onClick={deleteMedia}>
                            <Delete />
                          </IconButton>
                        </Box>
                      )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.dashboardActivitiesBoxs}>
                  <Grid item xs={12} className={classes.mediaUrlBox}>
                    <div
                      {...getRootProps({
                        onClick: (event) => event.stopPropagation(),
                      })}
                      style={{ width: "100%", position: "relative" }}
                    >
                      <input {...getInputProps()} />

                      <Box
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "420px",
                          marginBottom: "20px",
                          opacity: isDragActive || uploadingMediaUrls ? "0.5" : 1,
                          border: isDragActive ? "1px dashed #000000" : "",
                        }}
                      >
                        {mediaUrls?.length === 0 && (
                          <Box sx={{ width: "100%", height: "100%" }}>
                            <Button
                              variant="outline"
                              component="label"
                              sx={{
                                display: "flex",
                                gap: "3px",
                                alignItems: "center",
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                              }}
                              className={classes.uploadPhotoButton}
                            >
                              <InsertPhotoOutlinedIcon />
                              <span>Add Photos</span>
                              <input multiple type="file" onChange={addPhoto} hidden />
                            </Button>
                          </Box>
                        )}

                        {mediaUrls && mediaUrls?.length > 0 && (
                          <Box
                            sx={{
                              height: "400px",
                              overflowY: "auto",
                              flexWrap: true,
                              overflowX: "hidden",
                              "&::-webkit-scrollbar": {
                                width: 5,
                              },
                              "&::-webkit-scrollbar-track": {
                                width: 5,
                                backgroundColor: "#ddd",
                                "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                width: 5,
                                backgroundColor: "#0B0909",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {mediaUrls?.map((media, imageIndex) => (
                              <Box
                                sx={{
                                  width: "200px",
                                  display: "inline-block",
                                  margin: "5px",
                                  marginBottom: "0",
                                  position: "relative",
                                }}
                              >
                                <img
                                  onMouseEnter={() =>
                                    setDisplayCheckbox({
                                      [media.id]: true,
                                    })
                                  }
                                  onMouseLeave={() =>
                                    setDisplayCheckbox((prev) => {
                                      let updated = { ...prev };
                                      delete updated[media.id];
                                      return updated;
                                    })
                                  }
                                  className="mediaImage"
                                  style={{
                                    objectFit: "cover",
                                    height: "190px",
                                    width: "200px",
                                    borderRadius: "10px",
                                    border: "1px solid #ddd",
                                  }}
                                  src={getImageUrlWithHeightAndWidth(media.MediaUrl)}
                                  alt="cmaimage"
                                  onClick={() => handleSliderImageClick(imageIndex)}
                                />
                                {Object.keys(selectedMediaUrls).length > 0 || Object.keys(displayCheckbox).find((d) => d == media.id) ? (
                                  <div
                                    onMouseEnter={() =>
                                      setDisplayCheckbox({
                                        [media.id]: true,
                                      })
                                    }
                                    onMouseLeave={() =>
                                      setDisplayCheckbox((prev) => {
                                        let updated = { ...prev };
                                        delete updated[media.id];
                                        return updated;
                                      })
                                    }
                                  >
                                    <Checkbox
                                      sx={{
                                        position: "absolute!important",
                                        top: "3%!important",
                                        right: "2%!important",
                                        color: "#0B0909!important",
                                      }}
                                      checkedIcon={<BpCheckedIcon />}
                                      icon={<BpIcon />}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setSelectedMediaUrls((prev) => {
                                          let updated = { ...prev };
                                          if (e.target.checked) {
                                            updated = {
                                              ...updated,
                                              [media.id]: true,
                                            };
                                          } else {
                                            delete updated[media.id];
                                          }
                                          return updated;
                                        });
                                      }}
                                      checked={selectedMediaUrls[media.id] || false}
                                    />
                                  </div>
                                ) : null}
                              </Box>
                            ))}
                          </Box>
                        )}

                        {mediaUrls?.length > 0 && (
                          <ImgsViewer
                            imgs={mediaUrls?.map((item) => ({
                              src: getImageUrlWithHeightAndWidth(item?.MediaUrl),
                            }))}
                            isOpen={imageViewerOpen}
                            currImg={imageViewerStartingImage}
                            showThumbnails={true}
                            enableKeyboardInput={true}
                            onClickThumbnail={(e) => setImageViewerStartingImage(e)}
                            onClickPrev={() =>
                              imageViewerStartingImage - 1 >= 0
                                ? setImageViewerStartingImage((imageViewerStartingImage) => imageViewerStartingImage - 1)
                                : null
                            }
                            onClickNext={() =>
                              imageViewerStartingImage + 1 <= mediaUrls.length
                                ? setImageViewerStartingImage((imageViewerStartingImage) => imageViewerStartingImage + 1)
                                : null
                            }
                            onClose={() => setImageViewerOpen(false)}
                          />
                        )}
                      </Box>

                      {uploadingMediaUrls && (
                        <Box sx={{ position: "absolute", top: "42%", left: "48%" }}>
                          <FadeLoader size="40" color="rgba(0, 0, 0, 0.5)" />
                        </Box>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Comparables
              countFoot={countFoot}
              setCountFoot={setCountFoot}
              countTime={countTime}
              setCountTime={setCountTime}
              countTolerance={countTolerance}
              setCountTolerance={setCountTolerance}
              countDistance={countDistance}
              setCountDistance={setCountDistance}
              startPrice={startPrice}
              setStartPrice={setStartPrice}
              endPrice={endPrice}
              setEndPrice={setEndPrice}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Adjustments />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Pricing />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Configure />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Review />
          </TabPanel>
          {openAttachClient && (
            <AttachClient
              open={openAttachClient}
              setOpen={setOpenAttachClient}
              cmaId={location.id}
              singleCma={singleCma}
              isClientAvailable={isClientAvailable}
              handleSaveSubjectData={handleSaveSubjectData}
              clientData={{
                client0_firstName: singleCma?.client0_firstName || null,
                client0_lastName: singleCma?.client0_lastName || null,
                client0_email: singleCma?.client0_email || null,
                client1_firstName: singleCma?.client1_firstName || null,
                client1_lastName: singleCma?.client1_lastName || null,
                client1_email: singleCma?.client1_email || null,
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
export default withStyles(styleSheet, { name: "SubjectStyle" })(Subject);
