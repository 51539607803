export const uploadImageData = async (url, formData,method) => {
  const token = localStorage.getItem('token');

  let result = await fetch(url, {
    method: method?method:'POST',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => error);
  return result;
};
