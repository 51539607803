export const styleSheet = () => ({
  templateHeader: {
    minHeight: "100vh !important",
    padding: "50px 0px !important",
  },
  templateSuggestionSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    flexWrap: "nowrap !important",
    gap: "25px 15px !important",
  },
  templateSuggestionSectionItem: {
    cursor: "pointer !important",
    width: "24% !important",
  },
  templateSuggestionSectionItemTop: {
    width: "100% !important",
    height: "180px !important",
    backgroundColor: "#D9D9D9 !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    "& img": {
      width: "150px !important",
      height: "90% !important",
      objectFit: "cover !important",
    },
  },
  templateSuggestionSectionItemContent: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    marginTop: "8px  !important",
    fontWeight: "600 !important",
    textTransform: "uppercase !important",
  },
  socialMediaHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    marginBottom: "10px !important",
  },
  socialMediaHeaderLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
    cursor: "pointer !important",
    zIndex: "99 !important",
  },
  socialMediaHeaderLeftContent: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  sliderSection: {
    backgroundColor: "#D9D9D9 !important",
    height: "539px !important",
    width: "100% !important",
    position: "relative !important",
  },
  socialLabel: {
    backgroundColor: "rgba(0, 0, 0, 0.50) !important",
    minWidth: "95px !important",
    height: "30px !important",
    color: "white !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    position: "absolute !important",
    right: "10px !important",
    top: "10px !important",
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
  },
  slider: {
    height: "490px !important",
  },
  SliderAreaItem: {
    height: "490px !important",
    marginTop: "24px !important",
    outline: "none !important",
    "& img": {
      height: "100% !important",
      width: "100% !important",
    },
  },
  previewSelectionArea: {
    margin: "25px 0px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "15px !important",
  },
  previewSectionLabel: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
  },
  suggestedHeading: {
    display: "flex !important",
    alignItems: "center !important",
    gap: "20px !important",
  },
  suggestedHeadingLeft: {
    fontSize: "18px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    margin: "15px 0px !important",
  },
  suggestedHeadingRight: {
    flex: 1,
    height: "2.5px !important",
    background: "rgba(0, 0, 0, 0.20) !important",
  },
  templateHeading: {
    fontSize: "24px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  templateSubHeading: {
    fontSize: "16px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    margin: "25px 0px !important",
  },
  templateContent: {
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    margin: "10px 0px !important",
  },
  TemplateUpdateForm: {
    margin: "20px 0px !important",
    display: "flex !important",
    flexDirection: "column !important",
    gap: "20px !important",
  },
  formLabel: {
    fontSize: "15px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    margin: "7px 0px !important",
  },
  TemplateFormAttachButton: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "10px !important",
    fontSize: "15px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    color: "#0B0909 !important",
    margin: "15px 0px !important",
    borderColor: "rgba(33, 33, 33, 0.40) !important",
  },
  uploadedFileContent: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.60) !important",
    marginRight: "30px  !important",
  },
  uploadedFile: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "20px  10px !important",
  },
  submitButton: {
    borderRadius: "43px !important",
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontSize: "15px !important",
    fontFamily: "AvenirNext !important",
    marginTop: "15px !important",
  },
});
