import { createSlice } from "@reduxjs/toolkit";
import { AddAgentTab } from "./addTab";
import { getAgentTabs } from "./getTabs";
import { deleteAgentTabs } from "./deleteTab";
import { updateAgentTab } from "./updateTab";

const initialState = {
  addTab: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  tabsData: {
    success: false,
    data: { usersTabs: [] },
    isLoading: false,
    errMsg: null,
  },
  deleteTab: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  updateTab: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
};
const AgentTabs = createSlice({
  name: "AgentTabs",
  initialState,
  extraReducers: {
    [AddAgentTab.pending]: (state) => {
      state.addTab.isLoading = true;
      state.addTab.errMsg = null;
      state.addTab.success = false;
    },
    [AddAgentTab.fulfilled]: (state, action) => {
      if (action.payload?.addExpenseRecored) {
        let usersTabs = [...state.tabsData.data.usersTabs];
        if (action.payload?.addExpenseRecored?.dataValues && action.payload?.addExpenseRecored?.attachments) {
          usersTabs.unshift({ ...action.payload?.addExpenseRecored?.dataValues, attachments: action.payload?.addExpenseRecored?.attachments });
        } else {
          usersTabs.unshift(action.payload?.addExpenseRecored);
        }
        state.tabsData.data.usersTabs = usersTabs;
        state.addTab.success = "expense";
      }
      if (action.payload?.addawardBudgetRecored) {
        let usersTabs = [...state.tabsData.data.usersTabs];
        usersTabs.unshift(action.payload?.addawardBudgetRecored);
        state.tabsData.data.usersTabs = usersTabs;
        state.addTab.success = "award";
      }
      if (action.payload?.addApplyBudgetRecored) {
        let usersTabs = [...state.tabsData.data.usersTabs];
        usersTabs.unshift(action.payload?.addApplyBudgetRecored);
        state.tabsData.data.usersTabs = usersTabs;
        state.addTab.success = "applyAward";
      }
      state.addTab.isLoading = false;
      state.addTab.data = action.payload;
      if (action.payload.found?.balance) {
        state.tabsData.data.user.balance = action.payload.found?.balance;
      }
      if (action.payload.found?.marketing_balance) {
        state.tabsData.data.user.marketing_balance = action.payload.found?.marketing_balance;
      }
    },
    [AddAgentTab.rejected]: (state, action) => {
      state.addTab.isLoading = false;
      state.addTab.success = false;
      state.addTab.errMsg = action.payload;
    },
    [getAgentTabs.pending]: (state) => {
      state.tabsData.isLoading = true;
      state.tabsData.errMsg = null;
      state.tabsData.success = false;
    },
    [getAgentTabs.fulfilled]: (state, action) => {
      state.tabsData.isLoading = false;
      state.tabsData.success = true;
      if (action.payload?.pageNumber > 1) {
        state.tabsData.data.usersTabs = state.tabsData.data.usersTabs.concat(action.payload.usersTabs);
      } else {
        state.tabsData.data = action.payload;
      }
    },
    [getAgentTabs.rejected]: (state, action) => {
      state.tabsData.isLoading = false;
      state.tabsData.success = false;
      state.tabsData.errMsg = action.payload;
    },
    // delete agent tab
    [deleteAgentTabs.pending]: (state) => {
      state.deleteTab.isLoading = true;
      state.deleteTab.errMsg = null;
      state.deleteTab.success = false;
    },
    [deleteAgentTabs.fulfilled]: (state, action) => {
      if (action.payload?.addExpenseRecord) {
        let usersTabs = [...state.tabsData.data.usersTabs];
        usersTabs = usersTabs.filter((item) => item.id !== action.payload.deletedId);
        if (action.payload?.addExpenseRecord?.dataValues && action.payload?.addExpenseRecord?.attachments) {
          usersTabs.unshift({ ...action.payload?.addExpenseRecored?.dataValues, attachments: action.payload?.addExpenseRecord?.attachments });
        } else {
          usersTabs.unshift(action.payload?.addExpenseRecord);
        }
        state.tabsData.data.usersTabs = usersTabs;
        state.deleteTab.success = "expense";
      }
      state.deleteTab.isLoading = false;
      state.deleteTab.data = action.payload;
      if (action.payload.found?.balance) {
        state.tabsData.data.user.balance = action.payload.found?.balance;
      }
      if (action.payload.found?.marketing_balance) {
        state.tabsData.data.user.marketing_balance = action.payload.found?.marketing_balance;
      }
    },
    [deleteAgentTabs.rejected]: (state, action) => {
      state.deleteTab.isLoading = false;
      state.deleteTab.success = false;
      state.deleteTab.errMsg = action.payload;
    },
    // update agent tab
    [updateAgentTab.pending]: (state) => {
      state.updateTab.isLoading = true;
      state.updateTab.errMsg = null;
      state.updateTab.success = false;
    },
    [updateAgentTab.fulfilled]: (state, action) => {
      if (action.payload?.addExpenseRecord) {
        let usersTabs = [...state.tabsData.data.usersTabs];
        if (Number(action.payload.amount) === Number(action.payload.debit)) {
          let index = usersTabs.findIndex((item) => item.id === action.payload.updateId);
          if (action.payload?.addExpenseRecord?.dataValues && action.payload?.addExpenseRecord?.attachments) {
            usersTabs[index] = {
              ...action.payload?.addExpenseRecord?.dataValues,
              attachments: [...action.payload.uploadedFiles, ...action.payload?.addExpenseRecord?.attachments],
            };
          } else {
            usersTabs[index] = action.payload?.addExpenseRecord;
          }
        } else {
          usersTabs = usersTabs.filter((item) => item.id !== action.payload.updateId);
          if (action.payload?.addExpenseRecord?.dataValues && action.payload?.addExpenseRecord?.attachments) {
            usersTabs.unshift({
              ...action.payload?.addExpenseRecord?.dataValues,
              attachments: [...action.payload.uploadedFiles, ...action.payload?.addExpenseRecord?.attachments],
            });
          } else {
            usersTabs.unshift(action.payload?.addExpenseRecord);
          }
        }
        state.tabsData.data.usersTabs = usersTabs;
        state.updateTab.success = "expense";
        if (action.payload.found?.balance) {
          state.tabsData.data.user.balance = action.payload.found?.balance;
        }
        if (action.payload.found?.marketing_balance) {
          state.tabsData.data.user.marketing_balance = action.payload.found?.marketing_balance;
        }
      }
      state.updateTab.isLoading = false;
      state.updateTab.data = action.payload;
    },
    [updateAgentTab.rejected]: (state, action) => {
      state.updateTab.isLoading = false;
      state.updateTab.success = false;
      state.updateTab.errMsg = action.payload;
    },
  },
});
export default AgentTabs.reducer;
export * from "./addTab";
export * from "./getTabs";
export * from "./deleteTab";
export * from "./updateTab";
