import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { updateMakeover } from "../../../redux/listings/makeover";

function ProjectApproval(props) {
  let { open, setOpen, classes, makeoverDetail,listingDetail } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [projectDetail, setProjectDetail] = useState({
    max_budget: "",
    description: "",
  });

  const dispatch = useDispatch();
  const updateDetail = useSelector((state) => state.listings.makeover.update);

  useEffect(() => {
    if (open && makeoverDetail.max_budget) {
      setProjectDetail({
        max_budget: makeoverDetail.max_budget,
        description: makeoverDetail.description,
      });
    }
  }, [open]);



  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!projectDetail.max_budget) {
      isFormValid = false;
      errorMsg["max_budget"] = "Please enter the approval amount";
      setErrorAlert({
        errorMsg: "Please enter the approval amount",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleUpdateValue = (type, value) => {
    setProjectDetail({
      ...projectDetail,
      [type]: value,
    });
    setErrMsg({});
  };
  
 const handleSuccess=()=>{
  setErrorAlert({
    errorMsg: "You have successfully update the makeover",
    errorType: "success",
    isOpen: true,
  });
  setOpen(false);
 }
 const handleError=(error)=>{
  setErrorAlert({
    errorMsg: JSON.stringify(error),
    errorType: "error",
    isOpen: true,
  });
 }

  const handleUpdateMakeover = () => {
    if (open === "deny") {
      let obj = {
        status: "unapproved",
        id: makeoverDetail.id,
        contract_id:listingDetail?.transaction_contract?.id ,
        handleSuccess,
        handleError
      };
      dispatch(updateMakeover(obj));
    } else if (open === "approve") {
      if (handleValidate()) {
        let obj = {
          status: "approved",
          id: makeoverDetail.id,
          contract_id:listingDetail?.transaction_contract?.id ,
          handleSuccess,
          handleError,
          ...projectDetail,
        };
        dispatch(updateMakeover(obj));
      }
    } else {
      if (handleValidate()) {
        let obj = {
          id: makeoverDetail.id,
          contract_id:listingDetail?.transaction_contract?.id,
          handleSuccess,
          handleError,
          ...projectDetail,
        };
        dispatch(updateMakeover(obj));
      }
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={Boolean(open)}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogContent sx={{ width: "500px", mt: "20px", mb: "15px" }}>
          {open === "deny" ? (
            "Are you sure you want to  Deny this makeover?"
          ) : (
            <TextField
              value={projectDetail.max_budget}
              onChange={(e) => handleUpdateValue("max_budget", e.target.value)}
              fullWidth
              required
              variant="standard"
              helperText="Approval Amount"
              error={errMsg["max_budget"]}
              type="number"
              id="max_budget"
            />
          )}

          <br />
          <br />
          {open !== "deny" && makeoverDetail?.status === "unapproved" && (
            <TextField
              value={projectDetail.description}
              onChange={(e) => handleUpdateValue("description", e.target.value)}
              fullWidth
              multiline
              minRows={3}
              placeholder="Description of work completed."
              error={errMsg["description"]}
              size="small"
            />
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={updateDetail.isLoading}
            className={classes.saveModalButton}
            color="inherit"
            onClick={handleUpdateMakeover}
            autoFocus
            loadingPosition="start"
          >
            {open === "deny" ? "Deny" : "Submit"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "ProjectApprovalStyle" })(ProjectApproval);
