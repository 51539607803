import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getTextMessages = createAsyncThunk(
  "MessagesAndAlerts/getTextMessages",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/notifications/messages?pageNumber=${data?.pageNumber || 1}&pageSize=${data?.pageSize || 10}&sortOrder=desc`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return {...resp.data,pageNumber:data?.pageNumber,isNewMessage:data?.isNewMessage};
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

