import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");

const initialState = {
  loading: false,
  routing: [],
  errMsg: null,
};

export const getLeadRouting = createAsyncThunk(
  "finance/getLeadRouting",
  async (data, thunkAPI) => {
    try {
      const url = "/api/lead-routing/geographical-data?type=zip_code";
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const leadRouting = createSlice({
  name: "leadRouting",
  initialState,
  extraReducers: {
    [getLeadRouting.pending]: (state) => {
      state.loading = true;
    },
    [getLeadRouting.fulfilled]: (state, action) => {
      state.loading = false;
      state.routing = action.payload.data;
    },
    [getLeadRouting.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default leadRouting.reducer;
