import { createSlice } from "@reduxjs/toolkit";
import { getAllPhoneAudios } from "./getAllAudio";
import { addPhoneAudio } from "./addAudio";
import {deletePhoneAudio} from './deleteAudio'

const initialState = {
  audioList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    count: 0,
  },
  addAudio: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  deleteAudio: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const PhoneAudios = createSlice({
  name: "PhoneAudios",
  initialState,
  extraReducers: {
    [getAllPhoneAudios.pending]: (state, action) => {
      state.audioList.isLoading = true;
      state.audioList.errMsg = null;
      state.audioList.success = false;
      if (action.meta.arg.reset) {
        state.audioList.data = [];
      }
    },
    [getAllPhoneAudios.fulfilled]: (state, action) => {
      state.audioList.success = true;
      state.audioList.isLoading = false;
      state.audioList.data = action.payload;
    },
    [getAllPhoneAudios.rejected]: (state, action) => {
      state.audioList.isLoading = false;
      state.audioList.success = false;
      state.audioList.errMsg = action.payload;
    },

    [addPhoneAudio.pending]: (state) => {
      state.addAudio.isLoading = true;
      state.addAudio.errMsg = null;
      state.addAudio.success = false;
    },
    [addPhoneAudio.fulfilled]: (state, action) => {
      state.addAudio.success = true;
      state.addAudio.isLoading = false;
      state.addAudio.data = action.payload;
      let newData=state.audioList.data?[...state.audioList.data]:[]
      newData.unshift(action.payload)
      state.audioList.data=newData
    },
    [addPhoneAudio.rejected]: (state, action) => {
      state.addAudio.isLoading = false;
      state.addAudio.success = false;
      state.addAudio.errMsg = action.payload;
    },


    [deletePhoneAudio.pending]: (state) => {
      state.deleteAudio.isLoading = true;
      state.deleteAudio.errMsg = null;
      state.deleteAudio.success = false;
    },
    [deletePhoneAudio.fulfilled]: (state, action) => {
      state.deleteAudio.success = true;
      state.deleteAudio.isLoading = false;
      state.deleteAudio.data = action.payload;
      let newData=state.audioList.data?[...state.audioList.data]:[]
      let index=newData.findIndex((item)=>item.id===action.meta?.arg?.id)
      newData.splice(index,1)
      state.audioList.data=newData
    },
    [deletePhoneAudio.rejected]: (state, action) => {
      state.deleteAudio.isLoading = false;
      state.deleteAudio.success = false;
      state.deleteAudio.errMsg = action.payload;
    },

  },
});
export default PhoneAudios.reducer;
export * from "./getAllAudio";
export * from "./addAudio";
export * from "./deleteAudio"
