import React, { useState, useEffect } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { formatAddress } from "../../../../../../../utils/propertyData";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import ShareLocationRoundedIcon from "@mui/icons-material/ShareLocationRounded";
import GoogleMapReact from "google-map-react";
function MapView(props) {
  let { height, classes, disableLoading } = props;
  const [isLocationUnavailable, setIsLocationUnavailable] = useState(false);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [mapLoaded, setMapLoaded] = useState(false);
  const listDetail = useSelector((state) => state.allCmas.listingDetail);

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        address,
      },
      (results, status) => {
        if (status === "OK" && results[0]) {
          const { lat, lng } = results[0].geometry.location;
          if (results[0]?.partial_match) {
            setIsLocationUnavailable(true);
          } else {
            setCenter({ lat: lat(), lng: lng() });
          }
          // setBoundaryCoordinates(boundary);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  };
  useEffect(() => {
    if (listDetail?.data?.Coordinates) {
      let obj = {
        lat:
          typeof listDetail?.data?.Coordinates === "string"
            ? Number(listDetail?.data?.Coordinates?.split(",")?.[0] || 0)
            : listDetail?.data?.Coordinates?.[0],
        lng:
          typeof listDetail?.data?.Coordinates === "string"
            ? Number(listDetail?.data?.Coordinates?.split(",")?.[1] || 0)
            : listDetail?.data?.Coordinates?.[1],
      };
      setCenter(obj);
    }
  }, [listDetail?.data?.Coordinates]);

  const handleMapLoad = (map) => {
    setMapLoaded(true);
    geocodeAddress(
      formatAddress({
        ...listDetail?.data,
        isFullAddressNeeded: true,
      })
    );
  };
  const Marker = ({ text }) => (
    <div
      title={formatAddress({
        ...listDetail?.data,
        isFullAddressNeeded: true,
      })}
      style={{ color: "red" }}
    >
      <ShareLocationRoundedIcon sx={{ fontSize: "50px" }} />
    </div>
  );

  const handleReturnMap = () => {
    return (
      <Box
        sx={{
          height: height ? height : "90vh",
          width: "100%",
          position: "relative",
          border: "1px solid lightgrey",
        }}
      >
        {isLocationUnavailable ? (
          <Box className={classes.locationUnavailableArea}>
            <Typography className={classes.locationUnavailableAreaContent}>
              Map Location is unavailable for this listing.
            </Typography>
          </Box>
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }} // Replace with your Google Maps API key
            defaultCenter={center}
            defaultZoom={17}
            onLoad={handleMapLoad}
          >
            <Marker position={center}></Marker>
          </GoogleMapReact>
        )}
        {!mapLoaded && !disableLoading && (
          <Skeleton
            variant="rectangular"
            sx={{ height: height ? height : "90vh", width: "100%" }}
          />
        )}
      </Box>
    );
  };
  return (
    <Box id="property-map">
      <Box className={classes.mapViewSection}>
        {listDetail?.data?.Coordinates ? handleReturnMap() : null}
      </Box>
    </Box>
  );
}

export default withStyles(styleSheet, { name: "MapViewStyle" })(MapView);
