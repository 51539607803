import { createSlice } from "@reduxjs/toolkit";
import { getCallReportingStats } from "./getCallStats";

const initialState = {
  callStats: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const CallsReportingList = createSlice({
  name: "CallsReportingList",
  initialState,
  extraReducers: {
    [getCallReportingStats.pending]: (state) => {
      state.callStats.isLoading = true;
      state.callStats.errMsg = null;
      state.callStats.success = false;
    },
    [getCallReportingStats.fulfilled]: (state, action) => {
      state.callStats.success = true;
      state.callStats.isLoading = false;
      state.callStats.data = action.payload;
    },
    [getCallReportingStats.rejected]: (state, action) => {
      state.callStats.isLoading = false;
      state.callStats.success = false;
      state.callStats.errMsg = action.payload;
    },
  },
});
export default CallsReportingList.reducer;
export * from "./getCallStats";
