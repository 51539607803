import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const deleteFileAPI = createAsyncThunk("agentProfile/deleteFileAPI", async (data, thunkAPI) => {
  const { fileId,handleSuccessFile,handleError } = data;
  try {
    const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/recruitment/delete-file/${fileId}`, {
      headers: {
        "Content-type": "application/json",
      },
    });
    if (handleSuccessFile) {
        handleSuccessFile();
    }
    return {fileId};
  } catch (error) {
    if (error.response) {
      if (handleError) {
        handleError(error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      if (handleError) {
        handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
