import React from "react";
import { Routes, Route } from "react-router-dom";
import CallsReporting from "./calls";
import AgentActivities from "./agentActivity";
import SubHeader from "./subHeader";
import { Container, Box } from "@mui/material";
const ReportingPage = (props) => {
  return (
    <Box>
      <SubHeader {...props} />
      <Container maxWidth={"lg"}>
        <Routes>
          <Route
            path="/agent-activity"
            element={<AgentActivities {...props} />}
          />
          <Route path="/calls" element={<CallsReporting {...props} />} />
        </Routes>
      </Container>
    </Box>
  );
};
export default ReportingPage;
