import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  TextField,
  InputLabel,
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { getData } from "../../../../utils/getData";
import { postData } from "../../../../utils/postData";
import { useDispatch, useSelector } from "react-redux";
import { handleUpdateUsers } from "../../../../redux/agents/agentRoster/getAgentRosterList";
import { getMarkets } from "../../../../redux/agents/addRecruitAgent";
import NumberFormat from "react-number-format";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { getTeams } from "../../../../redux/teams/team";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { generateTimeOptions } from "../../agentDetails/cards/details/additionalInformation";

const timeOptions = generateTimeOptions();

function AddUserModal(props) {
  let { open, setOpen, classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const markets = useSelector((state) => state.agentList.markets);
  const teamsData = useSelector((item) => item.teams.teams);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState({});
  const [rolesData, setRolesData] = useState([]);
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    personal_email: "",
    password: "",
    role_id: "",
    phone: "",
    market_id: "",
    onboarding_date: "",
    onboarding_coordinator: "",
    onboarding_location: "",
    team_id: "",
  });
  const handleGetRoles = async () => {
    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/users/all-roles`
    );
    if (result.status === 200) {
      setRolesData(result.data);
    }
  };
  useEffect(() => {
    handleGetRoles();
  }, []);

  useEffect(() => {
    if (!teamsData.isLoading && !teamsData?.data?.teams?.length) {
      dispatch(getTeams());
    }
  }, []);

  useEffect(() => {
    if (!markets || markets.length === 0) {
      dispatch(getMarkets());
    }
  }, []);
  const handleUpdateDetail = (field, value) => {
    setUserDetail({ ...userDetail, [field]: value });
    setErrMsg({});
  };
  const handleUpdateDateField = (key, value) => {
    setUserDetail((prev) => {
      let newDate;

      // Check if the existing value is a valid date
      if (
        prev.onboarding_date instanceof Date &&
        !isNaN(prev.onboarding_date)
      ) {
        newDate = new Date(prev.onboarding_date);
      } else {
        newDate = new Date();
      }

      // Update the date or time portion based on the key
      if (key === "onboarding_date_date" && value) {
        newDate.setFullYear(
          value.getFullYear(),
          value.getMonth(),
          value.getDate()
        );
      } else if (key === "onboarding_date_time" && value) {
        newDate.setHours(value.getHours(), value.getMinutes());
      } else {
        newDate = "";
      }

      return { ...prev, onboarding_date: newDate };
    });
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!userDetail.first_name) {
      isFormValid = false;
      errorMsg["first_name"] = "Please enter the first name";
      setErrorAlert({
        errorMsg: "Please enter the first name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.last_name) {
      isFormValid = false;
      errorMsg["last_name"] = "Please enter the last name";
      setErrorAlert({
        errorMsg: "Please enter the last name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.email) {
      isFormValid = false;
      errorMsg["email"] = "Please enter the email";
      setErrorAlert({
        errorMsg: "Please enter the email",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.password) {
      isFormValid = false;
      errorMsg["password"] = "Please enter the password";
      setErrorAlert({
        errorMsg: "Please enter the password",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.market_id) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the market",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["market_id"] = "Please select the market";
    } else if (
      !userDetail.team_id &&
      (rolesData?.find((item) => item.id === userDetail?.role_id)?.name ===
        "Agents" ||
        rolesData?.find((item) => item.id === userDetail?.role_id)?.name ===
          "Admin Agent")
    ) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the team",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["team_id"] = "Please select the team";
    } else if (!userDetail.role_id) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select role",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleAddSubmitUser = () => {
    if (handleValidate()) {
      handleAddUser();
    }
  };
  const handleAddUser = async () => {
    setLoading(true);
    const result = await postData(
      `${process.env.REACT_APP_BASE_URL}api/users`,
      userDetail
    );
    if (result.status === 200) {
      dispatch(handleUpdateUsers(result.data));
      setErrorAlert({
        errorMsg: "You have successfully added the user",
        errorType: "success",
        isOpen: true,
      });
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };

  const handleClose = () => {
    setUserDetail({
      first_name: "",
      last_name: "",
      email: "",
      personal_email: "",
      password: "",
      role_id: "",
      phone: "",
      market_id: "",
      onboarding_date: "",
      onboarding_coordinator: "",
      onboarding_location: "",
      team_id: "",
    });
    setOpen(false);
  };
  const formattedTime =
    userDetail.onboarding_date instanceof Date &&
    !isNaN(userDetail.onboarding_date)
      ? userDetail.onboarding_date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "";

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        sx={{
          "& .MuiPaper-root": { overflow: "visible" },
          "& .MuiDialog-container": { mt: "30px" },
        }}
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className={classes.createUserHeader}
          id="create-user-dialog"
        >
          <Typography className={classes.userHeading}>
            Create New User
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ overflow: "visible", mt: "10px" }}
          >
            <Grid item xs={12}>
              {" "}
              <ButtonGroups
                options={rolesData.map((item) => ({
                  title: item.name,
                  value: item.id,
                }))}
                value={userDetail.role_id}
                onChangeMenu={(value, data) => {
                  if (data?.title === "Agents") {
                    handleUpdateDetail("role_id", value);
                  } else {
                    setUserDetail((preDetail) => ({
                      ...preDetail,
                      role_id: value,
                      onboarding_coordinator: "",
                      onboarding_date: "",
                      onboarding_location: "",
                      team_id:
                        data?.title === "Admin Agent" ? preDetail.team_id : "",
                    }));
                  }
                }}
                id="category-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                color="white"
                placeholder="Role"
                {...props}
              />
            </Grid>
          </Grid>
          {userDetail.role_id ? (
            <Grid
              container
              direction="row"
              spacing={3}
              sx={{ overflow: "visible", mt: "10px" }}
            >
              <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  required
                  error={errMsg.first_name}
                  className={classes.inputLabel}
                >
                  First Name
                </InputLabel>
                <TextField
                  value={userDetail.first_name}
                  onChange={(e) =>
                    handleUpdateDetail("first_name", e.target.value)
                  }
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  placeholder={"First Name"}
                  variant="outlined"
                  size="small"
                  error={errMsg.first_name}
                  helperText={errMsg.first_name}
                />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  required
                  error={errMsg.last_name}
                  className={classes.inputLabel}
                >
                  Last Name
                </InputLabel>
                <TextField
                  value={userDetail.last_name}
                  onChange={(e) =>
                    handleUpdateDetail("last_name", e.target.value)
                  }
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  placeholder={"Last Name"}
                  variant="outlined"
                  size="small"
                  error={errMsg.last_name}
                  helperText={errMsg.last_name}
                />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                <InputLabel className={classes.inputLabel}>
                  Personal Email
                </InputLabel>
                <TextField
                  value={userDetail.personal_email}
                  onChange={(e) =>
                    handleUpdateDetail("personal_email", e.target.value)
                  }
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  placeholder={"Enter personal email"}
                  variant="outlined"
                  size="small"
                  type={"email"}
                  error={errMsg.personal_email}
                  helperText={errMsg.personal_email}
                />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  required
                  error={errMsg.email}
                  className={classes.inputLabel}
                >
                  Method Email
                </InputLabel>
                <TextField
                  value={userDetail.email}
                  onChange={(e) => handleUpdateDetail("email", e.target.value)}
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  placeholder={"Enter email"}
                  variant="outlined"
                  size="small"
                  type={"email"}
                  error={errMsg.email}
                  helperText={errMsg.email}
                />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                <InputLabel className={classes.inputLabel}>
                  Phone Number
                </InputLabel>
                <NumberFormat
                  format={"###-###-####"}
                  mask="_"
                  className={classes.customPhoneField}
                  onChange={(e) => {
                    let number = userDetail.phone.split("-").join("").trim();
                    if (number.length < 12) {
                      handleUpdateDetail("phone", e.target.value);
                    }
                  }}
                  displayType="input"
                  placeholder={"Enter phone no"}
                  autoFocus
                />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  required
                  error={errMsg.password}
                  className={classes.inputLabel}
                >
                  Password
                </InputLabel>
                <TextField
                  value={userDetail.password}
                  onChange={(e) =>
                    handleUpdateDetail("password", e.target.value)
                  }
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  placeholder={"Enter password"}
                  variant="outlined"
                  size="small"
                  type={"password"}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  name="password"
                  autoComplete={false}
                  id="user-password"
                  error={errMsg.password}
                  helperText={errMsg.password}
                />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  className={classes.inputLabel}
                  error={errMsg.market_id}
                  required
                >
                  Market
                </InputLabel>
                <Autocomplete
                  disablePortal
                  id="market-select-dropdown"
                  value={
                    userDetail.market_id
                      ? markets?.find(
                          (item) => item.id === userDetail.market_id
                        )
                      : undefined
                  }
                  onChange={(event, value) =>
                    handleUpdateDetail("market_id", value.id)
                  }
                  getOptionLabel={(option) => option.name}
                  options={markets?.length ? markets : []}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Market"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={errMsg.market_id}
                      helperText={errMsg.market_id}
                      classes={{ root: classes.detailInput }}
                    />
                  )}
                />
              </Grid>
              {rolesData?.find((item) => item.id === userDetail?.role_id)
                ?.name === "Agents" ||
              rolesData?.find((item) => item.id === userDetail?.role_id)
                ?.name === "Admin Agent" ? (
                <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
                  <InputLabel className={classes.inputLabel} required>
                    Team
                  </InputLabel>
                  <Autocomplete
                    disablePortal
                    id="team-select-dropdown"
                    value={
                      userDetail.team_id
                        ? teamsData?.data?.teams?.find(
                            (item) => item.id === userDetail.team_id
                          )
                        : undefined
                    }
                    onChange={(event, value) =>
                      handleUpdateDetail("team_id", value.id)
                    }
                    getOptionLabel={(option) => option.name}
                    options={
                      teamsData?.data?.teams?.length
                        ? teamsData?.data?.teams
                        : []
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Team"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={errMsg.team_id}
                        helperText={errMsg.team_id}
                        classes={{ root: classes.detailInput }}
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {rolesData?.find((item) => item.id === userDetail?.role_id)
                ?.name === "Agents" ? (
                <Grid item lg={6} xs={12}>
                  <InputLabel className={classes.inputLabel}>
                    Onboarding Date{" "}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      onChange={(value) =>
                        handleUpdateDateField("onboarding_date_date", value)
                      }
                      value={userDetail.onboarding_date || null}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          classes={{ root: classes.detailInput }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              ) : null}
              {rolesData?.find((item) => item.id === userDetail?.role_id)
                ?.name === "Agents" ? (
                <Grid item lg={6} xs={12}>
                  <InputLabel className={classes.inputLabel}>
                    Onboarding Coordinator
                  </InputLabel>
                  <Autocomplete
                    disablePortal
                    id="onboarding-coordinator-dropdown"
                    value={
                      userDetail.onboarding_coordinator
                        ? storedListOfUsers.list?.find(
                            (item) =>
                              item.id === userDetail.onboarding_coordinator
                          )
                        : undefined
                    }
                    onChange={(event, value) =>
                      handleUpdateDetail("onboarding_coordinator", value.id)
                    }
                    getOptionLabel={(option) => option.full_name}
                    options={
                      storedListOfUsers?.list
                        ? storedListOfUsers.list?.filter(
                            (item) =>
                              item?.role?.name === "Team Leader" ||
                              item?.full_name === "Audrey MacKay" ||
                              item?.id === 82
                          )
                        : []
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Onboarding Coordinator"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={errMsg.onboarding_coordinator}
                        helperText={errMsg.onboarding_coordinator}
                        classes={{ root: classes.detailInput }}
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {rolesData?.find((item) => item.id === userDetail?.role_id)
                ?.name === "Agents" ? (
                <Grid item lg={6} xs={12}>
                  <InputLabel className={classes.inputLabel}>
                    Onboarding Time{" "}
                  </InputLabel>
                  <TextField
                    disabled={userDetail?.onboarding_date ? false : true}
                    select
                    fullWidth
                    value={formattedTime || ""}
                    onChange={(event) => {
                      const timeValue = event.target.value;
                      const [hour, minute] = timeValue.split(/[:\s]/);
                      const isPM = timeValue.includes("PM");
                      const date = new Date(
                        userDetail.onboarding_date || new Date()
                      );
                      date.setHours(
                        isPM && hour !== "12"
                          ? parseInt(hour, 10) + 12
                          : parseInt(hour, 10),
                        parseInt(minute, 10)
                      );
                      handleUpdateDateField("onboarding_date_time", date);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    classes={{ root: classes.detailInput }}
                  >
                    {timeOptions.map((time) => (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : null}
              {rolesData?.find((item) => item.id === userDetail?.role_id)
                ?.name === "Agents" ? (
                <Grid item lg={6} xs={12}>
                  <InputLabel className={classes.inputLabel}>
                    Onboarding Location
                  </InputLabel>
                  <TextField
                    fullWidth
                    onChange={(e) =>
                      handleUpdateDetail("onboarding_location", e.target.value)
                    }
                    value={userDetail.onboarding_location}
                    select
                    classes={{ root: classes.detailInput }}
                    size="small"
                  >
                    <MenuItem value={"virtually-slack"}>
                      Virtually - Slack
                    </MenuItem>
                    <MenuItem value={"in-person"}>In-Person</MenuItem>
                  </TextField>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <LoadingButton
                  onClick={() => handleAddSubmitUser()}
                  classes={{ root: classes.commisionSelectionButTon }}
                  color="inherit"
                  autoFocus
                  loadingPosition="start"
                  loading={loading}
                  fullWidth
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddUserModalStyle" })(
  AddUserModal
);
