import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { deletePhoneAudio } from "../../../redux/phoneConfiguration/audios/deleteAudio";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "17px!important",
    color: "#000000DE",
  },
  createButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
  },
  closeButton: {
    color: "#0B0909!important",
  },
}));

function ConfirmModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const deleteAudio = useSelector((item) => item.phoneConfiguration.PhoneAudios.deleteAudio);

  useEffect(() => {
    if (deleteAudio.success && !deleteAudio.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully deleted the audio",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
    } else if (!deleteAudio.isLoading && deleteAudio.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(deleteAudio.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deleteAudio.success]);

  const { open, setOpen } = props;

  const handleConfirm = () => {
    dispatch(deletePhoneAudio({ id: open.id }));
  };

  return (
    <React.Fragment>
      <Dialog disablePortal open={open}>
        <DialogTitle className={classes.title}>Confirmation</DialogTitle>
        <DialogContent sx={{ minHeight: "200px" }}>
          <center>
            <RestoreFromTrashIcon sx={{ fontSize: "110px", color: "red" }} />
          </center>
          Are you sure you want to delete this audio?
        </DialogContent>
        <DialogActions>
          <Button className={classes.closeButton} onClick={() => setOpen(false)}>
            No
          </Button>
          <LoadingButton
            onClick={() => handleConfirm()}
            classes={{ root: classes.createButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={deleteAudio.isLoading}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default ConfirmModal;
