import React, { useState } from "react";
import { Box, Typography, Dialog, IconButton } from "@mui/material";
import Avatar from "../../components/uiElements/avatar";
import pdfIcon from "../../assets/images/pdfIcon.png";
import { CustomTooltip } from "../../components/shared/tooltip";
import momentTZ from "moment-timezone";
import { useSelector } from "react-redux";
import ImageIcon from "@mui/icons-material/Image";
import ImgsViewer from "react-images-viewer";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const checkFileType = (url) => {
  return url.substring(url.lastIndexOf(".") + 1).toLowerCase();
};

function EmailDetail(props) {
  let { classes, openedEmail } = props;
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [pdfOpen, setPdfOpen] = useState(false);
  const adminDetail = useSelector((state) => state.adminAuth.adminDetail);

  const renderAttachment = (url) => {
    const fileType = checkFileType(url);

    if (fileType === "pdf") {
      return (
        <div
          style={{
            overflow: "hidden !important",
            height: "152px",
            width: "230px",
            cursor: "pointer",
            position: "relative",
            padding: "8px",
            paddingBottom: "0px",
          }}
        >
          <embed
            src={url}
            type="application/pdf"
            style={{
              width: "calc(100% + 16px)",
              height: "100%",
              minHeight: "100%",
              zIndex: -1,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "230px",
              height: "152px",
              cursor: "pointer",
              zIndex: 999,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "10px",
              height: "152px",
              cursor: "pointer",
              zIndex: 999,
              background: "white",
            }}
          ></div>
        </div>
      );
    } else if (
      fileType === "png" ||
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "gif"
    ) {
      return (
        <img
          src={url}
          alt="attachment"
          style={{
            padding: "8px",
            paddingBottom: "0px",
            height: "152px",
            width: "100%",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      );
    } else {
      return <p>Unsupported file format</p>;
    }
  };

  const handleReturnDate = (date) => {
    const createdAtMoment = momentTZ.tz(
      date,
      adminDetail?.market?.timezone || process.env.REACT_APP_TIMEZONE
    );

    const currentYear = momentTZ().year();
    const emailYear = createdAtMoment.year();

    const formattedDate = createdAtMoment.format("MMM Do");

    // Conditionally add the year if it's different from the current year
    const displayDate =
      emailYear === currentYear
        ? formattedDate
        : `${createdAtMoment.format("MMM Do YY")}`;

    return displayDate;
  };
  return (
    <Box className={classes.emailDetailSectionInner}>
      {" "}
      <Box className={classes.DetailTopSection}>
        <Box className={classes.detailMainHeader}>
          <Typography className={classes.detailMainHeading}>
            {openedEmail.subject}
          </Typography>
          <CustomTooltip
            placement="bottom"
            title={`Sent by ${openedEmail?.sentBy?.full_name}`}
          >
            {" "}
            <Avatar
              variant="circle"
              sx={{ width: "35px", height: "35px", fontSize: "13px" }}
              src={
                openedEmail?.sentBy?.profile_images?.profile_img ||
                openedEmail?.sentBy?.profile_images?.profile_img_thumbnail
              }
            >
              {`${openedEmail?.sentBy?.full_name?.slice(
                0,
                1
              )}${openedEmail?.sentBy?.full_name
                ?.split(" ")?.[1]
                ?.slice(0, 1)}`}
            </Avatar>
          </CustomTooltip>
        </Box>
        <br />
        <Box className={classes.itemHeader}>
          <Box className={classes.itemHeaderLeft}>
            <Avatar
              variant="circle"
              sx={{ width: "40px", height: "40px", fontSize: "14px" }}
              src={
                openedEmail?.sentAs?.profile_images?.profile_img ||
                openedEmail?.sentAs?.profile_images?.profile_img_thumbnail
              }
            >
              {`${openedEmail?.sentAs?.full_name?.slice(
                0,
                1
              )}${openedEmail?.sentAs?.full_name
                ?.split(" ")?.[1]
                ?.slice(0, 1)}`}
            </Avatar>
            <Box className={classes.profileDetail}>
              <Typography className={classes.subjectProfileDetailName}>
                {openedEmail?.sentAs?.full_name}{" "}
                <span>{`<${openedEmail?.sentAs?.email}>`}</span>
              </Typography>
              <Typography className={classes.subjectDetailAddress}>
                to {openedEmail?.to}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "13px !important" }}
            className={classes.itemHeaderRight}
          >
            {momentTZ
              .tz(
                openedEmail?.createdAt,
                adminDetail?.market?.timezone || process.env.REACT_APP_TIMEZONE
              )
              .format("LT")}{" "}
            {handleReturnDate(openedEmail?.createdAt)}
          </Typography>
        </Box>
        <br />
        <Typography
          dangerouslySetInnerHTML={{
            __html: openedEmail.body,
          }}
        ></Typography>
      </Box>
      {openedEmail?.attachments?.length ? (
        <Box className={classes.attachmentSection}>
          <Typography className={classes.attachmentHeading}>
            Attachments
          </Typography>
          <Box
            className={classes.attachments}
            sx={{
              overflowX:
                openedEmail?.attachments?.length > 2 ? "scroll" : "auto",
            }}
          >
            {openedEmail?.attachments?.map((item, index) => (
              <Box
                key={index}
                className={classes.attachmentsItem}
                onClick={() => {
                  if (checkFileType(item.file_path) === "pdf") {
                    setPdfOpen(true);
                  } else {
                    setImageViewerOpen(true);
                  }
                  setSelectedMedia(item);
                }}
              >
                {renderAttachment(item.file_path)}
                <Box className={classes.attachmentTitleArea}>
                  {checkFileType(item.file_path) === "pdf" ? (
                    <img src={pdfIcon} width={25} height={25} alt={pdfIcon} />
                  ) : (
                    <ImageIcon sx={{ color: "#009FD1" }} />
                  )}
                  <Typography className={classes.attachmentTitleContent}>
                    {item.file}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
      <ImgsViewer
        imgs={[{ src: selectedMedia?.file_path }]}
        isOpen={imageViewerOpen}
        currImg={0}
        showThumbnails={true}
        enableKeyboardInput={true}
        onClose={() => setImageViewerOpen(false)}
      />
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiPaper-root": { borderRadius: "10px", overflow: "visible" },
        }}
        open={pdfOpen}
        onClose={() => setPdfOpen(false)}
        aria-labelledby="pdf-dialog-preview"
        aria-describedby="pdf-dialog-preview-description"
        fullWidth
        maxWidth="lg"
      >
        <Box sx={{ position: "relative" }}>
          <IconButton
            className={classes.closeIcon}
            onClick={() => setPdfOpen(false)}
            sx={{
              background: "lightgrey",
              right: "-40px !important",
              border: "2px solid white",
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <embed
          src={selectedMedia.file_path}
          type="application/pdf"
          style={{
            width: "100%",
            height: "100vh",
            overflow: "hidden !important",
            padding: "8px",
            paddingBottom: "0px",
            minHeight: "100%",
          }}
        />
      </Dialog>
    </Box>
  );
}

export default EmailDetail;
