import React, { useState, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Stack, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAgentPoints } from "../../../redux/agents/agentRosterDetail/points";
import TableSkeleton from "../../../components/loader/tableLoader.jsx";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { useNavigate } from "react-router-dom";
function PointsHistory(props) {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
  });
  const navigate = useNavigate();
  let { classes, adminDetail } = props;
  const dispatch = useDispatch();
  const pointsData = useSelector(
    (state) => state.agentRoster.points.pointsData
  );

  useEffect(() => {
    if (adminDetail.id) {
      let startDate =
        moment().startOf("month").format().slice(0, 11) + "00:00:01";
      let endDate = moment().endOf("month").format().slice(0, 11) + "23:23:59";
      dispatch(
        getAgentPoints({
          user_id: adminDetail.id,
          startDate,
          endDate,
          ...filters,
        })
      );
    }
  }, [adminDetail.id]);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      renderCell: ({ row }) => moment(row.createdAt).format("l"),
      minWidth: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "Activity",
      headerName: "Activity",
      renderCell: ({ row }) => {
        return (
          <Box>
            <Box sx={{ fontWeight: "500", display: "inline-block" }}>
              {row?.title || row?.action}:
            </Box>{" "}
            {row.description}
          </Box>
        );
      },
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "points",
      headerName: "Points",
      renderCell: ({ row }) => {
        return row.point > 0 ? (
          <Box sx={{ color: row.point > 0 ? "#2E7D32" : "#DA0000" }}>
            {row.point > 0 ? "+" : "-"}
            {row.point}
          </Box>
        ) : (
          ""
        );
      },
      minWidth: 80,
      editable: false,
      sortable: true,
    },
  ];

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ mb: "-15px" }}
      >
        <Typography>Recent Activity </Typography>
        <Typography
          className={classes.seeMoreArePoints}
          onClick={() => navigate(`/dashboard/points`)}
        >
          See More <EastRoundedIcon fontSize="small" sx={{ ml: "6px" }} />{" "}
        </Typography>
      </Stack>
      <Box className={classes.tableRoot}>
        <DataGridPro
          rows={pointsData.data}
          columns={columns}
          loading={pointsData.isLoading}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          hideFooter
          disableSelectionOnClick
          rowHeight={46}
        />
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "pointsHistoryStyle",
})(PointsHistory);
