import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import UserSelection from "./userSelection";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { addTask } from "../../../redux/transactions/addTask";

function AddTask(props) {
  let { open, setOpen, classes, id } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [taskDetail, setTaskDetail] = useState({
    // type: "Follow up",
    assigned_to: "",
    name: "",
    due_date: "",
    contract_id: "",
  });
  const dispatch = useDispatch();
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const addTaskResponse = useSelector((item) => item.transactions.Tasks.addTask);

  useEffect(() => {
    if (addTaskResponse.success && !addTaskResponse.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the task",
        errorType: "success",
        isOpen: true,
      });
      setTaskDetail({ assigned_to: "", name: "", due_date: "" });
      setOpen(false);
    } else if (!addTaskResponse.isLoading && addTaskResponse.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(addTaskResponse.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addTaskResponse.success]);

  const handleUpdateDetail = (field, value) => {
    setTaskDetail({ ...taskDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!taskDetail.name) {
      isFormValid = false;
      errorMsg["name"] = "Please enter the task description";
      setErrorAlert({
        errorMsg: "Please enter the task description",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!taskDetail.due_date) {
      isFormValid = false;
      errorMsg["due_date"] = "Please select due date";
      setErrorAlert({
        errorMsg: "Please select due date",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!taskDetail.assigned_to) {
      isFormValid = false;
      errorMsg["assigned_to"] = "Please select the user";
      setErrorAlert({
        errorMsg: "Please select the user",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSelected = (value) => {
    handleUpdateDetail("assigned_to", value);
  };

  const handleAddTask = () => {
    if (handleValidate()) {
      dispatch(addTask({ ...taskDetail, contract_id: id }));
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-titlesd">
          Create Task
        </DialogTitle>
        <DialogContent sx={{ width: "700px", minHeight: "40vh" }}>
          <br />
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sx={{ zIndex: "1", marginBottom: "20px" }}>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={2}
                placeholder="Task Description"
                variant="outlined"
                size="small"
                value={taskDetail.name}
                onChange={(e) => handleUpdateDetail("name", e.target.value)}
                error={errMsg.name}
                helperText={errMsg.name}
              />
            </Grid>

            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Date & Time
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                value={taskDetail.due_date}
                onChange={(e) => handleUpdateDetail("due_date", e.target.value)}
                error={errMsg.due_date}
                helperText={errMsg.due_date}
                type="datetime-local"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1", marginTop: "20px" }}>
              <UserSelection value={taskDetail.assigned_to} options={agentRosterList.list} handleSelected={handleSelected} {...props} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={addTaskResponse.isLoading}
            className={classes.saveModalButton}
            color="inherit"
            onClick={() => handleAddTask()}
            autoFocus
            loadingPosition="start"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddTaskStyle" })(AddTask);
