import React from "react";
import { Box, Grid, ListItemText, Paper, Stack, Skeleton } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
const ListingHeaderCardSkelton = (props) => {
  let { classes, disableRemarks,disableShadow } = props;
  return (
    <Box>
      <Paper sx={{ backgroundColor: "white" }} evaluation={disableShadow?0:""} className={classes.pageRoot}>
        <Grid container direction="row">
          <Grid md={8} lg={9} xs={12} className={classes.TransactionUserInfoLeftPanel}>
            <Skeleton variant="rounded" width="260px" height="210px" animation="wave" />
            <Box sx={{ ml: "20px" }}>
              <Skeleton variant="rounded" sx={{ mb: "5px" }} width="170px" height="30px" animation="wave" />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Skeleton variant="rounded" width="150px" height="20px" animation="wave" />
              </Box>
              <Box sx={{ mt: "10px" }}>
                <Skeleton variant="rounded" width="80px" height="20px" animation="wave" />
              </Box>
              <Grid container direction="row" alignItems="center" spacing={3} sx={{ mt: "10px" }}>
                <Grid item>
                  <ListItemText
                    primary={<Skeleton variant="rounded" sx={{ mb: "5px" }} width="80px" height="20px" animation="wave" />}
                    secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                  />
                </Grid>
                <Grid item>
                  <ListItemText
                    primary={<Skeleton sx={{ mb: "5px" }} variant="rounded" width="80px" height="20px" animation="wave" />}
                    secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                  />
                </Grid>
                <Grid item>
                  <ListItemText
                    primary={<Skeleton sx={{ mb: "5px" }} variant="rounded" width="80px" height="20px" animation="wave" />}
                    secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                  />
                </Grid>
                <Grid item>
                  <ListItemText
                    primary={<Skeleton sx={{ mb: "5px" }} variant="rounded" width="80px" height="20px" animation="wave" />}
                    secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                  />
                </Grid>
                {disableRemarks ? null : (
                  <Grid item>
                    <ListItemText
                      primary={<Skeleton sx={{ mb: "5px" }} variant="rounded" width="80px" height="20px" animation="wave" />}
                      secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid md={4} lg={3} xs={12} className={classes.userInfoRightPanel}>
            <Stack direction="row" justifyContent={"flex-end"} alignItems="center" spacing={1}>
              <Box sx={{ display: "inline-block", marginRight: "12px", fontSize: "12px" }}>
                <Skeleton variant="rounded" width="50px" height="25px" animation="wave" />
              </Box>
              <Skeleton variant="rounded" width="40px" height="40px" animation="wave" />
              <Skeleton variant="rounded" width="40px" height="40px" animation="wave" />
              <Skeleton variant="rounded" width="40px" height="40px" animation="wave" />
            </Stack>
            <Stack direction="row" sx={{ mt: "10px" }} justifyContent={"flex-end"} alignItems="center" spacing={1}>
              <Skeleton variant="rounded" width="120px" height="25px" animation="wave" />
            </Stack>
          </Grid>
          <Grid md={4} lg={3}></Grid>
          {disableRemarks ? null : (
            <Grid md={8} lg={9}>
              {" "}
              <Box sx={{ fontSize: "14px", mt: "-20px" }}>
                <Skeleton variant="rounded" width="100%" height="40px" animation="wave" />
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};
export default withStyles(styleSheet, { name: "ListingHeaderCardSkeltonStyle" })(ListingHeaderCardSkelton);
