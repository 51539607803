import React, { useState } from "react";
import { Box, Typography, Card, Grid, TextField,MenuItem } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import { useSelector } from "react-redux";

const InterestedParties = (props) => {
  let { classes, updateContractDetail, contractDetail, errMsg } = props;
  const propertyList = useSelector((item) => item.propertyList);
  return (
    <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible" }}>
      <Box id="property_type" className={classes.transactionFormCardTop}>
        <Typography classes={{ root: classes.transactionFormCardHeading }} sx={{ mr: "20px" }}>
          Property Information
        </Typography>
        <ButtonGroups
          id="category-button-menu"
          bgColor="#0B0909"
          bgColorHover="#0B0909"
          placement={"bottom"}
          color="white"
          minWidth="140px"
          width="140px"
          fontSize="12.5px"
          options={[
            { title: "Condo", value: "condo" },
            { title: "Lot", value: "lot" },
            { title: "Land", value: "land" },
            { title: "Single Family", value: "single_family" },
            { title: "Town home", value: "town_home" },
          ]}
          value={contractDetail.property_type}
          onChangeMenu={(value) => updateContractDetail("property_type", value)}
          placeholder="Select Type"
          {...props}
        />
        <Box sx={{ color: "red" }}>{errMsg?.property_type}</Box>
      </Box>
      <Grid container spacing={4} className={classes.transactionFormArea}>
      { (contractDetail.contract_type==="seller" || contractDetail.contract_type==="landlord") && 
        <Grid item xs={4}><TextField
        id="attached_listing"
        fullWidth
        onChange={(e) => {
          updateContractDetail("attached_listing", `${e.target.value}`);
        }}
        value={contractDetail.attached_listing}
        select
        variant="standard"
        label="Select Listing"
        required
        size="small"
      >
        {propertyList?.list?.data &&
          propertyList?.list?.data.length > 0 &&
          propertyList?.list?.data?.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.property_address}
            </MenuItem>
          ))}
      </TextField></Grid>
}
        <Grid item xs={3}>
          <TextField
            value={contractDetail.street}
            onChange={(e) => updateContractDetail("street", e.target.value)}
            fullWidth
            required
            variant="standard"
            placeholder="123 Main Street"
            helperText="Property Address"
            error={errMsg["street"]}
            id="street"
          />
        </Grid>
        {contractDetail.property_type === "condo" && (
          <Grid item xs={2}>
            <TextField
              value={contractDetail.unit_number}
              onChange={(e) => updateContractDetail("unit_number", e.target.value)}
              fullWidth
              variant="standard"
              placeholder="GA"
              helperText="Unit Number"
              error={errMsg["unit_number"]}
              id="unit_number"
            />
          </Grid>
        )}
        {contractDetail.property_type === "lot" && (
          <Grid item xs={2}>
            <TextField
              value={contractDetail.lot_number}
              onChange={(e) => updateContractDetail("lot_number", e.target.value)}
              fullWidth
              variant="standard"
              placeholder="GA"
              helperText="Lot Number"
              id="lot_number"
              error={errMsg["lot_number"]}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <TextField
            value={contractDetail.city}
            onChange={(e) => updateContractDetail("city", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="Atlanta"
            helperText="City"
            name="city"
            error={errMsg["city"]}
            id="city"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={contractDetail.state}
            onChange={(e) => updateContractDetail("state", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="GA"
            name="state"
            helperText="State"
            error={errMsg["state"]}
            id="state"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={contractDetail.zipcode}
            onChange={(e) => updateContractDetail("zipcode", e.target.value)}
            fullWidth
            variant="standard"
            name="zipCode"
            placeholder="30082"
            helperText="Zip"
            error={errMsg["zipcode"]}
            id="zipcode"
          />
        </Grid>
      </Grid>
    </Card>
  );
};
export default withStyles(styleSheet, { name: "InterestedPartiesStyle" })(InterestedParties);
