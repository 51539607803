export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "30px 30px 9px 30px",
    marginBottom: "16px",
  },
  customTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
  },
  userInfoLeftPanel: {
    display: "flex",
    "& .MuiAvatar-root": {
      position: "relative",
      width: "160px",
      height: "160px",
      marginRight: "30px",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
  },
  userInfoLeftName: {
    fontSize: "20px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  userInfoDetailPhone: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  userInfoDetailEmail: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "lowercase !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  userInfoDetailStateDes: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)",
  },
  userInfoDetailStateValue: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  customerTeamChip: {
    marginTop: "10px",
    background: "rgba(102, 187, 106, 0.5)",
    color: "white !important",
    height: "25px !important",
    fontSize: "14px !important",
  },
  statusChip: {
    background: "rgba(102, 187, 106, 0.5)",
    color: "white !important",
    height: "25px !important",
    fontSize: "12px !important",
  },
  customMarketChip: {
    marginTop: "10px",
    background: "#9ADBFF",
    color: "white !important",
    height: "25px !important",
    fontSize: "14px !important",
  },
  userInfoChipsItem: {
    display: "flex !important",
    justifyContent: "flex-end  !important",
    alignItems: "center  !important",
    gap: "0px 10px  !important",
  },
  userInfoContactButtons: {
    display: "flex !important",
    justifyContent: "flex-end  !important",
    gap: "0px 10px  !important",
  },
  userInfoChips: {},
  userInfoRightPanel: {
    textAlign: "right",
    "& .MuiButton-root": {
      width: "40px!important",
      minWidth: "40px!important",
      height: "40px",
      backgroundColor: "white",
      color: "black",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid rgba(33, 33, 33, 0.4) !important",
    },
  },
  userInfoRightPanelInner: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "space-between !important",
    height: "100% !important",
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
});
