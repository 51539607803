import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const deleteNote = createAsyncThunk("AgentNotes/deleteNote", async (data, thunkAPI) => {
  try {
    const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/users/note/${data.userId}/${data.noteId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (data?.handleSuccess) {
      data.handleSuccess();
    }
    return { ...resp.data, noteId: data.noteId };
  } catch (error) {
    if (error.response) {
      data.handleError(error.response.data.errorMessage || error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      data.handleError(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
