import * as React from "react";
import KnowledgeBaseMain from "./main";
import KnowledgeBaseDetail from "./main/detail";
import { Route, Routes } from "react-router-dom";
const KnowledgeBase = () => {
  return (
    <Routes>
      <Route path="/main" element={<KnowledgeBaseMain />} />
      <Route path="main/:id" element={<KnowledgeBaseDetail />} />
    </Routes>
  );
};

export default KnowledgeBase;
