import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAllTransactions = createAsyncThunk(
  "TransactionsList/getAllTransactions",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/transactions/contracts?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&search=${data.search?data.search:""}&filterByType=${data.filterByType}&sortBy=${data.sortBy || ''}&sortOrder=desc&status=${data.status || 'all'}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

