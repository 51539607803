import React, { useState } from "react";
import { Card, CardContent, CardHeader, ListItemText, Button, Grid, Stack } from "@mui/material";
import { styleSheet } from "./style";
import CheckPayment from "../../modals/checkPayment";
import { withStyles } from "@mui/styles";
import { NumberFormat } from "../../../../utils/numberFormat";
import moment from "moment"
import {useSelector} from "react-redux"
function MakeOverRePayment(props) {
  const [openCheck, setOpenCheck] = useState(false);
  const ListingData = useSelector((state) => state.listings.listingDetail.ListingData);
  let { classes, makeoverDetail } = props;
  return (
    <Card sx={{ backgroundColor: "white", mt: "20px" }} className={classes.TransactionSalesRoot}>
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title={"Repayment Details"}
        action={
          makeoverDetail?.repayment_amount?null:
          <Button onClick={() => setOpenCheck(true)} variant="outlined" color="inherit" size="small">
            CHECK Payment
          </Button>
        }
      />
      <CardContent>
        <Grid container>
          <Grid item sm={12} md={8}>
            <Stack direction={"row"} spacing={2}>
              <ListItemText
                sx={{ "& .MuiListItemText-secondary": { color: makeoverDetail?.repayment_status==="paid"?"green":"red", fontWeight: "500",fontSize:"13px" ,textTransform:"uppercase"},"& .MuiListItemText-primary":{mt:"-5px"} }}
                classes={{ primary: classes.makeOverListingHeading }}
                primary="Repayment Status: "
                secondary={makeoverDetail?.repayment_status}
              />
              <ListItemText classes={{ primary: classes.makeOverListingHeading }} primary="Closing Date:" secondary={ListingData?.data?.transaction_contract?.closing_date?moment(ListingData?.data?.transaction_contract?.closing_date).format('L'):""} />
              <ListItemText
                classes={{ primary: classes.makeOverListingHeading }}
                primary="Repayment Amount:"
                secondary={
                  makeoverDetail?.repayment_amount ? NumberFormat({ number: makeoverDetail?.repayment_amount, maximumFractionDigits: 2 }) : ""
                }
              />
              <ListItemText classes={{ primary: classes.makeOverListingHeading }} primary="Payment Method:" secondary={makeoverDetail?.repayment_amount?makeoverDetail?.repayment_method==="check_payment"?"Via Check":"At Closing":""} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CheckPayment open={openCheck} makeoverDetail={makeoverDetail} setOpen={setOpenCheck} />
    </Card>
  );
}
export default withStyles(styleSheet, {
  name: "MakeOverRePaymentStyle",
})(MakeOverRePayment);
