import React from "react";
import { Box,Paper } from "@mui/material";
import ListingDocPanel from "./documentPanel";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import ViewCarouselRoundedIcon from "@mui/icons-material/ViewCarouselRounded";
function Documents(props) {
  const handleCheckDocumentType = (type) => {
    if (props.ListingData?.transaction_documents?.filter((item) => item.document?.type === type)?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
      (props.ListingData?.transaction_documents?.length===0 || !props.ListingData?.transaction_documents )?<DataNotFound
      color="#BDBDBD"
      fontSize="26px"
      title="No Documents Exists!"
      component={Paper}
      icon={<ViewCarouselRoundedIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
    />:
    <Box>
      {handleCheckDocumentType("sales") && <ListingDocPanel title="Sales Documents" type="sales" {...props} />}
      {handleCheckDocumentType("miscellaneous") && <ListingDocPanel title="Misc Documents" type="miscellaneous" {...props} />}
      {handleCheckDocumentType("listing") && <ListingDocPanel title="Listing Documents" type="listing" {...props} />}
      {handleCheckDocumentType("closing") && <ListingDocPanel title="Closing Documents" type="closing" {...props} />}
    </Box>
  );
}
export default Documents;
