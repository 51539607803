import axios from "../../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  data: [],
  errMsg: null,
};

export const getActionPlans = createAsyncThunk("actionPlans/getActionPlans", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/leads/actionPlans`, {
      headers: { Authorization: "Bearer " + localStorage.token, "Content-Type": "application/json" },
    });
    return resp.data?.actionPlans;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const actionPlans = createSlice({
  name: "actionPlans",
  initialState,
  extraReducers: {
    [getActionPlans.pending]: (state) => {
      state.loading = true;
    },
    [getActionPlans.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getActionPlans.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default actionPlans.reducer;
