import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTeams = createAsyncThunk("teams/getTeams", async (data, thunkAPI) => {
  let pageNumber = 1;
  let pageSize = 100;

  if (data) {
    pageNumber = data.pageNumber;
    pageSize = data.pageSize;
  }

  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/teams?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=id&sortOrder=desc`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { data: resp.data?.data, pageSize, pageNumber};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const createTeam = createAsyncThunk("teams/createTeam", async (data, thunkAPI) => {
  try {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}api/teams`,
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      data
    };

    const resp = await axios(config);
    return {...resp.data.data,team_leader:data.team_leader};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const updateTeam = createAsyncThunk("teams/updateTeam", async (data, thunkAPI) => {
  try {
    const { id, value } = data;

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}api/teams/${id}`,
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      data: value
    };

    const resp = await axios(config);
    return {...value,id};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});


const initialState = {
  success: false,
  data: { count: 0, teams: []},
  isLoading: false,
  errMsg: null,
  addTeam:{
    isLoading: false,
    errMsg: null,
    success: false,
  },
  updateTeam:{
    isLoading: false,
    errMsg: null,
    success: false,
  },
};

const teams = createSlice({
  name: "teams",
  initialState,
  reducers: {
    updateAttachedPlan: (state, action) => {
       let attachedPlan= state.data?[...state.data]:[];
       attachedPlan.push(action.payload) 
      state.data = attachedPlan;
    },
  },
  extraReducers: {
    [getTeams.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getTeams.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.data = action.payload.pageSize === 15 && action.payload.pageNumber !== 1? 
        {count: action.payload.data.count, teams: [...state.data?.teams, ...action.payload.data?.teams]} :
        action.payload.data
    },
    [getTeams.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
    // add team
    [createTeam.pending]: (state) => {
      state.addTeam.isLoading = true;
      state.addTeam.errMsg = null;
      state.addTeam.success = false;
    },
    [createTeam.fulfilled]: (state, action) => {
      state.addTeam.isLoading = false;
      state.addTeam.success = true;
      let newTeams=[...state.data.teams]
      newTeams.unshift(action.payload)
      state.data.teams=newTeams
    },
    [createTeam.rejected]: (state, action) => {
      state.addTeam.isLoading = false;
      state.addTeam.success = false;
      state.addTeam.errMsg = action.payload;
    },
     // update team
     [updateTeam.pending]: (state) => {
      state.updateTeam.isLoading = true;
      state.updateTeam.errMsg = null;
    },
    [updateTeam.fulfilled]: (state, action) => {
      state.updateTeam.isLoading = false;
      state.updateTeam.success = true;
      let newTeams=[...state.data.teams]
      let index=newTeams.findIndex((item)=>item.id===action.payload.id)
      newTeams.splice(index,1,action.payload)
      state.data.teams=newTeams
    },
    [updateTeam.rejected]: (state, action) => {
      state.updateTeam.isLoading = false;
      state.updateTeam.success = false;
      state.updateTeam.errMsg = action.payload;
    },
  },
});

export const { updateAttachedPlan } = teams.actions;
export default teams.reducer;
