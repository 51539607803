import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const deletePublicDataImage = createAsyncThunk("publicData/deletePublicDataImage", async (data, thunkAPI) => {
  let { schema, handleSuccessDelete, handleError } = data;
  try {
    const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/transactions/listing/public-data/media/${data.listing_public_data_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: schema,
    });
    if (handleSuccessDelete) {
        handleSuccessDelete();
    }
    return { image_id: schema.image_id };
  } catch (error) {
    if (error.response) {
      if (handleError) {
        handleError(error.response.data.errorMessage || error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      if (handleError) {
        handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
