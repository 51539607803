import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { XAccessToken } from "../../../utils/propertyData";
export const updateSearch = createAsyncThunk(
  "LeadSavedSearches/updateSearch",
  async (data, thunkAPI) => {
    try {
      let site_id =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.site_id?._id;
      let token =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.token;
      let agent =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.assignedAgent
          ?._id;
      let lead = thunkAPI.getState()?.leads?.savedSearches.searchList.data?._id;

      let url = `${process.env.REACT_APP_IDX_BASE_URL}api/v1/save-search/${site_id}/${data?.searchId}`;
      const resp = await axios.put(
        url,
        {
          title: data?.title,
          emailNotification: data?.emailNotification,
          agent,
          lead,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            // "x-access-token": XAccessToken,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
