import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAllInventories = createAsyncThunk(
  "InventoryList/getAllInventories",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/inventory?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&sortBy=${data.type==="lockbox"?data?.sortBy || "":""}&sortOrder=${data.type==="lockbox"?data.sortOrder || "":""}&status=${data.status || ""}&search=${data.search || ""}&type=${data.type|| ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

