import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

export default function EmailDetailSkelton() {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "30px",
        }}
      >
        <Skeleton animation="wave" height={35} width="30%" />
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </Box>
      <Card sx={{ boxShadow: "none" }}>
        <CardHeader
          sx={{ padding: "0px" }}
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={25}
              width="50%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={20} width="60%" />}
        />
        <br />
        <br />
        <br />

        <Skeleton
          animation="wave"
          height={35}
          sx={{ ml: "16px" }}
          width="40%"
        />

        {Array.from(Array(10).keys()).map(() => (
          <Skeleton
            animation="wave"
            height={25}
            sx={{ ml: "16px" }}
            width="4100%"
          />
        ))}
      </Card>
    </Box>
  );
}
