export const styleSheet = (theme) => ({
  addVendorHeading:{
    fontSize:'22px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
    display:'flex',
    justifyContent:'space-between'
  },
  
  saveButton:{
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft:'30px !important',
    paddingRight:'30px !important'
  },
 });
