import React from "react";
import { Routes, Route } from "react-router-dom";
import VendorsDetail from "./vendorDetail";
import VendorsList from "./vendorsList";

const Vendors = () => {
  return (
    <Routes>
      <Route path="/" element={<VendorsList />} />
      <Route path="/:id" element={<VendorsDetail />} />
    </Routes>
  );
};
export default Vendors;