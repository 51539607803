import React, { useEffect, useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Stack, Card, Typography, Skeleton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import shield1 from "../../../assets/images/shield1.png";
import shield2 from "../../../assets/images/shield2.png";
import shield3 from "../../../assets/images/shield3.png";
import shield4 from "../../../assets/images/shield4.png";
import PointsHistory from "./pointsHistory";
import { useSelector } from "react-redux";
import { CustomTooltip } from "../../../components/shared/tooltip";
import { useNavigate } from "react-router-dom";

function AgentPoints(props) {
  let { classes, adminDetail } = props;
  const [rank, setRank] = useState("-");
  const navigate = useNavigate();
  const pointsData = useSelector(
    (state) => state.agentRoster.points.pointsData
  );
  useEffect(() => {
    if (pointsData?.leaderboard?.length && adminDetail.id) {
      let newPoints = [...pointsData.leaderboard];
      let newLeaderboard = newPoints.sort((a, b) => b.point - a.point);
      let newPointsWithRank = [];
      let rankNumber = 0;
      newLeaderboard.map((item, index) => {
        if (index === 0) {
          newPointsWithRank.push({ ...item, rank: 1 });
          rankNumber = rankNumber + 1;
        } else {
          if (item.point === newLeaderboard[index - 1].point) {
            newPointsWithRank.push({ ...item, rank: rankNumber });
          } else {
            rankNumber = rankNumber + 1;
            newPointsWithRank.push({ ...item, rank: rankNumber });
          }
        }
      });
      setRank(
        newPointsWithRank.find((item) => item.user_id == adminDetail.id)
          ?.rank || "-"
      );
    }
  }, [pointsData?.leaderboard, adminDetail.id]);
  return (
    <Box className={classes.pointsTabRoot}>
      <Box className={classes.pointsTopArea}>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems="center"
          flexWrap={"wrap"}
          spacing={3}
        >
          {pointsData.isLoading ? (
            <Skeleton
              variant="rounded"
              className={classes.pointsTopLeftCard1}
            ></Skeleton>
          ) : (
            <Card className={classes.pointsTopLeftCard1}>
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ lineHeight: "30px" }}
                spacing={4}
              >
                <StarIcon fontSize="small" sx={{ mr: "7px" }} />{" "}
                {pointsData?._metadata?.total_point || "0"}
              </Stack>
              <Typography sx={{ fontSize: "14px" }}>
                Points Earned This Month
              </Typography>
            </Card>
          )}
        </Stack>
        <Box className={classes.pointsTopRIght}>
          {" "}
          {pointsData.isLoading ? (
            <Skeleton
              variant="rounded"
              className={classes.pointsTopRightCard}
            ></Skeleton>
          ) : (
            <Card className={classes.pointsTopRightCard}>
              <Typography className={classes.pointsTopRightCardHeading}>
                Current Rank
              </Typography>
              <CustomTooltip
                title="Check back tomorrow for updated rankings."
                placement="top"
              >
                <Box className={classes.pointsTopRightShields}>
                  <Box className={classes.pointsTopRightShieldValue}>
                    {pointsData?._metadata?.total_point > 0 ? rank : "-"}
                  </Box>
                  <img
                    src={
                      pointsData?._metadata?.total_point > 0
                        ? rank === "-"
                          ? shield1
                          : rank === 1
                          ? shield3
                          : rank === 2
                          ? shield4
                          : rank === 3
                          ? shield2
                          : shield1
                        : shield1
                    }
                    alt="point shield"
                  />
                </Box>
              </CustomTooltip>
              <Box
                className={classes.pointsTopRightCardLink}
                onClick={() => navigate("/dashboard/leaderboard")}
              >
                View Leaderboard
              </Box>
            </Card>
          )}
        </Box>
      </Box>
      <br />
      <PointsHistory adminDetail={adminDetail} {...props} />
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "AgentPointsStyle",
})(AgentPoints);
