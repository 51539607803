import React, { useEffect, useState, useContext } from "react";
import { postData } from "../../utils/postData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "../uiElements/avatar/index";
import { AppContext } from "./callContext";
import { getContactList } from "../../redux/calls/getContactList";
import { useDispatch } from "react-redux";
import Contacts from "./contacts";
import { numberBtnData } from "../../utils/keyPad";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";

const OnCallKeypadPanel = ({
  warnInprogress,
  setWarnInprogress,
  activeButton,
  setActiveButton,
  hold,
  setHold,
  warmCall,
  setWarmCall,
  selectedUser,
  setSelectedUser,
  callTransTimer,
  setCallTransTimer,
  timer,
  setTimer,
  pageNumber,
  setPageNumber,
  search,
  setSearch,
  activeCallForModal,
  ...props
}) => {
  const { adminDetail, setDisplayOnCallPanel } = useContext(AppContext);

  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    let interval = null;
    if (callTransTimer) {
      interval = setInterval(() => {
        setTimer((time) => time + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [callTransTimer]);

  useEffect(() => {
    if (search && search.length > 2) {
      dispatch(
        getContactList({
          pageNumber: 1,
          search: search,
        })
      );
    } else if (!search) {
      dispatch(
        getContactList({
          pageNumber,
          search: search,
        })
      );
    }
  }, [pageNumber, search]);

  const sendDigit = (digit) => {
    activeCallForModal.sendDigits(digit);
  };

  const holdCall = async (e) => {
    let data = {
      call_id: activeCallForModal.customParameters.get("call_id") || activeCallForModal.parameters.CallSid,
      extension: adminDetail.twilio_call_extension.name,
      hold: true,
    };
    if (activeCallForModal._direction === "OUTGOING") {
      data = {
        ...data,
        caller_id: activeCallForModal.parameters.CallSid,
        conferenceId: activeCallForModal.parameters.CallSid,
        outboundCall: true,
      };
    } else {
      data = {
        ...data,
        caller_id: activeCallForModal.customParameters.get("confFriendlyName") || activeCallForModal.customParameters.get("call_id"),
        conferenceId: activeCallForModal.customParameters.get("confFriendlyName") || activeCallForModal.customParameters.get("call_id"),
      };
    }
    const result = await postData(`${process.env.REACT_APP_TWILIO_SERVER}hold-call`, data);
    if (result.status === 200) {
      setHold(true);
    }
  };

  const unHoldCall = async (e) => {
    let data = {
      call_id: activeCallForModal.customParameters.get("call_id") || activeCallForModal.parameters.CallSid,
      extension: adminDetail.twilio_call_extension.name,
      hold: false,
    };
    if (activeCallForModal._direction === "OUTGOING") {
      data = {
        ...data,
        caller_id: activeCallForModal.parameters.CallSid,
        conferenceId: activeCallForModal.parameters.CallSid,
        outboundCall: true,
      };
    } else {
      data = {
        ...data,
        caller_id: activeCallForModal.customParameters.get("confFriendlyName") || activeCallForModal.customParameters.get("call_id"),
        conferenceId: activeCallForModal.customParameters.get("confFriendlyName") || activeCallForModal.customParameters.get("call_id"),
      };
    }
    const result = await postData(`${process.env.REACT_APP_TWILIO_SERVER}hold-call`, data);
    if (result.status === 200) {
      setHold(false);
    }
  };

  const makeCallForTransferWarmCall = async (selected) => {
    setWarnInprogress(true);
    if (hold) {
      const result = await postData(`${process.env.REACT_APP_TWILIO_SERVER}add-participent`, {
        From:
          adminDetail.twilio_call_extension?.name && selected.twilio_call_extension?.name
            ? `client:${adminDetail.twilio_call_extension.name}`
            : adminDetail?.twilio_call_extension?.direct_number,
        FromName:
          adminDetail.twilio_call_extension?.name && selected.twilio_call_extension?.name ? `WT from ${adminDetail.first_name}` : null,
        extension_id: adminDetail.twilio_call_extension.id,
        extension: adminDetail.twilio_call_extension.name,
        to: selected.twilio_call_extension?.name ? `client:${selected.twilio_call_extension.name}` : selected.phone,
        user_id: selected.id,
        conferenceId:
          activeCallForModal.customParameters.get("confFriendlyName") ||
          activeCallForModal.parameters.CallSid ||
          activeCallForModal.customParameters.get("call_id"),
      });

      if (result.status === 200) {
        setCallTransTimer(true);
        setWarmCall(result.data.callSid);
        setSelectedUser(selected);
        setWarnInprogress(false);
      } else {
        console.log(result);
      }
    } else {
      setWarnInprogress(false);
      if (!toast.isActive(toastId.current)) {
        toast.error("Place call on hold first!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
  const makeCallForTransferColdCall = async (selected) => {
    setWarnInprogress(true);
    // if (hold) {
    const result = await postData(`${process.env.REACT_APP_TWILIO_SERVER}cold-transfer`, {
      From:
        adminDetail.twilio_call_extension?.name && selected.twilio_call_extension?.name
          ? `client:${adminDetail.twilio_call_extension.name}`
          : adminDetail?.twilio_call_extension?.direct_number,
      FromName:
        adminDetail.twilio_call_extension?.name && selected.twilio_call_extension?.name ? `CT from ${adminDetail.first_name}` : null,
      extension_id: adminDetail.twilio_call_extension.id,
      extension: adminDetail.twilio_call_extension.name,
      call_id: activeCallForModal.parameters.CallSid,
      to: selected.twilio_call_extension?.name ? `client:${selected.twilio_call_extension.name}` : selected.phone,
      conferenceId: activeCallForModal.customParameters.get("confFriendlyName") || activeCallForModal.parameters.CallSid,
      user_id: selected.id,
    });
    if (result.status === 200) {
      setWarnInprogress(false);
      // if (!toast.isActive(toastId.current)) {
      //   toast.success("Transfer complete!", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      // }
    } else {
      console.log(result);
      if (!toast.isActive(toastId.current)) {
        toast.error(JSON.stringify(result.message), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      setWarnInprogress(false);
    }
    // } else {
    // }
  };

  const abortCall = async () => {
    if (warmCall) {
      const result = await postData(`${process.env.REACT_APP_TWILIO_SERVER}abort-call`, {
        extension: adminDetail.twilio_call_extension.name,
        call_id: warmCall,
      });
      if (result.status === 200) {
        setWarmCall(null);
        setSelectedUser(null);
        setTimer(0);
        setCallTransTimer(false);
      } else {
        console.log(result);
      }
    } else {
      if (!toast.isActive(toastId.current)) {
        toast.error("No call in progress!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
  const completeTransfer = async () => {
    setDisplayOnCallPanel(false);
    if (warmCall) {
      const result = await postData(`${process.env.REACT_APP_TWILIO_SERVER}complete-transfer`, {
        extension: adminDetail.twilio_call_extension.name,
        call_id: activeCallForModal.parameters.CallSid,
        user_id: selectedUser.id,
        conferenceId: activeCallForModal.customParameters.get("confFriendlyName") || activeCallForModal.parameters.CallSid,
      });
      if (result.status === 200) {
        setWarmCall(null);
        setSelectedUser(null);
        // if (!toast.isActive(toastId.current)) {
        //   toast.success("Transfer complete!", {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        // }
      } else {
        console.log(result);
      }
    } else {
      if (!toast.isActive(toastId.current)) {
        toast.error("No call in progress!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  return (
    <div className="live-call-panel call-panel-area">
      <div>
        <div className="transfer-call-area">
          <div
            className={`transfer-call-button ${activeButton === "warm_transfer" ? "active" : ""}`}
            onClick={(e) => {
              if (activeButton === "warm_transfer") {
                setActiveButton("");
              } else {
                setActiveButton("warm_transfer");
              }
            }}
          >
            <div>
              <div className="transfer-call-title">Warm</div>
              <div className="transfer-call-text ">Transfer</div>
            </div>
          </div>
          <div
            className={`transfer-call-button ${activeButton === "cold_transfer" ? "active" : ""}`}
            onClick={(e) => {
              if (activeButton === "cold_transfer") {
                setActiveButton("");
              } else {
                setActiveButton("cold_transfer");
              }
            }}
          >
            <div>
              <div className="transfer-call-title">Cold</div>
              <div className="transfer-call-text ">Transfer</div>
            </div>
          </div>
          <div
            className={`transfer-call-button ${activeButton === "vm_transfer" ? "active" : ""}`}
            onClick={(e) => {
              if (activeButton === "vm_transfer") {
                setActiveButton("");
              } else {
                setActiveButton("vm_transfer");
              }
            }}
          >
            <div>
              <div className="transfer-call-title">VM</div>
              <div className="transfer-call-text ">Transfer</div>
            </div>
          </div>
        </div>
        <div className="btn-on-hold-wrapper">
          <button
            className={`btn-on-hold ${hold ? "active" : ""}`}
            onClick={(e) => {
              if (!warnInprogress) {
                if (hold) {
                  unHoldCall();
                } else {
                  holdCall();
                }
              }
            }}
            disabled={warnInprogress}
          >
            {hold ? "Remove Hold" : "Place on Hold"}
          </button>
        </div>

        {warmCall && selectedUser ? (
          <>
            <div className="already-transfer-call-area">
              <div className="already-transfer-call-area-detail">
                <div className="transfer-user-profile">
                  <Avatar
                    sx={{ textTransform: "uppercase", fontSize: "22px" }}
                    alt={selectedUser.full_name}
                    variant="square"
                    src={selectedUser?.profile_images?.profile_img || selectedUser?.profile_images?.profile_img_thumbnail}
                  >
                    {`${selectedUser.first_name?.slice(0, 1)}${selectedUser.last_name?.slice(0, 1)}`}
                  </Avatar>
                  <p>{selectedUser?.full_name}</p>
                </div>
                <div className="transfer-timer">
                  {("0" + Math.floor((timer / 60000) % 60)).slice(-2)}:{("0" + Math.floor((timer / 1000) % 60)).slice(-2)}
                </div>
              </div>
              <div className="transfer-button-actions">
                <div onClick={abortCall} className={`transfer-call-button abort`}>
                  <div>
                    <div className="transfer-call-title">Abort</div>
                    <div className="transfer-call-text ">Transfer</div>
                  </div>
                </div>
                <div onClick={completeTransfer} className={`transfer-call-button`}>
                  <div>
                    <div className="transfer-call-title">Complete</div>
                    <div className="transfer-call-text ">Transfer</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : activeButton === "warm_transfer" || activeButton === "cold_transfer" || activeButton === "vm_transfer" ? (
          <>
            <Contacts
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              setSearch={setSearch}
              search={search}
              makeCallForTransferWarmCall={makeCallForTransferWarmCall}
              makeCallForTransferColdCall={makeCallForTransferColdCall}
              activeButton={activeButton}
              isTransfer
              height="215px"
              {...props}
            />
          </>
        ) : (
          <div className="live-call-keypad">
            {numberBtnData.map((item, index) => (
              <div
                key={index}
                onClick={(e) => {
                  sendDigit(item.value.toString());
                }}
                className="live-call-keypad-button"
              >
                <div>
                  <div className="keypad-dial-number">{item.value}</div>
                  <div className="keypad-dial-text">{item.subValue.map((e) => e)}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
export default withStyles(styleSheet, { name: "OnCallKeypadPanelStyle" })(OnCallKeypadPanel);
