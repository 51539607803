import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

function Skelton() {
  return (
    <Card variant="outlined" sx={{ borderRadius: "14px" }}>
      <CardHeader
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={15}
            width="50%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={20} width="60%" />}
      />
      <Skeleton animation="wave" height={15} sx={{ ml: "16px" }} width="40%" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 1,
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          animation="wave"
          height={15}
          width="80%"
          sx={{ ml: "16px" }}
        />
        <Skeleton
          animation="wave"
          variant="circular"
          sx={{ mr: "16px" }}
          width={30}
          height={30}
        />
      </Box>
    </Card>
  );
}

Skelton.propTypes = {
  loading: PropTypes.bool,
};

export default function EmailListSkelton(props) {
  let { count = 4 } = props;
  return Array.from(Array(count).keys()).map((item, index) => (
    <Box key={index} sx={{ mb: "15px" }}>
      <Skelton />
    </Box>
  ));
}
