import React, { useEffect, useState } from "react";
import { getInboxLeads, getTextHistory } from "../../../redux/leads/inbox";
import { Box } from "@mui/system";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { useDispatch, useSelector } from "react-redux";
import InboxLeads from "./inboxLeads";
import SelectedLeadHeader from "./selectedLeadHeader";
import { useStyles } from "./style";
import LeadsChatHistoryArea from "./leadsChatHistoryArea";
import SendMessage from "./sendMessage";
import UserDetail from "./userDetail";
import ChatIcon from "@mui/icons-material/Chat";
import { getLeadStageListAPI } from "../../../redux/leads/getLeadStageListAPI";
import { getAgentRecruitingStages } from "../../../redux/agents/agentRecruitment/getAgentsRecruitmentStages";
import { useLocation } from "react-router-dom";
const InboxContainer = (props) => {
  let { style, list } = props;
  const classes = useStyles();
  let { state } = useLocation();
  const leadProfile = {
    list: { data: { lastActivity: "2022-08-03T03:01:57.000Z" } },
  };
  const dispatch = useDispatch();
  const { list: usersList, teamsList } = useSelector(
    (item) => item.users.storedListOfUsers
  );
  const loggedInUserId = useSelector(
    (state) => state.adminAuth?.adminDetail?.id
  );

  const [currentStage, setCurrentStage] = useState("");
  const [selectedUser, setSelectedUser] = useState(); /* list?.data[0] */
  const [primaryPhone, setPrimaryPhblockone] = useState(null);
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [currentAssigne, setCurrentAssigne] = useState(null);

  const setStages = async () => {
    await dispatch(getAgentRecruitingStages());
    await dispatch(getLeadStageListAPI());
  };
  useEffect(() => {
    setStages();
  }, []);

  useEffect(() => {
    if (state?.message_id) {
      setSelectedUser(state?.messageDetail);
      handleClick(state?.messageDetail);
    }
  }, [state?.message_id]);

  useEffect(() => {
    if (list?.list?.data?.phones && list?.list?.data?.phones.length) {
      setPrimaryPhblockone(list?.list?.data?.phones[0]?.value || []);
    }
    if (list?.list?.data?.emails && list?.list?.data?.emails.length) {
      setPrimaryEmail(list?.list?.data?.emails[0]?.value || null);
    }

    if (list?.list?.data?.addresses && list?.list?.data?.addresses.length) {
      setAddresses(list?.list?.data?.addresses || null);
    }

    if (list?.list?.data?.stage) {
      setCurrentStage(list?.list?.data?.stage);
    }
    if (list?.list?.data?.assignedTo) {
      setCurrentAssigne(list?.list?.data?.assignedTo);
    }
  }, [list]);

  useEffect(() => {
    dispatch(getInboxLeads({ id: loggedInUserId, limit: 30 }));
  }, []);
  // useEffect(() => {
  //     dispatch(getTextMessageAPI({}))
  // },[])

  const handleClick = (data) => {
    setSelectedUser(data);
    setCurrentAssigne("");
    setCurrentStage("");
    dispatch(
      getTextHistory({
        userId: data.userId,
        limit: 45,
        agentId: data.realtor_agent_id,
        lead_id: data.lead_id,
      })
    );
  };
  return (
    <div className={classes.mainWrapper}>
      <>
        <InboxLeads
          list={list}
          selectedUser={selectedUser}
          {...props}
          handleClick={handleClick}
        />
        {selectedUser ? (
          <div className={classes.mainContent}>
            <SelectedLeadHeader
              list={list}
              primaryPhone={primaryPhone}
              teamsList={teamsList}
              usersList={usersList}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              {...props}
            />

            <LeadsChatHistoryArea {...props} />
            <SendMessage selectedUser={selectedUser} {...props} />
          </div>
        ) : (
          <Box className={classes.mainContent}>
            <Box className={classes.chatNotStartedArea}>
              <Box className={classes.chatNotStartedBox}>
                <center>
                  <ChatIcon sx={{ fontSize: "150px", color: "lightgray" }} />
                  <br />
                  Start chat by clicking on any lead users
                </center>
              </Box>
            </Box>
          </Box>
        )}
        {selectedUser && (
          <UserDetail
            selectedUser={selectedUser}
            primaryPhone={primaryPhone}
            primaryEmail={primaryEmail}
            currentStage={currentStage}
            setCurrentStage={setCurrentStage}
            leadProfile={leadProfile}
            currentAssigne={currentAssigne}
            setCurrentAssigne={setCurrentAssigne}
            usersList={usersList}
            {...props}
          />
        )}
      </>
    </div>
  );
};

export default withStyles(styleSheet, { name: "inboxStyle" })(InboxContainer);
