export const formatPersonTypeData = (type, formData) => {
  const splitted = type.split(' ');
  const str = splitted[0].toLowerCase();
  const number = splitted[1];
  
  const obj = {
    [`${str}${number-1}_personId`]: formData.personId,
    [`${str}${number-1}_entity_name`]: formData.entityName,
    [`${str}${number-1}_first_name`]: formData.firstName,
    [`${str}${number-1}_last_name`]: formData.lastName,
    [`${str}${number-1}_phone`]: formData.phone,
    [`${str}${number-1}_email`]: formData.email,
    [`${str}${number-1}_middle_initials`]: formData.middleInitials,
  };
  
  return obj;
};

export const initForm = {
  personId: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  middleInitials: null
};