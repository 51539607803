import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Card, Button } from "@mui/material";
import { TabContext } from "@mui/lab";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import { Link, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Container } from "@mui/system";
import ListingsAll from "./ListingsPage";
import { Route, Routes } from "react-router-dom";
import { getAllListings } from "../../../redux/listings/listings";
import { useDispatch } from "react-redux";
import AddListing from "../modals/addListing";
import { useSelector } from "react-redux";
export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function ListingsMain(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    sortBy: "",
    is_rental: location.pathname === "/listings/under-contract" ? false : location.pathname === "/listings/upcoming" ? true : "",
    status:
      location.pathname === "/listings/active"
        ? "active,coming soon"
        : location.pathname === "/listings/closed"
        ? "closed"
        : location.pathname === "/listings/withdrawn"
        ? "terminated"
        : "all",
    search: "",
  });
  const [locationChanged, setLocationChanged] = useState(true);
  const addList = useSelector((state) => state.listings.ListingsData.addList);

  const handleUpdateFilter = (field, value, is_rental) => {
    if (field === 'status') {
      setLocationChanged(true);
    }
    setFilters({
      ...filters,
      pageNumber: 1,
      [field]: value,
      is_rental: is_rental,
    });
  };

  useEffect(() => {
    dispatch(getAllListings({ ...filters, reset: locationChanged }));
    setLocationChanged(false);
  }, [filters]);

  useEffect(() => {
    if (addList.success) dispatch(getAllListings({ ...filters, reset: true, pageNumber: 1, pageSize: 25  }));
  }, [addList]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => {
        setLocationChanged(true)
        setFilters({ ...filters, search })}, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <Box>
      <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
        {" "}
        <Box className={classes.TopTransactionsBarArea}>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TransactionsTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                  >
                    <Tab
                      label="Active"
                      value={`/listings/active`}
                      to={`/listings/active`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("status", "active,coming soon")}
                    />
                    <Tab
                      label="Under Contract"
                      value={`/listings/under-contract`}
                      to={`/listings/under-contract`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("status", "under contract")}
                    />
                    <Tab
                      label="Upcoming"
                      value={`/listings/upcoming`}
                      to={`/listings/upcoming`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("status", "upcoming")}
                    />{" "}
                    <Tab
                      label="Withdrawn"
                      value={`/listings/withdrawn`}
                      to={`/listings/withdrawn`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("status", "terminated")}
                    />{" "}
                    <Tab
                      label="CLOSED"
                      value={`/listings/closed`}
                      to={`/listings/closed`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("status", "closed")}
                    />
                  </Tabs>
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Button
              onClick={() => setOpen(true)}
              className={classes.addTransactionButton}
              variant="contained"
              startIcon={<CreateNewFolderRoundedIcon />}
            >
              New Listing
            </Button>{" "}
            <Search>
              <StyledInputBase onChange={(e) => setSearch(e.target.value)} placeholder="Search Listings" inputProps={{ "aria-label": "search" }} />
            </Search>
          </Box>
        </Box>
      </Card>
      <Container className={classes.transactionsStatsArea} maxWidth="xl">
        <Routes>
          <Route path="/" element={<ListingsAll setFilters={setFilters} filters={filters} {...props} />} />
          <Route path="/active" element={<ListingsAll setFilters={setFilters} filters={filters} {...props} />} />
          <Route path="/under-contract" element={<ListingsAll setFilters={setFilters} filters={filters} {...props} />} />
          <Route path="/upcoming" element={<ListingsAll setFilters={setFilters} filters={filters} {...props} />} />
          <Route path="/withdrawn" element={<ListingsAll setFilters={setFilters} filters={filters} {...props} />} />
          <Route path="/closed" element={<ListingsAll setFilters={setFilters} filters={filters} {...props} />} />
        </Routes>
      </Container>
      <AddListing open={open} setOpen={setOpen} />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ListingsMainStyle" })(ListingsMain);
