export const styleSheet = () => ({
  rowViewPage: {
    minHeight: "100vh",
  },
  rowViewCard: {
    padding: "25px",
    marginTop: "80px !important",
  },
  tableHeaderColumn: {
    borderRight: "1px solid rgba(0, 0, 0, 0.30)",
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    textTransform: "uppercase !important",
    color: "#0068E2 !important",
    whiteSpace: "nowrap !important",
  },
  tableHeaderColumn1: {
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    textTransform: "uppercase !important",
    color: "#0068E2 !important",
    whiteSpace: "nowrap !important",
  },
  tableHeaderColumn2: {
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    textTransform: "uppercase !important",
    paddingLeft: "9px !important",
  },
  allColsFooter: {
    height: "150px !important",
    backgroundColor: "rgba(217, 217, 217, 0.30)  !important",
    border: "1px solid rgba(0, 0, 0, 0.30)",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  footerPaginationArea: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  PaginationMainButton: {
    backgroundColor: "#ACACAC !important",
    border: "1px solid rgba(0, 0, 0, 0.50) !important",
    borderRadius: "6px !important",
    minWidth: "35px !important",
    color: "white !important",
    padding: "5px 6px !important",
  },
  paginationInnerSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "4px !important",
  },
  paginationInnerSectionItem: {
    width: "17px",
    height: "34px",
    borderRadius: "3px",
    border: "1px solid rgba(0, 0, 0, 0.50)",
    cursor: "pointer !important",
  },
  featuredCol1: {
    borderRight: "1px solid #e0e0e0  !important",
    borderLeft: "1px solid #e0e0e0  !important",
    borderTop: "1px solid #e0e0e0  !important",
    padding: "2px  !important",
    textAlign: "right  !important",
    fontWeight: "600 !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
  },
  featuredCol2: {
    borderRight: "1px solid #e0e0e0  !important",
    borderLeft: "1px solid #e0e0e0  !important",
    borderTop: "1px solid #e0e0e0  !important",
    padding: "8px  !important",
    fontWeight: "600 !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
  },
  descSectionPositive: {
    fontSize: "17px",
    textAlign: "end",
    color: "#4CAF50",
  },
  descSectionNegative: {
    fontSize: "17px",
    textAlign: "right",
    color: "#E65100",
  },
  descSectionZero: {
    fontSize: "17px",
    alignItems: "flex-end",
    color: "rgba(0, 0, 0, 0.42)",
  },
  labelValue: {
    // display: "-webkit-box",
    // WebkitLineClamp: 1,
    // WebkitBoxOrient: "vertical",
    // overflow: "hidden",
    // textOverflow: "clip",
    overflow: "hidden",
    maxHeight: "19px",
    textAlign: "right",
  },
});
