import { createSlice } from "@reduxjs/toolkit";
import { getAllListings } from "./getAllListings";
import { addListing } from "./addListing";

const initialState = {
  ListingsAll: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    count: 0,
  },
  addList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const Listings = createSlice({
  name: "Listings",
  initialState,
  extraReducers: {
    [getAllListings.pending]: (state, action) => {
      state.ListingsAll.isLoading = true;
      state.ListingsAll.errMsg = null;
      state.ListingsAll.success = false;
    },
    [getAllListings.fulfilled]: (state, action) => {
      state.ListingsAll.success = true;
      state.ListingsAll.isLoading = false;
      if (action.payload.reset) {
        state.ListingsAll.data = action.payload.data.listings;
      } else {
        state.ListingsAll.data = [...state.ListingsAll.data, ...action.payload.data.listings];
      }

      state.ListingsAll.count = action.payload.data.count;
    },
    [getAllListings.rejected]: (state, action) => {
      state.ListingsAll.isLoading = false;
      state.ListingsAll.success = false;
      state.ListingsAll.errMsg = action.payload;
    },

    [addListing.pending]: (state) => {
      state.addList.isLoading = true;
      state.addList.errMsg = null;
      state.addList.success = false;
    },
    [addListing.fulfilled]: (state, action) => {
      state.addList.success = true;
      state.addList.isLoading = false;
      state.addList.data = action.payload;
      state.ListingsAll = {
        ...state.ListingsAll,
        data: state.ListingsAll.data ? [...state.ListingsAll.data, action.payload] : [action.payload],
      };
    },
    [addListing.rejected]: (state, action) => {
      state.addList.isLoading = false;
      state.addList.success = false;
      state.addList.errMsg = action.payload;
    },
  },
});
export default Listings.reducer;
export * from "./getAllListings";
export * from "./addListing";
