import {
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useStyles } from "../style";
import moment from "moment";

function ComparableTable({ rows = [], status = "active" }) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const matchesMd = useMediaQuery("(max-width:900px)");

  const formatCash = (RS = 0) =>
    Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(RS);
  let avgOriginalListPrice = 0;
  let avgListPrice = 0;
  let avgSoldPrice = 0;
  let avgpricePerSqFt = 0.0;

  rows?.map((item, i) => {
    if (item?.OriginalListPrice && parseInt(item?.OriginalListPrice)) {
      avgOriginalListPrice += parseInt(item?.OriginalListPrice);
    }
    if (item?.ListPrice && parseInt(item?.ListPrice)) {
      avgListPrice += parseInt(item?.ListPrice);
    }
    if (item?.ClosePrice && parseInt(item?.ClosePrice)) {
      avgSoldPrice += parseInt(item?.ClosePrice);
    }
    if (item?.pricePerSqFt && parseFloat(item?.pricePerSqFt)) {
      avgpricePerSqFt += parseFloat(item?.pricePerSqFt);
    }
    return true;
  });

  return (
    <TableContainer
      sx={{ overflowX: "hidden" }}
      className={classes.tableContainer}
    >
      <Table className="table">
        <TableHead>
          <TableRow className={classes.tablerow}>
            <TableCell
              className={classes.tableHead}
              sx={{ borderRight: "1px solid #E0E0E0" }}
            >
              Address
            </TableCell>
            {/* <TableCell className={classes.tableHead}>Beds</TableCell>
              <TableCell className={classes.tableHead}>Baths</TableCell> */}
            {matches ? (
              <>
                <TableCell className={classes.tableHead}>
                  Beds <br /> Baths
                </TableCell>
              </>
            ) : (
              <>
                <TableCell className={classes.tableHead}>Beds</TableCell>
                <TableCell className={classes.tableHead}>Baths</TableCell>
              </>
            )}
            {matches ? (
              <>
                <TableCell className={classes.tableHead}>
                  Sqft <br /> PPSF
                </TableCell>
              </>
            ) : null}
            {matches ? (
              <TableCell className={classes.tableHead}>
                price
                <br />
                closed
              </TableCell>
            ) : null}
            {!matches && (
              <>
                <TableCell className={classes.tableHead}>Sqft</TableCell>
                <TableCell className={classes.tableHead}>Lot</TableCell>
              </>
            )}
            {!matches && (
              <TableCell className={classes.tableHead}>
                Original List Price
              </TableCell>
            )}
            {!matches && (
              <>
                <TableCell className={classes.tableHead}>
                  Last List Price
                </TableCell>
                <TableCell className={classes.tableHead}>Sold Price</TableCell>
              </>
            )}
            {!matches && (
              <TableCell className={classes.tableHead}>PPSF</TableCell>
            )}
            {!matchesMd && (
              <>
                <TableCell className={classes.tableHead}>Listed</TableCell>
                <TableCell className={classes.tableHead}>Contract</TableCell>
                <TableCell className={classes.tableHead}>sold</TableCell>
              </>
            )}
            <TableCell className={classes.tableHead}>dom</TableCell>
          </TableRow>
        </TableHead>
        {/*  mlsStatus Active(Green) = Active OR Coming Soon 
        Pending(Yellow) = Pending OR Under Contract OR Active Under Contract 
        Closed(Red) = Sold OR Closed 
        const formatCash = Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 1
        }).format(2500);
        console.log(formatCash);
        */}
        <TableBody className={status}>
          {rows?.map((row, i) => {
            const totalCount =
              (parseInt(row?.BathroomsFull, 10) || 0) +
              (parseInt(row?.BathroomsHalf) / 2 || 0);
            return (
              <TableRow
                key={i}
                sx={{
                  "&:last-child": {
                    border: 0,
                    backgroundColor: "#EFEFEF",
                    fontWeight: "600",
                  },
                  "& th": {
                    textWrap: "balance",
                    width: matches ? "80px !important" : "auto",
                    borderRight: matches
                      ? "none !important"
                      : "1px solid #E0E0E0 !important",
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableRow}
                >
                  {row.StreetNumber} {row.StreetName}
                </TableCell>
                {matches ? (
                  <>
                    <TableCell className={classes.tableRow}>
                      {row.BedroomsTotal}
                      <br />
                      {totalCount}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell className={classes.tableRow}>
                      {row.BedroomsTotal}
                    </TableCell>
                    <TableCell className={classes.tableRow}>
                      {totalCount}
                    </TableCell>
                  </>
                )}
                {matches ? (
                  <TableCell className={classes.tableRow}>
                    {row?.BuildingAreaTotal || 0}
                    <br />
                    {row?.pricePerSqFt}
                  </TableCell>
                ) : null}
                {matches ? (
                  <TableCell className={classes.tableRow}>
                    ${formatCash(row.OriginalListPrice)}
                    <br />
                    {formatCash(row.ClosePrice)}
                  </TableCell>
                ) : null}
                {!matches && (
                  <TableCell className={classes.tableRow}>
                    {row.BuildingAreaTotal || 0}
                  </TableCell>
                )}
                {!matches && (
                  <>
                    <TableCell className={classes.tableRow}>
                      {row?.LotSizeAcres
                        ? Math.round(row?.LotSizeAcres * 10) / 10
                        : 0}
                    </TableCell>
                    <TableCell className={classes.tableRow}>
                      $
                      {matchesMd
                        ? formatCash(`${row.OriginalListPrice}`)
                        : row.OriginalListPrice}
                    </TableCell>
                    <TableCell className={classes.tableRow}>
                      $
                      {matchesMd
                        ? formatCash(`${row.ListPrice}`)
                        : row.ListPrice}
                    </TableCell>
                    <TableCell className={classes.tableRow}>
                      $
                      {matchesMd
                        ? formatCash(`${row?.ClosePrice || 0}`)
                        : row.ClosePrice}
                    </TableCell>
                  </>
                )}
                {!matches && (
                  <TableCell className={classes.tableRow}>
                    ${row.pricePerSqFt || 0}
                  </TableCell>
                )}
                {!matchesMd && (
                  <>
                    <TableCell className={classes.tableRow}>
                      {row?.MlsStatus.toLowerCase() === "pending" ||
                      row?.MlsStatus.toLowerCase() === "under contract" ||
                      row?.MlsStatus.toLowerCase() === "active under contract"
                        ? row?.OnMarketDate
                          ? moment(row?.OnMarketDate).format("M/D/YY")
                          : row?.ListingContractDate
                          ? moment(row?.ListingContractDate).format("M/D/YY")
                          : "-"
                        : row?.ListingContractDate
                        ? moment(row?.ListingContractDate).format("M/D/YY")
                        : "-"}
                    </TableCell>
                    <TableCell className={classes.tableRow}>
                      {row?.ListingContractDate
                        ? moment(row?.ListingContractDate).format("M/D/YY")
                        : row?.ListingContractDate
                        ? moment(row?.ListingContractDate).format("M/D/YY")
                        : "-"}
                    </TableCell>
                    <TableCell className={classes.tableRow}>
                      {row?.MlsStatus.toLowerCase() === "sold" ||
                      row?.MlsStatus.toLowerCase() === "closed"
                        ? row?.CloseDate
                          ? moment(row?.CloseDate).format("M/D/YY")
                          : "-"
                        : "-"}
                    </TableCell>
                  </>
                )}
                <TableCell className={classes.tableRow}>
                  {row?.DaysOnMarket}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow
            className={classes.rowBG}
            sx={{
              "&:last-child": {
                border: 0,
                backgroundColor: "#EFEFEF",
                fontWeight: "600",
              },
              "& th": {
                borderRight: matches
                  ? "none !important"
                  : "1px solid #E0E0E0 !important",
              },
            }}
          >
            <TableCell component="th" scope="row" className={classes.rowOne}>
              Averages
            </TableCell>
            <TableCell className={classes.rowOne}></TableCell>
            <TableCell className={classes.rowOne}></TableCell>
            <TableCell className={classes.rowOne}>
              {" "}
              {matches
                ? "$" + formatCash(`${avgOriginalListPrice / rows?.length}`)
                : ""}
            </TableCell>
            <TableCell className={classes.rowOne}></TableCell>
            {!matches && (
              <>
                <TableCell className={classes.rowOne}>
                  $
                  {matchesMd
                    ? formatCash(`${avgOriginalListPrice / rows?.length}`)
                    : avgOriginalListPrice / rows?.length}
                </TableCell>
                <TableCell className={classes.rowOne}>
                  $
                  {matchesMd
                    ? formatCash(`${avgListPrice.toFixed(2) / rows?.length}`)
                    : avgListPrice.toFixed(2) / rows?.length}
                </TableCell>
                <TableCell className={classes.rowOne}>
                  {" "}
                  $
                  {matchesMd
                    ? formatCash(`${avgSoldPrice / rows?.length}`)
                    : avgSoldPrice / rows?.length}
                </TableCell>
                <TableCell className={classes.rowOne}>
                  $
                  {matchesMd
                    ? formatCash(`${avgpricePerSqFt / rows?.length}`)
                    : avgpricePerSqFt / rows?.length}
                </TableCell>
                <TableCell className={classes.rowOne}></TableCell>
              </>
            )}
            {!matchesMd && (
              <>
                <TableCell className={classes.rowOne}></TableCell>
                <TableCell className={classes.rowOne}></TableCell>
                <TableCell className={classes.rowOne}></TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ComparableTable;
