import { makeStyles } from "@mui/styles";
export const styleSheet = () => ({
  pageRoot: {
    height: "100%",
  },
  Sidebar: {
    background: " #FFFFFF",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
    width: "100%",
    height: "calc(100vh - 132px)",
    marginTop: "8px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
  },
  agentListHeading: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  agentListDescription: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "2 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
    marginTop: "5px  !important",
  },
  agentListTime: {
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    marginTop: "8px  !important",
    width: "40px !important",
    textAlign: "center !important",
    whiteSpace: "nowrap !important",
  },
  dateWrap: {
    display: "flex",
    alignItems: "center",
    color: "#595959 !important",
    fontSize: "13.6px !important",
    fontFamily: "AvenirNext !important",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 130px)",
    marginTop: "7px",
  },
  chatNotStartedArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
    width: "100%",
  },
  chatNotStartedBox: {
    color: "rgba(0, 0, 0, 0.60)",
    fontWeight: "500",
  },

  iconList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  iocnGroup: {
    marginRight: "8px",
    display: "flex",
    "& svg": {
      fill: "rgba(0, 0, 0, 0.54)",
    },
  },
  btnSend: {
    borderRadius: "4px",
    border: "1px solid rgba(33, 33, 33, 0.4)",
    background: "#0B0909 !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    color: "#fff !important",
    fontWeight: "400",
    fontSize: "13px !important",
    height: "30px",
    letterSpacing: "0.46px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  chatBodyContent: {
    // height: "calc(100vh - 450px)",
    background: "transparent",
    overflow: "auto",
    padding: "5px",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
  },
  chatWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  loggedOutUserChat: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "600px",
  },
  loggedInUserChat: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  loggedOutUserChatList: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    gap: "0px 10px",
    "& div": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .MuiAvatar-circular": {
      minWidth: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  callTypeReceiver: {
    width: "100%",
    maxWidth: "600px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#fff",
    borderRadius: "6px",
    marginTop: "8px",
    padding: "8px 16px",
    "& div": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  loggedInUserChatList: {
    width: "100%",
    maxWidth: "600px",
    display: "flex",
    alignItems: "center",
    gap: "0px 10px",
    borderRadius: "6px",
    marginTop: "12px",
    "& .MuiAvatar-circular": {
      minWidth: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  loggedInUserChatInnerBlock: {
    display: "flex",
    flex: 1,
    background: "#fff",
    border: "1px solid #E0E0E0",
    padding: "8px 16px",
    gap: "0px 10px",
    borderRadius: "6px",
  },
  loggedOutUserChatInnerBlock: {
    display: "flex",
    flex: 1,
    background: "#fff",
    border: "1px solid #E0E0E0",
    padding: "8px 16px",
    gap: "0px 10px",
    borderRadius: "6px",
  },
  iconAvatar: {
    display: "flex",
    flex: 1,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customBtnGroup: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "4px",
    "& button": {
      "& active": {
        background: "rgba(33, 33, 33, 0.04)",
      },
      background: "white",
      borderRadius: "4px",
      fontWeight: "500",
      fontSize: "15px",
      padding: "4px 22px !important",
      border: "1px solid rgba(33, 33, 33, 0.4)",
      lineHeight: "26px",
      textTransform: "uppercase",
      color: "#0B0909",
      marginLeft: "8px",
      backgroundColor: "rgba(33, 33, 33, 0.04)",
    },
  },
  btnAssign: {},
  listMenuWrapper: {
    "& .MuiMenu-list": {
      maxHeight: "450px",
      padding: 0,
      overflow: "auto",
      "& span": {
        padding: "10px 10px 0",
        borderBottom: "1px solid rgb(241, 244, 248)",
        fontWeight: "bold",
        width: "100%",
        lineHeight: "40px",
        fontSize: "11px",
        color: "rgb(109, 130, 145)",
        textTransform: "uppercase",
        display: "flex",
      },
      "& .MuiMenuItem-root": {
        "& .MuiAvatar-root": {
          width: "26px",
          height: "26px",
        },
        "&:hover": {
          background: "rgba(64, 162, 217, 0.1)",
        },
      },
    },
  },
  chatHeaderArea: {
    padding: "20px",
    height: "auto",
  },
  chatHeaderTitle: {
    fontSize: "20px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    lineHeight: "40px !important",
  },
  userDetailSectionHeading: {
    fontSize: "20px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  userDetailProjectsStats: {
    padding: "5px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiAvatarGroup-avatar": {
      width: "30px !important",
      height: "30px !important",
      fontSize: "13px !important",
    },
  },
  userDetailProjectHeading: {
    fontSize: "17px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  userDetailProjectBottom: {
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "800 !important",
  },
  userDetailProjectDes: {
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  attachmentArea: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "10px",
  },
  attachmentItem: {
    width: "96px",
    height: "125px",
    position: "relative",
    border: "1px solid lightgrey",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  attachmentDeleteIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    display: "none",
  },
  chatHeaderDetailItem: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
    display: "inline-block !important",
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  chatHeaderDetailItemDescription: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
    // display: "inline-block !important",
    // display: "-webkit-box !important",
    // "-webkit-line-clamp": "3 !important",
    // "-webkit-box-orient": "vertical !important",
    // overflow: "hidden !important",
  },
  chatHeaderDetailItemBold: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    display: "inline-block !important",
  },
  mainWrapper: {
    display: "flex",
    height: "calc(100vh - 130px)",
    justifyContent: "center",
    width: "100%",
  },
  dropzoneAreaInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18.8px",
    color: "black",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "6px !important",
    left: "0px",
    background: "rgba(255,255,255,.6)",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  userInfoDetail: {
    height: "100%",
    overflow: "auto",
    width: "100%",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .MuiListItem-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-circular": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        position: "unset",
      },
    },
  },
  TopSubNavBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopSubNavBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  "@media (max-width: 1270px)": {
    TopSubNavTabsUI: {
      maxWidth: "420px",
    },
  },
  "@media (max-width: 1050px)": {
    TopSubNavTabsUI: {
      maxWidth: "300px",
    },
  },
  TopSubNavTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  attachProjectButtonArea: {
    display: "flex",
    justifyContent: "center",
  },
  attachProjectButton: {
    borderRadius: "4px  !important",
    border: "1px solid rgba(33, 33, 33, 0.4)",
    background: "#0B0909 !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    color: "#fff !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "22px !important",
    letterSpacing: "0.46px !important",
    textTransform: "capitalize !important",
    margin: "10px 0px",
  },
  writeCommentSection: {
    padding: "0px 5px",
    "& .MuiOutlinedInput-root": {
      alignItems: "flex-end !important",
    },
  },
  adornedEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  inputBox: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
    padding: "8px",
    marginTop: "5px",
    "& .MuiInput-root:after": {
      border: "none !important",
    },
    "& .MuiInput-root:before": {
      border: "none !important",
    },
  },
});

export const useStyles = makeStyles(() => ({
  mainWrapper: {
    display: "flex",
    height: "100%",
    overflow: "auto",
    justifyContent: "center",
  },
  userInfoDetail: {
    height: "100%",
    overflow: "auto",
    "& .MuiListItem-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-circular": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        position: "unset",
      },
    },
  },
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  infoListItem: {
    display: "flex",
    flexDirection: "column",
  },
  headText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    margin: 0,
    color: "rgba(0, 0, 0, 0.87)",
  },
  subChildText: {
    margin: 0,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "143%",
    color: "rgba(0, 0, 0, 0.60)",
  },
  userName: {
    display: "flex",
    flexDirection: "column",
  },

  activityBtn: {
    padding: "0 24px 12px",
    display: "flex",
    "& button": {
      borderRadius: "4px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "uppercase",
      "&:nth-of-type(1)": {
        border: "1px solid rgba(33, 33, 33, 0.4)",
        background: "#fff",
        color: "#0B0909",
      },
      "&:nth-of-type(2)": {
        border: "1px solid rgba(33, 33, 33, 0.4)",
        background: "#0B0909",
        boxShadow:
          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
        color: "#fff",
        marginLeft: "30px",
      },
    },
  },
  dateWrap: {
    display: "flex",
    alignItems: "center",
    color: "#595959 !important",
    fontSize: "13.6px !important",
  },
  chatNotStartedArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
    width: "100%",
  },
  chatNotStartedBox: {
    color: "rgba(0, 0, 0, 0.60)",
    fontWeight: "500",
  },
}));
