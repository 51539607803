import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: [],
  errMsg: null,
};

export const getAppointmentTypes = createAsyncThunk(
  "appointmentTypes/getAppointmentTypes",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/appointments/type-and-outcome`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const appointmentTypes = createSlice({
  name: "appointmentTypes",
  initialState,
  extraReducers: {
    [getAppointmentTypes.pending]: (state) => {
      state.isLoading = true;
    },
    [getAppointmentTypes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    [getAppointmentTypes.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default appointmentTypes.reducer;
