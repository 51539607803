import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  citiesListGAMLS: [],
  citiesListFMLS: [],
  citiesListStellar: [],
  citiesListMiamire:[],
  citiesListCarolina:[],
  errMsg: null,
};

export const getAgentRecruitingCities = createAsyncThunk("agentRecruitment/getAgentRecruitingCities", async (type, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/recruitment/cities/${type}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const citiesList = createSlice({
  name: "citiesList",
  initialState,
  extraReducers: {
    [getAgentRecruitingCities.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRecruitingCities.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.citiesListGAMLS) {
        state.citiesListGAMLS = action.payload.citiesListGAMLS;
      }
      if (action.payload.citiesListFMLS) {
        state.citiesListFMLS = action.payload.citiesListFMLS;
      }
      if (action.payload.citiesListStellar) {
        state.citiesListStellar = action.payload.citiesListStellar;
      }
      if (action.payload.citiesListMiamire) {
        state.citiesListMiamire = action.payload.citiesListMiamire;
      }
      if (action.payload.citiesListCarolina) {
        state.citiesListCarolina = action.payload.citiesListCarolina;
      }
    },
    [getAgentRecruitingCities.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default citiesList.reducer;
