import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "../../../container/users/userDetail/modals/style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../responseAlert";
import { TextField, Grid, Box, IconButton, Autocomplete, Avatar, Input } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { addAgentRequest, getTicketCategories } from "../../../redux/widgets";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { handleAddMinimizeTab } from "../../../redux/widgets/index";
import { handleUpdateModalState } from "../../../redux/widgets";
import { uid } from "uid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AttachFileIcon from "@mui/icons-material/AttachFile";

function AddAgentRequestModal(props) {
  let { classes } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [uploadFiles, setUploadFiles] = useState([]);
  const dispatch = useDispatch();

  const [requestDetail, setRequestDetail] = useState({
    category: undefined,
    description: "",
    summary: "",
    agent: undefined,
  });

  const open = useSelector((state) => state.widgets.openAgentRequest);
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const categories = useSelector((state) => state.widgets.categories);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const agentRequest = useSelector((state) => state.widgets.agentRequest);
  const maximizedTab = useSelector((state) => state.widgets.maximizedTab);
  const handleUpdateDetail = (field, value) => {
    setRequestDetail({ ...requestDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!requestDetail.category) {
      isFormValid = false;
      errorMsg["category"] = "Please select the category";
      setErrorAlert({
        errorMsg: "Please select the category",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!requestDetail.agent?.id) {
      isFormValid = false;
      errorMsg["agent"] = "Please select the agent";
      setErrorAlert({
        errorMsg: "Please select the agent",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!requestDetail.summary) {
      isFormValid = false;
      errorMsg["summary"] = "Please enter the issue summary";
      setErrorAlert({
        errorMsg: "Please enter the issue summary",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!requestDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter the issue detail";
      setErrorAlert({
        errorMsg: "Please enter the issue detail",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleAddRequest = () => {
    if (handleValidate()) {
      const Data = new FormData();
      Data.append("category", requestDetail.category);
      Data.append("priority", requestDetail.priority);
      Data.append("user_id", requestDetail.agent?.id);
      Data.append("description", requestDetail.description);
      Data.append("summary", requestDetail.summary);
      uploadFiles?.map((file) => {
        Data.append("attachments[]", file);
      });

      let obj = {
        formData: Data,
        handleSuccess,
        handleError,
      };
      dispatch(addAgentRequest(obj));
    }
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully created the request ticket",
      errorType: "success",
      isOpen: true,
    });
    handleClose(false);
    setUploadFiles([]);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleClose = () => {
    setRequestDetail({
      category: undefined,
      description: "",
      summary: "",
      agent: undefined,
    });
    dispatch(handleUpdateModalState({ type: "openAgentRequest", action: false }));
  };

  useEffect(() => {
    if (maximizedTab && maximizedTab.type === "agentRequest" && open) {
      setUploadFiles(maximizedTab.tabDetail.uploadFiles);
      setRequestDetail({
        ...maximizedTab.tabDetail,
      });
    }
  }, [maximizedTab, open]);

  useEffect(() => {
    if (!categories?.length && open) {
      dispatch(getTicketCategories());
    }
  }, [open]);

  const handleUploadFiles = (file) => {
    if (uploadFiles.length > 2) {
      return;
    }
    if (file) {
      setUploadFiles((prevSt) => [file, ...prevSt]);
    }
  };
  const handleRemoveFile = (itemIndex) => {
    setUploadFiles((prevState) => prevState.filter((i, index) => index !== +itemIndex));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        // onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          sx: {
            position: "fixed",
            zIndex: 1000,
            right:
              rightPositions?.length && rightPositions.find((item) => item.type === "openAgentRequest")
                ? rightPositions.find((item) => item.type === "openAgentRequest").right
                : -20,
            bottom: -15,
            borderRadius: "6px",
          },
        }}
        hideBackdrop
        disableEnforceFocus
        disableBackdropClick
      >
        <DialogTitle className={classes.appointmentModalHeading} id="alert-dialog-title">
          <Box> Create Agent Request</Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={() => {
                dispatch(
                  handleAddMinimizeTab({
                    title: requestDetail?.summary
                      ? requestDetail?.summary
                      : requestDetail?.description
                      ? requestDetail?.description
                      : requestDetail?.category
                      ? requestDetail?.category
                      : "Agent Request",
                    type: "agentRequest",
                    id: `${uid()}agentRequest`,
                    tabDetail: {
                      ...requestDetail,
                      user: requestDetail.agent ? { ...requestDetail.agent } : null,
                      uploadFiles: uploadFiles,
                    },
                  })
                );
                handleClose();
              }}
            >
              {" "}
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={() => handleClose()}>
              {" "}
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.customScrollBar} sx={{ width: "800px" }}>
          <Grid container direction="row" spacing={2} sx={{ marginTop: "-15px" }}>
            <Grid item md={12} lg={4} sx={{ mb: "50px" }}>
              <Autocomplete
                disableClearable
                options={categories}
                getOptionLabel={(option) => option}
                value={requestDetail?.category || ""}
                onChange={(e, option) => {
                  handleUpdateDetail("category", option);
                }}
                sx={{ mt: "12px" }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    placeholder="Start Typing or Select"
                    aria-describedby="stepper-linear-event_types"
                    size="small"
                    variant="standard"
                    fullWidth
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item md={12} lg={4} sx={{ mb: "50px" }}>
              <Autocomplete
                disableClearable
                options={
                  agentRosterList?.list?.filter((it) => it.is_agent).length > 0
                    ? agentRosterList?.list?.filter((it) => it.is_agent).sort((a, b) => a.last_name.localeCompare(b.last_name))
                    : []
                }
                getOptionLabel={(option) => option.first_name + " " + option.last_name}
                onChange={(e, option) => {
                  handleUpdateDetail("agent", option);
                }}
                value={
                  requestDetail?.agent
                    ? requestDetail?.agent
                    : maximizedTab?.type === "agentRequest" && maximizedTab.tabDetail?.agent
                    ? ""
                    : undefined
                }
                sx={{ mt: "12px" }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Avatar src={option?.profile_images?.profile_img} sx={{ width: "20px", height: "20px", mr: "8px" }} />
                    {option.first_name} {option.last_name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Agent"
                    placeholder="Start Typing or Select"
                    aria-describedby="stepper-linear-event_types"
                    size="small"
                    variant="standard"
                    fullWidth
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item md={12} lg={4} sx={{ mb: "50px" }}>
              <Autocomplete
                disableClearable
                options={["urgent","high", "medium", "low"]}
                getOptionLabel={(option) => option}
                value={requestDetail?.priority || ""}
                onChange={(e, option) => {
                  handleUpdateDetail("priority", option);
                }}
                sx={{ mt: "12px" }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Priority"
                    placeholder="Start Typing or Select"
                    aria-describedby="stepper-linear-event_types"
                    size="small"
                    variant="standard"
                    fullWidth
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="summary"
                classes={{ root: classes.detailInput }}
                placeholder="Enter summary"
                value={requestDetail.summary}
                onChange={(e) => handleUpdateDetail("summary", e.target.value)}
                error={errMsg.summary}
                helperText={errMsg.summary}
                variant="standard"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={4}
                placeholder="Enter issue details here"
                value={requestDetail.description}
                onChange={(e) => handleUpdateDetail("description", e.target.value)}
                error={errMsg.description}
                helperText={errMsg.description}
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", marginTop: "8px" }}>
            {uploadFiles?.map((item, index) =>
              item.size ? (
                <div key={index} className={classes.attachmentList}>
                  <span>{item.name}</span>
                  <span className="text-secondary ms-1">({Math.round(item.size / 1024)}KB)</span>
                  <DeleteForeverIcon fontSize="30" onClick={() => handleRemoveFile(index)} />
                </div>
              ) : null
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: "4px 13px 24px 20px", justifyContent: "space-between" }}>
          <Input
            type="file"
            id="invisible-input"
            style={{ display: "none" }}
            accept=".pdf, .jpg, .png"
            onChange={(e) => handleUploadFiles(e.target.files[0])}
          />

          <Button
            sx={{
              color: "#000000",
              textTransform: "capitalize",
            }}
            component="label"
            htmlFor="invisible-input"
            variant="text"
            startIcon={<AttachFileIcon sx={{ color: "rgba(0,0,0,0.54)" }} />}
          >
            Attachments
          </Button>

          <Box>
            <Button
              onClick={() => handleClose()}
              sx={{
                color: "#000000",
                mr: "10px",
              }}
              variant="text"
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={() => handleAddRequest()}
              classes={{ root: classes.commisionSelectionButTon }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={agentRequest.isLoading}
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddAgentRequestModalStyle" })(AddAgentRequestModal);
