import * as React from "react";
import { Card, Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
function InventorySkelton(props) {
  let { count = 5 } = props;
  return (
    <Box>
      {Array.from(Array(count).keys()).map(() => (
        <Card sx={{ mb: "15px" }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="square" sx={{ height: 50, width: 50 }} />}
            title={<Skeleton animation="wave" height={25} width="80%" style={{ marginBottom: 6 }} />}
            subheader={<Skeleton animation="wave" height={20} width="50%" />}
          />
        </Card>
      ))}
    </Box>
  );
}
export default InventorySkelton;
