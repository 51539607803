import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAllPropertyList = createAsyncThunk("propertyList/getAllPropertyList", async (data, thunkAPI) => {
  try {
    let stringParam = `${process.env.REACT_APP_BASE_URL}api/transactions/listings/get-all?is_rental=${
      data.is_rental ? data.is_rental : "''"
    }`;
    if (data.under_contract) {
      stringParam = stringParam + `&under_contract=true`;
    }
    const resp = await axios.get(stringParam, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
