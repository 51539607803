import React from "react";
import { Skeleton, Grid, DialogContent } from "@mui/material";

function SmartListModalSkeleton(props) {
  return (
    <DialogContent sx={{ width: '600px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton animation="wave" height={50} width="100%" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" height={50} width="100%" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" height={50} width="100%" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" height={50} width="100%" />
        </Grid>
      </Grid>
    </DialogContent>
  );
}
export default SmartListModalSkeleton;
