import React, { useEffect, useState } from "react";
import { IconButton, List, ListItem, ListItemText, Menu, MenuItem, Paper, Box, Button } from "@mui/material";
import Avatar from "../../../components/uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import { MenuRounded } from "@mui/icons-material";
import {updateData} from '../../../utils/updateData'
import { ArchiveChat } from "../../../redux/leads/inbox";
import {useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom";
function SelectedLeadHeader(props) {
  let { selectedUser, classes, list, primaryPhone, teamsList, usersList, setSelectedUser } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAssignEl, setAnchorAssignEl] = useState(null);
  const open = Boolean(anchorEl);
  const assignOpen = Boolean(anchorAssignEl);
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const handleAnchorClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const handleCloseChat = () => {
    let obj = {
      sent: new Date().toISOString(),
      agentRecruitmentId: selectedUser?.realtor_agent_id,
      id:selectedUser?.id,
      fromNumber: selectedUser.fromNumber,
      toNumber: selectedUser.toNumber,
    };
    dispatch(ArchiveChat(obj));
     updateData(`${process.env.REACT_APP_BASE_URL}api/recruitment/textMessages/archiveChat?userId=${selectedUser.userId}&agentId=${selectedUser.realtor_agent_id?selectedUser.realtor_agent_id:""}&lead_id=${selectedUser.lead_id ?selectedUser.lead_id :""}`, {});
    setSelectedUser(null);
  };
  return (
    <Paper>
      <List className={classes.tableHeader}>
        <ListItem sx={{cursor:"pointer"}}
             onClick={() => {
                if (selectedUser?.realtor_agent_id) {
                  navigate(`/tools/recruiting/details/${selectedUser?.realtor_agent_id}`);
                }
                if (selectedUser?.lead_id) {
                  navigate(`/leads/people/details/${selectedUser?.lead_id}`);
                }
            }}
        >
          {selectedUser?.firstName ? (
            <ListItemText primary={selectedUser?.firstName} /* secondary={<React.Fragment>{selectedUser?.phones[0]?.value}</React.Fragment>}  */ />
          ) : (
            <ListItemText primary={list.data[0]?.firstName} secondary={<React.Fragment>{primaryPhone}</React.Fragment>} />
          )}
        </ListItem>
        <Box className={classes.customBtnGroup}>
          <Button
            className={classes.btnAssign}
            aria-controls={assignOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={assignOpen ? "true" : undefined}
            onClick={(event) => setAnchorAssignEl(event.currentTarget)}
          >
            ASSIGN
          </Button>
          <Menu
            className={classes.listMenuWrapper}
            anchorEl={anchorAssignEl}
            id="account-menu"
            open={assignOpen}
            onClose={() => setAnchorAssignEl(null)}
            // hidden={incomingCall || outgoingCall}
            onClick={() => setAnchorAssignEl(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>Team Inboxes</span>
            {teamsList?.map((i, index) => (
              <MenuItem key={index}>
                <Avatar sizes="small" />
                {i.name}
              </MenuItem>
            ))}
            <span>Team Members</span>
            {usersList?.map((i, index) => (
              <MenuItem key={index}>
                <Avatar sizes="small" />
                {i.full_name}
              </MenuItem>
            ))}
          </Menu>
          <Button onClick={() => handleCloseChat()} className={classes.btnClose}>
            CLOSE
          </Button>
          <IconButton
            onClick={handleAnchorClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuRounded />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleAnchorClose}
            // hidden={incomingCall || outgoingCall}
            onClick={handleAnchorClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleAnchorClose}>Mark as unread</MenuItem>
            <MenuItem>Mark as unread</MenuItem>
          </Menu>
        </Box>
      </List>
    </Paper>
  );
}
export default withStyles(styleSheet, { name: "SelectedLeadHeaderStyle" })(SelectedLeadHeader);
