import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getPointsLeaderBoards = createAsyncThunk(
  "AgentPoints/getPointsLeaderBoards",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }api/users/points/leaderboard?startDate=${
          data?.startDate || ""
        }&endDate=${data?.endDate || ""}&previousResult=${
          data?.previousResult || false
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data.leaderboard;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getPointsLeaderBoardsPrevious = createAsyncThunk(
  "AgentPoints/getPointsLeaderBoardsPrevious",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }api/users/points/leaderboard?startDate=${
          data?.startDate || ""
        }&endDate=${data?.endDate || ""}&previousResult=${
          data?.previousResult || false
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data.leaderboard;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
