import React from "react";
import { Routes, Route } from "react-router-dom";
import AgentRecruits from "./agentRecruiting/index";
import AgentRecruitsDetailView from "./agentRecruiting/agentRecruitsDetailsView/index";
import CMAList from "./cmas/list";
import Subject from "./cmas/Subject";
import AgentRecruitsTasks from "./agentRecruiting/AgentRecruitTasks";
import ReportingPage from "./reporting"

const Tools = () => {
  return (
    <Routes>
      <Route path="/recruiting" element={<AgentRecruits />} />
      <Route path="/recruiting/list/:id" exact={true} element={<AgentRecruits />} />
      <Route path="/recruiting/list/more/:id" exact={true} element={<AgentRecruits />} />
      <Route path="/recruiting/tasks/:type" exact={true} element={<AgentRecruitsTasks />} />
      <Route path="/recruiting/details/:id" element={<AgentRecruitsDetailView />} />
      <Route path="/cmas/list" element={<CMAList />} />
      <Route path="/cmas/:id" element={<Subject />} />
      <Route path="/reporting/*" element={<ReportingPage />} />
    </Routes>
  );
};

export default Tools;
