import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  ListItemText,
  Button,
  Chip,
  Box,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { styleSheet } from "../documents/style";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  updateMakeoverInvoice,
  uploadSignedAuthDoc,
  handleUpdateMakeoverInvoice,
} from "../../../../redux/listings/makeover";
import docSample from "../../../../assets/images/docSample.png";
import processingIcon from "../../../../assets/images/processingIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../../utils/getData";
import ResponseAlert from "../../../../components/responseAlert";
import { ResetDocumentState } from "../../../../redux/listings/listingDetail";
import Dropzone from "react-dropzone";
import moment from "moment";
import dragIcon from "../../../../assets/dragIcon.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { NumberFormat } from "../../../../utils/numberFormat";
import AddInvoiceMakeOver from "../../modals/addInvoice";
import { generateMakeoverInvoiceDoc } from "../../../../redux/listings/makeover";
import { uploadVendorDoc } from "../../../../redux/vendors/vendorDetail";
import PayInvoice from "../../modals/payInvoice";
import ConfirmModal from "../../../../components/widgets/modals/ConfirmModal";
const InvoicesAndPayments = ({
  classes,
  type,
  title,
  hideAction,
  vendors,
  projectDetail,
}) => {
  const [open, setOpen] = useState(false);
  const [openAddInvoice, setOpenAddInvoice] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const dispatch = useDispatch();
  const [openPayInvoice, setOpenPayInvoice] = useState(false);
  const [processing, setProcessing] = useState([]);
  const [voidConfirmation, setVoidConfirmation] = useState(false);
  const [voidInfo, setVoidInfo] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const uploadDoc = useSelector(
    (item) => item.listings.makeover.uploadAuthDocInvoice
  );
  const updateInvoice = useSelector(
    (item) => item.listings.makeover.updateInvoice
  );
  const generateInvoiceDoc = useSelector(
    (item) => item.listings.makeover.generateInvoiceDoc
  );
  const uploadW9Doc = useSelector(
    (item) => item.vendors.VendorDetail.uploadDoc
  );
  useEffect(() => {
    if (uploadDoc.success && !uploadDoc.isLoading) {
      setErrorAlert({
        errorMsg: "You have successfully added the document",
        errorType: "success",
        isOpen: true,
      });
      dispatch(ResetDocumentState());
    } else if (!uploadDoc.isLoading && uploadDoc.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(uploadDoc.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [uploadDoc.success]);

  useEffect(() => {
    if (updateInvoice.success && !updateInvoice.isLoading) {
      setErrorAlert({
        errorMsg: "You have successfully update the invoice status",
        errorType: "success",
        isOpen: true,
      });
      dispatch(ResetDocumentState());
    } else if (!updateInvoice.isLoading && updateInvoice.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateInvoice.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateInvoice.success]);

  const downLoadFile = async (src) => {
    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/transactions/signed-url` +
        "?src=" +
        encodeURIComponent(src)
    );
    if (result.status === 440) {
      window.location.href = "/login";
    }
    window.open(result.data, "_blank");
  };

  const handleFileChange = (file, data) => {
    setProcessing([...processing, data.id]);
    const Data = new FormData();
    Data.append("document", file);
    Data.append("id", data.id);
    dispatch(uploadSignedAuthDoc({ id: data.id, formData: Data }));
  };
  const handleUpdateProcessing = (id) => {
    setProcessing([...processing, `${id}action`]);
  };

  const handleSuccess = (data) => {
    console.log(data, "gggggg");
    dispatch(handleUpdateMakeoverInvoice(data));
    setErrorAlert({
      errorMsg: "You have successfully upload the W9 document",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleFileChangeW9 = (file, data) => {
    const Data = new FormData();
    Data.append("document", file);
    dispatch(
      uploadVendorDoc({
        id: data.vendor.id,
        formData: Data,
        data,
        success: handleSuccess,
      })
    );
  };
  const handleUpdateInvoice = (data, index) => {
    dispatch(updateMakeoverInvoice(data));
  };

  const handleDragEnter = (index) => {
    document.getElementById(`listings-documents-drag-${index}`).style.display =
      "flex";
  };
  const handleDragLeave = (index) => {
    document.getElementById(`listings-documents-drag-${index}`).style.display =
      "none";
  };
  const handleFileChangeDrop = (Files, data) => {
    if (Files[0]?.type?.split("/")[1] === "pdf") {
      handleFileChange(Files[0], data);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleConfirm = () => {
    setVoidInfo({});
    setVoidConfirmation(false);
    handleUpdateInvoice(
      { id: voidInfo.id, status: "approved" },
      voidInfo.index
    );
  };
  return (
    <React.Fragment>
      <Card
        sx={{ backgroundColor: "white" }}
        className={classes.TransactionSalesRoot}
      >
        <CardHeader
          action={
            <Button
              onClick={() => setOpenAddInvoice(true)}
              startIcon={<AddIcon />}
              size="small"
              variant="contained"
              className={classes.addInvoiceButton}
              color="inherit"
            >
              Add invoice
            </Button>
          }
          classes={{ title: classes.pageHeading }}
          title={title}
        />
        <CardContent>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Name</TableCell>
                  <TableCell align="left">$ Amount</TableCell>
                  <TableCell align="center">Invoice Status</TableCell>
                  <TableCell align="center">DocThumbnail</TableCell>
                  <TableCell align="center">Authorization Doc</TableCell>
                  <TableCell align="center"> Approved By</TableCell>
                  <TableCell align="center"> Payment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDetail?.makeover_invoices?.length
                  ? projectDetail?.makeover_invoices?.map((row, index) => (
                      <Dropzone
                        accept={".pdf"}
                        noClick={true}
                        noKeyboard={true}
                        onDragEnter={() => handleDragEnter(index)}
                        onDragLeave={() => handleDragLeave(index)}
                        disabled={
                          row.status === "approved" && row.authorization_doc
                            ? false
                            : true
                        }
                        onDrop={(acceptedFiles) => {
                          handleFileChangeDrop(acceptedFiles, row);
                          handleDragLeave(index);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <TableRow
                            {...getRootProps()}
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              position: "relative",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Box
                                id={`listings-documents-drag-${index}`}
                                className={classes.dropzoneArea}
                              >
                                <Box className={classes.dropzoneAreaInner}>
                                  <img
                                    src={dragIcon}
                                    alt="dragIcon"
                                    style={{
                                      width: "25px",
                                      marginRight: "10px",
                                    }}
                                  />{" "}
                                  <Box>Drop files to upload.</Box>
                                </Box>
                              </Box>
                              <ListItemText
                                classes={{
                                  primary: classes.makeOverListingHeading,
                                }}
                                primary={row?.vendor?.name || "CompanyName"}
                                secondary={`Invoice ${row.invoice_no}`}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {NumberFormat({
                                number: row?.amount_due,
                                maximumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell align="center">
                              {row.status === "submitted" && (
                                <Chip
                                  size="small"
                                  sx={{
                                    backgroundColor: "#0B0909",
                                    color: "white",
                                    minWidth: "100px",
                                    textTransform: "capitalize",
                                  }}
                                  label={row.status}
                                />
                              )}
                              {row.status === "unapproved" && (
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                  sx={{
                                    minWidth: "100px",
                                    textTransform: "capitalize",
                                  }}
                                  label={"Rejected"}
                                />
                              )}
                              {row.status === "approved" && (
                                <Chip
                                  size="small"
                                  sx={{
                                    backgroundColor: "#66BB6A",
                                    color: "white",
                                    minWidth: "100px",
                                    textTransform: "capitalize",
                                  }}
                                  label={row.status}
                                />
                              )}
                              {row.status === "paid" && (
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  color="success"
                                  sx={{
                                    minWidth: "100px",
                                    textTransform: "capitalize",
                                  }}
                                  label={row.status}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              onClick={() => downLoadFile(row.file_src)}
                            >
                              <center>
                                {row.status !== "missing" && (
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "60px",
                                      height: "78px",
                                      "&:hover": {
                                        "& .download-and-update-button": {
                                          display: "block",
                                        },
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        row.processing
                                          ? processingIcon
                                          : row.file_src
                                          ? process.env.REACT_APP_DOCUMENT_IMG +
                                            encodeURIComponent(
                                              row.file_src.replace(
                                                ".pdf",
                                                ".png"
                                              )
                                            )
                                          : docSample
                                      }
                                      width="60px"
                                      height="78px"
                                      style={{
                                        cursor: "pointer",
                                        border: "0.4px solid #000000",
                                        objectFit: "contain",
                                      }}
                                      alt="doc"
                                    />
                                    <Box
                                      className="download-and-update-button"
                                      sx={{
                                        position: "absolute",
                                        top: "25px",
                                        right: "-15px",
                                        display: "none",
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          downLoadFile(row.file_src)
                                        }
                                        className={classes.downloadButton}
                                        variant="contained"
                                        size="small"
                                      >
                                        Download
                                      </Button>
                                    </Box>
                                  </Box>
                                )}
                              </center>
                            </TableCell>
                            <TableCell>
                              <center>
                                {row.status === "approved" &&
                                !row.authorization_doc ? (
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                      dispatch(
                                        generateMakeoverInvoiceDoc({
                                          id: row.id,
                                        })
                                      );
                                      setProcessing([...processing, row.id]);
                                    }}
                                    color="success"
                                    sx={{
                                      minWidth: "100px",
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                    }}
                                    label={"GENERATE"}
                                  />
                                ) : (
                                  ""
                                )}
                                {(row.status === "approved" ||
                                  row.status === "paid") &&
                                row.authorization_doc ? (
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "60px",
                                      height: "78px",
                                      "&:hover": {
                                        "& .download-and-update-button": {
                                          display: "block",
                                        },
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        processing.includes(row.id)
                                          ? processingIcon
                                          : row.authorization_doc ||
                                            row.signed_authorization_doc
                                          ? process.env.REACT_APP_DOCUMENT_IMG +
                                            encodeURIComponent(
                                              (
                                                row.signed_authorization_doc ||
                                                row.authorization_doc
                                              ).replace(".pdf", ".png")
                                            )
                                          : docSample
                                      }
                                      width="60px"
                                      height={"78px"}
                                      style={{
                                        cursor: "pointer",
                                        border: "0.4px solid #000000",
                                        objectFit: "contain",
                                      }}
                                      alt="doc"
                                    />
                                    {row.signed_authorization_doc && (
                                      <CheckCircleIcon
                                        className={`${classes.checkIcon} check-icon`}
                                      />
                                    )}

                                    <Box
                                      className="download-and-update-button"
                                      sx={{
                                        position: "absolute",
                                        top: row.signed_authorization_doc
                                          ? "30px"
                                          : "12px",
                                        right: "-15px",
                                        display: "none",
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          downLoadFile(
                                            row.signed_authorization_doc ||
                                              row.authorization_doc
                                          )
                                        }
                                        className={classes.downloadButton}
                                        variant="contained"
                                        size="small"
                                      >
                                        Download
                                      </Button>
                                      {!row.signed_authorization_doc ? (
                                        <Button
                                          className={classes.uploadButtonDoc}
                                          startIcon={
                                            <UploadFileRoundedIcon
                                              sx={{ mr: "-2.5px" }}
                                              fontSize="small"
                                            />
                                          }
                                          size="small"
                                          variant="outlined"
                                          component="label"
                                        >
                                          Upload
                                          <input
                                            onChange={(e) =>
                                              handleFileChange(
                                                e.target.files[0],
                                                row
                                              )
                                            }
                                            hidden
                                            accept=".pdf"
                                            multiple
                                            type="file"
                                          />
                                        </Button>
                                      ) : null}
                                    </Box>
                                  </Box>
                                ) : null}
                              </center>
                            </TableCell>
                            <TableCell align="right">
                              {row.status !== "missing" &&
                                (row.status === "approved" ||
                                  row.status === "paid") && (
                                  <ListItemText
                                    primary={row.approved_by}
                                    secondary={
                                      row.approval_time
                                        ? moment(row.approval_time).format("L")
                                        : null
                                    }
                                  />
                                )}
                              {row.status === "unapproved" ? (
                                <Button
                                  onClick={() =>
                                    handleUpdateInvoice(
                                      { id: row.id, status: "approved" },
                                      index
                                    )
                                  }
                                  variant="outlined"
                                  size="small"
                                  color="success"
                                  classes={{
                                    root: classes.documentTableButton,
                                  }}
                                >
                                  Approve
                                </Button>
                              ) : null}
                            </TableCell>
                            <TableCell align="center" sx={{ width: "100px" }}>
                              <center>
                                {row.status === "submitted" && (
                                  <Box>
                                    <Button
                                      onClick={() =>
                                        handleUpdateInvoice(
                                          { id: row.id, status: "approved" },
                                          index
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                      color="success"
                                      classes={{
                                        root: classes.documentTableButton,
                                      }}
                                    >
                                      Approve
                                    </Button>
                                    <br />
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="error"
                                      onClick={() =>
                                        handleUpdateInvoice(
                                          { id: row.id, status: "unapproved" },
                                          index
                                        )
                                      }
                                      classes={{
                                        root: classes.documentTableButton,
                                      }}
                                      sx={{ marginTop: "10px" }}
                                    >
                                      Reject
                                    </Button>
                                  </Box>
                                )}{" "}
                                {row.signed_authorization_doc &&
                                  row.vendor?.w9_form &&
                                  (!row.generated_check ||
                                    row.status === "approved") &&
                                  row.status !== "unapproved" && (
                                    <Box>
                                      <Button
                                        onClick={() => {
                                          setInvoiceDetail(row);
                                          setOpenPayInvoice(true);
                                        }}
                                        variant="outlined"
                                        size="small"
                                        color="success"
                                        classes={{
                                          root: classes.documentTableButton,
                                        }}
                                      >
                                        Pay
                                      </Button>
                                      <br />
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        onClick={() =>
                                          handleUpdateInvoice(
                                            {
                                              id: row.id,
                                              status: "unapproved",
                                            },
                                            index
                                          )
                                        }
                                        classes={{
                                          root: classes.documentTableButton,
                                        }}
                                        sx={{ marginTop: "10px" }}
                                      >
                                        Reject
                                      </Button>
                                    </Box>
                                  )}
                                {row.signed_authorization_doc &&
                                  !row.vendor?.w9_form &&
                                  !row.generated_check && (
                                    <Box>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="success"
                                        classes={{
                                          root: classes.documentTableButton,
                                        }}
                                        component="label"
                                        sx={{
                                          color: "#FFB74D",
                                          borderColor: "#FFB74D",
                                          "&:hover": { borderColor: "#ff9800" },
                                        }}
                                      >
                                        <input
                                          onChange={(e) =>
                                            handleFileChangeW9(
                                              e.target.files[0],
                                              row
                                            )
                                          }
                                          hidden
                                          accept=".pdf"
                                          multiple
                                          type="file"
                                        />
                                        Upload W9
                                      </Button>
                                      <br />
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        onClick={() =>
                                          handleUpdateInvoice(
                                            {
                                              id: row.id,
                                              status: "unapproved",
                                            },
                                            index
                                          )
                                        }
                                        classes={{
                                          root: classes.documentTableButton,
                                        }}
                                        sx={{ marginTop: "10px" }}
                                      >
                                        Reject
                                      </Button>
                                    </Box>
                                  )}
                                {row.signed_authorization_doc &&
                                row.vendor?.w9_form &&
                                row.generated_check &&
                                row.status === "paid" ? (
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "60px",
                                      height: "78px",
                                      "&:hover": {
                                        "& .download-and-update-button": {
                                          display: "block",
                                        },
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        processing.includes(`${row.id}action`)
                                          ? processingIcon
                                          : row.generated_check
                                          ? process.env.REACT_APP_DOCUMENT_IMG +
                                            encodeURIComponent(
                                              row.generated_check.replace(
                                                ".pdf",
                                                ".png"
                                              )
                                            )
                                          : docSample
                                      }
                                      width="60px"
                                      height="78px"
                                      style={{
                                        cursor: "pointer",
                                        border: "0.4px solid #000000",
                                      }}
                                      alt="doc"
                                    />
                                    <Box
                                      className="download-and-update-button"
                                      sx={{
                                        position: "absolute",
                                        top: "12.5px",
                                        right: "-15.5px",
                                        display: "none",
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          downLoadFile(row.generated_check)
                                        }
                                        className={classes.downloadButton}
                                        variant="contained"
                                        size="small"
                                      >
                                        Download
                                      </Button>
                                      <Button
                                        size="small"
                                        className={classes.voidButton}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => {
                                          setVoidConfirmation(true);
                                          setVoidInfo({
                                            index,
                                            id: row.id,
                                          });
                                        }}
                                      >
                                        Void
                                      </Button>
                                    </Box>
                                  </Box>
                                ) : null}
                              </center>
                            </TableCell>
                          </TableRow>
                        )}
                      </Dropzone>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          {!hideAction ? (
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setOpen(true)}
              className={classes.saveModalButton}
            >
              Add Document
            </Button>
          ) : null}
        </CardContent>
      </Card>
      <Backdrop
        color="inherit"
        invisible={true}
        open={
          uploadDoc.isLoading ||
          updateInvoice.isLoading ||
          generateInvoiceDoc.isLoading ||
          uploadW9Doc.isLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ConfirmModal
        open={voidConfirmation}
        setOpen={setVoidConfirmation}
        hidetrash
        title="Are you sure you want to proceed?"
        handleConfirm={handleConfirm}
      />
      <AddInvoiceMakeOver
        open={openAddInvoice}
        setOpen={setOpenAddInvoice}
        vendors={vendors}
        projectDetail={projectDetail}
      />
      <PayInvoice
        open={openPayInvoice}
        handleUpdateProcessing={handleUpdateProcessing}
        setOpen={setOpenPayInvoice}
        invoiceDetail={invoiceDetail}
      />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "ListingDocPanelStyle",
})(InvoicesAndPayments);
