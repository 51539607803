import React, { useState } from "react";
import { Avatar, Box, Button, Grid, ListItemText, Paper, Tab, Tabs, Typography } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import { TabContext } from "@mui/lab";
import { Link, useLocation } from "react-router-dom";
import flowerIcon from "../../../../assets/images/agents/flowerIcon.png";
import flowerIconWhite from "../../../../assets/images/agents/flowerIconWhite.png";
import Chip from "@mui/material/Chip";

export function AgentRosterTabs({ classes, agentProfile, id }) {
  const location = useLocation();
  return (
    <Box sx={{ width: "100%" }}>
      <TabContext>
        <Box sx={{ borderTop: 1, borderColor: "divider", marginTop: "20px" }}>
          <Tabs
            value={location.pathname}
            TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
            variant="scrollable"
            aria-label="users-tabs"
            sx={{ justifyContent: "space-around" }}
            classes={{ root: classes.customTabsUI }}
          >
            <Tab label="Details" value={`/users/${id}/details`} to={`/users/${id}/details`} component={Link} sx={{ padding: "0px 30px" }} />
            <Tab label="Payments" value={`/users/${id}/payments`} to={`/users/${id}/payments`} component={Link} sx={{ padding: "0px 30px" }} />
            <Tab label="Training" value={`/users/${id}/training`} to={`/users/${id}/training`} component={Link} sx={{ padding: "0px 30px" }} />
            <Tab label="Inventory" value={`/users/${id}/inventory`} to={`/users/${id}/inventory`} component={Link} sx={{ padding: "0px 30px" }} />
            <Tab label="Points" value={`/users/${id}/points`} to={`/users/${id}/points`} component={Link} sx={{ padding: "0px 30px" }} />
          </Tabs>
        </Box>
      </TabContext>
    </Box>
  );
}

const NameHeaderCard = ({ first_name, last_name, phone, email, profile_images, team, classes, id, admin_channel_id ,slack_team_id}) => {
  const [imageFlower, setImageFlower] = useState(flowerIcon);
  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRoot}>
      <Grid container direction="row">
        <Grid md={8} lg={9} xs={12} className={classes.userInfoLeftPanel}>
          <Avatar alt={`${first_name} ${last_name}`} variant="rounded" src={profile_images?.profile_img || profile_images?.profile_img_thumbnail} />
          <Box>
            <Typography classes={{ root: classes.userInfoLeftName }}>
              {first_name} {last_name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneIphoneIcon sx={{ fontSize: 18, marginRight: "5px" }} />
              <Typography classes={{ root: classes.userInfoDetailPhone }} variant="subtitle1">
                {" "}
                {phone ? phone : "NA"}
              </Typography>
              <EmailIcon
                sx={{
                  fontSize: 18,
                  marginLeft: "20px",
                  marginRight: "5px",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              />
              <Typography classes={{ root: classes.userInfoDetailEmail }} variant="subtitle1" fontSize={14}>
                {" "}
                {email ? email : "NA"}
              </Typography>
            </Box>
            <Grid container direction="row" alignItems="center" spacing={3} sx={{ marginTop: "10px" }}>
              <Grid item>
                <ListItemText
                  primary={<Typography classes={{ root: classes.userInfoDetailStateValue }}>$102,393,439</Typography>}
                  secondary={<Typography classes={{ root: classes.userInfoDetailStateDes }}>Volume YTD</Typography>}
                />
              </Grid>
              <Grid item>
                <ListItemText
                  primary={<Typography classes={{ root: classes.userInfoDetailStateValue }}>15</Typography>}
                  secondary={<Typography classes={{ root: classes.userInfoDetailStateDes }}>Closed</Typography>}
                />
              </Grid>
              <Grid item>
                <ListItemText
                  primary={<Typography classes={{ root: classes.userInfoDetailStateValue }}>3</Typography>}
                  secondary={<Typography classes={{ root: classes.userInfoDetailStateDes }}>Pending</Typography>}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid md={4} lg={3} xs={12} className={classes.userInfoRightPanel}>
          <Button variant="outlined">
            <PhoneIcon />
          </Button>
          {admin_channel_id && slack_team_id ? (
            <Button
              onMouseEnter={() => {
                setImageFlower(flowerIconWhite);
              }}
              onMouseLeave={() => {
                setImageFlower(flowerIcon);
              }}
              onClick={() => window.open(`slack://channel?team=${slack_team_id}&id=${admin_channel_id}`)}
              variant="outlined"
            >
              <img src={imageFlower} style={{ width: "22px" }} alt="flower icon" />
            </Button>
          ) : null}

          <Button variant="outlined">
            <MailOutlineIcon />
          </Button>
          <Button variant="outlined">
            <ReceiptIcon />
          </Button>
          <br />
          <Chip classes={{ root: classes.customerTeamChip }} label={team?.name ? team?.name : "Team Name"} color="success" />
        </Grid>
      </Grid>
      <AgentRosterTabs classes={classes} id={id} />
    </Paper>
  );
};

export default withStyles(styleSheet, { name: "agentRosterTopCardStyle" })(NameHeaderCard);
