import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from "@mui/styles";
import LinearProgress from '@mui/material/LinearProgress';

import TaskCard from "./TaskCard";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { calculateDueInShortForm, capitalize } from "../helpers";

import { changeTaskStatus, getKanbanTaskList, setTaskCurrentPageNo } from "../../../redux/projects/tasks";
import { resetList, setSelectedDepartmentID } from "../../../redux/projects/common";

import SidebarContext from "../../../components/authRoutes/sidebarContext";
import ResponseAlert from "../../../components/responseAlert";
import { openTaskDetails, setSelectedTaskId } from "../../../redux/projects/project";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 170px)",
    overflowX: 'auto',
    overflowY: 'hidden',
    flexWrap: 'nowrap',
    display: 'flex',
    gap: '10px',
    '&::-webkit-scrollbar': {
      width: '20px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #ddd',
      webkitBoxShadow: 'inset 0 0 6px #000'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  column: {
    borderRadius: '8px !important',
    minWidth: '290px',
  },
  accordion: {
    '&.MuiAccordion-root': {
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      border: '1px solid #ddd',
      backgroundColor: "rgba(217, 217, 217, 0.4)",
      margin: '0px!important',
      padding: '0px!important',
      borderRadius: '8px !important',
    }
  },
  notCollapsedAccordion: {
    '&.MuiAccordion-root': {
      width: 'auto',
      height: 'auto',
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      border: '1px solid #ddd',
      backgroundColor: "rgba(217, 217, 217, 0.4)",
      margin: '0px!important',
      padding: '0px!important',
    }
  },
  notCollapsedDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    '& .MuiTypography-h6': {
      writingMode: 'vertical-rl',
      transform: 'rotate(180deg)'
    }
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      padding: '0px!important',
      border: 'none'
    }
  },
  scrollbar: {
    height: "calc(100vh - 270px)",
    marginBottom: '10px',
    overflow: 'hidden',
    overflowY: 'auto',
    "&::-webkit-scrollbar": {
      width: 5
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(92 88 88 / 60%)",
      borderRadius: 1
    }
  },
  columnTitle: {
    fontFamily: 'AvenirNext-400 !important',
    fontWeight: '600 !important',
    fontSize: '20px !important',
  },
  columnCardCount: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px !important',
  }
}));

function KanbanView(props) {
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSidebarOpen = useContext(SidebarContext);
  const [teamMemberDropdown, openTeamMemberDropdown] = React.useState({});
  const [statusData, setStatusData] = React.useState(
    {
      Queued : [],
      Working : [],
      Review : [],
      Submitted : [],
      Approved : [],
      Completed : [],
    }
  );
  const [isCollapsed, setIsCollapsed] = React.useState(
    {
      Queued : true,
      Working : true,
      Review : true,
      Submitted : true,
      Approved : true,
      Completed : true,
    }
  );

  const tasks = useSelector((state) => state.project.kanbanTasks);
  const tasksLoading = useSelector((state) => state.project.tasksLoading);
  const searchText = useSelector((state) => state.project.searchText);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenTask = (id) => {
    dispatch(setSelectedTaskId(id));
    dispatch(openTaskDetails(true));
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    if (droppableSource.droppableId === droppableDestination.droppableId) {
      const sourceClone = Array.from(source);
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      sourceClone.splice(droppableDestination.index, 0, removed);

      const result = {};
      result[droppableSource.droppableId] = sourceClone;
  
      return result;
    } else {
      const sourceClone = Array.from(source);
      const destClone = Array.from(destination);
      const [removed] = sourceClone.splice(droppableSource.index, 1);

      let updatedStatus = {...removed, status: droppableDestination.droppableId};

      dispatch(changeTaskStatus({ taskID: removed.id, status: droppableDestination.droppableId.toLowerCase(), shouldFetchTask: false })); 
      destClone.splice(droppableDestination.index, 0, updatedStatus);
  
      const result = {};
      result[droppableSource.droppableId] = sourceClone;
      result[droppableDestination.droppableId] = destClone;
  
      return result;
    }
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!destination) {
      return;
    }
    setOpenAlert(false);

    const status = destination.droppableId.toLowerCase();
    if (['completed', 'approved', 'submitted', 'cancelled'].includes(status)) {
      setOpenAlert(true);
      return;
    }

    const result = move(
      statusData[source.droppableId],
      statusData[destination.droppableId],
      source,
      destination
    );

    const updatedStatusData = {...statusData};
    updatedStatusData[source.droppableId] = result[source.droppableId];
    updatedStatusData[destination.droppableId] = result[destination.droppableId];

    setStatusData(updatedStatusData);
  }
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  React.useEffect(() => {
    const { departmentID } = props;
    if (departmentID) dispatch(setSelectedDepartmentID(departmentID));

    setTimeout(() => {
      dispatch(getKanbanTaskList({ departmentID }));
    }, 500);

    return () => {
      dispatch(resetList());
      dispatch(setTaskCurrentPageNo(1));
    };
  }, [props.value, searchText]);

  React.useEffect(() => {
    const staData = {
      Queued : [],
      Working : [],
      Review : [],
      Submitted : [],
      Approved : [],
      Completed : [],
    };

    if (tasks.length === 0) {
      setStatusData({
        Queued : [],
        Working : [],
        Review : [],
        Submitted : [],
        Approved : [],
        Completed : [],
      });
    } else {
      tasks?.map((d, i) => {
        let dueIn = calculateDueInShortForm(d.project.due_date);
        let teamMember = null;
        if (d.team_member) {
          teamMember = {
            src: d.team_member.profile_images? d.team_member.profile_images.profile_img_thumbnail : null,
            title: d.team_member.full_name
          };
        }
  
        let task = {
          id: d.id,
          projectID: d.project_id,
          agentID: d.agent_id,
          status: d.status,
          agent: {
            image: d?.agent?.profile_images?.profile_img_thumbnail || null,
            last_name: d?.agent?.last_name,
            first_name: d?.agent?.first_name,
            full_name: d?.agent?.full_name
          },
          task: {
            name: d?.task_name,
            title: d.project?.title || '',
          },
          attachments: d.task_deliverables || [],
          comments: d.task_comments || [],
          dueIn,
          teamMember,
          department_id: d.department_id,
          created: d.createdAt
        };
        const taskStatus = capitalize(task.status); 
        if (
          ['Queued', 'Working', 'Review', 'Submitted', 'Approved', 'Completed'].includes(taskStatus)
        ) {
          staData[taskStatus].push(task);
        }
      });
      setStatusData(staData);
    }
  }, [tasks, tasks.length]);
  /**
   * End Region: useEffect Hooks
   */
  return (
    <Box sx={{ height: "calc(100vh - 150px)" }}>
      <ResponseAlert 
        alertType={'error'}
        alertMessage={'These columns [Completed, Approved, Submitted] are restricted'}
        setOpen={setOpenAlert}
        open={openAlert}
        autoHideDuration={"4000"} onClose={() => setOpenAlert(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
        <Box sx={{ minHeight: '5px', width: isSidebarOpen? "calc(100vw - 330px)" : "calc(100vw - 130px)" }}>
          {tasksLoading && (
            <LinearProgress 
              variant="indeterminate"
            />
          )} 
        </Box>
        
        <DragDropContext onDragEnd={onDragEnd}>
          <Box 
            className={classes.container} 
            sx={{ minWidth: isSidebarOpen? "calc(100vw - 134px - 213px)" : "calc(100vw - 134px - 250px)", width: isSidebarOpen? "calc(100vw - 134px - 213px)" : "100%"}}
          >
            {Object.keys(statusData).map((sD, listIndex) => {
              return (
                <Box 
                  key={sD} 
                  className={classes.column}
                  sx={{ minWidth: isCollapsed[sD]? "290px" : "40px", width: isCollapsed[sD]? "290px" : "40px", }}
                >
                  <Accordion 
                    className={isCollapsed[sD] === true? classes.accordion : classes.notCollapsedAccordion} 
                    expanded={isCollapsed[sD] === true} 
                    onChange={() => setIsCollapsed({...isCollapsed, [sD]: !isCollapsed[sD]})}
                  >
                    <AccordionSummary
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{   
                        width: isCollapsed[sD]? "290px" : "40px", 
                        '& .MuiAccordionSummary-root': {
                          padding: '0px 5px', 
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                          margin: '10px 0px!important'
                        }
                      }}
                    >
                      <Box className={isCollapsed[sD] === true? '' : classes.notCollapsedDetails} sx={{ marginBottom: '5px', }}>
                        {isCollapsed[sD] === true && (
                          <>
                            <Typography className={classes.columnTitle}>
                              {sD} 
                            </Typography>

                            <Typography className={classes.columnCardCount}>
                              {statusData[sD].length} Cards
                            </Typography>
                          </>
                        )}

                        {isCollapsed[sD] === false && (
                          <>
                            <Typography className={classes.columnCardCount}>
                              ({statusData[sD].length})
                            </Typography>
                            <Typography variant="h6" className={classes.columnTitle}>
                              {sD} 
                            </Typography>
                          </>
                        )}

                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Box 
                        className={classes.scrollbar}
                      >
                        <Droppable droppableId={sD}>
                          {(droppableProvided, droppableSnapshot) => (
                            <Box ref={droppableProvided.innerRef} sx={{ margin: '0px 10px', height: '100%' }}> 
                                {statusData[sD].map((li, index) => (
                                  <Draggable key={li.id} draggableId={`${sD}-${li.id}`} index={index}>
                                    {(provided, snapshot) => (
                                      <TaskCard 
                                        taskDetails={li} 
                                        provided={provided} 
                                        snapshot={snapshot}
                                        handleOpenTask={handleOpenTask} 
                                        openTeamMemberDropdown={openTeamMemberDropdown}
                                        teamMemberDropdown={teamMemberDropdown}
                                      />
                                    )}
                                  </Draggable>
                                ))} 
                            </Box>
                          )}
                        </Droppable>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </Box>
        </DragDropContext>
    </Box>
  );
}

export default KanbanView;
