export const styleSheet = () => ({
  digitalContentSectionArea: {
    minHeight: "100vh !important",
    padding: "50px 0px !important",
  },
  digitalContentSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    flexWrap: "wrap !important",
    gap: "20px !important",
  },
  digitalContentSectionItem: {
    cursor: "pointer !important",
    width: "292px !important",
  },
  digitalContentSectionItemTop: {
    width: "100% !important",
    height: "170px !important",
    backgroundColor: "#D9D9D9 !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "flex-end !important",
    "& img": {
      width: "70% !important",
      height: "95% !important",
      objectFit: "contain !important",
    },
  },
  digitalContentSectionItemContent: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    marginTop: "8px  !important",
    fontWeight: "600 !important",
    textTransform: "uppercase !important",
  },
});
