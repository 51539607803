import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const updateCheckoutInventory = createAsyncThunk("InventoryList/updateCheckoutInventory", async (data, thunkAPI) => {
  let transaction_listings_v2=data.transaction_listings_v2;
  let detach=data.detach
  let InventoryId=data.InventoryId;
  let id = data.id;
  delete data.id;
  delete data.transaction_listings_v2;
  delete data.InventoryId;
  delete data.detach;
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/inventory/check-out/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return {...data,transaction_listings_v2,InventoryId,detach};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
