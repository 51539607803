import React, { useContext, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "../uiElements/avatar/index";
import { Stack, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import PhoneCallbackRoundedIcon from "@mui/icons-material/PhoneCallbackRounded";
import PhoneMissedRoundedIcon from "@mui/icons-material/PhoneMissedRounded";
import Loading from "../shared/loading";
import { useSelector, useDispatch } from "react-redux";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import DataNotFound from "../notFound/dataNotFound";
import { AppContext } from "../callPanel/callContext";
import PhoneForwardedRoundedIcon from "@mui/icons-material/PhoneForwardedRounded";
import PhoneDisabledRoundedIcon from "@mui/icons-material/PhoneDisabledRounded";
import { setOffset } from "../../redux/calls/getRecentCalls";
import ResponseAlert from "../responseAlert";
import momentTZ from "moment-timezone";
import moment from "moment";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Chip from "@mui/material/Chip";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import RecentCallsSkelton from "./skeltons/recents";

export default function RecentCalls(props) {
  let { adminAuth } = props;
  const [scrollFlag, setScrollFlag] = useState(true);
  const [audioDetail, setAudioDetail] = useState({
    id: "",
    audio: "",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [counter, setCounter] = React.useState(0);
  const [counterDisplay, setCounterDisplay] = React.useState(0);
  const { setSelectedOutgoingUser, dialNumber } = useContext(AppContext);
  const dispatch = useDispatch();
  const recentCalls = useSelector((state) => state.recentCalls);

  const handleScrollEvent = (e) => {
    //console.log(e);
    var bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    if (
      bottom &&
      !recentCalls.isLoading &&
      scrollFlag &&
      !recentCalls.isLimitReached
    ) {
      setScrollFlag(false);
      dispatch(setOffset());
      setTimeout(() => {
        setScrollFlag(true);
      }, 3000);
    }
  };

  const handleCopy = (number) => {
    navigator.clipboard.writeText(number);
    setErrorAlert({
      errorMsg: "Copied Successfully",
      errorType: "success",
      isOpen: true,
    });
  };
  const handleGetDuration = (seconds) => {
    return [parseInt((seconds / 60) % 60), parseInt(seconds % 60)]
      .join(":")
      .replace(/\b(\d)\b/g, "0$1");
  };

  React.useEffect(() => {
    if (counterDisplay < counter && counter > 0) {
      setTimeout(() => setCounterDisplay(counterDisplay + 1), 1000);
    } else {
      if (audioDetail.audio) {
        audioDetail.audio?.pause();
        setAudioDetail({
          id: "",
          audio: "",
        });
        setCounter(0);
        setCounterDisplay(0);
      }
      if (counter === 0 && counterDisplay !== 0) {
        setCounterDisplay(0);
      }
    }
  }, [counter, counterDisplay]);

  const handleFindName = (user, call) => {
    if (adminAuth?.adminDetail?.first_name === user?.first_name) {
      return `${call.firstName || ""} ${call.lastName || ""}`;
    } else {
      return user.full_name;
    }
  };

  const handleFindProfileImage = (user) => {
    if (adminAuth?.adminDetail?.first_name === user?.first_name) {
      return null;
    } else {
      return (
        user?.profile_images?.profile_img ||
        user?.profile_images?.isolated_headshot_thumbnail
      );
    }
  };

  const handleFindProfileAvatarLetters = (user, call) => {
    if (adminAuth?.adminDetail?.first_name === user?.first_name) {
      return `${call.firstName?.slice(0, 1) || ""}${
        call.lastName?.slice(0, 1) || ""
      }`;
    } else {
      return `${user?.first_name?.slice(0, 1) || ""}${
        user?.last_name?.slice(0, 1) || ""
      }`;
    }
  };

  return (
    <List onScroll={handleScrollEvent} className="recent-call-list-area">
      {!recentCalls.isLoading &&
        (recentCalls?.callData?.length === 0 || !recentCalls?.callData) && (
          <DataNotFound
            color="rgba(0, 0, 0, 0.6)"
            fontSize="20px"
            icon={
              <PhoneDisabledRoundedIcon
                sx={{ fontSize: "120px", color: "rgba(0, 0, 0, 0.2)" }}
                fontSize="large"
              />
            }
            title="No Recent Calls"
          />
        )}
      {(!recentCalls.isLoading || recentCalls.offset > 0) &&
        recentCalls.callData?.map((item, index) => (
          <ListItem
            className="recent-call-list-area-item"
            style={{ padding: "0px 8px" }}
            alignItems="center"
          >
            <ListItemAvatar alignItems="center" sx={{ display: "flex" }}>
              <Avatar
                sx={{ width: "20px", height: "20px" }}
                alt={item.call?.firstName}
                src={handleFindProfileImage(item.call?.called_user)}
              >
                {item.call.firstName
                  ? item.call?.firstName?.slice(0, 1) +
                    (item.call?.lastName?.slice(0, 1) || "")
                  : item.call.called_user?.first_name
                  ? handleFindProfileAvatarLetters(
                      item.call.called_user,
                      item.call
                    )
                  : "NN"}
              </Avatar>
            </ListItemAvatar>
            <ListItemIcon sx={{ display: "flex", flexDirection: "column" }}>
              {item.call.isIncoming ? (
                item.status === "no-answer" ||
                item.status === "busy" ||
                item.status === "canceled" ? (
                  <PhoneMissedRoundedIcon color="error" />
                ) : (
                  <PhoneCallbackRoundedIcon />
                )
              ) : (
                <PhoneForwardedRoundedIcon />
              )}
              {item.call?.twilio_voicemails?.length ? (
                item.id === audioDetail.id ? (
                  <StopCircleIcon
                    onClick={() => {
                      audioDetail.audio?.pause();
                      setAudioDetail({
                        id: "",
                        audio: "",
                      });
                      setCounter(0);
                      setCounterDisplay(0);
                    }}
                    sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.87)" }}
                  />
                ) : (
                  <PlayCircleFilledWhiteIcon
                    onClick={() => {
                      let newAudio = new Audio(
                        item.call?.twilio_voicemails[0].src
                      );
                      setAudioDetail({
                        id: item.id,
                        audio: newAudio,
                      });
                      newAudio.play();
                      setCounter(
                        Number(item.call?.twilio_voicemails[0]?.duration)
                      );
                    }}
                    sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.87)" }}
                  />
                )
              ) : (
                ""
              )}

              {item.call?.twilio_voicemails?.length ? (
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: "700",
                  }}
                >
                  {counterDisplay && counter
                    ? handleGetDuration(Number(counterDisplay))
                    : handleGetDuration(
                        Number(item.call?.twilio_voicemails[0]?.duration)
                      )}
                </Box>
              ) : (
                ""
              )}
            </ListItemIcon>
            <ListItemText
              xs={{ marginLeft: "10px" }}
              primary={
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Box
                    sx={{
                      width: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.call.firstName
                      ? item.call.firstName + "  " + (item.call.lastName || "")
                      : item.call.called_user && item.call.called_user.full_name
                      ? handleFindName(item.call.called_user, item.call)
                      : "No Name"}
                  </Box>
                  <Box sx={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                    {moment(item.createdAt).isSame(moment(), "day")
                      ? momentTZ
                          .tz(item.createdAt, "America/New_York")
                          ?.format("LT")
                      : momentTZ
                          .tz(item.createdAt, "America/New_York")
                          ?.format("ll")
                          ?.split(",")[0]}
                  </Box>
                </Stack>
              }
              secondary={
                <>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography variant="overline" display="block">
                      {item.call.isIncoming
                        ? item.call.fromNumber
                        : item.call?.toNumber}
                    </Typography>
                    {item.call?.number_prefix_label && (
                      <Chip
                        className="recent-all-action-chip"
                        size="small"
                        label={item.call?.number_prefix_label}
                        variant="outlined"
                      />
                    )}
                    <ContentCopyIcon
                      className="recent-all-action-button"
                      onClick={() =>
                        handleCopy(
                          item.call.isIncoming
                            ? item.call.fromNumber
                            : item.call?.toNumber
                        )
                      }
                      fontSize="small"
                      sx={{ cursor: "pointer" }}
                    />
                    <PhoneInTalkIcon
                      className="recent-all-action-button"
                      onClick={(e) => {
                        if (item.call.isIncoming) {
                          dialNumber(
                            item.call.fromNumber,
                            null,
                            item.call.name,
                            item.call.realtor_agent_id
                              ? item.call.realtor_agent_id
                              : item.call.lead_id
                              ? item.call.lead_id
                              : null
                          );
                        } else {
                          setSelectedOutgoingUser(
                            item.call.called_user
                              ? item.call.called_user
                              : {
                                  first_name: item.call.firstName,
                                  last_name: item.call.lastName,
                                }
                          );
                          dialNumber(
                            item.call.toNumber,
                            null,
                            item.call.name,
                            item.call.realtor_agent_id
                              ? item.call.realtor_agent_id
                              : item.call.lead_id
                              ? item.call.lead_id
                              : null
                          );
                        }
                        props.handleCloseCall();
                      }}
                      sx={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  </Stack>
                </>
              }
            />
          </ListItem>
        ))}
      {recentCalls.isLimitReached && (
        <div className="limit-reached-api">No more Exist</div>
      )}
      {recentCalls.isLoading && <RecentCallsSkelton count={5} />}
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </List>
  );
}
