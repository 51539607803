import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const updateTimeLineFavorite = createAsyncThunk(
  "recruitment/updateTimeLineFavorite",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/timelines/${data.timeline_id}`,
        {
            is_starred:data.is_starred
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
