import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Skeleton } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
const MapWithStreetView = (props) => {
  let { classes } = props;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const mapContainerRef = useRef(null);
  let streetView;
  const listDetail = useSelector((state) => state.allCmas.listingDetail);
  useEffect(() => {
    // Load the Google Maps JavaScript API script
    const googleMapsScript = document.createElement("script");
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&callback=initMap`;
    window.initMap = initMap;
    document.head.appendChild(googleMapsScript);

    return () => {
      // Clean up script when component unmounts
      document.head.removeChild(googleMapsScript);
    };
  }, []);

  const initMap = () => {
    // Initialize the map
    const map = new window.google.maps.Map(mapContainerRef.current, {
      center: {
        lat:
          typeof listDetail?.data?.Coordinates === "string"
            ? Number(listDetail?.data?.Coordinates?.split(",")?.[0] || 0)
            : listDetail?.data?.Coordinates?.[0],
        lng:
          typeof listDetail?.data?.Coordinates === "string"
            ? Number(listDetail?.data?.Coordinates?.split(",")?.[1] || 0)
            : listDetail?.data?.Coordinates?.[1],
      }, // Default center
      zoom: 12, // Default zoom level
    });

    // Initialize the street view panorama
    streetView = new window.google.maps.StreetViewPanorama(
      mapContainerRef.current,
      {
        position: {
          lat:
            typeof listDetail?.data?.Coordinates === "string"
              ? Number(listDetail?.data?.Coordinates?.split(",")?.[0] || 0)
              : listDetail?.data?.Coordinates?.[0],
          lng:
            typeof listDetail?.data?.Coordinates === "string"
              ? Number(listDetail?.data?.Coordinates?.split(",")?.[1] || 0)
              : listDetail?.data?.Coordinates?.[1],
        }, // Default street view position
        pov: { heading: 0, pitch: 0 }, // Default point of view
        visible: true, // Make street view visible
      }
    );

    // Set street view to map
    map.setStreetView(streetView);
    // Check if street view is available
    const streetViewService = new window.google.maps.StreetViewService();
    streetViewService.getPanorama(
      {
        location: {
          lat:
            typeof listDetail?.data?.Coordinates === "string"
              ? Number(listDetail?.data?.Coordinates?.split(",")?.[0] || 0)
              : listDetail?.data?.Coordinates?.[0],
          lng:
            typeof listDetail?.data?.Coordinates === "string"
              ? Number(listDetail?.data?.Coordinates?.split(",")?.[1] || 0)
              : listDetail?.data?.Coordinates?.[1],
        },
        radius: 50,
      }, // Use a radius to check availability in the vicinity
      (data, status) => {
        if (status !== "OK") {
          setLoading(false);
          setError("Street view is not available at this location");
        }
      }
    );
  };

  return (
    <>
      <div ref={mapContainerRef} style={{ height: "90vh", width: "100%" }} />
      {loading && (
        <Skeleton
          variant="rectangular"
          sx={{ height: "90vh", width: "100%" }}
        />
      )}
      {error && (
        <Box className={classes.mapStreetViewNotAvailable}>
          <Box>
            <center>
              <TravelExploreIcon sx={{ fontSize: "150px" }} />
            </center>
            <Typography className={classes.notAvailableText}>
              Street view not available for this listing
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default withStyles(styleSheet, { name: "MapWithStreetViewStyle" })(
  MapWithStreetView
);
