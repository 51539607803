import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Chip, InputLabel, Grid, Card, CardHeader, Box, Stack, Typography } from "@mui/material";
import agentLockIcon from "../../../../assets/images/agents/agentLockIcon.png";
import agentHouseIcon from "../../../../assets/images/agents/agentHouseIcon.png";
import agentAvatar from "../../../../assets/images/agents/agentAvatar.png";
import ResponseAlert from "../../../../components/responseAlert";

function InventoryItemDetailModal(props) {
  let { open, setOpen, classes, lockBoxDetail } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.appointmentModalHeading} id="alert-dialog-title">
          <Box> Item Detail</Box>
          <Card sx={{ minWidth: "250px", padding: "5px 15px" }}>
            <CardHeader
              className={classes.EquipmentDetailSubBox}
              classes={{
                title: classes.agentInventoryModalCardHeader,
                subheader: classes.agentInventoryModalCardDes,
              }}
              avatar={<img src={agentLockIcon} alt="agent Method Icon White" />}
              title={lockBoxDetail.inventory?.AssetName}
              subheader={`CBS Code: ${lockBoxDetail.inventory?.CBScode}`}
            />
          </Card>
        </DialogTitle>
        <DialogContent sx={{ width: "550px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={9} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                CBS Code
              </InputLabel>
              <Card evaluation={2} sx={{ padding: "5px 15px" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Typography>{lockBoxDetail.inventory?.CBScode}</Typography>
                  <Typography
                    onClick={() => {
                      navigator.clipboard.writeText(lockBoxDetail.inventory?.CBScode);
                      setErrorAlert({ errorMsg: "Copied successfully!", errorType: "success", isOpen: true });
                    }}
                    sx={{ color: "#8D8B8B", fontSize: "13px", cursor: "pointer" }}
                  >
                    copy code
                  </Typography>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={9} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Listing attached
              </InputLabel>
              <Card evaluation={2} sx={{ padding: "5px 15px" }}>
                <CardHeader
                  className={classes.EquipmentDetailSubBox}
                  classes={{
                    title: classes.agentInventoryModalCardHeader,
                    subheader: classes.agentInventoryModalCardDes,
                  }}
                  avatar={<img src={agentHouseIcon} alt="agent Method Icon White" />}
                  title="123 Main Street"
                  subheader={
                    <Box>
                      <Typography>Roswell, GA 30075</Typography>
                      <Chip
                        sx={{
                          background: "#ECD06F",
                          minWidth: "150px",
                          color: "white",
                          marginTop: "26px",
                        }}
                        label="TO BE LISTED"
                      />
                    </Box>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={9} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Assigned to
              </InputLabel>
              <Card evaluation={2} sx={{ padding: "5px 15px" }}>
                <CardHeader
                  className={classes.EquipmentDetailSubBox}
                  classes={{
                    title: classes.agentInventoryModalCardHeader,
                    subheader: classes.agentInventoryModalCardDes,
                  }}
                  avatar={<img src={agentAvatar} alt="agent Avatar" />}
                  title="Elena"
                  subheader="Ferro"
                  action={
                    <Box sx={{ marginTop: "6px" }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        {" "}
                        <Typography variant="subtitle2" gutterBottom component="div">
                          Active Listings
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom component="div">
                          3
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        {" "}
                        <Typography variant="subtitle2" gutterBottom component="div">
                          Total Supras
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom component="div">
                          4
                        </Typography>
                      </Stack>
                    </Box>
                  }
                />
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button onClick={() => setOpen(false)} classes={{ root: classes.inventoryDetailCheckIn }} color="inherit" autoFocus>
            Check In
          </Button>
          <Button onClick={() => setOpen(false)} classes={{ root: classes.commisionSelectionButTon }} color="inherit" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, {
  name: "InventoryItemDetailModalStyle",
})(InventoryItemDetailModal);
