import React from "react";
import { Card, CardHeader, Paper, Box, Stepper, Step, StepLabel, Typography, StepContent } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import moment from "moment";
import momentTZ from "moment-timezone";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import EditDate from "../../modals/editDate";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));
let CustomColor = ["#0B0909", "#A5D6A7", "rgba(219, 10, 10, 0.6)", "#FFCA28", "#FFCA28", "#33691E"];

const QontoStepIconRoot = styled("div")(({ theme, ownerState, color }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: "#0B0909",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 10,
    height: 10,
    marginLeft: "4px",
    borderRadius: "50%",
    color: color,
    backgroundColor: "currentColor",
  },
  "& .QontoStepIcon-label": {
    color: "#0B0909",
    fontSize: "13px",
    marginRight: "5px",
    minWidth: "73px",
  },
}));

function TransactionsDetailDates(props) {
  let { classes, ContractData } = props;

  const [open, setOpen] = React.useState(false);
  const [selectedDateToEdit, setSelectedDateToEdit] = React.useState({});

  const openDateChangeDialog = (date) => { 
    setSelectedDateToEdit(date);
    setOpen(true);
  };
  const closeDateChangeDialog = () => setOpen(false);

  const steps = [
    {
      label: "Binding Agreement",
      key: 'binding_date',
      date: ContractData?.binding_date ? ContractData?.binding_date : "",
      documentName: 'Amendment to Binding Agreement Date',
    },
    {
      label: "Earnest Money Due",
      key: 'earnest_money_due_date',
      date: ContractData?.earnest_money_due_date ? ContractData?.earnest_money_due_date : "",
      documentName: 'Amendment to Earnest Money Due Date',
    },
    {
      label: "Due Dilligence Ends",
      key: 'due_dilligence_date',
      date: ContractData?.due_dilligence_date ? ContractData?.due_dilligence_date : "",
      documentName: 'Amendment to extend Due Diligence Period',
    },
    {
      label: "Appraisal Contingency Ends",
      key: 'appraisal_contingency_date',
      date: ContractData.financing_type === "cash" ? null : ContractData?.appraisal_contingency_date ? ContractData?.appraisal_contingency_date : "",
      documentName: 'Amendment to Appraisal Contingency',
    },
    {
      label: "Finance Contingency Ends",
      key: 'finance_contingency_date',
      date: ContractData.financing_type === "cash" ? null : ContractData?.finance_contingency_date ? ContractData?.finance_contingency_date : "",
      documentName: 'Amendment to Financing Contingency',
    },
    {
      label: "Closing Date",
      key: 'closing_date',
      date: ContractData?.closing_date ? ContractData?.closing_date : "",
      documentName: 'Amendment to Closing Date',
    },
  ].filter((item) => item.date)
    .sort((a, b) => {
      let c = new Date(a.date);
      let d = new Date(b.date);
      return c - d;
    });

  function QontoStepIcon(props) {
    const { active, className, icon } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} color={CustomColor[icon - 1]} className={className}>
        <div className="QontoStepIcon-label">
          {momentTZ
            .utc(steps[icon - 1].date || "")
            .format("MMM Do YY")
            ?.replace("th", ", ")?.replace("rd", ", ")?.replace("nd", ", ")?.replace("st", ", ")}
        </div>
        <div className="QontoStepIcon-circle" />
      </QontoStepIconRoot>
    );
  };

  return (
    <Card className={classes.overViewTabCardMain}>

      <EditDate 
        open={open} 
        dates={steps} 
        closeModal={closeDateChangeDialog} 
        ContractData={ContractData} 
        selectedDateToEdit={selectedDateToEdit}
      />

      <CardHeader classes={{ title: classes.overViewTabCardHeading }} title="Dates" />
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={3} orientation="vertical" connector={<QontoConnector color="red" />}>
          {steps.map((step, index) => (
            <Step expanded date={step.date} key={step.label} onClick={() => openDateChangeDialog(step)}>
              <StepLabel StepIconComponent={QontoStepIcon}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Card>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailDatesStyle" })(TransactionsDetailDates);
