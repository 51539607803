import React from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import DigitalContent from "./digitalSection";
import DigitalSubCategories from "./category";
import CategoryTemplate from "./category/template";
function DigitalMarketing(props) {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<DigitalContent {...props} />} />
        <Route
          path="/category/:id"
          element={<DigitalSubCategories {...props} />}
        />
        <Route path="/template/:id" element={<CategoryTemplate {...props} />} />
      </Routes>
    </Box>
  );
}

export default DigitalMarketing;
