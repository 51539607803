import React from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

const PickerDialog = ({
  value,
  onClick,
  onChange,
}) => (
  <div style={{ position: 'absolute', zIndex: '999999' }} >
    <div
      style={{ position: 'fixed', top: '20px', right: '0px', bottom: '0px', left: '0px' }}
      onClick={onClick}
    />
    <ChromePicker
      color={value}
      onChange={onChange}
    />
  </div>
)

PickerDialog.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func
}

export default PickerDialog
