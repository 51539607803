import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  ListItemText,
  Box,
  IconButton,
  TableHead,
  Typography,
  Button,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import momentTZ from "moment-timezone";
import UpdateTaskStatus from "../../modals/updateTaskStatus";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import ArchiveAndUnArchiveTask from "../../modals/archiveAndUnArchive";
import { useSelector, useDispatch } from "react-redux";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import Avatar from "../../../../components/uiElements/avatar/index";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { getTransactionTaskDetail } from "../../../../redux/transactions/getTransactionTaskDetail.js";
import reactStringReplace from "react-string-replace";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import Linkify from "react-linkify";
import { Link } from "react-router-dom";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
const TransactionTasksPanel = ({
  classes,
  title,
  data,
  disableCard,
  handleCompleteTask,
  contractId,
  handleArchiveTask,
  activeTaskType,
  completedTasks,
  setActiveTaskType,
  pendingTasks,
  setCommentView,
  setTaskDetail,
  taskDetail,
  commentView,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const adminAuth = useSelector((state) => state.adminAuth);
  const [currentTask, setCurrentTask] = useState({});
  return (
    <React.Fragment>
      <Card
        elevation={disableCard ? 0 : 2}
        disableCard
        sx={{ backgroundColor: "white" }}
        className={classes.TransactionSalesRoot}
      >
        <CardHeader
          classes={{ title: classes.pageHeading }}
          title={title}
          action={
            <>
              {completedTasks === "available" &&
              activeTaskType === "pending" ? (
                <Button
                  className={classes.completedTaskButton}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setActiveTaskType("completed");
                    setCommentView(false);
                  }}
                >
                  View Completed{" "}
                </Button>
              ) : null}
              {pendingTasks === "available" &&
              activeTaskType === "completed" ? (
                <Button
                  color="inherit"
                  variant="text"
                  size="small"
                  startIcon={<KeyboardBackspaceRoundedIcon />}
                  onClick={() => {
                    setActiveTaskType("pending");
                    setCommentView(false);
                  }}
                  className={classes.pendingTaskButton}
                >
                  Back to Outstanding
                </Button>
              ) : null}
            </>
          }
        />
        <CardContent>
          <TableContainer className={classes.tableSection}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Task Name</TableCell>
                  <TableCell>
                    {activeTaskType === "completed"
                      ? "Completed Date"
                      : "Due Date"}{" "}
                  </TableCell>
                  {activeTaskType === "pending" ? (
                    <TableCell>Assigned To</TableCell>
                  ) : null}

                  {activeTaskType === "completed" ? (
                    <TableCell>Completed By</TableCell>
                  ) : null}

                  <TableCell>Latest Comment</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ flex: 1 }} component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {activeTaskType === "completed" ? (
                        row.complete_date ? (
                          <Box
                            sx={{
                              color: "#02C12C",
                            }}
                            className={classes.dateArea}
                          >
                            {" "}
                            <CheckRoundedIcon
                              fontSize="small"
                              sx={{ mt: "-3px" }}
                            />{" "}
                            <Typography sx={{ fontSize: "14px" }}>
                              {momentTZ
                                .tz(
                                  row.complete_date,
                                  adminAuth?.adminDetail?.market?.timezone ||
                                    process.env.REACT_APP_TIMEZONE
                                )
                                .format("L")}
                            </Typography>
                          </Box>
                        ) : null
                      ) : (
                        <Box
                          sx={{
                            color:
                              momentTZ
                                .tz(
                                  row.due_date,
                                  adminAuth?.adminDetail?.market?.timezone ||
                                    process.env.REACT_APP_TIMEZONE
                                )
                                .diff(
                                  momentTZ.tz(
                                    new Date(),
                                    adminAuth?.adminDetail?.market?.timezone ||
                                      process.env.REACT_APP_TIMEZONE
                                  ),
                                  "days"
                                ) < 0
                                ? "#EE0000"
                                : momentTZ
                                    .tz(
                                      row.due_date,
                                      adminAuth?.adminDetail?.market
                                        ?.timezone ||
                                        process.env.REACT_APP_TIMEZONE
                                    )
                                    .diff(
                                      momentTZ.tz(
                                        new Date(),
                                        adminAuth?.adminDetail?.market
                                          ?.timezone ||
                                          process.env.REACT_APP_TIMEZONE
                                      ),
                                      "days"
                                    ) < 3
                                ? "#EE8F00"
                                : "black",
                          }}
                          className={classes.dateArea}
                        >
                          {" "}
                          {momentTZ
                            .tz(
                              row.due_date,
                              adminAuth?.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .diff(
                              momentTZ.tz(
                                new Date(),
                                adminAuth?.adminDetail?.market?.timezone ||
                                  process.env.REACT_APP_TIMEZONE
                              ),
                              "days"
                            ) < 0 ? (
                            <ErrorRoundedIcon
                              fontSize="small"
                              sx={{ mt: "-3px" }}
                            />
                          ) : (
                            <AccessTimeRoundedIcon
                              fontSize="small"
                              sx={{ mt: "-3px" }}
                            />
                          )}{" "}
                          <Typography sx={{ fontSize: "14px" }}>
                            {momentTZ
                              .tz(
                                row.due_date,
                                adminAuth?.adminDetail?.market?.timezone ||
                                  process.env.REACT_APP_TIMEZONE
                              )
                              .format("L")}
                          </Typography>
                        </Box>
                      )}{" "}
                    </TableCell>
                    {activeTaskType === "pending" ? (
                      <TableCell>
                        {row?.assigned_to &&
                        agentRosterList?.list.find(
                          (item) => item.id === Number(row?.assigned_to)
                        ) ? (
                          <Box className={classes.dateArea}>
                            <Avatar
                              src={
                                agentRosterList?.list.find(
                                  (item) => item.id === Number(row?.assigned_to)
                                )?.profile_images?.profile_img
                              }
                              sx={{
                                width: 30,
                                height: 28,
                                textTransform: "uppercase",
                                fontWeight: "400",
                              }}
                              variant="circle"
                            >
                              {agentRosterList?.list
                                .find(
                                  (item) => item.id === Number(row?.assigned_to)
                                )
                                ?.first_name?.slice(0, 1)}
                            </Avatar>
                            <Typography sx={{ fontSize: "14px" }}>
                              {
                                agentRosterList?.list.find(
                                  (item) => item.id === Number(row?.assigned_to)
                                )?.full_name
                              }
                            </Typography>
                          </Box>
                        ) : (
                          <Box className={classes.latestCommentSection}>
                            {" "}
                            <WorkRoundedIcon sx={{ color: "#757575" }} />
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              Transactions Dept
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ) : null}

                    {activeTaskType === "completed" ? (
                      <TableCell>
                        {row.status === "completed" && (
                          <ListItemText
                            sx={{
                              "& .MuiListItemText-primary": {
                                fontSize: "14px",
                              },
                            }}
                            primary={
                              <Box className={classes.dateArea}>
                                <Avatar
                                  src={
                                    agentRosterList?.list.find(
                                      (item) =>
                                        item.id === Number(row.last_activity_by)
                                    )?.profile_images?.profile_img
                                  }
                                  sx={{
                                    width: 30,
                                    height: 28,
                                    textTransform: "uppercase",
                                    fontWeight: "400",
                                  }}
                                  variant="circle"
                                >
                                  {agentRosterList?.list
                                    .find(
                                      (item) =>
                                        item.id === Number(row.last_activity_by)
                                    )
                                    ?.first_name?.slice(0, 1)}
                                </Avatar>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {
                                    agentRosterList?.list.find(
                                      (item) =>
                                        item.id === Number(row.last_activity_by)
                                    )?.full_name
                                  }
                                </Typography>
                              </Box>
                            }
                          />
                        )}
                      </TableCell>
                    ) : null}
                    <TableCell>
                      {" "}
                      {row?.comments?.[0] ? (
                        <Box className={classes.latestCommentSection}>
                          <Avatar
                            src={
                              agentRosterList?.list.find(
                                (item) =>
                                  item.id ===
                                  Number(row?.comments?.[0]?.created_by)
                              )?.profile_images?.profile_img
                            }
                            sx={{
                              width: 25,
                              height: 25,
                              textTransform: "uppercase",
                              fontWeight: "400",
                            }}
                            variant="circle"
                          >
                            {agentRosterList?.list
                              .find(
                                (item) =>
                                  item.id ===
                                  Number(row?.comments?.[0]?.created_by)
                              )
                              ?.first_name?.slice(0, 1)}
                          </Avatar>
                          <Box className={classes.latestCommentSectionLeft}>
                            <Typography
                              className={classes.commentContentText}
                              variant="body2"
                              color="inherit"
                            >
                              <Linkify
                                properties={{ target: "_blank" }}
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                {reactStringReplace(
                                  row?.comments?.[0].comment,
                                  /@\[(.*?)]/g,
                                  (match, i) => (
                                    <CustomTooltip
                                      title={match?.split("^")?.[0]}
                                      placement="top"
                                    >
                                      <Box
                                        sx={{
                                          color:
                                            match?.split("^")?.[1] ==
                                            adminAuth?.adminDetail.id
                                              ? "#007EA6 !important"
                                              : "#434343 !important",
                                          background:
                                            match?.split("^")?.[1] ==
                                            adminAuth?.adminDetail.id
                                              ? "#CCF3FF !important"
                                              : "#ECECEC !important",
                                          display: "inline-block",
                                          textDecoration: "none",
                                          fontWeight: "500",
                                        }}
                                        key={i}
                                        to={`/agent_roster/${
                                          match?.split("^")?.[1] || 69
                                        }/overview`}
                                        component={Link}
                                      >
                                        @{match?.split(" ")?.[0]}
                                      </Box>
                                    </CustomTooltip>
                                  )
                                )}
                              </Linkify>
                            </Typography>
                          </Box>
                        </Box>
                      ) : null}
                    </TableCell>

                    <TableCell sx={{ display: "flex", flexWrap: "nowrap" }}>
                      {row.status === "completed" ? (
                        <CustomTooltip title="Already Completed">
                          <IconButton>
                            <CheckRoundedIcon sx={{ color: "#02C12C" }} />
                          </IconButton>
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip title="Complete Task">
                          <IconButton
                            onClick={() => {
                              setCurrentTask(row);
                              setOpen(true);
                            }}
                          >
                            <CheckIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                          </IconButton>
                        </CustomTooltip>
                      )}
                      <CustomTooltip title="Comment on this Task">
                        <IconButton
                          onClick={() => {
                            if (!commentView || taskDetail.id !== row.id) {
                              setCommentView(true);
                              dispatch(
                                getTransactionTaskDetail({ taskId: row.id })
                              );
                              setTaskDetail(row);
                            } else {
                              setCommentView((view) => !view);
                            }
                          }}
                          sx={{
                            border:
                              commentView && taskDetail?.id === row.id
                                ? "1px solid rgba(0, 0, 0, 0.30)"
                                : "",
                          }}
                        >
                          <MessageRoundedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          />
                        </IconButton>
                      </CustomTooltip>

                      {row.archived ? (
                        <CustomTooltip
                          onClick={() => {
                            setOpenArchive("unArchive");
                            setCurrentTask(row);
                          }}
                          title="Unarchive Task"
                        >
                          <IconButton>
                            <UnarchiveIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            />
                          </IconButton>
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip title="Archive Task">
                          <IconButton
                            onClick={() => {
                              setOpenArchive("archive");
                              setCurrentTask(row);
                            }}
                          >
                            <ArchiveRoundedIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            />
                          </IconButton>
                        </CustomTooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <UpdateTaskStatus
        handleCompleteTask={handleCompleteTask}
        contractId={contractId}
        open={open}
        id={currentTask?.id}
        setOpen={setOpen}
      />
      <ArchiveAndUnArchiveTask
        open={openArchive}
        id={currentTask?.id}
        handleArchiveTask={handleArchiveTask}
        contractId={contractId}
        setOpen={setOpenArchive}
        taskDetail={currentTask}
      />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "TransactionTasksPanelStyle",
})(TransactionTasksPanel);
