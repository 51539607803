export const validationObj = {
  buyer: {
    street: true,
    property_type: true,
    city: true,
    state: true,
    zipcode: true,
    opposite_party_name: true,
    price: true,
    binding_date: true,
    closing_date: true,
    due_dilligence_days: true,
    financing_type: true,
    finance_contingency_days: true,
    appraisal_contingency_days: true,
    earnest_money_amount: true,
    earnest_money_holder: true,
    earnest_money_due_date: true,
    lender_company_name: true,
    closing_attorney_first_name: true,
    buyer0_type_person: {
      buyer0_first_name: true,
      buyer0_client_phone: true,
      buyer0_client_email: true,
    },
    buyer0_type_entity: {
      buyer0_entity_name: true,
      buyer0_entity_phone: true,
      buyer0_entity_email: true,
    },
    buyer1_type_person: {
      buyer1_first_name: true,
      buyer1_client_phone: true,
      buyer1_client_email: true,
    },
    buyer1_type_entity: {
      buyer1_entity_name: true,
      buyer1_entity_phone: true,
      buyer1_entity_email: true,
    },
  },
  seller: {
    property_type: true,
    city: true,
    state: true,
    street: true,
    zipcode: true,
    opposite_party_name: true,
    attached_listing: true,
    price: true,
    binding_date: true,
    closing_date: true,
    financing_type: true,
    finance_contingency_days: true,
    appraisal_contingency_days: true,
    earnest_money_amount: true,
    earnest_money_holder: true,
    earnest_money_due_date: true,
    lender_company_name: true,
    closing_attorney_first_name: true,
    seller0_type_person: {
      seller0_first_name: true,
      seller0_client_phone: true,
      seller0_client_email: true,
    },
    seller0_type_entity: {
      seller0_entity_name: true,
      seller0_entity_phone: true,
      seller0_entity_email: true,
    },
    seller1_type_person: {
      seller1_first_name: true,
      buyer1_client_phone: true,
      buyer1_client_email: true,
    },
    seller1_type_entity: {
      seller1_entity_name: true,
      seller1_entity_phone: true,
      seller1_entity_email: true,
    },
  },
  tenant: {
    street: true,
    property_type: true,
    city: true,
    state: true,
    zipcode: true,
    opposite_party_name: true,
    price: true,
    binding_date: true,
    closing_date: true,
    due_dilligence_days: true,
    financing_type: true,
    finance_contingency_days: true,
    appraisal_contingency_days: true,
    earnest_money_amount: true,
    earnest_money_holder: true,
    earnest_money_due_date: true,
    lender_company_name: true,
    closing_attorney_first_name: true,
    buyer0_type_person: {
      buyer0_first_name: true,
      buyer0_client_phone: true,
      buyer0_client_email: true,
    },
    buyer0_type_entity: {
      buyer0_entity_name: true,
      buyer0_entity_phone: true,
      buyer0_entity_email: true,
    },
    buyer1_type_person: {
      buyer1_first_name: true,
      buyer1_client_phone: true,
      buyer1_client_email: true,
    },
    buyer1_type_entity: {
      buyer1_entity_name: true,
      buyer1_entity_phone: true,
      buyer1_entity_email: true,
    },
  },
  landlord: {
    property_type: true,
    city: true,
    state: true,
    street: true,
    zipcode: true,
    opposite_party_name: true,
    attached_listing: true,
    price: true,
    binding_date: true,
    closing_date: true,
    financing_type: true,
    finance_contingency_days: true,
    appraisal_contingency_days: true,
    earnest_money_amount: true,
    earnest_money_holder: true,
    earnest_money_due_date: true,
    lender_company_name: true,
    closing_attorney_first_name: true,
    seller0_type_person: {
      seller0_first_name: true,
      seller0_client_phone: true,
      seller0_client_email: true,
    },
    seller0_type_entity: {
      seller0_entity_name: true,
      seller0_entity_phone: true,
      seller0_entity_email: true,
    },
    seller1_type_person: {
      seller1_first_name: true,
      buyer1_client_phone: true,
      buyer1_client_email: true,
    },
    seller1_type_entity: {
      seller1_entity_name: true,
      seller1_entity_phone: true,
      seller1_entity_email: true,
    },
  },
  referral: {
    price: true,
    street: true,
    city: true,
    state: true,
    zipcode: true,
    closing_date: true,
    // closing_attorney_first_name: true,
    // lender_company_name: true,
    // loan_officer_email: true,
  },
};
