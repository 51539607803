import React, { useState, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Container, Typography, Button } from "@mui/material";
import editOutline from "../../assets/images/editOutline.png";
import EmailList from "./emailList";
import EmailDetail from "./emailDetail";
import SendEmail from "./sendEmail";
import { useDispatch, useSelector } from "react-redux";
import { getEmails, getEmailTemplate } from "../../redux/sendEmailAs";
import EmailDetailSkelton from "./skelton/detailSkelton";
function SendEmailAs(props) {
  const [open, setOpen] = useState(false);
  let { classes } = props;
  const dispatch = useDispatch();
  const openedEmail = useSelector((state) => state.SendEmailAs.openedEmail);
  const emailsList = useSelector((state) => state.SendEmailAs.emailsList);
  const template = useSelector((state) => state.SendEmailAs.template);
  useEffect(() => {
    dispatch(
      getEmails({
        pageNumber: 1,
        pageSize: 20,
      })
    );
    if (!template) {
      dispatch(getEmailTemplate());
    }
  }, []);
  return (
    <Box className={classes.pageRoot}>
      <Container maxWidth="lg">
        <Box className={classes.PageHeader}>
          <Typography className={classes.pageHeading}>
            Send Email As Agent
          </Typography>
          <Button
            startIcon={
              <img
                src={editOutline}
                width={17}
                height={17}
                alt={"editOutline"}
                style={{ marginRight: "5px" }}
              />
            }
            onClick={() => setOpen(true)}
            size="large"
            variant="contained"
            className={classes.composeButton}
          >
            Compose
          </Button>
        </Box>
        <Box className={classes.emailSection}>
          <Box className={classes.emailListSection}>
            <EmailList openedEmail={openedEmail} {...props} />
          </Box>
          <Box className={classes.emailDetailSection}>
            {openedEmail && !emailsList.isLoading ? (
              <EmailDetail openedEmail={openedEmail} {...props} />
            ) : (
              <EmailDetailSkelton />
            )}
          </Box>
        </Box>
      </Container>
      <SendEmail open={open} setOpen={setOpen} {...props} />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "SendEmailAsStyle" })(
  SendEmailAs
);
