import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  zipCodesListGAMLS: [],
  zipCodesListFMLS: [],
  zipCodesListStellar: [],
  zipCodesListMiamire: [],
  zipCodesListCarolina:[],
  errMsg: null,
};

export const getAgentRecruitingZipcodes = createAsyncThunk("agentRecruitment/getAgentRecruitingZipcodes", async (type, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/recruitment/zipCode/${type}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const zipcodesList = createSlice({
  name: "zipcodesList",
  initialState,
  extraReducers: {
    [getAgentRecruitingZipcodes.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRecruitingZipcodes.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.zipCodesListGAMLS) {
        state.zipCodesListGAMLS = action.payload.zipCodesListGAMLS;
      }
      if (action.payload.zipCodesListFMLS) {
        state.zipCodesListFMLS = action.payload.zipCodesListFMLS;
      }
      if (action.payload.zipCodesListStellar) {
        state.zipCodesListStellar = action.payload.zipCodesListStellar;
      }
      if (action.payload.zipCodesListMiamire) {
        state.zipCodesListMiamire = action.payload.zipCodesListMiamire;
      }
      if (action.payload.zipCodesListCarolina) {
        state.zipCodesListCarolina = action.payload.zipCodesListCarolina;
      }
    },
    [getAgentRecruitingZipcodes.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default zipcodesList.reducer;
