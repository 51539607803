import { createSlice } from "@reduxjs/toolkit";
import { handleGetAlertTemplates } from "./getAlertTemplates";
import { handleAddAlertTemplate } from "./addAlertTemplate";
import { handleUpdateTemplate } from "./updateTemplate";
import { handleDeleteTemplate } from "./deleteTemplate";

const initialState = {
  templateList: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  addTemplate: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  updateTemplate: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  deleteTemplate: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
};

const AlertTemplates = createSlice({
  name: "AlertTemplates",
  initialState,
  extraReducers: {
    [handleGetAlertTemplates.pending]: (state) => {
      state.templateList.isLoading = true;
      state.templateList.errMsg = null;
      state.templateList.success = false;
    },
    [handleGetAlertTemplates.fulfilled]: (state, action) => {
      state.templateList.success = true;
      state.templateList.isLoading = false;
      state.templateList.data = action.payload;
    },
    [handleGetAlertTemplates.rejected]: (state, action) => {
      state.templateList.isLoading = false;
      state.templateList.success = false;
      state.templateList.errMsg = action.payload;
    },
    [handleAddAlertTemplate.pending]: (state) => {
      state.addTemplate.isLoading = true;
      state.addTemplate.errMsg = null;
      state.addTemplate.success = false;
    },
    [handleAddAlertTemplate.fulfilled]: (state, action) => {
      state.addTemplate.success = true;
      state.addTemplate.isLoading = false;
      state.addTemplate.data = action.payload;
      let total = state.templateList?.data?._metadata?.total;
      if (state.templateList?.data?._metadata?.total) {
        state.templateList.data._metadata.total = Number(total) + 1;
      }
      let templateData = [...state.templateList?.data?.alertTemplates];
      templateData.unshift({ ...action.payload });
      state.templateList.data.alertTemplates = templateData;
    },
    [handleAddAlertTemplate.rejected]: (state, action) => {
      state.addTemplate.isLoading = false;
      state.addTemplate.success = false;
      state.addTemplate.errMsg = action.payload;
    },
    [handleUpdateTemplate.pending]: (state) => {
      state.updateTemplate.isLoading = true;
      state.updateTemplate.errMsg = null;
      state.updateTemplate.success = false;
    },
    [handleUpdateTemplate.fulfilled]: (state, action) => {
      state.updateTemplate.success = true;
      state.updateTemplate.isLoading = false;
      state.updateTemplate.data = action.payload;
      let templateData = [...state.templateList?.data?.alertTemplates];
      let index = templateData.findIndex((item) => item.id === action.payload.id);
      templateData[index] = { ...templateData[index], ...action.payload };
      state.templateList.data.alertTemplates = templateData;
    },
    [handleUpdateTemplate.rejected]: (state, action) => {
      state.updateTemplate.isLoading = false;
      state.updateTemplate.success = false;
      state.updateTemplate.errMsg = action.payload;
    },
    // delete event
    [handleDeleteTemplate.pending]: (state) => {
      state.deleteTemplate.isLoading = true;
      state.deleteTemplate.errMsg = null;
      state.deleteTemplate.success = false;
    },
    [handleDeleteTemplate.fulfilled]: (state, action) => {
      state.deleteTemplate.success = true;
      state.deleteTemplate.isLoading = false;
      state.deleteTemplate.data = action.payload;
      let templateData = [...state.templateList?.data?.alertTemplates];
      let count = state.templateList?.data?._metadata?.total || 0;
      let index = templateData.findIndex((item) => item.id === action.payload.id);
      templateData.splice(index, 1);
      state.templateList.data.alertTemplates = templateData;
      state.templateList.data._metadata.total = Number(count) - 1;
    },
    [handleDeleteTemplate.rejected]: (state, action) => {
      state.deleteTemplate.isLoading = false;
      state.deleteTemplate.success = false;
      state.deleteTemplate.errMsg = action.payload;
    },
  },
});
export default AlertTemplates.reducer;
export * from "./getAlertTemplates";
export * from "./addAlertTemplate";
export * from "./updateTemplate";
export * from "./deleteTemplate";
