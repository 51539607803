import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Grid,
  ButtonGroup,
  Typography,
  Box,
  Paper,
  Stack,
} from "@mui/material";
import agentMethodIconWhite1 from "../../../../assets/images/agents/agentMethodIconWhite1.png";
import agentMethodIconBlack1 from "../../../../assets/images/agents/agentMethodIconBlack1.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
import { checkoutAgentInventory } from "../../../../redux/agents/agentRosterDetail/inventory";
import { useDispatch, useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import { getInventoryLogHistory } from "../../../../redux/agents/agentRosterDetail/inventory/getLogsHistory";
import { getAgentInventories } from "../../../../redux/agents/agentRosterDetail/inventory/getAgentInventory";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";

function AgentCheckOutSignageModal(props) {
  let { open, setOpen, classes, inventoryData } = props;
  const [SundayWhiteCount, setSundayWhiteCount] = useState(0);
  const [SaturdayWhiteCount, setSaturdayWhiteCount] = useState(0);
  const [SundayBlackCount, setSundayBlackCount] = useState(0);
  const [SaturdayBlackCount, setSaturdayBlackCount] = useState(0);
  const [manage, setManage] = useState("openHouse");
  const [counts, setCounts] = useState({
    saturdayBlackCount: 0,
    saturdayWhiteCount: 0,
    sundayBlackCount: 0,
    sundayWhiteCount: 0,
    directionBlackCount: 0,
    directionalWhiteCount: 0,
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const checkOut = useSelector(
    (state) => state.agentRoster.agentInventory.checkOut
  );

  const handleFilterAndCount = (color, day, type) => {
    if (type === "directional") {
      return inventoryData
        ?.filter(
          (item) => item.SignageColor === color && item.SignageType === type
        )
        .reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.SignageCount || 0,
          0
        );
    } else {
      return inventoryData
        ?.filter(
          (item) =>
            item.SignageColor === color &&
            item.OpenHouseDay === day &&
            (item.SignageType === type || !item.SignageType)
        )
        .reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.SignageCount || 0,
          0
        );
    }
  };

  useEffect(() => {
    if (inventoryData?.length) {
      let obj = {
        saturdayBlackCount: handleFilterAndCount(
          "black",
          "saturday",
          "open_house"
        ),
        saturdayWhiteCount: handleFilterAndCount(
          "white",
          "saturday",
          "open_house"
        ),
        sundayBlackCount: handleFilterAndCount("black", "sunday", "open_house"),
        sundayWhiteCount: handleFilterAndCount("white", "sunday", "open_house"),
        directionBlackCount: handleFilterAndCount(
          "black",
          "saturday",
          "directional"
        ),
        directionalWhiteCount: handleFilterAndCount(
          "white",
          "saturday",
          "directional"
        ),
      };
      setCounts(obj);
    }
  }, [inventoryData]);

  useEffect(() => {
    if (checkOut.success && !checkOut.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully checkout the signage",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setSundayWhiteCount(0);
      setSaturdayWhiteCount(0);
      setSundayBlackCount(0);
      setSaturdayBlackCount(0);
      dispatch(getAgentInventories({ id }));
      dispatch(getInventoryLogHistory({ id: id }));
    } else if (!checkOut.isLoading && checkOut.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(checkOut.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [checkOut.isLoading]);

  const handleSundayWhiteDec = () => {
    if (SundayWhiteCount > 0) {
      setSundayWhiteCount((pre) => pre - 1);
    }
  };
  const handleSundayWhiteInc = () => {
    setSundayWhiteCount((pre) => pre + 1);
  };
  const handleSaturdayWhiteDec = () => {
    if (SaturdayWhiteCount > 0) {
      setSaturdayWhiteCount((pre) => pre - 1);
    }
  };
  const handleSaturdayWhiteInc = () => {
    setSaturdayWhiteCount((pre) => pre + 1);
  };

  const handleSundayBlackDec = () => {
    if (SundayBlackCount > 0) {
      setSundayBlackCount((pre) => pre - 1);
    }
  };
  const handleSundayBlackInc = () => {
    setSundayBlackCount((pre) => pre + 1);
  };
  const handleSaturdayBlackDec = () => {
    if (SaturdayBlackCount > 0) {
      setSaturdayBlackCount((pre) => pre - 1);
    }
  };
  const handleSaturdayBlackInc = () => {
    setSaturdayBlackCount((pre) => pre + 1);
  };

  const handleValidate = () => {
    if (
      SundayWhiteCount ||
      SaturdayWhiteCount ||
      SundayBlackCount ||
      SaturdayBlackCount
    ) {
      return true;
    } else {
      setErrorAlert({
        errorMsg: "Please add the values first!",
        errorType: "warning",
        isOpen: true,
      });
      return false;
    }
  };

  const handleSaveCheckout = () => {
    if (handleValidate()) {
      let payload = { AgentId: id };
      if (manage === "directional") {
        payload = {
          ...payload,
          directional: {
            SignageBlackCount: SundayBlackCount,
            SignageWhiteCount: SundayWhiteCount,
          },
        };
      } else {
        payload = {
          ...payload,
          saturday: {
            SignageBlackCount: SaturdayBlackCount,
            SignageWhiteCount: SaturdayWhiteCount,
          },
          sunday: {
            SignageBlackCount: SundayBlackCount,
            SignageWhiteCount: SundayWhiteCount,
          },
        };
      }
      dispatch(checkoutAgentInventory(payload));
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          className={classes.appointmentModalHeading}
          id="alert-dialog-title"
        >
          <Box>Check Out Signage</Box>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography>Signage Type</Typography>
            <ButtonGroups
              id="Manage-button-menu"
              bgColor="white"
              bgColorHover="white"
              placement={"bottom"}
              height="36px"
              itemFontSize="16px"
              size="medium"
              color="black"
              options={[
                { title: "Open House", value: "openHouse" },
                { title: "Directional", value: "directional" },
              ]}
              onChangeMenu={(value) => {
                setManage(value);
              }}
              border={"1px solid rgba(33, 33, 33, 0.4)"}
              value={manage}
              placeholder={"Manage"}
              {...props}
            />
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: "50px", minWidth: "600px" }}>
          <br />
          <br />
          <Grid container direction="row" spacing={2}>
            <Grid
              item
              lg={manage === "directional" ? 6 : 3}
              md={6}
              xs={12}
              sx={{ zIndex: "2", position: "relative" }}
            >
              {(counts.sundayWhiteCount > 0 && manage === "openHouse") ||
              (counts.directionalWhiteCount > 0 &&
                manage === "directional") ? null : (
                <Box sx={{ position: "absolute", left: "32.3%", top: "5px" }}>
                  <CustomTooltip title="You can't add because This inventory item not added">
                    <ErrorIcon
                      sx={{
                        color: "red",
                        cursor: "pointer",
                        ml: "80px",
                        mb: "-10px",
                      }}
                    />
                  </CustomTooltip>
                </Box>
              )}
              <center>
                <Box className={classes.checkoutImgBoxArea}>
                  <img
                    src={agentMethodIconWhite1}
                    alt="agent Method Icon White"
                  />
                  <Box className={classes.checkoutImgBoxAreaText}>SUN</Box>
                </Box>
                <ButtonGroup
                  component={Paper}
                  sx={{
                    pointerEvents:
                      (counts.sundayWhiteCount > 0 && manage === "openHouse") ||
                      (counts.directionalWhiteCount > 0 &&
                        manage === "directional")
                        ? ""
                        : "none",
                  }}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    onClick={handleSundayWhiteDec}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    className={classes.checkoutBoxAreaButton}
                    sx={{ fontSize: "20px", padding: "0px 20px" }}
                  >
                    {SundayWhiteCount}
                  </Button>
                  <Button
                    onClick={handleSundayWhiteInc}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <AddIcon />
                  </Button>
                </ButtonGroup>
                <Typography classes={{ root: classes.checkoutBoxAreaDes }}>
                  White {manage === "directional" ? "Directional" : "OH Sunday"}{" "}
                </Typography>
              </center>
            </Grid>
            <Grid
              item
              hidden={manage === "directional"}
              lg={3}
              md={6}
              xs={12}
              sx={{
                zIndex: "1",
                position: "relative",
              }}
            >
              {counts.saturdayWhiteCount > 0 &&
              manage === "openHouse" ? null : (
                <Box sx={{ position: "absolute", left: "32.3%", top: "5px" }}>
                  <CustomTooltip title="You can't add because This inventory item not added">
                    <ErrorIcon
                      sx={{
                        color: "red",
                        cursor: "pointer",
                        ml: "80px",
                        mb: "-10px",
                      }}
                    />
                  </CustomTooltip>
                </Box>
              )}
              <center>
                <Box className={classes.checkoutImgBoxArea}>
                  <img
                    src={agentMethodIconWhite1}
                    alt="agent Method Icon White"
                  />
                  <Box className={classes.checkoutImgBoxAreaText}>SAT</Box>
                </Box>
                <ButtonGroup
                  sx={{
                    pointerEvents:
                      counts.saturdayWhiteCount > 0 && manage === "openHouse"
                        ? ""
                        : "none",
                  }}
                  component={Paper}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    onClick={handleSaturdayWhiteDec}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    className={classes.checkoutBoxAreaButton}
                    sx={{ fontSize: "20px", padding: "0px 20px" }}
                  >
                    {SaturdayWhiteCount}
                  </Button>
                  <Button
                    onClick={handleSaturdayWhiteInc}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <AddIcon />
                  </Button>
                </ButtonGroup>
                <Typography classes={{ root: classes.checkoutBoxAreaDes }}>
                  White OH Saturday
                </Typography>
              </center>
            </Grid>
            <Grid
              item
              lg={manage === "directional" ? 6 : 3}
              md={6}
              xs={12}
              sx={{
                zIndex: "2",
                position: "relative",
              }}
            >
              {(counts.sundayBlackCount > 0 && manage === "openHouse") ||
              (counts.directionBlackCount > 0 &&
                manage === "directional") ? null : (
                <Box sx={{ position: "absolute", left: "32.3%", top: "5px" }}>
                  <CustomTooltip title="You can't add because This inventory item not added">
                    <ErrorIcon
                      sx={{
                        color: "red",
                        cursor: "pointer",
                        ml: "80px",
                        mb: "-10px",
                      }}
                    />
                  </CustomTooltip>
                </Box>
              )}
              <center>
                <Box className={classes.checkoutImgBoxArea}>
                  <img
                    src={agentMethodIconBlack1}
                    alt="agent Method Icon White"
                  />
                  <Box
                    sx={{ color: "white" }}
                    className={classes.checkoutImgBoxAreaText}
                  >
                    SUN
                  </Box>
                </Box>
                <ButtonGroup
                  sx={{
                    pointerEvents:
                      (counts.sundayBlackCount > 0 && manage === "openHouse") ||
                      (counts.directionBlackCount > 0 &&
                        manage === "directional")
                        ? ""
                        : "none",
                  }}
                  component={Paper}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    onClick={handleSundayBlackDec}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    className={classes.checkoutBoxAreaButton}
                    sx={{ fontSize: "20px", padding: "0px 20px" }}
                  >
                    {SundayBlackCount}
                  </Button>
                  <Button
                    onClick={handleSundayBlackInc}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <AddIcon />
                  </Button>
                </ButtonGroup>
                <Typography classes={{ root: classes.checkoutBoxAreaDes }}>
                  Black {manage === "directional" ? "Directional" : "OH Sunday"}
                </Typography>
              </center>
            </Grid>
            <Grid
              item
              lg={3}
              xs={12}
              md={6}
              hidden={manage === "directional"}
              sx={{
                zIndex: "1",
                position: "relative",
              }}
            >
              {counts.saturdayBlackCount > 0 &&
              manage === "openHouse" ? null : (
                <Box sx={{ position: "absolute", left: "32.3%", top: "5px" }}>
                  <CustomTooltip title="You can't add because This inventory item not added">
                    <ErrorIcon
                      sx={{
                        color: "red",
                        cursor: "pointer",
                        ml: "80px",
                        mb: "-10px",
                      }}
                    />
                  </CustomTooltip>
                </Box>
              )}
              <center>
                <Box className={classes.checkoutImgBoxArea}>
                  <img
                    src={agentMethodIconBlack1}
                    alt="agent Method Icon White"
                  />
                  <Box
                    sx={{ color: "white" }}
                    className={classes.checkoutImgBoxAreaText}
                  >
                    SAT
                  </Box>
                </Box>
                <ButtonGroup
                  sx={{
                    pointerEvents:
                      counts.saturdayBlackCount > 0 && manage === "openHouse"
                        ? ""
                        : "none",
                  }}
                  component={Paper}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    onClick={handleSaturdayBlackDec}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    className={classes.checkoutBoxAreaButton}
                    sx={{ fontSize: "20px", padding: "0px 20px" }}
                  >
                    {SaturdayBlackCount}
                  </Button>
                  <Button
                    onClick={handleSaturdayBlackInc}
                    className={classes.checkoutBoxAreaButton}
                  >
                    <AddIcon />
                  </Button>
                </ButtonGroup>
                <Typography classes={{ root: classes.checkoutBoxAreaDes }}>
                  Black OH Saturday
                </Typography>
              </center>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>

          <LoadingButton
            onClick={() => handleSaveCheckout()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={checkOut.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AgentCheckOutModalStyle" })(
  AgentCheckOutSignageModal
);
