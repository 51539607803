export const styleSheet = (theme) => ({
  pointsTabRoot: {
    marginBottom: "16px",
    backgroundColor: "white",
    padding: "15px",
    marginTop: "-15px",
  },
  pointsTopRIght: {
    marginTop: "-40px",
  },
  pointsTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  pointsTopLeftCard1: {
    padding: "15px !important",
    minWidth: "184px !important",
    height: "77px !important",
    background: "#E8E8E8 !important",
    boxShadow: "none !important",
    borderRadius: "6px !important",
    fontWeight: "500  !important",
    fontSize: "25px  !important",
  },
  pointsTopLeftCard2: {
    padding: "15px !important",
    minWidth: "184px !important",
    height: "80px !important",
    background: "white !important",
    borderRadius: "6px !important",
    fontWeight: "500  !important",
    fontSize: "25px  !important",
    border: "1px solid rgba(0, 0, 0, 0.3) !important",
  },
  pointsTopRightCard: {
    padding: "15px !important",
    width: "136px !important",
    height: "136px !important",
    background: "#E8E8E8 !important",
    boxShadow: "none !important",
    borderRadius: "6px !important",
    fontWeight: "500  !important",
    fontSize: "25px  !important",
  },
  pointsTopRightCardHeading: {
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    textAlign: "center !important",
    marginBottom: "5px !important",
  },
  pointsTopRightCardLink: {
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    textAlign: "center !important",
    textDecoration: "underline !important",
    cursor: "pointer !important",
    textTransform: "uppercase !important",
    color: "#000000 !important",
    marginTop: "10px !important",
  },
  pointsTopRightShieldValue: {
    position: "absolute",
    left: "50%",
    top: "42%",
    transform: "translateY(-50%) translateX(-50%)",
    color: "white",
    fontSize: "20px  !important",
  },
  addPointButton: {
    color: "white !important",
    background: "#0B0909  !important",
    fontSize: "12px  !important",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    height: "32px  !important",
  },
  pointsTopRightShields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& img": {
      height: "60px",
      width: "60px",
    },
  },
  tableRoot: {
    height: "288px",
    width: "100%",
    marginTop: "20px",
    backgroundColor: "white",
  },
  pointsTabStatsRoot: {
    marginBottom: "16px",
    backgroundColor: "white",
    padding: "10px 15px",
  },
  seeMoreArePoints: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer !important",
    fontSize: "14px  !important",
  },
});
