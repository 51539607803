import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { formatAddress } from "../../../../../../utils/propertyData.js";
function PropertyStats(props) {
  let { classes } = props;
  const listDetail = useSelector((state) => state.allCmas.listingDetail);
  return (
    <>
      <Typography className={classes.propertyHeading}>
        Property Details for {formatAddress(listDetail?.data)}
      </Typography>
      <Grid container spacing={2} sx={{ mt: "30px" }}>
        {listDetail?.data?.SubdivisionName ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ paddingTop: "0px !important" }}
          >
            <Box className={classes.propertyDetailItem}>
              <Typography className={classes.propertyDetailItemHeading}>
                Subdivision Complex
              </Typography>
              <Typography className={classes.propertyDetailItemValues}>
                {" "}
                {listDetail?.data?.SubdivisionName}
              </Typography>
            </Box>
          </Grid>
        ) : null}

        {listDetail?.data?.ArchitecturalStyle?.length ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ paddingTop: "0px !important" }}
          >
            <Box className={classes.propertyDetailItem}>
              <Typography className={classes.propertyDetailItemHeading}>
                Architectural Style
              </Typography>
              <Typography className={classes.propertyDetailItemValues}>
                {listDetail?.data?.ArchitecturalStyle?.join(", ")}
              </Typography>
            </Box>
          </Grid>
        ) : null}

        {listDetail?.data?.ExteriorFeatures?.length ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ paddingTop: "0px !important" }}
          >
            <Box className={classes.propertyDetailItem}>
              <Typography className={classes.propertyDetailItemHeading}>
                Exterior
              </Typography>
              <Typography className={classes.propertyDetailItemValues}>
                {listDetail?.data?.ExteriorFeatures?.join(", ")}
              </Typography>
            </Box>
          </Grid>
        ) : null}
        {listDetail?.data?.GarageSpaces ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ paddingTop: "0px !important" }}
          >
            <Box className={classes.propertyDetailItem}>
              <Typography className={classes.propertyDetailItemHeading}>
                Num Of Garage Spaces
              </Typography>
              <Typography className={classes.propertyDetailItemValues}>
                {listDetail?.data?.GarageSpaces}
              </Typography>
            </Box>
          </Grid>
        ) : null}
        {listDetail?.data?.ParkingTotal ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ paddingTop: "0px !important" }}
          >
            <Box className={classes.propertyDetailItem}>
              <Typography className={classes.propertyDetailItemHeading}>
                Num Of Parking Spaces
              </Typography>
              <Typography className={classes.propertyDetailItemValues}>
                {listDetail?.data?.ParkingTotal}
              </Typography>
            </Box>
          </Grid>
        ) : null}

        {listDetail?.data?.ParkingFeatures?.length ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ paddingTop: "0px !important" }}
          >
            <Box className={classes.propertyDetailItem}>
              <Typography className={classes.propertyDetailItemHeading}>
                Parking Features
              </Typography>
              <Typography className={classes.propertyDetailItemValues}>
                {listDetail?.data?.ParkingFeatures?.join(", ")}
              </Typography>
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={12} md={12} lg={6} sx={{ paddingTop: "0px !important" }}>
          <Box className={classes.propertyDetailItem}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className={classes.propertyDetailItemHeading}
            >
              Property Attached
            </Typography>
            <Typography
              className={classes.propertyDetailItemValues}
              variant="subtitle2"
              display="block"
              gutterBottom
            >
              {listDetail?.data?.PropertyAttachedYN ? "Yes" : "No"}
            </Typography>
          </Box>
        </Grid>
        {listDetail?.data?.WaterfrontFeatures ? (
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ paddingTop: "0px !important" }}
          >
            <Box className={classes.propertyDetailItem}>
              <Typography className={classes.propertyDetailItemHeading}>
                Waterfront Features
              </Typography>
              <Typography className={classes.propertyDetailItemValues}>
                {listDetail?.data?.WaterfrontFeatures?.join(", ")}
              </Typography>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
export default withStyles(styleSheet, { name: "PropertyDetailStyle" })(
  PropertyStats
);
