import React from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import BasicDetails from "../cards/details/BasicDetails";
import HandShotAndDetails from '../cards/details/handshotAndLogos'
import BiographyDetails from '../cards/details/biography'
import PaymentDetails from '../cards/details/paymentDetail'
const AgentRosterDetailsTab = ({classes}) => {

  return (
    <React.Fragment>
    <BasicDetails />
    <BiographyDetails />
    <HandShotAndDetails />
    <PaymentDetails />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, { name: "agentRosterDetailsCardStyle" })(AgentRosterDetailsTab);
