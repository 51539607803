import React, { useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "../../../../components/uiElements/modal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addAgentRecruitmentSmartList,
  updateAgentRecruitmentSmartList,
} from "../../../../redux/agents/agentRecruitment/addUpdateAgentRecruitmentSmartList";
import ResponseAlert from "../../../../components/responseAlert";
import { deleteSmartList } from "../../../../redux/agents/getAgentList";
import { addLeadSmartList, updateLeadSmartList } from "../../../../redux/leads/addUpdateLeadSmartList";
import { deleteLeadsSmartList } from "../../../../redux/leads/getLeadList";

export const SaveSmartListModal = ({
  loading,
  count,
  prevUsers,
  prevTeams,
  conditions,
  prevName,
  prevDescription,
  sortOrder,
  sortBy,
  state,
  id,
  smartList,
  type,
  api_type,
  ...props
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedUsersList, setSelectedUsersList] = useState(prevUsers || []);
  const [selectedTeamsList, setSelectedTeamsList] = useState(prevTeams || []);
  const [title, setTitle] = useState(prevName || "");
  const [description, setDescription] = useState(prevDescription || "");
  const { loading: actionLoading, success } = useSelector((item) => item.agentList.modalActions);
  const { loading: leadActionLoading, success: leadSuccess } = useSelector((item) => item.leadList.modalActions);
  const deleteList = useSelector((item) => item.agentList.deleteList);
  const deleteLeadList = useSelector((item) => item.leadList.deleteList);
  const [openerror, setOpenerror] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMesage] = useState();

  useEffect(() => {
    if (success && typeof props.closeModal === "function") {
      props.closeModal(true);
    }
  }, [success]);
  useEffect(() => {
    if (leadSuccess && typeof props.closeModal === "function") {
      props.closeModal(true);
    }
  }, [leadSuccess]);
  useEffect(() => {
    if (deleteList.success && !deleteList.loading && state) {
      setAlertType("success");
      setAlertMesage("You have successfully deleted the list");
      setOpenerror(true);

      navigate("/tools/recruiting");
      setTimeout(() => {
        props.closeModal(true);
      }, 1000);
    } else if (!deleteList.loading && deleteList.errMsg) {
      setAlertType("error");
      setAlertMesage(JSON.stringify(deleteList.errMsg));
      setOpenerror(true);
    }
  }, [deleteList.loading]);

  useEffect(() => {
    if (deleteLeadList.success && !deleteLeadList.loading && state) {
      setAlertType("success");
      setAlertMesage("You have successfully deleted the list");
      setOpenerror(true);

      navigate("/leads/people");
      setTimeout(() => {
        props.closeModal(true);
      }, 1000);
    } else if (!deleteLeadList.loading && deleteLeadList.errMsg) {
      setAlertType("error");
      setAlertMesage(JSON.stringify(deleteLeadList.errMsg));
      setOpenerror(true);
    }
  }, [deleteLeadList.loading]);

  const saveList = () => {
    if (!title) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter Title");
    } else if (!description) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter Description");
    } else {
      if (id) {
        if (api_type === "lead") {
          dispatch(
            updateLeadSmartList({
              name: title,
              sharedWith: { userIds: selectedUsersList, teamIds: selectedTeamsList },
              description,
              conditions: conditions,
              sortBy,
              sortOrder,
              count: count,
              id: id,
            })
          );
        } else {
          dispatch(
            updateAgentRecruitmentSmartList({
              name: title,
              sharedWith: { userIds: selectedUsersList, teamIds: selectedTeamsList },
              description,
              conditions: conditions,
              sortBy,
              sortOrder,
              count: count,
              id: id,
            })
          );
        }
      } else {
        if (api_type === "lead") {
          dispatch(
            addLeadSmartList({
              name: title,
              sharedWith: { userIds: selectedUsersList, teamIds: selectedTeamsList },
              description,
              conditions: conditions,
              sortBy,
              sortOrder,
              count: count,
            })
          );
        } else {
          dispatch(
            addAgentRecruitmentSmartList({
              name: title,
              sharedWith: { userIds: selectedUsersList, teamIds: selectedTeamsList },
              description,
              conditions: conditions,
              sortBy,
              sortOrder,
              count: count,
            })
          );
        }
      }
    }
  };
  const handleDeleteList = () => {
    if (api_type == "lead") {
      dispatch(deleteLeadsSmartList({ id, smartList }));
    } else {
      dispatch(deleteSmartList({ id, smartList }));
    }
  };

  return (
    <>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        dialogHead={<DialogHead type={type} />}
        onSave={saveList}
        dialogBody={
          <DialogBody
            selectedUsersList={selectedUsersList}
            setSelectedUsersList={setSelectedUsersList}
            selectedTeamsList={selectedTeamsList}
            setSelectedTeamsList={setSelectedTeamsList}
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
          />
        }
        dialogFooter={<DialogFooter />}
        state={state}
        closeModal={props.closeModal}
        maxWidth="sm"
        type={type}
        onDelete={() => handleDeleteList()}
        loading={actionLoading || leadActionLoading}
        fullWidth={true}
      />
      <Backdrop sx={{ color: "#fff", zIndex: 10000000000 }} open={deleteList.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponseAlert
        setOpen={setOpenerror}
        open={openerror}
        alertType={alertType}
        alertMessage={alertMessage}
        style={{ position: "inherit" }}
      />
    </>
  );
};

// Components

const DialogHead = (props) => (
  <DialogTitle>
    <Typography component="h5" variant="h5">
      {props.type === "Update" ? "Edit " : "Save "}
      smart list
    </Typography>
  </DialogTitle>
);

const DialogBody = ({
  selectedUsersList,
  setSelectedUsersList,
  selectedTeamsList,
  setSelectedTeamsList,
  title,
  setTitle,
  description,
  setDescription,
}) => {
  const { list, teamsList } = useSelector((item) => item.users.storedListOfUsers);
  const handleUsersToggle = (e, value) => {
    const currentIndex = selectedUsersList.indexOf(value.id);
    const newChecked = [...selectedUsersList];

    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedUsersList([...newChecked]);
  };

  const handleTeamsToggle = (e, value) => {
    const currentIndex = selectedTeamsList.indexOf(value.id);
    const newChecked = [...selectedTeamsList];

    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedTeamsList([...newChecked]);
  };

  return (
    <DialogContent sx={{ padding: "20px" }}>
      <TextField
        size="small"
        fullWidth
        id="smart-list-name"
        label="Title"
        variant="outlined"
        placeholder="Enter Smart List Name"
        sx={{ marginBottom: "20px", marginTop: "20px" }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        size="small"
        fullWidth
        id="smart-list-description"
        label="Description"
        variant="outlined"
        placeholder="Enter Smart List Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <Typography marginTop={"10px"}> Share With</Typography>
      <List sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}>
        <ListItem key={""} disablePadding sx={{ borderBottom: "1px solid #c4c4c4 !important", background: "rgba(0, 0, 0, 0.04)" }}>
          <ListItemButton>Teams</ListItemButton>
        </ListItem>
        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          {teamsList?.length
            ? teamsList.map((value, i) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem key={i} disablePadding sx={{ borderBottom: "1px solid #c4c4c4 !important" }}>
                    <ListItemButton role={undefined} onClick={(e) => handleTeamsToggle(e, value)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedTeamsList.indexOf(value.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value.name} />
                    </ListItemButton>
                  </ListItem>
                );
              })
            : null}
        </Box>
      </List>

      <List sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}>
        <ListItem key={""} disablePadding sx={{ borderBottom: "1px solid #c4c4c4 !important", background: "rgba(0, 0, 0, 0.04)" }}>
          <ListItemButton>Users</ListItemButton>
        </ListItem>
        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          {list?.length
            ? list.map((value, i) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem key={i} disablePadding sx={{ borderBottom: "1px solid #c4c4c4 !important" }}>
                    <ListItemButton role={undefined} onClick={(e) => handleUsersToggle(e, value)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedUsersList.indexOf(value.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value.full_name} />
                    </ListItemButton>
                  </ListItem>
                );
              })
            : null}
        </Box>
      </List>
    </DialogContent>
  );
};

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);
