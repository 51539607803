import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const uploadLeadsFile = createAsyncThunk("Leads/uploadLeadsFile", async (data, thunkAPI) => {
  let { schema, handleSuccess, handleError } = data;
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/leads/upload-file`, schema, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    if(handleSuccess){
      handleSuccess()
    }
    return resp.data;
  } catch (error) {
    if (error.response) {
      if(handleError){
        handleError(error.response.data.message)
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      if(handleError){
        handleError(error.message)
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
