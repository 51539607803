export const styleSheet = () => ({
  pageRoot: {
    marginTop: "2px",
  },
  status: {
    border: "1px solid #ccc",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "center",
    width: "auto !important",
    textTransform: "capitalize !important",
  },
  mAlign:{
    alignItems:"center",
  }
});
