import { createSlice } from "@reduxjs/toolkit";
import { addTransaction } from "./addTransactionContract";
import { getAllTransactions } from "./getTransactionContracts";

const initialState = {
  addTransaction: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  allTransactions: {
    success: false,
    data: [],
    count: 0,
    this_week_closing: 0,
    total_active: 0,
    total_closed: false,
    errMsg: null,
  },
};
const TransactionsList = createSlice({
  name: "TransactionsList",
  initialState,
  extraReducers: {
    [addTransaction.pending]: (state) => {
      state.addTransaction.isLoading = true;
      state.addTransaction.errMsg = null;
      state.addTransaction.success = false;
    },
    [addTransaction.fulfilled]: (state, action) => {
      state.addTransaction.success = true;
      state.addTransaction.isLoading = false;
      state.addTransaction.data = action.payload;
    },
    [addTransaction.rejected]: (state, action) => {
      state.addTransaction.isLoading = false;
      state.addTransaction.success = false;
      state.addTransaction.errMsg = action.payload;
    },
    [getAllTransactions.pending]: (state,action) => {
      state.allTransactions.isLoading = true;
      state.allTransactions.errMsg = null;
      if (action.meta.arg.reset) {
        state.allTransactions.data = [];
      }
      state.allTransactions.success = false;
    },
    [getAllTransactions.fulfilled]: (state, action) => {
      state.allTransactions.success = true;
      state.allTransactions.isLoading = false;
      if (action.meta.arg.reset) {
        state.allTransactions.data = action.payload.contracts;
      } else {
        state.allTransactions.data = [...state.allTransactions.data.concat(action.payload.contracts)].filter((item,pos,self)=>self.findIndex((ite)=>ite.id===item.id)==pos);
      }
      state.addTransaction.errMsg = null;
      state.allTransactions.count = action.payload.count;

      state.allTransactions.this_month_closed = action.payload.this_month_closed;
      state.allTransactions.this_year_volume = action.payload.this_year_volume;
      state.allTransactions.this_week_closing = action.payload.this_week_closing;
      state.allTransactions.total_active = action.payload.total_active;
      state.allTransactions.total_closed = action.payload.total_closed;
      state.allTransactions.total_past_contingencies = action.payload.total_past_contingencies;

      state.addTransaction.success = false;
    },
    [getAllTransactions.rejected]: (state, action) => {
      state.allTransactions.isLoading = false;
      state.allTransactions.success = false;
      state.allTransactions.errMsg = action.payload;
    },
  },
});
export default TransactionsList.reducer;
export * from "./addTransactionContract";
export * from "./getTransactionContracts";
export * from "./getParticularContract";
