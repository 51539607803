export const styleSheet = () => ({
  emailTabRoot: {
    position: "relative",
    marginBottom: "10px",
  },
  quillEditor: {
    "& .ql-editor": {
      minHeight: "120px",
      fontSize: "14px",
    },
  },
  emailTabToRootText: {
    position: "absolute",
    top: "15px",
    left: "10px",
  },
  emailTabToRootInput: {
    "& .MuiInputBase-root": {
      // paddingLeft: "70px",
    },
  },
  textLinkBtn: {
    fontSize: "18px",
    cursor: "pointer",
    "& svg": {
      position: "relative",
      top: "2px",
      marginRight: "2px",
    },
  },
  attachmentList: {
    margin: "5px",
    marginLeft: "0",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "4px",
    border: "1px solid #dddddd",
    padding: "4px",
    fontSize: "12px",
    "& svg": {
      fontSize: "20px",
      marginLeft: "4px",
      cursor: "pointer",
    },
  },
  inputSectionArea: {
    position: "relative !important",
  },
  mediaSectionArea: {
    position: "absolute !important",
    width: "100% !important",
    padding: "0px 2px !important",
    bottom: "4px !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "flex-end !important",
    flexDirection: "row !important",
  },
  selectFileArea: {
    backgroundColor: "white !important",
  },
  uploadedFileSection: {
    flex: 1,
    display: "flex !important",
    justifyContent: "flex-start !important",
    flexWrap: "wrap !important",
    gap: "10px !important",
    paddingLeft: "5px !important",
    paddingBottom: "3px !important",
  },
  uploadedFileSectionItem: {
    position: "relative !important",
    border: "1px solid rgb(204, 210, 213) !important",
    backgroundColor: "rgb(230, 233, 235) !important",
    color: "rgb(54, 70, 80) !important",
    height: "40px !important",
    width: "40px !important",
    borderRadius: "5px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    "& img": {
      width: "100% !important",
      height: "100% !important",
      objectFit: "cover !important",
    },
    "&:hover": {
      "& svg": {
        display: "block !important",
      },
    },
  },
  uploadedDeleteIcon: {
    color: "#ff8181 !important",
    display: "none !important",
    position: "absolute !important",
    top: "-10px !important",
    right: "-10px !important",
    backgroundColor: "white !important",
    borderRadius: "100% !important",
    cursor: "pointer !important",
  },
});
