import React from "react";
import { Box, Card, Container } from "@mui/material";
import TemplateList from "./templateList";
import { Route, Routes } from "react-router-dom";
import CreateTemplate from "./createTemplate";
function TaskTemplates(props) {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Card
        variant="outlined"
        sx={{
          position: "sticky",
          top: "64px",
          zIndex: "100",
          overflow: "visible",
        }}
      >
        <Box sx={{ height: "50px" }}></Box>
      </Card>
      <Container maxWidth="lg">
        <Routes>
          <Route path="/" element={<TemplateList {...props} />} />
          <Route path="/create" element={<CreateTemplate {...props} />} />
          <Route
            path="/update"
            element={<CreateTemplate type="update" {...props} />}
          />
        </Routes>
      </Container>
    </Box>
  );
}

export default TaskTemplates;
