import React from "react";
import { Routes, Route } from "react-router-dom";
import PeopleDetailView from "./people/peopleDetail/";
import LeadsTableList from "./people/index";
import { useSelector } from "react-redux";
import InboxContainer from "./inbox";
import SmartList from "../smartLists";
const Leads = () => {
  const {
    list,
    smartList,
    smartListLoading,
    deleteList,
    columnsVisibility,
    columnsOrder,
    sorting,
    filters,
  } = useSelector((item) => item.leadList);

  return (
    <Routes>
      <Route
        path="/people"
        element={
          <LeadsTableList
            data={list}
            smartList={smartList}
            columnsVisibility={columnsVisibility}
            columnsOrder={columnsOrder}
            sorting={sorting}
            filters={filters}
            smartListLoading={smartListLoading}
            deleteList={deleteList}
          />
        }
      />
      <Route
        path="/people/list/:id"
        exact={true}
        element={
          <LeadsTableList
            data={list}
            smartList={smartList}
            columnsVisibility={columnsVisibility}
            columnsOrder={columnsOrder}
            sorting={sorting}
            filters={filters}
            smartListLoading={smartListLoading}
            deleteList={deleteList}
          />
        }
      />
      <Route
        path="/people/list/more/:id"
        exact={true}
        element={
          <LeadsTableList
            data={list}
            smartList={smartList}
            columnsVisibility={columnsVisibility}
            columnsOrder={columnsOrder}
            sorting={sorting}
            filters={filters}
            smartListLoading={smartListLoading}
            deleteList={deleteList}
          />
        }
      />
      <Route path="/people/details/:id" element={<PeopleDetailView />} />
      <Route path="/inbox" element={<InboxContainer list={list} />} />
      <Route path="/smartlists" element={<SmartList />} />
    </Routes>
  );
};

export default Leads;
