export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "30px 30px 9px 30px",
    marginBottom: "16px",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  TransactionAddressSec: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
    "& img": {
      width: "75px  !important",
      height: "47px !important",
      objectFit: "cover  !important",
      border: ".5px solid lightgrey  !important",
      borderRadius: "6px !important",
    },
  },
  dateArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "8px !important",
  },
  agentSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
    backgroundColor: "#f4f4f4 !important",
    borderRadius: "20px",
    padding: "2px 6px !important",
  },
  tabsSection: {
    padding: "20px 0px !important",
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
  },
  tabs: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    gap: "15px !important",
  },
  tabsItem: {
    backgroundColor: "#D9D9D9 !important",
    color: "black !important",
    fontWeight: "500 !important",
    padding: "5px 15px !important",
    borderRadius: "25px !important",
    cursor: "pointer !important",
    fontSize: "14px !important",
  },
  tabsItemActive: {
    backgroundColor: "black !important",
    color: "white !important",
    fontWeight: "500 !important",
    padding: "5px 15px !important",
    borderRadius: "25px !important",
    cursor: "pointer !important",
    fontSize: "14px !important",
  },
});
