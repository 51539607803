export const getData = async (url) => {
  const token = localStorage.getItem('token')
  let result = await fetch(url, {
    method: 'GET', headers: {
      Authorization: "Bearer " + token,
    }
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.log('GET_DATA_ERROR: ', error));
  return result;
};