import { Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState, Fragment } from "react";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../../../../components/uiElements/button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { setInfoAgentRecruitment } from "../../../../redux/agents/agentRecruitment/setInfoAgentRecruitment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlockIcon from "@mui/icons-material/Block";
import ResponseAlert from "../../../../components/responseAlert";
export const EditEmailModal = ({ closeModal, state }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [email, setEmail] = useState([{ value: "", isEditable: true, isPrimary: false }]);

  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const setEmailFunc = (param) => {
    setEmail((prev) => [...prev, param]);
  };
  const updateEmailsFunc = (param) => {
    const data = email.filter((_, index) => index !== param);
    setEmail(data);
  };

  const {
    list: { lead_emails },
  } = useSelector((item) => item.agentProfile.agentProfile);

  const agentInfoState = useSelector((item) => item.agentProfile);

  useEffect(() => {
    if (lead_emails && lead_emails.length) {
      const newArray = lead_emails.map(({ id, value, isPrimary, isEditable }) => ({
        id,
        value,
        isPrimary,
        isEditable,
      }));
      setEmail(newArray);
    }
  }, [lead_emails]);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the email",
      errorType: "success",
      isOpen: true,
    });
    closeModal();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error ? JSON.stringify(error) : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const onSave = async () => {
    const resp = await dispatch(
      setInfoAgentRecruitment({
        schema: { emails: email },
        agentId: params?.id,
        handleSuccess,
        handleError
      })
    );
    if (resp?.payload) {
      closeModal();
    }
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        loading={agentInfoState?.agentInfo?.loading}
        dialogHead={<DialogHead />}
        onSave={onSave}
        dialogBody={<DialogBody email={email} setEmailFunc={setEmailFunc} updateEmailsFunc={updateEmailsFunc} setEmail={setEmail} />}
        dialogFooter={<DialogFooter />}
        state={state}
        buttonWidth="100px"
        maxWidth="sm"
        fullWidth
        closeModal={closeModal}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
};

// Components
const EmailForm = ({ emailLabel, pnEmail, bnLabel, mb, updateNumbers, id, email, setEmail }) => {
  const changeEmailFunc = (e) => {
    const emailAddress = [...email];
    if (emailAddress[id].isEditable) {
      emailAddress[id].value = e.target.value;
      setEmail(emailAddress);
    }
  };
  const changePNFunc = (e) => {
    const number = [...email];
    number[id].isPrimary = !number[id].isPrimary;
    setEmail(number);
  };
  return (
    <Grid container direction="row" alignItems="center" spacing={2} sx={{ flexGrow: 1, marginBottom: mb || 0, overflow: "visible" }}>
      <Grid item sm={10}>
        <Input
          size="small"
          onChange={(e) => changeEmailFunc(e)}
          value={email[id].value}
          label={emailLabel || false}
          placeholder="test@testmail.com"
        />
      </Grid>
      <Grid
        item
        sm={1}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormControlLabel
          value="top"
          checked={email[id].isPrimary}
          control={<Checkbox />}
          onChange={(e) => changePNFunc(e)}
          label={pnEmail || false}
          labelPlacement="top"
          sx={{ "& .MuiFormControlLabel-label": { textAlign: "center", mt: pnEmail ? "-20px" : "-5px", fontSize: "14px" } }}
        />
      </Grid>
      <Grid item sm={1} sx={{ textAlign: "center" }}>
        {email[id].isEditable ? (
          <IconButton aria-label="delete" sx={{ mt: id === 0 ? "25px" : "-5px" }} onClick={() => updateNumbers(id)}>
            <CancelIcon sx={{ color: "black" }} />
          </IconButton>
        ) : (
          <IconButton sx={{ mt: id === 0 ? "25px" : "-5px" }}>
            <BlockIcon sx={{ color: "black" }} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

const DialogHead = () => (
  <DialogTitle sx={{ mt: "10px" }}>
    <Typography component="h5" variant="h5">
      Edit Email Addresses
    </Typography>
  </DialogTitle>
);

const DialogBody = ({ email, setEmailFunc, updateEmailsFunc, setEmail }) => (
  <DialogContent sx={{ mt: "30px", overflow: "visible" }}>
    {email && email.length ? (
      email.map((number, index) => {
        if (index === 0) {
          return (
            <EmailForm
              emailLabel="Email Adress"
              pnEmail="Primary Email"
              updateNumbers={updateEmailsFunc}
              id={index}
              setEmail={setEmail}
              mb="15px"
              email={email}
            />
          );
        } else {
          return (
            <>
              <EmailForm mb="15px" id={index} updateNumbers={updateEmailsFunc} setEmail={setEmail} email={email} />
            </>
          );
        }
      })
    ) : (
      <Typography
        variant="subtitle1"
        component="p"
        sx={{
          display: "block",
          color: "#adadad",
          textAlign: "center",
        }}
      >
        No Email Addresses Found!
      </Typography>
    )}

    <br />
    <Button
      sx={{ textTransform: "capitalize", fontFamily: "AvenirNext-400", mt: "-15px" }}
      onClick={() => setEmailFunc({ value: "", isPrimary: false, isEditable: true })}
      variant="text"
      themeVersion
      startIcon={<AddCircleIcon />}
    >
      Add another email
    </Button>
  </DialogContent>
);

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);
