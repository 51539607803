export const styleSheet = (theme) => ({
  modalHeading: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    display: "flex",
    justifyContent: "space-between",
  },
  iconLabel: {
    fontSize: "13px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
  },
  modalHeadingContent: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
  },
  rsvpCardHeader: {
    fontSize: "28px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  rsvpCardDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)",
  },
  saveButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  addButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    paddingLeft: "10px !important",
    paddingRight: "10px !important",
    textTransform: "capitalize !important",
  },
  modalContent: {
    width: "800px !important",
    "& .MuiFormControlLabel-label": {
      whiteSpace: "nowrap  !important",
      fontSize: "14px !important",
    },
    "& .MuiTypography-root": {
      fontSize: "14px !important",
    },
    "& .MuiInputLabel-root": {
      fontSize: "14px !important",
      color: "rgba(0, 0, 0, 0.87) !important",
    },
  },
  selectedIconBox: {
    width: "42px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(151, 151, 151, 0.2)",
    border: "1px solid rgba(0, 0, 0, 0.5)",
  },
  CardHeaderTitle: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    minWidth: "10ch",
    color: "inherit !important",
  },
  CardHeaderDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "inherit !important",
    // color: "rgba(0, 0, 0, 0.6)  !important",
  },
  userListCardNotData: {
    height: "300px  !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  userListCard: {
    height: "300px  !important",
    overflow: "auto  !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
  },
  tableRoot: {
    height: "380px",
    width: "100%",
    backgroundColor: "white",
  },
  customIconList: {
    paddingLeft:"7px !important",
    alignItems:"flex-start !important",
    "& .MuiListItemAvatar-root": {
      minWidth: "40px  !important",
    },
    "& .MuiSvgIcon-root": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  customListHeading: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    marginTop:"-2px !important"
  },
  customListHeading1: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    marginTop:"-7px !important"
  },
  customListHeading2: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    marginTop:"-4px !important"
  },
  customListContent: {
    fontSize: "13px !important",
    fontWeight: "300 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  rvcpButtonArea:{
    marginTop:"15px",
    marginLeft:'8px',
    "& .MuiSvgIcon-root": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiButtonBase-root":{
      fontSize:'12px !important'
    }
  },
  rsvpExpandedArea:{
    "& .MuiFormLabel-root":{
      color: "rgba(0, 0, 0, 0.87) !important",
      fontSize: "14px !important",
    }
  },
  updateRSVPButton:{
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textDecoration:"underline",
    color: "rgba(0, 0, 0, 0.5)",
    cursor:"pointer"
  }
});
