import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import TableSkeleton from "../../../../components/loader/tableLoader";
import Avatar from "../../../../components/uiElements/avatar";
function CallsRecordsTable(props) {
  let { classes, data, loading } = props;
  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Avatar
              sx={{ width: "30px", height: "30px", fontSize: "14px" }}
            >{`${params.row.name.split("")[0]?.slice(0, 1)}${params.row.name
              .split("")[1]
              ?.slice(0, 1)
              ?.toUpperCase()}`}</Avatar>
            <Box> {params.row.name}</Box>
          </Stack>
        );
      },
      width: 260,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "uniqueCallsCount",
      headerName: "Unique Calls",
      type: "number",
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "connectedCallsCount",
      headerName: "Calls Connected",
      type: "number",
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "conversationCount",
      headerName: "Conversations",
      type: "number",
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "talkTime",
      headerName: "Talk Time",
      type: "number",
      renderCell: ({ row }) => (Number(row.talkTime) / 60)?.toFixed(0),
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "incomingCalls",
      headerName: "Calls Incoming",
      type: "number",
      width: 160,
      editable: false,
      sortable: true,
    },
    {
      field: "missedCalls",
      headerName: "Calls Missed",
      type: "number",
      width: 130,
      editable: false,
      sortable: true,
    },
  ];
  return (
    <Box>
      {" "}
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={data}
          loading={loading}
          columns={columns}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
        />
      </Paper>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "CallsRecordsTableStyle" })(
  CallsRecordsTable
);
