import axios from "../../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  channels: [],
  errMsg: null,
};

export const getSlackChannels = createAsyncThunk("slackChannels/getSlackChannels", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/lead-routing/slack-channels`, {
      headers: { Authorization: "Bearer " + localStorage.token, "Content-Type": "application/json" },
    });
    return resp.data?.slackchannels;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const slackChannels = createSlice({
  name: "slackChannels",
  initialState,
  extraReducers: {
    [getSlackChannels.pending]: (state) => {
      state.loading = true;
    },
    [getSlackChannels.fulfilled]: (state, action) => {
      state.loading = false;
      state.channels = action.payload;
    },
    [getSlackChannels.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default slackChannels.reducer;
