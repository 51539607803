import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Stack,
  MenuItem,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSelector, useDispatch } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { updateAgentRosterProfile } from "../../../../../redux/agents/agentRoster/updateProfile";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { toast } from "react-toastify";
import { statusData } from "../../../../../utils/agents";

const BasicDetails = ({ classes }) => {
  const [isEditAble, setIsEditAble] = useState(false);
  const toastId = React.useRef(null);
  const [basicDetail, setBasicDetail] = useState({
    id: "",
    first_name: "",
    last_name: "",
    createdAt: "",
    street: "",
    city: "",
    state: "",
    postal_code: "",
    phone: "",
    email: "",
    personal_email: "",
    emergency_name: "",
    dob: "",
    emergency_phone: "",
    dob_year: "",
    dob_month: "",
    dob_date: "",
    status: "",
    type: "basicDetail",
  });
  const dispatch = useDispatch();
  const agentRosterProfile = useSelector(
    (item) => item.agentRoster.agentRosterProfile
  );
  const { profile, updateLoading } = agentRosterProfile;
  const handleUpdateField = (field, value) => {
    setBasicDetail({
      ...basicDetail,
      [field]: value,
    });
  };
  useEffect(() => {
    if (!basicDetail.id) {
      setBasicDetail({
        id: profile.id,
        first_name: profile.first_name,
        last_name: profile.last_name,
        join_date: profile.join_date,
        street: profile.street,
        city: profile.city,
        state: profile.state,
        postal_code: profile.postal_code,
        phone: profile.phone,
        email: profile.email,
        personal_email: profile.personal_email,
        emergency_name: profile.emergency_name,
        status: profile.status,
        dob: profile.dob || undefined,
        dob_date: profile.dob_date
          ? profile.dob_date
          : profile.dob
          ? new Date(profile.dob).getDate()
          : "",
        dob_month: profile.dob_month
          ? profile.dob_month
          : profile.dob
          ? new Date(profile.dob).getMonth() + 1
          : "",
        dob_year: profile.dob ? new Date(profile.dob) : "",
        emergency_phone: profile.emergency_phone,
        type: "basicDetail",
      });
    }
  }, [profile]);

  const handleSuccess = () => {
    if (!toast.isActive(toastId.current)) {
      toast.success("You have successfully update basic detail", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsEditAble(false);
    }
    setIsEditAble(false);
  };
  const handleError = (error) => {
    if (!toast.isActive(toastId.current)) {
      toast.error(JSON.stringify(error), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSave = () => {
    let obj = { ...basicDetail, handleSuccess, handleError };
    if (basicDetail.dob_date && basicDetail.dob_month && basicDetail.dob_year) {
      obj.dob = new Date(
        new Date(
          `${basicDetail.dob_month}/${basicDetail.dob_date}/${new Date(
            basicDetail.dob_year
          ).getFullYear()}`
        ).toLocaleString("en", { timeZone: "America/New_York" })
      ).toISOString();
    }
    if (basicDetail.dob_year) {
      obj.dob_year = new Date(basicDetail.dob_year).getFullYear();
    } else {
      obj.dob = "";
    }
    dispatch(updateAgentRosterProfile(obj));
  };
  return (
    <Card
      variant="outlined"
      sx={{ backgroundColor: "white" }}
      className={classes.pageRoot}
    >
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title="Basic Details"
        action={
          <div>
            <IconButton
              onClick={() => setIsEditAble(!isEditAble)}
              aria-label="locked"
            >
              {isEditAble ? (
                <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              ) : (
                <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              )}
            </IconButton>
          </div>
        }
      />
      <CardContent>
        <Grid container direction="row" spacing={6}>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="First Name"
              value={basicDetail.first_name}
              placeholder="First Name"
              onChange={(e) => handleUpdateField("first_name", e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Last Name"
              value={basicDetail.last_name}
              placeholder="Last Name"
              onChange={(e) => handleUpdateField("last_name", e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                value={basicDetail.join_date || ""}
                onChange={(value) => handleUpdateField("join_date", value)}
                label="Join Date"
                readOnly={isEditAble ? false : true}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    label="Join Date"
                    classes={{ root: classes.detailInput }}
                    {...params}
                  />
                )}
                disableFuture={isEditAble ? true : false}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              select
              classes={{ root: classes.detailInput }}
              label="Status"
              value={basicDetail.status}
              onChange={(e) => handleUpdateField("status", e.target.value)}
              variant="standard"
            >
              {statusData.map((item) => (
                <MenuItem key={item} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
          <Grid item lg={5} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Street Address"
              value={basicDetail.street}
              onChange={(e) => handleUpdateField("street", e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="City"
              value={basicDetail.city}
              variant="standard"
              onChange={(e) => handleUpdateField("city", e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={1} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="State"
              value={basicDetail.state}
              onChange={(e) => handleUpdateField("state", e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Zip Code"
              onChange={(e) => handleUpdateField("postal_code", e.target.value)}
              value={basicDetail.postal_code}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Phone"
              value={basicDetail.phone}
              onChange={(e) => handleUpdateField("phone", e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Email"
              value={basicDetail.email}
              placeholder="Enter Email"
              onChange={(e) => handleUpdateField("email", e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Personal Email"
              value={basicDetail.personal_email}
              onChange={(e) =>
                handleUpdateField("personal_email", e.target.value)
              }
              placeholder="Enter Personal Email"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4}></Grid>
          <Grid item lg={2} xs={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              select
              classes={{ root: classes.detailInput }}
              label="Birthday Month"
              value={basicDetail.dob_month}
              placeholder="Enter Entity Type "
              onChange={(e) => handleUpdateField("dob_month", e.target.value)}
              variant="standard"
            >
              {Array.from(Array(12).keys()).map((item) => (
                <MenuItem key={item} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              select
              classes={{ root: classes.detailInput }}
              label="Birthday Day"
              value={basicDetail.dob_date}
              onChange={(e) => handleUpdateField("dob_date", e.target.value)}
              variant="standard"
            >
              {Array.from(Array(31).keys()).map((item) => (
                <MenuItem key={item} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item lg={2} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year"]}
                label="Birthday Year"
                value={basicDetail.dob_year || ""}
                readOnly={isEditAble ? false : true}
                disableFuture
                onChange={(value) => handleUpdateField("dob_year", value)}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    label="Birthday year"
                    classes={{ root: classes.detailInput }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Emergency Contact Name"
              value={basicDetail.emergency_name}
              onChange={(e) =>
                handleUpdateField("emergency_name", e.target.value)
              }
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Emergency Phone"
              value={basicDetail.emergency_phone}
              onChange={(e) =>
                handleUpdateField("emergency_phone", e.target.value)
              }
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid hidden={!isEditAble} item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <LoadingButton
                onClick={handleSave}
                loading={updateLoading === "basicDetail"}
                classes={{ root: classes.saveButTon }}
                color="inherit"
                autoFocus
                size="small"
                loadingPosition="start"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styleSheet, { name: "agentRosterDetailsBasicStyle" })(
  BasicDetails
);
