import * as React from "react";
import { Card, Stack, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
function StatsSaleSkelton() {
  return (
    <Box>
      <Card variant="outlined" sx={{ padding: "15px" }}>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <center>
              <Skeleton animation="wave" width={"80%"} height={20} />
              <Skeleton animation="wave" width={"100%"} height={25} />
              <Skeleton animation="wave" width={"65%"} height={20} />
            </center>
          </Grid>
          <Grid item sm={4}>
            <center>
              <Skeleton animation="wave" width={"80%"} height={20} />
              <Skeleton animation="wave" width={"100%"} height={25} />
              <Skeleton animation="wave" width={"65%"} height={20} />
            </center>
          </Grid>
          <Grid item sm={4}>
            <center>
              <Skeleton animation="wave" width={"80%"} height={20} />
              <Skeleton animation="wave" width={"100%"} height={25} />
              <Skeleton animation="wave" width={"65%"} height={20} />
            </center>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: "15px" }}>
          <Grid item sm={4}>
            <center>
              <Skeleton animation="wave" width={"80%"} height={20} />
              <Skeleton animation="wave" width={"100%"} height={25} />
              <Skeleton animation="wave" width={"65%"} height={20} />
            </center>
          </Grid>
          <Grid item sm={4}>
            <center>
              <Skeleton animation="wave" width={"80%"} height={20} />
              <Skeleton animation="wave" width={"100%"} height={25} />
              <Skeleton animation="wave" width={"65%"} height={20} />
            </center>
          </Grid>
          <Grid item sm={4}>
            <center>
              <Skeleton animation="wave" width={"80%"} height={20} />
              <Skeleton animation="wave" width={"100%"} height={25} />
              <Skeleton animation="wave" width={"65%"} height={20} />
            </center>
          </Grid>
        </Grid>
        <Box sx={{mt:'-40px',mb:'-40px'}}>
          <Skeleton animation="wave" width={"100%"} height={380} />
        </Box>
      </Card>
    </Box>
  );
}
export default StatsSaleSkelton;
