import moment from "moment";
import momentTz from "moment-timezone";
import { parseISO, format } from "date-fns-tz";

export const dateFormat = (date) => {
  const formatedDate = moment(date).format("YYYY-MM-DDTHH:mm");
  return formatedDate;
};

export const minusTimezoneOffset = (date, timezone) => {
  let formated = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone: timezone })
  return momentTz(formated).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

 
};
