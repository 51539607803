import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Avatar,
  Stack,
  Box,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import ResponseAlert from "../../../components/responseAlert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getData } from "../../../utils/getData";
import { updateData } from "../../../utils/updateData";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { handleUpdateStatus } from "../../../redux/listings/listingDetail";
import { useDispatch } from "react-redux";

function AttachMLSNumber(props) {
  let { open, setOpen, classes, id, setMlsData, ListingData, isUpdate } = props;
  const [executedDate, setExecutedDate] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [mlsNumber, setMlsNumber] = useState("");
  const [notFoundMls, setNotFoundMls] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [allMlsRecords, setAllMlsRecords] = useState([]);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const getStreetMls = async () => {
    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/transactions/get-all-mls-records/${
        ListingData.id
      }?street=${
        ListingData?.street?.split(" ")[0] +
        " " +
        ((+ListingData?.street?.split(" ")[0] &&
          ListingData?.street?.split(" ")[1]) ||
          "")
      }&type=listing`
    );
    if (result.status === 200) {
      setAllMlsRecords(result.data);
    }
  };
  useEffect(() => {
    getStreetMls();
    if (
      ListingData.status === "coming soon" ||
      ListingData.status === "active"
    ) {
      setStatus(ListingData.status);
    }
  }, []);
  useEffect(() => {
    if (isUpdate && mlsNumber === "") {
      setMlsNumber(isUpdate);
    }
  }, [isUpdate]);

  const handleValidate = () => {
    if (!(mlsNumber || currentId) && !status) {
      setErrMsg(true);
      setErrorAlert({
        errorMsg: "Please enter MLS number or  select the status",
        errorType: "warning",
        isOpen: true,
      });
      return false;
    } else if (!executedDate && status === "coming soon") {
      setErrorAlert({
        errorMsg: "Please select the date ",
        errorType: "warning",
        isOpen: true,
      });
      return false;
    } else {
      return true;
    }
  };

  const handleScheduleActiveList = async () => {
    let obj = {
      executed_on: moment.utc(moment(executedDate).utc()).format(),
    };
    const result = await updateData(
      `${process.env.REACT_APP_BASE_URL}api/transactions/listings/schedule-list-to-active/${id}`,
      obj
    );
    if (result.status === 200) {
      setErrorAlert({
        errorMsg: "You have successfully scheduled the listing active date",
        errorType: "success",
        isOpen: true,
      });
      dispatch(handleUpdateStatus("coming soon"));
      setLoading(false);
      setTimeout(() => {
        setOpen(false);
      }, 1200);
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };
  const handleActiveList = async () => {
    let obj = {
      status: "active",
    };
    const result = await updateData(
      `${process.env.REACT_APP_BASE_URL}api/transactions/listings/${id}`,
      obj
    );
    if (result.status === 200) {
      setErrorAlert({
        errorMsg: "You have successfully activated the list",
        errorType: "success",
        isOpen: true,
      });
      dispatch(handleUpdateStatus("active"));
      setLoading(false);
      setTimeout(() => {
        setOpen(false);
      }, 1200);
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };
  const handleAttachMls = async (status) => {
    const result = await getData(
      `${
        process.env.REACT_APP_BASE_URL
      }api/transactions/get-mls-record/${id}?mls_id=${
        mlsNumber || currentId
      }&type=listing`
    );
    if (result.status === 200) {
      let submitUrl = `${process.env.REACT_APP_BASE_URL}api/transactions/listings/attach-mls`;
      await updateData(
        submitUrl + "/" + id + "/" + (currentId ? currentId : mlsNumber)
      );
      setErrorAlert({
        errorMsg: "You have successfully attach the MLS number",
        errorType: "success",
        isOpen: true,
      });
      setMlsData(result.data);
      if (status) {
        if (!isUpdate) {
          if (status === "coming soon") {
            handleScheduleActiveList();
          } else {
            handleActiveList();
          }
        }
      } else {
        setLoading(false);
        setTimeout(() => {
          setOpen(false);
        }, 1200);
      }
    } else if (result.status === 404) {
      setLoading(false);
      setNotFoundMls(true);
    }
  };

  const handleSubmit = async (type) => {
    if (handleValidate()) {
      setLoading(type);
      if (status && (mlsNumber || currentId)) {
        handleAttachMls(true);
      } else if (status && !(mlsNumber || currentId)) {
        if (status === "coming soon") {
          handleScheduleActiveList();
        } else {
          handleActiveList();
        }
      } else if (!status && (mlsNumber || currentId)) {
        handleAttachMls();
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction={"row"} spacing={6}>
            <Typography className={classes.CloseContractHeading}>
              {" "}
              {isUpdate ? "Update " : "Attach "} MLS Number
            </Typography>
            <Box hidden={isUpdate}>
              <ButtonGroups
                id="listings-button-menu-g"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="35px"
                size="medium"
                color="white"
                value={status}
                options={[
                  { title: "Coming soon", value: "coming soon" },
                  { title: "Active", value: "Active" },
                ]}
                onChangeMenu={(value) => setStatus(value)}
                placeholder="Status"
                {...props}
              />
            </Box>
            {!isUpdate && status === "coming soon" ? (
              <Stack
                hidden={
                  isUpdate === "Update" || status === "Active" ? true : false
                }
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                alignItems={"center"}
              >
                <Typography>Active Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    value={executedDate}
                    disablePast
                    onChange={(newValue) => setExecutedDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        sx={{ mb: "20px", width: "165px" }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            ) : null}
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ width: "800px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Manually Enter MLS Number
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                onChange={(e) => {
                  setMlsNumber(e.target.value);
                  setCurrentId();
                  setErrMsg(false);
                }}
                value={mlsNumber}
                size="small"
                variant="outlined"
                error={errMsg}
                helperText={errMsg ? "Please enter mls number" : ""}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <LoadingButton
                sx={{ mt: "21px" }}
                className={classes.saveModalButton}
                color="inherit"
                autoFocus
                loadingPosition="start"
                onClick={() => handleSubmit("top")}
                loading={loading === "top"}
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ mt: "24px" }}>
                {notFoundMls
                  ? "Error: No Listing matching MLS xxxxxxxx found."
                  : "Or, choose from the below:"}
              </Typography>
            </Grid>
          </Grid>
          <br />
          {allMlsRecords && allMlsRecords?.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">MLS No</TableCell>
                    <TableCell align="left">Address</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">List Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allMlsRecords?.map((item, index) => (
                    <TableRow
                      key={index}
                      selected={item.mlsId === currentId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Button
                          variant="outlined"
                          onClick={() => setCurrentId(item.mlsId)}
                          color="inherit"
                          size="small"
                        >
                          Select
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <Avatar
                          variant="square"
                          sx={{ width: "25px", height: "25px" }}
                          src={item.property_img}
                        />{" "}
                      </TableCell>
                      <TableCell align="left">{item.mlsId}</TableCell>
                      <TableCell align="left">
                        {" "}
                        <p className="font-18">{item.address.split(",")[0]}</p>
                        <p className="font-18">{item.address.split(",")[1]}</p>
                      </TableCell>
                      <TableCell align="left">
                        ${new Intl.NumberFormat().format(item.price)}
                      </TableCell>
                      <TableCell align="left">
                        {moment(item.date).format("l")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => {
              setOpen(false);
              setLoading(false);
            }}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSubmit("bottom")}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={loading === "bottom"}
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AttachMLSNumberStyle" })(
  AttachMLSNumber
);
