import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCommissionPlansDetails = createAsyncThunk("commissionPlans/getCommissionPlansDetails", async (id, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/finance/commission-plans/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const getCommissionPlans = createAsyncThunk("commissionPlans/getCommissionPlans", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/finance/commission-plans`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: data,
    });
    return {...resp.data?.data,search:data.search};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const addCommissionPlans = createAsyncThunk("commissionPlans/addCommissionPlans", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/finance/commission-plans`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const updateCommissionPlans = createAsyncThunk("commissionPlans/updateCommissionPlans", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/finance/commission-plans/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const initialState = {
  success: false,
  data: [],
  isLoading: false,
  actionLoading: false,
  actionError: null,
  errMsg: null,
  totalCount: 0,
  commissionPlanDetails: {
    isLoading: false,
    details: {},
  },
  search:false
};
const commissionPlans = createSlice({
  name: "commissionPlans",
  initialState,
  extraReducers: {
    [getCommissionPlans.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getCommissionPlans.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.search=action.payload.search
      if (action.meta.arg?.reset) {
        state.data = action.payload.allPlans;
      } else {
        state.data = [...state.data.concat(action.payload.allPlans)];
      }
      state.totalCount = action.payload.count;
    },
    [getCommissionPlans.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
    [addCommissionPlans.pending]: (state) => {
      state.actionLoading = true;
      state.actionError = null;
    },
    [addCommissionPlans.fulfilled]: (state, action) => {
      state.actionLoading = false;
      state.actionError = null;
    },
    [addCommissionPlans.rejected]: (state, action) => {
      console.log(action.payload);
      state.actionLoading = false;
      // state.actionError = null;
      // state.errMsg = action.payload;
    },

    [updateCommissionPlans.pending]: (state) => {
      state.actionLoading = true;
      state.actionError = null;
    },
    [updateCommissionPlans.fulfilled]: (state, action) => {
      state.actionLoading = false;
      state.actionError = null;
    },
    [updateCommissionPlans.rejected]: (state, action) => {
      state.actionLoading = false;
      // state.actionError = null;
      // state.errMsg = action.payload;
    },

    [getCommissionPlansDetails.pending]: (state) => {
      state.commissionPlanDetails.isLoading = true;
    },
    [getCommissionPlansDetails.fulfilled]: (state, action) => {
      state.commissionPlanDetails.isLoading = false;
      state.commissionPlanDetails.details = action.payload;
    },
    [getCommissionPlansDetails.rejected]: (state, action) => {
      state.commissionPlanDetails.isLoading = false;
      // state.actionError = null;
      // state.errMsg = action.payload;
    },
  },
});
export default commissionPlans.reducer;
