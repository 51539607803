import React from "react";
import { Box, Card, Skeleton, Grid } from "@mui/material";
import UserHeaderCardSkelton from "../../../users/skeltons/cardSkelton";
function AgentMainSkelton() {
  return (
    <Box>
      <UserHeaderCardSkelton />
      <Grid sx={{ mt: "0px",mb:"30px" }} container spacing={2}>
        <Grid item sm={6}>
          {" "}
          <Card variant="outlined" sx={{ padding: "20px", mt: "15px" }}>
            <Skeleton variant="rounded"  animation="wave"  width="200px" height="40px" />
            <Grid sx={{ mt: "0px" }} container spacing={4}>
              <Grid item sm={12}>
                <Skeleton variant="rounded"  animation="wave"  width="100%" height="300px" />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={6}>
          {" "}
          <Card variant="outlined" sx={{ padding: "20px", mt: "15px", mb: "15px" }}>
            <Skeleton variant="rounded"  animation="wave"  width="200px" height="40px" />
            <Grid sx={{ mt: "0px" }} container spacing={4}>
              <Grid item sm={12} md={4}>
                <Skeleton variant="rounded"  animation="wave"  width="100%" height="140px" />
              </Grid>
              <Grid item sm={12} md={4}>
                <Skeleton variant="rounded"  animation="wave"  width="100%" height="140px" />
              </Grid>
              <Grid item sm={12} md={4}>
                <Skeleton variant="rounded"  animation="wave"  width="100%" height="140px" />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
export default AgentMainSkelton;
