import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MessageIcon from "@mui/icons-material/Message";
import PushPinIcon from "@mui/icons-material/PushPin";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Input from "../../../../../components/uiElements/input/Input";
import Button from "../../../../../components/uiElements/button";
import { useDispatch, useSelector } from "react-redux";
import { updateLeadTimelineAPI } from "../../../../../redux/leads/updateLeadTimelineAPI";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import StarIcon from "@mui/icons-material/Star";
import { useAudio } from "../../../../../utils/playAudio";
import { durationTimeFormat } from "../../../helper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../Tabs/TabContent/mentions/defaultStyle.js";
import defaultMentionStyle from "../../Tabs/TabContent/mentions/defaultMentionStyle";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import Avatar from "../../../../../components/uiElements/avatar";
import parse from "html-react-parser";
import ImgsViewer from "react-images-viewer";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
const moment = require("moment-timezone");

const NameCard = ({
  name,
  date,
  avatar,
  duration,
  nameLabel,
  isHideAvatar,
}) => {
  const stringAvatar = () => {
    if (avatar) {
      return {
        src: `${avatar}`,
      };
    } else if (nameLabel) {
      return {
        children: `${nameLabel?.split(" ")[0]?.[0]}${
          nameLabel?.split(" ")[1]?.[0] || ""
        }`,
      };
    } else {
      return {
        src: "",
      };
    }
  };

  return (
    <List disablePadding>
      <ListItem disablePadding>
        {isHideAvatar ? null : (
          <ListItemAvatar>
            <Avatar sx={{ fontSize: "16px" }} {...stringAvatar()} />
          </ListItemAvatar>
        )}

        <ListItemText
          primary={
            <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <Box
                sx={{
                  fontWeight: "600",
                  fontFamily: "AvenirNext",
                  fontSize: "14px",
                }}
              >
                <Box>{name}</Box>
              </Box>
              {duration !== undefined ? (
                <Typography sx={{ fontSize: "14px", color: "#707070" }}>
                  ({durationTimeFormat(duration)})
                </Typography>
              ) : null}
            </Box>
          }
          secondary={
            <Box sx={{ fontFamily: "AvenirNext-400", fontSize: "12px" }}>
              {date}
            </Box>
          }
        />
      </ListItem>
    </List>
  );
};

export const MessageTimeline = ({
  classes,
  color,
  short,
  usersList,
  item,
  handleUpdateFavorite,
  disabledConnecter,
  leadProfileDetail,
}) => {
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [mediaUrls, setMediaUrls] = React.useState([]);
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  const itemType = item.type;
  const fil = usersList.filter((el) => el.id === item?.[itemType].createdById);

  const getUser = (id) => {
    return usersList.find((el) => el.id === id);
  };

  return (
    <TimelineItem key={item.id} classes={{ root: classes.root }}>
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        {disabledConnecter === false ? <TimelineConnector /> : null}
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-5px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #C2C2C2",
          }}
        >
          <NameCard
            avatar={
              item?.is_incoming
                ? leadProfileDetail?.profile_img
                : fil[0]?.profile_images?.profile_img
            }
            name={
              <Box className={classes.nameWithIcon}>
                <CustomTooltip
                  title={item[itemType]?.fromNumber}
                  placement="top"
                >
                  <Box sx={{ cursor: "help" }}>
                    {item?.is_incoming
                      ? leadProfileDetail?.firstName +
                        " " +
                        leadProfileDetail?.lastName
                      : fil?.[0]?.full_name}{" "}
                  </Box>
                </CustomTooltip>
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: "14px", color: "#B3B3B3" }}
                />{" "}
                <CustomTooltip title={item[itemType]?.toNumber} placement="top">
                  <Box sx={{ cursor: "help" }}>
                    {item?.is_incoming
                      ? fil?.[0]?.full_name ||
                        getUser(item?.[itemType]?.userId)?.full_name
                      : item?.[itemType]?.firstName
                      ? item?.[itemType]?.firstName +
                        " " +
                        item?.[itemType]?.lastName
                      : getUser(item?.[itemType]?.userId)?.full_name}{" "}
                  </Box>
                </CustomTooltip>
              </Box>
            }
            nameLabel={
              item?.is_incoming
                ? leadProfileDetail?.firstName +
                  " " +
                  (leadProfileDetail?.lastName || "")
                : fil?.[0]?.full_name
            }
            date={moment(item?.[itemType].created)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
          <IconButton
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
            size="small"
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>
        <Box sx={{ paddingTop: "10px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              overflowWrap: "anywhere",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {item?.[itemType]?.message}
          </Typography>
          {item?.text_message?.attachments?.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
                flexWrap: "wrap",
                mt: "15px",
              }}
            >
              {item?.text_message?.attachments?.map((item, index) => (
                <img
                  src={item.url}
                  width="80px"
                  height="80px"
                  style={{
                    objectFit: "cover",
                    border: "1px solid lightgrey",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMediaUrls([item.url]);
                    setImageViewerOpen(true);
                  }}
                  alt={item.url}
                />
              ))}
            </Box>
          ) : null}
        </Box>
      </TimelineContent>
      {mediaUrls?.length > 0 && (
        <ImgsViewer
          imgs={mediaUrls?.map((item) => ({
            src: item,
          }))}
          isOpen={imageViewerOpen}
          currImg={0}
          showThumbnails={true}
          enableKeyboardInput={true}
          onClose={() => setImageViewerOpen(false)}
        />
      )}
    </TimelineItem>
  );
};

export const EmailTimeline = ({
  classes,
  color,
  short,
  item,
  usersList,
  handleUpdateFavorite,
  isEmailThread,
  disabledConnecter,
  leadProfileDetail,
}) => {
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openedThread, setOpenedThread] = useState([]);

  const fil = isEmailThread
    ? usersList.filter(
        (el) => el.id === item?.emails_thread?.messages[0]?.createdById
      )
    : usersList.filter((el) => el.id === item?.emails_datum?.createdById);
  const getUser = (id) => {
    return usersList.find((el) => el.id === id);
  };
  const convertHtmlToPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <TimelineItem
      key={item.id}
      classes={{ root: classes.root }}
      sx={{
        "& .MuiTimelineConnector-root": {
          backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
          width: isFavorite ? "3px" : "2px",
        },
      }}
    >
      <TimelineSeparator>
        {/* <TimelineConnector sx={{ bgcolor: short ? "white" : "" }} /> */}
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EmailIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        {disabledConnecter === false ? <TimelineConnector /> : null}
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-5px", overflow: "auto" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            name={
              <Box className={classes.nameWithIcon}>
                {item?.is_incoming
                  ? leadProfileDetail?.firstName +
                    " " +
                    leadProfileDetail?.lastName
                  : item?.emails_datum?.createdById ||
                    item?.emails_thread?.messages[0]?.createdById
                  ? fil?.[0]?.full_name
                  : item?.emails_datum?.from?.split("<")?.[0]
                  ? item?.emails_datum?.from?.split("<")?.[0]
                  : fil?.[0]?.full_name}{" "}
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: "14px", color: "#B3B3B3" }}
                />{" "}
                {item?.is_incoming
                  ? fil?.[0]?.full_name
                  : item?.emails_datum?.createdById ||
                    item?.emails_thread?.messages[0]?.createdById
                  ? `${leadProfileDetail?.firstName} ${leadProfileDetail?.lastName}`
                  : item?.emails_datum?.to?.split("<")?.[0]
                  ? item?.emails_datum?.to?.split("<")?.[0]
                  : `${leadProfileDetail?.firstName} ${leadProfileDetail?.lastName}`}
              </Box>
            }
            // name={
            //   fil?.[0]?.full_name
            //     ? fil?.[0]?.full_name
            //     : leadProfileDetail?.firstName
            //     ? `${leadProfileDetail?.firstName} ${leadProfileDetail?.lastName}`
            //     : "User Not found!"
            // }
            nameLabel={
              item?.is_incoming
                ? leadProfileDetail?.firstName +
                  " " +
                  leadProfileDetail?.lastName
                : item?.emails_datum?.createdById ||
                  item?.emails_thread?.messages[0]?.createdById
                ? fil?.[0]?.full_name
                : item?.emails_datum?.from?.split("<")?.[0]
                ? item?.emails_datum?.from?.split("<")?.[0]
                : fil?.[0]?.full_name
            }
            avatar={
              fil[0]?.profile_images?.profile_img ||
              leadProfileDetail?.profile_img
            }
            date={moment(item?.createdAt || item.emails_datum?.created)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
          <IconButton
            size="small"
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>{" "}
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "AvenirNext",
            fontSize: "14px",
            mt: "4px",
            borderBottom: "1px solid #C2C2C2",
            paddingBottom: "6.5px",
          }}
        >
          {item?.emails_thread?.messages[0]?.subject ||
            item?.emails_datum?.subject}
        </Typography>
        <Box sx={{ paddingTop: "10px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              overflowWrap: "anywhere",
              color: "rgba(0, 0, 0, 0.6)",
              "& img": {
                maxWidth: "100% !important",
              },
            }}
          >
            <pre
              style={{
                whiteSpace: "initial",
                fontFamily: "AvenirNext !important",
                color: "rgba(0, 0, 0, 0.6) !important",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: isEmailThread
                    ? item?.emails_thread?.messages[0]?.body_data?.body_text ||
                      item?.emails_thread?.messages[0]?.body_data?.body_html
                    : item?.emails_datum?.body_data?.body_text ||
                      item?.emails_datum?.body_data?.body_html,
                }}
              />
            </pre>
          </Typography>
        </Box>
        {isEmailThread && item?.emails_thread?.messages?.length > 1 ? (
          <Box sx={{ mt: "20px" }}>
            <IconButton
              size="small"
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                setOpenedThread([]);
              }}
            >
              <MoreHorizIcon fontSize="large" sx={{ color: "#9a9ea1" }} />
            </IconButton>
            {!isCollapsed ? (
              <Card
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                  setOpenedThread([]);
                }}
                className={classes.threadClosedCard}
                variant="outlined"
              >
                {item?.emails_thread?.messages?.length - 1} more emails in
                thread
              </Card>
            ) : null}
          </Box>
        ) : null}
        {isEmailThread &&
        isCollapsed &&
        item?.emails_thread?.messages?.length ? (
          <Box className={classes.threadMessagesArea}>
            {item?.emails_thread?.messages?.slice(1)?.map((message, index) =>
              !openedThread?.includes(`${index}`) &&
              item?.emails_thread?.messages?.length > 2 ? (
                <Card
                  onClick={() =>
                    setOpenedThread((threads) => [...threads, `${index}`])
                  }
                  className={classes.threadClosedSubCard}
                  variant="outlined"
                  key={message.id}
                  sx={{ mt: index === 0 ? "-15px !important" : "" }}
                >
                  <Typography className={classes.threadClosedSubCardName}>
                    {message?.is_incoming
                      ? `${leadProfileDetail?.firstName} ${leadProfileDetail?.lastName}`
                      : message.createdById
                      ? getUser(message.createdById)?.full_name
                      : "User Not found!"}
                  </Typography>
                  <Typography className={classes.threadClosedSubCardDes}>
                    {convertHtmlToPlainText(
                      message?.body_data?.body_text ||
                        message?.body_data?.body_html
                    )}
                  </Typography>
                  <Typography className={classes.threadClosedSubCardDate}>
                    {moment(item?.createdAt)
                      .tz("America/New_York")
                      .format("MMM DD")}
                  </Typography>
                </Card>
              ) : (
                <Card
                  variant="outlined"
                  sx={{ mt: index === 0 ? "-15px" : "", position: "relative" }}
                  className={classes.threadMessagesAreaItem}
                  key={message.id}
                >
                  {item?.emails_thread?.messages?.length > 2 ? (
                    <IconButton
                      size="small"
                      onClick={() =>
                        setOpenedThread((threads) =>
                          threads.filter((val) => val !== `${index}`)
                        )
                      }
                      className={classes.subCollapsedArea}
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{ color: "#9a9ea1" }}
                      />
                    </IconButton>
                  ) : null}

                  <NameCard
                    name={
                      message?.is_incoming
                        ? `${leadProfileDetail?.firstName} ${leadProfileDetail?.lastName}`
                        : message.createdById
                        ? getUser(message.createdById)?.full_name
                        : "User Not found!"
                    }
                    avatar={
                      message.createdById
                        ? getUser(message.createdById)?.profile_images
                            ?.profile_img
                        : leadProfileDetail?.profile_img
                    }
                    nameLabel={
                      message?.is_incoming
                        ? `${leadProfileDetail?.firstName} ${leadProfileDetail?.lastName}`
                        : message.createdById
                        ? getUser(message.createdById)?.full_name
                        : "User Not found!"
                    }
                    date={moment(item?.createdAt)
                      .tz("America/New_York")
                      .format("MMMM Do YYYY, h:mm a")}
                  />
                  <CardContent className={classes.threadMessagesAreaItemDetail}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        overflowWrap: "anywhere",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {message?.subject}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        overflowWrap: "anywhere",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "AvenirNext !important",
                          color: "rgba(0, 0, 0, 0.6) !important",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              message?.body_data?.body_text ||
                              message?.body_data?.body_html,
                          }}
                        />
                      </pre>
                    </Typography>
                  </CardContent>
                </Card>
              )
            )}
          </Box>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  );
};

export const NoteTimeline = ({
  classes,
  color,
  short,
  item,
  usersList,
  handleUpdateFavorite,
  disabledConnecter,
}) => {
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  const [inputText, setInputText] = useState("");
  const [isEditable, setIsEditble] = useState(false);
  const itemType = item.type;
  const fil = usersList.filter((el) => el.id === item?.[itemType].createdById);
  const [userIds, setUserIds] = useState([]);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const adminAuth = useSelector((state) => state.adminAuth);
  const dispatch = useDispatch();

  const {
    leadTimeline: { status },
  } = useSelector((item) => item);

  const editModeFunc = () => {
    setInputText(item?.[itemType]?.body);
    setIsEditble(true);
  };
  const cancelFunc = () => {
    setIsEditble(false);
    setInputText("");
  };

  const onSaveFnc = async () => {
    if (inputText && inputText.replaceAll("\n", "")) {
      const schema = {
        path: `notes/${item?.[itemType]?.id}`,
        schema: {
          body: inputText,
          userIds: userIds.filter(
            (item, index) => userIds.indexOf(item) === index
          ),
          type: "note",
        },
        timeline_id: item.id,
        timeline_type: item.type,
      };
      const fetch = await dispatch(updateLeadTimelineAPI({ ...schema }));
      if (fetch.payload) {
        setInputText("");
        setUserIds([]);
        setIsEditble(false);
      }
    }
  };

  const sanitizeHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText;
  };

  const sanitizedValue = sanitizeHTML(inputText);

  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
      key={item.id}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PushPinIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        {disabledConnecter === false ? <TimelineConnector /> : null}
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-5px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #C2C2C2",
          }}
        >
          <NameCard
            avatar={fil[0]?.profile_images?.profile_img}
            name={
              fil?.[0]?.full_name ||
              fil?.[0]?.first_name ||
              fil?.[0]?.last_name ||
              "User not found!"
            }
            nameLabel={
              fil?.[0]?.full_name ||
              fil?.[0]?.first_name ||
              fil?.[0]?.last_name ||
              "User not found!"
            }
            date={moment(item?.[itemType].created)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
          <IconButton
            size="small"
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>
        {isEditable ? (
          <Box sx={{ mt: "20px" }}>
            <MentionsInput
              value={sanitizedValue}
              onChange={(event, newValue, newPlainTextValue, mentions) => {
                setUserIds(mentions?.map((item) => item.id));
                setInputText(event.target.value);
              }}
              style={defaultStyle}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  onSaveFnc();
                  setInputText("");
                }
              }}
            >
              <Mention
                trigger="@"
                data={
                  agentRosterList?.list?.length
                    ? agentRosterList?.list?.map((item) => ({
                        id: item.id,
                        display: item?.first_name + " " + item.last_name,
                      }))
                    : []
                }
                style={defaultMentionStyle}
                markup={"@[__display__^__id__]"}
                // renderSuggestion={this.renderUserSuggestion}
              />
            </MentionsInput>
            {/* <Input multiline rows="4" value={inputText} onChange={(e) => setInputText(e.target.value)} /> */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {status?.pending ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  <Button onClick={cancelFunc}>Cancel</Button> &nbsp;&nbsp;
                  <Button onClick={onSaveFnc} variant="contained">
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              alignItems: "center",
              mt: "-5px",
            }}
          >
            {" "}
            <pre
              style={{
                whiteSpace: "pre-wrap",
                fontFamily: "AvenirNext !important",
                color: "rgba(0, 0, 0, 0.6) !important",
              }}
            >
              <Typography
                onClick={editModeFunc}
                sx={{
                  fontSize: "14px",
                  overflowWrap: "anywhere",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {inputText
                  ? reactStringReplace(
                      parse(inputText),
                      /@\[(.*?)]/g,
                      (match, i) => (
                        <CustomTooltip
                          title={match?.split("^")?.[0]}
                          placement="top"
                        >
                          <Box
                            sx={{
                              color:
                                match?.split("^")?.[1] ==
                                adminAuth?.adminDetail.id
                                  ? "#007EA6 !important"
                                  : "#434343 !important",
                              background:
                                match?.split("^")?.[1] ==
                                adminAuth?.adminDetail.id
                                  ? "#CCF3FF !important"
                                  : "#ECECEC !important",
                              display: "inline-block",
                              textDecoration: "none",
                              fontWeight: "500",
                            }}
                            key={i}
                            to={`/agent_roster/${
                              match?.split("^")?.[1] || 69
                            }/overview`}
                            component={Link}
                          >
                            @{match?.split(" ")?.[0]}
                          </Box>
                        </CustomTooltip>
                      )
                    )
                  : reactStringReplace(
                      parse(item?.[itemType]?.body),
                      /@\[(.*?)]/g,
                      (match, i) => (
                        <CustomTooltip
                          title={match?.split("^")?.[0]}
                          placement="top"
                        >
                          <Box
                            sx={{
                              color:
                                match?.split("^")?.[1] ==
                                adminAuth?.adminDetail.id
                                  ? "#007EA6 !important"
                                  : "#434343 !important",
                              background:
                                match?.split("^")?.[1] ==
                                adminAuth?.adminDetail.id
                                  ? "#CCF3FF !important"
                                  : "#ECECEC !important",
                              display: "inline-block",
                              textDecoration: "none",
                              fontWeight: "500",
                            }}
                            key={i}
                            to={`/agent_roster/${
                              match?.split("^")?.[1] || 69
                            }/overview`}
                            component={Link}
                          >
                            @{match?.split("^")?.[0]}
                          </Box>
                        </CustomTooltip>
                      )
                    )}
              </Typography>
            </pre>
            <IconButton
              color="primary"
              sx={{ opacity: 0 }}
              className="edit-pen-timeline"
              component="span"
              onClick={editModeFunc}
            >
              <BorderColorIcon sx={{ color: "#7e7e7e", fontSize: "16px" }} />
            </IconButton>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export const CallTimeline = ({
  classes,
  color,
  short,
  item,
  usersList,
  handleUpdateFavorite,
  disabledConnecter,
}) => {
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  const [playing, toggle, stop, currentTime, duration, updateCurrentTime] =
    useAudio(item?.call?.recordingUrl);
  const [inputText, setInputText] = useState("");
  const [isEditable, setIsEditble] = useState(false);
  const itemType = item.type;
  const fil = usersList.filter((el) => el.id === item?.[itemType].createdById);
  const filProfile = usersList.filter(
    (el) => el.id === item?.[itemType].userId
  );
  const dispatch = useDispatch();

  const editModeFunc = () => {
    setInputText(item?.[itemType]?.note);
    setIsEditble(true);
  };
  const cancelFunc = () => {
    setIsEditble(false);
    setInputText("");
  };

  const onSaveFnc = async () => {
    const schema = {
      path: `calls/${item?.[itemType]?.id}`,
      schema: {
        note: inputText,
      },
      timeline_id: item.id,
      timeline_type: item.type,
    };
    const fetch = await dispatch(updateLeadTimelineAPI({ ...schema }));
    if (fetch.payload) {
      setIsEditble(false);
    }
  };

  useEffect(() => {
    return () => {
      if (playing) {
        stop();
      }
    };
  }, [playing]);

  const handleDownload = (audioUrl, fileName) => {
    fetch(audioUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // Set the desired file name
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the audio:", error);
      });
  };

  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
      key={item.id}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        {/* <TimelineConnector sx={{ bgcolor: short ? "white" : "" }} /> */}
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {item?.is_incoming ? (
            <PhoneCallbackIcon sx={{ fontSize: "21px" }} />
          ) : (
            <CallIcon sx={{ fontSize: "21px" }} />
          )}
        </TimelineDot>
        {disabledConnecter === false ? <TimelineConnector /> : null}
      </TimelineSeparator>

      <TimelineContent sx={{ py: "12px", px: 4, mt: "-5px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            borderBottom: "1px solid #C2C2C2",
          }}
        >
          <NameCard
            isTooltip={{ title: item[itemType]?.toNumber, placement: "right" }}
            avatar={
              item?.is_incoming
                ? ""
                : fil[0]?.profile_images?.profile_img ||
                  filProfile[0]?.profile_images?.profile_img
            }
            duration={
              item?.[itemType]?.duration ? item?.[itemType].duration : 0
            }
            nameLabel={
              item?.is_incoming
                ? `${item?.[itemType]?.firstName} ${item?.[itemType]?.lastName}`
                : fil?.[0]?.full_name ||
                  fil?.[0]?.first_name ||
                  filProfile?.[0]?.full_name ||
                  item?.[itemType]?.userName ||
                  "Unknown Name"
            }
            name={
              <Box className={classes.nameWithIcon}>
                <CustomTooltip
                  title={item[itemType]?.fromNumber}
                  placement="top"
                >
                  <Box sx={{ cursor: "help" }}>
                    {item?.is_incoming
                      ? `${item?.[itemType]?.firstName} ${item?.[itemType]?.lastName}`
                      : fil?.[0]?.full_name ||
                        fil?.[0]?.first_name ||
                        filProfile?.[0]?.full_name ||
                        item?.[itemType]?.userName ||
                        "Unknown Name"}
                  </Box>
                </CustomTooltip>
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: "14px", color: "#B3B3B3" }}
                />{" "}
                <CustomTooltip title={item[itemType]?.toNumber} placement="top">
                  {item?.is_incoming ? (
                    fil?.[0]?.full_name ||
                    fil?.[0]?.first_name ||
                    item?.[itemType]?.userName ||
                    "Unknown Name"
                  ) : (
                    <Box sx={{ cursor: "help" }}>
                      {item?.[itemType]?.firstName +
                        " " +
                        item?.[itemType]?.lastName}
                    </Box>
                  )}
                </CustomTooltip>
              </Box>
            }
            date={moment(item?.[itemType].created)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
          <Box
            sx={{
              paddingBottom: "5px",
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              gap: "0px 5px",
            }}
          >
            {item.call.recordingUrl ? (
              <IconButton
                onClick={() =>
                  handleDownload(
                    item.call.recordingUrl,
                    `${
                      item?.is_incoming
                        ? `${item?.[itemType]?.firstName} ${item?.[itemType]?.lastName}`
                        : fil?.[0]?.full_name ||
                          fil?.[0]?.first_name ||
                          filProfile?.[0]?.full_name ||
                          item?.[itemType]?.userName ||
                          "Unknown Name"
                    } call recording`
                  )
                }
                size="small"
              >
                <FileDownloadRoundedIcon sx />{" "}
              </IconButton>
            ) : null}

            {item.call.recordingUrl ? (
              <Box>
                {playing ? (
                  <Box className={classes.callPlayArea}>
                    <PauseCircleFilledIcon onClick={toggle} />{" "}
                    <input
                      type="range"
                      value={currentTime}
                      max={duration}
                      onChange={(e) => updateCurrentTime(e.target.value)}
                    />
                  </Box>
                ) : (
                  <PlayCircleFilledIcon onClick={toggle} />
                )}
              </Box>
            ) : null}
            <IconButton
              size="small"
              onClick={() => {
                handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
                if (isFavorite) {
                  setIsFavorite(false);
                } else {
                  setIsFavorite(true);
                }
              }}
              sx={{ mt: "-8px" }}
            >
              {isFavorite ? (
                <StarIcon sx={{ color: "#F3B30E" }} />
              ) : (
                <StarBorderIcon sx={{ color: "#7e7e7e" }} />
              )}
            </IconButton>
          </Box>
        </Box>
        {isEditable ? (
          <Box>
            <Input
              multiline
              rows="4"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
            <Box
              sx={{ marginTop: "10px", display: "flex", justifyContent: "end" }}
            >
              <Button onClick={cancelFunc}>Cancel</Button> &nbsp;&nbsp;
              <Button onClick={onSaveFnc} variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              mt: "5px",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                overflowWrap: "anywhere",
                color: "rgba(0, 0, 0, 0.6)",
              }}
              onClick={editModeFunc}
            >
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  fontFamily: "AvenirNext !important",
                  color: "rgba(0, 0, 0, 0.6) !important",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: inputText || item?.[itemType]?.note,
                  }}
                />
              </pre>
            </Typography>
            <IconButton
              color="primary"
              sx={{ opacity: 0 }}
              className="edit-pen-timeline"
              component="span"
              onClick={editModeFunc}
            >
              <BorderColorIcon sx={{ color: "#7e7e7e", fontSize: "16px" }} />
            </IconButton>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export const AssignmentTimeline = ({
  classes,
  color,
  item,
  disabledConnecter,
  isLead,
}) => {
  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
      key={item.id}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: "#D2D2D2",
            width: "2px",
          },
        }}
      >
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FlipCameraAndroidIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        {disabledConnecter === false ? <TimelineConnector /> : null}
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-1px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            avatar={""}
            isHideAvatar={true}
            name={
              <Box className={classes.nameWithIcon}>
                <Box sx={{ fontWeight: "500" }}>Assigned to</Box>
                {item?.lead_assignment?.assignedToUser?.full_name ||
                  item?.lead_assignment?.assignedToPond?.name}
                <Box sx={{ fontWeight: "500" }}>By</Box>
                {item?.lead_assignment?.assignedByUser?.full_name}
              </Box>
            }
            date={moment(item?.createdAt)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export const ActivityTimeline = ({
  classes,
  color,
  item,
  handleUpdateFavorite,
  disabledConnecter,
}) => {
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
      key={item.id}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QuestionAnswerIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        {disabledConnecter === false ? <TimelineConnector /> : null}
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-1px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            avatar={""}
            isHideAvatar={true}
            name={
              <Box className={classes.nameWithIcon}>
                {item?.lead_event?.type}
              </Box>
            }
            date={moment(item?.createdAt)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
          <IconButton
            size="small"
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>
        {item?.lead_event?.property ? (
          <Box className={classes.propertySection}>
            <Box>
              <Typography
                component={"a"}
                target="_blank"
                href={item?.lead_event?.property?.url}
                sx={{
                  fontFamily: "AvenirNext",
                  fontSize: "16px",
                  textDecoration: "none",
                  fontWeight: "600",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {item?.lead_event?.property?.street}
              </Typography>
              <Typography sx={{ fontFamily: "AvenirNext", fontSize: "14px" }}>
                MLS#{item?.lead_event?.property?.mlsNumber}
              </Typography>
            </Box>
            <Box className={classes.propertySectionImg}>
              <img
                src={item?.lead_event?.property?.primaryImg}
                alt={item?.lead_event?.property?.street}
              />
            </Box>
          </Box>
        ) : null}
        <Typography sx={{ fontFamily: "AvenirNext", fontSize: "13px" }}>
          via: {item?.lead_event?.source}
        </Typography>
        {item?.lead_event?.message ? (
          <Typography sx={{ fontFamily: "AvenirNext", fontSize: "13px" }}>
            {item?.lead_event?.message}
          </Typography>
        ) : null}
        {item?.lead_event?.description ? (
          <Typography
            sx={{ fontFamily: "AvenirNext", fontSize: "13px", mt: "15px" }}
          >
            {item?.lead_event?.description}
          </Typography>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  );
};
