import { createSlice } from "@reduxjs/toolkit";
import { handleGetEvents } from "./getEvents";
import { handleAddEvent } from "./addEvent";
import { handleUpdateEvent } from "./updateEvent";
import { handleDeleteEvent } from "./deleteEvent";

const initialState = {
  eventList: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  addEvent: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  updateEvent: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  deleteEvent: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
};

const Events = createSlice({
  name: "Events",
  initialState,
  extraReducers: {
    [handleGetEvents.pending]: (state) => {
      state.eventList.isLoading = true;
      state.eventList.errMsg = null;
      state.eventList.success = false;
    },
    [handleGetEvents.fulfilled]: (state, action) => {
      state.eventList.success = true;
      state.eventList.isLoading = false;
      state.eventList.data = action.payload;
    },
    [handleGetEvents.rejected]: (state, action) => {
      state.eventList.isLoading = false;
      state.eventList.success = false;
      state.eventList.errMsg = action.payload;
    },
    [handleAddEvent.pending]: (state) => {
      state.addEvent.isLoading = true;
      state.addEvent.errMsg = null;
      state.addEvent.success = false;
    },
    [handleAddEvent.fulfilled]: (state, action) => {
      state.addEvent.success = true;
      state.addEvent.isLoading = false;
      state.addEvent.data = action.payload;
      let eventsData = [...state.eventList?.data?.data];
      eventsData.unshift({...action.payload,who_to_alert:action.payload?.who_to_alert?JSON.stringify(action.payload?.who_to_aler):null});
      state.eventList.data.data = eventsData;
    },
    [handleAddEvent.rejected]: (state, action) => {
      state.addEvent.isLoading = false;
      state.addEvent.success = false;
      state.addEvent.errMsg = action.payload;
    },
    [handleUpdateEvent.pending]: (state) => {
      state.updateEvent.isLoading = true;
      state.updateEvent.errMsg = null;
      state.updateEvent.success = false;
    },
    [handleUpdateEvent.fulfilled]: (state, action) => {
      state.updateEvent.success = true;
      state.updateEvent.isLoading = false;
      state.updateEvent.data = action.payload;
      let eventsData = [...state.eventList?.data?.data];
      let index = eventsData.findIndex((item) => item.id === action.payload.id);
      eventsData[index] = { ...eventsData[index], ...action.payload };
      state.eventList.data.data = eventsData;
    },
    [handleUpdateEvent.rejected]: (state, action) => {
      state.updateEvent.isLoading = false;
      state.updateEvent.success = false;
      state.updateEvent.errMsg = action.payload;
    },
    // delete event
    [handleDeleteEvent.pending]: (state) => {
      state.deleteEvent.isLoading = true;
      state.deleteEvent.errMsg = null;
      state.deleteEvent.success = false;
    },
    [handleDeleteEvent.fulfilled]: (state, action) => {
      state.deleteEvent.success = true;
      state.deleteEvent.isLoading = false;
      state.deleteEvent.data = action.payload;
      let eventsData = [...state.eventList?.data?.data];
      let count=state.eventList?.data?.count || 0
      let index = eventsData.findIndex((item) => item.id === action.payload.id);
      eventsData.splice(index, 1);
      state.eventList.data.data = eventsData;
      state.eventList.data.count = Number(count)-1;
    },
    [handleDeleteEvent.rejected]: (state, action) => {
      state.deleteEvent.isLoading = false;
      state.deleteEvent.success = false;
      state.deleteEvent.errMsg = action.payload;
    },
  },
});
export default Events.reducer;
export * from "./getEvents";
export * from "./addEvent";
export * from "./updateEvent";
export * from "./deleteEvent";
