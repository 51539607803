import React, { useEffect, useState, useCallback } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Skeleton,
  Stack,
  Divider,
  ListItem,
  ListItemAvatar,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getCallReportingStats } from "../../../../redux/reporting/calls";
import CallStatsSkelton from "../../skelton/callStats.js";
import UserSelectionsForStats from "../../../../components/uiElements/userSelectionDropdown";
import DatePickersForStats from "../../../../components/uiElements/datePicker";
import CallsRecordsTable from "./callsTable.js";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AgentActivityChart from "../agentActivity/activityChart.js";
import * as XLSX from "xlsx/xlsx.mjs";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import ButtonDropdown from "../../../../components/uiElements/buttonDropdownGeneral";
import { granularityOptions } from "../agentActivity/index.js";
import { getAgentReportingChartsData } from "../../../../redux/reporting/agentActivities";
const moment = require("moment-timezone");
function CallsReporting(props) {
  let { classes } = props;
  const [selectedStats, setSelectedStats] = useState(null);
  const [filters, setFilters] = useState({
    fromDate: moment().tz("America/New_York").startOf("month").format(),
    lastDate: moment().tz("America/New_York").endOf("month").format(),
    granularity: { title: "Daily", value: "daily" },
  });
  const [previousPeriod, setPreviousPeriod] = useState({
    isChecked: false,
    fromDate: moment()
      .tz("America/New_York")
      .subtract(1, "month")
      .startOf("month")
      .format(),
    lastDate: moment()
      .tz("America/New_York")
      .subtract(1, "month")
      .endOf("month")
      .format(),
  });
  const [chartType1, setChartType1] = useState({
    title: "Calls",
    value: "calls",
  });
  const [chartType2, setChartType2] = useState({
    title: "nothing",
    value: "nothing",
  });
  const dispatch = useDispatch();
  const callStats = useSelector((state) => state.reporting.calls.callStats);
  const chartData = useSelector((state) => state.reporting.agents.chartData);

  useEffect(() => {
    dispatch(getCallReportingStats(filters));
  }, [filters?.fromDate, filters?.lastDate]);

  useEffect(() => {
    if (selectedStats) {
      let findStats = callStats?.data.find(
        (item) => item.id === selectedStats.id
      );
      setSelectedStats(findStats);
    }
  }, [callStats?.data]);

  const handleUpdateLastPeriod = (data) => {
    let days = getDaysDifference(data);
    if (days === 30) {
      setPreviousPeriod({
        isChecked: false,
        fromDate: moment(data.fromDate)
          .tz("America/New_York")
          .subtract(1, "months")
          .format(),
        lastDate: moment(data.lastDate)
          .tz("America/New_York")
          .subtract(1, "months")
          .format(),
      });
    } else {
      setPreviousPeriod({
        isChecked: false,
        fromDate: moment(data.fromDate)
          .tz("America/New_York")
          .subtract(days + 1, "days")
          .format(),
        lastDate: moment(data.lastDate)
          .tz("America/New_York")
          .subtract(days + 1, "days")
          .format(),
      });
    }
  };
  const handleGetDates = (data) => {
    let days = getDaysDifference(data);
    let granularity = filters.granularity;
    if (days <= 1) {
      granularity = { title: "Hourly", value: "hourly" };
    } else if (days > 1 && days <= 30) {
      granularity = { title: "Daily", value: "daily" };
    } else if (days > 30 && days < 364) {
      granularity = { title: "Weekly", value: "weekly" };
    } else if (days >= 364) {
      granularity = { title: "Monthly", value: "monthly" };
    }
    setFilters((filter) => {
      return {
        ...filter,
        fromDate: data.fromDate,
        lastDate: data.lastDate,
        granularity: granularity,
      };
    });
    handleUpdateLastPeriod(data);
  };
  const handleGetUserDetail = (user) => {
    if (user === "everyOne") {
      setSelectedStats(null);
      setFilters({
        ...filters,
        userId: "",
      });
    } else {
      let findStats = callStats?.data.find((item) => item.id === user.id);
      setSelectedStats(findStats);
      setFilters({
        ...filters,
        userId: user.id,
      });
    }
  };
  const handleGetStatsSum = useCallback(
    (type) => {
      if (callStats?.data?.length) {
        let sum = callStats?.data.reduce(
          (ac, cv) => Number(ac) + Number(cv[type]),
          0
        );
        if (type === "talkTime") {
          return (Number(sum) / 60).toFixed();
        } else {
          return sum;
        }
      } else {
        return "0";
      }
    },
    [selectedStats, callStats?.data]
  );
  const handleSelectGranularity = (value) => {
    setFilters({
      ...filters,
      granularity: value,
    });
  };
  const handleExport = () => {
    let usersData = selectedStats
      ? callStats?.data.filter((item) => item.id === selectedStats.id)
      : [...callStats?.data];
    let users = [
      [
        "Name",
        "Calls Incoming",
        "Calls Missed",
        "Conversations",
        "Talk Time",
        "Unique Calls",
      ],
    ];
    usersData.forEach((item) => {
      let userArray = [
        item.name,
        item.incomingCalls,
        item.missedCalls,
        item.conversationCount,
        (Number(item.talkTime || 0) / 60).toFixed(0),
        item.uniqueCallsCount,
      ];
      users.push(userArray);
    });
    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(users);
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users");
    XLSX.writeFile(wb, `calls-export-${Date()}.csv`);
  };
  const enumerateDaysBetweenDates = (startDate, endDate) => {
    let date = [];
    while (moment(startDate) <= moment(endDate)) {
      date.push(startDate);
      startDate = moment(startDate).add(1, "days").format();
    }
    return date;
  };
  const handleMakeChartData = useCallback(() => {
    let activityData = chartData?.data?.agentActivity;
    let datesBTW = enumerateDaysBetweenDates(
      filters.fromDate,
      filters.lastDate
    );
    let labels = [];
    let datasets = [];
    if (filters.granularity?.value === "daily" && datesBTW?.length) {
      labels = datesBTW.map((item) =>
        moment(item).tz("America/New_York").format("LL")
      );
    } else if (filters.granularity?.value === "hourly") {
      let timeLable = [
        "12:00AM",
        "1:00AM",
        "2:00AM",
        "3:00AM",
        "4:00AM",
        "5:00AM",
        "6:00AM",
        "7:00AM",
        "8:00AM",
        "9:00AM",
        "10:00AM",
        "11:00AM",
        "12:00PM",
        "1:00PM",
        "2:00PM",
        "3:00PM",
        "4:00PM",
        "5:00PM",
        "6:00PM",
        "7:00PM",
        "8:00PM",
        "9:00PM",
        "10:00PM",
        "11:00PM",
      ];
      labels = timeLable.map(
        (item) =>
          `${moment(filters.fromDate)
            .tz("America/New_York")
            .format("LL")} ${item}`
      );
    } else if (filters.granularity?.value === "weekly") {
      let weeksData = [];
      if (chartType1.value !== "nothing") {
        weeksData = activityData
          ? activityData[chartType1.value].map(
              (item, index) =>
                `Week of ${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "weeks")
                  .format("MMM Do YY")}`
            )
          : [];
      } else if (chartType2.value !== "nothing") {
        weeksData = activityData
          ? activityData[chartType1.value].map(
              (item, index) =>
                `Week of ${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "weeks")
                  .format("MMM Do YY")}`
            )
          : [];
      }
      labels = weeksData;
    } else if (filters.granularity?.value === "monthly") {
      let monData = [];
      if (chartType1.value !== "nothing") {
        monData = activityData
          ? activityData[chartType1.value].map(
              (item, index) =>
                `${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "months")
                  .format("LL")}`
            )
          : [];
      } else if (chartType2.value !== "nothing") {
        monData = activityData
          ? activityData[chartType2.value].map(
              (item, index) =>
                `${moment(filters.fromDate)
                  .tz("America/New_York")
                  .add(index, "months")
                  .format("LL")}`
            )
          : [];
      }
      labels = monData;
    }
    if (chartType1.value !== "nothing") {
      let obj = {
        label: chartType1.title,
        data: activityData
          ? activityData[chartType1.value]?.length === 1 &&
            filters.granularity?.value === "hourly"
            ? Array.from(Array(24).keys()).map(() => "0")
            : chartType1.value === "talkTime"
            ? activityData[chartType1.value].map((valueTalk) =>
                (Number(valueTalk || 0) / 60).toFixed(0)
              )
            : activityData[chartType1.value]
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(255, 99, 132, 0.5)",
        },
      };
      datasets.push(obj);
    }
    if (chartType2.value !== "nothing") {
      let obj = {
        label: chartType2.title,
        data: activityData
          ? activityData[chartType2.value]?.length === 1 &&
            filters.granularity?.value === "hourly"
            ? Array.from(Array(24).keys()).map(() => "0")
            : chartType2.value === "talkTime"
            ? activityData[chartType2.value].map((valueTalk) =>
                (Number(valueTalk || 0) / 60).toFixed(0)
              )
            : activityData[chartType2.value]
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(53, 162, 235, 0.5)",
        },
      };
      datasets.push(obj);
    }
    let obj = {
      labels: labels,
      datasets,
    };
    return obj;
  }, [filters.granularity, chartData?.data?.agentActivity]);
  const getDaysDifference = (data) => {
    let a = moment(data.fromDate);
    let b = moment(data.lastDate);
    return b.diff(a, "days");
  };
  const handleCalculatePreviousLabel = useCallback(() => {
    let days = getDaysDifference(filters);
    if (days === 0) {
      return `${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("MMM Do YY")} 12AM - ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("MMM Do YY")} 11PM`;
    } else if (days === 6) {
      return `Week of ${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")} - Week of ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")}`;
    } else {
      return `${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")} - ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")}`;
    }
  }, [previousPeriod, filters]);
  const handleFilterGranularityOptions = useCallback(() => {
    let day = getDaysDifference(filters);
    if (day <= 1) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "weekly" && item.value !== "monthly"
      );
      return filteredOptions;
    } else if (day > 1 && day <= 7) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "hourly" && item.value !== "monthly"
      );
      return filteredOptions;
    } else if (day > 7 && day <= 30) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "hourly" && item.value !== "monthly"
      );
      return filteredOptions;
    } else if (day > 30 && day <= 364) {
      let filteredOptions = granularityOptions.filter(
        (item) => item.value !== "daily" && item.value !== "hourly"
      );
      return filteredOptions;
    } else if (day > 364) {
      let filteredOptions = granularityOptions.filter(
        (item) =>
          item.value !== "daily" &&
          item.value !== "hourly" &&
          item.value !== "monthly"
      );
      return filteredOptions;
    }
  }, [filters.fromDate, filters.lastDate]);

  useEffect(() => {
    if (previousPeriod.isChecked) {
      dispatch(
        getAgentReportingChartsData({
          ...filters,
          granularity: filters?.granularity?.value,
          timeSeries: true,
          fromDate: previousPeriod.fromDate.slice(0, 10) + "T00:00:01",
          lastDate: previousPeriod.lastDate.slice(0, 10) + "T23:59:59",
        })
      );
    } else {
      dispatch(
        getAgentReportingChartsData({
          ...filters,
          granularity: filters?.granularity?.value,
          timeSeries: true,
          fromDate: filters.fromDate.slice(0, 10) + "T00:00:01",
          lastDate: filters.lastDate.slice(0, 10) + "T23:59:59",
        })
      );
    }
  }, [filters, previousPeriod.isChecked]);

  return (
    <Box className={classes.root}>
      <Box className={classes.callScreenTopArea}>
        <Typography className={classes.callReportHeading} variant="h4">
          Call Report
        </Typography>
        <Box className={classes.callScreenTopFilter}>
          <Button
            onClick={handleExport}
            disableElevation
            disabled={callStats.isLoading}
            className={classes.exportButton}
            variant="contained"
          >
            <DownloadRoundedIcon fontSize="small" />
          </Button>
          <Box sx={{ mr: "15px" }}>
            <UserSelectionsForStats
              defaultValue="Everyone"
              handleGetUserDetail={handleGetUserDetail}
              {...props}
            />
          </Box>
          <DatePickersForStats
            defaultValue="This Month"
            handleGetDates={handleGetDates}
            {...props}
          />
        </Box>
      </Box>
      <Card variant="outlined" className={classes.agentActivitiesChartGraph}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ mb: "15px" }}
          spacing={2}
        >
          <ButtonDropdown
            placeHolder="Select"
            options={[]}
            value={chartType1}
            handleSelect={() => {}}
            disabled
            startIcon={
              <SquareRoundedIcon
                fontSize="small"
                sx={{ color: "rgba(255, 99, 132, 0.5)" }}
              />
            }
          />
          <Divider orientation="vertical" flexItem />
          <ButtonDropdown
            placeHolder="Select"
            options={handleFilterGranularityOptions(granularityOptions)}
            value={filters?.granularity}
            handleSelect={handleSelectGranularity}
          />
          <Divider orientation="vertical" flexItem />
          <ListItem className={classes.listItemForSelection} disablePadding>
            <ListItemAvatar sx={{ minWidth: "40px", ml: "-13px" }}>
              <Checkbox
                checked={previousPeriod.isChecked}
                onChange={(e) =>
                  setPreviousPeriod({
                    ...previousPeriod,
                    isChecked: e.target.checked,
                  })
                }
              />
            </ListItemAvatar>
            <ListItemText
              primary="Compare to previous period:"
              secondary={handleCalculatePreviousLabel()}
            />
          </ListItem>
        </Stack>
        <Divider />
        {chartData.isLoading ? (
          <Box sx={{ height: "220px" }}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height="100%"
            />
          </Box>
        ) : (
          <Box>
            <AgentActivityChart data={handleMakeChartData()} {...props} />
            <Box className={classes.graphBottomDates}>
              <Typography>
                {moment(
                  previousPeriod.isChecked
                    ? previousPeriod.fromDate
                    : filters.fromDate
                )
                  .tz("America/New_York")
                  .format("ll")}
              </Typography>
              <Typography>
                {moment(
                  previousPeriod.isChecked
                    ? previousPeriod.lastDate
                    : filters.lastDate
                )
                  .tz("America/New_York")
                  .format("ll")}
              </Typography>
            </Box>
          </Box>
        )}
      </Card>
      {callStats.isLoading ? (
        <CallStatsSkelton />
      ) : (
        <Grid container spacing={2} sx={{ mt: "12px" }}>
          <Grid item sm={12} md={3} lg={2}>
            <Card variant="outlined" sx={{ padding: "10px" }}>
              <Box className={classes.callStatContent}>Unique CALLS</Box>
              <Box className={classes.callStatValue}>
                {selectedStats
                  ? selectedStats.uniqueCallsCount
                  : handleGetStatsSum("uniqueCallsCount") || "0"}
              </Box>
            </Card>
          </Grid>
          <Grid item sm={12} md={3} lg={2}>
            <Card variant="outlined" sx={{ padding: "10px" }}>
              <Box className={classes.callStatContent}>CALLS Connected</Box>
              <Box className={classes.callStatValue}>
                {selectedStats
                  ? selectedStats.connectedCallsCount
                  : handleGetStatsSum("connectedCallsCount") || "0"}
              </Box>
            </Card>
          </Grid>
          <Grid item sm={12} md={3} lg={2}>
            <Card variant="outlined" sx={{ padding: "10px" }}>
              <Box className={classes.callStatContent}>CONVERSATIONS</Box>
              <Box className={classes.callStatValue}>
                {selectedStats
                  ? selectedStats.conversationCount
                  : handleGetStatsSum("conversationCount") || "0"}
              </Box>
            </Card>
          </Grid>

          <Grid item sm={12} md={3} lg={2}>
            <Card variant="outlined" sx={{ padding: "10px" }}>
              <Box className={classes.callStatContent}>Talk Time</Box>
              <Box className={classes.callStatValue}>
                {selectedStats
                  ? (Number(selectedStats.talkTime) / 60).toFixed(0)
                  : handleGetStatsSum("talkTime") || "0"}
              </Box>
            </Card>
          </Grid>
          <Grid item sm={12} md={3} lg={2}>
            <Card variant="outlined" sx={{ padding: "10px" }}>
              <Box className={classes.callStatContent}>CALLS Incoming</Box>
              <Box className={classes.callStatValue}>
                {selectedStats
                  ? selectedStats.incomingCalls
                  : handleGetStatsSum("incomingCalls") || "0"}
              </Box>
            </Card>
          </Grid>
          <Grid item sm={12} md={3} lg={2}>
            <Card variant="outlined" sx={{ padding: "10px" }}>
              <Box className={classes.callStatContent}>CALLS MISSED</Box>
              <Box className={classes.callStatValue}>
                {selectedStats
                  ? selectedStats.missedCalls
                  : handleGetStatsSum("missedCalls") || "0"}
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      <br />
      <br />
      <CallsRecordsTable
        loading={callStats.isLoading}
        data={
          selectedStats
            ? callStats?.data.filter((item) => item.id === selectedStats.id)
            : callStats?.data
        }
        {...props}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "CallsReportingStyle" })(
  CallsReporting
);
