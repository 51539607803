import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
export const PhoneCell = ({ number }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: "5px",
      }}
    >
      <LocalPhoneIcon /> {number}
    </div>
  );
};


