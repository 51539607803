import { createSlice } from "@reduxjs/toolkit";
import { getTimelinesAgentsRecruitment } from "./agentRecruitment/getTimelinesAgentsRecruitment";
import { updateTimelineRecordAgentRecruitment } from "./agentRecruitment/updateTimelineRecordAgentRecruitment";

const initialState = {
  actionState: {
    loading: false,
    errMsg: "",
    error: false,
    success: "",
    firstTimeLoading:true
  },
  data: [],
  count: 0,
};

const timelines = createSlice({
  name: "timelines",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.actionState = false;
      state.actionState.loading = false;
      state.actionState.errMsg = "";
      state.actionState.success = false;
      state.actionState.firstTimeLoading = true;
    },
    handResetTimeLineData:(state)=>{
      state.actionState.loading = false;
      state.actionState.errMsg = "";
      state.actionState.success = false;
      state.actionState.firstTimeLoading = true;
      state.data=[];
      state.count=0
    }
  },

  extraReducers: {
    [getTimelinesAgentsRecruitment.pending]: (state, action) => {
      state.actionState.loading = true;
    },
    [getTimelinesAgentsRecruitment.fulfilled]: (state, action) => {
      state.actionState.loading = false;
      state.actionState.firstTimeLoading = false;
      state.data = action.payload.timelines;
      state.count = action.payload._metadata.total;
    },
    [getTimelinesAgentsRecruitment.rejected]: (state, action) => {
      state.actionState.loading = false;
      state.actionState.errMsg = action.payload;
    },

    [updateTimelineRecordAgentRecruitment.pending]: (state, action) => {
      state.actionState.error = false;
      state.actionState.loading = true;
    },
    [updateTimelineRecordAgentRecruitment.fulfilled]: (state, action) => {
      if (action.payload.data) {
        const objIndex = state.data.findIndex(
          (obj) =>
            obj[action.payload.timeline_type == "note" ? "realtor_note" : action.payload.timeline_type]?.id == action?.payload?.data.id
        );
        if (action.payload.timeline_type == "note") {
          state.data[objIndex].realtor_note = action.payload.data;
        } else if (action.payload.timeline_type == "call") {
          state.data[objIndex].call = action.payload.data;
        } else {
          console.log("typeee", action.payload.timeline_type);
        }
      }

      state.actionState.loading = false;
    },
    [updateTimelineRecordAgentRecruitment.rejected]: (state, action) => {
      state.actionState.loading = false;
      state.actionState.error = true;
    },
  },
});

export const { resetState,handResetTimeLineData } = timelines.actions;

export default timelines.reducer;
