import React from "react";

import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { styled, alpha } from "@mui/material/styles";
import { Container, Box, Button, Typography, InputBase ,IconButton} from "@mui/material";
import { Home, Folder, Archive } from "@mui/icons-material";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // vertical padding + font size from searchIcon
    padding: "0 10px 0 10px ",
    width: "289.86px",
    height: "35px",
    transition: theme.transitions.create("width"),
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: "0.15px",
    [theme.breakpoints.up("sm")]: {
      "&:focus": {},
    },
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const HeaderDocs = ({ classes, addFolderModal, archiveFunction, handleSearchInputChange,selectedCheckboxIds }) => {
  return (
    <Box className={classes.docsAppBar} sx={{ position: "sticky", top: "64px", zIndex: "100" }} >
      <Container maxWidth="xl">
        <Box className={classes.docsAppBarArea}>
        <Box className={classes.docsTitle} >
          <Home fontSize="large" />
          Docs
        </Box>
        <Box className={classes.docsAppBarRight}>
            <Box  className={classes.docsAppBarRight} sx={{mr:"30px"}}>
              <Button size="small"  variant="contained" startIcon={<Folder sx={{fontSize:'20px'}} />} classes={{root:classes.newfolderButton}} onClick={addFolderModal} >                
                New Folder
              </Button>
              <IconButton edge={false} sx={{ml:"30px"}}>
              <Box >
              <DownloadRoundedIcon  sx={{color: "rgba(0, 0, 0, 0.87)"}}/> 
              <Box className={classes.customAppbarButtonText}> Download </Box>
              </Box>
              </IconButton>
              <IconButton disabled={selectedCheckboxIds.length===0} onClick={archiveFunction} classes={{root:classes.customAppbarButton}}>
              <Box >
              <Archive  sx={{color:selectedCheckboxIds.length===0?"rgba(0, 0, 0, 0.54)":"rgba(0, 0, 0, 0.87)",cursor:selectedCheckboxIds.length===0?"not-allowed !important":"pointer"}}/> 
              <Box className={classes.customAppbarButtonText}> Archive </Box>
              </Box>
              </IconButton>
            </Box>
            <Search>
              <StyledInputBase placeholder="Search Folders" inputProps={{ "aria-label": "search" }} onChange={(e) => handleSearchInputChange(e)} />
            </Search>
        </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withStyles(styleSheet, { name: "HeaderDocsStyle" })(HeaderDocs);
