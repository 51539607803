import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
};

export const getSignedUrl = createAsyncThunk("finance/getSignedUrl", async (src, thunkAPI) => {
  try {
    let headers = {
      Authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}api/transactions/signed-url?src=${encodeURIComponent(src)}`, { headers })
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          return err;
        });
    });
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const signedUrl = createSlice({
  name: "signedUrl",
  initialState,
  extraReducers: {
    [getSignedUrl.pending]: (state) => {
      state.loading = true;
    },
    [getSignedUrl.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getSignedUrl.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default signedUrl.reducer;
