import { createSlice } from "@reduxjs/toolkit";
import { getAgentRequests } from "./getAllRequests";
import { getRequestDetail } from "./getTicket";
import { addTicketAttachment } from "./addTicketAttachment";
import { removeTicketAttachment } from "./removeAttachment";
import { updateRequestTicket } from "./updateTicket";
import { addTicketComment } from "./addComment";
import { addTicketEvent } from "./addEvent";
const initialState = {
  requestList: {
    success: false,
    data: [],
    isLoading: true,
    errMsg: null,
    count: 1,
  },
  requestDetail: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  addAttachment: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  removeAttachment: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateTicket: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addComment: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addEvent: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const AgentRequests = createSlice({
  name: "AgentRequests",
  initialState,
  reducers: {
    handleAddComment: (state, action) => {
      let comments = state.requestDetail?.data?.comments
        ? [...state.requestDetail?.data?.comments]
        : [];
      comments.push(action.payload);
      state.requestDetail.data.comments = comments;
    },
  },
  extraReducers: {
    [getAgentRequests.pending]: (state, action) => {
      state.requestList.isLoading = true;
      state.requestList.errMsg = null;
      state.requestList.success = false;
      if (action.meta?.arg?.pageNumber === 1) {
        state.requestList.data = [];
      }
    },
    [getAgentRequests.fulfilled]: (state, action) => {
      state.requestList.success = true;
      state.requestList.isLoading = false;
      if (action.meta?.arg?.pageNumber > 1) {
        state.requestList.data = [
          ...state.requestList.data,
          ...action.payload?.tickets,
        ];
      } else {
        state.requestList.data = action.payload?.tickets;
      }

      state.requestList.count = action.payload?.count;
    },
    [getAgentRequests.rejected]: (state, action) => {
      state.requestList.isLoading = false;
      state.requestList.success = false;
      state.requestList.errMsg = action.payload;
    },

    // request detail
    [getRequestDetail.pending]: (state) => {
      state.requestDetail.isLoading = true;
      state.requestDetail.errMsg = null;
      state.requestDetail.success = false;
    },
    [getRequestDetail.fulfilled]: (state, action) => {
      state.requestDetail.success = true;
      state.requestDetail.isLoading = false;
      state.requestDetail.data = {
        ...action.payload,
        comments: action.payload?.comments,
      };
    },
    [getRequestDetail.rejected]: (state, action) => {
      state.requestDetail.isLoading = false;
      state.requestDetail.success = false;
      state.requestDetail.errMsg = action.payload;
    },

    // add ticket attachment
    [addTicketAttachment.pending]: (state) => {
      state.addAttachment.isLoading = true;
      state.addAttachment.errMsg = null;
      state.addAttachment.success = false;
    },
    [addTicketAttachment.fulfilled]: (state, action) => {
      state.addAttachment.success = true;
      state.addAttachment.isLoading = false;
      state.addAttachment.data = action.payload;
      let attachments = state.requestDetail?.data?.attachments
        ? [...state.requestDetail?.data?.attachments]
        : [];
      attachments.push({ ...action.payload[0], isProcessing: true });
      state.requestDetail.data.attachments = attachments;
    },
    [addTicketAttachment.rejected]: (state, action) => {
      state.addAttachment.isLoading = false;
      state.addAttachment.success = false;
      state.addAttachment.errMsg = action.payload;
    },
    // remove  ticket attachment
    [removeTicketAttachment.pending]: (state) => {
      state.removeAttachment.isLoading = true;
      state.removeAttachment.errMsg = null;
      state.removeAttachment.success = false;
    },
    [removeTicketAttachment.fulfilled]: (state, action) => {
      state.removeAttachment.success = true;
      state.removeAttachment.isLoading = false;
      state.removeAttachment.data = action.payload;
      let attachments = state.requestDetail?.data?.attachments
        ? [...state.requestDetail?.data?.attachments]
        : [];
      state.requestDetail.data.attachments = attachments.filter(
        (item) => item.id !== action.payload?.ticketId
      );
    },
    [removeTicketAttachment.rejected]: (state, action) => {
      state.removeAttachment.isLoading = false;
      state.removeAttachment.success = false;
      state.removeAttachment.errMsg = action.payload;
    },
    // update agent request  ticket
    [updateRequestTicket.pending]: (state) => {
      state.updateTicket.isLoading = true;
      state.updateTicket.errMsg = null;
      state.updateTicket.success = false;
    },
    [updateRequestTicket.fulfilled]: (state, action) => {
      state.updateTicket.success = true;
      state.updateTicket.isLoading = false;
      state.updateTicket.data = action.payload;
      if (action.payload.schema.status === "closed") {
        state.requestDetail.data = {};
        let requestList = [...state.requestList.data];
        state.requestList.data = requestList.filter(
          (item) => item.id !== action.payload.id
        );
      } else {
        state.requestDetail.data = {
          ...state.requestDetail.data,
          ...action.payload,
        };
      }
    },
    [updateRequestTicket.rejected]: (state, action) => {
      state.updateTicket.isLoading = false;
      state.updateTicket.success = false;
      state.updateTicket.errMsg = action.payload;
    },
    // add comment on agent request ticket
    [addTicketComment.pending]: (state) => {
      state.addComment.isLoading = true;
      state.addComment.errMsg = null;
      state.addComment.success = false;
    },
    [addTicketComment.fulfilled]: (state, action) => {
      state.addComment.success = true;
      state.addComment.isLoading = false;
      state.addComment.data = action.payload;
      let comments = state.requestDetail?.data?.comments
        ? [...state.requestDetail?.data?.comments]
        : [];
      comments.push(action.payload);
      state.requestDetail.data.comments = comments.filter(
        (item) => !item.isLoading
      );
    },
    [addTicketComment.rejected]: (state, action) => {
      state.addComment.isLoading = false;
      state.addComment.success = false;
      state.addComment.errMsg = action.payload;
    },
    // add event on agent ticket
    [addTicketEvent.pending]: (state) => {
      state.addEvent.isLoading = true;
      state.addEvent.errMsg = null;
      state.addEvent.success = false;
    },
    [addTicketEvent.fulfilled]: (state, action) => {
      state.addEvent.success = true;
      state.addEvent.isLoading = false;
      state.addEvent.data = action.payload;
      let comments = state.requestDetail?.data?.comments
        ? [...state.requestDetail?.data?.comments]
        : [];
      comments.push(action.payload);
      state.requestDetail.data.comments = comments;
    },
    [addTicketEvent.rejected]: (state, action) => {
      state.addEvent.isLoading = false;
      state.addEvent.success = false;
      state.addEvent.errMsg = action.payload;
    },
  },
});
export const { handleAddComment } = AgentRequests.actions;
export default AgentRequests.reducer;
export * from "./getAllRequests";
export * from "./getTicket";
export * from "./addTicketAttachment";
export * from "./removeAttachment";
export * from "./updateTicket";
export * from "./addComment";
export * from "./addEvent";
