import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { accounts } from "../../../utils/propertyData";
export const saveSearch = createAsyncThunk(
  "LeadSavedSearches/saveSearch",
  async (data, thunkAPI) => {
    try {
      let site_id =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.site_id;
      let token =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.token;
      let agent =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.assignedAgent
          ?._id;
      let lead = thunkAPI.getState()?.leads?.savedSearches.searchList.data?._id;
      let XAccessToken =
        accounts?.find((item) => item?._id === site_id?.account)
          ?.secret_token || "";
      let url = `${process.env.REACT_APP_IDX_BASE_URL}api/v1/save-search/${site_id?._id}`;
      const resp = await axios.post(
        url,
        {
          title: data?.title,
          searchId: data?.searchId,
          emailNotification: data?.emailNotification,
          agent,
          lead,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            // "x-access-token": XAccessToken,
          },
        }
      );
      if (data?.handleSuccess && resp.data) {
        data?.handleSuccess();
      }
      return {
        ...resp.data.data,
        advanceSearchId: {
          query: { ...data.query },
          geoOperator: data?.geoOperator,
        },
      };
    } catch (error) {
      if (error.response) {
        if (data?.handleError) {
          data.handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (data?.handleError) {
          data.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
