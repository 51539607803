import { createSlice } from "@reduxjs/toolkit";
import { getFeaturesData } from "./getFeaturesData";
import { getPublicData } from "./getPublicData";
import { updatePublicData } from "./updatePublicData";
import { uploadPublicDataImage } from "./uploadImage";
import { deletePublicDataImage } from "./deleteImage";
import { updatePublicDataCategories } from "./updateSubcategories";
const initialState = {
  publicDataDetail: {},
  isLoading: false,
  errMsg: null,
  success: false,
  update: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
  upload: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
  deleteImage: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
  featureData: {
    features: [],
    selectedFeatures: [],
    isLoading: false,
    errMsg: null,
    success: false,
  },
  updateCategories: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
};
const ListingPublicData = createSlice({
  name: "ListingPublicData",
  initialState,
  reducers: {
    handleUpdateImages: (state, action) => {
      state.publicDataDetail.images = action.payload;
    },
  },
  extraReducers: {
    //  get featured data
    [updatePublicDataCategories.pending]: (state) => {
      state.updateCategories.isLoading = true;
      state.updateCategories.errMsg = null;
      state.updateCategories.success = false;
    },
    [updatePublicDataCategories.fulfilled]: (state, action) => {
      state.updateCategories.success = true;
      state.updateCategories.isLoading = false;
      state.updateCategories.errMsg = null;
      state.publicDataDetail.features = action.payload.features;
      state.featureData.selectedFeatures = action.payload.features;
    },
    [updatePublicDataCategories.rejected]: (state, action) => {
      state.updateCategories.isLoading = false;
      state.updateCategories.success = false;
      state.updateCategories.errMsg = action.payload;
    },
    //  update sub categories data
    [getFeaturesData.pending]: (state) => {
      state.featureData.isLoading = true;
      state.featureData.errMsg = null;
      state.featureData.success = false;
    },
    [getFeaturesData.fulfilled]: (state, action) => {
      state.featureData.success = true;
      state.featureData.isLoading = false;
      state.featureData.errMsg = null;
      state.featureData.features = action.payload.features;
      state.featureData.selectedFeatures = action.payload.selectedFeatures;
    },
    [getFeaturesData.rejected]: (state, action) => {
      state.featureData.isLoading = false;
      state.featureData.success = false;
      state.featureData.errMsg = action.payload;
    },

    //  get listing data
    [getPublicData.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getPublicData.fulfilled]: (state, action) => {
      state.success = true;
      state.isLoading = false;
      state.errMsg = null;
      state.publicDataDetail = action.payload;
    },
    [getPublicData.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
    // update listing public data
    [updatePublicData.pending]: (state) => {
      state.update.isLoading = true;
      state.update.errMsg = null;
      state.update.success = false;
    },
    [updatePublicData.fulfilled]: (state, action) => {
      state.update.success = true;
      state.update.isLoading = false;
      state.update.errMsg = null;
      state.publicDataDetail = { ...state.publicDataDetail, ...action.payload };
    },
    [updatePublicData.rejected]: (state, action) => {
      state.update.isLoading = false;
      state.update.success = false;
      state.update.errMsg = action.payload;
    },
    // update  public data images
    [uploadPublicDataImage.pending]: (state) => {
      state.upload.isLoading = true;
      state.upload.errMsg = null;
      state.upload.success = false;
    },
    [uploadPublicDataImage.fulfilled]: (state, action) => {
      state.upload.success = true;
      state.upload.isLoading = false;
      state.upload.errMsg = null;
      let newImages = state.publicDataDetail?.images ? [...state.publicDataDetail?.images] : [];
      state.publicDataDetail.images = [...newImages, ...action.payload.images];
    },
    [uploadPublicDataImage.rejected]: (state, action) => {
      state.upload.isLoading = false;
      state.upload.success = false;
      state.upload.errMsg = action.payload;
    },
    // update  public data images
    [deletePublicDataImage.pending]: (state) => {
      state.deleteImage.isLoading = true;
      state.deleteImage.errMsg = null;
      state.deleteImage.success = false;
    },
    [deletePublicDataImage.fulfilled]: (state, action) => {
      state.deleteImage.success = true;
      state.deleteImage.isLoading = false;
      state.deleteImage.errMsg = null;
      let newImages = state.publicDataDetail?.images ? [...state.publicDataDetail?.images] : [];
      state.publicDataDetail.images = newImages.filter((item) => !action.payload.image_id.includes(item.id));
    },
    [deletePublicDataImage.rejected]: (state, action) => {
      state.deleteImage.isLoading = false;
      state.deleteImage.success = false;
      state.deleteImage.errMsg = action.payload;
    },
  },
});
export const { handleUpdateImages } = ListingPublicData.actions;
export default ListingPublicData.reducer;

export * from "./getFeaturesData";
export * from "./getPublicData";
export * from "./updatePublicData";
export * from "./uploadImage";
export * from "./deleteImage";
export * from "./updateSubcategories";
