import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const updateAgentRosterProfile = createAsyncThunk(
  "agentRosterProfile/updateAgentRosterProfile",
  async (data, thunkAPI) => {
    const handleSuccess = data.handleSuccess;
    const handleError = data.handleError;
    delete data.handleSuccess;
    delete data.handleError;
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/users/${data.id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      if (handleSuccess) {
        handleSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
