import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Container,
  Chip,
  Typography,
  Button,
  Checkbox,
  TextField,
} from "@mui/material";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import ComparableMap from "./ComparableMap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCMAComparable,
  getCmaComparableRecordById,
  getSingleCma,
  selectComparable,
  setComparableSwitch,
} from "../../../redux/tools/cmas.js";
import DataGridComponent from "../../projects/DataGridComponent";
import { numberWithCommas } from "./helper.js";
import CancelIcon from "@mui/icons-material/Cancel";
import { debounce } from "lodash";
import { GridActionsCellItem } from "@mui/x-data-grid";
import NoImage from "../../../assets/images/noimage.png";
import LoadingIcon from "../../../assets/images/cmaicons/loading.png";
import WarningIcon from "../../../assets/images/cmaicons/warning.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateComparable from "./CreateComparable";
import { getMarkets } from "../../../redux/agents/addRecruitAgent";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData.js";

function makeArrayUnique(array) {
  // Create an object to store unique items based on ListingId
  const uniqueItems = {};

  // Iterate over the array
  for (const item of array) {
    const { ListingId, isSelected } = item;

    // If an item with the same ListingId doesn't exist in uniqueItems
    // or if the existing item has isSelected: false and the current item has isSelected: true,
    // update the item in uniqueItems
    if (
      !uniqueItems[ListingId] ||
      (!uniqueItems[ListingId].isSelected && isSelected)
    ) {
      uniqueItems[ListingId] = item;
    }
  }

  // Convert the object values back to an array
  const uniqueArray = Object.values(uniqueItems);

  return uniqueArray;
}

function Comparables(props) {
  const {
    classes,
    countTime,
    setCountTime,
    countFoot,
    setCountFoot,
    countTolerance,
    setCountTolerance,
    countDistance,
    setCountDistance,
    startPrice,
    setStartPrice,
    endPrice,
    setEndPrice,
  } = props;
  const dispatch = useDispatch();
  const url = window.location.pathname;
  const cmaId = url.split("/")[3];

  const params = useParams();
  const [mapBounds, setMapBounds] = useState(null);
  let [comparableData, setComparableData] = useState([]);
  let [selectedComparableData, setSelectedComparableData] = useState([]);
  const [rows, setRows] = useState([]);
  const [comparableRows, setComparableRows] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [queryChanged, setQueryChanged] = React.useState(false);

  const [openCreateComparable, setOpenCreateComparable] = React.useState(false);
  const [listView, setListView] = React.useState(false);

  const [switchValue, setSwitchValue] = useState(null);
  const [datasets, setDatasets] = useState([]);

  const agents = useSelector((state) =>
    state.users.storedListOfUsers.list.filter((user) => user.is_agent === true)
  );
  const markets = useSelector((state) => state.agentList.markets);
  const { agent_id } = useSelector((state) => state.allCmas.singleCma);

  const {
    deleteCMALoading,
    cmaRecord,
    singleCma,
    selectedComparable,
    comparableSwitch,
    comparableRecordLoading,
  } = useSelector((state) => state.allCmas);

  const decreaseFoot = () => {
    if (countFoot === 10) {
      return;
    }
    let n = countFoot;
    if (n === 50) n = n - 20;
    else if (n === null) n = 100;
    else n = n - 10;
    setCountFoot(n);
  };
  const increaseFoot = () => {
    if (countFoot === 100) {
      setCountFoot(null);
      return;
    } else if (countFoot === null) return;
    let n = countFoot;
    if (n === 30) n = n + 20;
    else n = n + 10;
    setCountFoot(n);
  };
  const decreaseTolerance = () => {
    if (countTolerance === 5) {
      return;
    }
    let n = countTolerance;
    if (n === 30) n = n - 10;
    else if (n === 50) n = n - 20;
    else if (n === null) n = 50;
    else n = n - 5;
    setCountTolerance(n);
  };
  const increaseTolerance = () => {
    if (countTolerance === 50) {
      setCountTolerance(null);
      return;
    } else if (countTolerance === null) return;

    let n = countTolerance;
    if (n === 20) n = n + 10;
    else if (n === 30) n = n + 20;
    else n = n + 5;
    setCountTolerance(n);
  };
  const decreaseTime = () => {
    if (countTime === 6) {
      return;
    }
    let n = countTime;
    if (n === 12) n = n - 4;
    else if (n === 24 || n === 18) n = n - 6;
    else if (n === 36) n = n - 12;
    else n = n - 2;
    setCountTime(n);
  };
  const increaseTime = () => {
    if (countTime === 36) {
      return;
    }
    let n = countTime;
    if (n === 8) n = n + 4;
    else if (n === 12 || n === 18) n = n + 6;
    else if (n === 24) n = n + 12;
    else n = n + 2;
    setCountTime(n);
  };
  const decreaseDistance = () => {
    if (countDistance === 0.25) {
      return;
    }
    let n = countDistance;
    if (n === 30 || n === 20) n = n - 10;
    if (n === 10) n = n - 5;
    if (n === 5 || n === 4 || n === 3) n = n - 1;
    if (n === 2 || n === 1.5) n = n - 0.5;
    else n = n - 0.25;
    setCountDistance(n);
  };
  const increaseDistance = () => {
    if (countDistance === 30) {
      return;
    }
    let n = countDistance;
    if (n === 10 || n === 20) n = n + 10;
    if (n === 5) n = n + 5;
    if (n === 2 || n === 3 || n === 4) n = n + 1;
    if (n === 1 || n === 1.5) n = n + 0.5;
    else n = n + 0.25;
    setCountDistance(n);
  };

  const mapBoundCalculate = (comD, scomD) => {
    if (comD.length > 0) {
      let markers = [...comD, ...scomD]?.map((c) => ({
        lat: parseFloat(c?.latitude),
        lng: parseFloat(c?.longitude),
      }));
      let bounds = new window.google.maps.LatLngBounds();

      for (let i = 0; i < markers.length; i++) {
        let position = new window.google.maps.LatLng(
          parseFloat(markers[i].lat),
          parseFloat(markers[i].lng)
        );
        bounds.extend(position);
      }

      let position = new window.google.maps.LatLng(
        parseFloat(singleCma?.latitude),
        parseFloat(singleCma?.longitude)
      );
      bounds.extend(position);
      setMapBounds(bounds);
    }
  };

  const getComparableData = React.useRef(
    debounce(async (val) => {
      const { shouldCalculateBound } = val;
      delete val.shouldCalculateBound;
      const d = await dispatch(getCmaComparableRecordById({ ...val }));
      if (shouldCalculateBound) {
        mapBoundCalculate(d.payload.data, d.payload.selectedComparable);
      }
      setQueryChanged(true);
      setComparableData([...d.payload.data]);
    }, 500)
  ).current;

  const manualCreateCMA = (manualComparables) => {
    let updatedComparableDataRows = [...comparableRows];
    let updatedSelctedComparableData = [...selectedComparableData];

    manualComparables.map((comparable) => {
      comparable.isSelected = true;
      updatedComparableDataRows.push(comparable);
      updatedSelctedComparableData.push(comparable);
    });

    setSelectedComparableData(updatedSelctedComparableData);
    setComparableRows(updatedComparableDataRows);

    dispatch(getSingleCma({ id: cmaId }));

    if (!listView) {
      getComparableData({
        id: cmaId,
        sqft_tolerance: countFoot,
        vintage_tolerance: countTolerance,
        time_frame: countTime,
        distance: countDistance,
        listingType: switchValue,
        startPrice: startPrice,
        endPrice: endPrice,
        shouldCalculateBound: false,
      });
    }
  };

  const addToCMASelectedComparable = async (listingID, id, listingType) => {
    let updatedComparableDataRows = [...comparableRows];
    updatedComparableDataRows = updatedComparableDataRows.map((p) => {
      if (p.ListingId === listingID) p.isSelected = true;
      return p;
    });
    setComparableRows(updatedComparableDataRows);

    let url = window.location.pathname;
    let cmaId = url.split("/")[3];
    const resp = await dispatch(
      selectComparable({
        cmaId: cmaId,
        listingId: listingID,
        ListingType: switchValue || listingType,
      })
    );

    let updatedSelctedComparableData = [...selectedComparableData];
    updatedSelctedComparableData.push(resp.payload.data);
    setSelectedComparableData(updatedSelctedComparableData);

    dispatch(getSingleCma({ id: cmaId }));

    if (!listView) {
      getComparableData({
        id: cmaId,
        sqft_tolerance: countFoot,
        vintage_tolerance: countTolerance,
        time_frame: countTime,
        distance: countDistance,
        listingType: switchValue,
        startPrice: startPrice,
        endPrice: endPrice,
        shouldCalculateBound: false,
      });
    }
  };

  const handleRemoveCMAComparable = async (id) => {
    let updatedComparableDataRows = [...comparableRows];
    updatedComparableDataRows = updatedComparableDataRows.map((p) => {
      if (p.id === id) p.isSelected = false;
      return p;
    });

    await dispatch(deleteCMAComparable({ id: id, cmaId: cmaId }));

    let updatedSelctedComparableData = [...selectedComparableData];
    updatedSelctedComparableData = updatedSelctedComparableData.filter(
      (p) => p.id !== id
    );
    setSelectedComparableData(updatedSelctedComparableData);
    setComparableRows(updatedComparableDataRows);

    getComparableData({
      id: cmaId,
      sqft_tolerance: countFoot,
      vintage_tolerance: countTolerance,
      time_frame: countTime,
      distance: countDistance,
      listingType: switchValue,
      startPrice: startPrice,
      endPrice: endPrice,
      shouldCalculateBound: false,
    });
    dispatch(getSingleCma({ id: cmaId }));
  };

  const handleChangeFMLSwitch = (event) => {
    let selectedMLS = event.target.checked ? datasets[1] : datasets[0];
    setSwitchValue(selectedMLS);

    dispatch(setComparableSwitch(event.target.checked));
    getComparableData({
      id: cmaId,
      sqft_tolerance: countFoot,
      vintage_tolerance: countTolerance,
      time_frame: countTime,
      distance: countDistance,
      listingType: selectedMLS,
      startPrice: startPrice,
      endPrice: endPrice,
      shouldCalculateBound: true,
    });
  };

  const handleChangeListView = (event) => {
    setListView(event.target.checked);
  };

  const cols = React.useMemo(() => [
    {
      field: "MlsStatus",
      headerName: "Status",
      align: "center",
      renderCell: (row) => {
        return (
          <Chip
            label={
              row.value === "Active Under Contract" ? "ActiveUC" : row.value
            }
            sx={{
              color: row.value === "Pending" ? "#0B0909!important" : "#fff",
              backgroundColor:
                row.value === "Closed"
                  ? "red"
                  : row.value === "Active"
                  ? "green"
                  : row.value === "Pending"
                  ? "yellow"
                  : row.value === "Active Under Contract"
                  ? "#4CC7CF"
                  : "grey",
              width: "120px",
              padding: "0",
            }}
          />
        );
      },
    },
    {
      field: "MediaURL",
      headerName: "Image",
      width: 120,
      renderCell: (row) => {
        return (
          <img
            className="mediaImage"
            src={row.value || NoImage}
            alt="cmaimage"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 210,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <span
              style={{
                marginBottom: "2px",
                fontWeight: "600",
                display: "block",
                fontSize: "15px",
                lineHeight: "20.02px",
                letterSpacing: "0.5px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.title}
            </span>
            <span
              style={{
                fontWeight: "400",
                display: "block",
                fontSize: "12px",
                lineHeight: "20.02px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.subtitle}
            </span>
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      renderCell: (params) => {
        return (
          <Typography
            sx={{ fontSize: "20px!important", fontWeight: "500!important" }}
          >
            {params.value
              ? `$${numberWithCommas(Math.round(params.value))}`
              : ""}
          </Typography>
        );
      },
      width: 150,
      flex: 1,
    },
    {
      field: "BedroomsTotal",
      headerName: "Beds",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 92,
      flex: 1,
    },
    {
      field: "BathroomsFull",
      headerName: "Baths",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 92,
      flex: 1,
    },
    {
      field: "BuildingAreaTotal",
      headerName: "Sqft",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value ? numberWithCommas(params.value) : ""}
        </Typography>
      ),
      width: 92,
      flex: 1,
    },
    {
      field: "YearBuilt",
      headerName: "Yr Built",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "16px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 95,
      flex: 1,
    },
    {
      field: "DaysOnMarket",
      headerName: "DOM",
      width: 95,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 95,
      flex: 1,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Delete"
          onClick={() => handleRemoveCMAComparable(params.id)}
        />,
      ],
    },
  ]);

  const comparableCols = React.useMemo(() => [
    {
      field: "MlsStatus",
      headerName: "Status",
      align: "center",
      renderCell: (row) => {
        return (
          <Chip
            label={
              row.value === "Active Under Contract" ? "ActiveUC" : row.value
            }
            sx={{
              color: row.value === "Pending" ? "#0B0909!important" : "#fff",
              backgroundColor:
                row.value === "Closed"
                  ? "red"
                  : row.value === "Active"
                  ? "green"
                  : row.value === "Pending"
                  ? "yellow"
                  : row.value === "Active Under Contract"
                  ? "#4CC7CF"
                  : "grey",
              width: "120px",
              padding: "0",
            }}
          />
        );
      },
    },
    {
      field: "MediaURL",
      headerName: "Image",
      width: 120,
      renderCell: (row) => {
        return (
          <img
            className="mediaImage"
            src={row.value || NoImage}
            alt="cmaimage"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 210,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <span
              style={{
                marginBottom: "2px",
                fontWeight: "600",
                display: "block",
                fontSize: "15px",
                lineHeight: "20.02px",
                letterSpacing: "0.5px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.title}
            </span>
            <span
              style={{
                fontWeight: "400",
                display: "block",
                fontSize: "12px",
                lineHeight: "20.02px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.subtitle}
            </span>
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      renderCell: (params) => {
        return (
          <Typography
            sx={{ fontSize: "20px!important", fontWeight: "500!important" }}
          >
            {params.value
              ? `$${numberWithCommas(Math.round(params.value))}`
              : ""}
          </Typography>
        );
      },
      width: 150,
      flex: 1,
    },
    {
      field: "BedroomsTotal",
      headerName: "Beds",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 92,
      flex: 1,
    },
    {
      field: "BathroomsFull",
      headerName: "Baths",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 92,
      flex: 1,
    },
    {
      field: "BuildingAreaTotal",
      headerName: "Sqft",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value ? numberWithCommas(params.value) : ""}
        </Typography>
      ),
      width: 92,
      flex: 1,
    },
    {
      field: "YearBuilt",
      headerName: "Yr Built",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "16px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 92,
      flex: 1,
    },
    {
      field: "DaysOnMarket",
      headerName: "DOM",
      width: 92,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 95,
      flex: 1,
      type: "actions",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Checkbox
            sx={{ fontSize: "16px!important" }}
            onChange={() => {
              !params.row.isSelected
                ? addToCMASelectedComparable(
                    params.row.ListingId,
                    params.row.cma_id,
                    switchValue || params.row.ListingType
                  )
                : handleRemoveCMAComparable(params.row.id);
            }}
            checked={params.row.isSelected}
          />
        );
      },
    },
  ]);

  React.useEffect(() => {
    setComparableData([...cmaRecord]);
  }, [cmaRecord]);

  const [agent, market] = React.useMemo(() => {
    if (
      agents.length > 0 &&
      typeof agent_id === "number" &&
      markets.length > 0
    ) {
      const agent = agents.find((agent) => agent.id === Number(agent_id));
      const market = markets?.find((market) => market.id === agent?.market_id);
      return [agent, market];
    }
    return [null, null];
  }, [agent_id, agents, markets]);

  const [showSwitch] = React.useMemo(() => {
    if (agent && market) {
      if (market?.datasets?.length === 2) {
        setDatasets(market?.datasets);
        setSwitchValue(market?.datasets[0]);
        dispatch(setComparableSwitch(false));
        return [true];
      } else {
        setSwitchValue(
          market?.datasets?.length === 1 ? market.datasets[0] : null
        );
        setDatasets([]);
        dispatch(setComparableSwitch(false));
        return [false];
      }
    } else {
      setSwitchValue(null);
      return [false, null];
    }
  }, [agent, market, agent_id]);

  React.useEffect(() => {
    setSelectedComparableData(
      singleCma?.cma_comparables ? [...singleCma?.cma_comparables] : []
    );
  }, [singleCma?.cma_comparables]);

  React.useEffect(() => {
    if (!firstLoad) {
      getComparableData({
        id: cmaId,
        sqft_tolerance: countFoot,
        vintage_tolerance: countTolerance,
        time_frame: countTime,
        distance: countDistance,
        listingType: switchValue,
        startPrice: startPrice,
        endPrice: endPrice,
        shouldCalculateBound: true,
      });
    } else {
      setFirstLoad(false);
    }
  }, [
    countFoot,
    countTolerance,
    countTime,
    countDistance,
    startPrice,
    endPrice,
  ]);

  React.useEffect(() => {
    let comparableR = [];
    comparableData?.map((d) => {
      if (d.PropertyType !== "Residential Lease") {
        comparableR.push({
          ...d,
          id: d.ListingId,
          isSelected: false,
          price: d.MlsStatus === "Closed" ? d.ClosePrice : d.ListPrice,
          address: {
            title: `${d.StreetNumber || ""} ${d.StreetName || ""} ${
              d.StreetSuffix || ""
            }  ${d.UnitNumber || ""}`,
            subtitle: `${d.City || ""}, ${d.StateOrProvince || ""} ${
              d.PostalCode || ""
            }`,
          },
        });
      }
    });
    let selectedComparableR =
      [
        ...selectedComparableData?.map((d) => {
          return {
            ...d,
            isSelected: true,
            price: d.MlsStatus === "Closed" ? d.ClosePrice : d.ListPrice,
            address: {
              title: `${d.StreetNumber || ""} ${d.StreetName || ""} ${
                d.StreetSuffix || ""
              } ${d.UnitNumber || ""}`,
              subtitle: `${d.City || ""}, ${d.StateOrProvince || ""} ${
                d.PostalCode || ""
              }`,
            },
          };
        }),
      ] || [];

    setQueryChanged(false);
    setComparableRows(
      makeArrayUnique(
        [...comparableR, ...selectedComparableR].sort(
          (a, b) => parseInt(a.ListingId) - parseInt(b.ListingId)
        )
      )
    );
  }, [comparableData, selectedComparableData, listView]);

  React.useEffect(() => {
    let selectedComparableR =
      [
        ...selectedComparableData?.map((d) => {
          return {
            ...d,
            MediaURL:getImageUrlWithHeightAndWidth(d.MediaURL),
            isSelected: true,
            price: d.MlsStatus === "Closed" ? d.ClosePrice : d.ListPrice,
            address: {
              title: `${d.StreetNumber || ""} ${d.StreetName || ""} ${
                d.StreetSuffix || ""
              } ${d.UnitNumber || ""}`,
              subtitle: `${d.City || ""}, ${d.StateOrProvince || ""} ${
                d.PostalCode || ""
              }`,
            },
          };
        }),
      ] || [];

    setRows([...selectedComparableR]);
  }, [selectedComparableData]);
  return (
    <Container maxWidth="xl" sx={{ height: "100%", marginTop: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <fieldset className={classes.filterBox}>
            <legend className="filter-legend">Filters</legend>
            <Grid
              container
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              flexWrap={true}
              px={2}
            >
              {showSwitch && (
                <Grid item xs={12} flexShrink={0}>
                  <Box
                    display={"flex"}
                    gap={"3"}
                    alignItems={"center"}
                    mb={"10px"}
                  >
                    <span style={{ marginRight: "15px" }}>
                      {datasets[0]?.toUpperCase()}
                    </span>
                    <FormControlLabel
                      sx={{ mr: 0 }}
                      control={
                        <Switch
                          checked={comparableSwitch}
                          onChange={handleChangeFMLSwitch}
                        />
                      }
                    />
                    {datasets[1]?.toUpperCase()}
                  </Box>
                </Grid>
              )}

              <Grid item xs={2} flexShrink={0}>
                <div className={classes.bottomHead}>Square Foot Tolerance</div>
                <div className={classes.counter}>
                  <div className={classes.plus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        decreaseFoot();
                      }}
                    >
                      -
                    </p>
                  </div>
                  <div className={classes.count}>
                    <p className={classes.counterP}>
                      {countFoot ? `${countFoot}%` : "N/A"}
                    </p>
                  </div>
                  <div className={classes.minus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        increaseFoot();
                      }}
                    >
                      +
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} flexShrink={0}>
                <div className={classes.bottomHead}>Vintage Tolerance</div>
                <div className={classes.counter}>
                  <div className={classes.plus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        decreaseTolerance();
                      }}
                    >
                      -
                    </p>
                  </div>
                  <div className={classes.count}>
                    <p className={classes.counterP}>
                      {countTolerance ? `${countTolerance} Yrs` : "N/A"}
                    </p>
                  </div>
                  <div className={classes.minus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        increaseTolerance();
                      }}
                    >
                      +
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} flexShrink={0}>
                <div className={classes.bottomHead}>Time Frame</div>
                <div className={classes.counter}>
                  <div className={classes.plus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        decreaseTime();
                      }}
                    >
                      -
                    </p>
                  </div>
                  <div className={classes.count}>
                    <p className={classes.counterP}>{countTime} Mos</p>
                  </div>
                  <div className={classes.minus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        increaseTime();
                      }}
                    >
                      +
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} flexShrink={0}>
                <div className={classes.bottomHead}>Distance</div>
                <div className={classes.counter}>
                  <div className={classes.plus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        decreaseDistance();
                      }}
                    >
                      -
                    </p>
                  </div>
                  <div className={classes.count}>
                    <p className={classes.counterP}>{countDistance} Mi</p>
                  </div>
                  <div className={classes.minus}>
                    <p
                      className={classes.counterP}
                      onClick={() => {
                        increaseDistance();
                      }}
                    >
                      +
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={2.45} flexShrink={0}>
                <div className={classes.bottomHead}>Price</div>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <TextField
                    variant="outlined"
                    size="small"
                    onBlur={(e) => setStartPrice(e.target.value)}
                  />
                  <div>-</div>
                  <TextField
                    variant="outlined"
                    size="small"
                    onBlur={(e) => setEndPrice(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
        <Grid item md={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "60px",
              justifyContent: "space-between",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              sx={{ fontFamily: "AvenirNext!important" }}
            >
              {comparableRecordLoading ? (
                <>
                  <img alt="loading-img" src={LoadingIcon} />
                  <span> Loading... </span>
                </>
              ) : comparableRows.length < 1000 ? (
                `Displaying ${comparableRows.length} results`
              ) : (
                <>
                  <img alt="loading-img" src={WarningIcon} />
                  <span>
                    {" "}
                    Displaying the most recent 1000 of{" "}
                    {comparableRows.length + selectedComparableData.length}{" "}
                    results, narrow your criteria to display all results.{" "}
                  </span>
                </>
              )}
            </Box>
            <Box display={"flex"} gap={"20px"}>
              <Box
                display={"flex"}
                gap={"3"}
                alignItems={"center"}
                mb={2}
                sx={{ fontFamily: "AvenirNext!important" }}
              >
                <FormControlLabel
                  sx={{ mr: 0 }}
                  control={
                    <Switch
                      checked={listView}
                      onChange={handleChangeListView}
                    />
                  }
                />
                List View
              </Box>
              <Button
                className={classes.tabsHeaderBtn}
                onClick={() => setOpenCreateComparable(true)}
                sx={{
                  color: "#ffffff",
                  background: "#000000",
                  width: "200px!important",
                }}
                variant="outlined"
              >
                <AddCircleIcon /> Add Comparable
              </Button>
            </Box>

            {openCreateComparable && (
              <CreateComparable
                open={openCreateComparable}
                setOpen={setOpenCreateComparable}
                cmaId={params.id}
                singleCma={singleCma}
                manualCreateCMA={manualCreateCMA}
                switchValue={switchValue}
              />
            )}
          </Box>

          {!listView && (
            <ComparableMap
              handleChangeFMLSwitch={handleChangeFMLSwitch}
              comparableData={comparableData}
              bounds={mapBounds}
              queryChanged={queryChanged}
              setQueryChanged={setQueryChanged}
              mapBoundCalculate={mapBoundCalculate}
              selectedComparable={selectedComparable || []}
              selectedComparableData={selectedComparableData || []}
              handleRemoveCMAComparable={handleRemoveCMAComparable}
              addToCMASelectedComparable={addToCMASelectedComparable}
              classes={classes}
              switchValue={switchValue}
            />
          )}

          {listView && (
            <Box
              style={{
                width: "100%",
                height: 500,
              }}
              className={classes.tableCointainer}
            >
              <DataGridComponent
                columns={comparableCols || []}
                rows={comparableRows || []}
                rowHeight={80}
                pageSize={100}
                hideFooter
                loading={deleteCMALoading}
                sx={{
                  fontFamily: "AvenirNext !important",
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "600",
                  },
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item md={12} mt={"20px"}>
          <Typography mb={"10px"} sx={{ fontFamily: "AvenirNext!important" }}>
            Selected Comparables
          </Typography>
          <Box
            sx={{
              width: "100%",
              marginBottom: "30px",
              height: rows.length > 5 ? 60 + 100 * rows.length : 400,
            }}
            className={classes.tableCointainer}
          >
            <DataGridComponent
              columns={cols || []}
              rows={rows || []}
              rowHeight={80}
              pageSize={100}
              hideFooter
              loading={deleteCMALoading}
              sx={{
                fontFamily: "AvenirNext !important",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
export default withStyles(styleSheet, { name: "ComparablesStyle" })(
  Comparables
);
