export const styleSheet = () => ({
  locationUnavailableArea: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    width: "100% !important",
    height: "100% !important",
    backgroundColor: "rgba(0,0,0,.1) !important",
  },
  locationUnavailableAreaContent: {
    color: "black !important",
    fontFamily: "AvenirNext !important",
    fontSize: "16px !important",
  },
  mapStreetViewNotAvailable: {
    height: "80vh",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  notAvailableText: {
    marginTop: "20px !important",
    fontSize: "16px !important",
    textTransform: "capitalize !important",
    color: "#666666 !important",
    fontFamily: '"AvenirNextLTPro-Regular" !important',
  },
  dialogHeader: {
    height: "80px",
    borderBottom: "1px solid lightgrey",
    zIndex: "100000000000000000 !important",
  },
  dialogHeaderButtons: {
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  dialogActivePhotosButton: {
    backgroundColor: "black !important",
    color: "white !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    whiteSpace: "nowrap !important",
  },
  dialogPhotosButton: {
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    whiteSpace: "nowrap !important",
  },
  dialogHeaderCloseButton: {
    position: "absolute !important",
    top: "15px !important",
    right: "15px !important",
  },
  dialogHeaderCloseButtonIcon: {
    fontSize: "25px !important",
  },
});
