export const styleSheet = () => ({
  mTitle: {
    fontWeight: "400 !important",
    fontSize: "34px !important",
    fontFamily: "Avenir Next !important",
    padding: "10px 0 10px 24px !important",
  },
  mTabListPadding: {
    padding: "5px 0 20px 36px",
    "& .MuiButtonBase-root":{
      padding:'12px 55px  !important'
    }
  },
  mTabSelected: {
    "& .MuiTab-root.Mui-selected": {
      color: "#2A2828 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#2A2828 !important"
    },
  },
  mAccordianBorder: {
    border: "0 !important",
  },
  mAccordianSummary: {
      backgroundColor: "#fff !important"
  },
  mTabsInnerStyle: {
    display: "flex",
    alignItems: "center",
  },
  mTxtStyle: {
    paddingLeft: "15px",
  },
  mIconStyle: {
    color: "#757575",
  },
  mTurnIconStyle: {
    color: "#757575",
    transform: "rotate(180deg)",
  },
  mImgStyle: {
    paddingRight: "20px !important",
  },
  mJustifyContent:{
    display:"flex !important",
    justifyContent:"flex-end !important",
  },
  mCreateButton:{
    color: "#fff !important",
    borderRadius:"4px !important",
    backgroundColor:"#000000 !important",
    '&:disabled': {
      opacity: "0.5",
    }
  },
    
  ModalHeading:{
    fontSize:'22px !important',
    fontWeight:'500 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
    padding: "10px 0 10px 24px !important",
  },
  mCancel:{
    color: "#000000 !important",
    marginRight: "20px !important",
  },
})