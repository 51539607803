import * as React from "react";
import TemplatesList from './templateList'
import AddEmailTemplates from './addTemplate'
import EditEmailTemplates from './editTemplate'
import { Route, Routes } from "react-router-dom";

const TextTemplates = (props) => {
  return (
    <Routes>
      <Route path="/" element={<TemplatesList {...props} />} />
      <Route path="/:id/edit" element={<EditEmailTemplates {...props} />} />
      <Route path="/add" element={<AddEmailTemplates {...props} />} />
    </Routes>
  );
};

export default TextTemplates;
