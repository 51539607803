import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const updateTimelineRecordAgentRecruitment = createAsyncThunk(
  "login/updateTimelineRecordAgentRecruitment",
  async (data, thunkAPI) => {
    const { schema, path } = data;

    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/${path}`,
        { ...schema },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return {
        data: resp?.data,
        timeline_type: data.timeline_type,
        timeline_id: data.timeline_id,
      };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
