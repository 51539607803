import React from "react";
import { Box, Stack, Card, CardHeader } from "@mui/material";
import Avatar from "../../uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import avtarIcon from "../../../assets/avtar.png";
import FolderIcon from "@mui/icons-material/Folder";
import momentTZ from "moment-timezone";
import { useNavigate } from "react-router-dom";
function Documents(props) {
  let { classes,data,key,setOpenSearch } = props;
  const navigate = useNavigate();
  return (
    <Box onClick={() => {
      setOpenSearch(false);
      navigate(`/docs/${data.id}`);
    }} key={key} className={classes.peoplesArea}>
      <Card elevation={0}  sx={{ cursor: "pointer", "&:hover": { background: "#F0F0F0" },width: "657px" }}>
        <CardHeader
          avatar={<FolderIcon sx={{fontSize:'60px',color:"rgba(0, 0, 0, 0.54)"}} />}
          action={
            <Box sx={{ fontSize: "12px", lineHeight: "22px",mt:"10px" }}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                <Avatar variant="square" sx={{width:"25px !important",height:'25px !important'}} src={avtarIcon} >NA</Avatar>
                <Box>UserOrowner Lastname</Box>
              </Stack>
              Last Edited by {data?.modified_by?.first_name?(data?.modified_by?.first_name+" "+ data?.modified_by?.last_name):""} on {data?.last_modify?momentTZ.tz(data.last_modify, "America/New_York")?.format("l"):""} at {data?.last_modify?momentTZ.tz(data.last_modify, "America/New_York")?.format("LT"):""}
            </Box>
          }
          title={data.name}
          subheader={`${data?.property_city || ""}, ${data?.property_state || ""} ${data?.property_zip_code || ""}`}
        />
      </Card>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "DocumentsStyle" })(Documents);
