import { createSlice } from "@reduxjs/toolkit";
import { getTextMessages } from "./getTextMessages";
import { getAlerts } from "./getAlerts";
const initialState = {
  messages: {
    success: false,
    data: undefined,
    count: 0,
    isLoading: false,
    errMsg: null,
  },
  alerts: {
    success: false,
    data: undefined,
    count: 0,
    isLoading: false,
    errMsg: null,
  },
};
const MessagesAndAlerts = createSlice({
  name: "MessagesAndAlerts",
  initialState,
  reducers: {
    addNewMessage: (state, action) => {
      let messages = state.messages.data ? [...state.messages.data] : [];
      messages.unshift(action.payload);
      state.messages.data = messages;
    },
    addNewAlert: (state, action) => {
      let alerts = state.alerts.data ? [...state.alerts.data] : [];
      alerts.unshift(action.payload);
      state.alerts.data = alerts;
    },
    removeAlert: (state, action) => {
      let alerts = state.alerts.data ? [...state.alerts.data] : [];
      state.alerts.data = alerts.filter(
        (item) => item.id !== action.payload.id
      );
    },
    removeMessage: (state, action) => {
      let messages = state.messages.data ? [...state.messages.data] : [];
      state.messages.data = messages.filter(
        (item) => item.id !== action.payload.id
      );
    },
  },
  extraReducers: {
    [getTextMessages.pending]: (state) => {
      state.messages.isLoading = true;
      state.messages.errMsg = null;
      state.messages.success = false;
    },
    [getTextMessages.fulfilled]: (state, action) => {
      state.messages.success = true;
      state.messages.isLoading = false;
      if (action.payload?.pageNumber > 1 || action.payload?.isNewMessage) {
        state.messages.data = state.messages.data
          .concat(action.payload.data)
          .filter(
            (item, index, self) =>
              self.findIndex((it) => it.id === item.id) == index
          );
      } else {
        state.messages.data = action.payload.data;
      }
      state.messages.count = action.payload.count;
    },
    [getTextMessages.rejected]: (state, action) => {
      state.messages.isLoading = false;
      state.messages.success = false;
      state.messages.errMsg = action.payload;
    },
    [getAlerts.pending]: (state) => {
      state.alerts.isLoading = true;
      state.alerts.errMsg = null;
      state.alerts.success = false;
    },
    [getAlerts.fulfilled]: (state, action) => {
      state.alerts.success = true;
      state.alerts.isLoading = false;
      if (action.payload?.pageNumber > 1 || action.payload?.isNewAlert) {
        state.alerts.data = state.alerts.data
          .concat(action.payload.data)
          .filter(
            (item, index, self) =>
              self.findIndex((it) => it.id === item.id) == index
          );
      } else {
        state.alerts.data = action.payload.data;
      }
      state.alerts.count = action.payload.count;
    },
    [getAlerts.rejected]: (state, action) => {
      state.alerts.isLoading = false;
      state.alerts.success = false;
      state.alerts.errMsg = action.payload;
    },
  },
});
export const { addNewMessage, addNewAlert, removeAlert, removeMessage } =
  MessagesAndAlerts.actions;
export default MessagesAndAlerts.reducer;
export * from "./getTextMessages";
export * from "./getAlerts";
