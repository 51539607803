import React from "react";
import { Box, Skeleton, Grid, Card, Stack } from "@mui/material";
function SearchKnowledgeSkelton(props) {
  return (
    <Box>
      <Skeleton animation="wave" height={50} width="250px" />
      <Grid container spacing={2}>
        <Grid item sm={12} md={4}>
          {" "}
          <Skeleton animation="wave" height={320} sx={{ mb: "-50px", mt: "-50px" }} width="100%" />
        </Grid>
        <Grid item sm={12} md={4}>
          {" "}
          <Skeleton animation="wave" height={320} sx={{ mb: "-50px", mt: "-50px" }} width="100%" />
        </Grid>
        <Grid item sm={12} md={4}>
          {" "}
          <Skeleton animation="wave" height={320} sx={{ mb: "-50px", mt: "-50px" }} width="100%" />
        </Grid>
        <Grid item sm={12} md={4}>
          {" "}
          <Skeleton animation="wave" height={320} sx={{ mb: "-50px", mt: "-80px" }} width="100%" />
        </Grid>
        <Grid item sm={12} md={4}>
          {" "}
          <Skeleton animation="wave" height={320} sx={{ mb: "-50px", mt: "-80px" }} width="100%" />
        </Grid>
        <Grid item sm={12} md={4}>
          {" "}
          <Skeleton animation="wave" height={320} sx={{ mb: "-50px", mt: "-80px" }} width="100%" />
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <Card sx={{ padding: "20px" }}>
            <Stack direction={"row"} spacing={2}>
              <Skeleton animation="wave" height={40} variant="circular" width="40px" />
              <Skeleton animation="wave" height={40} width="250px" />
            </Stack>
            <br />
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
          </Card>
        </Grid>
        <Grid item sm={12} md={6}>
          <Card sx={{ padding: "20px" }}>
            <Stack direction={"row"} spacing={2}>
              <Skeleton animation="wave" height={40} variant="circular" width="40px" />
              <Skeleton animation="wave" height={40} width="250px" />
            </Stack>
            <br />
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
          </Card>
        </Grid>
        <Grid item sm={12} md={6}>
          <Card sx={{ padding: "20px" }}>
            <Stack direction={"row"} spacing={2}>
              <Skeleton animation="wave" height={40} variant="circular" width="40px" />
              <Skeleton animation="wave" height={40} width="250px" />
            </Stack>
            <br />
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
          </Card>
        </Grid>
        <Grid item sm={12} md={6}>
          <Card sx={{ padding: "20px" }}>
            <Stack direction={"row"} spacing={2}>
              <Skeleton animation="wave" height={40} variant="circular" width="40px" />
              <Skeleton animation="wave" height={40} width="250px" />
            </Stack>
            <br />
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{  mb: "7px" }}>
              <Skeleton animation="wave" height={20} variant="circular" width="20px" />
              <Skeleton animation="wave" height={20} width="80%" />
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
export default SearchKnowledgeSkelton;
