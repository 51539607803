import React from "react";
import { Routes, Route } from "react-router-dom";
import AgentsListing from "./agentListing";
import AgentRosterDetails from "./agentDetails";

const AgentRoster = () => {
  return (
    <Routes>
      <Route path="/" element={<AgentsListing />} />
      <Route path="/:id/*" element={<AgentRosterDetails />} />
    </Routes>
  );
};
export default AgentRoster;