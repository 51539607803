import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          left: 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function ButtonDropdown(props) {
  let {
    classes,
    options,
    value,
    placeHolder,
    handleSelect,
    startIcon,
    disabled,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectValue = (val) => {
    handleSelect(val);
    handleClose();
  };
  return (
    <div>
      <Button
        id="button-general-dropdown"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className={classes.dateDropdownButtonStyle}
        disableElevation
        onClick={handleClick}
        endIcon={disabled?null:open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        startIcon={startIcon && value?.title !== "Nothing" ? startIcon : ""}
        sx={{ whiteSpace: "nowrap" }}
        disabled={disabled}
      >
        {value?.title ? value?.title : placeHolder || ""}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ maxHeight: "320px", overflowY: "auto" }}>
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {options.map((item, value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem
                  onClick={() => handleSelectValue(item)}
                  key={value}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText id={labelId} primary={item.title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </StyledMenu>
    </div>
  );
}
export default withStyles(styleSheet, { name: "ButtonDropdownStyle1" })(
  ButtonDropdown
);
