import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getUserPasswords = createAsyncThunk("AgentPasswords/getUserPasswords", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/users/passwords/${data.id}?pageNumber=${data.pageNumber || 1}&pageSize=${data.pageSize || 15}&orderBy=website_title&sortOrder=asc`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { ...resp.data, pageNumber: data.pageNumber };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
