import React from 'react';
import Avatar from '@mui/joy/Avatar';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function AvatarComponent(props) {
  function stringAvatar(props) {
    let n = props.nameInitial ? props.nameInitial.split(' '):props.name.split(' ');
    let initial = '';
    if (n.length === 1) {
      initial = n[0][0];
    } else {
      initial = `${n[0][0]}${n[1][0]}`;
    }
    return {
      sx: { fontSize: '17px', background:"#BDBDBD", color: '#fff', fontWeight: '600', ...props.sx },
      children: initial,
    };
  }
 
  return ( 
    props.src ?
      props.title? 
      <LightTooltip title={props.title}>          
        <Avatar {...props} src={props.src} />
      </LightTooltip> : <Avatar {...props} src={props.src} />
    :
    props.nameInitial?
      props.title?
        <LightTooltip title={props.title}>          
          <Avatar {...stringAvatar(props)} />
        </LightTooltip> :
        <Avatar {...stringAvatar(props)} />
      : null
  );
}
