import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getInventorySpecificLogHistory = createAsyncThunk(
  "agentInventory/getInventorySpecificLogHistory",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/inventory/logs?inventory_id=${data.id}&agent_id=${data?.agent_id || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

