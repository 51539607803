import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getActivitiesStats = createAsyncThunk(
  "leads/getActivitiesStats",
  async (data, thunkAPI) => {
    try {
      let headers = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      };
      if (data.params) {
        headers["params"] = data.params;
      }
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/leads/events/average/${data?.personId}`,
        headers
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
