import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { updateTransactionContract } from "../../../redux/transactions/updateTransactionContract";
import momentTZ from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "25px!important",
    margin: "5px 0px!important",
  },
  field: {
    border: "none!important",
    borderBottom: "1px solid #000000DE!important",
  },
  autocompleteOption: {
    padding: "15px",
    margin: "10px",
    fontFamily: "AvenirNext !important",
  },
  optionHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px",
  },
  optionHeaderSpan: {
    fontFamily: "AvenirNext !important",
    fontSize: "14px",
  },
  optionSubHeaderLeft: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
  optionSubHeaderRight: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
}));

function EditPriceModal(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const muiClasses = useStyles();

  let { open, classes, ContractData, setOpen } = props;

  const [errorAlert, setErrorAlert] = React.useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [formData, setFormData] = React.useState({});
  const updateContract = useSelector((state) => state.transactions.TransactionsContract.updateContract);

  React.useEffect(() => {
    return () => {
      setFormData({});
    };
  }, []);

  React.useEffect(() => {
    setFormData({
      price: ContractData.price,
      earnest_money_amount: ContractData.earnest_money_amount,
    });
  }, [ContractData]);

  React.useEffect(() => {
    if (updateContract?.success && !updateContract.isLoading && open) {
      setErrorAlert({ errorMsg: "You have successfully updated the price", errorType: "success", isOpen: true });
      setOpen(false);
    }
  }, [updateContract]);

  const handleUpdateDate = () => {
    dispatch(updateTransactionContract({ id, contractData: { ...ContractData, ...formData } }));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="edit-important-date-dialog-title"
        aria-describedby="edit-important-date-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={muiClasses.modalHeader} id="edit-important-date-dialog">
          Edit Price
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Price"
                variant="standard"
                value={formData.price}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, price: e.target.value }))}
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                label="EM Amount"
                variant="standard"
                value={formData.earnest_money_amount}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, earnest_money_amount: e.target.value }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleUpdateDate()}
            classes={{ root: classes.saveModalButton }}
            color="inherit"
            autoFocus
            loading={updateContract.isLoading}
            loadingPosition="start"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNoteModalStyle" })(EditPriceModal);
