import { makeStyles } from "@mui/styles";

export const styleSheet = () => ({
chatListBtn: {
    border: '1px solid rgba(33, 33, 33, 0.4)',
    borderRadius: '4px',
    backgroundColor: 'pink'
  },
  listMenu: {
    background: 'red',
  },
  customButtonGroup: {
    position: "absolute",
    right: "19px",
    bottom: "15px",
    "& button": {
      height: "40px",
      fontFamily: "AvenirNext",
      fontSize: "15px",
      width: "127px !important",
      "&.MuiButton-text": {
        color: "rgba(11, 9, 9, 1)",
      },
      "&.MuiButton-contained": {
        backgroundColor: "rgba(11, 9, 9, 1)",
      },
      "&:first-of-type": {
        marginRight: "10px",
      },
    },
  },
  dateWrap: {
    display: "flex",
     fontSize:'13px !important'
  },
  Sidebar: {
    background: " #FFFFFF",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
    width: "372px",
    minWidth: "372px",
    height: "calc(100vh - 80px)",
    overflow: "auto",
    margin: "7px 7px 0",
    display: "flex",
    flexDirection: "column",
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customBtnGroup: {
    display: "flex",
    border: "1px solid rgba(33, 33, 33, 0.4)",
    borderRadius: "4px",
    marginRight: "12px",
    "& button": {
      "& active": {
        background: "rgba(33, 33, 33, 0.04)",
      },
      background: "white",
      borderRadius: "4px",
      fontWeight: "500",
      fontSize: "15px",
      padding: "8px 22px",
      lineHeight: "26px",
      textTransform: "uppercase",
      color: "#0B0909",
      "&:nth-of-type(2)": {
        borderRight: "1px solid rgba(33, 33, 33, 0.4)",
        borderLeft: "1px solid rgba(33, 33, 33, 0.4)",
        borderRadius: 0,
      },
    },
  },
  listMenuWrapper: {
    "& .MuiMenu-list": {
      maxHeight: "450px",
      padding: 0,
      overflow: "auto",
      "& span": {
        padding: "10px 10px 0",
        borderBottom: "1px solid rgb(241, 244, 248)",
        fontWeight: "bold",
        width: "100%",
        lineHeight: "40px",
        fontSize: "11px",
        color: "rgb(109, 130, 145)",
        textTransform: "uppercase",
        display: "flex",
      },
      "& .MuiMenuItem-root": {
        "& .MuiAvatar-root": {
          width: "26px",
          height: "26px",
        },
        "&:hover": {
          background: "rgba(64, 162, 217, 0.1)",
        },
      },
    },
  },
  chatBodyContent: {
    height: "calc(100vh - 220px)",
    background: "transparent",
    overflow: "auto",
    padding: "5px",
  },
  chatWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  senderData: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "600px",
  },
  recieverData: {
    width: "100%",

    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  senderList: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    background: "#fff",
    border: "1px solid #E0E0E0",
    borderRadius: "6px",
    marginTop: "22px",
    padding: "8px 16px",

    "& .MuiAvatar-circular": {
      marginRight: 16,
    },
  },
  recieverList: {
    width: "100%",
    maxWidth: "600px",
    display: "inline-flex",
    alignItems: "center",
    background: "#fff",
    border: "1px solid #E0E0E0",
    borderRadius: "6px",
    marginTop: "22px",
    padding: "8px 16px",
    "& div": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .MuiAvatar-circular": {
      marginLeft: 16,
      minWidth: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  iconAvatar: {
    display: "flex",
    flex: 1,
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    background: "#FFFFFF",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
    padding:"10px 15px",
    marginTop:'5px',
    "& .MuiInput-root:after":{
     border:"none !important"
    },
    "& .MuiInput-root:before":{
      border:"none !important"
     }
  },
  iconList: {
    position: "absolute",
    right: 15,
    display: "flex",
    alignItems: "center",
  },
  iocnGroup: {
    marginRight: 22,
    display: "flex",
    "& svg": {
      fill: "rgba(0, 0, 0, 0.54)",
    },
  },
  btnSend: {
    borderRadius: "4px",
    border: "1px solid rgba(33, 33, 33, 0.4)",
    background: "#0B0909 !important",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    color: "#fff !important",
    fontWeight: "400",
    fontSize: "13px !important",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: "uppercase",
  },
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "7px",
  },
})

export const useStyles = makeStyles(() => ({
  mainWrapper: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  userInfoDetail: {
    width: "437px",
    minWidth: "437px",
    padding: "7px 7px 0",
    height: "calc(100vh - 80px)",
    overflow: "auto",
    "& .MuiListItem-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-circular": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        position: "unset",
      },
    },
  },
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "7px",
  },
  infoListItem: {
    display: "flex",
    flexDirection: "column",
  },
  headText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    margin: 0,
    color: "rgba(0, 0, 0, 0.87)",
  },
  subChildText: {
    margin: 0,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "143%",
    color: "rgba(0, 0, 0, 0.60)",
  },
  userName: {
    display: "flex",
    flexDirection: "column",
  },

  activityBtn: {
    padding: "0 24px 12px",
    display: "flex",
    "& button": {
      borderRadius: "4px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "uppercase",
      "&:nth-of-type(1)": {
        border: "1px solid rgba(33, 33, 33, 0.4)",
        background: "#fff",
        color: "#0B0909",
      },
      "&:nth-of-type(2)": {
        border: "1px solid rgba(33, 33, 33, 0.4)",
        background: "#0B0909",
        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
        color: "#fff",
        marginLeft: "30px",
      },
    },
  }, dateWrap: {
    display: "flex",
    alignItems: "center",
  },
  chatNotStartedArea:{
    display:"flex",
    justifyContent:'center',
    alignItems:'center',
    height:'70vh',
    width:'100%'
  },
  chatNotStartedBox:{
    color: "rgba(0, 0, 0, 0.60)",
    fontWeight: "500",
  }
}));