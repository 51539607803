import React,{useState} from "react";
import { Routes, Route } from "react-router-dom";
import PhoneNumbersList from './phoneNumbers'
import PhoneExtensions from "./phoneExtensions"
import PhoneQueues from "./phoneQueue"
import { TabContext } from "@mui/lab";
import { styleSheet } from "./phoneNumbers/style";
import { withStyles } from "@mui/styles";
import { Box, Tabs, Tab, Card, Button ,useMediaQuery} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import TabMenu from './TabMenu';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import PhoneAudios from "./phoneAudio"

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const PhoneConfiguration = (props) => {
  const [search,setSearch]= useState(false);
  let{classes}=props
  const location = useLocation();
  const makeDropDown = useMediaQuery("(max-width:1024px)");
  
  return (
    <React.Fragment>
     <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
        {" "}
        <Box className={classes.TopUsersBarArea}>
          <Box className={classes.TopUsersBarAreaLeft}>
            <Box>
              <TabContext>
                <Box className={classes.UsersTabsUI}>
                  {!makeDropDown && (
                    <Tabs
                     value={location.pathname}
                     TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                     sx={{
                      display: makeDropDown? 'none' : '',
                      "& .MuiTabScrollButton-horizontal": {
                        color: "#000!important",
                      },
                    }}
                   >
                      <Tab label="Phone Numbers" value={`/phone-configuration/phone-numbers`} to={`/phone-configuration/phone-numbers`} component={Link} sx={{ padding: "0px 10px" }} />
                      <Tab label="Extensions" value={`/phone-configuration/extensions`} to={`/phone-configuration/extensions`} component={Link} sx={{ padding: "0px 10px" }} />
                      <Tab label="Queues" value={`/phone-configuration/queues`} to={`/phone-configuration/queues`}  component={Link} sx={{ padding: "0px 10px" }} />
                      <Tab label="Audio" value={`/phone-configuration/audio`} to={`/phone-configuration/audio`}  component={Link} sx={{ padding: "0px 10px" }} />
                    </Tabs>
                  )}
                  {makeDropDown && (
                      <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                      variant="scrollable"
                      aria-label="Users-tabs"
                      sx={{ justifyContent: "space-around" }}
                    >
                    <TabMenu />
                    </Tabs>
                  )}
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopUsersBarAreaLeft}>
            {" "}
            <Search>
              <StyledInputBase onChange={(e)=>setSearch(e.target.value)}  placeholder="Search Phone Number" inputProps={{ "aria-label": "search" }} />
            </Search>
          </Box>
        </Box>
      </Card>
    <Routes>
      <Route path="/phone-numbers" element={<PhoneNumbersList />} />
      <Route path="/extensions" element={<PhoneExtensions />} />
      <Route path="/queues" element={<PhoneQueues />} />
      <Route path="/audio" element={<PhoneAudios />} />
    </Routes>
    </React.Fragment>
  );
};
export default withStyles(styleSheet, { name: "PhoneConfigurationStyle" })(PhoneConfiguration);