import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getEmailTemplatesAgentsRecruitment = createAsyncThunk(
  "emailTemplates/getEmailTemplatesAgentsRecruitment",
  async (data, thunkAPI) => {
    let type = data.type ? data.type : "";
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/templates?type=${type}&search=${data.search?data.search:""}&limit=${data.limit || 100}&offset=${data.offset || 0}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return {templates:resp.data.templates,offset:data.offset,search:data.search?data.search:null};
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
