export const styleSheet = (theme) => ({
  overViewTabCardMain: {
    marginBottom: "16px",
    padding: "0px 20px 15px 20px",
    "& .MuiStepConnector-line": {
      borderColor: "#BDBDBD !important",
      marginLeft: "75px !important",
      borderWidth: "2px !important",
    },
  },
  overViewTabCardHeading: {
    fontSize: "26px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  overViewTabCardDes: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)  !important",
  },
  overViewTabCard: {
    padding: "15px",
    marginBottom: "20px",
  },
  overViewTabCardPeoples: {
    padding: "5px",
    marginBottom: "5px",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentTableButton: {
    fontSize: "12px  !important",
    borderRadius: "9px  !important",
    minWidth: "80px !important",
  },
  importantNotesDes: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    marginTop: "10px  !important",
  },
  importantPeoplesDes: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  importantNotesHeader: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    fontFamily: "AvenirNext  !important",
    maxWidth: "40ch !important",
    textOverflow: "ellipsis !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
  },

  overViewTabCardDesBottom: {
    textAlign: "right",
    marginTop: "25px",
    "& .MuiButton-root": {
      width: "25px!important",
      minWidth: "25px!important",
      height: "25px",
      backgroundColor: "white",
      color: "black",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid rgba(33, 33, 33, 0.4) !important",
    },
  },
  importantPeopleName: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    width: "20ch !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  importantPeopleNameMD: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    width: "16ch !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  importantTasksDetailHeader:{
    fontSize: "15px !important",
  },
  importantNotesCard: {
    marginBottom: "20px",
    padding: "0px 15px",
    border: "1px solid rgba(33, 33, 33, 0.4)  !important",
  },
  noteDatesText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    marginLeft: "-7px !important",
  },
  importantNotesContent: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.6) !important",
    padding: "0px  !important",
  },
});
