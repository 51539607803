import React from "react";
import { Box, Stack, Chip } from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import houseIcon from "../../../assets/house.png";
import momentTZ from "moment-timezone";
import { useNavigate } from "react-router-dom";
function CMAs(props) {
  let { classes,data,key,setOpenSearch } = props;
  const navigate = useNavigate();
  return (
    <Box  onClick={() => {
      setOpenSearch(false);
      navigate(`/tools/cmas/${data.id}`);
    }} key={key} className={classes.CMAsArea}>
      <Box className={classes.listingAreaLeft}>
        <Box className={classes.listingAreaLeftImg}>
          <img src={houseIcon} alt="houseIcon" />
        </Box>
        <Box className={classes.listingAreaLeftContent}>
          <Box className={classes.listingAreaLeftContentHead}> {data?.StreetNumber} {data?.StreetName}   {data?.StreetSuffix}</Box>
          <Box className={classes.listingAreaLeftContentAdd}>{data?.City}, {data?.StateOrProvince} {data?.PostalCode}</Box>
          <Box className={classes.listingAreaLeftContentAdd} sx={{ mt: "15px" }}>
            Last Edited by {data?.users_v2?.first_name} {data?.users_v2?.last_name} on {data?.last_activity?momentTZ.tz(data.last_activity, "America/New_York")?.format("l"):""} at {data?.last_activity?momentTZ.tz(data.last_activity, "America/New_York")?.format("LT"):""}
          </Box>
        </Box>
      </Box>
      <Box className={classes.listingAreaRight}>
        <Box className={classes.listingAreaRightBottom}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.cmasAreaRightStatTop}>{data?.comparables || "0"}</Box>
              <Box className={classes.listingAreaRightStatBottom} sx={{ textAlign: "center" }}>
                Comps
              </Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.cmasAreaRightStatTop}>${ new Intl.NumberFormat().format(data?.averageValue || "0")}</Box>
              <Box className={classes.listingAreaRightStatBottom} sx={{ textAlign: "center" }}>
                Avg Value
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ListingsPowerStyle" })(CMAs);
