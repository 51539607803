import React from "react";
import { Box, Stack, Paper, ListItemText, Chip, Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import momentTZ from "moment-timezone";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getAllListings } from "../../../../../redux/listings/listings";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import Avatar from "../../../../../components/uiElements/avatar/index";
import TableSkeleton from "../../../../../components/loader/tableLoader"  

function ListingsTable(props) {
  let { classes, loading, filters } = props;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const listDetail = useSelector((state) => state.listings?.FMLSListDetail?.newSearchDetail);
  const ListingsAll = useSelector((state) => state.listings.ListingsData.ListingsAll);
  const redirectDetails = (ListingId) => {
    navigate(`/listings/fmls/${ListingId}`);
  };
  const columns = [
    {
      field: "Image",
      headerName: "Image",
      renderCell: (params) => {
        return (
          <Avatar
            variant="rounded"
            src={params?.row?.Media[0]?.MediaURL}
            sx={{ width: "99px", height: "75px", padding: "3px 0px", objectFit: "contain", borderRadius: "5px", "& img": { borderRadius: "5px" } }}
          />
        );
      },
      sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Address",
      renderCell: (params) => {
        return (
          <ListItemText
            primary={`${params?.row?.StreetNumber || ""} ${params?.row?.StreetName || ""} ${params?.row?.StreetSuffix || ""}`}
            secondary={`${params?.row?.City || ""} ${params?.row?.StateOrProvince || ""} ${params?.row?.PostalCode || ""}`}
          />
        );
      },
      sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "ListAgentFullName",
      headerName: "Agent",
      renderCell: (params) => {
        // console.log(params);
        return params.row?.ListAgentFullName ? (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Avatar sx={{ background: "#807979" }} src={params.row?.agent?.profile_images?.profile_img_thumbnail} variant="rounded">
              {params?.row?.ListAgentFullName?.slice(0, 1) + params?.row?.ListAgentFullName?.split(" ")[1]?.slice(0, 1)}
            </Avatar>
            <ListItemText primary={params?.row?.ListAgentFullName} secondary={params?.row?.ListOfficeName} />
          </Stack>
        ) : null;
      },
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "Stats",
      headerName: "Stats",
      renderCell: (params) => {
        return (
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{params?.row?.DaysOnMarket || "0"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>DOM</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{params?.row?.BedroomsTotal || "0"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Beds</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{params?.row?.BathroomsFull || "0"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Baths</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{new Intl.NumberFormat().format(params?.row?.BuildingAreaTotal || "0")}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Sq. Feet</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{params?.row?.YearBuilt || "--"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Yr. Built</Box>
            </Box>
          </Stack>
        );
      },
      sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      width: 270,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      align: "left",
      renderCell: (params) => {
        return (
          <CustomTooltip title={params.row.MlsStatus}>
            <Chip
              size="small"
              label={params.row.MlsStatus === "Active Under Contract" ? "ActiveUC" : params.row.MlsStatus || "Active"}
              sx={{
                color:
                  params.row.MlsStatus === "Expired"
                    ? "#FF9800"
                    : params.row.MlsStatus === "Canceled"
                    ? "orange"
                    : params.row.MlsStatus === "Active"
                    ? "#2E7D32"
                    : params.row.MlsStatus === "Pending"
                    ? "#81C784"
                    : params.row.MlsStatus === "Closed"
                    ? "#C62828"
                    : "#2E7D32",
                borderColor:
                  params.row.MlsStatus === "Expired"
                    ? "#FF9800"
                    : params.row.MlsStatus === "Canceled"
                    ? "orange"
                    : params.row.MlsStatus === "Active"
                    ? "#2E7D32"
                    : params.row.MlsStatus === "Pending"
                    ? "#81C784"
                    : params.row.MlsStatus === "Closed"
                    ? "#C62828"
                    : params.row.MlsStatus === "Withdrawn"
                    ? "#607D8B"
                    : "#2E7D32",
              }}
              variant="outlined"
            />
          </CustomTooltip>
        );
      },
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "Date",
      headerName: "Date",
      renderCell: (params) => {
        // console.log(params);
        return (
          <ListItemText
            primary={
              params?.row?.MlsStatus === "Expired"
                ? momentTZ.tz(params?.row?.ExpirationDate, "America/New_York")?.format("l")
                : params?.row?.MlsStatus === "Pending"
                ? momentTZ.tz(params?.row?.PurchaseContractDate, "America/New_York")?.format("l")
                : params?.row?.MlsStatus === "Active"
                ? momentTZ.tz(params?.row?.ListingContractDate, "America/New_York")?.format("l")
                : params?.row?.MlsStatus === "Closed"
                ? momentTZ.tz(params?.row?.CloseDate, "America/New_York")?.format("l")
                : params?.row?.MlsStatus === "Active Under Contract"
                ? momentTZ.tz(params?.row?.PurchaseContractDate, "America/New_York")?.format("l")
                : params?.row?.MlsStatus === "Withdrawn"
                ? momentTZ.tz(params?.row?.WithdrawnDate, "America/New_York")?.format("l")
                : "mm/dd/yy"
            }
            secondary={
              params?.row?.MlsStatus === "Expired"
                ? "Expiration"
                : params?.row?.MlsStatus === "Active"
                ? "Listed"
                : params?.row?.MlsStatus === "Active Under Contract"
                ? "Pending"
                : params?.row?.MlsStatus
            }
          />
        );
      },
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Price",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Box>
            $
            {params.row.MlsStatus === "Closed"
              ? new Intl.NumberFormat().format(params.row?.ClosePrice || "0")
              : new Intl.NumberFormat().format(params.row?.ListPrice || "0")}
          </Box>
        );
      },
      width: 110,
      editable: false,
      sortable: false,
    },
  ];
  return (
    <Container className={classes.transactionsStatsArea} maxWidth="xl">
      <Box>
        {" "}
        <br />
        <Paper className={classes.tableRoot}>
          <DataGridPro
            rows={listDetail.data?.value ? listDetail.data?.value?.map((item, index) => ({ ...item, id: index })) : []}
            columns={columns}
            loading={loading}
            rowHeight={80}
            onRowClick={(e) => {
              redirectDetails(e.row?.ListingId);
            }}
            components={{
              LoadingOverlay: TableSkeleton
            }}
            onRowsScrollEnd={(e) => {
              console.log(ListingsAll.count);
              if (ListingsAll.count > e.virtualRowsCount && !ListingsAll.isLoading) {
                dispatch(
                  getAllListings({
                    ...filters,
                    pageNumber: Number(e.virtualRowsCount / 25) + 1,
                    pageSize: 25,
                  })
                );
              }
            }}
          />
        </Paper>
      </Box>
    </Container>
  );
}

export default withStyles(styleSheet, { name: "ListingsTableFmlsStyle" })(ListingsTable);
