import React, { useState } from "react";
import { Card, CardHeader, Button, Grid, IconButton, Typography } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/Edit";
import EditFeatures from "./components/editFeatures";
function TransactionsDetailFeatures(props) {
  const [open, setOpen] = useState(false);
  let { classes, publicDataDetail } = props;

  const handleGetCategories = (data) => {
    return data.filter((value, index, self) => index === self.findIndex((t) => t.listing_category.id === value.listing_category.id));
  };

  return (
    <React.Fragment>
      <Card className={classes.overViewTabCardMain}>
        <CardHeader
          classes={{ title: classes.overViewTabCardHeading }}
          action={
            publicDataDetail?.features?.length ? (
              <IconButton onClick={() => setOpen(true)}>
                <EditIcon size="small" />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#0B0909",
                  "&:hover": { backgroundColor: "#0f0a0a" },
                  color: "white",
                  fontWeight: "400",
                  mr: "10px",
                  mt: "10px",
                }}
                startIcon={<AddRoundedIcon sx={{ mr: "-5px", mt: "-3px" }} />}
                onClick={() => setOpen(true)}
              >
                Add
              </Button>
            )
          }
          sx={{ paddingLeft: "0px", paddingRight: "0px", mb: "12px" }}
          title="Features"
        />
        <Grid container spacing={2}>
          {publicDataDetail?.features?.length
            ? handleGetCategories(publicDataDetail?.features)?.map((item) => (
                <Grid item sm={12} md={6}>
                  <Typography sx={{ fontSize: "15px" }}>
                    <Typography sx={{ fontWeight: "600", fontFamily: "AvenirNext", display: "inline-block" }}>
                      {item?.listing_category?.name}:
                    </Typography>{" "}
                    {publicDataDetail?.features
                      .filter((it) => item.listing_category.id === it.listing_category.id)
                      ?.map((val) => val.name)
                      ?.join(", ")}
                  </Typography>
                </Grid>
              ))
            : null}
        </Grid>
      </Card>
      <EditFeatures open={open} setOpen={setOpen} {...props} />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "TTransactionsDetailMatterPortStyle" })(TransactionsDetailFeatures);
