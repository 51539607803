import * as React from "react";
import { Box, Typography, Grid, MenuItem, Card, TextField } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";

const ReferralInformation = (props) => {
  let { classes, updateContractDetail, contractDetail, errMsg } = props;
  const handleReturnField = (index, field) => {
    return `buyer${index}_${field}`;
  };
  return (
    <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible" }}>
      <Typography classes={{ root: classes.transactionFormCardHeading }}>Referral Information</Typography>
      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail[handleReturnField(0, "type")] || ""}
            onChange={(e) => updateContractDetail(handleReturnField(0, "type"), e.target.value)}
            fullWidth
            variant="standard"
            select
            helperText="Type"

          >
            <MenuItem value="person">Person</MenuItem>
            <MenuItem value="entity">Entity</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            value={contractDetail[handleReturnField(0, "first_name")] || ""}
            onChange={(e) => updateContractDetail(handleReturnField(0, "first_name"), e.target.value)}
            fullWidth
            variant="standard"
            helperText="Client First Name"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail[handleReturnField(0, "middle_initial")] || ""}
            onChange={(e) => updateContractDetail(handleReturnField(0, "middle_initial"), e.target.value)}
            fullWidth
            variant="standard"
            helperText="Middle Initial"
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            value={contractDetail[handleReturnField(0, "last_name")] || ""}
            onChange={(e) => updateContractDetail(handleReturnField(0, "last_name"), e.target.value)}
            fullWidth
            variant="standard"
            helperText="Client Last Name"
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            value={contractDetail.closing_date}
            onChange={(e) => updateContractDetail("closing_date", e.target.value)}
            fullWidth
            variant="standard"
            type={"date"}
            helperText="Closing Date"
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            value={contractDetail.price}
            onChange={(e) => updateContractDetail("price", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="$112.5"
            helperText="Price"
            error={errMsg["price"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={4} md={4}>
          <TextField
            value={contractDetail.street}
            onChange={(e) => updateContractDetail("street", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="123 Main Street"
            helperText="Property Address"
            error={errMsg["street"]}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.city}
            onChange={(e) => updateContractDetail("city", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="Atlanta"
            helperText="City"
            error={errMsg["city"]}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.state}
            onChange={(e) => updateContractDetail("state", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="GA"
            helperText="State"
            error={errMsg["state"]}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            fullWidth
            value={contractDetail.zipcode}
            onChange={(e) => updateContractDetail("zipcode", e.target.value)}
            variant="standard"
            placeholder="30080"
            helperText="Zip"
            error={errMsg["zipcode"]}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default withStyles(styleSheet, { name: "ReferralInformationStyle" })(ReferralInformation);
