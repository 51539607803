import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Card, CardHeader, Box } from "@mui/material";
import agentEquipmentIcon from "../../../../assets/images/agents/agentEquipmentIcon.png";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponseAlert from "../../../../components/responseAlert";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import Loading from "../../../../components/shared/loading";

import { checkoutAgentInventory } from "../../../../redux/agents/agentRosterDetail/inventory";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { getAgentInventories } from "../../../../redux/agents/agentRosterDetail/inventory/getAgentInventory";

function AgentCheckOutEquipmentModal(props) {
  const [selectedInventory, setSelectedInventory] = useState("");
  const [search, setSearch] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const checkOut = useSelector((state) => state.agentRoster.agentInventory.checkOut);
  let { open, setOpen, classes, inventoryData, InventoryList, filter, setFilter } = props;

  const checkIfAlreadyAttached = (id) => {
    let IsAttached = inventoryData.data?.find((item) => Number(item.id) === Number(id));
    if (IsAttached) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (checkOut.success && !checkOut.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully checkout the equipment",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setSelectedInventory("");
      dispatch(getAgentInventories({ id }));
    } else if (!checkOut.isLoading && checkOut.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(checkOut.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [checkOut.isLoading]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setFilter({ ...filter, search }), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);
  const handleValidate = () => {
    if (selectedInventory) {
      return true;
    } else {
      setErrorAlert({
        errorMsg: "Please select inventory item first",
        errorType: "warning",
        isOpen: true,
      });
      return false;
    }
  };
  const handleCheckoutEquipment = () => {
    if (handleValidate()) {
      let obj = {
        InventoryId: selectedInventory,
        AgentId: id,
      };
      dispatch(checkoutAgentInventory(obj));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.paymentModalHeading} id="alert-dialog-title">
          Check Out Equipment
        </DialogTitle>
        <DialogContent sx={{ width: "600px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={6}>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Search
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder="Search Equipment by Name or Serial Number"
                variant="outlined"
                size="small"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Equipment
              </InputLabel>
              <Card variant="outlined" className={classes.EquipmentDetailBOx}>
                <Grid container spacing={2}>
                  {InventoryList.isLoading ? (
                    <Grid item xs={12} sx={{ zIndex: "1" }}>
                      <Box className={classes.loadingArea}>
                        <Loading />
                      </Box>
                    </Grid>
                  ) : inventoryData.length > 0 ? (
                    inventoryData?.map((item) => (
                      <Grid item xs={6} sx={{ zIndex: "1" }}>
                        <Card
                          onClick={() => {
                            if (selectedInventory) {
                              setSelectedInventory("");
                            } else {
                              setSelectedInventory(item.id);
                            }
                          }}
                          sx={{ cursor: "pointer", padding: "5px 5px 0px 0px" }}
                        >
                          <CardHeader
                            className={classes.EquipmentDetailSubBox}
                            classes={{
                              title: classes.agentInventoryModalCardHeader,
                              subheader: classes.agentInventoryModalCardDes,
                            }}
                            action={
                              selectedInventory === item.id ? (
                                <CheckBoxIcon sx={{ color: "green" }} />
                              ) : checkIfAlreadyAttached(item.id) ? (
                                <CheckBoxIcon sx={{ color: "white" }} />
                              ) : null
                            }
                            avatar={<img src={agentEquipmentIcon} alt="agent Method Icon White" />}
                            title={item.AssetName}
                            subheader={`Serial Number: ${item.SerialNumber}`}
                          />
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <DataNotFound
                      color="#BDBDBD"
                      fontSize="20px"
                      icon={<WidgetsIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
                      title="No equipment Exist"
                    />
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCheckoutEquipment()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={checkOut.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, {
  name: "AgentCheckOutEquipmentModalStyle",
})(AgentCheckOutEquipmentModal);
