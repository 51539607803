import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Stack, Box, MenuItem } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { CreateInvoice } from "../../../redux/listings/makeover";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

function AddInvoiceMakeOver(props) {
  let { open, setOpen, classes, vendors, projectDetail } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [invoiceDetail, setInvoiceDetail] = useState({
    amount_due: "",
    description: "",
    vendor_id: "",
    invoice_no: "",
  });
  const [filesPreview, setFilesPreview] = useState([]);
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();
  const addInvoice = useSelector((state) => state.listings.makeover.addInvoice);
  useEffect(() => {
    if (addInvoice.success && !addInvoice.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the invoice",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!addInvoice.isLoading && addInvoice.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(addInvoice.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addInvoice.success]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!invoiceDetail.vendor_id) {
      isFormValid = false;
      errorMsg["agent_id"] = "Please select vendor";
      setErrorAlert({
        errorMsg: "Please select vendor",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!invoiceDetail.invoice_no) {
      isFormValid = false;
      errorMsg["invoice_no"] = "Please enter the invoice number";
      setErrorAlert({
        errorMsg: "Please enter the invoice number",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!invoiceDetail.amount_due) {
      isFormValid = false;
      errorMsg["amount_due"] = "Please enter the amount";
      setErrorAlert({
        errorMsg: "Please enter the amount",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!invoiceDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter description";
      setErrorAlert({
        errorMsg: "Please enter description",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!files.length) {
      isFormValid = false;
      errorMsg["file"] = "Please select the invoice file";
      setErrorAlert({
        errorMsg: "Please select the invoice file",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleAddInvoice = () => {
    if (handleValidate()) {
      const Data = new FormData();
      Data.append("amount_due", invoiceDetail.amount_due);
      Data.append("invoice_no", invoiceDetail.invoice_no);
      Data.append("description", invoiceDetail.description);
      Data.append("vendor_id", invoiceDetail.vendor_id);
      Data.append("document", files[0].file);
      dispatch(CreateInvoice({ id: projectDetail?.id, formData: Data }));
    }
  };
  const handleUpdateValue = (type, value) => {
    setInvoiceDetail({
      ...invoiceDetail,
      [type]: value,
    });
    setErrMsg({});
  };

  const handleFileChange = (e) => {
    let id = Math.random().toString(36).substring(7);
    setFiles([{ id: id, file: e.target.files[0] }]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setFilesPreview([{ id: id, file: reader.result, url: URL.createObjectURL(e.target.files[0]) }]);
    };
    let url = reader.readAsDataURL(e.target.files[0]);
  };
  const handleDelFile = (item) => {
    setFiles(files.filter((ite) => ite.id != item.id));
    setFilesPreview(filesPreview.filter((ite) => ite.id != item.id));
  };

  const handleClose = () => {
    setInvoiceDetail({ amount_due: "", description: "", vendor_id: "", invoice_no: "" });
    setFiles([])
    setFilesPreview([])
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogTitle DialogActions={<Box>Close</Box>} className={classes.CloseContractHeading} id="alert-dialog-titlesd">
          Add Invoice
        </DialogTitle>
        <DialogContent sx={{ width: "700px", mt: "10px", mb: "15px" }}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={8}>
              <TextField
                value={invoiceDetail.vendor_id}
                defaultValue={""}
                onChange={(e) => handleUpdateValue("vendor_id", e.target.value)}
                fullWidth
                required
                variant="outlined"
                size="small"
                error={errMsg["vendor_id"]}
                helperText=" Select vendor"
                select
              >
                <MenuItem value="" disabled selected>
                  Select Vendor
                </MenuItem>
                {vendors?.map((item) => (
                  <MenuItem value={item.id}> {item.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <TextField
                value={invoiceDetail.invoice_no}
                onChange={(e) => handleUpdateValue("invoice_no", e.target.value)}
                fullWidth
                variant="standard"
                helperText="Invoice #"
                name="invoice_no"
                error={errMsg["invoice_no"]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={invoiceDetail.amount_due}
                onChange={(e) => handleUpdateValue("amount_due", e.target.value)}
                fullWidth
                variant="standard"
                helperText="Amount Due"
                error={errMsg["amount_due"]}
                type="number"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={invoiceDetail.description}
                onChange={(e) => handleUpdateValue("description", e.target.value)}
                fullWidth
                multiline
                minRows={3}
                placeholder="Description of work completed."
                error={errMsg["description"]}
                size="small"
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item md={12} lg={5}>
                  <Button
                    className={classes.uploadButton}
                    startIcon={<UploadFileRoundedIcon fontSize="small" />}
                    variant="outlined"
                    component="label"
                    size="small"
                  >
                    ATTACH INVOICE <input onChange={handleFileChange} hidden accept=".pdf" multiple type="file" />
                  </Button>
                </Grid>
                <Grid item md={12} lg={7}>
                  {filesPreview.length ? (
                    <Stack direction={"row"} spacing={2} justifyContent={"flex-start"} flexWrap="wrap-reverse">
                      {filesPreview.map((item, index) => (
                        <Box sx={{ position: "relative", mb: "5px" }}>
                          {item.file.slice(17, 20) === "pdf" ? (
                            <a href={item.url} rel="noreferrer" target={"_blank"} style={{ display: "block" }}>
                              <iframe style={{ pointerEvents: "none" }} width="120" height="120" src={item.file}>
                                {" "}
                              </iframe>
                            </a>
                          ) : (
                            <a href={item.url} rel="noreferrer" target={"_blank"}>
                              {" "}
                              <img
                                style={{ width: "120px", height: "120px", objectFit: "cover" }}
                                src={item.file}
                                alt={`expense document ${index}`}
                              />
                            </a>
                          )}

                          <HighlightOffRoundedIcon
                            onClick={() => handleDelFile(item)}
                            sx={{ position: "absolute", top: "-10px", right: "-10px", cursor: "pointer" }}
                          />
                        </Box>
                      ))}
                    </Stack>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2} justifyContent={"flex-end"} sx={{ height: "100%" }} alignItems={"flex-end"}>
                <Button
                  onClick={() => handleClose(false)}
                  sx={{
                    color: "#000000",
                  }}
                  variant="text"
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={addInvoice.isLoading}
                  className={classes.saveModalButton}
                  color="inherit"
                  onClick={() => handleAddInvoice()}
                  autoFocus
                  loadingPosition="start"
                >
                  Add
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddInvoiceMakeOverStyle" })(AddInvoiceMakeOver);
