import React from "react";
import { useStyles } from "./style.js";
import { Grid, Typography } from "@mui/material";

function DetailsGrid({ detailValue, diffrence, positive }) {
  const classes = useStyles();

  return (
    <Grid className={classes.rows} container>
      <Grid item xs={6}>
        <Typography
          className={`${classes.fontFamily} ${classes.tableCell}`}
          noWrap
        >
          {detailValue}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={`${classes.fontFamily} ${classes.tableCell} ${
            classes.alignRight
          } ${positive ? classes.positive : classes.negative}`}
          noWrap
        >
          {diffrence ? diffrence : ""}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DetailsGrid;
