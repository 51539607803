import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  TextField,
  Typography,
  MenuItem,
  Button,
  Grid,
  CardHeader,
  Tooltip,
  Checkbox,
} from "@mui/material";
import TransactionTasksPanel from "./tasksPanel";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import ViewCarouselRoundedIcon from "@mui/icons-material/ViewCarouselRounded";
import { useSelector, useDispatch } from "react-redux";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { getTransactionTasks } from "../../../../redux/transactions/getTasks";
import { addTaskComment } from "../../../../redux/transactions/storeTaskComment";
import { addTaskAttachment } from "../../../../redux/transactions/addTaskAttachment";
import { deleteTaskAttachment } from "../../../../redux/transactions/deleteTaskAttachment";
import {
  handleUpdateAttachments,
  handleUpdateAttachmentOrder,
} from "../../../../redux/transactions/tasks";
import ContractTasksSkelton from "../skelton";
import CommentSection from "../../../../components/shared/commentSection";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import FileSaver from "file-saver";
import JSZip from "jszip";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FadeLoader } from "react-spinners";
import { getSignedURL, openDeliverable } from "./helpers";
import { styled } from "@mui/styles";
import dragIcon from "../../../../assets/dragIcon.png";
import processingIcon from "../../../../assets/images/processingIcon.png";
import { toast } from "react-toastify";
import { getTaskTemplates } from "../../../../redux/transactions/taskTemplates";
import LoadingButton from "@mui/lab/LoadingButton";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px #0B0909, inset 0 -1px 0 #0B0909",
  backgroundColor: "#fff",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#7E7E7E",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  boxShadow: "none",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#7E7E7E",
  },
});

function droppingAnimationStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

  // patching the existing style
  return {
    ...style,
    transform: `${translate}`,
    transition: `all ${curve} ${duration + 1}s`,
  };
}

function TransactionDetailTasks(props) {
  let { classes, setErrorAlert, id } = props;
  const zip = new JSZip();
  const [activeTaskType, setActiveTaskType] = useState("pending");
  const [commentView, setCommentView] = useState(false);
  const [taskDetail, setTaskDetail] = useState({});
  const [templateId, setTemplateId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const taskRef = useRef();
  const [displayDeliverableCheckbox, setDisplayDeliverableCheckbox] = useState(
    {}
  );
  const [selectedAttachments, setSelectedAttachments] = useState({});
  const taskAttachment = useSelector(
    (item) => item.transactions.Tasks.taskAttachment
  );
  const TasksData = useSelector((item) => item.transactions.Tasks.TasksData);
  const taskDetails = useSelector(
    (item) => item.transactions.Tasks.taskDetail.data
  );
  const templateData = useSelector(
    (item) => item.transactions.templates.templateData
  );
  const adminAuth = useSelector((state) => state.adminAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!TasksData.isLoading) {
      dispatch(getTransactionTasks({ id: props.id }));
    }
  }, []);

  useEffect(() => {
    dispatch(getTaskTemplates({ pageNumber: pageNumber }));
  }, [pageNumber]);

  useEffect(() => {
    if (taskDetail?.id) {
      taskRef.current = taskDetail;
    }
  }, [taskDetail?.id]);

  const handleSubmitComment = (data) => {
    let obj = {
      comment: data?.value,
      userIds: data?.userIds,
      taskId: taskDetail?.id,
      user: {
        ...adminAuth.adminDetail,
        profile_images: {
          profile_img_thumbnail: adminAuth.adminDetail?.profile_img,
        },
      },
    };
    dispatch(addTaskComment(obj));
  };
  /*  dropzone are will be here where user select the picture or drop here */
  const reorderDeliverables = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDrop = React.useCallback((files) => {
    if (files[0]?.type?.split("/")[1] === "pdf") {
      uploadTaskAttachment(files);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
    onDragLeave();
  }, []);

  const onDragEnter = () => {
    document.getElementById(`upload-file-for-task`).style.border =
      "2px dotted black";
    document.getElementById(`upload-file-for-task-icon`).style.display = "flex";
  };

  const onDragLeave = () => {
    document.getElementById(`upload-file-for-task`).style.border = "none";
    document.getElementById(`upload-file-for-task-icon`).style.display = "none";
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
  });

  const uploadTaskAttachment = (files) => {
    const Data = new FormData();
    Data.append("attachments", files[0]);
    let obj = {
      formData: Data,
      taskId: taskDetail.id || taskRef.current.id,
    };
    dispatch(addTaskAttachment(obj));
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorderDeliverables(
      taskDetails.attachments,
      result.source.index,
      result.destination.index
    );

    dispatch(handleUpdateAttachmentOrder({ attachments: [...reorderedItems] }));
    const orders = reorderedItems.map((item, index) => ({
      order: index + 1,
      id: item.id,
    }));

    const token = localStorage.getItem("token");
    const url = `${
      process.env.REACT_APP_BASE_URL
    }api/transactions/contracts/task/${
      taskDetails?.id || taskDetail?.id
    }/attachment/order`;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      method: "put",
      url,
      data: JSON.stringify({ orders: [...orders] }),
    };
    await axios(options);
  };

  const getLastPartOfString = (str) => {
    const parts = str.split("/");
    const lastPart = parts.pop();
    const commaIndex = lastPart.indexOf(",");
    const dotIndex = lastPart.lastIndexOf(".");
    const firstPart =
      commaIndex !== -1 ? lastPart.substring(0, commaIndex) : lastPart;
    const secondPart = dotIndex !== -1 ? lastPart.substring(dotIndex) : "";
    return firstPart + secondPart;
  };

  /*  end dropzone are */
  const downloadDeliverable = async () => {
    const dArray = [];
    Object.keys(selectedAttachments).map((id) => {
      if (selectedAttachments[id]) {
        dArray.push(parseInt(id));
      }
    });
    if (dArray.length === 1) {
      toast.promise(
        Promise.all(
          taskDetails.attachments.map((d, index) => {
            if (dArray.includes(d.id)) {
              return getSignedURL(d.uuid).then(async (url) => {
                FileSaver.saveAs(new Blob([url]), getLastPartOfString(d.file));
              });
            }
          })
        ),
        {
          pending: "Downloading selected deliverables is in progress",
          success: "Successfully Downloaded",
          error: "Something went wrong while downloading deliverables",
        }
      );
    } else {
      toast.promise(
        Promise.all(
          taskDetails.attachments.map((d, index) => {
            if (dArray.includes(d.id)) {
              return getSignedURL(d.uuid).then(async (url) => {
                let filename = getLastPartOfString(d.file);
                zip.file(filename, new Blob([url]));
              });
            }
          })
        ).then((z) => {
          zip.generateAsync({ type: "blob" }).then(function (blob) {
            FileSaver.saveAs(blob, "attachment_deliverables.zip");
          });
        }),
        {
          pending: "Downloading selected deliverables is in progress",
          success: "Successfully Downloaded",
          error: "Something went wrong while downloading deliverables",
        }
      );
    }
  };

  const deleteDeliverableFile = async () => {
    const dArray = [];
    Object.keys(selectedAttachments).map((id) => {
      if (selectedAttachments[id]) {
        dArray.push(parseInt(id));
      }
    });
    dArray.map((deliverableID) => delete selectedAttachments[deliverableID]);
    if (dArray.length === 0) return;

    const promises = dArray.map((attachmentId) =>
      dispatch(deleteTaskAttachment({ attachmentId: attachmentId }))
    );
    toast.promise(Promise.all([...promises]), {
      pending: "Deleting deliverable(s) is in progress",
      success: "Successfully Deleted",
      error: "Something went wrong while deleting deliverables",
    });
    dispatch(handleUpdateAttachments({ deleteAbleIds: dArray }));
  };

  const handleConfirm = async () => {
    if (templateId) {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}api/transactions/contracts/${id}`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "put",
        url,
        data: JSON.stringify({ template_id: templateId }),
      };
      await axios(options);
      setLoading(false);
      dispatch(getTransactionTasks({ id: props.id }));
    } else {
      setErrorAlert({
        errorMsg: "Please select the template first",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight && !templateData.isLoading) {
      setPageNumber((page) => page + 1);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={commentView ? 8 : 12}>
        {" "}
        {TasksData?.data?.tasks?.length === 0 ? (
          <Card className={classes.templateSelectionArea}>
            <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
              Select Task Template:
            </Typography>
            <TextField
              select
              value={templateId}
              onChange={(e) => setTemplateId(e.target.value)}
              size="small"
              sx={{ minWidth: "200px" }}
              onScroll={handleScroll}
            >
              {templateData?.data?.length ? (
                templateData?.data?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  Not Available
                </MenuItem>
              )}
            </TextField>
            <LoadingButton
              className={classes.confirmButton}
              variant="contained"
              size="small"
              onClick={handleConfirm}
              loadingPosition="start"
              loading={loading}
            >
              Confirm
            </LoadingButton>
          </Card>
        ) : null}
        {TasksData.isLoading ? (
          <ContractTasksSkelton disableTopCard />
        ) : TasksData?.data?.tasks?.length > 0 ? (
          <Box>
            <TransactionTasksPanel
              data={TasksData?.data?.tasks?.filter(
                (item) => item.status === activeTaskType
              )}
              title={
                activeTaskType === "pending"
                  ? "Outstanding Tasks"
                  : "Completed Tasks"
              }
              completedTasks={
                TasksData?.data?.tasks?.filter(
                  (item) => item.status === "completed"
                )?.length > 0
                  ? "available"
                  : ""
              }
              pendingTasks={
                TasksData?.data?.tasks?.filter(
                  (item) => item.status === "pending"
                )?.length > 0
                  ? "available"
                  : ""
              }
              activeTaskType={activeTaskType}
              setActiveTaskType={setActiveTaskType}
              setCommentView={setCommentView}
              setTaskDetail={setTaskDetail}
              taskDetail={taskDetail}
              commentView={commentView}
              {...props}
            />
          </Box>
        ) : (
          <DataNotFound
            color="#BDBDBD"
            fontSize="26px"
            title="No Task"
            icon={
              <ViewCarouselRoundedIcon
                sx={{ fontSize: "120px", color: "#BDBDBD" }}
                fontSize="large"
              />
            }
          />
        )}
      </Grid>
      {commentView ? (
        <Grid item md={commentView ? 4 : 0}>
          {" "}
          <Card>
            <CommentSection
              height={"700px"}
              innerHeight={"600px"}
              comments={taskDetails?.comments || []}
              handleSubmitComment={handleSubmitComment}
            />
          </Card>
          <Card className={classes.attachmentCardSection}>
            <CardHeader
              classes={{ title: classes.attachmentHeading }}
              title={"Attachments"}
              action={
                <Box className={classes.actionButtons}>
                  {taskDetails?.attachments?.length ? (
                    <Button
                      className={classes.actionButtonItem}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      component="label"
                    >
                      <input
                        multiple
                        type="file"
                        accept=".pdf"
                        onChange={(e) => uploadTaskAttachment(e.target.files)}
                        hidden
                      />
                      <UploadRoundedIcon />
                    </Button>
                  ) : null}
                  {Object.keys(selectedAttachments).length ? (
                    <Button
                      className={classes.actionButtonItem}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      onClick={downloadDeliverable}
                    >
                      <DownloadRoundedIcon />
                    </Button>
                  ) : null}{" "}
                  {Object.keys(selectedAttachments).length ? (
                    <Button
                      className={classes.actionButtonItem}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      onClick={deleteDeliverableFile}
                    >
                      <DeleteRoundedIcon />
                    </Button>
                  ) : null}{" "}
                </Box>
              }
            />
            {/* Drop Area Starts */}
            <div
              {...getRootProps({
                onClick: (event) => event.stopPropagation(),
              })}
              style={{
                width: "100%",
                minHeight: "144px",
                position: "relative",
              }}
            >
              <input {...getInputProps()} />

              <Box id="upload-file-for-task">
                <Box
                  className={
                    taskDetails?.attachments?.length
                      ? classes.uploadAttachmentArea01
                      : classes.uploadAttachmentArea
                  }
                >
                  <Box
                    id={`upload-file-for-task-icon`}
                    sx={{ top: "0px" }}
                    className={classes.dropzoneArea}
                  >
                    <Box className={classes.dropzoneAreaInner}>
                      <img
                        src={dragIcon}
                        alt="dragIcon"
                        style={{ width: "25px", marginRight: "10px" }}
                      />{" "}
                      <Box>Drop file to upload.</Box>
                    </Box>
                  </Box>
                  {taskAttachment?.isLoading && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "33%",
                        left: "46%",
                        zIndex: "999",
                      }}
                    >
                      <FadeLoader size="40" color="rgba(0, 0, 0, 0.5)" />
                    </Box>
                  )}
                  {!taskAttachment?.isLoading &&
                    !taskDetails?.attachments?.length && (
                      <Button
                        component="label"
                        className={classes.uploadAttachmentButton}
                        startIcon={
                          <UploadRoundedIcon
                            sx={{
                              color: "rgba(0, 0, 0, 0.54)",
                              fontSize: "25px",
                            }}
                          />
                        }
                      >
                        Upload Attachments
                        <input
                          multiple
                          type="file"
                          accept=".pdf"
                          onChange={(e) => uploadTaskAttachment(e.target.files)}
                          hidden
                        />
                      </Button>
                    )}
                </Box>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="taskDeliverablesDroppable"
                    direction="horizontal"
                  >
                    {(provided, snapshot) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classes.tasksArea}
                        sx={{
                          mb: taskDetails?.attachments?.length ? "4px" : "",
                        }}
                      >
                        {taskDetails?.attachments?.map((task, index) => (
                          <Draggable
                            key={task.id.toString()}
                            draggableId={task.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                isDragging={
                                  snapshot.isDragging &&
                                  !snapshot.isDropAnimating
                                }
                                style={droppingAnimationStyle(
                                  provided.draggableProps.style,
                                  snapshot
                                )}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    top: "3%",
                                  }}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={getLastPartOfString(task.file)}
                                    arrow
                                  >
                                    <img
                                      onClick={() => openDeliverable(task)}
                                      onMouseEnter={() =>
                                        setDisplayDeliverableCheckbox({
                                          [task.id]: true,
                                        })
                                      }
                                      onMouseLeave={() =>
                                        setDisplayDeliverableCheckbox(
                                          (prev) => {
                                            let updated = {
                                              ...prev,
                                            };
                                            delete updated[task.id];
                                            return updated;
                                          }
                                        )
                                      }
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = processingIcon;
                                      }}
                                      alt="deliverable"
                                      className={classes.taskImg}
                                      src={task.thumbnail}
                                    />
                                  </Tooltip>
                                  {Object.keys(selectedAttachments).length >
                                    0 ||
                                  Object.keys(displayDeliverableCheckbox).find(
                                    (d) => d == task.id
                                  ) ? (
                                    <div
                                      onMouseEnter={() =>
                                        setDisplayDeliverableCheckbox({
                                          [task.id]: true,
                                        })
                                      }
                                      onMouseLeave={() =>
                                        setDisplayDeliverableCheckbox(
                                          (prev) => {
                                            let updated = {
                                              ...prev,
                                            };
                                            delete updated[task.id];
                                            return updated;
                                          }
                                        )
                                      }
                                    >
                                      <Checkbox
                                        className={classes.TaskCheckbox}
                                        checkedIcon={<BpCheckedIcon />}
                                        iconStyle={{
                                          fill: "red",
                                        }}
                                        icon={<BpIcon />}
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          setSelectedAttachments((prev) => {
                                            let updated = {
                                              ...prev,
                                            };
                                            if (e.target.checked) {
                                              updated = {
                                                ...updated,
                                                [task.id]: true,
                                              };
                                            } else {
                                              delete updated[task.id];
                                            }
                                            return updated;
                                          });
                                        }}
                                        checked={
                                          selectedAttachments[task.id] || false
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </div>
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
}
export default withStyles(styleSheet, {
  name: "TransactionDetailTasksStyle",
})(TransactionDetailTasks);
