import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getLeadActivities = createAsyncThunk(
  "leads/getLeadActivities",
  async (data, thunkAPI) => {
    try {
      let headers = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      };
      if (data.params) {
        headers["params"] = data.params;
      }
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/leads/events?personId=${
          data?.personId || ""
        }&type=viewed property,favorite property added&limit=${
          data?.limit || 20
        }&offset=${data?.offset || 0}`,
        headers
      );
      return {...resp.data,offset:data?.offset};
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
