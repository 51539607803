export const styleSheet = (theme) => ({
  TransactionSalesRoot: {
    marginBottom: "16px",
    padding: "5px",
  },
  pageHeading: {
    fontSize: "24px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentTableButton: {
    fontSize: "12px  !important",
    borderRadius: "15px  !important",
    minWidth: "80px !important",
  },
  makeOverListingHeading: {
    fontWeight: "500 !important",
    fontSize: "14px  !important",
  },
  addProjectButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
  },
  dropzoneAreaInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18.8px",
    color: "black",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "0px",
    left: "0px",
    border: "2px dotted black",
    background: "rgba(255,255,255,.6)",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadButton: {
    fontSize: "11.5px  !important",
    borderRadius: "15px  !important",
    backgroundColor: "black !important",
    color: "white !important",
    marginBottom: "4px",
    width: "88px !important",
    height:"24px"
  },
  voidButton: {
    fontSize: "11.5px  !important",
    borderRadius: "15px  !important",
    backgroundColor: "white !important",
    color: "red !important",
    marginTop: "4px !important",
    width: "88px !important",
    height:"24px"
  },
  uploadButtonDoc: {
    fontSize: "11.5px  !important",
    borderRadius: "15px  !important",
    backgroundColor: "white !important",
    color: "black !important",
    border: "1px solid rgba(33, 33, 33, 0.4) !important",
    boxShadow: "0px 0px 0px 1px #E0E0E0  !important" ,
    marginTop: "4px !important",
    width: "88px !important",
    height:"24px"
  },
  checkIcon: {
    position: "absolute !important",
    top: "5px !important",
    right: "-10px !important",
    color: "#4CAF50 !important",
    background: "white !important",
    borderRadius: "100% !important",
    borderColor: "transparent !important",
  },
});
