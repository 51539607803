import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Stack,
  InputAdornment,
} from "@mui/material";
import { styleSheet } from "./style";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector, useDispatch } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { updateAgentRosterProfile } from "../../../../../redux/agents/agentRoster/updateProfile";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import { getTeams } from "../../../../../redux/teams/team";
import { AccessTime } from "@mui/icons-material";
import moment from "moment";

export const generateTimeOptions = () => {
  const times = [];
  const start = new Date();
  start.setHours(7, 0, 0, 0); // Start at 7:00 AM

  for (let i = 0; i <= 12 * 4; i++) {
    const hour = start.getHours();
    const minutes = start.getMinutes();
    const formattedHour = String(hour % 12 || 12).padStart(2, "0"); // Two-digit hour
    const formattedTime = `${formattedHour}:${minutes
      .toString()
      .padStart(2, "0")} ${hour < 12 ? "AM" : "PM"}`;
    times.push(formattedTime);
    start.setMinutes(minutes + 15); // Increment by 15 minutes
  }
  return times;
};

const timeOptions = generateTimeOptions();

const AdditionalInfoDetails = ({ classes }) => {
  const [isEditAble, setIsEditAble] = useState(false);
  const toastId = React.useRef(null);
  const [additionalDetail, setAdditionalDetail] = useState({
    id: "",
    team_id: "",
    email: "",
    admin_channel: "",
    license_type: "",
    license_number: "",
    license_exp: "",
    onboarding_coordinator: "",
    onboarding_location: "",
    onboarding_date: "",
    fub_api_key: "",
    leadDelayValues: 0,
  });
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);

  const dispatch = useDispatch();
  const agentRosterProfile = useSelector(
    (item) => item.agentRoster.agentRosterProfile
  );
  const teamsData = useSelector((item) => item.teams.teams);
  const { profile, updateLoading } = agentRosterProfile;
  useEffect(() => {
    if (!teamsData.isLoading) {
      dispatch(getTeams());
    }
  }, []);
  useEffect(() => {
    if (!additionalDetail.id) {
      setAdditionalDetail({
        id: profile.id,
        team_id: profile.team_id,
        email: profile.email,
        admin_channel: profile.admin_channel,
        license_type: profile.license_type,
        license_number: profile.license_number,
        license_exp: moment(profile.license_exp).format(),
        onboarding_date: profile.onboarding_date
          ? moment(profile.onboarding_date).format()
          : "",
        onboarding_coordinator: profile.onboarding_coordinator
          ? profile.onboarding_coordinator
          : "",
        onboarding_location: profile.onboarding_coordinator
          ? profile.onboarding_location
          : "",
        fub_api_key: profile.fub_api_key,
        type: "additionalDetail",
        leadDelayValues: profile?.leadDelayValues || 0,
        lead_claim_limit: profile.lead_claim_limit,
        lead_claimed: profile.lead_claimed,
      });
    }
  }, [profile]);

  const handleSuccess = () => {
    if (!toast.isActive(toastId.current)) {
      toast.success("You have successfully update additional detail", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsEditAble(false);
    }
    setIsEditAble(false);
  };
  const handleError = (error) => {
    if (!toast.isActive(toastId.current)) {
      toast.error(JSON.stringify(error), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSave = () => {
    dispatch(
      updateAgentRosterProfile({
        ...additionalDetail,
        license_exp: moment
          .utc(additionalDetail.license_exp)
          .format("YYYY-MM-DD"),
        handleSuccess,
        handleError,
      })
    );
  };
  const handleUpdateField = (field, value) => {
    setAdditionalDetail({
      ...additionalDetail,
      [field]: value,
    });
  };

  const handleUpdateDateField = (key, value) => {
    setAdditionalDetail((prev) => {
      let newDate;

      // Check if the existing value is a valid date
      if (
        prev.onboarding_date instanceof Date &&
        !isNaN(prev.onboarding_date)
      ) {
        newDate = new Date(prev.onboarding_date);
      } else {
        newDate = new Date();
      }

      // Update the date or time portion based on the key
      if (key === "onboarding_date_date" && value) {
        newDate.setFullYear(
          value.getFullYear(),
          value.getMonth(),
          value.getDate()
        );
      } else if (key === "onboarding_date_time" && value) {
        newDate.setHours(value.getHours(), value.getMinutes());
      } else {
        newDate = "";
      }

      return { ...prev, onboarding_date: newDate };
    });
  };

  const formattedTime =
    additionalDetail.onboarding_date &&
    !isNaN(Date.parse(additionalDetail.onboarding_date))
      ? new Date(additionalDetail.onboarding_date).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "";

  return (
    <Card
      variant="outlined"
      sx={{ backgroundColor: "white" }}
      className={classes.pageRoot}
    >
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title="Additional Information"
        action={
          <div>
            <IconButton
              onClick={() => setIsEditAble(!isEditAble)}
              aria-label="locked"
            >
              {isEditAble ? (
                <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              ) : (
                <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              )}
            </IconButton>
          </div>
        }
      />
      <CardContent>
        <Grid container direction="row" spacing={6}>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              type="number"
              inputProps={{ readOnly: true }}
              classes={{ root: classes.detailInput }}
              label="Leads Claimed"
              value={additionalDetail.lead_claimed}
              placeholder="Leads Claimed"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              onChange={(e) =>
                handleUpdateField("leadDelayValues", e.target.value)
              }
              value={additionalDetail.leadDelayValues}
              select
              classes={{ root: classes.detailInput }}
              label="Lead Delay value"
              variant="standard"
            >
              {[5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10].map(
                (item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              type="number"
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Lead Claim Limit"
              value={additionalDetail.lead_claim_limit}
              placeholder="Lead Claim Limit"
              onChange={(e) =>
                handleUpdateField("lead_claim_limit", e.target.value)
              }
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={6} sx={{ marginTop: "-10px" }}>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              onChange={(e) => handleUpdateField("team_id", e.target.value)}
              value={additionalDetail.team_id}
              select
              classes={{ root: classes.detailInput }}
              label="Team"
              variant="standard"
            >
              {teamsData?.data?.teams.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              onChange={(e) =>
                handleUpdateField("admin_channel", e.target.value)
              }
              value={additionalDetail.admin_channel}
              classes={{ root: classes.detailInput }}
              label="Agent Admin Channel"
              defaultValue={"someagent-admin"}
              variant="standard"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              onChange={(e) => handleUpdateField("email ", e.target.value)}
              value={additionalDetail.email}
              classes={{ root: classes.detailInput }}
              label="Method Email"
              defaultValue={"someagent.lastname@ourmethod.com"}
              variant="standard"
            />
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
          <Grid item lg={2} xs={12}>
            <TextField
              onChange={(e) =>
                handleUpdateField("license_number", e.target.value)
              }
              value={additionalDetail.license_number}
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="License Number"
              defaultValue={"325811"}
              variant="standard"
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              onChange={(e) =>
                handleUpdateField("license_type", e.target.value)
              }
              value={additionalDetail.license_type}
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="License Type"
              variant="standard"
            >
              {/* <MenuItem key={"Public domain"} value={"Public domain"}>
                Public domain
              </MenuItem>
              <MenuItem key={"Permissive"} value={"Permissive"}>
                Permissive
              </MenuItem>
              <MenuItem key={"LGPL"} value={"LGPL"}>
                LGPL
              </MenuItem>
              <MenuItem key={"Copyleft"} value={"Copyleft"}>
                Copyleft
              </MenuItem>
              <MenuItem key={"Proprietary"} value={"Proprietary"}>
                Proprietary
              </MenuItem> */}
            </TextField>
          </Grid>
          <Grid item lg={2} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                onChange={(value) => handleUpdateField("license_exp", value)}
                value={additionalDetail.license_exp}
                label="License Expires"
                readOnly={isEditAble ? false : true}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    label="Join Date"
                    classes={{ root: classes.detailInput }}
                    {...params}
                  />
                )}
                disablePast
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="FUB API Key"
              onChange={(e) => handleUpdateField("fub_api_key", e.target.value)}
              value={additionalDetail.fub_api_key}
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
          <Grid item lg={2} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                onChange={(value) =>
                  handleUpdateDateField("onboarding_date_date", value)
                }
                value={additionalDetail.onboarding_date}
                label="Onboarding Date"
                readOnly={isEditAble ? false : true}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    label="Join Date"
                    classes={{ root: classes.detailInput }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              select
              variant="standard"
              label="Onboarding Time"
              fullWidth
              value={formattedTime || ""}
              onChange={(event) => {
                const timeValue = event.target.value;
                const [hour, minute] = timeValue.split(/[:\s]/);
                const isPM = timeValue.includes("PM");
                const date = new Date(
                  additionalDetail.onboarding_date || new Date()
                );
                date.setHours(
                  isPM && hour !== "12"
                    ? parseInt(hour, 10) + 12
                    : parseInt(hour, 10),
                  parseInt(minute, 10)
                );
                handleUpdateDateField("onboarding_date_time", date);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccessTime />
                  </InputAdornment>
                ),
              }}
              classes={{ root: classes.detailInput }}
              disabled={
                !isEditAble || !additionalDetail.onboarding_date ? true : false
              }
            >
              {timeOptions.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              onChange={(e) =>
                handleUpdateField("onboarding_coordinator", e.target.value)
              }
              value={additionalDetail.onboarding_coordinator}
              select
              classes={{ root: classes.detailInput }}
              label="Onboarding Coordinator"
              variant="standard"
            >
              {storedListOfUsers.list
                ?.filter(
                  (item) =>
                    item?.role?.name === "Team Leader" ||
                    item?.full_name === "Audrey MacKay" ||
                    item?.id === 82
                )
                ?.map((item, index) => (
                  <MenuItem value={item.id}>{item?.full_name}</MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              onChange={(e) =>
                handleUpdateField("onboarding_location", e.target.value)
              }
              value={additionalDetail.onboarding_location}
              select
              classes={{ root: classes.detailInput }}
              label="Onboarding Location"
              variant="standard"
            >
              <MenuItem value={"virtually-slack"}>Virtually - Slack</MenuItem>
              <MenuItem value={"in-person"}>In-Person</MenuItem>
            </TextField>
          </Grid>
          <Grid hidden={!isEditAble} item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <LoadingButton
                onClick={handleSave}
                loading={updateLoading === "additionalDetail"}
                classes={{ root: classes.saveButTon }}
                color="inherit"
                autoFocus
                size="small"
                loadingPosition="start"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styleSheet, {
  name: "agentRosterDetailsAdditionalInfoStyle",
})(AdditionalInfoDetails);
