import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Grid, IconButton, TextField, MenuItem, Stack } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentRosterProfile } from "../../../../../redux/agents/agentRoster/updateProfile";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";

const PaymentDetails = ({ classes }) => {
  const [isEditAble, setIsEditAble] = useState(false);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [paymentDetail, setPaymentDetail] = useState({
    id: "",
    pay_commission_to: "",
    tax_id: "",
    entity_type: "",
    payment_preference: "Check",
    routing_number: "",
    account_number: "",
    payee_name: "",
    account_type: "",
    postal_code: "",
    state: "",
    city: "",
    street: "",
  });
  const agentRosterProfile = useSelector((item) => item.agentRoster.agentRosterProfile);
  const { profile, updateLoading } = agentRosterProfile;

  useEffect(() => {
    if (!paymentDetail.id) {
      setPaymentDetail({
        id: profile.id,
        pay_commission_to: profile?.financial_info?.pay_commission_to,
        tax_id: profile?.financial_info?.tax_id,
        entity_type: profile?.financial_info?.entity_type,
        payment_preference: profile?.financial_info?.payment_preference || "Check",
        routing_number: profile?.financial_info?.bank_details?.routing_number,
        account_number: profile?.financial_info?.bank_details?.account_number,
        payee_name: profile?.financial_info?.bank_details?.payee_name,
        account_type: profile?.financial_info?.bank_details?.account_type,
        postal_code: profile?.financial_info?.bank_details?.postal_code,
        state: profile?.financial_info?.bank_details?.state,
        city: profile?.financial_info?.bank_details?.city,
        street: profile?.financial_info?.bank_details?.street,
      });
    }
  }, [profile]);
  useEffect(() => {
    if (agentRosterProfile.success && !agentRosterProfile.updateLoading && agentRosterProfile.type === "payment") {
      if (!toast.isActive(toastId.current)) {
        toast.success("You have successfully update payment detail", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsEditAble(false);
      }
    } else if (!agentRosterProfile.updateLoading && agentRosterProfile.updateErrMsg && agentRosterProfile.type === "payment") {
      if (!toast.isActive(toastId.current)) {
        toast.error(JSON.stringify(agentRosterProfile.updateErrMsg), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }, [agentRosterProfile.updateLoading]);

  const handleUpdateField = (field, value) => {
    setPaymentDetail({
      ...paymentDetail,
      [field]: value,
    });
  };
  const handleSave = () => {
    let obj = {
      id: paymentDetail.id,
      financial_data: {
        pay_commission_to: paymentDetail.pay_commission_to,
        tax_id: paymentDetail.tax_id,
        entity_type: paymentDetail.entity_type,
        payment_preference: paymentDetail.payment_preference,
      },
      bank_details: {
        routing_number: paymentDetail.routing_number,
        account_number: paymentDetail.account_number,
        payee_name: paymentDetail.payee_name,
        account_type: paymentDetail.account_type,
        postal_code: paymentDetail.postal_code,
        state: paymentDetail.state,
        city: paymentDetail.city,
        street: paymentDetail.street,
      },
      type: "payment",
    };
    dispatch(updateAgentRosterProfile(obj));
  };
  return (
    <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRoot}>
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title="Payment Information"
        action={
          <div>
            <IconButton onClick={() => setIsEditAble(!isEditAble)} aria-label="locked">
              {isEditAble ? <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} /> : <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />}
            </IconButton>
          </div>
        }
      />
      <CardContent>
        <Grid container direction="row" spacing={6}>
          <Grid item lg={4} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Pay Commissions to"
              value={paymentDetail.pay_commission_to}
              placeholder="Enter Pay Commissions to "
              onChange={(e) => handleUpdateField("pay_commission_to", e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Tax ID or EIN"
              value={paymentDetail.tax_id}
              placeholder="Enter Tax ID or EIN "
              onChange={(e) => handleUpdateField("tax_id", e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              select
              classes={{ root: classes.detailInput }}
              label="Type"
              value={paymentDetail.entity_type}
              placeholder="Enter Entity Type "
              onChange={(e) => handleUpdateField("entity_type", e.target.value)}
              variant="standard"
            >
              <MenuItem key={"Individual"} value={"Individual"}>
                Individual
              </MenuItem>
              <MenuItem key={"Entity"} value={"Entity"}>
                Entity
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
          <Grid item lg={4} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Billing Address"
              placeholder="Enter street address"
              value={paymentDetail.street}
              onChange={(e) => handleUpdateField("street", e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="City"
              placeholder="Enter city"
              value={paymentDetail.city}
              onChange={(e) => handleUpdateField("city", e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="State "
              placeholder="Enter state "
              value={paymentDetail.state}
              onChange={(e) => handleUpdateField("state", e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Zip Code"
              placeholder="Enter Zip Code"
              value={paymentDetail.postal_code}
              onChange={(e) => handleUpdateField("postal_code", e.target.value)}
              variant="standard"
            />
          </Grid>

          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              select
              classes={{ root: classes.detailInput }}
              label="Payment Method"
              value={paymentDetail.payment_preference}
              onChange={(e) => handleUpdateField("payment_preference", e.target.value)}
              variant="standard"
            >
              <MenuItem key={"ACH"} value={"ACH"}>
                ACH
              </MenuItem>
              <MenuItem key={"Check"} value={"Check"}>
                Check
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              select
              classes={{ root: classes.detailInput }}
              label="Account Type"
              disabled={paymentDetail.payment_preference !== "ACH"}
              value={paymentDetail.account_type}
              onChange={(e) => handleUpdateField("account_type", e.target.value)}
              variant="standard"
            >
              <MenuItem key={"Personal"} value={"personal"}>
                Personal
              </MenuItem>
              <MenuItem key={"Business"} value={"business"}>
                Business
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              classes={{ root: classes.detailInput }}
              label="Routing Number"
              disabled={paymentDetail.payment_preference !== "ACH"}
              placeholder="Enter Routing Number"
              value={paymentDetail.routing_number}
              onChange={(e) => handleUpdateField("routing_number", e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              disabled={paymentDetail.payment_preference !== "ACH"}
              classes={{ root: classes.detailInput }}
              label="Account Number"
              placeholder="*************0402"
              value={paymentDetail.account_number}
              onChange={(e) => handleUpdateField("account_number", e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: isEditAble ? false : true }}
              disabled={paymentDetail.payment_preference !== "ACH"}
              classes={{ root: classes.detailInput }}
              label="Added By"
              placeholder="Enter payee name"
              value={paymentDetail.payee_name}
              onChange={(e) => handleUpdateField("payee_name", e.target.value)}
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
          {profile?.financial_info?.quickbooks_details && (
            <Grid item lg={4} xs={12}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  classes={{
                    title: classes.agentProfileDetailHeader,
                    subheader: classes.agentProfileDetail,
                  }}
                  avatar={<CheckCircleOutlineIcon sx={{ color: "#2E7D32" }} fontSize="large" />}
                  title={`Quickbooks Customer ID: ${profile?.financial_info?.quickbooks_details.customer_qb_id}`}
                  subheader={`Customer Name: ${profile?.financial_info?.quickbooks_details.customer_qb_name}`}
                />
              </Card>
            </Grid>
          )}
          {profile?.financial_info?.quickbooks_details && (
            <Grid item lg={4} xs={12}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  classes={{
                    title: classes.agentProfileDetailHeader,
                    subheader: classes.agentProfileDetail,
                  }}
                  avatar={<CheckCircleOutlineIcon sx={{ color: "#2E7D32" }} fontSize="large" />}
                  title={`Quickbooks Vendor ID: ${profile?.financial_info?.quickbooks_details.vendor_qb_id}`}
                  subheader={`Vendor Name: ${profile?.financial_info?.quickbooks_details.vendor_qb_name}`}
                />
              </Card>
            </Grid>
          )}
          <Grid hidden={!isEditAble} item xs={12}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton
                onClick={handleSave}
                loading={updateLoading === "payment"}
                classes={{ root: classes.saveButTon }}
                color="inherit"
                autoFocus
                size="small"
                loadingPosition="start"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styleSheet, {
  name: "agentRosterDetailsPaymentStyle",
})(PaymentDetails);
