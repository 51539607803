import React from "react";
import { Box, Grid, Card, CardHeader } from "@mui/material";
import Avatar from "../../uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function Peoples(props) {
  let { classes, data, setOpenSearch } = props;
  const navigate = useNavigate();
  return (
    <Box className={classes.peoplesArea}>
      <Grid container spacing={1}>
        {data?.length ?
          data.map((item, index) => (
            <Grid key={index} item md={6}>
              <Card
                onClick={() => {
                  setOpenSearch(false);
                  if(item.type==="lead"){
                    navigate(`/leads/people/details/${item.id}`);
                  }else if(item.type==="realtor_agent"){
                    navigate(`/tools/recruiting/details/${item.id}`);
                  }
                  else if(item.type==="vendor"){
                    navigate(`/vendors/${item.id}`);
                  }
                }}
                /* elevation={0} */ sx={{ cursor: "pointer", "&:hover": { background: "#F0F0F0" } }}
              >
                <CardHeader
                  avatar={
                    <Avatar sx={{ width: "40px  !important", height: "40px !important" }} variant="rounded" src={item?.profile_images?.profile_img} aria-label="recipe">
                      {`${item.firstName?.slice(0, 1)}${item.lastName?.slice(0, 1)}`}
                    </Avatar>
                  }
                  action={
                      item.type==="realtor_agent"?<Box sx={{fontSize:"14px",color: "rgba(0, 0, 0, 0.87)"}}>Agent</Box>:
                      item.type==="vendor"?<Box sx={{fontSize:"14px",color: "rgba(0, 0, 0, 0.87)"}}>Vendor</Box>:
                      item.type==="contact"?<Box sx={{fontSize:"14px",color: "rgba(0, 0, 0, 0.87)"}}>Contact</Box>:
                    <Box sx={{ fontSize: "12px", lineHeight: "22px" }}>
                      Last Activity:
                      <br /> {moment(moment(new Date(item.lastActivity).toString())).from(moment(new Date()))}
                    </Box>
                  }
                  title={`${item.firstName || item.MemberFirstName || item.first_name  || "Name NA"} ${item.lastName || item.last_name  ||item.MemberLastName  || ""}`}
                  subheader={item.type==="lead"?item?.source|| "Source NA":item.type==="realtor_agent"?item.MemberPreferredPhone || item.MemberMobilePhone || item.MemberDirectPhone || "Phone NA":item.contact_number || item.phoneNumber}
                />
              </Card>
            </Grid>
          )):""}
      </Grid>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "PeoplesStyle" })(Peoples);
