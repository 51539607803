import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Button,
  Typography,
  Stack,
  Box,
  CircularProgress,
  TextField,
  MenuItem,
  Backdrop,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import trainingIcon from "../../../../assets/images/agents/trainingIcon.png";
import IssuesDetailModal from "../modals/issueDetail";
import AddAppointmentModal from "../modals/addAppointment";
import { useParams } from "react-router-dom";
import { getAgentTrainingProgress } from "../../../../redux/agents/agentRosterDetail/trainingProgress";
import { useDispatch, useSelector } from "react-redux";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import { getOverAllKnowledgeBaseData } from "../../../../redux/knowledge/knowledgeSearch";
import { getTrainingIssue } from "../../../../redux/agents/agentRosterDetail/trainingIssue";
import { getTrainingMentors } from "../../../../redux/agents/agentRoster/getMentors";
import ViewCarouselRoundedIcon from "@mui/icons-material/ViewCarouselRounded";
import moment from "moment";
import TrainingVideos from "../../../users/skeltons/trainingVideos";
import TrainingIssues from "../../../users/skeltons/trainingIssues";
import VideoViewedHistory from "../modals/videoViewedHistory";
import EnrollMemberShipModal from "../modals/EnrollMemberShip";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Avatar from "../../../../components/uiElements/avatar/index";
import { updateTrainingEnrollment } from "../../../../redux/agents/agentRoster/updateEnroll";
import { updateAgentRosterProfile } from "../../../../redux/agents/agentRoster/updateProfile";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import ResponseAlert from "../../../../components/responseAlert";
import MemberShipConfirmation from "../modals/memberShipConfirmation";
const AgentTrainingTab = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [openEnroll, setOpenEnroll] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [issueType, setIssueType] = useState("add");
  const [openAppointment, setOpenAppointment] = useState(false);
  const [issueDetail, setIssueDetail] = useState({});
  const [openVideoHistory, setOpenVideoHistory] = useState(false);
  const [openConfirmationDetail, setOpenConfirmationDetail] = useState({
    open: false,
    isConfirm: false,
    confirmationText: "",
    field: "",
    fieldLabel: "",
    lead_assigned_date: "",
    graduation_date: "",
  });
  const [defaultStage, setDefaultStage] = useState("");
  const [defaultEnrolledStage, setDefaultEnrolledStage] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const { id } = useParams();
  const AgentTrainingProgress = useSelector(
    (state) => state.agentRoster.AgentTrainingProgress
  );
  const adminAuth = useSelector((state) => state.adminAuth);
  const trainingIssues = useSelector(
    (item) => item.agentRoster.AgentTrainingIssues.trainingIssues
  );
  const profile = useSelector(
    (item) => item.agentRoster?.agentRosterProfile?.profile
  );
  const updateEnroll = useSelector(
    (item) => item.agentRoster?.agentRosterProfile?.updateEnroll
  );
  useEffect(() => {
    if (!AgentTrainingProgress.isLoading) {
      dispatch(getAgentTrainingProgress({ id }));
    }
    dispatch(getOverAllKnowledgeBaseData());
    dispatch(getTrainingIssue({ id }));
    dispatch(getTrainingMentors());
  }, []);

  useEffect(() => {
    if (profile.stage) {
      setDefaultStage(profile.stage);
    }
  }, [profile.id]);

  useEffect(() => {
    if (profile.mentorship_enrollment?.id) {
      setDefaultEnrolledStage(profile.mentorship_enrollment?.stage);
    }
  }, [profile.mentorship_enrollment?.id]);

  const handleSuccess = () => {
    handleCloseModal(true);
    setErrorAlert({
      errorMsg: "You have successfully updated",
      errorType: "success",
      isOpen: true,
    });
  };
  const handleError = (errMsg) => {
    setErrorAlert({
      errorMsg: JSON.stringify(errMsg),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleConfirm = () => {
    let obj = {};
    if (!openConfirmationDetail.isConfirm) {
      obj[openConfirmationDetail.field] =
        openConfirmationDetail[openConfirmationDetail.field];
      if (openConfirmationDetail.field === "lead_assigned_date") {
        obj["is_lead_assigned"] = true;
      } else {
        obj["stage"] = defaultEnrolledStage;
      }
    }
    if (openConfirmationDetail.isConfirm) {
      if (openConfirmationDetail.field === "is_lead_assigned") {
        obj["is_lead_assigned"] = false;
      } else {
        obj["stage"] = defaultEnrolledStage;
      }
    }
    dispatch(
      updateTrainingEnrollment({
        schema: { ...obj },
        handleSuccess,
        handleError,
        id: profile?.mentorship_enrollment?.id,
      })
    );
  };
  const handleCloseModal = (flag) => {
    setOpenConfirmationDetail({
      open: false,
      isConfirm: false,
      field: "",
      fieldLabel: "",
      confirmationText: "",
    });
    if (!flag) {
      setDefaultEnrolledStage(profile.mentorship_enrollment?.stage);
    }
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item lg={6} xs={12}>
        <Card
          variant="outlined"
          sx={{ backgroundColor: "white" }}
          className={classes.pageRootTraining}
        >
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={"Training Stage"}
            sx={{ padding: "6px 10px 4px 16px" }}
            action={
              <TextField
                value={defaultStage}
                select
                className={classes.trainingStageDropdown}
                size="small"
                onChange={(e) => {
                  if (
                    adminAuth?.adminDetail?.role?.name === "Admin" ||
                    adminAuth?.adminDetail?.role?.name === "Team Leader"
                  ) {
                    dispatch(
                      updateAgentRosterProfile({ stage: e.target.value, id })
                    );
                    setDefaultStage(e.target.value);
                  }
                }}
                disabled={
                  adminAuth?.adminDetail?.role?.name === "Admin" ||
                  adminAuth?.adminDetail?.role?.name === "Team Leader"
                    ? false
                    : true
                }
              >
                <MenuItem value="Orientation"> Orientation</MenuItem>
                <MenuItem value="Onboarded"> Onboarded</MenuItem>
                <MenuItem value="Freshman"> Freshman</MenuItem>
                <MenuItem value="Sophomore"> Sophomore</MenuItem>
                <MenuItem value="Junior"> Junior</MenuItem>
                <MenuItem value="Senior"> Senior</MenuItem>
                <MenuItem value="Graduated"> Graduated</MenuItem>
              </TextField>
            }
          />
        </Card>
        <Card
          variant="outlined"
          sx={{ backgroundColor: "white", minHeight: "30vh" }}
          className={classes.pageRootTraining}
        >
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Training Issues
                <IconButton
                  onClick={() => {
                    setIssueType("add");
                    setOpen(true);
                  }}
                  aria-label="locked"
                >
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton>
              </div>
            }
            action={
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "#0B0909",
                  fontWeight: "400",
                  marginRight: "10px",
                  border: " 1px solid rgba(33, 33, 33, 0.4)",
                }}
                size="small"
                color="inherit"
              >
                View all
              </Button>
            }
          />
          <CardContent>
            {trainingIssues.isLoading && <TrainingIssues />}
            <Grid container direction="row" spacing={6}>
              {!trainingIssues.isLoading &&
                trainingIssues.data?.trainingIssues?.length > 0 &&
                trainingIssues.data?.trainingIssues.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <Card>
                      <CardHeader
                        classes={{
                          title: classes.agentProfileDetailHeader,
                          subheader: classes.agentProfileDetail,
                        }}
                        avatar={
                          <WarningAmberRoundedIcon
                            sx={{
                              color: "rgba(219, 10, 10, 0.6)",
                              fontSize: "40px",
                            }}
                          />
                        }
                        title={`${item.issueType} | ${item.issueCategory}`}
                        subheader={`by ${
                          item.issue_created_by?.first_name ||
                          item.issue_created_by?.full_name
                        } ${item.issue_created_by?.last_name || ""} on ${moment(
                          item.created || new Date()
                        ).format("MMMM Do YYYY, h:mm:ss a")}`}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {item.note}
                        </Typography>
                        <br />
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Last Updated:{" "}
                            {moment(item.updated || new Date()).format("l")} (
                            {moment(
                              moment(new Date(item.updated).toString())
                            ).from(moment(new Date()))}
                            ) by{" "}
                            {item.issue_updated_by?.first_name ||
                              item.issue_updated_by?.full_name}{" "}
                            {item.issue_updated_by?.last_name}
                          </Typography>
                          <Button
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                            }}
                            onClick={() => {
                              setIssueType("update");
                              setOpen(true);
                              setIssueDetail(item);
                            }}
                          >
                            View
                          </Button>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            {!trainingIssues.isLoading &&
            trainingIssues.data?.trainingIssues?.length === 0 ? (
              <Box className={classes.loadingArea}>
                <DataNotFound />
              </Box>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Card
          variant="outlined"
          sx={{ backgroundColor: "white" }}
          className={classes.pageRootTraining}
        >
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={"Mentorship Program"}
            sx={{ padding: "6px 10px 4px 16px" }}
            action={
              profile?.mentorship_enrollment?.id ? (
                <Box className={classes.enrolledBadgeArea}>
                  <Typography className={classes.enrolledBadgeHeading}>
                    Enrolled
                  </Typography>
                  <Typography className={classes.enrolledBadgeContent}>
                    {moment(
                      profile?.mentorship_enrollment?.enrollment_date
                    ).format("MMMM Do YYYY")}
                  </Typography>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  className={classes.enrollButton}
                  color="inherit"
                  onClick={() => {
                    if (
                      adminAuth?.adminDetail?.role?.name === "Admin" ||
                      adminAuth?.adminDetail?.role?.name === "Team Leader"
                    ) {
                      setOpenEnroll(true);
                    }
                  }}
                  disabled={
                    adminAuth?.adminDetail?.role?.name === "Admin" ||
                    adminAuth?.adminDetail?.role?.name === "Team Leader"
                      ? false
                      : true
                  }
                >
                  Enroll
                </Button>
              )
            }
          />
          {profile?.mentorship_enrollment?.id ? (
            <CardContent>
              <Box className={classes.mentorSection}>
                <Typography className={classes.mentorSectionLabel}>
                  Mentor:
                </Typography>
                <Box className={classes.mentorSectionBadge}>
                  <Avatar
                    src={
                      profile?.mentorship_enrollment?.mentor?.profile_images
                        ?.profile_img ||
                      profile?.mentorship_enrollment?.mentor?.profile_images
                        ?.profile_img_thumbnail
                    }
                    sx={{
                      width: "25px",
                      height: "25px",
                      fontSize: "12px",
                    }}
                  >
                    {`${profile?.mentorship_enrollment?.mentor?.full_name?.slice(
                      0,
                      1
                    )}${profile?.mentorship_enrollment?.mentor?.full_name
                      ?.split(" ")[1]
                      ?.slice(0, 1)}`}
                  </Avatar>
                  <Typography className={classes.mentorSectionBadgeContent}>
                    {profile?.mentorship_enrollment?.mentor?.full_name}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.mentorSection}>
                <Typography className={classes.mentorSectionLabel}>
                  Stage:
                </Typography>
                <TextField
                  value={
                    defaultEnrolledStage ||
                    profile?.mentorship_enrollment?.stage
                  }
                  select
                  className={classes.trainingStageDropdown}
                  size="small"
                  onChange={(e) => {
                    setOpenConfirmationDetail({
                      open: true,
                      isConfirm: e.target.value === "Graduated" ? false : true,
                      field:
                        e.target.value === "Graduated" ? "graduation_date" : "",
                      fieldLabel:
                        e.target.value === "Graduated" ? "Graduation Date" : "",
                      confirmationText:
                        e.target.value === "Graduated"
                          ? ""
                          : `This action will set the user’s Mentor Stage back <br /> to <span style="color:rgba(0, 52, 236, 0.87) !important">${e.target.value}</span>`,
                    });
                    setDefaultEnrolledStage(e.target.value);
                  }}
                >
                  <MenuItem value="Onboarded"> Onboarded</MenuItem>
                  <MenuItem value="In-Class"> In-Class</MenuItem>
                  <MenuItem value="Graduated"> Graduated</MenuItem>
                </TextField>
                {profile?.mentorship_enrollment?.graduation_date ? (
                  <Typography className={classes.mentorSectionLabelPost}>
                    Graduation Date{" "}
                    {moment(
                      profile?.mentorship_enrollment?.graduation_date
                    ).format("l")}
                  </Typography>
                ) : null}
              </Box>
              <Box
                className={classes.mentorSection}
                sx={{ mb: "0px !important" }}
              >
                <Typography className={classes.mentorSectionLabel}>
                  Leads:
                </Typography>
                {profile?.mentorship_enrollment?.is_lead_assigned ? (
                  <CustomTooltip
                    title="Mark as No Assigned Leads"
                    placement="top"
                  >
                    <Box
                      onClick={() =>
                        setOpenConfirmationDetail({
                          open: true,
                          isConfirm: true,
                          field: "is_lead_assigned",
                          fieldLabel: "",
                          confirmationText: `This action will reset the Mentor Leads note to <br /> <span style="color:rgba(255, 0, 0, 0.87) !important">No Leads Assigned</span>.`,
                        })
                      }
                      className={classes.mentorSectionBadge}
                    >
                      <CheckCircleRoundedIcon sx={{ color: "#23cb3e" }} />
                      <Typography className={classes.mentorSectionBadgeContent}>
                        Leads Assigned
                      </Typography>
                    </Box>
                  </CustomTooltip>
                ) : (
                  <CustomTooltip title="Mark as Assigned Leads" placement="top">
                    <Box
                      onClick={() =>
                        setOpenConfirmationDetail({
                          open: true,
                          isConfirm: false,
                          field: "lead_assigned_date",
                          fieldLabel: "Leads Assigned On",
                          confirmationText: "",
                          lead_assigned_date: "",
                        })
                      }
                      className={classes.mentorSectionBadge}
                    >
                      <CancelRoundedIcon
                        sx={{ color: "rgba(230, 20, 20, 1)" }}
                      />
                      <Typography className={classes.mentorSectionBadgeContent}>
                        No Assigned Leads
                      </Typography>
                    </Box>
                  </CustomTooltip>
                )}

                {profile?.mentorship_enrollment?.lead_assigned_by_name ? (
                  <Typography className={classes.mentorSectionLabelPost}>
                    By {profile?.mentorship_enrollment?.lead_assigned_by_name}{" "}
                    on{" "}
                    {moment(
                      profile?.mentorship_enrollment?.lead_assigned_date
                    ).format("L")}
                  </Typography>
                ) : null}
              </Box>
            </CardContent>
          ) : null}
        </Card>
        <Card
          variant="outlined"
          sx={{ backgroundColor: "white" }}
          className={classes.pageRootTraining}
        >
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Videos Viewed
                {/* <IconButton aria-label="locked">
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton> */}
              </div>
            }
            action={
              AgentTrainingProgress?.data?.count > 4 ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "white",
                    color: "#0B0909",
                    fontWeight: "400",
                    marginRight: "10px",
                    border: " 1px solid rgba(33, 33, 33, 0.4)",
                  }}
                  color="inherit"
                  onClick={() => setOpenVideoHistory(true)}
                >
                  View all
                </Button>
              ) : null
            }
          />
          <CardContent
            sx={{
              "& .MuiCardHeader-action": {
                mt: "10px",
                mr: "6px",
                mb: "0px",
              },
            }}
          >
            {AgentTrainingProgress.isLoading && <TrainingVideos />}
            <Grid container direction="row" spacing={6}>
              {!AgentTrainingProgress.isLoading &&
                AgentTrainingProgress?.data?.viewedEvents?.length > 0 &&
                AgentTrainingProgress?.data?.viewedEvents
                  ?.slice(0, 4)
                  ?.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <Card>
                        <CardHeader
                          classes={{
                            title: classes.agentProfileDetailHeader,
                            subheader: classes.agentProfileDetail,
                          }}
                          avatar={
                            <img
                              src={
                                item.post?.thumbnail
                                  ? item.post?.thumbnail
                                  : trainingIcon
                              }
                              alt={"Training Icon"}
                              style={{ maxWidth: "150px" }}
                            />
                          }
                          title={item.post?.title}
                          subheader={moment(
                            item.updatedAt || new Date()
                          ).format("LL")}
                          action={
                            <div>
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-flex",
                                }}
                              >
                                <CircularProgress
                                  size={50}
                                  sx={{
                                    boxShadow: "inset 0 0 0px 4px #EEEEEE",
                                    backgroundColor: "transparent",
                                    borderRadius: "100%",
                                  }}
                                  value={item.progress}
                                  color="inherit"
                                  variant="determinate"
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                  >
                                    {`${item.progress}%`}
                                  </Typography>
                                </Box>
                              </Box>
                            </div>
                          }
                        />
                      </Card>
                    </Grid>
                  ))}
            </Grid>
            {!AgentTrainingProgress.isLoading &&
            AgentTrainingProgress?.data?.viewedEvents?.length === 0 ? (
              <Box className={classes.loadingArea}>
                <DataNotFound
                  color="#BDBDBD"
                  fontSize="26px"
                  title="No Activity"
                  icon={
                    <ViewCarouselRoundedIcon
                      sx={{ fontSize: "120px", color: "#BDBDBD" }}
                      fontSize="large"
                    />
                  }
                />
              </Box>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <IssuesDetailModal
        open={open}
        appointments={appointments}
        setAppointments={setAppointments}
        setOpen={setOpen}
        issueDetails={issueDetail}
        issueType={issueType}
        setOpenAppointment={setOpenAppointment}
      />
      <AddAppointmentModal
        setAppointments={setAppointments}
        appointments={appointments}
        open={openAppointment}
        issueDetails={issueDetail}
        setOpen={setOpenAppointment}
      />
      <VideoViewedHistory
        open={openVideoHistory}
        id={id}
        eventData={AgentTrainingProgress?.data?.viewedEvents}
        setOpen={setOpenVideoHistory}
      />
      <EnrollMemberShipModal open={openEnroll} setOpen={setOpenEnroll} />
      <MemberShipConfirmation
        open={openConfirmationDetail.open}
        handleCloseModal={handleCloseModal}
        setOpenConfirmationDetail={setOpenConfirmationDetail}
        handleConfirm={handleConfirm}
        openConfirmationDetail={openConfirmationDetail}
        isLoading={updateEnroll.isLoading}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Backdrop color="inherit" invisible={true} open={updateEnroll.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default withStyles(styleSheet, { name: "AgentTrainingTabStyle" })(
  AgentTrainingTab
);
