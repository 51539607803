import React, { useRef, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Avatar from "../../components/uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import AgentListSkelton from "./skelton/agentList";
import moment from "moment";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import momentTZ from "moment-timezone";
import SendMessage from "./sendMessage";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../../components/shared/tooltip";
function LeadsChatHistoryArea(props) {
  let messagesEnd = useRef(null);
  let { classes, selectedUser, handleClose } = props;
  const { data: requestDetail, isLoading } = useSelector(
    (state) => state.AgentRequests.requestDetail
  );
  const adminAuth = useSelector((state) => state.adminAuth);

  useEffect(() => {
    if (requestDetail?.comments?.length) {
      messagesEnd.current.scrollIntoView();
    }
  }, [requestDetail?.comments?.length]);

  return (
    <React.Fragment>
      <Box className={classes.chatBodyContent} sx={{ flex: "1" }}>
        {isLoading ? (
          <AgentListSkelton count={4} />
        ) : requestDetail?.comments?.length && !isLoading ? (
          requestDetail?.comments?.map((data) => (
            <Box className={classes.chatWrapper}>
              {data.type === "call" ? (
                <Box className={classes.loggedInUserChat}>
                  <Box
                    sx={{ background: "transparent" }}
                    className={classes.callTypeReceiver}
                  >
                    <Box className={classes.iconAvatar}>
                      <Typography
                        sx={{
                          mr: "16px",
                          mt: "7px",
                        }}
                        className={classes.dateWrap}
                      >
                        {" "}
                        {moment(data.createdAt).isSame(moment(), "day")
                          ? momentTZ
                              .tz(data.createdAt, "America/New_York")
                              ?.format("LT")
                          : momentTZ
                              .tz(data.createdAt, "America/New_York")
                              ?.format("ll")
                              ?.split(",")[0]}
                      </Typography>
                      <LocalPhoneRoundedIcon
                        sx={{ mt: "7px", mr: "16px", color: "grey" }}
                      />
                      <Typography
                        sx={{ mt: "7px", fontWeight: "500", color: "black" }}
                      >
                        {" "}
                        {reactStringReplace(
                          data.comment || "",
                          /@\[(.*?)]/g,
                          (match, i) => (
                            <CustomTooltip
                              title={match?.split("^")?.[0]}
                              placement="top"
                            >
                              <Box
                                sx={{
                                  color:
                                    match?.split("^")?.[1] ==
                                    adminAuth?.adminDetail.id
                                      ? "#007EA6 !important"
                                      : "#434343 !important",
                                  background:
                                    match?.split("^")?.[1] ==
                                    adminAuth?.adminDetail.id
                                      ? "#CCF3FF !important"
                                      : "#ECECEC !important",
                                  display: "inline-block",
                                  textDecoration: "none",
                                }}
                                key={i}
                                to={`/agent_roster/${
                                  match?.split("^")?.[1] || 69
                                }/overview`}
                                component={Link}
                              >
                                @{match?.split(" ")?.[0]}
                              </Box>
                            </CustomTooltip>
                          )
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : data?.agent?.id !== adminAuth?.adminDetail?.id ? (
                <Box className={classes.loggedOutUserChat}>
                  <Box className={classes.loggedOutUserChatList}>
                    <Box className={classes.loggedOutUserChatInnerBlock}>
                      <Avatar
                        sx={{ fontSize: "16px" }}
                        src={
                          data?.agent?.profile_images?.profile_img_thumbnail ||
                          data?.agent?.profile_images?.profile_img
                        }
                      >
                        {`${data.agent?.full_name?.slice(
                          0,
                          1
                        )}${data.agent?.full_name?.split(" ")[1]?.slice(0, 1)}`}
                      </Avatar>
                      <Typography sx={{ mt: "7px", flex: 1 }}>
                   {reactStringReplace(
                          data.comment || "",
                          /@\[(.*?)]/g,
                          (match, i) => (
                            <CustomTooltip
                              title={match?.split("^")?.[0]}
                              placement="top"
                            >
                              <Box
                                sx={{
                                  color:
                                    match?.split("^")?.[1] ==
                                    adminAuth?.adminDetail.id
                                      ? "#007EA6 !important"
                                      : "#434343 !important",
                                  background:
                                    match?.split("^")?.[1] ==
                                    adminAuth?.adminDetail.id
                                      ? "#CCF3FF !important"
                                      : "#ECECEC !important",
                                  display: "inline-block",
                                  textDecoration: "none",
                                  fontWeight: "500",
                                }}
                                key={i}
                                to={`/agent_roster/${
                                  match?.split("^")?.[1] || 69
                                }/overview`}
                                component={Link}
                              >
                                @{match?.split(" ")?.[0]}
                              </Box>
                            </CustomTooltip>
                          )
                        )}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ mr: "10px" }}
                      className={classes.dateWrap}
                    >
                      {" "}
                      {moment(data?.createdAt).fromNow()}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  className={classes.loggedInUserChat}
                  sx={{ opacity: data.isLoading ? "0.4" : "1" }}
                >
                  <Box className={classes.loggedInUserChatList}>
                    <Typography
                      sx={{ ml: "10px" }}
                      className={classes.dateWrap}
                    >
                      {moment(data?.createdAt).fromNow()}
                    </Typography>
                    <Box className={classes.loggedInUserChatInnerBlock}>
                      <Typography sx={{ mt: "7px", flex: 1 }}>
                        {reactStringReplace(
                          data.comment || "",
                          /@\[(.*?)]/g,
                          (match, i) => (
                            <CustomTooltip
                              title={match?.split("^")?.[0]}
                              placement="top"
                            >
                              <Box
                                sx={{
                                  color:
                                    match?.split("^")?.[1] ==
                                    adminAuth?.adminDetail.id
                                      ? "#007EA6 !important"
                                      : "#434343 !important",
                                  background:
                                    match?.split("^")?.[1] ==
                                    adminAuth?.adminDetail.id
                                      ? "#CCF3FF !important"
                                      : "#ECECEC !important",
                                  display: "inline-block",
                                  textDecoration: "none",
                                  fontWeight: "500",
                                }}
                                key={i}
                                to={`/agent_roster/${
                                  match?.split("^")?.[1] || 69
                                }/overview`}
                                component={Link}
                              >
                                @{match?.split(" ")?.[0]}
                              </Box>
                            </CustomTooltip>
                          )
                        )}
                      </Typography>
                      <Avatar
                        sx={{ fontSize: "16px" }}
                        src={adminAuth?.adminDetail?.profile_img}
                      >
                        {adminAuth?.adminDetail?.first_name
                          ? `${adminAuth?.adminDetail?.first_name.slice(
                              0,
                              1
                            )}${adminAuth?.adminDetail?.last_name.slice(0, 1)}`
                          : "NA"}
                      </Avatar>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))
        ) : null}
        <div ref={messagesEnd} />
      </Box>
      <SendMessage selectedUser={selectedUser} handleClose={handleClose} />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "LeadsChatHistoryAreaStyle" })(
  LeadsChatHistoryArea
);
