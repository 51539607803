import * as React from "react";
import { Box, Typography, Grid, MenuItem, Card, TextField } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";

const Terms = (props) => {
  let { classes, updateContractDetail, contractDetail, errMsg } = props;
  return (
    <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible" }}>
      <Typography classes={{ root: classes.transactionFormCardHeading }}>Terms</Typography>
      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={4} md={3}>
          <TextField
            value={contractDetail.price}
            onChange={(e) => updateContractDetail("price", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="$12.25"
            helperText="Price"
            error={errMsg["price"]}
            id="price"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.binding_date}
            onChange={(e) => updateContractDetail("binding_date", e.target.value)}
            fullWidth
            variant="standard"
            type="date"
            helperText="Binding Date"
            error={errMsg["binding_date"]}
            id="binding_date"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.closing_date}
            onChange={(e) => updateContractDetail("closing_date", e.target.value)}
            fullWidth
            variant="standard"
            type="date"
            helperText="Closing Date"
            error={errMsg["closing_date"]}
            id="closing_date"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.due_dilligence_days}
            onChange={(e) => updateContractDetail("due_dilligence_days", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="25"
            helperText="Due Dilligece Days"
            error={errMsg["due_dilligence_days"]}
            id="due_dilligence_days"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.financing_type}
            onChange={(e) => updateContractDetail("financing_type", e.target.value)}
            fullWidth
            select
            variant="standard"
            placeholder="Debt financing"
            helperText="Financing Type"
            error={errMsg["financing_type"]}
            id="financing_type"
          >
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="conventional">conventional</MenuItem>
            <MenuItem value="fha">FHA</MenuItem>
            <MenuItem value="other">Other</MenuItem>
            <MenuItem value="va">VA</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            value={contractDetail.finance_contingency_days}
            onChange={(e) => updateContractDetail("finance_contingency_days", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="10"
            helperText="Finance Contingency Days"
            error={errMsg["finance_contingency_days"]}
            id="finance_contingency_days"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.appraisal_contingency_days}
            onChange={(e) => updateContractDetail("appraisal_contingency_days", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="10"
            helperText="Appraisal Contingency Days"
            error={errMsg["appraisal_contingency_days"]}
            id="appraisal_contingency_days"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.earnest_money_amount}
            onChange={(e) => updateContractDetail("earnest_money_amount", e.target.value)}
            fullWidth
            variant="standard"
            placeholder="$5,000"
            helperText="EM amount"
            error={errMsg["earnest_money_amount"]}
            id="earnest_money_amount"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.earnest_money_holder}
            onChange={(e) => updateContractDetail("earnest_money_holder", e.target.value)}
            fullWidth
            variant="standard"
            select
            error={errMsg["earnest_money_holder"]}
            helperText="Holder"
            id="earnest_money_holder"
          >
            <MenuItem value="Coop Broker">Coop Broker</MenuItem>
            <MenuItem value="Closing Attorney">Closing Attorney</MenuItem>
            <MenuItem value="Method">Method</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={contractDetail.earnest_money_due_date}
            onChange={(e) => updateContractDetail("earnest_money_due_date", e.target.value)}
            fullWidth
            variant="standard"
            type="date"
            error={errMsg["earnest_money_due_date"]}
            helperText="Earnest Money Due "
            id="earnest_money_due_date"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default withStyles(styleSheet, { name: "TermsStyle" })(Terms);
