import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Stack, Typography, Skeleton, Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddExpenseModal from "../modals/addExpenseModal";
import AddAwardModal from "../modals/addAwardBudget";
import ApplyAwardModal from "../modals/applyAward";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAgentTabs } from "../../../../redux/agents/agentRosterDetail/agentTabs";
import moment from "moment";
import ExpenseDetailModal from "../modals/expenseDetail";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import RequestPageRoundedIcon from "@mui/icons-material/RequestPageRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import ReceivePaymentModal from "../modals/receivePayment";
import GenerateStatement from "../modals/generateStatement";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../../components/loader/tableLoader.jsx";

const AgentRosterTabs = ({ classes }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openAddExpense, setOpenAddExpense] = useState(false);
  const [openExpenseDetail, setOpenExpenseDetail] = useState(false);
  const [expenseDetail, setExpenseDetail] = useState({});
  const [openAddAward, setOpenAddAward] = useState(false);
  const [openApplyAward, setOpenApplyAward] = useState(false);
  const [openReceivePayment, setOpenReceivePayment] = useState(false);
  const [openGenerateModal, setOpenGenerateModal] = useState(false);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
    search: "",
  });
  const tabsData = useSelector((state) => state.agentRoster.AgentTabs.tabsData);

  const columns = [
    {
      field: "Date",
      headerName: "Date",
      renderCell: ({ row }) => {
        return moment(row.createdAt || new Date()).format("MM/DD/YY");
      },
      width: 90,
      editable: false,
      sortable: true,
    },
    {
      field: "Category",
      headerName: "Category",
      renderCell: ({ row }) => {
        return <Box sx={{textTransform:"capitalize"}}>{row.category==="mls_fee"?"MLS Fee":row.category?.replace("_", " ") || "Credit"}</Box>;
      },
      width: 110,
      editable: false,
      sortable: true,
    },
    {
      field: "Title",
      headerName: "Title",
      renderCell: ({ row }) => {
        return <Box sx={{textTransform:"capitalize"}}>{row.title?.replace("_", " ") || "Credit"}</Box>;
      },
      minWidth: 250,
      editable: false,
      sortable: true,
      flex: 1.4,
    },
    {
      field: "Debit",
      headerName: "Debit",
      renderCell: ({ row }) => {
        return (
          <Box align="center" sx={{ color: "#C62828" }}>
            {row.debit ? `$${Number(row.debit || 0)?.toFixed(2)}` : ""}
          </Box>
        );
      },
      width: 100,
      editable: false,
      sortable: true,
    },
    {
      field: "Credit",
      headerName: "Credit",
      renderCell: ({ row }) => {
        return (
          <Box align="center" sx={{ color: "#2E7D32" }}>
            {row.credit ? `$${Number(row.credit || 0)?.toFixed(2)}` : ""}
          </Box>
        );
      },
      width: 100,
      editable: false,
      sortable: true,
    },
    {
      field: "Balance",
      headerName: "Balance",
      renderCell: ({ row }) => {
        return <Box align="center">{row.balance ? `$${Number(row.balance || 0)?.toFixed(2)}` : "$0.00"}</Box>;
      },
      width: 100,
      editable: false,
      sortable: true,
    },
    {
      field: "Marketing Debit",
      headerName: "Marketing Debit",
      renderCell: ({ row }) => {
        return (
          <Box align="center" sx={{ color: "#C62828" }}>
            {row.marketing_debit ? `$${Number(row.marketing_debit || 0)?.toFixed(2)}` : ""}
          </Box>
        );
      },
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "Marketing Credit",
      headerName: "Marketing Credit",
      renderCell: ({ row }) => {
        return (
          <Box align="center" sx={{ color: "#2E7D32" }}>
            {row.marketing_credit ? `$${Number(row.marketing_credit || 0)?.toFixed(2)}` : ""}
          </Box>
        );
      },
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "Marketing Balance",
      headerName: "Marketing Balance",
      renderCell: ({ row }) => {
        return <Box align="center">{row.marketing_balance ? `$${Number(row.marketing_balance || 0)?.toFixed(2)}` : "$0.00"}</Box>;
      },
      alignItems: "center",
      width: 135,
      editable: false,
      sortable: true,
    },
  ];

  useEffect(() => {
    dispatch(getAgentTabs({ id, ...filters }));
  }, [filters]);

  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ backgroundColor: "white", padding: "15px 15px 15px 10px" }} className={classes.pageRootPayment}>
        <CardHeader
          classes={{ title: classes.pageHeading }}
          title="Agent’s Tab"
          action={
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <ButtonGroups
                id="Manage-button-menu-inventory"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="36px"
                size="medium"
                color="white"
                minWidth="140px"
                options={[
                  {
                    title: "Add Expense",
                    value: "expense",
                    icon: <MonetizationOnRoundedIcon />,
                  },
                  { title: "Award Budget", value: "award_budget", icon: <EmojiEventsRoundedIcon /> },
                  { title: "Apply Budget", value: "apply_budget", icon: <StarsRoundedIcon /> },
                  { title: "Receive Payment", value: "receive_payment", icon: <CreditCardRoundedIcon /> },
                  { title: "Generate Statement", value: "generate_statement", icon: <RequestPageRoundedIcon /> },
                ]}
                onChangeMenu={(value) => {
                  if (value === "expense") {
                    setOpenAddExpense(true);
                  } else if (value === "award_budget") {
                    setOpenAddAward(true);
                  } else if (value === "apply_budget") {
                    setOpenApplyAward(true);
                  } else if (value === "receive_payment") {
                    setExpenseDetail({})
                    setOpenReceivePayment("add");
                  } else if (value === "generate_statement") {
                    setOpenGenerateModal(true);
                  }
                }}
                itemFontSize="14px"
                placeholder={"Manage"}
              />
            </Stack>
          }
        />
        <CardContent>
          <Typography>
            <Typography sx={{ display: "inline-block", fontSize: "16px",mr:"5px" }} variant="subtitle2" gutterBottom>
              Total Balance:
            </Typography>{" "}
            {tabsData.isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "1.5rem", display: "inline-block", width: "150px" }} />
            ) : (
              `$${tabsData?.data?.user?.balance ? Number(tabsData?.data?.user?.balance || "0")?.toFixed(2) : "0.0"}`
            )}
          </Typography>
          <Typography>
            <Typography sx={{ display: "inline-block", fontSize: "16px",mr:"10px" }} variant="subtitle2" gutterBottom>
              Total Marketing Balance:{" "}
            </Typography>
            {tabsData.isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "1.5rem", display: "inline-block", width: "150px" }} />
            ) : (
              `$${tabsData?.data?.user?.marketing_balance ? Number(tabsData?.data?.user?.marketing_balance || "0")?.toFixed(2) : "0.0"}`
            )}
          </Typography>
          <Box className={classes.tableRoot}>
            <DataGridPro
              rows={tabsData?.data?.usersTabs?.length ? tabsData?.data?.usersTabs : []}
              loading={tabsData.isLoading}
              components={{
                LoadingOverlay: TableSkeleton,
              }}
              onRowClick={(data) => {
                if (data.row?.users_tabs_type === "add_expense") {
                  setExpenseDetail(data.row);
                  setOpenExpenseDetail(true);
                }
                if (data.row?.users_tabs_type === "receive_payment") {
                  setExpenseDetail(data.row);
                  setOpenReceivePayment("update");
                }
              }}
              onRowsScrollEnd={(e) => {
                if (tabsData.data?.count > e.virtualRowsCount && !tabsData.isLoading) {
                  setFilters({
                    ...filters,
                    pageNumber: Number(e.virtualRowsCount / 15) + 1,
                    pageSize: 15,
                  });
                }
              }}
              columns={columns}
              disableSelectionOnClick
              rowHeight={56}
            />
          </Box>
        </CardContent>
      </Card>
      <AddAwardModal open={openAddAward} setOpen={setOpenAddAward} />
      <AddExpenseModal open={openAddExpense} setOpen={setOpenAddExpense} />
      <ApplyAwardModal open={openApplyAward} setOpen={setOpenApplyAward} />
      <ExpenseDetailModal open={openExpenseDetail} setOpen={setOpenExpenseDetail} expenseData={expenseDetail} />
      <ReceivePaymentModal open={openReceivePayment} setOpen={setOpenReceivePayment} paymentData={expenseDetail} />
      <GenerateStatement open={openGenerateModal} setOpen={setOpenGenerateModal} />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "AgentRosterTabsStyle",
})(AgentRosterTabs);
