import { getGridDefaultColumnTypes } from "@mui/x-data-grid";

const defaultColumnTypes = getGridDefaultColumnTypes();
console.log(defaultColumnTypes);
export const phone = [
  ...defaultColumnTypes.__default__.filterOperators,
  { label: "is good", value: "isGood", getApplyFilterFn: defaultColumnTypes.boolean.filterOperators[0].getApplyFilterFn },

  { label: "is bad", value: "isBad", getApplyFilterFn: defaultColumnTypes.boolean.filterOperators[0].getApplyFilterFn },
];

export const date_or_price = [
  ...defaultColumnTypes.__default__.filterOperators,
  ...defaultColumnTypes.number.filterOperators,
  {
    label: "between",
    value: "<>",
    getApplyFilterFn: defaultColumnTypes.number.filterOperators[0].getApplyFilterFn,
  },
];

export const include = [...defaultColumnTypes.__default__.filterOperators, ...defaultColumnTypes.singleSelect.filterOperators];

export const price = [...defaultColumnTypes.__default__.filterOperators, ...defaultColumnTypes.number.filterOperators];
