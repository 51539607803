import React from "react";
import { Box, Skeleton, Grid, Stack } from "@mui/material";
function TaskDetailSkelton(props) {
  return (
    <Box>
      <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
        <Skeleton animation="wave" variant="rectangular" height={40} width="40%" />
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Skeleton animation="wave" variant="rectangular" height={30} width="80px" />
          <Skeleton animation="wave" variant="rectangular" height={30} width="60px" />
          <Skeleton animation="wave" variant="circular" height={30} width="40px" />
        </Stack>
      </Stack>
      <Grid container spacing={2} sx={{ mt: "30px" }}>
        <Grid item sm={12} md={4.4}>
          <Skeleton animation="wave" variant="rectangular" height={"70vh"} width="100%" />
        </Grid>
        <Grid item sm={12} md={7.6}>
          <Skeleton animation="wave" variant="rectangular" height={"70vh"} width="100%" />
        </Grid>
      </Grid>
    </Box>
  );
}
export default TaskDetailSkelton;
