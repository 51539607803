import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getTaskTemplates = createAsyncThunk(
  "TasksTemplates/getTaskTemplates",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }api/transactions/template?pageNumber=${
          data.pageNumber || 1
        }&pageSize=${data.pageSize || 25}&sortBy=id&sortOrder=desc&search=${
          data?.search || ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
