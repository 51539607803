export const numberBtnData = [
    {
      value: 1,
      subValue: ["a", "b", "c"],
    },
    {
      value: 2,
      subValue: ["d", "e", "f"],
    },
    {
      value: 3,
      subValue: ["g", "h", "i"],
    },
    {
      value: 4,
      subValue: ["j", "k", "l"],
    },
    {
      value: 5,
      subValue: ["m", "n", "o"],
    },
    {
      value: 6,
      subValue: ["p", "q", "r", "s"],
    },
    {
      value: 7,
      subValue: ["t", "u", "v"],
    },
    {
      value: 8,
      subValue: ["w", "x", "y", "z"],
    },
    {
      value: 9,
      subValue: [],
    },
    {
      value: "*",
      subValue: [],
    },
    {
      value: 0,
      subValue: [],
    },
    {
      value: "#",
      subValue: [],
    },
  ];