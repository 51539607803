import { createSlice } from "@reduxjs/toolkit";
import { getAllPhoneExtensions } from "./getAllExtensions";
import { addPhoneExtension } from "./addExtension";
import {updatePhoneExtension} from './updateExtension'

const initialState = {
  extensionsList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    count: 0,
  },
  addExtension: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateExtension: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const PhoneExtensions = createSlice({
  name: "PhoneExtensions",
  initialState,
  extraReducers: {
    [getAllPhoneExtensions.pending]: (state, action) => {
      state.extensionsList.isLoading = true;
      state.extensionsList.errMsg = null;
      state.extensionsList.success = false;
      if (action.meta.arg.reset) {
        state.extensionsList.data = [];
      }
    },
    [getAllPhoneExtensions.fulfilled]: (state, action) => {
      state.extensionsList.success = true;
      state.extensionsList.isLoading = false;
      state.extensionsList.data = action.payload;
    },
    [getAllPhoneExtensions.rejected]: (state, action) => {
      state.extensionsList.isLoading = false;
      state.extensionsList.success = false;
      state.extensionsList.errMsg = action.payload;
    },

    [addPhoneExtension.pending]: (state) => {
      state.addExtension.isLoading = true;
      state.addExtension.errMsg = null;
      state.addExtension.success = false;
    },
    [addPhoneExtension.fulfilled]: (state, action) => {
      state.addExtension.success = true;
      state.addExtension.isLoading = false;
      state.addExtension.data = action.payload;
      let newData=state.extensionsList.data?[...state.extensionsList.data]:[]
      newData.unshift(action.payload)
      state.extensionsList.data=newData
    },
    [addPhoneExtension.rejected]: (state, action) => {
      state.addExtension.isLoading = false;
      state.addExtension.success = false;
      state.addExtension.errMsg = action.payload;
    },


    [updatePhoneExtension.pending]: (state) => {
      state.updateExtension.isLoading = true;
      state.updateExtension.errMsg = null;
      state.updateExtension.success = false;
    },
    [updatePhoneExtension.fulfilled]: (state, action) => {
      state.updateExtension.success = true;
      state.updateExtension.isLoading = false;
      state.updateExtension.data = action.payload;
      let newData=state.extensionsList.data?[...state.extensionsList.data]:[]
      let index=newData.findIndex((item)=>item.id===action.payload.id)
      newData[index]={...newData[index],...action.payload}
      state.extensionsList.data=newData
    },
    [updatePhoneExtension.rejected]: (state, action) => {
      state.updateExtension.isLoading = false;
      state.updateExtension.success = false;
      state.updateExtension.errMsg = action.payload;
    },

  },
});
export default PhoneExtensions.reducer;
export * from "./getAllExtensions";
export * from "./addExtension";
export * from "./updateExtension"
