import React, { useState } from "react";
import { Dialog, Box, IconButton } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import TextTab from "../../../container/leads/common/Tabs/TabContent/TextTab";
import ResponseAlert from "../../responseAlert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import { handleAddMinimizeTab } from "../../../redux/widgets/index";
import { handleUpdateModalState } from "../../../redux/widgets";
import { uid } from "uid";

function SendTextModal() {
  const dispatch = useDispatch();
  const [minimizeFlag, setMinimizeFlag] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const leadProfile = useSelector((item) => item.leadProfile.list);
  const open = useSelector((state) => state.widgets.sendTextOpen);
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const maximizedTab = useSelector((state) => state.widgets.maximizedTab);

  const handleClose = () => {
    dispatch(handleUpdateModalState({ type: "sendTextOpen", action: false }));
  };
  const handleAddMinimize = (data) => {
    dispatch(
      handleAddMinimizeTab({
        title: data.toNumber && data.toNumber[0] ? data.toNumber[0] : "New Text Message",
        type: "text",
        id: `${uid()}text`,
        modalType: "sendTextOpen",
        action: false,
        tabDetail: { ...data },
      })
    );
    setMinimizeFlag(false);
  };
  // get the position for widgets modal
  // we need to get the position related to other widget model

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          sx: {
            position: "fixed",
            right:
              rightPositions?.length && rightPositions.find((item) => item.type === "sendTextOpen")
                ? rightPositions.find((item) => item.type === "sendTextOpen").right
                : -20,
            bottom: -15,
            borderRadius: "6px",
            zIndex: 100000,
          },
        }}
        hideBackdrop
        disableEnforceFocus
        disableBackdropClick
      >
        <DialogContent sx={{ width: "700px" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "-20px" }}>
            <IconButton onClick={() => setMinimizeFlag(true)}>
              {" "}
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={() => handleClose()}>
              {" "}
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <TextTab
            IsPopup
            handleAddMinimize={handleAddMinimize}
            minimizeFlag={minimizeFlag}
            btnStyle={"btn-send-email"}
            leadProfile={leadProfile}
            disableSubject
            handleClosePopup={handleClose}
            maximizedTab={open ? maximizedTab : null}
            globalMsg={true}
          />
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "SendTextModalStyle" })(SendTextModal);
