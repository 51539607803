import React, { useState, useEffect } from "react";
import {  Card, CardContent, CardHeader, Grid, IconButton, Button, Typography, Stack, Box, CircularProgress } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import trainingIcon from "../../../../assets/images/agents/trainingIcon.png";
import IssuesDetailModal from "../modals/issueDetail";
import AddAppointmentModal from "../modals/addAppointment";
import { useParams } from "react-router-dom";
import { getAgentTrainingProgress } from "../../../../redux/agents/agentRosterDetail/trainingProgress";
import { useDispatch, useSelector } from "react-redux";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import { getOverAllKnowledgeBaseData } from "../../../../redux/knowledge/knowledgeSearch";
import { getTrainingIssue } from "../../../../redux/agents/agentRosterDetail/trainingIssue";
import ViewCarouselRoundedIcon from "@mui/icons-material/ViewCarouselRounded";
import TrainingIssues from "../../skeltons/trainingIssues";
import TrainingVideos from "../../skeltons/trainingVideos";
import VideoViewedHistory from "../../../agentRoster/agentDetails/modals/videoViewedHistory";
import moment from "moment";
const AgentTrainingTab = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [issueType, setIssueType] = useState("add");
  const [openAppointment, setOpenAppointment] = useState(false);
  const [issueDetail, setIssueDetail] = useState({});
  const [openVideoHistory, setOpenVideoHistory] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const AgentTrainingProgress = useSelector((state) => state.agentRoster.AgentTrainingProgress);
  const trainingIssues = useSelector((item) => item.agentRoster.AgentTrainingIssues.trainingIssues);

  useEffect(() => {
    if (!AgentTrainingProgress.isLoading) {
      dispatch(getAgentTrainingProgress({ id }));
    }
    dispatch(getOverAllKnowledgeBaseData());
    dispatch(getTrainingIssue({ id }));
  }, []);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item lg={6} xs={12}>
        <Card variant="outlined" sx={{ backgroundColor: "white", minHeight: "30vh" }} className={classes.pageRootTraining}>
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Training Issues
                <IconButton
                  onClick={() => {
                    setIssueType("add");
                    setOpen(true);
                  }}
                  aria-label="locked"
                >
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton>
              </div>
            }
            action={
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "#0B0909",
                  fontWeight: "400",
                  marginRight: "10px",
                  border: " 1px solid rgba(33, 33, 33, 0.4)",
                }}
                color="inherit"
              >
                View all
              </Button>
            }
          />
          <CardContent>
            {trainingIssues.isLoading && <TrainingIssues />}
            <Grid container direction="row" spacing={6}>
              {!trainingIssues.isLoading &&
                trainingIssues.data?.trainingIssues?.length > 0 &&
                trainingIssues.data?.trainingIssues.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <Card>
                      <CardHeader
                        classes={{
                          title: classes.agentProfileDetailHeader,
                          subheader: classes.agentProfileDetail,
                        }}
                        avatar={
                          <WarningAmberRoundedIcon
                            sx={{
                              color: "rgba(219, 10, 10, 0.6)",
                              fontSize: "40px",
                            }}
                          />
                        }
                        title={`${item.issueType} | ${item.issueCategory}`}
                        subheader={`by ${item.issue_created_by?.first_name || item.issue_created_by?.full_name} ${
                          item.issue_created_by?.last_name || ""
                        } on ${moment(item.created || new Date()).format("MMMM Do YYYY, h:mm:ss a")}`}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {item.note}
                        </Typography>
                        <br />
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                          <Typography variant="body2" color="text.secondary">
                            Last Updated: {moment(item.updated || new Date()).format("l")} (
                            {moment(moment(new Date(item.updated).toString())).from(moment(new Date()))}) by{" "}
                            {item.issue_updated_by?.first_name || item.issue_updated_by?.full_name} {item.issue_updated_by?.last_name}
                          </Typography>
                          <Button
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                            }}
                            onClick={() => {
                              setIssueType("update");
                              setOpen(true);
                              setIssueDetail(item);
                            }}
                          >
                            View
                          </Button>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            {!trainingIssues.isLoading && trainingIssues.data?.trainingIssues?.length === 0 ? (
              <Box className={classes.loadingArea}>
                <DataNotFound />
              </Box>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRootTraining}>
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Videos Viewed
                {/* <IconButton aria-label="locked">
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton> */}
              </div>
            }
            action={
              AgentTrainingProgress?.data?.viewedEvents?.length>4?
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "#0B0909",
                  fontWeight: "400",
                  marginRight: "10px",
                  border: " 1px solid rgba(33, 33, 33, 0.4)",
                }}
                color="inherit"
                onClick={() => setOpenVideoHistory(true)}
              >
                View all
              </Button>:null
            }
          />
          <CardContent>
            {AgentTrainingProgress.isLoading && <TrainingVideos count={5} />}
            <Grid container direction="row" spacing={6}>
              {!AgentTrainingProgress.isLoading &&
                AgentTrainingProgress?.data?.viewedEvents?.length > 0 &&
                AgentTrainingProgress?.data?.viewedEvents?.slice(0,4)?.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <Card>
                      <CardHeader
                        classes={{
                          title: classes.agentProfileDetailHeader,
                          subheader: classes.agentProfileDetail,
                        }}
                        avatar={
                          <img src={item.post?.thumbnail ? item.post?.thumbnail : trainingIcon} alt={"Training Icon"} style={{ maxWidth: "150px" }} />
                        }
                        title={item.post?.title}
                        subheader={moment(item.post?.createdAt || new Date()).format("LL")}
                        action={
                          <div>
                            <Box sx={{ position: "relative", display: "inline-flex" }}>
                              <CircularProgress size={50} value={item.progress} color="inherit" variant="determinate" />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="caption" component="div" color="text.secondary">
                                  {`${item.progress}%`}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        }
                      />
                    </Card>
                  </Grid>
                ))}
            </Grid>
            {!AgentTrainingProgress.isLoading && AgentTrainingProgress?.data?.viewedEvents?.length === 0 ? (
              <Box className={classes.loadingArea}>
                <DataNotFound
                  color="#BDBDBD"
                  fontSize="26px"
                  title="No Activity"
                  icon={<ViewCarouselRoundedIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
                />
              </Box>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <IssuesDetailModal
        open={open}
        appointments={appointments}
        setAppointments={setAppointments}
        setOpen={setOpen}
        issueDetails={issueDetail}
        issueType={issueType}
        setOpenAppointment={setOpenAppointment}
      />
      <AddAppointmentModal
        setAppointments={setAppointments}
        appointments={appointments}
        open={openAppointment}
        issueDetails={issueDetail}
        setOpen={setOpenAppointment}
      />
      <VideoViewedHistory open={openVideoHistory} id={id} eventData={AgentTrainingProgress?.data?.viewedEvents} setOpen={setOpenVideoHistory} />
    </Grid>
  );
};

export default withStyles(styleSheet, { name: "AgentTrainingTabStyle" })(AgentTrainingTab);
