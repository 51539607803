import * as React from "react";
import {Card,Stack} from "@mui/material";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
function RecentCallsSkelton(props) {
  let { count=6 } = props;
  return (
    <Box>
      {Array.from(Array(count).keys()).map(() => (
        <Card sx={{ mb: "10px"}}>
          <CardHeader
          sx={{padding:"10px 5px"}}
            avatar={<Stack spacing={1} alignItems="center" direction={"row"}><Skeleton animation="wave" variant="circular" width={40} height={40} /><Skeleton animation="wave" variant="circular" width={23} height={23} /></Stack>}
            title={<Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 4 }} />}
            action={<Skeleton animation="wave" height={20} width="40px" />}
            subheader={<Stack spacing={2} direction={"row"}><Skeleton animation="wave" height={15} width="60%" /><Skeleton animation="wave" height={15} width="20%" /><Skeleton animation="wave" height={15} width="20%" /></Stack>}
          />
        </Card>
      ))}
    </Box>
  );
}
export default RecentCallsSkelton;
