import { createSlice } from "@reduxjs/toolkit";
import { getAllPhones } from "./getAllPhones";
import { addPhoneNumber } from "./addPhone";
import {updatePhoneNumber} from './updatePhoneNumber'

const initialState = {
  phonesList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    count: 0,
  },
  addPhone: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updatePhone: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const PhoneNumbers = createSlice({
  name: "PhoneNumbers",
  initialState,
  extraReducers: {
    [getAllPhones.pending]: (state, action) => {
      state.phonesList.isLoading = true;
      state.phonesList.errMsg = null;
      state.phonesList.success = false;
      if (action.meta.arg.reset) {
        state.phonesList.data = [];
      }
    },
    [getAllPhones.fulfilled]: (state, action) => {
      state.phonesList.success = true;
      state.phonesList.isLoading = false;
      state.phonesList.data = action.payload;
    },
    [getAllPhones.rejected]: (state, action) => {
      state.phonesList.isLoading = false;
      state.phonesList.success = false;
      state.phonesList.errMsg = action.payload;
    },

    [addPhoneNumber.pending]: (state) => {
      state.addPhone.isLoading = true;
      state.addPhone.errMsg = null;
      state.addPhone.success = false;
    },
    [addPhoneNumber.fulfilled]: (state, action) => {
      state.addPhone.success = true;
      state.addPhone.isLoading = false;
      state.addPhone.data = action.payload;
       let newData=state.phonesList.data?[...state.phonesList.data]:[]
       newData.unshift(action.payload)
       state.phonesList.data=newData
    },
    [addPhoneNumber.rejected]: (state, action) => {
      state.addPhone.isLoading = false;
      state.addPhone.success = false;
      state.addPhone.errMsg = action.payload;
    },
    [updatePhoneNumber.pending]: (state) => {
      state.updatePhone.isLoading = true;
      state.updatePhone.errMsg = null;
      state.updatePhone.success = false;
    },
    [updatePhoneNumber.fulfilled]: (state, action) => {
      state.updatePhone.success = true;
      state.updatePhone.isLoading = false;
      state.updatePhone.data = action.payload;
      let newData=state.phonesList.data?[...state.phonesList.data]:[]
      let index=newData.findIndex((item)=>item.id===action.payload.id)
      newData[index]={...newData[index],...action.payload}
      state.phonesList.data=newData
    },
    [updatePhoneNumber.rejected]: (state, action) => {
      state.updatePhone.isLoading = false;
      state.updatePhone.success = false;
      state.updatePhone.errMsg = action.payload;
    },
  },
});
export default PhoneNumbers.reducer;
export * from "./getAllPhones";
export * from "./addPhone";
export * from "./updatePhoneNumber"
