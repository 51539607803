import React, { useState, useRef, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import { EmojiEmotionsOutlined, Send } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Menu from "@mui/material/Menu";
import { addTicketComment, handleAddComment } from "../../redux/agentRequests";
import ResponseAlert from "../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "./mentions/defaultStyle.js";
import defaultMentionStyle from "./mentions/defaultMentionStyle";
function SendMessage(props) {
  let { selectedUser, handleClose } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [anchor, setAnchor] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [userIds, setUserIds] = useState([]);
  const openMenu = Boolean(anchor);
  let { classes } = props;
  const dispatch = useDispatch();
  const addComment = useSelector((state) => state.AgentRequests.addComment);
  const adminAuth = useSelector((state) => state.adminAuth);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSuccess = () => {
    // setErrorAlert({
    //   errorMsg: "You have successfully commented",
    //   errorType: "success",
    //   isOpen: true,
    // });
    setUserIds([]);
  };

  const handleSendMessage = async (isClose) => {
    if (messageValue && messageValue.replaceAll("\n", "")) {
      let obj = {
        schema: {
          comment: messageValue,
          userIds: userIds.filter(
            (item, index) => userIds.indexOf(item) === index
          ),
        },
        ticketId: selectedUser?.id,
        agent: { ...adminAuth?.adminDetail },
        handleError,
        handleSuccess,
      };
      setMessageValue("");
      dispatch(
        handleAddComment({
          comment: messageValue,
          createdAt: new Date().toLocaleDateString(),
          isLoading: true,
          agent: { ...adminAuth?.adminDetail },
        })
      );
      await dispatch(addTicketComment(obj));
      if (isClose) {
        handleClose();
      }
    }
  };

  return (
    <React.Fragment>
      <Box className={classes.inputBox}>
        <Box sx={{ width: "50%", "& textarea": { outline: "none" } }}>
          <MentionsInput
            value={messageValue}
            onChange={(event, newValue, newPlainTextValue, mentions) => {
              setUserIds(mentions?.map((item) => item.id));
              setMessageValue(event.target.value);
            }}
            allowSuggestionsAboveCursor={true}
            forceSuggestionsAboveCursor={true}
            style={defaultStyle}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                handleSendMessage();
                setMessageValue("");
              }
            }}
          >
            <Mention
              trigger="@"
              data={
                agentRosterList?.list?.length
                  ? agentRosterList?.list
                      ?.filter((it) => it.id !== adminAuth?.adminDetail?.id)
                      ?.map((item) => ({
                        id: item.id,
                        display: item?.first_name + " " + item.last_name,
                      }))
                  : []
              }
              markup={"@[__display__^__id__]"}
              style={defaultMentionStyle}
              // renderSuggestion={this.renderUserSuggestion}
            />
          </MentionsInput>
        </Box>
        <Box className={classes.iconList}>
          <Box className={classes.iocnGroup}>
            <EmojiEmotionsOutlined
              sx={{ cursor: "pointer" }}
              onClick={(e) => setAnchor(e.currentTarget)}
            />
          </Box>
          <Button
            disabled={messageValue ? false : true}
            onClick={() => handleSendMessage(true)}
            sx={{ bgcolor: "rgba(46, 125, 50, 1) !important", mr: "8px" }}
            className={classes.btnSend}
            variant="contained"
            startIcon={<Send />}
          >
            Send & Close
          </Button>
          <LoadingButton
            loadingPosition="start"
            loading={addComment.isLoading}
            disabled={messageValue ? false : true}
            onClick={() => handleSendMessage()}
            className={classes.btnSend}
            variant="contained"
            startIcon={<Send />}
          >
            Send
          </LoadingButton>
        </Box>
      </Box>
      <Menu
        anchorEl={anchor}
        id="account-menu"
        open={openMenu}
        onClose={() => setAnchor("")}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Picker
          data={data}
          onEmojiSelect={(emojy) => {
            setAnchor("");
            setMessageValue((message) => message + emojy.native);
          }}
        />
      </Menu>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "SendMessageStyle" })(
  SendMessage
);
