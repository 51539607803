import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEmailTemplatesAgentsRecruitment } from "../../redux/agents/agentRecruitment/getEmailTemplatesAgenstRecruitment";
import { mergeEmailTemplateAgentRecruitment } from "../../redux/agents/agentRecruitment/mergeEmailTemplateAgentRecruitment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Loading from "./loading";
import OutsideClickHandler from "react-outside-click-handler";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  Typography,
  Popper,
  IconButton,
} from "@mui/material";

const PopoverModal = (props) => {
  let { type, closeModal, emailValue } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const [templates, setTemplates] = useState([]);
  const [routeId, setRouteId] = useState(null);
  const templateList = useSelector((item) => item.emailTemplates);
  const setListData = async () => {
    dispatch(
      getEmailTemplatesAgentsRecruitment({
        type: type ? type : "",
        limit: 1000,
      })
    );
    setTemplates(templateList?.emailTemplates || []);
  };

  const fillTemp = async (payload) => {
    const resp = await dispatch(mergeEmailTemplateAgentRecruitment(payload));
    if (resp?.payload) {
      props.selectedTemplate(resp.payload);
      props.closeModal();
    }
  };

  const selectTemplate = (param) => {
    props.setLoading(true);
    const payload = {
      templateId: param?.id,
    };
    if (type === "agent") {
      payload.mergeAgentId = routeId;
    } else {
      payload.personId = routeId;
    }
    fillTemp(payload);
    props.closeModal();
  };
  useEffect(() => {
    if (templateList?.emailTemplates.length === 0) {
      setListData();
    }
    setRouteId(Number(params?.id));
  }, []);

  useEffect(() => {
    if (templateList) {
      if (emailValue?.split("</div>")[0]?.split("/")[1]) {
        const enteredKeyword = emailValue
          ?.split("</div>")[0]
          ?.split("/")[1]
          .trim()
          .toLowerCase();
        const filteredItems = templateList.emailTemplates.filter((item) => {
          return item.name
            .toLowerCase()
            .startsWith(enteredKeyword.toLowerCase());
          // return item.name.substr(0,props.EditorValue.length).toLowerCase() == props.EditorValue.toLowerCase();
        });

        setTemplates(filteredItems);
      }
      return () => {
        setTemplates(templateList?.emailTemplates || []);
      };
    }
  }, [templateList, emailValue]);

  const convertHtmlToPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <>
      <Popper
        open={props.openStatus}
        anchorEl={document.getElementById("displayPopModal")}
        style={{ zIndex: "9999", maxWidth: "650px", minWidth: "400px" }}
        placement="bottom-start"
      >
        <OutsideClickHandler
          onOutsideClick={() => {
            props.closeModal();
          }}
        >
          <Box
            sx={{
              marginTop: 13,
              marginRight: 9,
              zIndex: 999,
              bgcolor: "background.paper",
              position: "relative",
            }}
          >
            {/* {props.openStatus && !templateList?.loading ? (
            <IconButton sx={{ position: "absolute", top: "-22px", right: "-22px", zIndex: 1000 }}>
              {" "}
              <HighlightOffIcon onClick={() => closeModal()} />
            </IconButton>
          ) : null} */}

            {templateList?.loading ? (
              <Box
                sx={{
                  width: "300px",
                  height: "160px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading type="spin" />
              </Box>
            ) : (
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  border: "1px solid #e0e0e0",
                  marginTop: "20px",
                  borderRadius: "3px",
                  padding: 0,
                }}
              >
                {templates && templates.length ? (
                  templates.map((temp, index) => (
                    <>
                      <ListItemButton
                        key={index}
                        onClick={() => selectTemplate(temp)}
                        sx={{
                          "&:hover": {
                            "& .templatePlainText": { display: "none" },
                            "& .templateBody": { display: "block" },
                          },
                        }}
                      >
                        <Box sx={{ display: "block" }}>
                          <Typography
                            component="h6"
                            variant="h6"
                            sx={{ fontSize: "16px" }}
                          >
                            {temp?.name}
                          </Typography>
                          <Typography
                            className={"templatePlainText"}
                            sx={{ wordBreak: "break-word" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                convertHtmlToPlainText(temp.body).length > 80
                                  ? `${convertHtmlToPlainText(
                                      temp.body
                                    )?.substring(0, 120)}...`
                                  : convertHtmlToPlainText(temp.body),
                            }}
                            component="p"
                            variant="subtitle1"
                          ></Typography>
                          <Typography
                            sx={{ display: "none" }}
                            className={"templateBody"}
                          >
                            <pre
                              style={{
                                whiteSpace: "initial",
                                fontFamily: "AvenirNext !important",
                                color: "rgba(0, 0, 0, 0.6) !important",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: temp.body,
                                }}
                              />
                            </pre>
                          </Typography>
                        </Box>
                      </ListItemButton>
                      {templates.length === index + 1 ? null : <Divider />}
                    </>
                  ))
                ) : (
                  <Typography sx={{ padding: "10px" }}>
                    No Templates found
                  </Typography>
                )}
              </List>
            )}
          </Box>
        </OutsideClickHandler>
      </Popper>
    </>
  );
};

export default PopoverModal;
