import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {  Container, Box, Button, TextField, Card, Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddInventoryItem from "./addInvetoryItem";
import { getAllInventories } from "../../redux/inventory";
import { useSelector, useDispatch } from "react-redux";
import { TabContext } from "@mui/lab";
import LockBoxTable from "./lockboxTable.js";
import EquipmentTable from "./equipmentTable.js"
import SignageTable from "./signageTable.js"
import { Route, Routes } from "react-router-dom";
function InventoryPage(props) {
  const location = useLocation();
  let { classes } = props;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 25,
    search: "",
    status: "",
    type: "",
    sortOrder:"asc",
    sortBy:"AssetStatus"
  });
  const dispatch = useDispatch();
  const InventoryList = useSelector((state) => state.inventory.InventoryData.InventoryList);

  useEffect(() => {
    dispatch(getAllInventories({...filter,type:filter.type || location.pathname?.split("/")[2]}));
  }, [filter]);
  //
  //   console.log(InventoryList, "InventoryList");

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setFilter({ ...filter, search }), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const handleUpdateFilter = (field, value) => {
    setFilter({
      ...filter,
      pageNumber: 1,
      [field]: value,
    });
  };

  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100", overflow: "visible" }}>
        {" "}
        <Box className={classes.TopTransactionsBarArea}>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TransactionsTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                  >
                    <Tab
                      label="Lockboxes"
                      value={`/inventory/lockbox`}
                      to={`/inventory/lockbox`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("type", "lockbox")}
                    />
                    <Tab
                      label="Signage"
                      value={`/inventory/signage`}
                      to={`/inventory/signage`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("type", "signage")}
                    />{" "}
                    <Tab
                      label="Equipment"
                      value={`/inventory/equipment`}
                      to={`/inventory/equipment`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("type", "equipment")}
                    />
                  </Tabs>
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Button className={classes.addTransactionButton} variant="contained" startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
              Add Item
            </Button>{" "}
            <TextField onChange={(e) => setSearch(e.target.value)} type="search" size="small" variant="outlined" placeholder="Search Inventory" />
          </Box>
        </Box>
      </Card>
      <Container maxWidth="xl">
        <Box className={classes.pageRoot}>
          {/* <Box className={classes.InventoryHeader}>
            <Typography variant="h5" gutterBottom component="div">
              {InventoryList.data?.count} Inventories
            </Typography>
            <Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Button className={classes.InventoryAddButton} variant="contained" startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
                  Add Item
                </Button>
                <TextField onChange={(e) => setSearch(e.target.value)} type="search" size="small" variant="outlined" placeholder="Search Inventory" />
              </Stack>{" "}
            </Box>
          </Box> */}
          <Routes>
            <Route path="/" element={<LockBoxTable InventoryList={InventoryList} setFilters={setFilter} filters={filter} {...props} />} />
            <Route path="/lockbox" element={<LockBoxTable InventoryList={InventoryList} setFilters={setFilter} filters={filter} {...props} />} />
            <Route path="/signage" element={<SignageTable InventoryList={InventoryList} setFilters={setFilter} filters={filter} {...props} />} />
            <Route path="/equipment" element={<EquipmentTable InventoryList={InventoryList} setFilters={setFilter} filters={filter} {...props} />} />
          </Routes>
        </Box>
        <AddInventoryItem open={open} defaultType={location.pathname?.split("/")[2]} setOpen={setOpen} />
      </Container>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "InventoryPageStyle" })(InventoryPage);
