import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../components/uiElements/avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Autocomplete,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  Box,
  Typography,
} from "@mui/material";
import {
  createProject,
  setProjectModalFields,
} from "../../../redux/projects/projects";
import { getDepartmentProjectList } from "../../../redux/projects/common";
import { getProjectModalFields } from "../../../redux/projects/projects";
import { getFieldSize } from "../helpers";
import ConfirmModal from "./ConfirmModal";
import { DateTimePicker } from "@mui/x-date-pickers";
import { getAllPropertyList } from "../../../redux/propertyListing";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "0px !important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  projectTitle: {
    fontSize: "20px!important",
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "AvenirNext !important",
    fontWeight: "600!important",
    letterSpacing: "0.15px!important",
    padding: "0px !important",
  },
  field: {
    border: "none!important",
    borderBottom: "1px solid #000000DE!important",
  },
  fieldError: {
    borderBottom: "1px solid #d32f2f!important",
  },
  textArea: {
    width: "100%!important",
    padding: "5px!important",
    height: "100px!important",
    border: "1px solid!important",
    borderColor: "#000000DE!important",
    borderRadius: "4px!important",
  },
  topfieldContainer: {
    marginTop: "1px!important",
  },
  createButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    minWidth: "120px",
  },
  closeButton: {
    color: "#0B0909!important",
  },
  footerSection: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    marginTop: "20px !important",
  },
  footerSectionLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "15px !important",
    "& .MuiInputBase-root": {
      height: "32px !important",
      width: "100px",
    },
  },
  footerSectionRight: {
    display: "flex !important",
    justifyContent: "flex-end!important",
    alignItems: "center !important",
    gap: "15px !important",
  },
}));

export default function CreateProject(props) {
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setOpen,
    handleClosePopup,
    handleAddMinimize,
    minimizeFlag,
    maximizedTab,
    projectModule,
  } = props;
  const propertyList = useSelector((item) => item.propertyList);

  const [formData, setFormData] = React.useState({
    due_date: new Date(),
    agent_id: "",
    department_id: "",
    task_type_id: "",
    visibility: "visible",
  });
  const [confirmationDialogOpen, openConfirmationDialog] =
    React.useState(false);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [errorAlert, setErrorAlert] = React.useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [loading, setLoading] = React.useState(false);
  const departments = useSelector((state) => state.project.departments);
  const departmentProjects = useSelector(
    (state) => state.project.departmentProjects
  );
  const modalFields = useSelector((state) => state.project.projectModalFields);
  const agents = useSelector((state) =>
    state.users.storedListOfUsers.list.filter((user) => user.is_agent === true)
  );
  const users = useSelector((state) =>
    state.users.storedListOfUsers.list.filter((user) => user.is_agent === true)
  ).map((user) => user.full_name);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const closeProjectForm = () => {
    openConfirmationDialog(false);
    if (setOpen) {
      setOpen(false);
    }

    if (handleClosePopup) {
      handleClosePopup();
    }
    setFormData({ visibility: "visible" });
    setFormDataErrors({});
    dispatch(setProjectModalFields([]));
  };

  const handleClose = () => {
    if (Object.keys(formData).length > 1) {
      openConfirmationDialog(true);
    } else {
      if (handleClosePopup) {
        handleClosePopup();
      }
      closeProjectForm();
    }
  };

  const handleCreateProject = () => {
    let errors = {};

    modalFields &&
      modalFields.map((field) => {
        if (!field.optional && field.type !== "switch") {
          if (!formData[field.name]) {
            errors[field.name] = true;
          }
        }

        if (field.type === "switch" && formData[field.name] === true) {
          setFormData({
            ...formData,
            [field.name]: formData[field.name] || false,
          });
          if (field.subfields) {
            field.subfields.map((subfield) => {
              if (!subfield.optional) {
                if (!formData[subfield.name]) {
                  errors[subfield.name] = true;
                }
              }
            });
          }
        }
      });

    if (!formData.due_date) {
      errors.due_date = true;
    }
    if (!formData.agent_id) {
      errors.agent_id = true;
    }
    if (!formData.task_type_id) {
      errors.task_type_id = true;
    }
    if (!formData.department_id) {
      errors.department_id = true;
    }

    setFormDataErrors(errors);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      dispatch(createProject({ ...formData, handleSuccess, handleError }));
    }
  };
  const handleSuccess = () => {
    setLoading(false);
    setErrorAlert({
      errorMsg: "You have successfully created the project",
      errorType: "success",
      isOpen: true,
    });
    setTimeout(() => {
      closeProjectForm();
    }, 2000);
  };
  const handleError = (error) => {
    setLoading(false);
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  useEffect(() => {
    if (formData.department_id) {
      dispatch(getDepartmentProjectList(formData.department_id));
    }
  }, [formData.department_id]);

  useEffect(() => {
    if (minimizeFlag) {
      handleAddMinimize({
        ...formData,
        tabTitle: `${
          formData.department_id
            ? departments.find((item) => item.id === formData.department_id)
                ?.name
            : ""
        }${formData?.task_type_id ? " | " : ""}${
          formData?.task_type_id
            ? departmentProjects.find(
                (item) => item.id === formData.task_type_id
              )?.name
            : ""
        }`,
        user: formData.agent_id
          ? agents.find((item) => item.id === formData.agent_id)
          : "",
      });
    }
  }, [minimizeFlag]);

  useEffect(() => {
    if (maximizedTab && maximizedTab.tabDetail) {
      setFormData(maximizedTab.tabDetail);
    }
  }, [maximizedTab]);

  useEffect(() => {
    if (formData.task_type_id && formData.department_id) {
      dispatch(
        getProjectModalFields({
          deptID: formData.department_id,
          projectID: formData.task_type_id,
        })
      );
    }
  }, [formData.task_type_id, formData.department_id]);

  React.useEffect(() => {
    if (formData["Is there a host agent"] && !formData["Create flyers"]) {
      setFormData({
        ...formData,
        "Create flyers": true,
      });
    }
  }, [formData]);

  useEffect(() => {
    dispatch(getAllPropertyList({ under_contract: 1 }));
  }, []);

  /**
   * End Region: useEffect Hooks
   */
  const handleSortListing = (data) => {
    let newData = [...data];
    if (newData?.length) {
      let sortedArray = newData?.sort(
        (a, b) =>
          parseFloat(a.street?.replace("/r", "/")?.replace(/\s/g, "")) -
          parseFloat(b.street?.replace("/r", "/")?.replace(/\s/g, ""))
      );
      return sortedArray;
    }
  };
  function convertTo24HourFormat(time12h) {
    const [time, period] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  return (
    <Box sx={{ padding: projectModule ? "20px 24px" : "" }}>
      <DialogTitle className={classes.projectTitle}>Create Project</DialogTitle>
      <DialogContent className={classes.dialog}>
        <ConfirmModal
          open={confirmationDialogOpen}
          handleYes={closeProjectForm}
          handleNo={() => openConfirmationDialog(false)}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={4} className={classes.topfieldContainer}>
            <Grid item xs={4}>
              <FormControl fullWidth error={formDataErrors.agent_id}>
                <InputLabel id="demo-simple-select-standard-label">
                  Select Agent
                </InputLabel>
                <Select
                  className={
                    formDataErrors.agent_id ? classes.fieldError : classes.field
                  }
                  variant="standard"
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="agent-select-for-create-project"
                  value={formData.agent_id}
                  onChange={(e) => {
                    setFormData({ ...formData, agent_id: e.target.value });
                    if (formDataErrors.agent_id) {
                      setFormDataErrors({ ...formDataErrors, agent_id: false });
                    }
                  }}
                  sx={{ "& .MuiSelect-select": { display: "flex" } }}
                  label="Select Agent"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {agents.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Avatar
                        src={
                          item?.profile_images?.profile_img ||
                          item?.profile_images?.slack_profile_img ||
                          item?.profile_images?.slack_profile_img_thumbnail
                        }
                        sx={{
                          width: "25px",
                          height: "25px",
                          mr: "7px",
                          fontSize: "13px",
                        }}
                        variant="circle"
                      >{`${item.first_name?.slice(0, 1)}${item.last_name?.slice(
                        0,
                        1
                      )}`}</Avatar>
                      {item.first_name} {item.last_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formDataErrors.agent_id ? "* required field" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth error={formDataErrors.department_id}>
                <InputLabel id="demo-simple-select-standard-label">
                  Department
                </InputLabel>
                <Select
                  className={
                    formDataErrors.department_id
                      ? classes.fieldError
                      : classes.field
                  }
                  variant="standard"
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="department-select"
                  value={formData.department_id}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      department_id: e.target.value,
                      task_type_id: "",
                    });
                    dispatch(setProjectModalFields([]));
                    if (formDataErrors.department_id) {
                      setFormDataErrors({
                        ...formDataErrors,
                        department_id: false,
                      });
                    }
                  }}
                  label="Department"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {departments.map((department) => (
                    <MenuItem value={department.id}>{department.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formDataErrors.department_id ? "* required field" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth error={formDataErrors.task_type_id}>
                <InputLabel id="demo-simple-select-standard-label">
                  Project
                </InputLabel>
                <Select
                  className={
                    formDataErrors.task_type_id
                      ? classes.fieldError
                      : classes.field
                  }
                  variant="standard"
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="project-select"
                  helperText={
                    formDataErrors.task_type_id ? "* required field" : ""
                  }
                  value={formData.task_type_id}
                  onChange={(e) => {
                    setFormData({ ...formData, task_type_id: e.target.value });
                    if (formDataErrors.task_type_id) {
                      setFormDataErrors({
                        ...formDataErrors,
                        task_type_id: false,
                      });
                    }
                  }}
                  label="Project"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {departmentProjects.map((project) => (
                    <MenuItem value={project.id}>{project.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formDataErrors.task_type_id ? "* required field" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <DateTimePicker
                fullWidth
                inputFormat="MM/dd/yyyy hh:mm a"
                id={`standard-due-date`}
                label={"Due Date"}
                value={formData.due_date || null}
                placeholder={"Due Date"}
                onChange={(e) => {
                  setFormData({ ...formData, due_date: e });
                  if (formDataErrors.due_date) {
                    setFormDataErrors({ ...formDataErrors, due_date: false });
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      error={formDataErrors.due_date}
                      helperText={
                        formDataErrors.due_date ? "* required field" : ""
                      }
                    />
                  );
                }}
              />
            </Grid>

            {modalFields &&
              modalFields.map((field) => {
                if (field.listing_options) {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <TextField
                        id="attached_listing"
                        fullWidth
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            Listing: e.target.value.id,
                            "Property Address": e.target.value.street,
                            State: e.target.value.state,
                            Zip: e.target.value.zipcode,
                          });
                          if (formDataErrors["Listing Id"]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              Listing: false,
                            });
                          }
                          // updateContractDetail("attached_listing", `${e.target.value}`);
                        }}
                        value={formData[field.name]}
                        select
                        variant="standard"
                        label="Select Listing"
                        required
                        size="small"
                      >
                        {propertyList?.list?.data &&
                          propertyList?.list?.data.length > 0 &&
                          handleSortListing(propertyList?.list?.data)?.map(
                            (item, index) => (
                              <MenuItem key={index} value={item}>
                                {item.property_address}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    </Grid>
                  );
                } else if (field.type === "text") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <TextField
                        fullWidth
                        variant="standard"
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        label={field.name}
                        value={formData[field.name]}
                        placeholder={field.name}
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        inputProps={{
                          style: {
                            borderBottom: formDataErrors[field.name]
                              ? "1px solid  #d32f2f"
                              : "1px solid #000000DE",
                          },
                        }}
                      />
                    </Grid>
                  );
                } else if (field.type === "textarea") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <TextField
                        fullWidth
                        multiline
                        aria-label="minimum height"
                        minRows={2}
                        maxRows={2}
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        label={field.name}
                        value={formData[field.name]}
                        placeholder={field.name}
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        inputProps={{
                          step: 300, // 5 min
                          style: {
                            borderColor: formDataErrors[field.name]
                              ? "#d32f2f"
                              : "#000000DE",
                          },
                        }}
                      />
                    </Grid>
                  );
                } else if (field.type === "date") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <DatePicker
                        inputFormat="yyyy-MM-dd"
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        label={field.name}
                        value={formData[field.name] || null}
                        placeholder={field.name}
                        onChange={(e) => {
                          setFormData({ ...formData, [field.name]: e });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              error={formDataErrors[field.name]}
                              helperText={
                                formDataErrors[field.name]
                                  ? "* required field"
                                  : ""
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  );
                } else if (field.type === "number") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <TextField
                        fullWidth
                        variant="standard"
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        label={field.name}
                        value={formData[field.name]}
                        placeholder={field.name}
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        inputProps={{
                          style: {
                            borderBottom: formDataErrors[field.name]
                              ? "1px solid  #d32f2f"
                              : "1px solid #000000DE",
                          },
                        }}
                      />
                    </Grid>
                  );
                } else if (
                  field.name === "Who is Hosting" ||
                  field.type === "autocomplete"
                ) {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <Autocomplete
                        options={
                          field.name === "Who is Hosting"
                            ? users
                            : field.options
                        }
                        fullWidth
                        variant="standard"
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        label={field.name}
                        value={formData[field.name]}
                        onChange={(e, value) => {
                          setFormData({ ...formData, [field.name]: value });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              error={formDataErrors[field.name]}
                              label={field.name}
                              helperText={
                                formDataErrors[field.name]
                                  ? "* required field"
                                  : ""
                              }
                            />
                          );
                        }}
                        freeSolo
                      />
                    </Grid>
                  );
                } else if (field.type === "dropdown") {
                  return (
                    <>
                      <Grid item xs={getFieldSize(field.size)}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-standard-label">
                            {field.name}
                          </InputLabel>
                          <Select
                            className={
                              formDataErrors[field.name]
                                ? classes.fieldError
                                : classes.field
                            }
                            variant="standard"
                            fullWidth
                            id={`standard-${field.name
                              .split(" ")
                              .join("")
                              .toLowerCase()}`}
                            label={field.name}
                            value={formData[field.name]}
                            placeholder={field.name}
                            error={formDataErrors[field.name]}
                            helperText={
                              formDataErrors[field.name]
                                ? "* required field"
                                : ""
                            }
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [field.name]: e.target.value,
                              });
                              if (formDataErrors[field.name]) {
                                setFormDataErrors({
                                  ...formDataErrors,
                                  [field.name]: false,
                                });
                              }
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {field.options.map((option) => (
                              <MenuItem value={option}>{option}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {formData[field.name] === "Printed" &&
                        field.subfields &&
                        field.subfields.map((subField) => {
                          if (subField.type === "date") {
                            return (
                              <Grid item xs={getFieldSize(subField.size)}>
                                <DatePicker
                                  inputFormat="MM/dd/yyyy"
                                  id={`standard-${subField.name
                                    .split(" ")
                                    .join("")
                                    .toLowerCase()}`}
                                  label={subField.name}
                                  value={formData[subField.name] || null}
                                  placeholder={subField.name}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [subField.name]: e,
                                    });
                                    if (formDataErrors[subField.name]) {
                                      setFormDataErrors({
                                        ...formDataErrors,
                                        [subField.name]: false,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      error={formDataErrors[field.name]}
                                      helperText={
                                        formDataErrors[field.name]
                                          ? "* required field"
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            );
                          }
                        })}
                    </>
                  );
                } else if (field.type === "switch") {
                  if (
                    formData["Is there a host agent"] &&
                    field.name === "Create flyers"
                  ) {
                    return null;
                  } else {
                    return (
                      <>
                        <Grid item xs={getFieldSize(field.size)}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formData[field.name] || false}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [field.name]: e.target.checked,
                                  });
                                  if (formDataErrors[field.name]) {
                                    setFormDataErrors({
                                      ...formDataErrors,
                                      [field.name]: false,
                                    });
                                  }
                                }}
                                defaultChecked
                              />
                            }
                            label={field.name}
                          />
                        </Grid>

                        {formData[field.name] === true &&
                          field.subfields &&
                          field.subfields.map((subField) => {
                            if (subField.type === "text") {
                              return (
                                <Grid item xs={getFieldSize(subField.size)}>
                                  <TextField
                                    fullWidth
                                    className={classes.subField}
                                    variant="standard"
                                    id={`standard-${subField.name
                                      .split(" ")
                                      .join("")
                                      .toLowerCase()}`}
                                    label={subField.name}
                                    value={formData[subField.name]}
                                    placeholder={subField.name}
                                    error={formDataErrors[subField.name]}
                                    helperText={
                                      formDataErrors[subField.name]
                                        ? "* required field"
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        [subField.name]: e.target.value,
                                      });
                                      if (formDataErrors[subField.name]) {
                                        setFormDataErrors({
                                          ...formDataErrors,
                                          [subField.name]: false,
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      style: {
                                        borderBottom: formDataErrors[field.name]
                                          ? "1px solid  #d32f2f"
                                          : "1px solid #000000DE",
                                      },
                                    }}
                                  />
                                </Grid>
                              );
                            } else if (subField.type === "textarea") {
                              return (
                                <Grid item xs={getFieldSize(subField.size)}>
                                  <TextField
                                    aria-label="minimum height"
                                    multiline
                                    minRows={2}
                                    fullWidth
                                    maxRows={2}
                                    id={`standard-${subField.name
                                      .split(" ")
                                      .join("")
                                      .toLowerCase()}`}
                                    label={subField.name}
                                    value={formData[subField.name]}
                                    placeholder={subField.name}
                                    error={formDataErrors[subField.name]}
                                    helperText={
                                      formDataErrors[subField.name]
                                        ? "* required field"
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        [subField.name]: e.target.value,
                                      });
                                      if (formDataErrors[subField.name]) {
                                        setFormDataErrors({
                                          ...formDataErrors,
                                          [subField.name]: false,
                                        });
                                      }
                                    }}
                                    className={classes.textArea}
                                    inputProps={{
                                      step: 300, // 5 min
                                      style: {
                                        borderColor: formDataErrors[
                                          subField.name
                                        ]
                                          ? "#d32f2f"
                                          : "#000000DE",
                                      },
                                    }}
                                  />
                                </Grid>
                              );
                            } else if (subField.type === "date") {
                              return (
                                <Grid item xs={getFieldSize(subField.size)}>
                                  <DatePicker
                                    inputFormat="MM/dd/yyyy"
                                    id={`standard-${subField.name
                                      .split(" ")
                                      .join("")
                                      .toLowerCase()}`}
                                    label={subField.name}
                                    value={formData[subField.name] || null}
                                    placeholder={subField.name}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        [subField.name]: e,
                                      });
                                      if (formDataErrors[subField.name]) {
                                        setFormDataErrors({
                                          ...formDataErrors,
                                          [subField.name]: false,
                                        });
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        error={formDataErrors[field.name]}
                                        helperText={
                                          formDataErrors[field.name]
                                            ? "* required field"
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                              );
                            } else if (subField.type === "dropdown") {
                              return (
                                <Grid item xs={getFieldSize(subField.size)}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      {subField.name}
                                    </InputLabel>
                                    <Select
                                      className={
                                        formDataErrors[field.name]
                                          ? classes.fieldError
                                          : classes.field
                                      }
                                      variant="standard"
                                      fullWidth
                                      id={`standard-${subField.name
                                        .split(" ")
                                        .join("")
                                        .toLowerCase()}`}
                                      label={subField.name}
                                      value={formData[subField.name]}
                                      placeholder={subField.name}
                                      error={formDataErrors[subField.name]}
                                      helperText={
                                        formDataErrors[subField.name]
                                          ? "* required field"
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          [subField.name]: e.target.value,
                                        });
                                        if (formDataErrors[subField.name]) {
                                          setFormDataErrors({
                                            ...formDataErrors,
                                            [subField.name]: false,
                                          });
                                        }
                                      }}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              );
                            } else if (subField.type === "time") {
                              return (
                                <Grid item xs={getFieldSize(subField.size)}>
                                  <TextField
                                    id={subField.name}
                                    label={subField.name}
                                    value={formData[subField.name] || null}
                                    type="time"
                                    error={formDataErrors[subField.name]}
                                    helperText={
                                      formDataErrors[subField.name]
                                        ? "* required field"
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        [subField.name]: e.target.value,
                                      });
                                      if (formDataErrors[subField.name]) {
                                        setFormDataErrors({
                                          ...formDataErrors,
                                          [subField.name]: false,
                                        });
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    fullWidth
                                    inputProps={{
                                      step: 300, // 5 min
                                      style: {
                                        borderBottom: formDataErrors[
                                          subField.name
                                        ]
                                          ? "1px solid  #d32f2f"
                                          : "1px solid #000000DE",
                                      },
                                    }}
                                  />
                                </Grid>
                              );
                            } else if (
                              subField.name === "Who is Hosting" ||
                              subField.type === "autocomplete"
                            ) {
                              return (
                                <Grid item xs={getFieldSize(subField.size)}>
                                  <Autocomplete
                                    options={
                                      subField.name === "Who is Hosting"
                                        ? users
                                        : field.options
                                    }
                                    fullWidth
                                    variant="standard"
                                    id={`standard-${subField.name
                                      .split(" ")
                                      .join("")
                                      .toLowerCase()}`}
                                    label={subField.name}
                                    value={formData[subField.name]}
                                    onChange={(e, value) => {
                                      setFormData({
                                        ...formData,
                                        [subField.name]: value,
                                      });
                                      if (formDataErrors[subField.name]) {
                                        setFormDataErrors({
                                          ...formDataErrors,
                                          [subField.name]: false,
                                        });
                                      }
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          error={formDataErrors[subField.name]}
                                          label={subField.name}
                                          helperText={
                                            formDataErrors[subField.name]
                                              ? "* required field"
                                              : ""
                                          }
                                        />
                                      );
                                    }}
                                    freeSolo
                                  />
                                </Grid>
                              );
                            }
                          })}
                      </>
                    );
                  }
                } else if (field.type === "time") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <TextField
                        id={field.name}
                        label={field.name}
                        value={
                          formData[field.name]
                            ? convertTo24HourFormat(formData[field.name])
                            : null
                        }
                        type="time"
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        onChange={(e) => {
                          const selectedTime = e.target.value;

                          // Splitting the time string to get hours and minutes
                          const [hours, minutes] = selectedTime.split(":");

                          // Creating a Date object to format the time
                          const date = new Date();
                          date.setHours(parseInt(hours));
                          date.setMinutes(parseInt(minutes));

                          // Formatting the time in 12-hour format using Intl.DateTimeFormat
                          const formattedTime = date.toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          });

                          setFormData({
                            ...formData,
                            [field.name]: formattedTime,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                          style: {
                            borderBottom: formDataErrors[field.name]
                              ? "1px solid  #d32f2f"
                              : "1px solid #000000DE",
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  );
                }
              })}
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions className={classes.footerSection}>
        <Box className={classes.footerSectionLeft}>
          <Typography sx={{ fontSize: "14px" }}>Agent Visibility</Typography>
          <TextField
            value={formData?.visibility}
            onChange={(e) =>
              setFormData((form) => ({ ...form, visibility: e.target.value }))
            }
            size="small"
            select
          >
            <MenuItem value="visible"> Visible</MenuItem>
            <MenuItem value="hidden"> Hidden</MenuItem>
          </TextField>
        </Box>
        <Box className={classes.footerSectionRight}>
          <Button className={classes.closeButton} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            className={classes.createButton}
            onClick={handleCreateProject}
            loadingPosition="start"
            loading={loading}
          >
            Create
          </LoadingButton>
        </Box>
      </DialogActions>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
