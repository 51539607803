import { createSlice } from "@reduxjs/toolkit";
import { addGroupRouting } from "./addGroup";
import { getLeadRoutingGroups } from "./getGroups";
import { updateGroupRouting } from "./updateGroup";
const initialState = {
  addGroup: {
    success: false,
    isLoading: false,
    errMsg: null,
  },
  updateGroup: {
    success: false,
    isLoading: false,
    errMsg: null,
  },
  groups: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const LeadRoutingGroups = createSlice({
  name: "LeadRoutingGroups",
  initialState,
  extraReducers: {
    // add group routing
    [addGroupRouting.pending]: (state) => {
      state.addGroup.isLoading = true;
      state.addGroup.errMsg = null;
      state.addGroup.success = false;
    },
    [addGroupRouting.fulfilled]: (state, action) => {
      state.addGroup.success = true;
      state.addGroup.isLoading = false;
      let groups = [...state.groups.data];
      groups.push(action.payload);
      state.groups.data = groups;
    },
    [addGroupRouting.rejected]: (state, action) => {
      state.addGroup.isLoading = false;
      state.addGroup.success = false;
      state.addGroup.errMsg = action.payload;
    },
    // add group routing
    [updateGroupRouting.pending]: (state) => {
      state.updateGroup.isLoading = true;
      state.updateGroup.errMsg = null;
      state.updateGroup.success = false;
    },
    [updateGroupRouting.fulfilled]: (state, action) => {
      state.updateGroup.success = true;
      state.updateGroup.isLoading = false;
      let groups = [...state.groups.data];
      let index=groups.findIndex((item)=>item.id===action.payload.id)
      groups[index]=action.payload
      state.groups.data = groups;
    },
    [updateGroupRouting.rejected]: (state, action) => {
      state.updateGroup.isLoading = false;
      state.updateGroup.success = false;
      state.updateGroup.errMsg = action.payload;
    },
    // get leader routing groups
    [getLeadRoutingGroups.pending]: (state) => {
      state.groups.isLoading = true;
      state.groups.errMsg = null;
      state.groups.success = false;
    },
    [getLeadRoutingGroups.fulfilled]: (state, action) => {
      state.groups.success = true;
      state.groups.isLoading = false;
      state.groups.data = action.payload;
    },
    [getLeadRoutingGroups.rejected]: (state, action) => {
      state.groups.isLoading = false;
      state.groups.success = false;
      state.groups.errMsg = action.payload;
    },
  },
});
export default LeadRoutingGroups.reducer;
export * from "./addGroup";
export * from "./getGroups";
export * from "./updateGroup"
