import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Grid, TextField, MenuItem, FormControlLabel, Switch, Stack, Typography, Box, IconButton } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import { uid } from "uid";
import { MultipleDropsdownSelection } from "../../../components/shared/multipleDropdownSelection";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ResponseAlert from "../../../components/responseAlert";
import { handleAddEvent, handleUpdateEvent, handleDeleteEvent } from "../../../redux/eventsAndAlerts/events";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import RSVPListModal from "./rsvpList";
import AddAlertModal from "./addAlert";
import halloween from "../../../assets/images/eventIcons/halloween.png";
import birthday from "../../../assets/images/eventIcons/birthday.png";
import boat from "../../../assets/images/eventIcons/boat.png";
import calendar from "../../../assets/images/eventIcons/calendar.png";
import coffee from "../../../assets/images/eventIcons/coffee.png";
import wheel from "../../../assets/images/eventIcons/wheel.png";
import pin from "../../../assets/images/eventIcons/pin.png";
import party from "../../../assets/images/eventIcons/party.png";

export const icons = [
  {
    icon: calendar,
    value: "calendar",
  },
  {
    icon: birthday,
    value: "birthday",
  },
  {
    icon: party,
    value: "party",
  },
  {
    icon: halloween,
    value: "halloween",
  },
  {
    icon: pin,
    value: "pin",
  },
  {
    icon: boat,
    value: "boat",
  },
  {
    icon: coffee,
    value: "coffee",
  },
  {
    icon: wheel,
    value: "wheel",
  },
];
const moment = require("moment-timezone");
export const otherItemArray = [
  {
    id: "company_wide",
    name: "Company Wide",
    email: "All agents, All Markets, exclude admins",
    filterType: "others",
    group: "",
  },
  {
    id: "atlanta_market",
    email: "All Atlanta Market Users",
    name: "Atlanta Market",
    filterType: "others",
    group: "",
  },
  {
    id: "alabama_market",
    email: "All Alabama Market Users",
    name: "Alabama Market",
    filterType: "others",
    group: "",
  },
  {
    id: "orlando_market",
    email: "All Orlando Market Users",
    name: "Orlando Market",
    filterType: "others",
    group: "",
  },
  {
    id: "miami_market",
    email: "All Miami Market Users",
    name: "Miami Market",
    filterType: "others",
    group: "",
  },
  {
    id: "charlotte_market",
    email: "All Charlotte Market Users",
    name: "Charlotte Market",
    filterType: "others",
    group: "",
  },
  {
    id: "all_admin_users",
    email: "all_admin_users",
    name: "All Individual Admin Users",
    filterType: "others",
    group: "",
  },
];

function AddEventModal(props) {
  let { classes, open, setOpen, eventData } = props;
  const eventTitle = useRef();
  const locationTitle = useRef();
  const eventLocation = useRef();
  const [openAlert, setOpenAlert] = useState(false);
  const eventBody = useRef();
  const [openList, setOpenList] = useState(false);
  const [isNoIcon, setIsNoIcon] = useState(false);
  const [eventDetail, setEventDetail] = useState({
    rsvps: false,
    event_date: "",
    start_time: null,
    end_time: null,
    event_type: "",
    in_person_or_virtual: "",
    rsvp_deadline: "",
    allowed_more_than_one: false,
    virtual_info: "",
    max_guest_count: "",
    who_to_alert: [],
    icon: null,
    user_ids: [],
  });
  const [actionType, setActionType] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const [preQuestions, setPreQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch();
  const usersList = useSelector((item) => item.users?.storedListOfUsers?.list);
  const addEvent = useSelector((item) => item.eventAlerts.events.addEvent);
  const updateEvent = useSelector((item) => item.eventAlerts.events.updateEvent);
  const deleteEvent = useSelector((item) => item.eventAlerts.events.deleteEvent);

  useEffect(() => {
    if (open === "update") {
      let newArrayData = [];
      if (eventData.who_to_alert && typeof eventData.who_to_alert === "string") {
        let othersData = JSON.parse(eventData.who_to_alert);
        if (othersData && othersData?.length) {
          otherItemArray.map((item) => {
            othersData.map((item1) => {
              if (item.id === item1) {
                newArrayData.push(item);
              }
            });
          });
          usersList.map((item) => {
            othersData.map((item1) => {
              if (item.full_name === item1 || item.name === item1) {
                newArrayData.push({ ...item, name: item.full_name || item.name, group: "Agents" });
              }
            });
          });
        }
      }
      setEventDetail({
        ...eventData,
        user_ids: newArrayData,
      });

      setPreQuestions(eventData.event_questions || []);
      setQuestions([]);
      if (eventData.icon) {
        setIsNoIcon(false);
      } else {
        setIsNoIcon(true);
      }
    }
  }, [open]);

  useEffect(() => {
    if (addEvent.success && !addEvent.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully created the event",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!addEvent.isLoading && addEvent.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addEvent.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addEvent.isLoading]);

  useEffect(() => {
    if (updateEvent.success && !updateEvent.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully updated the event",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
      setActionType("");
    } else if (!updateEvent.isLoading && updateEvent.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateEvent.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateEvent.isLoading]);

  useEffect(() => {
    if (deleteEvent.success && !deleteEvent.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully deleted the event",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
      setActionType("");
    } else if (!deleteEvent.isLoading && deleteEvent.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(deleteEvent.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deleteEvent.isLoading]);

  const handleClose = () => {
    setOpen(false);
    setEventDetail({
      rsvps: false,
      event_date: "",
      start_time: null,
      end_time: null,
      event_type: "",
      in_person_or_virtual: "",
      rsvp_deadline: "",
      allowed_more_than_one: false,
      virtual_info: "",
      max_guest_count: "",
      who_to_alert: [],
      icon: null,
      user_ids: [],
    });
    setIsNoIcon(false);
    setActionType("");
  };
  const handleUpdateDetail = (field, value) => {
    setEventDetail({
      ...eventDetail,
      [field]: value,
    });
    if (errMsg[field]) {
      let errors = { ...errMsg };
      delete errors[field];
      setErrMsg(errors);
    }
  };
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    return [...agents];
  };
  const handleGetOptions = () => {
    let users = [];
    if (usersList?.length) {
      usersList.map((item) => {
        let obj = {
          group: item?.role?.name,
          ...item,
          name: item.full_name || item.name,
          picture: item?.profile_images?.profile_img || "",
          filterType: "agent",
        };
        users.push(obj);
      });
    }
    return [...otherItemArray, ...CUstomSortUsers(users)];
  };
  const addQuestion = () => {
    setQuestions((previous) => {
      return [
        ...previous,
        {
          id: uid(),
          question_name: "",
          question_type: "written_answer",
        },
      ];
    });
  };
  const handleUpdateQuestionParam = (field, value, data) => {
    let preQuestions = [...questions];
    let index = preQuestions.findIndex((item) => item.id === data.id);
    preQuestions[index][field] = value;
    if (field === "question_type" && value) {
      preQuestions[index].question_options = ["", "", ""];
    }
    setQuestions(preQuestions);
  };

  const handleUpdateErrMsg = (field, value) => {
    if (errMsg[field] && value) {
      let errors = { ...errMsg };
      delete errors[field];
      setErrMsg(errors);
    }
  };
  const handleRemoveQuestion = (index) => {
    let preQuestions = [...questions];
    preQuestions.splice(index, 1);
    setQuestions(preQuestions);
  };
  const handleAddChoice = (index) => {
    let preQuestions = [...questions];
    preQuestions[index].question_options.push("");
    setQuestions(preQuestions);
  };
  const handleUpdateChoice = (value, index, index1) => {
    let preQuestions = [...questions];
    preQuestions[index].question_options[index1] = value;
    setQuestions(preQuestions);
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!eventTitle?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the event title";
      errorMsg["eventTitle"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventDetail.event_date) {
      isFormValid = false;
      let msg = "Please select the event date";
      errorMsg["event_date"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventDetail.start_time) {
      isFormValid = false;
      let msg = "Please select the event start time";
      errorMsg["start_time"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventDetail.end_time) {
      isFormValid = false;
      let msg = "Please select the event end time";
      errorMsg["end_time"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!locationTitle?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the location title";
      errorMsg["locationTitle"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventLocation?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the event location address";
      errorMsg["eventLocation"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventBody?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the event description";
      errorMsg["eventBody"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventDetail.event_type) {
      isFormValid = false;
      let msg = "Please select the event type";
      errorMsg["event_type"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventDetail.in_person_or_virtual) {
      isFormValid = false;
      let msg = "Please select the in-person or virtual";
      errorMsg["in_person_or_virtual"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventDetail.rsvp_deadline && eventDetail?.rsvps) {
      isFormValid = false;
      let msg = "Please select the RSVP deadline";
      errorMsg["rsvp_deadline"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!eventDetail.max_guest_count && eventDetail?.rsvps) {
      isFormValid = false;
      let msg = "Please enter the max guest count";
      errorMsg["max_guest_count"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (questions?.length && eventDetail?.rsvps && open !== "update" && questions.find((item) => item.question_name === "")) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please fill all the question fields or remove the questions",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handelCreateEvent = () => {
    if (handleValidate()) {
      let obj = {
        ...eventDetail,
        rsvp_deadline: eventDetail?.rsvp_deadline ? moment(new Date(eventDetail?.rsvp_deadline)).tz("America/New_York").format() : null,
        event_date: moment(new Date(eventDetail?.event_date)).tz("America/New_York").format(),
        start_time: eventDetail?.start_time,
        end_time: eventDetail?.end_time,
        title: eventTitle.current.value,
        location_title: locationTitle.current.value,
        location: eventLocation.current.value,
        body: eventBody.current.value,
        max_guest_count: eventDetail.max_guest_count ? Number(eventDetail.max_guest_count) : 0,
        questions: questions,
        user_ids: eventDetail.user_ids.filter((item) => item.filterType === "agent").map((item) => item.id),
        who_to_alert: eventDetail.user_ids
          .filter((item) => item.filterType === "others")
          .map((item) => item.id)
          .concat(eventDetail.user_ids.filter((item) => item.filterType === "agent").map((item) => item.name)),
      };
      dispatch(handleAddEvent(obj));
    }
  };

  const handelUpdateEvent = () => {
    if (handleValidate()) {
      let obj = {
        id: eventData.id,
        rsvp_deadline: eventDetail?.rsvp_deadline ? moment(new Date(eventDetail?.rsvp_deadline)).tz("America/New_York").format() : null,
        event_date: moment(new Date(eventDetail?.event_date)).tz("America/New_York").format(),
        start_time: eventDetail?.start_time,
        end_time: eventDetail?.end_time,
        title: eventTitle.current.value,
        location_title: locationTitle.current.value,
        location: eventLocation.current.value,
        body: eventBody.current.value,
        event_type: eventDetail?.event_type,
        in_person_or_virtual: eventDetail.in_person_or_virtual,
        virtual_info: eventDetail?.virtual_info,
        icon: eventDetail.icon,
      };
      dispatch(handleUpdateEvent(obj));
    }
  };
  const handleEventDel = () => {
    dispatch(handleDeleteEvent({ id: eventData.id }));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={Boolean(open)}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" }, "& .MuiDialog-container": { height: "95vh", mt: "50px" } }}
      >
        <DialogTitle className={classes.modalHeading} id="alert-dialog-title">
          <Box> {open === "update" ? "Event Detail" : "Add Event"}</Box>
          {open === "update" ? (
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {deleteEvent.isLoading ? <CircularProgress size={25} /> : ""}
              <ButtonGroups
                id="Manage-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="36px"
                size="medium"
                color="white"
                minWidth="140px"
                options={[
                  {
                    title: "Edit Details",
                    value: "edit",
                    icon: <BorderColorIcon sx={{ color: "black" }} fontSize="small" />,
                  },
                  { title: "View RSVP List", value: "view", icon: <EmailOutlinedIcon sx={{ color: "black" }} fontSize="small" /> },
                  { title: "Create Alert", value: "create", icon: <ErrorOutlinedIcon sx={{ color: "black" }} fontSize="small" /> },
                  { title: "Delete Event", value: "delete", icon: <DeleteIcon sx={{ color: "black" }} fontSize="small" /> },
                ].filter((item)=>((!eventDetail?.rsvps && item.value!=="view") || eventDetail?.rsvps))}
                onChangeMenu={(value) => {
                  setActionType(value);
                  if (value === "delete") {
                    handleEventDel();
                  } else if (value === "view") {
                    setOpenList(true);
                  } else if (value === "create") {
                    setOpenAlert(eventData.id);
                    handleClose();
                  }
                }}
                value={actionType}
                placeholder={"Manage"}
                {...props}
              />
            </Stack>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Box sx={{ paddingTop: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container direction={"row"} spacing={3}>
                <Grid sm={3} item sx={{pointerEvents: open === "update" && actionType !== "edit" ? "none" : ""}}>
                  <TextField
                    inputRef={eventTitle}
                    onBlur={() => handleUpdateErrMsg("eventTitle", eventTitle.current.value)}
                    fullWidth
                    variant="standard"
                    helperText="Event Title"
                    placeholder="Sales Meeting"
                    error={errMsg.eventTitle}
                    defaultValue={eventData?.title || ""}
                    InputProps={{
                      readOnly: open === "update" && actionType !== "edit"? true:false,
                    }}
                  />
                </Grid>
                <Grid sm={3} item sx={{pointerEvents:open === "update" && actionType !== "edit"? "none":""}}>
                  <DatePicker
                    views={["day"]}
                    disablePast
                    value={eventDetail.event_date}
                    onChange={(newValue) => handleUpdateDetail("event_date", newValue)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} variant="standard" error={errMsg.event_date} helperText={"Event Date"} />
                    )}
                
                  />
                </Grid>
                <Grid sm={3} item>
                  {/* <TimePicker
                    value={eventDetail.start_time}
                    onChange={(newValue) => handleUpdateDetail("start_time", newValue)}
                    placeholder="state time"
                    shouldDisableClock
                    renderInput={(params) => (
                      <TextField fullWidth {...params} variant="standard" error={errMsg.start_time} helperText={"Event Start Time"} />
                    )}
                  /> */}
                  <TextField
                    fullWidth
                    type="time"
                    variant="standard"
                    error={errMsg.start_time}
                    value={eventDetail.start_time}
                    onChange={(e) => handleUpdateDetail("start_time", `${e.target.value}:00`)}
                    helperText={"Event Start Time"}
                    sx={{
                      '& input[type="time"]::-webkit-calendar-picker-indicator': {
                        fontSize: "22px",
                        color: "rgba(0, 0, 0, 0.54) !important",
                        cursor: "pointer",
                      },
                    }}
                    InputProps={{
                      readOnly: open === "update" && actionType !== "edit"? true:false,
                    }}
                  />
                </Grid>
                <Grid sm={3} item>
                  <TextField
                    fullWidth
                    type="time"
                    variant="standard"
                    error={errMsg.end_time}
                    value={eventDetail.end_time}
                    onChange={(e) => handleUpdateDetail("end_time", `${e.target.value}:00`)}
                    helperText={"Event End Time"}
                    sx={{
                      '& input[type="time"]::-webkit-calendar-picker-indicator': {
                        fontSize: "22px",
                        color: "rgba(0, 0, 0, 0.54) !important",
                        cursor: "pointer",
                      },
                    }}
                    InputProps={{
                      readOnly: open === "update" && actionType !== "edit"? true:false,
                    }}
                  />
                </Grid>
                <Grid sm={3} item>
                  <TextField
                    inputRef={locationTitle}
                    onBlur={() => handleUpdateErrMsg("locationTitle", locationTitle.current.value)}
                    fullWidth
                    variant="standard"
                    helperText="Location Title"
                    placeholder="Atlanta Office"
                    error={errMsg.locationTitle}
                    defaultValue={eventData?.location_title || ""}
                    InputProps={{
                      readOnly: open === "update" && actionType !== "edit"? true:false,
                    }}
                  />
                </Grid>
                <Grid sm={6} item>
                  <TextField
                    inputRef={eventLocation}
                    onBlur={() => handleUpdateErrMsg("eventLocation", eventLocation.current.value)}
                    fullWidth
                    variant="standard"
                    helperText="Location Address"
                    placeholder="1792 Woodstock Rd Ste 100, Roswell, GA 30075"
                    error={errMsg.eventLocation}
                    defaultValue={eventData?.location}
                    InputProps={{
                      readOnly: open === "update" && actionType !== "edit"? true:false,
                    }}
                  />
                </Grid>
                <Grid sm={12} item>
                  <TextField
                    inputRef={eventBody}
                    onBlur={() => handleUpdateErrMsg("eventBody", eventBody.current.value)}
                    fullWidth
                    variant="outlined"
                    rows={3}
                    multiline
                    size="small"
                    placeholder="Event Description"
                    helperText={errMsg.eventBody}
                    error={errMsg.eventBody}
                    defaultValue={eventData?.body || ""}
                    InputProps={{
                      readOnly: open === "update" && actionType !== "edit"? true:false,
                    }}
                  />
                </Grid>
                <Grid sm={3} item sx={{pointerEvents:open === "update" && actionType !== "edit"? "none":""}}>
                  <TextField
                    value={eventDetail.event_type}
                    onChange={(e) => handleUpdateDetail("event_type", e.target.value)}
                    fullWidth
                    select
                    variant="standard"
                    helperText="Event type"
                    error={errMsg.event_type}
                  >
                    <MenuItem value="training">Meeting</MenuItem>
                    <MenuItem value="meeting">Party</MenuItem>
                    <MenuItem value="party">Training</MenuItem>
                  </TextField>
                </Grid>
                <Grid sm={3} item sx={{pointerEvents:open === "update" && actionType !== "edit"? "none":""}}>
                  <TextField
                    value={eventDetail.in_person_or_virtual}
                    onChange={(e) => handleUpdateDetail("in_person_or_virtual", e.target.value)}
                    fullWidth
                    select
                    variant="standard"
                    helperText="In-Person/Virtual"
                    error={errMsg.in_person_or_virtual}
                  >
                    <MenuItem value="in_person">In-Person Only</MenuItem>
                    <MenuItem value="virtual">Virtual Only</MenuItem>
                    <MenuItem value="in_person_or_virtual">In-Person/Virtual</MenuItem>
                  </TextField>
                </Grid>
                <Grid sm={6} item sx={{ paddingLeft: "40px !important",pointerEvents:open === "update" && actionType !== "edit"? "none":""}}> 
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography sx={{ fontSize: "14px" }}>Select Icon:</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleUpdateDetail("icon", "");
                            }
                            setIsNoIcon(e.target.checked);
                          }}
                          checked={isNoIcon}
                        />
                      }
                      label="No Icon"
                    />
                  </Stack>
                  <Stack sx={{ pointerEvents: isNoIcon ? "none" : "" }} alignItems={"center"} direction={"row"} spacing={0}>
                    {icons.map((item, index) => (
                      <IconButton
                        sx={{
                          borderRadius: "2px",
                          border: item.value === eventDetail?.icon ? "1px solid rgba(0, 0, 0, 0.5)" : "1px solid  white",
                          background: item.value === eventDetail?.icon ? "rgba(151, 151, 151, 0.2)" : "",
                          padding: "6px",
                          "&:hover": { border: "1px solid  rgba(0, 0, 0, 0.04)" },
                        }}
                        onClick={() => handleUpdateDetail("icon", item.value)}
                      >
                        <img src={item.icon} width="26px" alt={item.icon} />
                      </IconButton>
                    ))}
                  </Stack>
                </Grid>
                {eventDetail.in_person_or_virtual === "virtual" || eventDetail.in_person_or_virtual === "in_person_or_virtual" ? (
                  <Grid sm={12} item>
                    <TextField
                      value={eventDetail.virtual_info}
                      onChange={(e) => handleUpdateDetail("virtual_info", e.target.value)}
                      fullWidth
                      variant="outlined"
                      rows={4}
                      multiline
                      size="small"
                      placeholder="Zoom Information (optional)"
                      InputProps={{
                        readOnly: open === "update" && actionType !== "edit"? true:false,
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid sm={12} item sx={{ pointerEvents: open === "update" ? "none" : "" }}>
                  <MultipleDropsdownSelection
                    placeholder="Add Invitees"
                    label=""
                    detail={eventDetail}
                    setDetail={setEventDetail}
                    field="user_ids"
                    options={handleGetOptions()}
                    disableEmailCheck
                    updateFlag={open === "update" ? true : false}
                    {...props}
                  />
                </Grid>
                <Grid sm={2} item sx={{ pointerEvents: open === "update" ? "none" : "" }}>
                  <FormControlLabel
                    control={<Switch onChange={(e) => handleUpdateDetail("rsvps", e.target.checked)} checked={eventDetail.rsvps} />}
                    label="RSVPs"
                  />
                </Grid>
                <Grid sm={3} item sx={{ pointerEvents: open === "update" ? "none" : "" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => handleUpdateDetail("allowed_more_than_one", e.target.checked)}
                        checked={eventDetail.allowed_more_than_one}
                      />
                    }
                    label="Allow Plus 1"
                  />
                </Grid>
                {eventDetail?.rsvps ? (
                  <Grid sm={3} item sx={{ pointerEvents: open === "update" ? "none" : "" }}>
                    <TextField
                      fullWidth
                      type={"number"}
                      value={eventDetail.max_guest_count}
                      onChange={(e) => handleUpdateDetail("max_guest_count", e.target.value)}
                      variant="standard"
                      helperText="Max Guest Count"
                      placeholder="100"
                    />
                  </Grid>
                ) : null}
                {eventDetail?.rsvps ? (
                  <Grid sm={3} item>
                    <DatePicker
                      views={["day"]}
                      disablePast
                      value={eventDetail.rsvp_deadline}
                      onChange={(newValue) => handleUpdateDetail("rsvp_deadline", newValue)}
                      renderInput={(params) => <TextField fullWidth {...params} variant="standard" error={false} helperText={"RSVP Deadline"} />}
                    />
                  </Grid>
                ) : null}
                {eventDetail?.rsvps ? (
                  <Grid sm={12} item>
                    <Stack direction={"row"} spacing={2}>
                      <Typography>{open === "update" ? " Questions:" : "Custom Questions:"}</Typography>
                      {open === "update" ? null : (
                        <Button onClick={addQuestion} classes={{ root: classes.addButton }} size="small">
                          Add <AddIcon fontSize="small" />
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                ) : null}
              </Grid>
            </LocalizationProvider>
            <br />
            {eventDetail?.rsvps && open !== "update"
              ? questions.map((item, index) => (
                  <Grid container direction={"row"} spacing={3}>
                    <Grid sm={5} item>
                      <TextField
                        fullWidth
                        value={item.question_name}
                        onChange={(e) => handleUpdateQuestionParam("question_name", e.target.value, item)}
                        variant="standard"
                        helperText={`Question ${index + 1}`}
                        placeholder="1792 Woodstock Rd Ste 100, Roswell, GA 30075"
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(e) =>
                              handleUpdateQuestionParam("question_type", e.target.checked ? "multiple_choice" : "written_answer", item)
                            }
                            checked={item.question_type === "multiple_choice"}
                          />
                        }
                        label="Multiple Choice"
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(e) =>
                              handleUpdateQuestionParam("question_type", e.target.checked ? "written_answer" : "multiple_choice", item)
                            }
                            checked={item.question_type === "written_answer"}
                          />
                        }
                        label="Written Answer"
                      />
                    </Grid>
                    <Grid sm={1} item>
                      {" "}
                      <IconButton title="Remove Question" onClick={() => handleRemoveQuestion(index)}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Grid>
                    {item.question_type === "multiple_choice" ? (
                      <Grid sm={8} item>
                        <Grid container direction={"row"} spacing={2}>
                          {item.question_options.map((item1, index1) => (
                            <Grid sm={6} item>
                              <TextField
                                onChange={(e) => handleUpdateChoice(e.target.value, index, index1)}
                                value={item1}
                                fullWidth
                                variant="standard"
                                helperText={`Choice ${index1 + 1}`}
                                placeholder="Atlanta Office"
                              />
                            </Grid>
                          ))}
                          <Grid sm={6} item>
                            <Button onClick={() => handleAddChoice(index)} classes={{ root: classes.addButton }} size="small">
                              Add <AddIcon fontSize="small" />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                ))
              : null}
            {open === "update" && preQuestions?.length
              ? preQuestions.map((item, index) => (
                  <Grid container direction={"row"} spacing={3}>
                    <Grid sm={6} item>
                      <TextField
                        fullWidth
                        value={item.question_title}
                        variant="standard"
                        helperText={`Question ${index + 1}`}
                        placeholder="1792 Woodstock Rd Ste 100, Roswell, GA 30075"
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <FormControlLabel control={<Switch checked={item.question_type === "multiple_choice"} />} label="Multiple Choice" />
                    </Grid>
                    <Grid sm={3} item>
                      <FormControlLabel control={<Switch checked={item.question_type === "written_answer"} />} label="Written Answer" />
                    </Grid>

                    {item.question_type === "multiple_choice" ? (
                      <Grid sm={8} item>
                        <Grid container direction={"row"} spacing={2}>
                          {item.event_question_options.map((item1, index1) => (
                            <Grid sm={6} item>
                              <TextField
                                value={item1.option}
                                fullWidth
                                variant="standard"
                                helperText={`Choice ${index1 + 1}`}
                                placeholder="Atlanta Office"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                ))
              : null}
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            color="inherit"
            variant="outlined"
          >
            Cancel
          </Button>
          {open !== "update" ? (
            <LoadingButton
              onClick={handelCreateEvent}
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={addEvent.isLoading}
            >
              Create
            </LoadingButton>
          ) : null}

          {actionType === "edit" ? (
            <LoadingButton
              onClick={handelUpdateEvent}
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={updateEvent.isLoading}
            >
              Update
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <RSVPListModal {...props} open={openList} setOpen={setOpenList} />
      <AddAlertModal open={openAlert} setOpen={setOpenAlert} alertData={{}} />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddEventModalStyle" })(AddEventModal);
