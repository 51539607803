import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, IconButton, Box, Stack } from "@mui/material";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AddAgentPoint, updateAgentPoint, deleteAgentPoint } from "../../../../redux/agents/agentRosterDetail/points";
import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { CustomTooltip } from "../../../../components/shared/tooltip";
function UpdatePointActivity(props) {
  let { open, setOpen, classes, allActivities, pointData, month, handleDeleteUpdate } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [pointValue, setPointValue] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEditAble, setIsEditAble] = useState(false);
  const { id } = useParams();
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const [pointDetail, setPointDetail] = useState({
    description: "",
    event: "",
  });

  const addPoint = useSelector((state) => state.agentRoster.points.addPoint);
  const updatePoint = useSelector((state) => state.agentRoster.points.updatePoint);
  const deletePoint = useSelector((state) => state.agentRoster.points.deletePoint);
  useEffect(() => {
    if (open === "update") {
      setPointDetail({
        description: pointData.description,
        event: pointData?.title || pointData?.action,
      });
      setPointValue(pointData.point);
    }
  }, [open]);

  const handleClose = () => {
    setPointDetail({
      description: "",
      event: "",
    });
    setPointValue(0);
    setOpen(false);
    setIsEditAble(false);
  };
  const handleUpdateDetail = (field, value) => {
    setPointDetail({ ...pointDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!pointDetail.event) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the activity type",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!pointDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter the description";
      setErrorAlert({
        errorMsg: "Please enter the description",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSuccess = (update) => {
    setErrorAlert({
      errorMsg: `You have successfully ${update ? "updated" : "added"} the point`,
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleSuccessDelete = () => {
    setErrorAlert({
      errorMsg: `You have successfully delete the point`,
      errorType: "success",
      isOpen: true,
    });
    handleClose();
    setOpenDelete(false);
    if (month === "current") {
      handleDeleteUpdate(pointData?.point);
    }
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSavePoint = () => {
    if (handleValidate()) {
      let obj = {
        schema: { ...pointDetail },
        user_id: id,
        handleSuccess,
        handleError,
      };
      dispatch(AddAgentPoint(obj));
    }
  };

  const handleUpdatePoint = () => {
    if (handleValidate()) {
      let obj = {
        schema: { ...pointDetail, point_id: pointData.id },
        user_id: id,
        handleSuccess,
        handleError,
      };
      dispatch(updateAgentPoint(obj));
    }
  };

  const handleDeletePoint = () => {
    let obj = {
      point_id: pointData.id,
      user_id: id,
      handleSuccessDelete,
      handleError,
    };
    dispatch(deleteAgentPoint(obj));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" }, "& .MuiDialog-container": { mt: "30px" } }}
      >
        <DialogTitle className={classes.appointmentModalHeading} sx={{ mt: "10px", mb: "15px" }} id="alert-dialog-title">
          {open === "update" ? <Box>Activity Detail</Box> : <Box>Add Activity</Box>}
          {open === "update" ? (
            <Stack direction={"row"} spacing={0} alignItems={"center"}>
              {isEditAble ? (
                <CustomTooltip title="Cancel Update">
                  <IconButton onClick={() => setIsEditAble(false)} size="small">
                    <HighlightOffRoundedIcon fontSize="small" />
                  </IconButton>
                </CustomTooltip>
              ) : (
                <CustomTooltip title="Update">
                  <IconButton onClick={() => setIsEditAble(true)} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </CustomTooltip>
              )}{" "}
              <CustomTooltip title="Delete">
                <IconButton size="small" onClick={() => setOpenDelete(true)}>
                  <DeleteRoundedIcon />
                </IconButton>
              </CustomTooltip>
            </Stack>
          ) : null}
        </DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <Stack direction="row" spacing={3} sx={{ overflow: "visible" }}>
            <Box sx={{ zIndex: "1", pointerEvents: open === "update" ? "none" : "" }}>
              <ButtonGroups
                id="activity-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="38.5px"
                size="medium"
                color="white"
                minWidth="300px"
                menuHeight="360px"
                options={
                  Object.values(allActivities)?.length
                    ? Object.values(allActivities).map((item, index) => ({
                        title: item.activity?.length > 35 ? `${item.activity.slice(0, 35)}...` : item.activity,
                        value: Object.keys(allActivities)[index],
                        points: item.points,
                      }))
                    : []
                }
                onChangeMenu={(value, data) => {
                  handleUpdateDetail("event", value);
                  setPointValue(data.points);
                }}
                placeholder={open === "update" ? pointDetail.event : "Activity Type"}
                value={pointDetail.event}
                {...props}
              />
            </Box>

            <Box>
              <Button size="small" variant="text" sx={{ mt: "6px", color: "rgba(0, 0, 0, 0.87)", textTransform: "capitalize" }}>
                Point Value: <Box sx={{ display: "inline", color: "#2E7D32", ml: "8px" }}> {pointValue ? `+${pointValue}` : "0"} </Box>
              </Button>
            </Box>
          </Stack>
          <Box sx={{ pointerEvents: open === "update" && !isEditAble ? "none" : "", mt: "20px" }}>
            <TextField
              value={pointDetail.description}
              onChange={(e) => handleUpdateDetail("description", e.target.value)}
              fullWidth
              classes={{ root: classes.detailInput }}
              placeholder={"Description"}
              variant="outlined"
              size="small"
              error={errMsg.description}
              helperText={errMsg.description}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          {open === "update" && isEditAble ? (
            <LoadingButton
              onClick={handleUpdatePoint}
              classes={{ root: classes.commisionSelectionButTon }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={updatePoint.isLoading}
            >
              Update
            </LoadingButton>
          ) : (
            ""
          )}
          {open !== "update" ? (
            <LoadingButton
              onClick={handleSavePoint}
              classes={{ root: classes.commisionSelectionButTon }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={addPoint.isLoading}
            >
              Save
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Dialog
        disablePortal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" } }}
      >
        <DialogContent sx={{ width: "370px", textAlign: "center", mt: "20px" }}>
          Are you sure you want to delete the Activity titled “{pointData?.title || pointData?.action}: {pointData?.description} ?
        </DialogContent>
        <DialogActions sx={{ mb: "20px", justifyContent: "center" }}>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            classes={{ root: classes.deleteButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={deletePoint.isLoading}
            onClick={handleDeletePoint}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "UpdatePointActivityStyle" })(UpdatePointActivity);
