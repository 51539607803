import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllLockboxesPagination = createAsyncThunk(
  "LockBox/getAllLockboxesPagination",
  async (data, thunkAPI) => {
    let url = `${process.env.REACT_APP_BASE_URL}api/inventory?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&sortBy=id&sortOrder=desc&status=available&type=lockbox`;

    if (data.search && data.search.length !== 0) {
      url += `&search=${data.search}`;
    }

    try {
      const resp = await axios.get(
        url,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return { data: resp.data?.data, pageNumber: data.pageNumber};
    } catch (error) {
      console.log(error, 'lockboxes');
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

