import React, { useState } from "react";
import EventPage from "./events";
import AlertPage from "./alerts";
import AlertTemplates from "./templates";
import { Route, Routes } from "react-router-dom";
import { Card, Box, Tabs, Tab, Button, TextField, Container } from "@mui/material";
import { TabContext } from "@mui/lab";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Link, useLocation } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const EventsAndAlerts = (props) => {
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [openAddEvent, setOpenAddEvent] = useState(false);
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const [openAddAlert, setOpenAddAlert] = useState(false);
  const [search, setSearch] = useState(false);
  let { classes } = props;
  const location = useLocation();
  const handleGetTitle = () => {
    if (location.pathname?.split("events_and_alerts")[1] === "/events") {
      return "Event";
    } else if (location.pathname?.split("events_and_alerts")[1] === "/alerts") {
      return "Alert";
    } else {
      return "Template";
    }
  };
  const handleOpenModal = () => {
    if (handleGetTitle() === "Event") {
      setOpenAddEvent(true);
    } else if (handleGetTitle() === "Template") {
      setOpenAddTemplate(true);
    } else {
      setOpenAddAlert(true);
    }
  };
  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100", overflow: "visible" }}>
        {" "}
        <Box className={classes.TopTransactionsBarArea}>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TransactionsTabsUI}>
                  {!location?.state?.dashboardView ? (
                    <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                      variant="scrollable"
                      aria-label="transactions-tabs"
                      sx={{ justifyContent: "space-around" }}
                    >
                      <Tab
                        label="Events"
                        value={`/events_and_alerts/events`}
                        to={`/events_and_alerts/events`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() => setSearch(false)}
                      />
                      <Tab
                        label="Alerts"
                        value={`/events_and_alerts/alerts`}
                        to={`/events_and_alerts/alerts`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() => setSearch(false)}
                      />{" "}
                      <Tab
                        label="Alert Templates"
                        value={`/events_and_alerts/alert-template`}
                        to={`/events_and_alerts/alert-template`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() => setSearch(false)}
                      />
                    </Tabs>
                  ) : null}
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {location.pathname?.split("events_and_alerts")[1] === "/events" ? (
              <FormControlLabel
                sx={{ textTransform: "uppercase", "& 	.MuiFormControlLabel-label": { fontSize: "14px !important", color: "black" } }}
                control={<Switch checked={isCalendarView} onChange={(e) => setIsCalendarView(e.target.checked)} />}
                label="Calendar view"
              />
            ) : null}
            {!location?.state?.dashboardView ? (
              <Button onClick={handleOpenModal} className={classes.addTransactionButton} variant="contained" startIcon={<AddCircleIcon />}>
                Add {handleGetTitle()}
              </Button>
            ) : null}{" "}
            <TextField
              type="search"
              value={search === false ? "" : search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              variant="outlined"
              placeholder={`Search ${handleGetTitle()}`}
            />
          </Box>
        </Box>
      </Card>
      <Container maxWidth="xl">
        <Routes>
          <Route
            exact
            path="/events"
            element={
              <EventPage
                isCalendarView={isCalendarView}
                search={search}
                openAddEvent={openAddEvent}
                setOpenAddAlert={setOpenAddAlert}
                setOpenAddEvent={setOpenAddEvent}
                {...props}
              />
            }
          />
          <Route
            exact
            path="/alerts"
            element={<AlertPage openAddAlert={openAddAlert} search={search} setOpenAddAlert={setOpenAddAlert} {...props} />}
          />
          <Route
            exact
            path="/alert-template"
            element={<AlertTemplates openAddTemplate={openAddTemplate} search={search} setOpenAddTemplate={setOpenAddTemplate} {...props} />}
          />
        </Routes>
      </Container>
    </React.Fragment>
  );
};
export default withStyles(styleSheet, { name: "EventsAndAlertsStyle" })(EventsAndAlerts);
