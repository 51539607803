import React, { useEffect, useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SliderSection from "./sliderSection";
import SuggestedSection from "./suggestedSection";
import TemplateUpdateForm from "./updateForm";
import { getTemplateDetail } from "../../../../../redux/marketingCenter/digital";
import { useDispatch, useSelector } from "react-redux";
function CategoryTemplate(props) {
  let { classes } = props;
  const { id } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templateDetail = useSelector(
    (state) => state.marketingCenter.digital.templateDetail
  );
  useEffect(() => {
    dispatch(getTemplateDetail({ templateId: id }));
  }, [id]);

  return (
    <Box className={classes.templateHeader}>
      <Box className={classes.socialMediaHeader}>
        <Box
          onClick={() => navigate(-1)}
          className={classes.socialMediaHeaderLeft}
        >
          <KeyboardBackspaceIcon />
          <Typography className={classes.socialMediaHeaderLeftContent}>
            Back to Templates
          </Typography>
        </Box>
      </Box>
      {templateDetail?.isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={6}>
          <Grid item md={8}>
            <SliderSection {...props} />

            <SuggestedSection {...props} />
          </Grid>
          <Grid item md={4}>
            <Typography className={classes.templateHeading}>
              {templateDetail?.data?.title}
            </Typography>
            <Typography className={classes.templateSubHeading}>
              {isSubmitted
                ? "Your request has been submitted!"
                : "  Fill out the form below to request this design."}
            </Typography>
            {!isSubmitted ? (
              <Typography className={classes.templateContent}>
                Marketing requests are handled in the order they are received.
                Please reach out to your admin team for an accurate ETA.
              </Typography>
            ) : null}
            {!isSubmitted ? <TemplateUpdateForm setIsSubmitted={setIsSubmitted} {...props} /> : null}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
export default withStyles(styleSheet, { name: "CategoryTemplateStyles" })(
  CategoryTemplate
);
