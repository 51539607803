/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Routes, Route } from "react-router-dom";
import { styleSheet } from "./style.js";
// import "./style.scss";
import { Grid, Container, Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDocDetails } from "../../../redux/docs/getDocDetails";
import HeaderDocs from "./headerDocs";
import DataGridTable from "../../../components/dataGridTable";
import { Folder, Done, MoreVert } from "@mui/icons-material";
import Avatar from "../../../components/uiElements/avatar/index";
import moment from "moment";
import BuyerSeller from "./buyerSeller";
// import AddFolderModal from "./addFolderModal";
import AddFormModal from "./addFormModal";
import PrintModal from "./toolsModel/print";
import DownloadModal from "./toolsModel/download";
import FormDetail from "../formDetail";
import ResponseAlert from "../../../components/responseAlert";
import { deleteForm } from "../../../redux/docs/addfolderapi";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/shared/loading";
import DocsDetailSkelton from "../skeltons/docsDetail"
const DocDetails = ({ classes }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  let navigate = useNavigate();

  const data = useSelector((item) => item.docDetails);

  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [pageSize, setPageSize] = useState(5);

  const [openPrint, setOpenPrint] = React.useState(false);
  const [openDownload, setOpenDownload] = React.useState(false);
  const [openerror, setOpenerror] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMesage] = useState();

  const [fromDetails, setFormDetails] = useState();
  const [fromData, SetFormData] = useState([]);
  const [fromDataOld, SetFormDataOld] = useState([]);
  const [addFomrModalOpen, setAddFormModal] = useState(false);
  const folderName = fromDetails?.name;
  const [formNumber, setFormNumber] = useState();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <>
          {
            params.value.name?
            <Grid container onClick={() => redirectDetails(params.row.id, params.value.name)} className={classes.mAlign}>
              <Folder />
              {params.value.name?.slice(0, 45)} {params.value.name?.length>45?"...":""}
            </Grid>
            :""
          }
            
          </>
        );
      },
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      width: 400,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",

      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid container className={classes.status} onClick={() => redirectDetails(params.row.id, params.value.name)}>
              <Done />
              {params.value.status}
            </Grid>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "lastModifiedBy",
      headerName: "Last Modified By",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            {params.value.lastModifiedByName ? (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                onClick={() => redirectDetails(params.row.id, params.value.name)}
              >
                <Avatar
                  sx={{ width: 30, height: 28, mr: "10px", borderRadius: "6px", textTransform: "uppercase", color: "white",fontWeight:"400" }}
                  variant="square"
                  src={params.value.image}
                >
                  {params.value.lastModifiedByName?.slice(0, 1)}
                </Avatar>
                {params.value.lastModifiedByName}
              </Stack>
            ) : (
              ""
            )}
          </>
        );
      },
      width: 300,
      editable: false,
      sortable: false,
    },
    {
      field: "dateModified",
      headerName: "Date Modified",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid container onClick={() => redirectDetails(params.row.id, params.value.name)}>
              {params.value.dateDocDetails}
            </Grid>
          </>
        );
      },
      width: 300,
      editable: false,
      sortable: false,
    },
  ];

  const handleClose = () => setAddFormModal(false);

  const handleSearchInputChange = (e) => {
    if (e.target.value) {
      const filterData = fromDataOld?.length && fromDataOld.filter((item) => item.pdf_form.name.toLowerCase().includes(e.target.value.toLowerCase()));
      if (filterData?.length) {
        arrangeDocsData(filterData);
      } else {
        arrangeDocsData(filterData);
      }
    } else {
      arrangeDocsData(fromDataOld);
    }
  };

  useEffect(() => {
    if (data.docDetails) {
      setFormDetails(data.docDetails ? data.docDetails : {});
      SetFormDataOld(data.docDetails.forms?.length ? data.docDetails.forms : []);
      setFormNumber(data?.docDetails?.forms?.map((form) => form.pdf_form.form_number));
      arrangeDocsData(data.docDetails.forms?.length ? data.docDetails.forms : []);
    }
  }, [data.docDetails, data.loading]);

  useEffect(() => {
    getDocDetailsData(id);
  }, []);

  const getDocDetailsData = (docId) => {
    dispatch(getDocDetails(docId));
  };

  const redirectDetails = (formId, formName) => {
    navigate(`form/edit`, {
      state: {
        formId,
        fromDataOld,
        folderName,
        formName,
      },
    });
  };

  const arrangeDocsData = (data) => {
    if (data?.length) {
      let temp = [];
      data.forEach((element) => {
        let details = {
          id: element.id,
          status: { status: "Active" },
          name: {
            name: element.pdf_form.name,
          },
          dateModified: {
            dateDocDetails: element.last_modify ? moment(element.last_modify).format("DD/MM/YYYY") : "",
          },
          lastModifiedBy: {
            image: "",
            lastModifiedByName: element.last_modify_by ? element.last_modify_by : "",
          },
        };
        temp.push(details);
      });
      if (temp.length > 0) {
        SetFormData(temp);
      }
    } else {
      SetFormData([]);
    }
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
  };

  const archiveFunction = () => {
    if (selectedCheckboxIds?.length > 0) {
      Promise.all(
        selectedCheckboxIds?.length &&
          selectedCheckboxIds.map(async (forlderId) => {
            await dispatch(deleteForm(forlderId));
          })
      ).then(() => {
        let new_form_data = [...fromData];
        let filteredFormData = new_form_data.filter((item) => selectedCheckboxIds.findIndex((ite) => ite === item.id) === -1);
        SetFormData(filteredFormData);
        setSelectedCheckboxIds([]);
        setOpenerror(true);
        setAlertType("success");
        setAlertMesage("Successfully deleted!");
        // getDocDetailsData(id);
      });
    } else {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please select folders or folder");
    }
  };

  const addFormModalOpen = () => {
    setAddFormModal(true);
  };
  const handleClosePrint = () => setOpenPrint(false);
  const handleCloseDownload = () => setOpenDownload(false);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={{ height: 400, width: "100%" }}>
            {data.loading ? (
              <Container maxWidth="xl">
                <br />
                <br />
                <DocsDetailSkelton />
              </Container>
            ) : (
              <>
                <HeaderDocs
                  archiveFunction={archiveFunction}
                  handleSearchInputChange={handleSearchInputChange}
                  docName={fromDetails?.name}
                  addform={addFormModalOpen}
                  printTrigger={setOpenPrint}
                  DownloadTrigger={setOpenDownload}
                />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Container maxWidth="xl">
                        <Grid container className={classes.tableAlignment} spacing={2}>
                          <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller
                              tagName="B1"
                              bsName={(fromDetails?.buyer0_first_name || "") + " " + (fromDetails?.buyer0_last_name || "")}
                              bsEmail={fromDetails?.buyer0_email}
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller
                              tagName="S1"
                              bsName={(fromDetails?.seller0_first_name || "") + " " + (fromDetails?.seller0_last_name || "")}
                              bsEmail={fromDetails?.seller0_email}
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller tagName="CA" bsName={`NA`} bsEmail={`NA`} />
                          </Grid>
                          <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller
                              tagName="B2"
                              bsName={(fromDetails?.buyer1_first_name || "") + " " + (fromDetails?.buyer1_last_name || "")}
                              bsEmail={fromDetails?.buyer1_email}
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller
                              tagName="S2"
                              bsName={(fromDetails?.seller1_first_name || "") + " " + (fromDetails?.seller1_last_name || "")}
                              bsEmail={fromDetails?.seller1_email}
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller tagName="L" bsName={``} bsEmail={``} />
                          </Grid>
                        </Grid>
                        <Grid container className={classes.tableAlignment}>
                          <DataGridTable
                            rows={fromData ? fromData : []}
                            columns={columns}
                            pageSize={pageSize}
                            checkboxSelection={true}
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                            setSelectedCheckboxIds={setSelectedCheckboxIds}
                            handlePageSizeChange={handlePageSizeChange}
                          />
                        </Grid>
                      </Container>
                    }
                  />
                  <Route path="/form/edit" element={<FormDetail formsData={fromDataOld} />} />
                </Routes>
                <ResponseAlert setOpen={setOpenerror} open={openerror} alertType={alertType} alertMessage={alertMessage} />
                <AddFormModal
                  addFomrModalOpen={addFomrModalOpen}
                  handleClose={handleClose}
                  modalWidht={`auto`}
                  formNumber={formNumber && formNumber}
                />
              </>
            )}

            <PrintModal open={openPrint} handleClose={handleClosePrint} modalWidht="auto" formData={fromDataOld} documentID={id} />
            <DownloadModal open={openDownload} handleClose={handleCloseDownload} modalWidht="auto" formData={fromDataOld} documentID={id} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styleSheet, { name: "DocDetailsStyle" })(DocDetails);
