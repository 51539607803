import React from "react";
import { useStyles } from "./style.js";
import { Box, Container, Typography } from "@mui/material";
import DetailsGrid from "./DetailsGrid.jsx";
import bganalys from "../../assets/images/viewcma/bganalys.svg";
import moment from "moment";

function Property({ data = {} }) {
  const classes = useStyles();

  const totalCount =
    (parseInt(data?.BathroomsFull, 10) || 0) +
    (parseInt(data?.BathroomsHalf) / 2 || 0);

  const totalCountDif =
    (parseInt(data?.cma_comparable_adjustment?.AdjBathroomsFull, 10) || 0) +
    (parseInt(data?.cma_comparable_adjustment?.AdjBathroomsHalf) || 0);

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.headerSection} p={{ xs: 0, md: 2 }}>
          <Box className={classes.propertyImage}>
            <img
              className={classes.image}
              src={data?.MediaURL || bganalys}
              alt="analys"
            />
          </Box>
          <Box className={classes.addressContainer}>
            <Typography
              className={`${classes.fontFamily} ${classes.address1}`}
              noWrap
            >
              {data?.StreetNumber || ""} {data?.StreetName || ""}
            </Typography>
            <Typography
              className={`${classes.fontFamily} ${classes.address2}`}
              noWrap
            >
              {data?.City || ""},{data?.StateOrProvince || ""}{" "}
              {data?.PostalCode || ""}
            </Typography>
          </Box>
        </Box>
        <Box className={`${classes.tableContainer} alternateDiv`}>
          <DetailsGrid detailValue={data?.ListPrice} />
          <DetailsGrid detailValue={data?.MlsStatus} />
          <DetailsGrid
            detailValue={data?.BedroomsTotal}
            diffrence={data?.cma_comparable_adjustment?.AdjBedroomsTotal}
            positive={false}
          />
          <DetailsGrid
            detailValue={totalCount || 0}
            diffrence={totalCountDif}
          />
          <DetailsGrid
            detailValue={data?.BuildingAreaTotal}
            diffrence={data?.cma_comparable_adjustment?.AdjBuildingAreaTotal}
            positive={true}
          />
          <DetailsGrid detailValue={data?.TaxAnnualAmount} />
          <DetailsGrid
            detailValue={
              data?.MlsStatus.toLowerCase() === "pending" ||
              data?.MlsStatus.toLowerCase() === "under contract" ||
              data?.MlsStatus.toLowerCase() === "active under contract"
                ? data?.OnMarketDate
                  ? moment(data?.OnMarketDate).format("M/D/YY")
                  : data?.ListingContractDate
                  ? moment(data?.ListingContractDate).format("M/D/YY")
                  : "-"
                : data?.ListingContractDate
                ? moment(data?.ListingContractDate).format("M/D/YY")
                : "-"
            }
          />
          <DetailsGrid detailValue={data?.CloseDate || "n/a"} />
          <DetailsGrid detailValue={data?.DaysOnMarket} />
          <DetailsGrid
            detailValue={
              data?.LotSizeAcres ? Math.round(data?.LotSizeAcres * 10) / 10 : 0
            }
          />
          <DetailsGrid detailValue={data?.YearBuilt} />
          <DetailsGrid
            detailValue={data?.PoolFeatures || "n/a"}
            diffrence={data?.cma_comparable_adjustment?.AdjPoolFeatures}
            positive={false}
          />
          <DetailsGrid detailValue={data?.GarageSpaces} />
          <DetailsGrid detailValue={data?.Basement} />
          <DetailsGrid detailValue={data?.ConstructionMaterials} />
          <DetailsGrid detailValue={data?.ArchitecturalStyle} />
          <DetailsGrid detailValue={data?.FireplacesTotal} />
          <DetailsGrid detailValue={data?.Levels} />
          <DetailsGrid
            detailValue={
              data?.LotSizeAcres ? Math.round(data?.LotSizeAcres * 10) / 10 : 0
            }
          />
          <DetailsGrid detailValue={data?.Condition} positive={true} />
        </Box>
      </Container>
      <Box className={classes.bottomContainer}>
        <Box className={classes.totalContainer}>
          <Typography
            className={`${classes.fontFamily} ${classes.bottomValue}`}
          >
            Total Adj:{" "}
          </Typography>
          <Typography
            className={`${classes.fontFamily} ${classes.positive} ${classes.bottomValue}`}
            sx={{ fontWeight: "700 !important" }}
          >
            {data?.cma_comparable_adjustment?.TotalAdj
              ? data?.cma_comparable_adjustment?.TotalAdj
              : "+0.00"}
          </Typography>
        </Box>
        <Box>
          <Typography
            className={`${classes.fontFamily} ${classes.adjustedValue}`}
          >
            {data?.cma_comparable_adjustment?.TotalAdjValue}
          </Typography>
          <Typography
            className={`${classes.fontFamily} ${classes.bottomValue}`}
          >
            Adjusted Value
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Property;
