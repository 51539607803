import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LoadingSkelton from "./loader.jsx";
import PropertyStatsBar from "./statsBar.jsx";
import ListingDetailStats from "./listingDetail.jsx";
import SliderView from "./sliderView.jsx";
import PropertyStats from "./otherStats.jsx";
import PropertyLocation from "./propertyLocation.jsx";
import PropertyBuildingInfo from "./buildingInfo.jsx";
import PaymentCalculator from "./paymentCalculator.jsx";
import PropertyInfo from "./propertyInfo.jsx";
import PublicRecordsInfo from "./publicRecord.jsx";
import { formatAddress, getAcres } from "../../../../../../utils/propertyData";
import { NumberFormat } from "../../../../../../utils/numberFormat";
import ShortCutBar from "./shortCutBar.jsx";
import moment from "moment";
function PropertyDetail(props) {
  let { open, setOpen, classes } = props;
  const contentRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    // Add more refs for other sections as needed
  };
  const activeSectionRef = useRef(null);
  const [activeTab, setActiveTab] = useState("section1");
  const listingDetail = useSelector((state) => state.allCmas.listingDetail);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;

    let active = null;
    if (activeSectionRef.current === true) {
      // Determine the active section based on scroll position
      for (const [sectionId, ref] of Object.entries(contentRefs)) {
        if (
          ref.current &&
          ref.current.getBoundingClientRect().top <= window.innerHeight / 2 &&
          ref.current.getBoundingClientRect().bottom >= window.innerHeight / 2
        ) {
          active = sectionId;
        }
      }

      setActiveTab(active);
    }
  };

  const handleScrollToId = (id) => {
    activeSectionRef.current = false;
    setActiveTab(id);
    contentRefs[id].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setTimeout(() => {
      activeSectionRef.current = true;
    }, 1000);
  };
  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        {" "}
        {listingDetail?.isLoading ? (
          <DialogContent className={classes.propertyDetailArea}>
            <IconButton
              onClick={() => setOpen(false)}
              className={classes.closeButton}
            >
              <CloseRoundedIcon />
            </IconButton>{" "}
            <LoadingSkelton />
          </DialogContent>
        ) : (
          <DialogContent
            onScroll={handleScroll}
            className={classes.propertyDetailArea}
          >
            <IconButton
              onClick={() => setOpen(false)}
              className={classes.closeButton}
            >
              <CloseRoundedIcon />
            </IconButton>
            <Box className={classes.propertyHeaderSection}>
              <PropertyStatsBar {...props} />
            </Box>
            <ShortCutBar
              activeTab={activeTab}
              handleScrollToId={handleScrollToId}
              {...props}
            />
            <div ref={contentRefs.section1} id="section1">
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <SliderView {...props} />
                </Grid>
                <Grid item md={4}>
                  <ListingDetailStats {...props} />
                </Grid>
              </Grid>
              <Typography className={classes.publicRemarks} sx={{ mt: "15px" }}>
                {listingDetail?.data?.PublicRemarks}
              </Typography>
              <Typography
                className={classes.listingBYAgent}
                display="block"
                gutterBottom
                sx={{ mt: "20px" }}
              >
                Listed by {listingDetail?.data?.ListAgentFullName} -{" "}
                {listingDetail?.data?.ListOfficeName}
              </Typography>
            </div>
            <PropertyStats {...props} />
            <div ref={contentRefs.section2} id="section2">
              <PropertyLocation {...props} />
            </div>
            <div ref={contentRefs.section3} id="section3">
              <PropertyBuildingInfo {...props} />
            </div>
            <PaymentCalculator {...props} />
            <div ref={contentRefs.section4} id="section4">
              <PropertyInfo {...props} />
            </div>
            <div ref={contentRefs.section5} id="section5">
              <PublicRecordsInfo {...props} />
            </div>
            <br />
            <br />
            <Typography className={classes.moreInfoHeadingSeo}>
              More information about{" "}
              {formatAddress({
                ...listingDetail?.data,
                isFullAddressNeeded: true,
              })}
            </Typography>
            <Typography className={classes.moreInfoDescriptionSeo}>
              {(
                listingDetail?.data?.StreetNumber ||
                listingDetail?.data?.AddressNumber
              )?.toLowerCase()}{" "}
              {listingDetail?.data?.StreetName?.toLowerCase()}{" "}
              {listingDetail?.data?.StreetSuffix?.toLowerCase()}{" "}
              {listingDetail?.data?.StreetDirSuffix?.toLowerCase()} is a{" "}
              {listingDetail?.data?.PropertySubType?.toLowerCase()} for sale in{" "}
              {listingDetail?.data?.City?.toLowerCase()},{" "}
              {listingDetail?.data?.StateOrProvince}{" "}
              {listingDetail?.data?.PostalCode}. This property was listed for
              sale on{" "}
              {moment(listingDetail?.data?.ListingContractDate).format("LL")} by{" "}
              {listingDetail?.data?.ListOfficeName?.toLowerCase()}. at{" "}
              {listingDetail?.data?.ListPrice
                ? NumberFormat({
                    number: listingDetail?.data?.ListPrice,
                    currency: "USD",
                  })
                : null}
              . This listing's school district is{" "}
              {listingDetail?.data?.CountyOrParish?.split("-")?.[0]} County
              School District. Nearby schools include{" "}
              {listingDetail?.data?.ElementarySchool?.split(
                "-"
              )?.[0]?.toLowerCase()}{" "}
              Elementary School,{" "}
              {listingDetail?.data?.MiddleOrJuniorSchool?.split(
                "-"
              )?.[0]?.toLowerCase()}{" "}
              Middle School and{" "}
              {listingDetail?.data?.HighSchool?.split("-")?.[0]?.toLowerCase()}{" "}
              High School. {listingDetail?.data?.StreetNumber?.toLowerCase()}{" "}
              {listingDetail?.data?.StreetName?.toLowerCase()}{" "}
              {listingDetail?.data?.StreetSuffix?.toLowerCase()}{" "}
              {listingDetail?.data?.StreetDirSuffix?.toLowerCase()} is a{" "}
              {listingDetail?.data?.BedroomsTotal} bed,{" "}
              {listingDetail?.data?.BathroomsTotalDecimal} bath,{" "}
              {(listingDetail?.data?.BuildingAreaTotal &&
                listingDetail?.data?.BuildingAreaTotal > 0) ||
              (listingDetail?.data?.AboveGradeFinishedArea &&
                listingDetail?.data?.AboveGradeFinishedArea > 0)
                ? NumberFormat({
                    number:
                      listingDetail?.data?.BuildingAreaTotal ||
                      listingDetail?.data?.AboveGradeFinishedArea,
                  })
                : getAcres(listingDetail?.data)}{" "}
              {(listingDetail?.data?.BuildingAreaTotal &&
                listingDetail?.data?.BuildingAreaTotal > 0) ||
              (listingDetail?.data?.AboveGradeFinishedArea &&
                listingDetail?.data?.AboveGradeFinishedArea > 0)
                ? "sqft"
                : "Acres"}{" "}
              {listingDetail?.data?.PropertySubType} built in{" "}
              {listingDetail?.data?.YearBuilt}.
            </Typography>
            <br />
          </DialogContent>
        )}
      </Dialog>
    </Fragment>
  );
}
export default withStyles(styleSheet, { name: "PropertyDetailStyle" })(
  PropertyDetail
);
