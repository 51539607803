import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTeamLeaders = createAsyncThunk("teams/getTeamLeaders", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/users/get-all-leaders`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data|| [];
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const initialState = {
  success: false,
  data: [],
  isLoading: false,
  errMsg: null,
};

const teams = createSlice({
  name: "teamLeaders",
  initialState,
  extraReducers: {
    [getTeamLeaders.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getTeamLeaders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.data = action.payload
    },
    [getTeamLeaders.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
  },
});

export const { updateAttachedPlan } = teams.actions;
export default teams.reducer;
