import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Container,
  Card,
  Typography,
  Divider,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Skeleton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import { useDispatch, useSelector } from "react-redux";
import { getSlackChannels } from "../../../redux/leads/leadRouting/getSlackChannels.js";
import { getActionPlans } from "../../../redux/leads/leadRouting/actionPlans.js";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateLeadFlow from "./components/createLeadFlow.js";
import { getLeadRoutingGroups } from "../../../redux/leads/leadRouting/groups";
import {
  getLeadFlows,
  deleteLeadFlow,
  updateLeadFlow,
  getLeadDefaultChannel,
  updateLeadDefaultChannel,
} from "../../../redux/leads/leadRouting/routing";
import LeadRoutingSkelton from "../skelton/leadRoutingSkelton.js";
import DataNotFound from "../../../components/notFound/dataNotFound";
import ConfirmModal from "../../../components/globalModal/ConfirmModal.js";
import ResponseAlert from "../../../components/responseAlert";
import { getMarkets } from "../../../redux/agents/addRecruitAgent.js";
import { useLocation } from "react-router-dom";

function LeadRoutingOverview(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const slackChannels = useSelector((state) => state.leads.slackChannels);
  const actionPlans = useSelector((state) => state.leads.actionPlans);
  const groups = useSelector((item) => item.leads.LeadRoutingGroups.groups);
  const leadFlows = useSelector(
    (item) => item.leads.LeadRoutingOverView.leadFlows
  );
  const deleteFlow = useSelector(
    (item) => item.leads.LeadRoutingOverView.deleteFlow
  );
  const defaultChannel = useSelector(
    (item) => item.leads.LeadRoutingOverView.defaultChannel
  );
  const markets = useSelector((state) => state.agentList.markets);

  useEffect(() => {
    (async () => {
      if (!markets || markets.length === 0) {
        await dispatch(getMarkets());
      }
      if (slackChannels.channels?.length === 0) {
        await dispatch(getSlackChannels());
      }
      if (actionPlans.data?.length === 0 && !actionPlans.success) {
        await dispatch(getActionPlans());
      }
      if (groups.data?.length === 0) {
        await dispatch(getLeadRoutingGroups());
      }
      if (leadFlows.data?.length === 0) {
        await dispatch(getLeadFlows());
      }
      if (!defaultChannel.data?.value) {
        await dispatch(getLeadDefaultChannel());
      }
    })();
  }, [location.pathname]);
  useEffect(() => {
    if (deleteFlow.success && !deleteFlow.isLoading && confirmOpen) {
      setErrorAlert({
        errorMsg: "You have successfully deleted the lead flow",
        errorType: "success",
        isOpen: true,
      });
      setConfirmOpen(false);
    } else if (!deleteFlow.isLoading && deleteFlow.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(deleteFlow.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deleteFlow.isLoading]);

  // useEffect(() => {

  // }, []);

  const handleConfirm = () => {
    dispatch(deleteLeadFlow({ id: confirmOpen }));
  };
  // const handleUpdateLeadFlow = (data) => {};
  const handleUpdateChannelOrGroupValue = (value, data, item, groupId) => {
    if (data.type === "group") {
      dispatch(
        updateLeadFlow({
          id: item.id,
          groupId: data.id,
          slackChannelId: "",
          slackChannelName: "",
          group: groupId ? data : null,
          listGroupId: groupId,
        })
      );
    } else {
      dispatch(
        updateLeadFlow({
          id: item.id,
          groupId: "",
          slackChannelId: data.channelId,
          slackChannelName: data.channelName,
        })
      );
    }
  };

  const handleUpdateDetail = (field, value, item) => {
    dispatch(
      updateLeadFlow({
        id: item.id,
        groupId: item.groupId,
        [field]: value,
      })
    );
  };

  return (
    <Container maxWidth="lg">
      <Card className={classes.leadRoutingOverviewCard} variant="outlined">
        <Box className={classes.leadRoutingOverviewCardTopArea}>
          <Box className={classes.leadRoutingOverviewCardTop}>
            {console.log(defaultChannel, "defaultChannel")}
            <Typography variant="h6">Default Lead Channel:</Typography>
            {defaultChannel.isLoading ? (
              <Skeleton animation="wave" width="230px" height={60} />
            ) : (
              <ButtonGroups
                id="category-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="35px"
                size="medium"
                color="white"
                minWidth="190px"
                menuHeight="300px"
                options={groups.data
                  ?.map((item) => ({
                    ...item,
                    type: "group",
                    title: `Group: ${item.name}`,
                    value: item.id,
                  }))
                  .concat(
                    slackChannels.channels?.map((item) => ({
                      ...item,
                      type: "channel",
                      title: `#${item.channelName}`,
                      value: item.channelName,
                    }))
                  )}
                // options={slackChannels.channels?.map((item) => ({ title: item.channelName, value: item.channelId }))}
                onChangeMenu={(value, data) =>
                  handleUpdateChannelOrGroupValue(
                    value,
                    data,
                    {
                      id: defaultChannel.data?.id,
                    },
                    true
                  )
                }
                placeholder={
                  defaultChannel.data?.groupId
                    ? "Group : " + defaultChannel.data?.group.name
                    : defaultChannel.data?.slackChannelName
                }
                {...props}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.leadsFlowsHeading}>
          <Typography variant="h6">Lead Flows</Typography>
          <Button
            onClick={() => setOpen(true)}
            className={classes.leadsFlowsHeadingButton}
            variant="outlined"
            size="small"
            color="inherit"
            startIcon={<AddIcon />}
          >
            Add Flow
          </Button>
        </Box>
        <Divider />
        <Box className={classes.leadsFlowsListBox}>
          <Card variant="outlined" className={classes.leadsFlowsListBoxItem}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {leadFlows.isLoading ? (
                    <LeadRoutingSkelton hideAvatar />
                  ) : (
                    leadFlows.data.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ paddingLeft: "0px" }}
                        >
                          <Typography variant="h5">{item.value}</Typography>
                          Last Lead: {item.lead?.name || "--"} |{" "}
                          {item.lastLeadTimestamp
                            ? moment(item.lastLeadTimestamp).fromNow()
                            : "--"}{" "}
                          {/*  Kristina Gaines | 3 Hours ago */}
                          <br />
                          <Button
                            onClick={() => setConfirmOpen(item.id)}
                            color="error"
                            size="small"
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </TableCell>
                        <TableCell align="right">Distribution</TableCell>
                        <TableCell align="right">
                          {" "}
                          <ButtonGroups
                            id="category-button-menu"
                            bgColor="#0B0909"
                            bgColorHover="#0B0909"
                            placement={"bottom"}
                            height="35px"
                            size="medium"
                            color="white"
                            minWidth="190px"
                            options={groups.data
                              ?.map((item) => ({
                                ...item,
                                type: "group",
                                title: `Group: ${item.name}`,
                                value: item.id,
                              }))
                              .concat(
                                slackChannels.channels?.map((item) => ({
                                  ...item,
                                  type: "channel",
                                  title: `#${item.channelName}`,
                                  value: item.channelName,
                                }))
                              )}
                            onChangeMenu={(value, data) => {
                              handleUpdateChannelOrGroupValue(
                                value,
                                data,
                                item,
                                item.groupId
                              );
                            }}
                            placeholder={
                              item.groupId
                                ? "Group : " +
                                  (item?.group?.name ||
                                    groups.data?.find(
                                      (val) => val.id === item.groupId
                                    )?.name)
                                : item.slackChannelName
                            }
                            menuHeight="300px"
                            {...props}
                          />
                        </TableCell>
                        {/* <TableCell align="right">Action Plan</TableCell>
                        <TableCell align="right">
                          {" "}
                          <ButtonGroups
                            id="category-button-menu"
                            bgColor="#0B0909"
                            bgColorHover="#0B0909"
                            placement={"bottom"}
                            height="35px"
                            size="medium"
                            color="white"
                            minWidth="190px"
                            options={actionPlans.data?.map((item) => ({ title: item.name, value: item.name }))}
                            // onChangeMenu={(value) => handleUpdateDetail("category", value)}
                            placeholder="choose action plan"
                            {...props}
                          />
                        </TableCell> */}
                        <TableCell align="right">Market</TableCell>
                        <TableCell align="right">
                          {" "}
                          <ButtonGroups
                            options={
                              markets && markets.length
                                ? markets?.map((item) => ({
                                    title: item.name,
                                    value: item.id,
                                  }))
                                : []
                            }
                            value={item.market_id}
                            onChangeMenu={(value) =>
                              handleUpdateDetail("market_id", value, item)
                            }
                            id="markets-button-menu"
                            bgColor="#0B0909"
                            bgColorHover="#0B0909"
                            placement={"bottom"}
                            color="white"
                            placeholder={
                              item.market_id ? item.market.name : "Select"
                            }
                            {...props}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {leadFlows?.data?.length === 0 && !leadFlows.isLoading && (
              <DataNotFound
                color="#BDBDBD"
                fontSize="20px"
                title="No lead flow exists!"
              />
            )}
          </Card>
        </Box>
      </Card>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <CreateLeadFlow open={open} setOpen={setOpen} {...props} />
      <ConfirmModal
        loading={deleteFlow?.isLoading}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        handleConfirm={handleConfirm}
        title="Confirmation"
        content="Are you sure to want to delete this lead flow?"
        {...props}
      />
    </Container>
  );
}
export default withStyles(styleSheet, { name: "LeadRoutingOverview" })(
  LeadRoutingOverview
);
