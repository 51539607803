import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItemText,
  Paper,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import moment from "moment";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import flowerIcon from "../../../../assets/images/agents/flowerIcon.png";
import flowerIconWhite from "../../../../assets/images/agents/flowerIconWhite.png";
import AttachMLSNumber from "../../modals/attachMLS";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import houseIcon from "../../../../assets/images/houseIcon.png";
import { useNavigate } from "react-router-dom";
import AvatarCustom from "../../../../components/uiElements/avatar/index";

const TransactionHeaderCard = (props) => {
  const [open, setOpen] = useState(false);
  let { ContractData, classes, isAllowRedirectDocs, isAllowRedirectTask } =
    props;
  const [imageFlower, setImageFlower] = useState(flowerIcon);
  const [mlsData, setMlsData] = useState({});
  const navigate = useNavigate();
  let {
    innerHeight,
    outerHeight,
    avatarWidth,
    avatarHeight,
    hideBed,
    hideBath,
    hideSqFt,
    showClosingDate,
    disableMLs,
    disableCard,
    showInitialFull,
  } = props;

  return (
    <Paper
      elevation={disableCard ? 0 : 2}
      sx={{
        backgroundColor: "white",
        height: outerHeight ? outerHeight : "auto",
      }}
      className={classes.pageRoot}
    >
      <Grid container direction="row">
        <Grid
          md={showInitialFull ? showInitialFull : 8}
          lg={showInitialFull ? showInitialFull : 9}
          xs={12}
          className={classes.TransactionUserInfoLeftPanel}
        >
          <Avatar
            onClick={() => {
              if (isAllowRedirectDocs) {
                navigate(
                  `/transaction/contracts/contract-detail/${ContractData.id}/documents`
                );
              }
              if (isAllowRedirectTask) {
                navigate(
                  `/transaction/contracts/contract-detail/${ContractData.id}/tasks`
                );
              }
            }}
            sx={{
              "& img": { width: "auto" },
              cursor:
                isAllowRedirectDocs || isAllowRedirectTask ? "pointer" : "",
              width: avatarWidth ? `${avatarWidth} !important` : "",
              height: avatarHeight ? `${avatarHeight} !important` : "",
            }}
            variant="rounded"
            src={
              mlsData.property_img
                ? mlsData?.property_img
                : ContractData.property_img
                ? ContractData.property_img
                : houseIcon
            }
          />
          <Box>
            <Typography classes={{ root: classes.TransactionUserInfoLeftName }}>
              {ContractData.street}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                classes={{ root: classes.userInfoDetailPhone }}
                variant="subtitle1"
              >
                {ContractData.city}, {ContractData.state} {ContractData.zipcode}
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{ marginTop: innerHeight ? innerHeight : "40px" }}
            >
              <Grid item>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      ${new Intl.NumberFormat().format(ContractData.price)}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Price
                    </Typography>
                  }
                />
              </Grid>
              {hideBed ? null : (
                <Grid item>
                  <ListItemText
                    primary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateValue,
                        }}
                      >
                        {ContractData.bedrooms || mlsData.bedrooms || "0"}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateDes,
                        }}
                      >
                        Bedrooms
                      </Typography>
                    }
                  />
                </Grid>
              )}
              {hideBath ? null : (
                <Grid item>
                  <ListItemText
                    primary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateValue,
                        }}
                      >
                        {ContractData.baths || mlsData.baths || "0"}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateDes,
                        }}
                      >
                        Bathrooms
                      </Typography>
                    }
                  />
                </Grid>
              )}
              {hideSqFt ? null : (
                <Grid item>
                  <ListItemText
                    primary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateValue,
                        }}
                      >
                        {ContractData.sq_feet || mlsData.sq_feet || "0"}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateDes,
                        }}
                      >
                        Sq. Feet
                      </Typography>
                    }
                  />
                </Grid>
              )}
              {showClosingDate ? (
                <Grid item>
                  <ListItemText
                    primary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateValue,
                        }}
                      >
                        {moment(ContractData?.closing_date).format("ll")}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        classes={{
                          root: classes.TransactionUserInfoDetailStateDes,
                        }}
                      >
                        Closing Date
                      </Typography>
                    }
                  />
                </Grid>
              ) : null}
              <Grid item>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ContractData?.mls_id
                        ? ContractData?.mls_id
                        : ContractData.fmls_number
                        ? ContractData.fmls_number
                        : mlsData
                        ? mlsData.mlsId
                        : ""}
                      {(ContractData.mls_id ||
                        ContractData.fmls_number ||
                        mlsData.mlsId) && (
                        <IconButton
                          onClick={() => setOpen(true)}
                          className={classes.editMLlIcon}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                      )}

                      {ContractData.mls_id ||
                      ContractData.fmls_number ||
                      mlsData.mlsId ? null : (
                        <Button
                          variant="outlined"
                          size="small"
                          color="inherit"
                          onClick={() => setOpen(true)}
                        >
                          Attach
                        </Button>
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      MLS Number
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {!showInitialFull && (
          <Grid md={4} lg={3} xs={12} className={classes.userInfoRightPanel}>
            <Stack
              sx={{ mb: "15px" }}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography
                variant="h6"
                sx={{
                  display: "inline-block",
                  marginRight: "22px",
                  fontSize: "14px",
                }}
              >
                {ContractData?.contract_type === "seller"
                  ? "Listing Agent"
                  : ContractData?.contract_type === "buyer"
                  ? "Buyer Agent"
                  : "Listed By"}
              </Typography>
              <Typography
                sx={{ fontSize: "14px", textAlign: "left", minWidth: "10ch" }}
              >
                {ContractData.agent?.first_name} <br />{" "}
                {ContractData.agent?.last_name}{" "}
              </Typography>
              {ContractData?.agent ? (
                <AvatarCustom
                  sx={{ height: "45px", width: "45px", mr: "10px", ml: "15px" }}
                  variant="rounded"
                  src={
                    ContractData?.agent?.profile_images?.profile_img_thumbnail
                  }
                >
                  {`${ContractData?.agent.first_name?.slice(
                    0,
                    1
                  )}${ContractData?.agent.last_name?.slice(0, 1)}`}
                </AvatarCustom>
              ) : null}
            </Stack>
            <Button
              onClick={() => window.open("tel:" + ContractData?.agent?.phone)}
              variant="outlined"
            >
              <PhoneIcon />
            </Button>

            {ContractData?.agent?.admin_channel_id ? (
              <Button
                onMouseEnter={() => {
                  setImageFlower(flowerIconWhite);
                }}
                onMouseLeave={() => {
                  setImageFlower(flowerIcon);
                }}
                variant="outlined"
                onClick={() =>
                  window.open(
                    `slack://channel?team=${ContractData?.agent?.slack_team_id}&id=${ContractData?.agent?.admin_channel_id}`
                  )
                }
              >
                <img
                  style={{ width: "24px" }}
                  src={imageFlower}
                  alt="flower icon"
                />
              </Button>
            ) : null}

            <Button
              onClick={() =>
                window.open("mailto:" + ContractData?.agent?.email)
              }
              variant="outlined"
            >
              <AlternateEmailRoundedIcon />
            </Button>
          </Grid>
        )}
      </Grid>
      {open && (
        <AttachMLSNumber
          isUpdate={
            ContractData.mls_id || ContractData.fmls_number || mlsData.mlsId
          }
          ContractData={ContractData}
          disableMLs={disableMLs}
          setMlsData={setMlsData}
          open={open}
          id={ContractData.id}
          setOpen={setOpen}
        />
      )}
    </Paper>
  );
};

export default withStyles(styleSheet, { name: "TransactionHeaderCardStyle" })(
  TransactionHeaderCard
);
