export const styleSheet = (theme) => ({
  commisionSelectionButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  deleteButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  inventoryDetailCheckIn: {
    backgroundColor: "#2E7D32 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },

  paymentModalHeading: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  appointmentModalHeading: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    display: "flex",
    justifyContent: "space-between",
  },
  fieldLabelEnroll: {
    fontWeight: "500  !important",
    color: "rgba(0, 0, 0, 0.87)  !important",
    fontSize: "16px  !important",
    marginBottom: "5px !important",
  },
  boxLabelStyle: {
    fontWeight: "400",
    color: "#000000 !important",
    fontSize: "14px !important",
    marginBottom: "8px",
    marginLeft: "1px",
  },
  modalHeading: {
    fontSize: "20px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    display: "flex",
    justifyContent: "space-between",
  },
  notFoundTitle: {
    fontSize: "13px !important",
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  commentBox: {},
  commentBoxCommentsArea: {
    height: "54.2vh",
    padding: "8px",
    overflowY: "auto",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  customScrollBar: {
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  commentBoxCommentCell: {
    fontSize: "10px !important",
    border: "none  !important",
    padding: "6px !important",
  },
  checkoutImgBoxArea: {
    height: "110px",
    width: "100%",
    position: "relative",
    "& img": {
      width: "56px",
    },
  },
  checkoutImgBoxAreaText: {
    position: "absolute  !important",
    top: "50px  !important",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "13px",
    fontWeight: "500",
  },
  checkoutBoxAreaButton: {
    border: "1px solid rgba(33, 33, 33, 0.4)  !important",
    color: "#0B0909  !important",
    "&:hover": {
      border: "1px solid rgba(33, 33, 33, 0.8)  !important",
    },
  },
  checkoutBoxAreaDes: {
    fontSize: "13px  !important",
    marginTop: "4px !important",
  },
  EquipmentDetailBOx: {
    padding: "10px",
    minHeight: "300px",
  },
  EquipmentDetailSubBox: {
    padding: "5px !important",
  },
  agentInventoryModalCardDes: {
    fontSize: "12.5px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)  !important",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "-10px",
    marginBottom: "-10px",
  },

  agentInventoryModalCardHeader: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },

  historyLogsHeaderTitle: {
    fontSize: "20px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",

    minWidth: "10ch",
  },
  historyLogsTime: {
    fontSize: "13px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    whiteSpace: "nowrap !important",
    width: "155px !important",
    marginLeft: "15px",
  },
  historyLogsArea: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "15px",
  },
  historyLogsDetail: {
    fontSize: "13px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
  },
  lockboxInventoryModalCardHeader: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    marginTop: "14px !important",
  },
  signageStatHeader: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    textAlign: "center",
    fontFamily: "AvenirNext  !important",
  },
  signageStatDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    textAlign: "center",
    fontFamily: "AvenirNext  !important",
    color: "rgba(0, 0, 0, 0.5)",
  },
  signageModalCardHeader: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  customerTextField: {
    "& .MuiOutlinedInput-root": {
      fontSize: "12.5px !important",
    },
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customPhoneField: {
    border: "1px solid lightgrey",
    width: "100%",
    height: "40px",
    borderRadius: "5px",
    padding: "8.5px 14px",
    outline: "none",
    fontSize: "16px",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  signageModalCardHistoryTitle: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    fontFamily: "AvenirNext  !important",
  },
  commentSectionBox: {
    borderLeft: "2px solid rgba(0, 0, 0, 0.1)",
    paddingTop: "20px",
    overflow: "hidden",
  },
  commentSectionTop: {
    color: "#8D8B8B",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    height: "35px",
    borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
    width: "100%",
  },
  messageSection: {
    padding: "0px 15px 15px 15px",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  writeCommentSection: {
    borderTop: "2px solid rgba(0, 0, 0, 0.1)",
    padding: "15px",
    "& .MuiOutlinedInput-root": {
      alignItems: "flex-start !important",
    },
  },
  commentTime: {
    fontSize: "11px !important",
    color: "black !important",
    fontWeight: "400  !important",
    marginTop: "10px !important",
    textAlign: "right !important",
  },
  messageSectionProfileName: {
    fontSize: "12.5px !important",
    color: "black !important",
    fontWeight: "800  !important",
  },
  commentContent: {
    padding: "10px",
    marginLeft: "33px",
    marginTop: "-8px",
    marginRight: "5px",
    width: "87%",
    color: "black !important",
    borderRadius: "6px !important",
  },
  commentContentText: {
    fontSize: "12.5px !important",
    wordBreak: "break-word  !important",
  },
  adornedEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  generateModelHeader: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  generateButton: {
    backgroundColor: "#0B0909 !important",
    color: "#fff !important",
    minWidth: "105px !important",
    width: "105px !important",
    height: "37px !important",
    fontFamily: "AvenirNext  !important",
  },
  downloadButton: {
    border: "1px solid #0B0909 !important",
    color: "rgba(0, 0, 0, 0.6) !important",
    minWidth: "40px !important",
  },
  closeButton: {
    color: "rgba(0, 0, 0, 0.54) !important",
    cursor: "pointer",
    padding: "5px !important",
    "&:hover": {
      borderRadius: "50% !important",
      backgroundColor: "#D9D9D9 !important",
    },
    "& svg": {
      width: "30px !important",
      height: "30px !important",
    },
  },
  tableRoot: {
    height: "65vh",
    width: "100%",
    backgroundColor: "white",
    "& .MuiTypography-root": {
      whiteSpace: "pre-wrap",
    },
  },
  commentFieldBox: {
    border: "1px  solid lightgrey",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    padding: "3px 4.5px",
  },
  commentFieldBoxLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "5px 10px",
    width: "92%",
  },
  commentFieldActions: {
    width: "8%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    marginRight: "2px",
    height: "30px",
  },
  manteeArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    margin: "10px 0px !important",
  },
  createUserHeader: {
    background: "#EFEFEF !important",
    padding: "12px 24px !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  userHeading: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
  },
  inputLabel: {
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14px !important",
    "& .MuiFormLabel-asterisk": {
      color: "#F00 !important", // Change the color to red
    },
  },
});
