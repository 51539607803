import React from "react";
import { Routes, Route } from "react-router-dom";
import LeadRoutingOverview from "./routingOverview";
import LeadGroups from "./groups";
import { Box } from "@mui/material";
import SubHeader from "./subHeader";
const LeadRoutingPage = (props) => {
  return (
    <Box>
      <SubHeader {...props} />
      <Routes>
        <Route path="/" element={<LeadRoutingOverview   {...props}/>} />
        <Route path="/groups" element={<LeadGroups  {...props} />} />
      </Routes>
    </Box>
  );
};

export default LeadRoutingPage;
