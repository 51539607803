import React, { useState } from "react";
import { Card, CardHeader, Button, Typography, IconButton, Skeleton, Box, Stack, TextField } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Linkify from "react-linkify";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { updatePublicData } from "../../../../redux/listings/publicData";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ResponseAlert from "../../../../components/responseAlert";

function TransactionsDetailRemarks(props) {
  const [open, setOpen] = useState(false);
  let { classes, publicDataDetail, isLoading } = props;
  const [detail, setDetail] = useState({
    PublicRemarks: "",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();

  const update = useSelector((state) => state.listings.publicData.update);

  const handleSuccess = (update) => {
    setErrorAlert({ errorMsg: `You have successfully updated the public remarks`, errorType: "success", isOpen: true });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({ errorMsg: JSON.stringify(error), errorType: "error", isOpen: true });
  };

  const handleClose = () => {
    setOpen(false);
    setDetail({
      PublicRemarks: "",
    });
  };
  const handleSave = () => {
    let obj = {
      schema: { ...detail },
      listing_public_data_id: publicDataDetail.id,
      handleSuccess,
      handleError,
    };
    dispatch(updatePublicData(obj));
  };
  const handleUpdateDetail = (field, value) => {
    setDetail({ ...detail, [field]: value });
  };
  const handleOpen = () => {
    setOpen(true);
    setDetail({
      PublicRemarks: publicDataDetail.PublicRemarks,
    });
  };

  return (
    <React.Fragment>
      <Card className={classes.overViewTabCardMain}>
        <CardHeader
          classes={{ title: classes.overViewTabCardHeading }}
          action={
            open ? (
              <Stack direction="row" spacing={0} alignItems={"center"}>
                <LoadingButton
                  sx={{ width: "50px", height: "30px", fontSize: "12px" }}
                  classes={{ root: classes.saveModalButton }}
                  color="inherit"
                  autoFocus
                  loadingPosition="start"
                  loading={update.isLoading}
                  size="small"
                  onClick={handleSave}
                >
                  Save
                </LoadingButton>
                <IconButton onClick={() => handleClose()} size="small">
                  <ClearRoundedIcon />
                </IconButton>
              </Stack>
            ) : !publicDataDetail.PublicRemarks ? (
              <Button
                variant="contained"
                size="small"
                onClick={() => setOpen(true)}
                sx={{
                  backgroundColor: "#0B0909",
                  "&:hover": { backgroundColor: "#0f0a0a" },
                  color: "white",
                  fontWeight: "400",
                  mr: "10px",
                  mt: "10px",
                }}
                startIcon={<AddRoundedIcon sx={{ mr: "-5px", mt: "-3px" }} />}
              >
                Add
              </Button>
            ) : (
              <IconButton onClick={handleOpen}>
                <EditIcon size="small" />
              </IconButton>
            )
          }
          sx={{ paddingLeft: "0px", paddingRight: "0px", mb: "12px" }}
          title="Public Remarks"
        />
        <Typography sx={{ fontSize: "13px", color: "rgba(0, 0, 0, 0.6)" }}>
          {isLoading ? (
            <Box>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : open ? (
            <TextField
              fullWidth
              multiline
              rows={10}
              value={detail.PublicRemarks}
              onChange={(e) => handleUpdateDetail("PublicRemarks", e.target.value)}
              placeholder="Enter public remarks"
              variant="outlined"
              size="small"
              sx={{ "& .MuiOutlinedInput-input": { fontSize: "13px" } }}
            />
          ) : (
            <Linkify
              properties={{ target: "_blank" }}
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {publicDataDetail.PublicRemarks}
            </Linkify>
          )}
        </Typography>
      </Card>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailRemarksStyle" })(TransactionsDetailRemarks);
