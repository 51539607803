import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleUpdateMakeoverDetail } from "../makeover";
export const getParticularListing = createAsyncThunk("ListingDetail/getParticularListing", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/transactions/listings/${data.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    thunkAPI.dispatch(handleUpdateMakeoverDetail(resp.data?.data.makeover));
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
