import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { TextField, InputLabel, Grid, Stack, Box } from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useParams } from "react-router-dom";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { AddAgentTab } from "../../../../redux/agents/agentRosterDetail/agentTabs";

function AddExpenseModal(props) {
  let { open, setOpen, classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [filesPreview, setFilesPreview] = useState([]);
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const { id } = useParams();
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const addTab = useSelector((state) => state.agentRoster.AgentTabs.addTab);

  const [expenseDetail, setExpenseDetail] = useState({
    user_id: id,
    users_tabs_type: "add_expense",
    description: "",
    category: "",
    amount: "",
    title: "",
  });

  const handleUpdateDetail = (field, value) => {
    setExpenseDetail({ ...expenseDetail, [field]: value });
    setErrMsg({});
  };

  useEffect(() => {
    if (addTab.success === "expense" && !addTab.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the expense",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setExpenseDetail({
        user_id: id,
        users_tabs_type: "add_expense",
        description: "",
        category: "",
        amount: "",
        title: "",
      });
      setFilesPreview([]);
      setFiles([]);
    } else if (!addTab.isLoading && addTab.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addTab.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addTab.isLoading]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!expenseDetail.category) {
      isFormValid = false;
      errorMsg["category"] = "Please select the category first";
      setErrorAlert({
        errorMsg: "Please select the category first",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!expenseDetail.amount) {
      isFormValid = false;
      errorMsg["amount"] = "Please enter the expense amount";
      setErrorAlert({
        errorMsg: "Please enter the expense amount",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!expenseDetail.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter the expense title";
      setErrorAlert({
        errorMsg: "Please enter the expense title",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleAddExpense = () => {
    if (handleValidate()) {
      const Data = new FormData();
      Data.append("users_tabs_type", expenseDetail.users_tabs_type);
      Data.append("user_id", expenseDetail.user_id);
      Data.append("description", expenseDetail.description);
      Data.append("category", expenseDetail.category);
      Data.append("amount", expenseDetail.amount);
      Data.append("title", expenseDetail.title);
      if (files.length) {
        files.map((item) => {
          Data.append("attachments[]", item.file);
        });
      }
      dispatch(AddAgentTab(Data));
    }
  };
  const handleFileChange = (e) => {
    let id = Math.random().toString(36).substring(7);
    setFiles([...files, { id: id, file: e.target.files[0] }]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setFilesPreview([
        ...filesPreview,
        {
          id: id,
          file: reader.result,
          url: URL.createObjectURL(e.target.files[0]),
        },
      ]);
    };
    let url = reader.readAsDataURL(e.target.files[0]);
  };
  const handleDelFile = (item) => {
    setFiles(files.filter((ite) => ite.id != item.id));
    setFilesPreview(filesPreview.filter((ite) => ite.id != item.id));
  };
  const handleClose = () => {
    setOpen(false);
    setExpenseDetail({
      user_id: id,
      users_tabs_type: "add_expense",
      description: "",
      category: "",
      amount: "",
      title: "",
    });
    setFilesPreview([]);
    setFiles([]);
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": { overflow: "visible !important" },
          "& .MuiDialog-container": { mt: "30px" },
        }}
      >
        <DialogTitle
          className={classes.paymentModalHeading}
          id="alert-dialog-title"
        >
          Add Expense
        </DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: "-15px" }}
          >
            <Grid item lg={4} sx={{ zIndex: "2" }}>
              <ButtonGroups
                id="category-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="38.5px"
                size="medium"
                color="white"
                options={[
                  { title: "MLS Fees", value: "mls_fee" },
                  { title: "Postage", value: "postage" },
                  { title: "Floor plan", value: "floor_plan" },
                  { title: "Postcards", value: "post_cards" },
                  { title: "Photography", value: "photography" },
                  { title: "Shipping Fee", value: "shipping_fee" },
                  { title: "Miscellaneous", value: "miscellaneous" },
                  { title: "ARA Dues", value: "ara_dues" },
                  { title: "SM Ads", value: "sm_ads" },
                  { title: "MB Applied", value: "mb_applied" },
                  { title: "Other", value: "other" },
                ]}
                onChangeMenu={(value) => handleUpdateDetail("category", value)}
                placeholder="Category"
                {...props}
              />
            </Grid>
            <Grid item lg={5} sx={{ zIndex: "1" }}>
              {/* <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Amount
              </InputLabel> */}
              <TextField
                value={expenseDetail.amount}
                onChange={(e) => handleUpdateDetail("amount", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"$472.41"}
                variant="outlined"
                size="small"
                type="number"
                error={errMsg.amount}
                helperText={errMsg.amount}
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <TextField
                value={expenseDetail.title}
                onChange={(e) => handleUpdateDetail("title", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter Expense Title Here"}
                variant="outlined"
                size="small"
                error={errMsg.title}
                helperText={errMsg.title}
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Description
              </InputLabel>
              <TextField
                value={expenseDetail.description}
                onChange={(e) =>
                  handleUpdateDetail("description", e.target.value)
                }
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={6}
                placeholder="user can enter unique notes about the particular expense"
                variant="outlined"
                size="small"
                error={errMsg.description}
                helperText={errMsg.description}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                startIcon={<FileUploadRoundedIcon />}
                color="inherit"
                sx={{
                  textTransform: "capitalize",
                  border: "1px solid rgba(33, 33, 33, 0.4)",
                }}
                component="label"
              >
                Attach Files
                <input
                  onChange={handleFileChange}
                  hidden
                  accept=".png,.jpg,.jpeg, .pdf"
                  multiple
                  type="file"
                />
              </Button>
            </Grid>
            <Grid item xs={8}>
              {filesPreview.length ? (
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"flex-end"}
                  flexWrap="wrap-reverse"
                >
                  {filesPreview.map((item, index) => (
                    <Box sx={{ position: "relative", mb: "5px" }}>
                      {item.file.slice(17, 20) === "pdf" ? (
                        <a
                          href={item.url}
                          rel="noreferrer"
                          target={"_blank"}
                          style={{ display: "block" }}
                        >
                          <iframe
                            style={{ pointerEvents: "none" }}
                            width="120"
                            height="120"
                            src={item.file}
                          >
                            {" "}
                          </iframe>
                        </a>
                      ) : (
                        <a href={item.url} rel="noreferrer" target={"_blank"}>
                          {" "}
                          <img
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                            src={item.file}
                            alt={`expense document ${index}`}
                          />
                        </a>
                      )}

                      <HighlightOffRoundedIcon
                        onClick={() => handleDelFile(item)}
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleAddExpense()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addTab.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddCommissionModalStyle" })(
  AddExpenseModal
);
