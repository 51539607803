import React, { useState, Fragment } from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import ChatHeaderSkelton from "./skelton/chatHeader.js";
import Avatar from "../../components/uiElements/avatar/index";
const moment = require("moment-timezone");
function SelectedLeadHeader(props) {
  let { classes, usersList, handleAssign, handleClose } = props;
  const [anchorAssignEl, setAnchorAssignEl] = useState(null);
  const assignOpen = Boolean(anchorAssignEl);
  const { data: requestDetail, isLoading } = useSelector(
    (state) => state.AgentRequests.requestDetail
  );
  return (
    <Fragment>
      {isLoading ? (
        <ChatHeaderSkelton />
      ) : (
        <Paper variant="outlined" className={classes.chatHeaderArea}>
          <Grid container spacing={1}>
            <Grid item sm={12} md={8}>
              <Typography className={classes.chatHeaderTitle}>
                Agent Request{" "}
              </Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Box
                className={classes.customBtnGroup}
                sx={{
                  justifyContent:
                    requestDetail.status !== "closed"
                      ? "space-between"
                      : "flex-end",
                }}
              >
                <Button
                  size="small"
                  className={classes.btnAssign}
                  aria-controls={assignOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={assignOpen ? "true" : undefined}
                  onClick={(event) => setAnchorAssignEl(event.currentTarget)}
                >
                  ASSIGN
                </Button>
                {requestDetail.status !== "closed" ? (
                  <Button
                    size="small"
                    onClick={() => handleClose()}
                    className={classes.btnClose}
                  >
                    CLOSE
                  </Button>
                ) : null}
              </Box>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography className={classes.chatHeaderDetailItem}>
                {" "}
                <Typography className={classes.chatHeaderDetailItemBold}>
                  Created:{" "}
                </Typography>{" "}
                {moment(requestDetail.createdAt)
                  .tz("America/New_York")
                  .format("l")}{" "}
                (
                {moment(requestDetail.createdAt)
                  .tz("America/New_York")
                  .fromNow()}
                ){" "}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography className={classes.chatHeaderDetailItem}>
                {" "}
                <Typography className={classes.chatHeaderDetailItemBold}>
                  Last Comment:{" "}
                </Typography>{" "}
                {moment(requestDetail.lastUpdatedAt)
                  .tz("America/New_York")
                  .format("l")}{" "}
                (
                {moment(requestDetail.lastUpdatedAt)
                  .tz("America/New_York")
                  .fromNow()}
                ){" "}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography className={classes.chatHeaderDetailItem}>
                {" "}
                <Typography className={classes.chatHeaderDetailItemBold}>
                  Status:{" "}
                </Typography>{" "}
                {requestDetail.status}{" "}
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography className={classes.chatHeaderDetailItem}>
                {" "}
                <Typography className={classes.chatHeaderDetailItemBold}>
                  Summary:{" "}
                </Typography>{" "}
                {requestDetail.summary}
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography className={classes.chatHeaderDetailItemBold}>
                Description:{" "}
              </Typography>
              <Typography className={classes.chatHeaderDetailItemDescription}>
                {" "}
                {requestDetail.description}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Menu
        className={classes.listMenuWrapper}
        anchorEl={anchorAssignEl}
        id="account-menu"
        open={assignOpen}
        onClose={() => setAnchorAssignEl(null)}
        // hidden={incomingCall || outgoingCall}
        onClick={() => setAnchorAssignEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: "200px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <span>Team Leaders</span>
        {usersList
          .filter(
            (item) =>
              item.role?.name === "Team Leader" &&
              item.id !== requestDetail?.assignedTo
          )
          ?.map((i) => (
            <MenuItem onClick={() => handleAssign(i)} key={i.id} value={i.id}>
              <Avatar
                sx={{ fontSize: "13px" }}
                src={i.profile_images?.profile_img}
                sizes="small"
              >{`${i.first_name?.slice(0, 1)}${i.last_name?.slice(
                0,
                1
              )}`}</Avatar>
              {i.full_name}
            </MenuItem>
          ))}
        <span>Staff Members</span>
        {usersList
          .filter(
            (item) =>
              item.role?.name === "Admin" &&
              item.id !== requestDetail?.assignedTo
          )
          ?.map((i) => (
            <MenuItem onClick={() => handleAssign(i)} key={i.id} value={i.id}>
              <Avatar
                sx={{ fontSize: "13px" }}
                src={i.profile_images?.profile_img}
                sizes="small"
              >{`${i.first_name?.slice(0, 1)}${i.last_name?.slice(
                0,
                1
              )}`}</Avatar>
              {i.full_name}
            </MenuItem>
          ))}
      </Menu>
    </Fragment>
  );
}
export default withStyles(styleSheet, { name: "SelectedLeadHeaderStyle" })(
  SelectedLeadHeader
);
