import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const sendEmailAgentRecruitment = createAsyncThunk("login/sendEmailAgentRecruitment", async (data, thunkAPI) => {
  try {
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      if (key === "attachments" && Array.isArray(value)) {
        for (let val of value) {
          formData.append("attachments[]", val);
        }
      } else {
        formData.append(key, value);
      }
    }
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/recruitment/sendEmail`, formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return resp.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
