export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "10px",
    marginBottom: "16px",
  },

  pageHeading:{
    fontSize:'30px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
  },
  pageHeadingSocial:{
    fontSize:'30px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
    width:'30ch !important'
  },
  detailInput:{
    borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
  },
  agentProfileDetail:{
    fontSize:'13px !important',
    fontWeight:'400 !important',
    textTransform:'capitalize !important',
    minWidth:"10ch"
  },
  agentProfileDetailHeader:{
    fontSize:'13px !important',
    fontWeight:'500 !important',
    textTransform:'capitalize !important',
    minWidth:"10ch"
  },
  saveButTon:{
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft:'30px !important',
    paddingRight:'30px !important'
  },
  UploadCustomerButton:{
    minHeight:'180px',
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight:'500',
    cursor:'pointer'
  }
});
