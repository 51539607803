import { createSlice } from "@reduxjs/toolkit";
import { addTaskTemplate } from "./addTemplate";
import { getTaskTemplates } from "./getTemplates";
import { updateTaskTemplate } from "./updateTemplate";
import { deleteTaskTemplate } from "./deleteTemplate";

const initialState = {
  templateData: {
    success: false,
    data: [],
    metadata: {},
    isLoading: false,
    errMsg: null,
  },
  addTemplate: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateTemplate: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  deleteTemplate: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};

const TasksTemplates = createSlice({
  name: "TasksTemplates",
  initialState,
  extraReducers: {
    [addTaskTemplate.pending]: (state) => {
      state.addTemplate.isLoading = true;
      state.addTemplate.errMsg = null;
      state.addTemplate.success = false;
    },
    [addTaskTemplate.fulfilled]: (state, action) => {
      state.addTemplate.success = true;
      state.addTemplate.isLoading = false;
      state.addTemplate.data = action.payload;
    },
    [addTaskTemplate.rejected]: (state, action) => {
      state.addTemplate.isLoading = false;
      state.addTemplate.success = false;
      state.addTemplate.errMsg = action.payload;
    },

    [getTaskTemplates.pending]: (state) => {
      state.templateData.isLoading = true;
      state.templateData.errMsg = null;
      state.templateData.success = false;
    },
    [getTaskTemplates.fulfilled]: (state, action) => {
      state.templateData.success = true;
      state.templateData.isLoading = false;
      state.templateData.data = action.payload.templates;
      state.templateData.metadata = action.payload._metadata;
    },
    [getTaskTemplates.rejected]: (state, action) => {
      state.templateData.isLoading = false;
      state.templateData.success = false;
      state.templateData.errMsg = action.payload;
    },
    [updateTaskTemplate.pending]: (state) => {
      state.updateTemplate.isLoading = true;
      state.updateTemplate.errMsg = null;
      state.updateTemplate.success = false;
    },
    [updateTaskTemplate.fulfilled]: (state, action) => {
      state.updateTemplate.success = true;
      state.updateTemplate.isLoading = false;
      state.updateTemplate.data = action.payload;
    },
    [updateTaskTemplate.rejected]: (state, action) => {
      state.updateTemplate.isLoading = false;
      state.updateTemplate.success = false;
      state.updateTemplate.errMsg = action.payload;
    },
    [deleteTaskTemplate.pending]: (state) => {
      state.deleteTemplate.isLoading = true;
      state.deleteTemplate.errMsg = null;
      state.deleteTemplate.success = false;
    },
    [deleteTaskTemplate.fulfilled]: (state, action) => {
      state.deleteTemplate.success = true;
      state.deleteTemplate.isLoading = false;
      state.deleteTemplate.data = action.payload;
      state.templateData.data = state.templateData.data?.filter(
        (item) => item.id !== action.payload.templateId
      );
    },
    [deleteTaskTemplate.rejected]: (state, action) => {
      state.deleteTemplate.isLoading = false;
      state.deleteTemplate.success = false;
      state.deleteTemplate.errMsg = action.payload;
    },
  },
});
export default TasksTemplates.reducer;
export * from "./getTemplates";
export * from "./addTemplate";
export * from "./deleteTemplate";
export * from "./updateTemplate";
