import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import { AddAgentTab } from "../../../../redux/agents/agentRosterDetail/agentTabs";
import { useDispatch, useSelector } from "react-redux";

function ApplyAwardModal(props) {
  let { open, setOpen, classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const addTab = useSelector((state) => state.agentRoster.AgentTabs.addTab);

  const [expenseDetail, setExpenseDetail] = useState({
    user_id: id,
    users_tabs_type: "apply_budget",
    description: "",
    amount: "",
  });

  const handleUpdateDetail = (field, value) => {
    setExpenseDetail({ ...expenseDetail, [field]: value });
    setErrMsg({});
  };

  useEffect(() => {
    if (addTab.success === "applyAward" && !addTab.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully apply the award budget",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setExpenseDetail({
        user_id: id,
        users_tabs_type: "apply_budget",
        description: "",
        amount: "",
      });
    } else if (!addTab.isLoading && addTab.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addTab.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addTab.isLoading]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!expenseDetail.amount) {
      isFormValid = false;
      errorMsg["amount"] = "Please enter the award amount";
      setErrorAlert({
        errorMsg: "Please enter the expense amount",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!expenseDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter the apply award description";
      setErrorAlert({
        errorMsg: "Please enter the expense description",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleApplyBudget = () => {
    if (handleValidate()) {
      let obj = {
        ...expenseDetail,
        title: `Marketing Budget Applied`,
      };
      dispatch(AddAgentTab(obj));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible" } }}
      >
        <DialogTitle className={classes.paymentModalHeading} id="alert-dialog-title">
          Apply Marketing Budget
        </DialogTitle>
        <DialogContent sx={{ width: "600px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={6} sx={{ marginTop: "-35px" }}>
            <Grid item lg={5} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Amount
              </InputLabel>
              <TextField
                value={expenseDetail.amount}
                onChange={(e) => handleUpdateDetail("amount", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"$472.41"}
                variant="outlined"
                size="small"
                type="number"
                error={errMsg.amount}
                helperText={errMsg.amount}
              />
            </Grid>

            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Description
              </InputLabel>
              <TextField
                value={expenseDetail.description}
                onChange={(e) => handleUpdateDetail("description", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={6}
                placeholder="user can enter unqiue notes about the particular expense"
                variant="outlined"
                size="small"
                error={errMsg.description}
                helperText={errMsg.description}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleApplyBudget()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addTab.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "ApplyAwardModalStyle" })(ApplyAwardModal);
