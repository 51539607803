import { withStyles } from "@mui/styles";
import React, { useEffect, useState, useRef } from "react";
import { styleSheet } from "./style";
import {
  CallTimeline,
  EmailTimeline,
  MessageTimeline,
  NoteTimeline,
  AssignmentTimeline,
  ActivityTimeline,
} from "./timelineItems";
import { Timeline } from "@mui/lab";
import { theme } from "../../../../config/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Checkbox,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import Button from "../../../../components/uiElements/button";
import { useParams } from "react-router-dom";
import { getLeadTimelineAPI } from "../../../../redux/leads/getLeadTimelineAPI";
import { getTimelineCount } from "../../../../redux/leads/getTimelineCount";

import { updateTimeLineFavorite } from "../../../../redux/leads/updateTimelineFavorite";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
const LeadTimeline = ({ usersList, classes, leadProfileDetail }) => {
  const dispatch = useDispatch();
  const routeId = useParams();
  const [num, setNum] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([
    "all",
    "email",
    "text",
    "call",
    "note",
  ]);
  const filterRef = useRef(selectedFilters);
  const timelineRef = useRef(0);
  const { leadTimeline } = useSelector((item) => item);
  const myTimer = useRef(null);
  // const containerRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    filterRef.current = selectedFilters;
  }, [selectedFilters]);

  useEffect(() => {
    timelineRef.current = leadTimeline?.data?.length;
  }, [leadTimeline?.data?.length]);

  useEffect(() => {
    const startTimer = () => {
      myTimer.current = setInterval(() => {
        getLeadsTimeLine(1, true);
      }, 1000 * 5);
    };
    startTimer();
    return () => myTimer.current != null && clearInterval(myTimer.current);
  }, []);

  useEffect(() => {
    getLeadsTimeLine(num, null);
  }, [num, selectedFilters]);

  const handleReturnFilterTypes = () => {
    let filters = filterRef.current;
    if (filters?.includes("all")) {
      return null;
    } else {
      let newFilters = [];
      filters?.map((type) => {
        if (type === "email") {
          // newFilters.push("email");
          newFilters.push("email_thread");
        } else if (type === "text") {
          newFilters.push("text_message");
        } else {
          newFilters.push(type);
        }
      });
      return newFilters.join(",");
    }
  };

  useEffect(() => {
    if (routeId?.id) {
      dispatch(getTimelineCount({ leadId: routeId?.id }));
    }
  }, [routeId?.id]);

  const getLeadsTimeLine = (pageNumber, initial) => {
    if (!leadTimeline.status.pending) {
      let obj = {
        pageNumber,
        leadId: routeId?.id,
        initial: initial,
        type: handleReturnFilterTypes(),
      };
      dispatch(getLeadTimelineAPI(obj));
    }
  };

  const loadMore = () => {
    setNum((num) => num + 1);
  };

  const handleUpdateFavorite = (timeline_id, flag) => {
    dispatch(
      updateTimeLineFavorite({
        is_starred: flag,
        timeline_id: timeline_id,
      })
    );
  };

  const handleAddNewFilter = (filter) => {
    if (filter === "all") {
      setSelectedFilters(["all", "email", "text", "call", "note"]);
    } else {
      setSelectedFilters([filter]);
    }
  };

  const handleReturnFiltered = (data) => {
    let newFilteredData = [];
    if (selectedFilters.includes("all")) {
      return data;
    } else {
      if (selectedFilters.includes("starred")) {
        data?.map((item) => {
          if (item?.is_starred) {
            newFilteredData.push(item);
          }
        });
        return newFilteredData;
      } else {
        data?.map((item) => {
          if (selectedFilters.includes(item.type?.split("_")?.[0])) {
            newFilteredData.push(item);
          }
        });
        return newFilteredData;
      }
    }
  };

  const handleReturnCount = (type) => {
    let count = leadTimeline?.counts?.length
      ? leadTimeline?.counts?.find(
          (item) => item.type?.split("_")?.[0] === type?.split("_")?.[0]
        )?.count || 0
      : 0;
    return count;
  };

  const handleUpdateFilter = (type) => {
    let newFilters = [...selectedFilters];
    if (type === "all") {
      if (newFilters.includes(type)) {
        setSelectedFilters([]);
      } else {
        setSelectedFilters(["all", "email", "text", "call", "note"]);
      }
    } else if (newFilters.includes(type)) {
      setSelectedFilters(
        newFilters.filter((item) => item !== type && item !== "all")
      );
    } else {
      newFilters.push(type);
      setSelectedFilters(newFilters);
    }
  };

  const handleReturnPaginationCount = () => {
    let filters = filterRef.current;
    if (filters?.includes("all")) {
      return leadTimeline.stats?.total;
    } else {
      let newCount = 0;
      leadTimeline.stats?.typeCount?.map((item) => {
        if (filters?.includes(item.type?.split("_")?.[0])) {
          newCount = newCount + item.count;
        }
      });
      return newCount;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // console.log(window.innerHeight + document.documentElement.scrollTop, "window.innerHeight + document.documentElement.scrollTop)");
      // console.log(document.getElementById("main-section")?.offsetHeight+ 63, "document.getElementById?.offsetHeight");
      if (
        Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
        document.getElementById("main-section")?.offsetHeight + 63
      ) {
        if (
          leadTimeline?.data?.length < handleReturnPaginationCount() &&
          timelineRef.current < handleReturnPaginationCount() &&
          !leadTimeline.status.pending &&
          !leadTimeline.isLimitReached
        ) {
          loadMore();
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <Box className={classes.timelineFilterArea}>
        <Box className={classes.timelineFilterAreaLeft}>
          <Box
            className={
              selectedFilters?.includes("all")
                ? classes.timelineFilterAreaItemActive
                : classes.timelineFilterAreaItem
            }
            onClick={() => handleAddNewFilter("all")}
          >
            <FormatListBulletedRoundedIcon sx={{ fontSize: "18px" }} />
            <Typography>All</Typography>
          </Box>
          <Box
            className={
              selectedFilters?.includes("email") &&
              !selectedFilters?.includes("all")
                ? classes.timelineFilterAreaItemActive
                : classes.timelineFilterAreaItem
            }
            onClick={() => handleAddNewFilter("email")}
          >
            <EmailRoundedIcon
              sx={{
                fontSize: "18px",
                color:
                  selectedFilters?.includes("email") &&
                  !selectedFilters?.includes("all")
                    ? "rgba(114, 173, 242, 1)"
                    : "",
              }}
            />
            <Typography>{handleReturnCount("email")}</Typography>
          </Box>
          <Box
            className={
              selectedFilters?.includes("text") &&
              !selectedFilters?.includes("all")
                ? classes.timelineFilterAreaItemActive
                : classes.timelineFilterAreaItem
            }
            onClick={() => handleAddNewFilter("text")}
          >
            <MessageRoundedIcon
              sx={{
                fontSize: "18px",
                color:
                  selectedFilters?.includes("text") &&
                  !selectedFilters?.includes("all")
                    ? "rgba(181, 117, 220, 1)"
                    : "",
              }}
            />
            <Typography>{handleReturnCount("text_message")}</Typography>
          </Box>
          <Box
            className={
              selectedFilters?.includes("call") &&
              !selectedFilters?.includes("all")
                ? classes.timelineFilterAreaItemActive
                : classes.timelineFilterAreaItem
            }
            onClick={() => handleAddNewFilter("call")}
          >
            <PhoneRoundedIcon
              sx={{
                fontSize: "18px",
                color:
                  selectedFilters?.includes("call") &&
                  !selectedFilters?.includes("all")
                    ? "rgba(66, 175, 14, 1)"
                    : "",
              }}
            />
            <Typography>{handleReturnCount("call")}</Typography>
          </Box>
          <Box
            className={
              selectedFilters?.includes("note") &&
              !selectedFilters?.includes("all")
                ? classes.timelineFilterAreaItemActive
                : classes.timelineFilterAreaItem
            }
            onClick={() => handleAddNewFilter("note")}
          >
            <PushPinRoundedIcon
              sx={{
                fontSize: "20px",
                color:
                  selectedFilters?.includes("note") &&
                  !selectedFilters?.includes("all")
                    ? "rgba(253, 227, 134, 1)"
                    : "",
              }}
            />
            <Typography>{handleReturnCount("note")}</Typography>
          </Box>
          <Box
            className={
              selectedFilters?.includes("starred") &&
              !selectedFilters?.includes("all")
                ? classes.timelineFilterAreaItemActive
                : classes.timelineFilterAreaItem
            }
            onClick={() => handleAddNewFilter("starred")}
          >
            <StarRateRoundedIcon
              sx={{
                fontSize: "24px",
                color:
                  selectedFilters?.includes("starred") &&
                  !selectedFilters?.includes("all")
                    ? "rgba(243, 179, 14, 1)"
                    : "",
              }}
            />
            <Typography>{handleReturnCount("starred")}</Typography>
          </Box>
        </Box>
        <Box className={classes.timelineFilterAreaRight}>
          <Button
            onClick={handleClick}
            variant="outlined"
            size="small"
            color="inherit"
          >
            Filters
          </Button>
        </Box>
      </Box>
      <Box
        // onScroll={handleScroll}
        // ref={containerRef}
        className={classes.timeLineArea}
      >
        <Timeline position="right" sx={{ paddingLeft: "25px" }}>
          {leadTimeline?.data && leadTimeline?.data.length ? (
            handleReturnFiltered(leadTimeline?.data).map((item, index) => {
              switch (item.type) {
                case "email":
                  return (
                    <EmailTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.sky}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                      disabledConnecter={
                        leadTimeline?.data.length - 1 === index &&
                        !leadTimeline.status.pending
                          ? true
                          : false
                      }
                      leadProfileDetail={leadProfileDetail}
                    />
                  );
                case "email_thread":
                  return (
                    <EmailTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.sky}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                      isEmailThread
                      disabledConnecter={
                        leadTimeline?.data.length - 1 === index &&
                        !leadTimeline.status.pending
                          ? true
                          : false
                      }
                      leadProfileDetail={leadProfileDetail}
                    />
                  );
                case "text_message":
                  return (
                    <MessageTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.purple}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                      disabledConnecter={
                        leadTimeline?.data.length - 1 === index &&
                        !leadTimeline.status.pending
                          ? true
                          : false
                      }
                      leadProfileDetail={leadProfileDetail}
                    />
                  );
                case "note":
                  return (
                    <NoteTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.yellow}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                      disabledConnecter={
                        leadTimeline?.data.length - 1 === index &&
                        !leadTimeline.status.pending
                          ? true
                          : false
                      }
                    />
                  );
                case "call":
                  return (
                    <CallTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.green}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                      disabledConnecter={
                        leadTimeline?.data.length - 1 === index &&
                        !leadTimeline.status.pending
                          ? true
                          : false
                      }
                    />
                  );
                case "assignment":
                  return (
                    <AssignmentTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      color={"rgb(109, 130, 145)"}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                      disabledConnecter={
                        leadTimeline?.data.length - 1 === index &&
                        !leadTimeline.status.pending
                          ? true
                          : false
                      }
                    />
                  );
                case "activity":
                  return (
                    <ActivityTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      color={"rgb(255, 173, 129)"}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                      disabledConnecter={
                        leadTimeline?.data.length - 1 === index &&
                        !leadTimeline.status.pending
                          ? true
                          : false
                      }
                    />
                  );
                default:
                  return null;
              }
            })
          ) : !leadTimeline.status.pending ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{ color: "#bdbdbd" }}
              >
                No timeline history
              </Typography>
            </Box>
          ) : null}
          {leadTimeline.status.pending
            ? Array.from(Array(5).keys()).map((item, index) => (
                <TimelineItem key={index} classes={{ root: classes.root }}>
                  <TimelineSeparator
                    sx={{
                      "& .MuiTimelineConnector-root": {
                        backgroundColor: "#D2D2D2",
                      },
                    }}
                  >
                    <TimelineDot
                      sx={{
                        padding: "10px",
                        height: "43px",
                        width: "43px",
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{ py: "12px", px: 4, mt: "-5px", height: "130px" }}
                  >
                    <Box className={classes.timeSkeltonItem}>
                      <Box className={classes.timeSkeltonItemAvatar}></Box>
                      <Box className={classes.timeSkeltonItemContent}>
                        <Skeleton
                          variant="text"
                          width={"70%"}
                          height={30}
                          animation="wave"
                        />
                        <Skeleton
                          variant="text"
                          width={"80%"}
                          height={20}
                          animation="wave"
                        />
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ))
            : null}
        </Timeline>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: "250px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleUpdateFilter("all")}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <Checkbox
              checked={selectedFilters.includes("all") ? true : false}
              edge="start"
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <FormatListBulletedRoundedIcon
              sx={{ fontSize: "22px", color: "rgba(0, 0, 0, 0.54)" }}
            />
          </ListItemIcon>
          All
        </MenuItem>
        <MenuItem onClick={() => handleUpdateFilter("email")}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <Checkbox
              checked={selectedFilters.includes("email") ? true : false}
              edge="start"
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <EmailRoundedIcon
              sx={{ fontSize: "22px", color: "rgba(114, 173, 242, 1)" }}
            />
          </ListItemIcon>
          Emails
        </MenuItem>
        <MenuItem onClick={() => handleUpdateFilter("text")}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <Checkbox
              checked={selectedFilters.includes("text") ? true : false}
              edge="start"
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <MessageRoundedIcon
              sx={{ fontSize: "22px", color: "rgba(181, 117, 220, 1)" }}
            />
          </ListItemIcon>
          Text
        </MenuItem>
        <MenuItem onClick={() => handleUpdateFilter("call")}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <Checkbox
              checked={selectedFilters.includes("call") ? true : false}
              edge="start"
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <PhoneRoundedIcon
              sx={{ fontSize: "22px", color: "rgba(66, 175, 14, 1)" }}
            />
          </ListItemIcon>
          Calls
        </MenuItem>
        <MenuItem onClick={() => handleUpdateFilter("note")}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <Checkbox
              checked={selectedFilters.includes("note") ? true : false}
              edge="start"
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <PushPinRoundedIcon
              sx={{ fontSize: "22px", color: "rgba(253, 227, 134, 1)" }}
            />
          </ListItemIcon>
          Notes
        </MenuItem>
        {/* <MenuItem onClick={() => handleUpdateFilter("starred")}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <Checkbox
              checked={selectedFilters.includes("starred") ? true : false}
              edge="start"
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <StarRateRoundedIcon
              sx={{ fontSize: "22px", color: "rgba(243, 179, 14, 1)" }}
            />
          </ListItemIcon>
          Starred
        </MenuItem> */}
      </Menu>
    </>
  );
};
export default withStyles(styleSheet, { name: "leadTimelineStyle" })(
  LeadTimeline
);
