import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import {
  addReview,
  getReviews,
  updateReview,
  deleteReview,
} from "../../../../../redux/agents/agentRoster/agentReviews";
import DeleteIcon from "@mui/icons-material/Delete";
import { styleSheet } from "./style";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import _ from "lodash";

const AgentReviews = ({ classes }) => {
  /**
   * Variables and States
   */
  const dispatch = useDispatch();
  const agentRosterProfile = useSelector(
    (item) => item.agentRoster.agentRosterProfile
  );
  const { profile } = agentRosterProfile;

  const [isEditAble, setIsEditAble] = React.useState(false);
  const [reviewsForm, setReviewsForm] = React.useState([]); // Reviews state

  const getAllReviews = async () => {
    const resp = await dispatch(getReviews({ id: profile?.id }));
    if (resp.payload.reviews && resp.payload.reviews.length === 0) {
      // always generate 3 review fields
      setReviewsForm([
        { id: null, first_name: "", last_name: "", review: "" },
        { id: null, first_name: "", last_name: "", review: "" },
        { id: null, first_name: "", last_name: "", review: "" },
      ]);
    } else if (resp.payload.reviews && resp.payload.reviews.length === 1) {
      // always generate 3 review fields
      setReviewsForm([
        ...resp.payload.reviews,
        { id: null, first_name: "", last_name: "", review: "" },
        { id: null, first_name: "", last_name: "", review: "" },
      ]);
    } else if (resp.payload.reviews && resp.payload.reviews.length === 2) {
      // always generate 3 review fields
      setReviewsForm([
        ...resp.payload.reviews,
        { id: null, first_name: "", last_name: "", review: "" },
      ]);
    } else {
      setReviewsForm([...resp.payload.reviews?.slice(0, 3)]);
    }
  };
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  useEffect(() => {
    // fetching reviews
    getAllReviews();
  }, [profile]);
  /**
   * End Region: useEffect Hooks
   */

  const handleDeleteReview = (review, index) => {
    if (review.id) {
      dispatch(deleteReview({ id: review.id }));
      setReviewsForm((preReviews) => {
        return [
          ...preReviews.slice(0, index),
          { id: null, first_name: "", last_name: "", review: "" },
          ...preReviews.slice(index + 1),
        ];
      });
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{ backgroundColor: "white" }}
      className={classes.pageRoot}
    >
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title={
          <>
            Agent Reviews
            <Alert
              sx={{
                marginTop: "3px",
                border: "none",
                paddingLeft: "0",
                textTransform: "none",
              }}
              variant="outlined"
              severity="info"
            >
              Label, First Name and Last Name are required
            </Alert>
          </>
        }
        action={
          <div>
            <IconButton
              onClick={() => setIsEditAble(!isEditAble)}
              aria-label="locked"
            >
              {isEditAble ? (
                <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              ) : (
                <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              )}
            </IconButton>
          </div>
        }
      />
      <CardContent sx={{ pointerEvents: isEditAble ? "" : "none" }}>
        <Grid container spacing={6}>
          {reviewsForm.map((review, idx) => {
            return (
              <Grid item lg={12} xs={12}>
                {review?.id ? (
                  <Box className={classes.deleteSection}>
                    <CustomTooltip title="Delete Review" placement="top">
                      <IconButton
                        onClick={() => handleDeleteReview(review, idx)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CustomTooltip>
                  </Box>
                ) : null}
                <Box>
                  <TextField
                    fullWidth
                    placeholder="Label"
                    label="Label"
                    variant="outlined"
                    className={classes.ReviewField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    rows={12}
                    multiline
                    value={review.review}
                    inputProps={{ readOnly: isEditAble ? false : true }}
                    onChange={(e) => {
                      setReviewsForm(() => {
                        let r = _.cloneDeep(reviewsForm);
                        r[idx].review = e.target.value;
                        return r;
                      });
                    }}
                    onBlur={(e) => {
                      if (review.id) {
                        if (
                          review.review.length !== 0 &&
                          review.first_name.length !== 0 &&
                          review.last_name.length !== 0
                        )
                          dispatch(updateReview({ ...review }));
                      } else {
                        if (
                          review.review.length !== 0 &&
                          review.first_name.length !== 0 &&
                          review.last_name.length !== 0
                        )
                          dispatch(addReview({ ...review, id: profile.id }));
                      }
                    }}
                  />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={"10px"}
                  mt={"20px"}
                >
                  <TextField
                    inputProps={{ readOnly: isEditAble ? false : true }}
                    value={review.first_name || ""}
                    variant="standard"
                    label="First Name"
                    name="first_name"
                    onChange={(e) => {
                      setReviewsForm(() => {
                        let r = _.cloneDeep(reviewsForm);
                        r[idx].first_name = e.target.value;
                        return r;
                      });
                    }}
                    onBlur={(e) => {
                      if (review.id) {
                        if (
                          review.review.length !== 0 &&
                          review.first_name.length !== 0 &&
                          review.last_name.length !== 0
                        )
                          dispatch(updateReview({ ...review }));
                      } else {
                        if (
                          review.review.length !== 0 &&
                          review.first_name.length !== 0 &&
                          review.last_name.length !== 0
                        )
                          dispatch(addReview({ ...review, id: profile.id }));
                      }
                    }}
                  />
                  <TextField
                    inputProps={{ readOnly: isEditAble ? false : true }}
                    value={review.last_name || ""}
                    variant="standard"
                    label="Last Name"
                    name="last_name"
                    onChange={(e) => {
                      setReviewsForm(() => {
                        let r = _.cloneDeep(reviewsForm);
                        r[idx].last_name = e.target.value;
                        return r;
                      });
                    }}
                    onBlur={(e) => {
                      if (review.id) {
                        if (
                          review.review.length !== 0 &&
                          review.first_name.length !== 0 &&
                          review.last_name.length !== 0
                        )
                          dispatch(updateReview({ ...review }));
                      } else {
                        if (
                          review.review.length !== 0 &&
                          review.first_name.length !== 0 &&
                          review.last_name.length !== 0
                        )
                          dispatch(addReview({ ...review, id: profile.id }));
                      }
                    }}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styleSheet, { name: "agentRosterDetailsReviews" })(
  AgentReviews
);
