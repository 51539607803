import React from "react";
import { Box, Tabs, Tab, Card } from "@mui/material";
import { TabContext } from "@mui/lab";
import { Link, useLocation } from "react-router-dom";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";

function SubHeader(props) {
  let { classes } = props;
  const location = useLocation();
  const getPathName = () => {
    if (location.pathname?.includes("digital")) {
      return `${location.pathname?.split("digital")[0]}digital`;
    } else {
      return location.pathname;
    }
  };
  return (
    <Card
      variant="outlined"
      sx={{ position: "sticky", top: "64px", zIndex: "100" }}
    >
      {" "}
      <Box className={classes.MarketingBarArea}>
        <Box className={classes.MarketingBarAreaLeft}>
          {" "}
          <Box>
            {" "}
            <TabContext>
              <Box className={classes.TemplatesTabsUI}>
                <Tabs
                  value={getPathName()}
                  TabIndicatorProps={{
                    sx: { background: "black", color: "black" },
                  }}
                  aria-label="Templates-tabs"
                  sx={{ justifyContent: "space-around" }}
                >
                  <Tab
                    label="Digital"
                    value={`/marketing_center/digital`}
                    to={`/marketing_center/digital`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                  />
                  <Tab
                    label="Print"
                    value={`/marketing_center/print`}
                    to={`/marketing_center/print`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                    selected
                  />
                </Tabs>
              </Box>
            </TabContext>{" "}
          </Box>{" "}
        </Box>
      </Box>
    </Card>
  );
}
export default withStyles(styleSheet, { name: "SubHeaderListingStyles" })(
  SubHeader
);
