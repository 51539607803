import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { handleUpdateModalState } from "../../redux/widgets";
import { useDispatch, useSelector } from "react-redux";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
const options = [
  { title: "New Text", value: "sendTextOpen", icon: <SmsRoundedIcon /> },
  { title: "New Email", value: "sendEmailOpen", icon: <EmailRoundedIcon /> },
  {
    title: "New Project",
    value: "createProjectOpen",
    icon: <TaskAltRoundedIcon />,
  },
  {
    title: "Calendar Appt",
    value: "openAppointment",
    icon: <CalendarTodayRoundedIcon fontSize="small" />,
  },
  //   { title: "Reminder", value: "Reminder", icon: <AlarmRoundedIcon /> },
  {
    title: "Agent Request",
    value: "openAgentRequest",
    icon: <NotificationImportantIcon />,
  },
  { title: "Add Lead", value: "openAddLead", icon: <PersonRoundedIcon /> },
];

function WidgetsMenu(props) {
  let { setOpenWidgets, setErrorAlert } = props;
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const widgets = useSelector((state) => state.widgets);
  const dispatch = useDispatch();
  const handleOpenModal = (action) => {
    dispatch(handleUpdateModalState(action));
  };
  const handleAlert = () => {
    setErrorAlert({
      errorMsg:
        "You can't open this widget because browser width is not enough for this!",
      errorType: "warning",
      isOpen: true,
    });
  };
  return (
    <React.Fragment>
      <List disablePadding sx={{ minWidth: "200px" }}>
        {options.map((item, index) => (
          <ListItem
            onClick={() => {
              if (!widgets[item.value]) {
                if (rightPositions.length) {
                  if (
                    rightPositions[rightPositions.length - 1].right + 1450 >
                    window.innerWidth
                  ) {
                    handleAlert();
                  } else {
                    handleOpenModal({ type: item.value, action: true });
                  }
                } else {
                  handleOpenModal({ type: item.value, action: true });
                }
              } else {
                setErrorAlert({
                  errorMsg: "You can't open this widget because already opened",
                  errorType: "warning",
                  isOpen: true,
                });
              }
              setOpenWidgets(false);
            }}
            disablePadding
            sx={{ paddingTop: "2px", paddingBottom: "2px" }}
          >
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon sx={{ minWidth: "40px" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
}
export default WidgetsMenu;
