import React from "react";
import { Box, Skeleton, Grid } from "@mui/material";
import CommissionPlansTable from "../../users/skeltons/commissionPlansTable"
function VendorDetailSkelton() {
  return (
    <Box>
      <Skeleton variant="rounded" width="350px" height="40px" sx={{ mt: "-50px" }} />
      <Grid sx={{ mt: "10px" }} container spacing={4}>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "0px" }} container spacing={4}>
        <Grid item sm={12} md={4}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={1}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "0px" }} container spacing={4}>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "0px" }} container spacing={4}>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "10px" }} container spacing={4}>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "0px" }} container spacing={4}>
        <Grid item sm={12} md={4}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={1}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "0px" }} container spacing={4}>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <Grid sx={{ mt: "0px" }} container spacing={4}>
        <Grid item sm={12} md={3}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
        <Grid item sm={12} md={2}>
          <Skeleton variant="rounded" width="100%" height="30px" />
        </Grid>
      </Grid>
      <br />
      <CommissionPlansTable />
    </Box>
  );
}
export default VendorDetailSkelton;
