import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "../../../container/users/userDetail/modals/style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../responseAlert";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  TextField,
  InputLabel,
  Grid,
  FormControlLabel,
  Box,
  Checkbox,
  Autocomplete,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  ListItem,
  ListItemText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { addAppointment } from "../../../redux/widgets";
import { stringAvatar } from "../../../utils/avatarInitial";
import Input from "../../../components/uiElements/input/Input";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { handleAddMinimizeTab } from "../../../redux/widgets/index";
import { handleUpdateModalState } from "../../../redux/widgets";
import WarningAmberSharpIcon from "@mui/icons-material/WarningAmberSharp";
import { uid } from "uid";
import { dateFormat } from "../../../utils/dateFormat";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function AddAppointmentModal(props) {
  let { classes } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const [appointmentDetail, setAppointmentDetail] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    location: "",
    invitees: [],
    allDay: false,
  });

  const addAppoint = useSelector((item) => item.widgets.addAppoint);
  const open = useSelector((state) => state.widgets.openAppointment);
  const rightPositions = useSelector((state) => state.widgets.rightPositions);

  useEffect(() => {
    if (addAppoint.success && !addAppoint.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully create the appointment",
        errorType: "success",
        isOpen: true,
      });
      handleClose(false);
      setAppointmentDetail({
        title: "",
        description: "",
        start: "",
        end: "",
        location: "",
        invitees: [],
        allDay: false,
      });
    } else if (!addAppoint.isLoading && addAppoint.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(addAppoint.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addAppoint.isLoading]);
  const handleUpdateDetail = (field, value) => {
    setAppointmentDetail({ ...appointmentDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!appointmentDetail.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter title";
      setErrorAlert({
        errorMsg: "Please enter title",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter description";
      setErrorAlert({
        errorMsg: "Please enter description",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.start) {
      isFormValid = false;
      errorMsg["start"] = "Please select start date";
      setErrorAlert({
        errorMsg: "Please select start date",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.end) {
      isFormValid = false;
      errorMsg["end"] = "Please select start time";
      setErrorAlert({
        errorMsg: "Please select start time",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.location) {
      isFormValid = false;
      errorMsg["location"] = "Please enter location";
      setErrorAlert({
        errorMsg: "Please enter location",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleCreateAppointment = () => {
    if (handleValidate()) {
      let obj = {
        ...appointmentDetail,
        invitees: appointmentDetail.invitees?.length
          ? appointmentDetail.invitees?.map((item) => ({
              email: item.email,
              name: item.full_name,
            }))
          : {},
      };
      dispatch(addAppointment(obj));
    }
  };
  const handleClose = () => {
    dispatch(
      handleUpdateModalState({ type: "openAppointment", action: false })
    );
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        // onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          sx: {
            position: "fixed",
            zIndex: 1000,
            right:
              rightPositions?.length &&
              rightPositions.find((item) => item.type === "openAppointment")
                ? rightPositions.find((item) => item.type === "openAppointment")
                    .right
                : -20,
            bottom: -15,
            borderRadius: "6px",
          },
        }}
        hideBackdrop
        disableEnforceFocus
        disableBackdropClick
      >
        <DialogTitle
          className={classes.appointmentModalHeading}
          id="alert-dialog-title"
        >
          <Box> Create Appointment</Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={() => {
                dispatch(
                  handleAddMinimizeTab({
                    title: "New Appointment",
                    type: "appointment",
                    id: `${uid()}appointment`,
                    tabDetail: {},
                  })
                );
                handleClose();
              }}
            >
              {" "}
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={() => handleClose()}>
              {" "}
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          className={classes.customScrollBar}
          sx={{ width: "800px" }}
        >
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: "-15px" }}
          >
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Title
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder="Search or create tags"
                value={appointmentDetail.title}
                onChange={(e) => handleUpdateDetail("title", e.target.value)}
                error={errMsg.title}
                helperText={errMsg.title}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Description
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={4}
                placeholder="Search or create tags"
                value={appointmentDetail.description}
                onChange={(e) =>
                  handleUpdateDetail("description", e.target.value)
                }
                error={errMsg.description}
                helperText={errMsg.description}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item lg={5} xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Start Date & time
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  minutesStep={15}
                  fullWidth
                  disablePast
                  value={dateFormat(appointmentDetail?.start)}
                  onChange={(newValue) => handleUpdateDetail("start", newValue)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      error={errMsg.start}
                      helperText={errMsg.start}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              lg={1}
              xs={12}
              sx={{
                paddingTop: "52px !important",
                paddingLeft: "30px !important",
              }}
            >
              to
            </Grid>
            <Grid item lg={4} xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                End Date & time
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  minutesStep={15}
                  fullWidth
                  minDateTime={appointmentDetail?.start}
                  disablePast
                  error={appointmentDetail?.end ? true : false}
                  disabled={appointmentDetail?.start ? false : true}
                  value={dateFormat(appointmentDetail?.end)}
                  onChange={(newValue) => handleUpdateDetail("end", newValue)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      error={errMsg.end}
                      helperText={errMsg.end}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                sx={{ mt: "25px" }}
                control={
                  <Checkbox
                    checked={appointmentDetail.allDay}
                    onChange={(e) =>
                      handleUpdateDetail("allDay", e.target.checked)
                    }
                  />
                }
                label="All Day"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Location
              </InputLabel>
              <TextField
                fullWidth
                value={appointmentDetail.location}
                onChange={(e) => handleUpdateDetail("location", e.target.value)}
                error={errMsg.location}
                helperText={errMsg.location}
                classes={{ root: classes.detailInput }}
                placeholder="Search or create tags"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TagsInput
                fullWidth
                size="small"
                variant="outlined"
                newAppointment={appointmentDetail}
                setNewAppointment={setAppointmentDetail}
                name="tags"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "4px 13px 24px 20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCreateAppointment()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addAppoint.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddAppointmentModalStyle" })(
  AddAppointmentModal
);

const TagsInput = ({ newAppointment, setNewAppointment, type }) => {
  const storedListOfUsers = useSelector(
    (item) => item.users?.storedListOfUsers
  );
  const [selectedItem, setSelectedItem] = useState([]);
  const [inviteeWithoutEmail, setInviteeWithoutEmail] = useState([]);
  const [userlist, setUserList] = useState([]);

  useEffect(() => {
    let arr = [];
    arr = arr?.concat(storedListOfUsers?.list);
    const arrUpdated = arr.map((item) => {
      return {
        group: item?.role?.name,
        ...item,
        name: item.full_name || item.name,
        picture: item?.profile_images?.profile_img || "",
      };
    });
    setUserList(arrUpdated);
  }, []);

  useEffect(() => {
    const witoutEmailItems = selectedItem.filter(
      (si) =>
        si.email === undefined || si.email === null || si.email.length === 0
    );
    setInviteeWithoutEmail(witoutEmailItems);
  }, [selectedItem]);
  const CUstomSortUsers = useCallback(
    (users) => {
      let agents = users.filter((item) => item?.role?.name === "Agents");
      let admins = users.filter((item) => item?.role?.name === "Admin");
      let teamLeader = users.filter(
        (item) => item?.role?.name === "Team Leader"
      );
      let otherUsers = users.filter(
        (item) =>
          item?.role?.name !== "Team Leader" &&
          item?.role?.name !== "Admin" &&
          item?.role?.name !== "Agents"
      );
      return [
        ...otherUsers?.filter((item) => item.group !== undefined),
        ...teamLeader,
        ...admins,
        ...agents,
      ];
    },
    [userlist]
  );
  return (
    <React.Fragment>
      <Box>
        <Autocomplete
          multiple
          limitTags={4}
          disableClearable
          sx={{
            "& label": {
              fontWeight: "400",
              fontSize: "1rem",
              lineHeight: "1.75",
            },
            "& .MuiAutocomplete-endAdornment": {
              display: "none",
            },
            zIndex: 1000000,
          }}
          id="invitee-appointment-tags"
          selectOnFocus
          clearOnBlur
          disableCloseOnSelect
          autoHighlight
          groupBy={(option) => option?.group}
          options={
            CUstomSortUsers(
              userlist
            ) /* .sort((x, y) => (x.group < y.group ? -1 : x.group > y.group ? 1 : 0)) */
          }
          getOptionLabel={(option) => option?.name}
          value={selectedItem}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map(({ name, full_name, email, picture }, index) => (
              <Tooltip
                placement="top"
                title={email}
                arrow
                followCursor
                disableHoverListener={name ? false : true}
              >
                <Chip
                  label={name || email}
                  {...getTagProps({ index })}
                  key={index}
                  onDelete={() => {
                    const des = [...selectedItem];
                    des.splice(index, 1);
                    const appointments = {
                      ...newAppointment,
                      invitees: des,
                    };
                    setNewAppointment(appointments);
                    setSelectedItem(des);
                  }}
                  avatar={
                    <Avatar
                      {...stringAvatar(
                        name || full_name || email,
                        picture || false
                      )}
                    />
                  }
                ></Chip>
              </Tooltip>
            ))
          }
          renderOption={(a, b, c, d, e) => {
            return (
              <ListItem key={b.name} disablePadding dense {...a}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    c.selected ||
                    selectedItem.some((ii) => {
                      return ii.email === b.email;
                    })
                  }
                />
                <ListItemText
                  id={b.name}
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Avatar
                        sx={{ height: 30, width: 30 }}
                        {...stringAvatar(b.name, b?.picture || false)}
                      />
                      {b.name}
                    </Box>
                  }
                />
              </ListItem>
            );
          }}
          onKeyDown={(a) => {
            if (a.code === "Backspace") {
              const key_code = [...selectedItem];
              key_code.pop();
              const appointments = {
                ...newAppointment,
                invitees: key_code,
              };
              setNewAppointment(appointments);
              setSelectedItem(key_code);
              return;
            }
          }}
          onChange={(a, b, c, d, e) => {
            let schema = null;
            if (typeof d?.option == "object") {
              schema = d?.option;
            }
            if (typeof a.target.value === "string") {
              return null;
            }

            const duplicatedValues = selectedItem.findIndex(
              (i) => i.email === schema?.email
            );

            if (!!schema) {
              if (schema.group === "Agents") {
                schema.userId = schema.id;
              }
              if (duplicatedValues === -1) {
                const kk = [...selectedItem];
                kk.push(schema);
                setSelectedItem(kk);
                const appointments = {
                  ...newAppointment,
                  invitees: kk,
                };
                setNewAppointment(appointments);
                return;
              } else {
                const kk = [...selectedItem];
                kk.splice(duplicatedValues, 1);
                setSelectedItem(kk);
                const appointments = {
                  ...newAppointment,
                  invitees: kk,
                };
                setNewAppointment(appointments);
              }
            }
          }}
          renderInput={(params) => (
            <>
              <Input {...params} size="small" label="Invitees" />
            </>
          )}
        />
      </Box>
      {inviteeWithoutEmail.length !== 0 && (
        <Box
          sx={{
            color: "red",
            display: "flex",
            gap: "20px",
            margin: "20px 10px",
            fontSize: "18px",
            alignItems: "center",
          }}
        >
          <WarningAmberSharpIcon fontSize="large" />
          <div>
            There is no email attached to these records. They will not receive
            invites.
            <ul style={{ margin: "0px 10px", padding: "10px" }}>
              {inviteeWithoutEmail.map((iw) => (
                <li style={{ fontSize: "14px" }}>{iw.name}</li>
              ))}
            </ul>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};
