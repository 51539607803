import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, MenuItem } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import { closeTransactionContract } from "../../../redux/transactions/closeContract";
function CloseContract(props) {
  let { open, setOpen, classes, id } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [closeDetail, setCloseDetail] = useState({
    gci: "",
    commission_id: "",
    closing_notes: "",
    closing_date: "",
    closing_price: "",
  });
  const dispatch = useDispatch();
  const closeContract = useSelector((state) => state.transactions.TransactionsContract.closeContract);
  const handleUpdateDetail = (field, value) => {
    setCloseDetail({ ...closeDetail, [field]: value });
    setErrMsg({});
  };
  useEffect(() => {
    if (closeContract.success && !closeContract.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully closed this contract",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
    } else if (!closeContract.isLoading && closeContract.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(closeContract.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [closeContract.success]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!closeDetail.gci) {
      isFormValid = false;
      errorMsg["gci"] = "Please enter the gci amount";
      setErrorAlert({
        errorMsg: "Please enter the gci amount",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!closeDetail.closing_date) {
      isFormValid = false;
      errorMsg["closing_date"] = "Please select closing date";
      setErrorAlert({
        errorMsg: "Please select closing date",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!closeDetail.closing_price) {
      isFormValid = false;
      errorMsg["closing_price"] = "Please enter closing price";
      setErrorAlert({
        errorMsg: "Please enter closing price",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!closeDetail.closing_date) {
      isFormValid = false;
      errorMsg["closing_date"] = "Please select closing date";
      setErrorAlert({
        errorMsg: "Please select closing date",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!closeDetail.closing_notes) {
      isFormValid = false;
      errorMsg["closing_notes"] = "Please enter closing notes";
      setErrorAlert({
        errorMsg: "Please enter closing notes",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleCloseContract = () => {
    if (handleValidate()) {
      dispatch(closeTransactionContract({ ...closeDetail, id: id }));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-title">
          Close Contract
        </DialogTitle>
        <DialogContent sx={{ width: "600px", minHeight: "40vh" }}>
          <br />
          <Grid container direction="row" spacing={2}>
            <Grid item lg={4} xs={12} sx={{ zIndex: "1" }}>
              <TextField
                fullWidth
                value={closeDetail.gci}
                onChange={(e) => handleUpdateDetail("gci", e.target.value)}
                error={errMsg.gci}
                classes={{ root: classes.detailInput }}
                placeholder={"$472.41"}
                helperText="Confirm GCI Amount"
                size="small"
                variant="standard"
              />
            </Grid>
            <Grid item lg={4} xs={12} sx={{ zIndex: "1" }}>
              <TextField
                fullWidth
                value={closeDetail.closing_date}
                onChange={(e) => handleUpdateDetail("closing_date", e.target.value)}
                classes={{ root: classes.detailInput }}
                helperText="Confirm Closing Date"
                type="date"
                size="small"
                variant="standard"
                error={errMsg.closing_date}
              />
            </Grid>
            <Grid item lg={4} xs={12} sx={{ zIndex: "1" }}>
              <TextField
                fullWidth
                value={closeDetail.closing_price}
                onChange={(e) => handleUpdateDetail("closing_price", e.target.value)}
                classes={{ root: classes.detailInput }}
                placeholder={"$10,020"}
                helperText="Confirm Closing Price"
                size="small"
                variant="standard"
                error={errMsg.closing_price}
              />
            </Grid>
            <Grid item lg={5} xs={12} sx={{ zIndex: "2" }}></Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Closing Notes
              </InputLabel>
              <TextField
                value={closeDetail.closing_notes}
                onChange={(e) => handleUpdateDetail("closing_notes", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={6}
                placeholder="Enter closing notes"
                variant="outlined"
                size="small"
                error={errMsg.closing_notes}
                helperText={errMsg.closing_notes}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCloseContract()}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={closeContract.isLoading}
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "CloseContractStyle" })(CloseContract);
