import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import { Box, Checkbox, ListItemIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { capitalize } from "../../../projects/helpers.js";
import { updateTask } from "../../../../redux/agentTasks/index.js";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import AvatarComponent from "../../../projects/AvatarComponent.js";
import Avatar from "../../../../components/uiElements/avatar/index.jsx";

export default function TasksList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tasks, type, isLoading } = useSelector((state) => state.agentTasks);
  let [tasksToDisplay, setTasksToDisplay] = React.useState([]);

  React.useEffect(() => {
    setTasksToDisplay([...tasks]);
  }, [tasks]);

  const handleToggle = (id) => {
    let t = JSON.parse(JSON.stringify([...tasksToDisplay]));
    let val;
    t.map((task) => {
      if (task.id === id) {
        val = !task.isCompleted;
        task.isCompleted = !task.isCompleted;
      }
      return task;
    });
    setTasksToDisplay(t);
    dispatch(updateTask({ id, val }));
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress sx={{ color: "#0B0909" }} />
        </Box>
      )}

      {!isLoading && (
        <Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "AvenirNext!important",
              marginBottom: "20px",
            }}
            variant="h5"
          >
            {capitalize(type)} Tasks
          </Typography>
          <List
            sx={{ width: "600", minWidth: 600, bgcolor: "background.paper" }}
          >
            {tasksToDisplay.map((task) => {
              const labelId = `checkbox-list-label-${task.id}`;
              return (
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    textDecoration: task.isCompleted ? "line-through" : "none",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <Checkbox
                      edge="center"
                      checked={task.isCompleted}
                      onChange={() => handleToggle(task.id)}
                      tabIndex={-1}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ fontSize: "14px", width: "40px", height: "40px" }}
                    >
                      {`${task.realtor_agent?.MemberFullName?.slice(
                        0,
                        1
                      )}${task.realtor_agent?.MemberFullName?.split(
                        " "
                      )?.[1]?.slice(0, 1)}`}
                    </Avatar>
                    {/* <AvatarComponent
                      alt="task-realtor-agent"
                      nameInitial={task.realtor_agent?.MemberFullName}
                    /> */}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="a"
                          onClick={() =>
                            navigate(
                              `/tools/recruiting/details/${task.realtor_agent?.id}`
                            )
                          }
                          sx={{
                            color: "#01579B",
                            fontSize: "17px",
                            fontWeight: "500",
                            cursor: "pointer",
                            marginBottom: "5px",
                            "&:hover": {
                              borderBottom: "1px solid #01579B;",
                              marginBottom: "4px",
                            },
                          }}
                        >
                          {task.realtor_agent?.MemberFullName}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(0, 0, 0, 0.87)",
                            fontSize: "12px",
                            fontWeight: "600",
                            fontFamily: "AvenirNext!important",
                          }}
                        >
                          {task.dueDateTime
                            ? moment(task.dueDateTime).format("LL HH:MM:SS a")
                            : "All Day"}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                          component="p"
                          variant="body2"
                          color="text.primary"
                        >
                          {task.name}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                          component="p"
                          variant="body2"
                          color="text.primary"
                        >
                          {task.AssignedTo}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            })}

            {tasksToDisplay.length === 0 && (
              <Typography
                sx={{
                  fontWeight: "500",
                  padding: "10px",
                  fontFamily: "AvenirNext!important",
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "15px",
                }}
              >
                No Tasks Found!
              </Typography>
            )}
          </List>
        </Box>
      )}
    </>
  );
}
