import React, { useState, useEffect, Fragment } from "react";
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
  Autocomplete,
  Checkbox,
  Tooltip,
  Chip,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { addLeadAppointmentAPI } from "../../../../redux/leads/addLeadAppointmentAPI";
import { deleteLeadAppointmentAPI } from "../../../../redux/leads/deleteLeadAppointmentAPI";
import { updateLeadAppointmentAPI } from "../../../../redux/leads/updateLeadAppointmentAPI";
import { getAppointmentTypes } from "../../../../redux/leads/getAppointmentTypes";
import { dateFormat } from "../../../../utils/dateFormat";
import { stringAvatar } from "../../../../utils/avatarInitial";
import WarningAmberSharpIcon from "@mui/icons-material/WarningAmberSharp";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ResponseAlert from "../../../../components/responseAlert";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const inviteeFnc = (arr) => {
  const { emails, id, MemberEmail, name, memberPicture } = arr;
  let primary = emails?.find((l) => l.isPrimary);
  return [
    {
      name: name,
      leadId: id,
      id: id,
      email: primary ? primary.value : MemberEmail,
      picture: memberPicture || "",
    },
  ];
};

export const AddAppointmentsModal = ({
  closeModal,
  state,
  usersList,
  type,
  initialValue,
  id,
  ...props
}) => {
  const [newAppointment, setNewAppointment] = useState({
    title: "",
    description: "",
    staffDescription: "",
    typeId: null,
    start: null,
    end: null,
    location: "",
    invitees: [],
    appointment_invitees: usersList,
    allDay: false,
    type: "",
  });
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const leadProfile = useSelector((item) => item.leadProfile);
  useEffect(() => {
    dispatch(getAppointmentTypes());
  }, []);

  useEffect(() => {
    if (type === "Update") {
      setNewAppointment({
        ...initialValue,
        invitees: initialValue?.appointment_invitees,
        appointment_invitees: usersList,
      });
    } else {
      let obj = {
        ...newAppointment,
        invitees: inviteeFnc(leadProfile?.list?.data),
      };
      setNewAppointment(obj);
    }
  }, [type, initialValue]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!newAppointment.type) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the appointment type",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!newAppointment.title) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the appointment title",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["title"] = "Please enter the appointment title";
    } else if (!newAppointment.description) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the appointment description",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["description"] = "Please enter the appointment description";
    } else if (!newAppointment.start) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the start date",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["start"] = "Please select the start date";
    } else if (!newAppointment.end) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the end date",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["end"] = "Please select the end date";
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const saveAppointments = async () => {
    if (handleValidate()) {
      setLoading(true);
      const schema = {
        schema: {
          personId: id,
          title: newAppointment.title,
          description: newAppointment.description,
          staffDescription: newAppointment.staffDescription,
          typeId: newAppointment.typeId,
          start: newAppointment.start,
          end: newAppointment.end,
          location: newAppointment.location,
          invitees: newAppointment.invitees,
          allDay: newAppointment.allDay,
        },
        id: newAppointment?.id,
        handleSuccess,
        handleError,
      };

      if (type === "Update") {
        dispatch(updateLeadAppointmentAPI(schema));
      } else {
        dispatch(addLeadAppointmentAPI(schema));
      }
    }
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the appointment",
      errorType: "success",
      isOpen: true,
    });
    setLoading(false);
    closeModal();
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };
  const handleSuccessDelete = () => {
    setErrorAlert({
      errorMsg: `You have successfully deleted the appointment`,
      errorType: "success",
      isOpen: true,
    });
    closeModal();
  };

  const deleteTaskFnc = () => {
    setLoading(true);
    if (type === "Update") {
      dispatch(
        deleteLeadAppointmentAPI({ id: newAppointment.id, handleSuccessDelete })
      );
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "25px" } }}
        loading={loading}
        dialogHead={<DialogHead type={type} />}
        onSave={saveAppointments}
        dialogBody={
          <DialogBody
            newAppointment={newAppointment}
            setErrMsg={setErrMsg}
            errMsg={errMsg}
            setNewAppointment={setNewAppointment}
            usersList={usersList}
          />
        }
        dialogFooter={<DialogFooter />}
        state={state}
        closeModal={closeModal}
        maxWidth="sm"
        buttonWidth="100px"
        fullWidth
        type={type}
        onDelete={deleteTaskFnc}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
};

// Components
const AddAppointmentForm = ({
  mb,
  newAppointment,
  setNewAppointment,
  usersList,
  setErrMsg,
  errMsg,
}) => {
  const changeFromDateFn = (newValue) => {
    const newData = { ...newAppointment };
    newData.start = newValue;
    setNewAppointment(newData);
    setErrMsg({});
  };

  const changeToateFn = (newValue) => {
    const newData = { ...newAppointment };
    newData.end = newValue;
    setNewAppointment(newData);
    setErrMsg({});
  };

  const changeNameFunc = (event) => {
    const newData = { ...newAppointment };
    newData.title = event.target.value;
    setNewAppointment(newData);
    setErrMsg({});
  };
  const changeDescFunc = (event) => {
    const newData = { ...newAppointment };
    newData.description = event.target.value;
    setNewAppointment(newData);
    setErrMsg({});
  };
  const changeLocationFunc = (event) => {
    const newData = { ...newAppointment };
    newData.location = event.target.value;
    setNewAppointment(newData);
  };
  const changeAppointmentType = (value) => {
    const newData = { ...newAppointment };
    newData.type = value;
    setNewAppointment(newData);
    setErrMsg({});
  };

  return (
    <>
      <Box sx={{ display: "block", marginBottom: "10px" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ flexGrow: 1, marginBottom: mb || 0 }}
        >
          <Grid item sm={12}>
            <Input
              onChange={(e) => changeNameFunc(e)}
              value={newAppointment.title}
              placeholder="Title"
              label={
                <Typography component="p" variant="subtitle1">
                  Title
                </Typography>
              }
              size="small"
              error={errMsg["title"]}
              helperText={errMsg["title"]}
            />
          </Grid>
          <Grid item sm={12} sx={{ marginTop: "4px" }}>
            <Input
              onChange={(e) => changeDescFunc(e)}
              value={newAppointment.description}
              placeholder="Description"
              multiline
              rows={4}
              label={
                <Typography component="p" variant="subtitle1">
                  Description
                </Typography>
              }
              size="small"
              error={errMsg["description"]}
              helperText={errMsg["description"]}
            />
          </Grid>
          <Grid item sm={6} sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={dateFormat(newAppointment?.start)}
                minutesStep={15}
                fullWidth
                onChange={changeFromDateFn}
                disablePast
                label={
                  <Typography component="p" variant="subtitle1">
                    Start Date & Time
                  </Typography>
                }
                renderInput={(params) => (
                  <Input
                    size="small"
                    fullWidth
                    {...params}
                    error={errMsg["start"]}
                    helperText={errMsg["start"] || ""}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {/* <Grid item sm={2} sx={{ textAlign: "center", mt: 6 }}>
            <Typography component="subtitle" variant="subtitle">
              to
            </Typography>
          </Grid> */}
          <Grid item sm={6} sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                minutesStep={15}
                fullWidth
                value={dateFormat(newAppointment?.end)}
                onChange={changeToateFn}
                minDateTime={newAppointment?.start}
                disablePast
                error={newAppointment?.end ? true : false}
                disabled={newAppointment?.start ? false : true}
                label={
                  <Typography component="p" variant="subtitle1">
                    End Date & Time
                  </Typography>
                }
                renderInput={(params) => (
                  <Input
                    size="small"
                    fullWidth
                    {...params}
                    error={errMsg["end"]}
                    helperText={errMsg["end"] || ""}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={12} sx={{ marginTop: "10px" }}>
            <Input
              onChange={(e) => changeLocationFunc(e)}
              value={newAppointment.location}
              placeholder="Location"
              label={
                <Typography component="p" variant="subtitle1">
                  Location
                </Typography>
              }
              size="small"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ flexGrow: 1, marginBottom: mb || 0 }}
        >
          <Grid item sm={12}>
            <FormControl sx={{ mt: 2, minWidth: "100%" }}>
              <TagsInput
                fullWidth
                variant="outlined"
                newAppointment={newAppointment}
                setNewAppointment={setNewAppointment}
                name="tags"
                usersList={usersList}
              />
            </FormControl>
            <FormControl
              sx={{
                mt: 5,
                position: "absolute",
                top: "-10px",
                right: "30px",
              }}
            >
              <ButtonGroups
                id="appointment-type-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="38.5px"
                minWidth="185px"
                size="medium"
                color="white"
                fontSize="13px"
                options={[
                  { title: "Showing", value: "Showing" },
                  { title: "Meeting", value: "Meeting" },
                  { title: "Introduction", value: "Introduction" },
                  {
                    title: "Listing Presentation",
                    value: "Listing Presentation",
                  },
                ]}
                value={newAppointment.type}
                onChangeMenu={(value) => changeAppointmentType(value)}
                placeholder="Appointment Type"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const DialogHead = ({ type }) => (
  <DialogTitle>
    <Typography
      sx={{ fontSize: "24px", fontFamily: "AvenirNext-400", mt: "15px" }}
      component="h4"
      variant="h4"
    >
      {type === "Update" ? "Update Appointment" : "Create Appointment"}
    </Typography>
  </DialogTitle>
);

const DialogBody = ({
  newAppointment,
  setNewAppointment,
  usersList,
  setErrMsg,
  errMsg,
}) => (
  <DialogContent sx={{ mt: "10px", paddingBottom: "0px" }}>
    <AddAppointmentForm
      setNewAppointment={setNewAppointment}
      setErrMsg={setErrMsg}
      errMsg={errMsg}
      newAppointment={newAppointment}
      usersList={usersList}
    />
    <br />
  </DialogContent>
);

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);

const TagsInput = ({ newAppointment, setNewAppointment, type }) => {
  const { users } = useSelector((item) => item);
  const { agentProfile } = useSelector((item) => item.agentProfile);
  const [selectedItem, setSelectedItem] = useState([]);
  const [inviteeWithoutEmail, setInviteeWithoutEmail] = useState([]);
  const [userlist, setUserList] = useState([]);

  useEffect(() => {
    if (type !== "Update") {
      setSelectedItem(newAppointment?.invitees);
    }
  }, [newAppointment?.invitees]);

  useEffect(() => {
    let arr = [];
    arr = arr?.concat(inviteeFnc(agentProfile?.list));

    arr = arr?.concat(users?.storedListOfUsers?.list);
    const arrUpdated = arr.map((item) => {
      return {
        group: item?.role?.name,
        ...item,
        name: item.full_name || item.name,
        picture: item?.profile_images?.profile_img || "",
      };
    });
    setUserList(arrUpdated);
  }, []);

  useEffect(() => {
    const witoutEmailItems = selectedItem.filter(
      (si) =>
        si.email === undefined || si.email === null || si.email.length === 0
    );
    setInviteeWithoutEmail(witoutEmailItems);
  }, [selectedItem]);
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    let admins = users.filter((item) => item?.role?.name === "Admin");
    let teamLeader = users.filter((item) => item?.role?.name === "Team Leader");
    let otherUsers = users.filter(
      (item) =>
        item?.role?.name !== "Team Leader" &&
        item?.role?.name !== "Admin" &&
        item?.role?.name !== "Agents"
    );
    return [
      ...otherUsers?.filter((item) => item.group !== undefined),
      ...teamLeader,
      ...admins,
      ...agents,
    ];
  };
  return (
    <React.Fragment>
      <Box>
        <Autocomplete
          multiple
          limitTags={4}
          disableClearable
          sx={{
            "& label": {
              fontWeight: "400",
              fontSize: "1rem",
              lineHeight: "1.75",
            },
            "& .MuiAutocomplete-endAdornment": {
              display: "none",
            },
          }}
          id="invitee-appointment-tags"
          selectOnFocus
          clearOnBlur
          disableCloseOnSelect
          autoHighlight
          groupBy={(option) => option?.group}
          options={
            CUstomSortUsers(
              userlist
            ) /* .sort((x, y) => (x.group < y.group ? -1 : x.group > y.group ? 1 : 0)) */
          }
          getOptionLabel={(option) => option?.name}
          value={selectedItem}
          freeSolo
          renderTags={(value, getTagProps) =>
            value?.map(({ name, full_name, email, picture }, index) => (
              <Tooltip
                placement="top"
                title={email}
                arrow
                followCursor
                disableHoverListener={name ? false : true}
              >
                <Chip
                  label={name || email}
                  {...getTagProps({ index })}
                  key={index}
                  onDelete={() => {
                    const des = [...selectedItem];
                    des.splice(index, 1);
                    const appointments = {
                      ...newAppointment,
                      invitees: des,
                    };
                    setNewAppointment(appointments);
                    setSelectedItem(des);
                  }}
                  avatar={
                    <Avatar
                      {...stringAvatar(
                        name || full_name || email,
                        picture || false
                      )}
                    />
                  }
                ></Chip>
              </Tooltip>
            ))
          }
          renderOption={(a, b, c, d, e) => {
            return (
              <ListItem key={b.name} disablePadding dense {...a}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    c.selected ||
                    selectedItem.some((ii) => {
                      return ii.email === b.email;
                    })
                  }
                />
                <ListItemText
                  id={b.name}
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Avatar
                        sx={{ height: 30, width: 30, fontSize: "14px" }}
                        {...stringAvatar(b.name, b?.picture || false)}
                      />
                      {b.name}
                    </Box>
                  }
                />
              </ListItem>
            );
          }}
          onKeyDown={(a) => {
            if (a.code === "Backspace") {
              const key_code = [...selectedItem];
              key_code.pop();
              const appointments = {
                ...newAppointment,
                invitees: key_code,
              };
              setNewAppointment(appointments);
              setSelectedItem(key_code);
              return;
            }
          }}
          onChange={(a, b, c, d, e) => {
            let schema = null;
            if (typeof d?.option == "object") {
              schema = d?.option;
            }
            if (typeof a.target.value === "string") {
              return null;
            }

            const duplicatedValues = selectedItem.findIndex(
              (i) => i.email === schema?.email
            );

            if (!!schema) {
              if (schema.group === "Agents") {
                schema.userId = schema.id;
              }
              if (duplicatedValues === -1) {
                const kk = [...selectedItem];
                kk.push(schema);
                setSelectedItem(kk);
                const appointments = {
                  ...newAppointment,
                  invitees: kk,
                };
                setNewAppointment(appointments);
                return;
              } else {
                const kk = [...selectedItem];
                kk.splice(duplicatedValues, 1);
                setSelectedItem(kk);
                const appointments = {
                  ...newAppointment,
                  invitees: kk,
                };
                setNewAppointment(appointments);
              }
            }
          }}
          size="small"
          renderInput={(params) => (
            <Input
              {...params}
              label="Invitees"
              sx={{ "& .MuiInputBase-root": { padding: "5px" } }}
              size="small"
            />
          )}
        />
      </Box>
      {inviteeWithoutEmail.length !== 0 && (
        <Box
          sx={{
            color: "red",
            display: "flex",
            gap: "20px",
            margin: "20px 10px",
            fontSize: "18px",
            alignItems: "center",
          }}
        >
          <WarningAmberSharpIcon fontSize="large" />
          <div>
            There is no email attached to these records. They will not receive
            invites.
            <ul style={{ margin: "0px 10px", padding: "10px" }}>
              {inviteeWithoutEmail.map((iw) => (
                <li style={{ fontSize: "14px" }}>{iw.name}</li>
              ))}
            </ul>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};
