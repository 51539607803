import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {  List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import Avatar from "../../../../components/uiElements/avatar/index";
// Name Cell Component
export const NameCard = ({ name, market }) => {
  return (
    <List sx={{ width: "100%", maxWidth: 360, paddingTop: 0, paddingBottom: 0 }}>
      <ListItem alignItems="flex-start" sx={{padding:0}}>
        <ListItemAvatar>
          <Avatar sx={{fontSize:"17px",fontWeight:"400"}} >{name && name.trim() ? `${name[0]}${name?.split(" ")[1]?.slice(0,1)}` : "NA"}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={<React.Fragment>{market}</React.Fragment>} />
      </ListItem>
    </List>
  );
};

export const PhoneCell = ({ number }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: "5px",
      }}
    >
      <LocalPhoneIcon /> {number}
    </div>
  );
};

export const StageCell = ({ name }) => {
  return (
    <span
      style={{
        backgroundColor: "#000",
        borderRadius: "12px",
        padding: "6px 8px",
        color: "white",
      }}
    >
      {name}
    </span>
  );
};
