import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { RadioGroupUi } from "../../../../components/uiElements/radioGroup/RadioGroupUi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect } from "react";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";

export const TextFiltersOptions = ({ applyFilter, vals }) => {
  return (
    <Box sx={{ padding: "5px 0px 0px 15px", opacity: "0.6" }}>
      <RadioGroupUi
        onChange={(e) => {
          e.stopPropagation();
          applyFilter(
            { ...vals, operatorValue: e.target.value },
            vals.columnField
          );
        }}
        value={vals.operatorValue}
        onTextFieldChange={(e) => {
          e.stopPropagation();
          applyFilter({ ...vals, value: e.target.value }, vals.columnField);
        }}
        textFieldVal={vals.value || ""}
        name="text-filter"
        options={[
          { name: "is not empty", value: "isNotEmpty" },
          {
            name: "contains",
            value: "contains",
            inputField: true,
            inputFieldType: "text",
          },
          { name: "is empty", value: "isEmpty" },
        ]}
      />
    </Box>
  );
};

export const PriceFiltersOptions = ({ applyFilter, vals }) => {
  return (
    <Box sx={{ padding: "5px 0px 0px 15px", opacity: "0.6" }}>
      <RadioGroupUi
        onChange={(e) => {
          e.stopPropagation();
          if (e.target.value === "<>") {
            applyFilter(
              {
                ...vals,
                operatorValue: e.target.value,
                value: { start: "", end: "" },
              },
              vals.columnField
            );
          } else {
            applyFilter(
              { ...vals, operatorValue: e.target.value, value: "" },
              vals.columnField
            );
          }
        }}
        value={vals.operatorValue}
        onTextFieldChange={(e, val) => {
          e.stopPropagation();
          applyFilter({ ...vals, value: val }, vals.columnField);
        }}
        textFieldVal={vals.value || ""}
        name="text-filter"
        options={[
          { name: "is not empty", value: "isNotEmpty" },
          {
            name: "is less than",
            value: "<",
            inputField: true,
            inputFieldType: "number",
          },
          {
            name: "is greater than",
            value: ">",
            inputField: true,
            inputFieldType: "number",
          },
          {
            name: "is between",
            value: "<>",
            int_between: true,
            inputFieldType: "number",
          },
          { name: "is empty", value: "isEmpty" },
        ]}
      />
    </Box>
  );
};

export const PhoneFiltersOptions = ({ applyFilter, vals }) => {
  return (
    <Box sx={{ padding: "5px 0px 0px 15px", opacity: "0.6" }}>
      <RadioGroupUi
        onChange={(e) => {
          e.stopPropagation();
          applyFilter(
            { ...vals, operatorValue: e.target.value },
            vals.columnField
          );
        }}
        value={vals.operatorValue}
        onTextFieldChange={(e) => {
          e.stopPropagation();
          applyFilter({ ...vals, value: e.target.value }, vals.columnField);
        }}
        textFieldVal={vals.value || ""}
        name="text-filter"
        options={[
          { name: "is good", value: "isGood" },
          {
            name: "starts with",
            value: "startsWith",
            inputField: true,
            inputFieldType: "number",
          },
          {
            name: "does not starts with",
            value: "notStartsWith",
            inputField: true,
            inputFieldType: "number",
          },
          { name: "is not empty", value: "isNotEmpty" },
          { name: "is empty", value: "isEmpty" },
          { name: "is bad", value: "isBad" },
        ]}
      />
    </Box>
  );
};

export const DateFiltersOptions = ({ applyFilter, vals }) => {
  return (
    <Box sx={{ padding: "5px 0px 0px 15px", opacity: "0.6" }}>
      <RadioGroupUi
        onChange={(e) => {
          if (e.target.value === "<>") {
            applyFilter(
              {
                ...vals,
                operatorValue: e.target.value,
                value: {
                  start: { timeInterval: "days", value: "" },
                  end: { timeInterval: "days", value: "" },
                },
              },
              vals.columnField
            );
          } else {
            applyFilter(
              {
                ...vals,
                operatorValue: e.target.value,
                value: { timeInterval: "days", value: "" },
              },
              vals.columnField
            );
          }
        }}
        value={vals.operatorValue}
        onTextFieldChange={(e, valField) => {
          if (vals.operatorValue === "<>") {
            if (valField === "start") {
              applyFilter(
                {
                  ...vals,
                  value: {
                    ...vals.value,
                    start: {
                      timeInterval: vals.value?.start?.timeInterval || "days",
                      value: e.target.value,
                    },
                  },
                },
                vals.columnField
              );
            } else {
              applyFilter(
                {
                  ...vals,
                  value: {
                    ...vals.value,
                    end: {
                      timeInterval: vals.value?.end?.timeInterval || "days",
                      value: e.target.value,
                    },
                  },
                },
                vals.columnField
              );
            }
          } else {
            applyFilter(
              {
                ...vals,
                value: {
                  timeInterval: vals.value?.timeInterval || "days",
                  value: e.target.value,
                },
              },
              vals.columnField
            );
          }
        }}
        onDropDownSelectFieldChange={(e, valField) => {
          if (vals.operatorValue === "<>") {
            if (valField === "start") {
              applyFilter(
                {
                  ...vals,
                  value: {
                    ...vals.value,
                    start: {
                      timeInterval: e.target.value || "days",
                      value: vals.value?.start?.value,
                    },
                  },
                },
                vals.columnField
              );
            } else {
              applyFilter(
                {
                  ...vals,
                  value: {
                    ...vals.value,
                    end: {
                      timeInterval: e.target.value || "days",
                      value: vals.value?.end?.value,
                    },
                  },
                },
                vals.columnField
              );
            }
          } else {
            applyFilter(
              {
                ...vals,
                value: { ...vals.value, timeInterval: e.target.value },
              },
              vals.columnField
            );
          }
        }}
        textFieldVal={vals.value}
        name="date-filter"
        options={[
          {
            name: "was less than",
            value: "<",
            inputDateTimeFrame: true,
            inputFieldType: "number",
          },
          {
            name: "was greater than",
            value: ">",
            inputDateTimeFrame: true,
            inputFieldType: "number",
          },
          {
            name: "between",
            value: "<>",
            inputDateTimeFrame: true,
            inputFieldType: "number",
          },
          { name: "are empty", value: "isEmpty" },
        ]}
      />
    </Box>
  );
};

export const IncludeWithEmptyFiltersOptions = ({ applyFilter, vals, data }) => {
  return (
    <Box sx={{ padding: "5px 0px 0px 15px", opacity: "0.6" }}>
      <RadioGroupUi
        onChange={(e) => {
          applyFilter(
            { ...vals, operatorValue: e.target.value },
            vals.columnField
          );
        }}
        value={vals.operatorValue}
        textField={"name"}
        onDropDownSelectFieldChange={(e, val) => {
          e.stopPropagation();
          applyFilter({ ...vals, value: val }, vals.columnField);
        }}
        dropDownVal={vals.value || ""}
        name="text-filter"
        dataList={data}
        options={[
          { name: "are not empty", value: "isNotEmpty" },
          { name: "include any", value: "isAnyOf", multiDrop: true },
          { name: "exclude any", value: "not", multiDrop: true },
          { name: "are empty", value: "isEmpty" },
        ]}
      />
    </Box>
  );
};

export const IncludeWithoutEmptyFiltersOptions = ({
  applyFilter,
  vals,
  data,
}) => {
  return (
    <Box sx={{ padding: "5px 0px 0px 15px", opacity: "0.6" }}>
      <RadioGroupUi
        onChange={(e) => {
          applyFilter(
            { ...vals, operatorValue: e.target.value },
            vals.columnField
          );
        }}
        value={vals.operatorValue}
        textField={"name"}
        dropDownVal={vals.value || ""}
        onDropDownSelectFieldChange={(e, val) => {
          e.stopPropagation();
          applyFilter({ ...vals, value: val }, vals.columnField);
        }}
        name="include-exclude-empty-filter"
        dataList={data}
        options={[
          { name: "include", value: "include", multiDrop: true },
          { name: "exclude", value: "exclude", multiDrop: true },
        ]}
      />
    </Box>
  );
};
const SortableContent = ({ field, apiRef, type }) => {
  const applySort = (direction) => {
    try {
      apiRef.current.setSortModel([
        {
          field: field,
          sort: direction,
        },
      ]);
    } catch (error) {
      console.log("error in sort", error);
    }
  };
  return (
    <Box sx={{ padding: "5px 0px 0px 15px", width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <SortIcon sx={{ marginRight: "5px" }} />
        <Typography variant="subtitle1" fontSize={14}>
          Sort
        </Typography>
      </Box>

      <Box>
        <ButtonGroup size="small" variant="outlined" aria-label="sort-buttons">
          <Button variant="outlined" onClick={(e) => applySort("asc")}>
            {type == "price"
              ? "0-9"
              : type === "date"
              ? "OLD TO NEW"
              : "A TO Z"}
          </Button>
          <Button variant="outlined" onClick={(e) => applySort("desc")}>
            {type == "price"
              ? "9-0"
              : type === "date"
              ? "NEW TO OLD"
              : "Z TO A"}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

// Name Cell Component
export const AgentRecruitmentCustomFilters = ({
  apiRef,
  tagsList,
  filterButtonEl,
  setFilterButtonEl,
  ...props
}) => {
  const {
    filter: {
      filterModel: { items },
    },
    columns,
  } = apiRef.current.state;

  const [expanded, setExpanded] = useState(null);
  const [field, setField] = useState(
    apiRef.current.state?.focus?.columnHeader?.field ||
      apiRef.current.state?.tabIndex?.columnHeader?.field
  );
  useEffect(() => {
    setField(
      apiRef.current.state?.focus?.columnHeader?.field ||
        apiRef.current.state?.tabIndex?.columnHeader?.field
    );
  }, [
    apiRef.current.state?.focus?.columnHeader?.field,
    apiRef.current.state?.tabIndex?.columnHeader?.field,
  ]);

  useEffect(() => {
    setExpanded(null);
    // let currentIndex = items.findIndex((f) => f.columnField === field);
    // if (currentIndex < 0) {
    //   apiRef.current.upsertFilterItems([...items, { columnField: field }]);
    // }
  }, [field]);
  const applyFilter = (item, i) => {
    let newItems = [...items];
    newItems[i] = item;
    apiRef.current.upsertFilterItems(newItems);
  };

  const addNewFilter = (item) => {
    let newItems = [...items];
    newItems.push(item);
    apiRef.current.upsertFilterItems(newItems);
  };

  const clearFilter = () => {
    let newItems = [...items];
    newItems = newItems.filter((f) => f.columnField !== field);

    apiRef.current.upsertFilterItems(newItems);
  };

  const clearSingleFilter = (index) => {
    let newItems = [...items];
    newItems.splice(index, 1);
    apiRef.current.upsertFilterItems(newItems);
  };
  if (field) {
    return (
      <Box sx={{ width: "100%" }}>
        {columns["lookup"][field].props?.canSort && (
          <SortableContent
            type={columns["lookup"][field]["type"]}
            apiRef={apiRef}
            field={field}
          />
        )}
        <Box sx={{ padding: "5px 0px 0px 15px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <FilterAltIcon sx={{ marginRight: "5px" }} />
              <Typography variant="subtitle1" fontSize={14}>
                Filter
              </Typography>
            </Box>
            <IconButton
              aria-label="Add filter"
              color="primary"
              sx={{ color: "black" }}
              component="span"
              onClick={() => {
                addNewFilter({ columnField: field });
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        {items.map((val, i) => {
          if (val.columnField === field) {
            if (expanded === null) {
              setExpanded(i);
            }
            let Icon = columns["lookup"][field].props.icon;
            return (
              <Accordion
                key={i}
                expanded={expanded === i}
                disableGutters={true}
                onClick={(e) => {
                  setExpanded(i);
                }}
              >
                <AccordionSummary
                  aria-controls="filter-content"
                  id="filter-header"
                  sx={{
                    "&.MuiAccordionSummary-root": {
                      minHeight: "15px",
                    },
                    "&.MuiAccordionSummary-content": {
                      margin: "0px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Icon sx={{ marginRight: "5px" }} />
                      <Typography variant="subtitle1" fontSize={14}>
                        {columns["lookup"][field].headerName}
                      </Typography>
                    </Box>
                    <IconButton
                      aria-label="delete filter"
                      sx={{ color: "black" }}
                      component="span"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        clearSingleFilter(i);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </Box>
                  {/* <Typography>{columns["lookup"][field].headerName}</Typography> */}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    "&.MuiAccordionDetails-root": {
                      padding: "0px 5px 5px",
                    },
                  }}
                >
                  {columns["lookup"][field]["type"] === "text" ? (
                    <TextFiltersOptions
                      applyFilter={({ ...data }) => {
                        applyFilter(data, i);
                      }}
                      vals={val}
                    />
                  ) : columns["lookup"][field]["type"] === "phone" ? (
                    <PhoneFiltersOptions
                      applyFilter={({ ...data }) => {
                        applyFilter(data, i);
                      }}
                      vals={val}
                    />
                  ) : columns["lookup"][field]["type"] ===
                    "include/exclude/empty" ? (
                    <IncludeWithEmptyFiltersOptions
                      applyFilter={({ ...data }) => {
                        applyFilter(data, i);
                      }}
                      vals={val}
                      data={columns["lookup"][field].props.data}
                    />
                  ) : columns["lookup"][field]["type"] === "include/exclude" ? (
                    <IncludeWithoutEmptyFiltersOptions
                      applyFilter={({ ...data }) => {
                        applyFilter(data, i);
                      }}
                      vals={val}
                      data={columns["lookup"][field].props.data}
                    />
                  ) : columns["lookup"][field]["type"] === "date" ? (
                    <DateFiltersOptions
                      applyFilter={({ ...data }) => {
                        applyFilter(data, i);
                      }}
                      vals={val}
                      data={columns["lookup"][field].props.data}
                    />
                  ) : (
                    <PriceFiltersOptions
                      applyFilter={({ ...data }) => {
                        applyFilter(data, i);
                      }}
                      vals={val}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return null;
          }
        })}

        <Divider />
        <Box
          sx={{
            padding: "18px 18px 0px 18px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
            onClick={(e) => {
              apiRef.current.setColumnVisibility(field, false);
              setFilterButtonEl(null);
            }}
          >
            <VisibilityIcon sx={{ marginRight: "5px" }} />
            <Typography variant="subtitle1" fontSize={14}>
              Hide Column
            </Typography>
          </Box>

          <Box
            onClick={(e) => {
              clearFilter();
            }}
          >
            <Typography variant="subtitle1" fontSize={14}>
              Clear Filter
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
};
