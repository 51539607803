export const updateData = async (url, formData,method) => {

  const token = localStorage.getItem('token')

  let result = await fetch(url, {
    method: method?method:'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(formData)
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.log('GET_DATA_ERROR: ', error));
  return result;
};
  