import React, { useState, useEffect } from "react";
import { Box, Container, Card, CardHeader, CardContent, TextField, Stack, Button } from "@mui/material";
import { styleSheet } from "../style";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useLocation } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { updateData } from "../../../../utils/updateData";
import ResponseAlert from "../../../../components/responseAlert";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

let Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

function EditEmailTemplates(props) {
  const [loading, setLoading] = useState(false);
  const [templateDetail, setTemplateDetail] = useState({
    name: "",
    subject: "",
  });
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  let { state } = useLocation();
  const [edtitorValue, setEdtitorValue] = useState();
  const [emailValue, setEmailValue] = useState("");
  let { classes } = props;
  const navigate = useNavigate();
  const handleUpdateField = (field, value) => {
    setTemplateDetail({
      ...templateDetail,
      [field]: value,
    });
  };

  const handleOnChange = (value) => {
    setEmailValue(value);
  };
  useEffect(() => {
    if (state.id) {
      setTemplateDetail({
        name: state.name,
        subject: state.subject,
      });
      setEmailValue(state.body);
      console.log(state, "state");
    }
  }, [state]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!templateDetail.name) {
      isFormValid = false;
      errorMsg["name"] = "Please enter the email template name";
      setErrorAlert({
        errorMsg: "Please enter the email template name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!templateDetail.subject) {
      isFormValid = false;
      errorMsg["subject"] = "Please enter the email template subject";
      setErrorAlert({
        errorMsg: "Please enter the email template subject",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleUpdateTemplate = async () => {
    if (handleValidate()) {
      setLoading(true);
      let obj = {
        name: templateDetail.name,
        subject: templateDetail.subject,
        body: emailValue || "<p>🙋🏻‍♀️  &mdash; This is a new <b>test email</b></p>",
      };
      let response = await updateData(`${process.env.REACT_APP_BASE_URL}api/templates/${state.id}`, obj, "PATCH");
      console.log(response, "response");
      if (response.id) {
        setLoading(false);
        setErrorAlert({
          errorMsg: "You have successfully updated the email template",
          errorType: "success",
          isOpen: true,
        });
        setTimeout(() => {
          navigate("/templates/email-templates");
        }, 1000);
      } else {
        setLoading(false);
        setErrorAlert({
          errorMsg: JSON.stringify(response.message),
          errorType: "error",
          isOpen: true,
        });
      }
    }
  };
  return (
    <Container maxWidth="xl">
      <Box className={classes.addTempPageRoot}>
        <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRoot}>
          <CardHeader classes={{ title: classes.pageHeading }} title="Update Email Template" />
          <CardContent>
            <TextField
              fullWidth
              classes={{ root: classes.detailInput }}
              value={templateDetail.name}
              placeholder="This is the template name field"
              onChange={(e) => handleUpdateField("name", e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Name"
              size="small"
              error={errMsg.name}
              helperText={errMsg.name}
            />
            <br />
            <br />
            <TextField
              fullWidth
              classes={{ root: classes.detailInput }}
              value={templateDetail.subject}
              placeholder="this is the subject field of the template"
              onChange={(e) => handleUpdateField("subject", e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Subject"
              size="small"
              error={errMsg.subject}
              helperText={errMsg.subject}
            />
            <br />
            <br />
            <Box className={classes.quillEditor}>
              <ReactQuill className="react-quill" theme="snow" value={emailValue} onChange={handleOnChange} />
            </Box>
            <Stack sx={{ mt: "30px" }} direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button
                onClick={() => navigate("/templates")}
                sx={{
                  color: "#000000",
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={() => handleUpdateTemplate()}
                classes={{ root: classes.saveButton }}
                color="inherit"
                autoFocus
                loadingPosition="start"
                loading={loading}
              >
                Update
              </LoadingButton>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Container>
  );
}
export default withStyles(styleSheet, { name: "EditEmailTemplatesStyle" })(EditEmailTemplates);
