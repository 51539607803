import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  allAgent: [],
  errMsg: null,
};

export const getAgentList = createAsyncThunk(
  "docsDetails/getAgentList",
  async (data, thunkAPI) => {
    try {
      const url = "api/agent-roster/all-agents";
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            console.log("res", res);
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const allAgent = createSlice({
  name: "allAgent",
  initialState,
  extraReducers: {
    [getAgentList.pending]: (state) => {
      state.loading = true;
    },
    [getAgentList.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAgent = action.payload?.data;
    },
    [getAgentList.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default allAgent.reducer;
