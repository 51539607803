import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Card, CardHeader } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { AttachCommissionPlan } from "../../../../redux/agents/agentRosterDetail/attachCommissionPlan";
import { updateAttachedPlan } from "../../../../redux/agents/agentRosterDetail/getAttachedPlans";
import { useParams } from "react-router-dom";

function AddCommissionModal(props) {
  let { open, setOpen, classes } = props;
  const [selectedPlans, setSelectedPlans] = useState("");
  const toastId = React.useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const commissionPlans = useSelector((state) => state.finance.commissionPlans);
  const AttachedCommissionPlan = useSelector((state) => state.agentRoster.AttachedCommissionPlan);
  const AddCommissionPlan = useSelector((state) => state.agentRoster.AddCommissionPlan);
  useEffect(() => {
    if (AddCommissionPlan.success && !AddCommissionPlan.isLoading) {
      dispatch(updateAttachedPlan(AddCommissionPlan?.data?.data));
      if (!toast.isActive(toastId.current)) {
        toast.success("You have successfully select the plan", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setOpen(false);
    }
  }, [AddCommissionPlan]);

  const handleSelectPlan = () => {
    if (selectedPlans) {
      let obj = {
        commission_id: selectedPlans,
        id: id,
      };
      dispatch(AttachCommissionPlan(obj));
    } else {
      if (!toast.isActive(toastId.current)) {
        toast.warning("Please select the plan first", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const checkIfAlreadyAttached = (id) => {
    let IsAttached = AttachedCommissionPlan.data?.find((item) => item.id === id);
    if (IsAttached) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Dialog
      disablePortal
      open={open}
      sx={{ "& .MuiDialog-container": { mt: "30px" } }}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle className={classes.paymentModalHeading} id="alert-dialog-title">
        {"Attach Commission Plan"}
      </DialogTitle>
      <DialogContent sx={{ width: "600px" }}>
        <TextField fullWidth placeholder="Search Commission Plans" variant="outlined" size="small" />
        <br />
        <br />
        <Card variant="outlined" sx={{ backgroundColor: "white", padding: "10px" }}>
          {commissionPlans.data && commissionPlans.data?.length > 0 ? (
            commissionPlans.data.map((item, index) => (
              <Card
                key={index}
                onClick={() => {
                  if (!checkIfAlreadyAttached(item.id)) {
                    setSelectedPlans(item.id);
                  }
                }}
                sx={{
                  marginTop: "10px",
                  cursor: checkIfAlreadyAttached(item.id) ? "not-allowed" : "pointer",
                  background: checkIfAlreadyAttached(item.id) ? "#2BA579" : "",
                }}
              >
                <CardHeader
                  classes={{
                    title: classes.agentProfileDetailHeader,
                    subheader: classes.agentProfileDetail,
                  }}
                  action={
                    selectedPlans === item.id ? (
                      <CheckBoxIcon sx={{ color: "green" }} />
                    ) : checkIfAlreadyAttached(item.id) ? (
                      <CheckBoxIcon sx={{ color: "white" }} />
                    ) : null
                  }
                  title={`${item.name} - ${item.agentPer}% Agent / ${item.companyPer}% Company`}
                  subheader={
                    <>
                      We should support note/comment field for each commi plan as a helpful hint of when that plan should be used{" "}
                      <span style={{ fontSize: "12px", marginLeft: "15px" }}>
                        Created on {moment(item.createdAt || new Date()).format("LL")} By Lena Austin
                      </span>
                    </>
                  }
                />
              </Card>
            ))
          ) : (
            <DataNotFound />
          )}
        </Card>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "10px" }}>
        <Button
          onClick={() => setOpen(false)}
          sx={{
            color: "#000000",
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={AddCommissionPlan.isLoading}
          onClick={() => handleSelectPlan()}
          classes={{ root: classes.commisionSelectionButTon }}
          color="inherit"
          autoFocus
          loadingPosition="start"
        >
          Select
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styleSheet, { name: "AddCommissionModalStyle" })(AddCommissionModal);
