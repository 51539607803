export const NumberFormat = ({
  locales,
  number,
  currency,
  style,
  maximumFractionDigits,
  notation,
}) => {
  const nf = new Intl.NumberFormat(locales || "en-US", {
    style: style || "currency",
    currency: currency || "USD",
    maximumFractionDigits: maximumFractionDigits || 0,
    notation: notation || "standard",
  });
  return number ? nf.format(number || 0) : "";
};
