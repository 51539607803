import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const options = {
  responsive: true,
  tension: 0.3,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    // y: {
    // grid: {
    //   display: false
    // },
    //   ticks: {
    //     display: false,
    //   },
    // },
    xAxes: [
      {
        display: true,
      },
    ],
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
};

function AgentActivityChart(props) {
  let { data } = props;
  return (
    <Line
      width={600}
      height={120}
      plugins={[
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;
              let ctx = chart.ctx;
              ctx.save();
              ctx.beginPath();
              ctx.setLineDash([5, 5]);
              ctx.moveTo(x, yAxis.top);
              ctx.lineTo(x, yAxis.bottom);
              ctx.lineWidth = 1;
              ctx.strokeStyle = "rgba(0, 0, 255, 0.4)";
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ]}
      options={options}
      data={data}
    />
  );
}
export default AgentActivityChart;
