import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Grid, FormControlLabel, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { getSingleSmartList, updateSmartList } from "../../redux/smartList";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@mui/styles";
import MultiSelectCheckbox from "./MultiSelectCheckbox";
import SmartListModalSkeleton from "./skelton/modal";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "400px",
    height: "auto",
  },
}));

export default function EditSmartList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { open, setOpen, selectedSmartList } = props;
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const { list: usersList, teamsList } = useSelector((item) => item.users.storedListOfUsers);

  const handleUpdate = () => {
    let dataToSave = { ...formData };
    dataToSave.sharedWith = {
      userIds: formData.sharedWith.users.map((user) => user.id),
      teamIds: formData.sharedWith.teams.map((team) => team.id),
    };
    dispatch(updateSmartList({ id: selectedSmartList.id, smartListData: dataToSave }));
    setOpen(false);
  };

  const handleClose = () => {
    setFormData({});
    setOpen(false);
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  React.useEffect(() => {
    if (selectedSmartList) {
      (async function () {
        setLoading(true);
        const response = await dispatch(getSingleSmartList(selectedSmartList.id));
        if (response.payload) {
          const smartlist = response?.payload?.data || {};
          const sharedWith = {
            users: smartlist?.sharedWith?.userIds.map((id) => usersList.find((user) => user.id === id)) || [],
            teams: smartlist?.sharedWith?.teamIds.map((id) => teamsList.find((team) => team.id === id)) || [],
          };
          setFormData({
            ...smartlist,
            sharedWith: {
              ...sharedWith,
            },
          });
          setLoading(false);
        }
      })();
    }
  }, [selectedSmartList]);


  return (
    <Dialog
      disablePortal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Edit Smart List</DialogTitle>
      {loading && <SmartListModalSkeleton />}
      {!loading && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} gap={"5px"} flexDirection={"column"} display={"flex"}>
              <label>Name</label>
              <TextField
                fullWidth
                name="name"
                size={"small"}
                placeholder="Name"
                value={formData.name}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item xs={12} gap={"5px"} flexDirection={"column"} display={"flex"}>
              <label>Description</label>
              <TextField
                fullWidth
                name="description"
                size={"small"}
                placeholder="Description"
                value={formData.description}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider textAlign={"middle"} variant={"fullWidth"} flexItem sx={{ mt: "1rem", mb: "-10px" }}>
                Shared With
              </Divider>
            </Grid>

            <Grid item xs={12} gap={"5px"} flexDirection={"column"} display={"flex"}>
              <label>Users</label>
              <MultiSelectCheckbox
                label={"full_name"}
                type={"user(s)"}
                selected={formData?.sharedWith?.users || []}
                list={usersList || []}
                setSeleted={(values) => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      sharedWith: {
                        ...prev.sharedWith,
                        users: [...values],
                      },
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} gap={"5px"} flexDirection={"column"} display={"flex"}>
              <label>Teams</label>
              <MultiSelectCheckbox
                label={"name"}
                type={"team(s)"}
                selected={formData?.sharedWith?.teams || []}
                list={teamsList || []}
                setSeleted={(values) => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      sharedWith: {
                        ...prev.sharedWith,
                        teams: [...values],
                      },
                    };
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant={"contained"} onClick={handleUpdate} autoFocus>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
