export const isArrayCheck = (data) => {
  return Array.isArray(data) && data.length > 0;
};

export const makeKnowledgeBaseDataRow = (arrData, setData, categoriesData) => {
  var val = isArrayCheck(arrData)
    ? arrData.map((data, id) => ({
        id: id + 1,
        image: data?.thumbnail,
        title: data?.title,
        category: isArrayCheck(categoriesData)
          ? categoriesData.find((cat) => cat.id === data?.category_id)?.title
          : "",
        dateAdded: String(data?.createdAt).substring(0, 10),
        lastModified: String(data?.updatedAt).substring(0, 10),
        views: data?.views,
        createdBy: "Agentname",
      }))
    : [];
  // console.log('Row Data', towns_listing);
  setData(val);
};
