import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import React, { useEffect, useState } from "react";
import MessageIcon from "@mui/icons-material/Message";
import PushPinIcon from "@mui/icons-material/PushPin";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import EditIcon from "@mui/icons-material/Edit";
import Input from "../../../../../components/uiElements/input/Input";
import Button from "../../../../../components/uiElements/button";
import { useDispatch, useSelector } from "react-redux";
import { updateTimelineRecordAgentRecruitment } from "../../../../../redux/agents/agentRecruitment/updateTimelineRecordAgentRecruitment";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import StarIcon from "@mui/icons-material/Star";
import { useAudio } from "../../../../../utils/playAudio";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../../../leads/common/Tabs/TabContent/mentions/defaultStyle.js";
import defaultMentionStyle from "../../../../leads/common/Tabs/TabContent/mentions/defaultMentionStyle";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import ImgsViewer from "react-images-viewer";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function sanitizeHTML(html) {
  if (!html) {
    return "";
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Example: Remove all `<script>` tags and potentially other dangerous tags
  const scripts = tempDiv.getElementsByTagName("script");
  const styles = tempDiv.getElementsByTagName("style");

  while (scripts.length > 0) {
    scripts[0].parentNode.removeChild(scripts[0]);
  }

  while (styles.length > 0) {
    styles[0].parentNode.removeChild(styles[0]);
  }

  // Remove any inline styles or classes that might override your app's styles
  const allElements = tempDiv.getElementsByTagName("*");
  for (let i = 0; i < allElements.length; i++) {
    allElements[i].removeAttribute("style");
    allElements[i].removeAttribute("class");
  }

  return tempDiv.innerHTML;
}

const convertHtmlToPlainText = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
const moment = require("moment-timezone");
const NameCard = ({
  name,
  date,
  avatar,
  duration,
  isTooltip,
  customName,
  isHideAvatar,
}) => {
  const stringAvatar = () => {
    if (avatar) {
      return {
        src: `${avatar}`,
      };
    } else if (name && !customName) {
      return {
        children: `${name.split(" ")?.[0]?.[0] || ""}${
          name.split(" ")?.[1]?.[0] || name.split(" ")?.[1]?.[1] || ""
        }`,
      };
    } else {
      return {
        src: "",
      };
    }
  };

  return (
    <List disablePadding>
      <ListItem
        disablePadding
        alignItems="flex-start"
        sx={{ paddingBottom: "3px" }}
      >
        {" "}
        {isHideAvatar ? null : (
          <ListItemAvatar>
            <Avatar sx={{ fontSize: "16px" }} {...stringAvatar()} />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={
            <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <Box
                sx={{
                  fontWeight: "600",
                  fontFamily: "AvenirNext",
                  fontSize: "14px",
                }}
              >
                {!!isTooltip ? (
                  <Tooltip
                    title={isTooltip?.title}
                    placement={isTooltip?.placement}
                  >
                    <Box>{name}</Box>
                  </Tooltip>
                ) : (
                  name
                )}
              </Box>
              {duration ? (
                <Typography sx={{ fontSize: "14px", color: "#707070" }}>
                  ({duration} Sec)
                </Typography>
              ) : null}
            </Box>
          }
          secondary={
            <Box sx={{ fontFamily: "AvenirNext-400", fontSize: "12px" }}>
              {date}
            </Box>
          }
        />
      </ListItem>
    </List>
  );
};

export const MessageTimeline = ({
  classes,
  color,
  short,
  usersList,
  item,
  handleUpdateFavorite,
}) => {
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [mediaUrls, setMediaUrls] = React.useState([]);
  const itemType = item.type;
  const fil = usersList.filter((el) => el.id === item?.[itemType]?.createdById);
  const [isFavorite, setIsFavorite] = useState(item.is_starred);

  const getUser = (id) => {
    return usersList.find((el) => el.id === id);
  };

  return (
    <TimelineItem classes={{ root: classes.root }}>
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        {/* <TimelineConnector
          sx={{
            backgroundColor: short ? "white" : "",
          }}
        /> */}
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
          }}
        >
          <MessageIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-7px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            isTooltip={{ title: item[itemType]?.toNumber, placement: "right" }}
            avatar={fil[0]?.profile_images?.profile_img || ""}
            name={
              item[itemType]?.isIncoming
                ? `${item?.[itemType]?.firstName || ""} ${
                    item?.[itemType]?.lastName || ""
                  }${
                    getUser(item?.[itemType]?.userId)?.full_name
                      ? ` > ${getUser(item?.[itemType]?.userId)?.full_name}`
                      : ""
                  }`
                : `${fil?.[0]?.full_name || ""} > ${
                    item?.[itemType]?.firstName || ""
                  } ${item?.[itemType]?.lastName || ""}`
            }
            date={moment(item?.[itemType]?.created)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm:ss a")}
          />
          <IconButton
            size="small"
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>
        <Divider sx={{ bgcolor: "#B5B5B5" }} />
        <Box sx={{ paddingTop: "10px" }}>
          <Typography>{item?.[itemType]?.message}</Typography>
          {item?.text_message?.attachments?.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
                flexWrap: "wrap",
                mt: "15px",
              }}
            >
              {item?.text_message?.attachments?.map((item, index) => (
                <img
                  src={item.url}
                  width="80px"
                  height="80px"
                  style={{
                    objectFit: "cover",
                    border: "1px solid lightgrey",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMediaUrls([item.url]);
                    setImageViewerOpen(true);
                  }}
                  alt={item.url}
                />
              ))}
            </Box>
          ) : null}
        </Box>
      </TimelineContent>
      {mediaUrls?.length > 0 && (
        <ImgsViewer
          imgs={mediaUrls?.map((item) => ({
            src: item,
          }))}
          isOpen={imageViewerOpen}
          currImg={0}
          showThumbnails={true}
          enableKeyboardInput={true}
          onClose={() => setImageViewerOpen(false)}
        />
      )}
    </TimelineItem>
  );
};

export const EmailTimeline = ({
  classes,
  color,
  short,
  item,
  usersList,
  agentProfile,
  isEmailThread,
  handleUpdateFavorite,
}) => {
  const fil = isEmailThread
    ? usersList.filter(
        (el) => el.id === item?.emails_thread?.messages[0]?.createdById
      )
    : usersList.filter((el) => el.id === item?.emails_datum?.createdById);
  const [isReadMore, setIsReadMore] = useState(true);
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openedThread, setOpenedThread] = useState([]);

  const santized = item?.emails_datum?.body_data?.body_html
    ? sanitizeHtml(item?.emails_datum?.body_data?.body_html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "style"]),
        allowedAttributes: { img: ["src", "height", "width"] },
        allowedSchemes: ["data", "http", "https"],
      })
    : null;
  const getUser = (id) => {
    return usersList.find((el) => el.id === id);
  };
  return isEmailThread || item?.emails_datum ? (
    <TimelineItem classes={{ root: classes.root }}>
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        {/* <TimelineConnector sx={{ bgcolor: short ? "white" : "" }} /> */}
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
          }}
        >
          <EmailIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-7px", overflow: "auto" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            // name={fil?.[0]?.full_name || agentProfile?.list.MemberFullName}
            name={
              item?.is_incoming
                ? `${agentProfile?.list.MemberFullName}${
                    fil?.[0]?.full_name
                      ? fil?.[0]?.full_name
                      : getUser(item?.emails_datum?.userId)?.full_name
                      ? ` > ${
                          getUser(
                            item?.emails_datum?.userId || item?.realtor_agent_id
                          )?.full_name
                        }`
                      : ""
                  }`
                : `${
                    fil?.[0]?.full_name
                      ? fil?.[0]?.full_name
                      : getUser(item?.emails_datum?.userId)?.full_name
                      ? ` > ${getUser(item?.emails_datum?.userId)?.full_name}`
                      : ""
                  } > ${agentProfile?.list.MemberFullName}`
            }
            avatar={fil[0]?.profile_images?.profile_img}
            date={moment(item?.createdAt)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm:ss a")}
          />
          <IconButton
            size="small"
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>
        <Divider sx={{ bgcolor: "#B5B5B5" }} />
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "AvenirNext",
            fontSize: "14px",
            mt: "10px",
            paddingBottom: "6.5px",
          }}
        >
          {item?.emails_thread?.messages[0]?.subject ||
            item?.emails_datum?.subject}
        </Typography>
        <Box sx={{ paddingTop: "10px" }}>
          <Typography
            sx={{
              overflowWrap: "anywhere",
              whiteSpace: "pre-line",
              display: "-webkit-box !important",
              "-webkit-line-clamp": `${isReadMore ? "4" : "100000"} !important`,
              "-webkit-box-orient": "vertical !important",
              overflow: "hidden !important",
            }}
          >
            {santized ? (
              parse(santized)
            ) : (
              <Box
                sx={{
                  color: "black !important" /* Reset the color */,
                  background: "none" /* Reset background if needed */,
                }}
              >
                <div
                  style={{ color: "black !important" }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(
                      isEmailThread
                        ? item?.emails_thread?.messages[0]?.body_data
                            ?.body_text ||
                            item?.emails_thread?.messages[0]?.body_data
                              ?.body_html
                        : item?.emails_datum?.body_data?.body_text ||
                            item?.emails_datum?.body_data?.body_html
                    ),
                  }}
                />
              </Box>
            )}
          </Typography>
          {isReadMore ? (
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={() => setIsReadMore(false)}
              variant="text"
            >
              View Email
            </Button>
          ) : (
            false
          )}
        </Box>
        {isEmailThread && item?.emails_thread?.messages?.length > 1 ? (
          <Box sx={{ mt: "20px" }}>
            <IconButton
              size="small"
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                setOpenedThread([]);
              }}
            >
              <MoreHorizIcon fontSize="large" sx={{ color: "#9a9ea1" }} />
            </IconButton>
            {!isCollapsed ? (
              <Card
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                  setOpenedThread([]);
                }}
                className={classes.threadClosedCard}
                variant="outlined"
              >
                {item?.emails_thread?.messages?.length - 1} more emails in
                thread
              </Card>
            ) : null}
          </Box>
        ) : null}
        {isEmailThread &&
        isCollapsed &&
        item?.emails_thread?.messages?.length ? (
          <Box className={classes.threadMessagesArea}>
            {item?.emails_thread?.messages?.slice(1)?.map((message, index) =>
              !openedThread?.includes(`${index}`) &&
              item?.emails_thread?.messages?.length > 2 ? (
                <Card
                  onClick={() =>
                    setOpenedThread((threads) => [...threads, `${index}`])
                  }
                  className={classes.threadClosedSubCard}
                  variant="outlined"
                  key={message.id}
                  sx={{ mt: index === 0 ? "-15px !important" : "" }}
                >
                  <Typography className={classes.threadClosedSubCardName}>
                    {message?.is_incoming
                      ? `${agentProfile?.list.MemberFullName}`
                      : message.createdById
                      ? getUser(message.createdById)?.full_name
                      : "User Not found!"}
                  </Typography>
                  <Typography className={classes.threadClosedSubCardDes}>
                    {convertHtmlToPlainText(
                      message?.body_data?.body_text ||
                        message?.body_data?.body_html
                    )}
                  </Typography>
                  <Typography className={classes.threadClosedSubCardDate}>
                    {moment(item?.createdAt)
                      .tz("America/New_York")
                      .format("MMM DD")}
                  </Typography>
                </Card>
              ) : (
                <Card
                  variant="outlined"
                  sx={{ mt: index === 0 ? "-15px" : "", position: "relative" }}
                  className={classes.threadMessagesAreaItem}
                  key={message.id}
                >
                  {item?.emails_thread?.messages?.length > 2 ? (
                    <IconButton
                      size="small"
                      onClick={() =>
                        setOpenedThread((threads) =>
                          threads.filter((val) => val !== `${index}`)
                        )
                      }
                      className={classes.subCollapsedArea}
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{ color: "#9a9ea1" }}
                      />
                    </IconButton>
                  ) : null}

                  <NameCard
                    name={
                      message?.is_incoming
                        ? `${agentProfile?.list.MemberFullName}`
                        : message.createdById
                        ? getUser(message.createdById)?.full_name
                        : "User Not found!"
                    }
                    avatar={
                      message.createdById
                        ? getUser(message.createdById)?.profile_images
                            ?.profile_img
                        : fil[0]?.profile_images?.profile_img
                    }
                    nameLabel={
                      message?.is_incoming
                        ? `${agentProfile?.list.MemberFullName}`
                        : message.createdById
                        ? getUser(message.createdById)?.full_name
                        : "User Not found!"
                    }
                    date={moment(item?.createdAt)
                      .tz("America/New_York")
                      .format("MMMM Do YYYY, h:mm a")}
                  />
                  <CardContent className={classes.threadMessagesAreaItemDetail}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        overflowWrap: "anywhere",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {message?.subject}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        overflowWrap: "anywhere",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "AvenirNext !important",
                          color: "rgba(0, 0, 0, 0.6) !important",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              message?.body_data?.body_text ||
                              message?.body_data?.body_html,
                          }}
                        />
                      </pre>
                    </Typography>
                  </CardContent>
                </Card>
              )
            )}
          </Box>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  ) : null;
};

export const NoteTimeline = ({
  classes,
  color,
  short,
  item,
  usersList,
  handleUpdateFavorite,
}) => {
  const [inputText, setInputText] = useState("");
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  const [isEditable, setIsEditble] = useState(false);
  const itemType = item.type === "note" ? "realtor_note" : item.type;
  const fil = usersList.filter((el) => el.id === item?.[itemType].createdById);
  const [userIds, setUserIds] = useState([]);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const adminAuth = useSelector((state) => state.adminAuth);
  const dispatch = useDispatch();
  const {
    timelines: { actionState },
  } = useSelector((item) => item);

  const editModeFunc = () => {
    setInputText(item?.[itemType]?.body);
    setIsEditble(true);
  };
  const cancelFunc = () => {
    setIsEditble(false);
    setInputText("");
  };

  const onSaveFnc = async () => {
    if (inputText && inputText.replaceAll("\n", "")) {
      const schema = {
        path: `notes/${item?.[itemType]?.id}`,
        schema: {
          body: inputText,
          userIds: userIds.filter(
            (item, index) => userIds.indexOf(item) === index
          ),
        },
        timeline_id: item.id,
        timeline_type: item.type,
      };
      const fetch = await dispatch(
        updateTimelineRecordAgentRecruitment({ ...schema })
      );
      if (fetch.payload) {
        setInputText("");
        setUserIds([]);
        setIsEditble(false);
      }
    }
  };

  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        {/* <TimelineConnector sx={{ bgcolor: short ? "white" : "" }} /> */}
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
          }}
        >
          <PushPinIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-7px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            avatar={fil[0]?.profile_images?.profile_img}
            name={fil?.[0]?.full_name || "User not found!"}
            date={moment(item?.[itemType]?.created)
              .tz("America/New_York")
              .format("MMMM Do YYYY")}
          />
          <IconButton
            size="small"
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>
        <Divider sx={{ bgcolor: "#B5B5B5" }} />

        {isEditable ? (
          <Box>
            <MentionsInput
              value={inputText}
              onChange={(event, newValue, newPlainTextValue, mentions) => {
                setUserIds(mentions?.map((item) => item.id));
                setInputText(event.target.value);
              }}
              style={defaultStyle}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  onSaveFnc();
                  setInputText("");
                }
              }}
            >
              <Mention
                trigger="@"
                data={
                  agentRosterList?.list?.length
                    ? agentRosterList?.list?.map((item) => ({
                        id: item.id,
                        display: item?.first_name + " " + item.last_name,
                      }))
                    : []
                }
                style={defaultMentionStyle}
                markup={"@[__display__^__id__]"}
                // renderSuggestion={this.renderUserSuggestion}
              />
            </MentionsInput>
            {/* <Input multiline rows="4" value={inputText} onChange={(e) => setInputText(e.target.value)} /> */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {actionState?.loading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  <Button onClick={cancelFunc}>Cancel</Button> &nbsp;&nbsp;
                  <Button onClick={onSaveFnc} variant="contained">
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              paddingTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <Typography
              onClick={editModeFunc}
              sx={{ overflowWrap: "anywhere", whiteSpace: "pre-line" }}
            >
              {inputText
                ? reactStringReplace(inputText, /@\[(.*?)]/g, (match, i) => (
                    <CustomTooltip
                      title={match?.split("^")?.[0]}
                      placement="top"
                    >
                      <Box
                        sx={{
                          color:
                            match?.split("^")?.[1] == adminAuth?.adminDetail.id
                              ? "#007EA6 !important"
                              : "#434343 !important",
                          background:
                            match?.split("^")?.[1] == adminAuth?.adminDetail.id
                              ? "#CCF3FF !important"
                              : "#ECECEC !important",
                          display: "inline-block",
                          textDecoration: "none",
                          fontWeight: "500",
                        }}
                        key={i}
                        to={`/agent_roster/${
                          match?.split("^")?.[1] || 69
                        }/overview`}
                        component={Link}
                      >
                        @{match?.split(" ")?.[0]}
                      </Box>
                    </CustomTooltip>
                  ))
                : reactStringReplace(
                    parse(item?.[itemType]?.body),
                    /@\[(.*?)]/g,
                    (match, i) => (
                      <CustomTooltip
                        title={match?.split("^")?.[0]}
                        placement="top"
                      >
                        <Box
                          sx={{
                            color:
                              match?.split("^")?.[1] ==
                              adminAuth?.adminDetail.id
                                ? "#007EA6 !important"
                                : "#434343 !important",
                            background:
                              match?.split("^")?.[1] ==
                              adminAuth?.adminDetail.id
                                ? "#CCF3FF !important"
                                : "#ECECEC !important",
                            display: "inline-block",
                            textDecoration: "none",
                            fontWeight: "500",
                          }}
                          key={i}
                          to={`/agent_roster/${
                            match?.split("^")?.[1] || 69
                          }/overview`}
                          component={Link}
                        >
                          @{match?.split(" ")?.[0]}
                        </Box>
                      </CustomTooltip>
                    )
                  )}
            </Typography>
            <IconButton
              size="small"
              color="primary"
              className="edit-pen-timeline"
              sx={{ opacity: 0 }}
              component="span"
              onClick={editModeFunc}
            >
              <EditIcon sx={{ color: "#7e7e7e", fontSize: "20px" }} />
            </IconButton>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export const CallTimeline = ({
  classes,
  color,
  short,
  item,
  usersList,
  handleUpdateFavorite,
}) => {
  const [playing, toggle, stop, currentTime, duration, updateCurrentTime] =
    useAudio(item?.call?.recordingUrl);
  const [inputText, setInputText] = useState("");
  const [isEditable, setIsEditble] = useState(false);
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  const itemType = item.type;
  const fil = usersList.filter((el) => el.id === item?.[itemType]?.createdById);

  const dispatch = useDispatch();
  const {
    timelines: { actionState },
  } = useSelector((item) => item);

  const editModeFunc = () => {
    setInputText(item?.[itemType]?.note);
    setIsEditble(true);
  };
  const cancelFunc = () => {
    setIsEditble(false);
    setInputText("");
  };

  const onSaveFnc = async () => {
    const schema = {
      path: `calls/${item?.[itemType]?.id}`,
      schema: {
        note: inputText,
      },
      timeline_id: item.id,
      timeline_type: item.type,
    };
    const fetch = await dispatch(
      updateTimelineRecordAgentRecruitment({ ...schema })
    );
    if (fetch.payload) {
      setIsEditble(false);
      setInputText("");
    }
  };

  useEffect(() => {
    return () => {
      if (playing) {
        stop();
      }
    };
  }, [playing]);
  const getUser = (id) => {
    return usersList.find((el) => el.id === id);
  };
  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        {/* <TimelineConnector sx={{ bgcolor: short ? "white" : "" }} /> */}
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
          }}
        >
          {item?.is_incoming ? (
            <PhoneCallbackIcon sx={{ fontSize: "21px" }} />
          ) : (
            <CallIcon sx={{ fontSize: "21px" }} />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          py: "12px",
          px: 4,
          mt: "-7px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <NameCard
            isTooltip={{ title: item[itemType]?.toNumber, placement: "right" }}
            avatar={fil[0]?.profile_images?.profile_img}
            duration={
              item?.is_incoming
                ? item?.[itemType]?.duration
                  ? item?.[itemType].duration
                  : "0"
                : ""
            }
            name={
              item?.is_incoming
                ? `${item?.[itemType]?.firstName} ${
                    item?.[itemType]?.lastName
                  }${
                    getUser(item?.[itemType]?.userId)?.full_name
                      ? ` > ${getUser(item?.[itemType]?.userId)?.full_name}`
                      : ""
                  }`
                : `${fil?.[0]?.full_name} > ${item?.[itemType]?.firstName} ${item?.[itemType]?.lastName}`
            }
            date={moment(item?.[itemType]?.created)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm:ss a")}
          />
          <Box
            sx={{
              paddingBottom: "5px",
              display: "flex",
              cursor: "pointer",
            }}
          >
            {item.call.recordingUrl ? (
              <Box>
                {playing ? (
                  <Box className={classes.callPlayArea}>
                    <PauseCircleFilledIcon onClick={toggle} />{" "}
                    <input
                      type="range"
                      value={currentTime}
                      max={duration}
                      onChange={(e) => updateCurrentTime(e.target.value)}
                    />
                  </Box>
                ) : (
                  <PlayCircleFilledIcon onClick={toggle} />
                )}
              </Box>
            ) : null}

            <IconButton
              size="small"
              onClick={() => {
                handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
                if (isFavorite) {
                  setIsFavorite(false);
                } else {
                  setIsFavorite(true);
                }
              }}
            >
              {isFavorite ? (
                <StarIcon sx={{ color: "#F3B30E" }} />
              ) : (
                <StarBorderIcon sx={{ color: "#7e7e7e" }} />
              )}
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ bgcolor: "#B5B5B5" }} />
        {isEditable ? (
          <Box>
            <Input
              multiline
              rows="4"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {actionState?.loading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  <Button onClick={cancelFunc}>Cancel</Button> &nbsp;&nbsp;
                  <Button onClick={onSaveFnc} variant="contained">
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              paddingTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <Typography
              onClick={editModeFunc}
              sx={{ overflowWrap: "anywhere" }}
            >
              {inputText || (
                <div
                  dangerouslySetInnerHTML={{ __html: item?.[itemType]?.note }}
                ></div>
              )}
            </Typography>
            <IconButton
              sx={{ opacity: 0 }}
              size="small"
              className="edit-pen-timeline"
              color="primary"
              component="span"
              onClick={editModeFunc}
            >
              <EditIcon sx={{ color: "#7e7e7e", fontSize: "20px" }} />
            </IconButton>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export const AssignmentTimeline = ({
  classes,
  color,
  item,
  disabledConnecter,
}) => {
  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
      key={item.id}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: "#D2D2D2",
            width: "2px",
          },
        }}
      >
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FlipCameraAndroidIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-1px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            avatar={""}
            isHideAvatar={true}
            name={
              <Box className={classes.nameWithIcon}>
                <Box sx={{ fontWeight: "500" }}>Assigned to</Box>
                {item?.realtor_agent_assignment?.assignedToUser?.full_name}
                <Box sx={{ fontWeight: "500" }}>By</Box>
                {item?.realtor_agent_assignment?.assignedByUser?.full_name}
              </Box>
            }
            customName
            date={moment(item?.createdAt)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export const ActivityTimeline = ({
  classes,
  color,
  item,
  handleUpdateFavorite,
  disabledConnecter,
}) => {
  const [isFavorite, setIsFavorite] = useState(item.is_starred);
  return (
    <TimelineItem
      classes={{ root: classes.root }}
      sx={{
        "& :hover": {
          "& .edit-pen-timeline": {
            opacity: 1,
          },
        },
      }}
      key={item.id}
    >
      <TimelineSeparator
        sx={{
          "& .MuiTimelineConnector-root": {
            backgroundColor: isFavorite ? "#F3B30E" : "#D2D2D2",
            width: isFavorite ? "3px" : "2px",
          },
        }}
      >
        <TimelineDot
          sx={{
            background: color,
            padding: "10px",
            height: "43px",
            width: "43px",
            boxShadow: "none",
            border: isFavorite ? "3px solid #F3B30E" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QuestionAnswerIcon sx={{ fontSize: "21px" }} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 4, mt: "-1px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NameCard
            avatar={""}
            isHideAvatar={true}
            name={
              <Box className={classes.nameWithIcon}>
                {item?.realtor_agent_event?.type}
              </Box>
            }
            date={moment(item?.createdAt)
              .tz("America/New_York")
              .format("MMMM Do YYYY, h:mm a")}
          />
          <IconButton
            size="small"
            onClick={() => {
              handleUpdateFavorite(item.id, isFavorite ? "0" : "1");
              if (isFavorite) {
                setIsFavorite(false);
              } else {
                setIsFavorite(true);
              }
            }}
          >
            {" "}
            {isFavorite ? (
              <StarIcon sx={{ color: "#F3B30E" }} />
            ) : (
              <StarBorderIcon sx={{ color: "#7e7e7e" }} />
            )}
          </IconButton>
        </Box>
        <Typography sx={{ fontFamily: "AvenirNext", fontSize: "13px" }}>
          via: {item?.realtor_agent_event?.source}
        </Typography>
        {item?.realtor_agent_event?.message ? (
          <Typography sx={{ fontFamily: "AvenirNext", fontSize: "13px" }}>
            {item?.realtor_agent_event?.message}
          </Typography>
        ) : null}
        {item?.realtor_agent_event?.description ? (
          <Typography
            sx={{ fontFamily: "AvenirNext", fontSize: "13px", mt: "15px" }}
          >
            {item?.realtor_agent_event?.description}
          </Typography>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  );
};
