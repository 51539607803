export async function convertToFile(image, crop) {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      let file = new File([blob], "fileName.jpeg", { type: "image/jpeg" })
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        resolve({fileUrl: reader.result?.toString(), file});
      });
      reader.readAsDataURL(file);
    }, 'image/jpeg');
  });
}

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth*ratio, height: srcHeight*ratio };
}

export const resizeImage = async (file) => {
    // ensure the file is an image
    if (!file.type.match(/image.*/)) return null;

    const image = new Image();
    image.src = URL.createObjectURL(file);

    await new Promise((res) => image.onload = res);

    const dimensions = calculateAspectRatioFit(image.width, image.height, 2000, 2000)

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d", {alpha: true});

    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    if (image.height <= image.width) {
        const scaleProportions = canvas.height / image.height;
        const scaledWidth = scaleProportions * image.width;
        context.drawImage(image, (canvas.width - scaledWidth)/2, 0, scaledWidth, canvas.height);
    }
    else {
        const scaleProportions = canvas.width / image.width;
        const scaledHeight = scaleProportions * image.height;
        context.drawImage(image, 0, (canvas.height - scaledHeight)/2, canvas.width, scaledHeight);
    }

    return new Promise((res) => canvas.toBlob(res));
}