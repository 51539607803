export const styleSheet = (theme) => ({
  TransactionSalesRoot: {
    marginBottom: "16px",
    padding: "5px",
  },
  pageHeading: {
    fontSize: "26px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentTableButton:{
    fontSize:'12px  !important',
    borderRadius:"15px  !important",
    width:'100px !important'
  },
  saveModalButton:{
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft:'30px !important',
    paddingRight:'30px !important'
  },
  dropzoneArea:{
    position:"absolute",
    top:"0px",
    left:"0px",
    border:"2px dotted black",
    background:"rgba(255,255,255,.6)",
    width:"100%",
    height:'100%',
    borderRadius:"8px",
    zIndex:100000000,
    display:"none",
    justifyContent:"center",
    alignItems:"center"
  },
  dropzoneAreaInner:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontSize:"18.8px",
    color:"black",
    fontFamily: "AvenirNext",
    fontWeight:"600"
  },addInvoiceButton:{
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
  },
  downloadButton: {
    fontSize: "12px  !important",
    borderRadius: "15px  !important",
    backgroundColor: "black !important",
    color: "white !important",
    marginBottom: "4px",
    width: "92px !important",
    height:"24px"
  },
  uploadButton:{
    backgroundColor: "white !important",
    color: "#0B0909 !important",
    fontWeight: "400 !important",
    fontSize:"9px !important",
    borderRadius:"15px  !important",
    border: "0.4px solid #000000 !important",
    marginTop:"5px !important",
    height:"22px !important" 
  }
});
