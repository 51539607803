import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Box, MenuItem, Avatar, CardHeader, Card, IconButton, Typography } from "@mui/material";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { addPhoneQueue } from "../../../redux/phoneConfiguration/queue/addQueue";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function AddPhoneQueue(props) {
  let { open, setOpen, classes, audioList } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [extensionData, setExtensionData] = useState([]);
  const [queueMembers, setQueueMembers] = useState([]);
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const [phoneDetail, setPhoneDetail] = useState({
    name: "",
    call_time_limit: "",
    strategy: "",
    voicemail_id: "",
    hold_music_id: null,
  });
  const addQueue = useSelector((item) => item.phoneConfiguration.PhoneQueues.addQueue);
  const extensionsList = useSelector((item) => item.phoneConfiguration.PhoneExtensions.extensionsList);

  const handleUpdateDetail = (field, value, field1, value1) => {
    if (field1) {
      setPhoneDetail({ ...phoneDetail, [field]: value, [field1]: value1 });
    } else {
      setPhoneDetail({ ...phoneDetail, [field]: value });
    }
    setErrMsg({});
  };

  useEffect(() => {
    if (extensionData.length === 0 && extensionsList?.data?.length) {
      let new_extensionData = extensionsList?.data.filter((item, index) => extensionsList?.data.findIndex((it) => it.id === item.id) == index);
      setExtensionData(new_extensionData);
    }
  }, [extensionsList?.data]);

  useEffect(() => {
    if (addQueue.success && !addQueue.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully create the queue",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setPhoneDetail({
        name: "",
        call_time_limit: "",
        strategy: "",
        voicemail_id: "",
        hold_music_id: null,
      });
    } else if (!addQueue.isLoading && addQueue.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addQueue.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addQueue.success]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!phoneDetail.name) {
      errorMsg["name"] = "Please enter the queue name ";
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the queue name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!phoneDetail.strategy) {
      isFormValid = false;
      errorMsg["strategy"] = "Please select the strategy";
      setErrorAlert({
        errorMsg: "Please select the strategy",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!phoneDetail.call_time_limit) {
      isFormValid = false;
      errorMsg["call_time_limit"] = "Please select the call time limit";
      setErrorAlert({
        errorMsg: "Please select the call time limit",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!phoneDetail.voicemail_id) {
      isFormValid = false;
      errorMsg["voicemail_id"] = "Please select the voicemail";
      setErrorAlert({
        errorMsg: "Please select the voicemail",
        errorType: "warning",
        isOpen: true,
      });
    }

    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSaveQueue = () => {
    if (handleValidate()) {
      let newPhoneDetail = { ...phoneDetail };
      newPhoneDetail.members = queueMembers.length ? queueMembers.map((item) => item.id) : [];
      dispatch(addPhoneQueue(newPhoneDetail));
    }
  };
  const handleDragItem = (e, data) => {
    e.dataTransfer.setData("member", JSON.stringify(data));
  };
  const handleDragItemQueueToUsers = (e, data) => {
    e.dataTransfer.setData("user", JSON.stringify(data));
  };

  const handleAllowDrop = (e) => {
    e.preventDefault();
  };

  const handleDropQueueMembers = (ev) => {
    ev.preventDefault();
    let data = ev.dataTransfer.getData("member") ? JSON.parse(ev.dataTransfer.getData("member")) : {};
    if (!queueMembers.find((item) => item.id === data.id) && data.id) {
      let newUserData = [...extensionData];
      let index = newUserData.findIndex((item) => item.id === data.id);
      newUserData.splice(index, 1);
      setExtensionData(newUserData);
      let newMemberData = [...queueMembers];
      newMemberData.unshift(data);
      setQueueMembers(newMemberData);
    } else {
      let data1 = ev.dataTransfer.getData("user") ? JSON.parse(ev.dataTransfer.getData("user")) : {};
      let newMemberData = [...queueMembers];
      let targetIndex = newMemberData.findIndex(
        (item) =>
          item.first_name + " " + item.last_name === ev.target.outerText ||
          item.name === ev.target.outerText ||
          item.twilio_number?.number === ev.target.outerText
      );
      if (targetIndex && data1.id) {
        let initialIndex = newMemberData.findIndex((item) => item.id === data1.id);
        newMemberData.splice(initialIndex, 1);
        newMemberData.splice(targetIndex + 1, 0, data1);
        // newMemberData.unshift(data);
        setQueueMembers(newMemberData);
      }
    }
  };
  const handleDropQueueUsers = (ev) => {
    ev.preventDefault();
    let data = ev.dataTransfer.getData("user") ? JSON.parse(ev.dataTransfer.getData("user")) : {};
    if (!extensionData.find((item) => item.id === data.id) && data.id) {
      let newUserData = [...extensionData];
      newUserData.unshift(data);
      setExtensionData(newUserData);
      let newMemberData = [...queueMembers];
      let index = newMemberData.findIndex((item) => item.id === data.id);
      newMemberData.splice(index, 1);
      setQueueMembers(newMemberData);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.AddPhoneHeading} sx={{ mt: "10px", mb: "15px" }} id="alert-dialog-title">
          <Box> Create Queue</Box>
        </DialogTitle>
        <DialogContent sx={{ width: "800px", overflow: "visible" }}>
          <Grid container direction="row" spacing={4} sx={{ overflow: "visible" }}>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Queue
              </InputLabel>
              <TextField
                value={phoneDetail.name}
                onChange={(e) => handleUpdateDetail("name", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Name the Queue"}
                variant="standard"
                size="small"
                error={errMsg.name}
                helperText={errMsg.name}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Strategy
              </InputLabel>
              <TextField
                value={phoneDetail.strategy}
                onChange={(e) => handleUpdateDetail("strategy", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                variant="standard"
                size="small"
                select
                error={errMsg.strategy}
                id="user-phone"
                helperText={errMsg.strategy}
              >
                {[
                  { title: "Sequential", value: "Sequential" },
                  { title: "Ringall", value: "ring all" },
                ].map((item) => (
                  <MenuItem value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Timeout
              </InputLabel>
              <TextField
                value={phoneDetail.call_time_limit}
                onChange={(e) => handleUpdateDetail("call_time_limit", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                variant="standard"
                size="small"
                select
                error={errMsg.call_time_limit}
                id="user-phone"
                helperText={errMsg.call_time_limit}
              >
                {[
                  { title: "20 Seconds", value: 20 },
                  { title: "30 Seconds", value: 30 },
                  { title: "45 Seconds", value: 45 },
                  { title: "60 Seconds", value: 60 },
                  { title: "75 Seconds", value: 75 },
                  { title: "90 Seconds", value: 90 },
                  { title: "2 Minutes", value: 120 },
                ].map((item) => (
                  <MenuItem value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Voicemail Greeting
              </InputLabel>
              <TextField
                value={phoneDetail.voicemail_id}
                onChange={(e) => handleUpdateDetail("voicemail_id", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                variant="standard"
                size="small"
                select
                error={errMsg.voicemail_id}
                id="user-phone"
                helperText={errMsg.voicemail_id}
              >
                {audioList.map((item) => (
                  <MenuItem value={item.id}>{item.file_name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                All Extensions
              </Typography>
              <Card
                onDragOver={(e) => handleAllowDrop(e)}
                onDrop={(e) => handleDropQueueUsers(e)}
                classes={{ root: classes.mainListArea }}
                sx={{ p: "8px" }}
              >
                {extensionsList.isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Loading extensions...</Box>
                ) : (
                  ""
                )}
                {extensionData?.length
                  ? extensionData.map((item, index) => (
                      <Card
                        key={index}
                        id="user-block"
                        draggable="true"
                        onDragStart={(e) => handleDragItem(e, item)}
                        sx={{ mb: "5px", cursor: "grabbing" }}
                      >
                        <CardHeader
                          avatar={
                            <Avatar src={item?.users_v2?.profile_images?.profile_img} variant="rounded" aria-label="recipe">
                              {item.users_v2?.first_name?.slice(0, 1)}
                              {item.users_v2?.last_name?.slice(0, 1)}
                            </Avatar>
                          }
                          action={
                            <IconButton aria-label="settings">
                              <DragIndicatorIcon />
                            </IconButton>
                          }
                          title={item.users_v2?.first_name + " " + item.users_v2?.last_name}
                          subheader={item.name}
                        />
                      </Card>
                    ))
                  : ""}
              </Card>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Queue Members
              </Typography>
              <Card
                onDragOver={(e) => handleAllowDrop(e)}
                onDrop={(e) => handleDropQueueMembers(e)}
                classes={{ root: classes.mainListArea }}
                sx={{ p: "8px" }}
              >
                {queueMembers?.length
                  ? queueMembers.map((item, index) => (
                      <Card
                        id={`members-block_${index}`}
                        draggable="true"
                        onDragStart={(e) => handleDragItemQueueToUsers(e, item)}
                        sx={{ mb: "10px", cursor: "grabbing" }}
                        key={index}
                      >
                        <CardHeader
                          avatar={
                            <Avatar src={item?.users_v2?.profile_images?.profile_img} variant="rounded" aria-label="recipe">
                              {item.users_v2?.first_name?.slice(0, 1)}
                              {item.users_v2?.last_name?.slice(0, 1)}
                            </Avatar>
                          }
                          action={
                            <IconButton aria-label="settings">
                              <DragIndicatorIcon />
                            </IconButton>
                          }
                          title={item.users_v2?.first_name + " " + item.users_v2?.last_name}
                          subheader={item.name}
                        />
                      </Card>
                    ))
                  : ""}
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: "30px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSaveQueue()}
            classes={{ root: classes.AddPhoneButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addQueue.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddPhoneQueueStyle" })(AddPhoneQueue);
