import { Skeleton, Grid } from "@mui/material";
function CallStatsSkelton() {
  return (
    <Grid container spacing={2} sx={{ mt: "12px" }}>
      {Array.from(Array(6).keys()).map(() => (
        <Grid item sm={12} md={3} lg={2}>
          <Skeleton animation="wave" variant="rounded" width="100%" height="80px" />
        </Grid>
      ))}
    </Grid>
  );
}
export default CallStatsSkelton;
