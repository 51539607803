import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Stack,
  Box,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { addListing } from "../../../redux/listings/listings";
import { getMarkets } from "../../../redux/agents/addRecruitAgent";
import ButtonGroups from "../../../components/uiElements/buttonGroup";

const AddListing = (props) => {
  const { open, setOpen, classes } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [listingDetail, setListingDetail] = useState({
    street: "",
    city: "",
    state: "",
    zipcode: "",
    price: "",
    agent_id: "",
    is_rental: false,
    market_id: "",
  });

  const dispatch = useDispatch();
  const agentRosterList = useSelector((state) => state.users.storedListOfUsers);
  const addList = useSelector((state) => state.listings.ListingsData.addList);
  const markets = useSelector((state) => state.agentList.markets);
  useEffect(() => {
    if (!markets || markets.length === 0) {
      dispatch(getMarkets());
    }
  }, [markets, dispatch]);

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully added the listing",
      errorType: "success",
      isOpen: true,
    });
    setListingDetail({
      street: "",
      city: "",
      state: "",
      zipcode: "",
      price: "",
      agent_id: "",
      is_rental: false,
      market_id: "",
    });
    setOpen(false);
  };

  const handleValidate = () => {
    const errorMsg = {};
    const fields = [
      "agent_id",
      "market_id",
      "street",
      "city",
      "state",
      "zipcode",
      "price",
    ];
    let isFormValid = true;

    fields.forEach((field) => {
      if (!listingDetail[field]) {
        isFormValid = false;
        errorMsg[field] = `Please enter ${field.replace("_", " ")}`;
      }
    });

    setErrMsg(errorMsg);

    if (!isFormValid) {
      const firstErrorKey = Object.keys(errorMsg)[0];
      setErrorAlert({
        errorMsg: errorMsg[firstErrorKey],
        errorType: "warning",
        isOpen: true,
      });
    }

    return isFormValid;
  };

  const handleAddListing = () => {
    if (handleValidate()) {
      dispatch(
        addListing({ schema: listingDetail, handleSuccess, handleError })
      );
    }
  };

  const handleUpdateValue = (type, value) => {
    setListingDetail((prevDetail) => ({
      ...prevDetail,
      [type]: value,
    }));
    setErrMsg({});
  };

  const handleClose = () => {
    setListingDetail({
      street: "",
      city: "",
      state: "",
      zipcode: "",
      price: "",
      agent_id: "",
      is_rental: false,
      market_id: "",
    });
    setOpen(false);
  };

  return (
    <>
      <Dialog
        disablePortal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogTitle
          className={classes.CloseContractHeading}
          id="alert-dialog-titlesd"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box> Create Listing</Box>
            <Box className={classes.marketSection}>
              <Box sx={{ mt: "10px" }}>
                <ButtonGroups
                  options={
                    markets?.length
                      ? markets
                          ?.filter((it) => it.name !== "Default")
                          ?.map((item) => ({
                            title: item.name,
                            value: item.id,
                          }))
                      : []
                  }
                  value={listingDetail.market_id}
                  onChangeMenu={(value) =>
                    handleUpdateValue("market_id", value)
                  }
                  id="category-button-menu"
                  bgColor="#0B0909"
                  bgColorHover="#0B0909"
                  placement="bottom"
                  color="white"
                  placeholder="Market"
                  {...props}
                />
              </Box>
              <TextField
                onChange={(e) => handleUpdateValue("agent_id", e.target.value)}
                select
                variant="standard"
                label="Select agent"
                sx={{ minWidth: "240px" }}
                value={listingDetail.agent_id}
              >
                {agentRosterList?.list
                  ?.filter((it) => it.is_agent)
                  ?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.first_name} {item.last_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ width: "800px" }}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={4}>
              <TextField
                value={listingDetail.street}
                onChange={(e) => handleUpdateValue("street", e.target.value)}
                fullWidth
                required
                variant="standard"
                placeholder="123 Main Street"
                helperText="Property Address"
                error={!!errMsg["street"]}
                id="street"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={listingDetail.city}
                onChange={(e) => handleUpdateValue("city", e.target.value)}
                fullWidth
                variant="standard"
                placeholder="Atlanta"
                helperText="City"
                error={!!errMsg["city"]}
                id="city"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                value={listingDetail.state}
                onChange={(e) => handleUpdateValue("state", e.target.value)}
                fullWidth
                variant="standard"
                placeholder="GA"
                helperText="State"
                error={!!errMsg["state"]}
                id="state"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                value={listingDetail.zipcode}
                onChange={(e) => handleUpdateValue("zipcode", e.target.value)}
                fullWidth
                variant="standard"
                placeholder="30082"
                helperText="Zip"
                error={!!errMsg["zipcode"]}
                id="zipcode"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={listingDetail.price}
                onChange={(e) => handleUpdateValue("price", e.target.value)}
                fullWidth
                variant="standard"
                type="number"
                placeholder="$15.22"
                helperText="List Price"
                error={!!errMsg["price"]}
                id="price"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) =>
                      handleUpdateValue("is_rental", e.target.checked)
                    }
                    checked={listingDetail.is_rental}
                    color="success"
                  />
                }
                label="Rental Listing"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={handleClose}
            sx={{ color: "#000000" }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={addList.isLoading}
            className={classes.saveModalButton}
            color="inherit"
            onClick={handleAddListing}
            autoFocus
            loadingPosition="start"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </>
  );
};

export default withStyles(styleSheet, { name: "AddListingStyle" })(AddListing);
