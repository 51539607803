export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "20px",
    marginBottom: "16px",
  },
  customTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
  },
  TransactionUserInfoLeftPanel: {
    display: "flex",
    "& .MuiAvatar-root": {
      position: "relative",
      width: "260px",
      height: "210px",
      marginRight: "30px",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
    "& img":{
      objectFit:"contain !important"
    }
  },
  TransactionUserInfoLeftName: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  userInfoDetailPhone: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  userInfoDetailEmail: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "lowercase !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  TransactionUserInfoDetailStateDes: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign:'center'
  },
  TransactionUserInfoDetailStateValue: {
    fontSize: "20px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
    position:"relative !important"
  },
  customerTeamChip: {
    width: "176px",
    marginTop: "10px",
    background: "rgba(102, 187, 106, 0.5) !important",
    color: "black !important",
    height: "34px !important",
    fontSize: "14px !important",
    marginRight: "15px !important",
  },
  userInfoRightPanel: {
    textAlign: "right",
    "& .MuiButton-root": {
      width: "40px!important",
      minWidth: "40px!important",
      height: "40px",
      backgroundColor: "white",
      color: "black",
      marginRight: "10px",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid rgba(33, 33, 33, 0.4) !important",
    },
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  editMLlIcon:{
    position: "absolute !important",
    right:"-24px !important",
    top:"-25px !important",
    border:"1px solid lightgrey !important",
    padding:"2px !important"
  }
});
