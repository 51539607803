import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CircularProgress, Grid, TextField, Typography } from "@mui/material";

import { getAllLockboxes } from "../../../../../redux/listings/listingDetail/getAllLockboxes";
import LockBox from "./LockBox";
import { debounce } from "lodash";
import Clear from "@mui/icons-material/Clear";
import { getAllLockboxesPagination } from "../../../../../redux/listings/listingDetail/getAllLockboxesPagination";
import { Box } from "@mui/system";

export default function AttachDialog(props) {
  const dispatch = useDispatch();
  const listInnerRef = React.useRef();

  const { open, handleClose, handleSelect, classes } = props;
  const [pageNumber, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [searchText, setSearchText] = React.useState("");

  const allLockBoxes = useSelector((state) => state.listings.listingDetail.lockboxes.data);
  const allLockBoxesCount = useSelector((state) => state.listings.listingDetail.lockboxes.count);
  const allLockBoxesLoading = useSelector((state) => state.listings.listingDetail.lockboxes.isLoading);
  const allLockBoxesPaginationLoading = useSelector((state) => state.listings.listingDetail.lockboxes.isPaginationLoading);

  const debouncedSearch = React.useRef(
    debounce(async (search) => {
      setPageNo(1);
      dispatch(getAllLockboxes({ pageNumber: 1, pageSize, search }));
    }, 1000)
  ).current;

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
    debouncedSearch(e.target.value);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight - 100 === scrollHeight - 100) {
        if (allLockBoxes.length < allLockBoxesCount) {
          let updatedPageNo = pageNumber + 1;
          setPageNo(updatedPageNo);
          dispatch(getAllLockboxesPagination({ pageNumber: updatedPageNo, pageSize, search: searchText }));
        }
      }
    }
  };

  React.useEffect(() => {
    if (searchText.length === 0) dispatch(getAllLockboxes({ pageNumber, pageSize }));
  }, [searchText]);

  return (
    <Dialog
      disablePortal
      sx={{position:"absolute","& .MuiDialog-container":{height:"90vh",mt:"0px"}}}  
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        Attach Lockbox
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography variant="span" className={classes.subtitle}>
          Search
        </Typography>
        <TextField
          fullWidth
          label="Enter Lockbox Serial Number"
          onChange={onChangeSearchText}
          value={searchText}
          InputProps={{
            endAdornment:
              searchText.length !== 0 ? (
                <Button className={classes.clearButton} onClick={() => setSearchText("")}>
                  <Clear className={classes.clearButton} />
                </Button>
              ) : null,
          }}
        />

        <Typography sx={{ marginTop: "20px" }} className={classes.subtitle}>
          Lockboxes
        </Typography>

        <Card className={classes.cardsContainer} onScroll={() => onScroll()} ref={listInnerRef}>
          {allLockBoxesLoading && (
            <Box sx={{ margin: "20px 0px", justifyContent: "center", display: "flex" }}>
              <CircularProgress size={"20px"} />
            </Box>
          )}

          <Grid container display="flex" gap="15px">
            {!allLockBoxesLoading &&
              allLockBoxes.map((lockboxData) => (
                <Grid item xs={5.7}>
                  <LockBox classes={classes} lockboxData={lockboxData} handleSelect={handleSelect} />
                </Grid>
              ))}
          </Grid>

          {allLockBoxesPaginationLoading && (
            <Box sx={{ margin: "20px 0px", justifyContent: "center", display: "flex" }}>
              <CircularProgress size={"20px"} />
            </Box>
          )}
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
