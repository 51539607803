import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
function ShortCutBar(props) {
  let { classes, handleScrollToId, activeTab } = props;

  return (
    <Fragment>
      <Box
        sx={{
          position: activeTab === "section1" ? "static" : "sticky",
          top: "-20",
          backgroundColor:
            activeTab === "section1" ? "transparent" : "lightgrey",
          zIndex: 1,
        }}
        className={classes.shortCutBarContainer}
      >
        <Box className={classes.shortCutBarArea}>
          <Typography
            variant="subtitle2"
            display="block"
            gutterBottom
            className={classes.shortCutBarAreaItem}
            onClick={() => handleScrollToId("section1")}
            sx={{
              borderBottom:
                activeTab === "section1"
                  ? "3px solid #0064e5"
                  : "3px solid white",
            }}
          >
            Overview
          </Typography>
          <Typography
            variant="subtitle2"
            display="block"
            gutterBottom
            className={classes.shortCutBarAreaItem}
            onClick={() => handleScrollToId("section2")}
            sx={{
              borderBottom:
                activeTab === "section2"
                  ? "3px solid #0064e5"
                  : "3px solid white",
            }}
          >
            Location
          </Typography>
          <Typography
            variant="subtitle2"
            display="block"
            gutterBottom
            className={classes.shortCutBarAreaItem}
            onClick={() => handleScrollToId("section3")}
            sx={{
              borderBottom:
                activeTab === "section3"
                  ? "3px solid #0064e5"
                  : "3px solid white",
            }}
          >
            Building information
          </Typography>
          <Typography
            variant="subtitle2"
            display="block"
            gutterBottom
            className={classes.shortCutBarAreaItem}
            onClick={() => handleScrollToId("section4")}
            sx={{
              borderBottom:
                activeTab === "section4"
                  ? "3px solid #0064e5"
                  : "3px solid white",
            }}
          >
            Property Info
          </Typography>
          <Typography
            variant="subtitle2"
            display="block"
            gutterBottom
            className={classes.shortCutBarAreaItem}
            onClick={() => handleScrollToId("section5")}
            sx={{
              borderBottom:
                activeTab === "section5"
                  ? "3px solid #0064e5"
                  : "3px solid white",
            }}
          >
            Public Records
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}
export default ShortCutBar;
