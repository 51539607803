import React from "react";

import { makeStyles } from "@mui/styles";
import { AvatarGroup, Box, Card, CardContent, Typography } from "@mui/material";

import LinearProgress from '@mui/material/LinearProgress';

import StatusComponent from "../StatusComponent";
import AvatarComponent from "../AvatarComponent";

import ClockIcon from '../../../assets/images/projecticons/clock.png';
import ErrorClockIcon from '../../../assets/images/projecticons/errorclock.png';
import AttachmentIcon from '../../../assets/images/projecticons/attachment.png';
import { deptColorsMapping, deptTextColorsMapping } from "../helpers";
import moment from "moment";

const colors = ['rgba(81, 172, 10, 0.96)', '#EF4E74B5', '#7AF0C6', '#F8D149', '#4E4BFA'];

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function LinearProgressWithLabel({ value }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '55%' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress 
            variant="determinate" 
            value={value}
            sx={{
                width: '100%',
                backgroundColor: '#ddd',
                borderRadius: '15px',
                height: '6px',
                '& .MuiLinearProgress-bar': {
                    backgroundColor: colors[getRandomInt(5)]
                }
            }}
        />
        </Box>
        <Box sx={{ minWidth: value> 99 ? 35 : value === 0 ? 20 : 25 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '0px!important',
        marginBottom: '10px',
        width: 'auto',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
    },
    cardContent: {
        margin: '0px!important',
        padding: '2px 5px 5px 6px!important',
    },
    cardTitle1: {
      fontSize: '14px!important',
      fontWeight: '500!important'
    },
    cardTitle2: {
        fontSize: '14px!important',
        fontWeight: '500!important'
    },
    details: {
        display: 'flex',
        margin: '5px 0px',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '5px',
        width: '250px'
    },
    innerDetails: {
        display: 'flex',
        gap: '6px',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    innerDetailsSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '3px',
        flexWrap: 'wrap',  
        fontSize: '14px !important'
    },
    agent: {
        fontSize: '9px!important',
        fontWeight: '700!important',
        padding: '0!important',
        margin: '0!important',
        fontFamily: 'AvenirNext !important',
    },
    created: {
        fontSize: '9px!important',
        fontWeight: '400!important',
        padding: '0!important',
        margin: '0!important',
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'AvenirNext !important',
    }
}));

const ProjectCard = ({projectDetails, handleOpenProject}) => {
    const classes = useStyles();
    const [currentProject, setCurrentProject] = React.useState(projectDetails);

    React.useEffect(() => {
        setCurrentProject(projectDetails);
    }, [projectDetails]);

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent onClick={() => handleOpenProject(currentProject.id)} className={classes.cardContent}>
                <Box 
                    sx={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                    }}
                >
                    <Box 
                        sx={{
                            width: '48%',
                            borderRadius: '2px',
                            height: '12px',
                            padding: '0px 5px',
                            backgroundColor: deptColorsMapping(currentProject.department)
                        }}
                    >
                        <Typography sx={{ color: deptTextColorsMapping(currentProject.department), fontSize: '8px', fontWeight: '600' }}>{currentProject.department}</Typography>
                    </Box>
                
                    <LinearProgressWithLabel value={currentProject.progress.percentage} />
                </Box>
                <Typography  className={classes.cardTitle1}>
                    {currentProject.project.name}
                </Typography>

                <Typography className={classes.cardTitle2}>
                    {currentProject.project.address === 'undefined '? '' : currentProject.project.address || ''}
                </Typography>

                <Box className={classes.details}>
                    <StatusComponent sx={{ height: '25px', width: 'auto' }} status={currentProject.status} />

                    <Box className={classes.innerDetails}>
                        <Typography sx={{ color: currentProject.dueIn && currentProject.dueIn[0] === '-'? '#C62828' : '#000' }} className={classes.innerDetailsSection}>
                            {currentProject.dueIn && currentProject.dueIn[0] === '-'? <img width={14} height={14} src={ErrorClockIcon} /> : <img width={14} height={14}  src={ClockIcon} />}
                            
                            {currentProject.dueIn}
                        </Typography>

                        <Typography className={classes.innerDetailsSection}>
                            <img width={10} height={14} src={AttachmentIcon} />
                            {currentProject.attachments.length}
                        </Typography>

                        <AvatarGroup
                            sx={{
                                width: currentProject.teamMembers.length > 3 ? 
                                    '57px !important' : currentProject.teamMembers.length === 3 ? '57px !important' :
                                    currentProject.teamMembers.length === 2 ? '42px !important' : currentProject.teamMembers.length === 1 ?
                                    '32px !important' : '0px !important',
                                "& .MuiAvatarGroup-root": {
                                    margin: "0px !important",
                                },
                                "& .MuiAvatarGroup-avatar": {
                                    margin: "0px !important",
                                    marginLeft: "-20px !important",
                                },
                                "& .MuiAvatar-circular": {
                                    margin: "-2px -10px!important",
                                    width: "30px",
                                    height: "30px",
                                    fontSize: "16px",
                                },
                            }}
                            max={3}
                        >
                        {currentProject.teamMembers.map((image) => (
                            <AvatarComponent 
                                src={image.src} 
                                title={image.title} 
                                nameInitial={image.title}
                                sx={{ marginLeft: '-5px', width: '30px', height: '30px' }}
                            />
                        ))}
                        </AvatarGroup>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px 0px 0px 6px' }}>
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center', width: '55%'  }}>
                        <AvatarComponent 
                            src={currentProject.agent.image} 
                            nameInitial={currentProject.agent.full_name}
                            sx={{ marginLeft: '-5px', width: '17px', height: '17px', borderRadius: '5px', fontSize: '9px', fontWeight: '500', backgroundColor: deptColorsMapping(currentProject.department) }}
                        />
                        <Typography className={classes.agent}>{currentProject.agent.full_name}</Typography>
                    </Box>
                    
                    <Box sx={{ width: '57%' }}>
                        <Typography className={classes.created}>
                            Created {moment(currentProject.created || new Date()).format("MM/DD/YY")} 
                            {" "} @ {" "} {moment(currentProject.created || new Date()).format("H:MMa")}
                        </Typography>
                    </Box>
                </Box>

            </CardContent>
        </Card>
    );
}

export default ProjectCard;