import { createSlice } from "@reduxjs/toolkit";
import { addVendor } from "./addVendor";
import { getAllVendors } from "./getAllVendors";

const initialState = {
  addVendorData: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  VendorsData: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const VendorsList = createSlice({
  name: "VendorsList",
  initialState,
  extraReducers: {
    [addVendor.pending]: (state) => {
      state.addVendorData.isLoading = true;
      state.addVendorData.errMsg = null;
      state.addVendorData.success = false;
    },
    [addVendor.fulfilled]: (state, action) => {
      state.addVendorData.success = true;
      state.addVendorData.isLoading = false;
      state.addVendorData.data = action.payload;
      let vendorD = state.VendorsData.data?.vendors ? [...state.VendorsData.data?.vendors] : [];
      vendorD.unshift(action.payload);
      state.VendorsData.data.vendors = vendorD;
    },
    [addVendor.rejected]: (state, action) => {
      state.addVendorData.isLoading = false;
      state.addVendorData.success = false;
      state.addVendorData.errMsg = action.payload;
    },
    [getAllVendors.pending]: (state) => {
      state.VendorsData.isLoading = true;
      state.VendorsData.errMsg = null;
      state.VendorsData.success = false;
    },
    [getAllVendors.fulfilled]: (state, action) => {
      state.VendorsData.success = true;
      state.VendorsData.isLoading = false;
      if (state.VendorsData.data?.vendors && action.meta?.arg?.pageNumber > 1) {
        state.VendorsData.data.vendors = [...state.VendorsData.data?.vendors, ...action.payload.vendors];
      } else {
        state.VendorsData.data = action.payload;
      }
    },
    [getAllVendors.rejected]: (state, action) => {
      state.VendorsData.isLoading = false;
      state.VendorsData.success = false;
      state.VendorsData.errMsg = action.payload;
    },
  },
});
export default VendorsList.reducer;
export * from "./addVendor";
export * from "./getAllVendors";
