import { createSlice } from "@reduxjs/toolkit";
import {getTextTemplates} from './getTextTemplates'

const initialState = {
  emailsList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const LeadTextTemplates = createSlice({
  name: "LeadTextTemplates",
  initialState,
  extraReducers: {
     [getTextTemplates.pending]: (state) => {
      state.emailsList.isLoading = true;
      state.emailsList.errMsg = null;
      state.emailsList.success = false;
    },
    [getTextTemplates.fulfilled]: (state, action) => {
      state.emailsList.success = true;
      state.emailsList.isLoading = false;
      if(state?.emailsList?.data?.textmessagetemplates && action.meta?.arg?.offset>0){
        state.emailsList.data.textmessagetemplates = [...state?.emailsList?.data?.textmessagetemplates,...action.payload.textmessagetemplates];
      }else{
        state.emailsList.data = action.payload;
      }
 
    },
    [getTextTemplates.rejected]: (state, action) => {
      state.emailsList.isLoading = false;
      state.emailsList.success = false;
      state.emailsList.errMsg = action.payload;
    },
  },
});
export default LeadTextTemplates.reducer;
export * from './getTextTemplates'
