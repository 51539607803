import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import setAuthToken from "../../utils/setAuthToken";
export const adminLogin = createAsyncThunk(
  "login/adminLogin",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/users/login`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.data?.data?.userDetails?.id) {
        localStorage.setItem("userId", resp.data?.data?.userDetails?.id);
      }
      let token = resp.data.data.token;
      if (token) {
        setAuthToken(token);
        localStorage.setItem("token", token);
      }
      return resp.data?.data?.userDetails;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
