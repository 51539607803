/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Alert, Box, Container, Grid, IconButton, Tooltip } from "@mui/material";
import { getComparablePdf } from "../../../redux/tools/cmas";
import { PuffLoader } from "react-spinners";

export default function Review() {
  const { pdfData, isPdfUpdated, pdfDataLoading, pdfDataErrorMessage } = useSelector((state) => state.allCmas);
  const params = useParams();
  const dispatch = useDispatch();
  const cmaId = params.id;

  const fetchPDF = () => {
    dispatch(getComparablePdf({ id: cmaId}))
  }

  React.useState(() => {
    if (cmaId && isPdfUpdated && !pdfDataLoading) {
      fetchPDF();
    }
  }, [isPdfUpdated, cmaId]);  
  
  return (
    <Container maxWidth="xl" sx={{ height: pdfData?.url ? "100%" :  "calc(100vh - 190px)" }}>
      <Grid container spacing={2} alignItems='center' mt={1} mb={4}>
        <Grid item md={12} sx={{zIndex:0}}>
          {pdfDataLoading && !pdfData?.url && isPdfUpdated && (
            <Box display={"flex"} alignItems={"center"} gap={"9px"}>
              <PuffLoader color="rgba(0, 0, 0, 0.5)" size={"25"} />Generating CMA PDF...
            </Box>
          )} 

          {!pdfDataLoading && !pdfData?.url && !isPdfUpdated && (
            <Alert sx={{ "& .MuiAlert-icon": { paddingTop: "9px" }, "& .MuiAlert-message": { padding: 0, display: "flex", alignItems: "center", gap: "8px" } }} severity="error">
              {pdfDataErrorMessage} 
              <Tooltip title="Refetch PDF">
                <IconButton onClick={fetchPDF}>
                  <RestartAltIcon /> 
                </IconButton>
              </Tooltip>
            </Alert>
          )}

          {!pdfDataLoading && pdfData?.url && !isPdfUpdated && (
            <iframe
              src={pdfData.url}
              width={"100%"}
              height={1000}
            ></iframe>
          )}          
        </Grid>
      </Grid>
    </Container>
  );
}