import React from "react";
import { Box, Modal, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
  cursor: "pointer",
  textAlign: "center",
  color: "rgb(0 0 0 / 80%)",
};

const styleHeaderName = {
  fontWeight: "bold",
  fontFamily: "sans-serif",
  textTransform: "uppercase",
  color: "rgb(0 0 0 / 80%)",
};

const hrStyle = {
  border: "1px solid rgb(0 0 0 / 80%)",
  margin: "10px",
};

const GlobalModal = ({ open, handleClose, backgroundColor, borderColor, boxShadow, modalWidht, children, headerName, showHeader }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disablePortal

    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: modalWidht ? modalWidht : 400,
          bgcolor: backgroundColor ? backgroundColor : "background.paper",
          border: borderColor ? borderColor : "2px solid #808080",
          boxShadow: boxShadow ? boxShadow : 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          {showHeader && (
            <>
              <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <Typography align="left" variant="h5" sx={styleHeaderName}>
                  {headerName ? headerName : ""}
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} spacing={2} sx={style} onClick={() => handleClose()}>
                <Close />
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={hrStyle}></Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default GlobalModal;
