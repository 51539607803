import Axios from "axios";

var token = localStorage.getItem("token");

if (token === null && token === undefined && token === "") {
  window.location.replace("/admin");
  localStorage.removeItem("token");
}
const instance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    /*  'Content-Type': 'application/json', */
    "Access-Control-Allow-Origin": "*",
  },
});
instance.interceptors.response.use(
  (response) => response,
  (error) => error
);
export default instance;
