import * as React from "react";
import { Card, Stack, Grid, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import AgentRecruitingTimeline from "../../../common/Timeline";
import { useSelector } from "react-redux";
function AgentDetailLoader(props) {
  let { disableStats, isLead } = props;
  const { actionState } = useSelector((item) => item.timelines);
  const { list: usersList } = useSelector(
    (item) => item.users.storedListOfUsers
  );
  const { agentProfile } = useSelector((item) => item.agentProfile);
  return (
    <Box>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid
          item
          md={3}
          sm={12}
          sx={{ maxWidth: isLead ? "625px !important" : "" }}
        >
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={45}
                    height={45}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={25}
                    width="60%"
                    style={{ marginBottom: 4 }}
                  />
                }
                subheader={
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="85%"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
            </Card>
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={20}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="80%"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
            </Card>
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={20}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="80%"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
            </Card>
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Skeleton
              animation="wave"
              height={40}
              width="50%"
              style={{ marginBottom: 4 }}
            />
            {Array.from(Array(12).keys()).map(() => (
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  sx={{ padding: "0px" }}
                  title={
                    <Skeleton
                      animation="wave"
                      height={30}
                      width="80%"
                      style={{ marginBottom: 4 }}
                    />
                  }
                  action={
                    <Skeleton
                      animation="wave"
                      sx={{ mt: "-4px" }}
                      height={50}
                      width="60px"
                    />
                  }
                />
              </Card>
            ))}
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100px"}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={33}
                    width="20px"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                {Array.from(Array(12).keys()).map(() => (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="45px"
                    style={{
                      marginBottom: 4,
                      marginLeft: "0px",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100px"}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={33}
                    width="20px"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                {Array.from(Array(12).keys()).map(() => (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="45px"
                    style={{
                      marginBottom: 4,
                      marginLeft: "0px",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Card>{" "}
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100px"}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={33}
                    width="20px"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                {Array.from(Array(12).keys()).map(() => (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="45px"
                    style={{
                      marginBottom: 4,
                      marginLeft: "0px",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Card>
        </Grid>

        <Grid
          item
          md={6}
          sm={12}
          sx={{ maxWidth: isLead ? "1245px !important" : "" }}
        >
          <Card variant="outlined" sx={{ borderRadius: "10px" }}>
            <Box sx={{ padding: "5px 15px 0px 15px" }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                <Skeleton animation="wave" height={60} width="100px" />
                <Skeleton animation="wave" height={60} width="100px" />
                <Skeleton animation="wave" height={60} width="100px" />
                <Skeleton animation="wave" height={60} width="100px" />
              </Stack>
            </Box>{" "}
            <Divider />
            <Box sx={{ padding: "15px", mt: "-50px", mb: "-50px" }}>
              <Skeleton animation="wave" height={300} width="100%" />
            </Box>
            <Stack
              direction={"row"}
              sx={{ pr: "15px" }}
              spacing={2}
              justifyContent="flex-end"
              flexWrap="wrap"
            >
              <Skeleton animation="wave" height={60} width="100px" />
            </Stack>
          </Card>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="flex-between"
            flexWrap="wrap"
          >
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              <Skeleton animation="wave" height={60} width="160px" />
              <Skeleton animation="wave" height={60} width="100px" />
            </Stack>
            <Stack direction={"row"} spacing={0} justifyContent="flex-end">
              <Skeleton animation="wave" height={60} width="160px" />
            </Stack>
          </Stack>
          {actionState.firstTimeLoading ? (
            <Box sx={{ mt: "-50px", mb: "-50px" }}>
              <Skeleton animation="wave" height={300} width="100%" />
            </Box>
          ) : (
            <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
              <AgentRecruitingTimeline
                usersList={usersList}
                agentProfile={agentProfile}
              />
            </Paper>
          )}
        </Grid>
        <Grid
          item
          md={3}
          sm={12}
          sx={{ maxWidth: isLead ? "625px !important" : "" }}
        >
          <Card
            hidden={disableStats}
            variant="outlined"
            sx={{ padding: "15px", borderRadius: "10px" }}
          >
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <center>
                  <Skeleton animation="wave" width={"80%"} height={20} />
                  <Skeleton animation="wave" width={"100%"} height={25} />
                  <Skeleton animation="wave" width={"65%"} height={20} />
                </center>
              </Grid>
              <Grid item sm={4}>
                <center>
                  <Skeleton animation="wave" width={"80%"} height={20} />
                  <Skeleton animation="wave" width={"100%"} height={25} />
                  <Skeleton animation="wave" width={"65%"} height={20} />
                </center>
              </Grid>
              <Grid item sm={4}>
                <center>
                  <Skeleton animation="wave" width={"80%"} height={20} />
                  <Skeleton animation="wave" width={"100%"} height={25} />
                  <Skeleton animation="wave" width={"65%"} height={20} />
                </center>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: "15px" }}>
              <Grid item sm={4}>
                <center>
                  <Skeleton animation="wave" width={"80%"} height={20} />
                  <Skeleton animation="wave" width={"100%"} height={25} />
                  <Skeleton animation="wave" width={"65%"} height={20} />
                </center>
              </Grid>
              <Grid item sm={4}>
                <center>
                  <Skeleton animation="wave" width={"80%"} height={20} />
                  <Skeleton animation="wave" width={"100%"} height={25} />
                  <Skeleton animation="wave" width={"65%"} height={20} />
                </center>
              </Grid>
              <Grid item sm={4}>
                <center>
                  <Skeleton animation="wave" width={"80%"} height={20} />
                  <Skeleton animation="wave" width={"100%"} height={25} />
                  <Skeleton animation="wave" width={"65%"} height={20} />
                </center>
              </Grid>
            </Grid>
            <Box sx={{ mt: "-40px", mb: "-40px" }}>
              <Skeleton animation="wave" width={"100%"} height={380} />
            </Box>
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100px"}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={33}
                    width="20px"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                {Array.from(Array(12).keys()).map(() => (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="45px"
                    style={{
                      marginBottom: 4,
                      marginLeft: "0px",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100px"}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={33}
                    width="20px"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
              <Stack>
                {Array.from(Array(4).keys()).map(() => (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="100%"
                    style={{
                      marginBottom: 4,
                      marginLeft: "0px",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100px"}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={33}
                    width="20px"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                {Array.from(Array(12).keys()).map(() => (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="45px"
                    style={{
                      marginBottom: 4,
                      marginLeft: "0px",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Card>
          <Card
            variant="outlined"
            sx={{ padding: "15px", mb: "10px", borderRadius: "10px" }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardHeader
                sx={{ padding: "5px" }}
                avatar={
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100px"}
                    height={20}
                  />
                }
                title={
                  <Skeleton
                    animation="wave"
                    height={33}
                    width="20px"
                    style={{ marginBottom: 4 }}
                  />
                }
              />
              <Stack>
                {Array.from(Array(4).keys()).map(() => (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="100%"
                    style={{
                      marginBottom: 4,
                      marginLeft: "0px",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
export default AgentDetailLoader;
