import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  reviews: [
    { id: null, first_name: "", last_name: "", review: "" },
    { id: null, first_name: "", last_name: "", review: "" },
    { id: null, first_name: "", last_name: "", review: "" },
  ],
  errMsg: null,
};

export const addReview = createAsyncThunk(
  "agentReviews/addReview",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/users/reviews/${data.id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteReview = createAsyncThunk(
  "agentReviews/deleteReview",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/users/reviews/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateReview = createAsyncThunk(
  "agentReviews/updateReview",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/users/reviews/${data.id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getReviews = createAsyncThunk(
  "agentReviews/getReviews",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/users/reviews/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const agentReviews = createSlice({
  name: "agentReviews",
  initialState,
  reducers: {
  },
  extraReducers: {
    [getReviews.pending]: (state) => {
      state.loading = true;
    },
    [getReviews.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviews = action.payload.reviews;
    },
    [getReviews.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});
export const {} = agentReviews.actions;
export default agentReviews.reducer;
