export const styleSheet = () => ({
mColor: {
  backgroundColor:"#fff !important",
  "&:hover": {
    color: "#000000",
    backgroundColor: "#fff",
},
},
mBorder:{
  borderRadius: "4px",
  border:"1px solid #D5D9E2",
  padding:"10px",
},
mDisplay:{
  display:"flex",
  alignItems:"flex-end",
},
mStyle:{
"& .MuiFilledInput-root" :{
  backgroundColor:"#fff !important",
  "&:hover": {
    color: "#000000",
    backgroundColor: "#fff",
},
},
},
mButton:{
  textTransform: "uppercase !important",
  color: "#000000 !important",
  border:"1px solid #000000 !important",
  borderRadius:"4px !important",
  marginLeft:"10px !important",
  minWidth:"70% !important",
},
myButton:{
  textTransform: "capitalize !important",
  color: "#0B0909 !important",
  border: "1px solid #000000 !important",
  borderRadius:"2px !important",
  marginRight:"20px !important",
},
mJustifyContent:{
  display:"flex !important",
  justifyContent:"flex-end !important",
},
mCreateButton:{
  color: "#fff !important",
  borderRadius:"4px !important",
  backgroundColor:"#000000 !important",
},
mCancel:{
  color: "#000000 !important",
},
mDisplayFlex:{
  display:"flex",
},
mDisplayJustifyContent:{
  display:"flex",
  justifyContent:"space-between",
},
mDisplayFlexStart:{
  alignItems:"flex-start !important",
},
mPadding:{
  padding: "0 !important",
},
mImgPadding:{
  paddingRight: "8px !important",
},
mIconStyle:{
  display: "block !important",
  margin: "auto !important",
  color: "#4CAF50 !important",
},
mMargin:{
  margin: "0 !important",
},
myColor:{
  color: "#4CAF50 !important",
},
mCardBorder:{
  border: "2px solid #0F8A05"
}
});








// .flex_row {
//     display: flex;
// }
// input:focus-visible {
//     outline: none;
// }
// .align-center {
//     align-items: center;
// }
// .docs_row {
//     justify-content: space-between;

//     .inputs_with_folder_row {
//         .source_file {
//             background: #0b0909;
//             box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
//                 0px 1px 5px rgba(0, 0, 0, 0.12);
//             padding: 4px 15px;
//             border-radius: 9px;
//             span {
//                 color: #ffffff;
//                 letter-spacing: 0.4px;
//                 text-transform: uppercase;
//                 font-weight: 500;
//                 font-size: 14px;
//                 line-height: 24px;
//                 margin-left: 10px;
//             }
//         }

//         .dash_row {
//             margin: 0 15px;
//             text-align: center;
//             img {
//                 vertical-align: middle;
//             }
//             h5 {
//                 margin: 0;
//                 font-weight: 400;
//                 font-size: 12px;
//                 line-height: 166%;
//                 letter-spacing: 0.4px;
//                 color: rgba(0, 0, 0, 0.87);
//             }
//             input {
//                 border: 1px solid rgba(0, 0, 0, 0.23);
//                 border-radius: 4px;
//                 letter-spacing: 0.15px;
//                 color: rgba(0, 0, 0, 0.6);
//                 padding-left: 10px;
//                 width: 313.86px;
//                 height: 40px;
//                 margin-left: 20px;
//             }
//             &:last-child {
//                 margin-right: 0px;
//             }
//         }
//     }
// }
// .docs_table_row {
//     margin-top: 30px;
//     padding: 10px;
//     background: #ffffff;
//     box-shadow: 0px 0px 0px 1px #e0e0e0;
//     border-radius: 4px;
//     table {
//         width: 100%;
//         border-collapse: collapse;
//         th {
//             text-align: left;
//             padding: 15px 0;
//             box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
//         }
//         td {
//             padding: 15px 0;
//             box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
//             span {
//                 border: 1px solid rgba(0, 0, 0, 0.26);
//                 border-radius: 16px;
//                 padding: 5px 10px;
//             }
//             img {
//                 vertical-align: middle;
//             }
//         }
//     }
// }


// // new css 

// .main-dashboard-page .css-r11z79-MuiDataGrid-root{
//     background: #FFFFFF;
// box-shadow: 0px 0px 0px 1px #E0E0E0;
// border-radius: 4px;
// margin: 0 50px;
// }
// .css-17jjc08-MuiDataGrid-footerContainer{
//     border-top: none;
// }
// .MuiBox-root .css-1q39md6-MuiButtonBase-root-MuiButton-root{
//     font-weight: 400;
// font-size: 12px;
// line-height: 166%;
// letter-spacing: 0.4px;
// color: rgba(0, 0, 0, 0.87);
// padding: 12px 25px;
// border-radius: 9px;
// margin:0;
// text-transform: capitalize;
// }
// .MuiBox-root .css-1q39md6-MuiButtonBase-root-MuiButton-root:hover{

//     background-color: #000;
//     color: #fff;
    
// }
// .docs_title{
// display: flex !important;
// align-items: center;

// font-size: 24px !important;
// line-height: 133.4%;
// color: rgba(0, 0, 0, 0.87);

// svg{
//     margin-right: 10px;
// }
// }
// header.css-hip9hq-MuiPaper-root-MuiAppBar-root{
//     background-color: #fff;
//     padding:5px 30px;
//     margin-bottom: 30px;

// }
// header.css-hip9hq-MuiPaper-root-MuiAppBar-root .css-19r6kue-MuiContainer-root {
//     display: grid;
//     grid-template-columns: 10% 90%;
//     align-items: center;
// }
// header.css-hip9hq-MuiPaper-root-MuiAppBar-root .MuiBox-root.css-1t6c9ts {
//     justify-content: flex-end;
//     align-items: center;
// }
// .css-r11z79-MuiDataGrid-root .MuiDataGrid-row:last-child * {
//     border: none;
// }
// .css-mhc70k-MuiGrid-root>.MuiGrid-item{
//     padding:0 !important;
// }
// .css-1v5u2yv{
//     padding-left: 0px !important;
//     padding-right: 0px !important;
// }
// header.css-hip9hq-MuiPaper-root-MuiAppBar-root .MuiBox-root.css-1t6c9ts svg {
//     display: block;
//     margin: auto;
// }
// .newfolder_button {
//     display: flex !important;
//     align-items: center !important;
//     height: 50px !important;
//     text-transform: uppercase !important;
//     svg{
//         margin-right: 10px !important;
//     }
// }
// .MuiDataGrid-cell svg path {
//     fill: rgba(0, 0, 0, 0.54);
// }
// .MuiDataGrid-row *{
//     font-size: 16px;
// line-height: 150%;
// letter-spacing: 0.15px;
// color: rgba(0, 0, 0, 0.87);
// }
// .css-r11z79-MuiDataGrid-root .MuiDataGrid-row *{
//     border: none !important;
// }
// .css-1lgiupi-JoyAvatar-root{
//     margin-right: 10px;
// }
// .MuiDataGrid-cell[data-field="lastName"] {
//     border: 1px solid rgba(0, 0, 0, 0.26) !important;
//     width: 50px !important;
//     height: 50px !important;
//     border-radius: 50px;
//     justify-content: center !important;
//     min-width: 100px !important;
//     min-height: 32px !important;
//     max-height: 32px !important;
//     margin-top: 10px !important;
//     margin-right: 20px !important;
// }
// .css-r11z79-MuiDataGrid-root .MuiDataGrid-row{
//     border-bottom: 1px solid rgba(0, 0, 0, 0.26) !important;
//     }
//     .css-r11z79-MuiDataGrid-root .MuiDataGrid-cell[data-field="firstName"]{
//         svg{
//             margin-right: 15px;
//         }

//     }
//     .css-r11z79-MuiDataGrid-root .css-1lgiupi-JoyAvatar-root{
//         border-radius: 5px !important;
//     }
//     .css-19r6kue-MuiContainer-root {
//         max-width: 100% !important;
//     }
//   .form_title{
//     font-weight: 500;
// font-size: 20px;
// line-height: 160%;
// letter-spacing: 0.15px;
// color: rgba(0, 0, 0, 0.87);
//   }


//   .grid_form button {
//     border: 1px solid rgba(33, 33, 33, 0.4);
// border-radius: 4px;
// color: #0B0909;
// letter-spacing: 0.4px;
// text-transform: uppercase;
// font-weight: 500;
// font-size: 14px;
// line-height: 24px;
// padding:10px 20px;
// }

// .grid_form .MuiGrid-root.css-vj1n65-MuiGrid-root {
//     display: grid;
//     grid-template-columns: 23% 5% 16% 20% 18%;
//     justify-content: space-between;
//     align-items: center;
//     padding-top: 20px;
// }
// .grid_form {
//     padding: 20px 20px;
//     border-radius: 4px;
//     box-shadow: 0px 0px 0px 1px #e0e0e0;
//     margin-bottom: 40px;
//     position: relative;
// }
// .street_address .MuiGrid-root.css-vj1n65-MuiGrid-root {
//     display: grid;
//     grid-template-columns: 20% 20% 20% 10% 20%;
//     justify-content: space-between;
// }
// .buyer_row .MuiGrid-root.css-vj1n65-MuiGrid-root {
//     display: grid;
//     grid-template-columns: 28% 10% 28% 28%;
//     justify-content: space-between;
//     padding-top: 20px;
// }
// .select_field .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
//     width: 135px;
//    height: auto;
//     .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{

//         border: 1px solid rgba(33, 33, 33, 0.4);
//         border-radius: 4px;
//         padding:0;
//         height: 50px;
//     }
// }
// .buyer_row {
//     padding: 25px 15px;
//     border-radius: 4px;
//     box-shadow: 0px 0px 0px 1px #e0e0e0;
//     margin-top: 40px;

// }
// button.add-party{
//     border: 1px solid rgba(33, 33, 33, 0.4);
//     border-radius: 4px;
//     width: 200px;
// height: 42px;
// color: #0B0909;
// letter-spacing: 0.4px;
// font-weight: 400;
// font-size: 12px;
//     float:right;
//     margin-top: 20px;
// }
// .add_form_btn.folder_create_btn {
//     display: flex;
//     justify-content: end;
//     width: 100%;
//     margin-top: 30px;
// }
// .add_form_btn.folder_create_btn {
//     display: flex;
//     justify-content: end;
//     width: 100%;
//     margin-top: 30px;
// }

// .folder_row input {
//     width: 100%;
// }

// .folder_row .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
//     display: block;
//     width: 100%;
// }

// .folder_row .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary{
//     width:100%;
// }
// .folder_create_btn button{
//     font-size:14px !important;
//     text-transform: uppercase !important;
// }
// .popmain {
//   align-items: center;
//     justify-content: space-between;
//     padding-bottom: 40px;
//     padding-left:20px;
// }
// .MuiGrid-root.MuiGrid-item.css-1osj8n2-MuiGrid-root .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {   
//     width: 100%;
// }


// .MuiGrid-root.MuiGrid-item.css-1osj8n2-MuiGrid-root .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1ptx2yq-MuiInputBase-root-MuiInput-root{
// width:100%;
// }
// .grid_form a {
//     position: absolute;
//     top: 30px;
//     right: 20px;
   
//     path{
//         fill:rgba(0, 0, 0, 0.54);
//     }
// }
// .agent_select .css-168pop2-MuiList-root {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     margin-top:30px;
// }

// .agent_select .css-168pop2-MuiList-root li {
//     flex: 0 0 49%;
//     box-shadow: 0px 0px 0px 1px #e0e0e0;
//     border-radius: 4px;
//     margin-bottom: 20px;
//     align-items: flex-start;
// }
// .agent_select h3{
//     color: rgba(0, 0, 0, 0.87);
//     letter-spacing: 0.15px;
//     font-weight: 500;
// font-size: 20px;
// text-overflow: inherit;
//     white-space: nowrap;

// }
// .agent_select span{
//     font-weight: 400;
// font-size: 16px;
// line-height: 150%;
// letter-spacing: 0.15px;
// color: rgba(0, 0, 0, 0.87);
// }
// .agent_select li .css-konndc-MuiListItemText-root{
// padding-left: 10px;
// }
// .agent_select li.selected_list{
//     border: 3px solid #0F8A05;
// }
// .agent_select{
//   width:100% !important;
//   margin:0 !important;
// }