import React, { useState, useEffect } from "react";
import { addFolder } from "../../../../redux/docs/addfolderapi";
import { useDispatch, useSelector } from "react-redux";
import GlobalModal from "../../../../components/globalModal";
import { makeStyles, withStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { styleSheet } from "./style.js";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { getProperties } from "../../../../redux/common/getProperties";
import ResponseAlert from "../../../../components/responseAlert";
import { getDocList } from "../../../../redux/docs/getDocList";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
const style = {
  position: "absolute",
  top: "100% !important",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 992,
  height: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));
const Placeholder = ({ children }) => {
  const styles = usePlaceholderStyles();
  return <div className={styles.placeholder}>{children}</div>;
};
const AddFolderModal = ({ open, classes, handleClose, modalWidht, agentData }) => {
  const dispatch = useDispatch();
  const data = useSelector((item) => item.users.storedListOfUsers);
  const Agent = data.list;
  const FilteredAgent = Agent.filter((item) => item.is_agent === true);

  const [state, setState] = useState({
    agetn: "",
    mlsNumber: "",
    st_no: "",
    st_name: "",
    streetSuffix: "",
    sideRepresented: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    buyer0FirstName: "",
    buyer0LastName: "",
    seller0FirstName: "",
    seller0LastName: "",
    buyer1FirstName: "",
    buyer1LastName: "",
    seller1FirstName: "",
    seller1LastName: "",
    buyer0Email: "",
    buyer1Email: "",
    seller0Email: "",
    seller1Email: "",
    buyer0Middle: "",
    buyer1Middle: "",
    seller0Middle: "",
    seller1Middle: "",
    folderName: "",
    agent_id:""
  });

  // console.log(state.buyer0FirstName);
  // console.log(state.buyer1FirstName);
  // console.log(state.seller0FirstName);
  // console.log(state.seller1FirstName);

  const [agentAllData, setAgentAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openerror, setOpenerror] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMesage] = useState();

  const [additionalParty, setAdditionalParty] = useState([{ id: 0 }]);
  const [searchResult, setSearchResult] = useState(false);
  const [selected, setSelected] = useState(false);
  const [indexValue, setIndexValue] = useState(null);
  const [type, setType] = useState([]);
  const [typeCount, setTypeCount] = useState({});
  let [count, setCount] = useState(1);
  const [propertyData, setPropertyData] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const handleAdditionalParty = () => {
    if (!(state.buyer0FirstName || state.seller0FirstName)) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter client first");
    } else {
      let counts = {};
      type.forEach((x) => {
        counts[x] = (counts[x] || 0) + 1;
      });
      setTypeCount(counts);
      setAdditionalParty([...additionalParty, { id: count }]);
      setCount(count + 1);
    }
  };

  const handleCancel = () => {
    handleClose();
    setCount(1);
    setSearchResult(false);
    setSelected(false);
  };

  const handleDelete = (index) => {
    let newType = [...type];
    let newAdditionalParty = [...additionalParty];
    newAdditionalParty.splice(index, 1);
    newType.splice(index, 1);
    setType(newType);
    setAdditionalParty(newAdditionalParty);
  };

  const handleSearch = () => {
    if (state.mlsNumber || (state.st_no && state.st_name)) {
      setSearchResult(true);
      const data = { id: state.mlsNumber, st_no: state.st_no, st_name: state.st_name?.toLowerCase() };
      dispatch(getProperties(data)).then((res) => {
        if (res?.payload?.data?.value.length > 0) {
          setPropertyData(res.payload.data.value);
        } else {
          setOpenerror(true);
          setAlertType("error");
          setAlertMesage("Property number not found");
          setIsLoading(false);
        }
      });
    }
  };

  const handleCard = (value, StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode) => {
    setIndexValue(value);
    setSelected(true);
    setState({ ...state, street: StreetNumber + " " + StreetName + " " + StreetSuffix, city: City, state: StateOrProvince, zip: PostalCode });
  };

  const handleSetType = (e, index) => {
    if (type[index] === "BUYER" && e.target.value === "SELLER") {
      type[index] = "SELLER";
    }
    if (type[index] === "SELLER" && e.target.value === "BUYER") {
      type[index] = "BUYER";
    }
    if (type[index] === undefined) {
      setType([...type, e.target.value]);
    }
  };
  const handleFirstName = (e, index) => {
    if (type[index] === "BUYER") {
      if (Object.keys(typeCount).length === 0 || typeCount.BUYER === undefined) {
        setState({ ...state, buyer0FirstName: e.target.value });
      } else {
        setState({ ...state, buyer1FirstName: e.target.value });
      }
    }
    if (type[index] === "SELLER") {
      if (Object.keys(typeCount).length === 0 || typeCount.SELLER === undefined) {
        setState({ ...state, seller0FirstName: e.target.value });
      } else {
        setState({ ...state, seller1FirstName: e.target.value });
      }
    }
  };
  const handleMiddleName = (e, index) => {
    if (type[index] === "BUYER") {
      if (Object.keys(typeCount).length === 0 || typeCount.BUYER === undefined) {
        setState({ ...state, buyer0Middle: e.target.value });
      } else {
        setState({ ...state, buyer1Middle: e.target.value });
      }
    }
    if (type[index] === "SELLER") {
      if (Object.keys(typeCount).length === 0 || typeCount.SELLER === undefined) {
        setState({ ...state, seller0Middle: e.target.value });
      } else {
        setState({ ...state, seller1Middle: e.target.value });
      }
    }
  };
  const handleLastName = (e, index) => {
    if (type[index] === "BUYER") {
      if (Object.keys(typeCount).length === 0 || typeCount.BUYER === undefined) {
        setState({ ...state, buyer0LastName: e.target.value });
      } else {
        setState({ ...state, buyer1LastName: e.target.value });
      }
    }
    if (type[index] === "SELLER") {
      if (Object.keys(typeCount).length === 0 || typeCount.SELLER === undefined) {
        setState({ ...state, seller0LastName: e.target.value });
      } else {
        setState({ ...state, seller1LastName: e.target.value });
      }
    }
  };
  const handleEmail = (e, index) => {
    if (type[index] === "BUYER") {
      if (Object.keys(typeCount).length === 0 || typeCount.BUYER === undefined) {
        setState({ ...state, buyer0Email: e.target.value });
      } else {
        setState({ ...state, buyer1Email: e.target.value });
      }
    }
    if (type[index] === "SELLER") {
      if (Object.keys(typeCount).length === 0 || typeCount.SELLER === undefined) {
        setState({ ...state, seller0Email: e.target.value });
      } else {
        setState({ ...state, seller1Email: e.target.value });
      }
    }
  };

  useEffect(() => {
    setAdditionalParty([{ id: 0 }]);
    setCount(1);
    setSearchResult(false);
    setSelected(false);
    setType([]);
    setTypeCount({});
    setDisabled(false);
    setState({
      agetn: "",
      mlsNumber: "",
      st_no: "",
      st_name: "",
      streetSuffix: "",
      sideRepresented: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      buyer0FirstName: "",
      buyer0LastName: "",
      seller0FirstName: "",
      seller0LastName: "",
      buyer1FirstName: "",
      buyer1LastName: "",
      seller1FirstName: "",
      seller1LastName: "",
      buyer0Email: "",
      buyer1Email: "",
      seller0Email: "",
      seller1Email: "",
      buyer0Middle: "",
      buyer1Middle: "",
      seller0Middle: "",
      seller1Middle: "",
      folderName: "",
      agent_id:""
    });
  }, []);

  // useEffect(() => {
  //   if (agentData?.length) {
  //     let temp = [];
  //     agentData.forEach((element) => {
  //       let details = { label: element.name, id: element.id };
  //       temp.push(details);
  //     });
  //     setAgentAllData(temp);
  //   }
  //   setAdditionalParty([1]);
  //   setCount(1);
  //   setSearchResult(false);
  //   setSelected(false);
  // }, [agentData]);

  // const handelAgentAutoComplete = (e, v) => {
  //   if (v) {
  //     setState({
  //       ...state,
  //       agetn: v.id,
  //     });
  //   }
  // };

  // const handelChange = (e) => {
  //   setState({
  //     ...state,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const handleCreate = async () => {
    setIsLoading(true);
    if (!state.agent_id) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please select agent");
      setIsLoading(false);
    }
    // else if (!state.mlsNumber) {
    //   setOpenerror(true);
    //   setAlertType("error");
    //   setAlertMesage("Please Enter MLS number");
    //   setIsLoading(false);
    // }
    // else if (!state.streetNo) {
    //   setOpenerror(true);
    //   setAlertType("error");
    //   setAlertMesage("Please Enter street no");
    //   setIsLoading(false);
    // }
    else if (!state.street) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter street name");
      setIsLoading(false);
    }
    // else if (!state.streetSuffix) {
    //   setOpenerror(true);
    //   setAlertType("error");
    //   setAlertMesage("Please Enter street suffix");
    //   setIsLoading(false);
    // }
    else if (!state.sideRepresented) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter street represent name");
      setIsLoading(false);
    } else if (!state.city) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter city");
      setIsLoading(false);
    } else if (!state.state) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter state");
      setIsLoading(false);
    } else if (!state.zip) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter zip");
      setIsLoading(false);
    } else if (!(state.buyer0FirstName || state.seller0FirstName)) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter first name");
      setIsLoading(false);
    } else if (!(state.buyer0LastName || state.seller0LastName)) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter last name");
      setIsLoading(false);
    } else if (!(state.buyer0Email || state.seller0Email)) {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please Enter email");
      setIsLoading(false);
    }
    // else if (!state.buyer0Middle) {
    //   setOpenerror(true);
    //   setAlertType("error");
    //   setAlertMesage("Please Enter buyer middle name");
    //   setIsLoading(false);
    // }
    // else if (!state.clientTwoFirstname) {
    //   setOpenerror(true);
    //   setAlertType("error");
    //   setAlertMesage("Please Enter client2 first name");
    //   setIsLoading(false);
    // } else if (!state.clientTwoLastname) {
    //   setOpenerror(true);
    //   setAlertType("error");
    //   setAlertMesage("Please Enter client2 last name");
    //   setIsLoading(false);
    // } else if (!state.clientTwoEmail) {
    //   setOpenerror(true);
    //   setAlertType("error");
    //   setAlertMesage("Please Enter client2 email");
    //   setIsLoading(false);
    // }
    else {
      setDisabled(true);
      let details = {
        // form_ids: [],
        name: state.folderName,
        buyer0_first_name: state.buyer0FirstName,
        buyer0_last_name: state.buyer0LastName,
        seller0_first_name: state.seller0FirstName,
        seller0_last_name: state.seller0LastName,
        buyer1_first_name: state.buyer1FirstName,
        buyer1_last_name: state.buyer1LastName,
        seller1_first_name: state.seller1FirstName,
        seller1_last_name: state.seller1LastName,
        buyer0_email: state.buyer0Email,
        buyer1_email: state.buyer1Email,
        seller0_email: state.seller0Email,
        seller1_email: state.seller1Email,
        buyer0_middle_initials: state.buyer0Middle,
        buyer1_middle_initials: state.buyer1Middle,
        seller0_middle_initials: state.seller0Middle,
        seller1_middle_initials: state.seller1Middle,
        agent_id:state.agent_id,
        listing_mls_id: state.mlsNumber,
        type: state.sideRepresented,
        property_street: state.street,
        property_city: state.city,
        property_state: state.state,
        property_zip_code: state.zip,
      };
      await dispatch(addFolder(details)).then(async (res) => {
        if (res?.payload?.status === 200) {
          setOpenerror(true);
          setAlertType("success");
          setAlertMesage("Folder added successfully");
          setIsLoading(false);
          dispatch(getDocList());
          setDisabled(false);
          await handleClose();
        } else {
          setOpenerror(true);
          setAlertType("error");
          setAlertMesage(res?.payload?.response?.data?.message);
          setIsLoading(false);
          setDisabled(false);
        }
      });
    }
  };
  return (
    <>
      <GlobalModal open={open} handleClose={handleClose} modalWidht={modalWidht} showHeader={false} headerName={`New Folder`}>
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={8} md={8}>
              <Typography variant="h6" noWrap component="h3" className="form_title">
                New Folder
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <InputLabel id="demo-simple-select-standard-label">Select Agent</InputLabel>
              <FormControl variant="filled" sx={{ m: 1, minWidth: "90%" }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  className={classes.mColor}
                  value={state.agent_id}
                  displayEmpty
                  onChange={(event) => setState({ ...state,agent_id:event.target.value })}
                  renderValue={state.agent_id !== "" ? undefined : () => <Placeholder>Start Typing</Placeholder>}
                >
                  {FilteredAgent.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.full_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={2} className={classes.mBorder} onClick={() => searchResult && setSearchResult(false)}>
            {/* <Link href="#">
            <KeyboardArrowDownIcon />
          </Link> */}
            <Grid item xs={12} md={12} className={classes.mDisplayFlex}>
              <Grid item xs={6} md={6}>
                <Typography variant="h6" noWrap component="h3">
                  Add Property From MLS
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} className={classes.mJustifyContent}>
                {searchResult ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Grid>
            </Grid>
            <Grid Container spacing={2} item xs={12} md={12} className={classes.mDisplay}>
              <Grid item xs={5} md={4}>
                <TextField
                  id="standard-basic"
                  label="MLS Number"
                  variant="filled"
                  sx={{ minWidth: "90%" }}
                  className={classes.mStyle}
                  value={state.mlsNumber}
                  onChange={(event) => setState({ ...state, mlsNumber: event.target.value })}
                />
              </Grid>
              <Grid item xs={2} md={1}>
                <Typography variant="h6" noWrap component="span">
                  Or
                </Typography>
              </Grid>
              <Grid item xs={5} md={3}>
                <TextField
                  id="standard-basic"
                  label="Street Number"
                  variant="filled"
                  sx={{ marginRight: "10px", maxWidth: "80%" }}
                  className={classes.mStyle}
                  value={state.st_no}
                  onChange={(event) => setState({ ...state, st_no: event.target.value })}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="standard-basic"
                  label="Street Name"
                  variant="filled"
                  className={classes.mStyle}
                  value={state.st_name}
                  onChange={(event) => setState({ ...state, st_name: event.target.value })}
                />
              </Grid>
              <Grid item xs={6} md={2} className={classes.mJustifyContent}>
                <Button className={classes.mButton} onClick={() => handleSearch()}>
                  Search
                </Button>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid Container spacing={2} item xs={12} md={12} className={classes.mDisplay}>
                {(searchResult || selected) &&
                  propertyData?.map((val, index) => (
                    <Grid item xs={12} md={6} sx={{ padding: "0 8px",cursor:"pointer" }}>
                      <Card
                        key={index}
                        onClick={(value, StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode) =>
                          handleCard(index, val.StreetNumber, val.StreetName, val.StreetSuffix, val.City, val.StateOrProvince, val.PostalCode)
                        }
                        className={selected && indexValue === index && classes.mCardBorder}
                      >
                        <CardContent className={classes.mPadding}>
                          <List>
                            <ListItem className={classes.mDisplayFlexStart}>
                              <img src={`https://d2bvtii6wos6sc.cloudfront.net/h_100/${encodeURIComponent(val.Media[0].MediaURL)}`} width={99} height={75} className={classes.mImgPadding} />
                              <ListItemText
                                primary={
                                  <p className={classes.mMargin}>
                                    {val.StreetNumber} {val.StreetName} {val.StreetSuffix} {val.City},{val.StateOrProvince} {val.PostalCode}
                                  </p>
                                }
                                secondary={
                                  <p className={classes.mMargin}>
                                    FMLS:{val.ListingId} Status:<span className={classes.myColor}>{val.MlsStatus}</span>
                                  </p>
                                }
                                sx={{ width: "min-content" }}
                              />
                              <Typography variant="h6" noWrap component="h3">
                                ${val.ListPrice}
                                {selected && indexValue === index && <CheckCircleOutlineOutlinedIcon className={classes.mIconStyle} />}
                              </Typography>
                            </ListItem>
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                {/*<ListItem className="selected_list">
                  <img src={house} />
                  <ListItemText
                    primary="1234 Reallylongname Rd Kennesaw, GA 30084"
                    secondary="FMLS: 7088834 Status: Active"
                  />
                  <Typography variant="h6" noWrap component="h3">
                    $469.6k
                  </Typography>
                </ListItem>
                <ListItem>
                  <img src={house} />
                  <ListItemText
                    primary="1234 Reallylongname Rd Kennesaw, GA 30084"
                    secondary="FMLS: 7088834 Status: Active"
                  />
                  <Typography variant="h6" noWrap component="h3">
                    {" "}
                    $469.6k
                  </Typography>
                </ListItem>
                <ListItem>
                  <img src={house} />
                  <ListItemText
                    primary="1234 Reallylongname Rd Kennesaw, GA 30084"
                    secondary="FMLS: 7088834 Status: Active"
                  />
                  <Typography variant="h6" noWrap component="h3">
                    $469.6k
                  </Typography>
                </ListItem>
              </List>*/}
              </Grid>
            </Box>
          </Box>
          <Box mt={4}>
            <Grid Container spacing={2} item xs={12} md={12} className={classes.mDisplay}>
              <Grid item xs={5} md={3}>
                <InputLabel id="demo-simple-select-standard-label">Side Represented</InputLabel>
                <FormControl variant="filled" sx={{ m: 1, minWidth: "90%" }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    className={classes.mColor}
                    value={state.sideRepresented}
                    displayEmpty
                    onChange={(event) => setState({ ...state, sideRepresented: event.target.value })}
                    renderValue={state.sideRepresented !== "" ? undefined : () => <Placeholder>Buyer/Seller/Both</Placeholder>}
                  >
                    <MenuItem value="buyer">Buyer</MenuItem>
                    <MenuItem value="seller">Seller</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={7} md={4}>
                <TextField
                  id="standard-basic"
                  label="Street Name"
                  variant="filled"
                  className={classes.mStyle}
                  sx={{ m: 1, minWidth: "90%" }}
                  value={state.street}
                  onChange={(event) => setState({ ...state, street: event.target.value })}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  id="standard-basic"
                  label="City"
                  variant="filled"
                  className={classes.mStyle}
                  sx={{ m: 1, minWidth: "90%" }}
                  value={state.city}
                  onChange={(event) => setState({ ...state, city: event.target.value })}
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <TextField
                  id="standard-basic"
                  label="ST"
                  variant="filled"
                  className={classes.mStyle}
                  sx={{ m: 1, minWidth: "90%" }}
                  value={state.state}
                  onChange={(event) => setState({ ...state, state: event.target.value })}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextField
                  id="standard-basic"
                  label="Zip Code"
                  variant="filled"
                  className={classes.mStyle}
                  sx={{ m: 1, minWidth: "90%" }}
                  value={state.zip}
                  onChange={(event) => setState({ ...state, zip: event.target.value })}
                />
              </Grid>
            </Grid>
          </Box>
          {additionalParty?.map((item, index) => (
            <Box mt={2} className={classes.mBorder}>
              <Grid key={item.id} item xs={12} md={12} className={classes.mDisplayJustifyContent}>
                <FormControl>
                  {/* <InputLabel id="demo-simple-select-label">Buyer 1</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id={index}
                    value={type[index]}
                    displayEmpty
                    onChange={(e) => handleSetType(e, index)}
                    renderValue={type[index] === undefined ? () => <Placeholder>Choose</Placeholder> : ""}
                  >
                    <MenuItem value="BUYER" disabled={typeCount?.BUYER === 2}>
                      BUYER
                    </MenuItem>
                    <MenuItem value="SELLER" disabled={typeCount?.SELLER === 2}>
                      SELLER
                    </MenuItem>
                  </Select>
                </FormControl>
                {additionalParty.length > 1 && item.id !== 0 && <DeleteIcon onClick={() => handleDelete(index)} />}
              </Grid>
              <Grid Container spacing={2} item xs={12} md={12} className={classes.mDisplay}>
                <Grid item xs={6} md={4}>
                  <TextField
                    id="standard-basic"
                    label="Client First Name"
                    variant="filled"
                    sx={{ minWidth: "90%" }}
                    className={classes.mStyle}
                    value={
                      type[index] === "BUYER"
                        ? type.length >= 2 && (type[index - 1] === "BUYER" || type[index - 2] === "BUYER" || type[index - 3] === "BUYER")
                          ? state.buyer1FirstName
                          : state.buyer0FirstName
                        : type[index] === "SELLER"
                        ? type.length >= 2 && (type[index - 1] === "SELLER" || type[index - 2] === "SELLER" || type[index - 3] === "SELLER")
                          ? state.seller1FirstName
                          : state.seller0FirstName
                        : ""
                    }
                    onChange={(e) => handleFirstName(e, index)}
                  />
                </Grid>
                <Grid item xs={6} md={1}>
                  <TextField
                    id="standard-basic"
                    label="Init"
                    variant="filled"
                    sx={{ minWidth: "90%", marginRight: "5px" }}
                    className={classes.mStyle}
                    value={
                      type[index] === "BUYER"
                        ? type.length >= 2 && (type[index - 1] === "BUYER" || type[index - 2] === "BUYER" || type[index - 3] === "BUYER")
                          ? state.buyer1Middle
                          : state.buyer0Middle
                        : type[index] === "SELLER"
                        ? type.length >= 2 && (type[index - 1] === "SELLER" || type[index - 2] === "SELLER" || type[index - 3] === "SELLER")
                          ? state.seller1Middle
                          : state.seller0Middle
                        : ""
                    }
                    onChange={(e) => handleMiddleName(e, index)}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    id="standard-basic"
                    label="Client Last Name"
                    variant="filled"
                    sx={{ minWidth: "90%", marginLeft: "10px" }}
                    className={classes.mStyle}
                    value={
                      type[index] === "BUYER"
                        ? type.length >= 2 && (type[index - 1] === "BUYER" || type[index - 2] === "BUYER" || type[index - 3] === "BUYER")
                          ? state.buyer1LastName
                          : state.buyer0LastName
                        : type[index] === "SELLER"
                        ? type.length >= 2 && (type[index - 1] === "SELLER" || type[index - 2] === "SELLER" || type[index - 3] === "SELLER")
                          ? state.seller1LastName
                          : state.seller0LastName
                        : ""
                    }
                    onChange={(e) => handleLastName(e, index)}
                  />
                </Grid>
                <Grid item xs={5} md={3}>
                  <TextField
                    id="standard-basic"
                    label="Client Email"
                    variant="filled"
                    sx={{ minWidth: "100%" }}
                    className={classes.mStyle}
                    value={
                      type[index] === "BUYER"
                        ? type.length >= 2 && (type[index - 1] === "BUYER" || type[index - 2] === "BUYER" || type[index - 3] === "BUYER")
                          ? state.buyer1Email
                          : state.buyer0Email
                        : type[index] === "SELLER"
                        ? type.length >= 2 && (type[index - 1] === "SELLER" || type[index - 2] === "SELLER" || type[index - 3] === "SELLER")
                          ? state.seller1Email
                          : state.seller0Email
                        : ""
                    }
                    onChange={(e) => handleEmail(e, index)}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
          {type.length < 4 && (
            <Box mt={4}>
              <Grid className={classes.mJustifyContent}>
                <Button className={classes.myButton} onClick={() => handleAdditionalParty()}>
                  Add Additional Party
                </Button>
              </Grid>
            </Box>
          )}
          <Box mt={4}>
            <Grid item xs={12} md={12}>
              <TextField
                id="standard-basic"
                label="Folder Name"
                variant="filled"
                sx={{ minWidth: "100%" }}
                className={classes.mStyle}
                value={state.folderName}
                onChange={(event) => setState({ ...state, folderName: event.target.value })}
              />
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid item xs={12} md={12} className={classes.mJustifyContent}>
              <Grid item xs={3} md={1}>
                <Button className={classes.mCancel} onClick={() => handleCancel()}>
                  cancel
                </Button>
              </Grid>
              <Grid item xs={3} md={1}>
                <Button className={classes.mCreateButton} onClick={() => handleCreate()}>
                  {disabled ? "creating.." : "create"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </GlobalModal>
      <ResponseAlert setOpen={setOpenerror} open={openerror} alertType={alertType} alertMessage={alertMessage} style={{ position: "inherit" }} />
    </>
  );
};

// export default AddFolderModal;
export default withStyles(styleSheet, { name: "AddFolderModalStyle" })(AddFolderModal);
