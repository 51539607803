import * as React from "react";
import { TableRow, TableCell, ListItemText } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
/* Array.from(Array(10).keys()) */
function LeadRoutingSkelton(props) {
  let { count = 6, hideAvatar } = props;
  return Array.from(Array(count).keys()).map(() => (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell sx={{ width: hideAvatar ? "150px" : "60px" }}>
        {hideAvatar ? (
          <ListItemText
            primary={
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 4 }}
              />
            }
            secondary={<Skeleton animation="wave" height={15} width="100%" />}
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="circular"
            width={45}
            height={45}
          />
        )}
      </TableCell>
      <TableCell sx={{ width: "150px" }}>
        <ListItemText
          primary={
            <Skeleton
              animation="wave"
              height={20}
              width="70%"
              style={{ marginBottom: 4 }}
            />
          }
          secondary={<Skeleton animation="wave" height={15} width="50%" />}
        />
      </TableCell>
      <TableCell>
        <Skeleton
          animation="wave"
          height={30}
          width="140px"
          style={{ marginBottom: 4 }}
        />
      </TableCell>
      <TableCell>
        <Skeleton
          animation="wave"
          height={30}
          width="180px"
          style={{ marginBottom: 4 }}
        />
      </TableCell>
      <TableCell>
        <Skeleton
          animation="wave"
          height={30}
          width="180px"
          style={{ marginBottom: 4 }}
        />
      </TableCell>
    </TableRow>
  ));
}
export default LeadRoutingSkelton;
