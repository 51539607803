export const styleSheet = () => ({
  pageRoot: {
    marginTop: "20px",
    backgroundColor: "#F5F5F5",
  },
  mDisplay: {
    width: "100%",
    display: "flex",
    backgroundColor: "#F5F5F5",
  },
  mWidth_25: {
    width: "20%",
    height: "100%",
    overflowY: "auto",
    backgroundColor: "#fff",
    // position: "fixed",
    position: "sticky",
    top: "70px",
  },
  mWidth_75: {
    // position: "relative",
    // left: "25%",
    width: "70%",
    backgroundColor: "#F5F5F5",
    paddingLeft:"20px"
  },
  mainStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop:'30px'
  },
  mColor: {
    backgroundColor: "#ededed",
  },
  mCanvas: {
    display: "block",
    margin: "auto",
  },
  mPosition: {
    position: "relative",
    marginBottom:"30px"
  },
  mButtonStyle: {
    background: "#000000 !important",
    borderRadius: "12px !important",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "36px",
    color: "#ffffff !important",
    padding: "7px 30px !important",
    margin: "10px 0 !important",
    '&:disabled': {
      opacity: "0.5",
    }
  },
  mAccordianColor: {
    background: "#ededed !important",
  },
  tabBarArea:{
    // boxShadow: "0px 0px 0px 1px #E0E0E0",
    // height:"100%"
  },
  mAccordianDetails: {
    backgroundColor: "#fff",
    padding: "0 !important",
  },
  mAccordianTxt: {
    textAlign: "center",
    paddingTop: "10px",
  },
  mAccordianImage: {
    margin: "auto",
    width: "170px",
    border: "0.5px solid #a39b9b",
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
    cursor: "pointer",
  },
  mActive: {
    border: "0.8px solid #1D30D4 !important",
  },
});
