import React from "react";
import { Box, Stack, Chip } from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import houseIcon from "../../../assets/house.png";
import { useNavigate } from "react-router-dom";
import momentTZ from "moment-timezone";
import { CustomTooltip } from "../../shared/tooltip";
function Listings(props) {
  let { classes, data, key, setOpenSearch } = props;
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        setOpenSearch(false);
        navigate(`/listings/fmls/${data.ListingId}`);
      }}
      key={key}
      className={classes.listingArea}
    >
      <Box className={classes.listingAreaLeft}>
        <Box className={classes.listingAreaLeftImg}>
          <img src={data?.Media?.length ? data?.Media[0]?.MediaURL : houseIcon} alt="houseIcon" />
        </Box>
        <Box className={classes.listingAreaLeftContent}>
          <Box
            className={classes.listingAreaLeftContentHead}
            sx={{ width: "169px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
          >
            {" "}
            {data?.StreetNumber || "Street NA"} {data?.StreetName || "StreetName NA"} {data?.StreetSuffix}
          </Box>
          <Box className={classes.listingAreaLeftContentAdd}>
            {data?.City || data?.UnparsedAddress}, {data?.StateOrProvince} {data?.PostalCode || "PostalCode NA"}
          </Box>
          <Box>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Box className={classes.listingAreaLeftContentPrize}>
                $
                {data.MlsStatus === "Closed"
                  ? new Intl.NumberFormat().format(data?.ClosePrice || "0")
                  : new Intl.NumberFormat().format(data?.ListPrice || "0")}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box className={classes.listingAreaRight}>
          <Box>
            <CustomTooltip title={data.MlsStatus}>
              <Chip
                size="small"
                label={data.MlsStatus === "Active Under Contract" ? "ActiveUC" : data.MlsStatus || "Active"}
                sx={{
                  color:
                    data.MlsStatus === "Expired"
                      ? "#FF9800"
                      : data.MlsStatus === "Canceled"
                      ? "orange"
                      : data.MlsStatus === "Active"
                      ? "#2E7D32"
                      : data.MlsStatus === "Pending"
                      ? "#81C784"
                      : data.MlsStatus === "Closed"
                      ? "#C62828"
                      : "#2E7D32",
                  borderColor:
                    data.MlsStatus === "Expired"
                      ? "#FF9800"
                      : data.MlsStatus === "Canceled"
                      ? "orange"
                      : data.MlsStatus === "Active"
                      ? "#2E7D32"
                      : data.MlsStatus === "Pending"
                      ? "#81C784"
                      : data.MlsStatus === "Closed"
                      ? "#C62828"
                      : data.MlsStatus === "Withdrawn"
                      ? "#607D8B"
                      : "#2E7D32",
                }}
                variant="outlined"
              />
            </CustomTooltip>
          </Box>
          <Box className={classes.listingAreaRightTop} sx={{ textTransform: "capitalize", width: "30ch" }}>
            {data?.ListAgentFullName} <br />
            {data?.ListOfficeName
              ? `${data?.ListOfficeName?.slice(0, 25)} ${data?.ListOfficeName?.length > 25 ? "..." : ""}`
              : data?.ListAgentOfficeName || " ListOfficeName NA"}
          </Box>
        </Box>
        <Box className={classes.listingAreaRightBottom}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Box>
              <Box className={classes.closingDateStat}>
                {data.MlsStatus === "Expired"
                  ? momentTZ.tz(data.ExpirationDate, "America/New_York")?.format("l")
                  : data.MlsStatus === "Pending"
                  ? momentTZ.tz(data.PurchaseContractDate, "America/New_York")?.format("l")
                  : data.MlsStatus === "Active"
                  ? momentTZ.tz(data.ListingContractDate, "America/New_York")?.format("l")
                  : data.MlsStatus === "Closed"
                  ? momentTZ.tz(data.CloseDate, "America/New_York")?.format("l")
                  : data.MlsStatus === "Active Under Contract"
                  ? momentTZ.tz(data.PurchaseContractDate, "America/New_York")?.format("l")
                  : data.MlsStatus === "Withdrawn"
                  ? momentTZ.tz(data.WithdrawnDate, "America/New_York")?.format("l")
                  : "mm/dd/yy"}
              </Box>
              <Box className={classes.listingAreaRightStatBottom}>
                {data.MlsStatus === "Expired"
                  ? "Expiration"
                  : data.MlsStatus === "Active"
                  ? "Listed"
                  : data.MlsStatus === "Active Under Contract"
                  ? "Pending"
                  : data.MlsStatus}
              </Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{data?.DaysOnMarket || "0"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>DOM</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{data?.BedroomsTotal || "0"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Beds</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{data?.BathroomsFull || "0"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Baths</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{new Intl.NumberFormat().format(data?.BuildingAreaTotal || "0")}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Sq. Feet</Box>
            </Box>
            <Box className={classes.listingAreaRightStat}>
              <Box className={classes.listingAreaRightStatTop}>{data?.YearBuilt || "--"}</Box>
              <Box className={classes.listingAreaRightStatBottom}>Yr. Built</Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ListingsPowerStyle" })(Listings);
