import React, { useState } from "react";
import { Input, Box, Button } from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import { CollectionsOutlined, ContentCopy, EmojiEmotionsOutlined, Send } from "@mui/icons-material";
import { saveNewSms } from "../../../redux/leads/inbox";
import { useDispatch } from "react-redux";
import {postData} from '../../../utils/postData';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Menu from "@mui/material/Menu";

function SendMessage(props) {
  let {selectedUser}=props
  const [anchor,setAnchor]= useState("");
  const openMenu = Boolean(anchor);
  const [message, setMessage] = useState("");
  let { classes } = props;
  const dispatch = useDispatch();

  const handleSendMessage = () => {
    let obj = {
      isIncoming: false,
      message: message,
      sent: new Date().toISOString(),
      agentRecruitmentId: selectedUser?.realtor_agent_id,
      id:selectedUser?.id,
      fromNumber: selectedUser.fromNumber,
      toNumber: selectedUser.toNumber,
    };
    dispatch(saveNewSms(obj));
    setMessage("");
    if(selectedUser?.realtor_agent_id){
      let obj1={
        message: message,
        agentRecruitmentId: selectedUser?.realtor_agent_id,
        fromNumber: selectedUser.fromNumber,
        toNumber: [selectedUser.toNumber],
      }
      postData(`${process.env.REACT_APP_BASE_URL}api/recruitment/textMessages`,obj1)
    }
    if(selectedUser?.lead_id){
      let obj1={
        message: message,
        personId: selectedUser?.lead_id,
        fromNumber: selectedUser.fromNumber,
        toNumber: [selectedUser.toNumber],
      }
      postData(`${process.env.REACT_APP_BASE_URL}api/leads/textMessages`,obj1)
    }
  };
  return (
    <div className={classes.inputBox}>
      <Input value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Write your text.." />
      <Box className={classes.iconList}>
        <Box className={classes.iocnGroup}>
          <ContentCopy />
          <CollectionsOutlined />
          <EmojiEmotionsOutlined sx={{cursor:"pointer"}} onClick={(e)=>setAnchor(e.currentTarget)} />     
        </Box>
        <Button disabled={!message} onClick={handleSendMessage} className={classes.btnSend} variant="contained" startIcon={<Send />}>
          Send
        </Button>
      </Box>
      <Menu
        anchorEl={anchor}
        id="account-menu"
        open={openMenu}
        onClose={()=>setAnchor("")}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
       <Picker data={data} onEmojiSelect={(emojy)=>{
        setMessage(emojy.native)
        setAnchor("")
       }} />
      </Menu>
    </div>
  );
}
export default withStyles(styleSheet, { name: "SendMessageStyle" })(SendMessage);
