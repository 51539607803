import React, { useState, useEffect } from "react";
import { Typography, Box, Stack, Skeleton } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getLeadActivities } from "../../../../../redux/leads/getLeadActivities";
import { NumberFormat } from "../../../../../utils/numberFormat";
import momentTZ from "moment-timezone";
import ImgsViewer from "react-images-viewer";
function ActivityListModal(props) {
  let { open, setOpen, classes, personId } = props;
  const [filters, setFilters] = useState({
    limit: 20,
    offset: 0,
  });
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [mediaUrls, setMediaUrls] = React.useState([]);
  const [scrollFlag, setScrollFlag] = useState(true);
  const dispatch = useDispatch();
  const activities = useSelector((item) => item.leadProfile.activities);
  const adminDetail = useSelector((state) => state.adminAuth?.adminDetail);
  useEffect(() => {
    if (filters?.offset > 0) {
      dispatch(getLeadActivities({ ...filters, personId }));
    }
  }, [filters.offset]);

  const handleClose = () => {
    setOpen(false);
    setFilters({ limit: 20, offset: 0 });
  };

  const handleScrollEvent = (e) => {
    if (Number(activities?._metadata?.total || 0) > filters.offset) {
      var bottom =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
      if (bottom && !activities.isLoading && scrollFlag) {
        setScrollFlag(false);
        setFilters((filter) => ({ ...filter, offset: filter.offset + 20 }));
        setTimeout(() => {
          setScrollFlag(true);
        }, 3000);
      }
    }
  };
  const handleReturnDatesWiseData = (events) => {
    let groupData = events.reduce((acc, currentItem) => {
      const currentDate = momentTZ
        .tz(
          currentItem.created,
          adminDetail?.market?.timezone || process.env.REACT_APP_TIMEZONE
        )
        .format("LL");
      if (!acc[currentDate]) {
        acc[currentDate] = [];
      }
      acc[currentDate].push(currentItem);
      return acc;
    }, {});
    let newFilteredData = Object.keys(groupData).map((date) => ({
      date,
      items: groupData[date],
    }));
    return newFilteredData || [];
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.modalHeading} id="alert-dialog-title">
          <Typography className={classes.modalHeadingDes}>
            <WorkspacePremiumRoundedIcon fontSize="large" />
            All Activity
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <CloseRoundedIcon sx={{ color: "lightgrey" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          onScroll={handleScrollEvent}
          className={classes.activityList}
        >
          {activities?.events?.length
            ? handleReturnDatesWiseData(activities?.events)?.map(
                (value, index) => (
                  <Box key={index} className={classes.activityListItemOuter}>
                    <Typography
                      className={classes.activityListItemOuterHeading}
                    >
                      {value.date}
                    </Typography>
                    {value.items?.map((item) => (
                      <Box key={item.id} className={classes.activityListItem}>
                        <Box className={classes.activityListItemTime}>
                          {momentTZ
                            .tz(
                              item.created,
                              adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("LT")}
                        </Box>
                        <Box className={classes.activityListItemIcon}>
                          {" "}
                          <VisibilityOutlinedIcon
                            sx={{ color: "rgb(255, 173, 129)" }}
                          />
                        </Box>

                        <Box className={classes.activityListItemDes}>
                          <a
                            href={item?.property?.url}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            key={index}
                          >
                            <Stack direction={"row"} spacing={1}>
                              <Typography sx={{ color: "black" }}>
                                Viewed
                              </Typography>
                              <Typography
                                sx={{ color: "rgb(78, 173, 221) !important" }}
                              >
                                {item?.property?.street
                                  ? item?.property?.street +
                                    ", " +
                                    item?.property?.city +
                                    ", " +
                                    item?.property?.state +
                                    ", " +
                                    item?.property?.code
                                  : "Street Address not exists"}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            >
                              <Typography
                                sx={{ color: "rgb(74, 208, 159) !important" }}
                              >
                                {NumberFormat({
                                  number: item.property?.price,
                                  maximumFractionDigits: 0,
                                  notation: "compact",
                                })}
                              </Typography>
                              {item?.PropertyType ? (
                                <FiberManualRecordIcon
                                  sx={{ color: "lightgrey", fontSize: "9px" }}
                                />
                              ) : null}
                              {item?.PropertyType ? (
                                <Typography
                                  sx={{ color: "rgb(147, 165, 178)!important" }}
                                >
                                  {item?.PropertyType}
                                </Typography>
                              ) : null}
                              <FiberManualRecordIcon
                                sx={{ color: "lightgrey", fontSize: "9px" }}
                              />
                              <Typography
                                sx={{ color: "rgb(147, 165, 178)!important" }}
                              >
                                {item?.property?.bedrooms} Beds
                              </Typography>
                              <FiberManualRecordIcon
                                sx={{ color: "lightgrey", fontSize: "9px" }}
                              />
                              <Typography
                                sx={{ color: "rgb(147, 165, 178)!important" }}
                              >
                                {item?.property?.bathrooms} Baths
                              </Typography>
                              <FiberManualRecordIcon
                                sx={{ color: "lightgrey", fontSize: "9px" }}
                              />
                              <Typography
                                sx={{ color: "rgb(147, 165, 178)!important" }}
                              >
                                {new Intl.NumberFormat().format(
                                  item.property?.area
                                )}{" "}
                                SqFt
                              </Typography>
                            </Stack>
                          </a>
                        </Box>

                        {item?.property?.primaryImg ? (
                          <Box
                            onClick={() => {
                              setImageViewerOpen(true);
                              setMediaUrls([item?.property?.primaryImg]);
                            }}
                            className={classes.activityListItemImage}
                          >
                            <img
                              src={item?.property?.primaryImg}
                              alt={item?.property?.street}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                )
              )
            : null}
          {activities.isLoading
            ? Array.from(Array(2).keys()).map(() => (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={75}
                  width="100%"
                  sx={{ mt: "10px" }}
                />
              ))
            : null}
        </DialogContent>
      </Dialog>
      {mediaUrls?.length > 0 && (
        <ImgsViewer
          imgs={mediaUrls?.map((item) => ({
            src: item,
          }))}
          isOpen={imageViewerOpen}
          currImg={0}
          showThumbnails={true}
          enableKeyboardInput={true}
          onClose={() => setImageViewerOpen(false)}
        />
      )}
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "ActivityListModalStyle" })(
  ActivityListModal
);
