import React from "react";
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  ListItemButton,
} from "@mui/material";
import Avatar from "../../components/uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import AgentListSkelton from "./skelton/agentList";
import { useSelector } from "react-redux";
import ChatIcon from "@mui/icons-material/Chat";
import { CustomTooltip } from "../../components/shared/tooltip";
import { timeSince } from "../../utils/timeSince";

export const InboxName = ({
  onClick,
  profileDetail,
  id,
  selectedUser,
  classes,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{ backgroundColor: "white", border: "none", cursor: "pointer" }}
    >
      <List
        sx={{
          background: profileDetail.id === selectedUser?.id ? "#f5fafd" : "",
          borderLeft:
            profileDetail.id === selectedUser?.id
              ? "2px solid #40a2d9"
              : "2px solid transparent",
        }}
        disablePadding
      >
        <ListItem disablePadding alignItems="flex-start" onClick={onClick}>
          <ListItemButton sx={{ paddingBottom: "0px" }}>
            <CustomTooltip
              title={`${profileDetail?.agent?.first_name || "First Name"} ${
                profileDetail?.agent?.last_name || "Last Name"
              }`}
            >
              <ListItemAvatar sx={{ mt: "3px" }}>
                <Avatar
                  variant="rounded"
                  sx={{ fontSize: "17px", width: "40px", height: "40px" }}
                  src={profileDetail?.agent?.profile_images?.profile_img}
                >
                  {`${profileDetail?.agent?.first_name?.slice(0, 1) || "U"}${
                    profileDetail?.agent?.last_name?.slice(0, 1) || "N"
                  }`}
                </Avatar>
                <Typography className={classes.agentListTime}>
                  {timeSince(profileDetail?.createdAt)}{" "}
                </Typography>
              </ListItemAvatar>
            </CustomTooltip>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "10px",
                // boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
                width: "100%",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <Typography className={classes.agentListHeading} variant="body1">
                {profileDetail?.summary || "Title here short and long title"}
              </Typography>
              <Typography
                className={classes.agentListDescription}
                variant="subtitle1"
                sx={{
                  color: "rgba(0,0,0,0.6)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  maxWidth: "26ch",
                }}
              >
                {profileDetail?.description}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  );
};

function AgentList(props) {
  let {
    classes,
    handleClick,
    selectedUser,
    IsAssigned,
    handleGetAgentRequests,
  } = props;
  const requestList = useSelector((state) => state.AgentRequests.requestList);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);

  const handleScrollEvent = (e) => {
    //console.log(e);
    let bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2;
    if (bottom && !requestList.isLoading && !requestList.isLimitReached) {
      if (requestList?.data?.length < requestList.count) {
        let currentPageNumber = Math.ceil(requestList?.data?.length / 20);
        handleGetAgentRequests({
          pageNumber: currentPageNumber + 1,
        });
      }
    }
  };

  return (
    <Box onScroll={handleScrollEvent} className={classes.Sidebar}>
      {requestList?.data?.length ? (
        requestList?.data?.map((i, index) => (
          <InboxName
            classes={classes}
            selectedUser={selectedUser}
            profileDetail={{
              ...i,
              agent: agentRosterList?.list.find((it) => it.id === i.userId),
            }}
            id={index}
            onClick={(e) => handleClick(i)}
          />
        ))
      ) : !requestList.isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
          }}
        >
          <center>
            <ChatIcon sx={{ fontSize: "100px", color: "lightgray" }} />
            <br />
            {IsAssigned ? "Not Assigned any ticket" : "Not Exists"}
          </center>
        </Box>
      ) : null}
      {requestList.isLoading ? <AgentListSkelton count={5} /> : null}
    </Box>
  );
}
export default withStyles(styleSheet, { name: "AgentListStyle" })(AgentList);
