export const styleSheet = () => ({
  TopTemplatesBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopTemplatesBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  loadingArea: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width: 1270px)": {
    TemplatesTabsUI: {
      maxWidth: "420px",
    },
  },
  "@media (max-width: 1050px)": {
    TemplatesTabsUI: {
      maxWidth: "300px",
    },
  },
  TemplatesTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
});
