import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import { Divider,Box} from "@mui/material";

function Skelton() {
  return (
    <Card sx={{ maxWidth: 345, m: 2 }}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="rounded" width={40} height={40} /> }
        title={ <Skeleton
            animation="wave"
            height={15}
            width="80%"
            style={{ marginBottom: 6 }}
          /> }
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
    </Card>
  );
}

Skelton.propTypes = {
  loading: PropTypes.bool,
};

export default function AgentListSkelton(props) {
    let {count=1}=props
  return (
    Array.from(Array(count).keys()).map((item,index)=>(
        <Box key={index}>
        <Skelton  />
        <Divider />
      </Box>
    )) 
  );
}
