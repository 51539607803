import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAgentBusinessCardDetail = createAsyncThunk("agentBusinessCardDetail/getAgentBusinessCardDetail", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/users/bussiness-card-details/${data.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
export const updateAgentBusinessCard = createAsyncThunk("agentBusinessCardDetail/updateAgentBusinessCard", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/users/bussiness-card-button/${data.id}`,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
const initialState = {
 cardData:{
  success: false,
  data: undefined,
  isLoading: false,
  errMsg: null,
 } ,
 addCardData:{
  success: false,
  data: undefined,
  isLoading: false,
  errMsg: null,
 }
};
const agentBusinessCardDetail = createSlice({
  name: "agentBusinessCardDetail",
  initialState,
  extraReducers: {
    [getAgentBusinessCardDetail.pending]: (state) => {
      state.cardData.isLoading = true;
      state.cardData.errMsg = null;
      state.cardData.success = false;
    },
    [getAgentBusinessCardDetail.fulfilled]: (state, action) => {
      state.cardData.isLoading = false;
      state.cardData.success = true;
      state.cardData.data = action.payload;
    },
    [getAgentBusinessCardDetail.rejected]: (state, action) => {
      state.cardData.isLoading = false;
      state.cardData.success = false;
      state.cardData.errMsg = action.payload;
    },

    [updateAgentBusinessCard.pending]: (state) => {
      state.addCardData.isLoading = true;
      state.addCardData.errMsg = null;
      state.addCardData.success = false;
    },
    [updateAgentBusinessCard.fulfilled]: (state, action) => {
      state.addCardData.isLoading = false;
      state.addCardData.success = true;
      state.addCardData.data = action.payload;
    },
    [updateAgentBusinessCard.rejected]: (state, action) => {
      state.addCardData.isLoading = false;
      state.addCardData.success = false;
      state.addCardData.errMsg = action.payload;
    },

  },
});
export default agentBusinessCardDetail.reducer;
