export const styleSheet = () => ({
    pageRoot: {
      marginTop: "60px",
      padding: "20px",
    },
    InventoryHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    InventoryAddButton: {
      background: "#0B0909 !important",
      color: "white",
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
      borderRadius: "9px  !important",
    },
    tableImageArea: {
      width: "90px",
      display: "flex",
      justifyContent: "center",
      padding: "10px",
    },
    addItemModalHeading: {
      fontSize: "22px !important",
      fontWeight: "400 !important",
      fontFamily: "AvenirNext  !important",
      textTransform: "capitalize !important",
    },
    AddInventoryButton: {
      backgroundColor: "#0B0909 !important",
      color: "white !important",
      fontWeight: "400 !important",
      marginRight: "10px !important",
      paddingLeft: "30px !important",
      paddingRight: "30px !important",
    },

    addInventoryLabel: {
      fontWeight: "500 !important",
      color: "rgba(0, 0, 0, 0.87) !important",
      fontSize: "14px !important",
      marginBottom:'10px !important'
    },
    InventoryIconBox:{
      padding:"10px",
      paddingLeft:'0px',
      backgroundColor:'white'
    },
    InventoryIconBoxIcon:{
      width:'32px',
    },
    dropdowns:{
      padding: "16px 0px !important",
      paddingRight: "15px !important"
    },
    audience:{
      background:"black",
      color:"white !important",
      "& .MuiSvgIcon-root":{
        color: 'white !important',
     },
    },
    labels:{
      fontWeight: "400",
      fontSize: "16px",
      marginBottom: "2px",
      marginTop: "0px",
    },
    btns:{
      background:"white !important",
      color:"black !important",
      border:"1px solid #212121 !important",
      boxShadow:"none !important"
    },
    
  });
  