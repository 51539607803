import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const updateLeadTimelineAPI = createAsyncThunk("lead/updateLeadTimelineAPI", async (data, thunkAPI) => {
  const { path, schema } = data;

  try {
    const resp = await axios.put(
      `${process.env.REACT_APP_BASE_URL}api/leads/${path}`,
      { ...schema },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { data: resp.data, timeline_type: data.timeline_type, timeline_id: data.timeline_id };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
