import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Stack
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentRosterProfile } from "../../../../../redux/agents/agentRoster/updateProfile";
import { toast } from "react-toastify";

const BiographyDetails = ({ classes }) => {
  const toastId = React.useRef(null);
  const [isEditAble, setIsEditAble] = useState(false);
  const [bioDetail, setBioDetail] = useState({
    id: '',
   bio:'',
   type:'bio'
  });
  const dispatch = useDispatch();
  const agentRosterProfile = useSelector((item) => item.agentRoster.agentRosterProfile);
  const { profile, updateLoading } = agentRosterProfile;
  const handleUpdateField = (field, value) => {
    setBioDetail({
      ...bioDetail,
      [field]: value,
    });
  };
  useEffect(() => {
    if(!bioDetail.id){
      setBioDetail({
        id: profile.id,
        bio:profile.bio,
        type:'bio'
      })
    }
  },[profile])

  useEffect(() => {
    if (agentRosterProfile.success && !agentRosterProfile.updateLoading && agentRosterProfile.type==="bio") {
      if (!toast.isActive(toastId.current)) {
        toast.success("You have successfully update BIO", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsEditAble(false)
      }
    } else if (!agentRosterProfile.updateLoading && agentRosterProfile.updateErrMsg && agentRosterProfile.type==="bio") {
      if (!toast.isActive(toastId.current)) {
        toast.error(JSON.stringify(agentRosterProfile.updateErrMsg), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }, [agentRosterProfile.updateLoading]);

  const handleSave = () => {
    if(bioDetail.bio){
      dispatch(updateAgentRosterProfile(bioDetail));
    }
  };
  return (
    <Card
      variant="outlined"
      sx={{ backgroundColor: "white" }}
      className={classes.pageRoot}
    >
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title="Biography"
        action={
          <div>
            <IconButton onClick={() => setIsEditAble(!isEditAble)} aria-label="locked">
              {isEditAble ? <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} /> : <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />}
            </IconButton>
          </div>
        }
      />
      <CardContent>
        <Grid container direction="row" spacing={6}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              inputProps={{ readOnly: isEditAble ? false : true }}
              rows={12}
              onChange={(e) => handleUpdateField("bio", e.target.value)}
              label="Label"
              value={bioDetail.bio}
              placeholder="Enter BIO"
              variant="outlined"
               InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid hidden={!isEditAble} item xs={12}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton
                onClick={handleSave}
                loading={updateLoading==="bio"}
                classes={{ root: classes.saveButTon }}
                color="inherit"
                autoFocus
                size="small"
                loadingPosition="start"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styleSheet, {
  name: "agentRosterDetailsBiographyStyle",
})(BiographyDetails);
