export const styleSheet = (theme, props) => ({
  dateDropdownButtonStyle: {
    border: "1px solid rgb(215, 222, 226) !important",
    borderRadius: "4px",
    background: "linear-gradient(rgb(255, 255, 255), rgb(232, 236, 240))",
    color: "rgb(109, 130, 145) !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize  !important",
  },
  dateDropdownItem: {
    borderBottom: "1px solid lightgrey !important",
    paddingTop:"10px !important",
    paddingBottom:"10px !important",
    fontSize: "14px !important",
    "& 	.MuiInputBase-input":{
        width: "141px !important",
        fontSize: "13px !important",
        paddingLeft: "8px !important",
    }
  },
  customDateInput: {
    width: "141px !important",
  },
});
