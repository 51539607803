import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  stagesList: [],
  errMsg: null,
};

export const getAgentRecruitingStages = createAsyncThunk(
  "agentRecruitment/getAgentRecruitingStages",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/stages`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const stagesList = createSlice({
  name: "stagesList",
  initialState,
  extraReducers: {
    [getAgentRecruitingStages.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRecruitingStages.fulfilled]: (state, action) => {
      state.loading = false;
      state.stagesList = action.payload.stages;
    },
    [getAgentRecruitingStages.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default stagesList.reducer;
