import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  officesList: [],
  errMsg: null,
};

export const getAgentsRecruitmentAllOffices = createAsyncThunk("agentRecruitment/getAgentsRecruitmentAllOffices", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/recruitment/offices`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const officesList = createSlice({
  name: "officesList",
  initialState,
  extraReducers: {
    [getAgentsRecruitmentAllOffices.pending]: (state) => {
      state.loading = true;
    },
    [getAgentsRecruitmentAllOffices.fulfilled]: (state, action) => {
      state.loading = false;
      state.officesList = action.payload.arOffices;
    },
    [getAgentsRecruitmentAllOffices.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default officesList.reducer;
