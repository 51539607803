import React from "react";
import { Box, Card, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import AllColumns from "./allColumn.js";
import InitialColumns from "./initialColumn.js";
function AdjustmentsRowView(props) {
  let { classes } = props;
  return (
    <Box className={classes.rowViewPage}>
      <Card variant="outlined" className={classes.rowViewCard}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <InitialColumns {...props} />
          </Grid>
          <Grid item md={6}>
            <AllColumns {...props} />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default withStyles(styleSheet, { name: "AdjustmentsRowViewStyle" })(
  AdjustmentsRowView
);
