import React, { useState, useEffect } from "react";
import { Box, Autocomplete, Chip, Tooltip, ListItem, ListItemText, Checkbox } from "@mui/material";
import Input from "../uiElements/input/Input";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WarningAmberSharpIcon from "@mui/icons-material/WarningAmberSharp";
import Avatar from "../uiElements/avatar";
import { stringAvatar } from "../../utils/avatarInitial";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultipleDropsdownSelection = ({ options, label, detail, setDetail, field, placeholder, disableEmailCheck, updateFlag }) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [inviteeWithoutEmail, setInviteeWithoutEmail] = useState([]);
  useEffect(() => {
    if (!disableEmailCheck) {
      const witoutEmailItems = selectedItem.filter((si) => si.email === undefined || si.email === null || si.email.length === 0);
      setInviteeWithoutEmail(witoutEmailItems);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (updateFlag === true && selectedItem?.length === 0) {
      setSelectedItem(detail[field]);
    }
  }, [detail[field]]);
 
  return (
    <React.Fragment>
      <Box>
        <Autocomplete
          multiple
          limitTags={4}
          disableClearable
          sx={{
            "& label": {
              fontWeight: "400",
              fontSize: "1rem",
              lineHeight: "1.75",
            },
            "& .MuiAutocomplete-endAdornment": {
              display: "none",
            },
          }}
          id="general-multiple-tags"
          selectOnFocus
          clearOnBlur
          disableCloseOnSelect
          autoHighlight
          groupBy={(option) => option?.group}
          options={options}
          getOptionLabel={(option) => option?.name}
          value={selectedItem}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map(({ name, full_name, email, picture }, index) => (
              <Tooltip placement="top" title={email} arrow followCursor disableHoverListener={name ? false : true}>
                <Chip
                  label={name || email}
                  {...getTagProps({ index })}
                  key={index}
                  onDelete={() => {
                    const des = [...selectedItem];
                    des.splice(index, 1);
                    const menuData = {
                      ...detail,
                      [field]: des,
                    };
                    setDetail(menuData);
                    setSelectedItem(des);
                  }}
                  sx={{ "& .MuiAvatar-root": { color: "white !important" } }}
                  avatar={<Avatar sx={{ fontSize: "14px" }} {...stringAvatar(name || full_name || email, picture || false)} />}
                ></Chip>
              </Tooltip>
            ))
          }
          renderOption={(a, b, c, d, e) => {
            return (
              <ListItem key={b.name} disablePadding dense {...a}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    c.selected ||
                    selectedItem.some((ii) => {
                      return ii.email === b.email;
                    })
                  }
                />
                <ListItemText
                  id={b.name}
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Avatar sx={{ height: 30, width: 30, fontSize: "14px" }} {...stringAvatar(b.name, b?.picture || false)} />
                      {b.name}
                    </Box>
                  }
                />
              </ListItem>
            );
          }}
          onKeyDown={(a) => {
            if (a.code === "Backspace") {
              const key_code = [...selectedItem];
              key_code.pop();
              const menuData = {
                ...detail,
                [field]: key_code,
              };
              setDetail(menuData);
              setSelectedItem(key_code);
              return;
            }
          }}
          onChange={(a, b, c, d, e) => {
            let schema = null;
            if (typeof d?.option == "object") {
              schema = d?.option;
            }
            if (typeof a.target.value === "string") {
              return null;
            }

            const duplicatedValues = selectedItem.findIndex((i) => i.email === schema?.email);

            if (!!schema) {
              if (schema.group === "Agents") {
                schema.userId = schema.id;
              }
              if (duplicatedValues === -1) {
                const kk = [...selectedItem];
                kk.push(schema);
                setSelectedItem(kk);
                const menuData = {
                  ...detail,
                  [field]: kk,
                };
                setDetail(menuData);
                return;
              } else {
                const kk = [...selectedItem];
                kk.splice(duplicatedValues, 1);
                setSelectedItem(kk);
                const menuData = {
                  ...detail,
                  [field]: kk,
                };
                setDetail(menuData);
              }
            }
          }}
          renderInput={(params) => (
            <>
              <Input {...params} label={label} placeholder={placeholder} />
            </>
          )}
        />
      </Box>
      {inviteeWithoutEmail.length !== 0 && (
        <Box
          sx={{
            color: "red",
            display: "flex",
            gap: "20px",
            margin: "20px 10px",
            fontSize: "18px",
            alignItems: "center",
          }}
        >
          <WarningAmberSharpIcon fontSize="large" />
          <div>
            There is no email attached to these records. They will not receive invites.
            <ul style={{ margin: "0px 10px", padding: "10px" }}>
              {inviteeWithoutEmail.map((iw) => (
                <li style={{ fontSize: "14px" }}>{iw.name}</li>
              ))}
            </ul>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};
