export const styleSheet = (theme) => ({
  // background: theme.palette.primary.main,
  root: {
    "&:before": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      margin: "0px !important",
    },
    "& .MuiTimelineContent-root": {
      padding: "0px 12px 45px 25px  !important",
    },
  },
  threadClosedCard: {
    padding: "10px  !important",
    color: "#5dafde  !important",
    background: "#fcfdfd  !important",
    cursor: "pointer  !important",
  },
  threadClosedSubCard: {
    padding: "10px  !important",
    color: "#5dafde  !important",
    background: "#fcfdfd  !important",
    cursor: "pointer  !important",
    display: "flex !important",
    flexDirection: "row",
    flexWrap: "nowrap !important",
    gap: "0px 10px !important",
    marginBottom: "15px !important",
  },
  threadClosedSubCardName: {
    width: "100px !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    color: "black !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  threadClosedSubCardDate: {
    width: "50px !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    color: "lightgrey !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  threadClosedSubCardDes: {
    flex: 1,
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    width: "calc(100% - 150px) !important",
    color: "black !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  subCollapsedArea: {
    position: "absolute !important",
    top: "5px !important",
    right: "5px !important",
    zIndex: "999 !important",
  },
  threadMessagesAreaItem: {
    padding: "10px",
    marginBottom: "15px",
  },
  threadMessagesArea: {
    marginTop: "15px",
  },
  threadMessagesAreaItemDetail: {
    padding: "0px !important",
    fontSize: "14px !important",
  },
  nameWithIcon: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0px 5px",
  },
  timelineFilterArea: {
    display: "flex !important",
    justifyContent: "space-between !important",
    flexDirection: "row  !important",

    height: "50px  !important",
    overflow: "hidden !important",
  },
  timelineFilterAreaLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  timelineFilterAreaRight: {
    flex: 1,
    padding: "10px !important",
    borderBottom: "1px solid #C4C4C4 !important",
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    background: "#FCFCFC !important",
    "& .MuiButtonBase-root": {
      textTransform: "capitalize !important",
      border: "1px solid rgba(0, 0, 0, 0.30) !important",
      color: "rgba(0, 0, 0, 0.60) !important",
      borderRadius: "7px !important",
      fontSize: "14px !important",
      background: "white !important",
    },
  },
  timelineFilterAreaItem: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "0px 7px !important",
    color: "rgba(0, 0, 0, 0.30) !important",
    background: "#FCFCFC !important",
    borderRight: "1px solid #C4C4C4 !important",
    padding: "0px 12px  !important",
    height: "50px  !important",
    cursor: "pointer  !important",
    minWidth: "70px !important",
    fontFamily: "AvenirNext !important",
    borderBottom: "1px solid #C4C4C4 !important",
  },
  timelineFilterAreaItemActive: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "0px 7px !important",
    color: "black !important",
    background: "white !important",
    borderRight: "1px solid #C4C4C4 !important",
    padding: "0px 12px  !important",
    height: "51px  !important",
    cursor: "pointer  !important",
    minWidth: "70px !important",
    borderBottom: "1px solid white !important",
    fontFamily: "AvenirNext !important",
  },
  timeSkeltonItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0px 10px",
    flexDirection: "row",
  },
  timeSkeltonItemAvatar: {
    background: "#D2D2D2",
    height: "43px",
    width: "43px",
    borderRadius: "50%",
  },
  timeSkeltonItemContent: {
    flex: 1,
  },
  callPlayArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    width: "160px !important",
    "& input": {
      "--c": "black",
      "--g": "2px",
      "--l": "3px",
      "--s": "13px",
      width: "200px",
      height: "var(--s)",
      "--_c": "color-mix(in srgb, var(--c), #000 var(--p,0%))",
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      background: "none",
      cursor: "pointer",
      overflow: "hidden",
    },
    "& input:focus-visible,\ninput:hover": { "--p": "25%" },
    "& input:active,\ninput:focus-visible": { "--_b": "var(--s)" },
    '& input[type="range" i]::-webkit-slider-thumb': {
      height: "var(--s)",
      aspectRatio: "1",
      borderRadius: "50%",
      boxShadow: "0 0 0 var(--_b,var(--l)) inset var(--_c)",
      borderImage:
        "linear-gradient(90deg,var(--_c) 50%,#ababab 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw + var(--g))",
      WebkitAppearance: "none",
      appearance: "none",
      transition: ".3s",
    },
    '& input[type="range"]::-moz-range-thumb': {
      height: "var(--s)",
      width: "var(--s)",
      background: "none",
      borderRadius: "50%",
      boxShadow: "0 0 0 var(--_b,var(--l)) inset var(--_c)",
      borderImage:
        "linear-gradient(90deg,var(--_c) 50%,#ababab 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw + var(--g))",
      MozAppearance: "none",
      appearance: "none",
      transition: ".3s",
    },
    "@supports not (color: color-mix(in srgb,red,red))": {
      input: { "--_c": "var(--c)" },
    },
  },
  customSeekSlider: { width: "160px !important" },
  timeLineArea: {
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  propertySection: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    gap: "20px !important",
  },
  propertySectionImg: {
    width: "150px !important",
    height: "80px !important",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover !important",
      borderRadius: "6px !important",
      marginBottom: "15px !important",
    },
  },
});
