import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Box, MenuItem } from "@mui/material";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { updatePhoneExtension } from "../../../redux/phoneConfiguration/extensions";

function UpdatePhoneExtension(props) {
  let { open, setOpen, classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const [phoneDetail, setPhoneDetail] = useState({
    name: "",
    user_id: "",
    direct_number: "",
    id: "",
  });
  const updateExtension = useSelector((item) => item.phoneConfiguration.PhoneExtensions.updateExtension);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);
  const handleUpdateDetail = (field, value) => {
    setPhoneDetail({ ...phoneDetail, [field]: value });
    setErrMsg({});
  };

  useEffect(() => {
    if (open) {
      setPhoneDetail({
        name: open.name,
        user_id: open.user_id,
        id: open.id,
        direct_number: open.direct_number,
      });
    }
  }, [open]);

  useEffect(() => {
    if (updateExtension.success && !updateExtension.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully update the phone extension",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setPhoneDetail({
        name: "",
        user_id: "",
      });
    } else if (!updateExtension.isLoading && updateExtension.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateExtension.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateExtension.success]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!phoneDetail.name) {
      errorMsg["name"] = "Please enter extension name";
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter extension name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!phoneDetail.user_id) {
      isFormValid = false;
      errorMsg["user_id"] = "Please select the user";
      setErrorAlert({
        errorMsg: "Please select the user",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSaveExtension = () => {
    if (handleValidate()) {
      dispatch(updatePhoneExtension(phoneDetail));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.AddPhoneHeading} sx={{ mt: "10px", mb: "15px" }} id="alert-dialog-title">
          <Box> Update Extension</Box>
        </DialogTitle>
        <DialogContent sx={{ width: "600px", overflow: "visible" }}>
          <Grid container direction="row" spacing={6} sx={{ overflow: "visible" }}>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Select User
              </InputLabel>
              <TextField
                value={phoneDetail.user_id}
                onChange={(e) => handleUpdateDetail("user_id", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                variant="standard"
                size="small"
                select
                error={errMsg.user_id}
                name="phone"
                autoComplete={false}
                id="user-phone"
                helperText={errMsg.user_id}
              >
                {storedListOfUsers?.list &&
                  storedListOfUsers?.list?.length &&
                  storedListOfUsers?.list?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.first_name} {item.last_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Extension Name
              </InputLabel>
              <TextField
                value={phoneDetail.name}
                onChange={(e) => handleUpdateDetail("name", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Extension Name"}
                variant="standard"
                size="small"
                error={errMsg.name}
                helperText={errMsg.name}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Direct Number
              </InputLabel>
              <TextField
                value={phoneDetail.direct_number}
                onChange={(e) => handleUpdateDetail("direct_number", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Direct Number"}
                variant="standard"
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: "30px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSaveExtension()}
            classes={{ root: classes.AddPhoneButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={updateExtension.isLoading}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "UpdatePhoneExtensionStyle" })(UpdatePhoneExtension);
