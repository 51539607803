import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import { updateTransactionContract } from "../../../redux/transactions/updateTransactionContract";
import momentTZ from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "25px!important",
    margin: "5px 0px!important",
  },
  field: {
    border: "none!important",
    borderBottom: "1px solid #000000DE!important",
  },
  autocompleteOption: {
    padding: "15px",
    margin: "10px",
    fontFamily: "AvenirNext !important",
  },
  optionHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px",
  },
  optionHeaderSpan: {
    fontFamily: "AvenirNext !important",
    fontSize: "14px",
  },
  optionSubHeaderLeft: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
  optionSubHeaderRight: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
}));

function EditDateModal(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const muiClasses = useStyles();

  let { open, classes, ContractData, closeModal, selectedDateToEdit } = props;
  const ListingData = useSelector((state) => state.listings.listingDetail.ListingData);

  const [errorAlert, setErrorAlert] = React.useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [requireAmendment, setRequireAmendment] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  const updateContract = useSelector((state) => state.transactions.TransactionsContract.updateContract);

  React.useEffect(() => {
    return () => {
      setFormData({});
      setRequireAmendment(false);
    };
  }, []);

  React.useEffect(() => {
    setFormData({
      date: momentTZ.utc(selectedDateToEdit.date).format("MM/DD/YYYY"),
    });
  }, [selectedDateToEdit]);

  React.useEffect(() => {
    if (updateContract?.success && !updateContract.isLoading && open) {
      setErrorAlert({ errorMsg: "You have successfully updated the date", errorType: "success", isOpen: true });
      closeModal();
    }
  }, [updateContract]);

  const handleUpdateDate = () => {
    const date = {
      [selectedDateToEdit.key]: momentTZ.utc(formData.date).format("MM/DD/YYYY"),
    };

    dispatch(
      updateTransactionContract({ id, contractData: { ...ContractData, ...date }, requireAmendment, documentName: selectedDateToEdit.documentName })
    );
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => closeModal()}
        aria-labelledby="edit-important-date-dialog-title"
        aria-describedby="edit-important-date-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={muiClasses.modalHeader} id="edit-important-date-dialog">
          Edit Important Date
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center" marginTop={"10px"}>
              <Grid item xs={3}>
                <Typography variant="h6">{selectedDateToEdit.label}</Typography>
              </Grid>
              <Grid item xs={4}>
                <DesktopDatePicker
                  label="Date"
                  variant="standard"
                  value={formData.date || ""}
                  onChange={(val) => setFormData((prevForm) => ({ ...prevForm, date: val }))}
                  renderInput={(params) => <TextField {...params} error={false} />}
                />
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Switch defaultChecked={requireAmendment} checked={requireAmendment} onChange={(e) => setRequireAmendment(e.target.checked)} />
                  }
                  label="Change Requires Amendment"
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => closeModal()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleUpdateDate()}
            classes={{ root: classes.saveModalButton }}
            color="inherit"
            autoFocus
            loading={updateContract.isLoading}
            loadingPosition="start"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNoteModalStyle" })(EditDateModal);
