import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getCategoryTemplates } from "../../../../redux/marketingCenter/digital";
import { useSelector, useDispatch } from "react-redux";
import CategoriesSkelton from "../../skelton/categories";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
function DigitalSubCategories(props) {
  let { classes } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const templates = useSelector(
    (state) => state.marketingCenter.digital.templates
  );

  useEffect(() => {
    dispatch(getCategoryTemplates({ categoryId: id }));
  }, [id]);

  const filterSubCategories = () => {
    return templates?.data?.filter((it) => it.category?._id === id);
  };
  return (
    <Box className={classes.categorySectionArea}>
      <Box className={classes.categoryHeader}>
        <Box
          onClick={() => navigate("/marketing_center/digital")}
          className={classes.categoryHeaderLeft}
        >
          <KeyboardBackspaceIcon />
          <Typography className={classes.categoryHeaderLeftContent}>
            Back to Digital
          </Typography>
        </Box>
        {templates?.data?.length ? (
          templates?.data[0]?.category.name === "Social Media" ? (
            <Typography className={classes.categoryHeaderRight}>
              Social Media Assets
            </Typography>
          ) : templates?.data[0]?.category.name === "Eblast" ? (
            <Typography className={classes.categoryHeaderRight}>
              eBlasts <span> (Marketing Emails)</span>
            </Typography>
          ) : (
            <Typography className={classes.categoryHeaderRight}>
              {templates?.data[0]?.category.name}
            </Typography>
          )
        ) : null}
      </Box>
      {templates?.isLoading ? (
        <CategoriesSkelton count={8} width="285px" />
      ) : templates?.data?.length && filterSubCategories()?.length ? (
        <Box className={classes.categorySection}>
          {templates?.data?.map((item, index) => (
            <Box
              onClick={() =>
                navigate(`/marketing_center/digital/template/${item?._id}`)
              }
              key={index}
              className={classes.categorySectionItem}
            >
              <Box className={classes.categorySectionItemTop}>
                <img src={item.sub_category?.image} alt="categoryIcon" />
              </Box>
              <Typography className={classes.categorySectionItemContent}>
                {item.title}
              </Typography>
            </Box>
          ))}
        </Box>
      ) :!templates?.isLoading? (
        <Box className={classes.categorySectionItem}>
          <Box
            className={classes.categorySectionItemTop}
            sx={{ alignItems: "center !important" }}
          >
            <ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "grey" }} />
          </Box>
          <Typography className={classes.digitalContentSectionItemContent}>
            {" "}
            Not Exists
          </Typography>
        </Box>
      ):null}
    </Box>
  );
}

export default withStyles(styleSheet, { name: "DigitalSubCategoriesStyles" })(
  DigitalSubCategories
);
