import React, { useContext, useEffect, useRef, useState } from "react";
import momentTZ from "moment-timezone";
import {
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  IconButton,
  Chip,
  Checkbox,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemButton,
  Paper,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  AvatarGroup,
  Card,
} from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import { AddTasksModal } from "../../../common/Modal/addTasksModal";
import { AddTagsModal } from "../../../common/Modal/addTagsModal";
import { AddAppointmentsModal } from "../../../common/Modal/addAppointmentsModal";
import { modalSubmitSuccess } from "../../../../../redux/agents/getAgentProfile";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { Box } from "@mui/system";
import { AppContext } from "../../../../../components/callPanel/callContext";
import { getLeadTasksAPI } from "../../../../../redux/leads/getLeadTasksAPI";
import { getLeadAppointmentsAPI } from "../../../../../redux/leads/getLeadAppointmentsAPI";
import Avatar from "../../../../../components/uiElements/avatar/index";
import { uploadLeadsFile } from "../../../../../redux/leads/uploadLeadsFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { StyledBadge } from "../../../../../components/topNavBar/index";
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import faceBook from "../../../../../assets/images/social/faceBook.png";
import linkdinIcon from "../../../../../assets/images/social/linkdinIcon.png";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import PersonIcon from "@mui/icons-material/Person";
import Button from "../../../../../components/uiElements/button";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Dropzone from "react-dropzone";
import dragIcon from "../../../../../assets/dragIcon.png";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { updateLeadProfileAPI } from "../../../../../redux/leads/updateLeadProfileAPI";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
const StateLoader = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={22} sx={{ color: "#0B0909" }} />
    </Box>
  );
};
export const NameCard = ({
  selectedUser = {},
  isAllowRedirect,
  setErrorAlert,
  isAllowJoinDate,
}) => {
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const firstNameRef = useRef();
  const lastNameRef = useRef();

  const handleUpdate = async () => {
    if (
      firstNameRef.current?.value !== selectedUser?.firstName ||
      lastNameRef.current?.value !== selectedUser?.lastName
    ) {
      const data = {
        schema: {
          firstName: firstNameRef.current?.value,
          lastName: lastNameRef.current?.value,
        },
        leadId: selectedUser.id,
        handleSuccess,
        handleError,
      };

      await dispatch(updateLeadProfileAPI(data));
      setIsEditable(false);
    } else {
      setIsEditable(false);
    }
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the name",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  return (
    <Paper
      onClick={() => {
        if (isAllowRedirect) {
          if (selectedUser?.realtor_agent_id) {
            navigate(
              `/tools/recruiting/details/${selectedUser?.realtor_agent_id}`
            );
          }
          if (selectedUser?.lead_id) {
            navigate(`/leads/people/details/${selectedUser?.lead_id}`);
          }
        }
      }}
      variant="outlined"
      sx={{
        backgroundColor: "white",
        padding: "15px",
        cursor: isAllowRedirect ? "pointer" : "",
        borderRadius: "10px",
      }}
    >
      <List disablePadding>
        <ListItem disablePadding alignItems="flex-start">
          <ListItemAvatar>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
                color: "#2E7D32",
              }}
              variant={"dot"}
              color={"success"}
              customColor={
                selectedUser?.lastActivity
                  ? momentTZ().diff(
                      momentTZ(selectedUser?.lastActivity),
                      "minutes"
                    ) < 5
                    ? "#2E7D32"
                    : "#A4ABB6"
                  : "#A4ABB6"
              }
            >
              <Avatar sx={{ fontSize: "16px" }} src={selectedUser?.profile_img}>
                {`${selectedUser?.firstName?.slice(0, 1) || ""}${
                  selectedUser.lastName?.slice(0, 1) || ""
                }`}
              </Avatar>
            </StyledBadge>
          </ListItemAvatar>
          <ListItemText
            primary={
              isEditable ? (
                <Card
                  sx={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    gap: "0px 5px",
                    alignItems: "center",
                    mt: "-10px",
                  }}
                  variant="outlined"
                >
                  <TextField
                    fullWidth
                    size="small"
                    defaultValue={selectedUser?.firstName}
                    placeholder="First Name"
                    sx={{
                      "& input": {
                        fontSize: "13px",
                        padding: "4px 6px !important",
                      },
                    }}
                    inputRef={firstNameRef}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Last Name"
                    defaultValue={selectedUser?.lastName}
                    inputRef={lastNameRef}
                    sx={{
                      "& input": {
                        fontSize: "13px",
                        padding: "4px 6px !important",
                      },
                    }}
                  />
                  <CheckCircleIcon
                    sx={{ color: "#7bd279", cursor: "pointer" }}
                    onClick={handleUpdate}
                  />
                  <CancelIcon
                    onClick={() => {
                      setIsEditable(false);
                    }}
                    sx={{ color: "#e05353", cursor: "pointer" }}
                  />
                </Card>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    "& .MuiButtonBase-root": { display: "none" },
                    "&:hover": {
                      "& .MuiButtonBase-root": { display: "block" },
                    },
                  }}
                >
                  {selectedUser.firstName +
                    " " +
                    (selectedUser?.lastName || "")}
                  <IconButton
                    sx={{ position: "absolute", top: "0px", right: "0px" }}
                    size="small"
                    onClick={() => {
                      setIsEditable(true);
                    }}
                  >
                    <EditIcon
                      fontSize="small"
                      sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                    />
                  </IconButton>
                </Box>
              )
            }
            sx={{
              "& 	.MuiListItemText-primary": {
                fontSize: "19.2px",
                paddingLeft: isEditable ? "0px" : "5px",
                "&:hover": { background: "rgba(217, 217, 217, 0.25)" },
              },
              "& 	.MuiListItemText-secondary": {
                paddingLeft: isEditable ? "0px" : "5px",
              },
            }}
            secondary={
              isAllowJoinDate ? (
                `Joined ${momentTZ
                  .tz(selectedUser?.join_date, process.env.REACT_APP_TIMEZONE)
                  .format("MMMM YYYY")}`
              ) : selectedUser?.lastCommunication ? (
                <React.Fragment>
                  {" "}
                  Last Communication{" "}
                  {selectedUser?.lastCommunication &&
                  selectedUser?.lastCommunication !== "null"
                    ? momentTZ
                        .tz(
                          JSON.parse(selectedUser?.lastCommunication)?.date,
                          process.env.REACT_APP_TIMEZONE
                        )
                        .fromNow()
                    : ""}{" "}
                </React.Fragment>
              ) : null
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
};
export const ContactDetails = ({
  list,
  name,
  lead_id,
  disableUpdate,
  handleCallEvent,
}) => {
  const { dialNumber } = useContext(AppContext);
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: "white",
        padding: "6px 0px",
        borderRadius: "10px",
      }}
    >
      {list?.map((item) => (
        <List disablePadding>
          {item.value?.length ? (
            item.value?.map((option, index) =>
              option.value ? (
                <ListItem
                  disablePadding
                  sx={{
                    "&:hover": {
                      "& .MuiListItemSecondaryAction-root": {
                        display: disableUpdate
                          ? "none !important"
                          : "block !important",
                      },
                    },
                  }}
                  dense
                >
                  <ListItemButton
                    onClick={() => {
                      if (
                        (!item.value || item.value?.length === 0) &&
                        !disableUpdate
                      ) {
                        item.onClick();
                      }
                    }}
                    disableRipple
                  >
                    <ListItemSecondaryAction sx={{ display: "none" }}>
                      <Stack direction={"row"} spacing={0}>
                        {item.value ? (
                          <CopyToClipboard
                            onCopy={() =>
                              toast.success("Copied Successfully!", {
                                position: "top-right",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                              })
                            }
                            text={
                              item.type === "address"
                                ? `${option?.street || ""}, ${
                                    option?.state || ""
                                  }, ${option?.country || ""}`
                                : option.value
                            }
                          >
                            <IconButton sx={{ padding: "3px" }}>
                              <ContentCopyRoundedIcon fontSize="small" />
                            </IconButton>
                          </CopyToClipboard>
                        ) : null}
                        {item.value ? (
                          <IconButton
                            sx={{ padding: "3px" }}
                            onClick={item.onClick}
                            edge="end"
                            aria-label="comments"
                          >
                            <CreateRoundedIcon fontSize="small" />
                          </IconButton>
                        ) : null}
                      </Stack>
                    </ListItemSecondaryAction>
                    <ListItemIcon sx={{ minWidth: "37px" }}>
                      {item.type === "phone" ? (
                        <LocalPhoneIcon
                          onClick={() => {
                            if (item.type === "phone") {
                              if (option.value) {
                                dialNumber(
                                  option.value,
                                  null,
                                  name,
                                  null,
                                  lead_id
                                );
                                if (handleCallEvent) {
                                  handleCallEvent();
                                }
                              }
                            }
                          }}
                          sx={{
                            fontSize: 22,
                            color:
                              index > 0 ? "transparent" : "rgba(0, 0, 0, 0.54)",
                          }}
                        />
                      ) : item.type === "email" ? (
                        <EmailIcon
                          sx={{
                            fontSize: 22,
                            color:
                              index > 0 ? "transparent" : "rgba(0, 0, 0, 0.54)",
                          }}
                        />
                      ) : item.type === "address" ? (
                        <HomeIcon
                          sx={{
                            fontSize: 25,
                            ml: "-2px",
                            color: "rgba(0, 0, 0, 0.54)",
                            color:
                              index > 0 ? "transparent" : "rgba(0, 0, 0, 0.54)",
                          }}
                        />
                      ) : null}
                    </ListItemIcon>
                    <ListItemText
                      onClick={() => {
                        if (item.type === "phone") {
                          if (option.value) {
                            dialNumber(option.value, null, name, null, lead_id);
                            if (handleCallEvent) {
                              handleCallEvent();
                            }
                          }
                        }
                      }}
                      sx={{ maxWidth: "70%", overflow: "hidden" }}
                    >
                      {item.type === "address" ? (
                        <CustomTooltip
                          title={`${option?.street || null}, ${
                            option?.city ? `${option?.city}, ` : null
                          }  ${option?.code ? `${option?.code}` : null}`}
                          placement="top"
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            {option?.street || null},{" "}
                            {option?.city ? `${option?.city}, ` : null}{" "}
                            {option?.code ? `${option?.code}` : null}
                            {index + 1 === item.value.length ? null : <br />}
                          </span>
                        </CustomTooltip>
                      ) : (
                        <>
                          <CustomTooltip title={option.value} placement="top">
                            <span
                              style={{
                                textDecoration: option.bad_number
                                  ? "line-through"
                                  : "",
                                color: option.bad_number ? "red" : "",
                              }}
                            >
                              {option.value}
                            </span>
                          </CustomTooltip>{" "}
                          {item.type === "phone" && option.type ? (
                            <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                              ({option.type})
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ) : null
            )
          ) : disableUpdate ? null : (
            <ListItem disablePadding dense sx={{ mt: "0px" }}>
              <ListItemButton
                onClick={() => {
                  if (!item.value || item.value?.length === 0) {
                    item.onClick();
                  }
                }}
                disableRipple
                alignItems={"center"}
              >
                <ListItemIcon sx={{ minWidth: "37px" }}>
                  {item.type === "phone" ? (
                    <LocalPhoneIcon
                      sx={{
                        fontSize: 22,
                        color: "rgba(0, 0, 0, 0.54)",
                        marginRight: "14px",
                      }}
                    />
                  ) : item.type === "email" ? (
                    <EmailIcon
                      sx={{
                        fontSize: 22,
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                  ) : item.type === "address" ? (
                    <HomeIcon
                      sx={{
                        fontSize: 25,
                        ml: "-2px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                  ) : null}
                </ListItemIcon>
                <ListItemText>Add {item.type}</ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      ))}
    </Paper>
  );
};

export const Details = ({
  list,
  title,
  disablePadding,
  justifyContent,
  divider,
  removeBottomSpace,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: divider ? "8px 16px" : "",
      }}
    >
      <List disablePadding sx={{ mb: removeBottomSpace ? "-23px" : "" }}>
        <ListItem disablePadding={divider ? true : false}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ fontSize: "18px", padding: divider ? "0px" : "" }}
          >
            {title}
          </Typography>
        </ListItem>
        {list.map((item, index) => (
          <ListItem
            alignItems={"center"}
            sx={{
              paddingTop: disablePadding ? "4px" : "",
              paddingBottom: disablePadding ? "4px" : "",
              justifyContent: justifyContent ? justifyContent : "",
              paddingLeft: divider ? "0px" : "",
              paddingRight: divider ? "0px" : "",
            }}
            divider={divider && list?.length - 1 !== index ? true : false}
          >
            <Typography
              sx={{ whiteSpace: "nowrap" }}
              variant="subtitle2"
              component="p"
            >
              {item.key}:
            </Typography>
            &nbsp; &nbsp;
            <Typography variant="p" component="p" sx={{ fontSize: "14px" }}>
              {item.value}
            </Typography>
          </ListItem>
        ))}
        <br />
      </List>
    </Paper>
  );
};

export const TagsCard = ({ prevTags, removeTags, ...props }) => {
  const [addTagsModal, setAddTagsModal] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);
  const dispatch = useDispatch();
  return (
    <>
      {addTagsModal ? (
        <AddTagsModal
          {...props}
          state={addTagsModal}
          prevTags={prevTags?.map((t) => t.tag)}
          closeModal={() => {
            dispatch(modalSubmitSuccess());
            setAddTagsModal(false);
          }}
        />
      ) : null}
      <Paper
        variant="outlined"
        sx={{ backgroundColor: "white", padding: "15px", borderRadius: "10px" }}
      >
        <List disablePadding>
          <ListItem disablePadding sx={{ mt: "-5px" }}>
            <Typography variant="h6" component="h6" sx={{ fontSize: "18px" }}>
              Tags
              <IconButton
                color="primary"
                aria-label="Add Tags"
                component="span"
                onClick={() => setAddTagsModal(true)}
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
          {prevTags && prevTags.length ? (
            <ListItem
              disablePadding
              sx={{ flexWrap: "wrap", alignItems: "flex-start !important" }}
            >
              {prevTags.slice(0, isShowMore ? 5 : 1000).map((tag, i) => {
                return (
                  <Chip
                    sx={{ margin: "4px !important", marginBottom: "2px" }}
                    label={tag.tag}
                    onDelete={(e) => {
                      removeTags(
                        prevTags
                          .filter((t) => t.id !== tag.id)
                          .map((mp) => mp.tag)
                      );
                    }}
                    key={i}
                  />
                );
              })}
              {prevTags.length > 5 && isShowMore ? (
                <Chip
                  onClick={() => {
                    setIsShowMore(false);
                  }}
                  variant="outlined"
                  sx={{
                    margin: "4px !important",
                    marginBottom: "2px",
                    cursor: "pointer",
                  }}
                  label={`See ${prevTags.length - 5} More`}
                />
              ) : null}
              <br />
              <br />
            </ListItem>
          ) : (
            <Typography
              sx={{
                color: "#9c9c9c",
                fontFamily: "AvenirNext-400",
                fontSize: "14px",
                mt: "12px",
                paddingBottom: "6px",
              }}
            >
              No Tags found!
            </Typography>
          )}
        </List>
      </Paper>
    </>
  );
};
export const BackgroundCard = ({ text, handleSaveBackground }) => {
  const [openAddBackground, setOpenAddBackground] = useState(false);
  const [backgroundLabel, setBackgroundLabel] = useState("");
  const [isValidate, setValidate] = useState(true);
  const [backgroundContent, setBackgroundContent] = useState(text);

  const saveBackgrounds = () => {
    if (backgroundLabel) {
      handleSaveBackground(backgroundLabel);
      setOpenAddBackground(false);
      setBackgroundContent(backgroundLabel);
    } else {
      setValidate(false);
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: "white",
        paddingBottom: "15px",
        borderRadius: "10px",
      }}
    >
      <List disablePadding>
        <ListItem
          disablePadding
          sx={{ mt: "-5px", padding: "15px", paddingBottom: "6px" }}
        >
          <Typography variant="h6" component="h6" sx={{ fontSize: "18px" }}>
            Background
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            "&:hover": {
              "& .MuiListItemSecondaryAction-root": {
                display: "block !important",
              },
            },
          }}
          disablePadding
          onClick={() => {
            if (!backgroundContent && !openAddBackground) {
              setOpenAddBackground(true);
            }
          }}
        >
          {openAddBackground ? (
            <Box sx={{ width: "100%", padding: "15px" }}>
              <TextField
                multiline
                value={backgroundLabel}
                onChange={(e) => {
                  setBackgroundLabel(e.target.value);
                  setValidate(true);
                }}
                rows={6}
                fullWidth
                placeholder="Label"
                error={isValidate ? false : true}
                helperText={
                  isValidate ? "" : "Please enter the background content"
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: " flex-end",
                  paddingTop: "5px",
                }}
              >
                <IconButton size="small">
                  <CancelIcon
                    onClick={() => {
                      setValidate(true);
                      setOpenAddBackground(false);
                    }}
                    sx={{ color: "black" }}
                  />
                </IconButton>
                <IconButton size="small">
                  <CheckCircleIcon
                    sx={{ color: "#42b00c", cursor: "pointer" }}
                    onClick={saveBackgrounds}
                  />
                </IconButton>
              </Box>
            </Box>
          ) : null}
          {!openAddBackground ? (
            <ListItemButton
              sx={{
                fontSize: text ? "14px" : "16px",
                fontFamily: "AvenirNext-400",
                paddingRight: "30px",
              }}
              disableRipple
            >
              {backgroundContent ? (
                backgroundContent
              ) : (
                <Typography
                  sx={{
                    color: "#9c9c9c",
                    fontFamily: "AvenirNext-400",
                    fontSize: "14px",
                  }}
                >
                  Add Background
                </Typography>
              )}

              {backgroundContent ? (
                <ListItemSecondaryAction
                  sx={{ display: "none", position: "absolute", top: "20px" }}
                >
                  <IconButton
                    onClick={() => {
                      setOpenAddBackground(true);
                      setBackgroundLabel(backgroundContent);
                    }}
                    sx={{ padding: "3px" }}
                    edge="end"
                    aria-label="Background"
                  >
                    <CreateRoundedIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
            </ListItemButton>
          ) : null}
        </ListItem>
      </List>
    </Paper>
  );
};

export const SocialCard = ({ socialData }) => {
  return (
    <Paper
      variant="outlined"
      sx={{ backgroundColor: "white", padding: "15px", borderRadius: "10px" }}
    >
      <List disablePadding>
        <ListItem disablePadding sx={{ mt: "-5px" }}>
          <Typography variant="h6" component="h6" sx={{ fontSize: "18px" }}>
            Social
          </Typography>
        </ListItem>
        {(socialData?.company && socialData?.title) ||
        socialData?.linkedIn ||
        socialData?.facebook ? (
          <Box>
            {socialData?.company ? (
              <Typography
                sx={{ fontFamily: "AvenirNext-400", fontSize: "14px" }}
              >
                <Box sx={{ fontWeight: "600" }}>Company Name :</Box>
                {socialData?.company}
              </Typography>
            ) : null}
            {socialData?.title ? (
              <Typography
                sx={{ fontFamily: "AvenirNext-400", fontSize: "14px" }}
              >
                <Box sx={{ fontWeight: "600" }}>Title:</Box> {socialData?.title}
              </Typography>
            ) : null}
            <br />
            <Stack direction={"row"} spacing={1}>
              {socialData?.linkedIn ? (
                <a href={socialData?.linkedIn} rel="noreferrer" target="_blank">
                  <Chip
                    sx={{ cursor: "pointer" }}
                    avatar={<Avatar alt="Natacha" src={linkdinIcon} />}
                    label="linkdin"
                    variant="outlined"
                  />
                </a>
              ) : null}
              {socialData?.facebook ? (
                <a href={socialData?.facebook} rel="noreferrer" target="_blank">
                  <Chip
                    sx={{ cursor: "pointer" }}
                    avatar={<Avatar alt="Natacha" src={faceBook} />}
                    label="faceBook"
                    variant="outlined"
                  />
                </a>
              ) : null}
            </Stack>
          </Box>
        ) : (
          <Typography
            sx={{
              color: "#9c9c9c",
              fontFamily: "AvenirNext-400",
              fontSize: "14px",
              mt: "12px",
              paddingBottom: "6px",
            }}
          >
            No Social data found!
          </Typography>
        )}
      </List>
    </Paper>
  );
};

export const TasksCard = ({ tasks, markTaskAsComplete, ...props }) => {
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [initialValue, setInitialValue] = useState({});
  const [completeTask, setCompleteTask] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [isOpenCompletedTask, setIsOpenCompletedTask] = useState(false);
  const dispatch = useDispatch();

  const { leadProfile } = useSelector((item) => item);

  const fetch = async () => {
    await dispatch(getLeadTasksAPI({}));
  };

  useEffect(() => {
    fetch();
  }, []);

  const editItem = (task) => () => {
    setInitialValue(task);
    setActionType("Update");
    setAddTaskModal(true);
    setTaskId(task?.id);
  };

  const addTaskFunc = () => {
    setAddTaskModal(true);
    setActionType("");
    setInitialValue({});
  };

  const completeTaskFunc = (task, isCompleted) => () => {
    setCompleteTask(task.id);
    markTaskAsComplete(task.id, isCompleted);
  };

  return (
    <>
      <AddTasksModal
        state={addTaskModal}
        initialValue={initialValue}
        taskId={taskId}
        type={actionType}
        {...props}
        closeModal={() => {
          dispatch(modalSubmitSuccess());
          setAddTaskModal(false);
        }}
      />
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: "white",
          paddingBottom: "5px",
          mt: "0px !important",
          borderRadius: "10px",
        }}
      >
        <List disablePadding sx={{ padding: "15px", paddingBottom: "0px" }}>
          <ListItem disablePadding sx={{ mt: "-5px" }}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <TaskAltRoundedIcon
                sx={{ color: "rgba(0, 0, 0, 0.54)", mt: "-4px" }}
              />
            </ListItemIcon>
            <Typography variant="h6" component="h6" sx={{ fontSize: "18px" }}>
              Tasks
              <IconButton
                color="primary"
                aria-label="Add Tags"
                component="span"
                onClick={addTaskFunc}
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
        </List>
        {leadProfile?.lead_tasks?.status?.pending ? (
          <StateLoader />
        ) : leadProfile?.list?.data?.lead_tasks &&
          leadProfile?.list?.data?.lead_tasks.length > 0 ? (
          <List disablePadding>
            {leadProfile?.list?.data?.lead_tasks
              ?.filter((el) => !el?.isCompleted)
              .map((tsk, i) => {
                return (
                  <ListItem disablePadding key={i}>
                    <ListItemButton
                      sx={{ padding: "5px 15px 5px 20px" }}
                      dense
                      alignItems="flex-start"
                    >
                      <ListItemIcon
                        sx={{
                          justifyContent: "left !important",
                          "& .MuiCheckbox-root": { mt: "-12px" },
                          minWidth: "30px",
                        }}
                      >
                        <Checkbox
                          edge="start"
                          disableRipple
                          checked={false}
                          onChange={completeTaskFunc(tsk)}
                        />
                      </ListItemIcon>
                      <ListItemText
                        onClick={editItem(tsk)}
                        id={tsk.id}
                        primary={tsk.name}
                        secondary={
                          <Box
                            sx={{
                              color: "rgba(0, 0, 0, 0.6)",
                              fontFamily: "AvenirNext-400",
                              fontSize: "12px",
                              mt: "8px",
                            }}
                          >
                            {tsk.dueDate ? (
                              <Stack direction={"row"} alignItems={"center"}>
                                <AccessTimeRoundedIcon
                                  sx={{ mr: "5px", fontSize: "1rem" }}
                                  fontSize="small"
                                />{" "}
                                <Box
                                  sx={{
                                    color:
                                      new Date() > new Date(tsk.dueDate)
                                        ? "#FF9800"
                                        : "",
                                  }}
                                >
                                  {momentTZ
                                    .tz(
                                      tsk.dueDate,
                                      process.env.REACT_APP_TIMEZONE
                                    )
                                    .format("MMM Do YYYY")}
                                </Box>
                              </Stack>
                            ) : null}

                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              sx={{ mt: "4px" }}
                            >
                              <PersonIcon
                                fontSize="small"
                                sx={{ mr: "5px", fontSize: "1.05rem" }}
                              />
                              {tsk.AssignedTo}
                            </Stack>
                          </Box>
                        }
                      ></ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            {leadProfile?.list?.data?.lead_tasks?.filter(
              (el) => el?.isCompleted
            )?.length ? (
              <Button
                variant="text"
                sx={{
                  color: "#03A9F4",
                  fontFamily: "AvenirNext-400",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  mt: "10px",
                  paddingLeft: "12px",
                  ml: "15px",
                  textTransform: "capitalize",
                }}
                onClick={() => setIsOpenCompletedTask(!isOpenCompletedTask)}
              >
                Completed Tasks{" "}
                {isOpenCompletedTask ? (
                  <KeyboardArrowDownRoundedIcon sx={{ ml: "5px" }} />
                ) : (
                  <KeyboardArrowRightRoundedIcon sx={{ ml: "5px" }} />
                )}
              </Button>
            ) : null}

            {isOpenCompletedTask
              ? leadProfile?.list?.data?.lead_tasks
                  ?.filter((el) => el?.isCompleted)
                  .map((tsk, i) => {
                    return (
                      <ListItem disablePadding key={i}>
                        <ListItemButton
                          sx={{ padding: "5px 15px 5px 20px" }}
                          role=""
                          dense
                          alignItems="flex-start"
                        >
                          <ListItemIcon
                            sx={{
                              justifyContent: "left !important",
                              minWidth: "30px",
                              "& .Mui-checked": {
                                color: "rgba(2, 136, 209, 0.54)",
                                mt: "-12px",
                              },
                            }}
                          >
                            <Checkbox
                              edge="start"
                              disableRipple
                              checked={tsk.isCompleted}
                              onChange={completeTaskFunc(tsk, true)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            onClick={editItem(tsk)}
                            id={tsk.id}
                            primary={tsk.name}
                            secondary={
                              <Box
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontFamily: "AvenirNext-400",
                                  fontSize: "12px",
                                  mt: "8px",
                                }}
                              >
                                <Stack direction={"row"} alignItems={"center"}>
                                  <AccessTimeRoundedIcon
                                    sx={{ mr: "5px" }}
                                    fontSize="small"
                                  />{" "}
                                  <Box
                                    sx={{
                                      color: "#676767",
                                    }}
                                  >
                                    {tsk.dueDateTime
                                      ? momentTZ
                                          .tz(
                                            tsk.dueDate,
                                            process.env.REACT_APP_TIMEZONE
                                          )
                                          .format("MMM Do YYYY - ")
                                      : momentTZ
                                          .tz(
                                            tsk.dueDate,
                                            process.env.REACT_APP_TIMEZONE
                                          )
                                          .format("MMM Do YYYY")}
                                    {tsk.dueDateTime
                                      ? momentTZ
                                          .tz(
                                            tsk.dueDateTime,
                                            process.env.REACT_APP_TIMEZONE
                                          )
                                          .format("hh:mma")
                                      : null}
                                  </Box>
                                </Stack>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  sx={{ mt: "4px" }}
                                >
                                  <PersonIcon
                                    fontSize="small"
                                    sx={{ mr: "5px" }}
                                  />
                                  {tsk.AssignedTo}
                                </Stack>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })
              : null}
          </List>
        ) : (
          <Typography
            sx={{
              color: "#9c9c9c",
              fontFamily: "AvenirNext-400",
              fontSize: "14px",
              mt: "12px",
              paddingBottom: "10px",
              paddingLeft: "15px",
            }}
          >
            No tasks found!
          </Typography>
        )}
      </Paper>
    </>
  );
};

export const AppointmentsCard = ({ ...props }) => {
  const [appointmentModal, setAddAppointmentModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();

  const editItem = (appt) => () => {
    setInitialValue(appt);
    setActionType("Update");
    setAddAppointmentModal(true);
  };
  const addAppointmentsFunc = () => {
    setAddAppointmentModal(true);
    setActionType("");
    setInitialValue({});
  };

  const { leadProfile } = useSelector((item) => item);

  const fetch = async () => {
    await dispatch(getLeadAppointmentsAPI({}));
  };

  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      <AddAppointmentsModal
        state={appointmentModal}
        initialValue={initialValue}
        type={actionType}
        {...props}
        closeModal={() => {
          dispatch(modalSubmitSuccess());
          setAddAppointmentModal(false);
        }}
      />
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: "white",
          mt: "0px !important",
          borderRadius: "10px",
        }}
      >
        <List disablePadding>
          <ListItem sx={{ paddingBottom: "0px" }}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <CalendarTodayRoundedIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 0.54)", mt: "-4px" }}
              />
            </ListItemIcon>
            <Typography variant="h6" component="h6" sx={{ fontSize: "18px" }}>
              Appointments
              <IconButton
                color="primary"
                component="span"
                onClick={addAppointmentsFunc}
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
          {leadProfile?.appointments?.status?.pending ? (
            <StateLoader />
          ) : leadProfile?.list?.data?.appointments?.length ? (
            leadProfile?.list?.data?.appointments.map((appt, i) => {
              return (
                <ListItem disablePadding key={i} onClick={editItem(appt)}>
                  <ListItemButton
                    role=""
                    onClick={() => null}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "14px",
                        fontFamily: "AvenirNext",
                        fontWeight: "600",
                        maxWidth: "20ch",
                      },
                      "& .MuiListItemText-secondary": {
                        fontSize: "12px",
                        fontFamily: "AvenirNext",
                      },
                    }}
                  >
                    <ListItemText
                      id="1"
                      primary={appt.title}
                      secondary={
                        <>
                          {momentTZ
                            .tz(
                              appt.start,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("MMM DD YYYY")}{" "}
                          -{" "}
                          {momentTZ
                            .tz(
                              appt.end,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("MMM DD YYYY")}
                          <br />
                          {momentTZ
                            .tz(
                              appt.start,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("hh:mma")}{" "}
                          -{" "}
                          {momentTZ
                            .tz(
                              appt.end,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("hh:mma")}
                        </>
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <AvatarGroup
                        max={4}
                        sx={{
                          "& .MuiAvatarGroup-avatar": {
                            width: "30px",
                            height: "30px",
                            fontSize: "13px",
                            opacity: 1,
                          },
                        }}
                        variant="circled"
                        spacing="small"
                      >
                        {appt?.appointment_invitees?.map((inv, i) => {
                          return (
                            <Tooltip title={inv?.name} placement="bottom">
                              <Avatar
                                sx={{ fontSize: "13px" }}
                                alt={inv.name}
                                src={inv.picture}
                                key={i}
                              >{`${inv.name?.slice(0, 1)}${inv.name
                                ?.split(" ")[1]
                                ?.slice(0, 1)}`}</Avatar>
                            </Tooltip>
                          );
                        })}
                      </AvatarGroup>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <Typography
              sx={{
                color: "#9c9c9c",
                fontFamily: "AvenirNext-400",
                fontSize: "14px",
                mt: "5px",
                paddingBottom: "15px",
                paddingLeft: "16px",
              }}
            >
              {" "}
              No appointments found!
            </Typography>
          )}
        </List>
      </Paper>
    </>
  );
};

export const FilesCard = ({ id, handleDeleteFile, classes, setErrorAlert }) => {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const { leadProfile } = useSelector((item) => item);
  const userList = useSelector((item) => item.users.storedListOfUsers);

  const uploadFile = (file) => {
    const fileUploaded = file;
    const data = {
      schema: {
        lead_id: id,
        attachments: fileUploaded,
      },
      handleSuccess,
      handleError,
    };

    dispatch(uploadLeadsFile(data));
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully uploaded the file",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const handleAddClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChangeDrop = (Files, data) => {
    uploadFile(Files[0]);
  };

  const handleDragEnter = () => {
    document.getElementById(`upload-file-for-lead`).style.border =
      "2px dotted black";
    document.getElementById(`upload-file-for-lead-icon`).style.display = "flex";
  };
  const handleDragLeave = () => {
    document.getElementById(`upload-file-for-lead`).style.border = "none";
    document.getElementById(`upload-file-for-lead-icon`).style.display = "none";
  };
  return (
    <Paper
      variant="outlined"
      sx={{ backgroundColor: "white", height: "auto", borderRadius: "10px" }}
    >
      <Box
        id="upload-file-for-lead"
        sx={{ margin: "6px", borderRadius: "6px" }}
      >
        <Dropzone
          accept={".pdf"}
          noClick={true}
          noKeyboard={true}
          onDragEnter={() => handleDragEnter()}
          onDragLeave={() => handleDragLeave()}
          onDrop={(acceptedFiles) => {
            handleFileChangeDrop(acceptedFiles);
            handleDragLeave();
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <List {...getRootProps()} disablePadding sx={{ margin: "-6px" }}>
              <Box
                id={`upload-file-for-lead-icon`}
                sx={{ top: "0px" }}
                className={classes.dropzoneArea}
              >
                <Box className={classes.dropzoneAreaInner}>
                  <img
                    src={dragIcon}
                    alt="dragIcon"
                    style={{ width: "25px", marginRight: "10px" }}
                  />{" "}
                  <Box>Drop file to upload.</Box>
                </Box>
              </Box>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "32.5px" }}>
                  <FilePresentRoundedIcon
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      mt: "-2px",
                      ml: "-3px",
                    }}
                  />
                </ListItemIcon>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "AvenirNext!important",
                    fontWeight: "600",
                    gap: "4px",
                    fontSize: "18px",
                  }}
                >
                  Files
                  <IconButton
                    color="primary"
                    aria-label="Add Tags"
                    component="span"
                    onClick={handleAddClick}
                  >
                    <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
                  </IconButton>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(event) => uploadFile(event.target.files[0])}
                    style={{ display: "none" }}
                  />
                </Typography>
              </ListItem>
              {leadProfile?.lead_files?.status?.pending ? (
                <StateLoader />
              ) : leadProfile?.list?.data?.lead_files &&
                leadProfile?.list?.data?.lead_files.length > 0 ? (
                leadProfile?.list?.data?.lead_files.map((file, i) => {
                  return (
                    <ListItem
                      disablePadding
                      key={1}
                      sx={{
                        display: "block",
                        "&:hover": {
                          "& .MuiListItemSecondaryAction-root": {
                            display: "block !important",
                          },
                        },
                      }}
                    >
                      <ListItemButton disableRipple alignItems="flex-start">
                        <ListItemSecondaryAction
                          sx={{
                            display: "none",
                            position: "absolute",
                            top: "20px",
                          }}
                        >
                          <IconButton
                            onClick={() => handleDeleteFile(file.id)}
                            sx={{ padding: "3px" }}
                            edge="end"
                            aria-label="Background"
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <ListItemIcon
                          onClick={() => window.open(file.filePath)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center !important",
                              width: "85px",
                              height: "95px",
                              border: "1px solid #000000",
                              borderRadius: "5px",
                            }}
                          >
                            {file.filePath.slice(-3) === "pdf" ||
                            file.filePath.slice(-3) === "PDF" ? (
                              <iframe
                                src={file.filePath}
                                style={{
                                  overflow: "hidden",
                                  cursor: "pointer",
                                }}
                                title={file.fileName}
                                height="100%"
                                width="100%"
                                scrolling="no"
                                onClick={() => window.open(file.filePath)}
                              />
                            ) : (
                              <Avatar
                                onClick={() => window.open(file.filePath)}
                                variant="rounded"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={file.filePath}
                              />
                            )}
                          </Box>
                        </ListItemIcon>
                        <ListItemText>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#0B0909",
                                fontSize: "17px",
                                fontWeight: "600",
                                fontFamily: "AvenirNext!important",
                                overflowWrap: "break-word",
                                mr: "10px",
                                overflowWrap: "break-word",
                              }}
                              onClick={() => window.open(file.filePath)}
                            >
                              {file.fileName}
                            </Typography>
                            <Typography
                              onClick={() => window.open(file.filePath)}
                              sx={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                              }}
                            >
                              {momentTZ
                                .utc(file.createdAt)
                                .format("MMM Do YYYY")}
                            </Typography>

                            <Typography
                              onClick={() => window.open(file.filePath)}
                              sx={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                              }}
                            >
                              by{" "}
                              {file.createdById
                                ? userList?.list.find(
                                    (item) =>
                                      item.id === Number(file.createdById || 1)
                                  )?.full_name
                                : ""}
                            </Typography>
                          </Box>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    color: "#9c9c9c",
                    fontFamily: "AvenirNext-400",
                    fontSize: "14px",
                    mt: "5px",
                    paddingBottom: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  No Files yet, drag and drop to upload
                </Typography>
              )}
            </List>
          )}
        </Dropzone>
      </Box>
    </Paper>
  );
};

export const CollaboratorsCard = ({
  collaborators,
  setOpenCollaborateModal,
  handleDeleteCollaborate,
  usersList,
  ...props
}) => {
  let { classes } = props;
  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: "white",
          paddingBottom: "5px",
          borderRadius: "10px",
        }}
      >
        <List disablePadding sx={{ padding: "15px", paddingBottom: "0px" }}>
          <ListItem disablePadding sx={{ mt: "-5px" }}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <PeopleAltRoundedIcon
                sx={{ color: "rgba(0, 0, 0, 0.54)", mt: "-6px" }}
              />
            </ListItemIcon>
            <Typography variant="h6" component="h6" sx={{ fontSize: "18px" }}>
              Collaborators
              <IconButton
                onClick={() => setOpenCollaborateModal(true)}
                aria-label="Add Tags"
                component="span"
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
        </List>
        {collaborators?.length ? (
          <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
            {collaborators.map((item, value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem
                  key={value}
                  disablePadding
                  className={classes.listItem}
                  sx={{
                    "&:hover": {
                      "& .MuiListItemSecondaryAction-root": {
                        display: "block !important",
                      },
                    },
                  }}
                >
                  <ListItemButton>
                    <ListItemAvatar sx={{ minWidth: "40px" }}>
                      <Avatar
                        sx={{ width: "30px", height: "30px", fontSize: "12px" }}
                        src={
                          item?.users_v2?.profile_images?.profile_img
                            ? item?.users_v2?.profile_images?.profile_img
                            : usersList.find((k) => k.id === item.id)
                                ?.profile_images?.profile_img
                        }
                      >
                        {`${item.name?.slice(0, 1)}${item.name
                          ?.split(" ")[1]
                          ?.slice(0, 1)}`}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={`${item.name}`} />
                    <ListItemSecondaryAction
                      sx={{ display: "none" }}
                      className={classes.listItemSecondaryAction}
                    >
                      <IconButton
                        onClick={() => handleDeleteCollaborate(item.id)}
                        aria-label="Comments"
                      >
                        <DeleteIcon sx={{ color: "grey" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography
            sx={{
              color: "#9c9c9c",
              fontFamily: "AvenirNext-400",
              fontSize: "14px",
              mt: "12px",
              paddingBottom: "10px",
              paddingLeft: "15px",
            }}
          >
            No Collaborators
          </Typography>
        )}
      </Paper>
    </>
  );
};
