/*
 * Global theme declaration
 */

import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";
const themes = createTheme();

export const theme = createTheme({
  // Colors
  typography: {
    h1: {
      fontSize: 28,
      fontWeight: 600,
      fontFamily: "AvenirNext",
      textTransform: "capitalize !important",
      lineHeight: 1.5,
      [themes.breakpoints.up("md")]: {
        fontSize: 24,
      },

      [themes.breakpoints.up("lg")]: {
        fontSize: 26,
      },

      [themes.breakpoints.up("xl")]: {
        fontSize: 28,
      },
    },
    h2: {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 600,
      fontFamily: "AvenirNext",
    },
    h3: {
      fontSize: 22,
      lineHeight: 1.5,
      fontWeight: 600,
      fontFamily: "AvenirNext",
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.5,
      fontWeight: 600,
      fontFamily: "AvenirNext",
    },
    h5: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 600,
      fontFamily: "AvenirNext",
    },
    h6: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600,
      fontFamily: "AvenirNext",
    },
    subtitle1: {
      fontSize: 22,
      lineHeight: 1.5,
      fontWeight: 400,
      fontFamily: "AvenirNext",
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 400,
      fontFamily: "AvenirNext",
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 600,
      fontFamily: "AvenirNext",
    },
    button: {
      fontWeight: 600,
      textTransform: "capitalize",
      fontFamily: "AvenirNext",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      xsm: 391,
      sm: 600,
      md: 900,
      lg: 1200,
      lgx: 1440,
      xl: 1536,
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#000",
    },
    secondary: {
      main: green[500],
    },
    timelineDots: {
      yellow: "#FDE386",
      purple: "#B575DC",
      sky: "#72ADF2",
      green: "#42AF0E",
    },
  },
});
