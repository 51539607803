import * as React from "react";
import { Box, Typography, Grid, Card, TextField, MenuItem } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";

const CoopAgent = (props) => {
  let { classes, updateContractDetail, contractDetail,errMsg } = props;
  return (
    <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible" }}>
      <Typography classes={{ root: classes.transactionFormCardHeading }}>Coop Agent</Typography>

      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={12} md={5}>
          <TextField fullWidth variant="standard" select defaultChecked="" helperText="Select Existing Coop">
            <MenuItem disabled value="">
              {" "}
              <em>Large Dropdown (select or type unique value) </em>
            </MenuItem>
            <MenuItem value="Method">Method</MenuItem>
            <MenuItem value="Coop Broker">Coop Broker</MenuItem>
            <MenuItem value="Closing Attorney">Closing Attorney</MenuItem>
          </TextField>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <TextField
            value={contractDetail.co_op_brokerage_name}
            onChange={(e) => updateContractDetail("co_op_brokerage_name")}
            fullWidth
            variant="standard"
            helperText="Coop Brokerage Name"
            error={errMsg["co_op_brokerage_name"]}
            id="co_op_brokerage_name"
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            value={contractDetail.co_op_agent_company}
            onChange={(e) => updateContractDetail("co_op_agent_company", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Coop Agent Company Name"
            error={errMsg["co_op_agent_company"]}
            id="co_op_agent_company"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.co_op_first_name}
            onChange={(e) => updateContractDetail("co_op_first_name", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Coop First Name"
            error={errMsg["co_op_first_name"]}
            id="co_op_first_name"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.co_op_last_name}
            onChange={(e) => updateContractDetail("co_op_last_name", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Coop Last Name"
            error={errMsg["co_op_last_name"]}
            id="co_op_last_name"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.co_op_phone}
            onChange={(e) => updateContractDetail("co_op_phone", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Coop Phone"
            error={errMsg["co_op_phone"]}
            id="co_op_phone"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.co_op_email}
            onChange={(e) => updateContractDetail("co_op_email", e.target.value)}
            fullWidth
            variant="standard"
            name="email"
            type="email"
            error={errMsg["co_op_email"]}
            helperText="Coop Email"
            id="co_op_email"
          />
        </Grid>
      </Grid>
    </Card>
  );
};
export default withStyles(styleSheet, { name: "ClosingAttorneyStyle" })(CoopAgent);
