import * as React from "react";
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions,
} from "@mui/material";
import Button from "../button";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from "@mui/system";

const Dialog = (props) => {
  const {
    dialogHead,
    dialogBody,
    dialogFooter,
    state,
    onDelete,
    onSave,
    type,
    closeModal,
    deleteBtnTxt,
    hideSaveButton,
    loading,
    buttonWidth,
    ...other
  } = props;

  return (
    <MuiDialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={state}
      {...other}
    >
      {dialogHead ? dialogHead : null}
      {dialogBody ? dialogBody : null}
      {loading ? (
        <DialogActions
          sx={{
            margin: "20px",
            justifyContent: "center",
            alignItems: "centter",
          }}
        >
          <CircularProgress size={"20px"} />
        </DialogActions>
      ) : (
        <DialogActions sx={{ margin: "20px", justifyContent: "space-between" }}>
          <Box>
            {onDelete && type === "Update" ? (
              <Button variant="outlined" startIcon={<DeleteIcon />} onClick={onDelete} color="error">
                {deleteBtnTxt ? deleteBtnTxt : "Delete"}
              </Button>
            ) : null}
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Button variant="outlined" themeVersion onClick={closeModal}>
              Cancel
            </Button>
            {hideSaveButton ? null : (
              <Button variant="contained" sx={{minWidth:buttonWidth?buttonWidth:""}} themeVersion onClick={onSave}>
                {type ? type : "Save"}
              </Button>
            )}
          </Box>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
