import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getLeadSources = createAsyncThunk("leadProfile/getLeadSources", async (data, thunkAPI) => {
  try {
    let headers = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    };
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/leads/sources?limit=1000&offset=0&sort=id`, headers);
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
