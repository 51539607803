export const styleSheet = (theme) => ({
  saveModalButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  CloseContractHeading: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customDialog: {
    zIndex: 100000,
    "&.MuiDialog-paper": {
      overflowY: "visible !important",
    },
  },
  uploadButton: {
    backgroundColor: "white !important",
    color: "#0B0909 !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    borderRadius: "15px  !important",
    border: "0.4px solid #000000 !important",
    marginTop: "5px !important",
    height: "30px !important",
    minWidth: "160px",
    padding: "5px 15px !important",
  },
  marketSection: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "0px 20px !important",
  },
});
