import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentRosterProfile } from "../../../../../redux/agents/agentRoster/updateProfile";
import { toast } from "react-toastify";

const BiographyDetails = ({ classes }) => {
  const toastId = React.useRef(null);
  const [isEditAble, setIsEditAble] = useState(false);
  const [tab, setTab] = useState("long");
  const [bioDetail, setBioDetail] = useState({
    id: "",
    bio: "",
    short_bio: "",
    cma_bio: "",
    type: "bio",
  });
  const dispatch = useDispatch();
  const agentRosterProfile = useSelector(
    (item) => item.agentRoster.agentRosterProfile
  );
  const { profile, updateLoading } = agentRosterProfile;
  const handleUpdateField = (field, value) => {
    setBioDetail({
      ...bioDetail,
      [field]: value,
    });
  };
  useEffect(() => {
    if (!bioDetail.id) {
      setBioDetail({
        id: profile.id,
        bio: profile.bio,
        short_bio: profile.short_bio,
        cma_bio: profile.cma_bio,
        type: "bio",
      });
    }
  }, [profile]);

  const handleSuccess = () => {
    if (!toast.isActive(toastId.current)) {
      toast.success("You have successfully update BIO", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsEditAble(false);
    }
    setIsEditAble(false);
  };
  const handleError = (error) => {
    if (!toast.isActive(toastId.current)) {
      toast.error(JSON.stringify(error), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSave = () => {
    if (bioDetail.bio || bioDetail.short_bio || bioDetail.cma_bio) {
      dispatch(
        updateAgentRosterProfile({ ...bioDetail, handleSuccess, handleError })
      );
    }
  };
  return (
    <Card variant="outlined" sx={{ backgroundColor: "white", mb: "16px" }}>
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title="Biographies"
        action={
          <div>
            <IconButton
              onClick={() => setIsEditAble(!isEditAble)}
              aria-label="locked"
            >
              {isEditAble ? (
                <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              ) : (
                <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              )}
            </IconButton>
          </div>
        }
      />
      <Box className={classes.bioTabsSection}>
        <Box className={classes.bioTabs}>
          <Box
            onClick={() => setTab("long")}
            className={
              tab === "long" ? classes.bioTabsItemActive : classes.bioTabsItem
            }
          >
            Long Biography
          </Box>
          <Box
            onClick={() => setTab("short")}
            className={
              tab === "short" ? classes.bioTabsItemActive : classes.bioTabsItem
            }
          >
            Short Biography
          </Box>
          <Box
            onClick={() => setTab("cma")}
            className={
              tab === "cma" ? classes.bioTabsItemActive : classes.bioTabsItem
            }
          >
            CMA Biography
          </Box>
        </Box>
        <Box className={classes.tabsRightSection}></Box>
      </Box>
      <CardContent>
        {tab === "long" ? (
          <TextField
            fullWidth
            multiline
            inputProps={{ readOnly: isEditAble ? false : true }}
            rows={12}
            onChange={(e) => handleUpdateField("bio", e.target.value)}
            value={bioDetail.bio}
            placeholder="Enter Long BIO"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ backgroundColor: isEditAble ? "white" : "#f9f9f9" }}
          />
        ) : null}

        {tab === "short" ? (
          <TextField
            fullWidth
            multiline
            inputProps={{ readOnly: isEditAble ? false : true }}
            rows={12}
            onChange={(e) => handleUpdateField("short_bio", e.target.value)}
            value={bioDetail.short_bio}
            placeholder="Enter Short BIO"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ backgroundColor: isEditAble ? "white" : "#f9f9f9" }}
          />
        ) : null}
        {tab === "cma" ? (
          <TextField
            fullWidth
            multiline
            inputProps={{ readOnly: isEditAble ? false : true }}
            rows={12}
            onChange={(e) => handleUpdateField("cma_bio", e.target.value)}
            value={bioDetail.cma_bio}
            placeholder="Enter CMA BIO"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ backgroundColor: isEditAble ? "white" : "#f9f9f9" }}
          />
        ) : null}

        {isEditAble ? (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ mt: "20px" }}
          >
            <LoadingButton
              onClick={handleSave}
              loading={updateLoading === "bio"}
              classes={{ root: classes.saveButTon }}
              color="inherit"
              autoFocus
              size="small"
              loadingPosition="start"
            >
              Save
            </LoadingButton>
          </Stack>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default withStyles(styleSheet, {
  name: "agentRosterDetailsBiographyStyle",
})(BiographyDetails);
