import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import LoadingButton from "@mui/lab/LoadingButton";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    color: "#000000DE",
  },
  createButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
  },
  closeButton: {
    color: "#0B0909!important",
  },
}));

function ConfirmModal(props) {
  const classes = useStyles();
  const { open, setOpen, handleConfirm, title, content, loading } = props;
  return (
    <React.Fragment>
      <Dialog disablePortal  open={open}>
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent sx={{ minHeight: "200px" }}>
          <center>
            <RestoreFromTrashIcon sx={{ fontSize: "80px", color: "red" }} />
          </center>
          <Box sx={{ width: "30ch" }}> {content}</Box>
        </DialogContent>
        <DialogActions>
          <Button className={classes.closeButton} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            loadingPosition="start"
            loading={loading}
            onClick={() => handleConfirm()}
            classes={{ root: classes.createButton }}
            color="inherit"
            autoFocus
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ConfirmModal;
