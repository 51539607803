import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Autocomplete, Box, Card, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { getTransactionLeads } from "../../../redux/transactions/getTransactionLeads";
import { debounce } from "lodash";
import moment from "moment";
import { updateTransactionContract } from "../../../redux/transactions/updateTransactionContract";

const useStyles = makeStyles((theme) => ({
  field: {
    border: "none!important",
    borderBottom: "1px solid #000000DE!important",
  },
  autocompleteOption: {
    padding: "15px",
    margin: "10px",
    fontFamily: "AvenirNext !important",
  },
  optionHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px",
  },
  optionHeaderSpan: {
    fontFamily: "AvenirNext !important",
    fontSize: "14px",
  },
  optionSubHeaderLeft: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
  optionSubHeaderRight: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
}));

function LinkPeopleModal(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const muiClasses = useStyles();

  let { open, setOpen, classes, position, ContractData } = props;

  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [searchText, setSearchText] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [formData, setFormData] = React.useState({});

  const leads = useSelector((state) => state.transactions.TransactionsContract.leads?.data || []);
  const updateContract = useSelector((state) => state.transactions.TransactionsContract.updateContract);

  React.useEffect(() => {
    setFormData({});
    setOptions([]);
    setSearchText(null);
    setInputValue("");
    return () => {
      setFormData({});
      setOptions([]);
      setSearchText(null);
      setInputValue("");
    };
  }, [open]);

  React.useEffect(() => {
    if (updateContract.success && !updateContract.isLoading && open) {
      setErrorAlert({ errorMsg: "You have successfully added the people", errorType: "success", isOpen: true });
      setOpen(false);
    }
  }, [updateContract]);

  React.useEffect(() => {
    if (leads.people) setOptions(leads?.people);
  }, [leads]);

  const debouncedSearch = useRef(
    debounce(async (val) => {
      dispatch(getTransactionLeads({ name: val }));
    }, 500)
  ).current;

  const onChangeSearchText = (newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 0) debouncedSearch(newInputValue);
  };

  const onSelectLead = (selectedLead) => {
    setSearchText(selectedLead);
    if (selectedLead) {
      setFormData((prevForm) => {
        return {
          ...prevForm,
          personId: selectedLead.id,
        };
      });
    } else {
      setFormData((prevForm) => {
        return {
          ...prevForm,
          personId: "",
        };
      });
    }
  };

  const handleSelectPeople = () => {
    let postionObj = {};

    if (position === "Buyer 1") {
      postionObj = {
        buyer0_personId: formData.personId,
      };
    } else if (position === "Buyer 2") {
      postionObj = {
        buyer1_personId: formData.personId,
      };
    } else if (position === "Seller 1") {
      postionObj = {
        seller0_personId: formData.personId,
      };
    } else if (position === "Seller 2") {
      postionObj = {
        seller1_personId: formData.personId,
      };
    }
    dispatch(updateTransactionContract({ id, contractData: { ...ContractData, ...postionObj } }));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.paymentModalHeading} id="alert-dialog-title">
          Attach {position} to Lead Record
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <Autocomplete
                fullWidth
                onChange={(event, newValue) => onSelectLead(newValue)}
                onInputChange={(event, newInputValue) => {
                  onChangeSearchText(newInputValue);
                }}
                inputValue={inputValue}
                value={searchText}
                options={[...options]}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Search All Leads by name" />}
                renderOption={(props, option) => (
                  <Card component="div" {...props} className={muiClasses.autocompleteOption}>
                    <Typography className={muiClasses.optionHeader}>
                      <b>{option.name}</b> - <span className={muiClasses.optionHeaderSpan}>{option.source}</span>
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "3px" }}>
                      <Typography className={muiClasses.optionSubHeaderLeft}>
                        Created on {option.created ? moment(option.created).format("YYYY-MM-DD") : ""}
                      </Typography>
                      <Typography className={muiClasses.optionSubHeaderRight}>Assigned to {option.assignedTo}</Typography>
                    </Box>
                  </Card>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSelectPeople()}
            classes={{ root: classes.saveModalButton }}
            color="inherit"
            autoFocus
            loading={updateContract.isLoading}
            loadingPosition="start"
          >
            Select
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNoteModalStyle" })(LinkPeopleModal);
