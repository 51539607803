import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Button,
  Grid,
  InputLabel,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  ListItemButton,
  Skeleton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../../../components/responseAlert";
import { updatePublicDataCategories } from "../../../../../redux/listings/publicData";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useParams } from "react-router-dom";
function EditFeatures(props) {
  let { open, setOpen, classes, publicDataDetail } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const dispatch = useDispatch();
  const { features, selectedFeatures, isLoading } = useSelector(
    (state) => state.listings.publicData.featureData
  );
  const updateCategories = useSelector(
    (state) => state.listings.publicData.updateCategories
  );

  useEffect(() => {
    if (open && selectedFeatures?.length) {
      setSelectedSubCategories(
        selectedFeatures.map((item) => item.listing_sub_category_id)
      );
    }
  }, [open, selectedFeatures]);

  const handleSuccess = (update) => {
    setErrorAlert({
      errorMsg: `You have successfully update the categories`,
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSubCategories([]);
  };

  const handleUpdateSubCategories = (id) => {
    let newIds = [...selectedSubCategories];
    if (newIds.includes(id)) {
      setSelectedSubCategories(newIds.filter((item) => item !== id));
    } else {
      newIds.push(id);
      setSelectedSubCategories(newIds);
    }
  };
  
  const handleSave = () => {
    if (selectedSubCategories?.length) {
      let obj = {
        schema: { sub_categories: selectedSubCategories },
        listing_public_data_id: publicDataDetail.id,
        listing_id: id,
        handleSuccess,
        handleError,
      };
      dispatch(updatePublicDataCategories(obj));
    } else {
      setErrorAlert({
        errorMsg: `Please select the categories first`,
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={Boolean(open)}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiPaper-root": { overflow: "visible !important" },
          "& .MuiDialog-container": { mt: "30px" },
        }}
      >
        <DialogTitle className={classes.modalHeading} id="alert-dialog-title">
          <Box>Edit Features</Box>
          <Stack direction="row" spacing={0} alignItems={"center"}>
            <LoadingButton
              sx={{ width: "50px", height: "30px", fontSize: "12px" }}
              classes={{ root: classes.saveModalButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={updateCategories.isLoading}
              size="small"
              onClick={handleSave}
            >
              Save
            </LoadingButton>
            <IconButton onClick={() => handleClose()} size="small">
              <ClearRoundedIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent className={classes.publicDataFeaturesArea}>
          <Stack direction={"row"} spacing={1}>
            {features?.length
              ? features.map((item, index) => (
                  <Button
                    size="small"
                    variant={activeTab === index ? "contained" : "outlined"}
                    sx={{
                      color:
                        activeTab === index ? "white" : "rgba(0, 0, 0, 0.6)",
                      backgroundColor: activeTab === index ? "#000000" : "",
                      "&:hover": {
                        backgroundColor:
                          activeTab === index ? "#000000 !important" : "",
                      },
                    }}
                    onClick={() => setActiveTab(index)}
                    className={classes.featureTabsButton}
                  >
                    {item.name}
                  </Button>
                ))
              : null}
          </Stack>
          <Grid container direction="row" spacing={2} sx={{ mt: "20px" }}>
            {isLoading
              ? Array.from(Array(8).keys()).map(() => (
                  <Grid item lg={3} md={6} sm={12}>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width="100%"
                      height="220px"
                    />
                  </Grid>
                ))
              : features?.length
              ? features[activeTab].categories.map((item) => (
                  <Grid key={item.name} item sm={12} md={4} lg={3}>
                    <InputLabel className={classes.featureItemLabel}>
                      {item.name}:
                    </InputLabel>
                    <Card variant="outlined">
                      <List
                        dense
                        disablePadding
                        className={classes.dataCategoriesItem}
                      >
                        {item.sub_categories.map((item1) => (
                          <ListItem disablePadding key={item1.id}>
                            <ListItemButton
                              onClick={() =>
                                handleUpdateSubCategories(item1.id)
                              }
                              sx={{ padding: "0px" }}
                            >
                              <ListItemIcon sx={{ minWidth: "30px" }}>
                                <Checkbox
                                  checked={selectedSubCategories.includes(
                                    item1.id
                                  )}
                                />{" "}
                              </ListItemIcon>
                              <ListItemText primary={item1.name} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  </Grid>
                ))
              : null}
          </Grid>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "EditFeaturesStyle" })(
  EditFeatures
);
