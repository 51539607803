export const styleSheet = () => ({
  pageRoot: {
    padding: "50px 0px",
  },
  templateListHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  addTemplateButton: {
    textTransform: "capitalize  !important",
  },
  templateListHeaderRight: {
    display: "flex !important",
    justifyContent: "flex-end  !important",
    alignItems: "center  !important",
    gap: "10px  !important",
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  formCard: {
    padding: "20px !important",
  },
  formHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formCancelButton: {
    minWidth: "74px",
  },
  formSaveButton: {
    backgroundColor: "black !important",
    color: "white !important",
    minWidth: "74px !important",
  },
  templateTitleArea: {
    marginTop: "30px  !important",
    marginBottom: "30px !important",
    display: "flex !important",
    alignItems: "center !important",
    gap: "20px !important",
    "& .MuiInputBase-root": { height: "37.5px" },
  },
  taskFormItem: {
    padding: "30px 0px !important",
    borderBottom: "1px solid lightgrey !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "30px !important",
    alignItems: "center !important",
    "& .MuiInputBase-root": { height: "37.5px" },
    position: "relative !important",
  },
  taskFormItemContent: {
    width: "60px !important",
  },
  taskForm: {
    width: "65%",
  },
  taskFormItemIcon: {
    position: "absolute !important",
    top: "15px !important",
    right: "15px !important",
  },
  inputLabel: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginBottom: "5px ",
    color: "black !important",
  },
});
