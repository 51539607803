import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../../components/responseAlert";
import { TextField, InputLabel, Grid, Stack, Box } from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddTrainingAppointment } from "../../../../redux/agents/agentRosterDetail/trainingIssue";

function AddAppointmentModal(props) {
  let { open, setOpen, classes, issueDetails, setAppointments, appointments } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const [appointmentDetail, setAppointmentDetail] = useState({
    id: issueDetails.id,
    type: "",
    title: "",
    description: "",
    eyesOnlyDescription: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    location: "",
  });

  const addAppointment = useSelector((item) => item.agentRoster.AgentTrainingIssues.addAppointment);

  useEffect(() => {
    if (addAppointment.success && !addAppointment.isLoading) {
      setErrorAlert({
        errorMsg: "You have successfully create the appointment",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      let newAppointments = [...appointments];
      newAppointments.push(appointmentDetail);
      setAppointmentDetail(newAppointments);
      setAppointmentDetail({
        id: issueDetails.id,
        type: "",
        title: "",
        description: "",
        eyesOnlyDescription: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        location: "",
      });
    } else if (!addAppointment.isLoading && addAppointment.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(addAppointment.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addAppointment.isLoading]);
  const handleUpdateDetail = (field, value) => {
    setAppointmentDetail({ ...appointmentDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!appointmentDetail.type) {
      isFormValid = false;
      errorMsg["type"] = "Please select the appointment type";
      setErrorAlert({
        errorMsg: "Please select the appointment type",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter title";
      setErrorAlert({
        errorMsg: "Please enter title",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter description";
      setErrorAlert({
        errorMsg: "Please enter description",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.eyesOnlyDescription) {
      isFormValid = false;
      errorMsg["description"] = "Please enter Eyes Only Description";
      setErrorAlert({
        errorMsg: "Please enter Eyes Only Description",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.start_date) {
      isFormValid = false;
      errorMsg["start_date"] = "Please select start date";
      setErrorAlert({
        errorMsg: "Please select start date",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.start_time) {
      isFormValid = false;
      errorMsg["start_time"] = "Please select start time";
      setErrorAlert({
        errorMsg: "Please select start time",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.end_date) {
      isFormValid = false;
      errorMsg["end_date"] = "Please select end date";
      setErrorAlert({
        errorMsg: "Please select end date",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.end_time) {
      isFormValid = false;
      errorMsg["end_time"] = "Please select end time";
      setErrorAlert({
        errorMsg: "Please select end time",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!appointmentDetail.location) {
      isFormValid = false;
      errorMsg["location"] = "Please enter location";
      setErrorAlert({
        errorMsg: "Please enter location",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleCreateAppointment = () => {
    if (handleValidate()) {
      dispatch(AddTrainingAppointment({ ...appointmentDetail, id: issueDetails.id }));
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.appointmentModalHeading} id="alert-dialog-title">
          <Box> Create Appointment</Box>
          <Box>
            <ButtonGroups
              id="category-button-menu"
              bgColor="#0B0909"
              bgColorHover="#0B0909"
              placement={"bottom"}
              color="white"
              placeholder="Appointment type"
              size="small"
              value={appointmentDetail.type}
              options={[
                { title: "Virtual", value: "Virtual" },
                { title: "In Person", value: "In Person" },
              ]}
              onChangeMenu={(value) => handleUpdateDetail("type", value)}
              {...props}
            />
          </Box>
        </DialogTitle>
        <DialogContent className={classes.customScrollBar} sx={{ width: "800px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={2} sx={{ marginTop: "-15px" }}>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Title
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder="Search or create tags"
                value={appointmentDetail.title}
                onChange={(e) => handleUpdateDetail("title", e.target.value)}
                error={errMsg.title}
                helperText={errMsg.title}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Description
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={6}
                placeholder="Search or create tags"
                value={appointmentDetail.description}
                onChange={(e) => handleUpdateDetail("description", e.target.value)}
                error={errMsg.description}
                helperText={errMsg.description}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Eyes Only Description
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={6}
                value={appointmentDetail.eyesOnlyDescription}
                onChange={(e) => handleUpdateDetail("eyesOnlyDescription", e.target.value)}
                error={errMsg.eyesOnlyDescription}
                helperText={errMsg.eyesOnlyDescription}
                placeholder="Search or create tags"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item lg={3} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Date
              </InputLabel>
              <TextField
                fullWidth
                type="date"
                classes={{ root: classes.detailInput }}
                placeholder="Search or create tags"
                variant="outlined"
                size="small"
                value={appointmentDetail.start_date}
                onChange={(e) => handleUpdateDetail("start_date", e.target.value)}
                error={errMsg.start_date}
                helperText={errMsg.start_date}
              />
            </Grid>
            <Grid item lg={2} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Time
              </InputLabel>
              <TextField
                fullWidth
                type="time"
                value={appointmentDetail.start_time}
                onChange={(e) => handleUpdateDetail("start_time", e.target.value)}
                error={errMsg.start_time}
                helperText={errMsg.start_time}
                classes={{ root: classes.detailInput }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item lg={1} xs={12} sx={{ zIndex: "1" }}>
              to
            </Grid>
            <Grid item lg={3} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Date
              </InputLabel>
              <TextField
                fullWidth
                type="date"
                classes={{ root: classes.detailInput }}
                placeholder="Search or create tags"
                variant="outlined"
                value={appointmentDetail.end_date}
                onChange={(e) => handleUpdateDetail("end_date", e.target.value)}
                error={errMsg.end_date}
                helperText={errMsg.end_date}
                size="small"
              />
            </Grid>
            <Grid item lg={2} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Time
              </InputLabel>
              <TextField
                fullWidth
                type="time"
                value={appointmentDetail.end_time}
                onChange={(e) => handleUpdateDetail("end_time", e.target.value)}
                error={errMsg.end_time}
                helperText={errMsg.end_time}
                classes={{ root: classes.detailInput }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
              >
                Location
              </InputLabel>
              <TextField
                fullWidth
                value={appointmentDetail.location}
                onChange={(e) => handleUpdateDetail("location", e.target.value)}
                error={errMsg.location}
                helperText={errMsg.location}
                classes={{ root: classes.detailInput }}
                placeholder="Search or create tags"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCreateAppointment()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addAppointment.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddAppointmentModalStyle" })(AddAppointmentModal);
