import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { XAccessToken } from "../../../utils/propertyData";
export const getSearchSuggestions = createAsyncThunk(
  "LeadSavedSearches/getSearchSuggestions",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_IDX_BASE_URL}api/v1/suggestions/optimizedV2?dataset=${data?.dataset}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": XAccessToken,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
