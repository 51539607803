import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemButton,
  Snackbar,
  Typography,
  Backdrop,
} from "@mui/material";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import { useDispatch, useSelector } from "react-redux";
import { getEmailTemplatesAgentsRecruitment } from "../../../../redux/agents/agentRecruitment/getEmailTemplatesAgenstRecruitment";
import Loading from "react-loading";
import { mergeEmailTemplateAgentRecruitment } from "../../../../redux/agents/agentRecruitment/mergeEmailTemplateAgentRecruitment";
import { resetState } from "../../../../redux/agents/agentRecruitment/getAllTemplates";
import { useParams } from "react-router-dom";

export const GetEmailTemplatesModal = ({ closeModal, state, selectedTemplate, id, type }) => {
  const { loading: actionLoading} = useSelector((item) => item.agentProfile.agentProfile.modalActions);

  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" }}}
      dialogHead={<DialogHead />}
      hideSaveButton
      dialogBody={
        <DialogBody
          allAgent={[]}
          notificationManager={[]}
          getAllListing={[]}
          closeModal={closeModal}
          selectedTemplate={selectedTemplate}
          type={type}
        />
      }
      state={state}
      closeModal={closeModal}
      maxWidth="md"
      loading={actionLoading}
      fullWidth={true}
    />
  );
};

// Components
const DialogHead = () => (
  <DialogTitle>
    <Typography component="h5" variant="h5">
      Email Templates
    </Typography>
  </DialogTitle>
);

const DialogBody = ({ closeModal, selectedTemplate, type }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);
  const [routeId, setRouteId] = useState(null);
  const [offset, setOffset] = useState(0);
  const [scrollFlag, setScrollFlag] = useState(true);
  const [open, setOpen] = useState({
    state: false,
    type: "",
    desc: "",
  });

  const templateList = useSelector((item) => item.emailTemplates);

  const setListData = async () => {
    dispatch(getEmailTemplatesAgentsRecruitment({ type: type ? type : "", offset, search }));
    setTemplates(templateList?.emailTemplates || []);
  };

  useEffect(() => {
    setRouteId(Number(params?.id));
  }, []);

  useEffect(() => {
    if (!search) {
      setListData();
    }
  }, [offset]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setListData(), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    if (templateList) {
      setTemplates(templateList?.emailTemplates || []);
    }
  }, [templateList]);

  const fillTemp = async (payload) => {
    setLoading(true);
    const resp = await dispatch(mergeEmailTemplateAgentRecruitment(payload));
    if (resp?.payload) {
      selectedTemplate(resp.payload);
      closeModal();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const selectTemplate = (param) => () => {
    let payload;
    if (type === "agent") {
      payload = {
        templateId: param?.id,
        mergeAgentId: routeId,
      };
    } else {
      payload = {
        templateId: param?.id,
        mergePersonId: routeId,
      };
    }
    fillTemp(payload);
  };

  useEffect(() => {
    if (templateList?.mergeEmail?.errMsg) {
      setOpen({
        state: true,
        type: "error",
        desc: templateList?.mergeEmail?.errMsg,
      });
    }
  }, [templateList]);

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleScrollEvent = (e) => {
    //console.log(e);
    var bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    if (bottom && !templateList?.loading && scrollFlag && !templateList.isLimitReached) {
      setScrollFlag(false);
      setTimeout(() => {
        setScrollFlag(true);
      }, 3000);
      dispatch(setOffset((value) => value + 100));
    }
  };
  return (
    <DialogContent onScroll={handleScrollEvent}>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open?.state} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={open?.type} sx={{ width: "100%" }}>
          {open?.desc}
        </Alert>
      </Snackbar>

      <Input
        id="emailTemplate"
        placeholder="Search Email Templates"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setOffset(0);
        }}
        required
      />

      {templateList?.loading && offset === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <br />
          <br />
          <CircularProgress />
        </Box>
      ) : (
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            border: "1px solid #e0e0e0",
            marginTop: "20px",
            borderRadius: "3px",
            padding: 0,
          }}
        >
          {templates && templates.length ? (
            templates.map((temp, index) => (
              <>
                <ListItemButton key={index} onClick={selectTemplate(temp)}>
                  <Box sx={{ display: "block" }}>
                    <Typography component="h6" variant="h6" sx={{ fontSize: "16px" }}>
                      {temp?.name}
                    </Typography>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html:
                          temp.body?.replace(/<[^>]+>/g, "").length > 80
                            ? `${temp.body?.replace(/<[^>]+>/g, "")?.substring(0, 120)}...`
                            : temp.body?.replace(/<[^>]+>/g, ""),
                      }}
                      component="p"
                      variant="subtitle1"
                    ></Typography>
                  </Box>
                </ListItemButton>
                {templates.length === index + 1 ? null : <Divider />}
              </>
            ))
          ) : (
            <Typography>No Templates found</Typography>
          )}
        </List>
      )}
      {templateList?.loading && offset > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "-30px", zIndex: 10000, height: "120px" }}>
          <CircularProgress />
        </Box>
      ) : null}
      {templateList.isLimitReached ? <Box sx={{ textAlign: "center" }}>You fetched all email templates</Box> : null}
      <Backdrop color="inherit" invisible={true} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DialogContent>
  );
};
