import React from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import BlockIcon from "@mui/icons-material/Block";

function UnauthorizedPage({ classes }) {
  return (
    <div className={classes.UnauthorizedPage}>
      <div>
        {" "}
        <center>
          <BlockIcon sx={{ fontSize: 140 }} color="error" />
        </center>
        <h1 className={classes.UnauthorizedPageHeading}>Unauthorized Access</h1>
        <p className={classes.UnauthorizedPageContent}>
          You don't have permission to access this page <br />
          For more detail contact admin
        </p>
      </div>
    </div>
  );
}
export default withStyles(styleSheet, { name: "UnauthorizedPageStyle" })(
  UnauthorizedPage
);
