import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  callData: undefined,
  isLoading: false,
  errMsg: null,
  isLimitReached:false,
  offset:0
};
export const getRecentCalls = createAsyncThunk(
  "recentCalls/getRecentCalls",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/calls/recent/${data.id}?limit=10&offset=${data.offset}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
const recentCalls = createSlice({
  name: "recentCalls",
  initialState,
  reducers: {
    setOffset: (state, action) => {
      if(action.payload){
        state.offset=action.payload
      }else{
        state.offset += 10;
      }
    },
  },
  extraReducers: {
    [getRecentCalls.pending]: (state) => {
      state.isLoading = true;
      state.isLimitReached=false
    },
    [getRecentCalls.fulfilled]: (state, action) => {
      state.isLoading = false;
      if(action.payload?._metadata?.offset>0){
        if(action.payload.calls.length===0){
          state.isLimitReached=true
        }else{
          state.callData = state.callData.concat(action.payload.calls);
        }

      }else{
        state.callData = action.payload.calls;
      }
    },
    [getRecentCalls.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});
export const { setOffset } = recentCalls.actions;
export default recentCalls.reducer;