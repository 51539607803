export const styleSheet = () => ({
    "@global": {
      ".Unauthorized-page": {
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "height": "90vh",
        "lineHeight": "30px"
      },
      ".Unauthorized-page h1": {
        "fontSize": "40px",
        "fontWeight": "600",
        "margin": "0",
        "textAlign": "center",
        "lineHeight": "60px",
        "fontFamily": "'Roboto'"
      },
      ".Unauthorized-page p": {
        "margin": "0",
        "textAlign": "center",
        "fontFamily": "'Roboto'"
      },
      ".Unauthorized-page .MuiSvgIcon-root": {
        "fontSize": "120px",
        "fontFamily": "'Roboto'"
      },
      ".Unauthorized-page .form-group-element": {
        "marginTop": "30px",
        "fontSize": "14px"
      },
      ".Unauthorized-page .form-group-element b": {
        "cursor": "pointer",
        "marginLeft": "7px"
      },
      ".Unauthorized-page .form-group-element button": {
        "width": "100%",
        "height": "35px",
        "cursor": "pointer",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "backgroundColor": "black",
        "color": "white",
        "borderRadius": "25px",
        "maxWidth": "220px",
        "textAlign": "center"
      },
      ".global-data-not-found": {
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "height": "100%",
        "width": "100%"
      },
      ".global-data-not-found div": {
        "textAlign": "center"
      }
    }
  });