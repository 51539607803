import React, { useEffect, useState } from "react";
import { Typography, Grid, Card, TextField, MenuItem } from "@mui/material";
import { styleSheet } from "../style";
import { getData } from "../../../../utils/getData.js";
import { withStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";

const Lender = (props) => {
  const [lenders, setLenders] = useState([]);
  const [isDisableOthers, setIsDisableOthers] = useState(false);
  let { classes, updateContractDetail, contractDetail, errMsg, setContractDetail } = props;

  useEffect(() => {
    handleGetClosingAttorneys();
  }, []);

  const handleGetClosingAttorneys = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}api/transactions/contracts/lenders`;
    const result = await getData(url);
    if (result?.status === 200) {
      setLenders(result.data);
    }
  };

  const handleUpdateValueLender = (newValue) => {
    if (newValue) {
      let obj = {
        ...newValue,
        loan_officer_id: newValue.id,
        lender_company_name: newValue?.lender?.lender_company_name,
      };
      delete obj.id;
      delete obj.label;
      delete obj.lender;
      delete obj.lender_id;
      setContractDetail({
        ...contractDetail,
        ...obj,
      });
      setIsDisableOthers(true);
    } else {
      setIsDisableOthers(false);
      setContractDetail({
        ...contractDetail,
        loan_officer_id: "",
        lender_company_name: "",
        loan_officer_first_name: "",
        loan_officer_last_name: "",
        loan_officer_phone: "",
        loan_officer_email: "",
      });
    }
  };

  return (
    <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible" }}>
      <Typography classes={{ root: classes.transactionFormCardHeading }}>Lender</Typography>

      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            onChange={(event, newValue) => {
              handleUpdateValueLender(newValue);
            }}
            id="closing-attorneysdd"
            options={lenders
              .sort((a, b) => a.loan_officer_last_name.localeCompare(b.loan_officer_last_name))
              .map((item) => ({
                ...item,
                label: item.loan_officer_first_name + " " + item.loan_officer_last_name + " | " + item.lender?.lender_company_name,
              }))}
            fullWidth
            renderInput={(params) => <TextField fullWidth {...params} variant="standard" helperText="Select Existing Lender" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={contractDetail.lender_company_name}
            onChange={(e) => updateContractDetail("lender_company_name", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Lender Company Name"
            error={errMsg["lender_company_name"]}
            id="lender_company_name"
            disabled={isDisableOthers}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.loan_officer_first_name}
            onChange={(e) => updateContractDetail("loan_officer_first_name", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Loan Officer FIrst Name"
            id="loan_officer_first_name"
            error={errMsg["loan_officer_first_name"]}
            disabled={isDisableOthers}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.loan_officer_last_name}
            onChange={(e) => updateContractDetail("loan_officer_last_name", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Loan Officer Last Name"
            error={errMsg["loan_officer_last_name"]}
            id="loan_officer_last_name"
            disabled={isDisableOthers}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.loan_officer_phone}
            onChange={(e) => updateContractDetail("loan_officer_phone", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Loan Officer Phone"
            error={errMsg["loan_officer_phone"]}
            id="loan_officer_phone"
            disabled={isDisableOthers}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.loan_officer_email}
            onChange={(e) => updateContractDetail("loan_officer_email", e.target.value)}
            fullWidth
            variant="standard"
            name="email"
            type="email"
            helperText="Loan Officer Email"
            error={errMsg["loan_officer_email"]}
            id="loan_officer_email"
            disabled={isDisableOthers}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
export default withStyles(styleSheet, { name: "LenderStyle" })(Lender);
