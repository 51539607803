import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemButton,
  Snackbar,
  Typography,
  Backdrop,
} from "@mui/material";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import { useDispatch, useSelector } from "react-redux";
import { getEmailTemplatesAgentsRecruitment } from "../../../../redux/agents/agentRecruitment/getEmailTemplatesAgenstRecruitment";
import Loading from "react-loading";
import { mergeEmailTemplateAgentRecruitment } from "../../../../redux/agents/agentRecruitment/mergeEmailTemplateAgentRecruitment";
import { resetState } from "../../../../redux/agents/agentRecruitment/getAllTemplates";
import { useParams } from "react-router-dom";

export const GetEmailTemplatesModal = ({
  closeModal,
  state,
  selectedTemplate,
  id,
}) => {
  const {
    loading: actionLoading,
    success,
    errMsg,
  } = useSelector((item) => item.agentProfile.agentProfile.modalActions);

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success, errMsg]);

  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" } }}
      dialogHead={<DialogHead />}
      hideSaveButton
      dialogBody={
        <DialogBody
          allAgent={[]}
          notificationManager={[]}
          getAllListing={[]}
          closeModal={closeModal}
          selectedTemplate={selectedTemplate}
        />
      }
      state={state}
      closeModal={closeModal}
      maxWidth="md"
      loading={actionLoading}
      fullWidth={true}
    />
  );
};

// Components
const DialogHead = () => (
  <DialogTitle>
    <Typography component="h5" variant="h5">
      Email Templates
    </Typography>
  </DialogTitle>
);

const DialogBody = ({ closeModal, selectedTemplate }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [template, setTemplate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [routeId, setRouteId] = useState(null);
  const [open, setOpen] = useState({
    state: false,
    type: "",
    desc: "",
  });

  const templateList = useSelector((item) => item.emailTemplates);

  const setListData = async () => {
    dispatch(getEmailTemplatesAgentsRecruitment({}));
    setTemplates(templateList?.emailTemplates || []);
  };

  useEffect(() => {
    if (template !== false) {
      const timeOutId = setTimeout(() => {
        dispatch(getEmailTemplatesAgentsRecruitment({ search: template }));
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [template]);

  useEffect(() => {
    setListData();
    setRouteId(Number(params?.id));
  }, []);

  useEffect(() => {
    if (templateList) {
      setTemplates(templateList?.emailTemplates || []);
    }
  }, [templateList]);

  const fillTemp = async (payload) => {
    setLoading(true);
    const resp = await dispatch(mergeEmailTemplateAgentRecruitment(payload));
    if (resp?.payload) {
      selectedTemplate(resp.payload);
      setLoading(false);
      closeModal();
    } else {
      setLoading(false);
    }
  };

  const selectTemplate = (param) => () => {
    const payload = {
      templateId: param?.id,
      personId: routeId,
    };
    fillTemp(payload);
  };

  useEffect(() => {
    if (templateList?.mergeEmail?.errMsg) {
      setOpen({
        state: true,
        type: "error",
        desc: templateList?.mergeEmail?.errMsg,
      });
    }
  }, [templateList]);

  const handleClose = (e) => {
    setOpen(false);
  };
  const convertHtmlToPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open?.state}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={open?.type}
          sx={{ width: "100%" }}
        >
          {open?.desc}
        </Alert>
      </Snackbar>

      <Input
        id="emailTemplate"
        placeholder="Search Email Templates"
        value={template || ""}
        onChange={(e) => setTemplate(e.target.value)}
        required
      />

      {templateList?.loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", minHeight: "400px" }}
        >
          <br />
          <br />
          <CircularProgress />
        </Box>
      ) : (
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            border: "1px solid #e0e0e0",
            marginTop: "20px",
            borderRadius: "3px",
            padding: 0,
          }}
        >
          {templates && templates.length ? (
            templates.map((temp, index) => (
              <>
                <ListItemButton
                  sx={{
                    "&:hover": {
                      "& .templatePlainText": { display: "none" },
                      "& .templateBody": { display: "block" },
                    },
                  }}
                  key={index}
                  onClick={selectTemplate(temp)}
                >
                  <Box sx={{ display: "block" }}>
                    <Typography
                      component="h6"
                      variant="h6"
                      sx={{ fontSize: "16px" }}
                    >
                      {temp?.name}
                    </Typography>
                    <Typography
                      className={"templatePlainText"}
                      dangerouslySetInnerHTML={{
                        __html:
                          convertHtmlToPlainText(temp.body).length > 80
                            ? `${convertHtmlToPlainText(temp.body)?.substring(
                                0,
                                120
                              )}...`
                            : convertHtmlToPlainText(temp.body),
                      }}
                      component="p"
                      variant="subtitle1"
                    ></Typography>
                    <Typography
                      sx={{ display: "none" }}
                      className={"templateBody"}
                    >
                      <pre
                        style={{
                          whiteSpace: "initial",
                          fontFamily: "AvenirNext !important",
                          color: "rgba(0, 0, 0, 0.6) !important",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: temp.body,
                          }}
                        />
                      </pre>
                    </Typography>
                  </Box>
                </ListItemButton>
                {templates.length === index + 1 ? null : <Divider />}
              </>
            ))
          ) : (
            <Typography>No Templates found</Typography>
          )}
        </List>
      )}
      <Backdrop color="inherit" invisible={true} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DialogContent>
  );
};
