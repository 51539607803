import { createSlice } from "@reduxjs/toolkit";
import { updateVendor } from "./updateVendor";
import {getVendorDetail} from './getVendor'
import {getVendorPaymentRecord} from './getPaymentDetail'
import {uploadVendorDoc} from "./uploadW9Doc"

const initialState = {
  updateVendorData: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  VendorData: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  paymentRecord:{
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  uploadDoc:{
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  }
};
const VendorDetail = createSlice({
  name: "VendorDetail",
  initialState,
  extraReducers: {
    [updateVendor.pending]: (state) => {
      state.updateVendorData.isLoading = true;
      state.updateVendorData.errMsg = null;
      state.updateVendorData.success = false;
    },
    [updateVendor.fulfilled]: (state, action) => {
      state.updateVendorData.success = true;
      state.updateVendorData.isLoading = false;
      state.updateVendorData.data = action.payload;
      let vendorD=state.VendorData.data?.vendors?[...state.VendorData.data?.vendors]:[]
      vendorD.push(action.payload);
      state.VendorData.data.vendors=vendorD
    },
    [updateVendor.rejected]: (state, action) => {
      state.updateVendorData.isLoading = false;
      state.updateVendorData.success = false;
      state.updateVendorData.errMsg = action.payload;
    },
     [getVendorDetail.pending]: (state) => {
      state.VendorData.isLoading = true;
      state.VendorData.errMsg = null;
      state.VendorData.success = false;
    },
    [getVendorDetail.fulfilled]: (state, action) => {
      state.VendorData.success = true;
      state.VendorData.isLoading = false;
      state.VendorData.data = action.payload;
    },
    [getVendorDetail.rejected]: (state, action) => {
      state.VendorData.isLoading = false;
      state.VendorData.success = false;
      state.VendorData.errMsg = action.payload;
    },

    [getVendorPaymentRecord.pending]: (state) => {
      state.paymentRecord.isLoading = true;
      state.paymentRecord.errMsg = null;
      state.paymentRecord.success = false;
    },
    [getVendorPaymentRecord.fulfilled]: (state, action) => {
      state.paymentRecord.success = true;
      state.paymentRecord.isLoading = false;
      state.paymentRecord.data = action.payload;
    },
    [getVendorPaymentRecord.rejected]: (state, action) => {
      state.paymentRecord.isLoading = false;
      state.paymentRecord.success = false;
      state.paymentRecord.errMsg = action.payload;
    },
    // upload vendor w9 document
    [uploadVendorDoc.pending]: (state) => {
      state.uploadDoc.isLoading = true;
      state.uploadDoc.errMsg = null;
      state.uploadDoc.success = false;
    },
    [uploadVendorDoc.fulfilled]: (state, action) => {
      state.uploadDoc.success = true;
      state.uploadDoc.isLoading = false;
      state.paymentRecord.data = action.payload;
      state.VendorData.data={...state.VendorData.data,w9_form:action.payload}
    },
    [uploadVendorDoc.rejected]: (state, action) => {
      state.uploadDoc.isLoading = false;
      state.uploadDoc.success = false;
      state.uploadDoc.errMsg = action.payload;
    },
  },
});
export default VendorDetail.reducer;
export * from "./updateVendor";
export * from './getVendor'
export * from './getPaymentDetail'
export * from "./uploadW9Doc"
