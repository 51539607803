export const styleSheet = () => ({
  pageRoot: {
    marginTop: "50px !important",
  },
  loadingArea: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  pondTableHeading: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  pondAddButton: {
    background: "#0B0909 !important",
    color: "white",
    fontWeight: "400 !important",
    marginLeft: "40px  !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "6px  !important",
  },
  pondTableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userFieldLabel: {
    backgroundColor: "#FCFDFD !important",
    height: "40px !important",
    display: "flex !important",
    alignItems: "center !important",
    paddingLeft: "48px !important",
    color: "#7A8CA6 !important",
    fontSize: "13px !important",
  },
  saveButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
});
