import React, { useState } from "react";
import { Grid, Card, CardHeader, Avatar, AvatarGroup, Stack, Paper, Button } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import moment from "moment";


function TransactionsDetailMoney(props) {
  let { classes,ContractData } = props;
  return (
    <Card className={classes.overViewTabCardMain}>
      <CardHeader classes={{ title: classes.overViewTabCardHeading }} title="Earnest Money" />
      <Card className={classes.overViewTabCardPeoples}>
        <CardHeader
          classes={{
            title: classes.importantPeoplesHeader,
            subheader: classes.importantPeoplesDes,
          }}
          avatar={<Avatar src="" alt="OP" sx={{ width: 34, height: 34 }} />}
          title={`$${new Intl.NumberFormat().format(ContractData.earnest_money_amount)} - ${ContractData.earnest_money_holder} `}/* Coldwell Banker */
          subheader={` on ${moment.utc(ContractData.earnest_money_due_date).format('L')}`}
        />
      </Card>
    </Card>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailMoneyStyle" })(TransactionsDetailMoney);
