import React, { useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Typography, InputLabel, FormGroup, Checkbox, FormControlLabel, TextField, MenuItem, Button } from "@mui/material";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useSelector, useDispatch } from "react-redux";
import { submitTemplate } from "../../../../../redux/marketingCenter/digital";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../../../components/responseAlert";
import { useParams } from "react-router-dom";
function TemplateUpdateForm(props) {
  let { classes, setIsSubmitted } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const params = useParams();

  const [templateFields, setTemplateFields] = useState({});
  const dispatch = useDispatch();
  const templateDetail = useSelector((state) => state.marketingCenter.digital.templateDetail);
  const templateSubmit = useSelector((state) => state.marketingCenter.digital.templateSubmit);

  const handleSuccess = () => {
    setIsSubmitted(true);
    window.scrollTo(0, 0);
    // setErrorAlert({
    //   errorMsg: "You have successfully Requested",
    //   errorType: "success",
    //   isOpen: true,
    // });
  };
  const handleError = (errMsg) => {
    setErrorAlert({
      errorMsg: JSON.stringify(errMsg),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSubmit = () => {
    dispatch(
      submitTemplate({
        schema: { ...templateFields, templateId: params.id },
        handleSuccess,
        handleError,
      })
    );
  };

  const handleUpdateMulti = (e, data, option) => {
    let newDetail = { ...templateFields };
    if (newDetail[data.field]) {
      let selectedOptions = [...newDetail[data.field]];
      if (e.target.checked) {
        selectedOptions.push(option);
        newDetail[data.field] = [...selectedOptions];
      } else {
        newDetail[data.field] = [...selectedOptions].filter((item) => item != option);
      }
    } else {
      newDetail[data.field] = [option];
    }
    setTemplateFields(newDetail);
  };

  const handleUpdateText = (e, data) => {
    setTemplateFields((newDetail) => ({
      ...newDetail,
      [data.field]: e.target.value,
    }));
  };

  return (
    <Box className={classes.TemplateUpdateForm}>
      {templateDetail?.data?.modal_fields?.length
        ? templateDetail?.data?.modal_fields
            ?.map((row) => ({
              ...row,
              field: row?.name?.replace("Select ", "")?.replace(" ", "_")?.toLowerCase(),
            }))
            ?.map((item, index) => {
              if (item.type === "multi_select") {
                return (
                  <Box key={item.name} className={classes.TemplateUpdateFormItem}>
                    <InputLabel className={classes.formLabel}>{item.name}</InputLabel>
                    <FormGroup>
                      {item?.options?.map((ite, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => handleUpdateMulti(e, item, ite)}
                              checked={templateDetail[item.field]?.includes(ite)}
                            />
                          }
                          label={ite}
                          key={ite}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                );
              } else if (item.type === "text") {
                return (
                  <Box key={item.name} className={classes.TemplateUpdateFormItem}>
                    <InputLabel className={classes.formLabel}>{item.name}</InputLabel>
                    <TextField
                      placeholder={item.placeholder}
                      fullWidth
                      size="small"
                      onChange={(e) => handleUpdateText(e, item)}
                      value={templateDetail[item.field]}
                      sx={{ backgroundColor: "white" }}
                    />
                  </Box>
                );
              } else if (item.type === "dropdown") {
                return (
                  <Box key={item.name} className={classes.TemplateUpdateFormItem}>
                    <InputLabel className={classes.formLabel}> {item.name}</InputLabel>
                    <TextField
                      defaultValue={"cafe"}
                      fullWidth
                      select
                      size="small"
                      onChange={(e) => handleUpdateText(e, item)}
                      value={templateDetail[item.field]}
                    >
                      {item?.options?.map((ite) => (
                        <MenuItem value={ite}> {ite}</MenuItem>
                      ))}
                    </TextField>
                  </Box>
                );
              } else if (item.type === "file" || item.type === "image") {
                return (
                  <Box key={item.name}>
                    <Box className={classes.TemplateUpdateFormItem}>
                      <Button color="inherit" variant="outlined" className={classes.TemplateFormAttachButton} fullWidth>
                        {" "}
                        <FileUploadRoundedIcon /> Attach Files{" "}
                      </Button>
                    </Box>
                    <Box className={classes.TemplateUpdateFormItem}>
                      <InputLabel className={classes.formLabel}>Uploaded Files</InputLabel>
                      {Array.from(Array(3).keys()).map((item, index) => (
                        <Box className={classes.uploadedFile}>
                          <Typography className={classes.uploadedFileContent}>somelongfilenamehere.mov</Typography>
                          <CheckRoundedIcon fontSize="small" sx={{ color: "rgba(76, 175, 80, 1)" }} />
                          <CancelRoundedIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
              } else if (item.type === "textarea") {
                return (
                  <Box key={item.name} className={classes.TemplateUpdateFormItem}>
                    <InputLabel className={classes.formLabel}>{item.name}</InputLabel>
                    <TextField
                      placeholder="(Optional)"
                      fullWidth
                      size="small"
                      multiline
                      onChange={(e) => handleUpdateText(e, item)}
                      value={templateDetail[item.field]}
                      rows={5}
                      sx={{ backgroundColor: "white" }}
                    />
                  </Box>
                );
              } else if (item.type === "submit") {
                return (
                  <Box key={item.name} className={classes.TemplateUpdateFormItem}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      size="large"
                      className={classes.submitButton}
                      onClick={handleSubmit}
                      loading={Boolean(templateSubmit?.isLoading)}
                      loadingPosition="end"
                    >
                      {item.name}
                    </LoadingButton>
                  </Box>
                );
              }
            })
        : null}
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "TemplateUpdateFormStyles" })(TemplateUpdateForm);
