import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const updatePhoneExtension = createAsyncThunk("PhoneExtensions/updatePhoneExtension", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/phone-config/extensions/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
