import React from "react";
import ListingHeaderCardSkelton from "../cards/cardSkelton";
import { Paper, Container, TableContainer, Table, TableRow, TableCell, Skeleton, ListItemText } from "@mui/material";
function ListingDetailSkelton(props) {
  let { count = 10, disableTopCard } = props;
  return (
    <Container maxWidth="lg" sx={{ mt: "50px" }}>
      {disableTopCard ? null : <ListingHeaderCardSkelton {...props} />}

      <Paper sx={{ padding: "20px", marginBottom: "16px", backgroundColor: "white" }}>
        <TableContainer sx={{ padding: "0 15px", paddingBottom: "30px", minHeight: "500px", fontFamily: "AvenirNext!important" }}>
          <Table sx={{ minWidth: 500 }} size="small" aria-label="simple table">
            {Array.from(Array(count).keys()).map(() => (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ width: "200px" }}>
                  <ListItemText
                    primary={<Skeleton animation="wave" height={25} width="100%" style={{ marginBottom: 4 }} />}
                    secondary={<Skeleton animation="wave" height={20} width="60%" />}
                  />
                </TableCell>
                <TableCell sx={{ width: "70px" }}>
                  <ListItemText primary={<Skeleton animation="wave" height={30} width="50px" />} />
                </TableCell>
                <TableCell sx={{ width: "70px" }}>
                  <Skeleton animation="wave" height={140} width="70px" />
                </TableCell>
                <TableCell sx={{ width: "150px" }}>
                  <Skeleton animation="wave" height={90} width="150px" sx={{ padding: "0px" }} />
                </TableCell>
                <TableCell sx={{ width: "110px" }}>
                  <Skeleton animation="wave" height={30} width="110px" />
                </TableCell>
                <TableCell sx={{ width: "150px" }}>
                  {" "}
                  <ListItemText
                    primary={<Skeleton animation="wave" height={30} width="100%" style={{ marginBottom: 4 }} />}
                    secondary={<Skeleton animation="wave" height={25} width="100%" />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}
export default ListingDetailSkelton;
