import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertsLoading: true,
  activityLoading: true,
  closingsLoading: true,
  errMsg: null,
  projectCount: 0,
  activities: [],
  closings: [],
  alerts: {
    count: 0,
    rows: [],
  },
};

export const getAlerts = createAsyncThunk(
  "dashboard/getAlerts",
  async (data, thunkAPI) => {
    const userId = thunkAPI.getState().adminAuth.adminDetail?.id;
    const { offset } = data;

    const token = localStorage.getItem("token");
    try {
      const url = `${
        process.env.REACT_APP_BASE_URL
      }api/recruitment/textMessages/getTextHistory?userId=${userId}&limit=5&offset=${
        offset || 0
      }`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };
      const resp = await axios(config);

      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getProjectCount = createAsyncThunk(
  "dashboard/getProjectCount",
  async (data, thunkAPI) => {
    const userId = thunkAPI.getState().adminAuth.adminDetail?.id;

    const token = localStorage.getItem("token");
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/projects?agent_id=${userId}&status=["sumbitted"]`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };
      const resp = await axios(config);

      return resp?.data?.count;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getActivity = createAsyncThunk(
  "dashboard/getActivity",
  async (data, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/leads/people/recentActivity?pageNumber=1&pageSize=9&type=shared_property_via_email,Saved Property,Viewed Property`;

      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };
      const resp = await axios(config);
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getClosings = createAsyncThunk(
  "dashboard/getClosings",
  async (data, thunkAPI) => {
    const { id } = data;
    const token = localStorage.getItem("token");
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/users/payment_records/${id}?pageSize=10&pageNumber=1`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };
      const resp = await axios(config);
      console.log(resp.data);
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const sendText = createAsyncThunk(
  "SendTexts",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/text-messages/send-text`,
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Context-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboardData: (state, action) => {
      state.alertsLoading = false;
      state.activityLoading = false;
      state.closingsLoading = false;
      state.errMsg = null;
      state.projectCount = 0;
      state.activities = [];
      state.closings = [];
      state.alerts = {
        count: 0,
        rows: [],
      };
    },
  },
  extraReducers: {
    [getAlerts.pending]: (state) => {
      state.alertsLoading = true;
    },
    [getAlerts.fulfilled]: (state, action) => {
      state.alertsLoading = false;
      state.alerts = {
        count: action.payload?.textMessages?.count || 0,
        rows: action.payload?.textMessages?.rows || [],
      };
    },
    [getAlerts.rejected]: (state, action) => {
      state.alertsLoading = false;
      state.errMsg = action.payload;
    },

    [getProjectCount.fulfilled]: (state, action) => {
      state.projectCount = action.payload;
    },

    [getActivity.pending]: (state) => {
      state.activityLoading = true;
    },
    [getActivity.fulfilled]: (state, action) => {
      state.activityLoading = false;
      state.activities = action.payload.recentActivity;
    },
    [getActivity.rejected]: (state, action) => {
      state.activityLoading = false;
      state.errMsg = action.payload;
    },

    [getClosings.pending]: (state) => {
      state.closingsLoading = true;
    },
    [getClosings.fulfilled]: (state, action) => {
      state.closingsLoading = false;
      state.closings = action.payload?.data?.records;
    },
    [getClosings.rejected]: (state, action) => {
      state.closingsLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export const { resetDashboardData } = dashboard.actions;
export default dashboard.reducer;
