import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Stack, Card, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import ResponseAlert from "../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { addInventoryItem } from "../../redux/inventory";

function AddInventoryItem(props) {
  let { open, setOpen, classes, defaultType } = props;
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState({
    message: "",
    errorType: "",
    isOpen: false,
  });
  const [inventoryDetail, setInventoryDetail] = useState({
    AssetType: defaultType || "lockbox",
    AssetName: "",
    SignageType: "",
    SerialNumber: undefined,
    Icon: "",
    CBScode: undefined,
    OpenHouseDay: undefined,
    SignageColor: undefined,
    SignageCount: undefined,
    tagId: undefined,
  });

  const adminAuth = useSelector((state) => state.adminAuth);
  const addInventory = useSelector((state) => state.inventory.InventoryData.addInventory);
  const handleUpdateValue = (field, value, color) => {
    if (field === "AssetType") {
      setInventoryDetail({ ...inventoryDetail, [field]: value, Icon: "" });
      setErrMsg({});
    } else if (field === "Icon") {
      setInventoryDetail({ ...inventoryDetail, [field]: value, SignageColor: color });
      setErrMsg({});
    } else {
      setInventoryDetail({ ...inventoryDetail, [field]: value });
      setErrMsg({});
    }
  };
  useEffect(() => {
    if (defaultType) {
      handleUpdateValue("AssetType", defaultType);
    }
  }, [defaultType]);
  useEffect(() => {
    if (addInventory.success && !addInventory.isLoading) {
      setErrorAlert({
        message: "You have successfully add the inventory item",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setInventoryDetail({
        AssetType: "lockbox",
        AssetName: "",
        SerialNumber: "",
        Icon: "",
        CBScode: undefined,
        OpenHouseDay: undefined,
        SignageColor: undefined,
        SignageCount: undefined,
        SerialNumber: undefined,
        tagId: undefined,
      });
    } else if (!addInventory.isLoading && addInventory.errMsg) {
      setErrorAlert({
        message: JSON.stringify(addInventory.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addInventory.isLoading]);
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!inventoryDetail.AssetName) {
      isFormValid = false;
      errorMsg["AssetName"] = "Please enter the asset name";
      setErrorAlert({
        message: "Please enter the asset name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!inventoryDetail.SerialNumber && inventoryDetail.AssetType === "lockbox") {
      isFormValid = false;
      errorMsg["SerialNumber"] = "Please enter the Serial Number";
      setErrorAlert({
        message: "Please enter the Serial Number",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!inventoryDetail.CBScode && inventoryDetail.AssetType !== "signage") {
      isFormValid = false;
      errorMsg["CBScode"] = "Please enter the CBS code";
      setErrorAlert({
        message: "Please enter the CBS code",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!inventoryDetail.OpenHouseDay && inventoryDetail.AssetType === "signage" && inventoryDetail.SignageType === "open_house") {
      isFormValid = false;
      errorMsg["OpenHouseDay"] = "Please select Open House Day";
      setErrorAlert({
        message: "Please select Open House Day",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!inventoryDetail.SignageType && inventoryDetail.AssetType === "signage") {
      isFormValid = false;
      errorMsg["SignageType"] = "Please select signage type";
      setErrorAlert({
        message: "Please select Open House Day",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!inventoryDetail.SignageCount && inventoryDetail.AssetType === "signage") {
      isFormValid = false;
      errorMsg["SignageCount"] = "Please enter signage count";
      setErrorAlert({
        message: "Please enter signage count",
        errorType: "warning",
        isOpen: true,
      });
    } else if (inventoryDetail.SignageCount && inventoryDetail.SignageCount < 1 && inventoryDetail.AssetType === "signage") {
      isFormValid = false;
      errorMsg["SignageCount"] = "Please enter signage count greater than 0";
      setErrorAlert({
        message: "Please enter signage count greater than 0",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!inventoryDetail.Icon) {
      isFormValid = false;
      errorMsg["icon"] = "Please select then icon";
      setErrorAlert({
        message: "Please select then icon",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleAddInventoryItem = () => {
    if (handleValidate()) {
      let inventoryDetail_new = { ...inventoryDetail };
      Object.keys(inventoryDetail_new).forEach((k) => {
        if (!inventoryDetail_new[k]) {
          delete inventoryDetail_new[k];
        }
      });
      if (inventoryDetail?.SignageType === "directional ") {
        delete inventoryDetail_new.OpenHouseDay;
      }
      dispatch(addInventoryItem({ ...inventoryDetail_new, id: adminAuth?.adminDetail?.id }));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.addItemModalHeading} id="alert-dialog-title">
          Add Inventory Item
        </DialogTitle>
        <DialogContent sx={{ width: "600px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={3}>
            <Grid item lg={5} xs={12} sx={{ zIndex: "2" }}>
              <InputLabel className={classes.addInventoryLabel}>Inventory Type</InputLabel>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inventoryDetail.AssetType}
                  onChange={(e) => {
                    handleUpdateValue("AssetType", e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value={"lockbox"}>Lockbox</MenuItem>
                  <MenuItem value={"signage"}>Signage</MenuItem>
                  <MenuItem value={"equipment"}>Equipment</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={5} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.addInventoryLabel}>Name</InputLabel>
              <TextField
                fullWidth
                name="AssetName"
                classes={{ root: classes.detailInput }}
                error={errMsg.AssetName}
                helperText={errMsg.AssetName}
                placeholder="Name of item"
                value={inventoryDetail.AssetName}
                onChange={(e) => handleUpdateValue("AssetName", e.target.value)}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid hidden={inventoryDetail.AssetType !== "signage"} item lg={5} xs={12} sx={{ zIndex: "2" }}>
              <InputLabel className={classes.addInventoryLabel}>Signage Type</InputLabel>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inventoryDetail.SignageType}
                  onChange={(e) => handleUpdateValue("SignageType", e.target.value)}
                  size="small"
                  error={errMsg.SignageType}
                  helperText={errMsg.SignageType}
                >
                  <MenuItem value={"open_house"}>Open House</MenuItem>
                  <MenuItem value={"directional"}>Directional</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              hidden={inventoryDetail.AssetType === "lockbox" || inventoryDetail.AssetType === "equipment" ? false : true}
              item
              lg={5}
              xs={12}
              sx={{ zIndex: "1" }}
            >
              <InputLabel className={classes.addInventoryLabel}>Serial Number</InputLabel>
              <TextField
                value={inventoryDetail.SerialNumber}
                onChange={(e) => handleUpdateValue("SerialNumber", e.target.value)}
                fullWidth
                name="SerialNumber"
                classes={{ root: classes.detailInput }}
                placeholder={inventoryDetail.AssetType === "lockbox" ? "Enter serial number" : "Optional"}
                variant="outlined"
                size="small"
                error={errMsg.SerialNumber}
                helperText={errMsg.SerialNumber}
              />
            </Grid>

            <Grid hidden={inventoryDetail.AssetType !== "lockbox"} item lg={5} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.addInventoryLabel}>CBS Code</InputLabel>
              <TextField
                value={inventoryDetail.CBScode}
                onChange={(e) => handleUpdateValue("CBScode", e.target.value)}
                fullWidth
                name="CBScode"
                classes={{ root: classes.detailInput }}
                placeholder="Displayed if Type = Lockbox"
                variant="outlined"
                size="small"
                error={errMsg.CBScode}
                helperText={errMsg.CBScode}
              />
            </Grid>
            <Grid hidden={inventoryDetail.AssetType !== "signage"} item lg={5} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.addInventoryLabel}>Total Inventory</InputLabel>
              <TextField
                value={inventoryDetail.SignageCount}
                onChange={(e) => handleUpdateValue("SignageCount", e.target.value)}
                fullWidth
                name="tagId"
                type={"number"}
                min={0}
                classes={{ root: classes.detailInput }}
                placeholder="Optional"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid
              hidden={inventoryDetail.AssetType !== "signage" || inventoryDetail.SignageType === "directional" ? true : false}
              item
              lg={5}
              xs={12}
              sx={{ zIndex: "2" }}
            >
              <InputLabel className={classes.addInventoryLabel}>Open House Day</InputLabel>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inventoryDetail.OpenHouseDay}
                  onChange={(e) => handleUpdateValue("OpenHouseDay", e.target.value)}
                  size="small"
                  error={errMsg.OpenHouseDay}
                  helperText={errMsg.OpenHouseDay}
                >
                  <MenuItem value={"Saturday"}>Saturday</MenuItem>
                  <MenuItem value={"Sunday"}>Sunday</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid hidden={inventoryDetail.AssetType === "signage"} item lg={5} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.addInventoryLabel}>Tag ID</InputLabel>
              <TextField
                value={inventoryDetail.tagId}
                onChange={(e) => handleUpdateValue("tagId", e.target.value)}
                fullWidth
                name="tagId"
                type={"number"}
                classes={{ root: classes.detailInput }}
                placeholder="Optional"
                variant="outlined"
                size="small"
              />
            </Grid>

            <Grid item lg={7} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.addInventoryLabel}>Icon</InputLabel>
              <Card className={classes.InventoryIconBox}>
                <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
                  <Box className={classes.InventoryIconBoxImage}>
                    {" "}
                    <img
                      onClick={(e) => {
                        if (inventoryDetail.AssetType !== "signage") {
                          handleUpdateValue("Icon", e.target.src);
                        }
                      }}
                      style={{ width: "60px" }}
                      src={"https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/matterport-isolated+1matterport+(1).png"}
                      alt="agent Equipment Icon"
                    />
                    {inventoryDetail.Icon ===
                      "https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/matterport-isolated+1matterport+(1).png" && (
                      <Box className={classes.InventoryIconBoxImageSelected}>
                        <CheckCircleIcon color="success" />
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.InventoryIconBoxImage}>
                    {" "}
                    <img
                      onClick={(e) => {
                        if (inventoryDetail.AssetType !== "signage") {
                          handleUpdateValue("Icon", e.target.src);
                        }
                      }}
                      style={{ width: "26px" }}
                      src={"https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/supra-ekey+isolated+1.png"}
                      alt="agent Lock Icon"
                    />
                    {inventoryDetail.Icon === "https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/supra-ekey+isolated+1.png" && (
                      <Box className={classes.InventoryIconBoxImageSelected}>
                        <CheckCircleIcon color="success" />
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.InventoryIconBoxImage}>
                    {" "}
                    <img
                      onClick={(e) => handleUpdateValue("Icon", e.target.src, "white")}
                      className={classes.InventoryIconBoxIcon}
                      src={"https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/white.png"}
                      alt="agent Method Icon White"
                    />
                    {inventoryDetail.Icon === "https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/white.png" && (
                      <Box className={classes.InventoryIconBoxImageSelected}>
                        <CheckCircleIcon color="success" />
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.InventoryIconBoxImage}>
                    {" "}
                    <img
                      onClick={(e) => handleUpdateValue("Icon", e.target.src, "black")}
                      className={classes.InventoryIconBoxIcon}
                      src={"https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/black.png"}
                      alt="agent Equipment Icon"
                    />
                    {inventoryDetail.Icon === "https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/black.png" && (
                      <Box className={classes.InventoryIconBoxImageSelected}>
                        <CheckCircleIcon color="success" />
                      </Box>
                    )}
                  </Box>
                </Stack>
                {<Box sx={{ color: "red" }}>{errMsg.icon}</Box>}
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleAddInventoryItem()}
            classes={{ root: classes.AddInventoryButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addInventory.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ message: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.message}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddInventoryItemStyle" })(AddInventoryItem);
