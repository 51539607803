import React, { useEffect, useState } from "react";

import {
  Grid,
  Stack,
  Paper,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import Avatar from "../../../../components/uiElements/avatar/index";
import { withStyles } from "@mui/styles";
import GoogleMap from "google-map-react";
import { styleSheet } from "./style";
import {
  BackgroundCard,
  ContactDetails,
  SocialCard,
  TagsCard,
  NameCard,
  Details,
  TasksCard,
  AppointmentsCard,
  FilesCard,
  CollaboratorsCard,
} from "./cards/cards.jsx";
import { AgentRecruitingTabs } from "../../common/Tabs";
import AgentRecruitingTimeline from "../../common/Timeline";
import { getAgentRecruitingProfile } from "../../../../redux/agents/getAgentProfile";
import {
  getAgentAverageStats,
  resetAgentAverageStats,
} from "../../../../redux/agents/getAgentAverageStats";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../common/Pagination";
import { EditPhoneModal } from "../../common/Modal/editPhoneModal";
import { EditEmailModal } from "../../common/Modal/editEmailModal";
import { EditAddressModal } from "../../common/Modal/editAddressModal";
import { getAgentRecruitingAllTags } from "../../../../redux/agents/agentRecruitment/getAgentsRecruitmentAllTags";
import { markTaskAsCompleteAgentRecruitment } from "../../../../redux/agents/agentRecruitment/addAgentRecruitmentTasks";
import AgentStatistics from "./cards/agentStatistics";
import moment from "moment";
import { Box } from "@mui/system";
import { getAgentRecruitingStages } from "../../../../redux/agents/agentRecruitment/getAgentsRecruitmentStages";
import { updateStageNameAgentRecruitment } from "../../../../redux/agents/agentRecruitment/updateStageNameAgentRecruitment";
import { deleteFileAPI } from "../../../../redux/agents/agentRecruitment/deleteFile";
import { setInfoAgentRecruitment } from "../../../../redux/agents/agentRecruitment/setInfoAgentRecruitment";
import { stringAvatar } from "../../../../utils/avatarInitial";
import { removeAgentRecruitmentTags } from "../../../../redux/agents/agentRecruitment/removeAgentRecruitmentTags";
import IssuesDetailModal from "../../../agentRoster/agentDetails/modals/issueDetail";
import AddCollaboratorModal from "../../../agentRoster/agentDetails/modals/addCollaborator";
import StatsSaleSkelton from "./skeltons/salesStats";
import AgentDetailLoader from "./skeltons/mainLoader";
import ResponseAlert from "../../../../components/responseAlert";
import { handResetTimeLineData } from "../../../../redux/agents/getAllTimelines";
import { getAgentRecruitingIdsAfterFilter } from "../../../../redux/agents/getAgentList";

const allowRolesForTraining = ["Team Leader", "Admin", "Agent admin"];
const statisticFieldsFirstLine = [
  {
    firstLabel: "Closed Last",
    secondLabel: "12 Mo",
    varName: "totalClosed",
  },
  {
    firstLabel: "Pending",
    secondLabel: "Contracts",
    varName: "totalPendingContract",
  },
  {
    firstLabel: "Lifetime",
    secondLabel: "Sales",
    varName: "totalLifetimeSales",
  },
];

const statisticFieldsSecondLine = [
  {
    firstLabel: "Avg Sales Price",
    secondLabel: "(12mo)",
    varName: "averageSalesPrice",
  },
  {
    firstLabel: "Avg List Price",
    secondLabel: "(12mo)",
    varName: "averageListPrice",
  },
  {
    firstLabel: "Total Volume",
    secondLabel: "(12mo)",
    varName: "totalVolume",
  },
];

const Marker = ({ colorName }) => (
  <div
    className={"marker"}
    style={{ height: "20px", width: "40px", backgroundColor: colorName }}
  />
);

const defaultMapCenter = [33, -84];

const getCenter = (agentProfile) => {
  let filteredProperties = agentProfile?.properties?.filter(
    (it) => it.latitude && it.longitude
  );
  if (filteredProperties?.length) {
    const totalLat = filteredProperties.reduce((a, b) => a + +b.latitude, 0);
    const totalLng = filteredProperties.reduce((a, b) => a + +b.longitude, 0);

    return [
      totalLat / filteredProperties?.length,
      totalLng / filteredProperties?.length,
    ];
  } else {
    return defaultMapCenter;
  }
};

const getMarkerColor = (range) => {
  switch (range) {
    case "0-12":
      return "black";
    case "12-24":
      return "#b7bcbf";
    case "24-36":
      return "white";
    default:
      return null;
  }
};

const AgentRecruitsDetailView = ({ classes, allData }) => {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();
  const [routeId, setRouteId] = useState(param.id);
  const [primaryPhone, setPrimaryPhone] = useState(null);
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [open, setOpen] = useState(false);
  const [editAddressModal, setEditAddressModal] = useState(false);
  const [editPhoneModal, setEditPhoneModal] = useState(false);
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [currentStage, setCurrentStage] = useState("");
  const [currentAssigne, setCurrentAssigne] = useState("initial");
  const [openCollaborateModal, setOpenCollaborateModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { agentAverageStats, loading: statsLoading } = useSelector(
    (item) => item.agentAverageStats
  );

  const { tagsList } = useSelector(
    (item) => item.agentRecruitment.agentRecruitmentTagsList
  );
  const adminAuth = useSelector((state) => state.adminAuth);
  const { list: usersList } = useSelector(
    (item) => item.users.storedListOfUsers
  );
  const data = useSelector((item) => item.agentList);
  const { allFilteredAgentsIds, filters, sorting } = data;

  const { agentProfile, loading, tagsStatus, errMsg } = useSelector(
    (item) => item.agentProfile
  );

  const { stagesList } = useSelector((item) => item.stagesList);

  const fetch = async () => {
    setRouteId(param.id);
    const data = await dispatch(getAgentRecruitingProfile({ id: param.id }));
    if (data.payload.data) {
      let type = null;
      const fmls = data.payload.data.totalVolume12Months_FMLS;
      const gamls = data.payload.data.totalVolume12Months_GAMLS;
      if (
        data.payload.data.agent_gamls_data &&
        data.payload.data.agent_fmls_data
      ) {
        if (gamls > fmls) {
          type = "gamls";
        } else if (gamls < fmls) {
          type = "fmls";
        } else {
          type = "fmls";
        }
      } else if (data.payload.data.agent_gamls_data) {
        type = "gamls";
      } else if (data.payload.data.agent_galmls_data) {
        type = "galmls";
      } else if (data.payload.data.agent_fmls_data) {
        type = "fmls";
      } else if (data.payload.data.agent_stellar_data) {
        type = "stellar";
      } else if (data.payload.data.agent_miamire_data) {
        type = "miamire";
      } else if (data.payload.data.agent_charlotte_data) {
        type = "charlotte";
      }
      if (type) {
        dispatch(getAgentAverageStats({ id: param.id, type: type }));
      } else {
        dispatch(resetAgentAverageStats());
      }
    }
  };

  const setStages = async () => {
    await dispatch(getAgentRecruitingStages());
  };
  useEffect(() => {
    fetch();
    if (tagsList.length <= 0) {
      dispatch(getAgentRecruitingAllTags(param.id));
    }
    setStages();
  }, [param.id]);

  useEffect(() => {
    dispatch(
      getAgentRecruitingIdsAfterFilter({
        ...sorting,
        filters: JSON.stringify(filters),
        pageSize: 25,
        pageNumber: 1,
        resetList: true,
      })
    );
  }, []);

  useEffect(() => {
    if (
      agentProfile?.list?.lead_phones &&
      agentProfile?.list?.lead_phones.length
    ) {
      let phones = JSON.parse(
        JSON.stringify([...agentProfile?.list?.lead_phones])
      );
      let sorted = phones.sort(function (x, y) {
        return x.bad_number - y.bad_number;
      });
      setPrimaryPhone([...sorted]);
    } else {
      setPrimaryPhone([]);
    }
    if (agentProfile?.list?.lead_emails?.length) {
      setPrimaryEmail(agentProfile?.list?.lead_emails || null);
    } else {
      setPrimaryEmail(null);
    }
    if (agentProfile?.list?.stageName) {
      setCurrentStage(agentProfile?.list?.stageName);
    }
    if (agentProfile?.list?.assignedTo) {
      setCurrentAssigne(agentProfile?.list?.assignedTo);
    }
  }, [agentProfile]);

  const closeModal = () => {
    setEditPhoneModal(false);
    setEditEmailModal(false);
    setEditAddressModal(false);
  };

  const removeTags = (tags) => {
    dispatch(removeAgentRecruitmentTags({ tags: tags, id: routeId }));
  };

  const markTaskAsComplete = async (id, isCompleted) => {
    const schema = {
      schema: {
        isCompleted: isCompleted ? false : true,
      },
      id: id,
      statusUpdate: true,
    };
    const fetch = await dispatch(markTaskAsCompleteAgentRecruitment(schema));
    if (fetch?.payload) {
      setErrorAlert({
        errorMsg: "You have successfully update the  task status ",
        errorType: "success",
        isOpen: true,
      });
    }
  };

  const findStageId = (name) => {
    const st = stagesList.filter((el) => el.name === name && el.id);
    return st[0]?.id;
  };
  const findStageName = (id) => {
    const st = stagesList.filter((el) => el.id === id && el.name);
    return st[0]?.name;
  };

  const findUserName = (id) => {
    const st = usersList?.filter((el) => el.id === id && el?.full_name);
    return st[0]?.full_name;
  };

  const getProfileImg = (id) => {
    const st = usersList?.filter((el) => el.id === id);
    return st[0]?.profile_images?.profile_img;
  };

  const updateStageFnc = (e) => {
    const val = e.target.value;
    const id = findStageId(val);
    const name = findStageName(id);
    setCurrentStage(name);

    const schema = {
      agentRecruitmentId: routeId,
      stage: id,
    };
    dispatch(updateStageNameAgentRecruitment({ ...schema }));
  };

  const navigateRecors = (id) => {
    dispatch(handResetTimeLineData());
    navigate(`/tools/recruiting/details/${id}`);
  };
  const updateAssigneFnc = async (e) => {
    setCurrentAssigne(e.target.value);
    const schema = {
      assignedTo: e.target.value,
    };
    dispatch(setInfoAgentRecruitment({ agentId: routeId, schema }));
  };
  const handleSuccessFile = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the file",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };
  const handleDeleteFile = (fileId) => {
    const schema = {
      fileId,
      handleSuccessFile,
      handleError,
    };
    dispatch(deleteFileAPI({ ...schema }));
  };

  const bioTextUpdateFnc = (param) => {
    const schema = {
      schema: {
        bio: param,
      },
      agentId: routeId,
    };
    dispatch(setInfoAgentRecruitment({ ...schema }));
  };

  const handleDeleteCollaborate = (id) => {
    let userIds = [...agentProfile.list.collaborators].map((item) => item.id);
    let filteredUsers = userIds.filter((value) => value !== id);
    dispatch(
      setInfoAgentRecruitment({
        schema: { collaborators: filteredUsers },
        agentId: param.id,
      })
    );
  };

  return (
    <Box sx={{ height: "100%" }}>
      <div className={classes.pageRoot}>
        {errMsg ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Oops! An error occured</AlertTitle>
                {errMsg} —{" "}
                <Link
                  to={`/tools/recruiting/`}
                  style={{ textDecoration: "none" }}
                >
                  <strong>Go Back </strong>
                </Link>
                to agent recruitment list.
              </Alert>
            </Grid>
          </Grid>
        ) : loading ? (
          <Box sx={{ height: "100vh", width: "100%" }}>
            <AgentDetailLoader />
          </Box>
        ) : (
          <>
            <Grid container spacing={2} flexWrap={"nowrap"}>
              <Grid item xs={3} lg={3} sx={{ minWidth: "300px" }}>
                <Stack spacing={1}>
                  <NameCard
                    name={agentProfile?.list?.MemberFullName}
                    market={
                      agentProfile?.list?.agent_gamls_data?.OfficeName ||
                      agentProfile?.list?.agent_gamls_data?.realtor_agent_office
                        ?.OfficeName ||
                      agentProfile?.list?.agent_fmls_data?.OfficeName ||
                      agentProfile?.list?.agent_fmls_data?.realtor_agent_office
                        ?.OfficeName ||
                      agentProfile?.list?.agent_stellar_data?.OfficeName ||
                      agentProfile?.list?.agent_stellar_data
                        ?.realtor_agent_office?.OfficeName ||
                      agentProfile?.list?.agent_charlotte_data?.OfficeName ||
                      agentProfile?.list?.agent_charlotte_data
                        ?.realtor_agent_office?.OfficeName ||
                      agentProfile?.list?.agent_miamire_data?.OfficeName ||
                      agentProfile?.list?.agent_miamire_data
                        ?.realtor_agent_office?.OfficeName ||
                      agentProfile?.list?.MemberOfficeName
                    }
                  />
                  <ContactDetails
                    list={[
                      {
                        type: "phone",
                        onClick: () => setEditPhoneModal(true),
                        value: primaryPhone,
                      },
                      {
                        type: "email",
                        onClick: () => setEditEmailModal(true),
                        value: primaryEmail,
                      },
                    ]}
                    name={agentProfile.list.MemberFullName}
                    agent_id={agentProfile.list.id}
                    agentDetail={agentProfile.list}
                  />

                  <Details
                    title="Details"
                    list={[
                      {
                        key: "Stage",
                        value: agentProfile?.list?.stageName ? (
                          <Box>
                            <FormControl>
                              <Select
                                value={currentStage}
                                label="stage"
                                onChange={updateStageFnc}
                                sx={{
                                  background: "#212121",
                                  borderRadius: "12px",
                                  color: "white",
                                  position: "relative",
                                  height: "28.5px",
                                  padding: "0px 5px !important",
                                  "& .MuiSelect-select": {
                                    padding: "2px 6px !important",
                                    paddingRight: "25px !important",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: "white",
                                    position: "absolute",
                                    right: "0",
                                  },
                                }}
                              >
                                {stagesList && stagesList.length ? (
                                  stagesList.map((ii, index) => (
                                    <MenuItem value={ii.name} key={index}>
                                      {ii.name}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="" disabled>
                                    No items
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        ) : (
                          ""
                        ),
                      },
                      {
                        key: "Last Communication",
                        value: agentProfile?.list
                          ?.lastCommunicationTimestamp ? (
                          <>
                            {moment(
                              agentProfile?.list?.lastCommunicationTimestamp
                            ).fromNow()}
                            &nbsp; (
                            {moment(
                              agentProfile?.list?.lastCommunicationTimestamp
                            ).format("MMM Do")}
                            )
                          </>
                        ) : (
                          "Never"
                        ),
                      },
                      {
                        key: "Assigned to",
                        value: agentProfile?.list?.MemberFirstName ? (
                          <Box>
                            <FormControl>
                              <Select
                                value={currentAssigne}
                                label="Assigne"
                                onChange={updateAssigneFnc}
                                sx={{
                                  borderRadius: "7px",
                                  position: "relative",
                                  "& .MuiSelect-select": {
                                    padding: "0px !important",
                                    paddingRight: "25px !important",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    position: "absolute",
                                    right: "0",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                                input={<OutlinedInput />}
                                renderValue={() => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        fontSize: "12px",
                                        position: "relative",
                                        "& .MuiSvgIcon-root": {
                                          position: "relative",
                                        },
                                      }}
                                      {...stringAvatar(
                                        findUserName(currentAssigne),
                                        getProfileImg(currentAssigne) || ""
                                      )}
                                    />
                                    {findUserName(currentAssigne)}
                                  </Box>
                                )}
                              >
                                <MenuItem value="initial" disabled>
                                  Select Assignee
                                </MenuItem>
                                {usersList && usersList.length ? (
                                  usersList.map((ii, index) => (
                                    <MenuItem
                                      key={index}
                                      value={ii.id}
                                      sx={{ display: "flex", gap: "5px" }}
                                    >
                                      <Avatar
                                        sx={{
                                          width: 30,
                                          height: 30,
                                          fontSize: "12px",
                                        }}
                                        {...stringAvatar(
                                          ii?.full_name,
                                          ii?.profile_images?.profile_img ||
                                            false
                                        )}
                                      />
                                      {ii.full_name}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="" disabled>
                                    No users available
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        ) : (
                          ""
                        ),
                      },
                      {
                        key: "Market",
                        value: agentProfile?.list?.Market,
                      },
                      {
                        key: "Volume Last 12 Months (Stellar)",
                        value: agentProfile?.list?.totalVolume12Months_STELLAR
                          ? parseInt(
                              agentProfile?.list?.totalVolume12Months_STELLAR
                            )
                              .toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                              .slice(0, -3)
                          : "$0",
                      },
                      {
                        key: "Volume Last 12 Months (GAMLS)",
                        value: agentProfile?.list?.totalVolume12Months_GAMLS
                          ? parseInt(
                              agentProfile?.list?.totalVolume12Months_GAMLS
                            )
                              .toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                              .slice(0, -3)
                          : "$0",
                      },
                      {
                        key: "Volume Last 12 Months (GALMLS)",
                        value: agentProfile?.list?.totalVolume12Months_GALMLS
                          ? parseInt(
                              agentProfile?.list?.totalVolume12Months_GALMLS
                            )
                              .toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                              .slice(0, -3)
                          : "$0",
                      },
                      {
                        key: "Volume Last 12 Months (FMLS)",
                        value: agentProfile?.list?.totalVolume12Months_FMLS
                          ? parseInt(
                              agentProfile?.list?.totalVolume12Months_FMLS
                            )
                              .toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                              .slice(0, -3)
                          : "$0",
                      },
                      {
                        key: "Volume Last 12 Months (MIAMI)",
                        value: agentProfile?.list?.totalVolume12Months_MIAMIRE
                          ? parseInt(
                              agentProfile?.list?.totalVolume12Months_MIAMIRE
                            )
                              .toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                              .slice(0, -3)
                          : "$0",
                      },
                      {
                        key: "Volume Last 12 Months (Charlotte)",
                        value: agentProfile?.list?.totalVolume12Months_CAROLINA
                          ? parseInt(
                              agentProfile?.list?.totalVolume12Months_CAROLINA
                            )
                              .toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                              .slice(0, -3)
                          : "$0",
                      },
                      {
                        key: "Total Sales (Stellar)",
                        value: agentProfile?.list?.totalSales_STELLAR
                          ? agentProfile?.list?.totalSales_STELLAR
                          : "0",
                      },
                      {
                        key: "Total Sales (FMLS)",
                        value: agentProfile?.list?.totalSales_FMLS
                          ? agentProfile?.list?.totalSales_FMLS
                          : "0",
                      },
                      {
                        key: "Total Sales (GAMLS)",
                        value: agentProfile?.list?.totalSales_GAMLS
                          ? agentProfile?.list?.totalSales_GAMLS
                          : "0",
                      },
                      {
                        key: "Total Sales (GALMLS)",
                        value: agentProfile?.list?.totalSales_GALMLS
                          ? agentProfile?.list?.totalSales_GALMLS
                          : "0",
                      },
                      {
                        key: "Total Sales (MIAMI)",
                        value: agentProfile?.list?.totalSales_MIAMIRE
                          ? agentProfile?.list?.totalSales_MIAMIRE
                          : "0",
                      },
                      {
                        key: "Total Sales (Charlotte)",
                        value: agentProfile?.list?.totalSales_CAROLINA
                          ? agentProfile?.list?.totalSales_CAROLINA
                          : "0",
                      },
                      {
                        key: "Created on ",
                        value: agentProfile?.list?.createdOn?.date
                          ? moment(agentProfile?.list?.createdOn?.date).format(
                              "MM/DD/YYYY"
                            )
                          : "Never",
                      },
                      {
                        key: "Last modified",
                        value: agentProfile?.list?.lastModified?.date
                          ? moment(
                              agentProfile?.list?.lastModified?.date
                            ).format("MM/DD/YYYY")
                          : "Never",
                      },
                    ]}
                  />

                  <TagsCard
                    loading={tagsStatus?.pending}
                    tagsList={tagsList}
                    id={routeId}
                    prevTags={agentProfile?.list?.realtor_tags || []}
                    removeTags={removeTags}
                  />
                  <BackgroundCard bioTextUpdateFnc={bioTextUpdateFnc} />
                  <SocialCard socialData={agentProfile?.list?.socialData} />
                </Stack>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Stack spacing={1}>
                  <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
                    <AgentRecruitingTabs agentProfile={agentProfile} />
                  </Paper>
                  {allowRolesForTraining.includes(
                    adminAuth?.adminDetail?.role?.name
                  ) && (
                    <Stack
                      sx={{ mt: "15px", mb: "15px" }}
                      direction="row"
                      justifyContent="space-between"
                      flexWrap={"wrap"}
                      alignItems="center"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          disabled={
                            usersList?.find(
                              (item) =>
                                item.id == agentProfile?.list?.assignedTo
                            )
                              ? false
                              : true
                          }
                          onClick={() => setOpen(true)}
                          variant="outlined"
                          className={classes.trainingIssueButton}
                        >
                          TRAINING ISSUE
                        </Button>
                        <Button
                          className={classes.isaWaitButton}
                          variant="contained"
                        >
                          ISA WAIT
                        </Button>
                      </Stack>
                      <Button
                        color="success"
                        className={classes.RecordConversationButton}
                        variant="outlined"
                      >
                        Record Conversion
                      </Button>
                    </Stack>
                  )}
                  <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
                    <AgentRecruitingTimeline
                      usersList={usersList}
                      agentProfile={agentProfile}
                    />
                  </Paper>
                </Stack>
              </Grid>
              <Grid item xs={3} lg={3} sx={{ minWidth: "300px" }}>
                <Stack spacing={1}>
                  <Pagination
                    prev={() => {
                      let prevIndex =
                        allFilteredAgentsIds.findIndex((r) => r == routeId) - 1;
                      if (prevIndex > 0) {
                        navigateRecors(allFilteredAgentsIds[prevIndex]);
                      }
                    }}
                    next={() => {
                      let nextIndex =
                        allFilteredAgentsIds.findIndex((r) => r == routeId) + 1;
                      if (nextIndex > 0) {
                        navigateRecors(allFilteredAgentsIds[nextIndex]);
                      }
                    }}
                    currentIndex={
                      allFilteredAgentsIds.findIndex((r) => r == routeId) + 1
                    }
                    data={allFilteredAgentsIds.length}
                    statsLoading={statsLoading}
                  />
                  {statsLoading ? (
                    <StatsSaleSkelton />
                  ) : (
                    <>
                      <Paper
                        variant="outlined"
                        sx={{ backgroundColor: "white" }}
                      >
                        <br />
                        <AgentStatistics
                          list={agentAverageStats || []}
                          fields={statisticFieldsFirstLine}
                        />
                        <AgentStatistics
                          list={agentAverageStats || []}
                          fields={statisticFieldsSecondLine}
                        />
                        <div
                          className="map-size gray-filter"
                          style={{
                            height: "30vh",
                            width: "95%",
                            margin: "auto",
                            borderRadius: "5px",
                            border: "1px solid black",
                            overflow: "hidden",
                            boxShadow: "0 2px 2px 1px #a3a3a3",
                            filter: "grayscale(100%)",
                            marginBottom: "20px",
                          }}
                        >
                          <GoogleMap
                            className="map-border"
                            bootstrapURLKeys={{
                              key: process.env.REACT_APP_MAP_KEY,
                            }}
                            defaultCenter={getCenter(agentAverageStats)}
                            defaultZoom={8}
                          >
                            {agentAverageStats &&
                              agentAverageStats.properties?.length &&
                              agentAverageStats?.properties
                                ?.filter((it) => it.latitude && it.longitude)
                                .map((item, index) => (
                                  <Marker
                                    key={index}
                                    lat={item.latitude}
                                    lng={item.longitude}
                                    colorName={getMarkerColor(item.Legend)}
                                  />
                                ))}
                          </GoogleMap>
                        </div>
                      </Paper>
                    </>
                  )}
                  <FilesCard
                    id={routeId}
                    handleDeleteFile={handleDeleteFile}
                    classes={classes}
                    setErrorAlert={setErrorAlert}
                  />
                  <CollaboratorsCard
                    setOpenCollaborateModal={setOpenCollaborateModal}
                    classes={classes}
                    collaborators={agentProfile.list.collaborators?.filter(
                      (item) => item.id !== agentProfile?.list?.assignedTo
                    )}
                    handleDeleteCollaborate={handleDeleteCollaborate}
                  />
                  <TasksCard
                    usersList={usersList}
                    id={routeId}
                    tasks={agentProfile.list.realtor_tasks}
                    markTaskAsComplete={markTaskAsComplete}
                  />
                  <AppointmentsCard
                    appointments={agentProfile.list.realtor_appointments}
                    agentDetail={agentProfile.list}
                    usersList={usersList}
                    id={routeId}
                    adminDetail={adminAuth?.adminDetail}
                  />
                </Stack>
              </Grid>
            </Grid>

            <EditAddressModal
              closeModal={closeModal}
              state={editAddressModal}
            />
            <EditPhoneModal closeModal={closeModal} state={editPhoneModal} />
            <EditEmailModal closeModal={closeModal} state={editEmailModal} />
          </>
        )}
      </div>
      <IssuesDetailModal
        open={open}
        setOpen={setOpen}
        issueType={"add"}
        setAppointments={() => {}}
        userProfile={usersList?.find(
          (item) => item.id == agentProfile?.list?.assignedTo
        )}
      />
      <AddCollaboratorModal
        open={openCollaborateModal}
        setOpen={setOpenCollaborateModal}
        id={param.id}
        collaborators={agentProfile.list.collaborators?.filter(
          (item) => item.id !== agentProfile?.list?.assignedTo
        )}
        assignedTo={agentProfile?.list?.assignedTo}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Backdrop
        color="inherit"
        invisible={true}
        open={agentProfile?.deleteFile?.pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default withStyles(styleSheet, { name: "AgentRecruitsDetailViewStyle" })(
  AgentRecruitsDetailView
);
