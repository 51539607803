import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddTransactionNote } from "../../../redux/transactions/addNote";
import { updateContactNote } from "../../../redux/transactions/updateNote";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";

function AddNoteModal(props) {
  let { open, setOpen, classes, detail } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const [noteDetail, setNoteDetail] = useState({
    title: "",
    description: "",
  });
  const [errMsg, setErrMsg] = useState({});
  const addNote = useSelector((state) => state.transactions.TransactionsContract.addNote);
  const updateNote = useSelector((state) => state.transactions.TransactionsContract.updateNote);
  useEffect(() => {
    if (open === "update") {
      setNoteDetail({
        title: detail.title,
        description: detail.body,
      });
    }
  }, [open]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!noteDetail.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter the title";
    } else if (!noteDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter the Description";
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleSuccess = (update) => {
    setErrorAlert({ errorMsg: `You have successfully ${update ? "updated" : "created"}  the note`, errorType: "success", isOpen: true });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({ errorMsg: JSON.stringify(error), errorType: "error", isOpen: true });
  };
  const handleAddNote = () => {
    if (handleValidate()) {
      if (open === "update") {
        let obj = {
          noteDetail: {
            ...noteDetail,
            contractId: id,
          },
          noteId: detail.id,
          handleSuccess,
          handleError,
        };
        dispatch(updateContactNote(obj));
      } else {
      let obj = {
        noteDetail: {
          ...noteDetail,
          contractId: id,
        },
        handleSuccess,
        handleError,
      };
      dispatch(AddTransactionNote(obj));
    }
    }
  };
  const handleUpdateDetail = (field, value) => {
    setNoteDetail({ ...noteDetail, [field]: value });
    setErrMsg({});
  };
  const handleClose = () => {
    setOpen(false);
    setNoteDetail({
      title: "",
      description: "",
    });
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.paymentModalHeading} id="alert-dialog-title">
          {open === "update" ? "Update" : "Create"} Note
        </DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Note title
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter Title"}
                value={noteDetail.title}
                onChange={(e) => handleUpdateDetail("title", e.target.value)}
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 40 }}
                error={errMsg.title}
                helperText={errMsg.title}
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Description
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                error={errMsg.description}
                helperText={errMsg.description}
                rows={6}
                value={noteDetail.description}
                onChange={(e) => handleUpdateDetail("description", e.target.value)}
                placeholder="Enter note description"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "15px", mt: "-3px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleAddNote()}
            classes={{ root: classes.saveModalButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addNote.isLoading || updateNote.isLoading}
          >
            {open === "update" ? "Update" : "Save"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNoteModalStyle" })(AddNoteModal);
