import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Dialog, Grid, TextField, IconButton, Autocomplete, createFilterOptions  } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import { updateTaskStatus } from "../../../redux/transactions/updateTask";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { PDFDocument } from "pdf-lib";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { getParticularContract } from "../../../redux/transactions";

const filter = createFilterOptions();

const postSplitPDFFiles = async (body) => {
  return await fetch(`${process.env.REACT_APP_BASE_URL}api/transactions/upload-multiple-docs`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
    body: body,
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.log("GET_DATA_ERROR: ", error));
};

function UploadPdfSplit(props) {
  let { open, setOpen, classes, id, file, ContractData } = props;
  const [fileSrc, setFileSrc] = useState();
  useEffect(
    (e) => {
      if (file) {
        setFileSrc(URL.createObjectURL(file));
      }
    },
    [file]
  );
  const [uploadLoading, setUploadLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const [splitArr, setSplitArr] = useState([
    {
      from: "",
      to: "",
      docName: "",
      status: "",
    },
    {
      from: "",
      to: "",
      docName: "",
      status: "",
    },
    {
      from: "",
      to: "",
      docName: "",
      status: "",
    },
  ]);

  const pushRow = () => {
    let temp = {
      from: "",
      to: "",
      docName: "",
      status: "",
    };
    setSplitArr([...splitArr, temp]);
  };
  const handleUpdateValue = (value, field, i) => {
    console.log(value);
    let tempArr = [...splitArr];
    let obj = tempArr[i];
    obj[field] = value;
    tempArr.splice(i, 1, obj);
    setSplitArr([...tempArr]);
  };
  const handleDelete = (index) => {
    let tempArr = [...splitArr];
    tempArr.splice(index, 1);
    setSplitArr([...tempArr]);
  };
  const splitPdf = async (e) => {
    try {
      setUploadLoading(true);
      let arr = splitArr.filter((s) => s.docName !== "" && s.docName);
      console.log(arr);
      const byteFile = await getAsByteArray(file);
      let pdfDoc = await PDFDocument.load(byteFile, { ignoreEncryption: true });
      let files = [],
        fileNames = [];
      for (let spl of arr) {
        const subDocument = await PDFDocument.create();
        let pagesArr = Array(parseInt(spl.to) - parseInt(spl.from) + 1)
          .fill()
          .map((_, idx) => parseInt(spl.from) + idx - 1);
        const copiedPages = await subDocument.copyPages(pdfDoc, pagesArr);
        for (let page of copiedPages) {
          await subDocument.addPage(page);
        }
        const pdfBytes = await subDocument.save();
        let newFile = new File([pdfBytes], spl.docName + ".pdf", {
          type: "application/pdf",
        });
        files.push(newFile);
        fileNames.push(spl.docName);
      }
      let formData = new FormData();
      for (let fil of files) {
        let index = files.indexOf(fil);
        formData.append("documents[]", fil, fileNames[index] + ".pdf");
      }
      //   if (ContractData?.data?.attached_listing) {
      //     formData.append('listing_id', ContractData?.data?.attached_listing);
      //   }
      if (ContractData?.data?.id) {
        formData.append("contract_id", ContractData?.data?.id);
      }
      formData.append("address", ContractData?.data?.property_address);
      if (files.length) {
        let response = await postSplitPDFFiles(formData);
        if (response.status === 200) {
          setUploadLoading(false);
          dispatch(getParticularContract({ id: ContractData?.data?.id }));
          setErrorAlert({
            errorMsg: response.message,
            errorType: "success",
            isOpen: true,
          });
          setOpen(false);
          setSplitArr([
            {
              from: "",
              to: "",
              docName: "",
              status: "",
            },
            {
              from: "",
              to: "",
              docName: "",
              status: "",
            },
            {
              from: "",
              to: "",
              docName: "",
              status: "",
            },
          ]);
        } else {
          setOpen(false);
          setUploadLoading(false);
          setErrorAlert({
            errorMsg: response.message,
            errorType: "error",
            isOpen: true,
          });
        }
      } else {
        setUploadLoading(false);
        setErrorAlert({
          errorMsg: "Please select the document name",
          errorType: "warning",
          isOpen: true,
        });
      }
    } catch (error) {
      console.log(error);
      setUploadLoading(false);
      setErrorAlert({
        errorMsg: "Something went wrong! Please check page numbers.",
        errorType: "error",
        isOpen: true,
      });
    }
  };

  async function getAsByteArray(file) {
    return new Uint8Array(await readFile(file));
  }
  function readFile(file) {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader();

      // Register event listeners
      reader.addEventListener("loadend", (e) => resolve(e.target.result));
      reader.addEventListener("error", reject);

      // Read file
      reader.readAsArrayBuffer(file);
    });
  }

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{position:"absolute","& .MuiDialog-container":{height:"90vh",mt:"0px"}}}  
        maxWidth="lg"
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-title">
          Split Pdf Document
        </DialogTitle>
        <DialogContent sx={{ width: "1200px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <iframe src={fileSrc} id="pdfIframe" className="w-100" width="100%" height="650" />
            </Grid>
            <Grid item xs={12} md={5}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>From Page</TableCell>
                      <TableCell>To Page</TableCell>
                      <TableCell>Document Name</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {splitArr.map((row, index) => (
                      <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ width: "80px" }} component="th" scope="row">
                          <TextField
                            onChange={(e) => handleUpdateValue(e.target.value, "from", index)}
                            value={row.from}
                            fullWidth
                            placeholder="1"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ width: "80px" }} align="right">
                          <TextField
                            onChange={(e) => handleUpdateValue(e.target.value, "to", index)}
                            value={row.to}
                            placeholder="3"
                            sx={{ width: "100%" }}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            onChange={(event, newValue) => {
                              handleUpdateValue(newValue, "docName", index);
                            }}
                            value={row.docName}
                            options={
                              ContractData?.data?.transaction_documents && ContractData?.data?.transaction_documents?.length > 0
                                ? ContractData?.data?.transaction_documents?.map((item) => item.document_name || item.document?.name)
                                : []
                            }
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Document name" sx={{ width: "100%" }} fullWidth variant="outlined" size="small" />
                            )}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              const { inputValue } = params;
                              console.log(inputValue);
                              // // Suggest the creation of a new value
                              const isExisting = options.some((option) => inputValue === option);
                              console.log(isExisting);
                              if (inputValue !== "" && !isExisting) {
                                filtered.push(inputValue);
                              }

                              return filtered;
                            }}
                          />
                        </TableCell>
                        <TableCell padding="checkbox">
                          {index > 0 && (
                            <IconButton onClick={() => handleDelete(index)}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Button onClick={pushRow} sx={{ margin: "20px 15px 20px 18px" }} size="small" className={classes.saveModalButton}>
                  Add Row
                </Button>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={splitPdf}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={uploadLoading}
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "uploadPdfSplitStyle" })(UploadPdfSplit);
