import React, { useState, useEffect } from "react";
import { Box, Button, Card, Tab, Tabs } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import { TabContext } from "@mui/lab";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { Search, StyledInputBase } from "../../listings/listingsMain";
import { getCommissionPlans } from "../../../redux/finance/commissionPlans";
import { useDispatch } from "react-redux";
function NavBar({ classes, setCommissionModal }) {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const currentTab = ["/finance/closings/unprocessed", "/finance/closings/processed", "/finance/closings/commission-plans"].find((tab) =>
      location.pathname.startsWith(tab)
    );
    setActiveTab(currentTab);
  }, [location.pathname]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => {
        dispatch(getCommissionPlans({ pageNumber: 1, pageSize: 25, reset: true, search: search }));
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <Box className={classes.root}>
      <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
        <Box className={classes.TopBarArea}>
          <Box className={classes.TopBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TabsUI}>
                  <Tabs
                    value={activeTab}
                    TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                    aria-label="transactions-tabs"
                    sx={{
                      justifyContent: "space-around",
                      "& .Mui-selected": {
                        color: "#0B0909 !important",
                      },
                      "& .MuiTab-root": {
                        fontSize: "13px !important",
                        minHeight: "40px !important",
                      },
                      "& .MuiTabs-root": {
                        minHeight: "40px  !important",
                      },
                      "& .MuiTabs-scroller": {
                        height: "36px  !important",
                      },
                    }}
                  >
                    <Tab
                      label="Closings To Be Processed"
                      value={`/finance/closings/unprocessed`}
                      to={`/finance/closings/unprocessed`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />
                    <Tab
                      label="Processed Closings"
                      value={`/finance/closings/processed`}
                      to={`/finance/closings/processed`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />
                    {/* <Tab
                      label="Tasks"
                      value={`/transactions/tasks`}
                      to={`/transactions/tasks`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    /> */}
                    <Tab
                      label="Commission Plans"
                      value={`/finance/closings/commission-plans`}
                      to={`/finance/closings/commission-plans`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />{" "}
                  </Tabs>
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          {location.pathname.startsWith("/finance/closings/commission-plans") && (
            <Box className={classes.TopAreaRight}>
              {" "}
              <Button
                className={classes.addCommissionButton}
                variant="contained"
                startIcon={<CreateNewFolderRoundedIcon />}
                onClick={(e) => {
                  setCommissionModal(true);
                }}
              >
                New Commission Plan
              </Button>{" "}
              <Search>
                <StyledInputBase
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Commission Plans"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
          )}
        </Box>
      </Card>

      <Outlet />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "FinanceNav" })(NavBar);
