import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getSearchSuggestions } from "./getSuggestions";
import { getLastActivities } from "./getLastActivities";
export const getLeadSavedSearches = createAsyncThunk(
  "LeadSavedSearches/getLeadSavedSearches",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_IDX_BASE_URL
        }api/v1/embedded-app/saved-searches?context=${data?.context}${
          data?.signature ? `&signature=${data?.signature}` : ""
        }${data?.type ? `&type=${data?.type}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.data?.data) {
        if (
          typeof resp.data?.data === "object" &&
          !Array.isArray(resp.data?.data)
        ) {
          thunkAPI.dispatch(
            getSearchSuggestions({ dataset: resp.data?.data?.site_id?.dataset })
          );
          thunkAPI.dispatch(
            getLastActivities({
              site_id: resp.data?.data?.site_id?._id,
              token: resp.data?.data?.token,
            })
          );
        } else {
          thunkAPI.dispatch(
            getSearchSuggestions({
              dataset: resp.data?.data?.find((it) => it?.site_id !== null)
                ?.site_id?.dataset,
            })
          );
          thunkAPI.dispatch(
            getLastActivities({
              site_id: resp.data?.data?.find((it) => it?.site_id !== null)
                ?.site_id?._id,
              token: resp.data?.data?.find((it) => it?.site_id !== null)?.token,
            })
          );
        }
      }
      return { ...resp.data, reset: data.reset };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue({
          ...error.response.data,
          reset: data.reset,
        });
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
