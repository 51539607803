import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getLeadTimelineAPI = createAsyncThunk(
  "leadTimeline/getLeadTimelineAPI",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }api/leads/timelines?sortBy=createdAt&sortOrder=desc&pageNumber=${
          data?.pageNumber || 1
        }&pageSize=${data?.pageSize || 10}&leadId=${data?.leadId}&type=${data?.type || ""}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return {
        ...resp.data,
        initial: data?.initial,
        pageNumber: data?.pageNumber,
        type:data?.type
      };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
