import * as React from "react";
import { Card, Grid, Box, TableContainer, Table, TableRow, TableCell, Paper } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
function DocsDetailSkelton(props) {
  let { count = 6 } = props;
  return (
    <Box>
      <Grid container spacing={2}>
        {Array.from(Array(count).keys()).map(() => (
          <Grid item sm={12} md={4}>
            <Card sx={{ mb: "15px" }}>
              <CardHeader
                avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                title={<Skeleton animation="wave" height={20} width="50%" style={{ marginBottom: 6 }} />}
                subheader={<Skeleton animation="wave" height={15} width="80%" />}
              />
              <Skeleton sx={{ height: 60 }} animation="wave" variant="rectangular" />
              <CardContent>
                <React.Fragment>
                  <Skeleton animation="wave" height={15} style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" height={10} width="80%" />
                </React.Fragment>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          {Array.from(Array(count).keys()).map(() => (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell sx={{ width: "250px" }}>
                <Skeleton animation="wave" height={50} width="250px" sx={{ padding: "0px" }} />
              </TableCell>
              <TableCell sx={{ width: "120px" }}>
                <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
              </TableCell>
              <TableCell sx={{ width: "160px" }}>
                <Skeleton animation="wave" height={50} width="160px" sx={{ padding: "0px" }} />
              </TableCell>
              <TableCell sx={{ width: "160px" }}>
                <Skeleton animation="wave" height={50} width="160px" sx={{ padding: "0px" }} />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
}
export default DocsDetailSkelton;
