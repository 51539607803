import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const addTransaction = createAsyncThunk(
  "TransactionsList/addTransaction",
  async (data, thunkAPI) => {
    let id=data.id
    delete data.id
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/transactions/contracts`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

