import React, { useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../redux/marketingCenter/digital";
import CategoriesSkelton from "../skelton/categories";
function PrintContent(props) {
  let { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoriesList = useSelector(
    (state) => state.marketingCenter.digital.categoriesList
  );
  
  useEffect(() => {
    dispatch(getAllCategories({ medium: "Print" }));
  }, []);

  return (
    <Box className={classes.digitalContentSectionArea}>
      <Box className={classes.digitalContentSection}>
        {categoriesList.isLoading ? (
          <CategoriesSkelton count={4} width="292px" />
        ) : categoriesList?.data?.length ? (
          categoriesList?.data?.map((item, index) => (
            <Box
              onClick={() =>
                navigate(`/marketing_center/digital/category/${item?._id}`)
              }
              className={classes.digitalContentSectionItem}
              key={item?._id}
            >
              <Box className={classes.digitalContentSectionItemTop}>
                <img src={item.image} alt={item.name} />
              </Box>
              <Typography className={classes.digitalContentSectionItemContent}>
                {item?.name}
              </Typography>
            </Box>
          ))
        ) : (
          <Box className={classes.digitalContentSectionItem}>
            <Box
              className={classes.digitalContentSectionItemTop}
              sx={{ alignItems: "center !important" }}
            >
              <ErrorOutlineRoundedIcon
                sx={{ fontSize: "80px", color: "grey" }}
              />
            </Box>
            <Typography className={classes.digitalContentSectionItemContent}>
              {" "}
              Not Exists
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default withStyles(styleSheet, { name: "DigitalContentStyles" })(
  PrintContent
);
