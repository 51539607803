import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getMakeoverList = createAsyncThunk("ListingMakeover/getMakeoverList", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/transactions/makeover?pageSize=${data?.pageSize || "25"}&pageNumber=${data?.pageNumber || "1"}&repayment_status=${
        data?.status || ""
      }&search=${data?.search || ""}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { ...resp.data, pageNumber: data.pageNumber };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
