import { createSlice } from "@reduxjs/toolkit";
import { CreateNote } from "./addNote";
import { deleteNote } from "./deleteNote";
import { updateNote } from "./updateNote";

const initialState = {
  notes: [],
  addNote: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  updateNote: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  deleteNote: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
};
const AgentNotes = createSlice({
  name: "AgentNotes",
  initialState,
  reducers: {
    updateAgentNotes: (state, action) => {
      state.notes = action.payload;
    },
  },
  extraReducers: {
    [CreateNote.pending]: (state) => {
      state.addNote.isLoading = true;
      state.addNote.errMsg = null;
      state.addNote.success = false;
    },
    [CreateNote.fulfilled]: (state, action) => {
      state.addNote.isLoading = false;
      state.addNote.success = true;
      state.addNote.data = action.payload;
      let notes = [...state.notes];
      notes.unshift(action.payload);
      state.notes = notes;
    },
    [CreateNote.rejected]: (state, action) => {
      state.addNote.isLoading = false;
      state.addNote.success = false;
      state.addNote.errMsg = action.payload;
    },
    // delete note
    [deleteNote.pending]: (state) => {
      state.deleteNote.isLoading = true;
      state.deleteNote.errMsg = null;
      state.deleteNote.success = false;
    },
    [deleteNote.fulfilled]: (state, action) => {
      state.deleteNote.isLoading = false;
      state.deleteNote.success = true;
      state.deleteNote.data = action.payload;
      let notes = [...state.notes];
      let index = notes.findIndex((item) => item.id === action.payload.noteId);
      notes.splice(index, 1);
      state.notes = notes;
    },
    [deleteNote.rejected]: (state, action) => {
      state.deleteNote.isLoading = false;
      state.deleteNote.success = false;
      state.deleteNote.errMsg = action.payload;
    },
    // update note
    [updateNote.pending]: (state) => {
      state.updateNote.isLoading = true;
      state.updateNote.errMsg = null;
      state.updateNote.success = false;
    },
    [updateNote.fulfilled]: (state, action) => {
      state.updateNote.isLoading = false;
      state.updateNote.success = true;
      state.updateNote.data = action.payload;
      let notes = [...state.notes];
      let index = notes.findIndex((item) => item.id === action.payload.noteId);
      notes[index] = { ...notes[index], ...action.payload };
      state.notes = notes;
    },
    [updateNote.rejected]: (state, action) => {
      state.updateNote.isLoading = false;
      state.updateNote.success = false;
      state.updateNote.errMsg = action.payload;
    },
  },
});
export const { updateAgentNotes } = AgentNotes.actions;
export default AgentNotes.reducer;
export * from "./addNote";
export * from "./deleteNote";
export * from "./updateNote";
