import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  closing: {},
  errMsg: null,
  actionLoading:false,
};

export const getProcessedClosing = createAsyncThunk(
  "finance/getProcessedClosing",
  async (fileId, thunkAPI) => {
    try {
      const url = `/api/finance/processed-closings/${fileId}`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const unprocessedClosing = createSlice({
  name: "processedClosing",
  initialState,
  extraReducers: {
    [getProcessedClosing.pending]: (state) => {
      state.loading = true;
    },
    [getProcessedClosing.fulfilled]: (state, action) => {
      state.loading = false;
      state.closing = action.payload.data;
    },
    [getProcessedClosing.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default unprocessedClosing.reducer;
