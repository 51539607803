import React from "react";
import { Paper, Box, ListItemText, Chip, Stack } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import TableSkeleton from "../../../components/loader/tableLoader";
import Avatar from "../../../components/uiElements/avatar/index";
import { CustomTooltip } from "../../../components/shared/tooltip";
import { NumberFormat } from "../../../utils/numberFormat";
import { ListingColors } from "../../../utils/listingStatusColor";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import RequestPageRoundedIcon from "@mui/icons-material/RequestPageRounded";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function MakeoverListingTable(props) {
  let { classes, setFilters } = props;
  const navigate = useNavigate();
  const makeoverList = useSelector((state) => state.listings.makeover.makeoverList);

  const columns = [
    {
      field: "Image",
      headerName: "Image",
      renderCell: ({ row }) => {
        return (
          <Avatar
            variant="rounded"
            src={row?.transaction_listings_v2?.property_img}
            sx={{ width: "99px", height: "75px", padding: "3px 0px", objectFit: "contain", borderRadius: "5px", "& img": { borderRadius: "5px" } }}
          />
        );
      },
      width: 130,
      editable: false,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Address",
      renderCell: ({ row }) => {
        return (
          <ListItemText
            primary={row?.transaction_listings_v2?.street}
            secondary={`${row?.transaction_listings_v2?.city}, ${row?.transaction_listings_v2?.state}  ${row?.transaction_listings_v2?.zipcode}`}
          />
        );
      },
      width: 250,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "Agent",
      headerName: "Listing Agent",
      renderCell: ({ row }) => {
        // console.log(params);
        return row?.transaction_listings_v2?.agent ? (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Avatar src={row?.transaction_listings_v2?.agent?.profile_images?.profile_img_thumbnail} variant="rounded">
              {row?.transaction_listings_v2?.agent?.full_name?.slice(0, 1) +
                row?.transaction_listings_v2?.agent?.full_name?.split(" ")[1]?.slice(0, 1)}
            </Avatar>
            <ListItemText
              primary={row?.transaction_listings_v2?.agent?.full_name?.split(" ")[0]}
              secondary={row?.transaction_listings_v2?.agent?.full_name?.split(" ")[1]}
            />
          </Stack>
        ) : null;
      },
      minWidth: 140,
      editable: false,
      sortable: true,
      flex: 0.5,
    },
    {
      field: "max_budget",
      headerName: "Approved",
      renderCell: ({ row }) => {
        return <Box>{row?.max_budget ? NumberFormat({ number: row?.max_budget, maximumFractionDigits: 2 }) : ""}</Box>;
      },
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "invoice_paid_amount",
      headerName: "Spent",
      renderCell: ({ row }) => {
        return <Box>{row?.invoice_paid_amount ? NumberFormat({ number: row?.invoice_paid_amount, maximumFractionDigits: 2 }) : "$0.00"}</Box>;
      },
      width: 110,
      editable: false,
      sortable: true,
    },
    {
      field: "remaining",
      headerName: "Remaining",
      renderCell: ({ row }) => {
        // console.log(params);
        return (
          <Box>
            {row?.invoice_paid_amount && row?.max_budget
              ? NumberFormat({
                  number: Number(row?.max_budget || 0) - Number(row?.invoice_paid_amount || 0),
                  maximumFractionDigits: 2,
                })
              : row?.max_budget
              ? NumberFormat({ number: row?.max_budget, maximumFractionDigits: 2 })
              : "$0.00"}
          </Box>
        );
      },
      width: 110,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Listing Status",
      align: "center",
      renderCell: ({ row }) => (
        <CustomTooltip placement="top" title={row.transaction_listings_v2?.status}>
          <Chip
            size="small"
            sx={{
              backgroundColor: ListingColors[row.transaction_listings_v2?.status] || "#000000",
              minWidth: "100px",
              textTransform: "capitalize",
            }}
            label={row.transaction_listings_v2?.status}
            color="success"
          />
        </CustomTooltip>
      ),
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "Closing",
      headerName: "Closing Date",
      renderCell: ({ row }) => {
        return <Box>{row?.transaction_contracts_v2?.closing_date ? moment(row?.transaction_contracts_v2?.closing_date).format("L") : ""}</Box>;
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "Alerts",
      headerName: "Alerts",
      renderCell: ({ row }) => {
        return row.makeover_invoices?.length && row?.makeover_invoices?.filter((it) => it.status !== "paid")?.length ? (
          <Box>
            {" "}
            <CustomTooltip title={"Unpaid Invoice"}>
              <RequestPageRoundedIcon sx={{ color: "#C62828" }} fontSize="large" />
            </CustomTooltip>
          </Box>
        ) : null;
      },
      align: "center",
      width: 100,
      editable: false,
      sortable: true,
    },
  ];

  const redirectDetails = (data) => {
    navigate(`/listings/listing-detail/${data?.transaction_listings_v2?.id}/makeover`);
  };

  return (
    <Paper className={classes.tableRoot}>
      <DataGridPro
        rows={makeoverList?.data || []}
        columns={columns}
        loading={makeoverList.isLoading}
        rowHeight={80}
        components={{
          LoadingOverlay: TableSkeleton,
        }}
        onRowClick={({ row }) => {
          redirectDetails(row);
        }}
        onRowsScrollEnd={(e) => {
          if (makeoverList.count > e.virtualRowsCount && !makeoverList.isLoading) {
            setFilters((data) => ({ ...data, pageNumber: data.pageNumber + 1 }));
          }
        }}
      />
    </Paper>
  );
}
export default withStyles(styleSheet, { name: "MakeoverListingTableStyle" })(MakeoverListingTable);
