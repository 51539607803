import * as React from "react";
import ListingsMain from "./listingsMain";
import ListingDetail from "./listingDetail";
import ListingDatasetDetailPage from "./listingDatasetDetail";
import { Route, Routes } from "react-router-dom";
import OpenHouse from "./openHouse";
import MakeoverMain from "./makeover"

const Listings = (props) => {
  return (
    <Routes>
      <Route path="/*" element={<ListingsMain />} />
      <Route path="/makeover/*" element={<MakeoverMain />} />
      <Route path="/open-house/*" element={<OpenHouse />} />
      <Route path="/listing-detail/:id/*" element={<ListingDetail />} />
      <Route path="/fmls/:id/*" element={<ListingDatasetDetailPage />} />
    </Routes>
  );
};

export default Listings;
