export const styleSheet = (theme) => ({
  commentSectionBox: {
    borderLeft: "2px solid rgba(0, 0, 0, 0.1)",
    paddingTop: "20px",
    overflow: "hidden",
  },
  commentSectionTop: {
    color: "#8D8B8B",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    height: "35px",
    borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
    width: "100%",
  },
  messageSection: {
    padding: "0px 15px 15px 15px",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  writeCommentSection: {
    borderTop: "2px solid rgba(0, 0, 0, 0.1)",
    padding: "15px",
    "& .MuiOutlinedInput-root": {
      alignItems: "flex-start !important",
    },
  },
  commentTime: {
    fontSize: "11px !important",
    color: "black !important",
    fontWeight: "400  !important",
    marginTop: "10px !important",
    textAlign: "right !important",
  },
  messageSectionProfileName: {
    fontSize: "12.5px !important",
    color: "black !important",
    fontWeight: "800  !important",
  },
  commentContent: {
    padding: "10px",
    marginLeft: "33px",
    marginTop: "-8px",
    marginRight: "5px",
    width: "87%",
    color: "black !important",
    borderRadius: "6px !important",
  },
  commentContentText: {
    fontSize: "12.5px !important",
    wordBreak: "break-word  !important",
  },
  adornedEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  commentFieldBox: {
    border: "1px  solid lightgrey",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    padding: "3px 4.5px",
  },
  commentFieldBoxLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "5px 10px",
    width: "92%",
  },
  commentFieldActions: {
    width: "8%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    marginRight: "2px",
    height: "30px",
  },
});
