import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import UnprocessedTable from "./UnprocessedTable";
import CheckOrACHStats from "./CheckOrACHStats";
import { useDispatch, useSelector } from "react-redux";
import { getPendingPayments } from "../../../redux/finance/getPendingPayments";
import { useLocation } from "react-router-dom";
import ProcessedTable from "./ProcessedTable";
import AchToSend from "./AchToSend";
import ChecksToPrint from "./ChecksToPrint";

function UnprocessedClosings(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading: pendingChecksLoading, pendingPayments } = useSelector((item) => item.finance.pendingPayments);

  useEffect(() => {
    dispatch(getPendingPayments());
  }, []);
  return (
    <Container className={classes.StatsArea} maxWidth="lg">
      <CheckOrACHStats {...props} loading={pendingChecksLoading} data={pendingPayments} />
      {location.pathname === "/finance/closings/unprocessed" ? (
        <UnprocessedTable {...props} />
      ) : location.pathname === "/finance/closings/checks-to-print" || location.pathname === "/finance/closings/checks-to-print/" ? (
        <ChecksToPrint {...props} />
      ) : location.pathname === "/finance/closings/ach-to-send" || location.pathname === "/finance/closings/ach-to-send/" ? (
        <AchToSend {...props} />
      ) : (
        <ProcessedTable {...props} />
      )}
    </Container>
  );
}
export default withStyles(styleSheet, { name: "UnprocessedClosingsStyle" })(UnprocessedClosings);
