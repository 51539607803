import React, { useEffect, useState } from "react";
import { Grid, Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import ChatIcon from "@mui/icons-material/Chat";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "../../components/uiElements/avatar/index";
import PersonIcon from "@mui/icons-material/Person";
import Chip from "@mui/material/Chip";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Box, Button, Stack, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import BallotIcon from "@mui/icons-material/Ballot";
import SunnyCloudIcon from "../../assets/sunny-cloud_icon.svg";
import {
  getActivity,
  getAlerts,
  getClosings,
  getProjectCount,
  resetDashboardData,
} from "../../redux/dashboard/index.js";
import { timesAgo } from "./helpers.js";
import { numberWithCommas } from "../tools/cmas/helper";
import { getMarkets } from "../../redux/agents/addRecruitAgent.js";
import { useNavigate } from "react-router-dom";
import { handleMaximizeTab } from "../../redux/widgets";
import AlertSkelton from "./skeltons/alerts";
import ActivitySkelton from "./skeltons/activitySkelton";
import AgentPoints from "./pointsCard";
import { handleGetEvents } from "../../redux/eventsAndAlerts/events";
import DataNotFound from "../../components/notFound/dataNotFound";
import NoteIcon from "@mui/icons-material/Note";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EventDetail from "../eventsAndAlerts/modals/eventDetail";
import { NumberFormat } from "../../utils/numberFormat";
function QontoStepIconSkelton(props) {
  const { active, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <Skeleton height="33px" width="52px" />{" "}
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

const moment = require("moment-timezone");
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 18,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 12,
  },
  "& .QontoStepIcon-circle": {
    width: 11,
    height: 11,
    marginLeft: "4px",
    borderRadius: "50%",
    color: "#0B0909",
    backgroundColor: "currentColor",
  },
  "& .QontoStepIcon-label": {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "15px",
    marginRight: "5px",
  },
}));

function MainDashBoard(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [eventData, setEventData] = useState({});
  const [openEventDetail, setOpenEventDetail] = useState(false);
  const adminAuth = useSelector((state) => state.adminAuth);
  const eventList = useSelector((item) => item.eventAlerts.events.eventList);
  const {
    closings,
    closingsLoading,
    activities,
    activityLoading,
    alertsLoading,
    alerts: { count, rows },
    projectCount,
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getProjectCount());
      dispatch(getActivity());
      dispatch(getAlerts(offset));
      dispatch(getClosings({ id: adminAuth?.adminDetail?.id }));
      dispatch(getMarkets());
    }, 2000);

    return () => {
      dispatch(resetDashboardData);
    };
  }, [adminAuth.isAuthenticated === true]);

  const handleMaximize = (data) => {
    dispatch(handleMaximizeTab(data));
  };

  useEffect(() => {
    dispatch(
      handleGetEvents({
        pageNumber: 1,
        pageSize: 4,
        view_past_events: false,
        search: "",
        path: "user-events",
      })
    );
  }, []);

  function QontoStepIcon(props) {
    const { active, className, icon } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        <div className="QontoStepIcon-label">
          {moment(eventList?.data?.data[icon - 1].event_date)
            .tz("America/New_York")
            .format("MMM DD")}{" "}
        </div>{" "}
        <div className="QontoStepIcon-circle" />
      </QontoStepIconRoot>
    );
  }

  const getCurrentGreeting = () => {
    let myDate = new Date();
    let hrs = myDate.getHours();
    let greet;
    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
    return greet;
  };

  return (
    <Container maxWidth="xl">
      <div className={classes.mainDashboardPage}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Paper elevation={2} className={classes.methodWeatherBox}>
              <div className={classes.methodWeatherTop}>
                <h1 className={classes.methodWeatherTopH1}>
                  {getCurrentGreeting()}, {adminAuth?.adminDetail?.full_name}
                </h1>
                <div className={classes.methodWeatherTopInfo}>
                  <div className={classes.methodWeatherTopInfoDes}>
                    Partly Cloudy, Rain Possible
                    <br />
                    <span className={classes.methodWeatherTopInfoDesSpan}>
                      H 70&nbsp; L 53
                      <br />
                      Dunwoody, Georgia
                    </span>
                  </div>
                  <div>
                    <img
                      className={classes.methodWeatherTopInfoDesImg}
                      src={SunnyCloudIcon}
                      alt="cloud icon"
                    />
                    {/* <WbTwilightIcon /> */}
                  </div>
                </div>
              </div>
              <div className={classes.methodWeatherBottom}>
                There are <b>6 new </b> properties in your <b>Alpharetta</b>{" "}
                saved search.
                <br />
                {projectCount > 0 && (
                  <>
                    There is <b>{projectCount} new</b> completed task awaiting
                    your approval.
                  </>
                )}
              </div>
            </Paper>

            <Paper
              elevation={2}
              className={classes.boxArea}
              sx={{ minHeight: "350px !important" }}
            >
              <h2 className={classes.boxDashboardTitle}>Activity</h2>
              <TableContainer className={classes.boxDashboard}>
                <Table
                  sx={{ minWidth: 500 }}
                  size="small"
                  aria-label="simple table"
                >
                  {activityLoading && <ActivitySkelton count={5} />}
                  {!activityLoading && activities?.length === 0 && (
                    <DataNotFound
                      color="#BDBDBD"
                      fontSize="20px"
                      minHeight="350px"
                      icon={
                        <BallotIcon
                          sx={{ fontSize: "90px", color: "#BDBDBD" }}
                          fontSize="large"
                        />
                      }
                      title="No Activities Found"
                    />
                  )}
                  {!activityLoading &&
                    activities?.map((item) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          scope="row"
                          sx={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item?.firstName ? (
                            <Avatar
                              sx={{ fontWeight: "400", fontSize: "15px" }}
                              variant="circle"
                            >
                              {`${item?.firstName?.slice(
                                0,
                                1
                              )}${item?.lastName?.slice(0, 1)}`}
                            </Avatar>
                          ) : (
                            <Avatar isIcon variant="circle">
                              <PersonIcon />
                            </Avatar>
                          )}
                        </TableCell>

                        <TableCell
                          sx={{ width: "200px" }}
                          className={classes.tableCell}
                        >
                          {item?.name} <br></br>
                          {item.lastLeadActivityType}
                        </TableCell>

                        <TableCell className={classes.tableCell} align="left">
                          {item?.lastLeadActivityPropertyStreet
                            ? `${item?.lastLeadActivityPropertyStreet}, `
                            : ""}{" "}
                          {item?.lastLeadActivityPropertyPrice
                            ? `${NumberFormat({
                                number: item?.lastLeadActivityPropertyPrice,
                                maximumFractionDigits: 2,
                              })}, `
                            : ""}
                          {item.lastLeadActivityPropertyCode
                            ? `${item.lastLeadActivityPropertyCode}`
                            : ""}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {timesAgo(item.lastIdxVisit)}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </TableContainer>
            </Paper>

            <Paper elevation={2} className={classes.boxArea}>
              <h2 className={classes.boxDashboardTitle}>Recent Closings</h2>
              <TableContainer
                className={classes.boxDashboard}
                sx={{ minHeight: "20px" }}
              >
                <Table
                  sx={{ minWidth: 500 }}
                  size="small"
                  aria-label="simple table"
                >
                  {closingsLoading && <ActivitySkelton hideAvatar count={5} />}
                  {!closingsLoading && (
                    <TableHead className={classes.tableTh}>
                      <TableRow>
                        <TableCell className={classes.tableHCell}>
                          Address
                        </TableCell>
                        <TableCell className={classes.tableHCell} align="right">
                          Closing Date{" "}
                        </TableCell>
                        <TableCell className={classes.tableHCell} align="right">
                          Amount
                        </TableCell>
                        <TableCell className={classes.tableHCell} align="right">
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}

                  {!closingsLoading &&
                    closings?.map((item) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell scope="row" className={classes.tableCell}>
                          {item.property_address}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {moment(item.createdAt)
                            .tz("America/New_York")
                            .format("l")}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {`$${numberWithCommas(item.amount)}` || item.amount}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          <Chip
                            label={item.status}
                            variant="outlined"
                            className={
                              item.status === "Processing"
                                ? classes.processing
                                : item.status === "Deposited"
                                ? classes.deposited
                                : ""
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </TableContainer>
              {closings?.length === 0 && !closingsLoading && (
                <DataNotFound
                  color="#BDBDBD"
                  fontSize="20px"
                  minHeight="250px"
                  icon={
                    <ReceiptIcon
                      sx={{ fontSize: "90px", color: "#BDBDBD" }}
                      fontSize="large"
                    />
                  }
                  title="No Closings Found"
                />
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} md={5}>
            <Paper elevation={2} sx={{ display: "none" }}>
              <h2 className={classes.boxDashboardTitle}>Alerts</h2>
              <Box className={classes.boxDashboard}>
                {!alertsLoading && (
                  <>
                    {rows?.map((row) => {
                      return (
                        <Paper
                          onClick={() =>
                            handleMaximize({
                              id: row.id,
                              action: true,
                              type: "sendTextOpen",
                              tabDetail: { ...row, toNumber: [row.toNumber] },
                            })
                          }
                          elevation={3}
                          className={classes.alertboxDashboardItem}
                        >
                          <div className={classes.alertboxDashboardItemIcon}>
                            <ChatIcon />
                          </div>
                          <div style={{ width: "100%", paddingRight: "10px" }}>
                            <Box className={classes.alertboxDashboardItemTitle}>
                              <Typography variant="h5">{row.name}</Typography>
                              <Typography variant="h5">
                                {timesAgo(row.sent)}
                              </Typography>
                            </Box>
                            <Typography
                              sx={{ wordBreak: "break-word !important" }}
                              className={
                                classes.alertboxDashboardItemDescription
                              }
                            >
                              {" "}
                              {row.message}
                            </Typography>
                          </div>
                        </Paper>
                      );
                    })}
                  </>
                )}
                {!alertsLoading && rows?.length === 0 && <p>No Alerts Found</p>}

                {alertsLoading && <AlertSkelton count={6} />}
              </Box>
            </Paper>
            <Paper elevation={2} sx={{ mb: "20px" }}>
              <h2
                className={classes.boxDashboardTitle}
                style={{ marginBottom: "0px" }}
              >
                Points
              </h2>
              <AgentPoints adminDetail={adminAuth?.adminDetail} {...props} />
            </Paper>

            <Paper
              elevation={2}
              className={classes.boxArea}
              sx={{ mb: "20px", minHeight: "30vh !important" }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <h2 className={classes.boxDashboardTitle}>
                  Upcoming Events & Training
                </h2>
                {eventList.data?.data?.length > 4 ? (
                  <Button
                    onClick={() =>
                      navigate(`/events_and_alerts/events`, {
                        state: { dashboardView: true },
                      })
                    }
                    sx={{
                      border: "1px solid rgba(33, 33, 33, 0.4)",
                      height: "32px",
                      mr: "20px",
                      mt: "-12px",
                      whiteSpace: "nowrap",
                    }}
                    variant="outlined"
                    color="inherit"
                    size="small"
                  >
                    View All
                  </Button>
                ) : null}
              </Stack>

              <Box
                className={classes.boxDashboard}
                sx={{ minHeight: "250px !important" }}
              >
                <Stepper
                  activeStep={3}
                  orientation="vertical"
                  connector={<QontoConnector />}
                >
                  {eventList.isLoading ? (
                    <Paper elevation={2} className={classes.boxArea}>
                      <h2 className={classes.boxDashboardTitle}>
                        <Skeleton
                          variant="rectangular"
                          height="30px"
                          width="50%"
                        />
                      </h2>
                      <Box className={classes.boxDashboard}>
                        <Stepper
                          activeStep={3}
                          orientation="vertical"
                          connector={<QontoConnector />}
                        >
                          {Array.from(Array(4).keys()).map((step, index) => (
                            <Step expanded key={step.label}>
                              <StepLabel
                                StepIconComponent={QontoStepIconSkelton}
                              >
                                <Skeleton height="25px" width="210px" />
                              </StepLabel>
                              <StepContent
                                TransitionProps={{ unmountOnExit: false }}
                              >
                                <Typography>
                                  <Skeleton height="25px" width="110px" />
                                </Typography>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </Paper>
                  ) : (
                    ""
                  )}
                  {eventList.data?.data?.length && !eventList.isLoading
                    ? eventList.data?.data?.slice(0, 4)?.map((item, index) => (
                        <Step
                          expanded
                          key={item.id}
                          onClick={() => {
                            setEventData(item);
                            setOpenEventDetail(true);
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          <StepLabel StepIconComponent={QontoStepIcon}>{`${
                            item.title
                          }:  ${moment(item.start_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )} at ${
                            item.location_title || item.location
                          }`}</StepLabel>
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                          >
                            <Typography
                              className={classes.eventStepperDescription}
                            >
                              {" "}
                              {item.body}
                            </Typography>
                          </StepContent>
                        </Step>
                      ))
                    : ""}
                  {!eventList.isLoading && !eventList.data?.data?.length ? (
                    <DataNotFound
                      color="#BDBDBD"
                      fontSize="20px"
                      minHeight="250px"
                      icon={
                        <NoteIcon
                          sx={{ fontSize: "90px", color: "#BDBDBD" }}
                          fontSize="large"
                        />
                      }
                      title="No Events Exist"
                    />
                  ) : null}
                </Stepper>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <EventDetail
        open={openEventDetail}
        setOpen={setOpenEventDetail}
        eventData={eventData}
      />
    </Container>
  );
}
export default withStyles(styleSheet, { name: "MainDashBoardStyle" })(
  MainDashBoard
);
