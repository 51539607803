import React, { useState, useEffect } from "react";
import { Card, CardHeader, IconButton, Box, Skeleton, Grid, Avatar } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { updatePublicData } from "../../../../redux/listings/publicData";
import ResponseAlert from "../../../../components/responseAlert";
import template_01 from "../../../../assets/images/template_01.png";
import template_02 from "../../../../assets/images/template_02.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function ListingDataTemplates(props) {
  let { classes, publicDataDetail, isLoading } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [detail, setDetail] = useState({
    template: "",
  });

  useEffect(() => {
    if (publicDataDetail.template) {
      setDetail({ template: Number(publicDataDetail.template) });
    }
  }, [publicDataDetail.template]);
  const dispatch = useDispatch();
  const handleSuccess = (update) => {
    setErrorAlert({ errorMsg: `You have successfully update the template`, errorType: "success", isOpen: true });
  };

  const handleError = (error) => {
    setErrorAlert({ errorMsg: JSON.stringify(error), errorType: "error", isOpen: true });
  };

  const handleUpdateDetail = (field, value) => {
    setDetail({ ...detail, [field]: value });
    handleSave(value);
  };

  const handleSave = (value) => {
    let obj = {
      schema: { template: value },
      listing_public_data_id: publicDataDetail.id,
      handleSuccess,
      handleError,
    };
    dispatch(updatePublicData(obj));
  };

  return (
    <React.Fragment>
      <Card className={classes.overViewTabCardMain}>
        <CardHeader
          classes={{ title: classes.overViewTabCardHeading }}
          sx={{ paddingLeft: "0px", paddingRight: "0px", mb: "12px" }}
          title="Template"
        />
        {isLoading ? (
          <Box>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item md={12} lg={6}>
              <IconButton
                onClick={() => handleUpdateDetail("template", 1)}
                sx={{
                  borderRadius: "6px",
                  width: "100%",
                  border: detail?.template === 1 ? "5px solid #0BB85A" : "",
                  padding: detail?.template === 1 ? "0px" : "5px",
                  position: "relative",
                }}
              >
                {detail?.template === 1 ? (
                  <CheckCircleIcon
                    sx={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      color: "#0BB85A",
                      zIndex: 10,
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                ) : null}

                <Avatar sx={{ width: "100%", height: "106px" }} src={template_01} variant="rounded" />
              </IconButton>
            </Grid>
            <Grid item md={12} lg={6}>
              <IconButton
                onClick={() => handleUpdateDetail("template", 2)}
                sx={{
                  borderRadius: "6px",
                  width: "100%",
                  border: detail?.template === 2 ? "5px solid #0BB85A" : "",
                  padding: detail?.template === 2 ? "0px" : "5px",
                  position: "relative",
                }}
              >
                {detail?.template === 2 ? (
                  <CheckCircleIcon
                    sx={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      color: "#0BB85A",
                      zIndex: 10,
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                ) : null}

                <Avatar sx={{ width: "100%", height: "106px" }} src={template_02} variant="rounded" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Card>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "ListingDataTemplatesStyle" })(ListingDataTemplates);
