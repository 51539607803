import * as React from "react";
import { Card, CardHeader, Avatar, Grid, Skeleton } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";

function ListingsStats(props) {
  let { classes } = props;
  const ListingsAll = useSelector(
    (state) => state.listings.ListingsData.ListingsAll
  );
  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="center"
      display="flex"
    >
      <Grid item md={3} lg={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.ListingCardHeader,
              subheader: classes.ListingCardDetail,
            }}
            avatar={<Avatar sx={{ width: "40px", height: "40px" }}>OP</Avatar>}
            title={
              ListingsAll.isLoading ? (
                <Skeleton width={60} variant="text" />
              ) : (
                ListingsAll.count
              )
            }
            subheader="Listings"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.ListingCardHeader,
              subheader: classes.ListingCardDetail,
            }}
            avatar={<Avatar sx={{ width: "40px", height: "40px" }}>OP</Avatar>}
            title="0"
            subheader="Under Contract"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.ListingCardHeader,
              subheader: classes.ListingCardDetail,
            }}
            avatar={<Avatar sx={{ width: "40px", height: "40px" }}>OP</Avatar>}
            title={
              ListingsAll.isLoading ? (
                <Skeleton width={60} variant="text" />
              ) : (
                ListingsAll.data.this_week_closing || "0"
              )
            }
            subheader="Closing This week"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.ListingCardHeader,
              subheader: classes.ListingCardDetail,
            }}
            avatar={<Avatar sx={{ width: "40px", height: "40px" }}>OP</Avatar>}
            title="0"
            subheader="Closed This Month"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.ListingCardHeader,
              subheader: classes.ListingCardDetail,
            }}
            avatar={<Avatar sx={{ width: "40px", height: "40px" }}>OP</Avatar>}
            // title="$21.3m"
            title="0"
            subheader="Volume Closed"
          />
        </Card>
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "ListingsStatsStyle" })(
  ListingsStats
);
