import { Card, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react';

export default function LockBox({ lockboxData, classes, handleSelect }) {
  return (
    <Card className={classes.lockBoxCard} onClick={() => handleSelect && handleSelect(lockboxData)}>
      <Box>
        <img className={classes.lockBoxIcon} src={lockboxData.Icon} alt='lockbox-icon' />
      </Box>

      <Box>
        <Typography className={classes.lockBoxTitle}>{ lockboxData.SerialNumber }</Typography>
        <Typography className={classes.lockBoxSubtitle}>CBS Code { lockboxData.CBScode || '---' }</Typography>
      </Box>
    </Card>
  )
}
