import { createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "./adminLogin";
import { getAdminDetail } from "./getAdminDetail";
import { changePassword } from "./changePassword";

const initialState = {
  isAuthenticated: false,
  adminDetail: undefined,
  isLoading: true,
  errMsg: null,
  editPassword: {
    isLoading: false,
    errMsg: null,
    data: {},
  },
};
const adminAuth = createSlice({
  name: "login",
  initialState,
  reducers: {
    logoutSuccess: (state, action) => {
      state.isAuthenticated = false;
      state.adminDetail = {};
      state.isLoading = false;
      state.errMsg = null;
    },
  },
  extraReducers: {
    [adminLogin.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
    },
    [adminLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.adminDetail = action.payload;
    },
    [adminLogin.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.errMsg = action.payload;
    },
    [getAdminDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [getAdminDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.adminDetail = action.payload;
    },
    [getAdminDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.errMsg = action.payload;
    },
    // update password
    [changePassword.pending]: (state) => {
      state.editPassword.isLoading = true;
      state.editPassword.errMsg = null;
      state.editPassword.data = {};
    },
    [changePassword.fulfilled]: (state, action) => {
      state.editPassword.isLoading = false;
      state.editPassword.errMsg = null;
      state.editPassword.data = action.payload;
    },
    [changePassword.rejected]: (state, action) => {
      state.editPassword.isLoading = false;
      state.editPassword.isAuthenticated = false;
      state.editPassword.errMsg = action.payload;
    },
  },
});

export const { logoutSuccess } = adminAuth.actions;

export default adminAuth.reducer;

export * from "./adminLogin";
export * from "./getAdminDetail";
export * from "./logout";
