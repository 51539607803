import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Paper,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  CircularProgress,
  Backdrop,
  Container,
} from "@mui/material";
import Avatar from "../../../../components/uiElements/avatar/index";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import {
  BackgroundCard,
  ContactDetails,
  SocialCard,
  TagsCard,
  NameCard,
  Details,
  TasksCard,
  AppointmentsCard,
  FilesCard,
  CollaboratorsCard,
} from "./cards/cards.jsx";
import { LeadTabs } from "../../common/Tabs";
import LeadTimeline from "../../common/Timeline";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Pagination from "../../common/Pagination";
import { EditPhoneModal } from "../../common/Modal/editPhoneModal";
import { EditEmailModal } from "../../common/Modal/editEmailModal";
import { EditAddressModal } from "../../common/Modal/editAddressModal";
import moment from "moment";
import { Box } from "@mui/system";
import { getLeadByIdAPI } from "../../../../redux/leads/getLeadByIdAPI";
import { getLeadSources } from "../../../../redux/leads/getSources.js";
import { getLeadTagsAPI } from "../../../../redux/leads/getLeadTagsAPI";
import { updateLeadProfileAPI } from "../../../../redux/leads/updateLeadProfileAPI";
import { deleteFileAPI } from "../../../../redux/leads/deleteFile";
import { updateLeadTaskAPI } from "../../../../redux/leads/updateLeadTaskAPI";
import { handleResetLimit } from "../../../../redux/leads/leadTimeline";
import { getLeadStageListAPI } from "../../../../redux/leads/getLeadStageListAPI";
import { stringAvatar } from "../../../../utils/avatarInitial";
import IssuesDetailModal from "../../../agentRoster/agentDetails/modals/issueDetail";
import AddCollaboratorModal from "./modals/addCollaborator";
import AgentDetailLoader from "../../../tools/agentRecruiting/agentRecruitsDetailsView/skeltons/mainLoader";
import ActivitiesDetail from "./activitiesDetail";
import ResponseAlert from "../../../../components/responseAlert";
import { getLeadsIdsAfterFilter } from "../../../../redux/leads/getLeadList";
import SavedSearches from "../../savedSearches";
import { getAllPonds } from "../../../../redux/ponds";
import AssignedTo from "./modals/assignedTo.jsx";
const PeopleDetailView = ({ classes, allData }) => {
  const navigate = useNavigate();
  const [openCollaborateModal, setOpenCollaborateModal] = useState(false);
  const param = useParams();
  const dispatch = useDispatch();
  const [routeId, setRouteId] = useState(param.id);
  const [primaryPhone, setPrimaryPhone] = useState(null);
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [open, setOpen] = useState(false);
  const [editAddressModal, setEditAddressModal] = useState(false);
  const [editPhoneModal, setEditPhoneModal] = useState(false);
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [currentStage, setCurrentStage] = useState("");
  const [currentSource, setCurrentSource] = useState("");
  const [currentAssigne, setCurrentAssigne] = useState("");
  const [currentLender, setCurrentLender] = useState("initial");
  const [currentPond, setCurrentPond] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [openAssignedTo, setOpenAssignedTo] = useState(false);
  const leadTags = useSelector((item) => item.leadTags);
  const usersList = useSelector((item) => item.users.storedListOfUsers.list);
  const pondsList = useSelector((item) => item.Ponds.pondsList);
  const leadIdsLoading = useSelector((item) => item.leadList.leadIdsLoading);
  const allFilteredLeadsIds = useSelector(
    (item) => item.leadList.allFilteredLeadsIds
  );
  const smartList = useSelector((item) => item.leadList.smartList);
  const adminAuth = useSelector((state) => state.adminAuth);
  const [searchParams, setSearchParams] = useSearchParams();
  const leadStageList = useSelector((item) => item.leadStageList);
  const leadProfile = useSelector((item) => item.leadProfile);
  const fetch = async () => {
    let payload = { id: param?.id };
    if (searchParams.get("src")) {
      payload["params"] = { src: searchParams.get("src") };
    }
    const data = await dispatch(getLeadByIdAPI(payload));
    if (!data?.payload) {
      navigate(`/leads/people`);
    } else {
      setRouteId(data.payload.id);
    }
  };

  useEffect(() => {
    fetch();
  }, [param.id]);

  useEffect(() => {
    if (leadTags?.data.length <= 0) {
      dispatch(getLeadTagsAPI(param.id));
    }
  }, []);

  useEffect(() => {
    if (leadProfile?.sources?.data.length <= 0) {
      dispatch(getLeadSources());
    }
  }, []);

  const setStages = async () => {
    await dispatch(getLeadStageListAPI());
  };
  useEffect(() => {
    setStages();
    dispatch(getAllPonds({ pageSize: 1000 }));
  }, []);

  useEffect(() => {
    if (leadProfile?.list?.data?.phones) {
      setPrimaryPhone(leadProfile?.list?.data?.phones || []);
    }
    if (leadProfile?.list?.data?.emails) {
      setPrimaryEmail(leadProfile?.list?.data?.emails || []);
    }

    if (leadProfile?.list?.data?.addresses) {
      let newAddresses = [];

      if (leadProfile?.list?.data?.addresses?.length) {
        leadProfile?.list?.data?.addresses?.map((item) => {
          if (item.value) {
            newAddresses.push(item);
          } else {
            let obj = {
              ...item,
              value: `${item.street}, ${item.state}, ${item.city}`,
            };
            newAddresses.push(obj);
          }
        });
      }
      setAddresses(newAddresses);
    }
    if (leadProfile?.list?.data?.stage) {
      setCurrentStage(leadProfile?.list?.data?.stage);
    }
    if (
      leadProfile?.list?.data?.source &&
      leadProfile?.list?.data?.source !== currentSource
    ) {
      setCurrentSource(leadProfile?.list?.data?.source);
    }
    if (leadProfile?.list?.data?.assignedUserId) {
      setCurrentAssigne(leadProfile?.list?.data?.assignedUserId);
    } else {
      setCurrentAssigne("");
    }
    if (leadProfile?.list?.data?.assignedPondId) {
      setCurrentPond(leadProfile?.list?.data?.assignedPondId);
    } else {
      setCurrentPond("");
    }
    if (leadProfile?.list?.data?.assignedLenderId) {
      setCurrentLender(leadProfile?.list?.data?.assignedLenderId);
    } else {
      setCurrentLender("initial");
    }
  }, [leadProfile]);

  useEffect(() => {
    if (!leadIdsLoading) {
      let selectedList = smartList.find((ls) => ls.id == param.id);
      let currentIndex = allFilteredLeadsIds.findIndex((r) => r == routeId) + 1;
      if (!currentIndex) {
        dispatch(
          getLeadsIdsAfterFilter({
            filters: selectedList?.condition,
            sortBy: selectedList?.sortBy,
            sortOrder: selectedList?.sortOrder,
            pageSize: 25,
            pageNumber: 1,
            resetList: true,
          })
        );
      }
    }
  }, []);

  const closeModal = () => {
    setEditPhoneModal(false);
    setEditEmailModal(false);
    setEditAddressModal(false);
  };

  const removeTags = (tags) => {
    const schema = {
      schema: {
        tags: tags,
      },
      leadId: routeId,
      handleSuccess,
      handleError,
    };
    dispatch(updateLeadProfileAPI(schema));
  };

  const markTaskAsComplete = async (id, isCompleted) => {
    const schema = {
      schema: {
        isCompleted: isCompleted ? false : true,
      },
      taskId: id,
      statusUpdate: true,
    };
    const fetch = await dispatch(updateLeadTaskAPI(schema));
    if (fetch?.payload) {
      setErrorAlert({
        errorMsg: "You have successfully completed task ",
        errorType: "success",
        isOpen: true,
      });
    }
  };

  const findStageId = (name) => {
    const st = leadStageList?.data.filter((el) => el.name === name && el.id);
    return st[0]?.id;
  };
  const findStageName = (id) => {
    const st = leadStageList?.data.filter((el) => el.id === id && el.name);
    return st[0]?.name;
  };

  const findUserName = (id) => {
    const st = usersList?.filter((el) => el.id == id);
    return st[0]?.full_name;
  };

  const getProfileImg = (id) => {
    const st = usersList?.filter((el) => el.id == id);
    return st[0]?.profile_images?.profile_img || "";
  };

  const updateStageFnc = (e) => {
    const val = e.target.value;
    const id = findStageId(val);
    const name = findStageName(id);
    setCurrentStage(name);

    const schema = {
      leadId: routeId,
      schema: {
        stage: name,
      },
      handleSuccess,
      handleError,
    };
    dispatch(updateLeadProfileAPI({ ...schema }));
  };

  const updateSourceFnc = (e) => {
    const val = e.target.value;
    setCurrentSource(val);

    const schema = {
      leadId: routeId,
      schema: {
        source: val,
      },
      handleSuccess,
      handleError,
    };
    dispatch(updateLeadProfileAPI({ ...schema }));
  };

  const navigateRecords = (id) => {
    navigate(`/leads/people/details/${id}`);
    dispatch(handleResetLimit({}));
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleSuccessFile = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the file",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const updateLenderFnc = async (e) => {
    setCurrentLender(e.target.value);
    const schema = {
      schema: {
        assignedLenderName:
          usersList.find((item) => item.id === e.target.value)?.full_name ||
          null,
        assignedLenderId: e.target.value || null,
      },
      leadId: routeId,
      handleSuccess,
      handleError,
    };
    dispatch(updateLeadProfileAPI({ ...schema }));
  };
  const handleDeleteFile = (fileId) => {
    const schema = {
      fileId,
      handleSuccessFile,
      handleError,
    };
    dispatch(deleteFileAPI({ ...schema }));
  };

  const handleSaveBackground = (content) => {
    const schema = {
      schema: {
        background: content,
      },
      leadId: routeId,
      handleSuccess,
      handleError,
    };
    dispatch(updateLeadProfileAPI(schema));
  };

  const handleDeleteCollaborate = (id) => {
    let userIds = [...leadProfile?.list?.data?.collaborators].map(
      (item) => item.id
    );
    let filteredUsers = userIds.filter((value) => value !== id);
    dispatch(
      updateLeadProfileAPI({
        schema: { collaborators: filteredUsers },
        leadId: param.id,
        handleSuccess,
        handleError,
      })
    );
  };

  const handleSelectPond = (pond) => {
    setOpenAssignedTo(false);
    setCurrentPond(pond.id);
    setCurrentAssigne("");
    const schema = {
      schema: {
        assignedPondId: pond.id,
      },
      leadId: routeId,
      handleSuccess,
      handleError,
    };
    dispatch(updateLeadProfileAPI({ ...schema }));
  };
  const handleSelectUser = (user) => {
    setOpenAssignedTo(false);
    setCurrentPond("");
    setCurrentAssigne(user.id);
    const schema = {
      schema: {
        assignedTo: usersList.find((item) => item.id === user.id)?.full_name,
        assignedUserId: user.id,
      },
      leadId: routeId,
      handleSuccess,
      handleError,
    };
    dispatch(updateLeadProfileAPI({ ...schema }));
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box className={classes.pageRoot}>
        <Container sx={{ maxWidth: "2496px !important" }}>
          {leadProfile?.list?.status?.pending ? (
            <AgentDetailLoader isLead disableStats />
          ) : (
            <>
              <Grid
                container
                spacing={2}
                flexWrap={"nowrap"}
                justifyContent={"center"}
              >
                <Grid
                  item
                  xs={3}
                  lg={3}
                  sx={{ minWidth: "300px" /*  maxWidth: "625px !important" */ }}
                >
                  <Stack spacing={1}>
                    <NameCard
                      selectedUser={leadProfile?.list?.data}
                      market={leadProfile?.list?.data?.source || "null"}
                      setErrorAlert={setErrorAlert}
                    />
                    <ContactDetails
                      list={[
                        {
                          type: "phone",
                          onClick: () => setEditPhoneModal(true),
                          value: primaryPhone,
                        },
                        {
                          type: "email",
                          onClick: () => setEditEmailModal(true),
                          value: primaryEmail,
                        },
                        {
                          type: "address",
                          onClick: () => setEditAddressModal(true),
                          value: addresses,
                        },
                      ]}
                      name={leadProfile?.list?.data?.name}
                      lead_id={leadProfile?.list?.data?.id}
                    />
                    <Details
                      title="Details"
                      list={[
                        {
                          key: "Stage",
                          value: leadProfile?.list?.data?.stage ? (
                            <Box>
                              <FormControl>
                                <Select
                                  value={currentStage}
                                  label="stage"
                                  onChange={updateStageFnc}
                                  inputProps={{
                                    readOnly:
                                      adminAuth?.adminDetail?.role?.name ===
                                        "Admin" ||
                                      adminAuth?.adminDetail?.role?.name ===
                                        "Team Leader"
                                        ? false
                                        : true,
                                  }}
                                  sx={{
                                    background: "#212121",
                                    padding: "12px 2px !important",
                                    position: "relative",
                                    borderRadius: "15px",
                                    height: "20px",
                                    color: "white",
                                    fontSize: "14px",
                                    "& .MuiSelect-select": {
                                      padding: "3px 6px 2px 6px!important",
                                      textAlign: "center !important",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      display: "none",
                                    },
                                  }}
                                >
                                  {leadStageList?.data &&
                                  leadStageList?.data.length ? (
                                    leadStageList?.data.map((ii) => (
                                      <MenuItem
                                        sx={{ textAlign: "center" }}
                                        value={ii.name}
                                      >
                                        {ii.name}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="" disabled>
                                      No items
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                          ) : (
                            ""
                          ),
                        },
                        {
                          key: "Source ",
                          value: leadProfile?.list?.data?.source ? (
                            <Box>
                              <FormControl>
                                <Select
                                  value={currentSource}
                                  label="stage"
                                  onChange={updateSourceFnc}
                                  inputProps={{
                                    readOnly:
                                      adminAuth?.adminDetail?.role?.name ===
                                        "Admin" ||
                                      adminAuth?.adminDetail?.role?.name ===
                                        "Team Leader"
                                        ? false
                                        : true,
                                  }}
                                  sx={{
                                    background: "#212121",
                                    padding: "12px 2px !important",
                                    position: "relative",
                                    borderRadius: "15px",
                                    height: "20px",
                                    color: "white",
                                    fontSize: "14px",
                                    "& .MuiSelect-select": {
                                      padding: "3px 6px 2px 6px!important",
                                      textAlign: "center !important",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      display: "none",
                                    },
                                  }}
                                >
                                  {leadProfile?.sources?.data &&
                                  leadProfile?.sources?.data.length ? (
                                    leadProfile?.sources?.data.map((ii) => (
                                      <MenuItem
                                        sx={{ textAlign: "center" }}
                                        value={ii.name}
                                      >
                                        {ii.name}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="" disabled>
                                      No items
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                          ) : null,
                        },

                        {
                          key: "Assigned to",
                          value:
                            currentAssigne && currentPond ? (
                              <Box
                                onClick={(e) =>
                                  setOpenAssignedTo(e.currentTarget)
                                }
                                sx={{
                                  display: "flex",
                                  gap: 0.5,
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                              >
                                {
                                  pondsList.data?.find(
                                    (it) => it.id === currentPond
                                  )?.name
                                }{" "}
                                ({findUserName(currentAssigne)})
                              </Box>
                            ) : currentAssigne ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 0.5,
                                  alignItems: "center",
                                  fontSize: "14px",
                                  background: "rgba(217, 217, 217, 0.5)",
                                  padding: "5px 9px 5px 6px !important",
                                  borderRadius: "30px !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  setOpenAssignedTo(e.currentTarget)
                                }
                              >
                                <Avatar
                                  sx={{
                                    width: 25,
                                    height: 25,
                                    fontSize: "12px",
                                  }}
                                  {...stringAvatar(
                                    findUserName(currentAssigne),
                                    getProfileImg(currentAssigne) || false
                                  )}
                                />

                                {findUserName(currentAssigne)}
                              </Box>
                            ) : currentPond ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 0.5,
                                  alignItems: "center",
                                  fontSize: "14px",
                                  background: "rgba(217, 217, 217, 0.5)",
                                  padding: "5px 9px 5px 6px !important",
                                  borderRadius: "30px !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  setOpenAssignedTo(e.currentTarget)
                                }
                              >
                                {
                                  pondsList.data?.find(
                                    (it) => it.id === currentPond
                                  )?.name
                                }
                              </Box>
                            ) : (
                              "Select"
                            ),
                        },
                        {
                          key: "Lender",
                          value: (
                            <Box>
                              <FormControl>
                                <Select
                                  value={currentLender}
                                  label="Lender"
                                  onChange={updateLenderFnc}
                                  inputProps={{
                                    readOnly:
                                      adminAuth?.adminDetail?.role?.name ===
                                        "Admin" ||
                                      adminAuth?.adminDetail?.role?.name ===
                                        "Team Leader"
                                        ? false
                                        : true,
                                  }}
                                  sx={{
                                    borderRadius: "7px",
                                    position: "relative",
                                    "& .MuiSelect-select": {
                                      background: "rgba(217, 217, 217, 0.5)",
                                      padding: "5px 9px 5px 6px !important",
                                      borderRadius: "30px !important",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                    "& .MuiSelect-iconOutlined": {
                                      display: "none",
                                    },
                                  }}
                                  input={<OutlinedInput />}
                                  renderValue={(tt) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 0.5,
                                        alignItems: "center",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Avatar
                                        sx={{
                                          width: 25,
                                          height: 25,
                                          fontSize: "12px",
                                        }}
                                        {...stringAvatar(
                                          findUserName(currentLender),
                                          getProfileImg(currentLender) || false
                                        )}
                                      />

                                      {findUserName(currentLender) ||
                                        "Unassigned"}
                                    </Box>
                                  )}
                                >
                                  <MenuItem value="initial" disabled>
                                    Select Lender
                                  </MenuItem>
                                  <MenuItem
                                    value={null}
                                    disabled={
                                      currentLender === "initial" ||
                                      !currentLender
                                        ? true
                                        : false
                                    }
                                  >
                                    No Lender
                                  </MenuItem>
                                  {usersList && usersList.length ? (
                                    usersList
                                      .filter((u) => {
                                        return u.role.name === "Lender";
                                      })
                                      .map((ii) => (
                                        <MenuItem
                                          value={ii.id}
                                          sx={{ display: "flex", gap: "5px" }}
                                        >
                                          <Avatar
                                            sx={{
                                              width: 30,
                                              height: 30,
                                              fontSize: "13px",
                                            }}
                                            {...stringAvatar(
                                              ii?.full_name,
                                              ii?.profile_images?.profile_img
                                            )}
                                          />
                                          {ii.full_name}
                                        </MenuItem>
                                      ))
                                  ) : (
                                    <MenuItem value="" disabled>
                                      Not available
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                          ),
                        },
                        {
                          key: "Created on ",
                          value: leadProfile?.list?.data?.created
                            ? moment(leadProfile?.list?.data?.created).format(
                                "MM/DD/YYYY"
                              ) +
                              " " +
                              moment(leadProfile?.list?.data?.created).format(
                                "LT"
                              )
                            : "Never",
                        },
                        {
                          key: "Last modified",
                          value: leadProfile?.list?.data?.updated
                            ? moment(leadProfile?.list?.data?.updated).format(
                                "MM/DD/YYYY"
                              ) +
                              " " +
                              moment(leadProfile?.list?.data?.updated).format(
                                "LT"
                              )
                            : "Never",
                        },
                      ]}
                    />
                    <TagsCard
                      loading={leadTags.status.pending}
                      tagsList={leadTags?.data}
                      id={routeId}
                      prevTags={leadProfile?.list?.data?.lead_tags || []}
                      removeTags={removeTags}
                    />
                    <BackgroundCard
                      text={leadProfile?.list?.data?.background}
                      handleSaveBackground={handleSaveBackground}
                    />
                    <SocialCard
                      socialData={leadProfile?.list?.data?.socialData}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  lg={6} /* sx={{ maxWidth: "1245px !important" }} */
                >
                  <Stack spacing={1}>
                    <Paper
                      variant="outlined"
                      sx={{ backgroundColor: "white", borderRadius: "10px" }}
                    >
                      <LeadTabs leadProfile={leadProfile?.list?.data} />
                    </Paper>
                    {adminAuth?.adminDetail?.role?.name !== "Agents" && (
                      <Stack
                        sx={{ mt: "16px", mb: "16px" }}
                        direction="row"
                        justifyContent="space-between"
                        flexWrap={"wrap"}
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                            disabled={
                              usersList?.find(
                                (item) =>
                                  item.id == leadProfile?.list?.data?.assignedTo
                              )
                                ? false
                                : true
                            }
                            onClick={() => setOpen(true)}
                            className={classes.trainingIssueButton}
                            variant="outlined"
                          >
                            TRAINING ISSUE
                          </Button>
                          <Button
                            className={classes.isaWaitButton}
                            variant="contained"
                          >
                            ISA WAIT
                          </Button>
                        </Stack>
                        <Button
                          color="success"
                          className={classes.RecordConversationButton}
                          variant="outlined"
                        >
                          Record Conversion
                        </Button>
                      </Stack>
                    )}
                    <Paper
                      variant="outlined"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <LeadTimeline
                        leadProfileDetail={leadProfile?.list?.data}
                        usersList={usersList}
                      />
                    </Paper>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                  lg={3}
                  sx={{ minWidth: "300px" /* maxWidth: "625px !important" */ }}
                >
                  <Pagination
                    prev={() => {
                      let prevIndex =
                        allFilteredLeadsIds.findIndex((r) => r == routeId) - 1;
                      navigateRecords(allFilteredLeadsIds[prevIndex]);
                    }}
                    next={() => {
                      let nextIndex =
                        allFilteredLeadsIds.findIndex((r) => r == routeId) + 1;
                      navigateRecords(allFilteredLeadsIds[nextIndex]);
                    }}
                    currentIndex={
                      allFilteredLeadsIds.findIndex((r) => r == routeId) + 1
                    }
                    data={allFilteredLeadsIds.length}
                  />
                  <ActivitiesDetail
                    personId={param.id}
                    selectedUser={leadProfile?.list?.data}
                  />
                  <Stack spacing={1}>
                    <FilesCard
                      id={routeId}
                      handleDeleteFile={handleDeleteFile}
                      classes={classes}
                      setErrorAlert={setErrorAlert}
                    />
                    <CollaboratorsCard
                      collaborators={leadProfile?.list?.data?.collaborators?.filter(
                        (item) =>
                          item.id !== leadProfile?.list?.data?.assignedUserId &&
                          item.id !== leadProfile?.list?.data?.assignedLenderId
                      )}
                      classes={classes}
                      setOpenCollaborateModal={setOpenCollaborateModal}
                      handleDeleteCollaborate={handleDeleteCollaborate}
                      usersList={usersList}
                    />
                    <TasksCard
                      usersList={usersList}
                      id={routeId}
                      markTaskAsComplete={markTaskAsComplete}
                    />
                    <AppointmentsCard
                      usersList={usersList}
                      id={routeId}
                      adminDetail={adminAuth?.adminDetail}
                    />
                    <Paper
                      variant="outlined"
                      sx={{
                        backgroundColor: "white",
                        mt: "10px !important",
                        borderRadius: "10px",
                      }}
                    >
                      <SavedSearches
                        disableIframe
                        userDetail={leadProfile?.list?.data}
                      />
                    </Paper>
                  </Stack>
                </Grid>
              </Grid>
              <EditAddressModal
                closeModal={closeModal}
                isLoading={leadProfile?.list?.updateProfile?.isLoading}
                state={editAddressModal}
              />
              <EditPhoneModal
                isLoading={leadProfile?.list?.updateProfile?.isLoading}
                closeModal={closeModal}
                open={editPhoneModal}
              />
              <EditEmailModal
                isLoading={leadProfile?.list?.updateProfile?.isLoading}
                closeModal={closeModal}
                open={editEmailModal}
              />
            </>
          )}
        </Container>
      </Box>
      {usersList?.find(
        (item) => item.id == leadProfile?.list?.data?.assignedTo
      ) && (
        <IssuesDetailModal
          open={open}
          setOpen={setOpen}
          issueType={"add"}
          setAppointments={() => {}}
          userProfile={usersList?.find(
            (item) => item.id == leadProfile?.list?.data?.assignedTo
          )}
        />
      )}
      <AddCollaboratorModal
        open={openCollaborateModal}
        setOpen={setOpenCollaborateModal}
        id={param.id}
        isLoading={leadProfile?.list?.updateProfile?.isLoading}
        collaborators={leadProfile?.list?.data?.collaborators?.filter(
          (item) =>
            item.id !== leadProfile?.list?.data?.assignedUserId &&
            item.id !== leadProfile?.list?.data?.assignedLenderId
        )}
        assignedUserId={leadProfile?.list?.data?.assignedUserId}
        assignedLenderId={leadProfile?.list?.data?.assignedLenderId}
      />
      <Backdrop
        color="inherit"
        invisible={true}
        open={
          leadProfile?.list?.updateProfile?.isLoading ||
          leadProfile?.lead_tasks?.update?.pending
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <AssignedTo
        openAssignedTo={openAssignedTo}
        setOpenAssignedTo={setOpenAssignedTo}
        pondsList={pondsList.data}
        usersList={usersList}
        handleSelectPond={handleSelectPond}
        handleSelectUser={handleSelectUser}
      />
    </Box>
  );
};

export default withStyles(styleSheet, { name: "peopleDetailViewStyle" })(
  PeopleDetailView
);
