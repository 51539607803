import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const addAgentRecruitmentTasks = createAsyncThunk("agentRecruitment/addAgentRecruitmentTasks", async (data, thunkAPI) => {
  let { handleSuccess, handleError, schema } = data;
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/recruitment/tasks`, schema, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (handleSuccess) {
      handleSuccess();
    }
    return resp.data;
  } catch (error) {
    if (error.response) {
      if (handleError) {
        handleError(error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      if (handleError) {
        handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const markTaskAsCompleteAgentRecruitment = createAsyncThunk("login/markTaskAsCompleteAgentRecruitment", async (data, thunkAPI) => {
  try {
    let { schema, id, statusUpdate } = data;

    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/recruitment/tasks/${id}`, schema, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { ...resp.data, id, statusUpdate: statusUpdate, isCompleted: schema.isCompleted };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
