import React, { useState } from "react";
import { Button, Card, Typography } from "@mui/material";
import AttachDialog from "./AttachDialog";
import { Box, Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { attachLockBox } from "../../../../../redux/listings/listingDetail/attachLockbox";
import { getAllLockboxes } from "../../../../../redux/listings/listingDetail/getAllLockboxes";
import { updateListingInventoryDetail } from "../../../../../redux/listings/listingDetail";
import { makeStyles } from "@mui/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import InventoryItemDetailModal from "../../../../agentRoster/agentDetails/modals/inventoryItemDetailModal.js";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "34px!important",
    fontFamily: "AvenirNext!important",
  },
  subtitle: {
    fontSize: "16px!important",
  },
  cardsContainer: {
    width: "640px",
    height: "400px",
    overflow: "hidden",
    overflowY: "scroll!important",
    padding: "10px 5px 10px 15px",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  lockBoxCard: {
    display: "flex",
    gap: "20px",
    padding: "10px",
    cursor: "pointer",
  },
  lockBoxIcon: {
    width: "35px !important",
    height: "65px !important",
  },
  lockBoxTitle2: {
    fontFamily: "AvenirNext!important",
    fontStyle: "normal!important",
    fontWeight: "500!important",
    fontSize: "18px!important",
    lineHeight: "160%",
    color: "rgba(0, 0, 0, 0.87)",
  },
  lockboxButton: {
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    color: "#0B0909 !important",
    border: "1px solid rgba(33, 33, 33, 0.4) !important",
    marginLeft: "8px !important",
  },
  lockboxTitle: {
    fontFamily: "AvenirNext !important",
    fontWeight: "400 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "22px !important",
    lineHeight: "20px !important",
  },
  lockBoxTitle: {
    fontFamily: "AvenirNext!important",
    fontStyle: "normal!important",
    fontWeight: "500!important",
    fontSize: "25px!important",
    lineHeight: "160%",
    color: "rgba(0, 0, 0, 0.87)",
  },
  lockBoxSubtitle: {
    fontStyle: "normal",
    fontWeight: "400!important",
    fontSize: "16px!important",
    lineHeight: "143%!important",
    letterSpacing: "0.17px!important",
    color: "rgba(0, 0, 0, 0.6)",
  },
  clearButton: {
    color: "#000",
    padding: "0px",
  },
  lockBoxDetailBox: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    padding: "13px",
    border: "1px solid rgba(33, 33, 33, 0.4)",
    borderRadius: "4px",
    margin: "20px 10px 16px 10px",
    cursor: "pointer",
  },
}));

function LockBoxes(props) {
  const { ListingData } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openLockBoxDetail, setOpenLockBoxDetail] = useState(false);
  const [lockDetail, setLockDetail] = useState({});
  const lockBoxData = ListingData?.inventory_occupied_v2?.inventory;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = async (data) => {
    dispatch(updateListingInventoryDetail({ inventory: data }));
    setOpen(false);
    let response = await dispatch(attachLockBox({ InventoryId: data.id, ListingId: ListingData.id, AgentId: ListingData.agent_id }));
    if (response.payload?.data) {
      setLockDetail({ ...lockDetail, ...response.payload?.data });
      dispatch(updateListingInventoryDetail({ inventory_occupied_v2: response.payload?.data }));
    }
  };

  const updateSupraWeb = (flag) => {
    dispatch(updateListingInventoryDetail({ inventory_occupied_v2: { IsSupraWeb: flag } }));
  };
  const handleUpdateLockBoxData = (data) => {
    dispatch(updateListingInventoryDetail({ inventory_occupied_v2: "detach" }));
    setOpenLockBoxDetail(false);
  };
  React.useEffect(() => {
    dispatch(getAllLockboxes({ pageNumber: 1, pageSize: 15 }));
  }, []);
  const handleOpenLockBoxDetail = () => {
    let obj = {
      InventoryId: lockBoxData.id || ListingData?.inventory_occupied_v2?.inventory?.id || ListingData?.inventory_occupied_v2?.InventoryId,
      AssetStatus: lockBoxData.AssetStatus || ListingData?.inventory_occupied_v2?.inventory?.AssetStatus,
      ListingId: ListingData?.id,
      id: ListingData?.inventory_occupied_v2?.id,
      IsSupraWeb: ListingData?.inventory_occupied_v2?.IsSupraWeb,
      inventory: lockBoxData || ListingData?.inventory_occupied_v2?.inventory,
      transaction_listings_v2: ListingData,
      users_v2: ListingData?.agent,
      defaultData: lockBoxData,
    };
    setLockDetail(obj);
    setOpenLockBoxDetail(true);
  };
  return (
    <React.Fragment>
      <Card sx={{ padding: "15px" }}>
        <Typography className={classes.lockboxTitle}>Lockbox</Typography>
        {lockBoxData && (
          <Box onClick={() => handleOpenLockBoxDetail()} className={classes.lockBoxDetailBox}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                width="40px"
                height="80px"
                src="https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/supra-ekey+isolated+1.png"
                alt="lock-icon"
              />
            </Box>

            <Box>
              <Typography className={classes.lockBoxTitle2} sx={{ marginTop: "2px" }}>
                Serial No: <span style={{ fontWeight: "600" }}>{lockBoxData.SerialNumber}</span>
              </Typography>
              <Typography className={classes.lockBoxTitle2}>
                CBS Code: <span style={{ fontWeight: "600" }}>{lockBoxData.CBScode}</span>
              </Typography>
              <Typography className={classes.lockBoxTitle2}>
                Shackle Code: <span style={{ fontWeight: "600" }}>{lockBoxData.shackleCode}</span>
              </Typography>
            </Box>
          </Box>
        )}
        {lockBoxData ? (
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ mr: "10px", mb: "4px" }}>
              <Typography>Assigned in SupraWeb</Typography>
              {ListingData?.inventory_occupied_v2?.IsSupraWeb ? (
                <CheckCircleRoundedIcon sx={{ color: "#4CAF50" }} />
              ) : (
                <CancelRoundedIcon sx={{ color: "rgba(210, 0, 0, 0.76)" }} />
              )}
            </Stack>
          </Stack>
        ) : null}

        {!lockBoxData && (
          <Box sx={{ padding: "30px 10px" }}>
            <AttachDialog classes={classes} open={open} handleClose={handleClose} handleSelect={handleSelect} />

            <Box sx={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}>
              <img
                width="35px"
                height="65px"
                src="https://method-platform-sandbox.s3.amazonaws.com/inventory/icons/supra-ekey+isolated+1.png"
                alt="lock-icon"
              />
              <Button variant="outlined" className={classes.lockboxButton} onClick={handleClickOpen}>
                Attach Lockbox
              </Button>
            </Box>
          </Box>
        )}
      </Card>
      {lockBoxData && openLockBoxDetail ? (
        <InventoryItemDetailModal
          setLockBoxDetail={setLockDetail}
          tabDetail={lockDetail}
          open={openLockBoxDetail}
          setOpen={() => {
            setOpenLockBoxDetail(false);
          }}
          lockBoxDetail={lockDetail}
          updateSupraWeb={updateSupraWeb}
          setLockBoxData={handleUpdateLockBoxData}
          isListing
        />
      ) : null}
    </React.Fragment>
  );
}

export default LockBoxes;
