import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const deleteSearch = createAsyncThunk(
  "LeadSavedSearches/deleteSearch",
  async (data, thunkAPI) => {
    try {
      let site_id =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.site_id?._id;
      let searchId =
        thunkAPI.getState()?.leads?.savedSearches.searchDetail.searchId;
      let token =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.token;
      let url = `${process.env.REACT_APP_IDX_BASE_URL}api/v1/save-search/${site_id}/${searchId}`;
      const resp = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (data?.handleSuccess && resp.data) {
        data?.handleSuccess();
      }
      return { ...resp.data, searchId: searchId };
    } catch (error) {
      if (error.response) {
        if (data?.handleError) {
          data.handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (data?.handleError) {
          data.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
