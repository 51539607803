import React, { useState } from "react";
import { Grid, Dialog, DialogContent, Alert, Typography, Paper, ButtonBase, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import NoImage from "../../../assets/images/noimage.png";
import { styleSheet } from "./style.js";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { createMultipleComparable } from "../../../redux/tools/cmas.js";
import { LoadingButton } from "@mui/lab";
import SearchMLS from "./SearchMLS.js";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { Cancel } from "@mui/icons-material";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData.js";
const Img = styled("img")({
  width: "100%",
  height: "100px",
  objectFit: "cover",
  borderRadius: "10px",
});

function CreateComparable(props) {
  const dispatch = useDispatch();
  const { classes, open, setOpen, manualCreateCMA, switchValue } = props;
  const { id } = useParams();
  const [is_property_selected, setIs_property_selected] = React.useState({});
  const [selectedComparables, setSelectedComparables] = React.useState([]);
  const [buttonSwitchValue, setButtonSwitchValue] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const multipleComparable = useSelector((state) => state.allCmas.multipleComparable);
  const singleCma = useSelector((state) => state.allCmas.singleCma);
  const agents = useSelector((state) => state.users.storedListOfUsers.list.filter((user) => user.is_agent === true));

  const handleSuccess = (data) => {
    setErrorAlert({
      errorMsg: "You have successfully added the Property for comparing.",
      errorType: "success",
      isOpen: true,
    });
    manualCreateCMA(_.cloneDeep(data));
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleCreateCMAComparable = async () => {
    const ListingIds = selectedComparables.length > 0 ? selectedComparables.map((comparable) => comparable.ListingId) : undefined;
    if (!ListingIds) {
      setErrorMsg("You have to select one property!");
      return;
    }

    let obj = {
      cmaId: id,
      comparables: selectedComparables.map((comp) => ({
        listingId: comp.ListingId,
        ListingType: buttonSwitchValue || switchValue || comp.ListingType,
      })),
      handleSuccess,
      handleError,
    };
    setButtonSwitchValue(null);
    dispatch(createMultipleComparable(obj));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{
          position: "absolute",
          "& .MuiDialog-container": { height: "100vh" },
        }}
        maxWidth={"lg"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent maxWidth="xl" sx={{ padding: "10px 30px" }}>
          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <h2 className={classes.clientBoxH2}>Add Comparable(s)</h2>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.dashboardActivitiesBox2}>
            <Grid item xs={12} lg={6} md={6}>
              <SearchMLS
                open={open}
                classes={classes}
                agent_id={singleCma?.agent_id}
                isCreateCMA={false}
                agents={agents}
                setErrorMsg={setErrorMsg}
                setButtonSwitchValue={setButtonSwitchValue}
                is_property_selected={is_property_selected}
                onClickAction={(item) => {
                  const tempSelectedComparables = [...selectedComparables];
                  tempSelectedComparables.push(item);
                  setSelectedComparables(_.uniq(tempSelectedComparables));

                  const { ListingId } = item;
                  setIs_property_selected({
                    [ListingId]: true,
                  });
                }}
                itemLg={12}
                itemXs={12}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <div className={classes.dashboardAccordion}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="h6">Selected Comparable(s)</Typography>
                  </Grid>

                  {selectedComparables.length === 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        mt: "1rem",
                        height: "100px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "17px", color: "rgba(0, 0, 0, 0.6)" }} component="p" variant="body2" color="text.primary">
                        No Comparables Selected!
                      </Typography>
                    </Box>
                  )}

                  {selectedComparables.map((item, index) => {
                    return (
                      <Grid key={index} item xs={12} lg={12} className={classes.SelectedComparables}>
                        <Paper
                          sx={{
                            p: 2,
                            marginTop: "10px",
                            cursor: "pointer",
                            flexGrow: 1,
                            backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#1A2027" : "#fff"),
                          }}
                        >
                          {!multipleComparable.isLoading && (
                            <Cancel
                              className={classes.SelectedComparablesCancelIcon}
                              onClick={() => {
                                setSelectedComparables((prev) => prev.filter((comparable) => comparable.ListingId !== item.ListingId));
                              }}
                            />
                          )}
                          <Grid container spacing={2}>
                            <Grid item>
                              <ButtonBase sx={{ width: 128 }}>
                                <Img alt="house" src={item.MediaURL ? getImageUrlWithHeightAndWidth(item.MediaURL) : NoImage} />
                              </ButtonBase>
                            </Grid>
                            <Grid item xs={12} sm container>
                              <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                  <Typography gutterBottom variant="subtitle1" component="div">
                                    {item.UnparsedAddress}
                                  </Typography>
                                  <Typography gutterBottom variant="subtitle1" component="div" color="text.secondary">
                                    <b>{_.upperCase(item.ListingType)}:</b> {item.ListingId} <b>Status: </b>
                                    <span className={classes.spanGreen}>
                                      {item.Status === "Active Under Contract" ? "ActiveUC" : item.Status}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle1" component="div">
                                  <h6 className={classes.accordionPrice}>${item.ListPrice / 1000}K</h6>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end" sx={{ paddingTop: "40px !important" }}>
              <Button variant="text" onClick={() => setOpen(false)} className={classes.textButton}>
                Cancel
              </Button>
              <LoadingButton
                disabled={Object.keys(is_property_selected).length === 0}
                loading={multipleComparable.isLoading}
                loadingPosition="start"
                onClick={handleCreateCMAComparable}
                variant="contained"
                className={classes.ContainedButton}
              >
                Add
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "CreateCmaStyle" })(CreateComparable);
