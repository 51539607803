import React from "react";
import { Box, Skeleton } from "@mui/material";
function CategoriesSkelton(props) {
  let { count, width } = props;
  return (
    <Box
      sx={{
        display: "flex !important",
        justifyContent: "flex-start !important",
        flexWrap: "wrap !important",
        gap: "20px !important",
      }}
    >
      {Array.from(Array(count || 2).keys()).map((item, index) => (
        <Box key={index} sx={{ width: width ? width : "292px" }}>
          <Box sx={{ width: "100% !important", height: "170px !important" }}>
            <Skeleton
              animation="wave"
              height={"100%"}
              width="100%"
              variant="rectangular"
            />
          </Box>
          <Skeleton variant="text" sx={{ fontSize: "1.5rem", mt: "4px" }} />
        </Box>
      ))}
    </Box>
  );
}

export default CategoriesSkelton;
