export const styleSheet = () => ({
  TopTransactionsBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopTransactionsBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tagListingArea: {
    "& .MuiButtonBase-root": {
      borderRadius: "4px !important",
    },
  },
  loadingArea: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width: 1270px)": {
    TransactionsTabsUI: {
      maxWidth: "420px",
    },
  },
  "@media (max-width: 1050px)": {
    TransactionsTabsUI: {
      maxWidth: "300px",
    },
  },
  TransactionsTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  listingHeadingArea: {
    fontSize: "30px",
    fontWeight: "400",
    fontFamily: "AvenirNext",
    textAlign: "right",
    marginTop: "10px",
  },
  listingHeadingSub: {
    fontSize: "13px",
    fontWeight: "500",
    textAlign: "right",
    marginTop: "-2px",
  },
  listingStatHeading: {
    fontSize: "30px",
    fontWeight: "500  !important",
    textAlign: "center",
    fontFamily: "AvenirNext  !important",
    lineHeight: "30px",
    marginTop: "16px",
  },
  listingMiddleStat: {
    fontSize: "26px",
    fontWeight: "400",
    textAlign: "center",
    fontFamily: "AvenirNext  !important",
    lineHeight: "30px",
  },
  listingStatDes: {
    fontSize: "15px",
    fontFamily: "AvenirNext  !important",
    fontWeight: "400",
    textAlign: "center",
  },
  listingMiddleStatDes: {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "AvenirNext  !important",
    textAlign: "center",
  },
  listingMiddleStatIconDes: {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "AvenirNext  !important",
    textAlign: "center",
  },
  listingStatPrice: {
    fontSize: "30px",
    fontWeight: "600",
    fontFamily: "AvenirNext  !important",
    textAlign: "center",
  },
  listingStatChip: {
    background: "#2E7D32",
    color: "white  !important",
    fontWeight: "600  !important",
    minWidth: "140px",
  },
  listingDetailMiddleArea: {
    background: "#000000",
    color: "white",
    padding: "10px",
    marginTop: "1px",
  },
  remarksBlock: {
    padding: "20px",
    marginTop: "10px",
  },
  ListedByBox: {
    padding: "20px",
    marginTop: "10px",
    fontSize: "14px",
  },
  statsBoxArea: {
    padding: "10px 20px 20px 20px",
    marginTop: "10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  listFeeAndLivingArea: {
    marginTop: "10px",
    height: "100%",
    padding: "15px",
    fontSize: "14px",
  },
  propertyPicsGalleryArea: {
    marginBottom:'-30px !important"'
  },
  boldText: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    display: "inline-block",
    fontFamily: "AvenirNext  !important",
  },
  defaultText: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    display: "inline-block",
    fontFamily: "AvenirNext  !important",
  },
  blockHeading: {
    fontSize: "20px  !important",
    fontWeight: "600  !important",
    marginBottom: "10px  !important",
    fontFamily: "AvenirNext  !important",
  },
});
