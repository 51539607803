export const stringAvatar = (name, avatar) => {
  if (avatar) {
    return {
      src: `${avatar}`,
    };
  } else if (name) {
    return {
      children: nameFnc(name),
    };
  } else {
    return {
      src: "",
    };
  }
};
const nameFnc = (name) => {
  const nameSplit = name.split(" ");
  const firstLetter = nameSplit.map((ii, index) => (index < 2 ? ii[0] : null));
  return firstLetter.join("").toUpperCase();
};
