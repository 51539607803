/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  Grid,
  Container,
  Typography,
  Box,
  Paper,
  Card,
  Button,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { debounce } from "lodash";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateConfigurationPageData,
  updateConfigurationPages,
  updatePackages,
} from "../../../redux/tools/cmas";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import UploadImage from "./UploadImageModal";

function droppingAnimationStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

  // patching the existing style
  return {
    ...style,
    transform: `${translate}`,
    transition: `all ${curve} ${duration + 1}s`,
  };
}

const useStyles = makeStyles((theme) => ({
  listContainer: {
    padding: "30px",
    height: "800px",
    width: "100%!important",
    display: "flex",
    gap: "40px",
    boxSizing: "",
    justifyContent: "center",
    alignItems: "center"
  },
  column: {
    padding: "20px",
    border: "1px solid #ddd",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
    minHeight: "650px",
    maxHeight: "650px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  columnTitle: {
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    fontSize: "18px !important",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "20px !important",
  },
  cardItem: {
    display: "flex",
    padding: "10px",
    marginBottom: "15px",
    alignItems: "center",
    gap: "20px",
  },
  cardItemIcon: {
    "& .MuiSvgIcon-root": {
      fontSize: "40px",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  cardItemTitle: {
    "& h5": {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "600",
      fontSize: "14px!important",
      fontFamily: "AvenirNext!important",
    },
  },
  cardItemDescription: {
    fontWeight: "400",
    fontSize: "14px!important",
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext!important",
  },
}));

function Configure(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const cmaId = params.id;

  const [openImageUploadModal, setOpenImageUploadModal] = React.useState({});
  const [lists, setLists] = React.useState([[], []]);
  const [changed, setChanged] = React.useState(false);
  const [configureData, setConfigureData] = React.useState({
    base_CMA_package: {
      number_page_property_photo: null,
      price_page_property_photo: null,
    },
    cover_page_with_headshot: {
      cover_page_property_photo: null,
    },
    property_profile_SFR: {
      the_property_page_property_photo: null,
      standout_features: [],
    },
  });

  const {
    availableConfigurePackages,
    currentConfigurePackages,
    configurationData,
  } = useSelector((state) => state.allCmas);

  const getImage = (pack_name) =>
    `https://method-platform-sandbox.s3.amazonaws.com/cma/images/${pack_name}.png`;
  const pageIteratorFunc = (pack) => {
    return {
      ...pack,
      id: pack.page,
      img: getImage(pack.page),
      title: pack.page,
    };
  };

  React.useEffect(() => {
    configurationData.map((config) => {
      if (config.page === "base_CMA_package") {
        configureData["base_CMA_package"] = {
          number_page_property_photo: config.number_page_property_photo,
          price_page_property_photo: config.price_page_property_photo,
        };
      } else if (config.page === "cover_page_with_headshot") {
        configureData["cover_page_with_headshot"] = {
          cover_page_property_photo: config.cover_page_property_photo,
        };
      } else if (config.page === "property_profile_SFR") {
        configureData["property_profile_SFR"] = {
          the_property_page_property_photo:
            config.the_property_page_property_photo,
          standout_features: config.standout_features || [],
        };
      }
    });
    setConfigureData(configureData);
  }, []);

  React.useEffect(() => {
    configurationData.map((config) => {
      if (config.page === "base_CMA_package") {
        configureData["base_CMA_package"] = {
          number_page_property_photo: config.number_page_property_photo,
          price_page_property_photo: config.price_page_property_photo,
        };
      } else if (config.page === "cover_page_with_headshot") {
        configureData["cover_page_with_headshot"] = {
          cover_page_property_photo: config.cover_page_property_photo,
        };
      } else if (config.page === "property_profile_SFR") {
        configureData["property_profile_SFR"] = {
          the_property_page_property_photo:
            config.the_property_page_property_photo,
          
          standout_features: configureData["property_profile_SFR"].standout_features || [],
        };
      }
    });
    setConfigureData(configureData);
  }, [configurationData]);

  React.useEffect(() => {
    lists[0] = [...availableConfigurePackages.map(pageIteratorFunc)];
    lists[1] = [...currentConfigurePackages.map(pageIteratorFunc)];
    setLists(lists);
    setChanged(!changed);
  }, [availableConfigurePackages, currentConfigurePackages]);

  const move = (source, destination, droppableSource, droppableDestination) => {
    if (droppableSource.droppableId === droppableDestination.droppableId) {
      const sourceClone = Array.from(source);
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      sourceClone.splice(droppableDestination.index, 0, removed);
      const result = {};
      result[droppableSource.droppableId] = sourceClone;
      return result;
    } else {
      const sourceClone = Array.from(source);
      const destClone = Array.from(destination);
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      destClone.splice(droppableDestination.index, 0, removed);
      const result = {};
      result[droppableSource.droppableId] = sourceClone;
      result[droppableDestination.droppableId] = destClone;
      return result;
    }
  };

  const debounceUpdate = debounce((list) => {
    dispatch(
      updatePackages({ cmaId, pages: { pages: list.map((nl) => nl.id) } })
    );
  }, 500);

  const onDragEnd = ({ source, destination, draggableId }) => {
    debounceUpdate.cancel();
    if (!destination) {
      return;
    }
    const result = move(
      source.droppableId === "list-1" ? lists[0] : lists[1],
      destination.droppableId === "list-1" ? lists[0] : lists[1],
      source,
      destination
    );
    const newList = [];
    newList.push(result["list-1"] || lists[0]);
    newList.push(result["list-2"] || lists[1]);
    setLists(newList);

    debounceUpdate(newList[1]);
    dispatch(
      updateConfigurationPages({
        availableConfigurePackages: newList[0],
        currentConfigurePackages: newList[1],
      })
    );
  };

  const updateConfigureData = async (page, name, property_img) => {
    await dispatch(
      updateConfigurationPageData({
        cmaId,
        configureData: { page, name, property_img, standout_features: configureData[page].standout_features || [] },
      })
    );
  };

  const debounceUpdateConfiguration = React.useRef(debounce((configureData) => {
    dispatch(
      updateConfigurationPageData({
        cmaId,
        configureData,
      })
    );
  }, 300)).current;

  const handleStandoutChange = (val, index) => {
    if (val.length>34) return;
    debounceUpdateConfiguration.cancel();
    let updatedStandoutFeatures = deepClone(configureData.property_profile_SFR.standout_features || []);
    updatedStandoutFeatures[index] = val;
    setConfigureData((data) => {
      return {
        ...data,
        property_profile_SFR: {
          ...data.property_profile_SFR,
          standout_features: [...updatedStandoutFeatures]
        }
      }
    });
  };

  const saveStandout = () => {
    debounceUpdateConfiguration({ page: 'property_profile_SFR', standout_features: configureData.property_profile_SFR.standout_features })
  }

  const handleCloseImageUploadModal = () => {
    setOpenImageUploadModal({});
  }

  return (
    <Container sx={{ marginTop: "20px", positon: "relative"  }}>
      <UploadImage 
        open={openImageUploadModal?.number_page_property_photo} 
        handleClose={handleCloseImageUploadModal} 
        onChange={async (img, dataUrl) => {
          setConfigureData((prevConfigureData) => {
            prevConfigureData["base_CMA_package"].number_page_property_photo = dataUrl;
            return prevConfigureData;
          });
          await updateConfigureData(
            "base_CMA_package",
            "number_page_property_photo",
            img
          );
          setOpenImageUploadModal({});
        }}
        aspect={408/430}
        aspectWidth={408}
        aspectHeight={430}
      />
      <UploadImage 
        open={openImageUploadModal?.price_page_property_photo} 
        handleClose={handleCloseImageUploadModal} 
        onChange={async (img, dataUrl) => {
          setConfigureData((prevConfigureData) => {
            prevConfigureData["base_CMA_package"].price_page_property_photo = dataUrl;
            return prevConfigureData;
          });
          await updateConfigureData(
            "base_CMA_package",
            "price_page_property_photo",
            img
          );
          setOpenImageUploadModal({});
        }}
        aspect={700/450}
        aspectWidth={700}
        aspectHeight={450}
      />
      <UploadImage 
        open={openImageUploadModal?.cover_page_property_photo} 
        handleClose={handleCloseImageUploadModal} 
        onChange={async (img, dataUrl) => {
          setConfigureData((prevConfigureData) => {
            prevConfigureData["cover_page_with_headshot"].cover_page_property_photo = dataUrl;
            return prevConfigureData;
          });
          await updateConfigureData(
            "cover_page_with_headshot",
            "cover_page_property_photo",
            img
          )
          setOpenImageUploadModal({});
        }}
        aspect={650/550}
        aspectWidth={650}
        aspectHeight={550}
      />
      <UploadImage 
        open={openImageUploadModal?.the_property_page_property_photo} 
        handleClose={handleCloseImageUploadModal} 
        onChange={async (img, dataUrl) => {
          setConfigureData((prevConfigureData) => {
            prevConfigureData["property_profile_SFR"].the_property_page_property_photo = dataUrl;
            return prevConfigureData;
          });
          await updateConfigureData(
            "property_profile_SFR",
            "the_property_page_property_photo",
            img
          )
          setOpenImageUploadModal({});
        }}
        aspect={400/800}
        aspectWidth={400}
        aspectHeight={800}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Card className={classes.listContainer} >
          {lists.map((list, listIndex) => (
            <Box key={listIndex} sx={{ width: "45%" }}>
              <Typography className={classes.columnTitle}>
                {listIndex === 0 ? "Available Pages" : "Included Pages"}
              </Typography>

              <Droppable droppableId={`list-${listIndex + 1}`}>
                {(droppableProvided, droppableSnapshot) => (
                  <Box
                    ref={droppableProvided.innerRef}
                    className={classes.column}
                  >
                    {list.map((li, index) => (
                      <Draggable
                        key={li.id}
                        draggableId={li.id}
                        index={index}
                        isDragDisabled={li.id === "base_CMA_package"}
                      >
                        {(provided, snapshot) => (
                          <Paper
                            elevation={3}
                            className={classes.cardItem}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                            style={droppingAnimationStyle(provided.draggableProps.style, snapshot)}
                          >
                            <div className={classes.cardItemIcon}>
                              <img
                                width="60px"
                                height="75px"
                                alt="page-img"
                                src={li.img}
                              />
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingRight: "10px",
                                alignSelf: "center",
                              }}
                            >
                              <Typography className={classes.cardItemTitle}>
                                {li.title}
                              </Typography>
                              <Typography
                                className={classes.cardItemDescription}
                              >
                                {li.desc}
                              </Typography>
                            </div>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                )}
              </Droppable>
            </Box>
          ))}
        </Card>
      </DragDropContext>
      {currentConfigurePackages.find(
        (pack) => pack.page === "cover_page_with_headshot"
      ) && (
        <Card
          key={"cover_page_with_headshot"}
          sx={{ padding: "20px", marginTop: "20px", width: "100%" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="10px">
              <img alt="cover_page_with_headshot" src={getImage("cover_page_with_headshot")} />
              <Typography fontWeight="500">Cover Page w/Headshot</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              alignItems="center"
            >
              <Typography
                fontSize="14px"
                fontWeight="500"
                color="text.secondary"
              >
                Cover Page Property Photo
              </Typography>
              {configureData["cover_page_with_headshot"]
                .cover_page_property_photo && (
                <img
                  alt="cover_page_property_photo"
                  width={96}
                  height={80}
                  src={
                    configureData["cover_page_with_headshot"]
                      .cover_page_property_photo
                  }
                />
              )}
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  textTransform: "capitalize",
                  border: "1px solid rgba(33, 33, 33, 0.4)",
                }}
                onClick={() => setOpenImageUploadModal({cover_page_property_photo: true})}
                component="label"
              >
                Select File
              </Button>
            </Box>
          </Box>
        </Card>
      )}

      {currentConfigurePackages.find(
        (pack) => pack.page === "property_profile_SFR"
      ) && (
        <Card
          key={"property_profile_SFR"}
          sx={{ padding: "20px", marginTop: "20px" }}
        >
          <Grid container spacing={1} display='flex' alignItems='center'>
            <Grid item xs={3}>
              <Box display="flex" alignItems="center" gap="10px">
                  <img alt="property_profile_SFR" src={getImage("property_profile_SFR")} />
                  <Typography fontWeight="500">Property Profile SFR</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" gap="10px" alignItems="center" flexDirection='column'>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  Property Page Property Photo
                </Typography>
                {configureData["property_profile_SFR"]
                  .the_property_page_property_photo && (
                  <img
                    alt="the_property_page_property_photo"
                    width={40}
                    height={80}
                    src={
                      configureData["property_profile_SFR"]
                        .the_property_page_property_photo
                    }
                  />
                )}
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    textTransform: "capitalize",
                    border: "1px solid rgba(33, 33, 33, 0.4)",
                  }}
                  onClick={() => setOpenImageUploadModal({the_property_page_property_photo: true})}
                  component="label"
                >
                  Select File
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} alignItems="center" display="flex" flexDirection="column" gap="10px">
              <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  Standout Features (Each Feature can have max 34 characters)
                </Typography>
              <Box display="flex" gap="10px" flexWrap="wrap" justifyContent="end" >
                <TextField variant="standard" onBlur={saveStandout} onChange={(e) => handleStandoutChange(e.target.value, 0)} value={configureData.property_profile_SFR.standout_features[0] || ''} placeholder="Standout feature 1" />
                <TextField variant="standard" onBlur={saveStandout} onChange={(e) => handleStandoutChange(e.target.value, 1)} value={configureData.property_profile_SFR.standout_features[1] || ''} placeholder="Standout feature 2" />
                <TextField variant="standard" onBlur={saveStandout} onChange={(e) => handleStandoutChange(e.target.value, 2)} value={configureData.property_profile_SFR.standout_features[2] || ''} placeholder="Standout feature 3" />
                <TextField variant="standard" onBlur={saveStandout} onChange={(e) => handleStandoutChange(e.target.value, 3)} value={configureData.property_profile_SFR.standout_features[3] || ''} placeholder="Standout feature 4" />
                <TextField variant="standard" onBlur={saveStandout} onChange={(e) => handleStandoutChange(e.target.value, 4)} value={configureData.property_profile_SFR.standout_features[4] || ''} placeholder="Standout feature 5" />
                <TextField variant="standard" onBlur={saveStandout} onChange={(e) => handleStandoutChange(e.target.value, 5)} value={configureData.property_profile_SFR.standout_features[5] || ''} placeholder="Standout feature 6" />
              </Box>
            </Grid>
          </Grid>
        </Card>
      )}

      {currentConfigurePackages.find(
        (pack) => pack.page === "base_CMA_package"
      ) && (
        <Card
          key={"base_CMA_package"}
          sx={{ padding: "20px", marginTop: "20px" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="10px">
              <img alt="base_CMA_package" src={getImage("base_CMA_package")} />
              <Typography fontWeight="500">
                Basic CMA Package Options
              </Typography>
            </Box>
            <Box display="flex" gap="20px">
              <Box
                display="flex"
                flexDirection="column"
                gap="10px"
                alignItems="center"
              >
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  Numbers Page Property Photo
                </Typography>
                {configureData["base_CMA_package"]
                  .number_page_property_photo && (
                  <img
                    alt="number_page_property_photo"
                    width={60}
                    height={63.2}
                    src={
                      configureData["base_CMA_package"]
                        .number_page_property_photo
                    }
                  />
                )}
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    textTransform: "capitalize",
                    border: "1px solid rgba(33, 33, 33, 0.4)",
                  }}
                  onClick={() => setOpenImageUploadModal({number_page_property_photo: true})}
                  component="label"
                >
                  Select File
                </Button>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap="10px"
                alignItems="center"
              >
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  Price Page Property Photo
                </Typography>
                {configureData["base_CMA_package"]
                  .price_page_property_photo && (
                  <img
                    alt="price_page_property_photo"
                    width={126}
                    height={80}
                    src={
                      configureData["base_CMA_package"]
                        .price_page_property_photo
                    }
                  />
                )}
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    textTransform: "capitalize",
                    border: "1px solid rgba(33, 33, 33, 0.4)",
                  }}
                  onClick={() => setOpenImageUploadModal({price_page_property_photo: true})}
                  component="label"
                >
                  Select File
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </Container>
  );
}
export default Configure;
