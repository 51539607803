import React, { Fragment, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ResponseAlert from "../responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { changePassword } from "../../redux/auth/changePassword";
import { useSelector, useDispatch } from "react-redux";

function UpdatePassword(props) {
  const { open, setOpen, classes } = props;
  const [passwordDetail, setPasswordDetail] = useState({
    curr_password: "",
    new_password: "",
    conf_password: "",
  });
  const [step, setStep] = useState(1);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const editPassword = useSelector((state) => state.adminAuth.editPassword);

  const handleUpdateDetail = (field, value) => {
    setPasswordDetail({ ...passwordDetail, [field]: value });
    setErrMsg({});
  };
  const handleClose = () => {
    setOpen(false);
    setErrMsg({});
    setStep(1);
    setPasswordDetail({
      curr_password: "",
      new_password: "",
      conf_password: "",
    });
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!passwordDetail.curr_password && step === 1) {
      isFormValid = false;
      errorMsg["curr_password"] = "Please enter the current password";
      setErrorAlert({
        errorMsg: "Please enter the current password",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!passwordDetail.new_password && step === 1) {
      isFormValid = false;
      errorMsg["new_password"] = "Please enter the new password";
      setErrorAlert({
        errorMsg: "Please enter the new password",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!passwordDetail.conf_password && step === 2) {
      isFormValid = false;
      errorMsg["conf_password"] = "Please confirm new password";
      setErrorAlert({
        errorMsg: "Please confirm new password",
        errorType: "warning",
        isOpen: true,
      });
    } else if (
      passwordDetail.conf_password !== passwordDetail.new_password &&
      step === 2
    ) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Confirm password should ",
        errorType: "warning",
        isOpen: true,
      });
    }

    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "Password updated successfully",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };
  const handleConfirm = () => {
    if (step === 1) {
      if (handleValidate()) {
        setStep(2);
      }
    } else {
      if (handleValidate()) {
        dispatch(
          changePassword({
            schema: {
              curr_password: passwordDetail.curr_password,
              new_password: passwordDetail.new_password,
            },
            handleSuccess,
            handleError,
          })
        );
      }
    }
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Update Password</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={3} sx={{ mt: "0px" }}>
            {step === 1 ? (
              <Grid item xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  sx={{
                    fontWeight: "500",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                  }}
                >
                  Current Password
                </InputLabel>
                <TextField
                  value={passwordDetail.curr_password}
                  onChange={(e) =>
                    handleUpdateDetail("curr_password", e.target.value)
                  }
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  type="password"
                  variant="outlined"
                  size="small"
                  error={errMsg.curr_password}
                  helperText={errMsg.curr_password}
                />
              </Grid>
            ) : null}
            {step === 1 ? (
              <Grid item xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  sx={{
                    fontWeight: "500",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                  }}
                >
                  New Password
                </InputLabel>
                <TextField
                  value={passwordDetail.new_password}
                  onChange={(e) =>
                    handleUpdateDetail("new_password", e.target.value)
                  }
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  type="password"
                  variant="outlined"
                  size="small"
                  error={errMsg.new_password}
                  helperText={errMsg.new_password}
                />
              </Grid>
            ) : null}
            {step === 2 ? (
              <Grid item xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  sx={{
                    fontWeight: "500",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                  }}
                >
                  Confirm Password
                </InputLabel>
                <TextField
                  value={passwordDetail.conf_password}
                  onChange={(e) =>
                    handleUpdateDetail("conf_password", e.target.value)
                  }
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  type="password"
                  variant="outlined"
                  size="small"
                  error={errMsg.conf_password}
                  helperText={errMsg.conf_password}
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={handleClose}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={editPassword.isLoading}
            className={classes.saveButton}
            onClick={handleConfirm}
          >
            {step === 1 ? "Next" : "Save"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}

export default UpdatePassword;
