import { createSlice } from "@reduxjs/toolkit";
import {getEmailTemplates} from './getEmailTemplates'

const initialState = {
  emailsList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const LeadEmailTemplates = createSlice({
  name: "LeadEmailTemplates",
  initialState,
  extraReducers: {
     [getEmailTemplates.pending]: (state) => {
      state.emailsList.isLoading = true;
      state.emailsList.errMsg = null;
      state.emailsList.success = false;
    },
    [getEmailTemplates.fulfilled]: (state, action) => {
      state.emailsList.success = true;
      state.emailsList.isLoading = false;
      if(state?.emailsList?.data?.templates && action.meta?.arg?.offset>0){
        state.emailsList.data.templates = [...state?.emailsList?.data?.templates,...action.payload.templates];
      }else{
        state.emailsList.data = action.payload;
      }
 
    },
    [getEmailTemplates.rejected]: (state, action) => {
      state.emailsList.isLoading = false;
      state.emailsList.success = false;
      state.emailsList.errMsg = action.payload;
    },
  },
});
export default LeadEmailTemplates.reducer;
export * from './getEmailTemplates'
