import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  propertyList: [],
  errMsg: null,
};

export const getProperties = createAsyncThunk("docs/getProperties", async (data, thunkAPI) => {
  const { id, st_no, st_name } = data;
  console.log(data);
  let st_id = id.toString();
  let $filter = "";
  if (st_id) {
    $filter = `ListingId eq '${st_id}'`;
  }
  if ((st_no, st_name)) {
    if ($filter.length) {
      $filter += ` or `;
    }
    $filter += `startswith(tolower(StreetNumber),'${st_no.toLowerCase()}') and startswith(tolower(StreetName),'${st_name}')`;
  }
  try {
    const url = `${process.env.REACT_APP_BASE_URL}api/reso/fmls/properties?$filter=${$filter}`;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      method: "get",
      url,
    };

    return new Promise((resolve, reject) => {
      axios(options)
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          return err;
        });
    });
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const propertyList = createSlice({
  name: "propertyList",
  initialState,
  extraReducers: {
    [getProperties.pending]: (state) => {
      state.loading = true;
    },
    [getProperties.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertyList = action.payload.data;
    },
    [getProperties.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default propertyList.reducer;
