export const marketCustom = [
    {
      id: 1,
      title: "GA",
      backgroundColor: "#80CADB",
    },
    {
      id: 2,
      title: "ORL",
      backgroundColor: "#7BBD6A",
    },
    {
      id: 3,
      title: "AL",
      backgroundColor: "#D47575",
    },
    {
      id: 4,
      title: "NC",
      backgroundColor: "#DAD55D",
    },
    {
      id: 5,
      title: "MIA",
      backgroundColor: "#A484CD",
    },
  ];