export const styleSheet = () => ({
  preferenceRoot: {
    marginTop: "80px",
  },
  preferenceRootPaper: {
    padding: "30x !important",
    "& .MuiButton-root":{
        borderColor: 'rgba(33, 33, 33, 0.4) !important',
       color: '#0B0909 !important',
    },
    "& .MuiButton-sizeSmall":{
        background: 'rgba(33, 33, 33, 0.04)',
        borderLeft:'none',
        "&:hover":{
          borderLeft:'none',
        }
    },
    customButton:{
      background:'white !important'
    }, 
  },
  mainHeading: {
    fontWeight: "400 !important",
    fontSize: "24px !important",
    fontFamily: "AvenirNext  !important",
    marginBottom:'30px  !important'
  },
  subHeading: {
    fontWeight: "500",
    fontSize: "20px !important",
    fontFamily: "AvenirNext  !important",
    marginBottom:'10px  !important'
  },
});
