import { createSlice } from "@reduxjs/toolkit";
import { handleGetAlerts } from "./getAlerts";
import { handleAddAlert } from "./addAlert";
import { handleUpdateAlert } from "./updateAlert";
import { handleDeleteAlert } from "./deleteALert";
import { handleGetScheduleAlerts } from "./getScheduleAlerts";

const initialState = {
  alertList: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  scheduleAlertList: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  addAlert: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  updateAlert: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  deleteAlert: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
};

const Alerts = createSlice({
  name: "Alerts",
  initialState,
  extraReducers: {
    // get current alerts
    [handleGetAlerts.pending]: (state) => {
      state.alertList.isLoading = true;
      state.alertList.errMsg = null;
      state.alertList.success = false;
    },
    [handleGetAlerts.fulfilled]: (state, action) => {
      state.alertList.success = true;
      state.alertList.isLoading = false;
      state.alertList.data = action.payload;
    },
    [handleGetAlerts.rejected]: (state, action) => {
      state.alertList.isLoading = false;
      state.alertList.success = false;
      state.alertList.errMsg = action.payload;
    },
    // get schedule  alerts
    [handleGetScheduleAlerts.pending]: (state) => {
      state.scheduleAlertList.isLoading = true;
      state.scheduleAlertList.errMsg = null;
      state.scheduleAlertList.success = false;
    },
    [handleGetScheduleAlerts.fulfilled]: (state, action) => {
      state.scheduleAlertList.success = true;
      state.scheduleAlertList.isLoading = false;
      state.scheduleAlertList.data = action.payload;
    },
    [handleGetScheduleAlerts.rejected]: (state, action) => {
      state.scheduleAlertList.isLoading = false;
      state.scheduleAlertList.success = false;
      state.scheduleAlertList.errMsg = action.payload;
    },
    [handleAddAlert.pending]: (state) => {
      state.addAlert.isLoading = true;
      state.addAlert.errMsg = null;
      state.addAlert.success = false;
    },
    [handleAddAlert.fulfilled]: (state, action) => {
      state.addAlert.success = true;
      state.addAlert.isLoading = false;
      state.addAlert.data = action.payload;
      if (action.payload?.schedule) {
        let alertDataSchedule = state.scheduleAlertList?.data?.data ? [...state.scheduleAlertList?.data?.data] : [];
        let count = state.scheduleAlertList?.data?.count || 0;
        alertDataSchedule.unshift({ ...action.payload });
        state.scheduleAlertList.data.data = alertDataSchedule;
        state.scheduleAlertList.data.count = Number(count) + 1;
      } else {
        let count = state.alertList?.data?.count || 0;
        let alertData = state.alertList?.data?.data ? [...state.alertList?.data?.data] : [];
        alertData.unshift({ ...action.payload });
        state.alertList.data.data = alertData;
        state.alertList.data.count = Number(count) + 1;
      }
    },
    [handleAddAlert.rejected]: (state, action) => {
      state.addAlert.isLoading = false;
      state.addAlert.success = false;
      state.addAlert.errMsg = action.payload;
    },
    [handleUpdateAlert.pending]: (state) => {
      state.updateAlert.isLoading = true;
      state.updateAlert.errMsg = null;
      state.updateAlert.success = false;
    },
    [handleUpdateAlert.fulfilled]: (state, action) => {
      state.updateAlert.success = true;
      state.updateAlert.isLoading = false;
      state.updateAlert.data = action.payload;
      if (action.payload.schedule) {
        let alertData = [...state.scheduleAlertList?.data?.data];
        let index = alertData.findIndex((item) => item.id === action.payload.id);
        alertData[index] = {
          ...alertData[index],
          ...action.payload,
          who_to_alert: action.payload?.who_to_alert ? JSON.stringify(action.payload?.who_to_aler) : null,
        };
        state.scheduleAlertList.data.data = alertData;
      } else {
        let alertData = [...state.alertList?.data?.data];
        let index = alertData.findIndex((item) => item.id === action.payload.id);
        alertData[index] = {
          ...alertData[index],
          ...action.payload,
          who_to_alert: action.payload?.who_to_alert ? JSON.stringify(action.payload?.who_to_aler) : null,
        };
        state.alertList.data.data = alertData;
      }
    },
    [handleUpdateAlert.rejected]: (state, action) => {
      state.updateAlert.isLoading = false;
      state.updateAlert.success = false;
      state.updateAlert.errMsg = action.payload;
    },
    // delete event
    [handleDeleteAlert.pending]: (state) => {
      state.deleteAlert.isLoading = true;
      state.deleteAlert.errMsg = null;
      state.deleteAlert.success = false;
    },
    [handleDeleteAlert.fulfilled]: (state, action) => {
      state.deleteAlert.success = true;
      state.deleteAlert.isLoading = false;
      state.deleteAlert.data = action.payload;
      if (action.payload.schedule) {
        let alertData = [...state.scheduleAlertList?.data?.data];
        let count = state.scheduleAlertList?.data?.count || 0;
        let index = alertData.findIndex((item) => item.id === action.payload.id);
        alertData.splice(index, 1);
        state.scheduleAlertList.data.data = alertData;
        state.scheduleAlertList.data.count = Number(count) - 1;
      } else {
        let alertData = [...state.alertList?.data?.data];
        let count = state.alertList?.data?.count || 0;
        let index = alertData.findIndex((item) => item.id === action.payload.id);
        alertData.splice(index, 1);
        state.alertList.data.data = alertData;
        state.alertList.data.count = Number(count) - 1;
      }
    },
    [handleDeleteAlert.rejected]: (state, action) => {
      state.deleteAlert.isLoading = false;
      state.deleteAlert.success = false;
      state.deleteAlert.errMsg = action.payload;
    },
  },
});
export default Alerts.reducer;
export * from "./getAlerts";
export * from "./addAlert";
export * from "./updateAlert";
export * from "./deleteALert";
export * from "./getScheduleAlerts";
