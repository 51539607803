export const styleSheet = () => ({
  mainDashboardPage: {
    marginTop: "70px",
    maxWidth: "90%",
    margin: "0 auto",
    marginBottom:'50px'
  },
  methodWeatherBox: {
    height:"100%",
    padding: "15px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
  },
  descriptionBox: {
    height: "100%",
    padding: "15px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
  },

  descriptionBoxH1: {
    margin: 0,
    padding: 0,
  },

  thumbnailVideo: {
    width: "100%",
    height: "400px",
    cursor: "pointer",
    position: "relative",
    boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "40px",
  },
  imgCourses: {
    width: "100%",
    height:"100%",
    objectFit:"cover",
    boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "40px",
    "&:hover": {
      filter: "brightness(50%)",
    },
  },
  methodWeatherTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  descriptionImg: {
    cursor:"pointer",
    width: "100%",
    borderRadius: "40px",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    marginTop: "10px",
  },
  dFlex: {
    display: "flex",
    gap: "20px",
    width: "100%",
  },
  methodWeatherBoxImg: {
    width: "30px",
    height: "30px",
  },
  methodWeatherTopH1: {
    fontSize: "1.5rem",
    fontWeight: "600",
    fontFamily: 'AvenirNext!important',
    margin: "0px",
  },
  methodWeatherTopP: {
    fontSize: "14px",
  },
  methodWeatherBoxul: {
    boxShadow: "inset 0px 1px 0px rgb(0 0 0 / 12%)",
  },
  methodWeatherBoxli: {
    boxShadow: "inset 0px -1px 0px rgb(0 0 0 / 12%)",
    cursor: "pointer",
  },
  methodWeatherTopInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  methodWeatherTopInfoDes: {
    marginRight: "20px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    fontSize: "12px",
  },
  methodWeatherTopInfoDesSpan: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  methodWeatherTopInfoDesImg: {
    width: "52px",
  },
  methodWeatherBottom: {
    margin: "25px 0px",
    lineHeight: "35px",
  },
  alertBoxDashboard: {
    padding: "15px",
    minHeight: "60vh",
    "& .css-qivjh0-MuiStepLabel-label": {
      fontWeight: "600",
      fontSize: "13px",
    },
    "& .css-ahj2mt-MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "400",
      marginTop: "-5px",
    },
    "& .css-14yr603-MuiStepContent-root": {
      marginLeft: "56px",
    },
    "& .css-obxkkc-MuiStepConnector-root": {
      marginLeft: "56px",
    },
    "& .css-1mz1l2x-MuiStepContent-root": {
      marginLeft: "56px",
    },
  },
  alertBoxDashboardH2: {
    fontWeight: "400",
    fontSize: "24px",
    marginTop: "0px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  alertboxDashboardItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
    marginBottom: "15px",
  },
  alertboxDashboardItemIcon: {
    marginRight: "15px",
    "& .MuiSvgIcon-root": {
      fontSize: "40px",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },

  alertboxDashboardItemDesh3: {
    fontWeight: "600",
    fontSize: "14px",
    margin: "1px !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  alertboxDashboardItemDesp: {
    fontWeight: "400",
    fontSize: "14px",
    margin: "0px !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  dashboardActivitiesBox: {
    marginTop: "20px",
    padding: "15px",
    minHeight: "60vh",
  },
  "@media (max-width: 575px)": {
    dashboardActivitiesBox: {
      maxWidth: "280px",
      overflowX: "scroll",
    },
  },
  dashboardActivitiesBoxH2: {
    fontWeight: "400",
    fontSize: "24px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  dashboardActivitiesBoxTh: {
    fontWeight: "600 !important",
    whiteSpace: "nowrap",
  },
  tableImg: {
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "20px !important",
    height: "65px",
    width: "100%",
    margin: "5px",
  },
  videoTextHover: {
    position: "absolute",
    bottom: "0px",
    left: "30px",
  },
  upperHoverUI:{
    display:"none",
  },
  mainCardArea:{
    display: 'flex',
    justifyContent: 'center',
    "&:hover": {
      "& .IsHovered":{
        display:"flex !important",
        alignItems:"flex-end"
      },
      "& .hoveredBlock": {
        width: "100%", 
        height:'100%',
        borderRadius: "40px",
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
      }
    },
  },
  videoTextImg: {
    width: '425px',
    height:"220px",
    position: "relative !important",
    textAlign: 'center',
    cursor:"pointer",
    "& .hoveredBlock": {
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "0",
      height:"100%",
      padding: "30px 10px",
      transition: "1s ease"
    },
  },
  hoverButton: {
    marginLeft: "25%",
  },
  hoverText: {
    width:"100% !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize:"14px",
    fontFamily: "AvenirNext",
    fontWeight:"400",
    color: "white",
    "&:hover": {
      "& imgCourses": {
        filter: "brightness(50%) !important",
      },
    },
  },
  hoverText2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",
    height: "25px",
  },
  hoverTextMargin: {
    textAlign:"left",
    fontSize:"12px",
    marginBottom: "0px",
    marginLeft:"10px"
  },
  customListingChip:{
    textAlign:"left",
    marginBottom: "0px",
    border:"1px solid lightgrey",
    borderRadius:'12px',
    fontSize:"12px",
    padding:"1px 8px",
    marginLeft:"10px",
    whiteSpace:"noWrap"
  }
});
