import React, { useState } from "react";
import { Box, Container, Card, CardHeader, CardContent, TextField, Stack, Button } from "@mui/material";
import { styleSheet } from "../style";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { postData } from "../../../../utils/postData";
import ResponseAlert from "../../../../components/responseAlert";



function AddEmailTemplates(props) {
    const [loading,setLoading]=useState(false)
  const [templateDetail, setTemplateDetail] = useState({
    name: "",
  });
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [textValue, setTextValue] = useState("");
  let { classes } = props;
  const navigate = useNavigate();
  const handleUpdateField = (field, value) => {
    setTemplateDetail({
      ...templateDetail,
      [field]: value,
    });
  };

  const handleOnChange = (value) => {
    setTextValue(value);
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!templateDetail.name) {
      isFormValid = false;
      errorMsg["name"] = "Please enter the text template name";
      setErrorAlert({
        errorMsg: "Please enter the text template name",
        errorType: "warning",
        isOpen: true,
      });
    }
    if (!textValue) {
      isFormValid = false;
      errorMsg["emailText"] = "Please enter the text template body";
      setErrorAlert({
        errorMsg: "Please enter the text template body",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleSendEmail =async () => {
    if (handleValidate()) {
        setLoading(true)
      let obj = {
        name: templateDetail.name,
        message:  textValue ||
        "🙋🏻‍♀️ This is text email",
      };
     let response=await postData( `${process.env.REACT_APP_BASE_URL}api/templates/textMessage`,obj)
     if(response.id){
        setLoading(false)
        setErrorAlert({
            errorMsg: "You have successfully create the text template",
            errorType: "success",
            isOpen: true,
          });
          setTimeout(()=>{
            navigate("/templates/text-templates")
          },1000)
     }else{
        setLoading(false)
        setErrorAlert({
            errorMsg: JSON.stringify(response.message),
            errorType: "error",
            isOpen: true,
          });
     }
    }
  };
  return (
    <Container maxWidth="xl">
      <Box className={classes.addTempPageRoot}>
        <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRoot}>
          <CardHeader classes={{ title: classes.pageHeading }} title="Add Text Message Template" />
          <CardContent>
            <TextField
              fullWidth
              classes={{ root: classes.detailInput }}
              value={templateDetail.name}
              placeholder="This is the template name field"
              onChange={(e) => handleUpdateField("name", e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Name"
              size="small"
              error={errMsg.name}
              helperText={errMsg.name}
            />
            <br />
            <br />
             <Box >
              <TextField
              multiline
              rows={10}
              fullWidth
              label="Template body"
              onChange={(e)=>handleOnChange(e.target.value)}
              value={textValue}
              />
            </Box>
            <Stack sx={{ mt: "30px" }} direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button
                onClick={() => navigate("/templates")}
                sx={{
                  color: "#000000",
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={() => handleSendEmail()}
                classes={{ root: classes.saveButton }}
                color="inherit"
                autoFocus
                loadingPosition="start"
                loading={loading}
              >
                Save
              </LoadingButton>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Container>
  );
}
export default withStyles(styleSheet, { name: "AddEmailTemplatesStyle" })(AddEmailTemplates);
