import React, { useState } from "react";
import {
  Grid,
  Alert,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ResponseAlert from "../../../components/responseAlert";
import { useSelector, useDispatch } from "react-redux";
import { createNewCMA, getAllAgents } from "../../../redux/tools/cmas";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SearchMLS from "./SearchMLS.js";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyCDqh1uJXV0q8ltkiA8Ijp5B9cE9oZ2WsM");
Geocode.setLanguage("en");
Geocode.setRegion("es");

function CreateCma(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes, open, handleClose } = props;

  const { createLoading } = useSelector((state) => state.allCmas);
  const users = useSelector((state) =>
    state.users.storedListOfUsers.list.filter((user) => user.is_agent === true)
  );

  const [StreetNumber, setStreetNumber] = useState(null);
  const [State, setState] = useState(null);
  const [StreetName, setStreetName] = useState(null);
  const [City, setCity] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [UnitNumber, setUnitNumber] = useState(null);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [agent_id, setAgent_id] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [is_property_selected, setIs_property_selected] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isManuallyAdd, setIsManuallyAdd] = useState(false);
  const [ListingType, setListingType] = useState("");
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const markets = useSelector((state) => state.agentList.markets);
  const handleChangeAgent = (event) => {
    setAgent_id(event.target.value);
    let user = users?.find(
      (it) => it?.id === event.target.value && it?.market_id
    );
    if (user) {
      setListingType(
        markets?.find((it) => it.id === user?.market_id)?.datasets[0]
      );
    } else {
      setListingType("");
    }
  };

  const handleChangeAddBehaviour = (event) => {
    setIsManuallyAdd(event.target.checked);
  };

  const handleSuccess = (data) => {
    setErrorAlert({
      errorMsg: "You have successfully added the CMA",
      errorType: "success",
      isOpen: true,
    });
    setTimeout(() => {
      handleClose();
      let cmaID = data.id;
      navigate(`/tools/cmas/${cmaID}`);
    }, 1000);
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const createCMAByManually = async () => {
    if (!agent_id) {
      setErrorMsg("Please select agent");
      return;
    } else {
      setErrorMsg(null);
    }

    if (!StreetName || !StreetNumber || !City || !PostalCode) {
      setErrorMsg(
        "To manually create CMA, please enter Street Name, Street Number, City, and Postal Code"
      );
      return;
    } else {
      setErrorMsg(null);
    }

    // Get latitude & longitude from address.
    Geocode.fromAddress(
      `${StreetNumber} ${StreetName} ${UnitNumber} ${State} ${PostalCode}`
    ).then(
      async (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const data = {
          schema: {
            agent_id,
            StreetNumber,
            StreetName,
            UnitNumber,
            City,
            PostalCode,
            StateOrProvince: State,
            latitude: lat,
            longitude: lng,
            ListingType,
          },
          handleSuccess,
          handleError,
        };
        dispatch(createNewCMA(data));
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const createCMABySearch = async () => {
    if (!agent_id) {
      setErrorMsg("Please select agent");
      return;
    } else {
      setErrorMsg(null);
    }

    if (Object.keys(is_property_selected).length === 0) {
      setErrorMsg("Please select an property");
      return;
    } else {
      setErrorMsg(null);
    }

    const { ListingId } = selectedProperty;

    const data = {
      schema: {
        agent_id,
        ListingId,
        ListingType: ListingType,
        is_property_selected: true,
      },
      handleSuccess,
      handleError,
    };
    dispatch(createNewCMA(data));
  };

  React.useEffect(() => {
    dispatch(getAllAgents());
  }, []);

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{
          position: "absolute",
          "& .MuiDialog-container": { height: "auto" },
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div
            style={{ padding: "10px", marginTop: "0px", minHeight: "400px" }}
            className={classes.dashboardActivitiesBox}
          >
            <Grid
              container
              spacing={2}
              mb={2}
              mt={2}
              display="flex"
              alignItems={"center"}
            >
              <Grid item md={6}>
                <Typography
                  variant="h5"
                  className={classes.dashboardActivitiesBoxH2}
                >
                  New CMA
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                display="flex"
                alignItems={"center"}
                justifyContent="flex-end"
                gap={3}
              >
                <FormGroup mt={8}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isManuallyAdd}
                        onChange={handleChangeAddBehaviour}
                      />
                    }
                    label="Manually Add"
                  />
                </FormGroup>
                <FormControl variant="standard" sx={{ minWidth: 220 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={agent_id}
                    onChange={handleChangeAgent}
                    label="Select Agent"
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                  >
                    <MenuItem disabled value={""}>
                      Select Agent
                    </MenuItem>
                    {users &&
                      users.map((user) => {
                        return (
                          <MenuItem value={user.id}>{user.full_name}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} display="flex" alignItems={"center"}>
              <Grid item xs={12}>
                {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              </Grid>

              {isManuallyAdd === false && (
                <Grid item xs={12}>
                  <SearchMLS
                    open={open}
                    agent_id={agent_id}
                    isCreateCMA={true}
                    agents={users}
                    classes={classes}
                    setErrorMsg={setErrorMsg}
                    is_property_selected={is_property_selected}
                    onClickAction={(item) => {
                      setSelectedProperty(item);
                      const { ListingId } = item;
                      setIs_property_selected({
                        [ListingId]: true,
                      });
                    }}
                    setButtonSwitchValue={setListingType}
                    itemXs={12}
                    itemLg={6}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                {isManuallyAdd && (
                  <div className={classes.dashboardAccordion}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Manually Add Subject
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <TextField
                          fullWidth
                          onChange={(e) => setStreetNumber(e.target.value)}
                          id="standard-basic"
                          label="Street Number"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          onChange={(e) => setStreetName(e.target.value)}
                          id="standard-basic"
                          label="Street Name"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          onChange={(e) => setUnitNumber(e.target.value)}
                          id="standard-basic"
                          label="Unit Number"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <TextField
                          fullWidth
                          onChange={(e) => setCity(e.target.value)}
                          id="standard-basic"
                          label="City"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          fullWidth
                          onChange={(e) => setState(e.target.value)}
                          id="standard-basic"
                          label="State"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          onChange={(e) => setPostalCode(e.target.value)}
                          id="standard-basic"
                          label="Zip Code"
                          variant="standard"
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>

              <Grid container mt={4} justifyContent={"flex-end"}>
                <Grid item alignItems={"end"}>
                  <Button
                    variant="text"
                    className={classes.textButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    onClick={
                      isManuallyAdd ? createCMAByManually : createCMABySearch
                    }
                    color="inherit"
                    className={classes.ContainedButton}
                    autoFocus
                    loadingPosition="start"
                    loading={createLoading}
                  >
                    Create
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "CreateCmaStyle" })(CreateCma);
