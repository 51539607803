import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import TableSkeleton from "../../../../components/loader/tableLoader.jsx";
import Avatar from "../../../../components/uiElements/avatar/index.jsx";
function ActivityRecordsTable(props) {
  let { classes, data, loading } = props;
  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Avatar sx={{ width: "30px", height: "30px", fontSize: "14px" }}>{`${params.row.name.split("")[0]?.slice(0, 1)}${params.row.name
              .split("")[1]
              ?.slice(0, 1)
              ?.toUpperCase()}`}</Avatar>
            <Box> {params.row.name}</Box>
          </Stack>
        );
      },
      width: 280,
      editable: false,
      sortable: true,
    },
    {
      type:"number",
      field: "calls",
      headerName: "Calls",
      width: 160,
      editable: false,
      sortable: true,
    },
    {
      field: "talkTime",
      headerName: "Talk Time",
      type:"number",
      renderCell: ({row}) =>(Number(row.talkTime)/60)?.toFixed(0),
      width: 160,
      editable: false,
      sortable: true,
    },
    {
      field: "textMessages",
      headerName: "Texts",
      type:"number",
      width: 160,
      editable: false,
      sortable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      type:"number",
      width: 160,
      editable: false,
      sortable: true,
    },
    {
      field: "emails",
      headerName: "Emails",
      type:"number",
      width: 160,
      editable: false,
      sortable: true,
    },
  ];
  return (
    <Box>
      {" "}
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={data}
          loading={loading}
          columns={columns}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
        />
      </Paper>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ActivityRecordsTableStyle" })(ActivityRecordsTable);
