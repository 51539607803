import React, { useState } from "react";
import { Autocomplete, Checkbox, TextField, Typography,createFilterOptions } from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const RenderMultiInputOptions = ({ data, value, name, textField, ...props }) => {
  const [dataOptions, setDataOptions] = useState(data.slice(0, 5));

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    limit: 20,
});  
  return (
    <Autocomplete
      multiple
      {...props}
      size="small"
      fullWidth
      id={name}
      options={data.map((r) => r[textField]) || []}
      value={value || []}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      filterOptions={filterOptions}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            let arrLength = dataOptions.length;
            if (data.length > arrLength) {
              setDataOptions(data.slice(0,arrLength + 5));
            }
            console.log("Dscdscdscd");
          }
        },
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected || false} />

          <Typography component="subtitle" variant="subtitle">
            {option}
          </Typography>
        </li>
      )}
      renderInput={(params) => <TextField {...params} placeholder={name} />}
    />
  );
};
