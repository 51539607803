import React from "react";
import { Routes, Route } from "react-router-dom";
import EmailTemplates from "./emailTemplates";
import TextTemplates from "./textTemplates"
import { Box } from "@mui/material";
import SubHeader from "./subHeader";

const TemplatesPage = (props) => {
  return (
    <Box>
      <SubHeader {...props} />
      <Routes>
        <Route path="/email-templates/*" element={<EmailTemplates />} />
        <Route path="/text-templates/*" element={<TextTemplates />} />
      </Routes>
    </Box>
  );
};

export default TemplatesPage;
