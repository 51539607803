export const styleSheet = (theme) => ({
  overViewTabCardMain: {
    marginBottom: "16px",
    padding: "0px 20px 15px 20px",
    "& .MuiStepConnector-line": {
      borderColor: "#BDBDBD !important",
      marginLeft: "75px !important",
      borderWidth: "2px !important",
    },
  },
  overViewTabCardHeading: {
    fontSize: "24px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  overViewTabCardDes: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)  !important",
  },
  overViewTabCard: {
    padding: "15px",
    marginBottom: "20px",
  },
  importantNotesCard: {
    marginBottom: "20px",
    padding: "0px 15px",
    border: "1px solid rgba(33, 33, 33, 0.4)  !important",
  },
  overViewTabCardPeoples: {
    padding: "5px",
    marginBottom: "5px",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentTableButton: {
    fontSize: "12px  !important",
    borderRadius: "9px  !important",
    minWidth: "80px !important",
  },
  importantNotesDes: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    marginTop: "10px  !important",
  },
  privateRemarksContent: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.6) !important",
    padding: "0px 0px 5px 0px !important",
  },
  importantNotesContent: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.6) !important",
    padding: "0px  !important",
  },
  importantPeoplesDes: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  menuListText: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    fontFamily: "AvenirNext  !important",
  },
  importantNotesHeader: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    fontFamily: "AvenirNext  !important",
    maxWidth: "40ch !important",
    textOverflow: "ellipsis !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
  },
  noteDatesText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    marginLeft: "-7px !important",
  },
  importantPeoplesHeader: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  overViewTabCardDesBottom: {
    textAlign: "right",
    marginTop: "25px",
    "& .MuiButton-root": {
      width: "25px!important",
      minWidth: "25px!important",
      height: "25px",
      backgroundColor: "white",
      color: "black",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid rgba(33, 33, 33, 0.4) !important",
    },
  },
  specificationItemDetail: {
    padding: "0px  !important",
    "& .MuiTypography-root": {
      fontSize: "15px !important",
      fontWeight: "600 !important",
      textTransform: "capitalize !important",
      fontFamily: "AvenirNext  !important",
      color: "rgba(0, 0, 0, 0.8) !important",
      marginBottom: "7px",
    },
    "& .MuiListItemSecondaryAction-root": {
      fontSize: "15px !important",
      fontWeight: "400 !important",
      textTransform: "capitalize !important",
      fontFamily: "AvenirNext  !important",
      textAlign: "right  !important",
      marginRight: "-15px !important",
      color: "rgba(0, 0, 0, 0.8) !important",
    },
  },
  specificationCard: {
    marginBottom: "16px",
    padding: "0px 20px 15px 20px",
    "& .MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.8) !important",
    },
  },
  photosCard: {
    marginBottom: "16px",
    padding: "0px 20px 30px 20px",
    position: "relative",
  },
  photosCardContent: {
    maxHeight: "590px !important",
    overflowY: "auto !important",
    paddingRight: "10px",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  MatterportLink: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext-400  !important",
    "& a": {
      fontSize: "14px !important",
      color: "#005FEE !important",
      fontFamily: "AvenirNext-400  !important",
      overflowWrap: "break-word  !important",
    },
  },
  dropzoneAreaInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18.8px",
    color: "black",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "11.5px",
    left: "0px",
    background: "rgba(255,255,255,.6)",
    width: "100%",
    height: "100%",
    borderRadius: "15px !important",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  publicImagesItem: {
    position: "relative  !important",
    "& .Mui-checked": { color: "white  !important" },
    border: "1px solid lightgrey !important",
    borderRadius: "8px  !important",
    height: "113px !important",
  },
  publicImagesItemCheckbox: {
    position: "absolute !important",
    top: "10px !important",
    right: "10px !important",
    zIndex: 10,
    color: "white  !important",
    backgroundColor: "rgba(0,0,0,.3)  !important",
    padding: "0px  !important",
    borderRadius: "20% !important",
    display: "none !important",
  },
  startIconPhoto: {
    position: "absolute !important",
    top: "5px !important",
    left: "5px !important  !important",
    marginLeft: "5px !important",
    zIndex: 10,
    padding: "1px 2px!important",
    display: "none !important",
    "& .MuiSvgIcon-root": {
      color: "white",
      filter: "drop-shadow(.5px 1px 2px rgb(0 0 0 / .6))",
    },
  },
  saveModalButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
    minWidth: "50px  !important",
  },
  inputLabel: {
    fontSize: "15px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
  },
  deliverables: {
    width: "100% !important",
    overflow:"none !important"
  },
});
