import React, { memo } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { numberWithCommas, thounsandNumberFormater } from "./helper";
import Chip from "@mui/material/Chip";
import { renderToString } from "react-dom/server";

import { debounce } from "lodash";
import moment from "moment";

import StarRateIcon from "@mui/icons-material/StarRate";
import NoImage from "../../../assets/images/noimage.png";
import BedIcon from "../../../assets/images/cmaicons/bed.png";
import BathIcon from "../../../assets/images/cmaicons/bath.png";
import HomeIcon from "../../../assets/images/cmaicons/home.png";
import GreenCheckIcon from "../../../assets/green-check.png";

import "./mapStyle.css";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData.js";

const SubjectMarker = (props) => {
  return (
    <Button disabled>
      <Chip
        label="Subject"
        icon={
          <StarRateIcon
            sx={{
              padding: "2px",
              color: "#fff!important",
              borderRadius: "50%",
              fontSize: "24px!important",
              border: "1px solid #fff",
            }}
          />
        }
        sx={{
          cursor: "pointer",
          backgroundColor: "#0B0909!important",
          color: "#fff!important",
          fontFamily: "AvenirNext !important",
          fontWeight: "600",
          fontSize: "13px",
          lineHeight: "24px!important",
          zIndex: "2",
        }}
      />
    </Button>
  );
};

function buildContent(property, checked) {
  const content = document.createElement("div");
  // content.setAttribute(
  //   "style",
  //   `border: 1.5px solid ${property.priceTagBGColor}`
  // );

  const ch = checked ? "checked" : "unchecked";

  const ReactElement = (
    <>
      <div
        className="price-tag"
        style={{
          background: `${property.priceTagBGColor}`,
          color: `${property.priceTagColor}`,
        }}
      >
        {property.price}
        {checked && (
          <div className="icon">
            <img width={"16px"} src={GreenCheckIcon} alt="selected" />
          </div>
        )}
      </div>
      <div className="icon">
        <img alt="cma-media" src={getImageUrlWithHeightAndWidth(property.MediaURL || NoImage, 180,180)} />
      </div>
      <div className="details">
        <div className="select-box">
          <label className="container">
            {" "}
            SELECT
            <input type="checkbox" id={`checkbox-${property.ListingId}-input`} value={ch} />
            <span id={`checkbox-${property.ListingId}-checkmark`} class="checkmark"></span>
          </label>
        </div>

        <div className="address">
          {property.StreetNumber || ""} {property.StreetName || ""} {property.StreetSuffix || ""} {property.City || ""}{" "}
          {property.PostalCode || ""}
        </div>
        <div className="price-date">
          <span className="price">{property.price} </span>
          <div className="date">
            <span style={{ color: `${property.dateColor}` }}>
              {property.MlsStatus === "Active Under Contract" ? "ActiveUC" : property.MlsStatus}:{" "}
            </span>
            <span>{moment(property.date).format("MM/DD/YY")}</span>
          </div>
        </div>
        <div className="features">
          <div className="bed">
            <img alt="bed" src={BedIcon} />
            <span>{property.BedroomsTotal || 0}</span>
          </div>
          <div className="bath">
            <img alt="bath" src={BathIcon} />
            <span>{property.BathroomsFull || 0}</span>
          </div>
          <div className="home">
            <img alt="home" src={HomeIcon} />
            <span>{property.BuildingAreaTotal ? numberWithCommas(property.BuildingAreaTotal) : 0}</span>
          </div>
        </div>
      </div>
    </>
  );

  content.classList.add("property");
  const staticElement = renderToString(ReactElement);
  content.innerHTML = staticElement;
  return content;
}

const Marker = (options) => {
  const { index, marker, position, map, property, selected, changed, onClick, mouseLeaveEvent, mouseEnterEvent, setMarkers } = options;

  const [checked, setChecked] = React.useState(selected);

  React.useEffect(() => {
    setChecked(selected);
  }, [selected]);

  React.useEffect(() => {
    let currentMarker = marker;
    if (currentMarker) {
      currentMarker.map = null;
      currentMarker = null;
    }
    if (!currentMarker && map) {
      const advancedMarkerView = new window.google.maps.marker.AdvancedMarkerView({
        position,
        map,
        content: buildContent(property, checked),
        collisionBehavior: window.google.maps.CollisionBehavior.REQUIRED,
      });

      setMarkers(advancedMarkerView);

      const element = advancedMarkerView.element;

      ["pointerenter"].forEach((event) => {
        element.addEventListener(event, (e) => {
          mouseEnterEvent(e, index);
        });
      });

      ["pointerleave"].forEach((event) => {
        element.addEventListener(event, (e) => {
          mouseLeaveEvent(e, index);
        });
      });

      advancedMarkerView.addListener("click", (e) => {
        onClick(e, advancedMarkerView);
      });
    }
  }, [map, changed]);

  return null;
};

const ComparableMap = ({
  comparableData,
  handleRemoveCMAComparable,
  addToCMASelectedComparable,
  selectedComparable,
  selectedComparableData,
  classes,
  bounds,
  mapBoundCalculate,
  queryChanged,
  switchValue,
}) => {
  const { singleCma } = useSelector((state) => state.allCmas);
  const [map, setMap] = React.useState(null);
  const [markers, setMarkers] = React.useState([]);
  const [mergedComparables, setMergedComparables] = React.useState([]);
  const [changed, setChanged] = React.useState(false);
  const [comData, setComData] = React.useState([]);

  const debounceMouseLeaveEvent = React.useRef(
    debounce((markers, index) => {
      markers[index].content.classList.remove("highlight");
      markers[index].element.style.zIndex = "";
    }, 800)
  ).current;

  React.useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);

  React.useEffect(() => {
    setComData(comparableData.filter((d) => d.PropertyType !== "Residential Lease").slice(0, 1000 - selectedComparableData.length));
  }, [comparableData]);

  React.useEffect(() => {
    if (queryChanged) {
      setMarkers((m) => {
        return m.map((marker) => {
          if (marker) marker.map = null;
          marker = null;
          return marker;
        });
      });
      setChanged(!changed);
      // setQueryChanged(false);
    }
  }, [queryChanged]);

  React.useEffect(() => {
    setChanged(!changed);
    setMergedComparables([
      ...comData.map((cd) => ({ ...cd, selected: false })),
      ...selectedComparableData.map((cd) => ({ ...cd, selected: true })),
    ]);
  }, [comData, selectedComparableData]);

  // cleaning up mouse event debounce
  React.useEffect(() => {
    return () => {
      debounceMouseLeaveEvent.cancel();
    };
  }, [debounceMouseLeaveEvent]);

  return (
    <div
      className="comparable-map w-100 h-100"
      style={{
        height: 500,
        width: "100%",
        border: "2px solid #000000",
        borderRadius: "5px",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_KEY,
          libraries: ["marker"],
          version: "beta",
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        options={{
          mapId: "d089a9e24f93a07e",
          clickableIcons: true,
        }}
        defaultCenter={{
          lat: parseFloat(singleCma?.latitude || 34.0632049),
          lng: parseFloat(singleCma?.longitude || -84.395413),
        }}
        defaultZoom={16}
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          mapBoundCalculate(comparableData, selectedComparable);
        }}
        resetBoundsOnResize={false}
      >
        {map && (
          <SubjectMarker classes={classes} {...singleCma} lat={parseFloat(singleCma?.latitude)} lng={parseFloat(singleCma?.longitude)} />
        )}

        {map &&
          mergedComparables.map((item, index) => (
            <Marker
              index={index}
              changed={changed}
              selected={item.selected}
              marker={markers[index] || null}
              position={{
                lat: parseFloat(item?.latitude),
                lng: parseFloat(item?.longitude),
              }}
              setMarkers={(markerView) => {
                setMarkers((m) => {
                  m[index] = markerView;
                  return m;
                });
              }}
              onClick={(event, markerView) => {
                if (event.domEvent.target.id === `checkbox-${item.ListingId}-checkmark`) {
                  const isItemSelected = item.selected;

                  document.getElementById(`checkbox-${item.ListingId}-input`).value = isItemSelected ? "unchecked" : "checked";

                  setMergedComparables((mc) => {
                    item.selected = !isItemSelected;
                    mc[index].selected = !isItemSelected;
                    return mc;
                  });

                  markers[index].content.classList.remove("highlight");
                  markers[index].element.style.zIndex = "";

                  !isItemSelected
                    ? addToCMASelectedComparable(item.ListingId, item.cma_id, switchValue || item.ListingType)
                    : handleRemoveCMAComparable(item.id);
                }

                if (event.domEvent.target.className === "price-tag") {
                  const infoWindow = new window.google.maps.InfoWindow();
                  infoWindow.setPosition({
                    lat: parseFloat(item?.latitude),
                    lng: parseFloat(item?.longitude),
                  });
                  infoWindow.setContent("<div></div>");

                  infoWindow.open(map);

                  for (let i = 0; i < markers.length; i++) {
                    if (i !== index) {
                      if (markers[i]) {
                        markers[i].content.classList.remove("highlight");
                        markers[i].element.style.zIndex = "";
                      }
                    }
                  }

                  markers[index].content.classList.add("highlight");
                  markers[index].element.style.zIndex = 9999999;
                }
              }}
              mouseLeaveEvent={() => {
                debounceMouseLeaveEvent(markers, index);
              }}
              mouseEnterEvent={() => {
                debounceMouseLeaveEvent.cancel();
              }}
              map={map}
              property={{
                ...item,
                date:
                  item.MlsStatus === "Closed"
                    ? item.CloseDate
                    : item.MlsStatus === "Active" || item.MlsStatus === "Active Under Contract"
                    ? item.ListingContractDate
                    : item.MlsStatus === "Pending"
                    ? item.PurchaseContractDate
                    : item.MlsStatus === "Expired"
                    ? item.ExpirationDate
                    : "",
                dateColor:
                  item.MlsStatus === "Closed"
                    ? "red"
                    : item.MlsStatus === "Active"
                    ? "green"
                    : item.MlsStatus === "Pending"
                    ? "#c8c802"
                    : item.MlsStatus === "Expired"
                    ? "#FF9800"
                    : item.MlsStatus === "Active Under Contract"
                    ? "#4CC7CF"
                    : "",
                priceTagColor: item.MlsStatus === "Pending" ? "#0B0909!important" : "#fff",
                priceTagBGColor:
                  item.MlsStatus === "Closed"
                    ? "red"
                    : item.MlsStatus === "Active"
                    ? "green"
                    : item.MlsStatus === "Pending"
                    ? "yellow"
                    : item.MlsStatus === "Active Under Contract"
                    ? "#4CC7CF"
                    : "grey",
                price:
                  item.MlsStatus === "Closed"
                    ? `$${thounsandNumberFormater(item.ClosePrice)}`
                    : `$${thounsandNumberFormater(item.ListPrice)}`,
              }}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
};

export default withStyles(styleSheet, { name: "ComparableMapStyle" })(memo(ComparableMap));
