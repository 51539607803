import React from 'react'
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import logo2 from '../../assets/images/header/logo2.png' 
function MainLoader() {
    return (
        <div className="method-main-loader">
        <div className="blob setImage-dfd">
          <img className="m-auto"  src={logo2} alt="blob" />
        </div>
      </div>
    )
}
export default withStyles(styleSheet, { name: "MainLoaderStyle" })(MainLoader);