export const styleSheet = () => ({
  pageRoot: {
    marginTop: "30px !important",
  },
  loadingArea:{
    height:"80vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  TopUsersBarArea: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:"5px 20px",
    height:'50px'
  },
  TopUsersBarAreaLeft:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center'
  },
  UsersTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root":{
      fontSize:'13px !important',
      minHeight:'40px !important',
    },
    "& .MuiTabs-root":{
      minHeight:'40px  !important'
    },
    "& .MuiTabs-scroller":{
      height:'36px  !important'
    }
  },
  addTransactionButton: {
    background: "#0B0909 !important",
    fontWeight:'400  !important',
    fontSize:'12.5px !important',
    color: "white",
    height:'34px !important',
    marginRight:'15px !important',
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
  },
});
