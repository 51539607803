import io from "socket.io-client";
// ${process.env.REACT_APP_API_BASE}/
/* const socket = io(`${process.env.REACT_APP_API_BASE}`,{
  transports:["websocket"],
  "force new connection":true,
}); 
prod:https://notifications.ourmethod.com
dev: http://18.215.184.168:4001
*/
const establishSocketConnection=(id)=>{
 return  io.connect(
    `${process.env.REACT_APP_NOTIFICATION_SOCKET_BASE_URL}?userId=${id}`,
    {
      transports: ["websocket"],
      "force new connection": true,
      cookie: false,
      autoConnect: false,
    }
  );
}
export default establishSocketConnection;
