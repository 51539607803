export const styleSheet = () => ({
  pageRoot: {
    marginTop: "70px !important",
  },
  loadingArea:{
    height:"80vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  historyLogsTime:{
    fontSize: "14px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    whiteSpace:"nowrap !important",
    width:"155px !important",
    marginLeft:"15px"
  },

  historyLogsDetail:{
    fontSize: "14px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
  },
});
