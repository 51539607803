import React from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import momentTZ from "moment-timezone";
import Linkify from "react-linkify";
import { useNavigate } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../shared/tooltip.js";

function ShortAlertWidget(props) {
  let { classes, setOpenAlert, alertDetail,adminAuth } = props;

  const navigate = useNavigate();

  const handleRedirect = (alertDetail) => {
    if (alertDetail?.lead_id) {
      navigate(`/leads/people/details/${alertDetail?.lead_id}`);
    }
    if (alertDetail?.recruitment_id) {
      navigate(`/tools/recruiting/details/${alertDetail?.recruitment_id}`);
    }
    if (alertDetail?.project_id) {
      navigate(`/projects`, {
        state: { project_id: alertDetail?.project_id },
      });
    }
    if (alertDetail?.task_id) {
      navigate(`/projects`, {
        state: { task_id: alertDetail?.task_id },
      });
    }
    if (alertDetail?.ticket_id) {
      navigate(`/agent_tickets/assigned`, {
        state: { ticket_id: alertDetail?.ticket_id },
      });
    }
    setOpenAlert(false);
  };

  return (
    <Box sx={{ width: "340px" }}>
      <List disablePadding>
        <ListItem  disablePadding>
          <ListItemButton
            sx={{
              paddingRight: "4px",
              "&:hover": {
                "& .closeActionItem": { display: "block !important" },
              },
            }}
          >
            <ListItemAvatar onClick={() => handleRedirect(alertDetail)} sx={{ minWidth: "40px" }}>
              <ErrorOutlineIcon sx={{ fontSize: "27px", color: "grey" }} />
            </ListItemAvatar>
            <ListItemText
              sx={{ width: "80%" }}
              onClick={() => handleRedirect(alertDetail)}
              classes={{ secondary: classes.textMessagesListItemContent }}
              primary={
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  {" "}
                  <Typography className={classes.alertListItemHeading}>
                    {alertDetail.title}
                  </Typography>{" "}
                  <Typography className={classes.textMessagesListItemTime}>
                    {momentTZ(alertDetail.updatedAt).fromNow()}
                  </Typography>
                </Stack>
              }
              secondary={
                <Linkify
                  properties={{ target: "_blank" }}
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {reactStringReplace(
                        alertDetail?.body || "",
                        /@\[(.*?)]/g,
                        (match, i) => (
                          <CustomTooltip title={match?.split("^")?.[0]} placement="top">
                          <Box
                            sx={{
                              color:
                                match?.split("^")?.[1] ===
                                adminAuth?.adminDetail.id
                                  ? "#007EA6 !important"
                                  : "#434343 !important",
                              background:
                                match?.split("^")?.[1] ===
                                adminAuth?.adminDetail.id
                                  ? "#CCF3FF !important"
                                  : "#ECECEC !important",
                              display: "inline-block",
                              textDecoration: "none",
                            }}
                            key={i}
                            to={`/agent_roster/${
                              match?.split("^")?.[1] || 69
                            }/overview`}
                            component={Link}
                          >
                            @{match?.split(" ")?.[0]}
                          </Box>
                          </CustomTooltip>
                        )
                      )}
                </Linkify>
              }
            />
            <ListItemSecondaryAction
              className="closeActionItem"
              sx={{ mr: "-10px", display: "none" }}
            >
              <IconButton onClick={() => setOpenAlert(false)} size="small">
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ShortAlertWidgetStyle" })(
  ShortAlertWidget
);
