import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { approveUnprocessedClosings } from "./approveUnprocessedClosings";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  closing: {},
  errMsg: null,
  actionLoading: false,
};

export const getClosingInfo = createAsyncThunk("finance/getClosingInfo", async ({ id, processed }, thunkAPI) => {
  try {
    const url = `/api/finance/${processed ? "processed-closings" : "unprocessed-closings"}/${id}`;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      method: "get",
      url,
    };

    return new Promise((resolve, reject) => {
      axios(options)
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          return err;
        });
    });
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const setClosingInfoToNull = createAsyncThunk("finance/setClosingInfoToNull", async ({}, thunkAPI) => {
  try {
    return { closing: {} };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
const unprocessedClosing = createSlice({
  name: "unprocessedClosing",
  initialState,
  extraReducers: {
    [getClosingInfo.pending]: (state) => {
      state.loading = true;
    },
    [getClosingInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.closing = action.payload.data;
    },
    [getClosingInfo.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
    [approveUnprocessedClosings.pending]: (state) => {
      state.actionLoading = true;
    },
    [approveUnprocessedClosings.fulfilled]: (state, action) => {
      state.actionLoading = false;
    },
    [approveUnprocessedClosings.rejected]: (state, action) => {
      state.actionLoading = false;
    },
    [setClosingInfoToNull.fulfilled]: (state, action) => {
      state.closing = {};
    },
  },
});

export default unprocessedClosing.reducer;
