export const styleSheet = () => ({
  pageRoot: {
    padding: "70px !important",
    backgroundColor:'#f5f5f5 !important'
  },
  listItemSecondaryAction: {
    visibility: "hidden  !important",
  },
  listItem: {
    "&:hover $listItemSecondaryAction": {
      visibility: "inherit  !important",
    },
  },
  trainingIssueButton: {
    bgcolor: "#FFFFFF !important",
    color: "#000000 !important",
    borderColor: "#000000 !important",
    borderRadius: "30px !important",
    "&:hover": { borderColor: "#000000 !important" },
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    whiteSpace: "nowrap !important",
    marginTop: "3px  !important",
    marginBottom: "3px  !important",
    display:"flex !important",
    alignItems:"centner !important"
  },
  RecordConversationButton: {
    borderRadius: "30px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    whiteSpace: "nowrap !important",
    marginTop: "3px  !important",
    marginBottom: "3px  !important",
    backgroundColor:"white  !important",
    display:"flex !important",
    alignItems:"centner !important"
  },
  isaWaitButton: {
    background: "#0B0909 !important",
    "&:hover": { bgcolor: "#0B0909" },
    borderRadius: "30px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext-400  !important",
    whiteSpace: "nowrap !important",
    marginTop: "3px  !important",
    marginBottom: "3px  !important",
    display:"flex !important",
    alignItems:"centner !important"
  },
  dropzoneAreaInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18.8px",
    color: "black",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "11.5px",
    left: "0px",
    background: "rgba(255,255,255,.6)",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
});
