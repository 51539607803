import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  // Header css
  navContainer: {
    padding: "25px 60px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    "& img": {
      maxWidth: "250px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px 40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "18px 20px",
    },
  },
  container: {
    fontFamily: '"Work Sans", sans-serif',
    letterSpacing: "2.5px",
    lineHeight: "26px",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "35px",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "1024px !important",
      padding: "0 12px !important",
      lineHeight: "35px",
    },
  },
  smallContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "1024px !important",
      padding: "0 25px !important",
      lineHeight: "35px",
    },
  },

  section: {
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
    maxWidth: "100vw",
    padding: "40px 0px 0",
    [theme.breakpoints.up("xsm")]: {
      padding: "25px 0 0",
    },
    [theme.breakpoints.up("md")]: {
      padding: "90px 0 0",
    },
    fontFamily: '"Work Sans", sans-serif !important',
    "& img": {
      maxWidth: "100%",
      objectFit: "cover",
    },
  },
  analys: {
    textTransform: "uppercase !important",
    color: "white",
    "& h1": {
      fontFamily: '"Work Sans", sans-serif',
      fontWeight: 600,
      color: "#FFFFFF",
      letterSpacing: "2.5px",
      maxWidth: "410px",
      lineHeight: "1.4",
      position: "relative",
      fontSize: "16px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "21px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
        fontWeight: 500,
      },
    },
    "& .before": {
      height: "1px",
      backgroundColor: "#fff",
      width: "100%",
      maxWidth: "200px",
      margin: "30px 0",
    },
    "& .body1": {
      color: "white",
      fontSize: "16px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
      letterSpacing: "2.5px",
      fontWeight: 400,
      boxShadow: "0px 0px 10px 0px #000000B2",
      fontFamily: '"Work Sans", sans-serif',
    },
    "& .body2": {
      color: "white",
      fontSize: "12px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      letterSpacing: "2.5px",
      fontWeight: 400,
      boxShadow: "0px 0px 10px 0px #000000B2",
      fontFamily: '"Work Sans", sans-serif',
    },
  },
  prepared: {
    color: "white",
    textTransform: "uppercase !important",
    "& img": {
      maxWidth: "120px",
      maxHeight: "120px",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100px",
        maxHeight: "100px",
      },
    },
    "& h4": {
      color: "white",
      fontSize: "11px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
      letterSpacing: "2.5px",
      fontWeight: 600,
      fontFamily: '"Work Sans", sans-serif',
    },
    "& h5": {
      color: "white",
      fontSize: "11px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
      letterSpacing: "2.5px",
      fontWeight: 500,
      fontFamily: '"Work Sans", sans-serif',
    },
    "& p": {
      color: "white",
      fontSize: "10px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "14px",
      },
      letterSpacing: "2.5px",
      fontWeight: 400,
      fontFamily: '"Work Sans", sans-serif',
    },
    "& .agent-contact": {
      fontSize: "14px",
      color: "white",
      letterSpacing: "2.5px",
      fontWeight: 400,
      borderColor: "white !important",
      fontFamily: '"Work Sans", sans-serif',
      display: "none !important",
      "& span": {
        transition: "1s all ease",
      },
      "&:hover span": {
        transform: "translateX(10px)",
      },
      [theme.breakpoints.up("xsm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex !important",
      },
    },
    "& a": {
      borderColor: "white !important",
      color: "white !important",
      fontFamily: '"Work Sans", sans-serif',
    },
    "& .contactIcons": {
      display: "flex !important",
      [theme.breakpoints.up("sm")]: {
        display: "none !important",
      },
    },
    "& .agent_name": {
      color: "white",
      fontSize: "12px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
      letterSpacing: "2.5px",
      fontWeight: 400,
      fontFamily: '"Work Sans", sans-serif',
    },
  },
  herobg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: "18%",
    [theme.breakpoints.up("sm")]: {
      bottom: "30%",
    },
    backgroundColor: "#000000",
    zIndex: -1,
    boxShadow: "0px 4px 4px 0px #00000040",
  },
  sectiontitle: {
    fontFamily: "'Work' Sans",
    textAlign: "center",
    fontSize: "16px",
    padding: "16px 0px",
    [theme.breakpoints.up("xsm")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.up("lg")]: {
      textAlign: "left",
      fontSize: "24px !important",
    },
    fontWeight: "400",
    lineHeight: "35px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "26px",
    },
    letterSpacing: "2.5px",
    color: "black",
    borderBottom: "1.5px solid black",
    textTransform: "uppercase !important",
  },
  mainTitle: {
    fontFamily: "'Work' Sans",
    fontSize: "16px",
    [theme.breakpoints.up("xsm")]: {
      fontSize: "18px",
    },
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
    },
    fontWeight: "400",
    lineHeight: "35px",
    letterSpacing: "2.5px",
    color: "black",
    textTransform: "uppercase !important",
  },
  "@global": {
    ".pt-10": {
      [theme.breakpoints.up("sm")]: {
        paddingTop: "25px !important",
      },
    },
    ".text-right": {
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        textAlign: "right",
      },
    },
    ".subTitle": {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
      textWrap: "balance",
      padding: "15px 0",
      "& div:nth-child(2)": {
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "29px",
        letterSpacing: "2.5px",
        textAlign: "left",
        textTransform: "uppercase",
        padding: "0 15px 0 0",
        [theme.breakpoints.down("sm")]: {
          padding: "0 15px",
          fontSize: "16px",
        },
      },
      "& div:last-child": {
        height: "1px",
        width: "100%",
        flexGrow: 1,
        backgroundColor: "#0000004D",
      },
      "& div:first-child": {
        height: "1px",
        width: 0,
        flexGrow: 0,
        display: "none",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          flexGrow: 1,
          display: "inline",
        },
        backgroundColor: "#0000004D",
      },
    },
    ".relative": {
      position: "relative",
    },
    ".overflow-hidden": {
      overflow: "hidden",
    },
    ".bg-black": {
      backgroundColor: "black",
      color: "white",
    },
    "& .badge": {
      position: "absolute",
      top: "-20%",
      right: "-15%",
      width: "35px",
      height: "35px",
      fontSize: "12px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "40px",
        height: "40px",
        fontSize: "21px",
      },
      backgroundColor: "#D9D9D9",
      color: "black",
      borderRadius: "50%",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: '"Work Sans", sans-serif',
    },
    ".flex": {
      display: "flex",
    },
    ".justify-center": {
      justifyContent: "center",
    },
    ".items-center": {
      alignItems: "center",
    },
    ".w-full": {
      width: "100%",
    },
    ".text-center": {
      textAlign: "center",
    },
    ".standout_features button": {
      padding: "5px 2px !important",
      maxWidth: "206px",
      minWidth: "206px",
      fontSize: "12px",
      margin: "5px auto",
      textWrap: "nowrap",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "19px",
        maxWidth: "100%",
        minWidth: "100%",
        margin: "0 auto",
        padding: "12px 20px !important",
      },
      borderColor: "black",
      color: "black",
      fontWeight: 500,
      fontFamily: '"Work Sans", sans-serif',
      borderRadius: "0",
      "&:hover": {
        borderColor: "black",
      },
    },
    ".statusArea": {
      display: "flex",
      alignItems: "center",
    },
    ".round": {
      maxWidth: "30px",
      maxHeight: "30px",
      minWidth: "30px",
      minHeight: "30px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "20px",
        maxHeight: "20px",
        minWidth: "20px",
        minHeight: "20px",
        fontSize: "10px",
      },
      backgroundColor: "#D63636",
      borderRadius: "50%",
      "&.active": {
        backgroundColor: "#509745",
      },
      "&.closed": {
        backgroundColor: "#000",
      },
    },
  },
  boxStack: {
    marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      marginTop: "-40px",
      marginBottom: "20px",
    },
    marginBottom: "40px",
    width: "100%",
    "& svg": {
      color: "white",
      fontSize: "30px",
      [theme.breakpoints.up("md")]: {
        fontSize: "40px",
      },
    },
  },
  propertydbg: {
    position: "absolute",
    top: 0,
    left: "-56px",
    right: "-51px",
    bottom: "30%",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      right: "40%",
      left: 0,
      bottom: 0,
    },
    "@media (min-width: 600px) and (max-width: 899px)": {
      right: "0",
      padding: "20px",
    },
    backgroundColor: "#EFEFEF",
    zIndex: -1,
  },
  propertyBgInner: {
    padding: "0 20px",
    paddingTop: "30px !important",
  },
  propertydbgXRev: {
    position: "absolute",
    top: 0,
    left: "-56px",
    right: "-51px",
    bottom: "35%",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      right: 0,
      left: "40%",
      bottom: 0,
    },
    backgroundColor: "#EFEFEF",
    zIndex: -1,
  },
  propertydbgYRev: {
    position: "absolute",
    top: "25%",
    left: "-56px",
    right: "-51px",
    bottom: "0",
    [theme.breakpoints.up("md")]: {
      top: 0,
      height: "100%",
      left: 0,
      right: "40%",
      bottom: 0,
    },
    backgroundColor: "#EFEFEF",
    zIndex: -1,
  },
  propertyList: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 50px 0 !important",
    },
    fontFamily: '"Work Sans", sans-serif',
    "& li": {
      justifyContent: "space-between !important",
      margin: "0 0 13px",
      padding: "10px 15px",
      [theme.breakpoints.down("lg")]: {
        padding: "0 10px",
        letterSpacing: "normal",
      },
      "& span": {
        "&:first-child": {
          textTransform: "uppercase !important",
          textWrap: "nowrap !important",
        },
        fontFamily: '"Work Sans", sans-serif',
        bottom: "30%",
        fontSize: "11px !important",
        [theme.breakpoints.up("xsm")]: {
          fontSize: "13px !important",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "20px !important",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "14px !important",
        },
        textWrap: "nowrap !important",
        fontWeight: "400 !important",
      },
    },
    "& li:last-child": {
      margin: "0 0 0px",
    },
  },
  comparableSumSec: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15px !important",
    },
  },
  // Map section
  mapSec: {
    fontSize: "16px",
    fontWeight: "400",
    padding: "90px 0 0",
    [theme.breakpoints.down("md")]: {
      padding: "55px 0 0",
    },
    [theme.breakpoints.down("xsm")]: {
      paddingTop: "45px 0 0",
    },
  },
  mapWrapper: {
    padding: "40px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "35px 0",
    },
  },
  mapComponent: {
    height: "475px !important",
    [theme.breakpoints.down("sm")]: {
      height: "285px !important",
    },
  },
  indicatorsWrap: {
    paddingTop: "10px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    "& div": {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      maxWidth: "170px",
      marginRight: "25px",
      [theme.breakpoints.down("xsm")]: {
        marginRight: "15px",
      },
      "& span": {
        display: "block",
        height: "20px",
        width: "20px",
        borderRadius: "100%",
        marginRight: "10px",
        [theme.breakpoints.down("xsm")]: {
          height: "15px",
          width: "15px",
          marginRight: "8px",
        },
      },
      "& p": {
        fontSize: "20px",
        textTransform: "uppercase",
        [theme.breakpoints.down("sm")]: {
          fontSize: "11px",
        },
      },
    },
    "& div:last-child": {
      marginRight: "0",
    },
  },
  firstIndicator: {
    backgroundColor: "#D63636",
  },
  midIndicator: {
    backgroundColor: "#EEBD11",
  },
  lastIndicator: {
    backgroundColor: "#1F901D",
  },
  tableWrapper: {
    textTransform: "uppercase",
    fontFamily: '"Work Sans", sans-serif !important',
  },
  areaTable: {
    overflowX: "hidden !important",
    boxShadow: "none !important",
    textTransform: "uppercase",
    "& table": {
      [theme.breakpoints.down("lg")]: {
        minWidth: "auto",
      },
    },
    "& table thead tr th": {
      fontFamily: '"Work Sans", sans-serif !important',
      fontSize: "9px",
      // padding: "10px 5px",
      padding: "10px 0 0 10px",
      lineHeight: "16px",
      width: "min-content",
      fontWeight: "500",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "16px",
        padding: "16px",
        width: "auto",
        fontWeight: "400",
        lineHeight: "23px",
        letterSpacing: "2.5px",
      },
      "&:first-child": {
        [theme.breakpoints.down("sm")]: {
          width: "130.5px",
          height: "100%",
          borderRight: "1px solid #00000026",
        },
      },

      textAlign: "left",
    },
    "& table tr td": {
      fontFamily: '"Work Sans", sans-serif !important',
      fontSize: "9px",
      padding: "10px 0 0 10px",
      width: "min-content",
      fontWeight: "500",

      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
        padding: "12px",
        width: "auto",
        letterSpacing: "2.5px",
        lineHeight: "26.17px",
        fontWeight: "400",
      },
      [theme.breakpoints.up("md")]: {
        padding: "12px",
      },
      textAlign: "left",

      "&:first-child": {
        display: "flex",
        letterSpacing: "2.5px",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          width: "130.5px",
          height: "100%",
          borderRight: "1px solid #00000026",
        },

        "& .round": {
          position: "relative",
          color: "white",
          marginRight: "10px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& img, & span": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            letterSpacing: "0",
          },
          "& img": {
            width: "70% !important",
          },
        },
      },
    },
  },
  tableCell: {
    display: "block",
    height: "20px",
    width: "20px",
    borderRadius: "100%",
    marginRight: "10px",
    backgroundColor: "#D63636",
  },
  mapTablePoints: {
    "@media (min-width: 600px) and (max-width: 1199px)": {
      width: "90%",
    },
  },
  chartContainer: {
    padding: "45px 40px 45px",
    margin: "0 auto",
    backgroundColor: "#EFEFEF",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
    fontFamily: '"Work Sans", sans-serif !important',
  },
  // table
  tableContainer: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "37px",
    },
    "& .active": {
      backgroundColor: "#EAFFEB80",
    },
    "& .under-contract": {
      backgroundColor: "#FFFDD480",
    },
    "& .sold": {
      backgroundColor: "#FFEAEA80",
    },
  },
  tableHead: {
    fontFamily: '"Work Sans", sans-serif !important',
    textTransform: "uppercase",
    fontSize: "12px !important",
    fontWeight: "500",
    padding: "13px !important",
    [theme.breakpoints.down("md")]: {
      padding: "11px !important",
      borderBottom: "0px !important",
      borderRight: "0px !important",
      maxWidth: "min-content !important",
      fontSize: "10px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      padding: "5px !important",
      fontSize: "9px !important",
      "&:nth-child(4)": {
        display: "inline-block !important",
        maxWidth: "20% !important",
        width: "100%",
      },
    },
  },
  tableRow: {
    fontFamily: '"Work Sans", sans-serif !important',
    borderBottom: "0 !important",
    fontSize: "12px !important",
    padding: "13px !important",
    [theme.breakpoints.down("md")]: {
      padding: "11px !important",
      maxWidth: "min-content !important",
    },
    [theme.breakpoints.down("xsm")]: {
      padding: "5px !important",
      fontSize: "9px !important",
      "&:nth-child(4)": {
        display: "inline-block !important",
        maxWidth: "20% !important",
        width: "100%",
      },
    },
  },
  rowBG: {
    backgroundColor: "#EFEFEF !important",
  },
  rowOne: {
    fontFamily: '"Work Sans", sans-serif !important',
    fontSize: "12px !important",
    fontWeight: "600 !important",
    lineHeight: "17px !important",
    letterSpacing: "2.5px !important",
    textAlign: "left !important",
    textTransform: "uppercase !important",
    [theme.breakpoints.down("md")]: {
      padding: "5px !important",
      maxWidth: "min-content !important",
      fontSize: "9px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      padding: "5px !important",
      fontSize: "9px !important",
      "&:nth-child(4)": {
        display: "inline-block !important",
        maxWidth: "20% !important",
        width: "100%",
      },
    },
  },
  // the Price
  thePriceDeatils: {
    "& h6": {
      fontFamily: '"Work Sans", sans-serif !important',
      fontSize: "18px !important",
      fontWeight: "400",
      textTransform: "uppercase",
      letterSpacing: "2.5px",
      lineHeight: "26px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "22px",
        lineHeight: "31.99px",
      },
      [theme.breakpoints.up("sm")]: {
        lineHeight: "41px",
        fontSize: "28px",
      },
    },
    "& h5": {
      fontSize: "13px",
      lineHeight: "18.9px",
      fontWeight: 600,
      [theme.breakpoints.up("xsm")]: {
        fontSize: "16px",
        lineHeight: "23.26px",
      },
      [theme.breakpoints.up("sm")]: {
        lineHeight: "34.9px",
        fontSize: "24px",
      },
    },
    "& .num": {
      fontSize: "32px",
      lineHeight: "46.53px",
      fontWeight: 400,
      [theme.breakpoints.up("xsm")]: {
        fontSize: "32px",
        lineHeight: "46.53px",
      },
      [theme.breakpoints.up("sm")]: {
        lineHeight: "61.07px",
        fontSize: "42px",
      },
    },
    "& p": {
      fontFamily: '"Work Sans", sans-serif !important',
      fontWeight: "400",
      textTransform: "uppercase",
      "& span": {
        fontWeight: "600",
      },
      fontSize: "12px",
      lineHeight: "24.36px",
      letterSpacing: "2px",
      [theme.breakpoints.up("xsm")]: {
        fontSize: "12px",
        lineHeight: "26.16px",
        letterSpacing: "2.5px",
      },
      [theme.breakpoints.up("sm")]: {
        lineHeight: "34.88px",
        fontSize: "16px",
      },
    },
  },
  makeover: {
    position: "static",
    padding: "40px 0",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 0 40px",
    },
    [theme.breakpoints.up("xsm")]: {
      padding: "170px 30px 40px",
      marginTop: "-100px",
      zIndex: "-1",
    },
    backgroundColor: "black",
    color: "white !important",
    fontFamily: '"Work Sans", sans-serif !important',
    "& a": {
      color: "white !important",
      fontFamily: '"Work Sans", sans-serif !important',
      display: "block",
      textDecoration: "none",
    },
    "& .makeoverDetails": {
      "& h4": {
        fontFamily: '"Work Sans", sans-serif !important',
        color: "white !important",
        lineHeight: "23.26px",
        fontSize: "16px",
        [theme.breakpoints.up("xsm")]: {
          fontSize: "18px",
          lineHeight: "26.17px",
        },
        [theme.breakpoints.up("sm")]: {
          lineHeight: "37.8px",
          fontSize: "26px",
        },
        "& span": {
          fontWeight: "600",
        },
      },
      "& p": {
        fontFamily: '"Work Sans", sans-serif !important',
        fontWeight: "400",
        textTransform: "uppercase",
        letterSpacing: "2.5px",
        "& span": {
          fontWeight: "600",
        },
        textAlign: "center",
        fontSize: "12px",
        lineHeight: "17.45px",
        [theme.breakpoints.up("xsm")]: {
          fontSize: "13px",
          lineHeight: "18.9px",
        },
        [theme.breakpoints.up("sm")]: {
          lineHeight: "34.88px",
          fontSize: "14px",
          width: "70%",
          margin: "0 auto",
        },
      },
      "& button": {
        backgroundColor: "white",
        color: "black",
        borderRadius: 0,
        margin: "0 auto",
        letterSpacing: "2.5px",
        padding: "3px 15px",
        fontSize: "16px",
        [theme.breakpoints.up("xsm")]: {
          fontSize: "18px",
        },
      },
    },
  },
  //CHART
  chartSection: {
    paddingTop: "115px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "90px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  chartHeading: {
    padding: "16px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0 16px",
    },
    display: "flex",
    justifyContent: "start",
    borderBottom: "1.5px solid #000000",
    "& h2": {
      fontSize: "24px",
      fontWeight: "400",
      textTransform: "uppercase",
      fontFamily: '"Work Sans", sans-serif !important',
      margin: "0",
      "& span": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        fontSize: "20px",
        textAlign: "center",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        fontSize: "18px",
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: "16px",
      },
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  leftPart: {
    height: "50vh",
    width: "80vw",
    padding: "0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  rightPart: {
    padding: "0 40px",
    borderLeft: "1px solid #AFAFAF",
    [theme.breakpoints.down("md")]: {
      borderLeft: "none",
      borderTop: "1px solid #AFAFAF",
      marginTop: "30px !important",
      paddingTop: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
    "& h1": {
      fontSize: "16px",
      fontWeight: "700",
      letterSpacing: "2.5px",
      fontFamily: '"Work Sans", sans-serif !important',
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      padding: "15px 0 12px !important",
      margin: "0 !important",
      [theme.breakpoints.up("sm")]: {
        padding: "0 0 18px !important",
      },
      "& svg": {
        margin: "0 10px 0 0",
        [theme.breakpoints.up("xsm")]: {
          margin: "0 12px 0 0",
        },
        [theme.breakpoints.up("sm")]: {
          margin: "0 25px 0 0",
        },
      },
    },
    "& ul ": {
      "& li": {
        fontSize: "13px",
        fontWeight: "500",
        letterSpacing: "2.5px",
        fontFamily: '"Work Sans", sans-serif !important',
        textTransform: "uppercase",
        padding: "5px 0 !important",
        [theme.breakpoints.up("xsm")]: {
          padding: "10px 0 !important",
        },
        [theme.breakpoints.up("sm")]: {
          padding: "12px 0 !important",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "25px 0 !important",
        },
        "& span": {
          margin: "0 25px 0 0",
          fontSize: "16px",
          fontWeight: "600",
          letterSpacing: "2.5px",
          fontFamily: '"Work Sans", sans-serif !important',
          textTransform: "uppercase",
        },
      },
    },
  },
  chartDetailsBoxesWrapper: {
    paddingTop: "50px !important",
    [theme.breakpoints.down("md")]: {
      paddingTop: "35px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      paddingTop: "25px !important",
    },
    paddingBottom: "0 !important",
  },
  normalView: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  responsiveView: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  chartDetailsBoxes: {
    padding: "16px !important",
    border: "1px solid #000000",
    fontFamily: '"Work Sans", sans-serif !important',
    "& h5": {
      fontSize: "13px",
      fontWeight: "700",
      textTransform: "uppercase",
      letterSpacing: "2.5px",
      textAlign: "left",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "500",
      textTransform: "uppercase",
      letterSpacing: "2.5px",
      textAlign: "left",
    },
  },
  propertyDetailsGrid: {
    marginTop: "10px !important",
  },
}));
