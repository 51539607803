export const styleSheet = () => ({
  pageRoot: {
    marginTop: "40px",
    padding: "20px",
  },
  TemplateHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    fontSize:'14px'
  },
  TemplateAddButton: {
    background: "#0B0909 !important",
    color: "white",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
  },
  tableImageArea: {
    width: "90px",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  addItemModalHeading: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  AddTemplateButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  addTemplateLabel: {
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14px !important",
    marginBottom:'10px !important'
  },
  TemplateIconBox:{
    padding:"10px",
    paddingLeft:'0px',
    backgroundColor:'white'
  },
  TemplateIconBoxImageSelected:{
    position:'absolute',
    top:'0px',
    right:'-10px'
  },
  loadingArea:{
    height:"50vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  TemplateIconBoxImage:{
    width:'50px',
    height:'60px',
    position:'relative',
    display:"flex",justifyContent:'center',alignItems:'center',
    "&:hover":{
    backgroundColor:"lightgrey",
    borderRadius:'6px',
    },
    "& img":{
      width:'32px',
      cursor:'pointer'
    }
  }
});
