export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      minHeight: 180,
      lineHeight:"25px"
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
      lineHeight:"25px"
    },
    input: {
      padding: 9,
      border: '1px solid silver',
      lineHeight:"25px",
      borderRadius:"6px"
    },
  },
  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      borderRadius: "5px",
      maxHeight:"320px",
      overflow:"auto"
    },
    item: {
      padding: "5px 15px",
      width: "250px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      display: "-webkit-box !important",
      "-webkit-line-clamp": "1 !important",
      "-webkit-box-orient": "vertical !important",
      overflow: "hidden !important",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
}