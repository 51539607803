import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  addCourse: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
  update: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
  loading: false,
  errMsg: null,
  courseData: { courses: [], count: 0 },
  draftedCourse: { courses: [], count: 0 },
};

export const getAllCourseData = createAsyncThunk(
  "KnowledgeBaseCourses/getAllcourseData",
  async (data, thunkAPI) => {
    try {
      const url = `${
        process.env.REACT_APP_BASE_URL
      }api/knowledgebase/course?pageNumber=${data?.pageNumber || 1}&pageSize=${
        data?.pageSize || 20
      }&sortBy=id&sortOrder=desc&search=${data?.search}`;
      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      });
      return { ...resp.data, pageNumber: data?.pageNumber };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
export const getAllDraftedData = createAsyncThunk(
  "KnowledgeBaseCourses/getAllDraftedData",
  async (data, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/course?pageNumber=${data?.pageNumber}&pageSize=${data?.pageSize}&sortBy=id&sortOrder=desc&draft=true&search=${data?.search}`;
      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      });
      return { ...resp.data, pageNumber: data?.pageNumber };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const createCourse = createAsyncThunk(
  "KnowledgeBaseCourses/createCourse",
  async (data, thunkAPI) => {
    const {
      title,
      summary,
      audience,
      postsIds,
      visibility,
      handleSuccess,
      handleError,
    } = data;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/course`;
      const resp = await axios.post(
        url,
        { title, summary, audience, posts: postsIds, visibility },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );
      if (handleSuccess && resp?.data?.data) {
        handleSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateCourse = createAsyncThunk(
  "KnowledgeBaseCourses/updateCourse",
  async (data, thunkAPI) => {
    const {
      title,
      summary,
      audience,
      postsIds,
      visibility,
      courseId,
      handleUpdateSuccess,
      handleError,
    } = data;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/course/${courseId}`;
      const updatedData = {
        title,
        summary,
        audience,
        posts: postsIds,
        visibility,
      };
      const resp = await axios.put(url, updatedData, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      if (handleUpdateSuccess && resp?.data?.data) {
        handleUpdateSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const KnowledgeBaseCourses = createSlice({
  name: "KnowledgeBaseCourses",
  initialState,
  extraReducers: {
    [getAllCourseData.pending]: (state) => {
      state.loading = true;
    },
    [getAllCourseData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.pageNumber > 1) {
        state.courseData = [...state.courseData, ...action.payload.data];
      } else {
        state.courseData = action.payload.data;
      }
    },
    [getAllCourseData.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
    [getAllDraftedData.pending]: (state) => {
      state.loading = true;
    },
    [getAllDraftedData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.pageNumber > 1) {
        state.draftedCourse = [...state.draftedCourse, ...action.payload.data];
      } else {
        state.draftedCourse = action.payload.data;
      }
    },
    [getAllDraftedData.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
    // create course
    [createCourse.pending]: (state) => {
      state.addCourse.isLoading = true;
      state.addCourse.errMsg = null;
    },
    [createCourse.fulfilled]: (state, action) => {
      state.addCourse.isLoading = false;
      state.addCourse.success = true;
      if (action.payload) {
        if (action.payload.visibility === "Draft") {
          let newCourseData = state.courseData?.draftedCourse
            ? [...state.courseData?.draftedCourse]
            : [];
          newCourseData.unshift(action.payload);
          state.courseData.draftedCourse = newCourseData;
        } else {
          let newCourseData = state.courseData?.courses
            ? [...state.courseData?.courses]
            : [];
          newCourseData.unshift(action.payload);
          state.courseData.courses = newCourseData;
        }
      }
    },
    [createCourse.rejected]: (state, action) => {
      state.addCourse.isLoading = false;
      state.addCourse.errMsg = action.payload;
    },
    // update course
    [updateCourse.pending]: (state) => {
      state.update.isLoading = true;
      state.update.errMsg = null;
    },
    [updateCourse.fulfilled]: (state, action) => {
      state.update.isLoading = false;
      state.update.success = true;
      if (action.payload) {
        if (action.payload.visibility === "Draft") {
          let newCourseData = state.courseData?.draftedCourse
            ? [...state.courseData?.draftedCourse]
            : [];
          let index = newCourseData.findIndex(
            (item) => item.id === action.payload?.id
          );
          newCourseData[index] = { ...newCourseData[index], ...action.payload };
          state.courseData.draftedCourse = newCourseData;
        } else {
          let newCourseData = state.courseData?.courses
            ? [...state.courseData?.courses]
            : [];
          let index = newCourseData.findIndex(
            (item) => item.id === action.payload?.id
          );
          newCourseData[index] = { ...newCourseData[index], ...action.payload };
          state.courseData.courses = newCourseData;
        }
      }
    },
    [updateCourse.rejected]: (state, action) => {
      state.update.isLoading = false;
      state.update.errMsg = action.payload;
    },
  },
});

export default KnowledgeBaseCourses.reducer;
