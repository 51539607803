import React, { useState } from "react";
import { Box, Stack, Button, Container } from "@mui/material";
import AdjustmentsDetailView from "./detailView";
import AdjustmentsRowView from "./rowView";
function AdjustmentSection(props) {
  const [viewType, setViewType] = useState("detail");
  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          alignItems="center"
          spacing={1}
          sx={{ mb: "-65px", mt: "30px" }}
        >
          <Button
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.30)",
              fontFamily: "AvenirNext !important",
              fontWeight: "600",
              backgroundColor: "white !important",
            }}
            variant="outlined"
            color="inherit"
            onClick={() => {
              if (viewType === "detail") {
                setViewType("row");
              } else {
                setViewType("detail");
              }
            }}
          >
            {viewType === "detail" ? "Row View" : "Detail View"}
          </Button>
        </Stack>
      </Container>
      {viewType === "detail" ? (
        <AdjustmentsDetailView {...props} />
      ) : (
        <Container maxWidth="xl">
          <AdjustmentsRowView {...props} />
        </Container>
      )}
    </Box>
  );
}

export default AdjustmentSection;
