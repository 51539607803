import { createSlice } from "@reduxjs/toolkit";
import { getAgentReportingStats } from "./getActivityStats";
import {getAgentReportingChartsData} from "./getActivityChartData"

const initialState = {
  activityStats: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  chartData:{
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  }
};
const AgentsReportingList = createSlice({
  name: "AgentsReportingList",
  initialState,
  extraReducers: {
    [getAgentReportingStats.pending]: (state) => {
      state.activityStats.isLoading = true;
      state.activityStats.errMsg = null;
      state.activityStats.success = false;
    },
    [getAgentReportingStats.fulfilled]: (state, action) => {
      state.activityStats.success = true;
      state.activityStats.isLoading = false;
      state.activityStats.data = action.payload;
    },
    [getAgentReportingStats.rejected]: (state, action) => {
      state.activityStats.isLoading = false;
      state.activityStats.success = false;
      state.activityStats.errMsg = action.payload;
    },
    [getAgentReportingChartsData.pending]: (state) => {
      state.chartData.isLoading = true;
      state.chartData.errMsg = null;
      state.chartData.success = false;
    },
    [getAgentReportingChartsData.fulfilled]: (state, action) => {
      state.chartData.success = true;
      state.chartData.isLoading = false;
      state.chartData.data = action.payload;
    },
    [getAgentReportingChartsData.rejected]: (state, action) => {
      state.chartData.isLoading = false;
      state.chartData.success = false;
      state.chartData.errMsg = action.payload;
    },
  },
});
export default AgentsReportingList.reducer;
export * from "./getActivityStats";
export * from "./getActivityChartData"
