import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import { terminateTransactionContract } from "../../../redux/transactions/terminateContract";
function TerminateContract(props) {
  let { open, setOpen, classes, id } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();
  const terminateContract = useSelector((state) => state.transactions.TransactionsContract.terminateContract);

  useEffect(() => {
    if (terminateContract.success && !terminateContract.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully terminated  this contract",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
    } else if (!terminateContract.isLoading && terminateContract.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(terminateContract.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [terminateContract.success]);

  const handleCloseContract = () => {
    dispatch(terminateTransactionContract({ id: id }));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-title">
          Terminate Contract
        </DialogTitle>
        <DialogContent sx={{ width: "400px" }}>
          <br />
          Are you sure to terminate this contract?
          <br />
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCloseContract()}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={terminateContract.isLoading}
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "TerminateContractStyle" })(TerminateContract);
