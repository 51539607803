import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  DialogActions,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { FileUploadOutlined } from "@mui/icons-material";

import "react-image-crop/dist/ReactCrop.css";
import { convertToFile, resizeImage } from "./utils";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { getImageUrlWithHeightAndWidth } from "../../../../utils/propertyData";

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
  aspectWidth,
  aspectHeight
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: aspectWidth,
        height: aspectHeight,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "40px 30px!important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
}));

function UploadImage(props) {
  const { onChange, open, handleClose, aspect, aspectWidth, aspectHeight } =
    props;
  const classes = useStyles();

  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [croppedImage, setCroppedImage] = useState(); // To Preview Cropped Image
  const [completedCrop, setCompletedCrop] = useState(); // Crop state after cropping done
  const [loadingImageToCropBox, setLoadingImageToCropBox] = useState(false);

  const { updateConfigureDataLoading, singleCma } = useSelector(
    (state) => state.allCmas
  );

  async function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = await resizeImage(e.target.files[0]);
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
      });
      reader.readAsDataURL(file);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(
        centerAspectCrop(width, height, aspect, aspectWidth, aspectHeight)
      );
    }
  }

  React.useEffect(() => {
    setImgSrc("");
  }, [open]);

  const getBase64FromImageUrl = async (imageUrl) => {
    setLoadingImageToCropBox(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "api/common/get-binary-ignore-cors",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: imageUrl }),
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "fileName.jpg", {
          type: "image/jpeg",
          lastModified: new Date(),
        });
        setCrop(undefined); // Makes crop preview update between images.

        return new Promise((r) => {
          let a = new FileReader();
          a.onload = r;
          a.readAsDataURL(blob);
        }).then((e) => {
          setImgSrc(e.target.result.toString() || "");
          setLoadingImageToCropBox(false);
        });
      });
  };

  return (
    <Dialog
      disablePortal
      sx={{
        top: "50px",
        "& .MuiDialog-container": {
          minHeight: "400px",
          "& .MuiPaper-root": { minWidth: "calc(100vw - 580px) !important" },
        },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogContent
        className={classes.dialogContent}
        sx={{ minWidth: "300px", maxHeight: "800px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex">
            {singleCma?.cma_media_urls &&
              singleCma?.cma_media_urls?.length > 0 && (
                <Box
                  sx={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    "&::-webkit-scrollbar": {
                      width: 5,
                    },
                    "&::-webkit-scrollbar-track": {
                      width: 5,
                      backgroundColor: "#ddd",
                      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      width: 5,
                      backgroundColor: "#0B0909",
                      borderRadius: 1,
                    },
                  }}
                >
                  {singleCma?.cma_media_urls?.map((media) => (
                    <img
                      onClick={async () =>
                        await getBase64FromImageUrl(
                          getImageUrlWithHeightAndWidth(media.MediaUrl)
                        )
                      }
                      className="mediaImage"
                      style={{
                        objectFit: "cover",
                        height: "150px",
                        margin: "5px",
                        width: "150px",
                        borderRadius: "10px",
                        border: "1px solid #ddd",
                      }}
                      src={getImageUrlWithHeightAndWidth(media.MediaUrl)}
                      alt="cmaimage"
                    />
                  ))}
                </Box>
              )}
          </Grid>

          <Grid item xs={12} justifyContent="center" display="flex">
            <Button
              variant="outlined"
              startIcon={<FileUploadOutlined />}
              color="inherit"
              sx={{
                textTransform: "capitalize",
                border: "1px solid rgba(33, 33, 33, 0.4)",
              }}
              component="label"
            >
              Upload File
              <input
                onChange={onSelectFile}
                hidden
                accept=".png,.jpg,.jpeg"
                type="file"
              />
            </Button>
          </Grid>
          <Grid item xs={12} justifyContent="center" display="flex">
            {loadingImageToCropBox && "Setting up the image. Please Wait..."}
            {imgSrc && !loadingImageToCropBox && (
              <ReactCrop
                crop={crop}
                onChange={(cr) => setCrop(cr)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" gap="10px">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          {imgSrc.length > 0 && (
            <LoadingButton
              loading={updateConfigureDataLoading}
              onClick={async () => {
                const { fileUrl, file } = await convertToFile(
                  imgRef.current,
                  completedCrop
                );
                setCroppedImage(fileUrl);
                onChange(file, fileUrl);
              }}
              variant="contained"
            >
              Upload Image
            </LoadingButton>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default UploadImage;
