import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "17px!important",
    color: "#000000DE",
  },
  createButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
  },
  closeButton: {
    color: "#0B0909!important",
  },
}));

function ConfirmModal(props) {
  const classes = useStyles();
  const { open, handleNo, handleYes } = props;

  return (
    <Dialog disablePortal open={open}>
      <DialogTitle className={classes.title}>Confirmation</DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        You have unsaved changes. Are you sure you want to close the form?
      </DialogContent>
      <DialogActions>
        <Button className={classes.closeButton} onClick={handleNo}>
          No
        </Button>
        <Button className={classes.createButton} onClick={handleYes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
