import axios from "axios";
import moment from "moment";

const pluralOrSingular = (count) => (count > 1 ? "s" : "");

const statusColorsMapping = (status) => {
  switch (status) {
    case "Future":
      return "#BA68C8";
    case "Queued":
      return "#77bae0";
    case "On Hold":
      return "#C7C7C7";
    case "Working":
      return "#F9A825";
    case "Review":
      return "#FFD600";
    case "Submitted":
      return "#AED581";
    case "Approved":
      return "#66BB6A";
    case "Completed":
      return "#2E7D32";
    case "Canceled":
      return "#000";
    case "Correction":
      return "#E53935";
    default:
      break;
  }
};

export const deptColorsMapping = (deptName) => {
  switch (deptName) {
    case "Drafting":
      return "rgba(191, 151, 236, 0.46)";
    case "Listings":
      return "rgba(63, 82, 249, 0.25)";
    case "Transactions":
      return "rgba(54, 180, 251, 0.45)";
    case "Finance":
      return "rgba(102, 217, 107, 0.55)";
    case "Graphics":
      return "rgba(223, 164, 13, 0.45)";
    case "Valuation":
      return "rgba(153, 217, 102, 0.4)";
    case "Admin":
      return "rgba(239, 175, 80, 0.4)";
    case "IT-Support":
      return "rgba(204, 27, 165, 0.3)";
    case "Marketing":
      return "rgba(251, 246, 123, 0.79)";
    case "Front-line":
      return "rgba(209, 240, 122, 0.51)";
    default:
      return "#000";
  }
};

export const deptTextColorsMapping = (deptName) => {
  switch (deptName) {
    case "Drafting":
      return "#730D97";
    case "Listings":
      return "#4819CD";
    case "Transactions":
      return "#15445E";
    case "Finance":
      return "#0D490C";
    case "Graphics":
      return "#995D03";
    case "Valuation":
      return "#196F17";
    case "Admin":
      return "#CE5917";
    case "IT-Support":
      return "#AE159F";
    case "Marketing":
      return "#7C7E13";
    case "Front-line":
      return "#567400";
    default:
      return "#000";
  }
};

const statusPriority = {
  correction: 10,
  queued: 9,
  working: 8,
  review: 7,
  approved: 6,
  submitted: 5,
  onhold: 4,
  future: 3,
  completed: 2,
  canceled: 1,
};

export const statusComparator = (v1, v2) => {
  return statusPriority[v2.toLowerCase()] - statusPriority[v1.toLowerCase()];
};

export const capitalize = (s) => s && s[0] && s[0].toUpperCase() + s.slice(1);

export const statusColor = (status) => statusColorsMapping(status);

export const calculateDueIn = (dateTime) => {
  let a = moment(new Date()); //now
  let b = moment(new Date(dateTime));
  let dueIn;

  let mins = b.diff(a, "minutes");
  let hrs = b.diff(a, "hours");
  let days = b.diff(a, "days");

  if (mins > 60) {
    if (hrs > 24) {
      dueIn = `${days} Day${pluralOrSingular(days)}`;
    } else {
      dueIn = `${hrs} Hour${pluralOrSingular(hrs)}`;
    }
  } else if (mins <= 0) {
    dueIn = "Overdue";
  } else {
    dueIn = `${mins} Minute${pluralOrSingular(mins)}`;
  }

  return dueIn;
};

export const calculateDueInShortForm = (dateTime) => {
  let a = moment(new Date()); //now
  let b = moment(new Date(dateTime));
  let dueIn;

  let mins = b.diff(a, "minutes");
  let hrs = b.diff(a, "hours");
  let ds = b.diff(a, "days");

  if (mins > 60) {
    if (hrs > 24) {
      dueIn = `${ds}d`;
    } else {
      dueIn = `${hrs}h`;
    }
  } else if (mins <= 0) {
    let m = Math.abs(mins);
    if (m >= 1 && m <= 60) {
      dueIn = `-${m}m`;
    } else {
      let hours = Math.floor(m / 60);
      if (hours > 24) {
        let days = Math.floor(hours / 24);
        dueIn = `-${days}d`;
      } else if (hours >= 1 && hours <= 24) {
        dueIn = `-${hours}h`;
      }
    }
  } else {
    dueIn = `${mins}m`;
  }

  return dueIn;
};

export const getFieldSize = (size) => (size === "xl" ? 12 : size === "lg" ? 9 : size === "md" ? 6 : 3);

export const getSignedURL = async (deliverableSrc) => {
  const url = `${process.env.REACT_APP_BASE_URL}api/transactions/signed-url?src=${encodeURIComponent(deliverableSrc)}`;

  const resp = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    },
  });
  return resp.data;
};

export const openDeliverable = async (deliverable) => {
  const signedURL = await getSignedURL(deliverable.file);
  window.open(signedURL.data, "_blank");
};
