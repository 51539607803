export const styleSheet = () => ({
  searchHeading: {
    background: "rgba(113, 111, 111, 0.62)",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 10px",
    color: "white",
    fontSize: "14px",
  },
  listingArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7px",
    borderBottom: "1px solid lightgrey",
    cursor: "pointer",
    "&:hover": {
      background: "#F0F0F0",
    },
  },
  peoplesArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7px",
    cursor: "pointer",
    "&:hover": {
      background: "#F0F0F0",
    },
    borderBottom: "1px solid lightgrey",
    "& .MuiCardHeader-avatar": {
      marginRight: "6px !important",
    },
  },
  transactionsArea: {
    display: "flex",
    justifyContent: "space-between",
    padding: "7px",
    borderBottom: "1px solid lightgrey",
    cursor: "pointer",
    "&:hover": {
      background: "#F0F0F0",
    },
  },
  CMAsArea: {
    display: "flex",
    justifyContent: "space-between",
    padding: "7px",
    borderBottom: "1px solid lightgrey",
    cursor: "pointer",
    "&:hover": {
      background: "#F0F0F0",
    },
  },
  listingAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& img": {
      width: "113px",
      height: "79px",
      borderRadius: "8px",
    },
  },
  knowledgeBaseAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    "& img": {
      width: "113px",
      height: "79px",
      borderRadius: "8px",
    },
  },
  listingAreaLeftImg: {
    "& img": {
      border: "1px solid lightgrey",
    },
  },
  listingAreaRight: {
    display: "flex",
    justifyContent: "space-between",
  },
  listingAreaLeftContent: {
    paddingLeft: "10px",
    
  },
  listingAreaLeftContentHead: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000000",
    fontFamily: "AvenirNext",
  },
  KnowledgeBaseAreaLeftContentHead: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000000",
    fontFamily: "AvenirNext",
    marginTop: "7px",
  },
  listingAreaLeftContentAdd: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "500",
    fontFamily: "AvenirNext",
    marginBottom: "5px",
  },
  KnowledgeBaseAreaLeftContentAdd: {
    fontSize: "15px",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "500",
    fontFamily: "AvenirNext",
    marginTop: "5px",
  },
  listingAreaLeftContentPrize: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000000",
    fontFamily: "AvenirNext",
    minWidth: "8ch",
  },
  listingAreaRightTop: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "400",
    fontFamily: "AvenirNext",
    textAlign: "right",
  },
  transactionAreaRightTop: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "400",
    fontFamily: "AvenirNext",
    textAlign: "right",
  },
  listingAreaRightBottom: {
    marginTop: "10px",
    fontFamily: "AvenirNext",
    color: "rgba(0, 0, 0, 0.87)",
  },
  listingAreaRightStatTop: {
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "center",
  },
  cmasAreaRightStatTop: {
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "center",
  },
  closingDateStat: {
    fontSize: "13px",
    fontWeight: "500",
    textAlign: "center",
  },
  listingAreaRightStatBottom: {
    fontSize: "13px",
    fontWeight: "400",
  },
});
