import { createSlice } from "@reduxjs/toolkit";
import { getPowerSearchData } from "./getPowerSearch";
import { getSearchPropertyData } from "./getSearchProperty";

const initialState = {
  elementTarget: null,
  isOpenSearch: false,
  success: false,
  searchData: {},
  isLoading: false,
  isPropertyLoading: false,
  searchPropertyData: [],
  errMsg: null,
};

const powerSearch = createSlice({
  name: "powerSearch",
  initialState,
  reducers: {
    updateSearchParams: (state, action) => {
      if (action.payload.elementTarget) {
        state.elementTarget = action.payload.elementTarget;
      }
      if (action.payload.isOpenSearch) {
        state.isOpenSearch = action.payload.isOpenSearch;
      }
    },
  },
  extraReducers: {
    [getPowerSearchData.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
      state.isOpenSearch = false;
    },
    [getPowerSearchData.fulfilled]: (state, action) => {
      state.success = true;
      state.isLoading = false;
      if (action.meta?.arg?.seeAllFlag) {
        state.searchData[action.meta?.arg?.seeAllFlag] = action.payload[action.meta?.arg?.seeAllFlag] ;
      } else {
        state.searchData = action.payload;
      }
      if (state.elementTarget) {
        state.isOpenSearch = true;
      }
    },
    [getPowerSearchData.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },

    [getSearchPropertyData.pending]: (state) => {
      state.isPropertyLoading = true;
      state.errMsg = null;
      state.isOpenSearch = false;
      state.success = false;
    },
    [getSearchPropertyData.fulfilled]: (state, action) => {
      state.success = true;
      state.isPropertyLoading = false;
      state.searchPropertyData = action.payload;
      if (state.elementTarget) {
        state.isOpenSearch = true;
      }
    },
    [getSearchPropertyData.rejected]: (state, action) => {
      state.isPropertyLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
  },
});
export const { updateSearchParams } = powerSearch.actions;
export default powerSearch.reducer;
export * from "./getPowerSearch";
export * from "./getSearchProperty";
