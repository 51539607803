import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Grid,
  Container,
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  Paper,
  IconButton,
  AvatarGroup,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DataGridPro } from "@mui/x-data-grid-pro";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../../../components/loader/tableLoader";
import Avatar from "../../../components/uiElements/avatar";
import AddGroupModal from "./components/addGroup.js";
import { getLeadRoutingGroups } from "../../../redux/leads/leadRouting/groups";
import { CustomTooltip } from "../../../components/shared/tooltip";
import UpdateGroupModal from "./components/updateGroup.js";
function LeadGroups(props) {
  let { classes } = props;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 25,
    search: "",
    status: "",
  });
  const dispatch = useDispatch();
  const groups = useSelector((item) => item.leads.LeadRoutingGroups.groups);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      width: 260,
      editable: false,
      flex: 1,
      sortable: true,
    },
    {
      field: "distribution_type",
      headerName: "Distribution Type",
      renderCell: (params) => {
        return (
          <>
            <Grid
              sx={{ textTransform: "capitalize" }}
              container
              onClick={() => redirectDetails(params.row.id)}
              className={classes.status}
            >
              {params.row.distribution_type.replace(/_/g, " ")}
            </Grid>
          </>
        );
      },
      sortComparator: (v1, v2) =>
        v1.SerialNumber.localeCompare(v2.SerialNumber),
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      field: "Members",
      headerName: "Members",
      renderCell: ({ row }) => {
        return row.members?.length ? (
          <AvatarGroup
            max={8}
            sx={{
              "& .MuiAvatarGroup-avatar": {
                width: "25px",
                height: "25px",
                fontSize: "14px",
              },
            }}
            variant="rounded"
          >
            {row.members.map((item, index) => (
              <CustomTooltip title={item?.user?.full_name}>
                <Avatar
                  title={item?.user?.full_name}
                  sx={{
                    width: "25px",
                    height: "25px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  variant="rounded"
                  alt={
                    agentRosterList?.list.find(
                      (ite) => ite.id === item?.user?.id
                    )?.full_name
                  }
                  src={
                    agentRosterList?.list.find(
                      (ite) => ite.id === item?.user?.id
                    )?.profile_images?.profile_img
                  }
                >
                  {`${item?.user?.first_name?.slice(
                    0,
                    1
                  )}${item?.user?.last_name?.slice(0, 1)}`}
                </Avatar>
              </CustomTooltip>
            ))}
          </AvatarGroup>
        ) : (
          ""
        );
      },
      width: 320,
      editable: false,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <>
            <Grid container onClick={() => setOpenUpdate(params.row)}>
              <IconButton>
                {" "}
                <BorderColorOutlinedIcon
                  sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                />
              </IconButton>
            </Grid>
          </>
        );
      },
      width: 150,
      editable: false,
      sortable: false,
    },
  ];

  //
  const redirectDetails = (id) => {};
  useEffect(() => {
    if (groups.data?.length === 0) {
      dispatch(getLeadRoutingGroups());
    }
  }, []);
  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setFilter({ ...filter, search }), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);
  return (
    <Container maxWidth="lg">
      <Box className={classes.groupsPageRout}>
        <Box className={classes.groupHeader}>
          <Typography
            className={classes.groupHeading}
            variant="h5"
            gutterBottom
            component="div"
          >
            {groups.data?.length} Groups
          </Typography>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Button
                className={classes.groupAddButton}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => setOpen(true)}
              >
                Add Group
              </Button>
              <TextField
                onChange={(e) => setSearch(e.target.value)}
                type="search"
                size="small"
                variant="outlined"
                placeholder="Search Group"
              />
            </Stack>{" "}
          </Box>
        </Box>
        <Paper className={classes.tableRoot}>
          <DataGridPro
            rows={groups.data}
            loading={groups.isLoading}
            columns={columns}
            rowHeight={56}
            components={{
              LoadingOverlay: TableSkeleton,
            }}
          />
        </Paper>
      </Box>
      <AddGroupModal open={open} setOpen={setOpen} {...props} />
      <UpdateGroupModal setOpen={setOpenUpdate} open={openUpdate} {...props} />
    </Container>
  );
}

export default withStyles(styleSheet, { name: "LeadGroupsStyle" })(LeadGroups);
