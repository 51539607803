import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Stack, Box } from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { useParams } from "react-router-dom";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import { deleteAgentTabs, updateAgentTab } from "../../../../redux/agents/agentRosterDetail/agentTabs";
function ExpenseDetailModal(props) {
  const [actionType, setActionType] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [deletedFileIds, setDeletedFileIds] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [filesPreview, setFilesPreview] = useState([]);
  const [files, setFiles] = useState([]);
  const [errMsg, setErrMsg] = useState({});
  const { id } = useParams();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [expenseDetail, setExpenseDetail] = useState({
    user_id: id,
    users_tabs_type: "add_expense",
    description: "",
    category: "",
    amount: "",
    title: "",
  });
  const dispatch = useDispatch();
  let { open, setOpen, classes, expenseData } = props;
  const deleteTab = useSelector((state) => state.agentRoster.AgentTabs.deleteTab);
  const updateTab = useSelector((state) => state.agentRoster.AgentTabs.updateTab);
  useEffect(() => {
    if (open) {
      setUploadedFiles(expenseData.attachments);
      setExpenseDetail({
        ...expenseDetail,
        amount: expenseData.debit || expenseData.credit,
        ...expenseData,
      });
    }
  }, [open]);

  useEffect(() => {
    if (deleteTab.success && !deleteTab.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully delete the expense",
        errorType: "success",
        isOpen: true,
      });
      setOpenDelete(false);
      setOpen(false);
      setExpenseDetail({
        user_id: id,
        users_tabs_type: "add_expense",
        description: "",
        category: "",
        amount: "",
        title: "",
      });
      setFilesPreview([]);
      setFiles([]);
      setDeletedFileIds([]);
      setUploadedFiles([]);
      setActionType("");
    } else if (!deleteTab.isLoading && deleteTab.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(deleteTab.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deleteTab.isLoading]);

  useEffect(() => {
    if (updateTab.success && !updateTab.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully updated the expense",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setExpenseDetail({
        user_id: id,
        users_tabs_type: "add_expense",
        description: "",
        category: "",
        amount: "",
        title: "",
      });
      setFilesPreview([]);
      setFiles([]);
      setDeletedFileIds([]);
      setUploadedFiles([]);
      setActionType("");
    } else if (!updateTab.isLoading && updateTab.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateTab.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateTab.isLoading]);

  const handleFileChange = (e) => {
    let id = Math.random().toString(36).substring(7);
    setFiles([...files, { id: id, file: e.target.files[0] }]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setFilesPreview([...filesPreview, { id: id, file: reader.result, url: URL.createObjectURL(e.target.files[0]) }]);
    };
    let url = reader.readAsDataURL(e.target.files[0]);
  };
  const handleDelFile = (item) => {
    setFiles(files.filter((ite) => ite.id != item.id));
    setFilesPreview(filesPreview.filter((ite) => ite.id != item.id));
  };
  const handleUploadedFile = (item) => {
    let deletedFiled = [...deletedFileIds];
    deletedFiled.push(item.id);
    setDeletedFileIds(deletedFiled);
    setUploadedFiles(uploadedFiles.filter((ite) => ite.id != item.id));
  };
  const handleUpdateDetail = (field, value) => {
    setExpenseDetail({ ...expenseDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!expenseDetail.category) {
      isFormValid = false;
      errorMsg["category"] = "Please select the category first";
      setErrorAlert({
        errorMsg: "Please select the category first",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!expenseDetail.amount) {
      isFormValid = false;
      errorMsg["amount"] = "Please enter the expense amount";
      setErrorAlert({
        errorMsg: "Please enter the expense amount",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!expenseDetail.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter the expense title";
      setErrorAlert({
        errorMsg: "Please enter the expense title",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!expenseDetail.description) {
      isFormValid = false;
      errorMsg["description"] = "Please enter the expense description";
      setErrorAlert({
        errorMsg: "Please enter the expense description",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleUpdateExpense = () => {
    if (handleValidate()) {
      const Data = new FormData();
      Data.append("users_tabs_type", expenseDetail.users_tabs_type);
      Data.append("user_id", expenseDetail.user_id);
      Data.append("description", expenseDetail.description);
      Data.append("category", expenseDetail.category);
      Data.append("amount", expenseDetail.amount);
      Data.append("title", expenseDetail.title);
      Data.append("deletedFileIds", JSON.stringify(deletedFileIds));
      if (files.length) {
        files.map((item) => {
          Data.append("attachments[]", item.file);
        });
      }
      dispatch(
        updateAgentTab({
          formData: Data,
          id: expenseDetail.id,
          uploadedFiles: uploadedFiles,
          amount: expenseDetail.amount,
          debit: expenseData.debit || expenseData.credit,
        })
      );
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrMsg({});
    setActionType("");
    setDeletedFileIds([]);
    setUploadedFiles([]);
  };
  const handleDeleteExpense = () => {
    dispatch(deleteAgentTabs({ id: expenseData.id }));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" },"& .MuiDialog-container": { mt: "30px" } }}
      >
        <DialogTitle className={classes.appointmentModalHeading} id="alert-dialog-title">
          <Box> Expense Detail</Box>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <ButtonGroups
              id="Manage-button-menu"
              bgColor="#0B0909"
              bgColorHover="#0B0909"
              placement={"bottom"}
              height="36px"
              size="medium"
              color="white"
              minWidth="140px"
              options={[
                {
                  title: "Edit Details",
                  value: "edit",
                  icon: <BorderColorIcon fontSize="small" />,
                },
                { title: "Delete Expense", value: "delete", icon: <DeleteIcon fontSize="small" /> },
              ]}
              onChangeMenu={(value) => {
                setActionType(value);
                if (value === "delete") {
                  setOpenDelete(true);
                }
              }}
              value={actionType}
              placeholder={"Manage"}
              {...props}
            />
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ width: "600px", mt: "25px" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item lg={4} xs={6} sx={{ zIndex: "2", pointerEvents: actionType === "edit" ? "" : "none" }}>
              <ButtonGroups
                id="category-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="38.5px"
                size="medium"
                color="white"
                value={expenseDetail.category}
                options={[
                  { title: "MLS Fees", value: "mls_fee" },
                  { title: "Postage", value: "postage" },
                  { title: "Floor plan", value: "floor_plan" },
                  { title: "Postcards", value: "post_cards" },
                  { title: "Photography", value: "photography" },
                  { title: "Shipping Fee", value: "shipping_fee" },
                  { title: "Miscellaneous", value: "miscellaneous" },
                  { title: "ARA Dues", value: "ara_dues" },
                  { title: "SM Ads", value: "sm_ads" },
                  { title: "MB Applied", value: "mb_applied" },
                  { title: "Other", value: "other" },
                ]}
                onChangeMenu={(value) => handleUpdateDetail("category", value)}
                placeholder="Category"
                {...props}
              />
            </Grid>
            <Grid item lg={5} xs={6} sx={{ zIndex: "1", pointerEvents: actionType === "edit" ? "" : "none" }}>
              <TextField
                value={expenseDetail.amount}
                onChange={(e) => handleUpdateDetail("amount", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"$472.41"}
                variant="outlined"
                size="small"
                error={errMsg.amount}
                helperText={errMsg.amount}
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1", pointerEvents: actionType === "edit" ? "" : "none" }}>
              <TextField
                value={expenseDetail.title}
                onChange={(e) => handleUpdateDetail("title", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter Expense Title Here"}
                variant="outlined"
                size="small"
                error={errMsg.title}
                helperText={errMsg.title}
              />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1", pointerEvents: actionType === "edit" ? "" : "none" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Description
              </InputLabel>
              <TextField
                value={expenseDetail.description}
                onChange={(e) => handleUpdateDetail("description", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={6}
                placeholder="user can enter unique notes about the particular expense"
                variant="outlined"
                size="small"
                error={errMsg.description}
                helperText={errMsg.description}
              />
            </Grid>
            {actionType === "edit" ? (
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  startIcon={<FileUploadRoundedIcon />}
                  color="inherit"
                  sx={{ textTransform: "capitalize", border: "1px solid rgba(33, 33, 33, 0.4)" }}
                  component="label"
                >
                  Attach Files
                  <input onChange={handleFileChange} hidden accept=".png,.jpg,.jpeg, .pdf" multiple type="file" />
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={actionType === "edit" ? 8 : 12}>
              {uploadedFiles?.length ? (
                <Stack direction={"row"} spacing={2} justifyContent={actionType === "edit" ? "flex-end" : "flex-start"} flexWrap="wrap-reverse">
                  {uploadedFiles.map((item, index) => (
                    <Box sx={{ position: "relative", mb: "5px", border: "1px solid lightgrey" }}>
                      {item.file_path.slice(item.file_path?.length - 3) === "pdf" || item.file_path.slice(item.file_path?.length - 3) === "PDF" ? (
                        <a href={item.file_path} rel="noreferrer" target={"_blank"} style={{ display: "block" }}>
                          {" "}
                          <iframe style={{ pointerEvents: "none" }} width="120" height="120" src={item.file_path}>
                            {" "}
                          </iframe>
                        </a>
                      ) : (
                        <a href={item.file_path} rel="noreferrer" target={"_blank"}>
                          {" "}
                          <img
                            style={{ width: "120px", height: "120px", objectFit: "cover" }}
                            src={item.file_path}
                            alt={`expense document ${index}`}
                          />
                        </a>
                      )}
                      {actionType === "edit" ? (
                        <HighlightOffRoundedIcon
                          onClick={() => handleUploadedFile(item)}
                          sx={{ position: "absolute", top: "-10px", right: "-10px", cursor: "pointer" }}
                        />
                      ) : null}
                    </Box>
                  ))}
                </Stack>
              ) : null}
              {filesPreview.length ? (
                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} flexWrap="wrap-reverse">
                  {filesPreview.map((item, index) => (
                    <Box sx={{ position: "relative", mb: "5px" }}>
                      {item.file.slice(17, 20) === "pdf" ? (
                        <a href={item.url} rel="noreferrer" target={"_blank"} style={{ display: "block" }}>
                          <iframe style={{ pointerEvents: "none" }} width="120" height="120" src={item.file}>
                            {" "}
                          </iframe>
                        </a>
                      ) : (
                        <a href={item.url} rel="noreferrer" target={"_blank"}>
                          {" "}
                          <img style={{ width: "120px", height: "120px", objectFit: "cover" }} src={item.file} alt={`expense document ${index}`} />
                        </a>
                      )}

                      <HighlightOffRoundedIcon
                        onClick={() => handleDelFile(item)}
                        sx={{ position: "absolute", top: "-10px", right: "-10px", cursor: "pointer" }}
                      />
                    </Box>
                  ))}
                </Stack>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Close
          </Button>
          {actionType === "edit" ? (
            <LoadingButton
              onClick={() => handleUpdateExpense()}
              classes={{ root: classes.commisionSelectionButTon }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={updateTab.isLoading}
            >
              Update
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Dialog
        disablePortal
        
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" } }}
      >
        <DialogContent sx={{ width: "370px", textAlign: "center", mt: "20px" }}>
          Are you sure you want to delete the Expense "{expenseDetail.title}" ?
        </DialogContent>
        <DialogActions sx={{ mb: "20px", justifyContent: "center" }}>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            classes={{ root: classes.deleteButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={deleteTab.isLoading}
            onClick={handleDeleteExpense}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "ExpenseDetailModalStyle" })(ExpenseDetailModal);
