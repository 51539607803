import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  createPost: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
  update: {
    isLoading: false,
    errMsg: null,
    success: false,
  },
  loading: false,
  errMsg: null,
  postData: [],
  totalCount: 0,
};

export const getAllPostData = createAsyncThunk(
  "KnowledgeBasePosts/getAllPostData",
  async (data, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
        params: data,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const createNewPost = createAsyncThunk(
  "KnowledgeBasePosts/createNewPost",
  async (data, thunkAPI) => {
    let { handleSuccess, schema, handleError } = data;
    try {
      const {
        title,
        description,
        thumbnail,
        file,
        visibility,
        category_id,
        author_id,
        audience,
        type,
        document,
      } = schema;
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", description);
      formData.append("category_id", category_id);
      formData.append("visibility", visibility);
      formData.append("thumbnail", thumbnail);
      formData.append("author_id", author_id);
      formData.append("audience", audience);
      formData.append("type", type);
      if (type === "video") {
        formData.append("file", file);
      }
      if (type === "document") {
        formData.append("document", document);
      }
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post`;
      const resp = await axios.post(url, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (handleSuccess && resp?.data?.data) {
        handleSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updatePost = createAsyncThunk(
  "KnowledgeBasePosts/updatePost",
  async (data, thunkAPI) => {
    const {
      title,
      description,
      thumbnail,
      file,
      visibility,
      category_id,
      postId,
      handleUpdateSuccess,
      handleError,
      author_id,
      audience,
      type,
      document,
    } = data;
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", description);
      formData.append("category_id", category_id);
      formData.append("visibility", visibility);
      formData.append("audience", audience);
      formData.append("type", type);
      if (thumbnail) {
        formData.append("thumbnail", thumbnail);
      }
      if (type === "video" && file) {
        formData.append("file", file);
      }
      if (type === "document" && document) {
        formData.append("document", document);
      }

      formData.append("author_id", author_id);
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post/${postId}`;
      const resp = await axios.put(url, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (handleUpdateSuccess && resp?.data?.data) {
        handleUpdateSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
const KnowledgeBasePosts = createSlice({
  name: "KnowledgeBasePosts",
  initialState,
  extraReducers: {
    [getAllPostData.pending]: (state) => {
      state.loading = true;
    },
    [getAllPostData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.meta.arg.reset) {
        state.postData = [...action.payload.data.posts];
      } else {
        state.postData = [...state.postData.concat(action.payload.data.posts)];
      }
      state.totalCount = action.payload.data.count;
    },
    [getAllPostData.rejected]: (state, action) => {
      state.isLoading = false;

      state.errMsg = action.payload;
    },
    [createNewPost.pending]: (state) => {
      state.createPost.isLoading = true;
      state.createPost.errMsg = null;
    },
    [createNewPost.fulfilled]: (state, action) => {
      state.createPost.isLoading = false;
      state.createPost.success = true;
      if (action.payload) {
        let newPosts = [...state.postData];
        newPosts.unshift(action.payload);
        state.postData = newPosts;
      }
    },
    [createNewPost.rejected]: (state, action) => {
      state.createPost.isLoading = false;
      state.createPost.errMsg = action.payload;
    },
    // update post code will be here
    [updatePost.pending]: (state) => {
      state.update.isLoading = true;
      state.update.errMsg = null;
    },
    [updatePost.fulfilled]: (state, action) => {
      state.update.isLoading = false;
      state.update.success = true;
      if (action.payload) {
        let newPosts = [...state.postData];
        let index = newPosts.findIndex(
          (item) => item.id === action.payload?.id
        );
        newPosts[index] = { ...newPosts[index], ...action.payload };
        state.postData = newPosts;
      }
    },
    [updatePost.rejected]: (state, action) => {
      state.update.isLoading = false;
      state.update.errMsg = action.payload;
    },
  },
});

export default KnowledgeBasePosts.reducer;
