export const styleSheet = () => ({
  root: {
    display: "block",
    width: '100%',
  },
  inputRoot: {
    padding: "12px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid #b7b7b7",
    width: "100%",
    "& input ": {
      padding: "15px 15px",
    },
  },
  labelRoot: {
    fontSize: "14px",
    display: "block",
    marginBottom: "5px",
  },
});
