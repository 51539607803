import React, { useState ,useEffect} from "react";
import { Grid, Paper } from "@mui/material";
import TransactionsDetailImportantNotes from "../details/importantNotes";
import TransactionsDetailImportantPeoples from "../details/importantPeople";
import TransactionsDetailDates from "../details/dates";
import TransactionsDetailMoney from "../details/earnestMoney";
import TransactionHeaderCard from "../cards/TransactionHeaderCard";
import TransactionsDetailTaskCard from "../details/tasks";
import { AgentRecruitingTabs } from "../../../tools/common/Tabs/index.jsx";
import AgentRecruitingTimeline from "../../../tools/common/Timeline";
import {  selectedEmailList } from "../../../../redux/agents/getAgentProfile";
import { useSelector,useDispatch } from "react-redux";
function TransactionsDetailHud(props) {
  const dispatch=useDispatch()
  const { list: usersList } = useSelector((item) => item.users.storedListOfUsers);
  useEffect(()=>{
    dispatch(selectedEmailList([]));
  },[])
  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={8}>
        <TransactionHeaderCard ContractData={props.ContractData} hideSqFt hideBed hideBath showClosingDate showInitialFull={12} />
        <TransactionsDetailImportantNotes {...props} />
        <Paper sx={{ padding: "10px" }}>
          <AgentRecruitingTabs agentProfile={props.ContractData?.agent} {...props} />
        </Paper>
        <Paper sx={{ padding: "10px", mt: "15px" }}>
          <AgentRecruitingTimeline usersList={usersList} agentProfile={props.ContractData?.agent} />
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <TransactionsDetailImportantPeoples allowEmailClick md={12} {...props} />
        <TransactionsDetailTaskCard {...props} />
        <TransactionsDetailDates {...props} />
        <TransactionsDetailMoney {...props} />
      </Grid>
    </Grid>
  );
}
export default TransactionsDetailHud;
