import { createSlice } from "@reduxjs/toolkit";
import { addAgentAccountPasswords } from "./addPassword";
import { getUserPasswords } from "./getUserPassword";
import { deleteAccountPassword } from "./deleteAccountPassword";
import { updateAccountPassword } from "./updateAccountPassword";

const initialState = {
  addPassword: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  passwordList: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  deletePassword: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  updatePassword: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
};
const AgentPasswords = createSlice({
  name: "AgentPasswords",
  initialState,
  extraReducers: {
    [addAgentAccountPasswords.pending]: (state) => {
      state.addPassword.isLoading = true;
      state.addPassword.errMsg = null;
      state.addPassword.success = false;
    },
    [addAgentAccountPasswords.fulfilled]: (state, action) => {
      state.addPassword.isLoading = false;
      state.addPassword.data = action.payload;
      state.addPassword.success = true;
      let userPasswordNew = state.passwordList.data?.userPasswords?.length ? [...state.passwordList.data.userPasswords] : [];
      userPasswordNew.unshift(action.payload.userPassword);
      state.passwordList.data.userPasswords = userPasswordNew;
    },
    [addAgentAccountPasswords.rejected]: (state, action) => {
      state.addPassword.isLoading = false;
      state.addPassword.success = false;
      state.addPassword.errMsg = action.payload;
    },
    [getUserPasswords.pending]: (state) => {
      state.passwordList.isLoading = true;
      state.passwordList.errMsg = null;
      state.passwordList.success = false;
    },
    [getUserPasswords.fulfilled]: (state, action) => {
      state.passwordList.isLoading = false;
      state.passwordList.success = true;
      if (action.payload?.pageNumber > 1) {
        state.passwordList.data.userPasswords = state.passwordList.data?.userPasswords.concat(action.payload.userPasswords);
      } else {
        state.passwordList.data = action.payload;
      }
    },
    [getUserPasswords.rejected]: (state, action) => {
      state.passwordList.isLoading = false;
      state.passwordList.success = false;
      state.passwordList.errMsg = action.payload;
    },
    // delete agent tab
    [deleteAccountPassword.pending]: (state) => {
      state.deletePassword.isLoading = true;
      state.deletePassword.errMsg = null;
      state.deletePassword.success = false;
    },
    [deleteAccountPassword.fulfilled]: (state, action) => {
      state.deletePassword.isLoading = false;
      state.deletePassword.success = true;
      state.deletePassword.data = action.payload;
      let userPasswordNew = state.passwordList.data?.userPasswords?.length ? [...state.passwordList.data.userPasswords] : [];
      let index = userPasswordNew.findIndex((item) => item.id === action.payload.deletedId);
      userPasswordNew.splice(index, 1);
      state.passwordList.data.userPasswords = userPasswordNew;
    },
    [deleteAccountPassword.rejected]: (state, action) => {
      state.deletePassword.isLoading = false;
      state.deletePassword.success = false;
      state.deletePassword.errMsg = action.payload;
    },
    // update agent tab
    [updateAccountPassword.pending]: (state) => {
      state.updatePassword.isLoading = true;
      state.updatePassword.errMsg = null;
      state.updatePassword.success = false;
    },
    [updateAccountPassword.fulfilled]: (state, action) => {
      state.updatePassword.isLoading = false;
      state.updatePassword.success = true;
      state.updatePassword.data = action.payload;
      let userPasswordNew = state.passwordList.data?.userPasswords?.length ? [...state.passwordList.data.userPasswords] : [];
      let index = userPasswordNew.findIndex((item) => item.id === action.payload.userPassword?.id);
      userPasswordNew[index] = action.payload.userPassword;
      state.passwordList.data.userPasswords = userPasswordNew;
    },
    [updateAccountPassword.rejected]: (state, action) => {
      state.updatePassword.isLoading = false;
      state.updatePassword.success = false;
      state.updatePassword.errMsg = action.payload;
    },
  },
});
export default AgentPasswords.reducer;
export * from "./addPassword";
export * from "./getUserPassword";
export * from "./deleteAccountPassword";
export * from "./updateAccountPassword";
