import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField, Typography } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import ResponseAlert from "../../../components/responseAlert";
import { updateData } from "../../../utils/updateData";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
function UpdateListing(props) {
  let { open, setOpen, classes, id } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState("");
  const [executedDate, setExecutedDate] = useState(new Date());
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleScheduleActiveList = async () => {
    let obj = {
      executed_on: moment.utc(moment(executedDate).utc()).format(),
    };
    const result = await updateData(`${process.env.REACT_APP_BASE_URL}api/transactions/listing/schedule-list-to-active/${id}`, obj);
    if (result.status === 200) {
      setErrorAlert({
        errorMsg: "You have successfully scheduled the listing active date",
        errorType: "success",
        isOpen: true,
      });
      setLoading(false);
      setOpen(false);
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };
  const handleActiveList = async () => {
    let obj = {
      status: "active",
    };
    const result = await updateData(`${process.env.REACT_APP_BASE_URL}api/transactions/listing/${id}`, obj);
    if (result.status === 200) {
      setErrorAlert({
        errorMsg: "You have successfully activated the list",
        errorType: "success",
        isOpen: true,
      });
      setLoading(false);
      setOpen(false);
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };
  const handleSubmit = () => {
    if (value) {
      setLoading(true);
      if (value === "Coming soon") {
        handleScheduleActiveList();
      } else {
        handleActiveList();
      }
    } else {
      setErrorAlert({
        errorMsg: "Please select the Update type coming soon or active",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-title">
          Update Listing
        </DialogTitle>
        <DialogContent sx={{ width: "350px" }}>
          <Typography sx={{ mb: "10px" }}>Is this listing is coming soon or active?</Typography>
          <FormControl>
            <RadioGroup value={value} onChange={handleChange} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
              <FormControlLabel value="Coming soon" control={<Radio />} label="Coming soon" />
              <FormControlLabel value="Active" control={<Radio />} label="Active" />
            </RadioGroup>
          </FormControl>
          <br />
          <br />
          {value === "Coming soon" ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Schedule Active date"
                value={executedDate}
                onChange={(newValue) => setExecutedDate(newValue)}
                renderInput={(params) => <TextField sx={{ mb: "20px" }} size="small" fullWidth {...params} />}
              />
            </LocalizationProvider>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => {
              setOpen(false);
              setLoading(false);
            }}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSubmit()}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={loading}
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "UpdateListingStyle" })(UpdateListing);
