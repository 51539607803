export const styleSheet = () => ({
        "@global": {
          ".method-main-loader": {
            "height": "600px",
            "display": "flex",
            "justifyContent": "center",
            "alignItems": "center"
          },
          ".method-main-loader .blob": {
            "background": "#E5EAEE",
            "borderRadius": "50%",
            "boxShadow": "0 0 0 0 black",
            "margin": "10px",
            "height": "116px",
            "width": "116px",
            "animation": "pulse 2s infinite"
          },
          "@keyframes pulse": {
            "0%": {
              "boxShadow": "0 0 0 0 #181C32"
            },
            "70%": {
              "boxShadow": "0 0 0 100px rgba(0, 0, 0, 0)"
            },
            "100%": {
              "boxShadow": "0 0 0 0 rgba(0, 0, 0, 0)"
            }
          },
          ".method-main-loader .setImage-dfd": {
            "display": "flex",
            "justifyContent": "center",
            "alignItems": "center"
          },
          ".method-main-loader .setImage-dfd img": {
            "width": "80px"
          }
      }
  })