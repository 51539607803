import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSmartList = createAsyncThunk("smartlist/getSmartList", async (data, thunkAPI) => {
  let pageNumber = 1;
  let pageSize = 100;

  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/smartlists?type=${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { data: resp.data?.smartlists, metaData: resp.data?._metadata };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const getSingleSmartList = createAsyncThunk("smartlist/getSingleSmartList", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/smartlists/${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { data: resp.data?.smartlist };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const createSmartList = createAsyncThunk("smartlist/createSmartList", async (data, thunkAPI) => {
  try {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}api/smartlists?type=${data.type}`,
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data)
    };

    const resp = await axios(config);
    setTimeout(() => {
      thunkAPI.dispatch(getSmartList(data.type));
    }, 500);
    return resp?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const updateSmartList = createAsyncThunk("smartlist/updateSmartList", async (data, thunkAPI) => {
  console.log(data);
  try {
    const { id, smartListData } = data;

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}api/smartlists/${id}`,
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      data: smartListData
    };

    const resp = await axios(config);
    setTimeout(() => {
      thunkAPI.dispatch(getSmartList(smartListData.type));
    }, 500);
    return resp?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const deleteSmartList = createAsyncThunk("smartlist/deleteSmartList", async (data, thunkAPI) => {
  try {
    const { id, type } = data;
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}api/smartlists/${id}`,
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      }
    };

    const resp = await axios(config);
    setTimeout(() => {
      thunkAPI.dispatch(getSmartList(type));
    }, 500);
    return resp?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const initialState = {
  success: false,
  smartlists: [],
  smartlist: [],
  isLoading: false,
  singleSmartListLoading: false,
  errMsg: null,
};

const smartlists = createSlice({
  name: "smartlists",
  initialState,
  extraReducers: {
    [getSmartList.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getSmartList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.smartlists = action.payload.data
    },
    [getSmartList.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },

    [getSingleSmartList.pending]: (state) => {
      state.singleSmartListLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getSingleSmartList.fulfilled]: (state, action) => {
      state.singleSmartListLoading = false;
      state.success = true;
      state.smartlist = action.payload.data
    },
    [getSingleSmartList.rejected]: (state, action) => {
      state.singleSmartListLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
  },
});

export default smartlists.reducer;
