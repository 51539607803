import React, { useState, Fragment, useEffect } from "react";
import { Box, Grid, CircularProgress, Backdrop } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import AgentList from "./agentList.js";
import ChatIcon from "@mui/icons-material/Chat";
import SelectedLeadHeader from "./selectedLeadHeader";
import LeadsChatHistoryArea from "./leadsChatHistoryArea";
import { useSelector, useDispatch } from "react-redux";
import UserDetail from "./userDetail.js";
import {
  getRequestDetail,
  updateRequestTicket,
  getAgentRequests,
} from "../../redux/agentRequests";
import ResponseAlert from "../../components/responseAlert";
import { useLocation, useParams } from "react-router-dom";

function AgentRequestTicketManagementRoot(props) {
  let { classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  let { id } = useParams();
  const location = useLocation();
  const { list: usersList } = useSelector(
    (item) => item.users.storedListOfUsers
  );
  const adminAuth = useSelector((state) => state.adminAuth);
  const updateTicket = useSelector((state) => state.AgentRequests.updateTicket);
  const { data: requestDetail } = useSelector(
    (state) => state.AgentRequests.requestDetail
  );

  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (id && requestDetail?.id) {
      let obj = {
        userId:
          requestDetail.status === "assigned" ? adminAuth?.adminDetail?.id : "",
        teamId:
          requestDetail.status === "open"
            ? adminAuth?.adminDetail?.team?.id ||
              adminAuth?.adminDetail?.team?.teamId
            : "",
        status: requestDetail.status,
        pageNumber: 1,
      };
      dispatch(getAgentRequests(obj));
    }
  }, [id, requestDetail?.id]);

  useEffect(() => {
    setSelectedUser();
  }, [location.pathname]);

  useEffect(() => {
    if (id || location?.state?.ticket_id) {
      handleClick({ id: id || location?.state?.ticket_id });
    }
  }, [location?.state?.ticket_id]);

  const handleClick = (data) => {
    setSelectedUser(data);
    if (selectedUser?.id !== data.id) {
      dispatch(getRequestDetail({ ticketId: data.id }));
    }
  };

  const handleSuccess = () => {
    // setErrorAlert({
    //   errorMsg: "You have successfully assigned to new user",
    //   errorType: "success",
    //   isOpen: true,
    // });
  };

  const handleCloseSuccess = () => {
    // setErrorAlert({
    //   errorMsg: "You have successfully closed the ticket",
    //   errorType: "success",
    //   isOpen: true,
    // });
    setSelectedUser(null);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleAssign = (data) => {
    let obj = {
      handleSuccess,
      handleError,
      ticketId: selectedUser?.id,
      schema: { assignedTo: data.id },
    };
    dispatch(updateRequestTicket(obj));
  };

  const handleClose = () => {
    let obj = {
      ticketId: selectedUser?.id,
      schema: { status: "closed" },
      handleSuccess: handleCloseSuccess,
    };
    dispatch(updateRequestTicket(obj));
  };
  return (
    <Fragment>
      <Box className={classes.pageRoot}>
        <Grid container spacing={1}>
          <Grid item sm={3} lg={3} xl={3.6}>
            <AgentList
              handleClick={handleClick}
              selectedUser={selectedUser}
              {...props}
            />
          </Grid>
          <Grid
            item
            sm={selectedUser ? 5.4 : 8.4}
            lg={selectedUser ? 5.4 : 8.4}
            // sx={{ paddingLeft: "11.5px !important" }}
          >
            {selectedUser ? (
              <div className={classes.mainContent}>
                <SelectedLeadHeader
                  list={[]}
                  usersList={usersList}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  handleAssign={handleAssign}
                  handleClose={handleClose}
                  {...props}
                />

                <LeadsChatHistoryArea
                  handleClose={handleClose}
                  selectedUser={selectedUser}
                  {...props}
                />
              </div>
            ) : (
              <Box className={classes.mainContent}>
                <Box className={classes.chatNotStartedArea}>
                  <Box className={classes.chatNotStartedBox}>
                    <center>
                      <ChatIcon
                        sx={{ fontSize: "150px", color: "lightgray" }}
                      />
                      <br />
                      Start commenting by clicking on any agent request
                    </center>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
          {selectedUser ? (
            <Grid item sm={3.6} lg={3.6} xl={3}>
              {selectedUser && (
                <UserDetail
                  classes={classes}
                  selectedUser={selectedUser}
                  usersList={usersList}
                  {...props}
                />
              )}
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Backdrop color="inherit" invisible={true} open={updateTicket.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
export default withStyles(styleSheet, {
  name: "AgentRequestTicketManagementRootStyle",
})(AgentRequestTicketManagementRoot);
