import React, { useState } from "react";
import { Alert, Box, Snackbar, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../../../components/uiElements/button";
import Input from "../../../../../components/uiElements/input/Input";
import { saveCallLog } from "../../../../../redux/leads/callLog";

export const LogCall = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const [logCallText, setLogCallText] = useState("");
  const [open, setOpen] = useState({ state: false, type: "", desc: "" });
  const [loading, setLoading] = useState(false);
  const leadProfile = useSelector((item) => item.leadProfile);
  const handleSuccess = () => {
    setLoading(false);
    setOpen({
      state: true,
      type: "success",
      desc: "You have added call log successfully!",
    });
  };
  const handleError = (error) => {
    setLoading(false);
    setOpen({
      state: true,
      type: "error",
      desc: JSON.stringify(error),
    });
  };
  const handleUpdateText = (text) => {
    createNoteFunc(text);
  };
  const createNoteFunc = (text) => {
    const schema = {
      personId: param?.id,
      phone: leadProfile?.list?.data?.phones[0]?.value || "123-123-1232",
      isIncoming: false,
      fromNumber: `${process.env.REACT_APP_GLOBAL_TWILIO_NUMBER}`,
      note: logCallText || text,
    };
    if (logCallText || text) {
      setLoading(true);
      setLogCallText("");
      dispatch(saveCallLog({ schema, handleSuccess, handleError }));
    } else {
      setOpen({
        state: true,
        type: "error",
        desc: "You can't create empty call log!",
      });
    }
  };

  const onChange = (e) => {
    setLogCallText(e.target.value);
  };
  const handleClose = (e) => {
    setOpen({ state: false, type: "", desc: "" });
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiInputBase-root": {
            paddingBottom: `42px !important`,
          },
        }}
      >
        <Input
          id="outlined-textarea"
          value={logCallText}
          placeholder="Label"
          multiline
          fullWidth
          rows={6}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{
                  position: "absolute",
                  bottom: "23px",
                  left: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "0px 10px",
                }}
                position="end"
              >
                <Button
                  sx={{
                    borderRadius: "20px",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "1px 10px",
                  }}
                  variant="outlined"
                  size="small"
                  color="inherit"
                  onClick={() => handleUpdateText("No Answer")}
                >
                  No Answer
                </Button>
                <Button
                  sx={{
                    borderRadius: "20px",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "1px 10px",
                  }}
                  variant="outlined"
                  size="small"
                  color="inherit"
                  onClick={() => handleUpdateText("Left Voicemail")}
                >
                  Left Voicemail
                </Button>
                <Button
                  sx={{
                    borderRadius: "20px",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "1px 10px",
                  }}
                  variant="outlined"
                  size="small"
                  color="inherit"
                  onClick={() => handleUpdateText("Bad Number")}
                >
                  Bad Number
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        <Button onClick={createNoteFunc} variant="contained">
          {loading ? "Wait..." : "Log call"}
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open?.state}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={open?.type}
          sx={{ width: "100%" }}
        >
          {open?.desc}
        </Alert>
      </Snackbar>
    </>
  );
};
