import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  MenuList,
  Popover,
  Tab,
  Tabs,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AgentRecruitsTable from "../common/Table/AgentRecruitsTable";
import { styleSheet } from "./style.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAgentRecruitingList,
  modalSubmitSuccess,
  setRecruitmentFilter,
} from "../../../redux/agents/getAgentList";
import { getAgentRecruitingAllTags } from "../../../redux/agents/agentRecruitment/getAgentsRecruitmentAllTags";
import { getAgentRecruitingStages } from "../../../redux/agents/agentRecruitment/getAgentsRecruitmentStages";
import { getAgentRecruitingCities } from "../../../redux/agents/agentRecruitment/getAgentsRecruitmentCities";
import { getAgentRecruitingZipcodes } from "../../../redux/agents/agentRecruitment/getAgentsRecruitmentZipcodes";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TabContext } from "@mui/lab";
import { SaveSmartListModal } from "../common/Modal/SaveSmartListModal";
import { useState } from "react";
import { getAgentRecruitmentSmartList } from "../../../redux/agents/agentRecruitment/getAllSmartLists";
import { AddCircleRounded } from "@mui/icons-material";
import AddRecruit from "./AddRecruit";
import { getMarkets } from "../../../redux/agents/addRecruitAgent";
import TasksButton from "./TasksButton";
import { getAgentsRecruitmentAllOffices } from "../../../redux/agents/agentRecruitment/getAgentsRecruitmentAllOffices";

const AgentRecruits = ({ classes }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [saveSmartListModal, setSaveSmartList] = useState(false);
  const [moreItemsVal, setMoreItemsVal] = useState("More Items");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSmartList, setSelectedSmartList] = useState(null);
  const [openRecruitModal, setOpenAddRecruitModal] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const {
    agentRecruitmentTagsList: { tagsList, loading: tagsListLoading },
    agentsRecruitmentStages: { stagesList, loading: stagesListLoading },
    agentRecruitmentOfficesList: { officesList, loading: officesListLoading },
    agentsRecruitmentZipcodes: {
      zipCodesListFMLS,
      zipCodesListGAMLS,
      loading: zipCodeLoading,
      zipCodesListStellar,
      zipCodesListMiamire,
      zipCodesListCarolina,
    },
    agentsRecruitmentCities: {
      citiesListGAMLS,
      citiesListFMLS,
      loading: citiesLoading,
      citiesListStellar,
      citiesListMiamire,
      citiesListCarolina,
    },
  } = useSelector((item) => item.agentRecruitment);
  const data = useSelector((item) => item.agentList);
  const { storedListOfUsers } = useSelector((item) => item.users);
  const {
    agentList,
    filters,
    sorting,
    columnsOrder,
    totalAgentsCount,
    deleteList,
    smartList,
    columnsVisibility,
  } = data;
  const {
    list: usersList,
    loading: usersLoading,
    usersObj,
  } = storedListOfUsers;

  useEffect(() => {
    dispatch(getAgentRecruitmentSmartList({}));
  }, [deleteList.success]);

  useEffect(() => {
    (async () => {
      await dispatch(getMarkets({}));

      if (tagsList.length <= 0) {
        await dispatch(getAgentRecruitingAllTags());
      }
      if (officesList.length <= 0) {
        await dispatch(getAgentsRecruitmentAllOffices());
      }
      if (stagesList.length <= 0) {
        await dispatch(getAgentRecruitingStages());
      }
      if (citiesListGAMLS.length <= 0) {
        await dispatch(getAgentRecruitingCities("gamls"));
      }
      if (citiesListFMLS.length <= 0) {
        await dispatch(getAgentRecruitingCities("fmls"));
      }
      if (citiesListStellar.length <= 0) {
        await dispatch(getAgentRecruitingCities("stellar"));
      }
      if (citiesListMiamire.length <= 0) {
        await dispatch(getAgentRecruitingCities("miamire"));
      }
      if (citiesListCarolina.length <= 0) {
        await dispatch(getAgentRecruitingCities("charlotte"));
      }
      if (zipCodesListGAMLS.length <= 0) {
        await dispatch(getAgentRecruitingZipcodes("gamls"));
      }
      if (zipCodesListFMLS.length <= 0) {
        await dispatch(getAgentRecruitingZipcodes("fmls"));
      }
      if (zipCodesListStellar.length <= 0) {
        await dispatch(getAgentRecruitingZipcodes("stellar"));
      }
      if (zipCodesListMiamire.length <= 0) {
        await dispatch(getAgentRecruitingZipcodes("miamire"));
      }
      if (zipCodesListCarolina.length <= 0) {
        await dispatch(getAgentRecruitingZipcodes("charlotte"));
      }
    })();
  }, []);

  const closeModal = (success) => {
    if (success) {
      dispatch(modalSubmitSuccess());
    }
    setSaveSmartList(false);
  };

  const handlePopoverClose = (action) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!data.loading) {
      if (id) {
        let selectedList = smartList.find((ls) => ls.id == id);
        if (selectedList) {
          setSelectedSmartList(selectedList);
          dispatch(setRecruitmentFilter(JSON.parse(selectedList.condition)));
          dispatch(
            getAgentRecruitingList({
              filters: selectedList.condition,
              sortBy: selectedList.sortBy,
              sortOrder: selectedList.sortOrder,
              pageSize: 25,
              pageNumber: 1,
              resetList: true,
            })
          );
        }
      } else {
        setSelectedSmartList(null);
        if (location?.state?.fromOtherTab) {
          dispatch(setRecruitmentFilter([]));
          dispatch(
            getAgentRecruitingList({
              ...sorting,
              filters: [],
              pageSize: 25,
              pageNumber: 1,
              resetList: true,
            })
          );
        } else {
          dispatch(
            getAgentRecruitingList({
              ...sorting,
              filters: JSON.stringify(filters),
              pageSize: 25,
              pageNumber: 1,
              resetList: true,
              search: searchValue,
            })
          );
        }
      }
    }
  }, [id, smartList, openRecruitModal === false, searchValue]);

  useEffect(() => {
    if (searchText !== false) {
      const timeOutId = setTimeout(() => setSearchValue(searchText), 2000);
      return () => clearTimeout(timeOutId);
    }
  }, [searchText]);
  return (
    <Box>
      <Card
        variant="outlined"
        sx={{ position: "sticky", top: "64px", zIndex: "100" }}
      >
        <Box className={classes.TopBarArea}>
          <Box className={classes.TopBarAreaLeft}>
            <Box>
              <TabContext>
                <Box className={classes.RecruitingTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{
                      sx: { background: "black", color: "black" },
                    }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                    onChange={(e) => {
                      if (moreItemsVal !== "More Items") {
                        setMoreItemsVal("More Items");
                      }
                    }}
                  >
                    <Tab
                      label="All People"
                      value={`/tools/recruiting`}
                      to={`/tools/recruiting`}
                      state={{ fromOtherTab: true }}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      disabled={data.loading}
                    />
                    {smartList.slice(0, 3).map((ls, i) => {
                      return (
                        <Tab
                          label={`${ls.name} (${ls.count})`}
                          value={`/tools/recruiting/list/` + ls.id}
                          to={`/tools/recruiting/list/` + ls.id}
                          component={Link}
                          sx={{ padding: "0px 10px" }}
                          key={i}
                        />
                      );
                    })}
                    {smartList.slice(3).length ? (
                      <Tab
                        label={moreItemsVal}
                        value={`/tools/recruiting/list/more${
                          id ? "/" + id : ""
                        }`}
                        to={`/tools/recruiting/list/more${id ? "/" + id : ""}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.target);
                        }}
                        icon={
                          <KeyboardArrowDownIcon style={{ marginBottom: 0 }} />
                        }
                        sx={{
                          padding: "0px 10px",
                          flexDirection: "row-reverse",
                        }}
                        disabled={data.loading}
                      />
                    ) : null}
                  </Tabs>
                </Box>
              </TabContext>
            </Box>
          </Box>
          <Box className={classes.TopRecruitmentAreaLeft}>
            {openRecruitModal && (
              <AddRecruit
                open={openRecruitModal}
                setOpen={setOpenAddRecruitModal}
              />
            )}
            <TasksButton />

            <Button
              className={classes.addListButton}
              variant="contained"
              startIcon={<AddCircleRounded />}
              onClick={(e) => {
                setOpenAddRecruitModal(true);
              }}
            >
              Add Recruit
            </Button>
            <Button
              className={classes.addListButton}
              variant="contained"
              startIcon={<CreateNewFolderRoundedIcon />}
              onClick={(e) => {
                setSaveSmartList(true);
              }}
            >
              {location.pathname.startsWith("/tools/recruiting/list")
                ? "Update List"
                : "Save List"}
            </Button>
          </Box>
        </Box>
      </Card>

      <Container className={classes.recruitingTableArea} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <AgentRecruitsTable
              data={agentList || []}
              filters={filters}
              tagsList={tagsList}
              loading={data.loading}
              tagsListLoading={tagsListLoading}
              usersList={usersList}
              usersListLoading={usersLoading}
              stagesList={stagesList}
              officesList={officesList}
              officesListLoading={officesListLoading}
              stagesListLoading={stagesListLoading}
              citiesListGAMLS={citiesListGAMLS}
              citiesListFMLS={citiesListFMLS}
              citiesListCarolina={citiesListCarolina}
              citiesListStellar={citiesListStellar}
              citiesListMiamire={citiesListMiamire}
              citiesLoading={citiesLoading}
              zipCodesListFMLS={zipCodesListFMLS}
              zipCodesListGAMLS={zipCodesListGAMLS}
              zipCodesListStellar={zipCodesListStellar}
              zipCodesListMiamire={zipCodesListMiamire}
              zipCodesListCarolina={zipCodesListCarolina}
              zipCodeLoading={zipCodeLoading}
              columnsVisibility={columnsVisibility}
              sorting={sorting}
              totalAgentsCount={totalAgentsCount}
              columnsOrder={[...columnsOrder]}
              usersObj={usersObj}
              searchText={searchText}
              setSearchText={setSearchText}
              searchValue={searchValue}
            />
          </Grid>
        </Grid>
      </Container>
      <SaveSmartListModal />
      {saveSmartListModal ? (
        <SaveSmartListModal
          state={saveSmartListModal}
          conditions={filters}
          count={totalAgentsCount}
          sortBy={sorting.sortBy || ""}
          sortOrder={sorting.sortOrder || ""}
          closeModal={closeModal}
          prevTeams={selectedSmartList?.sharedWithTeams.map((t) => t.team_id)}
          prevUsers={selectedSmartList?.sharedWith.map((u) => u.user_id)}
          id={id}
          prevName={selectedSmartList?.name}
          prevDescription={selectedSmartList?.description}
          smartList={smartList}
          type={
            location.pathname.startsWith("/tools/recruiting/list")
              ? "Update"
              : "Save"
          }
        />
      ) : null}
      <Popover
        PaperProps={{
          style: { minWidth: "150px" },
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          <MenuItem value="" disabled>
            <em>More Items</em>
          </MenuItem>
          {smartList.slice(3).map((ls, i) => {
            return (
              <MenuItem
                value={ls.id}
                key={i}
                onClick={(e) => {
                  setMoreItemsVal(`${ls.name} (${ls.count})`);
                  navigate("/tools/recruiting/list/more/" + ls.id);
                  handlePopoverClose();
                }}
                disabled={data.loading}
              >
                {ls.name} ({ls.count})
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </Box>
  );
};
export default withStyles(styleSheet, { name: "agentRecruitsStyle" })(
  AgentRecruits
);
