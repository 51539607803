import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const sendEmailText = createAsyncThunk("Leads/sendEmailTexts", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/leads/textMessages`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Context-Type": "application/json",
        },
      });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
