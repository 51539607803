import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Grid, IconButton, TextField, Stack, MenuItem, Button, Box, Backdrop, CircularProgress } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DataNotFound from "../../../components/notFound/dataNotFound";
import { getVendorPaymentRecord } from "../../../redux/vendors/vendorDetail/getPaymentDetail";
import { getVendorDetail, updateVendor, uploadVendorDoc } from "../../../redux/vendors/vendorDetail";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import VendorDetailSkelton from "../skeltons";
import processingIcon from "../../../assets/images/processingIcon.png";
import docSample from "../../../assets/images/docSample.png";
import { getData } from "../../../utils/getData";
import moment from "moment";
import Dropzone from "react-dropzone";
import dragIcon from "../../../assets/dragIcon.png";

const VendorBasicDetails = ({ classes }) => {
  const [isEditAble, setIsEditAble] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [basicDetail, setBasicDetail] = useState({
    id: "",
    company_name: "",
    first_name: "",
    last_name: "",
    street: "",
    city: "",
    state: "",
    postal_code: "",
    paymentPreference: "ACH",
    account_type: "Business",
    routing_number: "",
    contact_number: "",
    account_number: "",
    contact_email: "",
    entity_type: "",
    tax_id: "",
    name: "",
  });
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    sortBy: "",
    filterByType: "",
    search: "",
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const VendorData = useSelector((item) => item.vendors.VendorDetail.VendorData);
  const updateVendorData = useSelector((item) => item.vendors.VendorDetail.updateVendorData);
  const paymentRecord = useSelector((item) => item.vendors.VendorDetail.paymentRecord);
  const uploadDoc = useSelector((item) => item.vendors.VendorDetail.uploadDoc);
  const handleUpdateField = (field, value) => {
    setBasicDetail({
      ...basicDetail,
      [field]: value,
    });
    if (!updateFlag) {
      setUpdateFlag(true);
    }
  };
  useEffect(() => {
    dispatch(getVendorDetail({ id: id }));
    dispatch(getVendorPaymentRecord({ id: id, ...filters }));
  }, []);

  useEffect(() => {
    if (updateVendorData.success && !updateVendorData.isLoading && isEditAble) {
      setErrorAlert({
        errorMsg: "You have successfully update basic detail",
        errorType: "success",
        isOpen: true,
      });
      setIsEditAble(false);
    } else if (!updateVendorData.isLoading && updateVendorData.errMsg && isEditAble) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateVendorData.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateVendorData.isLoading]);

  useEffect(() => {
    if (VendorData?.data?.id) {
      setBasicDetail({
        company_name: VendorData?.data?.company_name || VendorData?.data?.name,
        name: VendorData?.data?.company_name || VendorData?.data?.name,
        first_name: VendorData?.data?.first_name,
        last_name: VendorData?.data?.last_name,
        street: VendorData?.data?.street,
        city: VendorData?.data?.city,
        state: VendorData?.data?.state,
        postal_code: VendorData?.data?.postal_code,
        paymentPreference: VendorData?.data?.paymentPreference || VendorData?.data?.payment_preference,
        account_number: VendorData?.data?.bank_details?.account_number,
        account_type: VendorData?.data?.bank_details?.account_type || "",
        routing_number: VendorData?.data?.bank_details?.routing_number,
        contact_number: VendorData?.data?.contact_number,
        added_by: VendorData?.data?.bank_details?.added_by,
        contact_email: VendorData?.data?.contact_email || VendorData?.data?.email,
        tax_id: VendorData?.data?.bank_details?.tax_id || VendorData?.data?.tax_id,
        entity_type: VendorData?.data?.entity_type,
      });
    }
  }, [VendorData?.data?.id]);

  const handleUpdate = () => {
    dispatch(updateVendor({ ...basicDetail, id: VendorData?.data?.id }));
  };

  const handleSuccess = () => {
    setProcessing(true);
    setErrorAlert({
      errorMsg: "You have successfully upload the W9 document",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleFileChange = (file) => {
    if (file) {
      const Data = new FormData();
      Data.append("document", file);
      dispatch(uploadVendorDoc({ id: VendorData?.data?.id, formData: Data, success: handleSuccess }));
    }
  };
  const downLoadFile = async (src) => {
    const result = await getData(`${process.env.REACT_APP_BASE_URL}api/transactions/signed-url` + "?src=" + encodeURIComponent(src));
    if (result.status === 440) {
      window.location.href = "/login";
    }
    window.open(result.data, "_blank");
  };

  const handleFileChangeDrop = (Files, data) => {
    if (Files[0]?.type?.split("/")[1] === "pdf") {
      handleFileChange(Files[0]);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleDragEnter = () => {
    document.getElementById(`upload-w9-vendor`).style.border = "2px dotted black";
    document.getElementById(`upload-w9-vendor-icon`).style.display = "flex";
  };
  const handleDragLeave = () => {
    document.getElementById(`upload-w9-vendor`).style.border = "none";
    document.getElementById(`upload-w9-vendor-icon`).style.display = "none";
  };

  return (
    <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.BasicDetailRoot}>
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title={VendorData.isLoading ? "" : basicDetail.company_name ? basicDetail.company_name : "Vendorcompany Name, Inc"}
        action={
          <div>
            <IconButton
              onClick={() => {
                if (isEditAble && updateFlag) {
                  handleUpdate();
                  setUpdateFlag(false);
                }
                setIsEditAble(!isEditAble);
              }}
              aria-label="locked"
            >
              {isEditAble ? <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} /> : <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />}
            </IconButton>
          </div>
        }
      />
      <CardContent>
        {(VendorData.isLoading || paymentRecord.isLoading) && <VendorDetailSkelton />}
        {!VendorData.isLoading && (
          <Box>
            <Grid container direction="row" spacing={6}>
              <Grid item lg={3} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="First Name"
                  value={basicDetail.first_name}
                  placeholder="First Name"
                  onChange={(e) => handleUpdateField("first_name", e.target.value)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="Last Name"
                  value={basicDetail.last_name}
                  placeholder="Last Name"
                  onChange={(e) => handleUpdateField("last_name", e.target.value)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={5} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="Company Name"
                  value={basicDetail.name}
                  placeholder="Company Name"
                  onChange={(e) => handleUpdateField("name", e.target.value)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
              <Grid item lg={5} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="Street Address"
                  value={basicDetail.street}
                  onChange={(e) => handleUpdateField("street", e.target.value)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="City"
                  value={basicDetail.city}
                  variant="standard"
                  onChange={(e) => handleUpdateField("city", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={1} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="State"
                  value={basicDetail.state}
                  onChange={(e) => handleUpdateField("state", e.target.value)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="Zip Code"
                  onChange={(e) => handleUpdateField("postal_code", e.target.value)}
                  value={basicDetail.postal_code}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item lg={3} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="Print on check as"
                  value={basicDetail.companyNamePri}
                  placeholder="Company Name, Inc"
                  onChange={(e) => handleUpdateField("companyNamePri", e.target.value)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}
              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  select
                  classes={{ root: classes.detailInput }}
                  label="Payment Method"
                  value={basicDetail.paymentPreference}
                  onChange={(e) => handleUpdateField("paymentPreference", e.target.value)}
                  variant="standard"
                >
                  <MenuItem key={"ACH"} value={"ACH"}>
                    ACH
                  </MenuItem>
                  <MenuItem key={"Check"} value={"Check"}>
                    Check
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  select
                  classes={{ root: classes.detailInput }}
                  label="Account Type"
                  disabled={basicDetail.paymentPreference !== "ACH"}
                  value={basicDetail.account_type}
                  onChange={(e) => {
                    handleUpdateField("account_type", e.target.value);
                  }}
                  variant="standard"
                >
                  <MenuItem key={"Personal"} value={"personal"}>
                    Personal
                  </MenuItem>
                  <MenuItem key={"Business"} value={"business"}>
                    Business
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="Routing Number"
                  disabled={basicDetail.paymentPreference !== "ACH"}
                  placeholder="Enter Routing Number"
                  value={basicDetail.routing_number}
                  onChange={(e) => handleUpdateField("routing_number", e.target.value)}
                  variant="standard"
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  disabled={basicDetail.paymentPreference !== "ACH"}
                  classes={{ root: classes.detailInput }}
                  label="Account Number"
                  placeholder="*************0402"
                  value={basicDetail.account_number}
                  onChange={(e) => handleUpdateField("account_number", e.target.value)}
                  variant="standard"
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  classes={{ root: classes.detailInput }}
                  label="Tax ID or EIN "
                  placeholder="******0402"
                  value={basicDetail.tax_id}
                  onChange={(e) => handleUpdateField("tax_id", e.target.value)}
                  variant="standard"
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  // disabled={basicDetail.paymentPreference !== "ACH"}
                  classes={{ root: classes.detailInput }}
                  label="Account Email"
                  type="email"
                  placeholder="Enter email"
                  value={basicDetail.contact_email}
                  onChange={(e) => handleUpdateField("contact_email", e.target.value)}
                  variant="standard"
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ readOnly: isEditAble ? false : true }}
                  disabled
                  classes={{ root: classes.detailInput }}
                  label="Added By"
                  placeholder="Enter payee name"
                  value={basicDetail?.added_by}
                  variant="standard"
                />
              </Grid>
              <Grid hidden={!isEditAble} item lg={4} xs={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <LoadingButton
                    sx={{ minWidth: "150px", mt: "15px" }}
                    onClick={handleUpdate}
                    loading={updateVendorData.isLoading}
                    color="inherit"
                    autoFocus
                    variant="outlined"
                    loadingPosition="start"
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            <Grid container direction="row" sx={{ mt: "30px", mb: "20px" }} spacing={2}>
              <Grid item lg={4} xs={12}>
                <Card sx={{ minHeight: "92px", display: "flex", alignItems: "center" }}>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<CheckCircleOutlineIcon sx={{ color: "#2E7D32" }} fontSize="large" />}
                    title={`Quickbooks Vendor ID: 44521`}
                    subheader={`Vendor Name: ${VendorData?.data?.first_name ? VendorData?.data?.first_name + " " + VendorData?.data?.last_name : ""}`}
                  />
                </Card>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Card sx={{ minHeight: "92px", display: "flex", alignItems: "center" }}>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<CheckCircleOutlineIcon sx={{ color: "#2E7D32" }} fontSize="large" />}
                    title={`Payload Status: ${VendorData?.data?.bank_details?.verification_status || ""}`}
                    subheader={`${VendorData?.data?.bank_details?.payload_method_id || ""}`}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} lg={3} sx={{pointerEvents:isEditAble?"":"none"}}>
                <Card sx={{ minHeight: "92px" }} id="upload-w9-vendor">
                  <Dropzone
                    accept={".pdf"}
                    noClick={true}
                    noKeyboard={true}
                    onDragEnter={() => handleDragEnter()}
                    onDragLeave={() => handleDragLeave()}
                    onDrop={(acceptedFiles) => {
                      handleFileChangeDrop(acceptedFiles);
                      handleDragLeave();
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Box {...getRootProps()} component="label" sx={{ cursor: "pointer", position: "relative" }}>
                        <Box id={`upload-w9-vendor-icon`} sx={{ top: VendorData?.data?.w9_form ? "0px" : "11.5px" }} className={classes.dropzoneArea}>
                          <Box className={classes.dropzoneAreaInner}>
                            <img src={dragIcon} alt="dragIcon" style={{ width: "25px", marginRight: "10px" }} /> <Box>Drop file to upload.</Box>
                          </Box>
                        </Box>
                        <CardHeader
                          classes={{
                            title: classes.agentProfileDetailHeader,
                            subheader: classes.agentProfileDetail,
                          }}
                          sx={{ padding: "5px 16px", mt: VendorData?.data?.w9_form ? "0px" : "20.5px" }}
                          avatar={
                            VendorData?.data?.w9_form ? (
                              <CheckCircleOutlineIcon sx={{ color: "#2E7D32" }} fontSize="large" />
                            ) : (
                              <UploadFileRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} fontSize="large" />
                            )
                          }
                          title={VendorData?.data?.w9_form ? "Vendor W9" : `Upload W9`}
                          action={
                            VendorData?.data?.w9_form ? (
                              <Box
                                sx={{
                                  position: "relative",
                                  mt: "7px",
                                  mr: "10px",
                                  width: "60px",
                                  "&:hover": { "& .download-and-update-button": { display: "block" } },
                                }}
                              >
                                <img
                                  src={
                                    processing
                                      ? processingIcon
                                      : VendorData?.data?.w9_form
                                      ? process.env.REACT_APP_DOCUMENT_IMG + encodeURIComponent(VendorData?.data?.w9_form?.replace(".pdf", ".png"))
                                      : docSample
                                  }
                                  width="60px"
                                  style={{ cursor: "pointer", border: "0.4px solid #000000" }}
                                  alt="doc"
                                />

                                <Box
                                  className="download-and-update-button"
                                  sx={{ position: "absolute", top: "13px", right: "-10px !important", display: "none" }}
                                >
                                  <Button
                                    onClick={() => downLoadFile(VendorData?.data?.w9_form)}
                                    className={classes.downloadButton}
                                    variant="contained"
                                    size="small"
                                  >
                                    Download
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="inherit"
                                    classes={{ root: classes.replaceButton }}
                                    startIcon={<UploadFileRoundedIcon sx={{ mr: "-5px" }} />}
                                    component="label"
                                  >
                                    Replace
                                    <input onChange={(e) => handleFileChange(e.target.files[0])} hidden accept=".pdf" multiple type="file" />
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )
                          }
                        />
                        <input onChange={(e) => handleFileChange(e.target.files[0])} hidden accept=".pdf" multiple type="file" />
                      </Box>
                    )}
                  </Dropzone>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
        <br />
        {paymentRecord?.data?.records?.length === 0 && !paymentRecord.isLoading && (
          <DataNotFound
            color="#BDBDBD"
            fontSize="20px"
            icon={<PaymentsIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
            title="No payment record Exist"
          />
        )}
        {paymentRecord?.data?.records?.length && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Via</TableCell>
                  <TableCell align="center">Date Processed</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  {
                    createdAt: new Date(),
                    property_address: "1234 Reallylongname Street Atlanta GA 30033",
                    type: "MLS Fee",
                    amount: "$14,535.00",
                    payment_type: "ACH",
                    processed_date: new Date(),
                  },
                  {
                    createdAt: new Date(),
                    property_address: "1234 Reallylongname Street Atlanta GA 30033",
                    type: "MLS Fee",
                    amount: "$14,535.00",
                    payment_type: "ACH",
                    processed_date: new Date(),
                  },
                ].map((row) => (
                  <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {moment.utc(row.createdAt).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="left">{row.property_address}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.payment_type}</TableCell>
                    <TableCell align="center"> {moment.utc(row.processed_date).format("MM/DD/YYYY")}</TableCell>
                    <TableCell align="right">
                      {" "}
                      <Button
                        variant="contained"
                        size="small"
                        color="inherit"
                        sx={{
                          backgroundColor: "white",
                          color: "#000000",
                          fontWeight: "400",
                        }}
                      >
                        View Payment
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Backdrop color="inherit" invisible={true} open={uploadDoc.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
};

export default withStyles(styleSheet, { name: "VendorBasicDetailsStyle" })(VendorBasicDetails);
