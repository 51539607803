import * as React from "react";
import { Card, Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
function TrainingVideos(props) {
  let { count = 3 } = props;
  return (
    <Box>
      {Array.from(Array(count).keys()).map(() => (
        <Card sx={{ mb: "15px" }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="rectangular" width={120} height={50} />}
            title={<Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />}
            subheader={<Skeleton animation="wave" height={15} width="50%" />}
            action={<Skeleton sx={{ height: 50,width:50 }} animation="wave" variant="circular" />}
          />
        </Card>
      ))}
    </Box>
  );
}
export default TrainingVideos;
