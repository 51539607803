import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Grid, Divider, FormControl, Select, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { createSmartList } from "../../redux/smartList";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import MultiSelectCheckbox from "./MultiSelectCheckbox";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "400px",
    height: "auto",
  },
}));

export default function AddSmartList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { open, setOpen, setSelectedType } = props;
  const [formData, setFormData] = React.useState({});

  const { list: usersList, teamsList } = useSelector((item) => item.users.storedListOfUsers);

  const handlAdd = () => {
    let dataToSave = { ...formData };
    dataToSave.sharedWith = {
      userIds: formData.sharedWith.users.map((user) => user.id),
      teamIds: formData.sharedWith.teams.map((team) => team.id),
    };
    dispatch(createSmartList(dataToSave));
    setSelectedType(dataToSave.type);
    setOpen(false);
  };

  const handleClose = () => {
    setFormData({});
    setOpen(false);
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Dialog
      disablePortal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">New Smart List</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3} gap={"5px"} flexDirection={"column"} display={"flex"}>
            <label>Type</label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.type}
              name="type"
              fullWidth
              size="small"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            >
              <MenuItem value={"agent"}>Agent</MenuItem>
              <MenuItem value={"lead"}>Lead</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={9} gap={"5px"} flexDirection={"column"} display={"flex"}>
            <label>Name</label>
            <TextField
              fullWidth
              name="name"
              size={"small"}
              placeholder="Name"
              value={formData.name}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} gap={"5px"} flexDirection={"column"} display={"flex"}>
            <label>Description</label>
            <TextField
              fullWidth
              name="description"
              size={"small"}
              placeholder="Description"
              value={formData.description}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign={"middle"} variant={"fullWidth"} flexItem sx={{ mt: "1rem", mb: "-10px" }}>
              Shared With
            </Divider>
          </Grid>

          <Grid item xs={12} gap={"5px"} flexDirection={"column"} display={"flex"}>
            <label>Users</label>
            <MultiSelectCheckbox
              label={"full_name"}
              type={"user(s)"}
              selected={formData?.sharedWith?.users || []}
              list={usersList || []}
              setSeleted={(values) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    sharedWith: {
                      ...prev.sharedWith,
                      users: [...values],
                    },
                  };
                });
              }}
            />
          </Grid>
          <Grid item xs={12} gap={"5px"} flexDirection={"column"} display={"flex"}>
            <label>Teams</label>
            <MultiSelectCheckbox
              label={"name"}
              type={"team(s)"}
              selected={formData?.sharedWith?.teams || []}
              list={teamsList || []}
              setSeleted={(values) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    sharedWith: {
                      ...prev.sharedWith,
                      teams: [...values],
                    },
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant={"contained"} onClick={handlAdd} autoFocus>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
