import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const deleteAgentPoint = createAsyncThunk("AgentPoints/deleteAgentPoint", async (data, thunkAPI) => {
  let { handleSuccessDelete, handleError } = data;
  try {
    const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/users/points/${data.user_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: { point_id: data.point_id },
    });
    if (handleSuccessDelete) {
      handleSuccessDelete();
    }
    return { ...resp.data, deletedId: data.point_id };
  } catch (error) {
    if (error.response) {
      if (handleError) {
        handleError(error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      if (handleError) {
        handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
