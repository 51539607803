import React,{useEffect} from "react";
import MakeOverProjectDetail from "./projectDetail";
import { useSelector,useDispatch } from "react-redux";
import { getThemeProps } from "@mui/system";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import InvoicesAndPayments from "./invoicesAndPayments";
import MakeOverRePayment from "./repaymentDetail";
import MakeoverDocuments from "./documents";
import {getAllVendors} from '../../../../redux/vendors/vendorList'

function TransactionDetailMakeOver(props) {
  let { classes } = getThemeProps;
  const dispatch=useDispatch()
  const projectDetail = useSelector((state) => state.listings.makeover.detail);
  const VendorsData = useSelector((state) => state.vendors.VendorsList.VendorsData);

  useEffect(()=>{
    if(!VendorsData.data?.vendors?.length){
      dispatch(getAllVendors({pageSize:1000,pageNumber:1}))
    }

  },[])

  return (
    <React.Fragment>
      <MakeOverProjectDetail projectDetail={projectDetail} {...props} />
      {projectDetail?.makeover_documents?.length ? (
        <MakeoverDocuments
          projectDetail={projectDetail}
          isGenerate
          type="listing"
          hideAction={true}
          {...props}
        />
      ) : null}
      <InvoicesAndPayments
        title="Invoices and Payments"
        projectDetail={projectDetail}
        type="listing"
        hideAction={true}
        {...props}
        vendors={VendorsData.data?.vendors}
      />
      <MakeOverRePayment         makeoverDetail={projectDetail} {...props} />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, {
  name: "TransactionDetailMakeOverStyle",
})(TransactionDetailMakeOver);
