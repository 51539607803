import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const sendEmail = createAsyncThunk(
  "SendEmailAs/sendEmail",
  async (data, thunkAPI) => {
    let { handleSuccess, handleError, schema, sentAs, sentBy } = data;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/users/send-email-as/compose`,
        schema,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (handleSuccess && resp.data) {
        handleSuccess();
      }
      return {
        ...resp.data?.data,
        sentBy,
        sentAs,
        attachments: schema.attachments,
      };
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
