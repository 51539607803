import React, { useState, Fragment, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useSelector } from "react-redux";
import ButtonGroups from "../../../../../components/uiElements/buttonGroup";
const sliderArrowStyle = {
  cursor: "pointer",
  position: "absolute",
  zIndex: "99",
  fontSize: "40px !important",
  padding: "6px",
  color: "rgba(0, 0, 0, 0.54)",
  background: "#D9D9D9",
  borderRadius: "50%",
  top: "43%",
};
function SliderSection(props) {
  let { classes } = props;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [version, setVersion] = useState("");
  const templateDetail = useSelector(
    (state) => state.marketingCenter.digital.templateDetail
  );

  const ArrowLeft = ({ onClick }) => (
    <ArrowBackIosNewRoundedIcon
      sx={{ ...sliderArrowStyle, left: "10px" }}
      onClick={onClick}
    />
  );

  const ArrowRight = ({ onClick }) => (
    <ArrowForwardIosRoundedIcon
      sx={{ ...sliderArrowStyle, right: "10px" }}
      onClick={onClick}
    />
  );

  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    initialSlide: selectedIndex,
    slidesToScroll: 1,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    beforeChange: (oldIndex, newIndex) => {
      setSelectedIndex(newIndex);
    },
  };

  const getPreviewOptions = () => {
    if (templateDetail?.data?.variations?.length) {
      return templateDetail?.data?.variations
        ?.filter(
          (option, index, self) =>
            self.findIndex((ite) => ite.version_name === option.version_name) ==
            index
        )
        ?.map((item) => ({
          title: item.version_name,
          value: item.version_name,
        }));
    } else {
      return [];
    }
  };

  const getFilteredVariations = () => {
    if (version) {
      return templateDetail?.data?.variations?.filter(
        (item) => item.version_name === version
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (getPreviewOptions()?.length) {
      setVersion(getPreviewOptions()[0]?.value);
    }
  }, [templateDetail?.data?.variations]);

  return (
    <Fragment>
      <Box className={classes.sliderSection}>
        {templateDetail?.data?.variations?.length ? (
          <Box className={classes.socialLabel}>
            {getFilteredVariations()[selectedIndex]?.platform_type}
          </Box>
        ) : null}
        <Box className={classes.slider}>
          <Slider {...settings}>
            {getFilteredVariations()?.map((item, index) => (
              <Box key={item.version_name} className={classes.SliderAreaItem}>
                <img
                  fill={true}
                  style={{
                    objectFit: "contain",
                  }}
                  src={item?.version_image}
                  alt={item?.version_name}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
      <Box className={classes.previewSelectionArea}>
        <Typography className={classes.previewSectionLabel}>
          Preview Version:
        </Typography>
        <ButtonGroups
          id="category-button-menu"
          bgColor="#0B0909"
          bgColorHover="#0B0909"
          placement={"bottom"}
          height="32.5px"
          size="medium"
          color="white"
          options={getPreviewOptions()}
          onChangeMenu={(value) => {
            setVersion(value);
            setSelectedIndex(1);
          }}
          value={version}
          placeholder="Preview"
          {...props}
        />
      </Box>
    </Fragment>
  );
}
export default withStyles(styleSheet, { name: "SliderSectionStyles" })(
  SliderSection
);
