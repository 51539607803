import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import TableSkeleton from "../../components/loader/tableLoader"  

export default function DataGridComponent(props) {
    return (
        <DataGridPro
            {...props}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick
            components={{
               LoadingOverlay: TableSkeleton,
            }}
            sx={{
                ...props.sx,
                fontFamily: 'AvenirNext!important',
                fontWeight: 500,
                backgroundColor: "white",
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600
                },
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                // '*::-webkit-scrollbar': {
                //     width: 5
                // },
                // '*::-webkit-scrollbar-track': {
                //     width: 5,
                //     backgroundColor: "#ddd",
                //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                // },
                // '*::-webkit-scrollbar-thumb': {
                //     width: 5,
                //     backgroundColor: "#0B0909",
                //     borderRadius: 1
                // }
            }}
        />
    );
}
