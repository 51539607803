import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Grid, IconButton, TextField, Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AgentCheckOutEquipmentModal from "../modals/checkoutEquipmentModal";
import AgentCheckOutLockBoxModal from "../modals/checkoutLockBoxModal";
import AgentCheckOutSignageModal from "../../../agentRoster/agentDetails/modals/checkOutSignageModal";
import agentMethodIconWhite from "../../../../assets/images/agents/agentMethodIconWhite.png";
import agentMethodIconBlack from "../../../../assets/images/agents/agentMethodIconBlack.png";
import agentEquipmentIcon from "../../../../assets/images/agents/agentEquipmentIcon.png";
import agentLockIcon from "../../../../assets/images/agents/agentLockIcon.png";
import InventoryItemDetailModal from "../modals/inventoryItemDetailModal";
import InventoryItemDetailEquipmentModal from "../modals/inventoryItemDetailModal1";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import { getAllInventories } from "../../../../redux/inventory";
import InventorySkelton from "../../skeltons/inventory"
import WidgetsIcon from "@mui/icons-material/Widgets";
import { useSelector, useDispatch } from "react-redux";
import { getAgentInventories } from "../../../../redux/agents/agentRosterDetail/inventory/getAgentInventory";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import { useParams } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import moment from "moment";
const AgentInventoryTab = ({ classes }) => {
  const [lockBoxDetail, setLockBoxDetail] = useState({});
  const [equipmentBox, setEquipmentBox] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [openEquipment, setOpenEquipment] = useState(false);
  const [openLockBox, setOpenLockBox] = useState(false);
  const [openItemDetail, setOpenItemDetail] = useState(false);
  const [openItemDetail1, setOpenItemDetail1] = useState(false);
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 1000,
    search: "",
    status: "",
  });
  const InventoryList = useSelector((state) => state.inventory.InventoryData.InventoryList);
  const inventoryData = useSelector((state) => state.agentRoster.agentInventory.InventoryList);
  const logsHistory = useSelector((state) => state.agentRoster.agentInventory.logsHistory);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllInventories(filter));
  }, [filter.search]);

  useEffect(() => {
    dispatch(getAgentInventories({ id: id }));
  }, []);


  return (
    <Grid container direction="row" spacing={2}>
      <Grid item lg={4} xs={12}>
        <Card variant="outlined" sx={{ backgroundColor: "white", minHeight: "500px" }} className={classes.pageRootInventory}>
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Signage
                <IconButton onClick={() => setOpen(true)} aria-label="locked">
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton>
              </div>
            }
          />
          <CardContent>
            <Grid container direction="row" spacing={6}>
              {inventoryData?.isLoading ? (
                <Grid item xs={12} sx={{ zIndex: "1" }}>
                 <InventorySkelton />
                </Grid>
              ) : inventoryData?.data?.length > 0 && inventoryData?.data?.filter((it) => it.inventory?.AssetType === "signage")?.length > 0 ? (
                inventoryData?.data
                  ?.filter((it) => it.inventory?.AssetType === "signage")
                  ?.map((item) => (
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader
                          classes={{
                            title: classes.agentInventoryCardHeader,
                            subheader: classes.agentInventoryCardDes,
                          }}
                          avatar={
                            <img src={item.SignageColor === "white" ? agentMethodIconWhite : agentMethodIconBlack} alt="agent Method Icon White" />
                          }
                          title={`${item.SignageColor} Open House Signs(${item.OpenHouseDay})`}
                          subheader={`Quantity: ${item.SignageCount}`}
                        />
                      </Card>
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12}>
                  <DataNotFound
                    color="#BDBDBD"
                    fontSize="20px"
                    icon={<ListAltIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
                    title="No signage Exist"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card variant="outlined" sx={{ backgroundColor: "white", minHeight: "500px" }} className={classes.pageRootInventory}>
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Equipment
                <IconButton onClick={() => setOpenEquipment(true)} aria-label="locked">
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton>
              </div>
            }
          />
          <CardContent>
            <Grid container direction="row" spacing={6}>
              {inventoryData?.isLoading ? (
                <Grid item xs={12} sx={{ zIndex: "1" }}>
                 <InventorySkelton /> 
                </Grid>
              ) : inventoryData?.data?.length > 0 && inventoryData?.data?.filter((it) => it.inventory?.AssetType === "equipment")?.length > 0 ? (
                inventoryData?.data
                  ?.filter((it) => it.inventory?.AssetType === "equipment")
                  ?.map((item) => (
                    <Grid item xs={12}>
                      <Card
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpenItemDetail1(true);
                          setEquipmentBox(item);
                        }}
                      >
                        <CardHeader
                          classes={{
                            title: classes.agentInventoryCardHeader,
                            subheader: classes.agentInventoryCardDes,
                          }}
                          avatar={<img src={agentEquipmentIcon} alt="agent Method Icon White" />}
                          title={item.inventory?.AssetName}
                          subheader={`Serial Number: ${item.inventory?.SerialNumber}`}
                        />
                      </Card>
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12}>
                  <DataNotFound
                    color="#BDBDBD"
                    fontSize="20px"
                    icon={<WidgetsIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
                    title="No equipment Exist"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card variant="outlined" sx={{ backgroundColor: "white", minHeight: "500px" }} className={classes.pageRootInventory}>
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Lockboxes
                <IconButton onClick={() => setOpenLockBox(true)} aria-label="locked">
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton>
              </div>
            }
          />
          <CardContent>
            <Grid container direction="row" spacing={6}>
              {inventoryData?.isLoading ? (
                <Grid item xs={12} sx={{ zIndex: "1" }}>
                  <InventorySkelton /> 
                </Grid>
              ) : inventoryData?.data?.length > 0 && inventoryData?.data?.filter((it) => it.inventory?.AssetType === "lockbox")?.length > 0 ? (
                inventoryData?.data
                  ?.filter((it) => it.inventory?.AssetType === "lockbox")
                  ?.map((item) => (
                    <Grid item xs={12}>
                      <Card
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setLockBoxDetail(item);
                          setOpenItemDetail(true);
                        }}
                      >
                        <CardHeader
                          classes={{
                            title: classes.agentInventoryCardHeader,
                            subheader: classes.agentInventoryCardDes,
                          }}
                          avatar={<img src={agentLockIcon} alt="agent Method Icon White" />}
                          title={`${item.inventory?.AssetName}`}
                          subheader={`CBS Code: ${item.inventory?.CBScode}`}
                        />
                        {/* <CardContent classes={{ root: classes.agentInventoryCardContentDes }}>123456 main street longName RD Altana 300033</CardContent> */}
                      </Card>
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12}>
                  <DataNotFound
                    color="#BDBDBD"
                    fontSize="20px"
                    icon={<PunchClockIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
                    title="No Lockbox Exist"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
      <Box sx={{ mt: "24px", mb: "-9.5px", ml: "20px" }} className={classes.historyLogsTime}>
          <Box sx={{ display: "inline", background: "white", width: "30px", color: "rgba(0, 0, 0, 0.54)", padding: "0px 4px" }}>History</Box>{" "}
        </Box>
        <Card
          variant="outlined"
          sx={{
            padding: "12px",
            mb:"30px",
            minHeight:"150px"
          }}
        >
          {logsHistory.data.map((item) => (
            <Grid container sx={{ mt: "10px" }}>
              <Grid item sm={12} md={2} className={classes.historyLogsTime}>
                {moment(item.createdAt).format("L")} @ {moment(item.createdAt).format("LT")}{" "}
              </Grid>
              <Grid sx={{ paddingLeft: "0px !important", ml: "-10px" }} item sm={12} md={10} className={classes.historyLogsDetail}>
                {item.Description}
              </Grid>
            </Grid>
          ))}
        </Card>
      </Grid>
      <AgentCheckOutSignageModal
        open={open}
        InventoryList={InventoryList}
        inventoryData={InventoryList.data?.rows?.filter((item) => item.AssetType === "signage") || []}
        setOpen={setOpen}
      />
      <AgentCheckOutEquipmentModal
        open={openEquipment}
        setFilter={setFilter}
        filter={filter}
        InventoryList={InventoryList}
        inventoryData={InventoryList.data?.rows?.filter((item) => item.AssetType === "equipment") || []}
        setOpen={setOpenEquipment}
      />
      <AgentCheckOutLockBoxModal
        open={openLockBox}
        setFilter={setFilter}
        filter={filter}
        InventoryList={InventoryList}
        inventoryData={InventoryList.data?.rows?.filter((item) => item.AssetType === "lockbox") || []}
        setOpen={setOpenLockBox}
      />
      <InventoryItemDetailModal lockBoxDetail={lockBoxDetail} open={openItemDetail} setOpen={setOpenItemDetail} />
      <InventoryItemDetailEquipmentModal equipmentBox={equipmentBox} open={openItemDetail1} setOpen={setOpenItemDetail1} />
    </Grid>
  );
};

export default withStyles(styleSheet, { name: "AgentInventoryTabStyle" })(AgentInventoryTab);
