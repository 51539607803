import React, { useEffect } from "react";
import { Box, Card, Chip, Stack, Grid, Typography, Table, TableBody, TableRow, TableCell,Container } from "@mui/material";
import { styleSheet } from "../style";
import { Link, useLocation } from "react-router-dom";
import { withStyles } from "@mui/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ImgsViewer from "react-images-viewer";
import IosShareIcon from "@mui/icons-material/IosShare";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import Loading from "../../../../components/shared/loading";
import momentTZ from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { getListDetail, getSearchSuggestionData } from "../../../../redux/listings/listingFmlsDetail";
import { postData } from "../../../../utils/postData";
import { useParams } from "react-router-dom";

const sliderArrowStyle = {
  cursor: "pointer",
  position: "absolute",
  zIndex: "999",
  fontSize: "28px",
  color: "#fff",
  background: "#433F3F",
  borderRadius: "50%",
  top: "43%",
};

function PropertyDetailFMLS(props) {
  let { classes } = props;
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [imageViewerStartingImage, setImageViewerStartingImage] = React.useState(0);
  const location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const listDetail = useSelector((state) => state.listings?.FMLSListDetail?.listDetail);
  const adminAuth = useSelector((state) => state.adminAuth);

  useEffect(() => {
    if (!listDetail.isLoading) {
      dispatch(getListDetail({ id }));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getSearchSuggestionData({id:adminAuth?.adminDetail?.market?.id}));
  }, []);

  useEffect(() => {
    if (listDetail?.data?.ListingId) {
      let obj = {
        source: "Test",
        type: "Viewed Property",
        property: {
          street: listDetail?.data?.StreetNumber + " " + listDetail?.data?.StreetName + " " + listDetail?.data?.StreetSuffix,
          city: listDetail?.data?.City,
          state: listDetail?.data?.StateOrProvince,
          mlsNumber: listDetail?.data.ListingId,
          price: listDetail?.data?.MlsStatus === "Closed" ? listDetail?.data?.ClosePrice : listDetail?.data?.ListPrice,
          bedrooms: listDetail?.data?.BedroomsTotal,
          bathrooms: listDetail?.data?.BedroomsTotal,
          area: listDetail?.data?.BuildingAreaTotal,
        },
      };
      handleCallViewEvent(obj);
    }
  }, [listDetail?.data]);

  const handleCallViewEvent = async (obj) => {
    await postData(`${process.env.REACT_APP_BASE_URL}api/users/events`, obj);
  };

  const ArrowLeft = ({ onClick }) => <NavigateBefore sx={{ ...sliderArrowStyle, left: "10px" }} onClick={onClick} />;

  const ArrowRight = ({ onClick }) => <NavigateNext sx={{ ...sliderArrowStyle, right: "10px" }} onClick={onClick} />;

  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "20px",
    slidesToShow: 2,
    swipeToSlide: true,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
  };
  return (
    <Container maxWidth="lg">
    <Box>
      {listDetail.isLoading ? (
        <Box className={classes.loadingArea}>
          <Loading type="spin" />
        </Box>
      ) : (
        <Box>
          <br />
          <Card sx={{ padding: "5px" }}>
            <Box className={classes.propertyPicsGalleryArea} sx={{ zIndex: "10" }}>
              <Slider {...settings}>
                {listDetail?.data?.Media?.map((item, index) => {
                  if (index === 1 || index === 2 || index === 3) {
                    return <div style={{ height: "0px", visibility: "hidden" }}></div>;
                  } else if (index === 4) {
                    return [
                      <div>
                        {" "}
                        <img
                          onDoubleClick={() => {
                            setImageViewerOpen(true);
                            setImageViewerStartingImage(1);
                          }}
                          style={{ width: "280px", height: "270px", cursor: "pointer", border: "1px solid lightgrey", objectFit: "cover" }}
                          src={listDetail?.data?.Media[1].MediaURL}
                          alt="MediaURL"
                        />
                      </div>,
                      <div>
                        <img
                          onDoubleClick={() => {
                            setImageViewerOpen(true);
                            setImageViewerStartingImage(2);
                          }}
                          style={{
                            width: "280px",
                            height: "270px",
                            cursor: "pointer",
                            zIndex: "1",
                            border: "1px solid lightgrey",
                            objectFit: "cover",
                          }}
                          src={listDetail?.data?.Media[2].MediaURL}
                          alt="MediaURL"
                        />
                      </div>,
                      <div>
                        <img
                          onDoubleClick={() => {
                            setImageViewerOpen(true);
                            setImageViewerStartingImage(3);
                          }}
                          style={{
                            width: "280px",
                            height: "270px",
                            cursor: "pointer",
                            zIndex: "1",
                            border: "1px solid lightgrey",
                            objectFit: "cover",
                          }}
                          src={listDetail?.data?.Media[3].MediaURL}
                          alt="MediaURL"
                        />
                      </div>,
                      <div>
                        <img
                          onDoubleClick={() => {
                            setImageViewerOpen(true);
                            setImageViewerStartingImage(4);
                          }}
                          style={{
                            width: "280px",
                            height: "270px",
                            cursor: "pointer",
                            zIndex: "1",
                            border: "1px solid lightgrey",
                            objectFit: "cover",
                          }}
                          src={listDetail?.data?.Media[4].MediaURL}
                          alt="MediaURL"
                        />
                      </div>,
                    ];
                  } else {
                    return (
                      <div style={{ cursor: "pointer" }}>
                        {index === 0 ? (
                          <img
                            onDoubleClick={() => {
                              setImageViewerOpen(true);
                              setImageViewerStartingImage(index);
                            }}
                            style={{
                              width: "565px",
                              height: "545px",
                              cursor: "pointer",
                              zIndex: "5",
                              border: "1px solid lightgrey",
                              objectFit: "cover",
                            }}
                            src={item.MediaURL}
                            alt="MediaURL"
                          />
                        ) : (
                          <img
                            onDoubleClick={(e) => {
                              setImageViewerOpen(true);
                              setImageViewerStartingImage(index);
                            }}
                            style={{ width: "280px", height: "270px", cursor: "pointer", objectFit: "cover", border: "1px solid lightgrey" }}
                            src={item.MediaURL}
                            alt="MediaURL"
                          />
                        )}
                      </div>
                    );
                  }
                })}
              </Slider>
            </Box>
            <ImgsViewer
              imgs={listDetail?.data?.Media?.map((item, index) => ({ src: item.MediaURL })) || []}
              isOpen={imageViewerOpen}
              currImg={imageViewerStartingImage}
              showThumbnails={true}
              enableKeyboardInput={true}
              onClickThumbnail={(e) => setImageViewerStartingImage(e)}
              onClickPrev={() =>
                imageViewerStartingImage - 1 >= 0 ? setImageViewerStartingImage((imageViewerStartingImage) => imageViewerStartingImage - 1) : null
              }
              onClickNext={() =>
                imageViewerStartingImage + 1 <= listDetail?.data?.Media.length
                  ? setImageViewerStartingImage((imageViewerStartingImage) => imageViewerStartingImage + 1)
                  : null
              }
              onClose={() => setImageViewerOpen(false)}
            />

            <Grid container spacing={2} sx={{ zIndex: "1000" }}>
              <Grid item sm={12} md={5}>
                <Box className={classes.listingHeadingArea}>
                  {" "}
                  {listDetail?.data?.StreetNumber || "Street NA"} {listDetail?.data?.StreetName || "StreetName NA"} {listDetail?.data?.StreetSuffix}
                </Box>
                <Box className={classes.listingHeadingSub}>
                  {" "}
                  {listDetail?.data?.City || listDetail?.data?.UnparsedAddress}, {listDetail?.data?.StateOrProvince}{" "}
                  {listDetail?.data?.PostalCode || "PostalCode NA"}
                </Box>
              </Grid>
              <Grid item sm={12} md={7}>
                <Stack direction={"row"} justifyContent="flex-end" alignItems="center" spacing={5}>
                  <Box>
                    <Box className={classes.listingStatHeading}>{listDetail?.data?.BedroomsTotal || "0"}</Box>
                    <Box className={classes.listingStatDes}>Beds</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingStatHeading}>{listDetail?.data?.BathroomsFull || "0"}</Box>
                    <Box className={classes.listingStatDes}>Baths</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingStatHeading}>{new Intl.NumberFormat().format(listDetail?.data?.BuildingAreaTotal || "0")}</Box>
                    <Box className={classes.listingStatDes}>Sq. Feet</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingStatHeading}>{listDetail?.data?.YearBuilt || "--"}</Box>
                    <Box className={classes.listingStatDes}>Yr. Built</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingStatPrice}>
                      {" "}
                      $
                      {listDetail?.data?.MlsStatus === "Closed"
                        ? new Intl.NumberFormat().format(listDetail?.data?.ClosePrice || "0")
                        : new Intl.NumberFormat().format(listDetail?.data?.ListPrice || "0")}
                    </Box>
                    <center>
                      {" "}
                      <Chip
                        className={classes.listingStatChip}
                        sx={{
                          background:
                            listDetail?.data?.MlsStatus === "Expired"
                              ? "#FF9800"
                              : listDetail?.data?.MlsStatus === "Canceled"
                              ? "orange"
                              : listDetail?.data?.MlsStatus === "Active"
                              ? "#2E7D32"
                              : listDetail?.data?.MlsStatus === "Pending"
                              ? "#81C784"
                              : listDetail?.data?.MlsStatus === "Closed"
                              ? "#C62828"
                              : "#2E7D32",
                        }}
                        label={listDetail?.data.MlsStatus === "Active Under Contract" ? "ActiveUC" : listDetail?.data?.MlsStatus || "Active"}
                      />
                    </center>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Card>
          <Box className={classes.listingDetailMiddleArea}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={5}>
                <Stack direction={"row"} justifyContent="flex-end" alignItems="center" spacing={3}>
                  <Box>
                    <Box className={classes.listingMiddleStat}>{listDetail?.data?.SubdivisionName || "--"}</Box>
                    <Box className={classes.listingMiddleStatDes}>Subd/Bldg</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingMiddleStat}>{listDetail?.data?.LotSizeAcres || "--"}</Box>
                    <Box className={classes.listingMiddleStatDes}>Acres</Box>
                  </Box>
                </Stack>
              </Grid>
              <Grid item sm={12} md={7}>
                <Stack direction={"row"} justifyContent="flex-end" alignItems="center" spacing={4}>
                  <Box>
                    <Box className={classes.listingMiddleStat}>{listDetail?.data?.DaysOnMarket || "--"}</Box>
                    <Box className={classes.listingMiddleStatDes}>Days</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingMiddleStat}>{listDetail?.data?.ListingId || "--"}</Box>
                    <Box className={classes.listingMiddleStatDes}>FMLS</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingMiddleStat}>
                      <IosShareIcon />
                    </Box>
                    <Box className={classes.listingMiddleStatIconDes}>Share Listing</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingMiddleStat}>
                      <FavoriteRoundedIcon />
                    </Box>
                    <Box className={classes.listingMiddleStatIconDes}>Save Listing</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingMiddleStat}>
                      <FileCopyRoundedIcon />
                    </Box>
                    <Box className={classes.listingMiddleStatIconDes}>View Docs</Box>
                  </Box>
                  <Box>
                    <Box className={classes.listingMiddleStat}>
                      <ContactPageRoundedIcon />
                    </Box>
                    <Box className={classes.listingMiddleStatIconDes}>Start Offer</Box>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item sm={12} md={6}>
                <Card variant="outlined" className={classes.statsBoxArea}>
                  <Table sx={{ width: "60%" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.defaultText} sx={{ padding: "5px" }}></TableCell>
                        <TableCell className={classes.defaultText} sx={{ padding: "5px" }}>
                          Beds
                        </TableCell>
                        <TableCell className={classes.defaultText} sx={{ padding: "5px" }}>
                          Baths
                        </TableCell>
                        <TableCell className={classes.defaultText} sx={{ padding: "5px" }}>
                          Half Bath
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.defaultText}>Total</TableCell>
                        <TableCell align="center" className={classes.boldText}>
                          {listDetail?.data.BedroomsTotal || "0"}
                        </TableCell>
                        <TableCell align="center" className={classes.boldText}>
                          {listDetail?.data.BathroomsFull || "0"}
                        </TableCell>
                        <TableCell align="center" className={classes.boldText}>
                          {listDetail?.data.BathroomsHalf || "0"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.defaultText}>Main</TableCell>
                        <TableCell align="center" className={classes.boldText}>
                          {listDetail?.data.MainLevelBedrooms || "0"}
                        </TableCell>
                        <TableCell align="center" className={classes.boldText}>
                          {listDetail?.data.MainLevelBathrooms || "0"}
                        </TableCell>
                        <TableCell align="center" className={classes.boldText}>
                          {listDetail?.data.BathroomsHalf || "0"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Box sx={{ ml: "30px" }}>
                    <Box>
                      <Box className={classes.boldText}>Elem :</Box>{" "}
                      <Box className={classes.defaultText}>{listDetail?.data.ElementarySchool || "--"}</Box>
                    </Box>
                    <br />
                    <Box>
                      <Box className={classes.boldText}>Middle :</Box>{" "}
                      <Box className={classes.defaultText}>{listDetail?.data.MiddleOrJuniorSchool || "--"}</Box>
                    </Box>
                    <br />
                    <Box>
                      <Box className={classes.boldText}>MidHighdle :</Box>{" "}
                      <Box className={classes.defaultText}>{listDetail?.data.HighSchool || "--"}</Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}>
                <Card variant="outlined" className={classes.listFeeAndLivingArea}>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                      <Typography variant="h5" className={classes.blockHeading}>
                        Fees
                      </Typography>
                      <br />
                      <Box>
                        <Box className={classes.boldText}>Assoc Fee :</Box>{" "}
                        <Box className={classes.defaultText}>
                          $ {new Intl.NumberFormat().format(listDetail?.data?.AssociationFee || "0")}/
                          {listDetail?.data.AssociationFeeFrequency || "--"}
                        </Box>
                      </Box>
                      <Box>
                        <Box className={classes.boldText}>Initation Fee:</Box>{" "}
                        <Box className={classes.defaultText}>${new Intl.NumberFormat().format(listDetail?.data?.FMLS_InitiationFee || "0")}</Box>{" "}
                      </Box>
                      <Box>
                        <Box className={classes.boldText}>Rent Restrictions:</Box>{" "}
                        <Box className={classes.defaultText}>{listDetail?.data?.FMLS_AssociationRentRestrictionsYN ? "Yes" : "No"}</Box>
                      </Box>

                      <Box>
                        <Box className={classes.boldText}>Prop Taxes:</Box>{" "}
                        <Box className={classes.defaultText}>
                          ${new Intl.NumberFormat().format(listDetail?.data?.TaxAnnualAmount || "0")} / {listDetail?.data?.TaxYear || "--"}
                        </Box>{" "}
                      </Box>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Typography variant="h5" className={classes.blockHeading}>
                        Living Area
                      </Typography>
                      <br />
                      <Box>
                        <Box className={classes.boldText}>Sq Ft/Source:</Box>{" "}
                        <Box className={classes.defaultText}>
                          {new Intl.NumberFormat().format(listDetail?.data?.BuildingAreaTotal || "0")} / {listDetail?.data?.Owner || "--"}
                        </Box>
                      </Box>

                      <Box>
                        <Box className={classes.boldText}>Abv Grade Finished:</Box>{" "}
                        <Box className={classes.defaultText}>{listDetail?.data?.AboveGradeFinishedArea || "0"}</Box>
                      </Box>

                      <Box>
                        <Box className={classes.boldText}>Blw Grade Finished:</Box>{" "}
                        <Box className={classes.defaultText}>{listDetail?.data?.BelowGradeFinishedArea || "0"}</Box>
                      </Box>
                      <Box>
                        <Box className={classes.boldText}>Blw Grade Unfinsihed:</Box>{" "}
                        <Box className={classes.defaultText}>{listDetail?.data?.FMLS_BelowGradeUnfinishedArea || "0"}</Box>
                      </Box>
                      <Box>
                        <Box className={classes.boldText}>Basement:</Box>{" "}
                        <Box className={classes.defaultText}>
                          {listDetail?.data?.Basement?.length ? listDetail?.data?.Basement?.join(", ") : "--"}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}>
                <Card variant="outlined" className={classes.remarksBlock}>
                  <Typography className={classes.blockHeading} variant="h5">
                    Private Remarks
                  </Typography>
                  <Typography className={classes.defaultText}>{listDetail?.data.PrivateRemarks} </Typography>
                </Card>

                {listDetail?.data.MlsStatus === "Closed" && (
                  <Card variant="outlined" className={classes.ListedByBox}>
                    <Typography className={classes.blockHeading} variant="h5">
                      Sold Information
                    </Typography>
                    <Stack direction={"row"} justifyContent="space-between" spacing={10} sx={{ mb: "15px" }}>
                      <Box>
                        <Box className={classes.boldText}>Binding Agreement Date:</Box>{" "}
                        <Box className={classes.defaultText}>
                          {listDetail?.data.PurchaseContractDate
                            ? momentTZ.tz(listDetail?.data.PurchaseContractDate, "America/New_York")?.format("l")
                            : ""}
                        </Box>{" "}
                      </Box>

                      <Box>
                        <Box className={classes.boldText}>Sale Price:</Box>{" "}
                        <Box className={classes.defaultText}>{new Intl.NumberFormat().format(listDetail?.data?.ClosePrice || "0")}</Box>{" "}
                      </Box>
                    </Stack>
                    <Stack direction={"row"} justifyContent="space-between" spacing={10} sx={{ mb: "15px" }}>
                      <Box>
                        <Box className={classes.boldText}>Closing Date:</Box>{" "}
                        <Box className={classes.defaultText}>
                          {listDetail?.data.PurchaseContractDate ? momentTZ.tz(listDetail?.data.CloseDate, "America/New_York")?.format("l") : ""}
                        </Box>{" "}
                      </Box>
                      <Box>
                        <Box className={classes.boldText}>Seller Paid Costs: </Box>{" "}
                        <Box className={classes.defaultText}> {new Intl.NumberFormat().format(listDetail?.data?.ConcessionsAmount || "0")}</Box>{" "}
                      </Box>
                    </Stack>
                    <Stack direction={"row"} justifyContent="flex-end" spacing={10} sx={{ mb: "15px" }}>
                      <Box>
                        <Box className={classes.boldText}>Terms: </Box>{" "}
                        <Box className={classes.defaultText}> {listDetail?.data?.BuyerFinancing || "--"}</Box>{" "}
                      </Box>
                    </Stack>

                    <Box>
                      <Box className={classes.boldText}>Sell Agent: </Box>{" "}
                      <Box className={classes.defaultText}> {listDetail?.data?.BuyerAgentFullName || "--"}</Box>{" "}
                    </Box>
                    <Box>
                      <Box className={classes.boldText}>Sell Office: </Box>{" "}
                      <Box className={classes.defaultText}> {listDetail?.data?.BuyerOfficeName || "--"}</Box>{" "}
                    </Box>
                  </Card>
                )}
              </Grid>
              <Grid item sm={12} md={6}>
                <Card variant="outlined" className={classes.remarksBlock}>
                  <Typography className={classes.blockHeading} variant="h5">
                    Public Remarks
                  </Typography>
                  <Typography className={classes.defaultText}>{listDetail?.data.PublicRemarks}</Typography>
                </Card>
                <Card variant="outlined" className={classes.ListedByBox}>
                  <Typography className={classes.blockHeading} variant="h5">
                    Listed By
                  </Typography>
                  <Stack direction={"row"} spacing={10} sx={{ mb: "15px" }}>
                    <Typography className={classes.boldText} variant="h6">
                      {listDetail?.data?.ListAgentFullName || "ListAgentFullName NA"}
                    </Typography>
                    <Typography className={classes.boldText} variant="h6">
                      {" "}
                      {listDetail?.data?.ListAgentDirectPhone || "ListAgentDirectPhone NA"}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">{listDetail?.data?.ListOfficeName || "ListOfficeName NA"}</Typography>
                  <br />
                  <Stack direction={"row"} spacing={10} sx={{ mb: "15px" }}>
                    <Box>
                      <Box className={classes.boldText}>Coop Commission:</Box>{" "}
                      <Box className={classes.defaultText}>
                        {listDetail?.data?.BuyerAgencyCompensation}
                        {listDetail?.data?.BuyerAgencyCompensationType}
                      </Box>
                    </Box>
                    <Box>
                      <Box className={classes.boldText}>VRC:</Box>{" "}
                      <Box className={classes.defaultText}>{listDetail?.data?.DualVariableCompensationYN ? "Yes" : "No"}</Box>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}></Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <br />
      <br />
    </Box>
    </Container>
  );
}
export default withStyles(styleSheet, { name: "PropertyDetailFMLSStyles" })(PropertyDetailFMLS);
