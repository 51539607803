import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import ListingDetailSpecifications from "./specifications";
import TransactionsDetailRemarks from "./publicRemarks";
import TransactionsDetailMatterPort from "./matterPort";
import TransactionsDetailPhotos from "./photos";
import TransactionsDetailFeatures from "./features";
import { useDispatch, useSelector } from "react-redux";
import { getPublicData } from "../../../../redux/listings/publicData";
import TransactionsDetailGoogleMap from "./googleMap";
import ListingDataTemplates from "./templates";
import TransactionsDetailIdx from "./idx";
function TransactionsDetailData(props) {
  let { classes, ListingData } = props;
  const dispatch = useDispatch();
  const { publicDataDetail, isLoading } = useSelector(
    (state) => state.listings.publicData
  );
  useEffect(() => {
    if (ListingData?.id) {
      dispatch(getPublicData({ listing_id: ListingData?.id }));
    }
  }, [ListingData?.id]);
  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={5}>
        <ListingDetailSpecifications
          publicDataDetail={publicDataDetail}
          {...props}
        />
        <TransactionsDetailGoogleMap
          isLoading={isLoading}
          publicDataDetail={publicDataDetail}
          {...props}
        />
        <TransactionsDetailMatterPort
          isLoading={isLoading}
          publicDataDetail={publicDataDetail}
          {...props}
        />
        <ListingDataTemplates
          isLoading={isLoading}
          publicDataDetail={publicDataDetail}
          {...props}
        />
        <TransactionsDetailIdx
          isLoading={isLoading}
          publicDataDetail={publicDataDetail}
          {...props}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <TransactionsDetailRemarks
          isLoading={isLoading}
          {...props}
          publicDataDetail={publicDataDetail}
        />
        <TransactionsDetailPhotos
          isLoading={isLoading}
          {...props}
          publicDataDetail={publicDataDetail}
        />
        <TransactionsDetailFeatures
          {...props}
          publicDataDetail={publicDataDetail}
        />
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailDataStyle" })(
  TransactionsDetailData
);
