import React from "react";
import { Grid, Dialog, DialogContent } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { attachClient } from "../../../redux/tools/cmas.js";
import { LoadingButton } from "@mui/lab";

function AttachClient(props) {
  const dispatch = useDispatch();
  const { classes, cmaId, open, setOpen, clientData, isClientAvailable, handleSaveSubjectData } = props;

  const [formData, setFormData] = React.useState({ ...clientData });
  const { attachClientLoading } = useSelector((state) => state.allCmas);

  React.useEffect(() => {
    setFormData({ ...clientData });
  }, []);

  const handleAttachClient = () => {
    handleSaveSubjectData();
    dispatch(attachClient({ id: cmaId, formData }));

    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <Dialog disablePortal sx={{position:"absolute","& .MuiDialog-container":{height:"100vh"}}} maxWidth={"lg"} open={open} onClose={() => setOpen(false)}>
      <DialogContent maxWidth="xl" sx={{ padding: "30px 40px" }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <h2 className={classes.clientBoxH2}>{isClientAvailable() ? "Edit" : "Attach"} Client</h2>
          </Grid>
          <Grid item md={6} container justifyContent="flex-end"></Grid>
        </Grid>
        <Grid container spacing={2} className={classes.dashboardActivitiesBox2}>
          <Grid item xs={12}>
            <Typography>
              <h2 className={classes.clientBoxH3}>Client 1</h2>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-basic"
              label="First Name"
              variant="standard"
              value={formData.client0_firstName}
              onChange={(e) => setFormData({ ...formData, client0_firstName: e.target.value.length !== 0 ? e.target.value : null })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-basic"
              label="Last Name"
              variant="standard"
              value={formData.client0_lastName}
              onChange={(e) => setFormData({ ...formData, client0_lastName: e.target.value.length !== 0 ? e.target.value : null })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-basic"
              label="email@example.com"
              variant="standard"
              value={formData.client0_email}
              onChange={(e) => setFormData({ ...formData, client0_email: e.target.value.length !== 0 ? e.target.value : null })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <h2 className={classes.clientBoxH3}>Client 2</h2>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="standard-basic"
              label="First Name"
              variant="standard"
              value={formData.client1_firstName}
              onChange={(e) => setFormData({ ...formData, client1_firstName: e.target.value.length !== 0 ? e.target.value : null })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Last Name"
              variant="standard"
              value={formData.client1_lastName}
              onChange={(e) => setFormData({ ...formData, client1_lastName: e.target.value.length !== 0 ? e.target.value : null })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="standard-basic"
              label="email@example.com"
              variant="standard"
              value={formData.client1_email}
              onChange={(e) => setFormData({ ...formData, client1_email: e.target.value.length !== 0 ? e.target.value : null })}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" sx={{ paddingTop: "40px !important" }}>
            <Button variant="text" onClick={() => setOpen(false)} className={classes.textButton}>
              Cancel
            </Button>
            <LoadingButton
              loading={attachClientLoading}
              loadingPosition="start"
              onClick={handleAttachClient}
              variant="contained"
              className={classes.ContainedButton}
            >
              {isClientAvailable() ? "Edit" : "Attach"}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
export default withStyles(styleSheet, { name: "CreateCmaStyle" })(AttachClient);
