import React from "react";
import { Grid, Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Skeleton from "@mui/material/Skeleton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import AlertSkelton from "./alerts";
import ActivitySkelton from "./activitySkelton";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 18,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 12,
  },
  "& .QontoStepIcon-circle": {
    width: 11,
    height: 11,
    marginLeft: "4px",
    borderRadius: "60%",
    color: "#0B0909",
    backgroundColor: "currentColor",
  },
  "& .QontoStepIcon-label": {
    color: "#0B0909",
    fontSize: "13px",
    marginRight: "5px",
  },
}));

function QontoStepIcon(props) {
  const { active, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <Skeleton height="30px" width="52px" /> <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

function MainDashBoardSkelton(props) {
  const { classes } = props;
  return (
    <Container maxWidth="xl">
      <div className={classes.mainDashboardPage}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Paper elevation={2} className={classes.methodWeatherBox}>
              <div className={classes.methodWeatherTop}>
                <h1 className={classes.methodWeatherTopH1}>
                  <Skeleton variant="rectangular" height="30px" width="270px" />
                </h1>
                <div className={classes.methodWeatherTopInfo}>
                  <div className={classes.methodWeatherTopInfoDes}>
                    <Skeleton height="25px" width="110px" />
                    <Skeleton height="20px" width="110px" />
                    <Skeleton height="20px" width="110px" />
                  </div>
                  <div>
                    <Skeleton height="80px" width="50px" />
                    {/* <WbTwilightIcon /> */}
                  </div>
                </div>
              </div>
              <div className={classes.methodWeatherBottom}>
                <Skeleton variant="rectangular" height="23px" width="70%" />
              </div>
            </Paper>

            <Paper elevation={2} className={classes.boxArea}>
              <h2 className={classes.boxDashboardTitle}>
                {" "}
                <Skeleton variant="rectangular" height="30px" width="40%" />
              </h2>
              <TableContainer className={classes.boxDashboard}>
                <Table sx={{ minWidth: 500 }} size="small" aria-label="simple table">
                  <ActivitySkelton count={5} />
                </Table>
              </TableContainer>
            </Paper>

            <Paper elevation={2} className={classes.boxArea}>
              <h2 className={classes.boxDashboardTitle}>
                <Skeleton variant="rectangular" height="30px" width="40%" />
              </h2>
              <TableContainer className={classes.boxDashboard}>
                <Table sx={{ minWidth: 500 }} size="small" aria-label="simple table">
                  <ActivitySkelton hideAvatar count={5} />
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={5}>
            <Paper elevation={2}>
              <h2 className={classes.boxDashboardTitle}>
                <Skeleton variant="rectangular" height="30px" width="40%" />
              </h2>
              <Box className={classes.boxDashboard}>
                <AlertSkelton count={6} />
              </Box>
            </Paper>

            <Paper elevation={2} className={classes.boxArea}>
              <h2 className={classes.boxDashboardTitle}>
                <Skeleton variant="rectangular" height="30px" width="50%" />
              </h2>
              <Box className={classes.boxDashboard}>
                <Stepper activeStep={3} orientation="vertical" connector={<QontoConnector />}>
                  {Array.from(Array(6).keys()).map((step, index) => (
                    <Step expanded key={step.label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <Skeleton height="25px" width="210px" />
                      </StepLabel>
                      <StepContent TransitionProps={{ unmountOnExit: false }}>
                        <Typography>
                          <Skeleton height="25px" width="110px" />
                        </Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
export default withStyles(styleSheet, { name: "MainDashBoardStyle" })(MainDashBoardSkelton);
