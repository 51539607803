import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { InputLabel, Grid, Card, CardHeader, Box, Typography } from "@mui/material";
import agentEquipmentIcon from "../../../../assets/images/agents/agentEquipmentIcon.png";
import agentAvatar from "../../../../assets/images/agents/agentAvatar.png";

function InventoryItemDetailEquipmentModal(props) {
  let { open, setOpen, classes, equipmentBox } = props;

  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" }}}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle className={classes.appointmentModalHeading} id="alert-dialog-title">
        <Box> Item Detail</Box>
        <Card sx={{ minWidth: "250px", padding: "5px 15px" }}>
          <CardHeader
            className={classes.EquipmentDetailSubBox}
            classes={{
              title: classes.agentInventoryModalCardHeader,
              subheader: classes.agentInventoryModalCardDes,
            }}
            avatar={<img src={agentEquipmentIcon} alt="agent Method Icon White" />}
            title={equipmentBox.inventory?.AssetName}
            subheader={`Serial Number: ${equipmentBox.inventory?.SerialNumber}`}
          />
        </Card>
      </DialogTitle>
      <DialogContent sx={{ width: "550px", minHeight: "28vh" }}>
        <br />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={9} sx={{ zIndex: "1" }}>
            <InputLabel
              sx={{
                fontWeight: "500",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                marginBottom: "8px",
              }}
            >
              Assigned to
            </InputLabel>
            <Card evaluation={2} sx={{ padding: "5px 15px" }}>
              <CardHeader
                className={classes.EquipmentDetailSubBox}
                classes={{
                  title: classes.agentInventoryModalCardHeader,
                  subheader: classes.agentInventoryModalCardDes,
                }}
                avatar={<img src={agentAvatar} alt="agent Avatar" />}
                title="Elena"
                subheader="Ferro"
                action={
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography variant="subtitle2" gutterBottom component="div">
                      Checked Out 6/14/2022
                    </Typography>
                  </Box>
                }
              />
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "10px" }}>
        <Button onClick={() => setOpen(false)} classes={{ root: classes.inventoryDetailCheckIn }} color="inherit" autoFocus>
          Check In
        </Button>
        <Button onClick={() => setOpen(false)} classes={{ root: classes.commisionSelectionButTon }} color="inherit" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styleSheet, {
  name: "InventoryItemDetailEquipmentModalStyle",
})(InventoryItemDetailEquipmentModal);
