export const styleSheet = () => ({
  propertyDetailArea: {
    position: "relative !important",
  },
  closeButton: {
    position: "absolute !important",
    top: "0px !important",
    right: "0px !important",
  },
  propertyHeaderSection: {
    borderBottom: "1px solid lightgrey !important",
    paddingBottom: "7px !important",
  },
  tableHeaderColumn: {
    borderRight: "1px solid rgba(0, 0, 0, 0.30)",
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    textTransform: "uppercase !important",
    color: "#0068E2 !important",
    whiteSpace: "nowrap !important",
  },
  /* stats bar  */
  statsBarHeading: {
    fontSize: "18px !important",
    width: "100%",
    textOverflow: "ellipsis !important",
    overflow: "hidden !important",
    fontWeight: "600 !important",
    color: "#000000 !important",
    lineHeight: "23px !important",
    fontFamily: "AvenirNext !important",
  },
  statsBarCityZip: {
    fontSize: "14px !important",
    color: "#6c6c6c !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext !important",
    "& span": {
      textTransform: "capitalize !important",
    },
  },
  statsBarItemArea: {
    marginTop: "10px",
    marginRight: "30px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "0px 12px !important",
  },
  statsBarItemTitle: {
    fontSize: "17px !important",
    color: "#000000 !important",
    fontFamily: "AvenirNext !important",
    textAlign: "center !important",
    fontWeight: "600 !important",
  },
  statsBarItemValue: {
    fontSize: "14px !important",
    color: "#6c6c6c !important",
    fontFamily: "AvenirNext !important",
    textAlign: "center !important",
    fontWeight: "500 !important",
  },
  /*  end stats bar */
  /* listing state area */
  listingStatsRoot: {
    padding: "10px 0px",
  },
  listingStatsHeading: {
    fontSize: "12px !important",
    color: "#6c6c6c !important",
    fontFamily: "AvenirNext !important",
  },
  listingStatsArea: {
    marginTop: "20px !important",
  },
  listingStatsItem: {
    borderBottom: "1px solid lightgrey",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "7px 0px",
  },
  listingStatsItemLeft: {
    fontSize: "14px !important",
    width: "50% !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  listingStatsItemRight: {
    width: "50% !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  /* end listing area */
  publicRemarks: {
    fontSize: "15px !important",
    padding: "5px 0px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
  },
  /* slider view */
  SliderArea: {
    position: "relative !important",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    paddingTop: "75%",
    overflow: "hidden !important",
  },
  responsiveImage: {
    position: "absolute",
    top: "0 !important",
    left: "0 !important",
    width: "100% !important",
    height: "100% !important",
    zIndex: "5 !important",
    cursor: "pointer !important",
    border: "1px solid lightgray !important",
  },
  sliderSettingArea: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "-6px",
    backgroundColor: "#333333 !important",
    zIndex: "99999999999999 !important",
    height: "80px !important",
    overflow: "hidden !important",
  },
  sliderSettingSlider: {
    width: "calc(100% - 360px)",
    height: "80px",
    margin: "0px 30px",
    backgroundColor: "black",
  },
  sliderSettingButtonsArea: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "300px",
    height: "78px !important",
    zIndex: "99 !important",
  },
  sliderSettingButton: {
    width: "100px !important",
    height: "78px !important",
    background: "black",
    color: "white",
    borderLeft: "1px solid white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0064f5",
    },
  },
  sliderSettingButtonInside: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px",
  },
  sliderSettingButtonInsideDes: {
    fontSize: "14px !important",
    textAlign: "center !important",
    fontFamily: "AvenirNext !important",
  },
  listingBYAgent: {
    fontSize: "17px !important",
    padding: "25px 0px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
  },
  /* end slider view */
  /* property stats */
  propertyHeading: {
    display: "block !important",
    fontSize: "20px !important",
    fontWeight: "600 !important",
    marginTop: "10px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },

  propertyDetailItem: {
    borderBottom: "1px solid lightgrey",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "nowrap",
    height: "100%",
    padding: "7px 0px !important",
  },
  propertyDetailItemHeading: {
    width: "50% !important",
    fontSize: "14px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  propertyDetailItemValues: {
    width: "50% !important",
    fontSize: "16px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  /* end */
  /* location area */
  propertyLocationArea: {
    padding: "20px 0px !important",
  },
  propertyLocationHeading: {
    fontSize: "26px !important",
    fontWeight: "600 !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  propertySubHeader: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    flexWrap: "wrap !important",
    alignItems: "center !important",
  },
  propertySubHeaderItem: {
    fontSize: "14px !important",
    color: "#0064e5 !important",
    fontFamily: "AvenirNext !important",
  },
  propertySubHeaderItemInfo: {
    fontSize: "14px !important",
    color: "black !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    fontFamily: "AvenirNext !important",
  },
  mapListingDisclosure: {
    fontSize: "14px !important",
    color: "#000 !important",
    marginTop: "8px !important",
    fontFamily: "AvenirNext !important",
  },

  /* end */
  /*  payment calculator */
  paymentCalculator: {
    marginTop: "30px",
  },
  paymentCalculatorHeading: {
    fontSize: "26px !important",
    fontWeight: "600 !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
    marginBottom: "10px !important",
  },
  calculatorMainArea: {
    border: "1px solid #dadada !important",
    padding: "40px 30px !important",
  },
  calculatorMainAmount: {
    textAlign: "center !important",
    fontFamily: "AvenirNext !important",
    fontSize: "24px !important",
    color: "#171717 !important",
  },
  calculatorMainInterest: {
    textAlign: "center !important",
    fontFamily: "AvenirNext !important",
    fontSize: "16px !important",
    color: "#171717 !important",
  },
  calculatorBar: {
    height: "8px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "0px 1.5px !important",
    marginTop: "20px",
  },
  calculatorBarInterest: {
    backgroundColor: "#1b39ef !important",
    height: "8px !important",
  },
  calculatorBarTax: {
    backgroundColor: "#59a1fa !important",
    height: "8px !important",
  },
  calculatorBarDues: {
    backgroundColor: "#b0c5e8 !important",
    height: "8px !important",
  },
  calculatorStatsAreaItem: {
    borderBottom: "1px solid #dadada",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    padding: "6px 0px !important",
    "&:last-child": {
      borderBottom: "1px solid transparent !important",
    },
  },
  calculatorStatsAreaItemLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 8px !important",
    color: "#171717 !important",
  },
  calculatorStatsAreaItemLeftContent: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px !important",
    color: "#171717 !important",
  },
  calculatorStatsAreaRight: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px !important",
    color: "#171717 !important",
  },
  fieldLabelCal: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px !important",
    color: "#171717 !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 10px !important",
  },
  calculatorReadMore: {
    marginTop: "20px !important",
    fontFamily: "AvenirNext !important",
    fontSize: "14px !important",
    color: "#171717 !important",
  },
  calculatorReadMoreButton: {
    fontFamily: "AvenirNext !important",
    fontSize: "13px !important",
    textTransform: "capitalize !important",
  },
  /* end */
  /* Property info */
  propertyInfoDetailBoxInner: {
    padding: "16px !important",
    "& ul": {
      paddingLeft: "16px !important",
    },
  },
  propertyInfoHeading: {
    display: "block !important",
    fontSize: "26px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext !important",
    marginTop: "10px !important",
    color: "black !important",
  },
  propertyInfoDetailBoxInnerDes: {
    fontSize: "16px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
    paddingRight: "6px !important",
    display: "inline !important",
  },
  propertyInfoDetailBoxInnerHeading: {
    fontSize: "17px !important",
    display: "inline-block !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  propertyInfoDetailBoxHeading: {
    fontSize: "16px !important",
    borderBottom: "1px solid lightgrey",
    fontFamily: "AvenirNext !important",
    padding: "10px 16px !important",
    backgroundColor: "#f4f4f4 !important",
    color: "black !important",
    fontWeight: "600 !important",
  },
  propertyInfoDetailBoxInnerSubHeading: {
    fontSize: "15px !important",
    color: "black !important",
    fontWeight: "600 !important",
  },
  propertyInfoArea: {
    padding: "20px 0px !important",
  },
  propertyInfoDetailBox: {
    border: "1px solid lightgrey",
  },
  theme1Heading: {
    fontSize: "22px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    color: "#666666 !important",
    marginTop: "40px !important",
  },
  /* end */
  /* Public record section */
  publicRecordsItem: {
    borderBottom: "1px solid lightgrey",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "nowrap",
    marginTop: "7px",
  },
  publicRecordsItemTotal: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "nowrap",
    marginTop: "7px",
  },
  publicRecordsHeading: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  publicRecordsItemHeading: {
    width: "50% !important",
    fontSize: "16px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  publicRecordsItemValues: {
    width: "50% !important",
    fontSize: "16px !important",
    color: "black !important",
    fontFamily: "AvenirNext !important",
  },
  /* end */
  moreInfoHeadingSeo: {
    display: "block !important",
    fontSize: "17px !important",
    marginTop: "20px !important",
    color: "black !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext !important",
  },
  moreInfoDescriptionSeo: {
    display: "block !important",
    fontSize: "17px !important",
    marginTop: "20px !important",
    color: "black !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext !important",
  },
  /* shortcut bar */
  shortCutBarContainer: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "nowrap !important",
    position: "static",
    top: "0px",
  },
  shortCutBarArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 40px !important",
    zIndex: "99 !important",
    flexWrap: "wrap !important",
    height: "40px !important",
  },
  shortCutBarAreaItem: {
    cursor: "pointer !important",
    color: "#6c6c6c !important",
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    padding: "4px 0px !important",
    marginBottom: "0px !important",
    whiteSpace: "nowrap !important",
  },

  /* end  */
});
