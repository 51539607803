import React, { useState, useRef, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import GradeIcon from "@mui/icons-material/Grade";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorIcon from "@mui/icons-material/Error";
import ReportIcon from "@mui/icons-material/Report";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  IconButton,
} from "@mui/material";
import ResponseAlert from "../../../components/responseAlert";
import CircularProgress from "@mui/material/CircularProgress";
import { handleAddAlertTemplate, handleUpdateTemplate, handleDeleteTemplate } from "../../../redux/eventsAndAlerts/template";
import { useDispatch, useSelector } from "react-redux";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
function AddAlertTemplate(props) {
  let { classes, open, setOpen, templateData } = props;
  const alertTitle = useRef();
  const alertBody = useRef();
  const [errMsg, setErrMsg] = useState({});
  const [actionType, setActionType] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const addTemplate = useSelector((item) => item.eventAlerts.templates.addTemplate);
  const updateTemplate = useSelector((item) => item.eventAlerts.templates.updateTemplate);
  const deleteTemplate = useSelector((item) => item.eventAlerts.templates.deleteTemplate);

  useEffect(() => {
    if (addTemplate.success && !addTemplate.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully created the alert template",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!addTemplate.isLoading && addTemplate.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addTemplate.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addTemplate.isLoading]);

  useEffect(() => {
    if (updateTemplate.success && !updateTemplate.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully updated the alert template",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!updateTemplate.isLoading && updateTemplate.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateTemplate.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateTemplate.isLoading]);

  useEffect(() => {
    if (deleteTemplate.success && !deleteTemplate.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully deleted the alert template",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!deleteTemplate.isLoading && deleteTemplate.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(deleteTemplate.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deleteTemplate.isLoading]);

  useEffect(() => {
    if (open === "update" && templateData.updateFlag) {
      setActionType("edit");
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setActionType("");
  };
  const handleUpdateErrMsg = (field, value) => {
    if (errMsg[field] && value) {
      let errors = { ...errMsg };
      delete errors[field];
      setErrMsg(errors);
    }
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!alertTitle?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the alert title";
      errorMsg["alertTitle"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    } else if (!alertBody?.current?.value) {
      isFormValid = false;
      let msg = "Please enter the alert description";
      errorMsg["alertBody"] = msg;
      setErrorAlert({
        errorMsg: msg,
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleCreateTemplate = () => {
    if (handleValidate()) {
      let obj = {
        title: alertTitle.current.value,
        body: alertBody.current.value,
      };
      dispatch(handleAddAlertTemplate(obj));
    }
  };
  const handelUpdateTemplate = () => {
    if (handleValidate()) {
      let obj = {
        id: templateData.id,
        title: alertTitle.current.value,
        body: alertBody.current.value,
      };
      dispatch(handleUpdateTemplate(obj));
    }
  };
  const handleDelete = () => {
    dispatch(handleDeleteTemplate({ id: templateData.id }));
  };

  return (
    <React.Fragment>
      {" "}
      <Dialog
        disablePortal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" } ,"& .MuiDialog-container": {mt: "30px"}}}
      >
        <DialogTitle className={classes.modalHeading} id="alert-dialog-title">
          <Box> {open === "update" ? "Template Detail" : "Create Alert Template"}</Box>
          {open === "update" ? (
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {deleteTemplate.isLoading ? <CircularProgress size={25} /> : ""}
              <ButtonGroups
                id="Manage-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="36px"
                size="medium"
                color="white"
                minWidth="140px"
                options={[
                  {
                    title: "Edit Details",
                    value: "edit",
                    icon: <BorderColorIcon sx={{ color: "black" }} fontSize="small" />,
                  },
                  { title: "Delete Template", value: "delete", icon: <DeleteIcon sx={{ color: "black" }} fontSize="small" /> },
                ]}
                onChangeMenu={(value) => {
                  setActionType(value);
                  if (value === "delete") {
                    handleDelete();
                  }
                }}
                value={actionType}
                placeholder={"Manage"}
                {...props}
              />
            </Stack>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.modalContent} sx={{ pointerEvents: open === "update" && actionType !== "edit" ? "none" : "" }}>
          <Grid container direction={"row"} spacing={3} sx={{ mt: "0px" }}>
            <Grid sm={5} item>
              <TextField
                inputRef={alertTitle}
                onBlur={() => handleUpdateErrMsg("alertTitle", alertTitle.current.value)}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Alert Title"
                error={errMsg.alertTitle}
                helperText={errMsg.alertTitle}
                defaultValue={templateData?.title || ""}
              />
            </Grid>
            <Grid sm={12} item>
              <TextField
                fullWidth
                variant="outlined"
                rows={5}
                multiline
                size="small"
                placeholder="Alert Description"
                inputRef={alertBody}
                onBlur={() => handleUpdateErrMsg("alertBody", alertBody.current.value)}
                error={errMsg.alertBody}
                helperText={errMsg.alertBody}
                defaultValue={templateData?.body || ""}
              />
            </Grid>
            <Grid sm={12} item>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Typography>Select Alert Icon:</Typography>
                <FormControlLabel control={<Switch />} label="No Icon" />
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Box className={classes.selectedIconBox}>
                  <IconButton>
                    <ReportProblemIcon sx={{ color: "black" }} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton>
                    <WarningAmberIcon sx={{ color: "black" }} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton>
                    <ErrorIcon sx={{ color: "black" }} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton>
                    <ReportIcon sx={{ color: "black" }} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton>
                    <GradeIcon sx={{ color: "black" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            color="inherit"
            variant="outlined"
          >
            Cancel
          </Button>
          {open !== "update" ? (
            <LoadingButton
              onClick={handleCreateTemplate}
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={addTemplate.isLoading}
            >
              Create
            </LoadingButton>
          ) : null}
          {actionType === "edit" ? (
            <LoadingButton
              onClick={handelUpdateTemplate}
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={updateTemplate.isLoading}
            >
              Update
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "AddAlertTemplateStyle" })(AddAlertTemplate);
