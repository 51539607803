import React, { useState, useEffect } from "react";
import { Box, Stack, Paper, ListItemText, Chip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import moment from "moment";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getAllTransactions } from "../../../../redux/transactions";
import Avatar from "../../../../components/uiElements/avatar/index";
import TableSkeleton from "../../../../components/loader/tableLoader"  

function ContractTable(props) {
  let { classes, setFilters, filters } = props;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const allTransactions = useSelector((state) => state.transactions.TransactionsList.allTransactions);
  const redirectDetails = (id) => {
    navigate(`/transaction/contracts/contract-detail/${id}/detail-overview`);
  };
  const columns = [
    {
      field: "street",
      headerName: "Address",
      renderCell: (params) => {
        return (
          <ListItemText
            onClick={() => redirectDetails(params.row.id)}
            primary={params?.row?.street}
            secondary={`${params?.row?.city}, ${params?.row?.state}  ${params?.row?.zipcode}`}
          />
        );
      },
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "contract_type",
      headerName: "Type",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Chip
            onClick={() => redirectDetails(params.row.id)}
            sx={{ backgroundColor: params.row.contract_type === "seller" ? "#66BB6A" : "#0288D1", minWidth: "100px", textTransform: "capitalize" }}
            label={params.row.contract_type}
            color="success"
            size="small"
          />
        );
      },
      width: 150,
      editable: false,
      sortable: false,
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      renderCell: (params) => {
        return (
          <CustomTooltip title={params.row.status}>
            <Chip
              onClick={() => redirectDetails(params.row.id)}
              sx={{ backgroundColor: "#000000", minWidth: "100px", textTransform: "capitalize" }}
              label={params.row.status}
              color="success"
              size="small"
            />
          </CustomTooltip>
        );
      },
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "Agent",
      headerName: "Agent",
      sortComparator: (v1, v2) => v1?.first_name.localeCompare(v2?.first_name),
      renderCell: (params) => {
        // console.log(params);
        return (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Avatar
              sx={{fontSize: "14.5px" }}
              src={params.row?.agent?.profile_images?.profile_img_thumbnail}
              variant="rounded"
            >
              {params?.row?.agent?.first_name?.slice(0, 1) + params?.row?.agent?.last_name?.slice(0, 1)}
            </Avatar>
            <ListItemText
              onClick={() => redirectDetails(params.row.id)}
              primary={params?.row?.agent?.first_name}
              secondary={params?.row?.agent?.last_name}
            />
          </Stack>
        );
      },
      width: 170,
      editable: false,
      sortable: true,
    },
    {
      field: "Category",
      headerName: "Documents",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Stack onClick={() => redirectDetails(params.row.id)} sx={{ width: "100%" }}>
            <Box sx={{ textAlign: "right" }}>
              {(
                (Number(params.row?.transaction_documents?.filter((item) => item.status === "approved")?.length || 0) /
                  Number(params.row?.transaction_documents?.filter((item) => item.status !== "exempt")?.length || 0)) *
                100
              )?.toFixed(0)}
              %
            </Box>
            <Box>
              <LinearProgress
                color="inherit"
                variant="determinate"
                value={
                  (Number(params.row?.transaction_documents?.filter((item) => item.status === "approved")?.length || 0) /
                    Number(params.row?.transaction_documents?.filter((item) => item.status !== "exempt")?.length || 0)) *
                  100
                }
              />
            </Box>
            <Box sx={{ textAlign: "right" }}>
              {Number(params.row?.transaction_documents?.filter((item) => item.status === "approved")?.length || 0)} of{" "}
              {Number(params.row?.transaction_documents?.filter((item) => item.status !== "exempt")?.length || "0")} Documents
            </Box>
          </Stack>
        );
      },
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "closing_date",
      headerName: "Closing Date",
      renderCell: (params) => {
        // console.log(params);
        return <Box onClick={() => redirectDetails(params.row.id)}>{moment.utc(params.row.closing_date).format("L")}</Box>;
      },
      width: 100,
      type: "dateTime",
      editable: true,
      sortable: true,
    },
    {
      field: "price",
      headerName: "Price",
      type:"number",
      renderCell: (params) => {
        // console.log(params);
        return <Box onClick={() => redirectDetails(params.row.id)}>${new Intl.NumberFormat().format(params.row.price)}</Box>;
      },
      width: 130,
      editable: false,
      sortable: true,
    },
  ];
  return (
    <Box>
      {" "}
      <br />
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={allTransactions.data && allTransactions.data?.length ? allTransactions.data?.filter((item,pos,self)=>self.findIndex((ite)=>ite.id===item.id)==pos) : []}
          columns={columns}
          loading={allTransactions.isLoading}
          components={{
            LoadingOverlay: TableSkeleton
          }}
          rowHeight={56}
          onRowsScrollEnd={(e) => {
            if (allTransactions.count > e.virtualRowsCount && !allTransactions.isLoading) {
              dispatch(
                getAllTransactions({
                  ...filters,
                  pageNumber: Number(e.virtualRowsCount / 50) + 1,
                  pageSize: 50,
                })
              );
            }
          }}
          disableColumnFilter
          disableColumnMenu
        />
      </Paper>
    </Box>
  );
}
export default ContractTable;
