export const statusData = [
  {
    title: "Setup",
    value: "setup",
  },
  {
    title: "Setup Completed",
    value: "setup-completed",
  },
  {
    title: "Active",
    value: "active",
  },
  {
    title: "Off Boarding",
    value: "off-boarding",
  },
  {
    title: "Departed",
    value: "departed",
  },
];
