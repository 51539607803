import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  MenuItem,
} from "@mui/material";
import { TextField, Grid, InputLabel } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import ResponseAlert from "../../../../components/responseAlert";
import EditIcon from "@mui/icons-material/Edit";
import { NumberFormat } from "../../../../utils/numberFormat";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { updatePublicData } from "../../../../redux/listings/publicData";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
function ListingDetailSpecifications(props) {
  const [open, setOpen] = useState(false);
  let { classes, publicDataDetail, ListingData } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [specificationDetail, setSpecificationDetail] = useState({
    ListingIdMlsType1: "",
    ListingIdMlsType2: "",
    LeasePrice: "",
    ListPrice: "",
    PropertyType: "",
    BedroomsTotal: "",
    BathroomsFull: "",
    BathroomsHalf: "",
    BuildingAreaTotal: "",
    LotSizeAcres: "",
    Stories: "",
    YearBuilt: "",
    MlsType1: "",
    MlsType2: "",
    carportSpace: "",
    garageSpace: "",
    parkingSpace: "",
  });

  const dispatch = useDispatch();
  const update = useSelector((state) => state.listings.publicData.update);
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: `You have successfully updated the specification detail`,
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSave = () => {
    let obj = {
      schema: { ...specificationDetail },
      listing_public_data_id: publicDataDetail.id,
      handleSuccess,
      handleError,
    };
    dispatch(updatePublicData(obj));
  };

  const handleClose = () => {
    setOpen(false);
    setSpecificationDetail({
      ListingIdMlsType1: "",
      ListingIdMlsType2: "",
      LeasePrice: "",
      ListPrice: "",
      PropertyType: "",
      BedroomsTotal: "",
      BathroomsFull: "",
      BathroomsHalf: "",
      BuildingAreaTotal: "",
      LotSizeAcres: "",
      Stories: "",
      YearBuilt: "",
      Matterport: "",
      PublicRemarks: "",
      MlsType1: "",
      MlsType2: "",
      carportSpace: "",
      garageSpace: "",
      parkingSpace: "",
    });
  };

  const handleUpdateDetail = (field, value) => {
    setSpecificationDetail({ ...specificationDetail, [field]: value });
  };
  const handleOpen = () => {
    setOpen(true);
    setSpecificationDetail({
      ...specificationDetail,
      ...publicDataDetail,
    });
  };

  return (
    <React.Fragment>
      <Card className={classes.specificationCard}>
        <CardHeader
          classes={{ title: classes.overViewTabCardHeading }}
          action={
            open ? (
              <Stack direction="row" spacing={0} alignItems={"center"}>
                <LoadingButton
                  sx={{ width: "50px", height: "30px", fontSize: "12px" }}
                  classes={{ root: classes.saveModalButton }}
                  color="inherit"
                  autoFocus
                  loadingPosition="start"
                  loading={update.isLoading}
                  size="small"
                  onClick={handleSave}
                >
                  Save
                </LoadingButton>
                <IconButton onClick={() => handleClose()} size="small">
                  <ClearRoundedIcon />
                </IconButton>
              </Stack>
            ) : (
              <IconButton onClick={handleOpen}>
                <EditIcon size="small" />
              </IconButton>
            )
          }
          sx={{ paddingLeft: "0px", paddingRight: "0px", mb: "12px" }}
          title="Specifications"
        />
        <CardContent className={classes.specificationItemDetail}>
          {open ? (
            <Grid
              container
              direction="row"
              alignItems={"center"}
              sx={{ mb: "8px" }}
              spacing={1}
            >
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  <TextField
                    select
                    size="small"
                    value={specificationDetail?.MlsType1 || "MLS 1"}
                    sx={{
                      "& .MuiOutlinedInput-input": { padding: "3.2px 8px" },
                    }}
                    onChange={(e) =>
                      handleUpdateDetail("MlsType1", e.target.value)
                    }
                  >
                    <MenuItem sx={{ display: "none" }} disabled value="MLS 1">
                      MLS 1
                    </MenuItem>
                    <MenuItem value="FMLS">FMLS</MenuItem>
                    <MenuItem value="GAMLS">GAMLS</MenuItem>
                    <MenuItem value="GALMLS">GALMLS</MenuItem>
                    <MenuItem value="StellarMLS">StellarMLS</MenuItem>{" "}
                    <MenuItem value="MiamiMLS">MiamiMLS</MenuItem>
                    <MenuItem value="CanopyMLS">CanopyMLS</MenuItem>
                    <MenuItem value="FlexMLS">FlexMLS</MenuItem>
                    <MenuItem value="BeachesMLS">BeachesMLS</MenuItem>
                  </TextField>
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="7205379"
                  value={specificationDetail.ListingIdMlsType1}
                  onChange={(e) =>
                    handleUpdateDetail("ListingIdMlsType1", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.2px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  <TextField
                    select
                    size="small"
                    value={specificationDetail?.MlsType2 || "MLS 2"}
                    sx={{
                      "& .MuiOutlinedInput-input": { padding: "3.2px 8px" },
                    }}
                    onChange={(e) =>
                      handleUpdateDetail("MlsType2", e.target.value)
                    }
                  >
                    <MenuItem sx={{ display: "none" }} value="MLS 2">
                      MLS 2
                    </MenuItem>
                    <MenuItem value="FMLS">FMLS</MenuItem>
                    <MenuItem value="GAMLS">GAMLS</MenuItem>
                    <MenuItem value="GALMLS">GALMLS</MenuItem>
                    <MenuItem value="StellarMLS">StellarMLS</MenuItem>{" "}
                    <MenuItem value="MiamiMLS">MiamiMLS</MenuItem>
                    <MenuItem value="CanopyMLS">CanopyMLS</MenuItem>
                    <MenuItem value="FlexMLS">FlexMLS</MenuItem>
                    <MenuItem value="BeachesMLS">BeachesMLS</MenuItem>
                  </TextField>
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="10069250"
                  value={specificationDetail.ListingIdMlsType2}
                  onChange={(e) =>
                    handleUpdateDetail("ListingIdMlsType2", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.2px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  {ListingData?.is_rental ? "Lease Price:" : "List Price:"}{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="$10,200,000"
                  value={
                    ListingData?.is_rental
                      ? specificationDetail.LeasePrice
                      : specificationDetail.ListPrice
                  }
                  onChange={(e) =>
                    handleUpdateDetail(
                      ListingData?.is_rental ? "LeasePrice" : "ListPrice",
                      e.target.value
                    )
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.2px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Property Type:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Single Family Residence"
                  value={specificationDetail.PropertyType}
                  onChange={(e) =>
                    handleUpdateDetail("PropertyType", e.target.value)
                  }
                  variant="outlined"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}> Beds: </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="5"
                  value={specificationDetail.BedroomsTotal}
                  onChange={(e) =>
                    handleUpdateDetail("BedroomsTotal", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.5px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Full Baths:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="4"
                  value={specificationDetail.BathroomsFull}
                  onChange={(e) =>
                    handleUpdateDetail("BathroomsFull", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Half Baths:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="2"
                  value={specificationDetail.BathroomsHalf}
                  onChange={(e) =>
                    handleUpdateDetail("BathroomsHalf", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}> Sqft: </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="3,602"
                  value={specificationDetail.BuildingAreaTotal}
                  onChange={(e) =>
                    handleUpdateDetail("BuildingAreaTotal", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.1px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Acerage:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="0.345"
                  value={specificationDetail.LotSizeAcres}
                  onChange={(e) =>
                    handleUpdateDetail("LotSizeAcres", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.2px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Stories:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="2"
                  value={specificationDetail.Stories}
                  onChange={(e) =>
                    handleUpdateDetail("Stories", e.target.value)
                  }
                  variant="outlined"
                  type="number"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Year Built:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="2019"
                  value={specificationDetail.YearBuilt}
                  onChange={(e) =>
                    handleUpdateDetail("YearBuilt", e.target.value)
                  }
                  variant="outlined"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Carport Spaces:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="1"
                  value={specificationDetail.carportSpace}
                  onChange={(e) =>
                    handleUpdateDetail("carportSpace", e.target.value)
                  }
                  variant="outlined"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Garage Spaces:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="1"
                  value={specificationDetail.garageSpace}
                  onChange={(e) =>
                    handleUpdateDetail("garageSpace", e.target.value)
                  }
                  variant="outlined"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.inputLabel}>
                  {" "}
                  Parking Spaces:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="2"
                  value={specificationDetail.parkingSpace}
                  onChange={(e) =>
                    handleUpdateDetail("parkingSpace", e.target.value)
                  }
                  variant="outlined"
                  sx={{ "& .MuiOutlinedInput-input": { padding: "3.3px 8px" } }}
                />
              </Grid>
            </Grid>
          ) : (
            <List disablePadding>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.ListingIdMlsType1 || ""}
              >
                <ListItemText
                  primary={`${publicDataDetail?.MlsType1 || "MLS 1"} #:`}
                />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.ListingIdMlsType2 || ""}
              >
                <ListItemText
                  primary={`${publicDataDetail?.MlsType2 || "MLS 2"} #:`}
                />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={
                  ListingData?.is_rental
                    ? publicDataDetail?.LeasePrice
                      ? NumberFormat({
                          number: publicDataDetail?.LeasePrice,
                          maximumFractionDigits: 2,
                        })
                      : ""
                    : publicDataDetail.ListPrice
                    ? NumberFormat({
                        number: publicDataDetail?.ListPrice,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
              >
                <ListItemText
                  primary={
                    ListingData?.is_rental ? "Lease Price:" : "List Price:"
                  }
                />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.PropertyType || ""}
              >
                <ListItemText primary="Property Type:" />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.BedroomsTotal || ""}
              >
                <ListItemText primary={"Beds:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.BathroomsFull || ""}
              >
                <ListItemText primary={"Full Baths:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.BathroomsHalf || ""}
              >
                <ListItemText primary={"Half Baths:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.BuildingAreaTotal || ""}
              >
                <ListItemText primary={"Sqft:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.LotSizeAcres || ""}
              >
                <ListItemText primary={"Acerage:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.Stories || ""}
              >
                <ListItemText primary={"Stories:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.YearBuilt || ""}
              >
                <ListItemText primary={"Year Built:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.carportSpace || ""}
              >
                <ListItemText primary={"Carport Spaces:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.garageSpace || ""}
              >
                <ListItemText primary={"Garage Spaces:"} />
              </ListItem>
              <ListItem
                disablePadding
                secondaryAction={publicDataDetail?.parkingSpace || ""}
              >
                <ListItemText primary={"Parking Spaces:"} />
              </ListItem>
            </List>
          )}
        </CardContent>
      </Card>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, {
  name: "ListingDetailSpecificationsStyle",
})(ListingDetailSpecifications);
