export const styleSheet = () => ({
  dashboardAccordion: {
    boxShadow: "0px 0px 0px 1px #E0E0E0 !important",
    background: "white",
    borderRadius: "4px",
    padding: "16px",
    minHeight: '200px',
    maxHeight: "400px",
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  AccordionCard: {
    "& .accordionPaperLayout": {
      border: "3px solid #4CAF50 !important",
      boxShadow: "none",
      "& .MuiSvgIcon-root": {
        display: "block",
        color: "#4CAF50",
        margin: "5px 10px",
        fontSize: "2.5rem",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
  SelectedComparables: {
    position: 'relative',
    "& .accordionPaperLayout": {
      border: "3px solid #4CAF50 !important",
      boxShadow: "none",
      "& .MuiSvgIcon-root": {
        display: "block",
        color: "#4CAF50",
        margin: "5px 10px",
        fontSize: "2.5rem",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "none",
      cursor: "pointer",
      position: "absolute",
      zIndex: 999,
      right: "-10px",
      top: "12px",
      fontSize: "30px"
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        display: "block",
      },
    },
  },
  dashboardActivitiesBox: {
    marginTop: "30px",
    padding: "15px",
    paddingBottom: "30px",
  },
  "@media (max-width: 575px)": {
    dashboardActivitiesBox: {
      maxWidth: "280px",
      overflowX: "scroll",
    },
  },
  dashboardActivitiesBoxH2: {
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: 'AvenirNext!important',
    fontSize: '24px',
  },
  uploadPhotoButton: {
    fontFamily: 'AvenirNext!important',
    fontSize: '14px!important'
  },
  clientBoxH2: {
    fontWeight: "400",
    fontSize: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "10px",
    fontFamily: 'AvenirNext!important'
  },
  dashboardActivitiesBoxS: {
    fontWeight: "600",
    fontSize: "22px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0",
    marginBottom: "10px",
    fontFamily: 'AvenirNext!important'
  },
  dashboardActivitiesBoxH3: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "500",
    margin: "0",
  },
  clientBoxH3: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "20px",
    marginBottom: "0px",
  },
  dashboardActivitiesBoxH4: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "17px",
    fontWeight: "500",
    margin: "0",
    textAlign: "center",
    paddingTop: "20px",
  },
  dashboardAccordionSummary: {
    alignItems: "baseline !important",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  outlineButton: {
    color: "black !important",
    border: "1px solid rgba(33, 33, 33, 0.4) !important",
    height: "40px",
    borderRadius: '10px !important'
  },
  accordionPrice: {
    margin: "0",
    fontSize: "20px",
    fontWeight: "500",
  },
  spanGreen: {
    color: "#4CAF50",
  },
  "& .MuiTypography-subtitle1": {
    fontSize: "18px",
    lineHeight: "28px",
  },
  dashboardActivitiesBox2: {
    paddingBottom: "10px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  dashboardActivitiesBoxs: {
    margin: "0 auto !important",
    paddingBottom: "10px",
    width: "90% !important",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  textButton: {
    marginRight: "20px !important",
    height: "40px !important",
    color: "black !important",
    fontFamily: 'AvenirNext!important',
    fontWeight: '600!important'
  },
  ContainedButton: {
    background: "black !important",
    color: "white !important",
    height: "40px !important",
    width: '123px',
    fontFamily: 'AvenirNext!important',
    fontWeight: '600!important'
  },
  listHeaderBtn: {
    margin: "30px 0px !important",
    border: "1px solid black !important",
    "&:hover": {
      color: "white !important",
      background: "black !important",
      border: "1px solid black !important",
    },
  },
  tabsHeaderBtn: {
    "& .MuiSvgIcon-root": {
      width: '20px',
      height: '20px',
      marginRight: '8px'
    },
    "&:hover": {
      background: "#0B0909!important",
    },
    background: "#0B0909!important",
    color: "#fff!important",
    height: "35px!important",
    width: "175px!important",
    borderRadius: "9px!important",
    fontSize: '14px !important',
    fontFamily: 'AvenirNext!important',
    fontWeight: '600!important',
    justifyContent: 'center!important',
    alignItems: 'center!important',
    lineHeight: '21px!important'
  },
  tableImg: {
    "& img": {
      width: "150px",
      height: "47px",
      objectFit: "cover",
      borderRadius: "10px",
      boxShadow: "0px 0px 0px 1px #E0E0E0",
    },
  },
  mediaUrlBox: {
    height: '430px'
  },
  dashboardTabSelect: {
    display: 'flex',
    gap: '10px',
    paddingTop: '4px',
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root":{
      fontSize:'13px !important',
      minHeight:'40px !important',
    },
    "& .MuiTabs-scroller":{
      height:'40px  !important'
    }
  },
  expandCursor: {
    cursor: "pointer",
  },
  filterBox: {
    border: "1px solid rgba(0, 0, 0, 0.3)!important",
    background: "#fff",
    height: "180px",
    padding: "14px!important",
    borderRadius: "10px!important",
    fontFamily: 'AvenirNext!important',
    "& .filter-legend": {
      padding: "0px 10px"
    }
  },
  counter: {
    display: "flex",
    alignItems: "center",
    fontFamily: 'AvenirNext!important',
    borderRadius: "5px",
    background: "white",
    border: "1px solid rgba(33, 33, 33, 0.4)",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
  },
  counterP: {
    fontSize: "17px",
    fontFamily: 'AvenirNext!important',
    margin: "0px",
    lineHeight: "20px",
    padding: "8.5px 5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  plus: {
    width: "25%",
    cursor: "pointer",
  },
  count: {
    width: "50%",
    borderLeft: "1px solid rgba(33, 33, 33, 0.4)",
    borderRight: "1px solid rgba(33, 33, 33, 0.4)",
  },
  minus: {
    width: "25%",
    cursor: "pointer",
  },
  bottomHead: {
    fontSize: "15px",
  },
  cardTitle: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    padding: "12px !important",
    paddingBottom: "0px !important",
    fontFamily: 'AvenirNext!important',  
  },
  cardSubTitle: {
    fontSize: "14px !important",
    padding: "4px 12px !important",
    fontFamily: 'AvenirNext!important',  
  },
  descSection: {
    display: "flex",
    justifyContent: 'space-between',
    fontFamily: 'AvenirNext!important',  
    alignItems: "center",
    gap: "5px",
    padding: "12px",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  },
  descSectionInput: {
    outline: "none",
    border: "none",
    fontSize: "17px",
    textAlign: "end",
    color: 'rgba(0, 0, 0, 0.42)',
  },
  descSectionPositive: {
    fontSize: "17px",
    textAlign: "end",
    color: '#4CAF50',
  },
  descSectionNegative: {
    fontSize: "17px",
    textAlign: "right",
    color: '#E65100',
  },
  descSectionZero: {
    fontSize: "17px",
    alignItems: "flex-end",
    color: 'rgba(0, 0, 0, 0.42)',
  },
  "& .MuiCardActionArea-root": {
    cursor: "revert !important",
    background: "white !important",
  },
  houseAreaStrong: {
    fontWeight: "600",
  },
  houseArea: {
    width: "45%",
    fontWeight: "500",
    marginRight: "15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  houseDesc: {
    width: "55%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "end",    
    fontSize: "17px",
    alignItems: "flex-end",
    color: 'rgba(0, 0, 0, 0.42)',
  },
  cardHeading: {
    fontSize: "16px",
    fontWeight: "600",
    margin: "10px",
    fontFamily: "AvenirNext !important",
  },
  housePrice: {
    width: "25%",
    textAlign: "end",
  },
  grayPrice: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  orangePrice: {
    color: "#E65100",
  },
  greenPrice: {
    color: "#4CAF50",
  },
  houseDescTwo: {
    fontWeight: "500",
    width: "75%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  priceSection: {
    margin: "20px 10px",
    display: "flex",
    alignItems: "center",
  },
  priceDescTwo: {
    fontWeight: "600",
    width: "60%",
    textAlign: "end",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "AvenirNext !important",
  },
  housePriceTwo: {
    width: "40%",
    textAlign: "end",
  },
  AdjustmentsCards: {
    "& .MuiPaper-root": {
      margin: "10px",
    },
  },
  cardPages: {
    fontWeight: "400",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0px 20px",
  },
  cardPagesMargin: {
    margin: "20px 0px !important",
  },
  pageMarginTop: {
    marginTop: "20px !important",
  },
  pricingRange: {
    fontFamily: 'AvenirNext!important',
    fontWeight: '500!important',
    fontSize: '20px',
    padding: "13px 0px",
    "& strong": {
      fontWeight: "600",
    },
  },
  pricingRangeCenter: {
    fontFamily: 'AvenirNext!important',
    fontWeight: '500!important',
    fontSize: '20px',
    padding: "13px 0px",
    textAlign: "center",
    "& strong": {
      fontWeight: "600",
    },
  },
  pricingRangeInput: {
    fontFamily: 'AvenirNext!important',
    fontWeight: '500!important',
    fontSize: '20px',
    display: "flex",
    alignItems: "end",
    "& .MuiFormControl-root": {
      marginLeft: "20px",
    },
    "& input": {
      fontFamily: 'AvenirNext!important',    
      fontWeight: '500!important',
      fontSize: '20px',
    },    
    "& placeholder": {
      fontFamily: 'AvenirNext!important',    
      fontWeight: '500!important',
      fontSize: '20px',
    },
  },
  tooltipCard: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white !important",
      padding: "10px !important",
      borderRadius: "5px !important",
      color: "black !important",
    },
  },
  mapModal: {
    width: "100%",
    display: "flex",
    height: '126px!important',
  },
  mapModalImg: {
    borderRadius: "5px",
    marginRight: '15px'
  },
  mapModalData: {
    width: "330px",
    display: "flex!important",
    justifyContent: "space-between!important",
  },
  mapModalBottom: {
    display: "flex",
    alignItems: "center",
    marginTop: '8px',
    justifyContent: "space-between",
  },
  "&.slick-next": {
    "&:before": {
      content: "wedxe3d",
    },
  },
  tableCointainer: {
    height: "100vh", 
    width: "100%",
    margin: '0px auto',
    "& .mediaImage": {
      width: "99px",
      height: "75px",
      objectFit: "cover",
      borderRadius: "5px",
    },
  },
  slider: {
    height: '240px !important'
  },

  /*** Adjustment Page */
  card: {
    padding: '15px',
  },
  cardContent: {
    display: 'flex',
    gap: '20px',
  },

  /* Client Box */
  clientBox: {
    display: 'flex',
    gap: '20px',
    marginRight: '20px',
    alignItems: 'center',
    padding: '4px 0px'
  },
  clientTitle: {
    fontWeight: '600!important',
    fontFamily: 'AvenirNext!important',
  },
  clientDetails: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'AvenirNext!important',
  },
  clientDetailsText: {
    fontFamily: 'AvenirNext!important',
  }
});
