import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Chip, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import UserSelection from "./userSelection";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { addTransactionDocument } from "../../../redux/transactions/addTransactionDocument";

function AddTransactionDocument(props) {
  let { open, setOpen, classes, id, ContractData, doc_type } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [documentDetail, setTDocumentDetail] = useState({
    name: "",
    comment: "",
    document: "",
  });
  const dispatch = useDispatch();
  const addDocument = useSelector((item) => item.transactions.TransactionsContract.addDocument);

  useEffect(() => {
    if (addDocument.success && !addDocument.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the document",
        errorType: "success",
        isOpen: true,
      });
      setTDocumentDetail({ comment: "", name: "", document: "" });
      setOpen(false);
    } else if (!addDocument.isLoading && addDocument.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(addDocument.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addDocument.success]);
  const handleUpdateDetail = (field, value) => {
    setTDocumentDetail({ ...documentDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!documentDetail.name) {
      isFormValid = false;
      errorMsg["name"] = "Please enter the document name";
      setErrorAlert({
        errorMsg: "Please enter the document name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!documentDetail.document) {
      isFormValid = false;
      errorMsg["document"] = "Please select the document";
      setErrorAlert({
        errorMsg: "Please select the document",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleAddDoc = () => {
    if (handleValidate()) {
      const Data = new FormData();
      Data.append("transactionid", ContractData.id);
      Data.append("type", doc_type);
      Data.append("from", "transaction_contracts");
      Data.append("is_rental", ContractData?.contract_type === "landlord" ? true : false);
      Data.append("comment", documentDetail.comment);
      Data.append("address", ContractData.property_address);
      Data.append("document", documentDetail.document);
      Data.append("name", documentDetail.name);
      dispatch(addTransactionDocument(Data));
    }
  };
  const handleFileChange = (e) => {
    handleUpdateDetail("document", e.target.files[0]);
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-titlesd">
          Add Transaction Document
        </DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <br />
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Document Name
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder="Document name"
                variant="outlined"
                size="small"
                value={documentDetail.name}
                onChange={(e) => handleUpdateDetail("name", e.target.value)}
                error={errMsg.name}
                helperText={errMsg.name}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Comments
              </InputLabel>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                multiline
                rows={4}
                placeholder="Enter comments"
                variant="outlined"
                size="small"
                value={documentDetail.comment}
                onChange={(e) => handleUpdateDetail("comment", e.target.value)}
                error={errMsg.comment}
                helperText={errMsg.comment}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Upload Document
              </InputLabel>
              <Button variant="outlined" color="inherit" sx={{ textTransform: "capitalize" }} component="label">
                Choose File
                <input hidden accept=".pdf" onChange={handleFileChange} multiple type="file" />
              </Button>
              {errMsg.document && <div style={{ color: "red" }}>Please select Document first</div>}

              <br />
              <br />
              <Stack direction="row" spacing={1}>
                {documentDetail.document && (
                  <Chip label={documentDetail.document?.name} variant="outlined" onDelete={() => handleUpdateDetail("document", "")} />
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={addDocument.isLoading}
            className={classes.saveModalButton}
            color="inherit"
            onClick={() => handleAddDoc()}
            autoFocus
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddTransactionDocumentStyle" })(AddTransactionDocument);
