export const styleSheet = () => ({
  pageRoot: {
    padding: "70px 0px !important",
    minHeight: "100vh  !important",
    background: "#f1f1f1 !important",
  },
  PageHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageHeading: {
    fontSize: "17px !important",
    fontWeight: "500 !important",
    textTransform: "uppercase !important",
  },
  composeButton: {
    borderRadius: "13px !important",
    background: "linear-gradient(90deg, #CF00C7 0%, #5900B2 100%) !important",
    boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25) !important",
    textTransform: "capitalize !important",
    fontSize: "14px !important",
  },
  emailSection: {
    display: "flex !important",
    gap: "20px !important",
    marginTop: "20px",
  },
  emailListSection: {
    width: "380px !important",
  },
  /* email list section */
  searchInput: {
    background: "white !important",
    borderRadius: "13px !important",
    "& .MuiOutlinedInput-root": {
      height: "48px !important",
      borderRadius: 13, // Set border radius
      "& fieldset": {
        borderColor: "transparent", // Remove default border color
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Remove hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Remove focused border color
      },
    },
  },
  emailsListArea: {
    padding: "5px",
    background: "white",
    borderRadius: "13px",
    height: "100vh",
    maxHeight: "886px",
    marginTop: "15px",
  },
  emailsList: {
    height: "100%",
    borderRadius: "13px",
    padding: "10px",
    overflow: "auto",
    "&::-webkit-scrollbar-thumb": {
      background: "#E8E8E8",
      borderRadius: "25px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
    },
  },
  emailsListItem: {
    padding: "10px",
    borderRadius: "14px",
    border: "1px solid rgba(0, 0, 0, 0.30)",
    background: "#FFF",
    marginBottom: "12.5px",
    cursor: "pointer",
    "&:hover": {
      background: "white",
      border: "1px solid black",
    },
  },
  itemHeader: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  itemHeaderLeft: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    gap: "10px",
  },
  profileDetail: {
    display: "flex",
    flexDirection: "column",
  },
  profileDetailName: {
    fontSize: "14px  !important",
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.60) !important",
  },
  profileDetailAddress: {
    fontSize: "14px  !important",
    fontWeight: "700 !important",
    color: "#000 !important",
    width: "270px !important",
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  itemHeaderRight: {
    position: "absolute",
    top: "2px",
    right: "0px",
    fontSize: "12px  !important",
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.40) !important",
    whiteSpace: "nowrap !important",
  },
  itemBottomDetail: {
    display: "flex",
    gap: "5px",
    marginTop: "5px",
    alignItems: "flex-end",
  },
  itemSubjectArea: {
    flex: 1,
    width: "calc(100% - 80px)",
  },
  itemSubject: {
    fontSize: "13px  !important",
    fontWeight: "400 !important",
    color: "#000 !important",
  },
  itemMessage: {
    fontSize: "12px  !important",
    fontWeight: "400 !important",
    color: "#000 !important",

    display: "-webkit-box !important",
    "-webkit-line-clamp": "2 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  itemAttachmentArea: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "5px",
  },
  /* email list end section */

  /* email detail section */
  emailDetailSection: {
    padding: "15px",
    paddingRight: "10px",
    background: "white",
    borderRadius: "13px",
    height: "calc(100vh + 64px)",
    maxHeight: "950px",
    width: "calc(100% - 400px) !important",
    flex: 1,
  },
  detailMainHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailMainHeading: {
    color: "#000 !important",
    fontSize: "24px !important",
    fontWeight: "500 !important",
  },
  subjectProfileDetailName: {
    fontSize: "16px  !important",
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.60) !important",
    "& span": {
      fontSize: "14px  !important",
    },
  },
  subjectDetailAddress: {
    fontSize: "13px  !important",
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.60) !important",
    whiteSpace: "nowrap !important",
  },
  emailDetailSectionInner: {
    display: "flex  !important",
    flexDirection: "column !important",
    gap: "15px !important",
    height: "100%",
  },
  DetailTopSection: {
    flex: 1,
    overflow: "auto",
    paddingRight: "5px",
    "&::-webkit-scrollbar-thumb": {
      background: "#E8E8E8",
      borderRadius: "25px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      marginLeft: "10px",
    },
  },
  attachmentSection: {
    height: "265px   !important",
    minHeight: "230px !important",
    padding: "18px 0px   !important",
    borderTop: "1px solid rgba(0, 0, 0, 0.10)",
    width: "100% !important",
  },
  attachmentHeading: {
    color: "#000 !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
  },
  attachmentsItem: {
    width: "230px",
    minWidth: "230px",
    height: "190px",
    borderRadius: "22px",
    border: "0.5px solid  rgba(0, 0, 0, 0.20)",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden !important",
    cursor: "pointer !important",
  },
  attachments: {
    marginTop: "14px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "15px",
    overflowX: "auto",
    overflowY: "hidden",
    paddingBottom: "10px",
    "&::-webkit-scrollbar": {
      height: "10px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888 !important",
      borderRadius: "5px !important",
    },
  },
  attachmentTitleArea: {
    background: "#F2F2F2 !important",
    height: "47px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 10px",
    gap: "5px",
    "& img": {
      height: "25px",
      width: "25px",
    },
  },
  attachmentTitleContent: {
    fontSize: "12px !important",
    fontFamily: "AvenirNext !important",
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },

  /* email detail end section */

  /* send email form */
  sendEmailForm: {
    position: "relative",
    padding: "25px",
  },
  closeIcon: {
    position: "absolute !important",
    top: "6px !important",
    right: "6px !important",
  },
  autocompleteLabel: {
    "& .MuiTypography-root": {
      color: "#000000DE  !important",
    },
  },
  customInput: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #949494",
    "& .MuiAutocomplete-inputRoot": { paddingRight: "0px" },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  CustomTextField: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #949494 !important", // Adjust the default border color
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "1px solid #949494  !important", // Remove the bold border on hover
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #949494 !important", // Customize the active border color
    },
    "& .MuiInputBase-input": {
      fontSize: "14px !important",
      height: "25px",
    },
  },
  agentSelection: {
    borderBottom: "1px solid #949494",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "5px 0px",
  },
  selectAgentInput: {
    background: "#f4f4f4  !important",
    borderRadius: "6px !important",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
  questionMarks: {
    background: "#D9D9D9 !important",
    width: "24px !important",
    height: "24px !important",
    borderRadius: "50% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  fieldsSectionsArea: {
    display: "flex !important",
    flexDirection: "column !important",
    gap: "20px !important",
    position: "relative",
  },
  quillEditor: {
    "& .ql-editor": {
      "&::-webkit-scrollbar-thumb": {
        background: "#E8E8E8",
        borderRadius: "25px",
      },
      "&::-webkit-scrollbar-track": {
        background: "white",
      },
      "&::-webkit-scrollbar": {
        width: "10px",
      },
    },
  },
  modalFooter: {
    background: "#F2F2F2 !important",
    height: "68px !important",
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    padding: "5px 20px !important",
  },
  sendButton: {
    borderRadius: "10px !important",
    background: "linear-gradient(90deg, #CF00C7 0%, #5900B2 100%) !important",
    boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25) !important",
    textTransform: "uppercase !important",
    fontSize: "14px !important",
    minWidth: "112px  !important",
    height: "40px",
  },
  StartLabel: {
    marginRight: "10px !important",
    marginLeft: "1px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontFamily: "AvenirNext !important",
    "& .MuiTypography-root": {
      color: "rgba(0, 0, 0, 0.87) !important",
      fontFamily: "AvenirNext !important",
    },
  },
  attachmentTab: {
    background: "#E7E7E7 !important",
    borderRadius: "5px !important",
    width: "50% !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    paddingLeft: "15px !important",
  },
  attachmentTabContent: {
    color: "#0057FF !important",
    fontWeight: "500 !important",
    cursor: "pointer !important",
  },
  dropzoneAreaInner: {
    fontSize: "18.8px",
    color: "rgba(0, 0, 0, 0.50)",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "0px",
    left: "0px",
    background: "white",
    width: "100%",
    height: "100%",
    borderRadius: "8px !important",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
});
