import React, { useState, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Box,
  Stack,
  OutlinedInput,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { DataGridPro } from "@mui/x-data-grid-pro";
import UpdatePointActivity from "../../modals/updateActivity";
import { useDispatch, useSelector } from "react-redux";
import { getAgentPoints } from "../../../../../redux/agents/agentRosterDetail/points";
import { useParams } from "react-router-dom";
import TableSkeleton from "../../../../../components/loader/tableLoader.jsx";
import SearchIcon from "@mui/icons-material/Search";
const moment = require("moment-timezone");
export const getLastMonths = (text) => {
  let lastMonths = [];
  var monthName = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  let d = new Date();
  d.setDate(1);

  for (let i = 0; i <= 11; i++) {
    let obj = {
      title:
        i === 0
          ? text
            ? text
            : "This Month"
          : monthName[d.getMonth()] + " " + d.getFullYear(),
      value:
        i === 0
          ? "current"
          : moment(
              `${d.getFullYear()}${`${moment(d).format("MM") + 1}`}${moment(
                d
              ).format("DD")}`,
              `YYYYMMDD`
            )
              .format()
              .slice(0, 10),
    };
    lastMonths.push(obj);
    d.setMonth(d.getMonth() - 1);
  }
  return lastMonths;
};

function PointsHistory(props) {
  const [open, setOpen] = useState(false);
  const [month, setMonth] = useState("current");
  const [pointData, setPointData] = useState({});
  const [searchText, setSearchText] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
    search: "",
  });

  let { classes, userId, disableAccess } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const pointsData = useSelector(
    (state) => state.agentRoster.points.pointsData
  );

  useEffect(() => {
    if (!pointsData.isLoading && !pointsData.isLimitReached) {
      if (month === "current") {
        let startDate =
          moment().startOf("month").format().slice(0, 11) + "00:00:01";
        let endDate =
          moment().endOf("month").format().slice(0, 11) + "23:23:59";
        dispatch(
          getAgentPoints({
            user_id: id || userId,
            startDate,
            endDate,
            ...filters,
          })
        );
      } else {
        let startDate =
          moment(month).startOf("month").format().slice(0, 11) + "00:00:01";
        let endDate =
          moment(month).endOf("month").format().slice(0, 11) + "23:23:59";
        dispatch(
          getAgentPoints({
            user_id: id || userId,
            ...filters,
            startDate,
            endDate,
          })
        );
      }
    }
  }, [month, filters]);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      renderCell: ({ row }) =>
        moment(row.createdAt).tz("America/New_York").format("l"),
      minWidth: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "Activity",
      headerName: "Activity",
      renderCell: ({ row }) => {
        return (
          <Box>
            <Box sx={{ fontWeight: "500", display: "inline-block" }}>
              {row?.title || row?.activity}:
            </Box>{" "}
            {row.description}
          </Box>
        );
      },
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "Earned",
      headerName: "Earned",
      renderCell: ({ row }) => {
        return row.point > 0 ? (
          <Box sx={{ color: "#2E7D32" }}>+{row.point}</Box>
        ) : (
          ""
        );
      },
      minWidth: 80,
      editable: false,
      sortable: true,
    },
    {
      field: "Lost",
      headerName: "Spent/Lost",
      renderCell: ({ row }) => {
        return row.point < 0 ? (
          <Box sx={{ color: "#DA0000" }}>-{row.point}</Box>
        ) : (
          ""
        );
      },
      minWidth: 80,
      editable: false,
      sortable: true,
    },
    {
      field: "Balance",
      headerName: "Balance",
      renderCell: ({ row }) => {
        let index = pointsData.data.findIndex((item) => item.id === row.id);
        let newArray = pointsData.data.slice(0, index);
        let newBalance = newArray.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.point) || 0,
          0
        );
        return (
          <Box>
            {Number(pointsData?._metadata?.total_point || 0) - newBalance}
          </Box>
        );
      },
      minWidth: 100,
      editable: false,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (searchText !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ pageNumber: 1, pageSize: 15, search: searchText }),
        2000
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchText]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <Stack direction="row" spacing={2}>
          <Typography>Points History </Typography>
          {disableAccess ? null : (
            <Button
              size="small"
              onClick={() => setOpen(true)}
              variant="contained"
              className={classes.addPointButton}
              startIcon={<AddRoundedIcon />}
            >
              Add
            </Button>
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            value={month}
            onChange={(e) => {
              setFilters({
                pageNumber: 1,
                pageSize: 15,
              });
              setMonth(e.target.value);
            }}
            select
            size="small"
            sx={{
              minWidth: "170px",
              "& .MuiSelect-outlined": { padding: "6.4px 14px" },
              bgcolor: "rgba(226, 226, 226, 1)",
              borderRadius: "4px",
              "& fieldset": { border: "none" },
              cursor: "pointer",
            }}
          >
            {getLastMonths().map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
          <OutlinedInput
            variant="outlined"
            placeholder="Search Points"
            size="small"
            sx={{ height: "36px", width: "190px" }}
            value={searchText === false ? "" : searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            }
          />
        </Stack>
      </Stack>
      <Box className={classes.tableRoot}>
        <DataGridPro
          rows={pointsData.data}
          columns={columns}
          onRowsScrollEnd={(e) => {
            if (
              pointsData._metadata?.count > e.virtualRowsCount &&
              !pointsData.isLoading
            ) {
              setFilters({
                ...filters,
                pageNumber: Number(e.virtualRowsCount / 15) + 1,
                pageSize: 15,
              });
            }
          }}
          hideFooter
          loading={pointsData.isLoading}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowClick={({ row }) => {
            if (!disableAccess) {
              setOpen("update");
              setPointData(row);
            }
          }}
          disableSelectionOnClick
          rowHeight={56}
        />
      </Box>
      <UpdatePointActivity
        open={open}
        setOpen={setOpen}
        pointData={pointData}
        month={month}
        {...props}
        allActivities={pointsData.allActivities}
      />
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "pointsHistoryStyle",
})(PointsHistory);
