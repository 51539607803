import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { styleSheet } from "./agentStatisticsStyle";
import { formattedBigNumbers } from "../../../../../utils/formatBigNumber";

const AgentStatistics = ({ list, fields, classes }) => {
  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.root}>
        {fields && fields.length > 0
          ? fields.map((item, index) => (
              <Grid item xs={4} key={index} >
                <Typography variant="h5" component="h5" align="center">
                  {list?.[item.varName]
                    ? formattedBigNumbers(list?.[item.varName])
                    : 0}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  align="center"
                  className={classes.desc1}
                >
                  {item.firstLabel}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  align="center"
                  className={classes.desc2}
                >
                  {item.secondLabel}
                </Typography>
              </Grid>
            ))
          : ""}
      </Grid>
    </>
  );
};

export default withStyles(styleSheet, { name: "agentStatisticsStyle" })(
  AgentStatistics
);
