import React from "react";
import TransactionDocPanel from "./documentPanel";
function Documents(props) {
  const handleCheckDocumentType = (type) => {
    if (props.ContractData?.transaction_documents?.filter((item) => item.document?.type === type)?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      {handleCheckDocumentType("sales") && <TransactionDocPanel title="Sales Documents" type="sales" {...props} />}
      {handleCheckDocumentType("miscellaneous") && <TransactionDocPanel title="Misc Documents" type="miscellaneous" {...props} />}
      {handleCheckDocumentType("listing") && <TransactionDocPanel title="Listing Documents" type="listing" {...props} />}
      {handleCheckDocumentType("closing") && <TransactionDocPanel title="Closing Documents" type="closing" {...props} />}
    </React.Fragment>
  );
}
export default Documents;
