import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import { archiveAndUnarchiveTask } from "../../../redux/transactions/archiveAndUnarchiveTask";
function ArchiveAndUnArchiveTask(props) {
  let {
    open,
    setOpen,
    classes,
    id,
    handleArchiveTask,
    contractId,
    taskDetail,
  } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();
  const updateArchive = useSelector(
    (state) => state.transactions.Tasks.updateArchive
  );

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSuccess = () => {
    if (handleArchiveTask) {
      handleArchiveTask(contractId, id, open === "archive" ? true : false);
    }
    setErrorAlert({
      errorMsg: `You have successfully  ${
        open === "archive" ? "Archived" : "UnArchived"
      } this task`,
      errorType: "success",
      isOpen: true,
    });
    setOpen(false);
  };
  const handleCloseContract = () => {
    dispatch(
      archiveAndUnarchiveTask({
        id: id,
        archived: open === "archive" ? true : false,
        handleSuccess,
        handleError,
      })
    );
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            width: "400px",
            textAlign: "center",
            fontWeight: "500",
            mt: "15px",
          }}
        >
          This action will {open === "archive" ? "Archive" : "UnArchive"} the
          task “{taskDetail?.name}”
          <br />
          <br />
          Please confirm to continue.
        </DialogContent>
        <DialogActions
          sx={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCloseContract()}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={updateArchive.isLoading}
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "ArchiveAndUnArchiveTaskStyle" })(
  ArchiveAndUnArchiveTask
);
