import { createSlice } from "@reduxjs/toolkit";
import { getLeadTimelineAPI } from "./getLeadTimelineAPI";
import { updateLeadTimelineAPI } from "./updateLeadTimelineAPI";
import { getTimelineCount } from "./getTimelineCount";
const initialState = {
  data: [],
  timelineType: "",
  isLimitReached: false,
  stats: {
    total: 10,
  },
  status: {
    pending: false,
    success: false,
    error: false,
  },
  counts: {},
};

const leadTimeline = createSlice({
  name: "leadTimeline",
  initialState,
  reducers: {
    handleResetLimit: (state, action) => {
      state.isLimitReached = false;
    },
  },
  extraReducers: {
    [getLeadTimelineAPI.pending]: (state, action) => {
      if (action.meta?.arg?.initial) {
      } else {
        state.status.pending = true;
      }
    },
    [getLeadTimelineAPI.fulfilled]: (state, action) => {
      state.status.pending = false;
      if (action?.payload?.timelines) {
        if (action.payload?.initial) {
          if (
            state.data?.length > action.payload?.timelines?.length &&
            state.timelineType !== action?.payload?.type
          ) {
            state.data = [
              ...action.payload?.timelines,
              ...state.data.slice(action.payload?.timelines?.length),
            ];
          } else {
            state.data = action.payload?.timelines;
          }
        } else {
          if (action?.payload?.timelines?.length) {
            state.isLimitReached = false;
          }
          if (action.payload.pageNumber > 1) {
            state.data = [...state.data, ...action.payload?.timelines];
          } else {
            state.data = action.payload?.timelines;
          }
        }
      } else {
        state.data = [];
      }
      if (action?.payload?.timelines?.length === 0 && !action.payload?.initial) {
        state.isLimitReached = true;
      }

      if (!action?.payload?.type) {
        state.stats = action.payload?._metadata;
      } else {
        state.timelineType = action?.payload?.type;
      }
    },
    [getLeadTimelineAPI.rejected]: (state, action) => {
      state.status.pending = false;
      state.status.error = true;
    },
    [getTimelineCount.fulfilled]: (state, action) => {
      state.counts = action.payload.counts;
    },
    [updateLeadTimelineAPI.pending]: (state) => {
      state.status.pending = true;
    },
    [updateLeadTimelineAPI.fulfilled]: (state, action) => {
      if (action.payload.data) {
        const objIndex = state.data.findIndex(
          (obj) =>
            obj[action.payload.timeline_type]?.id == action?.payload?.data.id
        );
        if (action.payload.timeline_type == "note") {
          state.data[objIndex].note = action.payload.data;
        } else {
          console.log("Other type", action.payload.timeline_type);
        }
      }

      state.status.pending = false;
    },
    [updateLeadTimelineAPI.rejected]: (state, action) => {
      state.status.pending = false;
      state.status.error = true;
    },
  },
});
export const { handleResetLimit } = leadTimeline.actions;
export default leadTimeline.reducer;
