import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
    pageRoot: {
      marginTop: "30px!important",
      height: "calc(100vh - 150px)!important",
    },
    appbarRoot: {
      height: "50px",
      background: "#fff!important",
      "& .MuiTab-root": {
        padding: "10px 15px",
        fontSize: "15px",
        color: "#0B0909",
        fontWeight: "400",
      },
      "& .MuiTabs-indicator": {
        background: "#000000",
        height: "3px",
      },
      "& .MuiTab-root.Mui-selected": {
        fontWeight: "500",
        color: "#0B0909",
      },
    },
    appbar: {
      display: "flex!important",
      justifyContent: "space-between!important",
      background: "#fff!important",
      fontFamily: "Avenir Next!important",
      borderBottom: "1px solid rgb(162 143 143 / 60%)!important",
      padding: "0px 40px",
    },
    appbarLeft: {
      padding: "0px 10px!important",
      width: "52%",
      display: "flex",
    },
    UsersTabsUI: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      fontFamily: "Avenir Next!important",
    },
    appbarRight: {
      padding: "5px!important",
      display: "flex !important",
      justifyContent: "flex-end !important",
      alignItems: "center  !important",
    },
    clearButton: {
      color: "#000",
      padding: "0px",
    },
    button: {
      background: "#0B0909!important",
      color: "#fff!important",
      height: "37px!important",
      width: "150px!important",
      "&:hover": {
        background: "#0B0909!important",
      },
      borderRadius: "9px!important",
      marginTop: "1px!important",
    },
    tabSection: {
      marginTop: "30px",
    },
  }));