import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Paper, Stack, Box, Grid, ListItemText, Chip } from "@mui/material";
import TableSkeleton from "../../components/loader/tableLoader.jsx";
import Avatar from "../../components/uiElements/avatar/index";
import { useSelector } from "react-redux";
import { CustomTooltip } from "../../components/shared/tooltip";
import { ListingColors } from "../../utils/listingStatusColor";
import InventoryItemDetailModal from "../agentRoster/agentDetails/modals/inventoryItemDetailModal.js";
function LockBoxTable(props) {
  const [open, setOpen] = useState(false);
  const [tabDetail, setTabDetail] = useState({});
  let { classes, InventoryList, filters, setFilters } = props;
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box className={classes.tableImageArea}>
              {" "}
              <img
                src={params.row.Icon}
                style={{ width: "25px" }}
                alt="agent Equipment Icon"
              />
            </Box>
            <Box sx={{ ml: "10px" }}> {params.row.AssetName}</Box>
          </Stack>
        );
      },
      // sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      width: 140,
      editable: false,
      sortable: true,
    },
    {
      field: "SerialNumber",
      headerName: "Serial Number",

      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid container className={classes.status}>
              {params.row.SerialNumber}
            </Grid>
          </>
        );
      },
      minWidth: 120,
      flex: 0.3,
      editable: false,
      sortable: true,
    },
    {
      field: "CBSCode",
      headerName: "CBS Code",
      renderCell: (params) => {
        return (
          <>
            <Grid container className={classes.mAlign}>
              {params.row.CBScode}
            </Grid>
          </>
        );
      },
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "shackleCode ",
      headerName: "Shackle Code ",
      renderCell: (params) => {
        return (
          <>
            <Grid container className={classes.mAlign}>
              {params.row.shackleCode}
            </Grid>
          </>
        );
      },
      width: 140,
      editable: false,
      sortable: false,
    },
    {
      field: "TagID",
      headerName: "Tag ID",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid container>{params.row.tagId}</Grid>
          </>
        );
      },
      width: 120,
      editable: false,
      sortable: false,
    },
    {
      field: "AssetStatus",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <>
            {row?.AssetStatus ? (
              <CustomTooltip
                title={
                  row?.AssetStatus === "lost"
                    ? "Marked as Lost"
                    : row?.AssetStatus?.replace(/_/g, " ")
                }
              >
                <Chip
                  size="small"
                  label={
                    row?.AssetStatus === "available"
                      ? "In Office"
                      : row?.AssetStatus === "lost"
                      ? "Lost"
                      : "Checked Out"
                  }
                  style={{
                    background:
                      row?.AssetStatus === "available"
                        ? "rgba(69, 167, 79, 0.75)"
                        : row?.AssetStatus === "lost"
                        ? "#8A7CC3"
                        : "rgba(111, 185, 227, 0.76)",
                    color: "white",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                />
              </CustomTooltip>
            ) : (
              ""
            )}
          </>
        );
      },
      minWidth: 150,
      flex: 0.4,
      editable: true,
      sortable: true,
    },
    {
      field: "check-out",
      headerName: "Agent Assigned",
      renderCell: (params) => {
        if (params.row["check-out"] && params.row["check-out"][0]?.users_v2) {
          let userDetail = params.row["check-out"][0].users_v2;
          let profile_images = agentRosterList?.list.find(
            (item) => item.id === params.row["check-out"][0].users_v2?.id
          )?.profile_images;
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Avatar
                sx={{ fontSize: "14.5px" }}
                src={
                  profile_images?.profile_img ||
                  profile_images?.profile_img_thumbnail ||
                  profile_images?.slack_profile_img
                }
                variant="rounded"
              >
                {userDetail?.first_name?.slice(0, 1) +
                  userDetail?.last_name?.slice(0, 1)}
              </Avatar>
              <ListItemText
                classes={{
                  primary: classes.listTitleInventory,
                  secondary: classes.listDesInventory,
                }}
                primary={userDetail?.first_name}
                secondary={userDetail?.last_name}
              />
            </Stack>
          );
        } else {
          return null;
        }
      },
      valueGetter: (params) =>
        params.row["check-out"]
          ? params.row["check-out"][0]?.users_v2?.first_name
          : "",
      minWidth: 150,
      editable: false,
      sortable: true,
      flex: 0.5,
    },
    {
      field: "listings",
      headerName: "Listing Attached",
      renderCell: (params) => {
        if (
          params.row["check-out"] &&
          params.row["check-out"][0]?.transaction_listings_v2
        ) {
          let propertyDetail =
            params.row["check-out"][0].transaction_listings_v2;
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Avatar
                sx={{ width: "60px", height: "40px" }}
                src={propertyDetail.property_img}
                variant="rounded"
              >
                NA
              </Avatar>
              <ListItemText
                classes={{
                  primary: classes.listTitleInventory,
                  secondary: classes.listDesInventory,
                }}
                primary={
                  propertyDetail.street ||
                  propertyDetail?.property_address
                    ?.replace(
                      `${propertyDetail?.city} ${propertyDetail?.state}, ${propertyDetail?.zipcode}`,
                      ""
                    )
                    ?.replace(
                      `${propertyDetail?.city}, ${propertyDetail?.state} ${propertyDetail?.zipcode}`,
                      ""
                    )
                }
                secondary={`${propertyDetail?.city} ${propertyDetail?.state} ${propertyDetail?.zipcode}`}
              />
            </Stack>
          );
        } else {
          return null;
        }
      },
      flex: 1,
      minWidth: 280,
      editable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Listing Status",
      renderCell: (params) => {
        if (
          params.row["check-out"] &&
          params.row["check-out"][0]?.transaction_listings_v2
        ) {
          let propertyDetail =
            params.row["check-out"][0].transaction_listings_v2;
          return (
            <CustomTooltip title={params.row.status}>
              <Chip
                size="small"
                sx={{
                  backgroundColor:
                    ListingColors[propertyDetail.status] || "#000000",
                  textTransform: "capitalize",
                }}
                label={propertyDetail.status}
                color="success"
              />
            </CustomTooltip>
          );
        } else {
          return null;
        }
      },
      minWidth: 160,
      flex: 0.5,
      editable: false,
      sortable: true,
    },
  ];

  const handleOpenDetail = (data) => {
    let propertyDetail = data["check-out"]?.length
      ? data["check-out"][0]?.transaction_listings_v2
      : null;
    let userDetail = data["check-out"]?.length
      ? data["check-out"][0]?.users_v2
      : null;
    let profile_images = userDetail
      ? agentRosterList?.list.find(
          (item) => item.id === data["check-out"][0]?.users_v2?.id
        )?.profile_images
      : null;
    let obj = {
      InventoryId: data.id,
      AssetStatus: data.AssetStatus,
      ListingId: propertyDetail?.id,
      id: data["check-out"]?.length ? data["check-out"][0]?.id : "",
      IsSupraWeb: data["check-out"]?.length
        ? data["check-out"][0]?.IsSupraWeb
        : false,
      inventory: data,
      transaction_listings_v2: propertyDetail,
      users_v2: userDetail ? { ...userDetail, profile_images } : null,
      defaultData: data,
    };
    setTabDetail(obj);
    setOpen(true);
  };

  return (
    <Paper className={classes.tableRoot}>
      <DataGridPro
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: "AssetStatus", sort: "desc" }],
        //   },
        // }}
        rows={InventoryList?.data?.rows ? InventoryList?.data?.rows : []}
        columns={columns}
        loading={InventoryList.isLoading}
        rowHeight={56}
        components={{
          LoadingOverlay: TableSkeleton,
        }}
        onRowClick={(data) => handleOpenDetail(data.row)}
        onRowsScrollEnd={(e) => {
          if (
            InventoryList.data?.count > e.virtualRowsCount &&
            !InventoryList.isLoading
          ) {
            setFilters({
              ...filters,
              pageNumber: Number(e.virtualRowsCount / 25) + 1,
              pageSize: 25,
            });
          }
        }}
      />
      <InventoryItemDetailModal
        setLockBoxDetail={setTabDetail}
        tabDetail={tabDetail}
        open={open}
        setOpen={setOpen}
        lockBoxDetail={tabDetail}
      />
    </Paper>
  );
}
export default withStyles(styleSheet, { name: "LockBoxTableStyle" })(
  LockBoxTable
);
