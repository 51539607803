import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { CircularProgress } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    color: "#000000DE",
  },
  createButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
  },
  closeButton: {
    color: "#0B0909!important",
  },
}));

function ConfirmModal(props) {
  const classes = useStyles();
  const { open, setOpen, handleConfirm, title, hidetrash, loading } = props;
  return (
    <React.Fragment>
      <Dialog disablePortal fullWidth maxWidth="sm" open={open}>
        <DialogTitle className={classes.title}>Confirmation</DialogTitle>
        <DialogContent>
          {!hidetrash && (
            <center>
              <RestoreFromTrashIcon sx={{ fontSize: "80px", color: "red" }} />
            </center>
          )}

          <Box>
            {" "}
            {title
              ? title
              : "This tab has data in draft. Are you sure you want to remove this tab?"}
          </Box>
        </DialogContent>
        {loading ? (
          <Box sx={{ margin: "auto", height: "60px" }}>
            <CircularProgress size={"40px"} />
          </Box>
        ) : (
          <DialogActions>
            <Button
              className={classes.closeButton}
              onClick={() => setOpen(false)}
            >
              No
            </Button>
            <Button
              onClick={() => handleConfirm()}
              classes={{ root: classes.createButton }}
              color="inherit"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default ConfirmModal;
