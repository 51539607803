import React, { useEffect, useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Stack, Typography, Paper } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Avatar from "../../../components/uiElements/avatar";
import { getData } from "../../../utils/getData";
import TableSkeleton from "../../../components/loader/tableLoader.jsx";
function CustomQuestionResponse(props) {
  let { classes, open, setOpen, eventData, userDetail } = props;
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState({});
  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "question_title",
      headerName: "Question",
      minWidth: 200,
      flex: 0.5,
      editable: false,
      sortable: true,
    },
    {
      field: "written_answer",
      headerName: "Response",
      minWidth: 220,
      flex: 0.5,
      editable: false,
      sortable: true,
    },
  ];
  useEffect(() => {
    if (open && userDetail?.users_v2?.id) {
      handleGetQuestionResponse();
    }
  }, [userDetail?.users_v2?.id]);
  const handleGetQuestionResponse = async () => {
    setLoading(true);
    const result = await getData(`${process.env.REACT_APP_BASE_URL}api/events-alerts-v2/event/response/${eventData.id}/${userDetail?.users_v2?.id}`);
    if (result.status === 200) {
      let customResponses = [];
      let eventQuestions = result.data?.event_questions || [];
      let eventResponse = result.data?.event_responses[0]?.event_question_responses || [];
      eventQuestions.map((item, index) => {
        customResponses.push({ ...item, written_answer: eventResponse[index]?.written_answer || "" });
      });
      setQuestions(customResponses);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  return (
    <Dialog
      disablePortal
      style={{ position: "absolute" }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle className={classes.modalHeading} id="alert-dialog-title">
        <Box> Custom Question Responses</Box>
        <Stack direction="row" spacing={1}>
          <Avatar
            src={userDetail?.users_v2?.profile_images?.profile_img}
            sx={{ fontSize: "13px", width: "25px", height: "25px" }}
          >{`${userDetail?.users_v2?.full_name?.split(" ")[0]?.slice(0, 1)}${userDetail?.users_v2?.full_name?.split(" ")[1]?.slice(0, 1)}`}</Avatar>

          <Typography>
            {userDetail?.users_v2?.full_name} {userDetail.more_than_one === "yes" ? " +1" : ""}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: "600px", mt: "20px" }}>
        <Paper className={classes.tableRoot}>
          <DataGridPro
            rows={questions}
            columns={columns}
            loading={loading}
            rowHeight={56}
            components={{
              LoadingOverlay: TableSkeleton,
            }}
          />
        </Paper>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
        <Button
          onClick={() => handleClose()}
          sx={{
            color: "#000000",
          }}
          color="inherit"
          variant="outlined"
          size="small"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default withStyles(styleSheet, { name: "CustomQuestionResponseStyle" })(CustomQuestionResponse);
