import React, { useState, useEffect } from "react";
import "./style.js";
import { ValidateEmail } from "../../utils/emailValidator";
import { Grid, Container, Input } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { getAllUsersTeamsListToUseInAllSections } from "../../redux/users/getAllUsersListToUseInAllSections.js";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [loginDetail, setLoginDetail] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  // fetching data from store
  const adminAuth = useSelector((state) => state.adminAuth);

  useEffect(() => {
    if (localStorage.token) {
      navigate("/main/dashboard");
    }
  }, []);
  useEffect(() => {
    if (!adminAuth.isLoading) {
      if (adminAuth.errMsg === null && !(adminAuth.adminDetail === undefined)) {
        if (loading) {
          setLoading(false);
          // setOpen("success");
          //---- stored all users list to use with in the platform
          dispatch(getAllUsersTeamsListToUseInAllSections());
          navigate("/main/dashboard");
        }
      } else {
        if (loading) {
          setLoading(false);
          setErrorMsg({
            alert: adminAuth.errMsg || "Please try again later",
          });
          setOpen("error");
        }
      }
    }
  }, [adminAuth]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleUpdateLoginDetail = (type, value) => {
    setLoginDetail({ ...loginDetail, [type]: value });
    if (type === "email") {
      if (ValidateEmail(value)) {
        setErrorMsg({});
      } else {
        setErrorMsg({ email: "Enter valid email" });
      }
    }
  };
  const handleValidate = () => {
    let isFormValid = true;
    let error = {};
    if (!loginDetail.email) {
      isFormValid = false;
      error.email = "Please enter email";
    } else if (!ValidateEmail(loginDetail.email)) {
      isFormValid = false;
      error.email = "Please enter valid email";
    } else if (!loginDetail.password) {
      isFormValid = false;
      error.password = "Please enter password";
    }
    setErrorMsg(error);
    return isFormValid;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (handleValidate()) {
      setLoading(true);
      let loginDetail_new = { ...loginDetail };
      if (localStorage.webpush_token && localStorage.webpush_token !== "") {
        loginDetail_new.webpush_token = localStorage.webpush_token;
      }
      dispatch(adminLogin(loginDetail_new));
    }
  };
  return (
    <div className="login-page">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7} md={6}>
            <div className="login-form-area">
              <div className="login-form-area-box">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div className="form-group-element">
                      <Input
                        value={loginDetail.email}
                        onChange={(e) =>
                          handleUpdateLoginDetail("email", e.target.value)
                        }
                        name="email"
                        fullWidth
                        placeholder="Email Address"
                        type="email"
                      />
                      {errorMsg.email && (
                        <div className="text-danger">{errorMsg.email}</div>
                      )}
                    </div>
                    <div className="form-group-element">
                      <Input
                        value={loginDetail.password}
                        onChange={(e) =>
                          handleUpdateLoginDetail("password", e.target.value)
                        }
                        name="password"
                        fullWidth
                        placeholder="Password"
                        type="password"
                        error={false}
                      />
                      {errorMsg.password && (
                        <div className="text-danger">{errorMsg.password}</div>
                      )}
                    </div>
                    <div className="form-group-element">
                      <button type="submit">Continue</button>
                    </div>
                    <div className="form-group-element">
                      <center>
                        Forgot Password? <b>Reset Password</b>
                      </center>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open ? true : false}
        autoHideDuration={"2000"}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={open ? open : ""}
          sx={{ width: "100%" }}
        >
          {errorMsg.alert}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default withStyles(styleSheet, { name: "LoginPageStyle" })(LoginPage);
