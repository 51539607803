import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function DepartmentMenu(props) {
  const { departments, handleDepartmentChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const selectedTab = useSelector((state) => state.project.selectedTab);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        size="small"
        onClick={handleClick}
        sx={{
          height: '30px',
          color: "#0B0909 !important",
          fontSize: "13px !important",
          fontWeight: selectedTab>2? "500!important" : "400!important",
          marginTop: "4px",
          '& .MuiButton-endIcon': {
            marginTop: '-2px'
          }
        }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Others
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <>
          {departments.map((department, index) => (
            <MenuItem 
              selected={selectedTab === index+3}
              onClick={(e) => { 
                handleClose();
                handleDepartmentChange(e, index+3);
              }} disableRipple>
              {department.name}
            </MenuItem>
          ))}
          <MenuItem
            selected={selectedTab === departments.length+3}
            onClick={(e) => { 
              handleClose();
              handleDepartmentChange(e, departments.length+3);
            }} disableRipple
          >
            Completed
          </MenuItem>
          <MenuItem
            selected={selectedTab === departments.length+4}
            onClick={(e) => { 
              handleClose();
              handleDepartmentChange(e, departments.length+4);
            }} disableRipple
          >
            Canceled
          </MenuItem>
        </>
      </StyledMenu>
    </>
  );
}