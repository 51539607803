import { createSlice } from "@reduxjs/toolkit";
import { getLeadSavedSearches } from "./getSavedSearches";
import { getSearchSuggestions } from "./getSuggestions";
import { getPropertyList } from "./getPropertyList";
import { saveSearch } from "./saveSearch";
import { deleteSearch } from "./deleteSearch";
import { updateSearchQuery } from "./updateSavedQuery";
import { mIdxSignUp } from "./midxSignup";
import { updateSearch } from "./updateSaveSearch";
import { getLastActivities } from "./getLastActivities";
import { queryParsing } from "../../../utils/propertyData";

const initialState = {
  searchList: {
    success: false,
    data: {},
    isLoading: true,
    errMsg: null,
    isUserFound: true,
    isComingSoon: false,
    meta: {},
    otherAccounts: [],
  },
  propertyList: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  saveSearch: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  signUp: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  deleteSearch: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  updateQuery: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  searchDetail: {},
  geoOperator: "OR",
  searchParams: {
    ListPrice: "",
    select: "",
    BedroomsTotal: "",
    BathroomsTotalDecimal: "",
    BathroomsTotalInteger:"",
    BuildingAreaTotal: "",
    LotSizeAcres: "",
    YearBuilt: "",
    PropertyType: "Residential",
    PropertySubType: "",
    MlsStatus: `(Active OR "Active Under Contract" OR "Coming Soon")`,
    sort: "ModificationTimestamp desc",
  },
  suggestions: {},
  validFields: {},
  lastActivities: {},
  selectedAccount: null,
};

const LeadSavedSearches = createSlice({
  name: "LeadSavedSearches",
  initialState,
  reducers: {
    handleSelectAccount: (state, action) => {
      state.selectedAccount = action.payload;

      if (
        action.payload.value === state.searchList.data.email ||
        state.searchList.otherAccounts.find(
          (it) => it.email === action.payload.value
        )
      ) {
        state.searchList.isUserFound = true;
      } else {
        state.searchList.isUserFound = false;
      }
      if (action.payload.value !== state.searchList.data.email) {
        let newData = state.searchList.otherAccounts.find(
          (it) => it.email === action.payload.value
        );
        if (newData) {
          state.searchList.data.savedSearches = newData;
        }
      }
    },
    handleUpdateParams: (state, action) => {
      state.searchParams[action.payload.field] = action.payload.value;
      if (action.payload.pageNumber) {
        state.pageNumber = action.payload.pageNumber;
      }
    },
    handleUpdateActiveSite: (state, action) => {
      state.searchList.data = action.payload;
    },
    handleResetSearchList: (state, action) => {
      state.searchList = {
        success: false,
        data: {},
        isLoading: true,
        errMsg: null,
      };
    },
    handleAddOrRemoveKeyFromSearch: (state, action) => {
      let newSearchParams = { ...state.searchParams };
      delete newSearchParams[action.payload.fieldToRemove];
      state.searchParams = {
        [action.payload.fieldToAdd]: action.payload.fieldToAddValue
          ? action.payload.fieldToAddValue
          : "",
        ...newSearchParams,
      };
    },
    handleResetSearchValue: (state, action) => {
      state.searchParams = initialState.searchParams;
      if (action?.payload?.resetSearchDetail) {
        state.searchDetail = { isLoading: false };
      }
    },
    handleUpdateSearchDetail: (state, action) => {
      state.searchDetail = action.payload.searchDetail;
      let newSearchParam = {};
      let filterString =
        action.payload?.searchDetail.advanceSearchId?.query?.q?.replaceAll(
          "q=",
          ""
        );
      if (action.payload?.searchDetail.advanceSearchId?.geoOperator) {
        state.geoOperator =
          action.payload?.searchDetail.advanceSearchId?.geoOperator;
      }
      newSearchParam = queryParsing(filterString);
      if (Object.keys(newSearchParam)?.length) {
        state.searchParams = {
          ...newSearchParam,
          sort: action.payload?.searchDetail.advanceSearchId?.query?.sort,
          start: action.payload?.searchDetail.advanceSearchId?.query?.start,
          rows: action.payload?.searchDetail.advanceSearchId?.query?.rows,
        };
      }
    },
  },
  extraReducers: {
    [getLeadSavedSearches.pending]: (state, action) => {
      state.searchList.isLoading = true;
      state.searchList.errMsg = null;
      state.searchList.success = false;
      state.searchList.isUserFound = true;
      state.searchList.isComingSoon = false;
      state.searchList.otherAccounts = [];
      if (action.meta?.arg?.reset) {
        state.searchList.meta = {};
      }
    },
    [getLeadSavedSearches.fulfilled]: (state, action) => {
      state.searchList.success = true;
      state.searchList.isLoading = false;
      if (
        typeof action.payload.data === "object" &&
        !Array.isArray(action.payload.data)
      ) {
        state.searchList.data = action.payload.data;
      } else {
        state.searchList.data = action.payload.data?.find(
          (it) => it?.site_id !== null
        );
        state.searchList.otherAccounts = action.payload.data;
      }
      if (action.payload.reset) {
        state.searchList.meta = action.payload.meta;
      } else {
        state.searchList.meta = {
          ...action.payload.meta,
          ...state.searchList.meta,
        };
      }

      state.searchList.isUserFound = true;
      state.searchList.isComingSoon = false;
    },
    [getLeadSavedSearches.rejected]: (state, action) => {
      state.searchList.isLoading = false;
      state.searchList.success = false;
      if (
        action.payload.code === 400 ||
        action.payload.message === "Coming Soon"
      ) {
        state.searchList.isComingSoon = true;
        state.searchList.errMsg = action.payload.message;
      } else {
        state.searchList.errMsg = action.payload.message;
        if (action.payload.reset) {
          state.searchList.meta = action.payload.meta;
        } else {
          state.searchList.meta = {
            ...action.payload.meta,
            ...state.searchList.meta,
          };
        }
        state.searchList.isUserFound = false;
      }
    },
    [getSearchSuggestions.fulfilled]: (state, action) => {
      if (action.payload?.data) {
        state.suggestions = action.payload?.data?.suggestions;
        state.validFields = action.payload?.data?.validFields;
      }
    },
    // last activities
    [getLastActivities.fulfilled]: (state, action) => {
      state.lastActivities = action.payload.data;
    },
    [getPropertyList.pending]: (state) => {
      state.propertyList.isLoading = true;
      state.propertyList.errMsg = null;
      state.propertyList.success = false;
    },
    [getPropertyList.fulfilled]: (state, action) => {
      state.propertyList.success = true;
      state.propertyList.isLoading = false;
      state.propertyList.data = action.payload;
    },
    [getPropertyList.rejected]: (state, action) => {
      state.propertyList.isLoading = false;
      state.propertyList.success = false;
      state.propertyList.errMsg = action.payload;
    },
    [saveSearch.pending]: (state) => {
      state.saveSearch.isLoading = true;
      state.saveSearch.errMsg = null;
      state.saveSearch.success = false;
    },
    [saveSearch.fulfilled]: (state, action) => {
      state.saveSearch.success = true;
      state.saveSearch.isLoading = false;
      state.saveSearch.data = action.payload;
      let newSearchListData = { ...state.searchList.data };
      newSearchListData.savedSearches = [
        ...newSearchListData.savedSearches,
        action.payload,
      ];
      state.searchList.data = newSearchListData;
    },
    [saveSearch.rejected]: (state, action) => {
      state.saveSearch.isLoading = false;
      state.saveSearch.success = false;
      state.saveSearch.errMsg = action.payload;
    },
    // mIdx Signup

    [mIdxSignUp.pending]: (state) => {
      state.signUp.isLoading = true;
      state.signUp.errMsg = null;
      state.signUp.success = false;
    },
    [mIdxSignUp.fulfilled]: (state, action) => {
      state.signUp.success = true;
      state.signUp.isLoading = false;
      if (
        state.searchList?.data?._id &&
        !state.searchList.otherAccounts.length
      ) {
        state.searchList.otherAccounts = [{ ...state.searchList?.data }];
      }
      state.signUp.data = action.payload;
      state.searchList.data = {
        savedSearches: [],
        ...action.payload.data,
        site_id: {
          _id: action.payload.site_id || action.payload.data?.site_id,
          ...action.payload.data?.site_id,
          ...action.payload.site_id,
        },
      };
      state.searchList.meta = {};
      state.searchList.isUserFound = true;
    },
    [mIdxSignUp.rejected]: (state, action) => {
      state.signUp.isLoading = false;
      state.signUp.success = false;
      state.signUp.errMsg = action.payload;
    },
    [deleteSearch.pending]: (state) => {
      state.deleteSearch.isLoading = true;
    },
    [deleteSearch.fulfilled]: (state, action) => {
      state.deleteSearch.isLoading = false;
      let newSearchListData = { ...state.searchList.data };
      newSearchListData.savedSearches = [
        ...newSearchListData.savedSearches,
      ]?.filter((item) => item?.searchId !== action.payload.searchId);
      state.searchList.data = newSearchListData;
    },
    [deleteSearch.rejected]: (state, action) => {
      state.deleteSearch.isLoading = false;
      state.deleteSearch.errMsg = action.payload;
    },
    [updateSearchQuery.pending]: (state) => {
      state.updateQuery.isLoading = true;
    },
    [updateSearchQuery.fulfilled]: (state, action) => {
      state.updateQuery.isLoading = false;
      let newSearchListData = { ...state.searchList.data };
      let newSavedSearches = [...newSearchListData.savedSearches];
      let index = newSavedSearches.findIndex(
        (item) => item.searchId === action.payload.data.searchId
      );
      newSavedSearches[index] = {
        ...newSavedSearches[index],
        ...action.payload.data,
        title: action.payload.title,
        advanceSearchId: {
          query: action.payload.data.query,
          geoOperator: action.payload?.isAND ? "AND" : "OR",
        },
      };
      newSearchListData.savedSearches = newSavedSearches;
      state.searchList.data = newSearchListData;
    },
    [updateSearchQuery.rejected]: (state, action) => {
      state.updateQuery.isLoading = false;
      state.updateQuery.errMsg = action.payload;
    },
    [updateSearch.fulfilled]: (state, action) => {
      let newSearchListData = { ...state.searchList.data };
      let newSavedSearches = [...newSearchListData.savedSearches];
      let index = newSavedSearches.findIndex(
        (item) => item.searchId === action.payload.data.searchId
      );
      newSavedSearches[index] = {
        ...newSavedSearches[index],
        ...action.payload.data,
      };
      newSearchListData.savedSearches = newSavedSearches;
      state.searchList.data = newSearchListData;
    },
  },
});
export const {
  handleUpdateParams,
  handleResetSearchValue,
  handleAddOrRemoveKeyFromSearch,
  handleSelectAccount,
} = LeadSavedSearches.actions;
export const {
  handleUpdateSearchDetail,
  handleResetSearchList,
  handleUpdateActiveSite,
} = LeadSavedSearches.actions;
export default LeadSavedSearches.reducer;
export * from "./getSavedSearches";
export * from "./getSuggestions";
export * from "./getPropertyList";
export * from "./deleteSearch";
export * from "./updateSavedQuery";
export * from "./midxSignup";
