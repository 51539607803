export const styleSheet = () => ({
  sideBarRoot: {
    screenY: "auto",
    height: "100vh",
  },
  sideBarListText: {
    fontSize: "12.5px  !important",
    textTransform: "uppercase",
  },
  navCollapseItems: {},
  sideBarRootList: {
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",

    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
});
