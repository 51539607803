import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import ResponseAlert from "../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { deletePond } from "../../redux/ponds";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";

function DeletePond(props) {
  let { open, setOpen, classes, selectedPond } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [assignTo, setAssignTo] = useState(null);
  const dispatch = useDispatch();

  const deletePondS = useSelector((item) => item.Ponds.deletePond);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);

  const handleClose = () => {
    setOpen(false);
    setAssignTo(null);
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the Pond",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const handleDeletePond = () => {
    if (assignTo) {
      dispatch(
        deletePond({
          id: selectedPond.id,
          assignTo: assignTo,
          handleSuccess,
          handleError,
        })
      );
    } else {
      setErrorAlert({
        errorMsg: "Please select the agent first",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className={classes.paymentModalHeading}
          id="alert-dialog-title"
        >
          Delete Pond
        </DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to delete this pond: "{selectedPond?.name}"? <br />
            Please select an agent to whom the leads will be assigned after the
            pond is deleted.
          </Typography>
          <br />
          <Autocomplete
            disableClearable
            options={storedListOfUsers?.list?.filter((it) => it.is_agent)}
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            onChange={(e, option) => {
              setAssignTo(option.id);
            }}
            sx={{ backgroundColor: "#E8ECF0" }}
            value={
              assignTo
                ? storedListOfUsers?.list?.find((it) => it.id === assignTo)
                : null
            }
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select a pond agent"
                aria-describedby="stepper-linear-event_types"
                size="small"
                fullWidth
                required
                className={classes.selectAgentInput}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: assignTo ? (
                    <Avatar
                      sx={{
                        width: "30px",
                        height: "30px",
                        fontSize: "13px",
                        ml: "3px",
                      }}
                      src={
                        assignTo
                          ? storedListOfUsers?.list.find(
                              (it) => it.id === assignTo
                            )?.profile_images?.profile_img_thumbnail
                          : ""
                      }
                    >
                      {`${storedListOfUsers?.list
                        .find((it) => it.id === assignTo)
                        .first_name?.slice(0, 1)}${storedListOfUsers?.list
                        .find((it) => it.id === assignTo)
                        .last_name?.slice(0, 1)}`}
                    </Avatar>
                  ) : null,
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    fontSize: "13px",
                    mr: "8px",
                  }}
                  src={
                    option.profile_images?.profile_img_thumbnail ||
                    option.profile_images?.profile_img
                  }
                >{`${option.first_name?.slice(0, 1)}${option?.last_name.slice(
                  0,
                  1
                )}`}</Avatar>
                {option?.first_name} {option?.last_name}
              </li>
            )}
          ></Autocomplete>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            classes={{ root: classes.saveButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={deletePondS.isLoading}
            onClick={handleDeletePond}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "DeletePondStyle" })(DeletePond);
