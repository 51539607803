import React from "react";
import { Box, Stack  } from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import ClosAbleMenu from "../widgets/closableMenu";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
function Footer(props) {
  const globalWidgets = useSelector((state) => state.widgets);
  let { classes } = props;
  const handleGroupedTab = () => {
    let groupedTabs = groupBy(globalWidgets.minimizedFooterTabs, function (n) {
      return n.type;
    });
    return groupedTabs;
  };
  const getFooterTabs = () => {
    return Object.keys(handleGroupedTab());
  };
  if (globalWidgets.minimizedFooterTabs.length) {
    return (
      <Box className={classes.globalFooterArea}>
        <Stack direction={"row"} justifyContent="flex-end" sx={{ mr: "23px" }} spacing={1}>
          {getFooterTabs().map((item, index) => (
            <ClosAbleMenu
              key={index}
              subMenu={handleGroupedTab()[item]}
              type={item}
              title={handleGroupedTab()[item][handleGroupedTab()[item].length - 1].title}
              {...props}
            />
          ))}
        </Stack>
      </Box>
    );
  } else {
    return null;
  }
}
export default withStyles(styleSheet, { name: "FooterStyle" })(Footer);
