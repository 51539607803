import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getTemplateDetail = createAsyncThunk(
  "MarketingCenterDigital/getTemplateDetail",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/marketing/templates/${data?.templateId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data?.data?._id ? resp.data?.data : resp.data?.message;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
