import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Grid, Container, Box, Typography, Stack, Button, TextField, Paper ,IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { DataGridPro } from "@mui/x-data-grid-pro";
import {getTextTemplates} from '../../../../redux/leads/textTemplates'
import { ListItemText } from '@mui/material';
import TableSkeleton from "../../../../components/loader/tableLoader"  
import EditIcon from '@mui/icons-material/Edit';
// import { getAllInventories } from "../../redux/Template";
import { useSelector, useDispatch } from "react-redux";

function TemplatesList(props) {
  let { classes } = props;
  const [open, setOpen] = useState(false);
  const [search,setSearch]= useState(false);
  const navigate=useNavigate()
  const [filter, setFilter] = useState({
    offset:0,
    page:1,
    limit:25,
    search:"",
    status:""
  });
  const dispatch = useDispatch();
  const TemplateList =useSelector((state) => state.leads.TextTemplates.emailsList);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const columns = [
    {
      field: "Template",
      headerName: "Template",
      renderCell: (params) => {
        return (
          <Box >
            <ListItemText
            primary={(<Box sx={{color: "#0288D1",fontSize:'14px'}}>{params?.row?.name}</Box>)}
            secondary={(<Box style={{overflow:'hidden',textOverflow:"ellipsis",whiteSpace:"nowrap",width:"660px" }} /*  dangerouslySetInnerHTML={{ __html: params?.row?.body}} */>{params?.row?.subject}</Box>)}
            />
          </Box>
        );
      },
      width: 660,
      editable: false,
      sortable: true,
    },
    {
      field: "Owner",
      headerName: "Owner",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid sx={{color: "#0288D1",fontSize:'14px'}} container onClick={() => redirectDetails(params.row.id)}>
              {
                params.row.createdById?agentRosterList?.list.find((item) => item.id === Number(params.row.createdById || 1))?.full_name:""
              }
            </Grid>
          </>
        );
      },
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <>
            <Grid container onClick={() =>  navigate(`/templates/text-templates/${params.row.id}/edit`, { state: params.row }) }>
              <IconButton > <EditIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} /></IconButton>
            </Grid>
          </>
        );
      },
      type:"actions",
      width: 150,
      editable: false,
      sortable: false,
    },
  ];

  useEffect(() => {
    dispatch(getTextTemplates(filter));
  }, [filter]);
// 
//   console.log(TemplateList, "TemplateList");
  const redirectDetails = (id) => {

  };

  useEffect(() => {
    if(search!==false){
      const timeOutId = setTimeout(() => setFilter({...filter,search}), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <Container>
      <Box className={classes.pageRoot}>
        <Box className={classes.TemplateHeader}>
          <Typography >
           {TemplateList?.data?._metadata?.total} Text Messages Templates
          </Typography>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Button onClick={()=>navigate('/templates/text-templates/add')} size="small"  variant="outlined" color="inherit" startIcon={<AddIcon />} >
                 New Template
              </Button>
              <TextField sx={{"input":{height:'15px'}}} onChange={(e)=>setSearch(e.target.value)} type="search" size="small" variant="outlined" placeholder="Search Templates" />
            </Stack>{" "}
          </Box>
        </Box>
        <Paper className={classes.tableRoot}>
          <DataGridPro rows={TemplateList?.data?.textmessagetemplates ? TemplateList?.data?.textmessagetemplates : []} columns={columns} loading={TemplateList.isLoading}
           rowHeight={56}
           checkboxSelection
           components={{
            LoadingOverlay: TableSkeleton
          }}
           onRowsScrollEnd={(e) => {
            if (TemplateList?.data?._metadata?.total > e.virtualRowsCount) {
              setFilter({
                ...filter,
                page: Number(e.virtualRowsCount / 25)+1,
                limit: 25,
                offset:((filter.page)*25)
              })
            }
          }}
           />
        </Paper>
      </Box>
    </Container>
  );
}

export default withStyles(styleSheet, { name: "TemplatesListStyle" })(TemplatesList);
