import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Autocomplete } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import arrowUp from "../../../../assets/arrowUp.svg";
import { useDispatch, useSelector } from "react-redux";
import { createNewPost, updatePost } from "../../../../redux/knowledge/posts";
import { Uploader } from "../../../../utils/upload";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";

function AddKnowledgeBasePost(props) {
  const dispatch = useDispatch();
  let { open, setOpen, classes, categoryData, editable, setEditable } = props;
  const [thumbnailPreview, setThumbnailPreview] = useState({});
  const [videoPreview, setVideoPreview] = useState({});
  const [uploader, setUploader] = useState(undefined);
  const [percentage, setPercentage] = useState(undefined);
  const [fileLoading, setFileLoading] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState(false);
  const [document, setDocument] = useState(false);
  const [documentPreview, setDocumentPreview] = useState({});
  const [postDetail, setPostDetail] = useState({
    category_id: "",
    title: "",
    description: "",
    visibility: "",
    author: "",
    file: "",
    audience: "",
    type: "video",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const users = useSelector((state) => state.users.storedListOfUsers.list);
  const createPost = useSelector(
    (state) => state.knowledgeBase.posts.createPost
  );
  const updatePostState = useSelector(
    (state) => state.knowledgeBase.posts.update
  );
  useEffect(() => {
    if (editable) {
      setPostDetail({
        category_id: editable.category_id,
        title: editable.title,
        description: editable.body,
        visibility: editable.visibility,
        author: editable.author_id,
        audience: editable.audience,
        type: editable?.type || "video",
      });
      setThumbnailPreview({ url: editable.thumbnail });
      if (editable.video_src) {
        setVideoPreview({ url: editable.video_src });
      }
      if (editable.document) {
        setDocumentPreview({ url: editable.document });
      }
    }
  }, [editable]);

  const handleUpdateDetail = (field, value) => {
    setPostDetail({ ...postDetail, [field]: value });
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully created the post for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
    setPercentage(undefined);
  };

  const handleUpdateSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the post for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
    setPercentage(undefined);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleValidate = (isNotUpdate) => {
    let isFormValid = true;
    let errorMsg = {};
    if (!postDetail.visibility) {
      isFormValid = false;
      errorMsg["visibility"] = "Please select the visibility first";
      setErrorAlert({
        errorMsg: "Please select the visibility first",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!postDetail.category_id) {
      isFormValid = false;
      errorMsg["category_id"] = "Please select the category first";
      setErrorAlert({
        errorMsg: "Please select the category first",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!postDetail.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter the post title ";
      setErrorAlert({
        errorMsg: "Please enter the post title",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!postDetail.audience) {
      isFormValid = false;
      errorMsg["audience"] = "Please select the audience";
      setErrorAlert({
        errorMsg: "Please select the audience",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!postDetail.description) {
      isFormValid = false;
      errorMsg["author"] = "Please enter the description";
      setErrorAlert({
        errorMsg: "Please enter the description",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!thumbnailFile && !isNotUpdate) {
      isFormValid = false;
      errorMsg["thumbnail"] = "Please select the post thumbnail";
      setErrorAlert({
        errorMsg: "Please select the post thumbnail",
        errorType: "warning",
        isOpen: true,
      });
    } else if (
      !document &&
      postDetail?.type === "document" &&
      !documentPreview.url
    ) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select a document first!",
        errorType: "warning",
        isOpen: true,
      });
    }
    return isFormValid;
  };

  const CreatePost = () => {
    if (handleValidate()) {
      let obj = {
        schema: {
          ...postDetail,
          thumbnail: thumbnailFile,
          document,
          author_id: postDetail?.author,
        },
        handleSuccess,
        handleError,
      };
      dispatch(createNewPost(obj));
    }
  };

  const UPdatePost = () => {
    if (handleValidate(true)) {
      let obj = {
        ...postDetail,
        postId: editable.id,
        author_id: postDetail?.author,
        thumbnail: thumbnailFile,
        document,
        handleUpdateSuccess,
        handleError,
      };
      dispatch(updatePost(obj));
    }
  };

  const onSelectVideo = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      setVideoPreview({
        file: reader.result,
        url: URL.createObjectURL(e.target.files[0]),
      });
    };
    const fileData = e.target?.files?.[0];
    setFileLoading(true);
    if (fileData) {
      const videoUploaderOptions = {
        fileName: "file_name",
        file: fileData,
        contentType: fileData.type,
      };

      let percentage = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      setUploader(uploader);
      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            setPercentage(percentage);
            console.log(`${percentage}%`);
          }
        })
        .onError((error) => {
          handleUpdateDetail("file", undefined);
          console.error(error);
        });

      uploader.start();
      uploader.onURL((res) => {
        setFileLoading(false);
        handleUpdateDetail("file", res.result.Location);
        setErrorAlert({
          errorMsg: "File is uploaded successfully",
          errorType: "success",
          isOpen: true,
        });
      });
    }
  };

  const onCancel = () => {
    if (uploader) {
      uploader.abort();
      handleUpdateDetail("file", undefined);
    }
  };

  const handleFileChange = (e) => {
    setThumbnailFile(e.target.files[0]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setThumbnailPreview({
        file: reader.result,
        url: URL.createObjectURL(e.target.files[0]),
      });
    };
    let url = reader.readAsDataURL(e.target.files[0]);
  };

  const handleDocumentChange = (e) => {
    setDocument(e.target.files[0]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setDocumentPreview({
        file: reader.result,
        url: URL.createObjectURL(e.target.files[0]),
      });
    };
    let url = reader.readAsDataURL(e.target.files[0]);
  };

  const handleClose = () => {
    setOpen(false);
    setVideoPreview({});
    setThumbnailPreview({});
    setDocument(false);
    setDocumentPreview({});
    setPercentage(undefined);
    onCancel();
    setEditable(false);
    setThumbnailFile(false);
    setPostDetail({
      category_id: "",
      title: "",
      description: "",
      visibility: "",
      author: "",
      file: "",
      audience: "",
      type: "video",
      document: "",
    });
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          className={classes.addItemModalHeading}
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pointerEvents: fileLoading ? "none" : "",
          }}
        >
          <Box>{editable.id ? "Update Post" : "Create Post"}</Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "15px" }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={postDetail?.type}
              onChange={(e) => handleUpdateDetail("type", e.target.value)}
              size="small"
              className={classes.audience}
              sx={{ minWidth: "130px" }}
            >
              <MenuItem disabled value={""}>
                Type
              </MenuItem>
              <MenuItem value={"video"}>Video</MenuItem>
              <MenuItem value={"document"}>Document</MenuItem>
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={postDetail?.visibility || "visibility"}
              onChange={(e) => handleUpdateDetail("visibility", e.target.value)}
              size="small"
              className={classes.audience}
              sx={{ minWidth: "130px" }}
            >
              <MenuItem disabled value={"visibility"}>
                visibility
              </MenuItem>
              <MenuItem value={"Published"}>Published</MenuItem>
              <MenuItem value={"Draft"}>Draft</MenuItem>
              <MenuItem value={"Unlisted"}>Unlisted</MenuItem>
            </Select>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pointerEvents: fileLoading ? "none" : "" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item lg={6} xs={6} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Title</h4>
                <TextField
                  fullWidth
                  value={postDetail?.title}
                  onChange={(e) => handleUpdateDetail("title", e.target.value)}
                  classes={{ root: classes.detailInput }}
                  placeholder="Enter title"
                  variant="outlined"
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={6} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Author</h4>
                {editable && !postDetail?.author ? null : (
                  <Autocomplete
                    disableClearable
                    options={
                      users?.filter(
                        (it, i, self) =>
                          self.findIndex(
                            (ite) => ite.full_name === it.full_name
                          ) == i
                      ) || []
                    }
                    getOptionLabel={(option) => option.full_name}
                    onChange={(e, option) => {
                      handleUpdateDetail("author", option.id);
                    }}
                    value={
                      postDetail?.author
                        ? users?.find(
                            (item) =>
                              Number(item.id) === Number(postDetail?.author)
                          )
                        : undefined
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search user"
                        aria-describedby="stepper-linear-event_types"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  ></Autocomplete>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={6}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Category</h4>
                {editable && !postDetail?.author ? null : (
                  <Autocomplete
                    disableClearable
                    options={categoryData || []}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, option) => {
                      handleUpdateDetail("category_id", option.id);
                    }}
                    value={
                      postDetail?.category_id
                        ? categoryData?.find(
                            (item) =>
                              Number(item?.id || 0) ===
                              Number(postDetail?.category_id)
                          )
                        : undefined
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Category"
                        aria-describedby="stepper-linear-event_types"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  ></Autocomplete>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={6}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Audience</h4>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={postDetail.audience}
                  onChange={(e) =>
                    handleUpdateDetail("audience", e.target.value)
                  }
                  size="small"
                >
                  <MenuItem value={"All Users"}>All Users</MenuItem>
                  <MenuItem value={"Agents"}>Agents</MenuItem>
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={12} xs={12} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Description</h4>
                <TextField
                  value={postDetail?.description}
                  onChange={(e) =>
                    handleUpdateDetail("description", e.target.value)
                  }
                  id="outlined-multiline-static"
                  placeholder="Search or create tags"
                  multiline
                  rows={5}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item lg={5} md={6} xs={12} sx={{ zIndex: "1" }}>
              <Box sx={{ height: "130px" }}>
                {videoPreview?.url && postDetail?.type === "video" ? (
                  <Box
                    sx={{ position: "relative", border: "1px solid lightgrey" }}
                  >
                    <a
                      href={videoPreview.url}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      {" "}
                      <video
                        controls
                        style={{
                          width: "100%",
                          height: "120px",
                          objectFit: "cover",
                        }}
                        src={videoPreview.url}
                        alt={`Post document`}
                      />
                    </a>
                    <HighlightOffRoundedIcon
                      onClick={() => {
                        handleUpdateDetail("file", "");
                        setVideoPreview({});
                      }}
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ) : null}
                {documentPreview?.url && postDetail?.type === "document" ? (
                  <Box
                    sx={{ position: "relative", border: "1px solid lightgrey" }}
                  >
                    <a
                      href={documentPreview.url}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      {" "}
                      <iframe
                        style={{
                          width: "100%",
                          height: "120px",
                          objectFit: "cover",
                        }}
                        src={documentPreview.url}
                        title="post document"
                      />
                    </a>
                    <HighlightOffRoundedIcon
                      onClick={() => {
                        setDocument(false);
                        setDocumentPreview({});
                      }}
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item lg={5} md={6} xs={12} sx={{ zIndex: "1" }}>
              <Box sx={{ height: "130px" }}>
                {thumbnailPreview?.url ? (
                  <Box
                    sx={{ position: "relative", border: "1px solid lightgrey" }}
                  >
                    <a
                      href={thumbnailPreview.url}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      {" "}
                      <img
                        style={{
                          width: "100%",
                          height: "120px",
                          objectFit: "cover",
                        }}
                        src={thumbnailPreview.url}
                        alt={`Post document`}
                      />
                    </a>
                    <HighlightOffRoundedIcon
                      onClick={() => {
                        setThumbnailFile(false);
                        setThumbnailPreview({});
                      }}
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item lg={5} xs={12} sx={{ zIndex: "1" }}>
              {percentage ? (
                <Box sx={{ width: `${percentage || 0}%` }}>
                  <LinearProgress
                    variant="determinate"
                    value={percentage || 0}
                  />
                </Box>
              ) : null}
              <FormControl fullWidth>
                {postDetail?.type === "video" ? (
                  <Button
                    variant="contained"
                    component="label"
                    className={classes.btns}
                  >
                    <img src={arrowUp} alt="arrowUp" />
                    Upload Video
                    <input
                      onChange={onSelectVideo}
                      accept="video/mp4,video/x-m4v,video/*"
                      //  onChange={(e)=>setVideoFile(e.target.files[0])}
                      type="file"
                      hidden
                      onClick={(event) => (event.target.value = "")}
                    />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    component="label"
                    className={classes.btns}
                  >
                    <img src={arrowUp} alt="arrowUp" />
                    {document ? " Replace " : " Upload "}
                    File
                    <input
                      onChange={handleDocumentChange}
                      accept=".pdf"
                      type="file"
                      hidden
                      onClick={(event) => (event.target.value = "")}
                    />
                  </Button>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={5} xs={12} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  component="label"
                  className={classes.btns}
                >
                  <img src={arrowUp} alt="arrowUp" />
                  Upload Thumbnail
                  <input
                    onClick={(event) => (event.target.value = "")}
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={handleFileChange}
                    type="file"
                    hidden
                  />
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            marginBottom: "10px",
            pointerEvents: fileLoading ? "none" : "",
            padding: "5px 24px",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <Button
              onClick={() => handleClose()}
              sx={{
                color: "#000000",
              }}
              className={classes.btns}
              variant="outlined"
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={fileLoading}
              onClick={() => {
                if (fileLoading) {
                  return false;
                }
                if (!editable.id) {
                  CreatePost();
                } else {
                  UPdatePost();
                }
              }}
              classes={{ root: classes.AddPostButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={createPost.isLoading || updatePostState?.isLoading}
            >
              {editable.id ? "Update" : "Save"}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddKnowledgeBasePostStyle" })(
  AddKnowledgeBasePost
);
