import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RenderMultiInputOptions } from "../customFilterabeDataGrid/multipSelectDropdown/RenderMultiInputOptions";

export const RadioGroupUi = ({
  dataList,
  value,
  name,
  label,
  options,
  onChange,
  textField,
  textFieldVal,
  dropDownVal,
  onDropDownSelectFieldChange,
  onTextFieldChange,
  ...props
}) => {
  const [textVals, setTextVal] = useState(textFieldVal);
  useEffect(() => {
    setTextVal(textFieldVal);
  }, [textFieldVal]);
  return (
    <FormControl size="small" fullWidth>
      <RadioGroup
        aria-labelledby={`${name}-radio-buttons-group-label`}
        value={value}
        name={name}
        onChange={(e) => {
          onChange(e);
        }}
      >
        {options.map((op, i) => {
          return (
            <FormControlLabel
              sx={{
                "& .MuiTypography-root": {
                  width: "100%",
                },
              }}
              key={i}
              label={
                op.inputField && value === op.value ? (
                  <TextField
                    type={op.inputFieldType}
                    value={textVals}
                    onChange={(e) => {
                      e.stopPropagation();
                      setTextVal(e.target.value);
                    }}
                    onBlur={(e) => onTextFieldChange(e, textVals)}
                    inputProps={{ sx: { height: "30px", padding: "0 14px" } }}
                    InputLabelProps={{ sx: { height: "30px", fontSize: "13px", top: "-9px" } }}
                    label={op.name}
                    sx={{ height: "30px" }}
                    autoFocus
                  />
                ) : op.multiDrop && value === op.value ? (
                  <>
                    <Typography>{op.name}</Typography>
                    <RenderMultiInputOptions
                      name={op.name}
                      value={dropDownVal}
                      data={dataList}
                      textField={textField}
                      onChange={onDropDownSelectFieldChange}
                    />
                  </>
                ) : op.int_between && value === op.value ? (
                  <Box sx={{ display: "d-flex" }}>
                    <TextField
                      variant="outlined"
                      type={op.inputFieldType}
                      placeholder=""
                      value={textVals && textVals.start}
                      onBlur={(e) => onTextFieldChange(e, textVals)}
                      onChange={(e) => {
                        setTextVal({ ...textVals, start: e.target.value });
                      }}
                      inputProps={{
                        sx: {
                          height: "25px",
                          padding: "0 2px",
                          fontSize: "11px",
                          width: "60px",
                          "&::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                        },
                      }}
                      InputLabelProps={{ sx: { height: "25px", fontSize: "11px", top: "-9px" } }}
                      sx={{ height: "25px", marginRight: "20px" }}
                    />
                    <TextField
                      variant="outlined"
                      placeholder=""
                      type={op.inputFieldType}
                      value={textVals && textVals.end}
                      inputProps={{
                        sx: {
                          height: "25px",
                          padding: "0 2px",
                          fontSize: "11px",
                          width: "60px",
                          "&::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                        },
                      }}
                      InputLabelProps={{ sx: { height: "25px", fontSize: "11px", top: "-9px" } }}
                      sx={{ height: "25px", marginRight: "0px" }}
                      onBlur={(e) => onTextFieldChange(e, textVals)}
                      onChange={(e) => {
                        setTextVal({ ...textVals, end: e.target.value });
                      }}
                    />
                  </Box>
                ) : op.inputDateTimeFrame && value === op.value ? (
                  op.value == "<>" ? (
                    <Box sx={{ display: "d-flex", justifyContent: "space-between" }}>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          variant="outlined"
                          type={op.inputFieldType}
                          placeholder=""
                          value={textVals && textVals.start && textVals.start.value}
                          onBlur={(e) => onTextFieldChange(e, "start")}
                          onChange={(e) => {
                            setTextVal({ ...textVals, start: { ...textVals.start, value: e.target.value } });
                          }}
                          inputProps={{
                            sx: {
                              height: "25px",
                              padding: "0 2px",
                              fontSize: "11px",
                              width: "45px",
                              "&::-webkit-inner-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0,
                              },
                            },
                          }}
                          InputLabelProps={{ sx: { height: "25px", fontSize: "11px", top: "-9px" } }}
                          sx={{ height: "25px", marginRight: "0px" }}
                        />
                        <Select
                          value={(textVals && textVals.start && textVals.start.timeInterval) || "days"}
                          onChange={(e) => onDropDownSelectFieldChange(e, "start")}
                          IconComponent={() => null}
                          displayEmpty
                          sx={{
                            height: "25px",
                            background: "black",
                            color: "white",
                            fontSize: "11px",
                          }}
                          inputProps={{ sx: { paddingLeft: "4px", paddingRight: "4px!important" } }}
                        >
                          <MenuItem value={null} disabled>
                            <Typography component="subtitle" variant="subtitle">
                              Select
                            </Typography>
                          </MenuItem>
                          <MenuItem value="hours">
                            <Typography component="subtitle" variant="subtitle">
                              Hours Ago
                            </Typography>
                          </MenuItem>
                          <MenuItem value="days">
                            <Typography component="subtitle" variant="subtitle">
                              Days Ago
                            </Typography>
                          </MenuItem>
                          <MenuItem value="months">Months Ago</MenuItem>
                        </Select>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          variant="outlined"
                          placeholder=""
                          type={op.inputFieldType}
                          value={textVals && textVals.end && textVals.end.value}
                          inputProps={{
                            sx: {
                              height: "25px",
                              padding: "0 2px",
                              fontSize: "11px",
                              width: "45px",
                              "&::-webkit-inner-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0,
                              },
                            },
                          }}
                          InputLabelProps={{ sx: { height: "25px", fontSize: "11px", top: "-9px" } }}
                          sx={{ height: "25px", marginRight: "0px" }}
                          onBlur={(e) => onTextFieldChange(e, "end")}
                          onChange={(e) => {
                            setTextVal({ ...textVals, end: { ...textVals.end, value: e.target.value } });
                          }}
                        />
                        <Select
                          value={(textVals && textVals.end && textVals.end.timeInterval) || "days"}
                          onChange={(e) => onDropDownSelectFieldChange(e, "end")}
                          type={op.inputFieldType}
                          IconComponent={() => null}
                          displayEmpty
                          sx={{
                            height: "25px",
                            background: "black",
                            color: "white",
                            fontSize: "11px",
                          }}
                          inputProps={{ sx: { paddingLeft: "4px", paddingRight: "4px!important" } }}
                        >
                          <MenuItem value={null} disabled>
                            <Typography component="subtitle" variant="subtitle">
                              Select
                            </Typography>
                          </MenuItem>
                          <MenuItem value="hours">
                            <Typography component="subtitle" variant="subtitle">
                              Hours Ago
                            </Typography>
                          </MenuItem>
                          <MenuItem value="days">
                            <Typography component="subtitle" variant="subtitle">
                              Days Ago
                            </Typography>
                          </MenuItem>
                          <MenuItem value="months">Months Ago</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography>{op.name}</Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          type={op.inputFieldType}
                          variant="outlined"
                          value={textVals?.value}
                          inputProps={{
                            sx: {
                              height: "25px",
                              padding: "0 2px",
                              fontSize: "11px",
                              width: "45px",
                              "&::-webkit-inner-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0,
                              },
                            },
                          }}
                          InputLabelProps={{ sx: { height: "25px", fontSize: "11px", top: "-9px" } }}
                          sx={{ height: "25px", marginRight: "0px" }}
                          label={""}
                          onBlur={onTextFieldChange}
                          onChange={(e) => {
                            console.log(textVals);
                            setTextVal({ ...textVals, value: e.target.value });
                          }}
                          autoFocus
                        />
                        <Select
                          value={(textVals && textVals.timeInterval) || "days"}
                          onChange={onDropDownSelectFieldChange}
                          displayEmpty
                          sx={{
                            height: "25px",
                            background: "black",
                            color: "white",
                            fontSize: "11px",
                          }}
                          inputProps={{ sx: { paddingLeft: "4px", paddingRight: "4px!important" } }}
                        >
                          <MenuItem value={null} disabled>
                            <Typography component="subtitle" variant="subtitle">
                              Select
                            </Typography>
                          </MenuItem>
                          <MenuItem value="hours">
                            <Typography component="subtitle" variant="subtitle">
                              Hours Ago
                            </Typography>
                          </MenuItem>
                          <MenuItem value="days">
                            <Typography component="subtitle" variant="subtitle">
                              Days Ago
                            </Typography>
                          </MenuItem>
                          <MenuItem value="months">Months Ago</MenuItem>
                        </Select>
                      </Box>
                    </>
                  )
                ) : (
                  op.name
                )
              }
              value={op.value}
              control={
                <Radio
                  checked={op.value === value}
                  size="small"
                  sx={{
                    color: "black",
                    "&.Mui-checked": {
                      color: "black",
                    },
                  }}
                />
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
