import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  allChecks: [],
  errMsg: null,
  processLoading: false,
};

export const getPendingChecks = createAsyncThunk("finance/getPendingChecks", async (data, thunkAPI) => {
  try {
    const url = "/api/finance/payments?payment_preference=check";
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      method: "get",
      url,
    };

    return new Promise((resolve, reject) => {
      axios(options)
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          return err;
        });
    });
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const processChecks = createAsyncThunk("finance/processChecks", async (data, thunkAPI) => {
  try {
    let headers = {
      Authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    };

    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/finance/generate-checks`, data, { headers });

    return res.status === 200 ? res.data : res.response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
const pendingChecks = createSlice({
  name: "pendingChecks",
  initialState,
  reducers: {
    updateChecksStore: (state, action) => {
      state.allChecks = action.payload;
    },
  },
  extraReducers: {
    [getPendingChecks.pending]: (state) => {
      state.loading = true;
    },
    [getPendingChecks.fulfilled]: (state, action) => {
      state.loading = false;
      state.allChecks = action.payload.data.map((r) => ({ ...r, needToPrint: true }));
    },
    [getPendingChecks.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },

    [processChecks.pending]: (state) => {
      state.processLoading = true;
    },
    [processChecks.fulfilled]: (state, action) => {
      state.processLoading = false;
    },
    [processChecks.rejected]: (state, action) => {
      state.processLoading = false;
    },
  },
});

export const { updateChecksStore } = pendingChecks.actions;

export default pendingChecks.reducer;
