import { createSlice } from "@reduxjs/toolkit";
import { createMakeover } from "./createMakeover";
import { updateMakeover } from "./updateMakeover";
import { uploadDocument } from "./uploadDocument";
import { updateDocument } from "./updateDocument";
import { generateMakeoverDoc } from "./generateMakeoverDoc";
import { generateMakeoverInvoiceDoc } from "./generateInvoiceDoc";
import { CreateInvoice } from "./addInvoice";
import { updateMakeoverInvoice } from "./updateMakeoverInvoice";
import { uploadSignedAuthDoc } from "./uploadSignAuthDoc";
import { handlePayInvoice } from "./payInvoice";
import { handleAddRepayment } from "./addRepayment";
import { getMakeover } from "./getMakeOverDetail";
import { getMakeoverList } from "./getMakeOverList";
const initialState = {
  makeoverList: {
    success: false,
    data: [],
    count: 0,
    _metaData: {},
    isLoading: true,
    errMsg: null,
  },
  detail: null,
  addMakeover: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  update: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  uploadDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  uploadAuthDocInvoice: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  generateDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addInvoice: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateInvoice: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  generateInvoiceDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  payInvoice: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addRepayment: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const ListingMakeover = createSlice({
  name: "ListingMakeover",
  initialState,

  reducers: {
    ResetDocumentState: (state, action) => {
      state.addMakeover.errMsg = null;
      state.addMakeover.success = false;
      state.uploadDoc.errMsg = null;
      state.uploadDoc.success = false;
      state.updateDoc.errMsg = null;
      state.updateDoc.success = false;
      state.updateInvoice.errMsg = null;
      state.updateInvoice.success = false;
      state.generateInvoiceDoc.errMsg = null;
      state.generateInvoiceDoc.success = false;
      state.uploadAuthDocInvoice.errMsg = null;
      state.uploadAuthDocInvoice.success = false;
    },
    handleUpdateMakeoverDetail: (state, action) => {
      state.detail = action.payload;
    },
    handleUpdateMakeoverInvoice: (state, action) => {
      let documentData = state.detail.makeover_invoices ? [...state.detail.makeover_invoices] : [];
      let index = documentData.findIndex((item) => item.id === action.payload.id);
      documentData.splice(index, 1, { ...documentData[index], vendor: { ...documentData[index].vendor, w9_form: action.payload.w9_form } });
      state.detail.makeover_invoices = documentData;
    },
  },
  extraReducers: {
    // get makeover list detail
    [getMakeoverList.pending]: (state) => {
      state.makeoverList.isLoading = true;
      state.makeoverList.errMsg = null;
      state.makeoverList.success = false;
    },
    [getMakeoverList.fulfilled]: (state, action) => {
      state.makeoverList.success = true;
      state.makeoverList.isLoading = false;
      if (action.payload?.pageNumber && action.payload?.pageNumber > 1) {
        state.makeoverList.data = state.makeoverList.data.concat(action.payload.makeovers);
      } else {
        state.makeoverList.data = action.payload.makeovers;
      }
      state.makeoverList.count = action.payload._metaData.count;
      state.makeoverList._metaData = action.payload._metaData;
    },
    [getMakeoverList.rejected]: (state, action) => {
      state.addMakeover.isLoading = false;
      state.addMakeover.success = false;
      state.addMakeover.errMsg = action.payload;
    },
    // create make over
    [createMakeover.pending]: (state) => {
      state.addMakeover.isLoading = true;
      state.addMakeover.errMsg = null;
      state.addMakeover.success = false;
    },
    [createMakeover.fulfilled]: (state, action) => {
      state.addMakeover.success = true;
      state.addMakeover.isLoading = false;
      state.addMakeover.data = action.payload;
      state.detail = { ...action.payload, processing: true };
    },
    [createMakeover.rejected]: (state, action) => {
      state.addMakeover.isLoading = false;
      state.addMakeover.success = false;
      state.addMakeover.errMsg = action.payload;
    },
    // get makeover detail
    [getMakeover.fulfilled]: (state, action) => {
      state.detail = {
        ...state.detail,
        ...action.payload,
        makeover_documents: state.detail.makeover_documents,
        makeover_invoices: state.detail.makeover_invoices,
      };
    },
    // update makeover
    [updateMakeover.pending]: (state) => {
      state.update.isLoading = true;
      state.update.errMsg = null;
      state.update.success = false;
    },
    [updateMakeover.fulfilled]: (state, action) => {
      state.update.success = true;
      state.update.isLoading = false;
      state.update.data = action.payload;
      state.detail = { ...state.detail, ...action.payload.makeover };
    },
    [updateMakeover.rejected]: (state, action) => {
      state.update.isLoading = false;
      state.update.success = false;
      state.update.errMsg = action.payload;
    },
    // upload make over document
    [uploadDocument.pending]: (state) => {
      state.uploadDoc.isLoading = true;
      state.uploadDoc.errMsg = null;
      state.uploadDoc.success = false;
    },
    [uploadDocument.fulfilled]: (state, action) => {
      state.uploadDoc.success = true;
      state.uploadDoc.isLoading = false;
      state.uploadDoc.data = action.payload;
      if (state.detail && state.detail?.makeover_documents) {
        let newDocs = [...state.detail?.makeover_documents];
        let index = newDocs.findIndex((item) => item.id === action.payload.id);
        newDocs[index] = action.payload;
        state.detail.makeover_documents = newDocs;
      }
    },
    [uploadSignedAuthDoc.rejected]: (state, action) => {
      state.uploadDoc.isLoading = false;
      state.uploadDoc.success = false;
      state.uploadDoc.errMsg = action.payload;
    },
    // upload make over document
    [uploadSignedAuthDoc.pending]: (state) => {
      state.uploadAuthDocInvoice.isLoading = true;
      state.uploadAuthDocInvoice.errMsg = null;
      state.uploadAuthDocInvoice.success = false;
    },
    [uploadSignedAuthDoc.fulfilled]: (state, action) => {
      state.uploadAuthDocInvoice.success = true;
      state.uploadAuthDocInvoice.isLoading = false;
      state.uploadAuthDocInvoice.data = action.payload;
      if (state.detail && state.detail?.makeover_invoices) {
        let newDocs = [...state.detail?.makeover_invoices];
        let index = newDocs.findIndex((item) => item.id === action.payload.id);
        newDocs[index] = { ...newDocs[index], ...action.payload };
        state.detail.makeover_invoices = newDocs;
      }
    },
    [uploadDocument.rejected]: (state, action) => {
      state.uploadAuthDocInvoice.isLoading = false;
      state.uploadAuthDocInvoice.success = false;
      state.uploadAuthDocInvoice.errMsg = action.payload;
    },
    // update make over document
    [updateDocument.pending]: (state) => {
      state.updateDoc.isLoading = true;
      state.updateDoc.errMsg = null;
      state.updateDoc.success = false;
    },
    [updateDocument.fulfilled]: (state, action) => {
      state.updateDoc.success = action.meta?.arg?.type;
      state.updateDoc.isLoading = false;
      state.updateDoc.data = action.payload;
      let newData = action.payload;
      let documentData = state.detail.makeover_documents ? [...state.detail.makeover_documents] : [];
      let index = documentData.findIndex((item) => item.id === action.payload.id);
      documentData.splice(index, 1, newData);
      console.log(newData, "newData");
      if (newData?.escrowWiringInstructionDoc) {
        documentData.push(newData?.escrowWiringInstructionDoc);
      }
      state.detail.makeover_documents = documentData;
    },
    [updateDocument.rejected]: (state, action) => {
      state.updateDoc.isLoading = false;
      state.updateDoc.success = false;
      state.updateDoc.errMsg = action.payload;
    },
    // update make over invoice
    [updateMakeoverInvoice.pending]: (state) => {
      state.updateInvoice.isLoading = true;
      state.updateInvoice.errMsg = null;
      state.updateInvoice.success = false;
    },
    [updateMakeoverInvoice.fulfilled]: (state, action) => {
      state.updateInvoice.success = action.meta?.arg?.type;
      state.updateInvoice.isLoading = false;
      state.updateInvoice.data = action.payload;
      let newData = action.payload;
      let documentData = state.detail.makeover_invoices ? [...state.detail.makeover_invoices] : [];
      let index = documentData.findIndex((item) => item.id === action.payload.id);
      documentData.splice(index, 1, { ...documentData[index], ...newData });
      state.detail = { ...state.detail, makeover_invoices: documentData, invoice_paid_amount: newData.invoice_paid_amount };
    },
    [updateMakeoverInvoice.rejected]: (state, action) => {
      state.updateInvoice.isLoading = false;
      state.updateInvoice.success = false;
      state.updateInvoice.errMsg = action.payload;
    },
    // update pay invoice
    [handlePayInvoice.pending]: (state) => {
      state.payInvoice.isLoading = true;
      state.payInvoice.errMsg = null;
      state.payInvoice.success = false;
    },
    [handlePayInvoice.fulfilled]: (state, action) => {
      state.payInvoice.success = true;
      state.payInvoice.isLoading = false;
      state.payInvoice.data = action.payload;
      let documentData = state.detail.makeover_invoices ? [...state.detail.makeover_invoices] : [];
      let index = documentData.findIndex((item) => item.id === action.payload.id);
      documentData.splice(index, 1, { ...documentData[index], generated_check: action.payload.data, status: "paid" });
      state.detail.makeover_invoices = documentData;
    },
    [handlePayInvoice.rejected]: (state, action) => {
      state.payInvoice.isLoading = false;
      state.payInvoice.success = false;
      state.payInvoice.errMsg = action.payload;
    },
    // update makeover
    [generateMakeoverDoc.pending]: (state) => {
      state.generateDoc.isLoading = true;
      state.generateDoc.errMsg = null;
      state.generateDoc.success = false;
    },
    [generateMakeoverDoc.fulfilled]: (state, action) => {
      state.generateDoc.success = true;
      state.generateDoc.isLoading = false;
      state.generateDoc.data = action.payload;
      let documentData = state.detail.makeover_documents ? [...state.detail.makeover_documents] : [];
      let index = documentData.findIndex((item) => item.id === action.payload.id);
      documentData[index].file_src = action.payload.data;
      state.detail.makeover_documents = documentData;
    },
    [generateMakeoverDoc.rejected]: (state, action) => {
      state.generateDoc.isLoading = false;
      state.generateDoc.success = false;
      state.generateDoc.errMsg = action.payload;
    },
    // generate makeover invoice
    [generateMakeoverInvoiceDoc.pending]: (state) => {
      state.generateInvoiceDoc.isLoading = true;
      state.generateInvoiceDoc.errMsg = null;
      state.generateInvoiceDoc.success = false;
    },
    [generateMakeoverInvoiceDoc.fulfilled]: (state, action) => {
      state.generateInvoiceDoc.success = true;
      state.generateInvoiceDoc.isLoading = false;
      state.generateInvoiceDoc.data = action.payload;
      let invoiceData = state.detail.makeover_invoices ? [...state.detail.makeover_invoices] : [];
      let index = invoiceData.findIndex((item) => item.id === action.payload.id);
      invoiceData[index].authorization_doc = action.payload.data;
      state.detail.makeover_invoices = invoiceData;
    },
    [generateMakeoverInvoiceDoc.rejected]: (state, action) => {
      state.generateInvoiceDoc.isLoading = false;
      state.generateInvoiceDoc.success = false;
      state.generateInvoiceDoc.errMsg = action.payload;
    },
    // Add Invoice
    [CreateInvoice.pending]: (state) => {
      state.addInvoice.isLoading = true;
      state.addInvoice.errMsg = null;
      state.addInvoice.success = false;
    },
    [CreateInvoice.fulfilled]: (state, action) => {
      state.addInvoice.success = true;
      state.addInvoice.isLoading = false;
      state.addInvoice.data = action.payload;
      let invoiceData = state.detail.makeover_invoices ? [...state.detail.makeover_invoices] : [];
      invoiceData.unshift({ ...action.payload, processing: true });
      state.detail.makeover_invoices = invoiceData;
    },
    [CreateInvoice.rejected]: (state, action) => {
      state.addInvoice.isLoading = false;
      state.addInvoice.success = false;
      state.addInvoice.errMsg = action.payload;
    },
    // Add repayment
    [handleAddRepayment.pending]: (state) => {
      state.addRepayment.isLoading = true;
      state.addRepayment.errMsg = null;
      state.addRepayment.success = false;
    },
    [handleAddRepayment.fulfilled]: (state, action) => {
      state.addRepayment.success = true;
      state.addRepayment.isLoading = false;
      state.addRepayment.data = action.payload;
      state.detail = { ...state.detail, ...action.payload.makeover };
    },
    [handleAddRepayment.rejected]: (state, action) => {
      state.addRepayment.isLoading = false;
      state.addRepayment.success = false;
      state.addRepayment.errMsg = action.payload;
    },
  },
});
export const { handleUpdateMakeoverDetail, ResetDocumentState, handleUpdateMakeoverInvoice } = ListingMakeover.actions;
export default ListingMakeover.reducer;
export * from "./updateMakeover";
export * from "./createMakeover";
export * from "./uploadDocument";
export * from "./updateDocument";
export * from "./generateMakeoverDoc";
export * from "./addInvoice";
export * from "./updateMakeoverInvoice";
export * from "./generateInvoiceDoc";
export * from "./uploadSignAuthDoc";
export * from "./payInvoice";
export * from "./addRepayment";
export * from "./getMakeOverDetail";
export * from "./getMakeOverList";
