import { createSlice } from "@reduxjs/toolkit";
import { getTextTemplatesAgentsRecruitment } from "./getTextTemplatesAgentRecruitment";
import { mergeTextTemplateAgentRecruitment } from "./mergeTextTemplateAgentRecruitment";

const initialState = {
  loading: "",
  textTemplates: [],
  savedTextTemplates: [],
  mergeText: {
    list: {},
    loading: "",
    errMsg: "",
    success: "",
  },
  errMsg: null,
};

const textTemplates = createSlice({
  name: "textTemplates",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.loading = false;
      state.mergeText.loading = false;
      state.mergeText.errMsg = "";
      state.mergeText.success = false;
    },

    textEmailTemplate(state, action) {
      const { id, subject, body } = action.payload
      const existingTemplate = state.savedTextTemplates.find(obj => obj.id === id)
      if (existingTemplate) {
        state.savedTextTemplates = state.savedTextTemplates.map((obj) => {
          if (obj.id === id) {
            obj = action.payload;
          }
          return obj;
        });
      }else{
        state.savedTextTemplates.push(action.payload)
      }
    }
  },

  extraReducers: {
    [getTextTemplatesAgentsRecruitment.pending]: (state) => {
      state.loading = true;
    },
    [getTextTemplatesAgentsRecruitment.fulfilled]: (state, action) => {
      state.loading = false;
      if (action?.payload) {
        state.textTemplates = action?.payload?.textmessagetemplates || [];
      }
    },
    [getTextTemplatesAgentsRecruitment.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = "Error";
    },

    [mergeTextTemplateAgentRecruitment.pending]: (state) => {
      state.mergeText.loading = true;
      state.mergeText.errMsg = "";
      state.mergeText.success = false;
    },
    [mergeTextTemplateAgentRecruitment.fulfilled]: (state, action) => {
      state.mergeText.list = action.payload;
      state.mergeText.loading = false;
      state.mergeText.success = true;
    },
    [mergeTextTemplateAgentRecruitment.rejected]: (state, action) => {
      state.mergeText.loading = false;
      state.mergeText.errMsg = "Ooops! An error occured! ";
    },
  },
});

export const selectTextTemplateById = (state, id) => {
  return state.textTemplates.savedTextTemplates.find((list) => list.id === id);
};
export const { resetState, textEmailTemplate } = textTemplates.actions;

export default textTemplates.reducer;
