import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addListing = createAsyncThunk(
  "Listings/addListing",
  async (data, thunkAPI) => {
    const { handleSuccess, handleError, schema } = data;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/transactions/listings`,
        { ...schema },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (handleSuccess && resp.data) {
        handleSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
