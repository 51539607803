import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const addAgentRecruitmentTags = createAsyncThunk("agentProfile/addAgentRecruitmentTags", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/recruitment/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (data.handleSuccess) {
      data.handleSuccess();
    }
    return resp.data.agentRecruitment;
  } catch (error) {
    if (error.response) {
      if (data.handleError) {
        data.handleError(error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      if (data.handleError) {
        data.handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
