import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import shield1 from "../../../../../assets/images/shield1.png";
import shield2 from "../../../../../assets/images/shield2.png";
import shield3 from "../../../../../assets/images/shield3.png";
import shield4 from "../../../../../assets/images/shield4.png";
import Avatar from "../../../../../components/uiElements/avatar/index";
import DataNotFound from "../../../../../components/notFound/dataNotFound";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LocalPoliceRoundedIcon from "@mui/icons-material/LocalPoliceRounded";
function LeaderBoardList(props) {
  let { classes, leaderBoardData, leaderBoards, leaderBoardsPrevious,month} = props;
  const [previousRanks, setPreviousRanks] = useState([]);
  const getDataWithRank = (data) => {
    let newPoints = [...data];
    let newLeaderboard = newPoints.sort((a, b) => b.point - a.point);
    let newPointsWithRank = [];
    let rankNumber = 0;
    newLeaderboard.map((item, index) => {
      if((item.point>0 && new Date().getDate()>=1 && month==="current") || (item.point===0 && new Date().getDate()===1 && month==="current") || (month!=="current" && item.point>0)){
        if (index === 0 && item.point > 0) {
          newPointsWithRank.push({ ...item, rank: 1 });
          rankNumber = rankNumber + 1;
        } else {
          if (item.point === newLeaderboard[index - 1]?.point) {
            newPointsWithRank.push({ ...item, rank: rankNumber });
          } else {
            if (rankNumber > 0) {
              rankNumber = rankNumber + 1;
              newPointsWithRank.push({ ...item, rank: rankNumber });
            } else {
              newPointsWithRank.push({ ...item, rank: rankNumber });
            }
          }
        }
      }
    });

    return newPointsWithRank;
  };

  useEffect(() => {
    if (leaderBoardsPrevious?.length) {
      setPreviousRanks(getDataWithRank(leaderBoardsPrevious));
    }
  }, [leaderBoardsPrevious]);

  return (
    <List dense disablePadding className={classes.leaderBoardList}>
      {leaderBoards?.isLoading
        ? Array.from(Array(11).keys()).map(() => (
            <ListItem sx={{ height: "56px" }} disablePadding>
              <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%", borderRadius: "12px", mr: "8px" }} />
            </ListItem>
          ))
        : ""}
      {leaderBoardData?.length && !leaderBoards?.isLoading
        ? getDataWithRank(leaderBoardData)?.length? getDataWithRank(leaderBoardData).map((item, index) => (
            <ListItem disablePadding key={item.user_id}>
              <ListItemButton className={classes.leaderBoardListItem}>
                <ListItemAvatar>
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Box className={classes.pointsTopRightShields}>
                      <Box className={classes.pointsTopRightShieldValue}>{item.point > 0 ? item.rank || "-" : "-"} </Box>
                      <img
                        src={item.point > 0 ? (item.rank === 1 ? shield3 : item.rank === 2 ? shield4 : item.rank === 3 ? shield2 : shield1) : shield1}
                        alt="point shield"
                      />
                    </Box>
                    <Avatar src={item.profile_img} sx={{ width: "34px", height: "34px", fontSize: "14px" }}>
                      {`${item.name?.slice(0, 1) || ""}${item.name?.split(" ")[1]?.slice(0, 1) || ""}`}
                    </Avatar>
                  </Stack>
                </ListItemAvatar>
                <ListItemText
                  sx={{ ml: "10px" }}
                  primary={
                    <Stack direction="row">
                      {item.name}{" "}
                      {previousRanks?.find((it) => it.user_id === item.user_id)?.point > 0 &&
                      item.point > 0 &&
                      previousRanks?.find((it) => it.user_id === item.user_id)?.rank !== item?.rank ? (
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            color: previousRanks?.find((it) => it.user_id === item.user_id)?.rank < item?.rank ? "red" : "green",
                            ml: "15px",
                          }}
                          alignItems={"center"}
                        >
                          {previousRanks?.find((it) => it.user_id === item.user_id)?.rank - item?.rank > 0 ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}{" "}
                          {(previousRanks?.find((it) => it.user_id === item.user_id)?.rank - item?.rank)}
                        </Stack>
                      ) : null}
                    </Stack>
                  }
                />
                <ListItemSecondaryAction>
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography sx={{ fontSize: "20px" }}>{item.point}</Typography>
                    <StarRateRoundedIcon sx={{ mt: "-3px !important" }} />
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          )):<DataNotFound
          color="#BDBDBD"
          fontSize="16px"
          minHeight="340px"
          icon={<LocalPoliceRoundedIcon sx={{ fontSize: "100px", color: "#BDBDBD" }} fontSize="large" />}
          title="No Leaderboard Exist"
        />
        : null}
    </List>
  );
}
export default withStyles(styleSheet, {
  name: "LeaderBoardListStyle",
})(LeaderBoardList);
