export const styleSheet = () => ({
  pageRoot: {
    marginTop: "50px !important",
  },
  loadingArea: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  BasicDetailRoot: {
    padding: "10px",
    marginBottom: "16px",
    marginTop: "20px",
  },
  pageHeading: {
    fontSize: "30px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  detailInput: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
  },
  saveButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  replaceButton: {
    fontSize: "9px  !important",
    borderRadius: "15px  !important",
    width: "80px !important",
    height: "23px !important",
    background:"white  !important"
  },
  downloadButton: {
    fontSize: "9px  !important",
    borderRadius: "15px  !important",
    backgroundColor: "black !important",
    color: "white !important",
    marginBottom: "6px !important",
    width: "80px !important",
  },
  dropzoneAreaInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18.8px",
    color: "black",
    fontFamily: "AvenirNext",
    fontWeight: "600",
  },
  dropzoneArea: {
    position: "absolute",
    top: "11.5px",
    left: "0px",
    background: "rgba(255,255,255,.6)",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 100000000,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
});
