import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useParams } from 'react-router-dom';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function TasksButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { type } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (type) => {
    navigate(`/tools/recruiting/tasks/${type}`);
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        sx={{
          color: "#fff!important",
          fontSize: "15px!important",
          fontFamily: "AvenirNext!important",
          fontWeight: "600!important" ,
          '& .MuiButton-endIcon': {
            marginTop: '-5px'
          },
          background: "#0B0909 !important",
          fontSize: "12.5px !important",
          color: "white",
          height: "34px !important",
          width: '100px',
          marginRight: "15px !important",
          boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
          borderRadius: "9px  !important",
        }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Tasks
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <>
          <MenuItem
            selected={type === 'today'}
            onClick={() => { 
              handleClose();
              handleChange('today');
            }} disableRipple
          >
            Today
          </MenuItem>
          <MenuItem
            selected={type === 'overdue'}
            onClick={() => { 
              handleClose();
              handleChange('overdue');
            }} disableRipple
          >
            Overdue
          </MenuItem>
          <MenuItem
            selected={type === 'upcoming'}
            onClick={() => { 
              handleClose();
              handleChange('upcoming');
            }} disableRipple
          >
            Future
          </MenuItem>
        </>
      </StyledMenu>
    </>
  );
}