import React from "react";
import { Box, Container } from "@mui/material";
import SubHeader from "./subHeader";
import { Routes, Route } from "react-router-dom";
import DigitalMarketing from "./digital";
import MarketingPrint from "./print";
function MarketingCenterPage(props) {
  return (
    <Box>
      <SubHeader {...props} />
      <Container maxWidth={"xl"}>
        <Routes>
          <Route path="/print/*" element={<MarketingPrint {...props} />} />
          <Route path="/digital/*" element={<DigitalMarketing {...props} />} />
        </Routes>
      </Container>
    </Box>
  );
}
export default MarketingCenterPage;
