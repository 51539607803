import { withStyles } from "@mui/styles";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { styleSheet } from "./style";

const Pagination = ({ next, prev, data, currentIndex }) => {
  if (currentIndex) {
    return (
      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={1.5}>
          <IconButton
            size="small"
            edge="start"
            onClick={prev}
            aria-label="next"
            sx={{ padding: "4px 6px 4px 3px " }}
          >
            <ArrowBackIosNewRoundedIcon sx={{ fontSize: "22px" }} />
          </IconButton>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" component="p">
              {currentIndex} of people {data}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1.5}>
          <IconButton
            sx={{ padding: "4px 3px 4px 5px" }}
            size="small"
            onClick={next}
            aria-label="prev"
          >
            <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
          </IconButton>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
export default withStyles(styleSheet, { name: "paginationStyle" })(Pagination);
