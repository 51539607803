import React from "react";
import { Box, Tabs, Tab, Card } from "@mui/material";
import { TabContext } from "@mui/lab";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import { Container } from "@mui/system";
import ManageLibraryPosts from "./posts";
import ManageLibraryCourses from "./courses";
import { Route, Routes } from "react-router-dom";
import ManageLibraryCategories from "./categories";

function ManageLibraryMain(props) {
  let { classes } = props;
  const location = useLocation();
  return (
    <Box>
      <Card
        variant="outlined"
        sx={{ position: "sticky", top: "64px", zIndex: "100" }}
      >
        {" "}
        <Box className={classes.TopSubNavBarArea}>
          <Box className={classes.TopSubNavBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TopSubNavTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{
                      sx: { background: "black", color: "black" },
                    }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                  >
                    <Tab
                      label="Posts"
                      value={`/knowledge-base/manage-library/posts`}
                      to={`/knowledge-base/manage-library/posts`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />
                    <Tab
                      label="Courses"
                      value={`/knowledge-base/manage-library/courses`}
                      to={`/knowledge-base/manage-library/courses`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />
                    <Tab
                      label="Draft Courses"
                      value={`/knowledge-base/manage-library/draft-courses`}
                      to={`/knowledge-base/manage-library/draft-courses`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />{" "}
                    <Tab
                      label="Categories"
                      value={`/knowledge-base/manage-library/categories`}
                      to={`/knowledge-base/manage-library/categories`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />{" "}
                  </Tabs>
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopSubNavBarAreaLeft}></Box>
        </Box>
      </Card>
      <Container maxWidth="xl" sx={{ mt: "35px" }}>
        <Routes>
          <Route path="/posts" element={<ManageLibraryPosts {...props} />} />
          <Route
            path="/courses"
            element={<ManageLibraryCourses {...props} />}
          />
          <Route
            path="/draft-courses"
            element={<ManageLibraryCourses isDraft={true} {...props} />}
          />
          <Route
            path="/categories"
            element={<ManageLibraryCategories {...props} />}
          />
        </Routes>
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ManageLibraryMainStyle" })(
  ManageLibraryMain
);
