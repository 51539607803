import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  list: null,
  count: 0,
  totalAgents: 0,
  errMsg: null,
  search: "",
};

export const getAgentRosterList = createAsyncThunk(
  "agentList/getAgentRosterList",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/users`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
          params: data,
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const agentRosterList = createSlice({
  name: "agentRosterList",
  initialState,
  reducers: {
    handleUpdateUsers: (state, action) => {
      let users = state.list ? [...state.list] : [];
      users.unshift(action.payload);
      state.list = users;
    },
  },
  extraReducers: {
    [getAgentRosterList.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRosterList.fulfilled]: (state, action) => {
      state.loading = false;
      if (state.list && action.meta?.arg?.pageNumber > 1) {
        state.list = [...state.list, ...action.payload.data.allUsers];
      } else {
        state.list = action.payload.data.allUsers;
      }
      state.totalAgents = action.payload.data.count;
      state.search = action.meta?.arg?.search || "";
    },
    [getAgentRosterList.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});
export const { handleUpdateUsers } = agentRosterList.actions;
export default agentRosterList.reducer;
