import React, { useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Box,
  Stack,
  Card,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
} from "@mui/material";
import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  PointElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getPointsGraph } from "../../../../../redux/agents/agentRosterDetail/points";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  tension: 0.3,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    xAxes: [
      {
        display: false,
      },
    ],
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
    tooltip: {
      callbacks: {
        labelColor: function (tooltipItem, data) {
          if (tooltipItem.datasetIndex === 0) {
            return {
              borderColor: "#FFFFFF",
              backgroundColor: "#FFCD2E",
            };
          }
        },
        label: function (tooltipItems, data) {
          return ` ${
            tooltipItems.dataset?.labels[tooltipItems.dataIndex]
          } points`;
        },
      },
      backgroundColor: "#969494",
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function PointsStats(props) {
  let { classes, userId } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const graphStats = useSelector(
    (state) => state.agentRoster.points.graphStats
  );

  useEffect(() => {
    dispatch(getPointsGraph({ user_id: id || userId }));
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: "Points",
        labels: graphStats?.data?.userPointStats.map((value) => value),
        data: graphStats?.data?.userPointStats?.length
          ? graphStats?.data?.userPointStats.map((value, index) => {
              if (value < 0) {
                return 15;
              } else {
                return value;
              }
            })
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: graphStats?.data?.userPointStats.map(
          (value, index) => {
            if (value < 0) {
              return "red";
            } else {
              return "#000000";
            }
          }
        ),
      },
    ],
  };

  return (
    <Card variant="outlined" className={classes.pointsTabStatsRoot}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography>{new Date().getFullYear()} Points Stats</Typography>
        <PieChartRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.35)" }} />
      </Stack>
      <Box sx={{ mt: "40px", height: "280px" }}>
        <Bar options={options} data={data} />
      </Box>
      <List disablePadding dense sx={{ mt: "40px" }}>
        <ListItem sx={{ background: "#F5F5F5", mb: "8px" }}>
          <ListItemButton sx={{ padding: "0px" }}>
            <ListItemText
              primary={
                <Box>
                  Average Points Earned per{" "}
                  <Box sx={{ fontWeight: "600", display: "inline-block" }}>
                    Month
                  </Box>
                  :
                </Box>
              }
            />
            <ListItemSecondaryAction>
              {graphStats?.data?._metaData?.averagePointsPerMonth || "0"}
            </ListItemSecondaryAction>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ background: "#F5F5F5", mb: "8px" }}>
          <ListItemButton sx={{ padding: "0px" }}>
            <ListItemText
              primary={
                <Box>
                  Average Points Earned per{" "}
                  <Box sx={{ fontWeight: "600", display: "inline-block" }}>
                    Week
                  </Box>
                  :
                </Box>
              }
            />
            <ListItemSecondaryAction>
              {graphStats?.data?._metaData?.averagePointsPerWeek || "0"}
            </ListItemSecondaryAction>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ background: "#F5F5F5", mb: "8px" }}>
          <ListItemButton sx={{ padding: "0px" }}>
            <ListItemText
              primary={
                <Box>
                  Average Points Earned per{" "}
                  <Box sx={{ fontWeight: "600", display: "inline-block" }}>
                    Day
                  </Box>
                  :
                </Box>
              }
            />
            <ListItemSecondaryAction>
              {graphStats?.data?._metaData?.averagePointsPerDay || "0"}
            </ListItemSecondaryAction>
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
}
export default withStyles(styleSheet, {
  name: "PointsStatsStyle",
})(PointsStats);
