export const styleSheet = () => ({
  buyerSellerCardAlignment: {
    paddingTop: "35px !important",
    paddingLeft: "35px !important",
    paddingRight: "35px !important",
    paddingBottom: "15px !important",
    justifyContent: "center",
    aliginItem: "center",
  },
});
