import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {  useSelector } from "react-redux";
import {  InputLabel } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function TrainingRequirementsSelect(props) {
  let { open, setOpen, classes, trainingReq, setTrainingReq } = props;
  const overAllKnowledgeBaseData = useSelector((state) => state.knowledgeBase.search.overAllKnowledgeBaseData);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTrainingReq(value);
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.paymentModalHeading} id="alert-dialog-title">
          Training Requirements
        </DialogTitle>
        <DialogContent sx={{ width: "380px" }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Select Requirement Training</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              fullWidth
              value={trainingReq}
              onChange={handleChange}
              input={<OutlinedInput label="Select Requirement Training" />}
              renderValue={(selected) => selected.map((item) => item.title).join(", ")}
              MenuProps={MenuProps}
            >
              {overAllKnowledgeBaseData?.map((item) => (
                <MenuItem key={item.id} value={item}>
                  <Checkbox checked={trainingReq.findIndex((item1) => item1.id === item.id) > -1} />
                  <ListItemText primary={item.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <LoadingButton
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            onClick={() => setOpen(false)}
          >
            OK
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "TrainingRequirementsSelectStyle" })(TrainingRequirementsSelect);
