export const styleSheet = (theme) => ({
  AddPhoneButton:{
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft:'30px !important',
    paddingRight:'30px !important'
  },
  AddPhoneHeading:{
    fontSize:'22px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
    display:'flex',
    justifyContent:'space-between'
  },
  customerTextField:{
    "& .MuiOutlinedInput-root":{
      fontSize: "12.5px !important",
    }
  },
  loadingArea:{
    height:"50vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  mainListArea:{
    height:"36vh",
    overflow:"auto  !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",

    },
  }
});
