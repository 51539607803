import React from "react";
import { useStyles } from "./style.js";
import { Grid, Typography, useMediaQuery } from "@mui/material";

function DetailsGrid({ detailName, detailValue, shortName }) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:390px)");

  return (
    <Grid className={classes.rows} container>
      <Grid item xs={matches ? 12 : 6}>
        <Typography
          className={`${classes.fontFamily} ${classes.tableCell}`}
          noWrap
        >
          {!matches ? detailName : shortName}
        </Typography>
      </Grid>
      <Grid item xs={matches ? 12 : 6}>
        <Typography
          className={`${classes.fontFamily} ${classes.tableCell} ${classes.alignRight}`}
          noWrap
        >
          {detailValue ? detailValue : ""}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DetailsGrid;
