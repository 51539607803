import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px !important",
    border: "1px solid rgba(0,0,0,0.5)",
  },
  headerSection: {
    [theme.breakpoints.down("xsm")]: {
      height: "150px !important",
      padding: "0 !important",
    },
    [theme.breakpoints.down("md")]: {
      height: "272 !important",
      padding: "10px !important",
    },
    display: "flex",
    flexDirection: "column",
    height: "240px !important",
    boxSizing: "border-box",
  },
  propertyImage: {
    [theme.breakpoints.up("xsm")]: {
      display: "none",
    },
    height: "100px",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  fontFamily: {
    textTransform: "uppercase",
    fontFamily: '"Work Sans", sans-serif !important',
    letterSpacing: "2.5px !important",
  },
  name: {
    [theme.breakpoints.down("xsm")]: {
      fontSize: "12px !important",
      lineHeight: "17.4px !important",
      marginTop: "5px !important",
    },
    fontSize: "18px !important",
    fontWeight: "600 !important",
    lineHeight: "38px !important",
  },
  address1: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      display: "none",
    },
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "38px !important",
  },
  address2: {
    [theme.breakpoints.down("xsm")]: {
      display: "none",
    },
    fontWeight: "500 !important",
    fontSize: "15px !important",
    lineHeight: "32px !important",
  },
  tableCell: {
    [theme.breakpoints.down("xsm")]: {
      fontSize: "10px !important",
    },
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "1 !important",
    textOverflow: "ellipsis !important",
  },
  alignRight: {
    [theme.breakpoints.down("xsm")]: {
      textAlign: "left",
      margin: "5px 0px 0px 0px !important",
      color: "#5F5F5F",
    },
    textAlign: "right",
  },
  tableContainer: {},
  rows: {
    [theme.breakpoints.down("xsm")]: {
      padding: "0px 10px",
    },
    padding: "0px 15px",
    height: "38px",
    alignContent: "center",
  },
  bottomContainer: {
    [theme.breakpoints.down("md")]: {
      background: "#000",
      padding: "10px",
    },
    [theme.breakpoints.down("xsm")]: {
      padding: "0",
    },
    height: "120px",
  },
}));
