import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import { useSelector, useDispatch } from "react-redux";
import {
  autoAdjustmentComparable,
  addAdjustmentsInComparable,
  setComparableCustomValue,
} from "../../../../../redux/tools/cmas.js";
import { NumberFormat } from "../../../../../utils/numberFormat";
import { useParams } from "react-router-dom";
const columns = [
  // {
  //   title: "Subd/Complex",
  //   field: "SubdivisionName",
  // },
  {
    title: "total bedrooms",
    field: "BedroomsTotal",
    input: true,
  },
  { title: "baths full", field: "BathroomsFull", input: true },
  { title: "BAths half", field: "BathroomsHalf", input: true },
  { title: "# of parking spaces", field: "ParkingTotal", input: true },
  { title: "# of garage spaces", field: "GarageSpaces", input: true },
  // { title: "# of carport spaces", field: "carportSpace", input: true },
  // { title: "parking desc", field: "ParkingTotal" },
  { title: "Basement", field: "Basement" },
  // { title: "addtl rooms", field: "addtl" },
  { title: "Const Materials", field: "ConstructionMaterials" },
  // { title: "lot desc", field: "lot" },
  // { title: "STYLE", field: "STYLE" },
  { title: "# OF FIREPLACES", field: "FireplacesTotal", input: true },
  { title: "ACRES", field: "LotSizeAcres", input: true },
  { title: "Levels", field: "Levels" /* , input: true  */ },
  { title: "SQUARE FOOTAGE", field: "BuildingAreaTotal", input: true },
  { title: "YEAR BUILT", field: "YearBuilt" },
];

const AllColumns = (props) => {
  let { classes } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const cmaId = params.id;
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeFields, setActiveFields] = useState([]);
  const singleCma = useSelector((state) => state.allCmas.singleCma);
  const adjustmentData = useSelector((state) => state.allCmas.adjustmentData);

  const handleUpdateValue = (field, value) => {
    const data = {};
    if (value) {
      data[field] = [];
      singleCma?.cma_comparables?.map((comparable) => {
        let calculatedCMA =
          ((singleCma[field] || 0) - (comparable[field] || 0)) * value;
        data[field].push({
          id: comparable.id,
          adjustment: calculatedCMA,
        });
      });
      dispatch(
        autoAdjustmentComparable({
          id: cmaId,
          putData: data,
          shouldFetchAdjustment: true,
          updateState: true,
        })
      );
    }
  };

  const handleUpdateAdjValue = async (
    field,
    value,
    cma,
    cma_custom_field_id
  ) => {
    if (cma_custom_field_id) {
      await dispatch(
        setComparableCustomValue({
          cmaId: cma?.cma_id,
          id: cma_custom_field_id,
          value: value,
        })
      );
    } else {
      let obj = {};
      let comparableAdjustedValues = {};
      obj = adjustmentData?.cma_comparables?.find(
        (dat) => dat["id"]?.value === cma?.id
      );
      singleCma?.cma_custom_fields.map((customField) => {
        comparableAdjustedValues[customField.name] = obj[customField.name];
      });

      let object;
      if (comparableAdjustedValues[field]?.custom_field_id) {
        object = {
          [comparableAdjustedValues[field].name]: {
            id: comparableAdjustedValues[field]?.custom_field_id,
            adjustment: Number.parseFloat(
              value || comparableAdjustedValues[field]?.adjustment
            ),
          },
        };
      } else {
        object = {
          [field]: {
            id: null,
            adjustment: Number.parseFloat(value),
          },
        };
      }
      await dispatch(
        addAdjustmentsInComparable({
          ...object,
          id: cma?.id,
          cmaId: cma?.cma_id,
        })
      );
    }
    setTimeout(() => {
      setActiveFields((acFields) =>
        acFields.filter((it) => it !== `${field}${cma.id}`)
      );
    }, 1000);
  };

  useEffect(() => {
    if (singleCma?.cma_custom_fields?.length) {
      singleCma?.cma_custom_fields?.map((field) => {
        columns.push({
          title: field?.name,
          field: field?.name,
          cma_custom_field_id: field.id,
        });
      });
    }
  }, [singleCma?.cma_custom_fields?.length]);
  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#D9D9D9" }}>
              {columns
                ?.filter(
                  (item, i, self) =>
                    self.findIndex((it) => it.field === item.field) == i
                )
                .slice(activeIndex, activeIndex + 3)
                .map((column, index) => (
                  <TableCell
                    key={column.field}
                    style={{
                      borderLeft:
                        index === 0 ? "1px solid rgba(0, 0, 0, 0.30)" : "",
                      padding: "9px 7px 7px 7px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      width: "33.3% !important",
                    }}
                    className={classes.tableHeaderColumn}
                  >
                    {column?.title}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ backgroundColor: "rgba(217, 217, 217, 0.30)" }}>
              {columns
                .slice(activeIndex, activeIndex + 3)
                .map((column, index) => (
                  <TableCell
                    className={classes.featuredCol1}
                    sx={{ width: "33.3%" }}
                  >
                    {column?.input ? (
                      <TextField
                        onBlur={(e) =>
                          handleUpdateValue(column.field, e.target.value)
                        }
                        key={column.field}
                        sx={{
                          backgroundColor: "white",
                          width: "105px",
                          height: "28px",
                          "& .MuiInputBase-input": {
                            padding: "3px 6px",
                          },
                        }}
                        size="small"
                      />
                    ) : (
                      <Box sx={{ height: "28px" }}></Box>
                    )}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: "rgba(217, 217, 217, 0.30)" }}>
              {columns
                ?.filter(
                  (item, i, self) =>
                    self.findIndex((it) => it.field === item.field) == i
                )
                .slice(activeIndex, activeIndex + 3)
                .map((column, index) => (
                  <TableCell
                    sx={{
                      textAlign: "right",
                      width: "33.3% !important",
                    }}
                    className={classes.featuredCol2}
                  >
                    {singleCma[column.field] ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box
                          title={singleCma[column.field]}
                          className={classes.labelValue}
                          sx={{
                            paddingRight:
                              column.field === "ConstructionMaterials"
                                ? "33px"
                                : "",
                          }}
                        >
                          {singleCma[column.field]}
                        </Box>
                        <Box
                          sx={{
                            width: "101px",

                            // column.field === "ConstructionMaterials"
                            //   ? "142px"
                            //   : "101px",
                          }}
                        ></Box>
                      </Box>
                    ) : (
                      <Box sx={{ height: "17px" }}></Box>
                    )}
                  </TableCell>
                ))}
            </TableRow>
            {singleCma?.cma_comparables.map((row, idx) => (
              <TableRow key={row.id}>
                {columns
                  ?.filter(
                    (item, i, self) =>
                      self.findIndex((it) => it.field === item.field) == i
                  )
                  .slice(activeIndex, activeIndex + 3)
                  .map((column, index) => (
                    <TableCell
                      sx={{
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: index === 0 ? "1px solid #e0e0e0" : "",
                        borderTop: "1px solid #e0e0e0",
                        padding: "3.5px !important",
                        textAlign: "center",
                        fontWeight: "600 !important",
                        fontSize: "13px !important",
                        fontFamily: "AvenirNext !important",
                        width: "33.3% !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: column.cma_custom_field_id
                            ? "flex-start"
                            : "flex-end",
                          alignItems: "center",
                          flexWrap: "nowrap",
                          gap: "10px",
                        }}
                      >
                        {column.cma_custom_field_id ? null : (
                          <Box
                            title={row[column.field]}
                            className={classes.labelValue}
                          >
                            {row[column.field]}
                          </Box>
                        )}
                        {activeFields.includes(`${column.field}${row.id}`) ? (
                          <TextField
                            onBlur={(e) => {
                              handleUpdateAdjValue(
                                column.field,
                                e.target.value,
                                row,
                                adjustmentData?.cma_comparables?.find(
                                  (dat) => dat["id"]?.value === row?.id
                                )?.[column.field]?.custom_field_id
                              );
                            }}
                            autoFocus
                            defaultValue={
                              column.cma_custom_field_id
                                ? adjustmentData?.cma_comparables?.find(
                                    (dat) => dat["id"]?.value === row?.id
                                  )?.[column.field]?.value
                                : Number.parseFloat(
                                    adjustmentData?.cma_comparables?.find(
                                      (dat) => dat["id"]?.value === row?.id
                                    )?.[column.field]?.adjustment || 0
                                  )
                            }
                            sx={{
                              backgroundColor: "white",
                              width: "105px",
                              minWidth: "105px",
                              "& .MuiInputBase-input": {
                                padding: "3px 6px",
                                color:
                                  Number.parseFloat(
                                    adjustmentData?.cma_comparables?.find(
                                      (dat) => dat["id"]?.value === row?.id
                                    )?.[column.field]?.adjustment
                                  ) > 0
                                    ? "#4CAF50"
                                    : Number.parseFloat(
                                        adjustmentData?.cma_comparables?.find(
                                          (dat) => dat["id"]?.value === row?.id
                                        )?.[column.field]?.adjustment
                                      ) < 0
                                    ? "#E65100"
                                    : "rgba(0, 0, 0, 0.42)",
                              },
                            }}
                            key={`${column.field}${row.id}0`}
                            size="small"
                          />
                        ) : (
                          <TextField
                            onFocus={() =>
                              setActiveFields((acFields) => [
                                ...acFields,
                                `${column.field}${row.id}`,
                              ])
                            }
                            value={
                              column.cma_custom_field_id
                                ? adjustmentData?.cma_comparables?.find(
                                    (dat) => dat["id"]?.value === row?.id
                                  )?.[column.field]?.value
                                : adjustmentData?.cma_comparables?.find(
                                    (dat) => dat["id"]?.value === row?.id
                                  )?.[column.field]?.adjustment < 0
                                ? `(${new Intl.NumberFormat().format(
                                    Math.abs(
                                      adjustmentData?.cma_comparables?.find(
                                        (dat) => dat["id"]?.value === row?.id
                                      )?.[column.field]?.adjustment
                                    )
                                  )})`
                                : new Intl.NumberFormat().format(
                                    adjustmentData?.cma_comparables?.find(
                                      (dat) => dat["id"]?.value === row?.id
                                    )?.[column.field]?.adjustment || 0
                                  )
                            }
                            dir={column.cma_custom_field_id ? "" : "rtl"}
                            sx={{
                              backgroundColor: "white",
                              width: "105px",
                              minWidth: "105px",
                              "& .MuiInputBase-input": {
                                padding: "3px 6px",
                                color: column.cma_custom_field_id
                                  ? "#000"
                                  : Number.parseFloat(
                                      adjustmentData?.cma_comparables?.find(
                                        (dat) => dat["id"]?.value === row?.id
                                      )?.[column.field]?.adjustment
                                    ) > 0
                                  ? "#4CAF50"
                                  : Number.parseFloat(
                                      adjustmentData?.cma_comparables?.find(
                                        (dat) => dat["id"]?.value === row?.id
                                      )?.[column.field]?.adjustment
                                    ) < 0
                                  ? "#E65100"
                                  : "rgba(0, 0, 0, 0.42)",
                              },
                            }}
                            key={`${column.field}${row.id}1`}
                            size="small"
                          />
                        )}

                        {column.cma_custom_field_id ? (
                          activeFields.includes(
                            `${column.field}${row.id}${column.cma_custom_field_id}`
                          ) ? (
                            <TextField
                              onBlur={(e) => {
                                handleUpdateAdjValue(
                                  column.field,
                                  e.target.value,
                                  row
                                );
                                setActiveFields((acFields) =>
                                  acFields.filter(
                                    (it) =>
                                      it !==
                                      `${column.field}${row.id}${column.cma_custom_field_id}`
                                  )
                                );
                              }}
                              autoFocus
                              defaultValue={Number.parseFloat(
                                adjustmentData?.cma_comparables?.find(
                                  (dat) => dat["id"]?.value === row?.id
                                )?.[column.field]?.adjustment || 0
                              )}
                              sx={{
                                backgroundColor: "white",
                                width: "105px",
                                minWidth: "105px",
                                "& .MuiInputBase-input": {
                                  padding: "3px 6px",
                                },
                              }}
                              key={`${column.field}${row.id}0${column.cma_custom_field_id}`}
                              size="small"
                            />
                          ) : (
                            <TextField
                              onFocus={() =>
                                setActiveFields((acFields) => [
                                  ...acFields,
                                  `${column.field}${row.id}${column.cma_custom_field_id}`,
                                ])
                              }
                              value={
                                adjustmentData?.cma_comparables?.find(
                                  (dat) => dat["id"]?.value === row?.id
                                )?.[column.field]?.adjustment < 0
                                  ? `(${new Intl.NumberFormat().format(
                                      Math.abs(
                                        adjustmentData?.cma_comparables?.find(
                                          (dat) => dat["id"]?.value === row?.id
                                        )?.[column.field]?.adjustment
                                      )
                                    )})`
                                  : new Intl.NumberFormat().format(
                                      adjustmentData?.cma_comparables?.find(
                                        (dat) => dat["id"]?.value === row?.id
                                      )?.[column.field]?.adjustment || 0
                                    )
                              }
                              sx={{
                                backgroundColor: "white",
                                width: "105px",
                                minWidth: "105px",
                                "& .MuiInputBase-input": {
                                  padding: "3px 6px",
                                  color:
                                    Number.parseFloat(
                                      adjustmentData?.cma_comparables?.find(
                                        (dat) => dat["id"]?.value === row?.id
                                      )?.[column.field]?.adjustment
                                    ) > 0
                                      ? "#4CAF50"
                                      : Number.parseFloat(
                                          adjustmentData?.cma_comparables?.find(
                                            (dat) =>
                                              dat["id"]?.value === row?.id
                                          )?.[column.field]?.adjustment
                                        ) < 0
                                      ? "#E65100"
                                      : "rgba(0, 0, 0, 0.42)",
                                },
                              }}
                              dir={"rtl"}
                              key={`${column.field}${row.id}1${column.cma_custom_field_id}`}
                              size="small"
                            />
                          )
                        ) : null}
                      </Box>
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.allColsFooter}>
        <Box className={classes.footerPaginationArea}>
          <Button
            onClick={() => {
              if (activeIndex > 0) {
                setActiveIndex((index) => index - 1);
              }
            }}
            variant="outlined"
            className={classes.PaginationMainButton}
          >
            <ArrowBackIosNewRoundedIcon />
          </Button>
          <Box className={classes.paginationInnerSection}>
            {columns?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor:
                    activeIndex === index ||
                    activeIndex === index - 1 ||
                    activeIndex === index - 2
                      ? "#B9DEFF"
                      : "white",
                }}
                className={classes.paginationInnerSectionItem}
                onClick={() => {
                  if (
                    columns?.length - 1 === index ||
                    columns?.length - 2 === index ||
                    columns?.length - 3 === index
                  ) {
                    setActiveIndex(index - 2);
                  } else {
                    setActiveIndex(index);
                  }
                }}
              ></Box>
            ))}
          </Box>
          <Button
            onClick={() => {
              if (columns?.length - 3 > activeIndex) {
                setActiveIndex((index) => index + 1);
              }
            }}
            variant="outlined"
            className={classes.PaginationMainButton}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default withStyles(styleSheet, { name: "AllColumnsStyle" })(AllColumns);
