import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ToastContainer } from "react-toastify";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
  Backdrop,
  Stack,
  IconButton,
} from "@mui/material";
import Input from "../../../../../components/uiElements/input/Input";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import Button from "../../../../../components/uiElements/button";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailLead } from "../../../../../redux/leads/sendEmailLeads";
import { useParams } from "react-router-dom";
import {
  modalSubmitSuccess,
  selectedEmailList,
} from "../../../../../redux/agents/getAgentProfile";
import {
  saveEmailTemplate,
  selectEmailTemplateById,
} from "../../../../../redux/agents/agentRecruitment/getAllTemplates";
import { GetEmailTemplatesModal } from "../../Modal/getEmailTemplatesModal";
import { stringAvatar } from "../../../../../utils/avatarInitial";
import EmailTemplatesPopper from "../../../../../components/shared/getEmailTemplatesPopper";
import DeleteDialog from "../../../../../components/shared/deleteModal";
import ReactQuill, { Quill } from "react-quill";
import { ValidateEmail } from "../../../../../utils/emailValidator";
import "react-quill/dist/quill.snow.css";

let Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

const EmailTab = ({
  btnText,
  btnStyle,
  classes,
  leadProfile,
  disableFrom,
  IsPopup,
  handleAddMinimize,
  minimizeFlag,
  maximizedTab,
  handleClosePopup,
}) => {
  const dispatch = useDispatch();
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [edtitorValue, setEdtitorValue] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [confirmOpen, setconfirmOpen] = useState(false);
  const [isCC, setIsCC] = useState(false);
  const [isBCC, setIsBCC] = useState(false);
  const [CCList, setCCList] = useState([]);
  const [inputValueEmail, setInputValueEmail] = useState("");
  const [BCCList, setBCCList] = useState([]);
  const setConfirmHandler = () => {
    setconfirmOpen(true);
  };

  const closeDeleteHandler = () => {
    setconfirmOpen(false);
  };
  const [emailValue, setEmailValue] = useState("");
  const [subjectVal, setSubjectVal] = useState("");
  const [routeId, setRouteId] = useState();

  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const templateData = useSelector((state) =>
    selectEmailTemplateById(state, param.id)
  );
  const {
    adminAuth: { adminDetail },
  } = useSelector((item) => item);

  useEffect(() => {
    if (param) {
      setRouteId(param?.id);
    }
  }, [param]);

  useEffect(() => {
    if (templateData && !IsPopup) {
      setSubjectVal(templateData.subject);
      if (templateData.body.includes("m99.png")) {
        setEmailValue(templateData.body);
        if (
          templateData.body.slice(0, 60) !==
          "<div><br></div><div><br></div><div><br></div><div><br></div>"
        ) {
          setIsUpdate(true);
        }
      } else {
        setEmailValue(
          templateData.body + `<br /><br /><br />${adminDetail.email_signature}`
        );
      }
      handleCheckValue();
    } else if (adminDetail.email_signature) {
      setEmailValue(`<br /><br /><br /><br />${adminDetail.email_signature}`);
      handleCheckValue();
    }
  }, []);

  useEffect(() => {
    if (minimizeFlag) {
      let obj = {
        body: "test",
        subject: subjectVal,
        message: "",
        toEmailList: toEmailList,
        html: emailValue,
        attachments: uploadFiles || [],
        CCList: CCList,
        BCCList: BCCList,
        inputValueEmail,
      };
      handleAddMinimize(obj);
    }
  }, [minimizeFlag]);

  useEffect(() => {
    if (maximizedTab && maximizedTab.tabDetail) {
      selectedTagFun(maximizedTab.tabDetail?.toEmailList);
      setSelectedItem(maximizedTab.tabDetail?.toEmailList);
      setUploadFiles(maximizedTab.tabDetail?.attachments);
      setCCList(maximizedTab.tabDetail?.CCList || []);
      setBCCList(maximizedTab.tabDetail?.BCCList || []);
      setSubjectVal(maximizedTab.tabDetail?.subject || "");
      if (maximizedTab.tabDetail?.subject) {
        setSubjectVal(maximizedTab.tabDetail?.subject || "");
      }
      if (maximizedTab.tabDetail?.html) {
        setEmailValue(maximizedTab.tabDetail?.html);
      }

      if (maximizedTab.tabDetail?.inputValueEmail) {
        setInputValueEmail(maximizedTab.tabDetail?.inputValueEmail);
      }

      handleCheckValue();
    }
  }, [maximizedTab]);

  const handleCheckValue = () => {
    setTimeout(() => {
      setEmailValue(
        `<div>${
          document.getElementsByClassName("ql-editor")[0]?.innerHTML
        }</div>`
      );
    }, 200);
  };
  useEffect(() => {
    const saveDraft = setTimeout(() => {
      dispatch(
        saveEmailTemplate({
          id: routeId,
          subject: subjectVal,
          body: emailValue,
        })
      );
    }, 500);

    return () => {
      clearTimeout(saveDraft);
    };
  }, [emailValue]);
  const handleChangeValue = (value, e, type) => {
    setEmailValue(value);
    if (!isUpdate && type === "user") {
      setIsUpdate(true);
    }
    let foundSlash = false;
    const textItems = value.split("\n");

    textItems.map((newLine) => {
      let createDomNode = document.createElement("div");
      createDomNode.innerHTML = newLine;

      if (createDomNode.innerText !== "") {
        let getEditorValue = createDomNode.innerText;
        let firstChar = getEditorValue.charAt(0);
        if (firstChar === "/") {
          foundSlash = true;
          const removeChar = getEditorValue.replace("/", "");
          setEdtitorValue(removeChar);
        }
      }
    });

    if (foundSlash !== false) {
      setPopupModalStatus(true);
    } else {
      setPopupModalStatus(false);
    }
  };

  const clearTextEditor = () => {
    setEmailValue(`<br /><br /><br /><br />${adminDetail.email_signature}`);
    setIsUpdate(false);
    setSubjectVal("");
    setShowDeleteButton(false);
    setconfirmOpen(false);
    handleCheckValue();
  };

  const {
    agentProfile: { toEmailList },
  } = useSelector((item) => item.agentProfile);
  const { agentProfile, currentTab } = useSelector((item) => item.agentProfile);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [templateModal, setTemplateModal] = useState(false);

  const [open, setOpen] = useState({
    state: false,
    type: "",
    desc: "",
  });
  const [selectedItem, setSelectedItem] = useState([]);
  const [recipientList, setRecipientList] = useState([]);
  const [hideDeleteEmailButton, setHideDeleteEmailButton] = useState(true);

  const [popupModalStatus, setPopupModalStatus] = useState(false);

  useEffect(() => {
    if (
      subjectVal.length !== 0 ||
      emailValue.replace(/<[^>]*>?/gm, "").length !== 0
    ) {
      setHideDeleteEmailButton(false);
    } else {
      setHideDeleteEmailButton(true);
    }
  }, [emailValue, subjectVal]);

  useEffect(() => {
    if (agentProfile?.list?.MemberEmail) {
      const member = {
        name: agentProfile?.list?.MemberFullName,
        email: agentProfile?.list?.MemberEmail,
        picture: agentProfile?.list?.picture,
      };
      setRecipientList((prev) => [...prev, member]);
      setSelectedItem((prev) => [...prev, member]);
    }
    return () => {
      setOpen({
        state: false,
        type: "",
        desc: "",
      });
      dispatch(modalSubmitSuccess());
    };
  }, []);
  const handleUploadFiles = (file) => {
    if (uploadFiles.length > 2) {
      return;
    }
    if (file) {
      setUploadFiles((prevSt) => [file, ...prevSt]);
    }
  };
  const handleRemoveFile = (itemIndex) => {
    setUploadFiles((prevState) =>
      prevState.filter((i, index) => index !== +itemIndex)
    );
  };
  const handleSendForm = async (event) => {
    event.preventDefault();
  };
  const selectedTemplateFnc = ({ subject, body }) => {
    if (subject && body) {
      setShowDeleteButton(true);
      let emailOldVal = emailValue?.toString("html");
      let textValues = emailOldVal?.split("\n");
      var replaceText,
        replaceTextWith = "";

      textValues?.map((items) => {
        let createDomNode = document.createElement("div");
        createDomNode.innerHTML = items;
        if (createDomNode.innerText !== "") {
          let getEditorValue = createDomNode.innerText;
          let firstChar = getEditorValue.charAt(0);
          let realRichTextElm = createDomNode.firstChild;
          replaceText = createDomNode.innerHTML;
          if (firstChar === "/") {
            realRichTextElm.innerText = body;
            replaceTextWith = realRichTextElm;
          }
        }
      });
      let tagName = replaceTextWith.tagName?.toLowerCase();
      replaceTextWith = `<${tagName}>${replaceTextWith.innerText}</${tagName}>`;
      let updatedBody = emailOldVal?.replace(replaceText, replaceTextWith);
      dispatch(
        saveEmailTemplate({
          id: routeId,
          subject,
          updatedBody: body || updatedBody,
        })
      );
      setEmailValue(
        (body || updatedBody) + "<br />" + adminDetail.email_signature
      );
      setSubjectVal(subject);
      setOpen({
        state: "true",
        type: "success",
        desc: "Email template has been imported!",
      });
      setLoading(false);
    }
  };
  const sendEmailFunc = async () => {
    let NewEmailList = [...toEmailList];
    if (inputValueEmail && ValidateEmail(inputValueEmail)) {
      let obj = {
        name: "",
        email: inputValueEmail,
      };
      NewEmailList.push(obj);
    }
    for (let i = 0; i <= NewEmailList.length; i++) {
      const schema = {
        personId: IsPopup ? null : routeId,
        body: "test",
        subject: subjectVal || "No Subject",
        message: "message test",
        toEmail: NewEmailList[i]?.email,
        html:
          emailValue ||
          "<p>🙋🏻‍♀️  &mdash; This is a new <b>test email</b> from meh to test attachments</p>",
        attachments: uploadFiles || [],
        cc: CCList?.length ? CCList.map((item) => item.email)?.join(",") : "",
        bcc: BCCList?.length
          ? BCCList?.map((item) => item.email)?.join(",")
          : "",
      };
      if (!selectedItem.length && !ValidateEmail(inputValueEmail)) {
        setOpen({
          state: true,
          type: "error",
          desc: "Please enter recipient!",
        });
      }
      if (NewEmailList[i]?.email && !ValidateEmail(NewEmailList[i]?.email)) {
        setOpen({
          state: true,
          type: "error",
          desc: "Please enter valid email address",
        });
      }
      if (
        NewEmailList[i] !== undefined &&
        ValidateEmail(NewEmailList[i]?.email)
      ) {
        setSendEmailLoading(true);
        if (IsPopup) {
          delete schema.personId;
          schema.isCommon = true;
          schema.ccEmail = schema.cc;
          schema.bccEmail = schema.bcc;
          delete schema.cc;
          delete schema.bcc;
        }
        const fetch = await dispatch(sendEmailLead({ ...schema }));
        if (fetch?.payload) {
          setSubjectVal("");
          setSelectedItem([]);
          setEmailValue("<br />" + adminDetail.email_signature);
          dispatch(selectedEmailList([]));
          setOpen({
            state: true,
            type: "success",
            desc: "Email has been sent successfully!",
          });
          setSendEmailLoading(false);
          setTimeout(() => {
            if (handleClosePopup) {
              handleClosePopup();
            }
          }, 1000);
        } else {
          setSendEmailLoading(false);
          setOpen({
            state: true,
            type: "error",
            desc: "Please make sure, recipient is correct!",
          });
        }
      }
    }
  };
  const selectedTagFun = (pa) => {
    dispatch(selectedEmailList(pa));
  };

  useEffect(() => {
    if (leadProfile.name && leadProfile.emails?.length && !IsPopup) {
      let selectedEmail = [];
      leadProfile.emails?.map((item) => {
        let obj = {
          name: leadProfile.name,
          email: item.value,
          picture: "",
        };
        selectedEmail.push(obj);
      });
      selectedTagFun(selectedEmail);
      setSelectedItem(selectedEmail);
    }
  }, []);

  const handleClose = (e) => {
    setOpen(false);
  };
  const closeModal = (e) => {
    setTemplateModal(false);
  };
  const closeModalHandler = () => {
    setPopupModalStatus(false);
  };
  return (
    <>
      {currentTab?.loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSendForm}>
          <Box className={classes.emailTabRoot}>
            <Autocomplete
              multiple
              limitTags={3}
              sx={{
                "& .MuiInputBase-root": {
                  paddingLeft: "45px !important",
                },
                "& label": {
                  position: "absolute",
                  top: "16px",
                  left: "13px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                },
                "& .MuiAutocomplete-endAdornment": {
                  display: "none",
                },
              }}
              id="to-field-tags"
              options={recipientList ? recipientList : []}
              getOptionLabel={(option) => (
                <>
                  <Avatar
                    sx={{ width: 30, height: 30 }}
                    {...stringAvatar(option.name || option.email)}
                  />
                  &nbsp; {option.name || option?.email}
                </>
              )}
              blurOnSelect={true}
              value={selectedItem || []}
              inputValue={inputValueEmail}
              onInputChange={(event, newInputValue) => {
                setInputValueEmail(newInputValue);
              }}
              onBlur={(event) => {
                if (ValidateEmail(event.target.value)) {
                  setInputValueEmail("");
                  let schema = {
                    name: "",
                    email: event.target.value,
                    picture: "",
                  };
                  let newSelectedItems = [...selectedItem];
                  newSelectedItems.push(schema);
                  selectedTagFun(newSelectedItems);
                  setSelectedItem(newSelectedItems);
                }
              }}
              freeSolo
              renderTags={(value, getTagProps) =>
                value?.map(({ name, email }, index) => (
                  <Tooltip
                    placement="top"
                    title={name ? email : false}
                    arrow
                    followCursor
                    disableHoverListener={name ? false : true}
                  >
                    <Chip
                      label={name || email}
                      {...getTagProps({ index })}
                      key={index}
                      onDelete={() => {
                        const des = [...selectedItem];
                        des.splice(index, 1);
                        setSelectedItem(des);
                      }}
                      avatar={<Avatar {...stringAvatar(name || email)} />}
                    ></Chip>
                  </Tooltip>
                ))
              }
              onChange={(a, b, c, d, e) => {
                let schema = null;
                if (typeof d?.option == "object") {
                  schema = d?.option;
                }
                if (typeof a.target.value === "string") {
                  schema = {
                    name: "",
                    email: a.target.value,
                    picture: "",
                  };
                }
                if (a.keyCode === 8) {
                  const key_code = [...selectedItem];
                  key_code.pop();
                  setSelectedItem(key_code);
                  return;
                }
                const duplicatedValues = selectedItem.findIndex(
                  (i) => i.email === schema?.email
                );
                if (!!schema) {
                  if (duplicatedValues === -1) {
                    const kk = [...selectedItem];
                    kk.push(schema);
                    selectedTagFun(kk);
                    setSelectedItem(kk);
                    return;
                  }
                }
              }}
              renderInput={(params) => (
                <Box sx={{ position: "relative" }}>
                  <Input {...params} label="to :" />
                  <Stack
                    sx={{ position: "absolute", top: "15px", right: "0px" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    {!isCC ? (
                      <IconButton
                        onClick={() => setIsCC(true)}
                        sx={{
                          fontSize: "13px",
                          color: "#428EDA",
                          fontWeight: "bold",
                        }}
                      >
                        CC
                      </IconButton>
                    ) : (
                      ""
                    )}
                    {!isBCC ? (
                      <IconButton
                        onClick={() => setIsBCC(true)}
                        sx={{
                          fontSize: "13px",
                          color: "#428EDA",
                          fontWeight: "bold",
                        }}
                      >
                        BCC
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Box>
              )}
            />
          </Box>
          {disableFrom ? null : (
            <Box className={classes.emailTabRoot}>
              <Input
                value=""
                InputProps={{
                  startAdornment: (
                    <>
                      <Typography sx={{ minWidth: "50px" }}>From :</Typography>
                      <Chip
                        label={adminDetail?.full_name}
                        style={{ marginRight: "5px" }}
                        avatar={
                          <Avatar
                            {...stringAvatar(
                              adminDetail?.full_name,
                              adminDetail?.profile_img
                            )}
                            alt="Profile"
                          />
                        }
                      />
                    </>
                  ),
                }}
              />
            </Box>
          )}

          {isCC && (
            <Box className={classes.emailTabRoot}>
              <Autocomplete
                multiple
                limitTags={3}
                sx={{
                  "& .MuiInputBase-root": {
                    paddingLeft: "45px !important",
                  },
                  "& label": {
                    position: "absolute",
                    top: "16px",
                    left: "13px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    display: "none",
                  },
                }}
                id="to-field-tags"
                options={CCList ? CCList : []}
                getOptionLabel={(option) => (
                  <>
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      {...stringAvatar(option.name || option.email)}
                    />
                    &nbsp; {option.name || option?.email}
                  </>
                )}
                value={CCList || []}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value?.map(({ name, email }, index) => (
                    <Tooltip
                      placement="top"
                      title={name ? email : false}
                      arrow
                      followCursor
                      disableHoverListener={name ? false : true}
                    >
                      <Chip
                        label={name || email}
                        {...getTagProps({ index })}
                        key={index}
                        onDelete={() => {
                          const des = [...CCList];
                          des.splice(index, 1);
                          setCCList(des);
                        }}
                        avatar={<Avatar {...stringAvatar(name || email)} />}
                      ></Chip>
                    </Tooltip>
                  ))
                }
                onChange={(a, b, c, d, e) => {
                  let schema = null;
                  if (typeof d?.option == "object") {
                    schema = d?.option;
                  }
                  if (typeof a.target.value === "string") {
                    schema = {
                      name: "",
                      email: a.target.value,
                      picture: "",
                    };
                  }
                  if (a.keyCode === 8) {
                    const key_code = [...CCList];
                    key_code.pop();
                    setCCList(key_code);
                    return;
                  }
                  const duplicatedValues = CCList.findIndex(
                    (i) => i.email === schema?.email
                  );
                  if (!!schema) {
                    if (duplicatedValues === -1) {
                      const kk = [...CCList];
                      kk.push(schema);
                      setCCList(kk);
                      return;
                    }
                  }
                }}
                renderInput={(params) => (
                  <>
                    <Input {...params} label="CC :" />
                  </>
                )}
              />
            </Box>
          )}
          {isBCC && (
            <Box className={classes.emailTabRoot}>
              <Autocomplete
                multiple
                limitTags={2}
                sx={{
                  "& .MuiInputBase-root": {
                    paddingLeft: "45px !important",
                  },
                  "& label": {
                    position: "absolute",
                    top: "16px",
                    left: "13px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    display: "none",
                  },
                }}
                id="to-field-tags"
                options={BCCList ? BCCList : []}
                getOptionLabel={(option) => (
                  <>
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      {...stringAvatar(option.name || option.email)}
                    />
                    &nbsp; {option.name || option?.email}
                  </>
                )}
                value={BCCList || []}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value?.map(({ name, email }, index) => (
                    <Tooltip
                      placement="top"
                      title={name ? email : false}
                      arrow
                      followCursor
                      disableHoverListener={name ? false : true}
                    >
                      <Chip
                        label={name || email}
                        {...getTagProps({ index })}
                        key={index}
                        onDelete={() => {
                          const des = [...BCCList];
                          des.splice(index, 1);
                          setBCCList(des);
                        }}
                        avatar={<Avatar {...stringAvatar(name || email)} />}
                      ></Chip>
                    </Tooltip>
                  ))
                }
                onChange={(a, b, c, d, e) => {
                  let schema = null;
                  if (typeof d?.option == "object") {
                    schema = d?.option;
                  }
                  if (typeof a.target.value === "string") {
                    schema = {
                      name: "",
                      email: a.target.value,
                      picture: "",
                    };
                  }
                  if (a.keyCode === 8) {
                    const key_code = [...BCCList];
                    key_code.pop();
                    setBCCList(key_code);
                    return;
                  }
                  const duplicatedValues = BCCList.findIndex(
                    (i) => i.email === schema?.email
                  );
                  if (!!schema) {
                    if (duplicatedValues === -1) {
                      const kk = [...BCCList];
                      kk.push(schema);
                      setBCCList(kk);
                      return;
                    }
                  }
                }}
                renderInput={(params) => (
                  <>
                    <Input {...params} label="BCC : " />
                  </>
                )}
              />
            </Box>
          )}
          <Box className={classes.emailTabRoot}>
            <Input
              onChange={(e) => setSubjectVal(e.target.value)}
              value={subjectVal}
              InputProps={{
                startAdornment: (
                  <>
                    <Typography sx={{ minWidth: "65px" }}>Subject :</Typography>
                  </>
                ),
              }}
            />
          </Box>
          <ToastContainer />
          <div id="displayPopModal"> </div>
          <Box
            className={classes.quillEditor}
            sx={{
              "& .ql-editor": {
                minHeight: IsPopup ? "220px" : "120px",
              },
            }}
          >
            <ReactQuill
              className="react-quill"
              theme="snow"
              value={emailValue}
              onChange={handleChangeValue}
            />
          </Box>
          <Box sx={{ display: "flex", marginTop: "8px" }}>
            {uploadFiles?.map((item, index) => (
              <div key={index} className={classes.attachmentList}>
                <span>{item.name}</span>
                <span className="text-secondary ms-1">
                  ({Math.round(item.size / 1024)}KB)
                </span>
                <DeleteForeverIcon
                  fontSize="30"
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            ))}
          </Box>
          <>
            <Input
              type="file"
              id="invisible-input"
              style={{ display: "none" }}
              accept=".pdf, .jpg, .png"
              onChange={(e) => handleUploadFiles(e.target.files[0])}
            />
            <Grid
              container
              justifyContent="space-between"
              sx={{ marginTop: "30px" }}
              alignItems="center"
            >
              <Grid>
                <label
                  htmlFor="invisible-input"
                  className={classes.textLinkBtn}
                >
                  <AttachFileIcon fontSize="20" />
                  Attachments
                </label>
                <label
                  className={classes.textLinkBtn}
                  onClick={() => setTemplateModal(true)}
                >
                  <ContentCopyIcon
                    sx={{ marginLeft: "30px", marginRight: "10px" }}
                    fontSize="20"
                  />
                  Templates
                </label>
              </Grid>
              <Grid>
                <DeleteDialog
                  openPopup={confirmOpen}
                  onConfirm={clearTextEditor}
                  closeModal={closeDeleteHandler}
                ></DeleteDialog>
              </Grid>
              <Grid>
                <label
                  className="emailDeleteBtn"
                  onClick={() => setConfirmHandler(true)}
                >
                  {/* Delete button for texarea */}
                  {!hideDeleteEmailButton && isUpdate && (
                    <DeleteForeverIcon
                      style={{
                        width: "1.4rem",
                        height: "1.4rem",
                        position: "relative",
                        top: "9px",
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "error",
                      }}
                      sx={{ marginRight: "30px" }}
                      fontSize="40"
                    />
                  )}
                </label>
                <Button
                  onClick={sendEmailFunc}
                  disabled={sendEmailLoading}
                  variant="contained"
                >
                  {currentTab?.loading || sendEmailLoading
                    ? "Sending..."
                    : "Send Email"}
                </Button>
              </Grid>
            </Grid>
          </>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open?.state}
            autoHideDuration={1500}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={open?.type}
              sx={{ width: "100%" }}
            >
              {open?.desc}
            </Alert>
          </Snackbar>
        </form>
      )}
      <Backdrop color="inherit" invisible={true} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {popupModalStatus ? (
        <EmailTemplatesPopper
          openStatus={popupModalStatus}
          anchorEl={classes.emailTabRoot}
          selectedTemplate={selectedTemplateFnc}
          closeModal={closeModalHandler}
          EditorValue={edtitorValue}
          loading={loading}
          emailValue={emailValue}
          setLoading={setLoading}
        />
      ) : null}
      {templateModal ? (
        <GetEmailTemplatesModal
          selectedTemplate={selectedTemplateFnc}
          closeModal={closeModal}
          state={templateModal}
        />
      ) : null}
    </>
  );
};
export default withStyles(styleSheet, { name: "emailTab" })(EmailTab);
