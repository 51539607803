import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { LinearProgress, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "@mui/styles";
import { calculateDueInShortForm, capitalize } from "../helpers";
import { getKanbanProjectList } from "../../../redux/projects/projects";
import { resetList } from "../../../redux/projects/common";
import SidebarContext from "../../../components/authRoutes/sidebarContext";
import ProjectCard from "./ProjectCard";
import {
  openProjectDetails,
  setSelectedProjectId,
} from "../../../redux/projects/project";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 170px)",
    overflowX: "auto",
    overflowY: "hidden",
    flexWrap: "nowrap",
    display: "flex",
    gap: "10px",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px #ddd",
      webkitBoxShadow: "inset 0 0 6px #000",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  column: {
    borderRadius: "8px !important",
    minWidth: "290px",
  },
  accordion: {
    "&.MuiAccordion-root": {
      boxShadow: "0px 0px 0px 1px #E0E0E0",
      border: "1px solid #ddd",
      backgroundColor: "rgba(217, 217, 217, 0.4)",
      margin: "0px!important",
      padding: "0px!important",
      borderRadius: "8px !important",
    },
  },
  notCollapsedAccordion: {
    "&.MuiAccordion-root": {
      width: "auto",
      height: "auto",
      boxShadow: "0px 0px 0px 1px #E0E0E0",
      border: "1px solid #ddd",
      backgroundColor: "rgba(217, 217, 217, 0.4)",
      margin: "0px!important",
      padding: "0px!important",
    },
  },
  notCollapsedDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    "& .MuiTypography-h6": {
      writingMode: "vertical-rl",
      transform: "rotate(180deg)",
    },
  },
  accordionDetails: {
    "&.MuiAccordionDetails-root": {
      padding: "0px!important",
      border: "none",
    },
  },
  scrollbar: {
    height: "calc(100vh - 270px)",
    marginBottom: "10px",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(92 88 88 / 60%)",
      borderRadius: 1,
    },
  },
  columnTitle: {
    fontFamily: "AvenirNext-400 !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
  },
  columnCardCount: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "14px !important",
  },
}));

function KanbanView(props) {
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSidebarOpen = useContext(SidebarContext);

  const [statusData, setStatusData] = React.useState({
    Queued: [],
    Working: [],
    Review: [],
    Submitted: [],
    Approved: [],
    Completed: [],
  });
  const [isCollapsed, setIsCollapsed] = React.useState({
    Queued: true,
    Working: true,
    Review: true,
    Submitted: true,
    Approved: true,
    Completed: true,
  });

  const projects = useSelector((state) => state.project.projects);
  const projectsLoading = useSelector((state) => state.project.projectsLoading);
  const searchText = useSelector((state) => state.project.searchText);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenProject = (id) => {
    dispatch(setSelectedProjectId(id));
    dispatch(openProjectDetails(true));
  };
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  React.useEffect(() => {
    setTimeout(() => {
      dispatch(getKanbanProjectList());
    }, 500);

    return () => {
      dispatch(resetList());
    };
  }, [props.value, searchText]);

  React.useEffect(() => {
    const staData = { ...statusData };

    if (projects.length === 0) {
      setStatusData({
        Queued: [],
        Working: [],
        Review: [],
        Submitted: [],
        Approved: [],
        Completed: [],
      });
    } else {
      projects?.map((d, i) => {
        let dueIn = calculateDueInShortForm(d.due_date);
        let teamMembers = [],
          alreadyExist = [];

        d.project_tasks.map((task) => {
          if (task.team_member && !alreadyExist.includes(task.team_member.id)) {
            teamMembers.push({
              src:
                task.team_member?.profile_images?.profile_img_thumbnail || null,
              title: task.team_member.full_name,
            });
            alreadyExist.push(task.team_member.id);
          }
        });

        let totalTasks = d.project_tasks.length;
        let totalTasksCompl = d.project_tasks.filter((tsk) =>
          ["review", "approved", "submitted", "completed"].includes(
            tsk.status.toLowerCase()
          )
        ).length;
        let project = {
          id: d.id,
          status: d.status,
          project: {
            name: d?.project_name,
            address: d?.title,
          },
          agent: {
            image: d?.agent?.profile_images?.profile_img_thumbnail,
            last_name: d?.agent?.last_name,
            first_name: d?.agent?.first_name,
            full_name: d?.agent?.full_name,
          },
          progress: {
            percentage:
              totalTasksCompl !== 0
                ? parseInt((totalTasksCompl / totalTasks) * 100)
                : 0,
            text: `${totalTasksCompl} of ${totalTasks} Tasks Complete`,
          },
          dueIn,
          teamMembers,
          attachments: [],
          comments: [],
          department: d.department,
          created: d.createdAt,
        };

        const projectStatus = capitalize(project.status);
        if (
          [
            "Queued",
            "Working",
            "Review",
            "Submitted",
            "Approved",
            "Completed",
          ].includes(projectStatus)
        ) {
          staData[projectStatus].push(project);
        }
      });
      setStatusData(staData);
    }
  }, [projects]);
  /**
   * End Region: useEffect Hooks
   */
  return (
    <Box sx={{ height: "calc(100vh - 150px)" }}>
      <Box
        sx={{
          minHeight: "5px",
          width: isSidebarOpen ? "calc(100vw - 330px)" : "calc(100vw - 130px)",
        }}
      >
        {projectsLoading && <LinearProgress variant="indeterminate" />}
      </Box>
      <Box
        className={classes.container}
        sx={{
          minWidth: isSidebarOpen
            ? "calc(100vw - 134px - 213px)"
            : "calc(100vw - 134px - 250px)",
          width: isSidebarOpen ? "calc(100vw - 134px - 213px)" : "100%",
        }}
      >
        {Object.keys(statusData).map((sD, listIndex) => {
          return (
            <Box
              key={sD}
              className={classes.column}
              sx={{
                minWidth: isCollapsed[sD] ? "290px" : "40px",
                width: isCollapsed[sD] ? "290px" : "40px",
              }}
            >
              <Accordion
                className={
                  isCollapsed[sD] === true
                    ? classes.accordion
                    : classes.notCollapsedAccordion
                }
                expanded={isCollapsed[sD] === true}
                onChange={() =>
                  setIsCollapsed({ ...isCollapsed, [sD]: !isCollapsed[sD] })
                }
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    width: isCollapsed[sD] ? "290px" : "40px",
                    "& .MuiAccordionSummary-root": {
                      padding: "0px 5px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: "10px 0px!important",
                    },
                  }}
                >
                  <Box
                    className={
                      isCollapsed[sD] === true
                        ? ""
                        : classes.notCollapsedDetails
                    }
                    sx={{ marginBottom: "5px" }}
                  >
                    {isCollapsed[sD] === true && (
                      <>
                        <Typography className={classes.columnTitle}>
                          {sD}
                        </Typography>

                        <Typography className={classes.columnCardCount}>
                          {statusData[sD].length} Cards
                        </Typography>
                      </>
                    )}

                    {isCollapsed[sD] === false && (
                      <>
                        <Typography className={classes.columnCardCount}>
                          ({statusData[sD].length})
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.columnTitle}
                        >
                          {sD}
                        </Typography>
                      </>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Box className={classes.scrollbar}>
                    <Box sx={{ margin: "0px 10px", height: "100%" }}>
                      {statusData[sD].map((li, index) => (
                        <ProjectCard
                          projectDetails={li}
                          handleOpenProject={handleOpenProject}
                        />
                      ))}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default KanbanView;
