import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Button, IconButton, Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddCommissionModal from "../modals/addCommissionPlan";
import { getCommissionPlans } from "../../../../redux/finance/commissionPlans";
import { getAttachCommissionPlan } from "../../../../redux/agents/agentRosterDetail";
import { getPaymentRecords } from "../../../../redux/agents/agentRosterDetail/getPaymentRecords";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CommissionPlansTable from "../../../users/skeltons/commissionPlansTable"

const AgentRosterPaymentTab = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const AttachedCommissionPlan = useSelector((state) => state.agentRoster.AttachedCommissionPlan);
  const paymentRecords = useSelector((state) => state.agentRoster.paymentRecords);
  useEffect(() => {
    dispatch(getCommissionPlans());
    dispatch(getAttachCommissionPlan({ id }));
    dispatch(getPaymentRecords({ id }));
  }, []);

  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRootPayment}>
        <CardHeader classes={{ title: classes.pageHeading }} title="Payments" />
        <CardContent>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Via</TableCell>
                  <TableCell align="center">Date Processed</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentRecords?.data?.records.map((row) => (
                  <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {moment.utc(row.createdAt).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="left">{row.property_address}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.payment_type}</TableCell>
                    <TableCell align="center"> {moment.utc(row.processed_date).format("MM/DD/YYYY")}</TableCell>
                    <TableCell align="right">
                      {" "}
                      <Button
                        variant="contained"
                        size="small"
                        color="inherit"
                        sx={{
                          backgroundColor: "white",
                          color: "#000000",
                          fontWeight: "400",
                        }}
                      >
                        View Payment
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRootPayment}>
        <CardHeader
          classes={{ title: classes.pageHeading }}
          title="Commission Plans"
          action={
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#0B0909",
                "&:hover": { backgroundColor: "#0f0a0a" },
                color: "white",
                fontWeight: "400",
                marginRight: "10px",
              }}
              onClick={() => setOpen(true)}
            >
              Add Plan
            </Button>
          }
        />
        <CardContent>
          {AttachedCommissionPlan.isLoading && (
            <CommissionPlansTable />
          )}
          <TableContainer hidden={AttachedCommissionPlan.isLoading}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Plan ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Agent Split</TableCell>
                  <TableCell align="center">Company Split</TableCell>
                  <TableCell align="center">Added By</TableCell>
                  <TableCell align="center">Date Added</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AttachedCommissionPlan?.data?.map((item, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {item.commission_id}
                    </TableCell>
                    <TableCell align="left">{item.commission_plan?.name}</TableCell>
                    <TableCell align="center">{item.commission_plan?.agentPer}%</TableCell>
                    <TableCell align="center">{item.commission_plan?.companyPer}%</TableCell>
                    <TableCell align="center">{item.added_by}</TableCell>
                    <TableCell align="center"> {moment(item.updatedAt || new Date()).format("LL")}</TableCell>
                    <TableCell align="center">
                      {" "}
                      <IconButton>
                        {" "}
                        <DeleteRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {(AttachedCommissionPlan?.data?.length === 0 && !AttachedCommissionPlan.isLoading) && <DataNotFound color="#BDBDBD" fontSize="20px" title="No Commission Plan" />}
        </CardContent>
      </Card>
      <AddCommissionModal open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "AgentRosterPaymentTabStyle",
})(AgentRosterPaymentTab);
