import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  loading: false,
  singleCmaLoading: false,
  attachClientLoading: false,
  deleteCMALoading: false,
  createLoading: false,
  customFieldLoading: false,
  comparableRecordLoading: false,
  configureLoading: false,
  updateConfigureDataLoading: false,

  errMsg: null,
  customFieldsErrMsg: null,
  createCMAErrMsg: null,

  cmasData: [],
  cmaCount: 0,
  mlsRecords: [],
  mlsloading: false,
  allAgents: [],
  singleCma: null,
  cmaPrice: null,
  cmaComparable: null,
  adjustmentData: [],
  cmaRecord: [],
  selectedComparable: [],
  comparableSwitch: false,

  availableConfigurePackages: [],
  currentConfigurePackages: [],
  configurationData: [],

  isPdfUpdated: true, // to track if is there any changes on pdf
  pdfDataLoading: false,
  pdfData: null,
  pdfDataErrorMessage: "",
  multipleComparable: {
    isLoading: false,
  },
  listingDetail: {
    isLoading: false,
    data: {},
    errMsg: null,
  },
};

// Fetching listing detail for CMA
export const getListingDetail = createAsyncThunk(
  "cmaData/getListingDetail",
  async (data, thunkAPI) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/common/solr/${data?.core}/query?q=ListingId:${data?.ListingId}`;

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getComparablePdf = createAsyncThunk("cmaData/getComparablePdf", async (data, thunkAPI) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}api/cma/pdf/${data.id}`;
    const resp = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    });

    return { url: resp.data.data.pdfUrl, filename: resp.data.data.filename };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

// Fetching list of CMA
export const getAllCmaData = createAsyncThunk(
  "cmaData/getAllCmaData",
  async (data, thunkAPI) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/cma/all?pageNumber=${
        data?.pageNumber || 1
      }&pageSize=${data?.pageSize || 20}&search=${data?.search || ""}`;

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      return { ...resp?.data, pageNumber: data?.pageNumber || 1 };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Searching MLS
export const getMlsRecords = createAsyncThunk(
  "cmaData/getMlsRecords",
  async (data, thunkAPI) => {
    try {
      const { ListingId, StreetNumber, StreetName } = data;

      let filter = "";
      if (ListingId) {
        filter = `ListingId=${ListingId}`;
      } else if (StreetNumber && StreetName) {
        filter = `StreetNumber=${StreetNumber}&StreetName=${StreetName}`;
      }

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/mls-records?${filter}&ListingType=${data.ListingType}`;
      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });

      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getAllAgents = createAsyncThunk(
  "cmaData/getAllAgents",
  async (data, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/users/all-agents`;
      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });

      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getadjustmentData = createAsyncThunk(
  "cmaData/getadjustmentData",
  async (data, thunkAPI) => {
    try {
      const { id } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/adjustment/${id}`;

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const createNewCMA = createAsyncThunk(
  "cmaData/createNewCMA",
  async (data, thunkAPI) => {
    let { handleSuccess, handleError, schema } = data;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/create-cma`;
      const resp = await axios.post(url, schema, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      if (handleSuccess && resp?.data?.data) {
        handleSuccess(resp?.data?.data);
      }
      thunkAPI.dispatch(getAllCmaData());
      return resp?.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const attachClient = createAsyncThunk(
  "cmaData/attachClient",
  async (data, thunkAPI) => {
    const { id, formData } = data;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/attach-client/${id}`;
      const resp = await axios.post(url, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });

      thunkAPI.dispatch(getAllCmaData());
      return resp?.data?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateCma = createAsyncThunk(
  "cmaData/updateCma",
  async (data, thunkAPI) => {
    try {
      const { cmaData } = data;
      const tempData = _.cloneDeep(cmaData);
      const { cmaId } = tempData;
      delete tempData.cmaId;

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/${cmaId}`;
      await axios.put(url, tempData, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });

      if (data.fetchAdjustment) {
        thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
        thunkAPI.dispatch(getSingleCma({ id: cmaId }));
      }
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      thunkAPI.dispatch(getAllCmaData());
      return { ...tempData };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// This one will not change the loading state
export const getSingleCma = createAsyncThunk(
  "cmaData/getSingleCma",
  async (data, thunkAPI) => {
    try {
      const { id } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/${id}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };
      const resp = await axios(options);
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// This one will change the loading state
export const getSingleCmaV2 = createAsyncThunk(
  "cmaData/getSingleCmaV2",
  async (data, thunkAPI) => {
    try {
      const { id } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/${id}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };
      const resp = await axios(options);
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getCmaPrice = createAsyncThunk(
  "cmaData/getCmaPrice",
  async (data, thunkAPI) => {
    try {
      const { id } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/pricing/${id}`;

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomField = createAsyncThunk(
  "cmaData/getCustomField",
  async (data, thunkAPI) => {
    try {
      const { cmaId } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/custom-field/${cmaId}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };
      const resp = await axios(options);
      console.log(resp);
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const addCustomField = createAsyncThunk(
  "cmaData/addCustomField",
  async (data, thunkAPI) => {
    try {
      const { cmaId, custom } = data;
      let obj = {
        custom_key: custom.name,
        custom_value: custom.value,
      };

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/add-custom-field/${cmaId}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
          "Cache-Control": "no-cache",
        },
        method: "post",
        data: obj,
        url,
      };

      const resp = await axios(options);
      thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      if (resp?.response?.data?.status === 400) {
        return thunkAPI.rejectWithValue(
          `${JSON.stringify([custom.name, custom.value])} ${
            resp?.response?.data?.message
          }`
        );
      }

      return resp?.data?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateCustomField = createAsyncThunk(
  "cmaData/updateCustomField",
  async (data, thunkAPI) => {
    try {
      const {
        cmaId,
        custom: { id, name, value },
      } = data;
      let obj = {
        custom_key: name,
        custom_value: value,
      };

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/custom-field/${id}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
          "Cache-Control": "no-cache",
        },
        data: obj,
        method: "put",
        url,
      };
      const resp = await axios(options);
      thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      if (resp?.response?.data?.status === 400) {
        return thunkAPI.rejectWithValue(
          `${JSON.stringify([data.name, data.value])} ${
            resp?.response?.data?.message
          }`
        );
      }
      return data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const setComparableCustomValue = createAsyncThunk(
  "cmaData/setComparableCustomValue",
  async (data, thunkAPI) => {
    try {
      const { id, value, cmaId } = data;

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/custom-value/${id}`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: { comparableCustomValue: value },
        method: "put",
        url,
      };
      await axios(options);
      thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCustomField = createAsyncThunk(
  "cmaData/deleteCustomField",
  async (data, thunkAPI) => {
    try {
      const { cmaId, id, updatedCustomFields } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/custom-field/${id}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
          "Cache-Control": "no-cache",
        },
        method: "delete",
        url,
      };
      await axios(options);
      thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return updatedCustomFields;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const addAdjustmentsInComparable = createAsyncThunk(
  "cmaData/addAdjustmentsInComparable",
  async (data, thunkAPI) => {
    try {
      const { id, cmaId } = data;
      let shouldFetchAdjustment = true;
      if (data.dontFetchAdjustment) {
        shouldFetchAdjustment = false;
        delete data.dontFetchAdjustment;
      }

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/add-adjustment/${id}`;

      const resp = await axios.post(url, data, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      if (cmaId && shouldFetchAdjustment) {
        thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
      }
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const autoAdjustmentComparable = createAsyncThunk(
  "cmaData/autoAdjustmentComparable",
  async (data, thunkAPI) => {
    try {
      const { id, putData } = data;
      let shouldFetchAdjustment = false;
      if (data.shouldFetchAdjustment) {
        shouldFetchAdjustment = data.shouldFetchAdjustment;
        delete data.shouldFetchAdjustment;
      }

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/add-auto-adjustment/${id}`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "put",
        url,
        data: putData,
      };

      const response = await axios(options);
      if (id && shouldFetchAdjustment) {
        thunkAPI.dispatch(getadjustmentData({ id: id }));
      }
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getCmaComparableRecordById = createAsyncThunk(
  "cmaData/getCmaComparableRecordById",
  async (data, thunkAPI) => {
    try {
      const { id } = data;

      const query = {
        sqft_tolerance: data.sqft_tolerance || null,
        vintage_tolerance: data.vintage_tolerance || 30,
        time_frame: data.time_frame || 8,
        distance: data.distance || 0.5,
        ListingType: data.listingType,
      };
      if (data.startPrice.length > 0) {
        query.startPrice = parseFloat(data.startPrice);
      }
      if (data.endPrice.length > 0) {
        query.endPrice = parseFloat(data.endPrice);
      }

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/browser-comparable/${id}`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "post",
        url,
        data: query,
      };
      const res = await axios(options);
      return res?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCMAComparable = createAsyncThunk(
  "cmaData/deleteCMAComparable",
  async (data, thunkAPI) => {
    try {
      const { id, cmaId } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/comparable/${id}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
          "Cache-Control": "no-cache",
        },
        method: "DELETE",
        url,
      };
      const resp = await axios(options);
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return resp;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const selectComparable = createAsyncThunk(
  "cmaData/selectComparable",
  async (data, thunkAPI) => {
    try {
      const { cmaId } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/select-comparable/${cmaId}`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "post",
        url,
        data: data,
      };

      const resp = await axios(options);
      await thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const createMultipleComparable = createAsyncThunk(
  "allCMAData/createMultipleComparable",
  async (data, thunkAPI) => {
    const { cmaId, handleSuccess, handleError } = data;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/cma/select-multiple-comparables/${cmaId}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );

      if (handleSuccess && resp.data?.data) {
        handleSuccess(resp.data?.data);
        await thunkAPI.dispatch(getadjustmentData({ id: cmaId }));
        thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      }
      if (resp.response?.data?.errorMessage && handleError) {
        handleError(resp.response?.data?.errorMessage);
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getAvailableConfigPackagesSingleCMA = createAsyncThunk(
  "cmaData/getAvailableConfigPackagesSingleCMA",
  async (data, thunkAPI) => {
    try {
      const { id } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/remaining-packages/${id}`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "get",
        url,
      };
      const res = await axios(options);
      return res?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updatePackages = createAsyncThunk(
  "cmaData/updatePackages",
  async (data, thunkAPI) => {
    try {
      const { cmaId, pages } = data;

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/configuration/${cmaId}`;
      const resp = await axios.put(url, pages, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });

      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateConfigurationPages = createAsyncThunk(
  "cmaData/updateConfigurationPages",
  async (data, thunkAPI) => {
    return data;
  }
);

export const updateConfigurationPageData = createAsyncThunk(
  "cmaData/updateConfigurationPageData",
  async (data, thunkAPI) => {
    try {
      const { cmaId, configureData } = data;
      var formData = new FormData();
      configureData.standout_features?.forEach((standout_feature) => {
        formData.append("standout_features[]", standout_feature);
      });
      formData.append("property_img", configureData.property_img);
      formData.append("name", configureData.name);
      formData.append("page", configureData.page);

      const url = `${process.env.REACT_APP_BASE_URL}api/cma/configuration-data/${cmaId}`;
      const resp = await axios.put(url, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      });

      await thunkAPI.dispatch(
        getAvailableConfigPackagesSingleCMA({ id: cmaId })
      );

      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const changeAdjustmentVisibility = createAsyncThunk(
  "cmaData/changeAdjustmentVisibility",
  async (data, thunkAPI) => {
    try {
      const { cmaId, formData } = data;
      const url = `${process.env.REACT_APP_BASE_URL}api/cma/cma-hidden-field/${cmaId}`;
      var config = {
        method: "put",
        url,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(formData),
      };
      const resp = await axios(config);
      thunkAPI.dispatch(setCMAPdfUpdated({ flag: true }));
      return resp?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const resetSingleCMA = createAsyncThunk(
  "cmaData/resetSingleCMA",
  async (data, thunkAPI) => {
    return null;
  }
);

export const setCMAPdfUpdated = createAsyncThunk(
  "cmaData/setCMAPdfUpdated",
  async (data, thunkAPI) => {
    return data.flag;
  }
);

const allCMAData = createSlice({
  name: "allCMAData",
  initialState,
  reducers: {
    updateSingleCMA: (state, action) => {
      state.singleCma = { ...state.singleCma, ...action.payload };
    },
    resetMLSRecords: (state, action) => {
      state.mlsRecords = [];
    },
    setComparableSwitch: (state, action) => {
      state.comparableSwitch = action.payload;
    },
  },
  extraReducers: {
    // all CMAs for list
    [getAllCmaData.pending]: (state) => {
      state.loading = true;
    },
    [getAllCmaData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.pageNumber > 1) {
        state.cmaData = [...state.cmaData, ...action.payload.data];
      } else {
        state.cmaData = action.payload.data;
      }
      state.cmaCount = action.payload.count;
    },
    [getAllCmaData.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
    /* Listing detail */
    [getListingDetail.pending]: (state) => {
      state.listingDetail.isLoading = true;
      state.listingDetail.errMsg = null;
      state.listingDetail.data = {};
    },
    [getListingDetail.fulfilled]: (state, action) => {
      state.listingDetail.isLoading = false;
      state.listingDetail.errMsg = null;
      state.listingDetail.data = action.payload.response?.docs[0];
    },
    [getListingDetail.rejected]: (state, action) => {
      state.listingDetail.isLoading = false;
      state.listingDetail.errMsg = action.payload;
    },
    /* end Listing detail */
    [resetSingleCMA.fulfilled]: (state) => {
      state.singleCma = null;
    },

    // Agents list for creating CMA
    [getAllAgents.pending]: (state) => {
      state.loading = true;
    },
    [getAllAgents.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAgents = action.payload.data;
    },
    [getAllAgents.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },

    // MLS Records - needed to create CMA
    [getMlsRecords.pending]: (state) => {
      state.mlsloading = true;
    },
    [getMlsRecords.fulfilled]: (state, action) => {
      state.mlsloading = false;
      state.mlsRecords = action.payload?.data || [];
    },
    [getMlsRecords.rejected]: (state, action) => {
      state.mlsloading = false;
      state.errMsg = action.payload;
    },

    // Create New CMA
    [createNewCMA.pending]: (state) => {
      state.createLoading = true;
    },
    [createNewCMA.fulfilled]: (state, action) => {
      state.createLoading = false;
      state.singleCma = action.payload;
    },
    [createNewCMA.rejected]: (state, action) => {
      state.createLoading = false;
      state.createCMAErrMsg = action.payload;
    },

    // Single CMA to open details on CMA details page, will not change the loading state
    [getSingleCma.pending]: (state) => {
      state.deleteCMALoading = true;
    },
    [getSingleCma.fulfilled]: (state, action) => {
      state.deleteCMALoading = false;
      state.singleCma = action.payload.data;
      state.currentConfigurePackages = action.payload.data.configuration;
    },
    [getSingleCma.rejected]: (state, action) => {
      state.deleteCMALoading = false;
      state.errMsg = action.payload;
    },
    // Single CMA to open details on CMA details page, will change the loading state
    [getSingleCmaV2.pending]: (state) => {
      state.singleCmaLoading = true;
      state.deleteCMALoading = true;
    },
    [getSingleCmaV2.fulfilled]: (state, action) => {
      state.deleteCMALoading = false;
      state.singleCma = action.payload.data;
      state.currentConfigurePackages = action.payload.data.configuration;
      state.singleCmaLoading = false;
    },
    [getSingleCmaV2.rejected]: (state, action) => {
      state.singleCmaLoading = false;
      state.deleteCMALoading = false;
      state.errMsg = action.payload;
    },

    // Add Custom Field
    [addCustomField.pending]: (state, action) => {
      state.customFieldLoading = true;
    },
    [addCustomField.fulfilled]: (state, action) => {
      let updatedCMA = state.singleCma;
      updatedCMA.cma_custom_fields = updatedCMA.cma_custom_fields
        ? updatedCMA.cma_custom_fields
        : [];
      updatedCMA.cma_custom_fields.push(action.payload);
      state.singleCma = updatedCMA;
      state.customFieldLoading = false;
    },
    [addCustomField.rejected]: (state, action) => {
      let updatedCMA = state.singleCma;
      state.singleCma = updatedCMA;
      state.customFieldsErrMsg = action.payload;
      state.customFieldLoading = false;
    },

    // Update Custom Field
    [updateCustomField.pending]: (state, action) => {
      state.customFieldLoading = true;
    },
    [updateCustomField.fulfilled]: (state, action) => {
      let updatedCMA = state.singleCma;
      updatedCMA.cma_custom_fields = updatedCMA.cma_custom_fields.map(
        (field) => {
          if (field.id === action.payload.id) {
            field = { ...action.payload };
          }

          return field;
        }
      );
      state.singleCma = updatedCMA;
      state.customFieldsErrMsg = null;
      state.customFieldLoading = false;
    },
    [updateCustomField.rejected]: (state, action) => {
      let updatedCMA = state.singleCma;
      state.singleCma = updatedCMA;
      state.customFieldsErrMsg = action.payload;
      state.customFieldLoading = false;
    },

    // Delete Custom Field
    [deleteCustomField.pending]: (state, action) => {
      state.customFieldLoading = true;
    },
    [deleteCustomField.fulfilled]: (state, action) => {
      state.customFieldLoading = false;
      let updatedCMA = state.singleCma;
      updatedCMA.cma_custom_fields = action.payload;
      state.singleCma = updatedCMA;
    },

    // Update CMA
    [updateCma.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleCma = { ...state.singleCma, ...action.payload };
    },

    // Attach Client to single CMA
    [attachClient.pending]: (state) => {
      state.attachClientLoading = true;
    },
    [attachClient.fulfilled]: (state, action) => {
      state.attachClientLoading = false;
      state.singleCma = { ...state.singleCma, ...action.payload };
    },
    [attachClient.rejected]: (state, action) => {
      state.attachClientLoading = false;
      state.errMsg = action.payload;
    },

    // Delete comparable item from single selected CMA
    [deleteCMAComparable.pending]: (state) => {
      state.deleteCMALoading = true;
    },
    [deleteCMAComparable.fulfilled]: (state, action) => {
      state.deleteCMALoading = false;
    },
    [deleteCMAComparable.rejected]: (state) => {
      state.deleteCMALoading = false;
    },

    // Subject Prices
    [getCmaPrice.pending]: (state) => {
      state.loading = true;
    },
    [getCmaPrice.fulfilled]: (state, action) => {
      state.loading = false;
      state.cmaPrice = action.payload.data;
    },
    [getCmaPrice.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },

    // All Other Comaprables to Compare
    [getCmaComparableRecordById.pending]: (state) => {
      state.comparableRecordLoading = true;
    },
    [getCmaComparableRecordById.fulfilled]: (state, action) => {
      state.comparableRecordLoading = false;
      state.cmaRecord = action.payload.data;
      state.selectedComparable = action.payload.selectedComparable;
    },
    [getCmaComparableRecordById.rejected]: (state, action) => {
      state.comparableRecordLoading = false;
    },

    [addAdjustmentsInComparable.pending]: (state) => {
      state.loading = true;
    },
    [addAdjustmentsInComparable.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addAdjustmentsInComparable.rejected]: (state, action) => {
      state.loading = false;
    },

    [selectComparable.pending]: (state) => {
      state.deleteCMALoading = true;
    },
    [selectComparable.fulfilled]: (state, action) => {
      state.deleteCMALoading = false;
    },
    [selectComparable.rejected]: (state, action) => {
      state.loading = false;
    },

    [createMultipleComparable.pending]: (state) => {
      state.multipleComparable.isLoading = true;
    },
    [createMultipleComparable.fulfilled]: (state, action) => {
      state.multipleComparable.isLoading = false;
    },
    [createMultipleComparable.rejected]: (state, action) => {
      state.multipleComparable.isLoading = false;
    },

    [getadjustmentData.pending]: (state) => {
      state.loading = true;
    },
    [getadjustmentData.fulfilled]: (state, action) => {
      state.loading = false;
      state.adjustmentData = action.payload.data;
    },
    [getadjustmentData.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },

    // Get Available Configure Packages of a single cma
    [getAvailableConfigPackagesSingleCMA.pending]: (state) => {
      state.configureLoading = true;
    },
    [getAvailableConfigPackagesSingleCMA.fulfilled]: (state, action) => {
      state.configureLoading = false;
      state.configurationData = action.payload.configurationData;
      state.availableConfigurePackages = action.payload.remaining_packages;
    },
    [getAvailableConfigPackagesSingleCMA.rejected]: (state, action) => {
      state.configureLoading = false;
      state.errMsg = action.payload;
    },

    // Update Configuration Pages
    [updateConfigurationPages.fulfilled]: (state, action) => {
      state.availableConfigurePackages =
        action.payload.availableConfigurePackages;
      state.currentConfigurePackages = action.payload.currentConfigurePackages;
    },

    // Update Configuration Pages
    [updateConfigurationPageData.pending]: (state, action) => {
      state.updateConfigureDataLoading = true;
    },
    [updateConfigurationPageData.fulfilled]: (state, action) => {
      state.updateConfigureDataLoading = false;
    },
    [updateConfigurationPageData.rejected]: (state, action) => {
      state.updateConfigureDataLoading = false;
    },

    // Pdf Data
    [getComparablePdf.pending]: (state, action) => {
      state.pdfDataLoading = true;
      state.isPdfUpdated = true;
      state.pdfData = null;
    },
    [getComparablePdf.fulfilled]: (state, action) => {
      state.isPdfUpdated = false;
      state.pdfDataLoading = false;
      state.pdfData = action.payload;
      state.pdfDataErrorMessage = "";
    },
    [getComparablePdf.rejected]: (state, action) => {
      state.pdfDataLoading = false;
      state.isPdfUpdated = false;
      state.pdfData = null;
      state.pdfDataErrorMessage = action.payload;
    },

    [setCMAPdfUpdated.fulfilled]: (state, action) => {
      state.pdfData = null;
      state.isPdfUpdated = action.payload;
    },
  },
});
export const { updateSingleCMA, resetMLSRecords, setComparableSwitch } =
  allCMAData.actions;
export default allCMAData.reducer;
