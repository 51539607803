import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, Grid, MenuItem, TextField } from "@mui/material";
import ColorPicker from "./mui-color-picker/src/index";
import { createTeam, updateTeam } from "../../redux/teams/team";
import ResponseAlert from "../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
export default function TeamForm(props) {
  const { handleClose, open, classes, teamData } = props;
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [formData, setFormData] = React.useState({});
  const [errors, setErrors] = React.useState();
  const teamLeaders = useSelector((state) => state.teams.teamLeaders.data);
  const addTeam = useSelector((state) => state.teams.teams.addTeam);
  const updateTeams = useSelector((state) => state.teams.teams.updateTeam);
  React.useEffect(() => {
    if (teamData?.row) setFormData({ ...teamData.row, team_leader_name: teamData.row.team_leader?.full_name || 0 });
  }, [teamData]);

  React.useEffect(() => {
    if (!open) {
      setFormData({});
      setErrors({});
    }
  }, [open]);

  useEffect(() => {
    if (addTeam.success && !addTeam.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the team",
        errorType: "success",
        isOpen: true,
      });
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (!addTeam.isLoading && addTeam.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addTeam.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addTeam.isLoading]);

  useEffect(() => {
    if (updateTeams.success && !updateTeams.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully updated the team",
        errorType: "success",
        isOpen: true,
      });
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (!updateTeams.isLoading && updateTeams.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateTeams.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateTeams.isLoading]);

  const handleSave = () => {
    let er = errors;
    if (!formData.team_leader_id) {
      er = {
        ...er,
        team_leader_id: true,
      };
    }
    if (!formData.name) {
      er = {
        ...er,
        name: true,
      };
    }
    if (!formData.team_color) {
      er = {
        ...er,
        team_color: true,
      };
    }

    setErrors(er);

    if (Object.keys(er).length === 0) {
      let obj = teamLeaders.find((item) => item.id === formData.team_leader_id);
      let first_name = obj.full_name?.split(" ")[0];
      let last_name = obj.full_name?.split(" ")[1];
      let team_leader = {
        ...obj,
        first_name,
        last_name,
      };
      if (formData.id) {
        let obj = {
          id: formData.id,
          value: { ...formData, team_leader },
        };
        dispatch(updateTeam(obj));
      } else {
        let obj = {
          ...formData,
          team_leader,
        };
        dispatch(createTeam(obj));
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            position: "unset !important",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {teamData ? "Edit" : "Create"} Team
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="standard"
                label="Team Name"
                value={formData.name || ""}
                defaultValue={formData.name}
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                  if (e.target.value.length > 0) {
                    delete errors.name;
                    setErrors(errors);
                  }
                }}
                error={errors?.name}
                helperText={errors?.name ? "required" : ""}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.team_leader_id}
                  defaultValue={formData.team_leader_id}
                  placeholder="Select Team Leader"
                  label="Select Team Leader"
                  error={errors?.team_leader_id}
                  helperText={errors?.team_leader_id ? "required" : ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      team_leader_id: e.target.value,
                      team_leader_name: teamLeaders.find((user) => user.id === e.target.value).full_name,
                    });
                    delete errors.team_leader_id;
                    setErrors(errors);
                  }}
                >
                  {teamLeaders.map((l) => (
                    <MenuItem value={l.id}>{l.full_name}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <ColorPicker
                fullWidth
                label="Color"
                name="color"
                variant="standard"
                defaultValue={formData.team_color || ""}
                value={formData.team_color || ""}
                error={errors?.team_color}
                helperText={errors?.team_color ? "required" : ""}
                onChange={(e) => {
                  setFormData({ ...formData, team_color: e });
                  if (e) {
                    delete errors.team_color;
                    setErrors(errors);
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginTop: "30px", marginRight: "10px", marginBottom: "10px", gap: "10px" }}>
          <Button onClick={handleClose} className={classes.closeButton} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSave}
            className={classes.saveButton}
            loadingPosition="start"
            loading={addTeam.isLoading || updateTeams.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
