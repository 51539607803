import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import ResponseAlert from "../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddPond, updatePond } from "../../redux/ponds";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Autocomplete from "@mui/material/Autocomplete";

function CreatePond(props) {
  let { open, setOpen, classes, selectedPond } = props;
  const [searchText, setSearchText] = useState();
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const [pondDetail, setPondDetail] = useState({ userId: "", name: "" });
  const dispatch = useDispatch();

  const createPond = useSelector((item) => item.Ponds.createPond);
  const editPond = useSelector((item) => item.Ponds.editPond);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);

  const handleClose = () => {
    setPondDetail({ userId: "", name: "" });
    setSelectedUsers([]);
    setOpen(false);
  };

  const handleUpdateValue = (field, value) => {
    setPondDetail((detail) => ({ ...detail, [field]: value }));
    setErrMsg({});
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!pondDetail.name) {
      isFormValid = false;
      errorMsg["name"] = "Please enter the pond name";
      setErrorAlert({
        errorMsg: "Please enter the pond name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!pondDetail.userId) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the pond agent",
        errorType: "warning",
        isOpen: true,
      });
    } else if (selectedUsers?.length === 0) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the team members first",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully created the Pond",
      errorType: "success",
      isOpen: true,
    });

    handleClose();
  };

  const handleSuccessUpdate = () => {
    setErrorAlert({
      errorMsg: "You have successfully Updated the Pond",
      errorType: "success",
      isOpen: true,
    });

    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const handleCreatePond = () => {
    if (handleValidate()) {
      dispatch(
        AddPond({
          schema: {
            ...pondDetail,
            userIds: [...selectedUsers, pondDetail.userId],
          },
          handleSuccess,
          handleError,
        })
      );
    }
  };

  const handleUpdatePond = () => {
    if (handleValidate()) {
      dispatch(
        updatePond({
          schema: {
            ...pondDetail,
            userIds: [...selectedUsers, pondDetail.userId],
          },
          id: selectedPond?.id,
          handleSuccess: handleSuccessUpdate,
          handleError,
        })
      );
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selectedUsers.indexOf(value);
    const newChecked = [...selectedUsers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedUsers(newChecked);
  };
  const filteredList = (userList) => {
    if (searchText) {
      return userList.filter(
        (item) =>
          item.full_name
            .toLowerCase()
            ?.slice(0, searchText.length)
            ?.includes(searchText) && item.id !== pondDetail.userId
      );
    } else {
      return userList?.filter((item) => item.id !== pondDetail.userId);
    }
  };
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    let admins = users.filter((item) => item?.role?.name === "Admin");
    let teamLeader = users.filter((item) => item?.role?.name === "Team Leader");
    let otherUsers = users.filter(
      (item) =>
        item?.role?.name !== "Team Leader" &&
        item?.role?.name !== "Admin" &&
        item?.role?.name !== "Agents"
    );
    return [
      ...teamLeader,
      ...admins,
      ...agents,
      ...otherUsers?.filter((item) => item.group !== undefined),
    ];
  };

  useEffect(() => {
    if (selectedPond?.id) {
      setPondDetail({
        name: selectedPond.name,
        userId: selectedPond.userId,
      });
      setSelectedUsers(selectedPond.userIds);
    }
  }, [selectedPond?.id]);

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className={classes.paymentModalHeading}
          id="alert-dialog-title"
        >
          {selectedPond?.id ? "Update Pond" : " Add Pond"}
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Pond name"
            size="small"
            fullWidth
            value={pondDetail.name}
            onChange={(e) => handleUpdateValue("name", e.target.value)}
            error={errMsg.name}
            helperText={errMsg.name}
          />
          <br />
          <br />
          <Autocomplete
            disableClearable
            options={storedListOfUsers?.list}
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            onChange={(e, option) => {
              handleUpdateValue("userId", option.id);
            }}
            sx={{ backgroundColor: "#E8ECF0" }}
            value={
              pondDetail.userId
                ? storedListOfUsers?.list?.find(
                    (it) => it.id === pondDetail.userId
                  )
                : null
            }
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select a pond agent"
                aria-describedby="stepper-linear-event_types"
                size="small"
                fullWidth
                required
                className={classes.selectAgentInput}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: pondDetail.userId ? (
                    <Avatar
                      sx={{
                        width: "30px",
                        height: "30px",
                        fontSize: "13px",
                        ml: "3px",
                      }}
                      src={
                        pondDetail.userId
                          ? storedListOfUsers?.list.find(
                              (it) => it.id === pondDetail.userId
                            )?.profile_images?.profile_img_thumbnail
                          : ""
                      }
                    >
                      {`${storedListOfUsers?.list
                        .find((it) => it.id === pondDetail.userId)
                        .first_name?.slice(0, 1)}${storedListOfUsers?.list
                        .find((it) => it.id === pondDetail.userId)
                        .last_name?.slice(0, 1)}`}
                    </Avatar>
                  ) : null,
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    fontSize: "13px",
                    mr: "8px",
                  }}
                  src={
                    option.profile_images?.profile_img_thumbnail ||
                    option.profile_images?.profile_img
                  }
                >{`${option.first_name?.slice(0, 1)}${option?.last_name.slice(
                  0,
                  1
                )}`}</Avatar>
                {option?.first_name} {option?.last_name}
              </li>
            )}
          ></Autocomplete>
          <br />
          <Card variant="outlined">
            <Box className={classes.userFieldLabel}>Name</Box>
            <TextField
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              fullWidth
              size="small"
              placeholder="Search user"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <List
              dense
              sx={{
                width: "100%",
                maxHeight: "350px",
                bgcolor: "background.paper",
                overflow: "auto",
              }}
            >
              {CUstomSortUsers(filteredList(storedListOfUsers.list)).map(
                (item, value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(item.id)}
                          checked={selectedUsers.indexOf(item.id) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar src={item?.profile_images?.profile_img}>
                            {item.first_name?.slice(0, 1)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          primary={`${item.first_name} ${item.last_name}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              )}
            </List>
          </Card>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          {selectedPond?.id ? (
            <LoadingButton
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={editPond.isLoading}
              onClick={handleUpdatePond}
            >
              Update
            </LoadingButton>
          ) : (
            <LoadingButton
              classes={{ root: classes.saveButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={createPond.isLoading}
              onClick={handleCreatePond}
            >
              Save
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "CreatePondStyle" })(CreatePond);
