import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const deleteLeadAppointmentAPI = createAsyncThunk(
  "lead/deleteLeadAppointmentAPI",
  async (data, thunkAPI) => {
    const { id,handleSuccessDelete } = data;
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/leads/appointments/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if(handleSuccessDelete){
        handleSuccessDelete()
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
