import React, { memo } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { formatAddress } from "../../../../../../utils/propertyData";
import MapView from "./fullScreenDialog/map";
function PropertyLocation(props) {
  let { id, classes } = props;
  const listDetail = useSelector((state) => state.allCmas.listingDetail);

  return (
    <Box id={id} className={classes.propertyLocationArea}>
      <Typography
        className={classes.propertyLocationHeading}
        variant="subtitle2"
        display="block"
        gutterBottom
      >
        Location
      </Typography>
      <Box className={classes.propertySubHeader}>
        <Typography className={classes.propertySubHeaderItem}>
          {listDetail?.data?.StateOrProvince}
        </Typography>
        <ArrowRightIcon sx={{ color: "black" }} />
        <Typography
          className={classes.propertySubHeaderItem}
          sx={{ cursor: "pointer" }}
        >
          {listDetail?.data?.City}
        </Typography>
        <ArrowRightIcon sx={{ color: "black" }} />
        <Typography
          className={classes.propertySubHeaderItem}
          sx={{ cursor: "pointer" }}
        >
          {listDetail?.data?.PostalCode}
        </Typography>
        <ArrowRightIcon sx={{ color: "black" }} />
        <Typography
          className={classes.propertySubHeaderItemInfo}
          sx={{ color: "black !important" }}
        >
          {formatAddress(listDetail?.data)}
        </Typography>
      </Box>
      <Box id="property-map">
        <Box className={classes.mapViewSection}>
          <MapView disableLoading height={"400px"} />
        </Box>
      </Box>
      <Typography className={classes.mapListingDisclosure}>
        Listing Courtesy of {listDetail?.data?.ListOfficeName} -{" "}
        {listDetail?.data?.ListAgentFullName}
      </Typography>
      {listDetail?.data?.MlsStatus === "Closed" ? (
        <Typography className={classes.mapListingDisclosure}>
          Sold By {listDetail?.data?.BuyerOfficeName},{" "}
          {listDetail?.data?.BuyerAgentFullName}
        </Typography>
      ) : null}
    </Box>
  );
}
export default memo(PropertyLocation);
