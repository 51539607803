export const styleSheet = (theme) => ({
  commisionSelectionButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },

  paymentModalHeading: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  ModalHeading: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  customPhoneField: {
    border: "1px solid lightgrey",
    width: "100%",
    height: "40px",
    borderRadius: "5px",
    padding: "8.5px 14px",
    outline: "none",
    fontSize: "16px",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
});
