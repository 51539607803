import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getSearchSuggestions } from "./getSuggestions";
export const mIdxSignUp = createAsyncThunk(
  "LeadSavedSearches/mIdxSignUp",
  async (data, thunkAPI) => {
    let { schema, handleError, handleSuccess } = data;
    try {
      let site_id = data?.site_id;
      let url = `${process.env.REACT_APP_IDX_BASE_URL}api/v1/leads/signup/${site_id}?createdVia=Manual`;
      const resp = await axios.post(
        url,
        { ...schema },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (handleSuccess && resp.data) {
        handleSuccess();
        thunkAPI.dispatch(getSearchSuggestions({ dataset: data?.dataset }));
      }
      return { ...resp.data, site_id: data?.site_id };
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
