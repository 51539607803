export const styleSheet = () => ({
  mainDashboardPage: {
    marginTop: "70px",
    maxWidth: "90%",
    margin: "0 auto",
  },
  methodWeatherBox: {
    padding: "15px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
  },
  descriptionBox: {
    height: "100%",
    padding: "15px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
  },

  descriptionBoxH1: {
    margin: 0,
    padding: 0,
  },

  thumbnailVideo: {
    width: "100%",
    height: "400px",
    borderRadius: "40px",
    cursor: "pointer",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
  },
  imgCourses: {
    width: "100%",
    height: "200px",
    borderRadius: "40px",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
  },
  methodWeatherTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  descriptionImg: {
    width: "100%",
    borderRadius: "40px",
    height: "170px",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    marginTop: "10px",
  },
  dFlex: {
    display: "flex",
    gap: "20px",
    width: "100%",
  },
  methodWeatherBoxImg: {
    width: "30px",
    height: "30px",
  },
  methodWeatherTopH1: {
    fontSize: "24px",
    fontWeight: "500",
    margin: "0px",
  },
  methodWeatherTopP: {
    fontSize: "14px",
  },
  methodWeatherBoxul: {
    boxShadow: "inset 0px 1px 0px rgb(0 0 0 / 12%)",
  },
  methodWeatherBoxli: {
    boxShadow: "inset 0px -1px 0px rgb(0 0 0 / 12%)",
    padding: "7px 0px",
    cursor: "pointer",
  },
  methodWeatherTopInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  methodWeatherTopInfoDes: {
    marginRight: "20px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    fontSize: "12px",
  },
  methodWeatherTopInfoDesSpan: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  methodWeatherTopInfoDesImg: {
    width: "52px",
  },
  methodWeatherBottom: {
    margin: "25px 0px",
    lineHeight: "35px",
  },

  /** Box/Card Style - Common */
  boxArea: {
    marginTop: "20px",
    minHeight: "60vh",
  },
  "@media (max-width: 575px)": {
    boxArea: {
      maxWidth: "280px",
      overflowX: "scroll",
    },
  },
  boxDashboard: {
    padding: "0 15px",
    paddingBottom: "30px",
    minHeight: "500px",
    fontFamily: "AvenirNext!important",

    // style for stepper date
    "& .MuiStepContent-root": {
      marginLeft: "60.5px",
      borderLeft: "2px solid rgba(0, 0, 0, 0.15) !important",
      minHeight: "78px  !important",
      "& .MuiTypography-root": {
        fontSize: "14px !important",
      },
    },
    "& .MuiStepConnector-root": {
      marginLeft: "60.5px !important",
    },
    "& .MuiStepConnector-line": {
      display: "none !important",
    },
    "& .MuiStepLabel-label": {
      fontSize: "15px !important",
      fontWeight: "600  !important",
    },
    "& .MuiStepLabel-root": {
      padding: "4px 0px  !important",
    },
  },
  boxDashboardTitle: {
    fontWeight: "400",
    fontSize: "24px",
    marginTop: "0px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "15px",
  },

  tableTh: {
    fontWeight: "600 !important",
    whiteSpace: "nowrap",
  },
  tableHCell: {
    fontWeight: "600 !important",
    fontFamily: "AvenirNext!important",
    whiteSpace: "nowrap",
  },
  tableCell: {
    fontFamily: "AvenirNext!important",
    fontWeight: "500 !important",
  },

  // Alert Card
  alertboxDashboardItem: {
    display: "flex",
    padding: "10px",
    marginBottom: "15px",
    gap: "20px",
    cursor: "pointer",
  },
  alertboxDashboardItemIcon: {
    "& .MuiSvgIcon-root": {
      fontSize: "40px",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  alertboxDashboardItemTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& h5": {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "600",
      fontSize: "14px!important",
      fontFamily: "AvenirNext!important",
    },
  },
  alertboxDashboardItemDescription: {
    marginTop: "5px!important",
    fontWeight: "400",
    fontSize: "14px!important",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "AvenirNext!important",
  },
  // Alert Card

  // Closing Card
  deposited: {
    background: "rgba(0, 0, 0, 0.54) !important",
  },
  processing: {
    background: "#0B0909 !important",
    color: "#fff !important",
  },
  eventStepperDescription: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "2 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
});
