/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Close } from "@mui/icons-material";

function GenerateStatement(props) {
  const { id } = useParams();
  const { open, setOpen, classes } = props;
  const [pdfData, setPdfData] = React.useState(null);
  const [pdfDataLoading, setPdfDataLoading] = React.useState(false);

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    last0BalanceChecked: false,
  });

  const generateStatement = async (userId, { startDate, endDate, last0BalanceChecked }) => {
    try {
      setPdfDataLoading(true);

      let queryParams = "";

      if (startDate && endDate) {
        queryParams = `?startDate=${moment.parseZone(startDate).format('YYYY-MM-DD')}T00:00:01&endDate=${moment.parseZone(endDate).format('YYYY-MM-DD')}T23:59:59`;
      } else if (last0BalanceChecked) {
        queryParams = "?last_zero_balance=true";
      }

      const url = `${process.env.REACT_APP_BASE_URL}api/users/generate-statement/${userId}${queryParams}`;
      const resp = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.token,
          Accept: "application/pdf",
        },
      });

      const contentDispositionHeader = resp.headers['content-disposition'];
      let filename = 'test';
      if (contentDispositionHeader)
        filename = contentDispositionHeader.split("filename=")[1];

      const pdfUrl = URL.createObjectURL(resp.data, filename);

      return { url: pdfUrl, filename: filename }
    } catch (error) {
      console.log(error);
      // handle error
    }
  };

  const handleGenerateStatement = () => {
    generateStatement(id, formData)
      .then((data) => {
        setPdfDataLoading(false);
        const { url, filename } = data;

        setPdfData({
          url: url,
          filename: filename,
        });
      })
      .catch((error) => {
        console.log(error);
        // handle error
      });
  };

  const downloadPdf = () => {
    try {
      const link = document.createElement('a');
      link.href = pdfData.url;
      link.download = pdfData.filename;
      document.body.append(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    if (!open) {
      setFormData({
        startDate: null,
        endDate: null,
        last0BalanceChecked: false
      });
      setPdfDataLoading(false);
      setPdfData(null);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{  "& .MuiDialog-container": { mt: "30px" }}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.generateModelHeader} id="alert-dialog-title">
          Generate Statement

          <IconButton onClick={() => setOpen(false)} className={classes.closeButton}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px!important" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2} display="flex" alignItems="center">
              <Grid item xs={3.5}>
                <DatePicker
                  label="Start Date"
                  componentsProps={{
                    actionBar: {
                      actions: ["clear", "cancel", "accept"],
                    },
                  }}
                  value={formData.startDate}
                  onChange={(newValue) => setFormData({ ...formData, startDate: new Date(newValue) })}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </Grid>
              <Grid item xs={3.5}>
                <DatePicker
                  label="End Date"
                  componentsProps={{
                    actionBar: {
                      actions: ["clear", "cancel", "accept"],
                    },
                  }}
                  value={formData.endDate}
                  onChange={(newValue) => setFormData({ ...formData, endDate: new Date(newValue) })}
                  renderInput={(params) => <TextField size="small" {...params} />}  
                />
              </Grid>
              <Grid item>OR</Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  sx={{
                    "& .MuiTypography-root": {
                      flexShrink: 0,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={formData.last0BalanceChecked}
                      onChange={(e) => setFormData({ ...formData, last0BalanceChecked: e.target.checked })}
                    />
                  }
                  label="Since Last $0 Balance"
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

          <Box display={"flex"} justifyContent={"flex-end"} gap={"10px"} mt={"20px"}>
            {pdfData?.url && (<Button className={classes.downloadButton} onClick={downloadPdf}><FileDownloadIcon /></Button>)}
            <Button
              onClick={handleGenerateStatement}
              classes={{ root: classes.generateButton }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={pdfDataLoading}
            >
              {pdfDataLoading ? <CircularProgress sx={{ color: "#fff", width: "20px!important", height: "20px!important" }} /> : "Generate" }
            </Button>
          </Box>

          {pdfData?.url && (
            <Box sx={{ marginTop: "0.5rem" }}>
              <iframe src={pdfData.url} width={"100%"} height={550}></iframe>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddAgentNoteModalStyle" })(GenerateStatement);
