import React, { Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { formatAddress, getAcres } from "../../../../../../utils/propertyData";
function PropertyBuildingInfo(props) {
  let { classes } = props;
  const listDetail = useSelector((state) => state.allCmas.listingDetail);
  return (
    <Fragment>
      <Typography
        id="property-building-information"
        className={classes.propertyHeading}
      >
        Building Information for {formatAddress(listDetail?.data)}
      </Typography>
      <Grid container spacing={2} sx={{ mt: "10px" }}>
        <Grid item xs={12} md={12} lg={6}>
          <Box className={classes.propertyDetailItem}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className={classes.propertyDetailItemHeading}
            >
              Stories
            </Typography>
            <Typography
              className={classes.propertyDetailItemValues}
              variant="subtitle2"
              display="block"
              gutterBottom
            >
              {" "}
              {listDetail?.data?.Levels?.join(", ")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box className={classes.propertyDetailItem}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className={classes.propertyDetailItemHeading}
            >
              Year Built
            </Typography>
            <Typography
              className={classes.propertyDetailItemValues}
              variant="subtitle2"
              display="block"
              gutterBottom
            >
              {listDetail?.data?.YearBuilt}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box className={classes.propertyDetailItem}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className={classes.propertyDetailItemHeading}
            >
              Lot Size
            </Typography>
            <Typography
              className={classes.propertyDetailItemValues}
              variant="subtitle2"
              display="block"
              gutterBottom
            >
              {getAcres(listDetail?.data)} Acres
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default PropertyBuildingInfo;
