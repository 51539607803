import React, { useState, Fragment } from "react";
import {
  LinearProgress,
  Paper,
  Stack,
  Box,
  Typography,
  AvatarGroup,
  Backdrop,
  CircularProgress,
  IconButton,
  Chip,
} from "@mui/material";
import {
  ContactDetails,
  Details,
  NameCard,
} from "../leads/people/peopleDetail/cards/cards";
import Avatar from "../../components/uiElements/avatar/index";
import { useDispatch, useSelector } from "react-redux";
import processingIcon from "../../assets/images/processingIcon.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Dropzone from "react-dropzone";
import dragIcon from "../../assets/dragIcon.png";
import ResponseAlert from "../../components/responseAlert";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  addTicketAttachment,
  removeTicketAttachment,
  addTicketEvent,
} from "../../redux/agentRequests";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CustomTooltip } from "../../components/shared/tooltip";
import UserDetailSkelton from "./skelton/userDetail";
import {
  deptColorsMapping,
  deptTextColorsMapping,
  calculateDueInShortForm,
} from "../projects/helpers";
import StatusComponent from "../projects/StatusComponent";
import { getData } from "../../utils/getData";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { marketCustom } from "../../utils/markets";
function UserDetail(props) {
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();
  let { classes } = props;

  const { data: requestDetail, isLoading } = useSelector(
    (state) => state.AgentRequests.requestDetail
  );
  const removeAttachment = useSelector(
    (state) => state.AgentRequests.removeAttachment
  );
  const addAttachment = useSelector(
    (state) => state.AgentRequests.addAttachment
  );
  const adminAuth = useSelector((state) => state.adminAuth);
  const handleDragEnter = () => {
    document.getElementById(`agent-request-ticket-area`).style.border =
      "2px dotted black";
    document.getElementById(`agent-request-ticket_attachment`).style.display =
      "flex";
  };
  const handleDragLeave = () => {
    document.getElementById(`agent-request-ticket-area`).style.border =
      "1px solid lightgrey";
    document.getElementById(`agent-request-ticket_attachment`).style.display =
      "none";
  };

  const handleFileChangeDrop = (Files, data) => {
    if (Files[0]?.type?.split("/")[1] === "pdf") {
      handleFileChange(Files[0], data);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully uploaded the attachment",
      errorType: "success",
      isOpen: true,
    });
  };
  const handleDeleteSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the attachment",
      errorType: "success",
      isOpen: true,
    });
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleFileChange = (file) => {
    if (file) {
      const Data = new FormData();
      Data.append("attachments[]", file);
      dispatch(
        addTicketAttachment({
          ticketId: requestDetail?.id,
          formData: Data,
          handleSuccess,
          handleError,
        })
      );
    }
  };
  const handleDeleteAttachment = (id) => {
    let obj = {
      ticketId: id,
      handleError,
      handleDeleteSuccess,
    };
    dispatch(removeTicketAttachment(obj));
  };

  const handleCallEvent = () => {
    let obj = {
      ticketId: requestDetail?.id,
      schema: {
        event: "Call",
      },
    };
    dispatch(addTicketEvent(obj));
  };

  const handleCalculatePercentage = (project_tasks) => {
    let totalTasks = project_tasks.length;
    let totalTasksCompl = project_tasks.filter((tsk) =>
      ["review", "approved", "submitted", "completed"].includes(
        tsk.status.toLowerCase()
      )
    ).length;
    let percentage =
      totalTasksCompl !== 0
        ? parseInt((totalTasksCompl / totalTasks) * 100)
        : 0;
    return percentage;
  };
  const handleGetTeamMembers = (project_tasks) => {
    let teamMembers = [],
      alreadyExist = [];

    project_tasks.map((task) => {
      if (task.team_member && !alreadyExist.includes(task.team_member.id)) {
        teamMembers.push({
          src:
            task.team_member?.profile_images?.profile_img_thumbnail ||
            task.team_member?.profile_images?.profile_img ||
            null,
          title: task.team_member.full_name,
        });
        alreadyExist.push(task.team_member.id);
      }
    });
    return teamMembers;
  };

  const downLoadFile = async (src) => {
    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/transactions/signed-url` +
        "?src=" +
        encodeURIComponent(src)
    );
    if (result.status === 440) {
      window.location.href = "/login";
    }
    window.open(result.data, "_blank");
  };

  return (
    <Fragment>
      <Box className={classes.mainWrapper} sx={{ mt: "8px" }}>
        {isLoading ? (
          <UserDetailSkelton />
        ) : (
          <Box className={classes.userInfoDetail}>
            <Stack spacing={1}>
              <NameCard
                selectedUser={{
                  ...requestDetail?.agent,
                  firstName: requestDetail?.agent?.full_name?.split(" ")[0],
                  lastName: requestDetail?.agent?.full_name?.split(" ")[1],
                  profile_img:
                    requestDetail?.agent?.profile_images?.profile_img,
                }}
                isAllowRedirect
                isAllowJoinDate
              />
              <ContactDetails
                list={[
                  {
                    type: "phone",
                    onClick: () => {},
                    value: [{ value: requestDetail?.agent?.phone }],
                  },
                  {
                    type: "email",
                    onClick: () => {},
                    value: [{ value: requestDetail?.agent?.email }],
                  },
                  {
                    type: "address",
                    onClick: () => {},
                    value: [],
                  },
                ]}
                name={
                  requestDetail?.agent?.full_name ||
                  adminAuth?.adminDetail?.full_name
                }
                disableUpdate={true}
                handleCallEvent={handleCallEvent}
                // name={leadProfile?.list?.data?.name}
              />
              <Details
                title="Details"
                list={[
                  {
                    key: "Training Stage",
                    value: requestDetail?.agent?.stage ? (
                      <Chip
                        sx={{ backgroundColor: "black", color: "white" }}
                        label={requestDetail?.agent?.stage}
                        size="small"
                      />
                    ) : (
                      ""
                    ),
                  },
                  {
                    key: "Market",
                    value: requestDetail?.agent?.market?.name ? (
                      <Chip
                        sx={{
                          backgroundColor:
                            requestDetail?.agent?.market?.id &&
                            marketCustom.find(
                              (item) =>
                                item.id === requestDetail?.agent?.market?.id
                            )
                              ? marketCustom.find(
                                  (item) =>
                                    item.id === requestDetail?.agent?.market?.id
                                )?.backgroundColor
                              : "#03A9F4",
                          color: "white",
                        }}
                        label={requestDetail?.agent?.market?.name}
                        size="small"
                      />
                    ) : (
                      ""
                    ),
                  },
                  {
                    key: "Team Leader",
                    value: requestDetail?.team?.leader_info?.full_name ? (
                      <Chip
                        sx={{
                          "& .MuiChip-label": { paddingLeft: "5px !important" },
                        }}
                        label={
                          <Stack
                            direction="row"
                            alignItems={"center"}
                            spacing={1}
                          >
                            <Avatar
                              src={
                                requestDetail?.team?.leader_info?.profile_images
                                  ?.profile_img
                              }
                              sx={{
                                width: "25px",
                                height: "25px",
                                fontSize: "11px",
                              }}
                            >
                              {`${
                                requestDetail?.team?.leader_info?.full_name?.slice(
                                  0,
                                  1
                                ) || ""
                              }${
                                requestDetail?.team?.leader_info?.full_name
                                  ?.split(" ")[1]
                                  ?.slice(0, 1) || ""
                              }`}
                            </Avatar>
                            <Typography sx={{fontSize:"13px"}}>
                              {requestDetail?.team?.leader_info?.full_name}
                            </Typography>
                          </Stack>
                        }
                      />
                    ) : (
                      ""
                    ),
                  },
                  {
                    key: "Currently Assigned to",
                    value: requestDetail?.assignedUser?.full_name ? (
                      <Chip
                        sx={{
                          "& .MuiChip-label": { paddingLeft: "5px !important" },
                        }}
                        label={
                          <Stack
                            direction="row"
                            alignItems={"center"}
                            spacing={1}
                          >
                            <Avatar
                              src={
                                requestDetail?.assignedUser?.profile_images
                                  ?.profile_img
                              }
                              sx={{
                                width: "25px",
                                height: "25px",
                                fontSize: "11px",
                              }}
                            >
                              {`${
                                requestDetail?.assignedUser?.full_name?.slice(
                                  0,
                                  1
                                ) || ""
                              }${
                                requestDetail?.assignedUser?.full_name
                                  ?.split(" ")[1]
                                  ?.slice(0, 1) || ""
                              }`}
                            </Avatar>
                            <Typography sx={{fontSize:"13px"}}>
                              {requestDetail?.assignedUser?.full_name}
                            </Typography>
                          </Stack>
                        }
                      />
                    ) : null,
                  },
                ]}
                justifyContent="space-between"
                divider={true}
                removeBottomSpace={true}
              />
              <Paper
                variant="outlined"
                sx={{ padding: "8px 15px 15px 15px" }}
                id="agent-request-ticket-area"
              >
                <Dropzone
                  accept={".pdf"}
                  noClick={true}
                  noKeyboard={true}
                  onDragEnter={() => handleDragEnter()}
                  onDragLeave={() => handleDragLeave()}
                  onDrop={(acceptedFiles) => {
                    handleFileChangeDrop(acceptedFiles);
                    handleDragLeave();
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()} sx={{ position: "relative" }}>
                      <Box
                        id={`agent-request-ticket_attachment`}
                        className={classes.dropzoneArea}
                      >
                        <Box className={classes.dropzoneAreaInner}>
                          <img
                            src={dragIcon}
                            alt="dragIcon"
                            style={{
                              width: "25px",
                              marginRight: "10px",
                            }}
                          />{" "}
                          <Box>Drop file to upload.</Box>
                        </Box>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems={"center"}
                        sx={{ mt: "-5px" }}
                      >
                        <Typography
                          className={classes.userDetailSectionHeading}
                        >
                          Attachments
                        </Typography>
                        <IconButton component="label">
                          <AddCircleIcon sx={{ color: "black" }} />
                          <input
                            onChange={(e) =>
                              handleFileChange(e.target.files[0])
                            }
                            hidden
                            accept=".pdf"
                            multiple
                            type="file"
                          />
                        </IconButton>
                      </Stack>
                      <Box className={classes.attachmentArea}>
                        {requestDetail?.attachments?.length ? (
                          requestDetail?.attachments.map((item) => (
                            <Box
                              className={classes.attachmentItem}
                              sx={{
                                "&:hover": {
                                  "& .attachmentDeleteIconHov": {
                                    display: "block",
                                  },
                                },
                              }}
                            >
                              <Box
                                className={`${classes.attachmentDeleteIcon} attachmentDeleteIconHov`}
                              >
                                <IconButton
                                  onClick={() =>
                                    handleDeleteAttachment(item.id)
                                  }
                                  size="small"
                                >
                                  <CustomTooltip
                                    placement="top"
                                    title="Delete Attachment"
                                  >
                                    <DeleteForeverIcon
                                      sx={{ color: "grey" }}
                                      fontSize="large"
                                    />
                                  </CustomTooltip>
                                </IconButton>

                                <IconButton
                                  onClick={() =>
                                    downLoadFile(item.relativeFilePath)
                                  }
                                  size="small"
                                >
                                  <CustomTooltip
                                    placement="top"
                                    title="Open Attachment"
                                  >
                                    <DescriptionIcon
                                      sx={{
                                        color: "grey",
                                        fontSize: "30px",
                                        ml: "3px",
                                      }}
                                      fontSize="large"
                                    />
                                  </CustomTooltip>
                                </IconButton>
                              </Box>
                              <img
                                src={
                                  item.relativeFilePath && !item.isProcessing
                                    ? process.env.REACT_APP_DOCUMENT_IMG +
                                      encodeURIComponent(
                                        item.relativeFilePath.replace(
                                          ".pdf",
                                          ".png"
                                        )
                                      )
                                    : processingIcon
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = processingIcon;
                                }}
                                alt={"docSample"}
                              />
                            </Box>
                          ))
                        ) : (
                          <Typography
                            sx={{
                              color: "#9c9c9c",
                              fontFamily: "AvenirNext-400",
                              fontSize: "14px",
                              mt: "0px",
                              paddingBottom: "6px",
                            }}
                          >
                            No file Exists!
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </Dropzone>
              </Paper>
              <Paper variant="outlined" sx={{ padding: "8px 15px 15px 15px" }}>
                <Typography className={classes.userDetailSectionHeading}>
                  Associated Projects
                </Typography>
                {requestDetail?.projects?.length ? (
                  requestDetail?.projects.map((item) => (
                    <Paper
                      variant="outlined"
                      sx={{ padding: "10px", mb: "10px" }}
                    >
                      <Stack direction={"row"} spacing={2}>
                        <Box sx={{ width: "45%" }}>
                          <Box
                            sx={{
                              width: "100%",
                              borderRadius: "2px",
                              height: "14px",
                              padding: "0px 5px",
                              backgroundColor: deptColorsMapping(
                                item?.department || "Drafting"
                              ),
                            }}
                          >
                            <Typography
                              sx={{
                                color: deptTextColorsMapping(
                                  item?.department || "Drafting"
                                ),
                                fontSize: "9px",
                                fontWeight: "600",
                              }}
                            >
                              {item.department || "Drafting"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ width: "45%" }}>
                          <LinearProgress
                            color="inherit"
                            sx={{
                              height: "12px",
                              borderRadius: "10px",
                              "& .MuiLinearProgress-bar": {
                                background: "rgba(228, 13, 13, 0.31)",
                              },
                            }}
                            variant="determinate"
                            value={handleCalculatePercentage(
                              item.project_tasks
                            )}
                          />{" "}
                        </Box>
                        <Typography
                          sx={{
                            display: "inline-block",
                            width: "15%",
                            whiteSpace: "nowrap",
                            lineHeight: "14px",
                          }}
                        >
                          {" "}
                          {handleCalculatePercentage(item.project_tasks)} %
                        </Typography>
                      </Stack>
                      <Typography className={classes.userDetailProjectHeading}>
                        {item?.project_name}
                      </Typography>
                      <Typography className={classes.userDetailProjectDes}>
                        {item?.address}
                      </Typography>
                      <Box className={classes.userDetailProjectsStats}>
                        <StatusComponent
                          sx={{ height: "25px", width: "auto" }}
                          status={item.status}
                        />
                        <Stack direction={"row"} spacing={1}>
                          <Stack direction={"row"}>
                            <AccessTimeIcon
                              fontSize="small"
                              sx={{
                                color:
                                  calculateDueInShortForm(item.due_date)?.slice(
                                    0,
                                    1
                                  ) === "-"
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.54)",
                              }}
                            />
                            <Typography
                              sx={{
                                color:
                                  calculateDueInShortForm(item.due_date)?.slice(
                                    0,
                                    1
                                  ) === "-"
                                    ? "red"
                                    : "",
                              }}
                            >
                              {calculateDueInShortForm(item.due_date)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"}>
                            <AttachFileIcon
                              fontSize="small"
                              sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            />
                            <Typography>
                              {item.attachments?.length || "0"}
                            </Typography>
                          </Stack>
                        </Stack>
                        <AvatarGroup max={3}>
                          {handleGetTeamMembers(item.project_tasks)?.map(
                            (member) => (
                              <CustomTooltip
                                placement="top"
                                title={member?.title}
                              >
                                <Avatar
                                  sx={{
                                    width: "32px",
                                    height: "32px",
                                    fontSize: "13px",
                                  }}
                                  alt={member?.title}
                                  src={member.src}
                                >
                                  {`${member?.title?.slice(0, 1)}${member?.title
                                    ?.split(" ")[1]
                                    ?.slice(0, 1)}`}
                                </Avatar>
                              </CustomTooltip>
                            )
                          )}{" "}
                        </AvatarGroup>
                      </Box>
                      <Typography className={classes.userDetailProjectBottom}>
                        {item?.agent?.full_name}
                      </Typography>
                    </Paper>
                  ))
                ) : (
                  <Typography
                    sx={{
                      color: "#9c9c9c",
                      fontFamily: "AvenirNext-400",
                      fontSize: "14px",
                      mt: "12px",
                      paddingBottom: "6px",
                    }}
                  >
                    No Projects Exists!
                  </Typography>
                )}
                {/* <Box className={classes.attachProjectButtonArea}>
                  <Button variant="contain" className={classes.attachProjectButton}>Attach Project</Button>
                </Box> */}
              </Paper>
            </Stack>
          </Box>
        )}
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Backdrop
        color="inherit"
        invisible={true}
        open={addAttachment.isLoading || removeAttachment.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
export default UserDetail;
