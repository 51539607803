import { createSlice } from "@reduxjs/toolkit";
import { getEmailTemplatesAgentsRecruitment } from "./getEmailTemplatesAgenstRecruitment";
import { mergeEmailTemplateAgentRecruitment } from "./mergeEmailTemplateAgentRecruitment";

const initialState = {
  loading: "",
  emailTemplates: [],
  isLimitReached:false,
  savedEmailTemplates: [],
  mergeEmail: {
    list: {},
    loading: "",
    errMsg: "",
    success: "",
  },
  errMsg: null,
};

const emailTemplates = createSlice({
  name: "emailTemplates",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.loading = false;
      state.mergeEmail.loading = false;
      state.mergeEmail.errMsg = "";
      state.mergeEmail.success = false;
    },
    saveEmailTemplate(state, action) {
      const { id, subject, body } = action.payload
      const existingTemplate = state.savedEmailTemplates.find(obj => obj.id === id)
      if (existingTemplate) {
        state.savedEmailTemplates = state.savedEmailTemplates.map((obj) => {
          if (obj.id === id) {
            obj = action.payload;
          }
          return obj;
        });
      }else{
        state.savedEmailTemplates.push(action.payload)
      }
    }
  },

  extraReducers: {
    [getEmailTemplatesAgentsRecruitment.pending]: (state) => {
      state.loading = true;
    },
    [getEmailTemplatesAgentsRecruitment.fulfilled]: (state, action) => {
      state.loading = false;
      if(action.payload?.offset>0 && !action.payload?.search){
        if(action.payload?.templates.length===0){
          state.isLimitReached=true
        }else{
          state.emailTemplates = state.emailTemplates.concat(action.payload?.templates);
        }
      }else{
        state.emailTemplates = action.payload?.templates;
        state.isLimitReached=false
      }
    },
    [getEmailTemplatesAgentsRecruitment.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },

    [mergeEmailTemplateAgentRecruitment.pending]: (state) => {
      state.mergeEmail.loading = true;
      state.mergeEmail.errMsg = "";
      state.mergeEmail.success = false;
    },
    [mergeEmailTemplateAgentRecruitment.fulfilled]: (state, action) => {
      state.mergeEmail.list = action.payload;
      state.mergeEmail.loading = false;
      state.mergeEmail.success = true;
    },
    [mergeEmailTemplateAgentRecruitment.rejected]: (state, action) => {
      state.mergeEmail.loading = false;
      state.mergeEmail.errMsg = "Ooops! An error occured! ";
    },
  },
});


export const selectEmailTemplateById = (state, id) => {
  return state.emailTemplates.savedEmailTemplates.find((list) => list.id === id);
};
export const { resetState, saveEmailTemplate } = emailTemplates.actions;

export default emailTemplates.reducer;
