import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const updatePublicData = createAsyncThunk("publicData/updatePublicData", async (data, thunkAPI) => {
  let { schema, handleSuccess, handleError } = data;
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/transactions/listing/public-data/${data.listing_public_data_id}`, schema, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (handleSuccess) {
      handleSuccess();
    }
    return resp.data;
  } catch (error) {
    if (error.response) {
      if (handleError) {
        handleError(error.response.data.errorMessage || error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      if (handleError) {
        handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
