import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  tagsList: [],
  errMsg: null,
};

export const getAgentRecruitingAllTags = createAsyncThunk("agentRecruitment/getAgentRecruitingAllTags", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/recruitment/tags`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const tagsList = createSlice({
  name: "tagsList",
  initialState,
  extraReducers: {
    [getAgentRecruitingAllTags.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRecruitingAllTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.tagsList = action.payload.arTags;
    },
    [getAgentRecruitingAllTags.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default tagsList.reducer;
