export const styleSheet = (theme) => ({
  modalHeading: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveModalButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
    minWidth: "50px  !important",
  },
  inputLabel: {
    fontSize: "15px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
  },
  featureTabsButton: {
    borderRadius: "15px !important",
    minWidth: "100px !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext  !important",
    paddingTop: "2px !important",
    paddingBottom: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.6) !important",
    fontWeight: "600 !important",
  },
  featureItemLabel: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext  !important",
    borderColor: "rgba(0, 0, 0, 0.8) !important",
    fontWeight: "600 !important",
  },
  dataCategoriesItem: {
    height: "200px !important",
    overflow: "auto !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  publicDataFeaturesArea: {
    marginTop: "15px  !important",
    marginBottom: "15px  !important",
    height:"75vh !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
});
