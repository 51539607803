export const styleSheet = () => ({
  pageRoot: {
    marginTop: "30px !important",
  },
  loadingArea:{
    height:"80vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  TopUsersBarArea: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:"5px 20px",
    height:'50px'
  },
  TopUsersBarAreaLeft:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center'
  },
  "@media (max-width: 1270px)": {
    UsersTabsUI: {
    maxWidth:'420px'
    }
  },
  "@media (max-width: 1050px)": {
    UsersTabsUI: {
    maxWidth:'300px'
    }
  },
  UsersTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root":{
      fontSize:'13px !important',
      minHeight:'40px !important',
    },
    "& .MuiTabs-root":{
      minHeight:'40px  !important'
    },
    "& .MuiTabs-scroller":{
      height:'36px  !important'
    }
  },
  addPhoneButton: {
    background: "#0B0909 !important",
    fontWeight:'400  !important',
    fontSize:'14px !important',
    color: "white",
    height:'36px !important',
    marginRight:'15px !important',
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "4px  !important",
    color:'white !important',
    marginLeft:"20px !important",
    minWidth:"130px !important"
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  pageHeading:{
    fontSize:'24px !important',
    fontWeight:'500 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',

  },
});
