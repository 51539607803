import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import Button from "../../../../components/uiElements/button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import { updateLeadProfileAPI } from "../../../../redux/leads/updateLeadProfileAPI";
import { useRef } from "react";
import ViewportList from "react-viewport-list";

export const AddTagsModal = ({ closeModal, tagsList, loading, state, prevTags, id, ...props }) => {
  const [searchTag, setSearchTag] = useState("");
  const [selectedTagsList, setSelectedTagsList] = useState(prevTags || []);
  const [filteredTagsList, setFilteredTagsList] = useState([]);
  const [tagsListArr, setTagsListArr] = useState([]);

  const dispatch = useDispatch();

  const fetch = (param) => {
    setFilteredTagsList(param);
    setTagsListArr(param);
  };
  useEffect(() => {
    if (tagsList?.length) {
      fetch(tagsList);
    }
  }, [tagsList]);

  const { loading: actionLoading, success, errMsg } = useSelector((item) => item.agentProfile.agentProfile.modalActions);

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success, errMsg]);

  const saveTags = async () => {
    const schema = {
      schema: {
        tags: selectedTagsList,
      },
      leadId: id,
    };
    const fetch = await dispatch(updateLeadProfileAPI(schema));
    if (fetch?.payload) {
      closeModal();
    }
  };

  return (
    <Dialog
    sx={{ "& .MuiDialog-container": { mt: "30px" }}}     disablePortal
      dialogHead={<DialogHead />}
      onSave={saveTags}
      dialogBody={
        <DialogBody
          setSelectedTagsList={setSelectedTagsList}
          selectedTagsList={selectedTagsList}
          tagsList={tagsListArr}
          setTagsList={setTagsListArr}
          filteredTagsList={filteredTagsList}
          setFilteredTagsList={setFilteredTagsList}
          setSearchTag={setSearchTag}
          searchTag={searchTag}
        />
      }
      dialogFooter={<DialogFooter />}
      state={state}
      closeModal={closeModal}
      maxWidth="md"
      loading={actionLoading}
      fullWidth={true}
    />
  );
};

// Components
const AddTaskForm = ({ mb, tagsList, setFilteredTagsList, setSearchTag, searchTag }) => {
  const changeInputSearchFn = (event) => {
    const InputValue = event.target.value;

    setSearchTag(InputValue);
    const filterArray = tagsList.filter((el) => el.name.toLowerCase().includes(InputValue.toLowerCase()));
    if (filterArray?.length) {
      setFilteredTagsList(filterArray);
    } else if (!InputValue.trim()) {
      setFilteredTagsList(tagsList);
    } else {
      setFilteredTagsList([]);
    }
  };

  return (
    <>
      <Box sx={{ display: "block", marginBottom: "10px" }}></Box>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ flexGrow: 1, marginBottom: mb || 0 }}>
        <Grid item sm={12}>
          <Input placeholder="Search for tags..." onChange={changeInputSearchFn} value={searchTag} />
        </Grid>
      </Grid>
    </>
  );
};

const DialogHead = () => (
  <DialogTitle>
    <Typography component="h5" variant="h5">
      Tags
    </Typography>
  </DialogTitle>
);

const DialogBody = ({
  tagsList,
  setTagsList,
  filteredTagsList,
  setFilteredTagsList,
  setSearchTag,
  searchTag,
  setSelectedTagsList,
  selectedTagsList,
}) => {
  const addNewTagFunc = () => {
    setFilteredTagsList((prev) => [...tagsList, { name: searchTag.trim() }]);
    setTagsList((prev) => [...tagsList, { name: searchTag.trim() }]);
    setSelectedTagsList((prev) => [...prev, searchTag.trim()]);
    setSearchTag("");
  };

  const handleToggle = (e, value) => {
    const currentIndex = selectedTagsList.indexOf(value.name);
    const newChecked = [...selectedTagsList];

    if (currentIndex === -1) {
      newChecked.push(value.name);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedTagsList([...newChecked]);
  };

  const ref = useRef(null);
  return (
    <DialogContent>
      <AddTaskForm setFilteredTagsList={setFilteredTagsList} tagsList={tagsList} setSearchTag={setSearchTag} searchTag={searchTag} />
      <br />

      {filteredTagsList?.length <= 0 ? (
        <Button variant="text" themeVersion startIcon={<AddCircleIcon />} onClick={addNewTagFunc}>
          Create new tag “{searchTag}”
        </Button>
      ) : (
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            height: "calc(100vh - 400px)",
            overflow: "auto",
          }}
          ref={ref}
        >
          {
            <ViewportList viewportRef={ref} items={filteredTagsList} itemMinSize={10} margin={8}>
              {(value) => (
                <ListItem key={value.id} disablePadding sx={{ borderBottom: "1px solid #c4c4c4 !important" }}>
                  <ListItemButton role={undefined} onClick={(e) => handleToggle(e, value)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedTagsList.indexOf(value.name) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": value.id }}
                      />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-${value.id}`} primary={value.name} />
                  </ListItemButton>
                </ListItem>
              )}
            </ViewportList>
          }
        </List>
      )}
    </DialogContent>
  );
};

const DialogFooter = () => (
  <DialogActions>
    <Typography component="psubtitle1" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);
