import React from "react";
import { Box, Stack, Typography, Grid, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { NumberFormat } from "../../../../../../utils/numberFormat";
import { formatAddress, getAcres } from "../../../../../../utils/propertyData";

function PropertyStatsBar(props) {
  let { classes } = props;
  const listDetail = useSelector((state) => state.allCmas.listingDetail);
  return (
    <Box>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} md={4} lg={4}>
          <Typography className={classes.statsBarHeading}>
            {formatAddress(listDetail?.data)}
          </Typography>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography className={classes.statsBarCityZip}>
              <span>{listDetail?.data?.City?.toLowerCase() || ""}</span>,{" "}
              {listDetail?.data?.StateOrProvince} {listDetail?.data?.PostalCode}
            </Typography>{" "}
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <Box className={classes.statsBarItemArea}>
            <Box>
              <Typography className={classes.statsBarItemTitle}>
                {listDetail?.data?.OriginalListPrice ||
                listDetail?.data?.ListPrice
                  ? NumberFormat({
                      number:
                        listDetail?.data?.ListPrice ||
                        listDetail?.data?.OriginalListPrice,
                      currency: "USD",
                    })
                  : null}
              </Typography>
              <Typography className={classes.statsBarItemValue}>
                Price
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            {Number(listDetail?.data?.BedroomsTotal || 0) > 0 ? (
              <Box>
                <Typography className={classes.statsBarItemTitle}>
                  {listDetail?.data?.BedroomsTotal}
                </Typography>
                <Typography className={classes.statsBarItemValue}>
                  Beds
                </Typography>
              </Box>
            ) : null}
            <Divider orientation="vertical" flexItem />
            {Number(listDetail?.data?.BathroomsFull || 0) > 0 ? (
              <Box>
                <Typography className={classes.statsBarItemTitle}>
                  {listDetail?.data?.BathroomsFull}
                </Typography>
                <Typography className={classes.statsBarItemValue}>
                  Baths
                </Typography>
              </Box>
            ) : null}
            <Divider orientation="vertical" flexItem />
            {Number(listDetail?.data?.BathroomsHalf || 0) > 0 ? (
              <Box>
                <Typography className={classes.statsBarItemTitle}>
                  {listDetail?.data?.BathroomsHalf}
                </Typography>
                <Typography className={classes.statsBarItemValue}>
                  1/2 Bath
                </Typography>
              </Box>
            ) : null}
            {Number(listDetail?.data?.BathroomsHalf || 0) > 0 ? (
              <Divider orientation="vertical" flexItem />
            ) : null}
            {(listDetail?.data?.BuildingAreaTotal &&
              listDetail?.data?.BuildingAreaTotal > 0) ||
            (listDetail?.data?.AboveGradeFinishedArea &&
              listDetail?.data?.AboveGradeFinishedArea > 0) ? (
              <Box>
                <Typography className={classes.statsBarItemTitle}>
                  {NumberFormat({
                    number:
                      listDetail?.data?.BuildingAreaTotal > 0
                        ? listDetail?.data?.BuildingAreaTotal
                        : listDetail?.data?.AboveGradeFinishedArea,
                  })}{" "}
                  Sq.Ft.
                </Typography>
                <Typography className={classes.statsBarItemValue}>
                  {" "}
                  {NumberFormat({
                    number:
                      Number(
                        listDetail?.data?.ListPrice ||
                          listDetail?.data?.OriginalListPrice
                      ) /
                      Number(
                        listDetail?.data?.BuildingAreaTotal > 0
                          ? listDetail?.data?.BuildingAreaTotal
                          : listDetail?.data?.AboveGradeFinishedArea
                      ),
                    currency: "USD",
                  })}{" "}
                  / Sq.Ft.
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography className={classes.statsBarItemTitle}>
                  {getAcres(listDetail?.data)}
                </Typography>
                <Typography className={classes.statsBarItemValue}>
                  Acres
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default PropertyStatsBar;
