import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Divider,
  TextField,
  InputLabel,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useSelector, useDispatch } from "react-redux";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  addTaskTemplate,
  updateTaskTemplate,
} from "../../../redux/transactions/taskTemplates";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, Link, useLocation } from "react-router-dom";
function CreateTemplate(props) {
  let { classes, type } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const templateName = useRef(null);
  const [errMsg, setErrMsg] = useState({});
  const [deletedTaskIds, setDeletedTaskIds] = useState([]);
  const [tasks, setTasks] = useState([
    {
      name: "",
      assignedTo: null,
      days: "",
      dateType: "",
      eventStatus: "",
    },
  ]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { list: usersList } = useSelector(
    (item) => item.users.storedListOfUsers
  );
  const addTemplate = useSelector(
    (item) => item.transactions.templates.addTemplate
  );
  const updateTemplate = useSelector(
    (item) => item.transactions.templates.updateTemplate
  );

  const handleUpdateValue = (field, value, index) => {
    setTasks((prevTasks) => {
      const updatedTasks = [...prevTasks]; // Create a copy of the tasks array
      updatedTasks[index] = { ...updatedTasks[index], [field]: value }; // Update the specific task's field with the new value
      return updatedTasks; // Set the updated tasks array as the new state
    });
  };

  useEffect(() => {
    if (type === "update" && location?.state?.id) {
      templateName.current.value = location.state.title;
      setTasks(location.state.tasks);
      setDeletedTaskIds([]);
    }
  }, []);

  const handleDeleteTask = (index, item) => {
    setTasks(tasks.filter((it, id) => id !== index));
    if (item?.id) {
      setDeletedTaskIds((ids) => [...ids, item.id]);
    }
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!templateName.current.value) {
      isFormValid = false;
      errorMsg["name"] = "Please enter the template name";
      setErrorAlert({
        errorMsg: "Please enter the template name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (tasks?.length === 1 && !tasks[0]?.name) {
      setErrorAlert({
        errorMsg: "Please enter the template task detail first",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg:
        type === "update"
          ? "Transaction template updated successfully!"
          : "Transaction template  saved successfully!",
      errorType: "success",
      isOpen: true,
    });

    setTimeout(() => {
      navigate("/transaction/task_templates");
      setTasks([
        {
          name: "",
          assignedTo: null,
          days: "",
          dateType: "",
          eventStatus: "",
        },
      ]);
    }, 1000);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSaveEvent = () => {
    if (handleValidate()) {
      if (type === "update") {
        let newTasks = [];
        [...tasks]
          ?.filter((item) => item.name)
          ?.map((item) => {
            if (item.id) {
              newTasks.push({
                name: item.name,
                assignedTo: item.assignedTo,
                days: item.days,
                dateType: item.dateType,
                eventStatus: item.eventStatus,
                id: item.id,
              });
            } else {
              newTasks.push({
                name: item.name,
                assignedTo: item.assignedTo,
                days: item.days,
                dateType: item.dateType,
                eventStatus: item.eventStatus,
              });
            }
          });
        let obj = {
          schema: {
            title: templateName.current.value,
            tasks: newTasks,
            deletedTaskIds: deletedTaskIds,
          },
          id: location?.state?.id,
          handleSuccess,
          handleError,
        };
        console.log(obj, "obj", tasks, "tasks");
        dispatch(updateTaskTemplate(obj));
      } else {
        let obj = {
          schema: {
            title: templateName.current.value,
            tasks: [...tasks]?.filter((item) => item.name),
          },
          handleSuccess,
          handleError,
        };
        dispatch(addTaskTemplate(obj));
      }
    }
  };

  return (
    <Box className={classes.pageRoot}>
      <Card className={classes.formCard} variant="outlined">
        <Box className={classes.formHeader}>
          <Typography sx={{ fontSize: "18px" }}>
            {" "}
            {type === "update" ? "Update" : "New Task"} Template
          </Typography>
          <Box className={classes.templateListHeaderRight}>
            <Button
              className={classes.formCancelButton}
              variant="outlined"
              color="inherit"
              to={`/transaction/task_templates`}
              component={Link}
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSaveEvent}
              className={classes.formSaveButton}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={addTemplate.isLoading || updateTemplate.isLoading}
            >
              {type === "update" ? "Update" : "Save"}
            </LoadingButton>
          </Box>
        </Box>
        <Box className={classes.templateTitleArea}>
          <Typography sx={{ fontWeight: "500" }}>Template Name: </Typography>
          <TextField
            placeholder="Buyer Cash "
            size="small"
            sx={{ width: "65%" }}
            inputRef={templateName}
            error={errMsg.name}
            helperText={errMsg.name}
          />
        </Box>
        <Divider />
        {/*  add task item */}
        {tasks?.map((item, index) => (
          <Box key={index} className={classes.taskFormItem}>
            <IconButton
              onClick={() => handleDeleteTask(index, item)}
              className={classes.taskFormItemIcon}
            >
              <DeleteRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </IconButton>
            <Typography className={classes.taskFormItemContent}>
              Task {index + 1}
            </Typography>
            <Box className={classes.taskForm}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <InputLabel className={classes.inputLabel}>
                    Task Name:
                  </InputLabel>
                  <TextField
                    value={item.name}
                    onChange={(e) =>
                      handleUpdateValue("name", e.target.value, index)
                    }
                    placeholder="File Set Up"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <InputLabel className={classes.inputLabel}>
                    Assign To:
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-standard-label"
                    sx={{ "& .MuiSelect-select": { display: "flex" } }}
                    size="small"
                    value={
                      item.assignedTo === null
                        ? "Transactions Department"
                        : item.assignedTo
                    }
                    onChange={(e) =>
                      handleUpdateValue(
                        "assignedTo",
                        e.target.value === "Transactions Department"
                          ? null
                          : e.target.value,
                        index
                      )
                    }
                  >
                    <MenuItem
                      value={"Transactions Department"}
                      selected
                      key={`null-${index}`}
                    >
                      Transactions Department
                    </MenuItem>

                    <MenuItem
                      value=""
                      disabled
                      sx={{ borderBottom: "1px solid lightgrey" }}
                    >
                      TEAM MEMBERS
                    </MenuItem>

                    {usersList
                      ?.filter((it) => it.role?.name === "Admin")
                      .map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          <Avatar
                            src={
                              item?.profile_images?.profile_img ||
                              item?.profile_images?.slack_profile_img ||
                              item?.profile_images?.slack_profile_img_thumbnail
                            }
                            sx={{
                              width: "25px",
                              height: "25px",
                              mr: "7px",
                              fontSize: "13px",
                            }}
                            variant="circle"
                          >{`${item.first_name?.slice(
                            0,
                            1
                          )}${item.last_name?.slice(0, 1)}`}</Avatar>
                          {item.first_name} {item.last_name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item md={10}>
                  <InputLabel className={classes.inputLabel}>
                    Due Date:
                  </InputLabel>
                  <Grid container spacing={2} alignItems={"center"}>
                    {item.eventStatus === "on" ? null : (
                      <Grid item md={1.5}>
                        <TextField
                          value={item.days}
                          onChange={(e) =>
                            handleUpdateValue("days", e.target.value, index)
                          }
                          size="small"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                    )}
                    {item.eventStatus === "on" ? null : (
                      <Grid item md={1}>
                        <Typography
                          sx={{ fontSize: "15px", textAlign: "center" }}
                        >
                          days
                        </Typography>
                      </Grid>
                    )}

                    <Grid item md={4}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        value={item.eventStatus}
                        onChange={(e) =>
                          handleUpdateValue(
                            "eventStatus",
                            e.target.value,
                            index
                          )
                        }
                      >
                        <MenuItem value="on">On</MenuItem>
                        <MenuItem value="before">Before</MenuItem>
                        <MenuItem value="after">After</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item md={5}>
                      <TextField
                        value={item.dateType}
                        onChange={(e) =>
                          handleUpdateValue("dateType", e.target.value, index)
                        }
                        select
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="finance_contingency_date">
                          Finance Contingency Date
                        </MenuItem>
                        <MenuItem value="appraisal_contingency_date">
                          Appraisal Contingency Date
                        </MenuItem>
                        <MenuItem value="earnest_money_due_date">
                          Earnest Money Due Date
                        </MenuItem>
                        <MenuItem value="closing_date">Closing Date</MenuItem>
                        <MenuItem value="binding_date">Binding Date</MenuItem>
                        <MenuItem value="due_dilligence_date">
                          Due Diligence Date
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ))}

        <br />
        <br />
        <Button
          className={classes.formSaveButton}
          variant="contained"
          startIcon={<AddRoundedIcon />}
          size="small"
          sx={{ textTransform: "capitalize" }}
          onClick={() =>
            setTasks((preTasks) => [
              ...preTasks,
              {
                name: "",
                assignedTo: null,
                days: "",
                dateType: "",
                eventStatus: "",
              },
            ])
          }
        >
          Add Task
        </Button>
        <br />
        <br />
      </Card>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "CreateTemplateStyle" })(
  CreateTemplate
);
