import * as React from "react";
import { Card, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
function AlertSkelton(props) {
  let { count = 6 } = props;
  return (
    <Box>
      {Array.from(Array(count).keys()).map(() => (
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            sx={{ padding: "10px 15px", paddingRight: "20px" }}
            avatar={<Skeleton animation="wave" variant="circular" width={25} height={25} />}
            subheader={<Skeleton animation="wave" height={15} width="90%" style={{ marginBottom: 4 }} />}
            action={<Skeleton animation="wave" variant="circular" sx={{ mt: "10px" }} width={23} height={23} />}
            title={
              <Stack spacing={2} direction={"row"}>
                <Skeleton animation="wave" height={20} width="50%" />
                <Skeleton animation="wave" height={20} width="15%" />
              </Stack>
            }
          />
        </Card>
      ))}
    </Box>
  );
}
export default AlertSkelton;
