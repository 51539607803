import { GridToolbarColumnsButton } from "@mui/x-data-grid";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";
import { GridToolbarExport } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { getData } from "../../../../utils/getData";
import moment from "moment";
const XLSX = require("xlsx");
// Name Cell Component
export const AgentRecruitmentCustomToolbar = ({ setFilterButtonEl }) => {
  const [loading, setLoading] = useState(false);
  const { totalAgentsCount, filters, search } = useSelector(
    (item) => item.agentList
  );
  const { storedListOfUsers } = useSelector((item) => item.users);
  const { usersObj } = storedListOfUsers;

  const handleExport = async () => {
    setLoading(true);
    const param = `?pageSize=${5000}&pageNumber=${1}&search=${
      search || ""
    }&filters=${filters || ""}`;

    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/recruitment${param}`
    );
    setLoading(false);
    if (result.data?.length) {
      let users = [
        [
          "Name",
          "Assigned To",
          "Market",
          "Email",
          "License",
          "Phone",
          "Tags",
          "Office",
          "Last Communication",
          "Stage",
          "Volume 12 Months (Stellar)",
          "Volume 12 Months (Charlotte)",
          "Volume 12 Months (MIAMIRE)",
          "Volume 12 Months (FMLS)",
          "Volume 12 Months (GAMLS)",
          "Volume 12 Months (GALMLS)",
          "Total Sales (FMLS)",
          "Total Sales (MIAMIRE)",
          "Total Sales (Charlotte)",
          "Total Sales (GAMLS)",
          "Total Sales (GALMLS)",
          "Active Zips (Stellar)",
          "Active Zips FMLS",
          "Active Zips GAMLS",
          "Active Zips Miami",
          "Active Zips Carolina",
          "Active Cities (Miami)",
          "Active Cities (Stellar)",
          "Active Cities (FMLS)",
          "Active Cities (Carolina)",
          "Active Cities (GAMLS)",
          "Total Sales (Stellar)",
        ],
      ];
      result.data.forEach((user) => {
        let userArray = [
          user.MemberFullName,
          usersObj[user.assignedTo]
            ? usersObj[user.assignedTo].full_name
            : "--",
          user.Market || "--",
          user.MemberEmail || "--",
          user.MemberStateLicense || "--",
          user?.lead_phones[0]?.value || "--",
          user.realtor_tags.map((item, index) => item.tag).join(","),
          user?.agent_gamls_data?.realtor_agent_office?.OfficeName ||
            user?.agent_fmls_data?.realtor_agent_office?.OfficeName ||
            user?.agent_stellar_data?.realtor_agent_office?.OfficeName ||
            user?.agent_miamire_data?.realtor_agent_office?.OfficeName ||
            user?.MemberOfficeName ||
            "--",
          user.lastCommunicationTimestamp
            ? moment(user.lastCommunicationTimestamp).fromNow()
            : "--",
          user.stageName,
          user.totalVolume12Months_STELLAR
            ? parseInt(user.totalVolume12Months_STELLAR)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .slice(0, -3)
            : "--",
          user.totalVolume12Months_CAROLINA
            ? parseInt(user.totalVolume12Months_CAROLINA)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .slice(0, -3)
            : "--",
          user.totalVolume12Months_MIAMIRE
            ? parseInt(user.totalVolume12Months_MIAMIRE)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .slice(0, -3)
            : "--",
          user.totalVolume12Months_FMLS
            ? parseInt(user.totalVolume12Months_FMLS)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .slice(0, -3)
            : "--",
          user.totalVolume12Months_GAMLS
            ? parseInt(user.totalVolume12Months_GAMLS)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .slice(0, -3)
            : "--",
          user.totalVolume12Months_GALMLS
            ? parseInt(user.totalVolume12Months_GALMLS)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .slice(0, -3)
            : "--",
          user.totalSales_FMLS || "--",
          user.totalSales_MIAMIRE || "--",
          user.totalSales_CAROLINA || "--",
          user.totalSales_GAMLS || "--",
          user.totalSales_GALMLS || "--",
          user.activeZips_Stellar
            .map((item, index) => item.postal_code)
            .join(","),
          user.activeZips_FMLS.map((item, index) => item.postal_code).join(","),
          user.activeZips_GAMLS
            .map((item, index) => item.postal_code)
            .join(","),
          user.activeZips_MIAMIRE
            .map((item, index) => item.postal_code)
            .join(","),
          user.activeZips_CAROLINA
            .map((item, index) => item.postal_code)
            .join(","),
          user.activeCities_MIAMIRE.map((item, index) => item.city).join(","),
          user.activeCities_Stellar.map((item, index) => item.city).join(","),
          user.activeCities_FMLS.map((item, index) => item.city).join(","),
          user.activeCities_CAROLINA.map((item, index) => item.city).join(","),
          user.activeCities_GAMLS.map((item, index) => item.city).join(","),
          user.totalSales_STELLAR || "--",
        ];
        users.push(userArray);
      });
      const wb = XLSX.utils.book_new();
      const wsAll = XLSX.utils.aoa_to_sheet(users);
      XLSX.utils.book_append_sheet(wb, wsAll, "All Users");
      XLSX.writeFile(wb, `Recruitment-list-${Date()}.csv`);
    }
  };

  return (
    <Box sx={{ textAlign: "end", margin: "10px" }}>
      {Number(totalAgentsCount || 0) > 25 ? (
        <Button
          disabled={loading}
          onClick={handleExport}
          variant="text"
          sx={{ mr: "10px", textTransform: "capitalize" }}
          startIcon={<SaveAltIcon />}
        >
          Export
        </Button>
      ) : (
        <GridToolbarExport sx={{ mr: "10px" }} />
      )}
      <GridToolbarColumnsButton
        title="Columns"
        startIcon={null}
        sx={{
          padding: "10px 20px",
          background: "black",
          color: "white",
          "&:hover": {
            background: "black",
            color: "white",
          },
        }}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(e) => {
          setFilterButtonEl(e.target);
        }}
      />
      <Backdrop color="inherit" invisible={true} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
