import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { NumberFormat } from "../../../../../../utils/numberFormat";
import moment from "moment-timezone";
function ListingDetailStats(props) {
  let { classes } = props;
  const listDetail = useSelector((state) => state.allCmas.listingDetail);
  const handleCalcDaysOnMarket = () => {
    if (listDetail?.data?.StandardStatus === "Active") {
      let date1 = moment(listDetail?.data?.ListingContractDate);
      let date2 = moment();
      return date2.diff(date1, "days") >= 1
        ? date2.diff(date1, "days")
        : moment(listDetail?.data?.ListingContractDate).fromNow();
    } else if (listDetail?.data?.StandardStatus === "Active Under Contract") {
      let date1 = moment(listDetail?.data?.ListingContractDate);
      let date2 = moment(listDetail?.data?.PurchaseContractDate);
      return date2.diff(date1, "days") >= 1
        ? date2.diff(date1, "days")
        : moment(listDetail?.data?.PurchaseContractDate).fromNow();
    } else if (listDetail?.data?.StandardStatus === "Closed") {
      let date1 = moment(listDetail?.data?.CloseDate);
      let date2 = moment(listDetail?.data?.ListingContractDate);
      return date2.diff(date1, "days") >= 1
        ? date2.diff(date1, "days")
        : moment(listDetail?.data?.ListingContractDate).fromNow();
    } else if (
      listDetail?.data?.StandardStatus === "Withdrawn" ||
      listDetail?.data?.StandardStatus === "Expired"
    ) {
      let date1 = moment(listDetail?.data?.ListingContractDate);
      let date2 = moment(
        listDetail?.data?.OffMarketDate || listDetail?.data?.WithdrawnDate
      );
      return date2.diff(date1, "days") >= 1
        ? date2.diff(date1, "days")
        : moment(
            listDetail?.data?.OffMarketDate || listDetail?.data?.WithdrawnDate
          ).fromNow();
    } else if (listDetail?.data?.StandardStatus === "Coming Soon") {
      return "0";
    } else {
      return "0";
    }
  };
  return (
    <Box className={classes.listingStatsRoot}>
      <Typography className={classes.listingStatsHeading}>
        LISTING UPDATED:{" "}
        {moment(listDetail?.data?.ModificationTimestamp).format("l")}{" "}
        {moment(listDetail?.data?.ModificationTimestamp).format("LT")}
      </Typography>
      <Box className={classes.listingStatsArea}>
        <Box className={classes.listingStatsItem}>
          <Typography className={classes.listingStatsItemLeft}>
            Status
          </Typography>
          <Typography className={classes.listingStatsItemRight}>
            {listDetail?.data?.StandardStatus}
          </Typography>
        </Box>

        {listDetail?.data?.ListingId ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              MLS #
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {listDetail?.data?.ListingId}
            </Typography>
          </Box>
        ) : null}

        {listDetail?.data?.CumulativeDaysOnMarket ||
        listDetail?.data?.DaysOnMarket ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              Days on Site
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {listDetail?.data?.CumulativeDaysOnMarket ||
                listDetail?.data?.DaysOnMarket}
            </Typography>
          </Box>
        ) : (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              Days on Site
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {handleCalcDaysOnMarket()}
            </Typography>
          </Box>
        )}

        {listDetail?.data?.TaxAnnualAmount &&
        Number(listDetail?.data?.TaxAnnualAmount) > 0 ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              Taxes
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {NumberFormat({
                number: listDetail?.data?.TaxAnnualAmount,
                currency: "USD",
              })}{" "}
              / year
            </Typography>
          </Box>
        ) : null}

        {listDetail?.data?.AssociationFee ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              HOA Fees
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {NumberFormat({
                number: listDetail?.data?.AssociationFee,
                currency: "USD",
              })}{" "}
              /{" "}
              {listDetail?.data?.AssociationFeeFrequency === "Annually"
                ? "year"
                : "month"}
            </Typography>
          </Box>
        ) : null}

        {listDetail?.data?.PropertyType || listDetail?.data?.PropertySubType ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              MLS Type
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {listDetail?.data?.PropertyType ||
                listDetail?.data?.PropertySubType}
            </Typography>
          </Box>
        ) : null}

        {listDetail?.data?.YearBuilt ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              Year Built
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {listDetail?.data?.YearBuilt}
            </Typography>
          </Box>
        ) : null}

        {listDetail?.data?.LotSizeAcres &&
        listDetail?.data?.PropertySubType !== "Condominium" ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              Lot Size
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {listDetail?.data?.LotSizeAcres} Acres
            </Typography>
          </Box>
        ) : null}
        {listDetail?.data?.CountyOrParish ? (
          <Box className={classes.listingStatsItem}>
            <Typography className={classes.listingStatsItemLeft}>
              County
            </Typography>
            <Typography className={classes.listingStatsItemRight}>
              {listDetail?.data?.CountyOrParish}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
export default ListingDetailStats;
