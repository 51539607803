import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { dateFormat } from "../../../../utils/dateFormat";
function MemberShipConfirmation(props) {
  let {
    open,
    handleCloseModal,
    classes,
    openConfirmationDetail,
    handleConfirm,
    isLoading,
    setOpenConfirmationDetail,
  } = props;

  const [errMsg, setErrMsg] = useState({});

  const handleUpdateDetail = (field, value) => {
    setOpenConfirmationDetail((detail) => ({ ...detail, [field]: value }));
    setErrMsg({});
  };

  const handleClose = () => {
    handleCloseModal();
  };

  const handleSubmit = () => {
    if (openConfirmationDetail?.isConfirm) {
      handleConfirm();
    } else {
      if (openConfirmationDetail[openConfirmationDetail.field]) {
        handleConfirm();
      } else {
        setErrMsg({
          [openConfirmationDetail.field]: "Please select the date first",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={{
          "& .MuiPaper-root": { overflow: "visible !important" },
          "& .MuiDialog-container": { mt: "30px" },
        }}
      >
        <DialogContent>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: "-15px" }}
          >
            {openConfirmationDetail?.isConfirm ? (
              <Grid item xs={12} sx={{ zIndex: "1", mt: "25px" }}>
                <InputLabel
                  sx={{ textAlign: "center", whiteSpace: "wrap !important" }}
                  className={classes.fieldLabelEnroll}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: openConfirmationDetail?.confirmationText,
                    }}
                  />{" "}
                </InputLabel>
                <br />
                <InputLabel
                  sx={{ textAlign: "center" }}
                  className={classes.fieldLabelEnroll}
                >
                  Please confirm to continue.
                </InputLabel>
              </Grid>
            ) : null}
            {!openConfirmationDetail?.isConfirm ? (
              <Grid item xs={12} sx={{ zIndex: "1", mt: "30px" }}>
                <InputLabel className={classes.fieldLabelEnroll}>
                  {openConfirmationDetail?.fieldLabel}:
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    fullWidth
                    value={
                      openConfirmationDetail[openConfirmationDetail?.field]
                        ? dateFormat(
                            openConfirmationDetail[
                              openConfirmationDetail?.field
                            ]
                          )
                        : null
                    }
                    onChange={(newValue) =>
                      handleUpdateDetail(
                        openConfirmationDetail?.field,
                        newValue
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        fullWidth
                        {...params}
                        error={errMsg[openConfirmationDetail?.field]}
                        helperText={errMsg[openConfirmationDetail?.field]}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: "14px",
            justifyContent: openConfirmationDetail?.isConfirm
              ? "center"
              : "flex-end",
          }}
        >
          <Button onClick={handleClose} variant="outlined" color="inherit">
            Cancel
          </Button>

          <LoadingButton
            onClick={handleSubmit}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={isLoading}
          >
            CONFIRM
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "MemberShipConfirmationStyle" })(
  MemberShipConfirmation
);
