import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const updatePond = createAsyncThunk(
  "Ponds/updatePond",
  async (data, thunkAPI) => {
    const { handleSuccess, handleError, schema, id } = data;
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/leads/ponds/${id}`,
        schema,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.data && handleSuccess) {
        handleSuccess();
      }
      return { ...resp.data, id };
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
