import { createSlice } from "@reduxjs/toolkit";
import { checkoutAgentInventory } from "./checkOutInventory";
import { getAgentInventories } from "./getAgentInventory";
import { getInventoryLogHistory } from "./getLogsHistory";
import { getInventorySpecificLogHistory } from "./getSpecificLogs";
const initialState = {
  checkOut: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  InventoryList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  logsHistory: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  specificLogsHistory: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  comments: [],
  lostMark: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const agentInventory = createSlice({
  name: "agentInventory",
  initialState,
  reducers: {
    handleUpdateSuperWebStatus: (state, action) => {
      let userTabsNew = state.InventoryList.data ? [...state.InventoryList.data] : [];
      let index = userTabsNew.findIndex((item) => item.id === action.payload.id);
      userTabsNew[index].IsSupraWeb = action.payload.IsSupraWeb;
      state.InventoryList.data = userTabsNew;
    },
    handleAddComment: (state, action) => {
      let newComments = [...state.comments];
      newComments.push(action.payload);
      state.comments = newComments;
    },
  },
  extraReducers: {
    [checkoutAgentInventory.pending]: (state) => {
      state.checkOut.isLoading = true;
      state.checkOut.errMsg = null;
      state.checkOut.success = false;
    },
    [checkoutAgentInventory.fulfilled]: (state, action) => {
      state.checkOut.success = true;
      state.checkOut.isLoading = false;
      state.checkOut.data = action.payload;
      if (action.payload) {
        let inventoryD = state.InventoryList.data ? [...state.InventoryList.data] : [];
        inventoryD.push(action.payload);
        state.InventoryList.data = inventoryD;
      }
    },
    [checkoutAgentInventory.rejected]: (state, action) => {
      state.checkOut.isLoading = false;
      state.checkOut.success = false;
      state.checkOut.errMsg = action.payload;
    },
    [getAgentInventories.pending]: (state) => {
      state.InventoryList.isLoading = true;
      state.InventoryList.errMsg = null;
      state.InventoryList.success = false;
    },
    [getAgentInventories.fulfilled]: (state, action) => {
      state.InventoryList.success = true;
      state.InventoryList.isLoading = false;
      state.InventoryList.data = action.payload;
    },
    [getAgentInventories.rejected]: (state, action) => {
      state.InventoryList.isLoading = false;
      state.InventoryList.success = false;
      state.InventoryList.errMsg = action.payload;
    },
    // get overall logs history
    [getInventoryLogHistory.pending]: (state) => {
      state.logsHistory.isLoading = true;
      state.logsHistory.errMsg = null;
      state.logsHistory.success = false;
    },
    [getInventoryLogHistory.fulfilled]: (state, action) => {
      state.logsHistory.success = true;
      state.logsHistory.isLoading = false;
      state.logsHistory.data = action.payload;
    },
    [getInventoryLogHistory.rejected]: (state, action) => {
      state.logsHistory.isLoading = false;
      state.logsHistory.success = false;
      state.logsHistory.errMsg = action.payload;
    },
    // get specific logs history
    [getInventorySpecificLogHistory.pending]: (state) => {
      state.specificLogsHistory.isLoading = true;
      state.specificLogsHistory.errMsg = null;
      state.specificLogsHistory.success = false;
      state.comments = [];
    },
    [getInventorySpecificLogHistory.fulfilled]: (state, action) => {
      state.specificLogsHistory.success = true;
      state.specificLogsHistory.isLoading = false;
      state.specificLogsHistory.data = action.payload.data;
      state.comments = action.payload.comments;
    },
    [getInventorySpecificLogHistory.rejected]: (state, action) => {
      state.specificLogsHistory.isLoading = false;
      state.specificLogsHistory.success = false;
      state.specificLogsHistory.errMsg = action.payload;
    },

  },
});
export const { handleUpdateSuperWebStatus, handleAddComment } = agentInventory.actions;
export default agentInventory.reducer;
export * from "./checkOutInventory";
export * from "./getAgentInventory";
