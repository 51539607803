import { Container } from "@mui/material";
import PointsLeaderBoards from "../agentRoster/agentDetails/tabsContent/leaderBoards";
function DashboardLeaderBoards(props) {
  return (
    <Container maxWidth="xl" sx={{ mt: "40px", mb: "70px" }}>
      <PointsLeaderBoards disableAccess {...props}  />
    </Container>
  );
}
export default DashboardLeaderBoards;
