import { Box, CircularProgress, Typography } from "@mui/material";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', justifyContent: 'center' }}>
          <CircularProgress sx={{ color: "#000" }} variant="determinate" {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
                {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;