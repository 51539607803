import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "@mui/joy/Avatar";

import {
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { calculateDueIn, statusComparator } from "../helpers";

import Avatars from "../../../components/uiElements/avatar/index";
import {
  resetList,
  setSelectedDepartmentID,
} from "../../../redux/projects/common";
import {
  getTaskList,
  setTaskCurrentPageNo,
  setTaskFilterStatus,
} from "../../../redux/projects/tasks";
import DataGridComponent from "../DataGridComponent";
import { openDeliverable } from "../helpers";
import StatusComponent from "../StatusComponent";
import moment from "moment";
import {
  openTaskDetails,
  setSelectedTaskId,
} from "../../../redux/projects/project";
import { useLocation } from "react-router-dom";

var columnWidth = 0;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px auto!important",
    height: "calc(100vh - 350px)",
  },
  detailCardSection: {
    justifyContent: "space-between !important",
    display: "flex!important",
    width: "100%!important",
    marginBottom: "40px!important",
  },
  detailCard: {
    display: "flex!important",
    justifyContent: "unset!important",
    alignItems: "center!important",
    padding: "0px 19px!important",
    gap: "10px !important",
    height: "100px!important",
    width: "220px!important",
  },
  title: {
    fontSize: "30px!important",
    fontWeight: "400 !important",
  },
  desc: {
    fontSize: "14px!important",
    fontWeight: "400 !important",
    color: "rgba(0, 0, 0, 0.6)!important",
    lineHeight: "143%!important",
  },
  avatar: {
    background: "#BDBDBD!important",
    color: "#fff!important",
    marginRight: "10px!important",
  },
  openButton: {
    width: "100%!important",
    border: "1px solid #0B0909!important",
    borderColor: "#0B0909!important",
    color: "#0B0909!important",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
  deliverablesImg: {
    width: "40px",
    height: "50px",
    border: "1px solid #000",
  },
  deliverablesImgContainer: {
    display: "flex",
    gap: "7px",
  },
}));

const xgriColumns = [
  {
    field: "task",
    headerName: "Task",
    sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    renderCell: (params) => {
      return (
        <div>
          <span
            style={{
              fontWeight: "700",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
              letterSpacing: "0.5px",
            }}
          >
            {params.value.name}
          </span>
          <span
            style={{
              fontWeight: "400",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
            }}
          >
            {params.value.title}
          </span>
        </div>
      );
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
  {
    field: "agent",
    headerName: "Agent",
    sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
    renderCell: (params) => {
      return (
        <ListItem
          alignItems="flex-start"
          sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
        >
          <ListItemAvatar>
            <Avatars
              sx={{ marginRight: "10px", borderRadius: "4px" }}
              src={params.value.image}
              variant="square"
            >{`${params.value.full_name?.slice(0, 1)}${params.value.full_name
              ?.split(" ")[1]
              ?.slice(0, 1)}`}</Avatars>
          </ListItemAvatar>
          <ListItemText
            primary={params.value.first_name}
            secondary={
              <React.Fragment>{params.value.last_name}</React.Fragment>
            }
          />
        </ListItem>
      );
    },
    width: columnWidth * 20 + 200,
    sortable: true,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    sortComparator: statusComparator,
    type: "string",
    renderCell: (params) => {
      return <StatusComponent status={params.value} />;
    },
    minWidth: 130,
    sortable: true,
    filterable: true,
  },
  {
    field: "dueIn",
    headerName: "Due In",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    minWidth: 130,
    sortable: false,
    filterable: false,
  },
  {
    field: "teamMember",
    headerName: "Team Member",
    renderCell: (params) => {
      if (params.value) {
        return (
          <>
            <Avatars
              alt="Team Member"
              src={params.value.src}
              title={params.value.title}
            >
              {`${params.value.title?.slice(0, 1)}${params.value.title
                ?.split(" ")[1]
                ?.slice(0, 1)}`}
            </Avatars>
          </>
        );
      } else {
        return "";
      }
    },
    flex: 1,
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "created",
    headerName: "Created At",
    renderCell: (params) => {
      return <>{moment(params.value || new Date()).format("MM/DD/YY H:MMa")}</>;
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
];

function TaskGrid(props) {
  /**
   * Variables and States
   */
  const { departmentID } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([...xgriColumns]);
  const [dataGridRowHeight] = React.useState(52);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [firstFilterModelChanged, setFirstFilterModelChanged] =
    React.useState(false);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const tasks = useSelector((state) => state.project.tasks);
  const totalTasks = useSelector((state) => state.project.totalTasks);
  const tasksLoading = useSelector((state) => state.project.tasksLoading);
  const searchText = useSelector((state) => state.project.searchText);
  const pageNo = useSelector((state) => state.project.tasksCurrentPageNo);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenTask = (id) => {
    dispatch(setSelectedTaskId(id));
    dispatch(openTaskDetails(true));
  };

  const handleRowScrollEnd = () => {
    if (rows.length > 0 && rows.length < totalTasks) {
      let updatedPageNo = pageNo + 1;
      dispatch(setTaskCurrentPageNo(updatedPageNo));
      setTimeout(() => {
        dispatch(getTaskList({ departmentID: props.departmentID }));
      }, 500);
    }
  };

  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  React.useEffect(() => {
    if (departmentID) dispatch(setSelectedDepartmentID(departmentID));

    dispatch(setTaskCurrentPageNo(1));
    setTimeout(() => {
      dispatch(getTaskList({ departmentID }));
    }, 500);
    setFirstLoad(false);
    setFirstFilterModelChanged(false);
    const MINUTE_MS = 80000;
    const interval = setInterval(() => {
      dispatch(setTaskCurrentPageNo(1));
      setTimeout(() => {
        dispatch(getTaskList({ departmentID }));
      }, 500);
    }, MINUTE_MS);

    return () => {
      setFilterModel({
        items: [],
      });

      dispatch(resetList());
      clearInterval(interval);
      dispatch(setTaskCurrentPageNo(1));
      dispatch(setTaskFilterStatus(""));
    };
  }, [searchText]);

  React.useEffect(() => {
    if (!firstLoad) {
      dispatch(setTaskCurrentPageNo(1));
      setTimeout(() => {
        dispatch(getTaskList({ departmentID }));
      }, 500);
    }
  }, [filterModel]);

  React.useEffect(() => {
    const t = [];
    tasks?.map((d, i) => {
      let dueIn = calculateDueIn(d.project.due_date);

      let teamMember = null;

      if (d.team_member) {
        teamMember = {
          src: d.team_member.profile_images
            ? d.team_member.profile_images.profile_img_thumbnail
            : null,
          title: d.team_member.full_name,
        };
      }

      // saving agent value length to calculate agent column width
      columnWidth =
        d?.agent?.full_name?.length > columnWidth
          ? d?.agent?.full_name?.length
          : columnWidth;
      let task = {
        id: d.id,
        projectID: d.project_id,
        agentID: d.agent_id,
        status: d.status,
        agent: {
          image: d?.agent?.profile_images?.profile_img_thumbnail || null,
          last_name: d?.agent?.last_name,
          first_name: d?.agent?.first_name,
          full_name: d?.agent?.full_name,
        },
        task: {
          name: d?.task_name,
          title: d.project?.title || "",
        },
        attachments: d.task_deliverables || [],
        dueIn,
        teamMember,
        created: d.createdAt,
      };

      t.push(task);
    });
    setRows(t);

    let cols = [...columns];
    // Adding action colum to grid only when projects are available in the state
    if (cols.length === 5 && tasks.length > 0) {
      // dynamic agent column width
      cols.map((col) => {
        if (col.field === "agent") {
          col.width = columnWidth * 9 + 50;
        }

        return col;
      });

      cols.push({
        field: "attachments",
        headerName: "Attachments",
        sortable: false,
        renderCell: (params) => {
          return (
            <div className={classes.deliverablesImgContainer}>
              {params.value.map((file) => (
                <img
                  onClick={() => openDeliverable(file)}
                  className={classes.deliverablesImg}
                  alt="task_deliverable"
                  src={file.thumbnail}
                />
              ))}
            </div>
          );
        },
        flex: 1,
      });
    }

    setColumns(cols);
  }, [tasks]);

  React.useEffect(() => {
    if (state?.task_id) {
      handleOpenTask(state?.task_id);
    }
  }, [state?.project_id]);

  /**
   * End Region: useEffect Hooks
   */
  return (
    <>
      <Container className={classes.detailCardSection} maxWidth="lg">
        <Card className={classes.detailCard}>
          <div>
            <Avatar className={classes.avatar}> OP</Avatar>
          </div>

          <div>
            <Typography className={classes.title}>0s</Typography>
            <Typography className={classes.desc}>Active Tasks</Typography>
          </div>
        </Card>

        <Card className={classes.detailCard}>
          <div>
            <Avatar className={classes.avatar}>OP</Avatar>
          </div>

          <div>
            <Typography className={classes.title}>0s</Typography>
            <Typography className={classes.desc}>Outstanding Today</Typography>
          </div>
        </Card>

        <Card className={classes.detailCard}>
          <div>
            <Avatar className={classes.avatar}> OP</Avatar>
          </div>

          <div>
            <Typography className={classes.title}>0s</Typography>
            <Typography className={classes.desc}>Completed Today</Typography>
          </div>
        </Card>

        <Card className={classes.detailCard}>
          <div>
            <Avatar className={classes.avatar}> OP</Avatar>
          </div>

          <div>
            <Typography className={classes.title}>0s</Typography>
            <Typography className={classes.desc}>Overdue</Typography>
          </div>
        </Card>

        <Card className={classes.detailCard}>
          <div>
            <Avatar className={classes.avatar}> OP</Avatar>
          </div>

          <div>
            <Typography className={classes.title}>00:00</Typography>
            <Typography className={classes.desc}>Avg Time Per Task</Typography>
          </div>
        </Card>
      </Container>

      <Container className={classes.container} maxWidth="lg">
        <DataGridComponent
          rows={rows}
          rowHeight={dataGridRowHeight}
          columns={columns}
          loading={tasksLoading}
          rowCount={totalTasks}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [
                { field: "status", sort: "asc" },
                { field: "created", sort: "desc" },
              ],
            },
            filter: {
              filterModel: { ...filterModel },
            },
          }}
          onRowsScrollEnd={handleRowScrollEnd}
          filterMode="server"
          onFilterModelChange={(fm) => {
            if (firstFilterModelChanged) {
              if (fm.items.length > 0) {
                dispatch(setTaskFilterStatus(fm.items[0].value || ""));
                setFilterModel(fm);
              } else {
                dispatch(setTaskFilterStatus(""));
                setFilterModel({ items: [] });
              }
            } else {
              setFirstFilterModelChanged(true);
            }
          }}
          filterModel={filterModel}
          onCellClick={(row) => handleOpenTask(row.id)}
        />
      </Container>
    </>
  );
}

export default TaskGrid;
