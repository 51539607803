export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "30px 30px 9px 30px",
    marginBottom: "16px",
  },
  pageRootTraining: {
    padding: "10px",
    marginBottom: "16px",
  },
  pageRootInventory: {
    padding: "5px",
    marginBottom: "16px",
  },
  pageRootInventoryBottom: {
    padding: "20px",
    marginBottom: "16px",
  },
  pageRootPayment: {
    marginBottom: "16px",
    padding: "5px",
  },
  overViewTabCardMain: {
    marginBottom: "16px",
    padding: "10px 20px 15px 20px",
  },
  overViewTabCard: {
    padding: "15px",
    marginBottom: "20px",
  },
  overViewTabCardRightTop: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    height: "60px",
    fontFamily: "AvenirNext  !important",
  },
  overViewTabCardRightBottom: {
    fontSize: "40px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    textAlign: "right",
    fontFamily: "AvenirNext  !important",
  },
  overViewTabCardHeading: {
    fontSize: "24px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  overViewTabCardDes: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)  !important",
  },
  overViewTabCardDesBottom: {
    paddingBottom: "0px  !important",
    "& .MuiTypography-root": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      textTransform: "capitalize !important",
      color: "rgba(0, 0, 0, 0.87)  !important",
    },
  },
  pageHeading: {
    fontSize: "26px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  agentInventoryCardDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentInventoryCardContentDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)  !important",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "-10px",
    marginBottom: "-10px",
  },
  agentInventoryCardHeader: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addAccountButton: {
    color: "white !important",
    background: "#0B0909  !important",
    fontSize: "12px  !important",
    textTransform: "capitalize !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    marginTop: "20px",
    backgroundColor: "white",
    "& .MuiDataGrid-root": {
      borderColor: "white !important",
    },
  },
  deleteButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  historyLogsTime: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    whiteSpace: "nowrap !important",
    width: "155px !important",
    marginLeft: "15px",
  },

  historyLogsDetail: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
  },
  importantNotesCard: {
    marginBottom: "20px",
    padding: "0px 15px",
    border: "1px solid rgba(33, 33, 33, 0.4)  !important",
  },
  importantNotesHeader: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    fontFamily: "AvenirNext  !important",
    maxWidth: "40ch !important",
    textOverflow: "ellipsis !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
  },
  importantNotesDes: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    marginTop: "10px  !important",
  },
  importantNotesContent: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.6) !important",
    padding: "0px  !important",
  },
  noteDatesText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    marginLeft: "-7px !important",
  },
  trainingStageDropdown: {
    minWidth: "190px !important",
    borderRadius: "5px !important",
    backgroundColor: "#ECECEC !important",
    fontFamily: "AvenirNext  !important",
    outline: "none !important",
    border: "none !important",
    "& .MuiSelect-select": {
      fontFamily: "AvenirNext  !important",
      color: "black !important",
      fontSize: "14px !important",
      fontWeight: "500 !important",
      border: "none !important",
      outline: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      border: "none !important",
      outline: "none !important",
      borderRadius: "5px !important",
      "&.Mui-focused": {
        borderColor: "#ECECEC", // Change this to the color you want
      },
    },
  },
  enrollButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    marginRight: "10px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    borderRadius: "5px !important",
  },
  enrolledBadgeArea: {
    borderRadius: "7px !important",
    backgroundColor: "#F0F0F0 !important",
    minWidth: "145px !important",
    padding: "8px 15px !important",
  },
  enrolledBadgeHeading: {
    fontFamily: "AvenirNext  !important",
    color: "black !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textAlign: "center !important",
  },
  enrolledBadgeContent: {
    fontFamily: "AvenirNext  !important",
    color: "rgba(0, 0, 0, 0.50) !important",
    fontSize: "11px !important",
    fontWeight: "500 !important",
    textAlign: "center !important",
  },
  mentorSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 20px !important",
    marginBottom: "20px !important",
  },
  mentorSectionLabel: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "15px !important",
    fontWeight: "500 !important",
    minWidth: "60px !important",
  },
  mentorSectionBadge: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 10px !important",
    borderRadius: "7px !important",
    backgroundColor: "#F0F0F0 !important",
    padding: "8px !important",
    minWidth: "191px !important",
    cursor: "pointer !important",
  },
  mentorSectionBadgeContent: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
  },
  mentorSectionLabelPost: {
    color: "rgba(0, 0, 0, 0.40) !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
  },
});
