import React, { useEffect, useState } from "react";
import { Grid, Container, useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isArrayCheck } from "../../../utils/helper.js";
import {
  getKnowledgeBasePostDetail,
  getAllKnowledgeBasedDataList,
} from "../../../redux/knowledge/knowledgeSearch.js";
import KnowledgeDetailSkelton from "../skelton/knowledgeDetail.js";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import momentTZ from "moment-timezone";
import { Helmet } from "react-helmet";

function KnowledgeBaseDetail(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const above800pxScreen = useMediaQuery("(min-width:800px)");
  const isMobileView = useMediaQuery("(max-width:465px)");
  const { allKnowledgeBaseData } = useSelector(
    (state) => state.knowledgeBase.search
  );
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const postDetail = useSelector(
    (state) => state.knowledgeBase.search.postDetail
  );
  const adminAuth = useSelector((state) => state.adminAuth);
  const { recentlyAdded, categories } = allKnowledgeBaseData;
  const [recordId, setRecordId] = useState(null);

  useEffect(() => {
    if (!postDetail?.data?.id || postDetail?.data?.id !== id) {
      dispatch(getKnowledgeBasePostDetail({ id }));
    }
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (!recentlyAdded || !isArrayCheck(recentlyAdded)) {
      dispatch(getAllKnowledgeBasedDataList());
    }
  }, []);
  const sendProgress = async (progress) => {
    try {
      const formData = {
        post_id: postDetail?.data?.id,
        progress,
        progress_record_id: recordId,
      };
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL +
          "api/knowledgebase/save-video-progress",
        formData
      );
      if (response.data && response.data.status === 200) {
        setRecordId(response.data.data.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{postDetail?.data?.title}</title>
        <meta name="description" content={postDetail?.data?.body} />
        <link
          rel="canonical"
          href={`https://app.ourmethod.com/knowledge-base/main/${id}`}
        />
        {/* Open Graph meta tags */}
        <meta property="og:title" content={postDetail?.data?.title} />
        <meta property="og:description" content={postDetail?.data?.body} />
        <meta property="og:image" content={postDetail?.data?.thumbnail} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={postDetail?.data?.body} />
        <meta property="og:image" content={postDetail?.data?.thumbnail} />
      </Helmet>
      {postDetail.isLoading ? (
        <KnowledgeDetailSkelton />
      ) : (
        <div className={classes.mainDashboardPage}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper elevation={2} className={classes.methodWeatherBox}>
                <div className={classes.methodWeatherTop}>
                  <h1 className={classes.methodWeatherTopH1}>
                    {postDetail?.data?.title}
                  </h1>
                </div>
                <Box sx={{ flexGrow: 1, mt: 4 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={10}
                      height={
                        postDetail?.data?.type === "document"
                          ? "1000px"
                          : above800pxScreen
                          ? "600px"
                          : isMobileView
                          ? "350px"
                          : "450px"
                      }
                    >
                      <React.Fragment key={id}>
                        {postDetail?.data?.type === "document" ? (
                          <iframe
                            title="PDF Viewer"
                            src={postDetail?.data?.document}
                            width="100%"
                            height="900px"
                            frameBorder="0"
                          ></iframe>
                        ) : (
                          <ReactPlayer
                            url={postDetail?.data?.video_src}
                            light={postDetail?.data?.thumbnail}
                            height="100%"
                            width="100%"
                            controls={true}
                            progressInterval={10000}
                            onProgress={(e) => {
                              sendProgress(e.played * 100);
                            }}
                            onEnded={(e) => {
                              sendProgress(100);
                            }}
                            config={
                              adminAuth?.adminDetail?.role?.name !== "Admin"
                                ? {
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }
                                : {}
                            }
                          />
                        )}
                      </React.Fragment>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={8} xl={8}>
              <Paper className={classes.descriptionBox}>
                <h1 className={classes.descriptionBoxH1}>Description</h1>
                <p className={classes.methodWeatherTopP}>
                  {postDetail?.data?.body}
                </p>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <Paper elevation={2} className={classes.methodWeatherBox}>
                <Grid container spacing={2} marginBottom="10px">
                  {isArrayCheck(recentlyAdded) &&
                    recentlyAdded.map((data) => (
                      <Grid
                        className={classes.mainCardArea}
                        item
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                      >
                        <div
                          className={classes.videoTextImg}
                          onClick={() =>
                            navigate("/knowledge-base/main/" + data?.id, {
                              state: data,
                            })
                          }
                        >
                          <img
                            alt="knowledge-base-course"
                            className={classes.imgCourses}
                            src={data?.thumbnail}
                          />
                          <div
                            className={`${classes.upperHoverUI} hoveredBlock IsHovered`}
                          >
                            <div style={{ width: "100%" }}>
                              <div className={classes.hoverText}>
                                <h4 className={classes.hoverTextMargin}>
                                  {data?.title}
                                </h4>
                                <p className={classes.customListingChip}>
                                  {categories.find(
                                    (it) => it.id === data?.category_id
                                  )?.title || "NA"}
                                </p>
                              </div>
                              <div className={classes.hoverText2}>
                                <p className={classes.hoverTextMargin}>
                                  By:{" "}
                                  {data?.author_id
                                    ? agentRosterList?.list?.find(
                                        (item) => item.id === data?.author_id
                                      )?.full_name || ""
                                    : ""}
                                </p>
                                <p className={classes.hoverTextMargin}>
                                  Added on:{" "}
                                  {momentTZ
                                    .tz(data.createdAt, "America/New_York")
                                    ?.format("L")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}

export default withStyles(styleSheet, { name: "KnowledgeBaseStyle" })(
  KnowledgeBaseDetail
);
