import { createSlice } from "@reduxjs/toolkit";
import { getLeadStageListAPI } from "./getLeadStageListAPI";

const initialState = {
  data: [],
  status: {
    pending: false,
    success: false,
    error: false,
  },
};

const leadStageList = createSlice({
  name: "leadStageList",
  initialState,
  extraReducers: {
    [getLeadStageListAPI.pending]: (state) => {
      state.status.pending = true;
    },
    [getLeadStageListAPI.fulfilled]: (state, action) => {
      if (action?.payload?.stages) {
        state.data = action.payload.stages;
      }
      state.status.pending = false;
    },
    [getLeadStageListAPI.rejected]: (state) => {
      state.status.pending = false;
      state.status.error = true;
    },
  },
});

export default leadStageList.reducer;
