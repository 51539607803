import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  list: [],
  vendorList: [],
  teamsList: [],
  count: 0,
  errMsg: null,
  usersObj: {},
  vendorsObj: {},
};

export const getAllUsersTeamsListToUseInAllSections = createAsyncThunk(
  "agentList/getAllUsersTeamsListToUseInAllSections",
  async (thunkAPI) => {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/users/get-all-users-and-teams`, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const storeAllUsersList = createSlice({
  name: "storeAllUsersList",
  initialState,
  extraReducers: {
    [getAllUsersTeamsListToUseInAllSections.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsersTeamsListToUseInAllSections.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.data.users;
      state.usersObj = action.payload.data.users.reduce((prev, next) => {
        prev[next.id] = next;
        return prev;
      }, {});
      state.vendorsObj = action.payload.data.allVendors.reduce((prev, next) => {
        prev[next.id] = next;
        return prev;
      }, {});

      state.teamsList = action.payload.data.teams;
      state.vendorList = action.payload.data.allVendors;
      state.totalAgents = action.payload.count;
    },
    [getAllUsersTeamsListToUseInAllSections.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default storeAllUsersList.reducer;
