import React from "react";
import { List, ListItem, ListItemAvatar, ListItemText ,Box,Chip,Typography} from "@mui/material";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import moment from 'moment'
import Avatar from "../../../../components/uiElements/avatar/index";
// Name Cell Component
export const NameCard = ({ first_name, last_name, src }) => {
  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          {src ? (
            <Avatar alt="Cindy Baker" variant="rounded" src={src} />
          ) : (
            <Avatar alt={`${first_name} ${last_name}`} sx={{textTransform:'uppercase',fontSize:'15px'}} variant="rounded">
           {`${first_name[0]}${last_name[0]}`}
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText primary={first_name} secondary={<React.Fragment>{last_name}</React.Fragment>} />
      </ListItem>
    </List>
  );
};

export const UserRole = ({ role }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: "5px",
      }}
    >
      <AdminPanelSettingsIcon  sx={{color:"rgba(0, 0, 0, 0.54)"}} /> {role}
    </div>
  );
};

export const TeamCell = ({ name, color, join_date }) => {
  return (
    <Box>
      <Chip size="small" label={name} style={{ background: color, color: "white" }} />
      <Typography marginLeft={1} fontSize={12}>
        {join_date ? moment(join_date).format("[Expires ] MMM 'DD") : "--"}
      </Typography>
    </Box>
  );
};
