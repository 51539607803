import React, { useEffect, useState } from "react";
import { Grid, Card, CardHeader, Avatar, Stack, Button } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LinkPeople from "../../modals/linkPeople";
import { AddLink } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddEditPeople from "../../modals/addEditPeople";
import {  selectedEmailList } from "../../../../redux/agents/getAgentProfile";
import { useSelector,useDispatch } from "react-redux";
function TransactionsDetailImportantPeoples(props) {
  const navigate = useNavigate();

  const { classes, ContractData, disableCard, disableTitle, md,allowEmailClick } = props;
  
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('Add');
  const [selectedPeopleToEdit, setSelectedPeopleToEdit] = useState({});
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [importantPeople, setImportantPeople] = React.useState([]);
const dispatch=useDispatch()
const {
  agentProfile: { toEmailList },
} = useSelector((item) => item.agentProfile);
  const goToLeadDetailsPage = (item) => navigate(`/leads/people/details/${item.personId}/`);

  const handleClickAttachPersonID = (e, selectedPeople) => {
    setSelectedItem(selectedPeople);
  };

  const openEditPeopleModal = (selectedPeople) => {
    setMode('Edit');
    setSelectedPeopleToEdit(selectedPeople);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setMode('Add');
    setSelectedPeopleToEdit({});
  }

  useEffect(() => {
    if (selectedItem) setOpenLinkModal(true);
  }, [selectedItem]);

  useEffect(() => {
    if (openLinkModal === false) setOpenLinkModal(null);
  }, [openLinkModal]);

  useEffect(() => {
    const people = [
      {
        personId: ContractData.buyer0_personId,
        name: `${ContractData.buyer0_first_name} ${ContractData.buyer0_last_name}`,
        type: "Buyer 1",
        first_name: ContractData.buyer0_first_name,
        last_name: ContractData.buyer0_last_name,
        phone: ContractData.buyer0_phone,
        email: ContractData.buyer0_email,
        entity_name: ContractData.buyer0_entity_name,
        middle_initials: ContractData.buyer0_middle_initials,
      },
      {
        personId: ContractData.buyer1_personId,
        name: `${ContractData.buyer1_first_name} ${ContractData.buyer1_last_name}`,
        type: "Buyer 2",
        first_name: ContractData.buyer1_first_name,
        last_name: ContractData.buyer1_last_name,
        phone: ContractData.buyer1_phone,
        email: ContractData.buyer1_email,
        entity_name: ContractData.buyer1_entity_name,
        middle_initials: ContractData.buyer1_middle_initials,
      },
      {
        personId: ContractData.co_op_agent_id,
        name: `${ContractData.co_op_first_name} ${ContractData.co_op_last_name}`,
        type: "Coop Agent",
        first_name: ContractData.co_op_first_name,
        last_name: ContractData.co_op_last_name,
        phone: ContractData.co_op_phone,
        email: ContractData.co_op_email,
      },
      {
        personId: ContractData.seller0_personId,
        name: `${ContractData.seller0_first_name} ${ContractData.seller0_last_name}`,
        type: "Seller 1",
        first_name: ContractData.seller0_first_name,
        last_name: ContractData.seller0_last_name,
        phone: ContractData.seller0_phone,
        email: ContractData.seller0_email,
        entity_name: ContractData.seller0_entity_name,
        middle_initials: ContractData.seller0_middle_initials,
      },
      {
        personId: ContractData.seller1_personId,
        name: `${ContractData.seller1_first_name} ${ContractData.seller1_last_name}`,
        type: "Seller 2",
        first_name: ContractData.seller1_first_name,
        last_name: ContractData.seller1_last_name,
        phone: ContractData.seller1_phone,
        email: ContractData.seller1_email,
        entity_name: ContractData.seller1_entity_name,
        middle_initials: ContractData.seller1_middle_initials,
      },
    ];
    setImportantPeople(people.filter((p) => p.first_name !== null && p.last_name !== null));
  }, [ContractData]);

  const handleClickEmail=(data)=>{
  if(allowEmailClick && !toEmailList.find((item)=>item.email===data.email)){
    let newEmails=[...toEmailList]
   let obj= {
      name: data.name,
      email: data.email,
      picture: "",
      filterFlag:true
    }; 
    newEmails.push(obj)
    let uniqeEmail=newEmails.filter((item,index)=>newEmails.findIndex((it)=>it.email===item.email)==index)
    dispatch(selectedEmailList(uniqeEmail))
  }
  }

  return (
    <Card elevation={disableCard ? 0 : 2} className={classes.overViewTabCardMain}>
      
      <AddEditPeople 
        open={open} 
        importantPeople={importantPeople} 
        closeModal={closeModal} 
        ContractData={ContractData} 
        mode={mode} 
        selectedPeopleToEdit={selectedPeopleToEdit}
      />

      <LinkPeople
        open={openLinkModal}
        importantPeople={importantPeople}
        setOpen={setOpenLinkModal}
        ContractData={ContractData}
        position={selectedItem?.type}
      />

      <CardHeader
        action={
          md ? null : (
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#0B0909",
                "&:hover": { backgroundColor: "#0f0a0a" },
                color: "white",
                fontWeight: "400",
                mr: "10px",
                mt:"10px"
              }}
              onClick={() => setOpen(true)}
            >
              Add People
            </Button>
          )
        }
        classes={{ title: classes.overViewTabCardHeading }}
        title={disableTitle ? "" : "Important People"}
        sx={{padding:"15px 0px"}}
      />
      <Grid container spacing={2} direction="row">
        {importantPeople.map((item, index) => {
          return (
            <Grid key={index} item md={md ? md : 6}>
              <Card 
                className={classes.overViewTabCardPeoples} 
              >
                <CardHeader
                  classes={{
                    title: md?classes.importantPeopleNameMD:classes.importantPeopleName,
                    subheader: classes.importantPeoplesDes,
                  }}
                  avatar={
                    <Avatar src="" alt="OP" sx={{ width: 34, height: 34, textTransform: "uppercase", fontSize: "15px" }}>
                      {
                        item.entity_name? item.entity_name?.split(" ")[0]?.slice(0, 1) + item.entity_name?.split(" ")[1]?.slice(0, 1)
                        : item.name? item.name?.split(" ")[0]?.slice(0, 1) + item.name?.split(" ")[1]?.slice(0, 1) : ''
                      } 
                    </Avatar>
                  }
                  title={
                    <p 
                      onClick={(e) => (item.personId === 0 || item.personId === null) && openEditPeopleModal(item)}
                      style={{ margin: '0px', padding: '0px', cursor: (item.personId === 0 || item.personId === null)? 'pointer' : '' }}
                    >
                     {item.entity_name || item.name}
                    </p>
                  }
                  subheader={item.type}
                  action={
                    <Stack
                      direction="row"
                      classes={{ root: classes.overViewTabCardDesBottom }}
                      className={classes.overViewTabCardDesBottom}
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button variant="outlined">
                        <PhoneIcon sx={{ fontSize: "17px" }} />
                      </Button>
                      <Button onClick={()=>handleClickEmail(item)} variant="outlined">
                        <AlternateEmailRoundedIcon sx={{ fontSize: "17px" }} />
                      </Button>
                      {(item.personId === 0 || item.personId === null) && (
                        <Button variant="outlined" onClick={(e) => handleClickAttachPersonID(e, item)}>
                          <AddLink sx={{ fontSize: "17px" }} />
                        </Button>
                      )}

                      {item.personId !== null && item.personId !== 0 && (
                        <Button variant="outlined" onClick={() => goToLeadDetailsPage(item)} >
                          <RemoveRedEyeIcon sx={{ fontSize: "17px" }} />
                        </Button>
                      )}

                    </Stack>
                  }
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailImportantNotesStyleStyle" })(TransactionsDetailImportantPeoples);
