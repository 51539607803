export const styleSheet = () => ({
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  agentTableHeading:{
    fontSize:'16px !important',
    fontWeight:'500 !important',
    textTransform:'capitalize'
  }
});
