import React from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import PrintContent from "./printSection";
function MarketingPrint(props) {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<PrintContent {...props} />} />

      </Routes>
    </Box>
  );
}

export default MarketingPrint;
