import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, MenuItem } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import { rejectDocument } from "../../../redux/transactions/rejectDocument";
function RejectDoc(props) {
  let { open, setOpen, classes, docDetail, handleRemoveDoc, contractIndex } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [reason, setReason] = useState("");

  const dispatch = useDispatch();
  const rejectDoc = useSelector((item) => item.transactions.TransactionsContract.rejectDoc);
  useEffect(() => {
    if (rejectDoc.success && !rejectDoc.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully rejected the document",
        errorType: "success",
        isOpen: true,
      });
      setReason("");
      setOpen(false);
      if (handleRemoveDoc) {
        handleRemoveDoc(docDetail.document_id, contractIndex);
      }
    } else if (!rejectDoc.isLoading && rejectDoc.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(rejectDoc.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [rejectDoc.success]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!reason) {
      isFormValid = false;
      errorMsg["reason"] = "Please enter the reason to reject document";
      setErrorAlert({
        errorMsg: "Please enter the reason to reject document",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleRejectDoc = () => {
    if (handleValidate()) {
      dispatch(rejectDocument({ reason: reason, id: docDetail.id }));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-title">
          Reject Document
        </DialogTitle>
        <DialogContent sx={{ width: "430px", minHeight: "20vh" }}>
          Are you sure to reject this document? if yes then please enter the reason below
          <br />
          <br />
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <TextField
                fullWidth
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                  setErrMsg({});
                }}
                error={errMsg.reason}
                classes={{ root: classes.detailInput }}
                placeholder={"Reject reason"}
                label="Reason"
                helperText={errMsg.reason}
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleRejectDoc()}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={rejectDoc.isLoading}
            loadingPosition="start"
          >
            Reject
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "CloseContractStyle" })(RejectDoc);
