import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Typography, Box, Divider, TextareaAutosize, Button, Chip, AvatarGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";

import AvatarComponent from "../../AvatarComponent";
import { calculateDueIn, capitalize, openDeliverable, statusColor } from "../../helpers";
import { getProject, saveProjectDataFields } from "../../../../redux/projects/projects";
import { saveProjectTaskPublicComments } from "../../../../redux/projects/common";
import DataGridComponent from "../../DataGridComponent";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "40px 30px!important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  detailsHeader: {
    fontSize: "24px!important",
    fontWeight: "400!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  detailsHeaderDueIN: {
    fontSize: "22px!important",
    fontWeight: "400!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  detailsHeaderTask: {
    fontSize: "22px!important",
    fontWeight: "600!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  otherHeader: {
    fontWeight: "500!important",
    fontSize: "20px!important",
    fontFamily: "AvenirNext !important",
  },
  otherSubHeader: {
    fontWeight: "400!important",
    fontSize: "13px!important",
    fontFamily: "AvenirNext !important",
  },
  button: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    height: "37px!important",
    width: "100%!important",
    marginRight: "10px!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      background: "#0B0909!important",
    },
  },
  cancelButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    height: "37px!important",
    width: "100%!important",
    marginRight: "10px!important",
    marginTop: "5px!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      background: "#0B0909!important",
    },
  },
  historyButton: {
    width: "100%!important",
    marginTop: "5px!important",
    fontSize: "12px!important",
    color: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    textTransform: "capitalize !important",
  },
  closeButton: {
    width: "100%!important",
    borderColor: "#0B0909!important",
    color: "#0B0909!important",
    marginTop: "5px!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
  textArea: {
    width: "100%!important",
    padding: "10px!important",
    height: "120px!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "4px!important",
    fontWeight: "400!important",
    fontSize: "14px!important",
    lineHeight: "24px!important",
    letterSpacing: "0.15px!important",
  },
  deliverablesImg: {
    width: "55px",
    height: "65px",
    border: "1px solid #000",
    cursor: "pointer",
  },
  deliverablesImgContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
}));

const xgriColumns = [
  {
    field: "task",
    headerName: "Task",
    renderCell: (params) => {
      return (
        <div>
          <span style={{ fontWeight: "700", display: "block", fontSize: "14px", lineHeight: "20.02px", letterSpacing: "0.5px" }}>
            {params.value.name}
          </span>
          <span style={{ fontWeight: "400", display: "block", fontSize: "14px", lineHeight: "20.02px" }}>{params.value.address}</span>
        </div>
      );
    },
    width: 280,
    sortable: true,
  },
  {
    field: "comment",
    headerName: "Comments/Notes",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    width: 300,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      return (
        <Chip
          sx={{
            "& .MuiChip-label ": {
              color: "#fff",
              fontSize: "15px",
              width: "180px",
              textAlign: "center",
            },
            background: `${statusColor(params.value ? capitalize(params.value) : "")}`,
          }}
          label={params.value ? capitalize(params.value.toLowerCase() === "onhold" ? "On Hold" : params.value) : ""}
        />
      );
    },
    width: 150,
    sortable: true,
  },
  {
    field: "teamMember",
    headerName: "Team Member",
    renderCell: (params) => {
      if (params.value) {
        return (
          <>
            <AvatarComponent alt="Team Member" src={params.value.src} title={params.value.title} nameInitial={params.value.title} />
          </>
        );
      } else {
        return "";
      }
    },
    width: 100,
  },
];

export default function ProjectDetails(props) {
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollRef = React.useRef();

  const { open, setOpen, projectID } = props;
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([...xgriColumns]);
  const [dataGridRowHeight, setDataGridRowHeight] = React.useState(55);
  const [noteComment, setNoteComment] = React.useState({ value: "" });
  const [staffNote, setStaffNote] = React.useState("");
  const [publicNote, setPublicNote] = React.useState("");
  const [firstLoad, setFirstLoad] = React.useState(true);

  const project = useSelector((state) => state.project.project);
  const projectLoading = useSelector((state) => state.project.projectLoading);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleClose = () => {
    setOpen(false);
  };
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  React.useEffect(() => {
    setNoteComment({ value: "" });
    setStaffNote("");
    setPublicNote("");
    dispatch(getProject(projectID));
  }, [projectID]);

  React.useEffect(() => {
    const t = [];
    console.log(project);

    project.project_other_data?.filter((dataField) => {
      if (dataField.data_field.name === "Notes/Comments") {
        setNoteComment(dataField);
      }
    });

    setPublicNote(project.public_notes || "");

    project.project_tasks?.map((d, i) => {
      let teamMember = null;

      if (d.task_deliverables.length > 0) {
        setDataGridRowHeight(75 * Math.ceil(d.task_deliverables.length / 4));
      }

      if (d.team_member) {
        teamMember = {
          src: d.team_member?.profile_images?.profile_img_thumbnail,
          title: d.team_member.full_name,
        };
      }

      let task = {
        id: d.id,
        projectID: d.project_id,
        agentID: d.agent_id,
        status: d.status,
        agent: {
          name: d.agent?.full_name,
          image: d.agent?.profile_images?.profile_img_thumbnail || null,
        },
        comment: d.staff_notes,
        task: {
          name: d?.task_name,
          address: project.title,
        },
        attachments: d.task_deliverables || [],
        teamMember,
      };

      t.push(task);
    });
    setRows(t);

    let cols = [...columns];
    // Adding action colum to grid only when projects are available in the state
    if (cols.length === 4) {
      cols.push({
        field: "attachments",
        headerName: "Attachments",
        sortable: false,
        renderCell: (params) => {
          return (
            <div className={classes.deliverablesImgContainer}>
              {params.value.map((file) => (
                <img onClick={() => openDeliverable(file)} className={classes.deliverablesImg} alt="task_deliverable" src={file.thumbnail} />
              ))}
            </div>
          );
        },
        width: 280,
      });
    }

    setColumns(cols);
  }, [project.project_tasks]);

  React.useEffect(() => {
    if (scrollRef?.current && !firstLoad) {
      scrollRef.current.scrollIntoView();
    }
  }, [project?.project_comments?.length]);
  /**
   * End Region: useEffect Hooks
   */
  return (
    <div>
      <Dialog disablePortal style={{ position: "absolute" }} open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
        {projectLoading && <DialogContent sx={{ margin: "20px 10px" }}>Loading...</DialogContent>}

        {!projectLoading && (
          <DialogContent className={classes.dialogContent}>
            <Grid container>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                item
                xs={2.5}
              >
                <Typography className={classes.detailsHeader}>Project Details</Typography>
              </Grid>

              <Grid item xs={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography className={classes.detailsHeaderTask}>{project?.project_name}</Typography>

                  <Typography className={classes.otherSubHeader}>{project?.address}</Typography>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <AvatarGroup
                    sx={{
                      "& .MuiAvatarGroup-root": {
                        margin: "0px !important",
                      },
                      "& .MuiAvatarGroup-avatar": {
                        margin: "0px !important",
                        marginLeft: "-8px !important",
                      },
                      "& .MuiAvatar-circular": {
                        margin: "-3px -12px!important",
                      },
                    }}
                    max={3}
                  >
                    {project.project_tasks.map((task) => {
                      if (task.team_member) {
                        return (
                          <AvatarComponent
                            sx={{ width: "41px", height: "41px" }}
                            title={task.team_member.full_name}
                            nameInitial={task.team_member.full_name}
                            src={task.team_member?.profile_images?.profile_img_thumbnail || null}
                          />
                        );
                      }
                    })}
                  </AvatarGroup>
                </Box>
              </Grid>

              <Grid item xs={1.5} justify="center">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.detailsHeader}>{calculateDueIn(project.due_date)}</Typography>

                  <Typography className={classes.otherSubHeader}>Due In</Typography>
                </Box>
              </Grid>

              <Grid item xs={3} justify="center">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography className={classes.detailsHeader}>{project?.agent?.full_name}</Typography>

                    <Typography className={classes.otherSubHeader}>{project?.agent?.team?.name}</Typography>
                  </Box>
                  <AvatarComponent
                    variant="square"
                    src={project.agent?.profile_images?.profile_img_thumbnail}
                    nameInitial={project?.agent?.full_name}
                    sx={{ marginLeft: "14px", height: "50px", width: "50px", borderRadius: "4px" }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ marginTop: "10px", marginBottom: "20px" }} />

            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={2}
                  maxRows={2}
                  placeholder="Creation Notes are entered here"
                  className={classes.textArea}
                  defaultValue={noteComment.value}
                  onBlur={(e) => {
                    setNoteComment({
                      ...noteComment,
                      value: e.target.value,
                    });
                    dispatch(saveProjectDataFields({ id: noteComment.id, value: e.target.value }));
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={2}
                  maxRows={2}
                  placeholder="Publicly Visible Task Completion Comments"
                  className={classes.textArea}
                  defaultValue={publicNote}
                  onBlur={(e) => {
                    setPublicNote(e.target.value);
                    dispatch(saveProjectTaskPublicComments({ id: project.id, value: e.target.value, type: "project", noteType: "public_notes" }));
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button className={classes.button} variant="contained">
                    Approve / Reject
                  </Button>

                  <Button onClick={() => setOpen(false)} variant="outlined" className={classes.closeButton}>
                    Close
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ height: 400, width: "100%", marginTop: "20px" }}>
                  <DataGridComponent rows={rows} rowHeight={dataGridRowHeight} columns={columns} hideFooter />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
