import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../loader/tableLoader";
// Table Columns
export const CustomFilterableDataGrid = ({
  filterPanel,
  filters,
  toolbarPanel,
  ...props
}) => {
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  return (
    <DataGridPro
      {...props}
      filterModel={{
        items: filters,
      }}
      components={{
        FilterPanel: filterPanel,
        Toolbar: toolbarPanel,
        LoadingOverlay: TableSkeleton,
      }}
      sortingMode="server"
      componentsProps={{
        toolbar: {
          setFilterButtonEl,
        },
        panel: {
          anchorEl: filterButtonEl,
        },
        filterPanel: {
          apiRef: props?.apiRef,
          setFilterButtonEl,
        },
        columnsPanel: {
          setFilterButtonEl,
        },
        columnHeaderFilterIconButton: {
          onClick: (val, event) => {
            setFilterButtonEl(
              props?.apiRef?.current?.getColumnHeaderElement(val.field)
            );
            props.apiRef.current.showFilterPanel();
            props.apiRef.current.setColumnHeaderFocus(val.field);
          },
        },
      }}
      onColumnHeaderClick={(item, event, w, t) => {
        if (item.field !== "__check__") {
          setFilterButtonEl(event.target);
          props.apiRef.current.showFilterPanel(item.field);
          props.apiRef.current.setColumnHeaderFocus(item.field);
        }
      }}
      disableColumnMenu
      disableColumnResize
    />
  );
};
