import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const handleDeleteEvent = createAsyncThunk("Events/handleDeleteEvent", async (data, thunkAPI) => {
    let eventId=data.id;
    delete data.id
  try {
    const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/events-alerts-v2/event/${eventId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return {id:eventId};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
