import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  errMsg: null,
  categoryData: {},
  addCategory: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  update: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
};

export const getAllCategoryDataList = createAsyncThunk(
  "KnowledgeBaseCategories/getAllCategoryDataList",
  async (data, thunkAPI) => {
    try {
      const url = `${
        process.env.REACT_APP_BASE_URL
      }api/knowledgebase/post-category?pageNumber=${
        data?.pageNumber || 1
      }&pageSize=${data?.pageSize || 25}&sortBy=id&sortOrder=desc&search=${data?.search || ""}`;
      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      });
      return { ...resp.data, pageNumber: data?.pageNumber };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const createNewCategory = createAsyncThunk(
  "KnowledgeBaseCategories/createNewCategory",
  async (data, thunkAPI) => {
    let { title, handleSuccess, handleError } = data;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post-category`;
      const resp = await axios.post(
        url,
        { title },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );
      if (handleSuccess && resp?.data?.data) {
        handleSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateCategory = createAsyncThunk(
  "KnowledgeBaseCategories/updateCategory",
  async (data, thunkAPI) => {
    let { title, handleError, handleUpdateSuccess } = data;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post-category/${data.id}`;
      const resp = await axios.put(
        url,
        { title },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );
      if (handleUpdateSuccess && resp?.data?.data) {
        handleUpdateSuccess();
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const KnowledgeBaseCategories = createSlice({
  name: "KnowledgeBaseCategories",
  initialState,
  extraReducers: {
    [getAllCategoryDataList.pending]: (state) => {
      state.loading = true;
    },
    [getAllCategoryDataList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.pageNumber > 1) {
        state.categoryData = [...state.categoryData, ...action.payload.data];
      } else {
        state.categoryData = action.payload.data;
      }
    },
    [getAllCategoryDataList.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
    // add knowledge base category
    [createNewCategory.pending]: (state) => {
      state.addCategory.isLoading = true;
    },
    [createNewCategory.fulfilled]: (state, action) => {
      state.addCategory.isLoading = false;
      state.addCategory.success = true;
      let newCourseData = state.categoryData?.post_categories
        ? [...state.categoryData?.post_categories]
        : [];
      newCourseData.unshift(action.payload);
      state.categoryData.post_categories = newCourseData;
    },
    [createNewCategory.rejected]: (state, action) => {
      state.addCategory.isLoading = false;
      state.addCategory.errMsg = action.payload;
    },
    // update knowledge base category
    [updateCategory.pending]: (state) => {
      state.update.isLoading = true;
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.update.isLoading = false;
      state.update.success = true;

      let newCourseData = state.categoryData?.post_categories
        ? [...state.categoryData?.post_categories]
        : [];
      let index = newCourseData.findIndex(
        (item) => item.id === action.payload?.id
      );
      newCourseData[index] = { ...newCourseData[index], ...action.payload };
      state.categoryData.post_categories = newCourseData;
    },
    [updateCategory.rejected]: (state, action) => {
      state.update.isLoading = false;
      state.update.errMsg = action.payload;
    },
  },
});

export default KnowledgeBaseCategories.reducer;
