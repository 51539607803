export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "30px 30px 9px 30px",
    marginBottom: "16px",
  },
  pageRootTraining:{
    padding: "10px",
    marginBottom: "16px",
  },
  pageRootInventory:{
    padding: "5px",
    marginBottom: "16px",
  },
  pageRootInventoryBottom:{
    padding: "20px",
    marginBottom: "16px",
  },
  pageRootPayment: {
    marginBottom: "16px",
    padding:"5px",
  },
  overViewTabCardMain:{
    padding:"15px",
  },
  overViewTabCard:{
    padding:"15px",
    marginBottom:'20px',
  },
  overViewTabCardRightTop:{
    fontSize:'20px !important',
    fontWeight:'500 !important',
    textTransform:'capitalize !important',
    height:'60px',
    fontFamily:"AvenirNext  !important"
  },
  overViewTabCardRightBottom:{
    fontSize:'40px !important',
    fontWeight:'400 !important',
    textTransform:'capitalize !important',
    textAlign:'right',
    fontFamily:"AvenirNext  !important"
  },
  overViewTabCardHeading:{
    fontSize:'26px !important',
    fontWeight:'400 !important',
    textTransform:'capitalize !important',
    marginBottom:'15px  !important'
  },
  overViewTabCardDes:{
    fontSize:'16px !important',
    fontWeight:'400 !important',
    textTransform:'capitalize !important',
    color: 'rgba(0, 0, 0, 0.87)  !important'
  },
  overViewTabCardDesBottom:{
    paddingBottom:'0px  !important',
    "& .MuiTypography-root":{
      fontSize:'16px !important',
      fontWeight:'400 !important',
      textTransform:'capitalize !important',
      color: 'rgba(0, 0, 0, 0.87)  !important',
    }
  },
  customerNoteCardAvatarImg:{
    "& img":{
      height:'35px !important'
    }
  },
  pageHeading:{
    fontSize:'26px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  agentInventoryCardDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentInventoryCardContentDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)  !important",
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop:'-10px',
    marginBottom:'-10px'
  },
  agentInventoryCardHeader: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  loadingArea:{
    height:"50vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  }
});
