import React, { useEffect, useState, useContext } from "react";
import NumberFormat from "react-number-format";
import Fab from "@mui/material/Fab";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { AppContext } from "../callPanel/callContext";
import { numberBtnData } from "../../utils/keyPad";
const KeypadPanel = (props) => {
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");
  const { dialNumber } = useContext(AppContext);
  useEffect(() => {
    let number = currentPhoneNumber.split("-").join("").trim();
    if (number.length === 11 && number[0] !== "1") {
      setCurrentPhoneNumber("1" + number.slice(0, 10));
    }
  }, [currentPhoneNumber]);
  const dial = async (e) => {
    e.preventDefault();
    if (currentPhoneNumber) {
      let number = currentPhoneNumber.split("-").join("");
      if (currentPhoneNumber.length === 3) {
        number = currentPhoneNumber;
      } else {
        number = currentPhoneNumber.split("-").join("").trim();
      }
      props.handleCloseCall();
      dialNumber(number);
      setCurrentPhoneNumber("");
    }
  };
  const handleFormat = (input) => {
    console.log(input, currentPhoneNumber);
    let number = input.toString().split("-").join("").trim();
    if (number.length === 0) {
      return "#";
    } else if (number.length === 1) {
      return "##";
    } else if (number.length === 2) {
      return "###";
    } else if (number.length === 3) {
      return "###-#";
    } else if (number.length === 4) {
      return "###-##";
    } else if (number.length === 5) {
      return "###-###";
    } else if (number.length === 6) {
      return "###-###-#";
    } else if (number.length === 7) {
      return "###-###-##";
    } else if (number.length === 8) {
      return "###-###-###";
    } else if (number.length === 9) {
      return "###-###-####";
    } else if (number.length === 10) {
      return "###-###-#####";
    } else if (number.length === 11) {
      return "#-###-###-####";
    }
  };
  return (
    <div className="live-chat_keypad-panel">
      <form onSubmit={dial}>
        <div className="phonenumber-input-panel">
          <NumberFormat
            format={"###-###-####"}
            mask="_"
            className="phone-number-input-panel-field"
            value={currentPhoneNumber || ""}
            onChange={(e) => {
              let number = currentPhoneNumber.split("-").join("").trim();
              if (number.length < 12) {
                setCurrentPhoneNumber(e.target.value);
              }
            }}
            displayType="input"
            autoFocus
          />
          <Fab type="submit" color="success" aria-label="add">
            <PhoneRoundedIcon />
          </Fab>
        </div>
      </form>
      <div className="live-call-keypad">
        {numberBtnData.map((item, index) => (
          <div
            key={index}
            onClick={(e) => {
              let number = currentPhoneNumber.split("-").join("").trim();
              if (number.length < 12) {
                setCurrentPhoneNumber(currentPhoneNumber + item.value);
              }
            }}
            className="live-call-keypad-button"
          >
            <div>
              <div className="keypad-dial-number">{item.value}</div>
              <div className="keypad-dial-text">{item.subValue.map((e) => e)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default KeypadPanel;
