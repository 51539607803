import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  Paper,
} from "@mui/material";
import momentTZ from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  getAllCourseData,
  getAllDraftedData,
} from "../../../redux/knowledge/courses.js";
import AddNewCourse from "./modals/AddNewCourse.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../components/loader/tableLoader";
import { useLocation } from "react-router-dom";
function ManageLibraryCourses(props) {
  let { classes, isDraft } = props;
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const location = useLocation();
  const [search, setSearch] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    search: "",
  });
  const { postData } = useSelector((state) => state.knowledgeBase.posts);
  const { categoryData } = useSelector((state) => state.knowledgeBase.categories);
  const { draftedCourse, loading, courseData } = useSelector(
    (state) => state.knowledgeBase.courses
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (isDraft) {
      dispatch(getAllDraftedData(filters));
    } else {
      dispatch(getAllCourseData(filters));
    }
  }, [location.pathname,filters.search]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => {
        setFilters({ ...filters, search })}, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const coursecolumns = [
    { field: "title", headerName: "Title", flex: 1 },
    {
      field: "createdAt",
      headerName: "Date Added ",
      renderCell: (params) => {
        return momentTZ
          .tz(params.row.createdAt, "America/New_York")
          .format("MM/DD/YY");
      },
      flex: 0.3,
    },
    //
    {
      field: "views",
      headerName: "Total Posts",
      width: 300,
      renderCell: (params) => {
        return params.row?.knowledgebase_posts?.length || "0";
      },
      flex: 0.3,
    },

    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <Button
              container
              onClick={() => {
                setEditable(params.row);
                setOpen(true);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Box className={classes.InventoryHeader}>
        <Typography variant="h5" gutterBottom component="div">
          Manage Library
        </Typography>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Button
              className={classes.InventoryAddButton}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => setOpen(true)}
            >
              Add Course
            </Button>
            <TextField
              type="search"
              size="small"
              variant="outlined"
              placeholder="Search Course"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Stack>{" "}
        </Box>
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={
            isDraft
              ? draftedCourse.courses
                ? draftedCourse.courses
                : []
              : courseData.courses
              ? courseData.courses
              : []
          }
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          loading={loading}
          columns={coursecolumns}
          checkboxSelection={false}
          onRowsScrollEnd={(e) => {
            if (courseData.count > e.virtualRowsCount && !loading) {
              if (isDraft) {
                dispatch(
                  getAllDraftedData({
                    pageNumber: Number(e.virtualRowsCount / 25) + 1,
                    pageSize: 25,
                  })
                );
              } else {
                dispatch(
                  getAllCourseData({
                    pageNumber: Number(e.virtualRowsCount / 25) + 1,
                    pageSize: 25,
                  })
                );
              }
            }
          }}
        />
      </Paper>
      <AddNewCourse
        setEditable={setEditable}
        editable={editable}
        postData={postData ? postData : []}
        open={open}
        setOpen={setOpen}
        categoryData={categoryData.post_categories}
      />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "ManageLibraryCoursesStyle" })(
  ManageLibraryCourses
);
