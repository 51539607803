import React from "react";
import { Routes, Route } from "react-router-dom";
import DocsListing from "./docsListing";
import DocsDetails from "./docDetails";

const Docs = () => {
  return (
    <Routes>
      <Route path="/" element={<DocsListing />} />
      <Route path="/:id/*" element={<DocsDetails />} />
    </Routes>
  );
};

export default Docs;
