import React, { useState } from "react";
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import fileIcon from "../../assets/images/fileIcon.png";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import { useSelector, useDispatch } from "react-redux";
import { CustomTooltip } from "../../components/shared/tooltip";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ResponseAlert from "../../components/responseAlert";
import { ValidateEmail } from "../../utils/emailValidator";
import {
  sendEmail,
  uploadAttachments,
  deleteAttachment,
} from "../../redux/sendEmailAs";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import ImgsViewer from "react-images-viewer";
import { checkFileType } from "./emailDetail";
import Avatar from "../../components/uiElements/avatar/index";
import Dropzone from "react-dropzone";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";

const options = ["Send Offer to Listing Agent"];

let Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

function SendEmail(props) {
  let { open, setOpen, classes } = props;
  const dispatch = useDispatch();
  const [pdfOpen, setPdfOpen] = useState(false);
  const [CC, setCC] = useState(false);
  const [BCC, setBCC] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [emailDetail, setEmailDetail] = useState({
    to: "",
    from: "",
    cc: "",
    bcc: "",
    subject: "",
    body: "",
    sendById: "",
  });
  const [signature, setSignature] = useState(``);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [inputValueEmail, setInputValueEmail] = useState("");
  const [selectedCC, setSelectedCC] = useState([]);
  const [inputValueCC, setInputValueCC] = useState("");
  const [selectedBCC, setSelectedBCC] = useState([]);
  const [inputValueBCC, setInputValueBCC] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [errMsg, setErrMsg] = useState({});
  const agentRosterList = useSelector((state) =>
    state.users.storedListOfUsers.list.filter((user) => user.is_agent === true)
  );
  const emailSend = useSelector((state) => state.SendEmailAs.emailSend);
  const adminDetail = useSelector((state) => state.adminAuth.adminDetail);
  const template = useSelector((state) => state.SendEmailAs.template);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdateValue = (field, value, email_signature, email) => {
    if (email_signature) {
      setEmailDetail((detail) => ({
        ...detail,
        [field]: value,
        body: `<br /><br /><div>--</div><br /><br />`,
        from: email,
      }));
      setSignature(email_signature);
    } else if (field === "sendById") {
      setEmailDetail((detail) => ({
        ...detail,
        [field]: value,
        body: "",
        from: email,
      }));
      setSignature("");
    } else {
      setEmailDetail((detail) => ({ ...detail, [field]: value }));
    }
    setErrMsg((error) => ({ ...error, field: "" }));
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!emailDetail.sendById) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the agent",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!selectedEmail?.length) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the email",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!emailDetail.subject) {
      isFormValid = false;
      errorMsg["subject"] = "Please enter email subject";
      setErrorAlert({
        errorMsg: "Please enter email subject",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!emailDetail.body) {
      isFormValid = false;
      errorMsg["body"] = "Please enter email detail";
      setErrorAlert({
        errorMsg: "Please enter email detail",
        errorType: "warning",
        isOpen: true,
      });
    }

    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleClose = () => {
    setEmailDetail({
      from: "",
      subject: "",
      body: "",
      sendById: "",
    });
    setSignature("");
    setAttachments([]);
    setSelectedCC([]);
    setSelectedBCC([]);
    setSelectedEmail([]);
    setOpen(false);
    setCC(false);
    setBCC(false);
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully sent the email",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleSuccessAttachments = (data) => {
    if (data?.length) {
      setAttachments((attch) => [...attch, ...data]);
    }
    setErrorAlert({
      errorMsg: "You have successfully uploaded",
      errorType: "success",
      isOpen: true,
    });
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };
  const handleSendEmail = () => {
    if (handleValidate()) {
      let sentAs = agentRosterList.find((it) => it.id === emailDetail.sendById);
      let obj = {
        schema: {
          ...emailDetail,
          to: selectedEmail?.join(","),
          cc: CC ? selectedCC?.join(",") : null,
          bcc: BCC ? selectedBCC?.join(",") : null,
          from:
            window.location.origin === "https://app.ourmethod.com"
              ? emailDetail.from
              : "rohit@methodtesting.com",
          attachments: attachments,
          body: emailDetail?.body + signature,
        },
        sentAs: {
          id: sentAs?.id,
          full_name: sentAs?.full_name,
          email: sentAs?.email,
          profile_images: sentAs.profile_images,
        },
        sentBy: {
          id: adminDetail?.id,
          full_name: adminDetail?.full_name,
          email: adminDetail?.email,
          profile_images: {
            profile_img: adminDetail.profile_img,
          },
        },
        handleSuccess,
        handleError,
      };
      dispatch(sendEmail(obj));
    }
  };

  const handleFileChange = (files) => {
    const filesArray = Array.from(files);
    const Data = new FormData();
    filesArray?.map((file) => {
      Data.append("attachments[]", file);
    });
    let obj = {
      schema: Data,
      handleSuccess: handleSuccessAttachments,
      handleError,
    };
    toast.promise(dispatch(uploadAttachments(obj)), {
      pending: "Uploading files",
      success: "Successfully Uploaded",
      error: "Something went wrong while uploading",
    });
  };

  const handleDragEnter = () => {
    document.getElementById(`upload-file-for-send-email`).style.border =
      "2px dashed rgba(0, 0, 0, 0.30)";
    document.getElementById(`upload-file-for-send-email-icon`).style.display =
      "flex";
  };
  const handleDragLeave = () => {
    document.getElementById(`upload-file-for-send-email`).style.border =
      "2px dashed white";
    document.getElementById(`upload-file-for-send-email-icon`).style.display =
      "none";
  };

  const handleDeleteAttachment = (data) => {
    setAttachments((attch) => attch.filter((it) => it.id !== data.id));
    dispatch(deleteAttachment(data));
  };
  const handleCheckValue = () => {
    setTimeout(() => {
      setEmailDetail((detail) => ({
        ...detail,
        body: `<div>${
          document.getElementsByClassName("ql-editor")[0]?.innerHTML
        }</div>`,
      }));
    }, 200);
  };
  const handleSelectTemplate = () => {
    let signatures = "";
    if (emailDetail.sendById) {
      signatures = agentRosterList.find(
        (it) => it.id === emailDetail.sendById
      )?.email_signature;
    }

    setEmailDetail((detail) => ({
      ...detail,
      body: `<div>${template
        ?.replaceAll("\n", "<br /><br />")
        ?.replaceAll(
          "[input]",
          `<span style="color:#CF00C7;cursor:pointer">[input]</span>`
        )
        .replace(/\./g, ". ")}</div><br /><br />`,
    }));
    handleCheckValue();
    setSignature(signatures);
    handleCloseMenu();
  };

  const handleBlurTo = (event) => {
    const email = event.target.value;
    if (ValidateEmail(email) && !selectedEmail.includes(email)) {
      setSelectedEmail((prev) => [...prev, email]);
    }
    setInputValueEmail("");
  };

  const handleBlurCC = (event) => {
    const email = event.target.value;
    if (ValidateEmail(email) && !selectedCC.includes(email)) {
      setSelectedCC((prev) => [...prev, email]);
    }
    setInputValueCC("");
  };
  const handleBlurBCC = (event) => {
    const email = event.target.value;
    if (ValidateEmail(email) && !selectedBCC.includes(email)) {
      setSelectedBCC((prev) => [...prev, email]);
    }
    setInputValueBCC("");
  };

  const handleDelete = (emailToDelete, type) => () => {
    if (type === "to") {
      setSelectedEmail((prev) =>
        prev.filter((email) => email !== emailToDelete)
      );
    } else if (type === "cc") {
      setSelectedCC((prev) => prev.filter((email) => email !== emailToDelete));
    } else {
      setSelectedBCC((prev) => prev.filter((email) => email !== emailToDelete));
    }
  };

  return (
    <Box>
      {" "}
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiPaper-root": { borderRadius: "22px" },
        }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <Box className={classes.sendEmailForm}>
          <IconButton
            className={classes.closeIcon}
            onClick={() => handleClose()}
          >
            <CloseRoundedIcon />
          </IconButton>
          <Box className={classes.fieldsSectionsArea}>
            <Box className={classes.agentSelection}>
              <Typography className={classes.StartLabel}>Send As:</Typography>
              <Autocomplete
                disableClearable
                options={
                  agentRosterList?.filter((it) => it.is_agent).length > 0
                    ? agentRosterList.sort((a, b) =>
                        a.last_name.localeCompare(b.last_name)
                      )
                    : []
                }
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={(e, option) => {
                  handleUpdateValue(
                    "sendById",
                    option.id,
                    option.email_signature,
                    option.email
                  );
                }}
                sx={{ minWidth: "250px" }}
                value={
                  emailDetail.sendById
                    ? agentRosterList.find(
                        (it) => it.id === emailDetail.sendById
                      )
                    : null
                }
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search agent"
                    aria-describedby="stepper-linear-event_types"
                    size="small"
                    fullWidth
                    required
                    className={classes.selectAgentInput}
                    error={errMsg.sendById}
                    helperText={errMsg.sendById}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: emailDetail.sendById ? (
                        <Avatar
                          sx={{
                            width: "30px",
                            height: "30px",
                            fontSize: "13px",
                            ml: "3px",
                          }}
                          src={
                            emailDetail.sendById
                              ? agentRosterList.find(
                                  (it) => it.id === emailDetail.sendById
                                )?.profile_images?.profile_img_thumbnail
                              : ""
                          }
                        >
                          {`${agentRosterList
                            .find((it) => it.id === emailDetail.sendById)
                            .first_name.slice(0, 1)}${agentRosterList
                            .find((it) => it.id === emailDetail.sendById)
                            .last_name.slice(0, 1)}`}
                        </Avatar>
                      ) : null,
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Avatar
                      sx={{
                        width: "30px",
                        height: "30px",
                        fontSize: "13px",
                        mr: "8px",
                      }}
                      src={
                        option.profile_images?.profile_img_thumbnail ||
                        option.profile_images?.profile_img
                      }
                    >{`${option.first_name.slice(0, 1)}${option.last_name.slice(
                      0,
                      1
                    )}`}</Avatar>
                    {option.first_name} {option.last_name}
                  </li>
                )}
              ></Autocomplete>
              <CustomTooltip
                title={
                  <Box sx={{ textAlign: "center", padding: "2px 8px" }}>
                    Select an agent to send as.
                    <br /> The agent will be automatically <br /> BCC’d on the
                    email.
                  </Box>
                }
              >
                {" "}
                <Box className={classes.questionMarks}>
                  <QuestionMarkRoundedIcon
                    fontSize="small"
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "16px",
                      cursor: "help",
                    }}
                  />
                </Box>
              </CustomTooltip>
            </Box>
            <Autocomplete
              multiple
              limitTags={5}
              options={[]}
              blurOnSelect
              disableClearable
              value={selectedEmail}
              inputValue={inputValueEmail}
              onInputChange={(event, newInputValue) => {
                setInputValueEmail(newInputValue);
              }}
              id="to-email-tags"
              onBlur={handleBlurTo}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((email, index) => (
                  <Chip
                    label={email}
                    sx={{
                      borderRadius: "3px",
                      color: "black",
                      fontSize: "14px",
                    }}
                    size="small"
                    deleteIcon={
                      <CloseRoundedIcon
                        fontSize="small"
                        sx={{
                          fontSize: "18px !important",
                        }}
                      />
                    }
                    {...getTagProps({ index })}
                    onDelete={handleDelete(email, "to")}
                  />
                ))
              }
              renderInput={(params) => (
                <Box className={classes.customInput}>
                  <InputAdornment
                    position="start"
                    className={classes.autocompleteLabel}
                  >
                    To:
                  </InputAdornment>
                  <TextField
                    {...params}
                    sx={{
                      mb: "-1px",
                    }}
                    className={classes.CustomTextField}
                    InputProps={{
                      ...params.InputProps,

                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setCC((c) => !c)}
                            sx={{ fontSize: "14px", color: "#008FF6" }}
                          >
                            Cc
                          </IconButton>
                          <IconButton
                            onClick={() => setBCC((bcc) => !bcc)}
                            sx={{ fontSize: "14px", color: "#008FF6" }}
                          >
                            Bcc
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                    fontSize="small"
                    error={Boolean(errMsg.to)}
                    helperText={errMsg.to}
                  />
                </Box>
              )}
            />
            {CC ? (
              <Autocomplete
                multiple
                limitTags={5}
                options={[]}
                blurOnSelect
                value={selectedCC}
                inputValue={inputValueCC}
                onInputChange={(event, newInputValue) => {
                  setInputValueCC(newInputValue);
                }}
                disableClearable
                id="cc-email-tags"
                onBlur={handleBlurCC}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((email, index) => (
                    <Chip
                      label={email}
                      sx={{
                        borderRadius: "3px",
                        color: "black",
                        fontSize: "14px",
                      }}
                      size="small"
                      deleteIcon={
                        <CloseRoundedIcon
                          fontSize="small"
                          sx={{
                            fontSize: "18px !important",
                          }}
                        />
                      }
                      {...getTagProps({ index })}
                      onDelete={handleDelete(email, "cc")}
                    />
                  ))
                }
                renderInput={(params) => (
                  <Box className={classes.customInput}>
                    <InputAdornment
                      position="start"
                      className={classes.autocompleteLabel}
                    >
                      CC:
                    </InputAdornment>
                    <TextField
                      {...params}
                      sx={{ mb: "-1px" }}
                      variant="standard"
                      className={classes.CustomTextField}
                      fullWidth
                      fontSize="small"
                      error={Boolean(errMsg.cc)}
                      helperText={errMsg.cc}
                    />
                  </Box>
                )}
              />
            ) : null}
            {BCC ? (
              <Autocomplete
                multiple
                limitTags={5}
                options={[]}
                blurOnSelect
                value={selectedBCC}
                inputValue={inputValueBCC}
                onInputChange={(event, newInputValue) => {
                  setInputValueBCC(newInputValue);
                }}
                disableClearable
                id="cc-email-tags"
                onBlur={handleBlurBCC}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((email, index) => (
                    <Chip
                      label={email}
                      size="small"
                      sx={{
                        borderRadius: "3px",
                        color: "black",
                        fontSize: "14px",
                      }}
                      deleteIcon={
                        <CloseRoundedIcon
                          fontSize="small"
                          sx={{
                            fontSize: "18px !important",
                          }}
                        />
                      }
                      {...getTagProps({ index })}
                      onDelete={handleDelete(email, "bcc")}
                    />
                  ))
                }
                renderInput={(params) => (
                  <Box className={classes.customInput}>
                    <InputAdornment
                      position="start"
                      className={classes.autocompleteLabel}
                    >
                      BCC:
                    </InputAdornment>
                    <TextField
                      {...params}
                      sx={{ mb: "-1px" }}
                      variant="standard"
                      className={classes.CustomTextField}
                      fullWidth
                      fontSize="small"
                      error={Boolean(errMsg.bcc)}
                      helperText={errMsg.bcc}
                    />
                  </Box>
                )}
              />
            ) : null}
            <TextField
              value={emailDetail.subject}
              onChange={(e) => handleUpdateValue("subject", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" className={classes.StartLabel}>
                    Subject:
                  </InputAdornment>
                ),
              }}
              fullWidth
              variant="standard"
              fontSize="small"
              error={errMsg.subject}
              helperText={errMsg.subject}
              className={classes.CustomTextField}
            />
            <Box
              className={classes.quillEditor}
              sx={{
                "& .ql-editor": {
                  height: signature ? "auto" : "400px",
                  maxHeight: "400px",
                  fontSize: "18px",
                  padding: "6px",
                },
                "& .ql-container": {
                  border: "none",
                },
                position: "relative",
                borderRadius: "8px",
                border: "2px solid transparent",
              }}
              id="upload-file-for-send-email"
            >
              <Dropzone
                accept={".pdf"}
                noClick={true}
                noKeyboard={true}
                onDragEnter={() => handleDragEnter()}
                onDragLeave={() => handleDragLeave()}
                onDrop={(acceptedFiles) => {
                  handleFileChange(acceptedFiles);
                  handleDragLeave();
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    disablePadding
                    sx={{ margin: "-6px" }}
                  >
                    <Box
                      id={`upload-file-for-send-email-icon`}
                      sx={{ top: "0px" }}
                      className={classes.dropzoneArea}
                    >
                      <Box className={classes.dropzoneAreaInner}>
                        <center>
                          <UploadRoundedIcon
                            fontSize="large"
                            sx={{
                              color: "rgba(0, 0, 0, 0.50)",
                              fontSize: "50px",
                            }}
                          />
                        </center>{" "}
                        <Box>Drag & Drop to upload.</Box>
                      </Box>
                    </Box>
                    <ReactQuill
                      className="react-quill"
                      theme="snow"
                      value={emailDetail.body}
                      onChange={(value, e, type) =>
                        handleUpdateValue("body", value)
                      }
                      modules={{ toolbar: false }}
                    />
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: signature,
                      }}
                      sx={{
                        fontFamily: "AvenirNext !important",
                        paddingLeft: "6px",
                      }}
                    ></Typography>
                  </Box>
                )}
              </Dropzone>
            </Box>
            {attachments?.map((item, index) => (
              <Box key={index} className={classes.attachmentTab}>
                <Typography
                  onClick={() => {
                    if (checkFileType(item.file_path) === "pdf") {
                      setPdfOpen(true);
                    } else {
                      setImageViewerOpen(true);
                    }
                    setSelectedMedia(item);
                  }}
                  className={classes.attachmentTabContent}
                >
                  {item.file}
                </Typography>
                <IconButton onClick={() => handleDeleteAttachment(item)}>
                  {" "}
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.modalFooter}>
          <CustomTooltip title="USE TEMPLATE" placement="top">
            {" "}
            <IconButton onClick={handleClick}>
              <img src={fileIcon} alt={"fileIcon"} />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title="ADD ATTACHMENT" placement="top">
            <IconButton component="label" sx={{ mr: "12px" }}>
              <AttachFileRoundedIcon />
              <input
                onChange={(e) => handleFileChange(e.target.files)}
                hidden
                multiple
                type="file"
                accept=".pdf, .png, .jpg, .jpeg, .gif"
              />
            </IconButton>
          </CustomTooltip>
          <LoadingButton
            onClick={handleSendEmail}
            size="small"
            variant="contained"
            className={classes.sendButton}
            loadingPosition="start"
            loading={emailSend.isLoading}
          >
            Send
          </LoadingButton>
        </Box>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Menu
        id="template-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: "400px",
            overflow: "auto",
            width: "30ch",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={handleSelectTemplate}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <ImgsViewer
        imgs={[{ src: selectedMedia?.file_path }]}
        isOpen={imageViewerOpen}
        currImg={0}
        showThumbnails={true}
        enableKeyboardInput={true}
        onClose={() => setImageViewerOpen(false)}
      />
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiPaper-root": { borderRadius: "10px", overflow: "visible" },
        }}
        open={pdfOpen}
        onClose={() => setPdfOpen(false)}
        aria-labelledby="pdf-dialog-preview"
        aria-describedby="pdf-dialog-preview-description"
        fullWidth
        maxWidth="lg"
      >
        <Box sx={{ position: "relative" }}>
          <IconButton
            className={classes.closeIcon}
            onClick={() => setPdfOpen(false)}
            sx={{
              background: "lightgrey",
              right: "-40px !important",
              border: "2px solid white",
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <embed
          src={selectedMedia.file_path}
          type="application/pdf"
          style={{
            width: "100%",
            height: "100vh",
            overflow: "hidden !important",
            padding: "8px",
            paddingBottom: "0px",
            minHeight: "100%",
          }}
        />
      </Dialog>
    </Box>
  );
}

export default SendEmail;
