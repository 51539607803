import {
  Box,
  Card,
  Container,
  MenuItem,
  MenuList,
  Popover,
  Tab,
  Tabs,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styleSheet } from "../style.js";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TabContext } from "@mui/lab";
import React, { useState } from "react";
import TasksButton from "../TasksButton";
import TasksList from "./tasks.js";
import { getTasks } from "../../../../redux/agentTasks/index.js";

const AgentRecruitsTasks = ({ classes }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, type } = useParams();

  const [moreItemsVal, setMoreItemsVal] = useState("More Items");
  const [anchorEl, setAnchorEl] = useState(null);

  const data = useSelector((item) => item.agentList);
  const { smartList } = data;
  
  const handlePopoverClose = (action) => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    dispatch(getTasks(type));
  }, [type]);

  return (
    <Box>
      <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
        <Box className={classes.TopBarArea}>
          <Box className={classes.TopBarAreaLeft}>
            <Box>
              <TabContext>
                <Box className={classes.RecruitingTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                    onChange={(e) => {
                      if (moreItemsVal !== "More Items") {
                        setMoreItemsVal("More Items");
                      }
                    }}
                  >
                    <Tab
                      label="All People"
                      value={`/tools/recruiting`}
                      to={`/tools/recruiting`}
                      state={{ fromOtherTab: true }}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />

                    {smartList.slice(0, 3).map((ls, i) => {
                      return (
                        <Tab
                          label={`${ls.name} (${ls.count})`}
                          value={`/tools/recruiting/list/` + ls.id}
                          to={`/tools/recruiting/list/` + ls.id}
                          component={Link}
                          sx={{ padding: "0px 10px" }}
                          key={i}
                        />
                      );
                    })}
                    {smartList.slice(3).length ? (
                      <Tab
                        label={moreItemsVal}
                        value={`/tools/recruiting/list/more${id ? "/" + id : ""}`}
                        to={`/tools/recruiting/list/more${id ? "/" + id : ""}`}
                        component={Link}
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.target);
                        }}
                        icon={<KeyboardArrowDownIcon style={{ marginBottom: 0 }} />}
                        sx={{
                          padding: "0px 10px",
                          flexDirection: "row-reverse",
                        }}
                      />
                    ) : null}
                  </Tabs>
                </Box>
              </TabContext>
            </Box>
          </Box>
          <Box className={classes.TopRecruitmentAreaLeft}>
            <TasksButton />        
          </Box>
        </Box>
      </Card>

      <Container maxWidth="xl" sx={{ height: '100vh' }}>
        <Box sx={{ display: 'flex', marginTop: '20px' }}>
          <TasksList />
        </Box>
      </Container>

      <Popover
          PaperProps={{
            style: { minWidth: "150px" },
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          role={undefined}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
      >
          <MenuList>
          <MenuItem value="" disabled>
              <em>More Items</em>
          </MenuItem>
          {smartList.slice(3).map((ls, i) => {
              return (
                <MenuItem
                    value={ls.id}
                    key={i}
                    onClick={(e) => {
                    setMoreItemsVal(`${ls.name} (${ls.count})`);
                    navigate("/tools/recruiting/list/more/" + ls.id);
                    handlePopoverClose();
                    }}
                >
                    {ls.name} ({ls.count})
                </MenuItem>
              );
          })}
          </MenuList>
      </Popover>
    </Box>
  );
};
export default withStyles(styleSheet, { name: "agentRecruitsStyle" })(AgentRecruitsTasks);
