import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Grid, useMediaQuery } from "@mui/material";
import { withStyles } from "@mui/styles";
import AgentsTable from "../common/Table/AgentsTable";
import { TabContext } from "@mui/lab";
import { styleSheet } from "./style.js";
import InputBase from "@mui/material/InputBase";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { Box, Tabs, Tab, Card, Button } from "@mui/material";
import { getAgentRosterList } from "../../../redux/agents/agentRoster/getAgentRosterList";
import AddUserModal from "../agentDetails/modals/addUser";
import TabMenu from "./TabMenu";
import { styled, alpha } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const AgentsListing = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    only_agents: true,
    pageSize: 500,
    pageNumber: 1,
    search: "",
  });
  const data = useSelector((item) => item.agentRoster.agentRosterList);
  const { list, totalAgents, loading } = data;

  const makeDropDown = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    dispatch(getAgentRosterList(filters));
  }, [filters]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ ...filters, search, pageNumber: 1 }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{ position: "sticky", top: "64px", zIndex: "100" }}
      >
        {" "}
        <Box className={classes.TopUsersBarArea}>
          <Box className={classes.TopUsersBarAreaLeft}>
            <Box>
              <TabContext>
                <Box className={classes.UsersTabsUI}>
                  {!makeDropDown && (
                    <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{
                        sx: { background: "black", color: "black" },
                      }}
                      sx={{
                        display: makeDropDown ? "none" : "",
                        "& .Mui-selected": {
                          color: "#0B0909 !important",
                        },
                        "& .MuiTab-root": {
                          fontSize: "13px !important",
                          minHeight: "40px !important",
                        },
                        "& .MuiTabs-root": {
                          minHeight: "40px  !important",
                        },
                        "& .MuiTabs-scroller": {
                          height: "36px  !important",
                        },
                      }}
                    >
                      <Tab
                        label="Agent Roster"
                        value={`/agent_roster`}
                        to={`/agent_roster`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                      <Tab
                        label="Users"
                        value={`/users`}
                        to={`/users`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                      <Tab
                        label="Lenders"
                        value={`/users/lenders`}
                        to={`/users/lenders`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                      <Tab
                        label="Teams"
                        value={`/teams`}
                        to={`/teams`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                    </Tabs>
                  )}
                  {makeDropDown && (
                    <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{
                        sx: { background: "black", color: "black" },
                      }}
                      variant="scrollable"
                      aria-label="Users-tabs"
                      sx={{ justifyContent: "space-around" }}
                    >
                      <TabMenu />
                    </Tabs>
                  )}
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopUsersBarAreaLeft}>
            {" "}
            <Button
              onClick={() => setOpen(true)}
              className={classes.addTransactionButton}
              variant="contained"
              startIcon={<CreateNewFolderRoundedIcon />}
            >
              Add User
            </Button>{" "}
            <Search>
              <StyledInputBase
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Users"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
        </Box>
      </Card>
      <Container maxWidth="lg">
        <div className={classes.pageRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <AgentsTable
                data={list || []}
                setFilters={setFilters}
                filters={filters}
                loading={loading}
                totalAgents={totalAgents || 0}
                search={search}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddUserModal open={open} setOpen={setOpen} />
    </>
  );
};

export default withStyles(styleSheet, { name: "agentRosterStyle" })(
  AgentsListing
);
