import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const deleteTaskTemplate = createAsyncThunk(
  "TasksTemplates/deleteTaskTemplate",
  async (data, thunkAPI) => {
    let { handleSuccess, handleError } = data;
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/transactions/template/${data.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp?.data && handleSuccess) {
        handleSuccess();
      }
      return { ...resp.data, templateId: data.id };
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
