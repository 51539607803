import * as React from "react";
import {
    CircularProgress,
    Dialog as MuiDialog,
    DialogActions, DialogContentText, DialogContent, DialogTitle
} from "@mui/material";
import Button from "../uiElements/button";

const DeleteDialog = (props) => {
    const { openPopup, closeModal, onConfirm } = props
    return (
        <MuiDialog
            aria-labelledby="customized-dialog-title"
            open={openPopup}
            sx={{zIndex:1000000}}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Are you sure to permanent delete?
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    If you delete this content it will never come back
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button themeVersion onClick={closeModal}> Cancel</Button>
                <Button onClick={onConfirm}>Delete</Button>
            </DialogActions>

        </MuiDialog>
    );
};

export default DeleteDialog;
