import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import { useNavigate } from "react-router-dom";
import {
  LicenseCell,
  NameCard,
  PhoneCell,
  TeamCell,
} from "./TableCellsAgentRoster";
import {
  Typography,
  Chip,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";
import TableSkeleton from "../../../../components/loader/tableLoader";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import { useSelector } from "react-redux";
import { marketCustom } from "../../../../utils/markets";
import { getData } from "../../../../utils/getData";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import momentTZ from "moment-timezone";
import { statusData } from "../../../../utils/agents.js";

const XLSX = require("xlsx");
function CustomToolbar() {
  const [loading, setLoading] = useState(false);
  const data = useSelector((item) => item.agentRoster.agentRosterList);
  const { totalAgents, search } = data;
  const markets = useSelector((state) => state.agentList.markets);

  const handleExport = async () => {
    setLoading(true);
    const param = `?pageSize=${5000}&pageNumber=${1}&search=${
      search || ""
    }&only_agents=true`;

    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/users${param}`
    );
    setLoading(false);
    if (result.data?.allUsers?.length) {
      let users = [
        ["Name", "Phone", "Email", "Team", "License Type", "Market"],
      ];
      result?.data.allUsers.forEach((user) => {
        let userArray = [
          user.first_name + " " + user.last_name,
          user?.phone || "--",
          user?.email || "--",
          user?.team?.name || "--",
          user.license_type || "--",
          user?.market_id &&
          marketCustom.find((item) => item.id === user.market_id)
            ? marketCustom.find((item) => item.id === user.market_id)?.title
            : user?.market_id && markets?.length
            ? markets?.find((item) => item.id === user?.market_id)?.name
            : "",
        ];
        users.push(userArray);
      });
      const wb = XLSX.utils.book_new();
      const wsAll = XLSX.utils.aoa_to_sheet(users);
      XLSX.utils.book_append_sheet(wb, wsAll, "All Users");
      XLSX.writeFile(wb, `Agent-Roster-${Date()}.csv`);
    }
  };

  return (
    <React.Fragment>
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        {Number(totalAgents || 0) > 25 ? (
          <Button
            disabled={loading}
            onClick={handleExport}
            variant="text"
            sx={{ mr: "10px", textTransform: "capitalize" }}
            startIcon={<SaveAltIcon />}
          >
            Export
          </Button>
        ) : (
          <GridToolbarExport sx={{ mr: "10px" }} />
        )}
        {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
      </GridToolbarContainer>
      <Backdrop color="inherit" invisible={true} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
// Table Columns
const AgentRosterTable = ({
  classes,
  data,
  totalAgents,
  loading,
  setFilters,
  search,
}) => {
  const [status, setStatus] = useState("active");
  let navigate = useNavigate();
  const markets = useSelector((state) => state.agentList.markets);
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      renderCell: ({ row }) => {
        return (
          <NameCard
            first_name={row.first_name}
            src={
              row?.profile_images?.profile_img ||
              row?.profile_images?.profile_img_thumbnail
            }
            last_name={row.last_name}
          />
        );
      },
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      renderCell: ({ row }) => {
        return row.phone ? <PhoneCell number={row.phone} /> : "--";
      },
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      renderCell: ({ row }) => {
        return row.email ? row.email : "--";
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => (
        <Chip
          classes={{ root: classes.statusChip }}
          label={row?.status || "Setup"}
          sx={{
            background:
              row?.status === "setup"
                ? "#B780E3"
                : row?.status === "setup-completed"
                ? "#629FE8"
                : row?.status === "active"
                ? "#57C645"
                : row?.status === "off-boarding"
                ? "#EC4F4F"
                : "#000000",
            textTransform: "capitalize",
          }}
          color="success"
        />
      ),
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "team",
      headerName: "Team",
      renderCell: ({ row }) => {
        return row.team ? (
          <TeamCell
            name={row.team.name}
            color={row.team.team_color || "rgba(33, 14, 243, 0.62)"}
            join_date={row.join_date}
          />
        ) : (
          "--"
        );
      },
      valueGetter: ({ row }) => row?.team?.name,
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "license_type",
      headerName: "License Type",
      renderCell: ({ row }) => {
        return row.license_type ? (
          <LicenseCell name={row.license_type} expire={row.license_exp} />
        ) : (
          "--"
        );
      },
      editable: false,
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "market_id",
      headerName: "Market",
      valueGetter: ({ row }) =>
        row?.market_id && markets?.length
          ? markets?.find((item) => item.id === row?.market_id)?.name
          : "",
      renderCell: ({ row }) => {
        return (
          <>
            {row?.market_id ? (
              <CustomTooltip
                title={
                  row?.market_id && markets?.length
                    ? markets?.find((item) => item.id === row?.market_id)?.name
                    : ""
                }
              >
                <Chip
                  size="small"
                  label={
                    row?.market_id && markets?.length
                      ? markets?.find((item) => item.id === row?.market_id)
                          ?.name
                      : ""
                    // row?.market_id && marketCustom.find((item) => item.id === row.market_id)
                    //   ? marketCustom.find((item) => item.id === row.market_id)?.title
                    //   : row?.market_id && markets?.length
                    //   ? markets?.find((item) => item.id === row?.market_id)?.name
                    //   : ""
                  }
                  style={{
                    background:
                      row?.market_id &&
                      marketCustom.find((item) => item.id === row.market_id)
                        ? marketCustom.find((item) => item.id === row.market_id)
                            ?.backgroundColor
                        : "#80CADB",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            ) : (
              ""
            )}
          </>
        );
      },
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      editable: false,
      sortable: true,
      minWidth: 120,
      renderCell: ({ row }) => {
        return row.createdAt ? (
          <Box>
            {momentTZ
              .tz(row.createdAt, process.env.REACT_APP_TIMEZONE)
              .format("MMM DD YYYY")}
          </Box>
        ) : (
          "--"
        );
      },
      flex: 1,
    },
  ];

  // Detail view trigger for agent
  const onRowClick = ({ id }) => {
    navigate(`/agent_roster/${id}/overview`);
  };

  return (
    <>
      <Box
        sx={{
          margin: "10px 5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography
          classes={{ root: classes.agentTableHeading }}
          variant="subtitle1"
          component="p"
        >
          {totalAgents} {/* Active */} Agents
        </Typography>
        <TextField
          select
          classes={{ root: classes.detailInput }}
          label="Status"
          value={search ? "all" : status}
          onChange={(e) => setStatus(e.target.value)}
          variant="outlined"
          sx={{ width: 200, background: "white" }}
          size="small"
        >
          <MenuItem value={"all"}>All</MenuItem>

          {statusData.map((item) => (
            <MenuItem key={item} value={item.value}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box className={classes.tableRoot}>
        <DataGridPro
          columns={columns}
          rows={
            status !== "all" && !search
              ? data?.filter((item) => item.status === status)
              : data
          }
          loading={loading ? true : false}
          rowHeight={56}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: TableSkeleton,
          }}
          // checkboxSelection
          onRowClick={(param) => onRowClick(param)}
          onRowsScrollEnd={(e) => {
            if (totalAgents > e.virtualRowsCount && !loading) {
              setFilters((filters) => ({
                ...filters,
                pageNumber: filters.pageNumber + 1,
              }));
            }
          }}
        />
      </Box>
    </>
  );
};

export default withStyles(styleSheet, { name: "agentRosterTableStyle" })(
  AgentRosterTable
);
