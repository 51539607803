import React from "react";
import { useStyles } from "./style.js";
import { Box, Container, Typography } from "@mui/material";
import DetailsGrid from "./DetailsGrid.jsx";
import media from "../../assets/images/viewcma/media.png";
import moment from "moment";

function SubjectProperty({ data = {} }) {
  const classes = useStyles();

  const satatichead = {
    propertyName: "Subject Property",
    address1: "2960 ANGEL OAK CIRCLE",
    address2: "DACULA, GA 30019",
  };
  const totalCount =
    (parseInt(data?.BathroomsFull, 10) || 0) +
    (parseInt(data?.BathroomsHalf) / 2 || 0);

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.headerSection} p={{ xs: 0, md: 2 }}>
          <Box className={classes.propertyImage}>
            <img
              className={classes.image}
              src={data?.MediaURL || media}
              alt="media"
            />
          </Box>
          <Typography className={`${classes.fontFamily} ${classes.name}`}>
            {satatichead.propertyName}
          </Typography>
          <Typography className={`${classes.fontFamily} ${classes.address1}`}>
            {data?.StreetNumber || ""}
          </Typography>
          <Typography className={`${classes.fontFamily} ${classes.address2}`}>
            {data?.StreetName}
          </Typography>
        </Box>
        <Box className={`${classes.tableContainer} alternateDiv`}>
          <DetailsGrid
            detailName="Price"
            shortName="Price"
            detailValue={data?.ListPrice}
          />
          <DetailsGrid
            detailName="Status"
            shortName="Status"
            detailValue={data?.MlsStatus}
          />
          <DetailsGrid
            detailName="Beds"
            shortName="Beds"
            detailValue={data?.BedroomsTotal}
          />
          <DetailsGrid
            detailName="Baths"
            detailValue={totalCount || 0}
            shortName="Baths"
          />
          <DetailsGrid
            detailName="Building Area"
            detailValue={data?.BuildingAreaTotal}
            shortName="SQFT"
          />
          <DetailsGrid
            detailName="Tax Amount"
            detailValue={`$${data?.TaxAnnualAmount}`}
            shortName="Tax Amt"
          />
          <DetailsGrid
            detailName="Listing Date"
            shortName="List Date"
            detailValue={`${
              data?.MlsStatus.toLowerCase() === "pending" ||
              data?.MlsStatus.toLowerCase() === "under contract" ||
              data?.MlsStatus.toLowerCase() === "active under contract"
                ? data?.OnMarketDate
                  ? moment(data?.OnMarketDate).format("M/D/YY")
                  : data?.ListingContractDate
                  ? moment(data?.ListingContractDate).format("M/D/YY")
                  : "-"
                : data?.ListingContractDate
                ? moment(data?.ListingContractDate).format("M/D/YY")
                : "-"
            }`}
          />
          <DetailsGrid
            detailName="Closing Date"
            shortName="Sold Date"
            detailValue={`${
              data?.MlsStatus.toLowerCase() === "sold" ||
              data?.MlsStatus.toLowerCase() === "closed"
                ? data?.CloseDate
                  ? moment(data?.CloseDate).format("M/D/YY")
                  : "-"
                : "-"
            }`}
          />
          <DetailsGrid
            detailName="DOM"
            shortName="DOM"
            detailValue={`${data?.DaysOnMarket}`}
          />
          <DetailsGrid
            detailName="LOT"
            detailValue={`${
              data?.LotSizeAcres ? Math.round(data?.LotSizeAcres * 10) / 10 : 0
            }`}
            shortName="LOT"
          />
          <DetailsGrid
            detailName="Year Built"
            detailValue={data?.YearBuilt}
            shortName="Year Blt"
          />
          <DetailsGrid
            detailName="Pool Feature"
            detailValue={data?.PoolFeatures}
            shortName="Pool Feat."
          />
          <DetailsGrid
            detailName="Garage"
            detailValue={data?.GarageSpaces}
            shortName="Price"
          />
          <DetailsGrid
            detailName="Basement"
            detailValue={data?.Basement}
            shortName="Basement"
          />
          <DetailsGrid
            detailName="Cst. Material"
            detailValue={data?.ConstructionMaterials}
            shortName="Cst. Material"
          />
          <DetailsGrid
            detailName="Arch Style"
            detailValue={data?.ArchitecturalStyle}
            shortName="Arch Style"
          />
          <DetailsGrid
            detailName="Fireplace"
            detailValue={data?.FireplacesTotal}
            shortName="Fireplace"
          />
          <DetailsGrid
            detailName="Levels"
            detailValue={data?.Levels}
            shortName="Levels"
          />
          <DetailsGrid
            detailName="Lot Desc."
            detailValue={
              data?.LotSizeAcres ? Math.round(data?.LotSizeAcres * 10) / 10 : 0
            }
            shortName="Lot Desc."
          />
          <DetailsGrid
            detailName="Condition"
            detailValue={data?.Condition}
            shortName="Condition"
          />
        </Box>
      </Container>
      <Box className={classes.bottomContainer}></Box>
    </>
  );
}

export default SubjectProperty;
