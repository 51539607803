export const styleSheet = () => ({
  categorySectionArea: {
    minHeight: "100vh !important",
    padding: "50px 0px !important",
  },
  categorySection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    flexWrap: "wrap !important",
    gap: "25px 15px !important",
  },
  categorySectionItem: {
    cursor: "pointer !important",
    width: "285px !important",
  },
  categorySectionItemTop: {
    width: "100% !important",
    height: "170px !important",
    backgroundColor: "#D9D9D9 !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    "& img": {
      width: "150px !important",
      height: "90% !important",
      objectFit: "cover !important",
    },
  },
  categorySectionItemContent: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    marginTop: "8px  !important",
    fontWeight: "600 !important",
    textTransform: "uppercase !important",
  },
  categoryHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    marginBottom: "25px !important",
  },
  categoryHeaderLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
    cursor: "pointer !important",
  },
  categoryHeaderLeftContent: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  categoryHeaderRight: {
    fontSize: "20px !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    "& span": {
      color: "rgba(0, 0, 0, 0.40) !important",
    },
  },
});
