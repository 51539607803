import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { sortQuery } from "./getPropertyList";

export const updateSearchQuery = createAsyncThunk(
  "LeadSavedSearches/updateSearchQuery",
  async (data, thunkAPI) => {
    try {
      let searchParams = {
        ...thunkAPI.getState()?.leads?.savedSearches.searchParams,
      };
      let site_id =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.site_id?._id;
      let token =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.token;
      let query = "";
      let agentId = "";
      if (data?.agentId) {
        agentId = `?agent=${data?.agentId}`;
      }
      Object.keys(searchParams).forEach((k) => {
        if (!searchParams[k] && searchParams[k] !== 0) {
          delete searchParams[k];
        } else {
          if (query && k !== "sort" && k !== "start" && k !== "rows") {
            query =
              query +
              ` AND ${k}:${
                k === "City" &&
                !searchParams[k]?.includes("OR") &&
                searchParams[k]?.includes(" ") &&
                !searchParams[k]?.includes(`"`)
                  ? `"${searchParams[k]}"`
                  : searchParams[k]
              }`;
          } else if (k !== "sort" && k !== "start" && k !== "rows") {
            query = `${k}:${searchParams[k]}`;
          }
        }
      });
      // console.log(query, "query", sortQuery(query), "sortQuery(query)");

      let url = `${process.env.REACT_APP_IDX_BASE_URL}api/v1/properties/${site_id}/${data?.searchId}${agentId}`;
      const resp = await axios.put(
        url,
        {
          q: data?.isAND ? query : sortQuery(query),
          fq: data?.fq,
          sort: searchParams?.sort,
          start: searchParams?.start,
          rows: searchParams?.rows,
          overwrite: true,
          geoOperator: data?.isAND ? "AND" : "OR",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (resp?.data && data?.handleSuccess) {
        data?.handleSuccess();
      }
      return { ...resp.data, title: data?.title, isAND: data?.isAND };
    } catch (error) {
      if (error.response) {
        if (data?.handleError) {
          data?.handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (data?.handleError) {
          data?.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
