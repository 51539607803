import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
  Chip,
  Autocomplete,
  Checkbox,
  ListItem,
  ListItemText,
  Tooltip,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import { useDispatch, useSelector } from "react-redux";
import { addAgentRecruitmentAppointments } from "../../../../redux/agents/agentRecruitment/addAgentRecruitmentAppointments";
import { updateAgentRecruitmentAppointment } from "../../../../redux/agents/agentRecruitment/updateAgentRecruitmentAppointment";
import { deleteAgentRecruitmentAppointment } from "../../../../redux/agents/agentRecruitment/deleteAgentRecruitmentAppointment";
import { getAppointmentTypes } from "../../../../redux/agents/agentRecruitment/getRecruitmentAppointmentTypes";
import {
  dateFormat,
  dateFormatTimezone,
  minusTimezoneOffset,
} from "../../../../utils/dateFormat";
import { stringAvatar } from "../../../../utils/avatarInitial";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import WarningAmberSharpIcon from "@mui/icons-material/WarningAmberSharp";
import ResponseAlert from "../../../../components/responseAlert";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import moment from "moment";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const inviteeFnc = (arr) => {
  const { lead_emails, id, MemberEmail, MemberFullName, memberPicture } = arr;
  let primary = lead_emails?.find((l) => l.isPrimary);
  return [
    {
      group: null,
      name: MemberFullName,
      agentRecruitmentId: id,
      email: primary ? primary.value : MemberEmail,
      realtor_agent: true,
      picture: memberPicture || "",
    },
  ];
};

export const AddAppointmentsModal = ({
  closeModal,
  state,
  usersList,
  type,
  initialValue,
  id,
  agentDetail,
  timezone,
}) => {
  const agentProfile = useSelector((item) => item.agentProfile.agentProfile);
  const [newAppointment, setNewAppointment] = useState({
    title: "",
    description: "",
    staffDescription: "",
    typeId: "",
    start: null,
    end: null,
    type: "",
    location: "",
    invitees: [],
    allDay: false,
    outcome: "",
    assignedTo: "",
  });
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dateRef = useRef(null);
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (item) => item.agentProfile.agentProfile.modalActions
  );
  const appointmentTypes = useSelector(
    (item) => item.agentRecruitment?.appointmentTypes
  );

  useEffect(() => {
    const {
      title,
      typeId,
      location,
      description,
      staffDescription,
      allDay,
      start,
      end,
      id,
      realtor_appointment_invitees,
      outcome,
      assignedTo,
    } = initialValue;
    if (type === "Update" && id) {
      setNewAppointment({
        title,
        id,
        type: initialValue?.type,
        typeId,
        location,
        description,
        staffDescription,
        allDay,
        start,
        end,
        invitees: realtor_appointment_invitees,
        outcome,
        assignedTo,
      });
    } else {
      let defaultValue = `${
        agentDetail?.lead_phones?.length ? "Phone(s):" : ""
      } ${
        agentDetail?.lead_phones?.length
          ? agentDetail?.lead_phones?.map((item) => item.value).join(", ")
          : ""
      }\n
    ${agentDetail?.lead_emails?.length ? "Email(s):" : ""} ${
        agentDetail?.lead_emails?.length
          ? agentDetail?.lead_emails?.map((item) => item.value).join(", ")
          : ""
      }`;
      const zz = {
        ...newAppointment,
        staffDescription: defaultValue,
        invitees: inviteeFnc(agentProfile?.list),
      };
      setNewAppointment(zz);
    }
  }, [type, initialValue?.id, agentProfile?.list]);

  useEffect(() => {
    if (appointmentTypes?.data?.length === 0) {
      dispatch(getAppointmentTypes());
    }
  }, []);

  // useEffect(() => {
  //   if (state) {
  //     let defaultValue = `${agentDetail?.lead_phones?.length ? "Phone(s):" : ""} ${
  //       agentDetail?.lead_phones?.length ? agentDetail?.lead_phones?.map((item) => item.value).join(", ") : ""
  //     }\n
  //   ${agentDetail?.lead_emails?.length ? "Email(s):" : ""} ${
  //       agentDetail?.lead_emails?.length ? agentDetail?.lead_emails?.map((item) => item.value).join(", ") : ""
  //     }`;
  //     setNewAppointment({
  //       ...newAppointment,
  //       staffDescription: defaultValue,
  //     });
  //   }
  // }, [state]);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: `You have successfully ${
        type === "Update" ? "updated" : "created"
      } the appointment`,
      errorType: "success",
      isOpen: true,
    });
    closeModal();
    setNewAppointment({
      title: "",
      description: "",
      staffDescription: "",
      typeId: "",
      start: null,
      end: null,
      type: "",
      location: "",
      invitees: [],
      allDay: false,
      outcome: "",
      assignedTo: "",
    });
  };
  const handleSuccessDelete = () => {
    setErrorAlert({
      errorMsg: `You have successfully deleted the appointment`,
      errorType: "success",
      isOpen: true,
    });
    closeModal();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const handleCheckTimeDiff = () => {
    const moment1 = moment(newAppointment.start);
    const moment2 = moment(dateRef.current);
    // Calculate difference in minutes
    const minutesDifference = moment2.diff(moment1, "minutes");
    if (minutesDifference < 1) {
      setErrorAlert({
        errorMsg: `Your appointment end time is less than or equal to start time, please select again`,
        errorType: "warning",
        isOpen: true,
      });
      return false;
    }
    if (minutesDifference > 120) {
      setErrorAlert({
        errorMsg: `Your appointment during is greater than 2 hours`,
        errorType: "warning",
        isOpen: true,
      });
      return false;
    }
    return true;
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!newAppointment.type) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the appointment type",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!handleCheckTimeDiff()) {
      isFormValid = false;
    } else if (!newAppointment.assignedTo) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the assigned to user",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!newAppointment.title) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the appointment title",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["title"] = "Please enter the appointment title";
    } else if (!newAppointment.description) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the appointment description",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["description"] = "Please enter the appointment description";
    } else if (!newAppointment.start) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the start date",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["start"] = "Please select the start date";
    } else if (!newAppointment.end) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the end date",
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["end"] = "Please select the end date";
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const saveAppointments = () => {
    if (handleValidate()) {
      if (type === "Update") {
        const schema = {
          schema: {
            ...newAppointment,
            staffDescription: newAppointment?.staffDescription,
            agentRecruitmentId: id,
            phones: agentDetail?.lead_phones?.length
              ? agentDetail?.lead_phones?.map((item) => item.value)
              : [],
            start: minusTimezoneOffset(
              new Date(newAppointment.start),
              timezone
            ),
            end: minusTimezoneOffset(new Date(newAppointment.end), timezone),
          },
          id: newAppointment.id,
          handleSuccess,
          handleError,
        };
        dispatch(updateAgentRecruitmentAppointment({ ...schema }));
      } else {
        dispatch(
          addAgentRecruitmentAppointments({
            schema: {
              ...newAppointment,
              agentRecruitmentId: id,
              phones: agentDetail?.lead_phones?.length
                ? agentDetail?.lead_phones?.map((item) => item.value)
                : [],
              start: minusTimezoneOffset(newAppointment.start, timezone),
              end: minusTimezoneOffset(newAppointment.end, timezone),
            },
            handleSuccess,
            handleError,
          })
        );
      }
    }
  };
  const deleteAppointmentFnc = () => {
    if (type === "Update") {
      const { id } = newAppointment;
      dispatch(
        deleteAgentRecruitmentAppointment({
          id,
          handleSuccessDelete,
          handleError,
        })
      );
    }
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "25px" } }}
        loading={loading}
        dialogHead={<DialogHead type={type} />}
        onSave={saveAppointments}
        dialogBody={
          <DialogBody
            newAppointment={newAppointment}
            setErrMsg={setErrMsg}
            errMsg={errMsg}
            setNewAppointment={setNewAppointment}
            usersList={usersList}
            timezone={timezone}
            setErrorAlert={setErrorAlert}
            appointmentTypes={appointmentTypes}
            type
            dateRef={dateRef}
            handleCheckTimeDiff={handleCheckTimeDiff}
          />
        }
        dialogFooter={<DialogFooter />}
        state={state}
        closeModal={() => {
          closeModal();
          setNewAppointment({
            title: "",
            description: "",
            staffDescription: "",
            typeId: "",
            start: null,
            end: null,
            type: "",
            location: "",
            invitees: [],
            allDay: false,
            outcome: "",
            assignedTo: "",
          });
        }}
        maxWidth="sm"
        buttonWidth="100px"
        fullWidth={true}
        type={type}
        onDelete={deleteAppointmentFnc}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
};

// Components
const AddAppointmentForm = ({
  mb,
  newAppointment,
  setNewAppointment,
  usersList,
  type,
  errMsg,
  setErrMsg,
  timezone,
  setErrorAlert,
  appointmentTypes,
  dateRef,
  handleCheckTimeDiff,
}) => {
  const changeFromDateFn = (newValue) => {
    const newData = { ...newAppointment };
    newData.start = newValue;
    setNewAppointment(newData);
  };
  const changeToendFn = (newValue) => {
    const newData = { ...newAppointment };
    newData.end = newValue;
    dateRef.current = newValue;
    setNewAppointment(newData);
    setErrMsg({});
  };

  const handleUpdateValue = (field, value) => {
    const newData = { ...newAppointment };
    newData[field] = value;
    if (field === "type") {
      newData.outcome = "";
    }
    if (field === "assignedTo") {
      let newInvitees = [...newData.invitees]?.filter(
        (item) => item.userId !== newAppointment.assignedTo
      );
      let newInviteUser = usersList?.find((item) => item.id === value);
      if (newInviteUser) {
        let obj = {
          name: newInviteUser.full_name,
          userId: newInviteUser.id,
          email: newInviteUser.email,
          picture:
            newInviteUser?.profile_images?.slack_profile_img_thumbnail ||
            newInviteUser?.profile_images?.slack_profile_img,
        };
        newInvitees.push(obj);
      }
      newData.invitees = newInvitees;
    }
    setNewAppointment(newData);
    setErrMsg({});
  };
  // console.log(newAppointment, "newAppointment");
  return (
    <>
      <Box sx={{ display: "block", marginBottom: "10px" }}></Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ flexGrow: 1, marginBottom: mb || 0 }}
      >
        <Grid item sm={12}>
          <Input
            onChange={(e) => handleUpdateValue("title", e.target.value)}
            value={newAppointment.title}
            placeholder="Title"
            label={
              <Typography component="p" variant="subtitle1">
                Title
              </Typography>
            }
            size="small"
            error={errMsg["title"]}
            helperText={errMsg["title"]}
          />
        </Grid>
        <Grid item sm={12} sx={{ marginTop: "4px" }}>
          <Input
            onChange={(e) => handleUpdateValue("description", e.target.value)}
            value={newAppointment?.description}
            placeholder="Description"
            multiline
            rows={4}
            label={
              <Typography component="p" variant="subtitle1">
                Description
              </Typography>
            }
            size="small"
            error={errMsg["description"]}
            helperText={errMsg["description"]}
          />
        </Grid>
        {newAppointment?.type &&
        appointmentTypes?.data?.find(
          (item) => item.type === newAppointment?.type
        )?.outcome?.length ? (
          <Grid item sm={12} sx={{ marginTop: "4px" }}>
            <Typography component="p" variant="subtitle1">
              Outcome
            </Typography>
            <TextField
              value={newAppointment?.outcome}
              onChange={(e) => handleUpdateValue("outcome", e.target.value)}
              fullWidth
              size="small"
              select
            >
              {appointmentTypes?.data
                ?.find((item) => item.type === newAppointment?.type)
                ?.outcome?.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
            </TextField>
          </Grid>
        ) : null}
        <Grid item sm={12} sx={{ marginTop: "4px" }}>
          <Typography component="p" variant="subtitle1">
            Assigned To
          </Typography>
          <Select
            fullWidth
            labelId="demo-simple-select-standard-label"
            id="user-select-for-appointment"
            value={newAppointment.assignedTo}
            onChange={(e) => handleUpdateValue("assignedTo", e.target.value)}
            sx={{ "& .MuiSelect-select": { display: "flex" } }}
            size="small"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {usersList.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                <Avatar
                  src={
                    item?.profile_images?.profile_img ||
                    item?.profile_images?.slack_profile_img ||
                    item?.profile_images?.slack_profile_img_thumbnail
                  }
                  sx={{
                    width: "25px",
                    height: "25px",
                    mr: "7px",
                    fontSize: "13px",
                  }}
                  variant="circle"
                >{`${item.first_name?.slice(0, 1)}${item.last_name?.slice(
                  0,
                  1
                )}`}</Avatar>
                {item.first_name} {item.last_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={12} sx={{ marginTop: "4px" }}>
          <Input
            onChange={(e) =>
              handleUpdateValue("staffDescription", e.target.value)
            }
            value={newAppointment?.staffDescription}
            placeholder="Eyes Only Description"
            multiline
            rows={3}
            size="small"
            label={
              <Typography component="p" variant="subtitle1">
                Eyes Only Description
              </Typography>
            }
          />
        </Grid>
        <br />
        <Grid item sm={6} sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              value={newAppointment?.start}
              minutesStep={15}
              fullWidth
              error
              disablePast
              label={
                <Typography component="p" variant="subtitle1">
                  Start Date & Time
                </Typography>
              }
              onChange={changeFromDateFn}
              renderInput={(params) => (
                <Input
                  fullWidth
                  {...params}
                  error={errMsg["start"]}
                  helperText={errMsg["start"]}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={6} sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              minutesStep={15}
              fullWidth
              value={newAppointment?.end}
              onChange={changeToendFn}
              error={newAppointment?.end ? true : false}
              minDateTime={newAppointment?.start}
              disablePast
              disabled={newAppointment?.start ? false : true}
              label={
                <Typography component="p" variant="subtitle1">
                  End Date & Time
                </Typography>
              }
              onClose={handleCheckTimeDiff}
              renderInput={(params) => (
                <Input
                  fullWidth
                  {...params}
                  error={errMsg["end"]}
                  helperText={errMsg["end"]}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item sm={12} sx={{ marginTop: "10px" }}>
          <Input
            onChange={(e) => handleUpdateValue("location", e.target.value)}
            value={newAppointment.location}
            placeholder="Location..."
            label={
              <Typography component="p" variant="subtitle1">
                Location
              </Typography>
            }
            size="small"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ flexGrow: 1, marginBottom: mb || 0 }}
      >
        <Grid item sm={12}>
          <FormControl sx={{ mt: 5, minWidth: "100%" }}>
            <TagsInput
              fullWidth
              variant="outlined"
              newAppointment={newAppointment}
              setNewAppointment={setNewAppointment}
              name="tags"
              usersList={usersList}
              type={type}
            />
          </FormControl>
          <FormControl
            sx={{
              mt: 5,
              position: "absolute",
              top: "-10px",
              right: "30px",
            }}
          >
            <ButtonGroups
              id="appointment-type-menu"
              bgColor="#0B0909"
              bgColorHover="#0B0909"
              placement={"bottom"}
              height="38.5px"
              minWidth="185px"
              size="medium"
              color="white"
              fontSize="13px"
              options={
                appointmentTypes?.data?.length
                  ? appointmentTypes?.data?.map((item) => ({
                      title: item.type,
                      value: item.type,
                    }))
                  : [
                      { title: "Intro", value: "Intro" },
                      { title: "Face 2 Face", value: "Face 2 Face" },
                      { title: "Other", value: "Other" },
                    ]
              }
              value={newAppointment.type}
              onChangeMenu={(value) => handleUpdateValue("type", value)}
              placeholder="Appointment Type"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

const DialogHead = ({ type }) => (
  <DialogTitle>
    <Typography
      component="h4"
      variant="h4"
      sx={{ fontSize: "24px", fontFamily: "AvenirNext-400", mt: "15px" }}
    >
      {type === "Update" ? "Update Appointment" : "Create Appointment"}
    </Typography>
  </DialogTitle>
);

const DialogBody = ({
  newAppointment,
  setNewAppointment,
  usersList,
  type,
  errMsg,
  setErrMsg,
  timezone,
  setErrorAlert,
  appointmentTypes,
  dateRef,
  handleCheckTimeDiff,
}) => (
  <DialogContent>
    <AddAppointmentForm
      setNewAppointment={setNewAppointment}
      setErrMsg={setErrMsg}
      errMsg={errMsg}
      newAppointment={newAppointment}
      usersList={usersList}
      type={type}
      timezone={timezone}
      setErrorAlert={setErrorAlert}
      appointmentTypes={appointmentTypes}
      dateRef={dateRef}
      handleCheckTimeDiff={handleCheckTimeDiff}
    />
    <br />
  </DialogContent>
);

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);

const TagsInput = ({ newAppointment, setNewAppointment, type, usersList }) => {
  const { agentProfile } = useSelector((item) => item.agentProfile);
  const [selectedItem, setSelectedItem] = useState([]);
  const [inviteeWithoutEmail, setInviteeWithoutEmail] = useState([]);
  const [userlist, setUserList] = useState([]);
  useEffect(() => {
    let arr = [];
    arr = arr?.concat(inviteeFnc(agentProfile?.list));
    arr = arr?.concat(usersList);
    const arrUpdated = arr.map((item) => {
      return {
        group: item?.role?.name,
        ...item,
        name: item.full_name || item.name,
        picture: item?.profile_images?.profile_img || "",
      };
    });
    setUserList(arrUpdated);
  }, []);
  useEffect(() => {
    setSelectedItem(newAppointment?.invitees);
  }, [newAppointment?.invitees?.length]);

  useEffect(() => {
    const witoutEmailItems = selectedItem.filter(
      (si) =>
        si.email === undefined || si.email === null || si.email.length === 0
    );
    setInviteeWithoutEmail(witoutEmailItems);
  }, [selectedItem]);
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    let admins = users.filter((item) => item?.role?.name === "Admin");
    let teamLeader = users.filter((item) => item?.role?.name === "Team Leader");
    let otherUsers = users.filter(
      (item) =>
        item?.role?.name !== "Team Leader" &&
        item?.role?.name !== "Admin" &&
        item?.role?.name !== "Agents"
    );
    return [
      ...otherUsers?.filter((item) => item.group !== undefined),
      ...teamLeader,
      ...admins,
      ...agents,
    ];
  };
  return (
    <React.Fragment>
      <Box>
        <Autocomplete
          multiple
          limitTags={4}
          disableClearable
          sx={{
            "& label": {
              fontWeight: "400",
              fontSize: "1rem",
              lineHeight: "1.75",
            },
            "& .MuiAutocomplete-endAdornment": {
              display: "none",
            },
          }}
          id="invitee-appointment-tags"
          selectOnFocus
          clearOnBlur
          disableCloseOnSelect
          autoHighlight
          groupBy={(option) => option?.group}
          options={
            CUstomSortUsers(
              userlist
            ) /* .sort((x, y) => (x.group < y.group ? -1 : x.group > y.group ? 1 : 0)) */
          }
          getOptionLabel={(option) => option?.name}
          value={selectedItem}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map(({ name, full_name, email, picture }, index) => (
              <Tooltip
                placement="top"
                title={email}
                arrow
                followCursor
                disableHoverListener={name ? false : true}
              >
                <Chip
                  label={name || email}
                  {...getTagProps({ index })}
                  key={index}
                  onDelete={() => {
                    const des = [...selectedItem];
                    des.splice(index, 1);
                    const appointments = {
                      ...newAppointment,
                      invitees: des,
                    };
                    setNewAppointment(appointments);
                    setSelectedItem(des);
                  }}
                  avatar={
                    <Avatar
                      sx={{ fontSize: "16px" }}
                      {...stringAvatar(
                        name || full_name || email,
                        picture || false
                      )}
                    />
                  }
                ></Chip>
              </Tooltip>
            ))
          }
          renderOption={(a, b, c, d, e) => {
            return (
              <ListItem key={b.name} disablePadding dense {...a}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    c.selected ||
                    selectedItem.some((ii) => {
                      return ii.email === b.email;
                    })
                  }
                />
                <ListItemText
                  id={b.name}
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Avatar
                        sx={{ height: 30, width: 30, fontSize: "14px" }}
                        {...stringAvatar(b.name, b?.picture || false)}
                      />
                      {b.name}
                    </Box>
                  }
                />
              </ListItem>
            );
          }}
          onKeyDown={(a) => {
            if (a.code === "Backspace") {
              const key_code = [...selectedItem];
              key_code.pop();
              const appointments = {
                ...newAppointment,
                invitees: key_code,
              };
              setNewAppointment(appointments);
              setSelectedItem(key_code);
              return;
            }
          }}
          onChange={(a, b, c, d, e) => {
            let schema = null;
            if (typeof d?.option == "object") {
              schema = d?.option;
            }
            if (typeof a.target.value === "string") {
              return null;
            }

            const duplicatedValues = selectedItem.findIndex(
              (i) => i.email === schema?.email
            );

            if (!!schema) {
              if (schema.group) {
                schema.userId = schema.id;
              }
              if (duplicatedValues === -1) {
                const kk = [...selectedItem];
                kk.push({
                  name: schema.full_name,
                  userId: schema.id,
                  email: schema.email,
                  picture:
                    schema?.profile_images?.slack_profile_img_thumbnail ||
                    schema?.profile_images?.slack_profile_img,
                });
                setSelectedItem(kk);
                const appointments = {
                  ...newAppointment,
                  invitees: kk,
                };
                setNewAppointment(appointments);
                return;
              } else {
                const kk = [...selectedItem];
                kk.splice(duplicatedValues, 1);
                setSelectedItem(kk);
                const appointments = {
                  ...newAppointment,
                  invitees: kk,
                };
                setNewAppointment(appointments);
              }
            }
          }}
          renderInput={(params) => (
            <>
              <Input
                {...params}
                label="Invitees"
                sx={{ "& .MuiInputBase-root": { padding: "5px" } }}
                size="small"
              />
            </>
          )}
        />
      </Box>
      {inviteeWithoutEmail.length !== 0 && (
        <Box
          sx={{
            color: "red",
            display: "flex",
            gap: "20px",
            margin: "20px 10px",
            fontSize: "18px",
            alignItems: "center",
          }}
        >
          <WarningAmberSharpIcon fontSize="large" />
          <div>
            There is no email attached to these records. They will not receive
            invites.
            <ul style={{ margin: "0px 10px", padding: "10px" }}>
              {inviteeWithoutEmail.map((iw) => (
                <li style={{ fontSize: "14px" }}>{iw.name}</li>
              ))}
            </ul>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};
