import React from "react";
import { Box, Stack, Chip } from "@mui/material";
import Avatar from "../../uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import houseIcon from "../../../assets/house.png";
import avtarIcon from "../../../assets/avtar.png";
import momentTZ from "moment-timezone";
import { useNavigate } from "react-router-dom";
function Transactions(props) {
  let { classes, data, key, setOpenSearch } = props;
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        setOpenSearch(false);
        navigate(`/transaction/contracts/contract-detail/${data.id}/documents`);
      }}
      key={key}
      className={classes.transactionsArea}
    >
      <Box className={classes.listingAreaLeft}>
        <Box className={classes.listingAreaLeftImg}>
          <img src={data?.property_img || houseIcon} alt="houseIcon" />
        </Box>
        <Box className={classes.listingAreaLeftContent}>
          <Box className={classes.listingAreaLeftContentHead}> {data?.property_address}</Box>
          <Box className={classes.listingAreaLeftContentAdd}>
            {data?.city}, {data?.state} {data?.zipcode}
          </Box>
          <Box>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Box className={classes.listingAreaLeftContentPrize}>${new Intl.NumberFormat().format(data?.price || "0")}</Box>
              <Chip
                size="small"
                label={data?.status || "Active"}
                sx={{ color: data?.status === "terminated" ? "red" : "#2E7D32", textTransform: "capitalize" }}
                variant="outlined"
              />
              <Box>
                <Box className={classes.closingDateStat}>
                  {data?.closing_date ? momentTZ.tz(data.closing_date, "America/New_York")?.format("l") : ""}
                </Box>
                <Box className={classes.listingAreaRightStatBottom}>Closing Date</Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box className={classes.listingAreaRight}>
        <Box className={classes.transactionAreaRightTop}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Box sx={{mr:"10px"}}>
              {data?.agent?.first_name} {data?.agent?.last_name}
            </Box>
            <Avatar sx={{border:"1px solid lightgrey"}} variant="rounded" src={data?.agent?.profile_images?.profile_img || avtarIcon} >NA</Avatar>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "TransactionsStyle" })(Transactions);
