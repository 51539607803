import { NameCard, PhoneCell, PriceCell, StageCell } from "./TableCellsLead";
import { date_or_price, include, phone, price } from "./filterOperatorSchemas";
import moment from "moment";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, ListItemText, Stack, Tooltip } from "@mui/material";
import Avatar from "../../../../components/uiElements/avatar";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};
export const getColumns = (
  order,
  stagesList,
  usersList,
  tagsList,
  dialNumber,
  handleOpenWidget
) => {
  let defaultCols = {
    name: {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      renderCell: ({ row }) => {
        if (row.name) {
          return (
            <Box>
              <NameCard
                name={row?.name ? row.name : "Null"}
                lastActivity={row.lastActivity}
                avatar={""}
                market={row.source}
              />
            </Box>
          );
        } else {
          return <p>--</p>;
        }
      },
      props: {
        canSort: true,
        icon: TextFieldsIcon,
        type: "Text",
      },
      type: "text",
      sortable: false,
      targetIndex: 4,
      hidden: true,
    },

    phone: {
      field: "phone",
      headerName: "Phones",
      minWidth: 220,
      renderCell: ({ row }) => {
        return row.phones?.length && row.phones[0]?.value ? (
          <Stack alignItems={"center"} direction="row" spacing={1}>
            {" "}
            <Avatar
              onClick={() => {
                if (row.phones[0]?.status === "Valid") {
                  dialNumber(
                    row.phones[0]?.value,
                    null,
                    row?.name,
                    null,
                    row.id
                  );
                }
              }}
              sx={{
                bgcolor: "rgba(81, 172, 10, 0.96)",
                width: "28px",
                height: "28px",
                cursor: "pointer",
              }}
              fontSize="small"
            >
              <PhoneIphoneRoundedIcon fontSize="small" />
            </Avatar>{" "}
            <Avatar
              sx={{
                bgcolor: "rgba(174, 52, 193, 0.79)",
                width: "28px",
                height: "28px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleOpenWidget("sendTextOpen", row.phones[0]?.value)
              }
            >
              <TextsmsRoundedIcon sx={{ fontSize: "18px" }} fontSize="small" />
            </Avatar>{" "}
            <Box sx={{ fontWeight: "500" }}>{row.phones[0]?.value}</Box>
          </Stack>
        ) : (
          "--"
        );
      },
      props: {
        canSort: false,
        icon: LocalPhoneIcon,
        type: "Phone",
      },
      filterOperators: phone,
      type: "phone",
      sortable: false,
    },

    emails: {
      field: "emails",
      headerName: "Email",
      renderCell: ({ row }) => {
        return row?.emails?.length &&
          row?.emails.find((item) => item.isPrimary)?.value ? (
          <Stack alignItems={"center"} direction="row" spacing={1}>
            {" "}
            <Avatar
              sx={{
                bgcolor: "#7AE3FA",
                width: "28px",
                height: "28px",
                cursor: "pointer",
              }}
              fontSize="small"
              onClick={() =>
                handleOpenWidget(
                  "sendEmailOpen",
                  null,
                  row?.emails.find((item) => item.isPrimary)?.value
                )
              }
            >
              <EmailRoundedIcon fontSize="small" />
            </Avatar>{" "}
            <Box sx={{ fontWeight: "500" }}>
              {row?.emails.find((item) => item.isPrimary)?.value}
            </Box>
          </Stack>
        ) : (
          "--"
        );
      },
      props: {
        canSort: false,
        icon: TextFieldsIcon,
        type: "Text",
      },
      type: "text",
      sortable: false,
      minWidth: 120,
    },

    claimedAt: {
      field: "claimedAt",
      headerName: "Claimed At",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.claimedAt
              ? moment(row?.claimedAt).format("YYYY-MM-DD HH:mm:ss")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Claimed At",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
      minWidth: 150,
    },
    stage: {
      field: "stage",
      headerName: "Stage",
      minWidth: 130,
      renderCell: ({ row }) => {
        return row?.stage ? (
          <Box>
            <StageCell name={row.stage} />
          </Box>
        ) : (
          "--"
        );
      },
      props: {
        data: stagesList,
        canSort: true,
        icon: MenuIcon,
        type: "Stage",
      },
      type: "include/exclude",
      sortable: false,
      filterOperators: include,
    },
    price: {
      field: "price",
      headerName: "Price",
      renderCell: (params) => {
        return params?.row?.price ? (
          <Box>
            <PriceCell price={params?.row?.price} />
          </Box>
        ) : (
          "--"
        );
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "numbers",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    tags: {
      field: "tags",
      headerName: "Tags",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.lead_tags?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.lead_tags.map((item, index) => item.tag).join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      props: {
        data: tagsList,
        icon: AirplaneTicketIcon,
        type: "Tags",
      },
      type: "include/exclude/empty",
      sortable: false,
      filterOperators: include,
    },
    source: {
      field: "source",
      headerName: "source",
      minWidth: 200,
      renderCell: ({ row }) => {
        if (row.source) {
          return <p className="font-12 font-md">{row.source}</p>;
        } else {
          return "--";
        }
      },
      props: {
        canSort: true,
        icon: TextFieldsIcon,
        type: "Text",
      },
      type: "text",
      sortable: false,
      targetIndex: 4,
      hidden: true,
    },

    lastEmail: {
      field: "lastEmail",
      headerName: "Last Email",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastEmail ? moment(row?.lastEmail).format("ll") : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Email",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },

    lastReceivedEmail: {
      field: "lastReceivedEmail",
      headerName: "Last Received Email",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastReceivedEmail
              ? moment(row?.lastReceivedEmail).format("ll")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Received Email",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
    lastSentBatchEmail: {
      field: "lastSentBatchEmail",
      headerName: "Last Sent Batch Email",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastSentBatchEmail
              ? moment(row?.lastSentBatchEmail).format("ll")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Sent Batch Email",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },

    lastSentEmail: {
      field: "lastSentEmail",
      headerName: "Last Sent Email",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastSentEmail
              ? moment(row?.lastSentEmail).format("ll")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Sent Email",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
    emailSent: {
      field: "emailSent",
      headerName: "Email Sent",
      renderCell: ({ row }) => {
        return <Box>{row?.emailSent ? row?.emailSent : "--"}</Box>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
    emailsReceived: {
      field: "emailsReceived",
      headerName: "Email Received",
      renderCell: ({ row }) => {
        return <Box>{row?.emailsReceived ? row?.emailsReceived : "--"}</Box>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },

    lastCall: {
      field: "lastCall",
      headerName: "Last Call",
      renderCell: ({ row }) => {
        return (
          <Box>{row?.lastCall ? moment(row?.lastCall).format("ll") : "--"}</Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Call",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
    lastIncomingCall: {
      field: "lastIncomingCall",
      headerName: "Last Incoming Text",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastIncomingCall
              ? moment(row?.lastIncomingCall).format("ll")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Incoming Text",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
    lastOutgoingCall: {
      field: "lastOutgoingCall",
      headerName: "Last Outgoing Text",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastOutgoingCall
              ? moment(row?.lastOutgoingCall).format("ll")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Outgoing Text",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },

    callsIncoming: {
      field: "callsIncoming",
      headerName: "Call Received",
      renderCell: ({ row }) => {
        return <Box>{row?.callsIncoming ? row?.callsIncoming : "--"}</Box>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "number",
      sortable: false,
      filterOperators: date_or_price,
    },
    callsOutgoing: {
      field: "callsOutgoing",
      headerName: "Calls Made",
      renderCell: ({ row }) => {
        return <Box>{row?.callsOutgoing ? row?.callsOutgoing : "--"}</Box>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "number",
      sortable: false,
      filterOperators: date_or_price,
    },

    lastText: {
      field: "lastText",
      headerName: "Last Text",
      renderCell: ({ row }) => {
        return (
          <Box>{row?.lastText ? moment(row?.lastText).format("ll") : "--"}</Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Text",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
    lastSentText: {
      field: "lastSentText",
      headerName: "Last Sent Text",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastSentText ? moment(row?.lastSentText).format("ll") : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Sent Text",
      },
      type: "number",
      sortable: false,
      filterOperators: date_or_price,
    },
    lastReceivedText: {
      field: "lastReceivedText",
      headerName: "Last Received Text",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row?.lastReceivedText
              ? moment(row?.lastReceivedText).format("ll")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Received Text",
      },
      type: "number",
      sortable: false,
      filterOperators: date_or_price,
    },

    textsSent: {
      field: "textsSent",
      headerName: "Texts Sent",
      renderCell: ({ row }) => {
        return <Box>{row?.textsSent ? row?.textsSent : "--"}</Box>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "number",
      sortable: false,
      filterOperators: date_or_price,
    },
    textsReceived: {
      field: "textsReceived",
      headerName: "Texts Received",
      renderCell: ({ row }) => {
        return <Box>{row?.textsReceived ? row?.textsReceived : "--"}</Box>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "number",
      sortable: false,
      filterOperators: date_or_price,
    },
    assignedTo: {
      field: "assignedTo",
      headerName: "Assigned to",
      renderCell: (params) => {
        return params?.row?.assignedUserId ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              sx={{ fontSize: "12px", width: "30px", height: "30px" }}
              src={
                usersList.find((k) => k.id === params?.row?.assignedUserId)
                  ?.profile_images?.profile_img
              }
              variant="circled"
            >
              {`${
                params?.row?.assignedTo?.slice(0, 1) +
                params?.row?.assignedTo?.split(" ")[1]?.slice(0, 1)
              }`}
            </Avatar>
            <ListItemText
              sx={{ "& 	.MuiListItemText-primary": { fontSize: "14px" } }}
              primary={
                params?.row?.assignedTo
                  ? params?.row?.assignedTo
                  : usersList.find((k) => k.id === params?.row?.assignedUserId)
                      ?.full_name
              }
            />
          </Stack>
        ) : null;
      },
      props: {
        data: usersList.map((s) => {
          return { id: s.id, name: s.full_name };
        }),
        canSort: true,
        icon: MenuIcon,
        type: "Stage",
      },
      type: "include/exclude",
      sortable: false,
      hidden: true,
      minWidth: 200,
    },
    activity: {
      field: "activity",
      headerName: "Last Activity",
      renderCell: (params) => {
        return (
          <Box>
            {params.row?.lastActivity
              ? moment(params.row?.lastActivity).format("ll")
              : "--"}
          </Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Last Activity",
      },
      editable: false,
      sortable: false,
      flex: 1,
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
    created: {
      field: "created",
      headerName: "Created",
      renderCell: ({ row }) => {
        return (
          <Box>{row.created ? moment(row.created).format("ll") : "--"}</Box>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Created",
      },
      type: "date",
      sortable: false,
      filterOperators: date_or_price,
    },
  };
  let defaultColsArr = Object.keys(defaultCols),
    notExist = [];
  if (defaultColsArr.length != order.length) {
    notExist = defaultColsArr.filter(function (item) {
      return !order.includes(item.split(".")[0]);
    });
  }
  let cols = order.map((col) => defaultCols[col]);
  if (notExist.length) {
    cols = cols.concat(notExist.map((col) => defaultCols[col]));
  }
  return cols;
};
