import React, { useState, useEffect } from "react";
import { Container, Grid, Box, Typography, Button, Stack, Card, Tabs, Tab, Popover, MenuList, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import LeadsTable from "../common/Table/LeadsTable";
import { styleSheet } from "./style.js";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LabelRoundedIcon from "@mui/icons-material/LabelRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import AlarmRoundedIcon from "@mui/icons-material/AlarmRounded";
import { getLeadsListAPI } from "../../../redux/leads/getLeadsListAPI";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TabContext } from "@mui/lab";
import { getLeadsSmartList } from "../../../redux/leads/getAllSmartLists";
import { SaveSmartListModal } from "../../tools/common/Modal/SaveSmartListModal";
import { getPeopleStages } from "../../../redux/leads/getPeopleStages";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { getLeadsIdsAfterFilter, modalSubmitSuccess, setPeopleFilter } from "../../../redux/leads/getLeadList";
import { getLeadsAllTags } from "../../../redux/leads/getLeadsAllTags";
const LeadsTableList = (props) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [saveSmartListModal, setSaveSmartList] = useState(false);
  const { storedListOfUsers } = useSelector((item) => item.users);
  const { list: usersList, loading: usersLoading, usersObj } = storedListOfUsers;
  const [selectedSmartList, setSelectedSmartList] = useState(null);
  const [moreItemsVal, setMoreItemsVal] = useState("More Items");
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempFilters, setTempFilters] = React.useState([]);
  const [tempSorting, setTempSorting] = React.useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    leadsStages: { stagesList, loading: stagesListLoading },
    leadsTagsList: { tagsList, loading: tagsListLoading },
  } = useSelector((item) => item.leads);
  const location = useLocation();

  let { classes, data, smartList, smartListLoading, columnsVisibility, columnsOrder, sorting, filters, deleteList } = props;
  const { total: totalPeopleCount } = data.metadata;

  useEffect(() => {
    if (searchText !== false) {
      const timeOutId = setTimeout(() => setSearchValue(searchText), 2000);
      return () => clearTimeout(timeOutId);
    }
  }, [searchText]);

  useEffect(() => {
    (async () => {
      if (stagesList.length <= 0) {
        await dispatch(getPeopleStages());
      }
      if (tagsList && tagsList.length <= 0) {
        await dispatch(getLeadsAllTags());
      }
      await dispatch(getLeadsSmartList({}));
    })();
  }, [deleteList.success]);
  useEffect(() => {
    if (!data.status.pending && !smartListLoading) {
      if (id) {
        let selectedList = smartList.find((ls) => ls.id == id);
        if (selectedList) {
          setSelectedSmartList(selectedList);
          dispatch(setPeopleFilter(JSON.parse(selectedList.condition)));
          setTempFilters(JSON.parse(selectedList.condition));
          dispatch(
            getLeadsListAPI({
              filters: selectedList.condition,
              sortBy: selectedList.sortBy,
              sortOrder: selectedList.sortOrder,
              pageSize: 25,
              pageNumber: 1,
              resetList: true,
            })
          );
          dispatch(
            getLeadsIdsAfterFilter({
              filters: selectedList.condition,
              sortBy: selectedList.sortBy,
              sortOrder: selectedList.sortOrder,
              pageSize: 25,
              pageNumber: 1,
              resetList: true,
            })
          );
        }
      } else {
        setSelectedSmartList(null);
        if (location?.state?.fromOtherTab) {
          dispatch(setPeopleFilter([]));
          setTempFilters([]);
          dispatch(
            getLeadsListAPI({
              ...sorting,
              filters: [],
              pageSize: 25,
              pageNumber: 1,
              resetList: true,
            })
          );
        } else {
          dispatch(
            getLeadsListAPI({
              ...sorting,
              filters: JSON.stringify(filters),
              pageSize: 25,
              pageNumber: 1,
              resetList: true,
              search: searchValue,
            })
          );
          setTempFilters(filters);
          setTempSorting(sorting)
        }
        dispatch(
          getLeadsIdsAfterFilter({
            ...sorting,
            filters: JSON.stringify(filters),
            pageSize: 25,
            pageNumber: 1,
            resetList: true,
          })
        );
      }
    }
  }, [id, smartList, searchValue, location?.state?.fromOtherTab]);

  const closeModal = (success) => {
    if (success === true) {
      dispatch(modalSubmitSuccess());
    }
    setSaveSmartList(false);
  };

  const handlePopoverClose = (action) => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
        <Box className={classes.TopBarArea}>
          <Box className={classes.TopBarAreaLeft}>
            <Box>
              <TabContext>
                <Box className={classes.PeopleTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                    onChange={(e) => {
                      if (moreItemsVal !== "More Items") {
                        setMoreItemsVal("More Items");
                      }
                    }}
                  >
                    <Tab
                      label="All People"
                      value={`/leads/people`}
                      to={`/leads/people`}
                      state={{ fromOtherTab: true }}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      disabled={data.loading}
                    />
                    {smartList.slice(0, 3).map((ls, i) => {
                      return (
                        <Tab
                          label={`${ls.name} (${ls.count})`}
                          value={`/leads/people/list/` + ls.id}
                          to={`/leads/people/list/` + ls.id}
                          component={Link}
                          sx={{ padding: "0px 10px" }}
                          key={i}
                        />
                      );
                    })}
                    {smartList.slice(3).length ? (
                      <Tab
                        label={moreItemsVal}
                        value={`/leads/people/list/more${id ? "/" + id : ""}`}
                        to={`/leads/people/list/more${id ? "/" + id : ""}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.target);
                        }}
                        icon={<KeyboardArrowDownIcon style={{ marginBottom: 0 }} />}
                        sx={{
                          padding: "0px 10px",
                          flexDirection: "row-reverse",
                        }}
                        disabled={data.loading}
                      />
                    ) : null}
                  </Tabs>
                </Box>
              </TabContext>
            </Box>
          </Box>
          <Box>
            {/* {openRecruitModal && <AddRecruit open={openRecruitModal} setOpen={setOpenAddRecruitModal} />} */}
            {/* <TasksButton /> */}

            <Button
              className={classes.addListButton}
              variant="contained"
              // startIcon={<AddCircleRounded />}
              onClick={(e) => {
                // setOpenAddRecruitModal(true);
              }}
            >
              Add People
            </Button>
            <Button
              className={classes.addListButton}
              variant="contained"
              // startIcon={<CreateNewFolderRoundedIcon />}
              onClick={(e) => {
                setSaveSmartList(true);
              }}
            >
              {location.pathname.startsWith("/leads/people/list") ? "Update List" : "Save List"}
            </Button>
          </Box>
        </Box>
      </Card>
      <Container maxWidth="xl">
        <Box className={classes.pageRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box className={classes.leadsTableTopArea}>
                <Box className={classes.leadsTableTopAreaLeft}>
                  <Typography classes={{ root: classes.leadTableHeading }} variant="subtitle1" component="p">
                    Total {data.metadata.total || ""} People
                  </Typography>
                  <Stack direction="row" sx={{ ml: "30px" }} spacing={1}>
                    <Button className={classes.leadsTableTopAreaLeftButton} color="inherit" variant="outlined">
                      <MailOutlinedIcon />
                    </Button>
                    <Button className={classes.leadsTableTopAreaLeftButton} color="inherit" variant="outlined">
                      <PersonRoundedIcon />
                    </Button>
                    <Button className={classes.leadsTableTopAreaLeftButton} color="inherit" variant="outlined">
                      <LabelRoundedIcon />
                    </Button>
                    <Button className={classes.leadsTableTopAreaLeftButton} color="inherit" variant="outlined">
                      <DeleteRoundedIcon />
                    </Button>
                  </Stack>
                </Box>
                {/* we will need this in future */}
                <Box className={classes.leadsTableTopAreaRight}>
                  <OutlinedInput
                    value={searchText === false ? "" : searchText}
                    onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{ bgcolor: "white" }}
                    size="small"
                    placeholder="Search..."
                  />
                  <Box sx={{ display: "none" }}>
                    <ButtonGroups
                      id="category-button-menu"
                      bgColor="#0B0909"
                      bgColorHover="#0B0909"
                      placement={"bottom"}
                      height="35px"
                      size="medium"
                      color="white"
                      options={[
                        { title: "New Text", value: "New Text", icon: <SmsRoundedIcon /> },
                        { title: "New Email", value: "New Email", icon: <EmailRoundedIcon /> },
                        { title: "New Project", value: "New Project", icon: <TaskAltRoundedIcon /> },
                        { title: "Calendar Appt", value: "Calendar Appt", icon: <CalendarTodayRoundedIcon /> },
                        { title: "Reminder", value: "Reminder", icon: <AlarmRoundedIcon /> },
                      ]}
                      placeholder="Columns"
                      {...props}
                    />
                    <Button
                      startIcon={<FilterAltRoundedIcon />}
                      variant="outlined"
                      color="inherit"
                      className={classes.leadsTableTopAreaRightButton}
                    >
                      Filters
                    </Button>
                  </Box>
                </Box>
              </Box>
              <LeadsTable
                metadata={data.metadata}
                data={data?.data || []}
                loading={data.status.pending}
                filters={filters}
                usersList={usersList}
                usersListLoading={usersLoading}
                stagesList={stagesList}
                stagesListLoading={stagesListLoading}
                columnsVisibility={columnsVisibility}
                sorting={sorting}
                totalPeopleCount={totalPeopleCount}
                columnsOrder={[...columnsOrder]}
                usersObj={usersObj}
                tempFilters={tempFilters}
                setTempFilters={setTempFilters}
                tempSorting={tempSorting}
                setTempSorting={setTempSorting}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      {saveSmartListModal ? (
        <SaveSmartListModal
          state={saveSmartListModal}
          conditions={filters}
          count={totalPeopleCount}
          sortBy={sorting.sortBy || ""}
          sortOrder={sorting.sortOrder || ""}
          closeModal={closeModal}
          prevTeams={selectedSmartList?.sharedWithTeams.map((t) => t.team_id)}
          prevUsers={selectedSmartList?.sharedWith.map((u) => u.user_id)}
          id={id}
          prevName={selectedSmartList?.name}
          prevDescription={selectedSmartList?.description}
          smartList={smartList}
          api_type="lead"
          type={location.pathname.startsWith("/leads/people/list") ? "Update" : "Save"}
        />
      ) : null}
      <Popover
        PaperProps={{
          style: { minWidth: "150px" },
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          <MenuItem value="" disabled>
            <em>More Items</em>
          </MenuItem>
          {smartList.slice(3).map((ls, i) => {
            return (
              <MenuItem
                value={ls.id}
                key={i}
                onClick={(e) => {
                  setMoreItemsVal(`${ls.name} (${ls.count})`);
                  navigate("/leads/people/list/more/" + ls.id);
                  handlePopoverClose();
                }}
                disabled={data.loading}
              >
                {ls.name} ({ls.count})
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </Box>
  );
};
export default withStyles(styleSheet, { name: "leadsTableListStyle" })(LeadsTableList);
