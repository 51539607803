import React, { useState } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import ImgsViewer from "react-images-viewer";
import {
  getOptimizedImageUrl,
  samplePropertyIMage,
} from "../../../../../../../utils/propertyData";
function PhotoGrid() {
  const isSmallScreen = useMediaQuery("(max-width: 575px)");
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const listDetail = useSelector((state) => state.allCmas.listingDetail);

  const handleReturnImages = (images, images1) => {
    if (images) {
      return images?.filter((item) => item !== "null")?.length
        ? images?.filter((item) => item !== "null")
        : [samplePropertyIMage];
    } else {
      return images1?.filter((item) => item !== "null")?.length
        ? images1
            ?.filter((item) => item !== "null")
            ?.map((item) => item.MediaURL)
        : [samplePropertyIMage];
    }
  };
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Grid container spacing={isSmallScreen ? 0 : 1}>
        {handleReturnImages(
          listDetail?.data?.MediaURL,
          listDetail?.data?.Media
        )?.map((item, index) => (
          <Grid key={item} item xs={12} sm={6} md={4} lg={4}>
            <img
              onClick={() => {
                setCurrentImage(index);
                setImageViewerOpen(true);
              }}
              style={{
                cursor: "pointer",
                zIndex: "5",
                objectFit: "cover",
                position: "relative",
                height: "100%",
                width: "100%",
              }}
              src={getOptimizedImageUrl(
                item,
                isSmallScreen ? "original" : "640x480"
              )}
              alt="MediaURL"
            />
          </Grid>
        ))}
      </Grid>
      {handleReturnImages(listDetail?.data?.MediaURL, listDetail?.data?.Media)
        ?.length > 0 && (
        <ImgsViewer
          imgs={handleReturnImages(
            listDetail?.data?.MediaURL,
            listDetail?.data?.Media
          )?.map((item) => ({
            src: item,
          }))}
          isOpen={imageViewerOpen}
          currImg={currentImage}
          showThumbnails={true}
          enableKeyboardInput={true}
          onClose={() => setImageViewerOpen(false)}
        />
      )}
    </Box>
  );
}
export default PhotoGrid;
