import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
/* Array.from(Array(10).keys()) */
function AlertSkelton(props) {
  let { count=6 } = props;
  return (
    <Box>
      {Array.from(Array(count).keys()).map(() => (
        <Card sx={{ mb: "10px"}}>
          <CardHeader
          sx={{padding:"10px 15px"}}
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={<Skeleton animation="wave" height={20} width="70%" style={{ marginBottom: 4 }} />}
            action={<Skeleton animation="wave" height={20} width="100px" />}
            subheader={<Skeleton animation="wave" height={15} width="30%" />}
          />
        </Card>
      ))}
    </Box>
  );
}
export default AlertSkelton;
