import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../../../components/uiElements/button";
import Input from "../../../../../components/uiElements/input/Input";
import { callLogAgentRecruitment } from "../../../../../redux/agents/agentRecruitment/callLogAgentRecruitment";

export const LogCall = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { agentProfile, currentTab } = useSelector((item) => item.agentProfile);

  const [routeId, setRouteId] = useState();
  const [logCallText, setLogCallText] = useState("");
  const [open, setOpen] = useState({ state: false, type: "", desc: "" });

  useEffect(() => {
    if (param) {
      setRouteId(param?.id);
    }
  }, [param]);

  const createNoteFunc = () => {
    const schema = {
      agentRecruitmentId: routeId,
      phone: agentProfile?.list?.lead_phones[0]?.value || "123-123-1232",
      isIncoming: false,
      fromNumber: `${process.env.REACT_APP_GLOBAL_TWILIO_NUMBER}`,
      note: logCallText,
    };
    if (logCallText) {
      dispatch(callLogAgentRecruitment({ ...schema }));
      setOpen({
        state: true,
        type: "success",
        desc: "You have added call log successfully!",
      });
    } else {
      setOpen({
        state: true,
        type: "error",
        desc: "You can't create empty call log!",
      });
    }
    setLogCallText("");
  };

  const onChange = (e) => {
    setLogCallText(e.target.value);
  };
  const handleClose = (e) => {
    setOpen({ state: false, type: "", desc: "" });
  };

  return (
    <>
      <Input
        id="outlined-textarea"
        value={logCallText}
        placeholder="Label"
        multiline
        fullWidth
        rows={6}
        onChange={onChange}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        <Button onClick={createNoteFunc} variant="contained">
          {currentTab?.loading ? "Wait..." : "Log call"}
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open?.state}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={open?.type}
          sx={{ width: "100%" }}
        >
          {open?.desc}
        </Alert>
      </Snackbar>
    </>
  );
};
