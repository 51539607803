import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {getFeaturesData} from "./getFeaturesData"
export const getPublicData = createAsyncThunk(
  "publicData/getPublicData",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/transactions/listing/public-data/${data.listing_id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      thunkAPI.dispatch(getFeaturesData({listing_public_data_id:resp.data?.publicData.id}))
      return resp.data?.publicData;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

