import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const updateLeadTaskAPI = createAsyncThunk("lead/updateLeadTaskAPI", async (data, thunkAPI) => {
  const { taskId, schema, handleSuccess, handleError,statusUpdate } = data;
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/leads/tasks/${taskId}`, schema, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if(handleSuccess){
      handleSuccess()
    }
    return {...resp.data,taskId:taskId,isCompleted:schema?.isCompleted,statusUpdate:statusUpdate};
  } catch (error) {
    if (error.response) {
      if(handleError){
        handleError(error.response.data.message)
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      if(handleError){
        handleError(error.message)
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
