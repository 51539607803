import { createSlice } from "@reduxjs/toolkit";
import { getParticularListing } from "./getParticularListing";
import { closeTransactionListing } from "./closeListing";
import { terminateListing } from "./terminateListing";
import { addListingDocument } from "./addListingDocument";
import { uploadDocument } from "./uploadDocument";
import { updateDocument } from "./updateDocument";
import { rejectDocument } from "./rejectDocument";
import { AddListingNote } from "./addNote";
import { getAllLockboxes } from "./getAllLockboxes";
import { getAllLockboxesPagination } from "./getAllLockboxesPagination";
import { deleteListing } from "./deleteListing";
import {updateListingNote} from "./updateNote"
import {deleteListingNote} from "./deleteNote"

const initialState = {
  ListingData: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  terminateList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  deletedListing: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addDocument: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  uploadDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  rejectDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  closeListing: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addNote: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateNote: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  deleteNote:{
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  lockboxes: {
    success: false,
    data: [],
    count: 0,
    isLoading: false,
    isPaginationLoading: false,
    errMsg: null,
  },
};
const ListingDetail = createSlice({
  name: "ListingDetail",
  initialState,
  reducers: {
    ResetDocumentState: (state, action) => {
      state.addDocument.errMsg = null;
      state.addDocument.success = false;
      state.uploadDoc.errMsg = null;
      state.uploadDoc.success = false;
      state.updateDoc.errMsg = null;
      state.updateDoc.success = false;
      state.rejectDoc.errMsg = null;
      state.rejectDoc.success = false;
    },
    handleUpdateStatus: (state, action) => {
      state.ListingData.data.status = action.payload;
    },
    updateListingInventoryDetail: (state, action) => {
      let newListingData = { ...state.ListingData?.data };
      if (action.payload?.inventory) {
        if (newListingData.inventory_occupied_v2?.inventory) {
          state.ListingData.data = {
            ...newListingData,
            inventory_occupied_v2: {
              ...newListingData.inventory_occupied_v2,
              inventory: { ...newListingData.inventory_occupied_v2.inventory, ...action.payload?.inventory },
            },
          };
        } else {
          state.ListingData.data = {
            ...newListingData,
            inventory_occupied_v2: {
              inventory: { ...action.payload?.inventory },
            },
          };
        }
      }
      if (action.payload?.inventory_occupied_v2) {
        if (action.payload?.inventory_occupied_v2 === "detach") {
          state.ListingData.data = {
            ...newListingData,
            inventory_occupied_v2: null,
          };
        } else {
          state.ListingData.data = {
            ...newListingData,
            inventory_occupied_v2: {
              ...newListingData.inventory_occupied_v2,
              ...action.payload?.inventory_occupied_v2,
            },
          };
        }
      }
    },
  },
  extraReducers: {
    [getParticularListing.pending]: (state) => {
      state.ListingData.isLoading = true;
      state.ListingData.errMsg = null;
      state.ListingData.success = false;
      state.closeListing.errMsg = null;
      state.closeListing.success = false;
      state.terminateList.errMsg = null;
      state.terminateList.success = false;
      state.deletedListing.errMsg = null;
      state.deletedListing.success = false;
    },
    [getParticularListing.fulfilled]: (state, action) => {
      state.ListingData.success = true;
      state.ListingData.isLoading = false;
      state.ListingData.data = action.payload;
    },
    [getParticularListing.rejected]: (state, action) => {
      state.ListingData.isLoading = false;
      state.ListingData.success = false;
      state.ListingData.errMsg = action.payload;
    },
    [closeTransactionListing.pending]: (state) => {
      state.closeListing.isLoading = true;
      state.closeListing.errMsg = null;
      state.closeListing.success = false;
    },
    [closeTransactionListing.fulfilled]: (state, action) => {
      state.closeListing.success = true;
      state.closeListing.isLoading = false;
      state.closeListing.data = action.payload;
    },
    [closeTransactionListing.rejected]: (state, action) => {
      state.closeListing.isLoading = false;
      state.closeListing.success = false;
      state.closeListing.errMsg = action.payload;
    },

    [terminateListing.pending]: (state) => {
      state.terminateList.isLoading = true;
      state.terminateList.errMsg = null;
      state.terminateList.success = false;
    },
    [terminateListing.fulfilled]: (state, action) => {
      state.terminateList.success = true;
      state.terminateList.isLoading = false;
      state.terminateList.data = action.payload;
    },
    [terminateListing.rejected]: (state, action) => {
      state.terminateList.isLoading = false;
      state.terminateList.success = false;
      state.terminateList.errMsg = action.payload;
    },
    [deleteListing.pending]: (state) => {
      state.deletedListing.isLoading = true;
      state.deletedListing.errMsg = null;
      state.deletedListing.success = false;
    },
    [deleteListing.fulfilled]: (state, action) => {
      state.deletedListing.success = true;
      state.deletedListing.isLoading = false;
      state.deletedListing.data = action.payload;
    },
    [deleteListing.rejected]: (state, action) => {
      state.deletedListing.isLoading = false;
      state.deletedListing.success = false;
      state.deletedListing.errMsg = action.payload;
    },
    [addListingDocument.pending]: (state) => {
      state.addDocument.isLoading = true;
      state.addDocument.errMsg = null;
      state.addDocument.success = false;
    },
    [addListingDocument.fulfilled]: (state, action) => {
      state.addDocument.success = true;
      state.addDocument.isLoading = false;
      state.addDocument.data = action.payload;
      let newData = { ...action.payload, document: { type: "listing" } };
      let documentData = state.ListingData.data.transaction_documents ? [...state.ListingData.data.transaction_documents] : [];
      documentData.push(newData);
      state.ListingData.data.transaction_documents = documentData;
    },
    [addListingDocument.rejected]: (state, action) => {
      state.addDocument.isLoading = false;
      state.addDocument.success = false;
      state.addDocument.errMsg = action.payload;
    },

    [uploadDocument.pending]: (state) => {
      state.uploadDoc.isLoading = true;
      state.uploadDoc.errMsg = null;
      state.uploadDoc.success = false;
    },
    [uploadDocument.fulfilled]: (state, action) => {
      state.uploadDoc.success = true;
      state.uploadDoc.isLoading = false;
      state.uploadDoc.data = action.payload;
      let newData = action.payload;
      let documentData = state.ListingData.data.transaction_documents ? [...state.ListingData.data.transaction_documents] : [];
      let index = documentData.findIndex((item) => item.document_id === action.payload.document_id);
      documentData.splice(index, 1, newData);
      state.ListingData.data.transaction_documents = documentData;
    },
    [uploadDocument.rejected]: (state, action) => {
      state.uploadDoc.isLoading = false;
      state.uploadDoc.success = false;
      state.uploadDoc.errMsg = action.payload;
    },

    [updateDocument.pending]: (state) => {
      state.updateDoc.isLoading = true;
      state.updateDoc.errMsg = null;
      state.updateDoc.success = false;
    },
    [updateDocument.fulfilled]: (state, action) => {
      state.updateDoc.success = action.meta?.arg?.type;
      state.updateDoc.isLoading = false;
      state.updateDoc.data = action.payload;
      let newData = action.payload;
      let documentData = state.ListingData.data.transaction_documents ? [...state.ListingData.data.transaction_documents] : [];
      let index = documentData.findIndex((item) => item.document_id === action.payload.document_id);
      documentData.splice(index, 1, newData);
      state.ListingData.data.transaction_documents = documentData;
    },
    [updateDocument.rejected]: (state, action) => {
      state.updateDoc.isLoading = false;
      state.updateDoc.success = false;
      state.updateDoc.errMsg = action.payload;
    },

    [rejectDocument.pending]: (state) => {
      state.rejectDoc.isLoading = true;
      state.rejectDoc.errMsg = null;
      state.rejectDoc.success = false;
    },
    [rejectDocument.fulfilled]: (state, action) => {
      state.rejectDoc.success = true;
      state.rejectDoc.isLoading = false;
      state.rejectDoc.data = action.payload;
      let newData = action.payload;
      let documentData = state.ListingData.data.transaction_documents ? [...state.ListingData.data.transaction_documents] : [];
      let index = documentData.findIndex((item) => item.document_id === action.payload.document_id);
      documentData.splice(index, 1, newData);
      state.ListingData.data.transaction_documents = documentData;
    },
    [rejectDocument.rejected]: (state, action) => {
      state.rejectDoc.isLoading = false;
      state.rejectDoc.success = false;
      state.rejectDoc.errMsg = action.payload;
    },

    [AddListingNote.pending]: (state) => {
      state.addNote.isLoading = true;
      state.addNote.errMsg = null;
      state.addNote.success = false;
    },
    [AddListingNote.fulfilled]: (state, action) => {
      state.addNote.success = true;
      state.addNote.isLoading = false;
      state.addNote.data = action.payload;
      let notes = state.ListingData.data.notes ? [...state.ListingData.data.notes] : [];
      notes.push(action.payload.listingNote);
      state.ListingData.data.notes = notes;
    },
    [AddListingNote.rejected]: (state, action) => {
      state.addNote.isLoading = false;
      state.addNote.success = false;
      state.addNote.errMsg = action.payload;
    },

    // update note
    [updateListingNote.pending]: (state) => {
      state.updateNote.isLoading = true;
      state.updateNote.errMsg = null;
      state.updateNote.success = false;
    },
    [updateListingNote.fulfilled]: (state, action) => {
      state.updateNote.success = true;
      state.updateNote.isLoading = false;
      state.updateNote.data = action.payload;
      let notes = state.ListingData.data.notes ? [...state.ListingData.data.notes] : [];
      let index=notes.findIndex((item)=>item.id===action.payload.noteId)
      notes[index]={...notes[index],...action.payload}
      state.ListingData.data.notes = notes;
    },
    [updateListingNote.rejected]: (state, action) => {
      state.updateNote.isLoading = false;
      state.updateNote.success = false;
      state.updateNote.errMsg = action.payload;
    },

       // delete note
       [deleteListingNote.pending]: (state) => {
        state.deleteNote.isLoading = true;
        state.deleteNote.errMsg = null;
        state.deleteNote.success = false;
      },
      [deleteListingNote.fulfilled]: (state, action) => {
        state.deleteNote.success = true;
        state.deleteNote.isLoading = false;
        state.deleteNote.data = action.payload;
        let notes = state.ListingData.data.notes ? [...state.ListingData.data.notes] : [];
        let index=notes.findIndex((item)=>item.id===action.payload.noteId)
        notes.splice(index,1)
        state.ListingData.data.notes = notes;
      },
      [deleteListingNote.rejected]: (state, action) => {
        state.deleteNote.isLoading = false;
        state.deleteNote.success = false;
        state.deleteNote.errMsg = action.payload;
      },


    [getAllLockboxes.pending]: (state) => {
      state.lockboxes.isLoading = true;
      state.lockboxes.errMsg = null;
      state.lockboxes.success = false;
    },
    [getAllLockboxes.fulfilled]: (state, action) => {
      state.lockboxes.success = true;
      state.lockboxes.isLoading = false;
      state.lockboxes.count = action.payload.data.count;
      state.lockboxes.data = action.payload.data.rows;
    },
    [getAllLockboxes.rejected]: (state, action) => {
      state.lockboxes.isLoading = false;
      state.lockboxes.success = false;
      state.lockboxes.errMsg = action.payload;
    },

    [getAllLockboxesPagination.pending]: (state, action) => {
      state.lockboxes.isPaginationLoading = true;
      state.lockboxes.errMsg = null;
      state.lockboxes.success = false;
    },
    [getAllLockboxesPagination.fulfilled]: (state, action) => {
      state.lockboxes.success = true;
      state.lockboxes.isPaginationLoading = false;
      state.lockboxes.data = [...state.lockboxes.data, ...action.payload.data.rows];
    },
  },
});
export const { ResetDocumentState, handleUpdateStatus, updateListingInventoryDetail } = ListingDetail.actions;
export default ListingDetail.reducer;

