import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAttachCommissionPlan = createAsyncThunk("AttachedCommissionPlan/getAttachCommissionPlan", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/users/attached-plans/${data.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
const initialState = {
  success: false,
  data: undefined,
  isLoading: false,
  errMsg: null,
};
const AttachedCommissionPlan = createSlice({
  name: "AttachedCommissionPlan",
  initialState,
  reducers: {
    updateAttachedPlan: (state, action) => {
       let attachedPlan= state.data?[...state.data]:[];
       attachedPlan.push(action.payload) 
      state.data = attachedPlan;
    },
  },
  extraReducers: {
    [getAttachCommissionPlan.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [getAttachCommissionPlan.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.data = action.payload;
    },
    [getAttachCommissionPlan.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
  },
});
export const { updateAttachedPlan } = AttachedCommissionPlan.actions;
export default AttachedCommissionPlan.reducer;
