import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MapView from "./map";
import PhotoGrid from "./photoGrid";
// import StreetView from "./streetView";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import MapWithStreetView from "./mapWithStreetView.js";
const Transition = React.forwardRef(function Transition(props, ref, open) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog(props) {
  let { open, setOpen, activeView, setActiveView, classes } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdateActiveView = (view) => {
    setActiveView(view);
  };

  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ width: "100%" }}>
          <Box className={classes.dialogHeader}>
            <Box className={classes.dialogHeaderButtons}>
              <Button
                size="large"
                color="inherit"
                variant={activeView === "photos" ? "contained" : "outlined"}
                className={
                  activeView === "photos"
                    ? classes.dialogActivePhotosButton
                    : classes.dialogPhotosButton
                }
                onClick={() => handleUpdateActiveView("photos")}
              >
                Photos
              </Button>
              <Button
                className={
                  activeView === "map"
                    ? classes.dialogActivePhotosButton
                    : classes.dialogPhotosButton
                }
                variant={activeView === "map" ? "contained" : "outlined"}
                color="inherit"
                size="large"
                onClick={() => handleUpdateActiveView("map")}
              >
                Map
              </Button>
              <Button
                className={
                  activeView === "streetView"
                    ? classes.dialogActivePhotosButton
                    : classes.dialogPhotosButton
                }
                variant={activeView === "streetView" ? "contained" : "outlined"}
                color="inherit"
                size="large"
                onClick={() => handleUpdateActiveView("streetView")}
              >
                Street View
              </Button>
            </Box>
            <IconButton
              onClick={handleClose}
              className={classes.dialogHeaderCloseButton}
            >
              <CloseIcon
                className={classes.dialogHeaderCloseButtonIcon}
                sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                fontSize="large"
              />
            </IconButton>
          </Box>
          {activeView === "photos" ? (
            <PhotoGrid />
          ) : activeView === "map" ? (
            <MapView height="90vh" />
          ) : (
            <MapWithStreetView />
          )}
        </Box>
      </Dialog>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "FullScreenDialogStyle" })(
  FullScreenDialog
);
