export const styleSheet = () => ({
  pageRoot: {
    marginTop: "30px !important",
  },
  leadsTableTopArea: {
    margin: "10px 5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leadsTableTopAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  leadsTableTopAreaLeftButton: {
    padding: "5px 7px !important",
    minWidth: "40px !important",
    borderColor:'lightgrey !important',
    backgroundColor:"white !important"
  },
  leadsTableTopAreaRightButton:{
    padding: "5px 20px !important",
    borderColor:'lightgrey !important',
    marginLeft:'15px !important'
  },
  leadsTableTopAreaRight: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  TopBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  PeopleTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  addListButton: {
    background: "#0B0909 !important",
    fontWeight: "400  !important",
    fontSize: "12.5px !important",
    color: "white",
    height: "34px !important",
    marginRight: "15px !important",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
  },
  tabMore: {
    flexDirection: "row-reverse",
  },
});



