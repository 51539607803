import { createSlice } from "@reduxjs/toolkit";
import { addLeadAppointmentAPI } from "../leads/addLeadAppointmentAPI";
import { addLeadTaskAPI } from "../leads/addLeadTaskAPI";
import { deleteLeadAppointmentAPI } from "../leads/deleteLeadAppointmentAPI";
import { deleteLeadTaskAPI } from "../leads/deleteLeadTaskAPI";
import { getLeadByIdAPI } from "../leads/getLeadByIdAPI";
import { updateLeadAppointmentAPI } from "../leads/updateLeadAppointmentAPI";
import { updateLeadProfileAPI } from "../leads/updateLeadProfileAPI";
import { deleteFileAPI } from "../leads/deleteFile";
import { updateLeadTaskAPI } from "../leads/updateLeadTaskAPI";
import { uploadLeadsFile } from "./uploadLeadsFile";
import { getAppointmentTypes } from "./getAppointmentTypes";
import { getLeadActivities } from "./getLeadActivities";
import { getActivitiesStats } from "./getActivityStats";
import { getLeadSources } from "./getSources";
const initialState = {
  list: {
    data: {},
    status: {
      pending: false,
      success: false,
      error: false,
    },
    updateProfile: {
      isLoading: false,
      success: false,
      errMsg: null,
    },
  },
  lead_tasks: {
    status: {
      pending: false,
      success: false,
      error: false,
    },
    update: {
      pending: false,
      success: false,
      error: false,
    },
  },
  appointments: {
    status: {
      pending: false,
      success: false,
      error: false,
    },
  },
  lead_files: {
    status: {
      pending: false,
      success: false,
      error: false,
    },
  },
  appointmentTypes: [],
  activities: {
    isLoading: false,
    events: [],
    _metadata: {},
    errMsg: null,
  },
  activitiesStats: {},
  sources: {
    isLoading: false,
    data: [],
    errMsg: null,
  },
};

const leadProfile = createSlice({
  name: "leadProfile",
  initialState,
  reducers: {},
  extraReducers: {
    [getLeadByIdAPI.pending]: (state) => {
      state.list.status.pending = true;
    },
    [getLeadByIdAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.list.data = action.payload;
      }
      state.list.status.pending = false;
    },
    [getLeadByIdAPI.rejected]: (state, action) => {
      state.list.status.pending = false;
      state.list.status.error = true;
    },
    // get appointment type
    [getAppointmentTypes.fulfilled]: (state, action) => {
      state.appointmentTypes = action.payload;
    },

    [updateLeadProfileAPI.pending]: (state) => {
      state.list.updateProfile.isLoading = true;
    },
    [updateLeadProfileAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.list.data = {
          ...state.list.data,
          ...action.payload,
        };
      }
      state.list.updateProfile.isLoading = false;
    },
    [updateLeadProfileAPI.rejected]: (state, action) => {
      state.list.updateProfile.isLoading = false;
      state.list.updateProfile.errMsg = action.payload;
    },

    // delete file
    [deleteFileAPI.pending]: (state) => {
      state.list.updateProfile.isLoading = true;
    },
    [deleteFileAPI.fulfilled]: (state, action) => {
      let newLeadFiles = [...state.list.data.lead_files];
      state.list.data.lead_files = newLeadFiles.filter(
        (item) => item.id !== action.payload.fileId
      );
      state.list.updateProfile.isLoading = false;
    },
    [deleteFileAPI.rejected]: (state, action) => {
      state.list.updateProfile.isLoading = false;
      state.list.updateProfile.errMsg = action.payload;
    },

    [addLeadTaskAPI.pending]: (state) => {
      state.lead_tasks.update.pending = true;
    },
    [addLeadTaskAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.list.data = {
          ...state.list.data,
          lead_tasks: [...state.list.data.lead_tasks, action.payload],
        };
      }
      state.lead_tasks.update.pending = false;
    },
    [addLeadTaskAPI.rejected]: (state, action) => {
      state.lead_tasks.update.pending = false;
      state.lead_tasks.update.error = true;
    },

    [uploadLeadsFile.pending]: (state) => {
      state.lead_files.status.pending = true;
    },
    [uploadLeadsFile.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.list.data = {
          ...state.list.data,
          lead_files: [...state.list.data.lead_files, action.payload],
        };
      }
      state.lead_files.status.pending = false;
    },
    [uploadLeadsFile.rejected]: (state, action) => {
      state.lead_files.status.pending = false;
      state.lead_files.status.error = true;
    },

    [updateLeadTaskAPI.pending]: (state) => {
      state.lead_tasks.update.pending = true;
    },
    [updateLeadTaskAPI.fulfilled]: (state, action) => {
      const objIndex = state.list.data.lead_tasks.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (action?.payload?.statusUpdate) {
        state.list.data.lead_tasks[objIndex].isCompleted =
          action?.payload?.isCompleted;
      } else {
        state.list.data.lead_tasks[objIndex] = {
          ...state.list.data.lead_tasks[objIndex],
          ...action.payload,
        };
      }
      state.lead_tasks.update.pending = false;
    },
    [updateLeadTaskAPI.rejected]: (state, action) => {
      state.lead_tasks.update.pending = false;
      state.lead_tasks.update.error = true;
    },
    [deleteLeadTaskAPI.pending]: (state) => {
      state.lead_tasks.status.pending = true;
    },
    [deleteLeadTaskAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.list.data.lead_tasks = state.list.data.lead_tasks.filter(
          (item) => item.id !== action.payload.id
        );
      }
      state.lead_tasks.status.pending = false;
    },
    [deleteLeadTaskAPI.rejected]: (state, action) => {
      state.lead_tasks.status.pending = false;
      state.lead_tasks.status.error = true;
    },

    [addLeadAppointmentAPI.pending]: (state) => {
      state.appointments.status.pending = true;
    },
    [addLeadAppointmentAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.list.data = {
          ...state.list.data,
          appointments: [...state.list.data.appointments, action.payload],
        };
      }
      state.appointments.status.pending = false;
    },
    [addLeadAppointmentAPI.rejected]: (state, action) => {
      state.appointments.status.pending = false;
      state.appointments.status.error = true;
    },

    [updateLeadAppointmentAPI.pending]: (state) => {
      state.appointments.status.pending = true;
    },
    [updateLeadAppointmentAPI.fulfilled]: (state, action) => {
      const objIndex = state.list.data.appointments.findIndex(
        (obj) => obj.id == action.payload.id
      );
      if (action?.payload) {
        state.list.data.appointments[objIndex] = action.payload;
      }
      state.appointments.status.pending = false;
    },
    [updateLeadAppointmentAPI.rejected]: (state, action) => {
      state.appointments.status.pending = false;
      state.appointments.status.error = true;
    },
    [deleteLeadAppointmentAPI.pending]: (state) => {
      state.appointments.status.pending = true;
    },
    [deleteLeadAppointmentAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
      }
      state.appointments.status.pending = false;
    },
    [deleteLeadAppointmentAPI.rejected]: (state, action) => {
      state.appointments.status.pending = false;
      state.appointments.status.error = true;
    },
    // lead activities section
    [getLeadActivities.pending]: (state) => {
      state.activities.isLoading = true;
      state.activities.errMsg = null;
    },
    [getLeadActivities.fulfilled]: (state, action) => {
      state.activities.isLoading = false;
      if (action?.payload?.offset > 0) {
        state.activities.events = [
          ...state.activities.events,
          ...action.payload.events,
        ];
      } else {
        state.activities.events = action.payload.events;
      }
      state.activities._metadata = action.payload._metadata;

      state.activities.errMsg = null;
    },
    [getLeadActivities.rejected]: (state, action) => {
      state.activities.isLoading = false;
      state.activities.data = {};
      state.activities.errMsg = action.payload;
    },
    // get activities stats
    [getActivitiesStats.fulfilled]: (state, action) => {
      state.activitiesStats = action.payload;
    },
    // get lead sources
    [getLeadSources.pending]: (state) => {
      state.sources.isLoading = true;
      state.sources.errMsg = null;
    },
    [getLeadSources.fulfilled]: (state, action) => {
      state.sources.isLoading = false;
      state.sources.data = action.payload.leadSources;
      state.sources.errMsg = null;
    },
    [getLeadSources.rejected]: (state, action) => {
      state.sources.isLoading = false;
      state.sources.data = [];
      state.sources.errMsg = action.payload;
    },
  },
});
export default leadProfile.reducer;
