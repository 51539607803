export const styleSheet = (theme) => ({
  // background: theme.palette.primary.main,
  root: {
    "&:before": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      margin: "0px !important",
    },
    "& .MuiTimelineContent-root": {
      padding: "0px 12px 45px 25px  !important",
    },
  },
  threadClosedCard: {
    padding: "10px  !important",
    color: "#5dafde  !important",
    background: "#fcfdfd  !important",
    cursor: "pointer  !important",
  },
  threadClosedSubCard: {
    padding: "10px  !important",
    color: "#5dafde  !important",
    background: "#fcfdfd  !important",
    cursor: "pointer  !important",
    display: "flex !important",
    flexDirection: "row",
    flexWrap: "nowrap !important",
    gap: "0px 10px !important",
    marginBottom: "15px !important",
    width: "100% !important",
  },
  threadClosedSubCardName: {
    width: "100px !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    color: "black !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  threadClosedSubCardDate: {
    width: "50px !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    color: "lightgrey !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  threadClosedSubCardDes: {
    flex: 1,
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    width: "calc(100% - 150px) !important",
    color: "black !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  subCollapsedArea: {
    position: "absolute !important",
    top: "5px !important",
    right: "5px !important",
    zIndex: "999 !important",
  },
  threadMessagesAreaItem: {
    padding: "10px",
    marginBottom: "15px",
    width: "100% !important",
  },
  threadMessagesArea: {
    marginTop: "15px",
  },
  threadMessagesAreaItemDetail: {
    padding: "0px !important",
    fontSize: "14px !important",
  },
  nameWithIcon: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0px 5px",
  },
  callPlayArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    width: "160px !important",
    "& input": {
      "--c": "black",
      "--g": "2px",
      "--l": "3px",
      "--s": "13px",
      width: "200px",
      height: "var(--s)",
      "--_c": "color-mix(in srgb, var(--c), #000 var(--p,0%))",
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      background: "none",
      cursor: "pointer",
      overflow: "hidden",
    },
    "& input:focus-visible,\ninput:hover": { "--p": "25%" },
    "& input:active,\ninput:focus-visible": { "--_b": "var(--s)" },
    '& input[type="range" i]::-webkit-slider-thumb': {
      height: "var(--s)",
      aspectRatio: "1",
      borderRadius: "50%",
      boxShadow: "0 0 0 var(--_b,var(--l)) inset var(--_c)",
      borderImage:
        "linear-gradient(90deg,var(--_c) 50%,#ababab 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw + var(--g))",
      WebkitAppearance: "none",
      appearance: "none",
      transition: ".3s",
    },
    '& input[type="range"]::-moz-range-thumb': {
      height: "var(--s)",
      width: "var(--s)",
      background: "none",
      borderRadius: "50%",
      boxShadow: "0 0 0 var(--_b,var(--l)) inset var(--_c)",
      borderImage:
        "linear-gradient(90deg,var(--_c) 50%,#ababab 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw + var(--g))",
      MozAppearance: "none",
      appearance: "none",
      transition: ".3s",
    },
    "@supports not (color: color-mix(in srgb,red,red))": {
      input: { "--_c": "var(--c)" },
    },
  },
});
