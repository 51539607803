import React from "react";
import {  Container, Skeleton, Grid, Card } from "@mui/material";
function KnowledgeDetailSkelton(props) {
  let { count = 5 } = props;
  return (
    <Container maxWidth="lg" sx={{ mt: "50px",mb:"50px" }}>
      <Card variant="outlined" sx={{ padding: "20px" }}>
        <Skeleton animation="wave" height={60} width="30%" />
        <br />
        <Skeleton animation="wave" variant="rectangular" height={400} width="100%" />
      </Card>
      <br />
      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          <Card variant="outlined" sx={{ padding: "20px",height:'100%' }}>
            <Skeleton animation="wave" variant="rectangular" height={400} width="100%" />
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card variant="outlined" sx={{ padding: "20px" }}>
          {Array.from(Array(count).keys()).map(() => (
           <Skeleton animation="wave" sx={{borderRadius:'20px',marginBottom:'12px'}} variant="rectangular" height={170} width="100%" /> 
          ))}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
export default KnowledgeDetailSkelton;
