import React, { useState, useEffect } from "react";
import { FormControl, Grid, List, ListItem, MenuItem, OutlinedInput, Paper, Select, Stack, Box, Typography } from "@mui/material";
import { ContactDetails, Details, NameCard } from "../people/peopleDetail/cards/cards";
import { EditAddressModal } from "../common/Modal/editAddressModal";
import { EditPhoneModal } from "../common/Modal/editPhoneModal";
import { EditEmailModal } from "../common/Modal/editEmailModal";
import momentTZ from "moment-timezone";
import { stringAvatar } from "../../../utils/avatarInitial";
import { useStyles } from "./style";
import { FiberManualRecord } from "@mui/icons-material";
import Avatar from "../../../components/uiElements/avatar/index";
import Vector from "../../../assets/Vector.svg";
import HouseRounded from "../../../assets/HouseRounded.svg";
import BedRounded from "../../../assets/BedRounded.svg";
import BathtubRounded from "../../../assets/BathtubRounded.svg";
import moment from "moment";
import { setInfoAgentRecruitment } from "../../../redux/agents/agentRecruitment/setInfoAgentRecruitment";
import Button from "../../../components/uiElements/button";
import { useDispatch,useSelector } from "react-redux";
import { updateLeadProfileAPI } from "../../../redux/leads/updateLeadProfileAPI";
import { updateStageNameAgentRecruitment } from "../../../redux/agents/agentRecruitment/updateStageNameAgentRecruitment";
import {updateStage,updateAssignedTo} from "../../../redux/leads/inbox";

export const Activity = ({ name, statusText, title }) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
      <List>
        <ListItem>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>

          <Typography style={{ color: "green", marginLeft: "12px", display: "flex", alignItems: "center" }}>
            <FiberManualRecord fontSize="small" sx={{ marginRight: "6px" }} />
            {statusText}
          </Typography>
        </ListItem>
        <ListItem>
          <img src={Vector} alt="$" />
          <Typography sx={{ marginRight: "12px", marginLeft: "10px" }}>860k</Typography>
          <img src={HouseRounded} alt="address" />
          <Typography sx={{ marginRight: "12px", marginLeft: "10px" }}>19,381</Typography>
          <img src={BedRounded} alt="bed" />
          <Typography sx={{ marginRight: "12px", marginLeft: "10px" }}>6</Typography>
          <img src={BathtubRounded} alt="bathtub" />
          <Typography sx={{ marginRight: "12px", marginLeft: "10px" }}>6</Typography>
        </ListItem>
      </List>
      <Box className={classes.activityBtn}>
        <Button>View All(187)</Button>
        <Button>Edit Search Criteria</Button>
      </Box>
    </Paper>
  );
};

export const Background = ({ statusText, title, text }) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
      <List>
        <ListItem>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>{text}</Typography>
        </ListItem>
      </List>
    </Paper>
  );
};

function UserDetail(props) {
  const [editAddressModal, setEditAddressModal] = useState(false);
  const [editPhoneModal, setEditPhoneModal] = useState(false);
  const [editEmailModal, setEditEmailModal] = useState(false);
  const dispatch = useDispatch();
  let {
    classes,
    selectedUser,
    primaryPhone,
    primaryEmail,
    currentStage,
    setCurrentStage,
    leadProfile,
    currentAssigne,
    setCurrentAssigne,
    usersList,
  } = props;

  const closeModal = () => {
    setEditPhoneModal(false);
    setEditEmailModal(false);
    setEditAddressModal(false);
  };
  const { stagesList } = useSelector((item) => item.stagesList);
  const { leadStageList } = useSelector((item) => item);

  const findStageIdAgent = (name) => {
    const st = stagesList.filter((el) => el.name === name && el.id);
    return st[0]?.id;
  };
  const findStageIdLead = (name) => {
    const st = leadStageList?.data.filter((el) => el.name === name && el.id);
    return st[0]?.id;
  };
  const findStageNameLead = (id) => {
    const st = leadStageList?.data.filter((el) => el.id === id && el.name);
    return st[0]?.name;
  };
  const updateStageFncLead = (e) => {
    const val = e.target.value;
    const id = findStageIdLead(val);
    const name = findStageNameLead(id);
    setCurrentStage(val);
    if (selectedUser.lead_id) {
          const schema = {
      leadId: selectedUser.lead_id,
      schema: {
        stage: name,
      },
    };
    dispatch(updateLeadProfileAPI({ ...schema }));
    dispatch(updateStage({
      type:"lead",
      stageId: id,
      id:selectedUser.id
    }))
    }
  };
  const findStageNameAgent = (id) => {
    const st = stagesList.filter((el) => el.id === id && el.name);
    return st[0]?.name;
  };
  const updateStageFncAgent = (e) => {
    const val = e.target.value;
    const id = findStageIdAgent(val);
    const name = findStageNameAgent(id);
    setCurrentStage(val);
    if (selectedUser.realtor_agent_id) {
      const schema = {
        agentRecruitmentId: selectedUser.realtor_agent_id,
        stage: id,
      };
      dispatch(updateStageNameAgentRecruitment({ ...schema }));
      dispatch(updateStage({
        type:"agent",
        stageId: id,
        id:selectedUser.id
      }))
    }
  };
  const findUserName = (id) => {
    const st = usersList?.filter((el) => el.id === id);
    return st[0]?.full_name;
  };


  useEffect(() => {
    if (usersList.length) {
      setCurrentAssigne(selectedUser?.realtor_agent?.assignedTo || selectedUser?.lead?.assignedTo);
    }
  }, [usersList,selectedUser]);

  useEffect(()=>{
    if(leadStageList?.data?.length && selectedUser?.lead_id && selectedUser?.lead?.stageId){
      setCurrentStage(findStageNameLead(selectedUser?.lead?.stageId))
    }
  },[leadStageList?.data,selectedUser])

  useEffect(()=>{
    if(stagesList.length && selectedUser?.realtor_agent_id && selectedUser?.realtor_agent?.stageId){
      setCurrentStage(findStageNameAgent(selectedUser?.realtor_agent?.stageId))
    }
  },[stagesList,selectedUser])

  const getProfileImg = (id) => {
    const st = usersList?.filter((el) => el.id === id);
    return st[0]?.profile_images?.profile_img || "";
  };
  const updateAssigneFnc = (e) => {
    setCurrentAssigne(e.target.value);
    if (selectedUser.realtor_agent_id) {
      const schema = {
        assignedTo: e.target.value,
      };
      dispatch(setInfoAgentRecruitment({ agentId: selectedUser?.realtor_agent_id, schema }));
      dispatch(updateAssignedTo({
        type:"agent",
        assignedTo: e.target.value,
        id:selectedUser.id
      }))
    }
    if (selectedUser.lead_id) {
      const schema = {
        schema: {
          assignedTo: e.target.value,
        },
        leadId: selectedUser.lead_id,
      };
      dispatch(updateLeadProfileAPI({ ...schema }));
      dispatch(updateAssignedTo({
        type:"lead",
        assignedTo: e.target.value,
        id:selectedUser.id
      }))
    }
  };

  return (
    <div className={classes.userInfoDetail}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Stack spacing={1}>
            <NameCard selectedUser={selectedUser} isAllowRedirect />
            <ContactDetails
              list={[
                {
                  type: "phone",
                  onClick: () => setEditPhoneModal(true),
                  value: selectedUser?.realtor_agent?.lead_phones || [],
                },
                {
                  type: "email",
                  onClick: () => setEditEmailModal(true),
                  value: selectedUser?.realtor_agent?.lead_emails || [],
                },
                // {
                //   type: "address",
                //   onClick: () => setEditAddressModal(true),
                //   value: "Add Address",
                // },
              ]}
              // name={leadProfile?.list?.data?.name}
            />
            <Details
              title="Details"
              list={[
                {
                  key: "Stage",
                  value: (
                    <Box>
                      <FormControl>
                       {
                        selectedUser?.realtor_agent_id && 
                        <Select
                        value={currentStage}
                        label="stage"
                        onChange={updateStageFncAgent}
                        sx={{
                          background: "#212121",
                          padding: "3px 6px 3px 10px !important",
                          position: "relative",
                          borderRadius: "25px",
                          color: "white",
                          height: "28px",
                          "& .MuiSelect-select": {
                            padding: "2px 6px !important",
                            paddingRight: "25px !important",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "white",
                            position: "absolute",
                            right: "0",
                          },
                        }}
                      >
                            {stagesList && stagesList.length ? (
                                stagesList.map((ii, index) => (
                                  <MenuItem value={ii.name} key={index}>
                                    {ii.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  No items
                                </MenuItem>
                              )}
                      </Select>
                       }
                       {
                        selectedUser?.lead_id &&        <Select
                        value={currentStage}
                        label="stage"
                        onChange={updateStageFncLead}
                        sx={{
                          background: "#212121",
                          padding: "0px !important",
                          position: "relative",
                          borderRadius: "7px",
                          color: "white",
                          height: "20px",
                          color: "white",
                          "& .MuiSelect-select": {
                            padding: "2px 6px !important",
                            paddingRight: "25px !important",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "white",
                            position: "absolute",
                            right: "0",
                          },
                        }}
                      >
                        {leadStageList?.data &&
                        leadStageList?.data.length ? (
                          leadStageList?.data.map((ii) => (
                            <MenuItem value={ii.name}>
                              {ii.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            No items
                          </MenuItem>
                        )}
                      </Select>
                       }

                      </FormControl>
                    </Box>
                  ),
                },
                {
                  key: "Last Communication",
                  value: leadProfile?.list?.data?.lastActivity ? (
                    <>
                      {moment(moment(new Date(selectedUser?.realtor_agent?.lastCommunicationTimestamp).toString())).from(moment(new Date()))}
                      &nbsp; ({momentTZ.tz(selectedUser?.realtor_agent?.lastCommunicationTimestamp, process.env.REACT_APP_TIMEZONE).format("MMM Do")})
                    </>
                  ) : (
                    "Never"
                  ),
                },
                {
                  key: "Assigned to",
                  value: (
                    <Box>
                      <FormControl>
                        <Select
                          value={currentAssigne}
                          label="Assigne"
                          onChange={updateAssigneFnc}
                          sx={{
                            borderRadius: "7px",
                            position: "relative",
                            "& .MuiSelect-select": {
                              padding: "0px !important",
                              paddingRight: "25px !important",
                            },
                            "& .MuiSvgIcon-root": {
                              position: "absolute",
                              right: "0",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          input={<OutlinedInput />}
                          renderValue={(tt) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: 30,
                                  height: 30,
                                  fontSize:"16px !important",
                                  position: "relative",
                                  "& .MuiSvgIcon-root": {
                                    position: "relative",
                                  },
                                }}
                                {...stringAvatar(findUserName(currentAssigne), getProfileImg(currentAssigne) || "")}
                              />
                              {findUserName(currentAssigne)}
                            </Box>
                          )}
                        >
                          <MenuItem value="initial" disabled>
                            Select Assignee
                          </MenuItem>
                          {usersList && usersList.length ? (
                            usersList.map((ii) => (
                              <MenuItem value={ii.id} sx={{ display: "flex", gap: "5px" }}>
                                <Avatar sx={{ width: 30, height: 30,fontSize:"16px" }} {...stringAvatar(ii?.full_name, ii?.profile_images?.profile_img)} />
                                {ii.full_name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled>
                              No users available
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  ),
                },
              ]}
            />
            <Activity title="Activity" statusText="On Website Now" />
            <Background title="Background" text={selectedUser?.background} />
          </Stack>
        </Grid>
      </Grid>

      {editAddressModal ? <EditAddressModal closeModal={closeModal} state={editAddressModal} /> : null}
      {editPhoneModal ? <EditPhoneModal closeModal={closeModal} state={editAddressModal} /> : null}
      {editEmailModal ? <EditEmailModal closeModal={closeModal} state={editAddressModal} /> : null}
    </div>
  );
}
export default UserDetail;
