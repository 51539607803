import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import ResponseAlert from "../../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { updateLeadProfileAPI } from "../../../../../redux/leads/updateLeadProfileAPI";
function AddCollaboratorModal(props) {
  let {
    open,
    setOpen,
    classes,
    id,
    collaborators,
    isLoading,
    assignedUserId,
    assignedLenderId,
  } = props;
  const [searchText, setSearchText] = useState();
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);
  useEffect(() => {
    if (open && collaborators?.length) {
      setSelectedUsers(collaborators.map((item) => item.id));
    }
  }, [open]);

  const handleValidate = () => {
    let isFormValid = true;
    if (selectedUsers?.length === 0) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the user first",
        errorType: "warning",
        isOpen: true,
      });
    }
    return isFormValid;
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the collaborator",
      errorType: "success",
      isOpen: true,
    });
    setOpen(false);
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const handleAddCollaborator = () => {
    if (handleValidate()) {
      dispatch(
        updateLeadProfileAPI({
          schema: { collaborators: selectedUsers },
          leadId: id,
          handleSuccess,
          handleError,
        })
      );
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selectedUsers.indexOf(value);
    const newChecked = [...selectedUsers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedUsers(newChecked);
  };
  const filteredList = (userList) => {
    if (searchText) {
      return userList.filter((item) =>
        item.full_name
          .toLowerCase()
          ?.slice(0, searchText.length)
          ?.includes(searchText)
      );
    } else {
      return userList;
    }
  };
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    let admins = users.filter((item) => item?.role?.name === "Admin");
    let teamLeader = users.filter((item) => item?.role?.name === "Team Leader");
    let otherUsers = users.filter(
      (item) =>
        item?.role?.name !== "Team Leader" &&
        item?.role?.name !== "Admin" &&
        item?.role?.name !== "Agents"
    );
    return [
      ...teamLeader,
      ...admins,
      ...agents,
      ...otherUsers?.filter((item) => item.group !== undefined),
    ];
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          className={classes.paymentModalHeading}
          id="alert-dialog-title"
        >
          Add Collaborator
        </DialogTitle>
        <DialogContent sx={{ width: "400px" }}>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            fullWidth
            size="small"
            placeholder="Search user"
          />
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: 360,
              maxHeight: "55vh",
              bgcolor: "background.paper",
            }}
          >
            {CUstomSortUsers(filteredList(storedListOfUsers.list))
              .filter(
                (itemValue) =>
                  itemValue?.id !== assignedUserId &&
                  itemValue?.id !== assignedLenderId
              )
              .map((item, value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    key={value}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(item.id)}
                        checked={selectedUsers.indexOf(item.id) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar src={item?.profile_images?.profile_img}>
                          {item.first_name?.slice(0, 1)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={`${item.first_name} ${item.last_name}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleAddCollaborator()}
            classes={{ root: classes.saveButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddCollaboratorModalStyle" })(
  AddCollaboratorModal
);
