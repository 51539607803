import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const AttachCommissionPlan = createAsyncThunk("AddCommissionPlan/AttachCommissionPlan", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/users/attach-plan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
const initialState = {
  success: false,
  data: undefined,
  isLoading: false,
  errMsg: null,
};
const AddCommissionPlan = createSlice({
  name: "AddCommissionPlan",
  initialState,
  extraReducers: {
    [AttachCommissionPlan.pending]: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.success = false;
    },
    [AttachCommissionPlan.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.data = action.payload;
    },
    [AttachCommissionPlan.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.errMsg = action.payload;
    },
  },
});
export default AddCommissionPlan.reducer;
