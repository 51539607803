import React, { useState } from "react";
import {
  Box,
  ListItemIcon,
  ListItemButton,
  Card,
  List,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  IconButton,
} from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import Avatar from "../../uiElements/avatar";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import {
  handleRemoveMinimizedTab,
  handleMaximizeTab,
} from "../../../redux/widgets";
import { CustomTooltip } from "../../shared/tooltip.js";
import ConfirmModal from "../modals/ConfirmModal.js";
import { useDispatch, useSelector } from "react-redux";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import ResponseAlert from "../../responseAlert";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
function ClosAbleMenu(props) {
  let { classes, title, subMenu, type } = props;
  const [warning, setWarning] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.widgets);
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShowIcon = () => {
    if (type === "text") {
      return <SmsRoundedIcon />;
    } else if (type === "email") {
      return <EmailRoundedIcon />;
    } else if (type === "appointment") {
      return <CalendarTodayRoundedIcon />;
    } else if (type === "agentRequest") {
      return <NotificationImportantIcon />;
    } else if (type === "lead") {
      return <PersonRoundedIcon />;
    } else {
      return <TaskAltRoundedIcon />;
    }
  };
  const handleShowTitle = () => {
    if (type === "text") {
      return `${subMenu.length} Text Message Drafts`;
    } else if (type === "email") {
      return `${subMenu.length} Email Drafts`;
    } else if (type === "appointment") {
      return `${subMenu.length} Calendar Appointment Drafts`;
    } else if (type === "project") {
      return `${subMenu.length} Project Drafts`;
    } else if (type === "agentRequest") {
      return `${subMenu.length} Agent Request Drafts`;
    } else {
      return `${subMenu.length} ${type} Drafts`;
    }
  };

  const handleValidation = (id, data) => {
    let IsValid = true;
    if (type === "text" && data.tabDetail?.message !== "") {
      IsValid = false;
      setWarning(id);
    } else if (
      type === "project" &&
      (data.tabDetail?.agent_id ||
        data.tabDetail?.department_id ||
        data.tabDetail?.task_type_id)
    ) {
      IsValid = false;
      setWarning(id);
    }
    return IsValid;
  };

  const handleRemoveTab = (id, data) => {
    if (handleValidation(id, data)) {
      dispatch(handleRemoveMinimizedTab(id));
    }
  };

  const handleGetModalType = () => {
    if (type === "text") {
      return `sendTextOpen`;
    } else if (type === "email") {
      return `sendEmailOpen`;
    } else if (type === "appointment") {
      return `openAppointment`;
    } else if (type === "project") {
      return `createProjectOpen`;
    } else if (type === "agentRequest") {
      return `openAgentRequest`;
    } else if (type === "lead") {
      return `openAddLead`;
    } else {
    }
  };
  const handleAlert = () => {
    setErrorAlert({
      errorMsg:
        "You can't open this widget because browser width is not enough for this!",
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleMaximize = (data) => {
    let obj = {
      ...data,
      type: handleGetModalType(),
    };
    if (!widgets[obj.type]) {
      if (rightPositions.length) {
        if (
          rightPositions[rightPositions.length - 1].right + 1450 >
          window.innerWidth
        ) {
          handleAlert();
        } else {
          dispatch(handleMaximizeTab(obj));
        }
      } else {
        dispatch(handleMaximizeTab(obj));
      }
    } else {
      setErrorAlert({
        errorMsg: "You can't open this widget because already opened",
        errorType: "warning",
        isOpen: true,
      });
    }
    handleClose();
  };

  const handleConfirm = () => {
    dispatch(handleRemoveMinimizedTab(warning));
    setWarning(false);
  };
  return (
    <Box className={classes.closableMenu}>
      {subMenu?.length > 1 ? (
        <Card
          onClick={handleClick}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          sx={{ width: "280px", background: "white !important" }}
        >
          <List disablePadding>
            <ListItemButton disablePadding>
              <ListItemIcon sx={{ minWidth: "34px" }}>
                {handleShowIcon()}
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& span": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
                primary={handleShowTitle()}
              />
              {open ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
          </List>
        </Card>
      ) : (
        <Card sx={{ width: "280px" }} variant="outlined">
          <MenuItem
            sx={{ borderBottom: "1px solid lightgrey", height: "48px" }}
          >
            <ListItemIcon
              onClick={() =>
                handleMaximize({ id: subMenu[0].id, action: true })
              }
            >
              {handleShowIcon()}
            </ListItemIcon>
            <ListItemText
              onClick={() =>
                handleMaximize({ id: subMenu[0].id, action: true })
              }
              sx={{
                "& span": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            >
              {" "}
              {title}
            </ListItemText>
            <Stack
              direction={"row"}
              justifyContent="flex-end"
              alignItems={"center"}
            >
              <IconButton
                onClick={() =>
                  handleMaximize({ id: subMenu[0].id, action: true })
                }
                sx={{ padding: "2px" }}
              >
                {subMenu[0]?.tabDetail?.user ? (
                  <CustomTooltip
                    title={subMenu[0]?.tabDetail?.user?.first_name}
                  >
                    <Avatar
                      src={
                        subMenu[0]?.tabDetail?.user?.profile_images?.profile_img
                      }
                      sx={{ width: "20px", height: "20px", fontSize: "12px" }}
                      variant="circle"
                    >{`${subMenu[0]?.tabDetail?.user?.first_name?.slice(
                      0,
                      1
                    )}${subMenu[0]?.tabDetail?.user?.last_name?.slice(
                      0,
                      1
                    )}`}</Avatar>
                  </CustomTooltip>
                ) : null}
                <CropSquareIcon sx={{ cursor: "pointer" }} fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => handleRemoveTab(subMenu[0].id, subMenu[0])}
                sx={{ padding: "2px" }}
              >
                <ClearRoundedIcon fontSize="small" />
              </IconButton>
            </Stack>
          </MenuItem>
        </Card>
      )}
      {subMenu?.length > 1 ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              bottom: "50px !important",
              top: "initial !important",
              boxShadow: "0px 0px 0px 1px #E0E0E0",
              // filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "bottom" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <Box sx={{ width: "278px" }}>
            {subMenu.map((item) => (
              <MenuItem sx={{ borderBottom: "1px solid lightgrey" }}>
                <ListItemIcon>{handleShowIcon()}</ListItemIcon>
                <ListItemText
                  sx={{
                    "& span": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  }}
                >
                  {item.title}
                </ListItemText>
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems={"center"}
                >
                  {item?.tabDetail?.user ? (
                    <CustomTooltip title={item?.tabDetail?.user?.first_name}>
                      <Avatar
                        src={item?.tabDetail?.user?.profile_images?.profile_img}
                        sx={{ width: "20px", height: "20px", fontSize: "12px" }}
                        variant="circle"
                      >{`${item?.tabDetail?.user?.first_name?.slice(
                        0,
                        1
                      )}${item?.tabDetail?.user?.last_name?.slice(
                        0,
                        1
                      )}`}</Avatar>
                    </CustomTooltip>
                  ) : null}
                  <IconButton
                    onClick={() =>
                      handleMaximize({ id: item.id, action: true })
                    }
                    sx={{ padding: "2px" }}
                  >
                    <CropSquareIcon
                      sx={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveTab(item.id, item)}
                    sx={{ padding: "2px" }}
                  >
                    <ClearRoundedIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </MenuItem>
            ))}
          </Box>
        </Menu>
      ) : null}
      <ConfirmModal
        open={warning}
        setOpen={setWarning}
        handleConfirm={handleConfirm}
        {...props}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "FooterStyle" })(ClosAbleMenu);
