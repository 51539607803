import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Autocomplete, Switch, Box, Card, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useParams } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import { getTransactionLeads } from "../../../redux/transactions/getTransactionLeads";
import { debounce } from "lodash";
import moment from "moment";
import { updateTransactionContract } from "../../../redux/transactions/updateTransactionContract";
import CircularProgress from "@mui/material/CircularProgress";
import { formatPersonTypeData, initForm } from "./helpers";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "25px!important",
    margin: "5px 0px!important",
  },
  field: {
    border: "none!important",
    borderBottom: "1px solid #000000DE!important",
  },
  autocompleteOption: {
    padding: "15px",
    margin: "10px",
    fontFamily: "AvenirNext !important",
  },
  optionHeader: {
    fontFamily: "AvenirNext !important",
    fontSize: "16px",
  },
  optionHeaderSpan: {
    fontFamily: "AvenirNext !important",
    fontSize: "14px",
  },
  optionSubHeaderLeft: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
  optionSubHeaderRight: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "AvenirNext !important",
  },
}));

function AddEditPeopleModal(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const muiClasses = useStyles();

  let { open, classes, importantPeople, ContractData, mode = "Add", closeModal, selectedPeopleToEdit } = props;

  const [errorAlert, setErrorAlert] = React.useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [position, setPosition] = React.useState(null);
  const [searchText, setSearchText] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [entitySwitch, setEntitySwitch] = React.useState(false);

  const leads = useSelector((state) => state.transactions.TransactionsContract.leads?.data || []);
  const leadsLoading = useSelector((state) => state.transactions.TransactionsContract.leads?.isLoading || false);
  const updateContract = useSelector((state) => state.transactions.TransactionsContract.updateContract);

  React.useEffect(() => {
    setPosition(null);
    setFormData({});
    setOptions([]);
    setSearchText(null);
    setInputValue("");
    setEntitySwitch(false);
    return () => {
      setPosition(null);
      setFormData({});
      setOptions([]);
      setSearchText(null);
      setInputValue("");
      setEntitySwitch(false);
    };
  }, [open]);

  React.useEffect(() => {
    if (updateContract.success && !updateContract.isLoading && open) {
      setErrorAlert({ errorMsg: "You have successfully added the people", errorType: "success", isOpen: true });
      closeModal();
    }
  }, [updateContract]);

  React.useEffect(() => {
    if (leads.people) setOptions(leads?.people);
  }, [leads]);

  React.useEffect(() => {
    if (mode === "Edit") {
      setPosition(selectedPeopleToEdit.type);
      setFormData({
        entityName: selectedPeopleToEdit.entity_name,
        personId: selectedPeopleToEdit.personId,
        firstName: selectedPeopleToEdit.first_name,
        lastName: selectedPeopleToEdit.last_name,
        phone: selectedPeopleToEdit.phone,
        email: selectedPeopleToEdit.email,
        middleInitials: selectedPeopleToEdit.middle_initials,
      });

      if (
        selectedPeopleToEdit.entity_name !== null &&
        selectedPeopleToEdit.entity_name !== undefined &&
        selectedPeopleToEdit.entity_name?.length !== 0
      ) {
        setEntitySwitch(true);
      }
    }
  }, [mode]);

  React.useEffect(() => {
    if (entitySwitch) {
      setFormData({
        ...formData,
        firstName: "",
        lastName: "",
        middleInitials: "",
      });
    }
  }, [entitySwitch]);

  const debouncedSearch = React.useRef(
    debounce(async (val) => {
      dispatch(getTransactionLeads({ name: val }));
    }, 500)
  ).current;

  const onChangeSearchText = (newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 0) debouncedSearch(newInputValue);
  };

  const onSelectLead = (selectedLead) => {
    setSearchText(selectedLead);
    if (selectedLead) {
      setFormData((prevForm) => {
        return {
          ...prevForm,
          personId: selectedLead.id,
          firstName: selectedLead.firstName,
          lastName: selectedLead.lastName,
          phone: selectedLead.phones ? selectedLead.phones[0]?.value : "",
          email: selectedLead.emails ? selectedLead.emails[0]?.value : "",
          middleInitials: "",
        };
      });
    } else {
      setFormData((prevForm) => {
        return {
          ...prevForm,
          ...initForm,
        };
      });
    }
  };

  const handleAddPeople = () => {
    let postionObj = {};

    if (position) {
      if (mode === "Add") {
        postionObj = { ...formatPersonTypeData(position, formData) };
      } else {
        postionObj = { ...formatPersonTypeData(position, formData) };
        if (position !== selectedPeopleToEdit.type) {
          postionObj = { ...postionObj, ...formatPersonTypeData(selectedPeopleToEdit.type, initForm) };
        }
      }

      dispatch(updateTransactionContract({ id, contractData: { ...ContractData, ...postionObj } }));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => closeModal()}
        aria-labelledby="stakeholder-dialog-title"
        aria-describedby="stakeholder-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={muiClasses.modalHeader} id="stakeholder-dialog">
          {mode} Stakeholder
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={4} sx={{ zIndex: "1" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <FormControlLabel
                    disabled={importantPeople.find((p) => p.type === "Buyer 1")}
                    value="Buyer 1"
                    control={<Radio />}
                    label="Buyer 1"
                  />
                  <FormControlLabel
                    disabled={importantPeople.find((p) => p.type === "Buyer 2")}
                    value="Buyer 2"
                    control={<Radio />}
                    label="Buyer 2"
                  />
                  <FormControlLabel
                    disabled={importantPeople.find((p) => p.type === "Seller 1")}
                    value="Seller 1"
                    control={<Radio />}
                    label="Seller 1"
                  />
                  <FormControlLabel
                    disabled={importantPeople.find((p) => p.type === "Seller 2")}
                    value="Seller 2"
                    control={<Radio />}
                    label="Seller 2"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={8} sx={{ zIndex: "1" }}>
              <Autocomplete
                fullWidth
                onChange={(event, newValue) => onSelectLead(newValue)}
                onInputChange={(event, newInputValue) => {
                  onChangeSearchText(newInputValue);
                }}
                inputValue={inputValue}
                value={searchText}
                options={[...options]}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search All Leads by name"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {leadsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Card component="div" {...props} className={muiClasses.autocompleteOption}>
                    <Typography className={muiClasses.optionHeader}>
                      <b>{option.name}</b> - <span className={muiClasses.optionHeaderSpan}>{option.source}</span>
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "3px" }}>
                      <Typography className={muiClasses.optionSubHeaderLeft}>
                        Created on {option.created ? moment(option.created).format("YYYY-MM-DD") : ""}
                      </Typography>
                      <Typography className={muiClasses.optionSubHeaderRight}>Assigned to {option.assignedTo}</Typography>
                    </Box>
                  </Card>
                )}
              />
            </Grid>
          </Grid>

          {entitySwitch && (
            <Grid container spacing={3} mt={4}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Entity Name"
                  variant="standard"
                  className={muiClasses.field}
                  value={formData.entityName || ""}
                  onChange={(e) => setFormData((prevForm) => ({ ...prevForm, entityName: e.target.value }))}
                />
              </Grid>
            </Grid>
          )}

          {!entitySwitch && (
            <Grid container spacing={3} mt={4}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="standard"
                  className={muiClasses.field}
                  value={formData.firstName || ""}
                  onChange={(e) => setFormData((prevForm) => ({ ...prevForm, firstName: e.target.value }))}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Middle Initial"
                  variant="standard"
                  className={muiClasses.field}
                  value={formData.middleInitials || ""}
                  onChange={(e) => setFormData((prevForm) => ({ ...prevForm, middleInitials: e.target.value }))}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="standard"
                  className={muiClasses.field}
                  value={formData.lastName || ""}
                  onChange={(e) => setFormData((prevForm) => ({ ...prevForm, lastName: e.target.value }))}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3} mt={4} alignItems="center">
            <Grid item xs={5}>
              <TextField
                fullWidth
                variant="standard"
                className={muiClasses.field}
                label="Email Address"
                value={formData.email || ""}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, email: e.target.value }))}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                variant="standard"
                className={muiClasses.field}
                label="Phone Number"
                value={formData.phone || ""}
                onChange={(e) => setFormData((prevForm) => ({ ...prevForm, phone: e.target.value }))}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switch defaultChecked={entitySwitch} checked={entitySwitch} onChange={(e) => setEntitySwitch(e.target.checked)} />}
                label="Entity"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => closeModal()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleAddPeople()}
            classes={{ root: classes.saveModalButton }}
            color="inherit"
            autoFocus
            loading={updateContract.isLoading}
            loadingPosition="start"
            sx={{
              backgroundColor: position ? "#0B0909 !important" : "#ddd !important",
            }}
            disabled={position === null}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNoteModalStyle" })(AddEditPeopleModal);
