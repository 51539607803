import React, { useState, useEffect } from "react";
import { Grid, Card, CardHeader, Skeleton, AvatarGroup, Stack, Paper, Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import ViewCarouselRoundedIcon from "@mui/icons-material/ViewCarouselRounded";
import UpdateTaskStatus from "../../modals/updateTaskStatus";
import { getTransactionTasks } from "../../../../redux/transactions/getTasks";
import Loading from "../../../../components/shared/loading";

function TransactionsDetailTaskCard(props) {
  const [open, setOpen] = useState(false);
  let { classes, contractId } = props;
  const dispatch = useDispatch();
  const TasksData = useSelector((item) => item.transactions.Tasks.TasksData);
  const [id, setId] = useState("");
  useEffect(() => {
    if (!TasksData.isLoading) {
      let startingDueDate = new Date().toISOString();
      let endingDueDate = moment(new Date()).add(2, "d").toISOString();
      dispatch(getTransactionTasks({ id: props.id, startingDueDate, endingDueDate, status: "pending" }));
    }
  }, []);

  return (
    <Card className={classes.overViewTabCardMain}>
      <CardHeader classes={{ title: classes.overViewTabCardHeading }} title="Tasks" />
      {TasksData.isLoading &&
        Array.from(Array(5).keys()).map(() => (
          <Card className={classes.overViewTabCardPeoples}>
            <CardHeader
              classes={{
                title: classes.importantTasksDetailHeader,
                subheader: classes.importantPeoplesDes,
              }}
              title={<Skeleton animation="wave" height={20} width="70%" />} /* Coldwell Banker */
              subheader={<Skeleton animation="wave" height={15} width="40%" />}
              action={<Skeleton animation="wave" height={30} width="20px" />}
            />
          </Card>
        ))}
      {!TasksData.isLoading && TasksData?.data?.tasks?.length ? (
        TasksData?.data?.tasks.map((item) => (
          <Card className={classes.overViewTabCardPeoples}>
            <CardHeader
              classes={{
                title: classes.importantTasksDetailHeader,
                subheader: classes.importantPeoplesDes,
              }}
              title={item.name} /* Coldwell Banker */
              subheader={` ${moment.utc(item.due_date).format("L")}`}
              action={
                <Checkbox
                  onChange={() => {
                    if (item.status !== "completed") {
                      setId(item.id);
                      setOpen(true);
                    }
                  }}
                  checked={item.status === "completed"}
                />
              }
            />
          </Card>
        ))
      ) : !TasksData.isLoading ? (
        <DataNotFound
          color="#BDBDBD"
          fontSize="26px"
          title="No Task"
          icon={<ViewCarouselRoundedIcon sx={{ fontSize: "120px", color: "#BDBDBD" }} fontSize="large" />}
        />
      ) : (
        ""
      )}
      <UpdateTaskStatus open={open} id={id} setOpen={setOpen} />
    </Card>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailTaskCardStyle" })(TransactionsDetailTaskCard);
