import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const addLeadSmartList = createAsyncThunk("login/addLeadSmartList", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/smartlists?type=lead`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const updateLeadSmartList = createAsyncThunk("login/updateLeadSmartList", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/smartlists/`+data.id, {...data,id:null}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return {id:data.id, smartList:resp.data.smartlist};
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});