import {
  IconButton,
  Box,
  Chip,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Input from "../../../../../components/uiElements/input/Input";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { createTextAgentRecruitment } from "../../../../../redux/agents/agentRecruitment/createTextAgentRecruitment";
import { styleSheet } from "./style";
import { GetTextTemplatesModal } from "../../Modal/getTextTemplateModal";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import TextTemplatesPopper from "../../../../../components/shared/getTextTemplatesPopper";
import {
  textEmailTemplate,
  selectTextTemplateById,
} from "../../../../../redux/agents/agentRecruitment/getTextTemplates";
import DeleteDialog from "../../../../../components/shared/deleteModal";
import WallpaperOutlinedIcon from "@mui/icons-material/WallpaperOutlined";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { uploadImageData } from "../../../../../utils/uploadImage";
import { updateData } from "../../../../../utils/updateData";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../../../components/responseAlert";

const TextTab = ({ classes }) => {
  const param = useParams();
  const dispatch = useDispatch();
  const { agentProfile, currentTab } = useSelector((item) => item.agentProfile);
  const { twilio_call_extension } = useSelector(
    (item) => item.adminAuth.adminDetail
  );
  const [routeId, setRouteId] = useState();
  const [text, setText] = useState("");
  const [open, setOpen] = useState({ state: false, type: "", desc: "" });
  const [selectedItem, setSelectedItem] = useState([]);
  const [fromNumber, setFromNumber] = useState("");
  const [templateModal, setTemplateModal] = useState(false);
  const [edtitorValue, setEdtitorValue] = useState("");
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const templateData = useSelector((state) =>
    selectTextTemplateById(state, param.id)
  ); /// select editor data from store by Id
  const [confirmOpen, setconfirmOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [loading, setLoading] = useState(false);

  const adminName = useSelector(
    (state) => state.adminAuth.adminDetail.full_name
  );
  const contactFirstName = agentProfile.list.MemberFirstName;

  const setConfirmHandler = () => {
    setconfirmOpen(true);
  };
  const closeDeleteHandler = () => {
    setconfirmOpen(false);
  };
  const clearTextEditor = () => {
    setText("");
    setconfirmOpen(false);
  };
  useEffect(() => {
    if (param) {
      setRouteId(param?.id);
      setFromNumber(
        `${
          twilio_call_extension
            ? twilio_call_extension.direct_number
            : process.env.REACT_APP_GLOBAL_TWILIO_NUMBER
        }`
      );
    }
    if (templateData) {
      setText(templateData.body);
    }
  }, [param]);

  /// draft data
  useEffect(() => {
    const saveDraft = setTimeout(() => {
      dispatch(
        textEmailTemplate({
          id: routeId,
          body: text.toString("html"),
        })
      );
    }, 500);

    return () => {
      clearTimeout(saveDraft);
    };
  }, [text]);

  const sendTextFunc =async () => {
    const schema = {
      agentRecruitmentId: routeId,
      message: text,
      fromNumber,
      toNumber: selectedItem,
      mediaUrls: selectedMedia?.length
        ? selectedMedia?.map((item) => item.path)
        : null,
    };
    if (selectedItem.length) {
      let fetch=await dispatch(createTextAgentRecruitment({ ...schema }));
      if (fetch?.payload) {
        setOpen({
          state: true,
          type: "success",
          desc: "You have sent text successfully!",
        });
        setSelectedMedia([]);
        setSelectedItem([]);
      }else {
        setOpen({
          state: true,
          type: "error",
          desc: "Unknown error occurred! Please make sure, recipient is correct!",
        });
      }

    } else {
      setOpen({
        state: true,
        type: "error",
        desc: "Recipient is missing!",
      });
    }
    setText("");
  };

  const onChange = (e) => {
    let convertValue = e.target.value.toString("html");
    const eValue = `${convertValue}`;
    let foundSlash = false;
    const textItems = eValue.split("\n");
    textItems.map((newLine) => {
      let createDomNode = document.createElement("div");
      createDomNode.innerHTML = newLine;
      if (createDomNode.innerText !== "") {
        let getEditorValue = createDomNode.innerText;
        let firstChar = getEditorValue.charAt(0);
        if (firstChar === "/") {
          foundSlash = true;
          const removeChar = getEditorValue.replace("/", "");
          setEdtitorValue(removeChar);
        }
      }
    });
    if (foundSlash !== false) {
      setPopupModalStatus(true);
    } else {
      setPopupModalStatus(false);
    }
    setText(e.target.value);
  };
  const closeModal = (e) => {
    setTemplateModal(false);
  };

  const closeModalHandler = () => {
    setPopupModalStatus(false);
  };

  var mapObj = {
    "%sender_name%": adminName,
    "%contact_first_name%": contactFirstName,
    "%agent_name%": "Dummy Agent Name",
  };
  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html.replace("</div>", "\n");
    return divContainer.textContent || divContainer.innerText || "";
  }
  ///selected the suggestion value and replace the old value with current value
  const selectedTemplateFnc = ({ message }) => {
    if (message) {
      let msg = message.replace(
        /%sender_name%|%contact_first_name%|%agent_name%/gi,
        function (matched) {
          return mapObj[matched];
        }
      );
      let sendTextOldValue = text.toString("html");
      let sendTextNewValue = "/" + edtitorValue;

      let updatedBody = "";
      if (sendTextOldValue.includes(sendTextNewValue)) {
        updatedBody = sendTextOldValue.replace(sendTextNewValue, msg);
      } else {
        updatedBody = msg;
      }
      setText(getText(updatedBody));
      dispatch(
        textEmailTemplate({
          id: routeId,
          body: getText(updatedBody),
        })
      );
      setOpen({
        state: "true",
        type: "success",
        desc: "Message template has been imported!",
      });
    }
  };

  const handleFileChange = async (files) => {
    let newImagesArray = [];
    let preLength = selectedMedia?.length;
    const filesArray = Array.from(files);
    const Data = new FormData();
    Data.append("subfolder", "attachments/recruitment_id");
    Data.append("folder", "text-messages");
    filesArray?.map((file) => {
      Data.append("files[]", file);
      newImagesArray.push({ fileName: file?.name });
    });
    setSelectedMedia((media) => [...media, ...newImagesArray]);
    setLoading(true);
    const result = await uploadImageData(
      `${process.env.REACT_APP_BASE_URL}api/common/upload-file-to-s3`,
      Data,
      "POST"
    );
    if (result.status === 200) {
      setLoading(false);
      let newArray = [];
      newImagesArray?.map((item, index) => {
        newArray.push({ ...item, ...result.data[index] });
      });
      setSelectedMedia((media) => [...media.slice(0, preLength), ...newArray]);
    } else {
      setOpen({
        state: true,
        type: "error",
        desc: result.message || "APi not found ",
      });
      setLoading(false);
    }
  };

  const handleDelete = async (data, index) => {
    setSelectedMedia((media) => {
      return media.filter((it, idx) => idx !== index);
    });
    if (data.relativePath) {
      await updateData(
        `${process.env.REACT_APP_BASE_URL}api/common/remove-file-from-s3`,
        { relativeFilePath: data?.relativePath },
        "DELETE"
      );
    }
  };
  return (
    <>
      <Box sx={{ marginBottom: "10px" }}>
        <Box className={classes.emailTabRoot}>
          <Autocomplete
            multiple
            limitTags={3}
            onEnter
            sx={{
              "& .MuiInputBase-root": {
                paddingLeft: "45px !important",
              },
              "& label": {
                position: "absolute",
                top: "16px",
                left: "13px",
                textTransform: "capitalize",
                fontSize: "16px",
              },
              "& .MuiAutocomplete-endAdornment": {
                display: "none",
              },
            }}
            id="to-field-tags"
            options={agentProfile?.list?.lead_phones}
            getOptionLabel={(option) => {
              return (
                <>
                  <LocalPhoneIcon sx={{ fontSize: 18, marginTop: "2px" }} />{" "}
                  &nbsp;
                  {option.value}
                </>
              );
            }}
            value={selectedItem}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((vl, index) => (
                <Chip
                  label={vl}
                  {...getTagProps({ index })}
                  key={index}
                  onDelete={() => {
                    const des = [...selectedItem];
                    des.splice(index, 1);
                    setSelectedItem(des);
                  }}
                  icon={
                    <LocalPhoneIcon sx={{ fontSize: 18, marginTop: "2px" }} />
                  }
                ></Chip>
              ))
            }
            onChange={(a, b, c, d, e) => {
              let schema = null;

              if (typeof d?.option == "object") {
                schema = d?.option;
              }
              if (typeof a.target.value === "string") {
                schema = {
                  value: a.target.value,
                };
              }

              if (a.keyCode === 8) {
                const key_code = [...selectedItem];
                key_code.pop();
                setSelectedItem(key_code);

                return;
              }

              const duplicatedValues = selectedItem.findIndex(
                (i) => i === schema?.value
              );

              if (!!schema) {
                if (duplicatedValues === -1) {
                  const kk = [...selectedItem];
                  kk.push(schema.value);
                  setSelectedItem(kk);
                  return;
                }
              }
            }}
            renderInput={(params) => (
              <>
                <Input {...params} label="To :" />
              </>
            )}
          />
        </Box>
      </Box>

      <Box sx={{ marginBottom: "10px" }}>
        <Input
          InputProps={{
            startAdornment: (
              <>
                <Typography sx={{ minWidth: "50px" }}>From :</Typography>
                <Chip
                  label={fromNumber}
                  style={{ marginRight: "5px" }}
                  icon={
                    <LocalPhoneIcon sx={{ fontSize: 18, marginTop: "2px" }} />
                  }
                />
              </>
            ),
          }}
        />
      </Box>
      <div id="displayPopModal"> </div>
      <Box className={classes.inputSectionArea}>
        <Input
          id="outlined-textarea"
          value={text}
          placeholder="Text..."
          multiline
          fullWidth
          rows={6}
          onChange={onChange}
        />
        <Box className={classes.mediaSectionArea}>
          <Box className={classes.uploadedFileSection}>
            {selectedMedia?.map((item, index, self) => (
              <CustomTooltip
                key={index}
                title={item?.fileName ? item?.fileName : "Uploading Media"}
                placement="bottom"
              >
                <Box key={index} className={classes.uploadedFileSectionItem}>
                  <CancelRoundedIcon
                    fontSize="small"
                    className={classes.uploadedDeleteIcon}
                    key={index}
                    onClick={() => handleDelete(item, index, selectedMedia)}
                  />
                  {item?.path ? (
                    <img src={item?.path} alt="uploaded media" />
                  ) : (
                    <WallpaperOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#9A9EA1" }}
                    />
                  )}
                </Box>
              </CustomTooltip>
            ))}
          </Box>
          <Box className={classes.selectFileArea}>
            <CustomTooltip title="Attach Media" placement="top">
              <IconButton component="label">
                <WallpaperOutlinedIcon sx={{ color: "#9A9EA1" }} />
                <input
                  hidden
                  onChange={(e) => handleFileChange(e.target.files)}
                  accept=".jpg, .png"
                  multiple
                  type="file"
                />
              </IconButton>
            </CustomTooltip>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        sx={{ marginTop: "30px" }}
        alignItems="center"
      >
        <Grid>
          <label
            className={classes.textLinkBtn}
            onClick={() => setTemplateModal(true)}
          >
            <ContentCopyIcon
              sx={{  marginRight: "10px" }}
              fontSize="20"
            />
            Templates
          </label>
        </Grid>

        <Grid>
          <DeleteDialog
            openPopup={confirmOpen}
            onConfirm={clearTextEditor}
            closeModal={closeDeleteHandler}
          ></DeleteDialog>
        </Grid>
        <Grid>
          <label
            className="emailDeleteBtn"
            onClick={() => setConfirmHandler(true)}
          >
            {/* Delete button for texarea */}
            <DeleteForeverIcon
              style={{
                width: "1.4rem",
                height: "1.4rem",
                position: "relative",
                top: "9px",
                cursor: "pointer",
                marginRight: "10px",
                color: "error",
              }}
              sx={{ marginRight: "30px" }}
              fontSize="40"
            />
          </label>

          <LoadingButton
            onClick={sendTextFunc}
            variant="contained"
            loadingPosition="end"
            loading={currentTab?.loading  || loading}
          >
            Send text
          </LoadingButton>
        </Grid>
      </Grid>
      <ResponseAlert
        open={open.state}
        setOpen={() => setOpen({ desc: "", type: "", state: false })}
        alertType={open.type}
        alertMessage={open.desc}
      />
      {popupModalStatus ? (
        <TextTemplatesPopper
          openStatus={popupModalStatus}
          selectedTemplate={selectedTemplateFnc}
          EditorValue={edtitorValue}
          closeModal={closeModalHandler}
        />
      ) : null}

      {templateModal ? (
        <GetTextTemplatesModal
          selectedTemplate={selectedTemplateFnc}
          closeModal={closeModal}
          state={templateModal}
        />
      ) : null}
    </>
  );
};
export default withStyles(styleSheet, { name: "test" })(TextTab);
