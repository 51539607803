import React from "react";
import GoogleMapReact from "google-map-react";
import { Box } from "@mui/material";

const AnyReactComponent = ({ text }) => (
  <Box
    sx={{
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      border: "5px red solid",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize:"15px",
      fontWeight:'600',
      backgroundColor:'white'
    }}
  >
    {text}
  </Box>
);

export default function PropertySearchMap() {
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCDqh1uJXV0q8ltkiA8Ijp5B9cE9oZ2WsM" }}
        defaultCenter={{
          lat: parseFloat(34.0632049),
          lng: parseFloat(-84.395413),
        }}
        defaultZoom={16}
      >
        <AnyReactComponent lat={34.0632049} lng={-84.395413} text="Property" />
      </GoogleMapReact>
    </div>
  );
}
