export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "10px",
    marginBottom: "16px",
  },

  pageHeading: {
    fontSize: "24px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  pageHeadingSocial: {
    fontSize: "24px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
    width: "30ch !important",
  },
  detailInput: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  saveButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  UploadCustomerButton: {
    minHeight: "180px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "500",
    cursor: "pointer",
  },
  deleteSection: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    marginTop: "-35px !important",
  },
  inputLabel: {
    color: "black  !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
    fontSize: "12px",
  },
  ReviewField: {
    width: "100%!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "4px!important",
    fontWeight: "400",
    fontSize: "14px",

    "& .MuiInputBase-root": {
      padding: 0,
    },

    "& textarea": {
      lineHeight: "24px",
      padding: "11px 15px",
      margin: "8px 0",
      letterSpacing: "0.5px",
      textAlign: "justify",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ddd",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#0B0909",
        borderRadius: 1,
      },
    },
  },
  bioTabsSection: {
    display: "flex !important",
    flexDirection: "row !important",
    marginBottom: "10px",
  },
  tabsRightSection: {
    flex: 1,
    height: "43px !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.15) !important",
  },
  bioTabs: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    borderTop: "0.75px solid rgba(0, 0, 0, 0.15) !important",
    borderRight: "0.75px solid rgba(0, 0, 0, 0.15) !important",
  },
  bioTabsItem: {
    color: "black  !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
    fontSize: "16px",
    minWidth: "163px !important",
    height: "43px !important",
    display: "flex !important",
    alignItems: "center !important",
    border: "0.75px solid rgba(0, 0, 0, 0.15) !important",
    borderTop: "none !important",
    background: "#F6F6F6 !important",
    cursor: "pointer !important",
    paddingLeft: "16px !important",
  },
  bioTabsItemActive: {
    color: "black  !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
    fontSize: "16px",
    minWidth: "163px !important",
    height: "43px !important",
    display: "flex !important",
    alignItems: "center !important",
    borderRight: "0.75px solid white !important",
    borderLeft: "0.75px solid white !important",
    borderBottom: "0.75px solid white !important",
    borderTop: "none !important",
    background: "white !important",
    cursor: "pointer !important",
    paddingLeft: "16px !important",
  },
  miscellaneousDetailItem: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  miscellaneousDetailItemHeading: {
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
    fontSize: "16px !important",
  },
  miscellaneousDetailItemFields: {
    display: "flex !important",
    flexDirection: "column !important",
    gap: "20px",
  },
});
