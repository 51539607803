import * as React from "react";
import { Card, CardHeader, Avatar, Grid, Skeleton } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { thounsandNumberFormater } from "../../../tools/cmas/helper";

function TransactionsContractsStats(props) {
  let { classes } = props;
  const allTransactions = useSelector((state) => state.transactions.TransactionsList.allTransactions);

  return (
    <Grid container direction="row" spacing={1} justifyContent="flex-start">
      <Grid item md={3} lg={3} xl={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.transactionCardHeader,
              subheader: classes.transactionCardDetail,
            }}
            avatar={<Avatar sx={{ width: "35px", height: "35px" }}>OP</Avatar>}
            title={allTransactions.isLoading ? <Skeleton width={60} variant="text" /> : allTransactions.total_active || "0"}
            subheader="Contracts"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={3} xl={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.transactionCardHeader,
              subheader: classes.transactionCardDetail,
            }}
            avatar={<Avatar sx={{ width: "35px", height: "35px" }}>OP</Avatar>}
            title={allTransactions.isLoading ? <Skeleton width={60} variant="text" /> : allTransactions.total_past_contingencies || "0"}
            subheader="Past Contingencies"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={3} xl={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.transactionCardHeader,
              subheader: classes.transactionCardDetail,
            }}
            avatar={<Avatar sx={{ width: "35px", height: "35px" }}>OP</Avatar>}
            title={allTransactions.isLoading ? <Skeleton width={60} variant="text" /> : allTransactions.this_week_closing || "0"}
            subheader="Closing This week"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={3} xl={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.transactionCardHeader,
              subheader: classes.transactionCardDetail,
            }}
            avatar={<Avatar sx={{ width: "35px", height: "35px" }}>OP</Avatar>}
            title={allTransactions.isLoading ? <Skeleton width={60} variant="text" /> : allTransactions.this_month_closed || "0"}
            subheader="Closed This Month"
          />
        </Card>
      </Grid>
      <Grid item md={3} lg={3} xl={2}>
        <Card sx={{ mb: "10px" }}>
          <CardHeader
            classes={{
              title: classes.transactionCardHeader,
              subheader: classes.transactionCardDetail,
            }}
            avatar={<Avatar sx={{ width: "35px", height: "35px" }}>OP</Avatar>}
            title={allTransactions.isLoading ? <Skeleton width={60} variant="text" /> : `$${thounsandNumberFormater(allTransactions.this_year_volume)}`}
            subheader="Volume Closed"
          />
        </Card>
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "TransactionsContractsStatsStyle" })(TransactionsContractsStats);
