import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Typography } from "@mui/material";
import number from "../../../config/currency.js";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Icon from '@mui/material/Icon';

const sliderArrowStyle = {
  cursor: "pointer",
  position: "absolute",
  zIndex: "999",
  fontSize: "28px",
  color: "#fff",
  background: "#433F3F",
  borderRadius: "50%",
  top: "43%",
};

const ArrowLeft = ({ onClick }) => <NavigateBefore sx={{ ...sliderArrowStyle, left: "10px" }} onClick={onClick} />;

const ArrowRight = ({ onClick }) => <NavigateNext sx={{ ...sliderArrowStyle, right: "10px", zIndex: 0 }} onClick={onClick} />;

export const isArrayCheck = (arr) => {
  return Array.isArray(arr) && arr.length > 0;
};

export const slider_settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

export const slider_settings_2 = {
  className: "slider",
  dots: false,
  infinite: true,
  adaptiveHeight: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <ArrowRight />,
  prevArrow: <ArrowLeft />,
};

export const getRowAdjustment = (key, value, isVisible, onClick, classes) => {
  return (
    <div className={classes.descSection}>
      {onClick && <Icon 
        onClick={onClick && onClick}
        color={`${isVisible ? 'success' : 'error'}`}
      >{isVisible ? <VisibilityIcon /> : <VisibilityOffIcon /> }</Icon>}
      <div className={classes.houseArea}>
        <strong className={classes.houseAreaStrong}>{key}</strong>
      </div>

      <Typography className={classes.houseDesc}>{value}</Typography>
    </div>
  );
};

const objectsEqual = (o1, o2) => {
  if (o1 === null || o2 === null) return false;
  return typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
};

export const isArraysEqual = (a1, a2) => {
  if (!isArrayCheck(a1) || !isArrayCheck(a2)) {
    return false;
  }

  return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
};

var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export const thounsandNumberFormater = (number) => {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1).replace(/\.0+$/, "") + suffix;
};

export const numberWithCommas = (n) => {
  return number.format(parseFloat(n));
};

export const numberToCurrency = (n) => {
  return number.formatMoney(parseFloat(n));
};
