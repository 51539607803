import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getSearchSuggestionData = createAsyncThunk("Listings/getSearchSuggestionData", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/common/suggestions?market_id=${data.id ? data.id : 1}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
