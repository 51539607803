import * as React from "react";
import { Card, Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
function TrainingIssues(props) {
  let { count = 3 } = props;
  return (
    <Box>
      {Array.from(Array(count).keys()).map(() => (
        <Card sx={{ mb: "15px" }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={<Skeleton animation="wave" height={20} width="50%" style={{ marginBottom: 6 }} />}
            subheader={<Skeleton animation="wave" height={15} width="80%" />}
          />
          <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" />
          <CardContent>
            <React.Fragment>
              <Skeleton animation="wave" height={15} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
export default TrainingIssues;
