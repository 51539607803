import React from "react";
import { Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { useDispatch, useSelector } from "react-redux";
import { mIdxSignUp } from "../../../redux/leads/savedSearches";
import LoadingButton from "@mui/lab/LoadingButton";
function CreateAccount(props) {
  let { classes, handleUpdateView, setErrorAlert, userDetail } = props;
  const dispatch = useDispatch();
  const searchList = useSelector((item) => item.leads.savedSearches.searchList);
  const signUp = useSelector((item) => item.leads.savedSearches.signUp);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "Your have successfully created the account!",
      errorType: "success",
      isOpen: true,
    });
    handleUpdateView("search-list", "");
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSignUp = (data) => {
    let obj = {
      schema: {
        firstName:
          searchList?.meta?.payload?.person?.firstName || userDetail?.firstName,
        lastName:
          searchList?.meta?.payload?.person?.lastName || userDetail?.lastName,
        email:
          searchList?.meta?.payload?.person?.emails?.find(
            (item) => item.isPrimary
          )?.value ||
          searchList?.meta?.payload?.person?.emails[0]?.value ||
          userDetail?.emails?.find((item) => item.isPrimary)?.value ||
          userDetail?.emails[0]?.value,
        phoneNumber:
          searchList?.meta?.payload?.person?.phones?.find(
            (item) => item.isPrimary
          )?.value ||
          searchList?.meta?.payload?.person?.phones[0]?.value ||
          userDetail?.phones?.find((item) => item.isPrimary)?.value ||
          userDetail?.phones[0]?.value,
      },
      handleError,
      handleSuccess,
      site_id: data?._id,
      dataset: data?.dataset,
    };
    dispatch(mIdxSignUp(obj));
  };
  return (
    <Box className={classes.createAccountArea}>
      <Box
        className={classes.sitesListArea}
        sx={{ pointerEvents: signUp.isLoading ? "none" : "" }}
      >
        {searchList?.meta?.sites?.map((item, index) => (
          <Box
            onClick={() => handleSignUp(item)}
            key={index}
            className={classes.sitesListAreaItem}
          >
            <Box>
              <Typography className={classes.site_domain}>
                {item.site_domain}
              </Typography>
            </Box>
            <Box className={classes.siteLogoArea}>
              <img src={item.logo} alt={item.site_name} />
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={classes.addNewSearchButton}>
        <LoadingButton
          sx={{ paddingLeft: "15px", paddingRight: "15px" }}
          size="small"
          className={classes.searchListItemButton}
          onClick={() => handleUpdateView("search-list", "")}
          loading={Boolean(signUp.isLoading)}
          loadingPosition="end"
        >
          Cancel
        </LoadingButton>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "CreateAccountStyle" })(
  CreateAccount
);
