import React from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import { useNavigate } from "react-router-dom";
import { NameCard, UserRole, TeamCell } from "./TableCellsUsers";
import { Typography, Chip } from "@mui/material";
import { marketCustom } from "../../../../utils/markets";
import TableSkeleton from "../../../../components/loader/tableLoader";
import { getAgentRosterList } from "../../../../redux/agents/agentRoster/getAgentRosterList";
import { useDispatch, useSelector } from "react-redux";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import moment from "moment";
// Table Columns
const UsersTable = ({ classes, data, totalAgents, loading }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const markets = useSelector((state) => state.agentList.markets);
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      renderCell: ({ row }) => {
        return (
          <NameCard
            first_name={row.first_name}
            src={
              row?.profile_images?.profile_img ||
              row?.profile_images?.profile_img_thumbnail
            }
            last_name={row.last_name}
          />
        );
      },
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      field: "role.name",
      headerName: "Role",

      renderCell: ({ row }) => {
        return row.role ? <UserRole role={row.role?.name} /> : "--";
      },
      width: 170,
      editable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      renderCell: ({ row }) => {
        return row.email ? row.email : "--";
      },
      width: 220,
      editable: false,
      sortable: true,
      type: "string",
    },
    {
      field: "team.name",
      headerName: "Team/Department",
      renderCell: ({ row }) => {
        return row.team ? (
          <TeamCell
            name={row.team.name}
            color={row.team.team_color || "rgba(33, 14, 243, 0.62)"}
            join_date={row.join_date}
          />
        ) : (
          "--"
        );
      },
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "last_login",
      headerName: "Last Login",
      renderCell: ({ row }) => {
        return row.last_login
          ? moment(moment(new Date(row.last_login).toString())).from(
              moment(new Date())
            )
          : "";
      },
      width: 250,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "market_id",
      headerName: "Market",
      renderCell: ({ row }) => {
        return (
          <>
            {row?.market_id ? (
              <CustomTooltip
                title={
                  row?.market_id && markets?.length
                    ? markets?.find((item) => item.id === row?.market_id)?.name
                    : ""
                }
              >
                <Chip
                  size="small"
                  label={
                    row?.market_id && markets?.length
                      ? markets?.find((item) => item.id === row?.market_id)
                          ?.name
                      : ""
                    // row?.market_id && marketCustom.find((item) => item.id === row.market_id)
                    //   ? marketCustom.find((item) => item.id === row.market_id)?.title
                    //   : row?.market_id && markets?.length
                    //   ? markets?.find((item) => item.id === row?.market_id)?.name
                    //   : ""
                  }
                  style={{
                    background:
                      row?.market_id &&
                      marketCustom.find((item) => item.id === row.market_id)
                        ? marketCustom.find((item) => item.id === row.market_id)
                            ?.backgroundColor
                        : "#80CADB",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            ) : (
              ""
            )}
          </>
        );
      },
      editable: true,
      sortable: true,
      flex: 1,
    },
    // {
    //   field: null,
    //   type:"actions",
    //   headerName: "Actions",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <TaskIcon fontSize="large" sx={{color:"rgba(0, 0, 0, 0.54)"}} />{" "}<LockResetIcon  fontSize="large" sx={{color:"rgba(0, 0, 0, 0.54)"}} />
    //       </>
    //     );
    //   },
    //   editable: false,
    //   sortable: false,
    //   flex: 1,
    // },
  ];

  // Detail view trigger for agent
  const onRowClick = ({ id }) => {
    navigate(`/users/${id}/details`);
  };

  return (
    <>
      <Box sx={{ margin: "10px 5px" }}>
        <Typography
          classes={{ root: classes.agentTableHeading }}
          variant="subtitle1"
          component="p"
        >
          {totalAgents} Users
        </Typography>
      </Box>
      <Box className={classes.tableRoot}>
        <DataGridPro
          columns={columns}
          rows={data}
          loading={loading ? true : false}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowClick={(param) => onRowClick(param)}
          onRowsScrollEnd={(e) => {
            if (totalAgents > e.virtualRowsCount) {
              dispatch(
                getAgentRosterList({
                  pageNumber: Number(e.virtualRowsCount / 25) + 1,
                  pageSize: 25,
                  only_agents: false,
                })
              );
            }
          }}
        />
      </Box>
    </>
  );
};

export default withStyles(styleSheet, { name: "UsersTableStyle" })(UsersTable);
