import { createSlice } from "@reduxjs/toolkit";
import { getAllQueue } from "./getAllQueue";
import { addPhoneQueue } from "./addQueue";
import {updatePhoneQueue} from "./updateQueue"

const initialState = {
  queueList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    count: 0,
  },
  addQueue: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateQueue: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const PhoneQueues = createSlice({
  name: "PhoneQueues",
  initialState,
  extraReducers: {
    [getAllQueue.pending]: (state, action) => {
      state.queueList.isLoading = true;
      state.queueList.errMsg = null;
      state.queueList.success = false;
      if (action.meta.arg.reset) {
        state.queueList.data = [];
      }
    },
    [getAllQueue.fulfilled]: (state, action) => {
      state.queueList.success = true;
      state.queueList.isLoading = false;
      state.queueList.data = action.payload;
    },
    [getAllQueue.rejected]: (state, action) => {
      state.queueList.isLoading = false;
      state.queueList.success = false;
      state.queueList.errMsg = action.payload;
    },

    [addPhoneQueue.pending]: (state) => {
      state.addQueue.isLoading = true;
      state.addQueue.errMsg = null;
      state.addQueue.success = false;
    },
    [addPhoneQueue.fulfilled]: (state, action) => {
      state.addQueue.success = true;
      state.addQueue.isLoading = false;
      state.addQueue.data = action.payload;
      let newData=state.queueList.data?[...state.queueList.data]:[]
      newData.unshift(action.payload)
      state.queueList.data=newData
    },
    [addPhoneQueue.rejected]: (state, action) => {
      state.addQueue.isLoading = false;
      state.addQueue.success = false;
      state.addQueue.errMsg = action.payload;
    },
    [updatePhoneQueue.pending]: (state) => {
      state.updateQueue.isLoading = true;
      state.updateQueue.errMsg = null;
      state.updateQueue.success = false;
    },
    [updatePhoneQueue.fulfilled]: (state, action) => {
      state.updateQueue.success = true;
      state.updateQueue.isLoading = false;
      state.updateQueue.data = action.payload;
      let newData=state.queueList.data?[...state.queueList.data]:[]
      let index=newData.findIndex((item)=>item.id===action.payload.id)
      newData[index]={...newData[index],...action.payload}
      state.queueList.data=newData
    },
    [updatePhoneQueue.rejected]: (state, action) => {
      state.updateQueue.isLoading = false;
      state.updateQueue.success = false;
      state.updateQueue.errMsg = action.payload;
    },
  },
});
export default PhoneQueues.reducer;
export * from "./getAllQueue";
export * from "./addQueue";
export * from "./updateQueue"