import React from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Grid, Container, Box } from "@mui/material";
import {  useSelector } from "react-redux";
import Loading from "../../../components/shared/loading";
import FormDisplay from "./FormDisplay.js";

const FormDetail = (props) => {
  let {classes}=props
  const data = useSelector((item) => item.docList);

  return (
    <div className={classes.pageRoot}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={12} sx={{ pt: "0 !important" }}>
          <Box sx={{ height: 400, width: "100%" }}>
            {data.loading ? (
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <Box
                  sx={{
                    p: 8,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
               <Loading />
                </Box>
              </div>
            ) : (
              <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
                <FormDisplay {...props} />
              </Container>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

// export default FormDetail;
export default withStyles(styleSheet, { name: "FormStyle" })(FormDetail);
