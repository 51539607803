import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Stack, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { handleAddRepayment } from "../../../redux/listings/makeover";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import { NumberFormat } from "../../../utils/numberFormat";
function CheckPayment(props) {
  let { open, setOpen, classes, makeoverDetail } = props;
  const [errMsg, setErrMsg] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [projectDetail, setProjectDetail] = useState({
    repayment_amount: "",
    repayment_status: "paid",
    repayment_method: "check_payment",
  });
  const dispatch = useDispatch();
  const addRepayment = useSelector((state) => state.listings.makeover.addRepayment);

  useEffect(() => {
    if (open && makeoverDetail?.repayment_amount) {
      setProjectDetail({
        repayment_amount: makeoverDetail?.repayment_amount || "",
        repayment_status: makeoverDetail?.repayment_status || "paid",
        repayment_method: makeoverDetail?.repayment_method || "check_payment",
      });
    }
  }, [open]);

  useEffect(() => {
    if (addRepayment.success && !addRepayment.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the repayment detail",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!addRepayment.isLoading && addRepayment.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addRepayment.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addRepayment.isLoading]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!projectDetail.repayment_amount) {
      isFormValid = false;
      errorMsg["repayment_amount"] = "Please enter the amount on check to proceed further";
      setErrorAlert({
        errorMsg: "Please enter the amount on check",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleAddRepaymentAmount = () => {
    if (handleValidate()) {
      dispatch(handleAddRepayment({ ...projectDetail, repayment_amount: Number(projectDetail.repayment_amount), id: makeoverDetail.id }));
    }
  };
  const handleUpdateValue = (type, value) => {
    setProjectDetail({
      ...projectDetail,
      [type]: value,
    });
    setErrMsg({});
  };
  const handleClose = () => {
    setOpen(false);
    setProjectDetail({ repayment_amount: "" });
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogContent sx={{ width: "700px", mt: "20px", mb: "15px" }}>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <AttachMoneyRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} fontSize="large" />
                <Box>
                  Outstanding Makeover Balance:{" "}
                  {makeoverDetail?.invoice_paid_amount
                    ? NumberFormat({ number: makeoverDetail?.invoice_paid_amount, maximumFractionDigits: 2 })
                    : "$0"}
                </Box>
              </Stack>
            </Grid>
            <Grid item md={4}>
              <TextField
                value={projectDetail.repayment_amount}
                onChange={(e) => handleUpdateValue("repayment_amount", e.target.value)}
                fullWidth
                required
                variant="outlined"
                size="small"
                placeholder="Enter Amt on Check"
                error={errMsg["repayment_amount"]}
                type="number"
                id="repayment_amount"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={addRepayment.isLoading}
            className={classes.saveModalButton}
            color="inherit"
            onClick={() => handleAddRepaymentAmount()}
            autoFocus
            loadingPosition="start"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "CheckPaymentStyle" })(CheckPayment);
