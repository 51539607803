import React from "react";
import { Paper, TableContainer, Table, TableRow, TableCell, Skeleton, ListItemText } from "@mui/material";
function ContractTasksSkelton(props) {
  let { count = 10 } = props;
  return (
    <Paper sx={{ padding: "20px", marginBottom: "16px", backgroundColor: "white" }}>
      <TableContainer sx={{ padding: "0 15px", paddingBottom: "30px", minHeight: "500px", fontFamily: "AvenirNext!important" }}>
        <Table sx={{ minWidth: 500 }} size="small" aria-label="simple table">
          {Array.from(Array(count).keys()).map(() => (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell sx={{ width: "200px" }}>
                <ListItemText
                  primary={<Skeleton animation="wave" height={25} width="100%" style={{ marginBottom: 4 }} />}
                  secondary={<Skeleton animation="wave" height={20} width="60%" />}
                />
              </TableCell>
              <TableCell sx={{ width: "150px" }}>
                <ListItemText
                  primary={<Skeleton animation="wave" height={25} width="100%" style={{ marginBottom: 4 }} />}
                  secondary={<Skeleton animation="wave" height={20} width="60%" />}
                />
              </TableCell>
              <TableCell sx={{ width: "180px" }}>
                <Skeleton animation="wave" height={90} width="180px" sx={{ padding: "0px" }} />
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                <ListItemText primary={<Skeleton animation="wave" height={30} width="80px" />} />
              </TableCell>

              <TableCell sx={{ width: "150px" }}>
                {" "}
                <ListItemText
                  primary={<Skeleton animation="wave" height={30} width="70px" style={{ marginBottom: 4 }} />}
                  action={<Skeleton animation="wave" height={25} width="70px" />}
                />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Paper>
  );
}
export default ContractTasksSkelton;
