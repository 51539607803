import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getListDetail = createAsyncThunk(
  "Listings/getListDetail",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/reso/fmls/properties?access_token=51cfcd476147e3f0163f78c19afd9c22&$filter=ListingId eq '${data.id}'`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data?.data?.value[0];
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

