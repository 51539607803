export const styleSheet = (theme) => ({
  commisionSelectionButTon:{
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft:'30px !important',
    paddingRight:'30px !important'
  },
  inventoryDetailCheckIn:{
    backgroundColor: "#2E7D32 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "150px !important",
    paddingLeft:'30px !important',
    paddingRight:'30px !important',
  },
  
  paymentModalHeading:{
    fontSize:'22px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
  },
  appointmentModalHeading:{
    fontSize:'22px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
    display:'flex',
    justifyContent:'space-between'
  },
  agentProfileDetail:{
    fontSize:'13px !important',
    fontWeight:'400 !important',
    textTransform:'capitalize !important',
    minWidth:"10ch",
    color:"rgba(0, 0, 0, 0.6)  !important"
  },
  agentProfileDetailHeader:{
    fontSize:'13px !important',
    fontWeight:'500 !important',
    textTransform:'capitalize !important',
    minWidth:"10ch"
  },
  commentBox:{
    height:'60vh'
  },
  commentBoxCommentsArea:{
    height:'54.2vh', 
    padding:"8px",
    overflowY:'auto',
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  customScrollBar:{
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  commentBoxCommentCell:{
    fontSize:'10px !important',
    border:'none  !important',
    padding:'6px !important'
  },
  checkoutImgBoxArea:{
    height:'110px',
    width:'100%',
    position:"relative",
   "& img":{
   width:'56px'
   } 
  },
  checkoutImgBoxAreaText:{
    position:'absolute  !important',
    top:'50px  !important',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize:'13px',
    fontWeight:'500'
  },
  checkoutBoxAreaButton:{
    border: '1px solid rgba(33, 33, 33, 0.4)  !important',
    color:"#0B0909  !important",
    "&:hover":{
      border: '1px solid rgba(33, 33, 33, 0.8)  !important',
    }
  },
  checkoutBoxAreaDes:{
   fontSize:'13px  !important',
   marginTop:'4px !important'
  },
  EquipmentDetailBOx:{
    padding:'10px',
    minHeight:"300px"
  },
  EquipmentDetailSubBox:{
    padding:'5px !important',
  },
  agentInventoryModalCardDes: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)  !important",
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop:'-10px',
    marginBottom:'-10px'
  },
  agentInventoryModalCardHeader: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  customerTextField:{
    "& .MuiOutlinedInput-root":{
      fontSize: "12.5px !important",
    }
  },
  loadingArea:{
    height:"50vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  attachmentList: {
    margin: "5px",
    marginLeft: "0",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "4px",
    border: "1px solid #dddddd",
    padding: "4px",
    fontSize: "12px",
    "& svg": {
      fontSize: "20px",
      marginLeft: "4px",
      cursor: "pointer",
    },
  },
});
