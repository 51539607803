import React from "react";
import { Box, Tabs, Tab, Card, Chip, Stack } from "@mui/material";
import { TabContext } from "@mui/lab";
import { Link, useLocation } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useParams } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function SubHeader(props) {
  let { classes } = props;
  let { id } = useParams();
  const location = useLocation();
  return (
    <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
      {" "}
      <Box className={classes.TopTransactionsBarArea}>
        <Box className={classes.TopTransactionsBarAreaLeft}>
          {" "}
          <Box>
            {" "}
            <TabContext>
              <Box className={classes.TransactionsTabsUI}>
                <Tabs
                  value={location.pathname}
                  TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                  variant="scrollable"
                  aria-label="transactions-tabs"
                  sx={{ justifyContent: "space-around" }}
                >
                  <Tab
                    label="New Search"
                    value={`/listings/fmls/${id}/new-search`}
                    to={`/listings/fmls/${id}/new-search`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                  />
                  <Tab label="Saved Searches" component={Link} sx={{ padding: "0px 10px" }} />
                  <Stack className={classes.tagListingArea} direction={"row"} spacing={2}>
                    <Chip label="Marietta $300k-$400k" variant="outlined" onClick={() => {}} onDelete={() => {}} />
                    <Chip label="Marietta $300k-$400k" variant="outlined" onClick={() => {}} onDelete={() => {}} />
                    <Chip
                      label="Marietta $300k-$400k"
                      sx={{ background: "#0B0909", color: "white", "&:hover": { background: "#000000" }, "& .MuiSvgIcon-root": { color: "white" } }}
                      onClick={() => {}}
                      onDelete={() => {}}
                    />
                    <Chip label="Marietta $300k-$400k" variant="outlined" onClick={() => {}} onDelete={() => {}} />
                  </Stack>
                </Tabs>
              </Box>
            </TabContext>{" "}
          </Box>{" "}
        </Box>
        <Box className={classes.TopTransactionsBarAreaLeft}>
          <Search>
            <StyledInputBase placeholder="Power Search" inputProps={{ "aria-label": "search" }} />
          </Search>
        </Box>
      </Box>
    </Card>
  );
}
export default withStyles(styleSheet, { name: "SubHeaderListingStyles" })(SubHeader);
