import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  IconButton,
  DialogTitle,
  Typography,
  Grid,
  InputLabel,
  TextField,
  DialogActions,
  Button,
  Autocomplete,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import ResponseAlert from "../../responseAlert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import { handleAddMinimizeTab } from "../../../redux/widgets/index";
import { handleUpdateModalState } from "../../../redux/widgets";
import { uid } from "uid";
import NumberFormat from "react-number-format";
import LoadingButton from "@mui/lab/LoadingButton";
import { addNewLead } from "../../../redux/widgets/addLead";

const leadSourceOptions = [
  {
    title: "methodalabama.net",
    value: "methodalabama.net",
  },
  {
    title: "methodAtlanta.net",
    value: "methodAtlanta.net",
  },
  {
    title: "MethodTesting.com",
    value: "MethodTesting.com",
  },
  {
    title: "ourmethod.com",
    value: "ourmethod.com",
  },
  {
    title: "realtor",
    value: "realtor",
  },
  {
    title: "Realtor.com",
    value: "Realtor.com",
  },
  {
    title: "TheAtlantaMLS.com",
    value: "TheAtlantaMLS.com",
  },
  {
    title: "2X Real Estate Leads",
    value: "2X Real Estate Leads",
  },
  {
    title: "<unspecified>",
    value: "<unspecified>",
  },
  {
    title: "Agent Site",
    value: "Agent Site",
  },
  {
    title: "Import",
    value: "Import",
  },
];
function AddLeadModal(props) {
  let { classes } = props;
  const [userDetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    source: undefined,
  });
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const open = useSelector((state) => state.widgets.openAddLead);
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const addLead = useSelector((state) => state.widgets.addLead);
  const maximizedTab = useSelector((state) => state.widgets.maximizedTab);
  const handleClose = () => {
    dispatch(handleUpdateModalState({ type: "openAddLead", action: false }));
    setUserDetail({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      source: undefined,
    });
  };
  const handleAddMinimize = () => {
    dispatch(
      handleAddMinimizeTab({
        title:
          userDetail?.firstName ||
          userDetail?.lastName ||
          userDetail?.email ||
          "Add Person",
        type: "lead",
        id: `${uid()}lead`,
        modalType: "openAddLead",
        action: false,
        tabDetail: { ...userDetail },
      })
    );
  };
  // get the position for widgets modal
  // we need to get the position related to other widget model

  const handleUpdateDetail = (field, value) => {
    setUserDetail({ ...userDetail, [field]: value });
    setErrMsg({});
  };
  // handle form validation
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!userDetail.firstName) {
      isFormValid = false;
      errorMsg["firstName"] = "Please enter the first name";
      setErrorAlert({
        errorMsg: "Please enter the first name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.lastName) {
      isFormValid = false;
      errorMsg["lastName"] = "Please enter last name";
      setErrorAlert({
        errorMsg: "Please enter last name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.email) {
      isFormValid = false;
      errorMsg["email"] = "Please enter email address";
      setErrorAlert({
        errorMsg: "Please enter email address",
        errorType: "warning",
        isOpen: true,
      });
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userDetail.email)
    ) {
      isFormValid = false;
      errorMsg["email"] = "Please enter the valid email address";
      setErrorAlert({
        errorMsg: "Please enter the valid email address",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.phone) {
      isFormValid = false;
      errorMsg["phone"] = "Please enter the phone number";
      setErrorAlert({
        errorMsg: "Please enter the phone number",
        errorType: "warning",
        isOpen: true,
      });
    } else if (userDetail.phone && userDetail.phone.indexOf("_") > -1) {
      isFormValid = false;
      errorMsg["phone"] = "Please enter the valid number";
      setErrorAlert({
        errorMsg: "Please enter the valid number",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!userDetail.source) {
      isFormValid = false;
      errorMsg["source"] = "Please select the lead source";
      setErrorAlert({
        errorMsg: "Please select the lead source",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully add the lead",
      errorType: "success",
      isOpen: true,
    });
    handleClose(false);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };
  //  save information
  const handleSubmit = () => {
    if (handleValidate()) {
      let obj = {
        schema: {
          ...userDetail,
          source: userDetail?.source.value,
          emails: [
            {
              value: userDetail.email,
            },
          ],
          phones: [
            {
              value: userDetail.phone,
            },
          ],
          deduplication: true,
        },
        handleError,
        handleSuccess,
      };
      delete obj.schema.email;
      delete obj.schema.phone;
      dispatch(addNewLead(obj));
    }
  };

  useEffect(() => {
    if (maximizedTab && maximizedTab.type === "lead" && open) {
      setUserDetail({
        ...maximizedTab.tabDetail,
      });
    }
  }, [maximizedTab, open]);

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          sx: {
            position: "fixed",
            right:
              rightPositions?.length &&
              rightPositions.find((item) => item.type === "openAddLead")
                ? rightPositions.find((item) => item.type === "openAddLead")
                    .right
                : -20,
            bottom: -15,
            borderRadius: "6px",
            zIndex: 100000,
          },
        }}
        hideBackdrop
        disableEnforceFocus
        disableBackdropClick
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.ModalHeading}>Add Person</Typography>
          <Box>
            <IconButton onClick={() => handleAddMinimize()}>
              {" "}
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={() => handleClose()}>
              {" "}
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ width: "700px" }}>
          <Grid
            container
            direction="row"
            spacing={3}
            sx={{ overflow: "visible" }}
          >
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                First Name
              </InputLabel>
              <TextField
                value={userDetail.firstName}
                onChange={(e) =>
                  handleUpdateDetail("firstName", e.target.value)
                }
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"First Name"}
                variant="outlined"
                size="small"
                error={errMsg.firstName}
                helperText={errMsg.firstName}
              />
            </Grid>

            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Last Name
              </InputLabel>
              <TextField
                value={userDetail.lastName}
                onChange={(e) => handleUpdateDetail("lastName", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Last Name"}
                variant="outlined"
                size="small"
                error={errMsg.lastName}
                helperText={errMsg.lastName}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Email
              </InputLabel>
              <TextField
                value={userDetail.email}
                onChange={(e) => handleUpdateDetail("email", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter email"}
                variant="outlined"
                size="small"
                type={"email"}
                error={errMsg.email}
                helperText={errMsg.email}
              />
            </Grid>

            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Phone Number
              </InputLabel>
              <NumberFormat
                format={"###-###-####"}
                mask="_"
                className={classes.customPhoneField}
                onChange={(e) => handleUpdateDetail("phone", e.target.value)}
                displayType="input"
                placeholder={"Enter phone no"}
                autoFocus
              />
              {errMsg?.phone ? (
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "#d32f2f",
                    padding: "2px 16px",
                  }}
                >
                  {errMsg?.phone}
                </Box>
              ) : null}
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Lead source
              </InputLabel>
              <Autocomplete
                disableClearable
                options={leadSourceOptions}
                getOptionLabel={(option) => option.title}
                onChange={(e, option) => {
                  handleUpdateDetail("source", option);
                }}
                sx={{ mt: "12px" }}
                value={userDetail?.source}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search lead source"
                    aria-describedby="stepper-linear-event_types"
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    error={errMsg.source}
                    helperText={errMsg.source}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addLead.isLoading}
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddLeadModalStyle" })(
  AddLeadModal
);
