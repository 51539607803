import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAgentAchievements = createAsyncThunk(
  "AgentAchievement/getAgentAchievements",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/users/achievements/${data.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data?.handleSuccess) {
        data?.handleSuccess(resp.data);
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
