export const styleSheet = () => ({
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  agentTableHeading: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  statusChip: {
    background: "rgba(102, 187, 106, 0.5)",
    color: "white !important",
    height: "25px !important",
    fontSize: "13px !important",
  },
});
