import { createSlice } from "@reduxjs/toolkit";
import { addAppointment } from "./addAppointment";
import { getTicketCategories } from "./getTicketsCategories";
import { addAgentRequest } from "./addAgentRequest";
import { addNewLead } from "./addLead";
const positionTypes = {
  sendEmailOpen: 700,
  sendTextOpen: 700,
  openAppointment: 800,
  createProjectOpen: 800,
  openAgentRequest: 700,
  openAddLead: 800,
};

const initialState = {
  minimizedFooterTabs: [],
  sendEmailOpen: false,
  sendTextOpen: false,
  openAppointment: false,
  openAgentRequest: false,
  createProjectOpen: false,
  openAddLead: false,
  maximizedTab: null,
  rightPositions: [],
  addAppoint: {
    success: false,
    isLoading: false,
    errMsg: null,
  },
  categories: [],
  status: [],
  agentRequest: {
    success: false,
    isLoading: false,
    errMsg: null,
  },
  addLead: {
    success: false,
    isLoading: false,
    errMsg: null,
  },
};
const globalWidgets = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    handleAddMinimizeTab: (state, action) => {
      let currentTabs = [...state.minimizedFooterTabs];
      currentTabs.push(action.payload);
      let newPositions = [...state.rightPositions];
      let filteredArray = newPositions.filter(
        (item) => item.type !== action.payload.modalType
      );
      let newPositionArray = [];
      filteredArray.map((item, index) => {
        if (index == 0) {
          newPositionArray.push({ ...item, right: -20 });
        } else {
          let obj = {
            ...item,
            right:
              positionTypes[
                newPositionArray[newPositionArray.length - 1].type
              ] +
                10 +
                index ==
              1
                ? 0
                : newPositionArray[newPositionArray.length - 1].right,
          };
          newPositionArray.push(obj);
        }
      });
      state.rightPositions = newPositionArray;
      state.minimizedFooterTabs = currentTabs;
      state[action.payload.modalType] = action.payload.action;
      state.maximizedTab = null;
    },
    handleUpdateModalState: (state, action) => {
      state[action.payload.type] = action.payload.action;
      state.maximizedTab = null;
      let newPositions = [...state.rightPositions];
      if (action.payload.action) {
        if (newPositions.length === 0) {
          newPositions.push({ type: action.payload.type, right: -20 });
        } else {
          let obj = {
            type: action.payload.type,
            right:
              newPositions[newPositions.length - 1].right +
              10 +
              positionTypes[newPositions[newPositions.length - 1].type],
          };
          newPositions.push(obj);
        }
        state.rightPositions = newPositions;
      } else {
        let filteredArray = newPositions.filter(
          (item) => item.type !== action.payload.type
        );
        let newPositionArray = [];
        filteredArray.map((item, index) => {
          if (index == 0) {
            newPositionArray.push({ ...item, right: -20 });
          } else {
            let obj = {
              ...item,
              right:
                positionTypes[
                  newPositionArray[newPositionArray.length - 1].type
                ] +
                  10 +
                  index ==
                1
                  ? 0
                  : newPositionArray[newPositionArray.length - 1].right,
            };
            newPositionArray.push(obj);
          }
        });
        state.rightPositions = newPositionArray;
      }
    },
    handleRemoveMinimizedTab: (state, action) => {
      let currentTabs = [...state.minimizedFooterTabs];
      let tabIndex = currentTabs.findIndex(
        (item) => item.id === action.payload
      );
      currentTabs.splice(tabIndex, 1);
      state.minimizedFooterTabs = currentTabs;
    },
    handleMaximizeTab: (state, action) => {
      state[action.payload.type] = action.payload.action;
      if (action.payload.tabDetail) {
        state.maximizedTab = { tabDetail: action.payload.tabDetail };
      } else {
        let currentTabs = [...state.minimizedFooterTabs];
        let tabIndex = currentTabs.findIndex(
          (item) => item.id === action.payload.id
        );
        state.maximizedTab = currentTabs[tabIndex];
        currentTabs.splice(tabIndex, 1);
        state.minimizedFooterTabs = currentTabs;
      }
      let newPositions = [...state.rightPositions];
      if (newPositions.length === 0) {
        newPositions.push({ type: action.payload.type, right: -20 });
      } else {
        let obj = {
          type: action.payload.type,
          right:
            newPositions[newPositions.length - 1].right +
            10 +
            positionTypes[newPositions[newPositions.length - 1].type],
        };
        newPositions.push(obj);
      }
      state.rightPositions = newPositions;
    },
  },
  extraReducers: {
    [addAppointment.pending]: (state) => {
      state.addAppoint.isLoading = true;
      state.addAppoint.errMsg = null;
      state.addAppoint.success = false;
    },
    [addAppointment.fulfilled]: (state, action) => {
      state.addAppoint.success = true;
      state.addAppoint.isLoading = false;
    },
    [addAppointment.rejected]: (state, action) => {
      state.addAppoint.isLoading = false;
      state.addAppoint.success = false;
      state.addAppoint.errMsg = action.payload;
    },
    // set the categories
    [getTicketCategories.fulfilled]: (state, action) => {
      state.categories = action.payload?.categories || [];
      state.status = action.payload?.status || [];
    },
    // add agent request
    [addAgentRequest.pending]: (state) => {
      state.agentRequest.isLoading = true;
      state.agentRequest.errMsg = null;
      state.agentRequest.success = false;
    },
    [addAgentRequest.fulfilled]: (state, action) => {
      state.agentRequest.success = true;
      state.agentRequest.isLoading = false;
    },
    [addAgentRequest.rejected]: (state, action) => {
      state.agentRequest.isLoading = false;
      state.agentRequest.success = false;
      state.agentRequest.errMsg = action.payload;
    },
    // add new lead
    [addNewLead.pending]: (state) => {
      state.addLead.isLoading = true;
      state.addLead.errMsg = null;
      state.addLead.success = false;
    },
    [addNewLead.fulfilled]: (state, action) => {
      state.addLead.success = true;
      state.addLead.isLoading = false;
    },
    [addNewLead.rejected]: (state, action) => {
      state.addLead.isLoading = false;
      state.addLead.success = false;
      state.addLead.errMsg = action.payload;
    },
  },
});

export const {
  handleAddMinimizeTab,
  handleUpdateModalState,
  handleRemoveMinimizedTab,
  handleMaximizeTab,
} = globalWidgets.actions;

export default globalWidgets.reducer;
export * from "./addAppointment";
export * from "./getTicketsCategories";
export * from "./addAgentRequest";
