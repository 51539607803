import React, { useEffect, useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Stack,
  Typography,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Skeleton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "../../../components/uiElements/avatar";
import { getData } from "../../../utils/getData";
import { postData } from "../../../utils/postData";
import { updateData } from "../../../utils/updateData";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import Person2Icon from "@mui/icons-material/Person2";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DateRangeIcon from "@mui/icons-material/DateRange";
import RoomIcon from "@mui/icons-material/Room";
import ScheduleIcon from "@mui/icons-material/Schedule";
import NotesRoundedIcon from "@mui/icons-material/NotesRounded";
import TvOutlinedIcon from "@mui/icons-material/TvOutlined";
import ResponseAlert from "../../../components/responseAlert";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import { icons } from "./addEvent";
const moment = require("moment-timezone");
function EventDetail(props) {
  let { classes, open, setOpen, eventData } = props;
  const [isExpandRSVP, setIsExpandRSVP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [responseDetail, setResponseDetail] = useState({});
  const [isAllowMore, setIsAllowMore] = useState("no");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [questionResponse, setQuestionResponse] = useState([]);
  const adminAuth = useSelector((state) => state.adminAuth);
  const handleClose = () => {
    setOpen(false);
    setIsExpandRSVP(false);
    setResponseDetail({});
    setIsAllowMore("");
    setQuestionResponse([]);
  };
  useEffect(() => {
    if (open && adminAuth?.adminDetail?.id && eventData?.rsvps && !responseDetail.metaData) {
      handleGetEventResponse();
    }
  }, [open]);
  const handleGetEventResponse = async () => {
    setLoading(true);
    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/events-alerts-v2/event/response/${eventData.id}/${adminAuth?.adminDetail?.id}`
    );
    if (result.status === 200) {
      setResponseDetail(result);
      if (result.data?.event_responses?.length) {
        setQuestionResponse(result.data?.event_responses[0]?.event_question_responses);
      }
      if (result?.data?.allowed_more_than_one) {
        setIsAllowMore("yes");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleUpdateQuestionOption = (data) => {
    let newQuestion = [...questionResponse];
    let index = newQuestion.findIndex((item) => item.event_question_id === data.event_question_id);
    if (index > -1) {
      newQuestion[index] = { event_question_id: data.event_question_id, event_question_option_id: data.id };
    } else {
      newQuestion.push({ event_question_id: data.event_question_id, event_question_option_id: data.id });
    }
    setQuestionResponse(newQuestion);
  };

  const handleUpdateWrittenAns = (data, value) => {
    let newQuestion = [...questionResponse];
    let index = newQuestion.findIndex((item) => item.event_question_id === data.id);
    if (index > -1) {
      newQuestion[index] = { event_question_id: data.id, written_answer: value };
    } else {
      newQuestion.push({ event_question_id: data.id, written_answer: value });
    }
    setQuestionResponse(newQuestion);
  };
  const handleSubmit = () => {
    let obj = {
      is_attending: "yes",
      more_than_one: isAllowMore,
      is_responded: true,
      question_responses: questionResponse,
    };
    handleSubmitRSVP(obj);
  };

  const handleSubmitRSVP = async (data) => {
    setSubmitLoading(true);
    const result = await postData(`${process.env.REACT_APP_BASE_URL}api/events-alerts-v2/event/response/${eventData.id}`, data);
    if (result.status === 200) {
      setSubmitLoading(false);
      let newResponse = { ...responseDetail };
      if (responseDetail.data.event_responses[0]) {
        responseDetail.data.event_responses[0].is_attending = "yes";
        setResponseDetail(newResponse);
      } else {
        responseDetail.data.event_responses.push({ is_attending: "yes" });
      }
      setErrorAlert({
        errorMsg: "You have successfully submitted the RSVP",
        errorType: "success",
        isOpen: true,
      });
    } else {
      setSubmitLoading(false);
      setErrorAlert({
        errorMsg: "Something went wrong please try again later!",
        errorType: "error",
        isOpen: true,
      });
    }
  };
  const handleUpdate = () => {
    let obj = {
      is_attending: "yes",
      more_than_one: isAllowMore,
      is_responded: true,
      question_responses: questionResponse,
    };
    handleUpdateRSVP(obj, true);
  };
  const handleUpdateRSVP = async (data, isUpdate) => {
    if (isUpdate) {
      setUpdateLoading(true);
    } else {
      setCancelLoading(true);
    }
    const result = await updateData(`${process.env.REACT_APP_BASE_URL}api/events-alerts-v2/event/response/${eventData.id}`, data);
    if (result.status === 200) {
      if (isUpdate) {
        setUpdateLoading(false);
        let newResponse = { ...responseDetail };
        responseDetail.data.event_responses[0].is_attending = "yes";
        setResponseDetail(newResponse);
      } else {
        setCancelLoading(false);
        let newResponse = { ...responseDetail };
        responseDetail.data.event_responses[0].is_attending = "no";
        setResponseDetail(newResponse);
        setIsExpandRSVP(false);
      }
      setErrorAlert({
        errorMsg: `You have successfully ${isUpdate ? "updated" : "cancelled"} the RSVP`,
        errorType: "success",
        isOpen: true,
      });
    } else {
      if (isUpdate) {
        setUpdateLoading(false);
      } else {
        setCancelLoading(false);
      }
      setErrorAlert({
        errorMsg: "Something went wrong please try again later!",
        errorType: "error",
        isOpen: true,
      });
    }
  };
  const handleCancelRSVP = () => {
    let obj = {
      is_attending: "no",
    };
    handleUpdateRSVP(obj);
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" }, "& .MuiDialog-container": { mt: "50px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.modalHeading} sx={{ paddingTop: "30px" }} id="alert-dialog-title">
          <Stack direction="row" spacing={1} alignItems={"center"} sx={{ ml: "6px" }}>
            <img width="30px" src={icons.find((item) => item.value === eventData.icon)?.icon} alt="" />
            <Box className={classes.modalHeadingContent}> {eventData.title}</Box>
          </Stack>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            {eventData?.in_person_or_virtual === "in_person" ? (
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <Person2Icon />
                <Typography classes={{ root: classes.iconLabel }}> In Person Only</Typography>
              </Stack>
            ) : null}

            {eventData?.rsvps ? (
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <ErrorOutlineIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                <Typography classes={{ root: classes.iconLabel }}> RSVP Required</Typography>
              </Stack>
            ) : null}
            {eventData?.in_person_or_virtual === "virtual" || eventData?.in_person_or_virtual === "in_person_or_virtual" ? (
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <TvOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                <Typography classes={{ root: classes.iconLabel }}> Virtual {eventData?.virtual_info ? "Available" : ""} </Typography>
              </Stack>
            ) : null}
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ width: "700px", mt: "20px", paddingBottom: isExpandRSVP ? "" : "40px" }}>
          {" "}
          <ListItem className={classes.customIconList}>
            <ListItemAvatar>
              <DateRangeIcon />
            </ListItemAvatar>
            <ListItemText
              classes={{ primary: classes.customListHeading, secondary: classes.customListContent }}
              primary={moment(eventData.event_date).tz("America/New_York").format("L")}
            />
          </ListItem>
          <ListItem className={classes.customIconList}>
            <ListItemAvatar>
              <ScheduleIcon />
            </ListItemAvatar>
            <ListItemText
              classes={{ primary: classes.customListHeading, secondary: classes.customListContent }}
              primary={`${moment(eventData.start_time, "HH:mm:ss").format("HH:mm A")} - ${moment(eventData.end_time, "HH:mm:ss").format("HH:mm A")}`}
            />
          </ListItem>
          <ListItem className={classes.customIconList}>
            <ListItemAvatar>
              <RoomIcon />
            </ListItemAvatar>
            <ListItemText
              classes={{ primary: classes.customListHeading1, secondary: classes.customListContent }}
              primary={eventData.location_title}
              secondary={eventData.location}
            />
          </ListItem>
          <ListItem className={classes.customIconList}>
            <ListItemAvatar>
              <NotesRoundedIcon />
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.customListHeading2, secondary: classes.customListContent }} primary={eventData.body} />
          </ListItem>
          {eventData?.in_person_or_virtual === "virtual" && eventData?.virtual_info ? (
            <ListItem className={classes.customIconList}>
              <ListItemAvatar>
                <TvOutlinedIcon />
              </ListItemAvatar>
              <ListItemText
                classes={{ primary: classes.customListHeading1, secondary: classes.customListContent }}
                primary={"Join Zoom Meeting"}
                secondary={eventData.virtual_info}
              />
            </ListItem>
          ) : null}
          {loading ? <Skeleton variant="rounded" sx={{ m: "20px 0px" }} animation="wave" width="100%" height="260px" /> : null}
          {eventData?.rsvps && !loading /* && responseDetail?.metaData?.isInvited */ ? (
            <Stack direction="row" className={classes.rvcpButtonArea} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <DraftsOutlinedIcon />
                {responseDetail?.data?.event_responses[0]?.is_attending === "yes" && new Date() < new Date(eventData.rsvp_deadline) ? (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "rgba(20, 121, 67, 1)",
                      color: "white",
                      "&:hover": { bgcolor: "rgba(20, 121, 67, 1)", color: "white" },
                    }}
                    startIcon={<CheckIcon sx={{ color: "white !important" }} />}
                  >
                    Attending
                  </Button>
                ) : new Date() < new Date(eventData.rsvp_deadline) ? (
                  <Button
                    color="inherit"
                    onClick={() => setIsExpandRSVP(true)}
                    variant={isExpandRSVP ? "outlined" : "contained"}
                    sx={{
                      bgcolor: isExpandRSVP ? "white" : "#000000",
                      color: isExpandRSVP ? "#000000" : "white",
                      "&:hover": { bgcolor: isExpandRSVP ? "white" : "#000000", color: isExpandRSVP ? "#000000" : "white" },
                    }}
                  >
                    RSVP FOR THIS EVENT
                  </Button>
                ) : null}
                {responseDetail?.data?.event_responses[0]?.is_attending === "yes" && new Date() < new Date(eventData.rsvp_deadline) ? (
                  <Box onClick={() => setIsExpandRSVP(true)} className={classes.updateRSVPButton}>
                    Update RSVP
                  </Box>
                ) : null}
                {new Date() > new Date(eventData.rsvp_deadline) ? (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "red",
                      color: "white",
                      "&:hover": { bgcolor: "red", color: "white" },
                    }}
                  >
                    rsvp is closed
                  </Button>
                ) : null}
              </Stack>
              {responseDetail?.data?.event_responses[0]?.is_attending === "yes" ? (
                <Stack direction="row" spacing={1}>
                  <Avatar
                    src={adminAuth?.adminDetail?.profile_img}
                    sx={{ fontSize: "13px", width: "25px", height: "25px" }}
                  >{`${adminAuth?.adminDetail?.full_name?.split(" ")[0]?.slice(0, 1)}${adminAuth?.adminDetail?.full_name
                    ?.split(" ")[1]
                    ?.slice(0, 1)}`}</Avatar>
                  <Typography>{adminAuth?.adminDetail?.full_name}</Typography>
                </Stack>
              ) : null}
            </Stack>
          ) : null}
          {isExpandRSVP ? (
            <Box className={classes.rsvpExpandedArea}>
              {eventData?.allowed_more_than_one ? (
                <FormControl sx={{ ml: 3, mt: 3, paddingRight: "20px" }} fullWidth component="fieldset" variant="standard">
                  <FormLabel component="legend">Are you bringing a plus one?</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox onChange={(e) => setIsAllowMore(e.target.checked ? "yes" : "no")} checked={isAllowMore === "yes" ? true : false} />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox onChange={(e) => setIsAllowMore(e.target.checked ? "no" : "yes")} checked={isAllowMore === "no" ? true : false} />
                      }
                      label="No"
                    />
                  </FormGroup>
                </FormControl>
              ) : null}

              {responseDetail?.data?.event_questions?.length
                ? responseDetail?.data?.event_questions.map((item, index) => (
                    <FormControl key={item.id} sx={{ ml: 3, mt: 2, paddingRight: "20px" }} fullWidth component="fieldset" variant="standard">
                      <FormLabel component="legend">{item?.question_title}</FormLabel>
                      {item.question_type === "written_answer" ? (
                        <TextField
                          onChange={(e) => handleUpdateWrittenAns(item, e.target.value)}
                          value={questionResponse.find((it) => it.event_question_id === item.id)?.written_answer || ""}
                          fullWidth
                          variant="outlined"
                          rows={2}
                          multiline
                          size="small"
                          placeholder="Short Answer"
                        />
                      ) : (
                        <FormGroup>
                          {item.event_question_options?.length
                            ? item.event_question_options?.map((item1) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={() => handleUpdateQuestionOption(item1)}
                                        key={item1.id}
                                        checked={questionResponse.find((it) => it.event_question_option_id === item1.id) ? true : false}
                                      />
                                    }
                                    label={item1.option}
                                  />
                                );
                              })
                            : null}
                        </FormGroup>
                      )}
                    </FormControl>
                  ))
                : null}
              <br />
            </Box>
          ) : null}
        </DialogContent>
        {isExpandRSVP ? (
          <DialogActions sx={{ marginBottom: "10px", mtr: "20px" }}>
            <Button
              onClick={() => setIsExpandRSVP(false)}
              sx={{
                color: "#000000",
              }}
              color="inherit"
              variant="outlined"
            >
              Cancel
            </Button>
            {responseDetail?.data?.event_responses[0]?.is_attending === "yes" ? (
              <LoadingButton
                color="inherit"
                variant={"contained"}
                sx={{
                  bgcolor: "#C71E1E",
                  color: "white",
                  "&:hover": { bgcolor: "#C71E1E", color: "white" },
                }}
                onClick={handleCancelRSVP}
                loadingPosition="start"
                loading={cancelLoading}
              >
                Cancel RSVP
              </LoadingButton>
            ) : null}
            {responseDetail?.data?.event_responses[0] ? (
              <LoadingButton
                color="inherit"
                variant={"contained"}
                sx={{
                  bgcolor: "#000000",
                  color: "white",
                  "&:hover": { bgcolor: "#000000", color: "white" },
                }}
                loadingPosition="start"
                loading={updateLoading}
                onClick={() => handleUpdate()}
              >
                UPDATE RSVP
              </LoadingButton>
            ) : (
              <LoadingButton
                color="inherit"
                variant={"contained"}
                sx={{
                  bgcolor: "#000000",
                  color: "white",
                  "&:hover": { bgcolor: "#000000", color: "white" },
                }}
                loadingPosition="start"
                loading={submitLoading}
                onClick={() => handleSubmit()}
              >
                SUBMIT RSVP
              </LoadingButton>
            )}
          </DialogActions>
        ) : null}
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "EventDetailStyle" })(EventDetail);
