import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  agentAverageStats: {},
  errMsg: null,
};

export const getAgentAverageStats = createAsyncThunk("agentAverageStats/getAgentAverageStats", async (data, thunkAPI) => {
  const type = data.type ? `ListingType=${data.type}` : "";
  try {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}api/recruitment/agentAverageStats/${data.id}?${type}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });

    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});


const agentAverageStats = createSlice({
  name: "agentAverageStats",
  initialState,
  reducers: {
    resetAgentAverageStats: (state, action) => {
      state.agentAverageStats = {};
    },
  },
  extraReducers: {
    [getAgentAverageStats.pending]: (state) => {
      state.agentAverageStats = {};
      state.loading = true;
    },
    [getAgentAverageStats.fulfilled]: (state, action) => {
      state.loading = false;
      state.agentAverageStats = action.payload;
    },
    [getAgentAverageStats.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});
export const { resetAgentAverageStats } = agentAverageStats.actions;
export default agentAverageStats.reducer;
