import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const removeTicketAttachment = createAsyncThunk("AgentRequests/removeTicketAttachment", async (data, thunkAPI) => {
  let { handleError, handleDeleteSuccess } = data;
  try {
    const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/agent-ticket/remove-attachment/${data.ticketId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (handleDeleteSuccess) {
      handleDeleteSuccess();
    }
    return { ...resp.data, ticketId: data.ticketId };
  } catch (error) {
    if (error.response) {
      if (handleError) {
        handleError(error.response.data.errorMessage || error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      if (handleError) {
        handleError(error.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
