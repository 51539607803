import React, { useState, useEffect } from "react";
import GlobalModal from "../../../../components/globalModal";
import { Grid, Typography, Box, Tab, Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled, alpha } from "@mui/material/styles";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Folder} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import docum from "../../../../assets/docum.png";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import "./style.js";
import { getAllForms } from "../../../../redux/docs/getAllForms";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../../components/responseAlert";
import { FormatShapes } from "@mui/icons-material";
import { addForm } from "../../../../redux/docs/addformapi";
import { useParams } from "react-router-dom";
import { getDocDetails } from "../../../../redux/docs/getDocDetails";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

const style = {
  position: "fixed",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  height: "auto",
  overflow: "auto",
  paddingBottom: "20px !important",
};

const AccordionDisplay = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummaryDisplay = styled((props) => <AccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsDisplay = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const AddFormModal = ({ classes, addFomrModalOpen, handleClose, modalWidht, formNumber }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((item) => item.docDetails);
  const [value, setValue] = useState("1");
  const [expanded, setExpanded] = useState("panel1");

  const [allForms, setAllForms] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [openerror, setOpenerror] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMesage] = useState();

  const [formID, setFormID] = useState([]);
  const [formData, setFormData] = useState();
  const [disable, setDisable] = useState(false);

  // console.log("formNumber",formNumber);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCancel = () => {
    handleClose();
  };
  const handleCheckboxChange = (e) => {
    let id = e.target.value;
    if (formID?.includes(parseInt(id))) {
      let index = formID.indexOf(parseInt(id));
      let newFormID = [...formID];
      newFormID.splice(index, 1);
      setFormID(newFormID);
    } else {
      setFormID([...formID, parseInt(id)]);
    }
  };
  const handleAddForms = async () => {
    const newdata = { id: id, data: { form_ids: formID } };
    setDisable(true);
    await dispatch(addForm(newdata)).then(async (res) => {
      if (res?.payload?.status === 200) {
        setOpenerror(true);
        setAlertType("success");
        setAlertMesage("Form added successfully");
        setIsLoading(false);
        setDisable(false);
        dispatch(getDocDetails(id));
        await handleClose();
      } else {
        setOpenerror(true);
        setAlertType("error");
        setAlertMesage("Error");
        setIsLoading(false);
        setDisable(false);
      }
    });
  };

  useEffect(() => {
    dispatch(getAllForms()).then((res) => {
      if (res.payload.status === 200) {
        setAllForms(res.payload.data);
        let mydata = res.payload.data.map((m) => {
          let data = m.forms.filter((i) => {
            if (formNumber?.includes(i.form_number)) {
              return i.id;
            }
          });
          let mId = data.map((d) => {
            return d.id;
          });
          setFormID(mId);
        });
      } else {
        setOpenerror(true);
        setAlertType("error");
        setAlertMesage("Error");
        setIsLoading(false);
      }
    });
    setDisable(false);
  }, [formNumber]);

  useEffect(() => {
    if (data?.docDetails?.forms) {
      setFormData(data.docDetails.forms.map((form) => form.pdf_form.form_number));
    }
  }, [data.docDetails, data.loading]);

  // const data = [{ "primary_txt": "F201 - Purchase and Sale Agreement" }, { "primary_txt": "F201 - Purchase and Sale Agreement" }, { "primary_txt": "F201 - Purchase and Sale Agreement" }, { "primary_txt": "F201 - Purchase and Sale Agreement" }, { "primary_txt": "F201 - Purchase and Sale Agreement" }]

  // const secondData = [{ "primary_txt": "F201 - Purchase and Sale Agreement" }, { "primary_txt": "F201 - Purchase and Sale Agreement" }]

  return (
    <>
      <GlobalModal open={addFomrModalOpen} handleClose={handleClose} modalWidht={modalWidht} showHeader={false} headerName={`New Folder`}>
        <Box sx={style}>
          <Typography className={classes.ModalHeading}>Add Forms</Typography>
          <TabContext value={value}>
            <Box className={classes.mTabListPadding} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="tabs" className={classes.mTabSelected}>
                <Tab label="FORMS" value="1" />
                <Tab label="PACKAGES" value="2" />
                <Tab label="UPLOADS" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "4px" }}>
              <Box sx={{ height: "60vh", overflow: "auto" }}>
                {allForms.map((value, index) => (
                  <AccordionDisplay expanded={expanded === "panel1"} onChange={handleAccordian("panel1")} className={classes.mAccordianBorder}>
                    <AccordionSummaryDisplay aria-controls="panel1d-content" id="panel1d-header" className={classes.mAccordianSummary}>
                      <Typography className={classes.mTabsInnerStyle}>
                        <FolderOpenIcon className={classes.mIconStyle} />
                        <span className={classes.mTxtStyle}>{value.name}</span>
                      </Typography>
                    </AccordionSummaryDisplay>
                    <AccordionDetailsDisplay sx={{ padding: "10px", maxHeight: allForms?.length>1?"200px":"53vh", overflow: "auto" }} className={classes.mAccordianBorder}>
                      {value.forms.map((i, index) => (
                        <List disablePadding>
                          <ListItem disablePadding sx={{height:"36px"}}>
                            <ListItemAvatar>{index === 0 && <TurnLeftIcon className={classes.mTurnIconStyle} />}</ListItemAvatar>
                            <FormGroup sx={{width:"35px"}}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={i.id}
                                    defaultChecked={formData?.includes(i.form_number)}
                                    onChange={(e) => handleCheckboxChange(e)}
                                  />
                                }
                              />
                            </FormGroup>
                            <ArticleOutlinedIcon sx={{mr:"5px",fontSize:"26px"}}/>
                            {/* <img src={docum} className={classes.mImgStyle} /> */}
                            <ListItemText primary={i.name.length<42?i.name:i.name?.slice(0,42)+"..."} />
                          </ListItem>
                        </List>
                      ))}
                    </AccordionDetailsDisplay>
                  </AccordionDisplay>
                ))}
                {/*<AccordionDisplay
                expanded={expanded === "panel2"}
                onChange={handleAccordian("panel2")}
                className={classes.mAccordianBorder}
              >
                <AccordionSummaryDisplay
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  className={classes.mAccordianSummary}
                >
                  <Typography className={classes.mTabsInnerStyle}>
                    <FolderOpenIcon className={classes.mIconStyle} />
                    <span className={classes.mTxtStyle}>
                      Other Collapsed Folder
                    </span>
                  </Typography>
                </AccordionSummaryDisplay>
                <AccordionDetailsDisplay className={classes.mAccordianBorder}>
                  {secondData.map((value, index) =>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          {index === 0 && <TurnLeftIcon className={classes.mTurnIconStyle} />}
                        </ListItemAvatar>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox />} />
                        </FormGroup>
                        <img src={docum} className={classes.mImgStyle} />
                        <ListItemText primary={value.primary_txt} />
                      </ListItem>
                    </List>
                  )}
                </AccordionDetailsDisplay>
              </AccordionDisplay>
              <AccordionDisplay
                expanded={expanded === "panel3"}
                onChange={handleAccordian("panel3")}
                className={classes.mAccordianBorder}
              >
                <AccordionSummaryDisplay
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  className={classes.mAccordianSummary}
                >
                  <Typography className={classes.mTabsInnerStyle}>
                    <FolderOpenIcon className={classes.mIconStyle} />
                    <span className={classes.mTxtStyle}>
                      Other Collapsed Folder
                    </span>
                  </Typography>
                </AccordionSummaryDisplay>
                <AccordionDetailsDisplay className={classes.mAccordianBorder}>
                  {secondData.map((value, index) =>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          {index === 0 && <TurnLeftIcon className={classes.mTurnIconStyle} />}
                        </ListItemAvatar>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox />} />
                        </FormGroup>
                        <img src={docum} className={classes.mImgStyle} />
                        <ListItemText primary={value.primary_txt} />
                      </ListItem>
                    </List>
                  )}
                </AccordionDetailsDisplay>
                  </AccordionDisplay>*/}
              </Box>
              <Box mt={2}>
                <Grid item xs={12} md={12} className={classes.mJustifyContent} sx={{ pr: "20px" }}>
                  <Button className={classes.mCancel} onClick={() => handleCancel()}>
                    cancel
                  </Button>
                  <Button className={classes.mCreateButton} onClick={() => handleAddForms()} disabled={formID.length === 0}>
                    {disable ? "adding" : "add forms"}
                  </Button>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
          </TabContext>
        </Box>
      </GlobalModal>
      <ResponseAlert setOpen={setOpenerror} open={openerror} alertType={alertType} alertMessage={alertMessage} style={{ position: "inherit" }} />
    </>
  );
};

// export default AddFormModal;
export default withStyles(styleSheet, { name: "AddFormModalModalStyle" })(AddFormModal);
