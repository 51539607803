import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { handlePayInvoice } from "../../../redux/listings/makeover";

function PayInvoice(props) {
  let { open, setOpen, classes, invoiceDetail, handleUpdateProcessing } = props;
  const [errMsg, setErrMsg] = useState({});
  const [isCustomMemo, setIsCustomMemo] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const [payInvoiceDetail, setPayInvoiceDetail] = useState({
    check_start: "",
    custom_memo: "",
  });

  const dispatch = useDispatch();
  const payInvoice = useSelector((state) => state.listings.makeover.payInvoice);

  const handleClose = () => {
    setOpen(false);
    setIsCustomMemo(false)
    setPayInvoiceDetail({ check_start: "" });
  };
  useEffect(() => {
    if (payInvoice.success && !payInvoice.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully paid the invoice",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
      handleUpdateProcessing(invoiceDetail.id);
    } else if (!payInvoice.isLoading && payInvoice.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(payInvoice.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [payInvoice.isLoading]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!payInvoiceDetail.check_start) {
      isFormValid = false;
      errorMsg["check_start"] = "Please enter the check no in integer";
      setErrorAlert({
        errorMsg: "Please enter the check no in integer",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!payInvoiceDetail.custom_memo && isCustomMemo) {
      isFormValid = false;
      errorMsg["custom_memo"] = "Please enter the memo";
      setErrorAlert({
        errorMsg: "Please enter the memo",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleUpdateValue = (type, value) => {
    setPayInvoiceDetail({
      ...payInvoiceDetail,
      [type]: value,
    });
    setErrMsg({});
  };

  const handleMakeoverPayInvoice = () => {
    if (handleValidate()) {
      let obj = {
        id: invoiceDetail.id,
        check_start: Number(payInvoiceDetail.check_start || 0),
        makeover_id: invoiceDetail?.makeover_id,
      };
      if (isCustomMemo) {
        obj.custom_memo = payInvoiceDetail.custom_memo;
      }
      dispatch(handlePayInvoice(obj));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={Boolean(open)}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        classes={{ paper: classes.customDialog }}
      >
        <DialogContent sx={{ width: "500px", mt: "20px", mb: "15px" }}>
          <InputLabel
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "16px",
              mb: "4px",
            }}
          >
            Enter Check Number
          </InputLabel>
          <TextField
            value={payInvoiceDetail.check_start}
            onChange={(e) => handleUpdateValue("check_start", e.target.value)}
            fullWidth
            required
            variant="outlined"
            size="small"
            placeholder="Check no"
            error={errMsg["check_start"]}
            type="number"
            id="check_start"
          />
          <InputLabel
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "16px",
              mt: "20px",
              mb: "-4.5px",
            }}
          >
            Custom Memo
            <Switch checked={isCustomMemo} onChange={(e) => setIsCustomMemo(e.target.checked)} color="success" />
          </InputLabel>
          {isCustomMemo ? (
            <TextField
              value={payInvoiceDetail.custom_memo}
              onChange={(e) => handleUpdateValue("custom_memo", e.target.value)}
              fullWidth
              required
              variant="outlined"
              size="small"
              placeholder="Enter Custom Memo"
              error={errMsg["custom_memo"]}
              id="custom_memo"
            />
          ) : null}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose()}
            variant="text"
            sx={{
              color: "#000000",
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={payInvoice.isLoading}
            className={classes.saveModalButton}
            color="inherit"
            onClick={handleMakeoverPayInvoice}
            autoFocus
            sx={{ minWidth: "90px" }}
            loadingPosition="start"
          >
            Pay
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "PayInvoiceStyle" })(PayInvoice);
