import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Box, ButtonGroup } from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import { createLeadFlow } from "../../../../redux/leads/leadRouting/routing";
import { getMarkets } from "../../../../redux/agents/addRecruitAgent";
function CreateLeadFlow(props) {
  let { open, setOpen, classes } = props;
  const [tab, setTab] = useState("city");
  const [inputDetail, setInputDetail] = useState({
    slackChannelId: "",
    slackChannelName: "",
    groupId: "",
    actionPlanId: "",
    market_id: "",
  });
  const inputRef = useRef();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const slackChannels = useSelector((state) => state.leads.slackChannels);
  const actionPlans = useSelector((state) => state.leads.actionPlans);
  const groups = useSelector((item) => item.leads.LeadRoutingGroups.groups);
  const addFlow = useSelector((item) => item.leads.LeadRoutingOverView.addFlow);
  const markets = useSelector((state) => state.agentList.markets);

  useEffect(() => {
    if (!markets || markets.length === 0) {
      dispatch(getMarkets());
    }
  }, []);
  useEffect(() => {
    if (addFlow.success && !addFlow.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully created the lead flow",
        errorType: "success",
        isOpen: true,
      });
      setInputDetail({
        slackChannelId: "",
        slackChannelName: "",
        groupId: "",
        actionPlanId: "",
      });
      setTab("city");
      setOpen(false);
    } else if (!addFlow.isLoading && addFlow.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addFlow.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addFlow.isLoading]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdateChannelOrGroupValue = (value, data) => {
    if (data.type === "group") {
      setInputDetail({
        ...inputDetail,
        groupId: data.id,
        slackChannelId: "",
        slackChannelName: "",
      });
    } else {
      setInputDetail({
        ...inputDetail,
        groupId: "",
        slackChannelId: data.channelId,
        slackChannelName: data.channelName,
      });
    }
  };
  const handleUpdateActionPlane = (value) => {
    setInputDetail({
      ...inputDetail,
      actionPlanId: value,
    });
  };
  const handleValidate = () => {
    let isFormValid = true;
    if (!inputRef?.current?.value) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: `Please enter the ${tab ? "city name" : "zip code"}`,
        errorType: "warning",
        isOpen: true,
      });
    }
    if (!inputDetail?.slackChannelId && !inputDetail?.groupId) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the channel/group",
        errorType: "warning",
        isOpen: true,
      });
    }
    return isFormValid;
  };
  const handleSubmit = () => {
    if (handleValidate()) {
      let obj = {
        ...inputDetail,
        value: inputRef?.current?.value,
        type: tab,
      };

      Object.keys(obj).forEach((k) => {
        if (!obj[k]) {
          delete obj[k];
        }
      });
      dispatch(createLeadFlow(obj));
    }
  };
  const handleUpdateDetail = (field, value) => {
    setInputDetail({ ...inputDetail, [field]: value });
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" } }}
      >
        <DialogTitle className={classes.leadFlowHeading}>
          <Box>Create Lead Flow</Box>
          <ButtonGroup color="inherit" variant="outlined" aria-label="outlined button group">
            <Button disabled>Source</Button>
            <Button onClick={() => setTab("city")} sx={{ backgroundColor: tab === "city" ? "rgba(0, 0, 0, 0.04)" : "" }}>
              City
            </Button>
            <Button onClick={() => setTab("zip_code")} sx={{ backgroundColor: tab === "zip_code" ? "rgba(0, 0, 0, 0.04)" : "" }}>
              Zip Code
            </Button>
          </ButtonGroup>
        </DialogTitle>
        <DialogContent sx={{ width: "700px", mt: "15px" }}>
          <Grid container direction="row" spacing={2} sx={{ marginTop: "-15px" }}>
            <Grid item xs={5} sx={{ zIndex: "1" }}>
              <TextField
                inputRef={inputRef}
                fullWidth
                placeholder={tab === "city" ? "Enter City Name" : "Enter Zip Code"}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={7} sx={{ zIndex: 5, display: "flex", alignItems: "center" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  mr: "20px",
                }}
              >
                Distribution
              </InputLabel>
              <ButtonGroups
                id="category-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="40px"
                size="medium"
                color="white"
                minWidth="190px"
                menuHeight="300px"
                options={groups.data
                  ?.map((item) => ({ ...item, type: "group", title: `Group: ${item.name}`, value: item.id }))
                  .concat(
                    slackChannels.channels?.map((item) => ({ ...item, type: "channel", title: `#${item.channelName}`, value: item.channelName }))
                  )}
                onChangeMenu={(value, data) => handleUpdateChannelOrGroupValue(value, data)}
                placeholder="Channel / Group"
                {...props}
              />
            </Grid>
            <Grid item xs={7} sx={{ zIndex: 15, display: "flex", alignItems: "center", mt: "20px" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  mr: "20px",
                }}
              >
                Action Plan
              </InputLabel>
              <ButtonGroups
                id="category-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="35px"
                size="medium"
                color="white"
                minWidth="190px"
                options={actionPlans.data?.map((item) => ({ title: item.name, value: item.id }))}
                onChangeMenu={(value) => handleUpdateActionPlane(value)}
                placeholder="choose action plan"
                {...props}
              />
            </Grid>
            <Grid item xs={5} sx={{ zIndex: 4, display: "flex", alignItems: "center", mt: "20px" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  mr: "20px",
                }}
              >
                Market
              </InputLabel>
              <ButtonGroups
                options={markets && markets.length ? markets?.map((item) => ({ title: item.name, value: item.id })) : []}
                value={inputDetail.market_id}
                onChangeMenu={(value) => handleUpdateDetail("market_id", value)}
                id="markets-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                color="white"
                placeholder="Market"
                {...props}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={addFlow.isLoading}
            classes={{ root: classes.saveButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddCommissionModalStyle" })(CreateLeadFlow);
