import React from "react";
import { Box, Grid, ListItemText, Paper, Stack, Skeleton, Divider } from "@mui/material";
import { styleSheet } from "../../listings/listingDetail/cards/style";
import { withStyles } from "@mui/styles";
const UserHeaderCardSkelton = (props) => {
  let { classes } = props;
  return (
    <Box>
      <Paper sx={{ backgroundColor: "white" }} className={classes.pageRoot}>
        <Grid container direction="row" sx={{ mb: "15px" }}>
          <Grid md={8} lg={9} xs={12} className={classes.TransactionUserInfoLeftPanel}>
            <Skeleton variant="rounded" width="200px" height="180px" animation="wave" />
            <Box sx={{ ml: "20px" }}>
              <Skeleton variant="rounded" sx={{ mb: "5px" }} width="170px" height="30px" animation="wave" />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Skeleton variant="rounded" width="150px" height="20px" animation="wave" />
              </Box>
              <Grid container direction="row" alignItems="center" spacing={3} sx={{ mt: "40px" }}>
                <Grid item>
                  <ListItemText
                    primary={<Skeleton variant="rounded" sx={{ mb: "5px" }} width="80px" height="20px" animation="wave" />}
                    secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                  />
                </Grid>
                <Grid item>
                  <ListItemText
                    primary={<Skeleton sx={{ mb: "5px" }} variant="rounded" width="80px" height="20px" animation="wave" />}
                    secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                  />
                </Grid>
                <Grid item>
                  <ListItemText
                    primary={<Skeleton sx={{ mb: "5px" }} variant="rounded" width="80px" height="20px" animation="wave" />}
                    secondary={<Skeleton variant="rounded" width="80px" height="15px" animation="wave" />}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid md={4} lg={3} xs={12} className={classes.userInfoRightPanel}>
            <Stack direction="row" justifyContent={"flex-end"} alignItems="center" spacing={1}>
              <Skeleton variant="rounded" width="40px" height="40px" animation="wave" />
              <Skeleton variant="rounded" width="40px" height="40px" animation="wave" />
              <Skeleton variant="rounded" width="40px" height="40px" animation="wave" />
              <Skeleton variant="rounded" width="40px" height="40px" animation="wave" />
            </Stack>
            <Stack direction="row" sx={{ mt: "10px" }} justifyContent={"flex-end"} alignItems="center" spacing={1}>
              <Skeleton variant="rounded" width="120px" height="25px" animation="wave" />
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Stack direction={"row"} spacing={2} sx={{ mt: "15px" }}>
          <Skeleton variant="rounded" width="120px" height="40px" animation="wave" />
          <Skeleton variant="rounded" width="120px" height="40px" animation="wave" />
          <Skeleton variant="rounded" width="120px" height="40px" animation="wave" />
          <Skeleton variant="rounded" width="120px" height="40px" animation="wave" />
        </Stack>
      </Paper>
    </Box>
  );
};
export default withStyles(styleSheet, { name: "UserHeaderCardSkeltonStyle" })(UserHeaderCardSkelton);
