/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Box, CircularProgress, TextField, Paper, ButtonBase, IconButton, FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getMlsRecords, resetMLSRecords } from "../../../redux/tools/cmas";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Search } from "@mui/icons-material";
import { thounsandNumberFormater } from "./helper";
import _ from "lodash";
import { getMarkets } from "../../../redux/agents/addRecruitAgent";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData";
import NoImage from "../../../assets/images/noimage.png";

const Img = styled("img")({
  width: "100%",
  height: "100px",
  objectFit: "cover",
  borderRadius: "10px",
});

function SearchMLS(props) {
  const dispatch = useDispatch();

  const { classes, onClickAction, setErrorMsg, is_property_selected, agent_id, agents, itemLg, itemXs, setButtonSwitchValue } = props;

  const { mlsRecords, mlsloading, singleCma } = useSelector((state) => state.allCmas);

  const markets = useSelector((state) => state.agentList.markets);

  const [SearchListingId, setSearchListingId] = useState(null);
  const [searchStname, setSearchStName] = useState(null);
  const [searchStno, setSearchStNumber] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [switchValue, setSwitchValue] = useState(null);
  const [switchFlag, setSwitchFlag] = useState(false);
  const [datasets, setDatasets] = useState([]);

  const [agent, market] = React.useMemo(() => {
    if (agent_id) {
      const agent = agents.find((agent) => agent.id === Number(agent_id));
      const market = markets.find((market) => market.id === agent?.market_id);
      return [agent, market];
    }
    return [null, null];
  }, [singleCma, markets, agent_id]);

  const [showSwitch] = React.useMemo(() => {
    if (agent && market) {
      if (market?.datasets?.length === 2) {
        setDatasets(market?.datasets);
        setSwitchValue(market?.datasets[0]);
        setSwitchFlag(false);
        return [true];
      } else {
        setSwitchValue(market?.datasets?.length === 1 ? market.datasets[0] : null);
        setDatasets([]);
        setSwitchFlag(false);
        return [false];
      }
    } else {
      setSwitchValue(null);
      return [false, null];
    }
  }, [agent, market, agent_id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    await dispatch(getMarkets());
    dispatch(resetMLSRecords());
  }, []);

  const onClickSearch = () => {
    setSearchClicked(true);

    if (!agent_id) {
      setErrorMsg("Please select agent");
      return;
    }
    if ((!searchStno || !searchStname) && !SearchListingId) {
      setErrorMsg("To search, please enter MLS Number or, Street Name and Street Number");
      return;
    } else {
      setErrorMsg(null);
    }

    dispatch(
      getMlsRecords({
        ListingId: SearchListingId,
        StreetNumber: searchStno,
        StreetName: searchStname,
        ListingType: switchValue,
      })
    );
  };

  const handleChangeFMLSwitch = (event) => {
    setSwitchFlag(event.target.checked);
    setSwitchValue(event.target.checked ? datasets[1] : datasets[0]);
    if (setButtonSwitchValue) {
      setButtonSwitchValue(event.target.checked ? datasets[1] : datasets[0]);
    }
  };

  return (
    <div className={classes.dashboardAccordion}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h6">Add Property From MLS</Typography>
        </Grid>
        {showSwitch && (
          <Grid item xs={2} container justifyContent="flex-end">
            <Box display={"flex"} gap={"3"} alignItems={"center"}>
              <span style={{ marginRight: "15px" }}>{datasets[0]?.toUpperCase()}</span>
              <FormControlLabel sx={{ mr: 0 }} control={<Switch checked={switchFlag} onChange={handleChangeFMLSwitch} />} />
              {datasets[1]?.toUpperCase()}
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={SearchListingId}
            onChange={(e) => {
              setSearchListingId(e.target.value);
              setSearchStNumber(null);
              setSearchStName(null);
            }}
            id="standard-basic"
            label="MLS Number"
            variant="standard"
          />
        </Grid>
        <Grid item xs={1}>
          <h4 className={classes.dashboardActivitiesBoxH4}>Or</h4>
        </Grid>
        <Grid item xs={3.5}>
          <TextField
            fullWidth
            value={searchStno}
            onChange={(e) => {
              setSearchStNumber(e.target.value);
              setSearchListingId("");
            }}
            id="standard-basic"
            label="Street #"
            variant="standard"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={searchStname}
            onChange={(e) => {
              setSearchStName(e.target.value);
              setSearchListingId("");
            }}
            id="standard-basic"
            label="Street Name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={1.5} display={"flex"} justifyContent={"flex-end"} alignSelf={"end"}>
          <IconButton onClick={onClickSearch} variant="outlined" className={classes.outlineButton}>
            <Search />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1.5}>
        {searchClicked &&
          mlsRecords &&
          !mlsloading &&
          mlsRecords.map((item, index) => {
            return (
              <Grid key={index} item xs={itemXs} lg={itemLg} className={classes.AccordionCard}>
                <Paper
                  sx={{
                    p: 2,
                    marginTop: "10px",
                    cursor: "pointer",
                    flexGrow: 1,
                    backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#1A2027" : "#fff"),
                  }}
                  className={is_property_selected[item.ListingId] ? "accordionPaperLayout" : ""}
                  onClick={() => onClickAction(item)}
                >
                  <Grid container spacing={2}>
                    <Grid item>
                      <ButtonBase sx={{ width: 128 }}>
                        <Img alt="house" src={item.MediaURL ? getImageUrlWithHeightAndWidth(item.MediaURL) : NoImage} />
                      </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            {item.UnparsedAddress}
                          </Typography>
                          <Typography gutterBottom variant="subtitle1" component="div" color="text.secondary">
                            <b>{_.upperCase(item.ListingType)}:</b> {item.ListingId} <b>Status: </b>
                            <span className={classes.spanGreen}>{item.Status === "Active Under Contract" ? "ActiveUC" : item.Status}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" component="div">
                          <h6 className={classes.accordionPrice}>
                            {item.MlsStatus === "Closed"
                              ? `$${thounsandNumberFormater(item.SoldPrice)}`
                              : `$${thounsandNumberFormater(item.LastListPrice || item.ListPrice)}`}
                          </h6>
                          <CheckCircleOutlineIcon />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}

        <Grid item xs={12}>
          {mlsloading && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mt: "1rem",
                height: "100px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ color: "#0B0909" }} />
            </Box>
          )}
          {!mlsloading && mlsRecords.length === 0 && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mt: "1rem",
                height: "100px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "17px", color: "rgba(0, 0, 0, 0.6)" }} component="p" variant="body2" color="text.primary">
                No Records Found!
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default SearchMLS;
