import React from "react";
import ListingHeaderCardSkelton from "../../listings/listingDetail/cards/cardSkelton";
import {
  Paper,
  Container,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Skeleton,
  ListItemText,
  Grid,
} from "@mui/material";
function ProcessedDetailSkelton(props) {
  let { isContract } = props;
  return (
    <Container maxWidth="lg" sx={{ mt: "50px" }}>
      {isContract ? null : <ListingHeaderCardSkelton disableRemarks />}

      <Grid container spacing={2}>
        <Grid item sm={12}>
          {" "}
          <Paper
            sx={{
              padding: "20px",
              marginBottom: "16px",
              backgroundColor: "white",
            }}
          >
            <TableContainer
              sx={{
                padding: "0 15px",
                paddingBottom: "30px",
                minHeight: "500px",
                fontFamily: "AvenirNext!important",
              }}
            >
              <Table
                sx={{ minWidth: 500 }}
                size="small"
                aria-label="simple table"
              >
                {Array.from(Array(isContract ? 5 : 10).keys()).map(() => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ width: "150px" }}>
                      <ListItemText
                        primary={
                          <Skeleton
                            animation="wave"
                            height={30}
                            width="150px"
                          />
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <ListItemText
                        primary={
                          <Skeleton
                            animation="wave"
                            height={25}
                            width="100%"
                            style={{ marginBottom: 4 }}
                          />
                        }
                        secondary={
                          <Skeleton animation="wave" height={20} width="60%" />
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        {isContract ? null : (
          <Grid item sm={12} sx={{ mt: "20px" }}>
            <Paper
              sx={{
                padding: "20px",
                marginBottom: "16px",
                backgroundColor: "white",
              }}
            >
              <Skeleton animation="wave" height={50} width="300px" />
              <Grid container spacing={2}>
                <Grid item sm={12} md={4} lg={3}>
                  <Skeleton
                    animation="wave"
                    height={350}
                    width="100%"
                    sx={{ mt: "-50px", mb: "-50px" }}
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={3}>
                  <Skeleton
                    animation="wave"
                    height={350}
                    width="100%"
                    sx={{ mt: "-50px", mb: "-50px" }}
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={3}>
                  <Skeleton
                    animation="wave"
                    height={350}
                    width="100%"
                    sx={{ mt: "-50px", mb: "-50px" }}
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={3}>
                  <Skeleton
                    animation="wave"
                    height={350}
                    width="100%"
                    sx={{ mt: "-50px", mb: "-50px" }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
export default ProcessedDetailSkelton;
