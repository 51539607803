import { createSlice } from "@reduxjs/toolkit";
import { createLeadFlow } from "./createLeadFlow";
import { getLeadFlows } from "./getLeadFlows";
import { deleteLeadFlow } from "./deleteLeadFlow";
import { getLeadDefaultChannel } from "./getLeadDefaultChannel";
import { updateLeadFlow } from "./updateLeadFlow";
const initialState = {
  addFlow: {
    success: false,
    isLoading: false,
    errMsg: null,
  },
  deleteFlow: {
    success: false,
    isLoading: false,
    errMsg: null,
  },
  leadFlows: {
    success: false,
    data: [],
    isLoading: true,
    errMsg: null,
  },
  defaultChannel: {
    success: false,
    data: null,
    isLoading: true,
    errMsg: null,
  },
};
const LeadRoutingOverView = createSlice({
  name: "LeadRoutingOverView",
  initialState,
  extraReducers: {
    [createLeadFlow.pending]: (state) => {
      state.addFlow.isLoading = true;
      state.addFlow.errMsg = null;
      state.addFlow.success = false;
    },
    [createLeadFlow.fulfilled]: (state, action) => {
      state.addFlow.success = true;
      state.addFlow.isLoading = false;
      let leadFlows = [...state.leadFlows.data];
      leadFlows.push(action.payload);
      state.leadFlows.data = leadFlows;
    },
    [createLeadFlow.rejected]: (state, action) => {
      state.addFlow.isLoading = false;
      state.addFlow.success = false;
      state.addFlow.errMsg = action.payload;
    },
    // get leads routing leadFlows
    [getLeadFlows.pending]: (state) => {
      state.leadFlows.isLoading = true;
      state.leadFlows.errMsg = null;
      state.leadFlows.success = false;
    },
    [getLeadFlows.fulfilled]: (state, action) => {
      state.leadFlows.success = true;
      state.leadFlows.isLoading = false;
      state.leadFlows.data = action.payload;
    },
    [getLeadFlows.rejected]: (state, action) => {
      state.leadFlows.isLoading = false;
      state.leadFlows.success = false;
      state.leadFlows.errMsg = action.payload;
    },
    // delete lead routing flow
    [deleteLeadFlow.pending]: (state) => {
      state.deleteFlow.isLoading = true;
      state.deleteFlow.errMsg = null;
      state.deleteFlow.success = false;
    },
    [deleteLeadFlow.fulfilled]: (state, action) => {
      state.deleteFlow.success = true;
      state.deleteFlow.isLoading = false;
      let leadFlows = [...state.leadFlows.data];
      state.leadFlows.data = leadFlows.filter(
        (item) => item.id !== action.payload?.id
      );
    },
    [deleteLeadFlow.rejected]: (state, action) => {
      state.deleteFlow.isLoading = false;
      state.deleteFlow.success = false;
      state.deleteFlow.errMsg = action.payload;
    },
    // get lead default channel
    [getLeadDefaultChannel.pending]: (state) => {
      state.defaultChannel.isLoading = true;
      state.defaultChannel.errMsg = null;
      state.defaultChannel.success = false;
    },
    [getLeadDefaultChannel.fulfilled]: (state, action) => {
      state.defaultChannel.success = true;
      state.defaultChannel.isLoading = false;
      state.defaultChannel.data = action.payload;
    },
    [getLeadDefaultChannel.rejected]: (state, action) => {
      state.defaultChannel.isLoading = false;
      state.defaultChannel.success = false;
      state.defaultChannel.errMsg = action.payload;
    },
    // update lead group
    [updateLeadFlow.fulfilled]: (state, action) => {
      if (action.payload.groupData && action.payload.listGroupId === true) {
        state.defaultChannel.data.group = {
          ...action.payload.groupData,
        };
      }
      if (action.payload.listGroupId && action.payload.listGroupId !== true) {
        let newLeadFlow = state.leadFlows.data;
        let index = newLeadFlow.findIndex(
          (item) => item.group.id === action.payload.listGroupId
        );
        newLeadFlow[index].group = {
          ...newLeadFlow[index].group,
          ...action.payload.groupData,
        };
        newLeadFlow[index].groupId = action.payload.listGroupId;
      }
    },
  },
});
export default LeadRoutingOverView.reducer;
export * from "./createLeadFlow";
export * from "./getLeadFlows";
export * from "./deleteLeadFlow";
export * from "./updateLeadFlow";
export * from "./getLeadDefaultChannel";
export * from "./updateDefaultLeadChannel";
