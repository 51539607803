import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const handleGetAlerts = createAsyncThunk("Alerts/handleGetAlerts", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/events-alerts-v2/alerts?pageNumber=${data.pageNumber || 1}&pageSize=${data.pageSize || 25}&view_past_alerts=${data.view_past_alerts}&search=${data.search || ""}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
