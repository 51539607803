export const styleSheet = (theme) => ({
  root: {
    padding: "0px 8px",
    marginBottom: "15px !important",
  },
  [theme.breakpoints.down("xl")]: {
    title: {
      fontSize: "20px !important",
    },
    desc1: {
      fontSize: "13px !important",
    },
    desc2: {
      fontSize: "10px !important",
    },
  },
});
