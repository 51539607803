import React, { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import EmailTab from "./TabContent/EmailTab";
import RichTextEditor from "react-rte";
import { NoteTab } from "./TabContent/NoteTab";
import TextTab from "./TabContent/TextTab";
import { LogCall } from "./TabContent/LogCall";
import {useSelector} from "react-redux"

export function AgentRecruitingTabs({ agentProfile }) {
  const [value, setValue] = useState("1");

  const [emailValue, setEmailValue] = useState(
    RichTextEditor.createEmptyValue()
  );

  const {
    agentProfile: { toEmailList },
  } = useSelector((item) => item.agentProfile);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    console.log(toEmailList);
    if (toEmailList?.filter((item) => item.filterFlag)?.length) {
      setValue("2")
    }
  }, [toEmailList]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{ justifyContent: "space-around" }}
            variant="fullWidth"
          >
            <Tab
              label="Create note"
              value="1"
              icon={<InsertDriveFileIcon />}
              iconPosition="start"
            />
            <Tab
              label="send email"
              value="2"
              icon={<EmailIcon />}
              iconPosition="start"
            />
            <Tab
              label="send text"
              value="3"
              icon={<MessageIcon />}
              iconPosition="start"
            />
            <Tab
              label="log call"
              value="4"
              icon={<AddIcCallIcon />}
              iconPosition="start"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <NoteTab />
        </TabPanel>
        <TabPanel value="2">
          <EmailTab
            emailValue={emailValue}
            setEmailValue={setEmailValue}
            btnText={"Send Email"}
            btnStyle={"btn-send-email"}
            agentProfile={agentProfile}
          />
        </TabPanel>
        <TabPanel value="3">
          <TextTab />
        </TabPanel>
        <TabPanel value="4">
          <LogCall />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
