import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Card, Typography, Stack } from "@mui/material";
import { TabContext } from "@mui/lab";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import { Container } from "@mui/system";
import MakeoverListingTable from "./table";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Search, StyledInputBase } from "../listingsMain";
import { getMakeoverList } from "../../../redux/listings/makeover";
import { NumberFormat } from "../../../utils/numberFormat";

function MakeoverMain(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    sortBy: "",
    status:
      location.pathname === "/listings/makeover/outstanding"
        ? "outstanding"
        : location.pathname === "/listings/makeover/paid"
        ? "paid"
        : "outstanding",
    search: "",
  });
  const makeoverList = useSelector((state) => state.listings.makeover.makeoverList);

  const handleUpdateFilter = (field, value) => {
    setFilters({
      ...filters,
      pageNumber: 1,
      [field]: value,
      search: "",
    });
  };

  useEffect(() => {
    dispatch(getMakeoverList(filters));
  }, [filters]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => {
        setFilters({ ...filters, search, pageNumber: 1 });
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <Box>
      <Card variant="outlined" sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
        {" "}
        <Box className={classes.TopTransactionsBarArea}>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TransactionsTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{ sx: { background: "black", color: "black" } }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                  >
                    <Tab
                      label="Outstanding"
                      value={`/listings/makeover/outstanding`}
                      to={`/listings/makeover/outstanding`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("status", "outstanding")}
                    />
                    <Tab
                      label="Paid"
                      value={`/listings/makeover/paid`}
                      to={`/listings/makeover/paid`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => handleUpdateFilter("status", "paid")}
                    />
                  </Tabs>
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
        </Box>
      </Card>
      <Container className={classes.makeoverListArea} maxWidth="xl">
        <Box className={classes.makeoverListAreaTop}>
          <Typography className={classes.makeoverListHeading}>
            {makeoverList.count || ""} {location.pathname === "/listings/makeover/outstanding" ? " Outstanding" : " Paid"} Projects
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            {location.pathname === "/listings/makeover/outstanding" ? (
              <Box className={classes.makeoverTopStat}>
                <Typography className={classes.tableRootFooterItem1}>Total Spent</Typography>
                <Typography>{NumberFormat({ number: makeoverList?._metaData?.totalSpent, maximumFractionDigits: 2 })}</Typography>
              </Box>
            ) : null}
            {location.pathname === "/listings/makeover/outstanding" ? (
              <Box className={classes.makeoverTopStat}>
                <Typography className={classes.tableRootFooterItem1}>Total Remaining</Typography>
                <Typography> {NumberFormat({ number: makeoverList?._metaData?.totalRemaining, maximumFractionDigits: 2 })}</Typography>
              </Box>
            ) : null}

            <Search sx={{ mr: "0px" }}>
              <StyledInputBase onChange={(e) => setSearch(e.target.value)} placeholder="Search Makeover" inputProps={{ "aria-label": "search" }} />
            </Search>
          </Stack>
        </Box>
        <Routes>
          <Route path="/outstanding" element={<MakeoverListingTable isOutStanding={true} setFilters={setFilters} {...props} />} />
          <Route path="/paid" element={<MakeoverListingTable setFilters={setFilters} {...props} />} />
        </Routes>
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "MakeoverMainStyle" })(MakeoverMain);
