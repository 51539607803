import React from "react";
import { Box, Paper, List, ListItem, ListItemAvatar, Typography } from "@mui/material";
import Avatar from "../../../components/uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import LeadsInboxSkelton from "./components/leadsInboxSkelton";
import { useSelector } from "react-redux";
import momentTZ from "moment-timezone";
import ChatIcon from "@mui/icons-material/Chat";
import moment from "moment";
export const InboxName = ({ onClick, profileDetail,id,selectedUser }) => {
  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white", border: "none", cursor: "pointer" }} >
      <List sx={{background:profileDetail.id===selectedUser?.id?"#f5fafd":"",borderLeft:profileDetail.id===selectedUser?.id?"2px solid #40a2d9":"",borderBottom:"1px solid lightgrey"}}>
        <ListItem alignItems="flex-start" onClick={onClick} secondaryAction={(<Box sx={{fontSize:'11px'}}> 
        {moment(profileDetail?.created).isSame(moment(), 'day')?momentTZ.tz(profileDetail?.created, "America/New_York")?.format("LT"): momentTZ.tz(profileDetail?.created, "America/New_York")?.format("ll")}
        </Box>)}  >
          <ListItemAvatar>
            <Avatar sx={{fontSize:'17px'}} src={profileDetail?.profile_img}>
              {`${profileDetail.firstName?.slice(0, 1)}${profileDetail.lastName?.slice(0, 1)}`}
            </Avatar>
          </ListItemAvatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              // boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
              width: "100%",
            }}
          >
            <Typography variant="body1">
              {profileDetail.firstName} {profileDetail.lastName}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "rgba(0,0,0,0.6)",fontSize:'14px',lineHeight:'16px',maxWidth:'26ch' }}
            >
              {profileDetail?.message?.slice(0,50) || "NA"} {profileDetail?.message?.length>50?"...":""}
            </Typography>
          </Box>
        </ListItem>
      </List>
    </Paper>
  );
};

function InboxLeads(props) {
  let { classes, handleClick ,selectedUser} = props;
  const leadsList = useSelector((state) => state.leads.inbox.leadsList);
  return (
    <Box className={classes.Sidebar}>
      {" "}
      {leadsList.isLoading ? (
        <LeadsInboxSkelton count={5} />
      ) : leadsList.data?.chatUsers?.length ? (
        leadsList.data?.chatUsers?.filter((val)=>!val.archived)?.map((i, index) => <InboxName selectedUser={selectedUser} profileDetail={i} id={index} onClick={(e) => handleClick(i)} />)
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <center>
            <ChatIcon sx={{ fontSize: "100px", color: "lightgray" }} />
            <br />
            Not Exists
          </center>
        </Box>
      )}
    </Box>
  );
}
export default withStyles(styleSheet, { name: "InboxLeadsStyle" })(InboxLeads);
