import React, { useEffect, useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  ListItemButton,
  Typography,
  IconButton,
  Card,
  CardHeader,
  Box,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "../../../components/uiElements/avatar";
import { getData } from "../../../utils/getData";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import RVCPSkelton from "../skelton/rvcp";
import CustomQuestionResponse from "./customQuestionRes";
function RSVPListModal(props) {
  let { classes, open, setOpen, eventData } = props;
  const [loading, setLoading] = useState(false);
  const [openQuestions, setOpenQuestions] = useState(false);
  const [rsvpDetail, setRsvpDetail] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open && eventData.id) {
      handleGetRsvpList();
    }
  }, [open]);
  const handleGetRsvpList = async () => {
    setLoading(true);
    const result = await getData(`${process.env.REACT_APP_BASE_URL}api/events-alerts-v2/event/${eventData.id}/invitees`);
    if (result.metaData) {
      setRsvpDetail(result);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.modalHeading} id="alert-dialog-title">
          RSVP List
        </DialogTitle>
        <DialogContent sx={{ width: "800px" }}>
          {!loading ? (
            <Box>
              <Grid container direction={"row"} spacing={2} sx={{ mt: "0px" }}>
                <Grid sm={3} item>
                  <Card>
                    <CardHeader
                      classes={{
                        title: classes.rsvpCardHeader,
                        subheader: classes.rsvpCardDes,
                      }}
                      title={rsvpDetail?.metaData?.attending || "0"}
                      subheader="Attending"
                      sx={{ padding: "10px" }}
                    />
                  </Card>
                </Grid>
                <Grid sm={3} item>
                  <Card>
                    <CardHeader
                      classes={{
                        title: classes.rsvpCardHeader,
                        subheader: classes.rsvpCardDes,
                      }}
                      title={rsvpDetail?.metaData?.not_responded || "0"}
                      subheader="No Response"
                      sx={{ padding: "10px" }}
                    />
                  </Card>
                </Grid>
                <Grid sm={3} item>
                  <Card>
                    <CardHeader
                      classes={{
                        title: classes.rsvpCardHeader,
                        subheader: classes.rsvpCardDes,
                      }}
                      title={rsvpDetail?.metaData?.days_to_deadline || "0"}
                      subheader="Days to Deadline"
                      sx={{ padding: "10px" }}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Grid container direction={"row"} spacing={3} sx={{ mt: "0px" }}>
                <Grid sm={6} item>
                  <Typography sx={{ mb: "4px" }}>Attending</Typography>
                  {rsvpDetail?.attendingUsers?.length ? (
                    <Card variant="outlined" className={classes.userListCard}>
                      <List component="nav" aria-label="main mailbox folders" disablePadding>
                        {rsvpDetail?.attendingUsers?.map((item, index) => (
                          <ListItem
                            secondaryAction={
                              <IconButton
                                onClick={() => {
                                  setUserDetail(item);
                                  setOpenQuestions(true);
                                }}
                                edge="end"
                                aria-label="delete"
                              >
                                <HelpOutlinedIcon fontSize="small" sx={{ color: "#A7A7A7" }} />
                              </IconButton>
                            }
                            disablePadding
                            sx={{ borderBottom: "1px solid lightgrey" }}
                          >
                            <ListItemButton sx={{ pt: "5px", pb: "5px" }}>
                              <ListItemAvatar sx={{ minWidth: "40px" }}>
                                <Avatar
                                  src={item.users_v2?.profile_images?.profile_img}
                                  sx={{ fontSize: "13px", width: "25px", height: "25px" }}
                                >{`${item.users_v2?.full_name?.split(" ")[0]?.slice(0, 1)}${item.users_v2?.full_name
                                  ?.split(" ")[1]
                                  ?.slice(0, 1)}`}</Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={item.users_v2?.full_name + " " + (item.more_than_one === "yes" ? " +1" : "")} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  ) : (
                    <Card variant="outlined" className={classes.userListCardNotData}>
                      <center>
                        <AnnouncementIcon sx={{ fontSize: "55px", color: "grey" }} />
                        <Box>No Data</Box>
                      </center>
                    </Card>
                  )}
                </Grid>
                <Grid sm={6} item>
                  <Typography sx={{ mb: "4px" }}>No Response</Typography>
                  {rsvpDetail?.usersNotResponded?.length ? (
                    <Card variant="outlined" className={classes.userListCard}>
                      <List component="nav" aria-label="main mailbox folders" disablePadding>
                        {rsvpDetail?.usersNotResponded?.map((item, index) => (
                          <ListItem disablePadding sx={{ borderBottom: "1px solid lightgrey" }}>
                            <ListItemButton sx={{ pt: "5px", pb: "5px" }}>
                              <ListItemAvatar sx={{ minWidth: "40px" }}>
                                <Avatar
                                  src={item.users_v2?.profile_images?.profile_img}
                                  sx={{ fontSize: "13px", width: "25px", height: "25px" }}
                                >{`${item.users_v2?.full_name?.split(" ")[0]?.slice(0, 1)}${item.users_v2?.full_name
                                  ?.split(" ")[1]
                                  ?.slice(0, 1)}`}</Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={item.users_v2?.full_name} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  ) : (
                    <Card variant="outlined" className={classes.userListCardNotData}>
                      <center>
                        <AnnouncementIcon sx={{ fontSize: "55px", color: "grey" }} />
                        <Box>No Data</Box>
                      </center>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <RVCPSkelton />
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", mt: "20px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            color="inherit"
            variant="outlined"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CustomQuestionResponse open={openQuestions} userDetail={userDetail} eventData={eventData} setOpen={setOpenQuestions} />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "RSVPListModalStyle" })(RSVPListModal);
