export const durationTimeFormat = (duration) => {  
  if (duration === 0) return '0s';
  var hrs = Math.floor(duration / 3600);
  var mins = Math.floor((duration % 3600) / 60);
  var secs = Math.floor(duration % 60);

  var ret = "";

  if (hrs > 0) {
    ret += `${hrs}h${mins > 0 || secs > 0? ' ' : ''}`; 
  }

  if (mins > 0) {
    ret += `${mins}m${secs > 0? ' ' : ''}`;
  }

  if (secs > 0) {
    ret += `${secs}s`;
  }

  return ret;
}