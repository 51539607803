import * as React from "react";
import TransactionsList from "./transationsList";
import TransactionDetail from "./transactionsDetail";
import TaskTemplates from "./taskTemplates";
import AddTransactions from "./Forms";
import { Route, Routes } from "react-router-dom";
const Transactions = (props) => {
  return (
    <Routes>
      <Route
        exact
        path="/contracts/add-contract/"
        element={<AddTransactions {...props} />}
      />
      <Route
        path="/contracts/contract-detail/:id/*"
        element={<TransactionDetail {...props} />}
      />
      <Route path="/task_templates/*" element={<TaskTemplates {...props} />} />
      <Route path="/*" element={<TransactionsList {...props} />} />
    </Routes>
  );
};
export default Transactions;
