import React, { useState } from "react";
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  Avatar,
  Stack,
  Paper,
  Button,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import { styleSheet } from "./style";
import Linkify from "react-linkify";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import NoteIcon from "@mui/icons-material/Note";
import AddAgentNoteModal from "../modals/addAgentNote";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import editPencil from "../../../../assets/editPencil.png";
import deleteIcon from "../../../../assets/deleteIcon.png";
import ConfirmModal from "../../../../components/globalModal/ConfirmModal";
import { deleteNote } from "../../../../redux/agents/agentRosterDetail/notes";
import { useParams } from "react-router-dom";
import ResponseAlert from "../../../../components/responseAlert";

const Item = styled(Paper)(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: "white",
  width: "133px",
  height: "133px",
  boxShadow: "0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
}));
function AgentRosterOverviewTab(props) {
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [noteDetail, setNoteDetail] = useState({});
  const agentRosterProfile = useSelector((state) => state.agentRoster.agentRosterProfile);
  const notes = useSelector((state) => state.agentRoster.notes.notes);
  const deleteAgentNote = useSelector((state) => state.agentRoster.notes.deleteNote);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);

  let { classes } = props;

  const handleSuccess = () => {
    setErrorAlert({ errorMsg: `You have successfully deleted  the note`, errorType: "success", isOpen: true });
    setOpenDelete(false);
  };
  const handleError = (error) => {
    setOpenDelete(false);
    setErrorAlert({ errorMsg: JSON.stringify(error), errorType: "error", isOpen: true });
  };

  const handleConfirm = () => {
    let obj = {
      userId: id,
      noteId: noteDetail.id,
      handleSuccess,
      handleError,
    };
    dispatch(deleteNote(obj));
  };

  return (
    <Grid container spacing={2} sx={{ mb: "40px" }} direction="row">
      <Grid item md={7} lg={7} xs={12}>
        <Card variant="outlined" className={classes.overViewTabCardMain}>
          <CardHeader
            classes={{ title: classes.overViewTabCardHeading }}
            sx={{ padding: "0px", mb: "30px" }}
            title="Agent Notes"
            action={
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#0B0909",
                  "&:hover": { backgroundColor: "#0f0a0a" },
                  color: "white",
                  fontWeight: "400",
                  marginRight: "10px",
                  mt: "15px",
                }}
                onClick={() => setOpen(true)}
                startIcon={<AddRoundedIcon />}
              >
                Add Note
              </Button>
            }
          />
          {notes?.length > 0 &&
            notes?.map((item, index) => (
              <Card variant="outlined" key={index} className={classes.importantNotesCard}>
                <CardHeader
                  sx={{ padding: "0px", paddingTop: "3px" }}
                  classes={{
                    title: classes.importantNotesHeader,
                    subheader: classes.importantNotesDes,
                  }}
                  title={item.title}
                  action={
                    <CardHeader
                      classes={{
                        title: classes.noteDatesText,
                      }}
                      avatar={
                        <Avatar
                          sx={{ width: 22, height: 22 }}
                          classes={{ root: classes.customerNoteCardAvatarImg }}
                          alt={
                            item.created_by
                              ? agentRosterList?.list.find((it) => it.id === item.created_by)?.full_name
                              : agentRosterProfile?.profile?.first_name
                          }
                          src={
                            item.created_by
                              ? agentRosterList?.list.find((it) => it.id === item.created_by)?.profile_images?.profile_img
                              : agentRosterProfile?.profile?.profile_images?.profile_img_thumbnail || ""
                          }
                        />
                      }
                      title={`${
                        item?.created_by_user && item?.created_by_user?.full_name
                          ? item?.created_by_user?.full_name
                          : item.created_by
                          ? agentRosterList?.list.find((it) => it.id === item.created_by)?.full_name
                          : agentRosterProfile?.profile?.full_name || ""
                      }  on ${moment(item.createdAt || new Date()).format("L")}`}
                    />
                  }
                />
                <CardContent className={classes.importantNotesContent}>
                  <Linkify
                    properties={{ target: "_blank" }}
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {item.body}
                  </Linkify>
                </CardContent>
                <Stack direction="row" sx={{ mt: "-10px" }} justifyContent="flex-end" alignItems="center" spacing={2}>
                  <IconButton
                    onClick={(e) => {
                      setNoteDetail(item);
                      setOpenMenu(e.currentTarget);
                    }}
                    size="small"
                    sx={{ mr: "-15px", padding: "2px" }}
                  >
                    <MoreHorizRoundedIcon sx={{ color: "#CBC8C8" }} />
                  </IconButton>
                </Stack>{" "}
              </Card>
            ))}
          {notes?.length === 0 && (
            <DataNotFound
              color="#BDBDBD"
              fontSize="15px"
              minHeight="140px"
              icon={<NoteIcon sx={{ fontSize: "60px", color: "#BDBDBD" }} fontSize="large" />}
              title="No Notes Exist"
            />
          )}
        </Card>
      </Grid>
      <Grid item md={5} lg={5} xs={12}>
        {" "}
        <Card variant="outlined" className={classes.overViewTabCard}>
          <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
            <Item backgroundColor="#8BC34A">
              <Typography classes={{ root: classes.overViewTabCardRightTop }}>Calls</Typography>
              <Typography classes={{ root: classes.overViewTabCardRightBottom }}>31</Typography>
            </Item>
            <Item backgroundColor="#FFB74D">
              <Typography classes={{ root: classes.overViewTabCardRightTop }}>Time to Action</Typography>
              <Typography classes={{ root: classes.overViewTabCardRightBottom }}>48s</Typography>
            </Item>
            <Item backgroundColor="#FF8A80">
              <Typography classes={{ root: classes.overViewTabCardRightTop }}>Leads Claimed</Typography>
              <Typography classes={{ root: classes.overViewTabCardRightBottom }}>9</Typography>
            </Item>
          </Stack>
        </Card>
      </Grid>
      <AddAgentNoteModal open={open} setOpen={setOpen} detail={noteDetail} {...props} />
      <Menu
        anchorEl={openMenu}
        id="power-search-menu"
        open={Boolean(openMenu)}
        onClose={() => {
          setOpenMenu(false);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List disablePadding>
          <ListItem dense disablePadding size="small">
            <ListItemButton
              onClick={() => {
                setOpen("update");
                setOpenMenu(false);
              }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <img src={editPencil} width="18px" alt={"editPencil"} />
              </ListItemIcon>
              <ListItemText className={classes.menuListText} primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem dense disablePadding>
            <ListItemButton
              onClick={() => {
                setOpenDelete(true);
                setOpenMenu(false);
              }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <img src={deleteIcon} style={{ marginLeft: "-2px" }} width="20px" alt="deleteIcon" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        loading={deleteAgentNote.isLoading}
        title="Delete Confirmation"
        content="Are you sure you want to delete this note?"
        handleConfirm={handleConfirm}
      />{" "}
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "AgentRosterOverviewTabStyle" })(AgentRosterOverviewTab);
