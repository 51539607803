import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import { Box, Paper, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style";
function ChatHeaderSkelton(props) {
  let { classes } = props;
  return (
    <Paper variant="outlined" className={classes.chatHeaderArea}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={8}>
          <Skeleton animation="wave" height={50} width="60%" />
        </Grid>
        <Grid item sm={12} md={4}>
          <Box className={classes.customBtnGroup}>
            <Skeleton animation="wave" sx={{mr:"10px"}} height={50} width="120px" />
            <Skeleton animation="wave" height={50} width="120px" />
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton animation="wave" height={30} width="80%" />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton animation="wave" height={30} width="80%" />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton animation="wave" height={30} width="80%" />
        </Grid>
        <Grid item sm={12}>
          <Skeleton animation="wave" height={30} width="80%" />
        </Grid>
        <Grid item sm={12}>
          <Skeleton animation="wave" height={30} width="100%" />
          <Skeleton animation="wave" height={30} width="100%" />
        </Grid>
      </Grid>
    </Paper>
  );
}
export default withStyles(styleSheet, { name: "ChatHeaderSkeltonStyle" })(ChatHeaderSkelton);
