import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getTextHistory = createAsyncThunk(
  "LeadsInbox/getTextHistory",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/textMessages/getTextHistory?agentId=${data.agentId?data.agentId:""}&userId=${data.userId}&limit=${data.limit}&lead_id=${data.lead_id?data.lead_id:""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

