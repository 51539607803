import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  Skeleton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import houseIcon from "../../../../assets/house.png";
import BedRoundedIcon from "@mui/icons-material/BedRounded";
import BathtubRoundedIcon from "@mui/icons-material/BathtubRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import dollarTag from "../../../../assets/dollarTag.png";
import bedIcon from "../../../../assets/bedIcon.png";
import ScreenSearchDesktopRoundedIcon from "@mui/icons-material/ScreenSearchDesktopRounded";
import { useSelector, useDispatch } from "react-redux";
import { getLeadActivities } from "../../../../redux/leads/getLeadActivities";
import { getActivitiesStats } from "../../../../redux/leads/getActivityStats";
import { NumberFormat } from "../../../../utils/numberFormat";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import momentTZ from "moment-timezone";
import ActivityListModal from "./modals/activityList";
import Pagination from "../../common/Pagination";
function ActivitiesDetail(props) {
  let { classes, personId, selectedUser } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const activities = useSelector((item) => item.leadProfile.activities);
  const activitiesStats = useSelector(
    (item) => item.leadProfile.activitiesStats
  );
  useEffect(() => {
    dispatch(getLeadActivities({ personId }));
    dispatch(getActivitiesStats({ personId }));
  }, []);

  return (
    <Box>
      <Paper
        variant="outlined"
        className={classes.activitiesCard}
        sx={{ borderRadius: "10px" }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={1}
          sx={{ mb: "15px", mt: "-5px" }}
        >
          <Typography className={classes.activitiesCardTitle}>
            {" "}
            <ScreenSearchDesktopRoundedIcon
              sx={{ color: "rgba(0, 0, 0, 0.54)", mr: "10px" }}
            />{" "}
            Activity
          </Typography>
          {activities?.events?.length ? (
            <Stack direction="row" spacing={0.5} alignItems={"center"}>
              <CircleRoundedIcon
                color="success"
                fontSize="small"
                sx={{
                  fontSize: ".8rem",
                  color:
                    momentTZ().diff(
                      momentTZ(activities?.events?.[0]?.occured),
                      "minutes"
                    ) < 5
                      ? "#2E7D32"
                      : "rgba(0, 0, 0, 0.54)",
                }}
              />
              <Typography
                color="success"
                sx={{
                  color:
                    momentTZ().diff(
                      momentTZ(activities?.events?.[0]?.occured),
                      "minutes"
                    ) < 5
                      ? "#1B5E20"
                      : "rgba(0, 0, 0, 0.54)",
                  fontSize: "13px",
                }}
              >
                {momentTZ().diff(
                  momentTZ(activities?.events?.[0]?.occured),
                  "minutes"
                ) < 5
                  ? "Online Now"
                  : `Last seen ${momentTZ(
                      activities?.events?.[0]?.occured
                    )?.fromNow()}`}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        <Stack
          direction="row"
          alignItems={"center"}
          spacing={2}
          flexWrap={"wrap"}
          sx={{ mb: "15px" }}
        >
          {activitiesStats?.averagePrice ? (
            <Stack
              direction="row"
              className={classes.activityStatsItem}
              spacing={0}
              alignItems={"center"}
            >
              <img
                src={dollarTag}
                sx={{ marginTop: "-3px" }}
                alt="dollarTag"
                width="25px"
              />
              <Typography>
                {" "}
                {activitiesStats?.averagePrice
                  ? NumberFormat({
                      number: activitiesStats?.averagePrice,
                      maximumFractionDigits: 0,
                      notation: "compact",
                    })
                  : "0"}{" "}
              </Typography>
            </Stack>
          ) : null}
          {activitiesStats?.averageArea ? (
            <Stack
              direction="row"
              className={classes.activityStatsItem}
              spacing={0}
              alignItems={"center"}
            >
              <HouseRoundedIcon
                sx={{
                  color: "#7684ff",
                  mr: "3px",
                  fontSize: "24px",
                  mt: "-3px",
                }}
                fontSize="small"
              />
              <Typography>
                {" "}
                {new Intl.NumberFormat().format(
                  activitiesStats?.averageArea
                )}{" "}
                sf{" "}
              </Typography>
            </Stack>
          ) : null}
          {activitiesStats?.averageBeds ? (
            <Stack
              direction="row"
              className={classes.activityStatsItem}
              spacing={0}
              alignItems={"center"}
            >
              <img
                src={bedIcon}
                style={{ marginRight: "3px" }}
                alt="bedIcon"
                width="22px"
              />
              <Typography>{activitiesStats?.averageBeds} </Typography>
            </Stack>
          ) : null}
          {activitiesStats?.averageBaths ? (
            <Stack
              direction="row"
              className={classes.activityStatsItem}
              spacing={0}
              alignItems={"center"}
            >
              <BathtubRoundedIcon
                sx={{ color: "rgba(0, 214, 227, 1)", mr: "3px" }}
                fontSize="small"
              />
              <Typography>{activitiesStats?.averageBaths} </Typography>
            </Stack>
          ) : null}
        </Stack>
        <Box className={classes.recentActivitiesArea}>
          <Typography className={classes.recentActivitiesHeading}>
            {" "}
            RECENT ACTIVITIES{" "}
            {Number(activities?._metadata?.total || 0) > 5 ? (
              <span onClick={() => setOpen(true)}>
                {" "}
                - VIEW ALL ({activities?._metadata?.total}){" "}
              </span>
            ) : null}
          </Typography>
          {activities?.isLoading && !open ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ mt: "10px" }}
              height="200px"
              width="100%"
            />
          ) : activities?.events?.length ? (
            activities?.events?.slice(0, 5).map((item, index) => (
              <a
                href={item?.property?.url}
                target="_blank"
                style={{ textDecoration: "none" }}
                key={index}
              >
                <CustomTooltip
                  placement="left"
                  title={`Viewed ${momentTZ(item.updated).fromNow()}`}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    className={classes.recentActivitiesAreaItem}
                  >
                    <VisibilityOutlinedIcon
                      sx={{ color: "rgb(255, 173, 129)", fontSize: "19px" }}
                    />
                    <Typography className={classes.recentActivitiesContent}>
                      {item?.property?.street
                        ? item?.property?.street +
                          ", " +
                          item?.property?.city +
                          ", " +
                          item?.property?.state +
                          ", " +
                          item?.property?.code
                        : "Street Address not exists"}
                    </Typography>
                  </Stack>
                </CustomTooltip>
              </a>
            ))
          ) : (
            <Typography className={classes.recentActivitiesNotFound}>
              No activity found!
            </Typography>
          )}
        </Box>
        <Grid container spacing={1}>
          {/* {activities?.isLoading
            ? "Loading please wait"
            : activities?.events?.length
            ? activities?.events.map((item, index) => (
                <Grid key={index} item lg={6} xl={4}>
                  <Paper
                    variant="outlined"
                    className={classes.activitiesCardItem}
                  >
                    <Box className={"hoveredBlock"}>
                      <Box className={classes.hoveredBlockTopContent}>
                        {item.property?.street}
                      </Box>
                      <Stack
                        className={classes.hoveredBlockState}
                        direction={"row"}
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                        spacing={1}
                      >
                        <Box className={classes.activitiesPriceItem}>
                          {NumberFormat({
                            number: item.property?.price,
                            maximumFractionDigits: 0,
                            notation: "compact",
                          })}
                        </Box>
                        <Box sx={{ paddingBottom: "1px", paddingRight: "5px" }}>
                          <Stack
                            direction={"row"}
                            className={classes.activitiesStateItem}
                          >
                            <BedRoundedIcon
                              sx={{ fontSize: "15px", mr: "2px" }}
                            />{" "}
                            {item.property?.bedrooms}
                          </Stack>
                          <Stack
                            direction={"row"}
                            className={classes.activitiesStateItem}
                          >
                            <BathtubRoundedIcon
                              sx={{ fontSize: "15px", mr: "2px" }}
                            />{" "}
                            {item.property?.bathrooms}
                          </Stack>
                        </Box>
                      </Stack>
                    </Box>
                    <img
                      src={item?.property?.url || houseIcon}
                      alt="houseIcon"
                      onError={(e) => {
                        e.currentTarget.src = houseIcon;
                      }}
                    />
                  </Paper>
                </Grid>
              ))
            : "No activities exists"} */}
        </Grid>
        {/* <Stack
          direction="row"
          justifyContent={"center"}
          sx={{ mt: "15px" }}
          spacing={1}
        >
          {activities?._metadata?.total > 6 ? (
            <Button
              variant="outlined"
              className={classes.activitiesViewButton}
              size="small"
            >
              VIEW ALL ({activities?._metadata?.total})
            </Button>
          ) : null}
          {activities?._metadata?.total > 6 ? (
            <Button
              variant="contained"
              className={classes.activitiesEditButton}
              size="small"
            >
              EDIT SEARCH
            </Button>
          ) : null}
        </Stack> */}
      </Paper>
      <ActivityListModal
        open={open}
        setOpen={setOpen}
        personId={personId}
        {...props}
      />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "ActivitiesDetailStyle" })(
  ActivitiesDetail
);
