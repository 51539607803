import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Box } from "@mui/material";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { addVendor } from "../../../redux/vendors/vendorList";

function AddVendorModal(props) {
  let { open, setOpen, classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const addVendorData = useSelector((state) => state.vendors.VendorsList.addVendorData);
  const [vendorDetail, setVendorDetail] = useState({
    first_name: "",
    last_name: "",
    name: "",
    street: "",
    city: "",
    postal_code: "",
    state: "",
  });

  const handleUpdateDetail = (field, value) => {
    setVendorDetail({ ...vendorDetail, [field]: value });
    setErrMsg({});
  };
  useEffect(() => {
    if (addVendorData.success && !addVendorData.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully apply the award budget",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setVendorDetail({
        first_name: "",
        last_name: "",
        name: "",
        street: "",
        city: "",
        postal_code: "",
        state: "",
      });
    } else if (!addVendorData.isLoading && addVendorData.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(addVendorData.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addVendorData.isLoading]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!vendorDetail.first_name && !vendorDetail.name) {
      isFormValid = false;
      errorMsg["first_name"] = "Please enter the first name";
      setErrorAlert({
        errorMsg: "Please enter the first name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!vendorDetail.last_name && !vendorDetail.name) {
      isFormValid = false;
      errorMsg["last_name"] = "Please enter the last name";
      setErrorAlert({
        errorMsg: "Please enter the last name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!vendorDetail.name && (!vendorDetail.last_name || !vendorDetail.first_name)) {
      isFormValid = false;
      errorMsg["name"] = "Please enter the company name";
      setErrorAlert({
        errorMsg: "Please enter the company name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!vendorDetail.street) {
      isFormValid = false;
      errorMsg["street"] = "Please enter the street";
      setErrorAlert({
        errorMsg: "Please enter the street",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!vendorDetail.city) {
      isFormValid = false;
      errorMsg["city"] = "Please enter the city";
      setErrorAlert({
        errorMsg: "Please enter the city",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!vendorDetail.state) {
      isFormValid = false;
      errorMsg["state"] = "Please enter the state";
      setErrorAlert({
        errorMsg: "Please enter the state",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!vendorDetail.postal_code) {
      isFormValid = false;
      errorMsg["postal_code"] = "Please enter the postal code";
      setErrorAlert({
        errorMsg: "Please enter the postal code",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleAddSubmitUser = () => {
    if (handleValidate()) {
      console.log("vendorDetail",vendorDetail);
      dispatch(addVendor(vendorDetail));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.addVendorHeading} sx={{ mt: "10px", mb: "15px" }} id="alert-dialog-title">
          <Box> Add Vendor </Box>
        </DialogTitle>
        <DialogContent sx={{ width: "800px", overflow: "visible" }}>
          <Grid container direction="row" spacing={3} sx={{ overflow: "visible" }}>
            <Grid item lg={3} xs={12} sx={{ zIndex: "1" }}>
              <TextField
                value={vendorDetail.first_name}
                onChange={(e) => handleUpdateDetail("first_name", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"First Name"}
                label=" First Name"
                variant="standard"
                size="small"
                error={errMsg.first_name}
                helperText={errMsg.first_name}
              />
            </Grid>
            <Grid item lg={3} xs={12} sx={{ zIndex: "1" }}>
              <TextField
                value={vendorDetail.last_name}
                onChange={(e) => handleUpdateDetail("last_name", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Last Name"}
                label=" Last Name"
                variant="standard"
                size="small"
                error={errMsg.last_name}
                helperText={errMsg.last_name}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <TextField
                value={vendorDetail.name}
                onChange={(e) => handleUpdateDetail("name", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Company Name"}
                label="Company Name"
                variant="standard"
                size="small"
                error={errMsg.name}
                helperText={errMsg.name}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ marginTop: "-10px" }} spacing={6}>
            <Grid item lg={5} xs={12}>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                label="Street Address"
                value={vendorDetail.street}
                onChange={(e) => handleUpdateDetail("street", e.target.value)}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                error={errMsg.street}
                helperText={errMsg.street}
              />
            </Grid>
            <Grid item lg={3} xs={12}>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                label="City"
                value={vendorDetail.city}
                variant="standard"
                onChange={(e) => handleUpdateDetail("city", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errMsg.city}
                helperText={errMsg.city}
              />
            </Grid>
            <Grid item lg={2} xs={12}>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                label="State"
                value={vendorDetail.state}
                onChange={(e) => handleUpdateDetail("state", e.target.value)}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                error={errMsg.state}
                helperText={errMsg.state}
              />
            </Grid>
            <Grid item lg={2} xs={12}>
              <TextField
                fullWidth
                classes={{ root: classes.detailInput }}
                label="Zip Code"
                onChange={(e) => handleUpdateDetail("postal_code", e.target.value)}
                value={vendorDetail.postal_code}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                error={errMsg.postal_code}
                helperText={errMsg.postal_code}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="standard"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleAddSubmitUser()}
            classes={{ root: classes.saveButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addVendorData.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddVendorModalStyle" })(AddVendorModal);
