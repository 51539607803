export const styleSheet = () => ({
  pageRoot: {
    marginTop: "5px !important",
  },
  addTempPageRoot: {
    marginTop: "50px !important",
    minHeight:"82vh",
    "& .DraftEditor-root":{
      height:'400px'
    }
  },
  pageHeading:{
    fontSize:'20px !important',
    fontWeight:'400 !important',
    fontFamily:"AvenirNext  !important",
    textTransform:'capitalize !important',
  },
  loadingArea:{
    height:"80vh",
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  saveButton:{
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft:'30px !important',
    paddingRight:'30px !important'
  },
  quillEditor:{
    "& .ql-editor":{
      height:'400px',
      fontSize:'14px'
    }
  },
});