import { Container } from "@mui/material";
import UserPoints from "../agentRoster/agentDetails/tabsContent/points";
import { useSelector } from "react-redux";
function DashboardPoints(props) {
  const adminAuth = useSelector((state) => state.adminAuth);
  return (
    <Container maxWidth="xl" sx={{ mt: "40px", mb: "70px" }}>
      <UserPoints disableAccess {...props} userId={adminAuth?.adminDetail.id} />
    </Container>
  );
}
export default DashboardPoints;
