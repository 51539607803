import React, { useState } from "react";
import { Container, Grid, Box, Card, CardHeader, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddPhoneAudio from "../modals/addAudio";
import { getAllPhoneAudios } from "../../../redux/phoneConfiguration/audios";
import TableSkeleton from "../../../components/loader/tableLoader";
import ConfirmModal from "../modals/ConfirmModal";

const PhoneAudios = (props) => {
  let { classes, search } = props;
  const dispatch = useDispatch();
  const [openAddAudio, setOpenAddAudio] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdateExtension, setOpenUpdateExtension] = useState(false);
  const audioList = useSelector((item) => item.phoneConfiguration.PhoneAudios.audioList);
  const { isLoading } = audioList;

  useEffect(() => {
    if (!audioList.isLoading) {
      dispatch(getAllPhoneAudios({}));
    }
  }, []);

  const columns = [
    {
      field: "file_name",
      headerName: "Filename",
      renderCell: ({ row }) => {
        return <Box>{row?.file_name}</Box>;
      },
      width: 280,
      editable: false,
      sortable: true,
    },
    {
      field: "Category",
      headerName: "Category",
      renderCell: ({ row }) => {
        return <Box>{row?.category}</Box>;
      },
      width: 300,
      editable: false,
      sortable: true,
    },

    {
      field: "Currently Used",
      headerName: "Currently Used",
      renderCell: ({ row }) => {
        return <Box>{row.twilio_number?.number}</Box>;
      },
      width: 400,
      editable: false,
      sortable: true,
    },
    {
      field: "Action",
      headerName: "Action",
      renderCell: ({ row }) => {
        return (
          <Button
            variant="contained"
            size="small"
            color="inherit"
            sx={{
              backgroundColor: "white",
              color: "#000000",
              fontWeight: "400",
            }}
            onClick={() => setOpenDelete(row)}
          >
            delete
          </Button>
        );
      },
      width: 250,
      type: "actions",
      editable: false,
      sortable: true,
    },
  ];
  const handleConfirm = () => {
    setOpenDelete(false);
  };
  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.pageRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card>
                <CardHeader
                  classes={{ title: classes.pageHeading }}
                  title={
                    <Box className={classes.pageHeading}>
                      Audio{" "}
                      <Button onClick={() => setOpenAddAudio(true)} classes={{ root: classes.addPhoneButton }}>
                        Add Audio
                      </Button>
                    </Box>
                  }
                />
                <Box className={classes.tableRoot}>
                  <DataGridPro
                    components={{
                      LoadingOverlay: TableSkeleton,
                    }}
                    columns={columns}
                    rows={audioList.data ? audioList.data : []}
                    loading={isLoading}
                    rowHeight={56}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <ConfirmModal open={openDelete} handleConfirm={handleConfirm} setOpen={setOpenDelete} />
      <AddPhoneAudio open={openAddAudio} setOpen={setOpenAddAudio} />
    </>
  );
};

export default withStyles(styleSheet, { name: "PhoneAudiosStyle" })(PhoneAudios);
