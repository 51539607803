import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "../uiElements/avatar/index";
import Stack from "@mui/material/Stack";
import { Typography, Button } from "@mui/material";
import ContactsSkelton from "./skeltons/contacts";
import { useSelector, useDispatch } from "react-redux";
import DataNotFound from "../notFound/dataNotFound";
import TextField from "@mui/material/TextField";
import { AppContext } from "../callPanel/callContext";
import { setPageNumber, saveNewContact } from "../../redux/calls/getContactList";
import ResponseAlert from "../responseAlert";
import { postData } from "../../utils/postData";
import LoadingButton from "@mui/lab/LoadingButton";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NumberFormat from "react-number-format";
import { async } from "q";

function Contacts(props) {
  const [scrollFlag, setScrollFlag] = useState(true);
  const [searchText, setSearchText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const [addContactDetail, setAddContactDetail] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const { setSelectedOutgoingUser, dialNumber } = useContext(AppContext);
  let {
    setSearch,
    classes,
    search,
    makeCallForTransferWarmCall,
    makeCallForTransferColdCall,
    activeButton,
    isTransfer,
    openAddContact,
    setOpenAddContact,
  } = props;
  const contactList = useSelector((state) => state.contactList);
  const handleScrollEvent = (e) => {
    setSearch(false);
    //console.log(e);
    var bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    if (bottom && !contactList.isLoading && scrollFlag && !contactList.isLimitReached) {
      setScrollFlag(false);
      dispatch(setPageNumber());
      setTimeout(() => {
        setScrollFlag(true);
      }, 3000);
    }
  };

  useEffect(() => {
    if (searchText !== false) {
      const timeOutId = setTimeout(() => {
        setSearch(searchText);
        setPageNumber(1);
        if (searchText.length >= 10 && searchText.length < 11) {
          handleCheckSearchNumber();
        } else {
          setIsPhoneNumber(false);
        }
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchText]);

  const handleCheckSearchNumber = () => {
    if (!/^[0-9]+$/.test(searchText)) {
      setIsPhoneNumber(false);
    } else {
      setIsPhoneNumber(true);
    }
  };

  const handleUpdateDetail = (field, value) => {
    setAddContactDetail({ ...addContactDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!addContactDetail.firstName) {
      isFormValid = false;
      errorMsg["firstName"] = "Please enter the first name";
      setErrorAlert({
        errorMsg: "Please enter the first name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!addContactDetail.lastName) {
      isFormValid = false;
      errorMsg["lastName"] = "Please enter the last name";
      setErrorAlert({
        errorMsg: "Please enter the last name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!addContactDetail.companyName) {
      isFormValid = false;
      errorMsg["companyName"] = "Please enter the company name";
      setErrorAlert({
        errorMsg: "Please enter the company name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!addContactDetail.phoneNumber) {
      isFormValid = false;
      errorMsg["phoneNumber"] = "Please enter the phone number";
      setErrorAlert({
        errorMsg: "Please enter the phone number",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSaveContact = async () => {
    if (handleValidate()) {
      setLoading(true);
      let result = await postData(`${process.env.REACT_APP_BASE_URL}api/contacts`, addContactDetail);
      if (result.id) {
        dispatch(saveNewContact(result));
        setLoading(false);
        setOpenAddContact(false);
        setErrorAlert({
          errorMsg: "Contact is save successfully",
          errorType: "success",
          isOpen: true,
        });
        setAddContactDetail({
          firstName: "",
          lastName: "",
          companyName: "",
          phoneNumber: "",
        });
      } else {
        setLoading(false);
        setErrorAlert({
          errorMsg: JSON.stringify(result.errorMessage),
          errorType: "error",
          isOpen: true,
        });
      }
    }
  };

  return (
    <div className="call-contracts-list">
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        {" "}
        <TextField
          label="Search Contact"
          size="small"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          disabled={openAddContact}
          value={searchText || ""}
          fullWidth
        />
        <AddCircleIcon onClick={() => setOpenAddContact(!openAddContact)} sx={{ color: "#757575", cursor: "pointer", fontSize: "28px" }} />
      </Stack>

      <br />
      <center>
        {openAddContact ? (
          <form>
            <Box>
              <Stack spacing={2}>
                <TextField
                  label="First Name"
                  placeholder="Enter first name"
                  fullWidth
                  variant="standard"
                  value={addContactDetail.firstName}
                  error={errMsg.firstName}
                  helperText={errMsg.firstName}
                  onChange={(e) => handleUpdateDetail("firstName", e.target.value)}
                />
                <TextField
                  label="Last Name"
                  placeholder="Enter last name"
                  fullWidth
                  variant="standard"
                  value={addContactDetail.lastName}
                  error={errMsg.lastName}
                  helperText={errMsg.lastName}
                  onChange={(e) => handleUpdateDetail("lastName", e.target.value)}
                />
                <TextField
                  label="Company Name"
                  placeholder="Enter company name"
                  fullWidth
                  variant="standard"
                  value={addContactDetail.companyName}
                  error={errMsg.companyName}
                  helperText={errMsg.companyName}
                  onChange={(e) => handleUpdateDetail("companyName", e.target.value)}
                />
                <TextField
                  label="Phone Number"
                  placeholder="Enter phone number"
                  fullWidth
                  variant="standard"
                  value={addContactDetail.phoneNumber}
                  error={errMsg.phoneNumber}
                  helperText={errMsg.phoneNumber}
                  onChange={(e) => handleUpdateDetail("phoneNumber", e.target.value)}
                />
                <br />
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                  <Button
                    onClick={() => setOpenAddContact(false)}
                    sx={{
                      color: "#000000",
                      height: "35px",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={() => handleSaveContact()}
                    sx={{
                      backgroundColor: "#0B0909 !important",
                      color: "white !important",
                      fontWeight: "400 !important",
                      marginLeft: "20px !important",
                      paddingLeft: "30px !important",
                      paddingRight: "30px !important",
                      height: "35px",
                    }}
                    color="inherit"
                    autoFocus
                    size="small"
                    loadingPosition="start"
                    loading={loading}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            </Box>
          </form>
        ) : (
          <Box onScroll={handleScrollEvent} style={{ height: props.height ? props.height : "" }} className="call-contracts-list-box">
            {!contactList.isLoading && contactList?.contacts?.length === 0 && !isPhoneNumber && <DataNotFound />}
            {!contactList.isLoading && contactList?.contacts?.length === 0 && isPhoneNumber && (
              <Box
                onClick={(e) => {
                  if (isTransfer) {
                    if (activeButton === "warm_transfer") {
                      makeCallForTransferWarmCall({ phone: searchText });
                    } else if (activeButton === "cold_transfer" || activeButton === "vm_transfer") {
                      makeCallForTransferColdCall({ phone: searchText });
                    }
                  } else {
                    props.handleCloseCall();
                    dialNumber(searchText);
                    setSelectedOutgoingUser({ phone: searchText });
                  }
                }}
              >
                <Box className={classes.outsideCallBox}>
                  <span>
                    {" "}
                    Outside
                    <br />
                    <b>Call</b>
                  </span>
                </Box>
                <Box sx={{ fontSize: "13px", fontWeight: "500" }}>
                  <NumberFormat format="###-###-####" displayType="text" value={searchText} />
                </Box>
              </Box>
            )}
            {(!contactList.isLoading || contactList.pageNumber > 1) &&
              contactList?.contacts?.map((item, index) => (
                <div key={index} className="call-contracts-list-box-item">
                  <Stack
                    onClick={(e) => {
                      if (isTransfer) {
                        if (activeButton === "warm_transfer") {
                          makeCallForTransferWarmCall(item);
                        } else if (activeButton === "cold_transfer" || activeButton === "vm_transfer") {
                          makeCallForTransferColdCall(item);
                        }
                      } else {
                        props.handleCloseCall();
                        let firstname = item.first_name ? item.first_name : item.firstName ? item.firstName : "No";
                        let lastname = item.last_name ? item.last_name : item.lastName ? item.lastName : "Name";
                        dialNumber(item.phone, item.id, firstname + " " + lastname);
                        setSelectedOutgoingUser(item);
                      }
                    }}
                    spacing={1}
                  >
                    <Avatar
                      sx={{ textTransform: "uppercase", fontSize: "22px" }}
                      alt={item.full_name}
                      variant="square"
                      src={item.profile_images?.profile_img || item.profile_images?.profile_img_thumbnail}
                    >
                      {`${
                        item.first_name
                          ? item.first_name?.slice(0, 1)
                          : item.firstName
                          ? item.firstName?.slice(0, 1)
                          : item.full_name.split(" ")[0]?.slice(0, 1)
                      }${
                        item.last_name
                          ? item.first_name?.slice(0, 1)
                          : item.lastName
                          ? item.lastName?.slice(0, 1)
                          : item.full_name.split(" ")[1]?.slice(0, 1)
                      }`}
                    </Avatar>
                    <Typography variant="body2" gutterBottom>
                      {item?.full_name
                        ? item?.full_name
                        : item.firstName
                        ? item.firstName + " " + item.lastName
                        : item.first_name
                        ? item.first_name + " " + item.last_name
                        : ""}
                    </Typography>
                  </Stack>
                </div>
              ))}
            {contactList.isLimitReached && <div className="no-more-exists">No more Exist</div>}
            {contactList.isLoading && <ContactsSkelton />}
          </Box>
        )}
      </center>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </div>
  );
}
export default withStyles(styleSheet, { name: "ContactsStyle" })(Contacts);
