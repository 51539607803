import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleAddComment } from "./index";
export const addCommentOnLockboxDetail = createAsyncThunk("agentInventory/addCommentOnLockboxDetail", async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(handleAddComment(data.commentData));
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/inventory/comment/${data.InventoryId}`,
      { comment: data.comment },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
