import React from "react";
import { Routes, Route } from "react-router-dom";
import UsersListing from "./usersListing";
import UserDetail from "./userDetail";
function Users(props) {
  return (
    <Routes>
      <Route path="/" element={<UsersListing />} />
      <Route path="/lenders" element={<UsersListing isLenders={true} />} />
      <Route path="/:id/*" element={<UserDetail />} />
    </Routes>
  );
}
export default Users;
