import { createSlice } from "@reduxjs/toolkit";
import { AddTrainingIssue } from "./addTrainingIssue";
import { getTrainingIssueDetail } from "./getTrainingIssueDetail";
import { getTrainingIssue } from "./getTrainingIssues";
import { updateTrainingIssue } from "./updateTrainingIssue";
import { AddTrainingAppointment } from "./addAppointment";

const initialState = {
  addTrainingIssue: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  trainingIssues: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  trainingIssueDetail: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateIssue: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  addAppointment: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },

};
const AgentTrainingIssues = createSlice({
  name: "AgentTrainingIssues",
  initialState,
  extraReducers: {
    [AddTrainingIssue.pending]: (state) => {
      state.addTrainingIssue.isLoading = true;
      state.addTrainingIssue.errMsg = null;
      state.addTrainingIssue.success = false;
    },
    [AddTrainingIssue.fulfilled]: (state, action) => {
      let newList = state.trainingIssues?.data?.trainingIssues
        ? [...state.trainingIssues?.data?.trainingIssues]
        : [];
      newList.push(action.payload);
      state.trainingIssues.data.trainingIssues = newList;
      state.addTrainingIssue.success = true;
      state.addTrainingIssue.isLoading = false;
      state.addTrainingIssue.data = action.payload;
    },
    [AddTrainingIssue.rejected]: (state, action) => {
      state.addTrainingIssue.isLoading = false;
      state.addTrainingIssue.success = false;
      state.addTrainingIssue.errMsg = action.payload;
    },
   
    [getTrainingIssue.pending]: (state) => {
      state.trainingIssues.isLoading = true;
      state.trainingIssues.errMsg = null;
      state.trainingIssues.success = false;
    },
    [getTrainingIssue.fulfilled]: (state, action) => {
      state.trainingIssues.isLoading = false;
      state.trainingIssues.success = true;
      state.trainingIssues.data = action.payload;
    },
    [getTrainingIssue.rejected]: (state, action) => {
      state.trainingIssues.isLoading = false;
      state.trainingIssues.success = false;
      state.trainingIssues.errMsg = action.payload;
    },
    [getTrainingIssueDetail.pending]: (state) => {
      state.trainingIssueDetail.isLoading = true;
      state.trainingIssueDetail.errMsg = null;
      state.trainingIssueDetail.success = false;
      state.updateIssue.success = false;
      state.addTrainingIssue.success = false;
    },
    [getTrainingIssueDetail.fulfilled]: (state, action) => {
      state.trainingIssueDetail.isLoading = false;
      state.trainingIssueDetail.success = true;
      state.trainingIssueDetail.data = action.payload;
    },
    [getTrainingIssueDetail.rejected]: (state, action) => {
      state.trainingIssueDetail.isLoading = false;
      state.trainingIssueDetail.success = false;
      state.trainingIssueDetail.errMsg = action.payload;
    },

    [updateTrainingIssue.pending]: (state) => {
      state.updateIssue.isLoading = true;
      state.updateIssue.errMsg = null;
      state.updateIssue.success = false;
    },
    [updateTrainingIssue.fulfilled]: (state, action) => {
      let newList = state.trainingIssues?.data?.trainingIssues
        ? [...state.trainingIssues?.data?.trainingIssues]
        : [];
      let newListIndex = newList.findIndex(
        (item) => item.id === action.payload.id
      );
      newList.splice(newListIndex, 1, action.payload);
      state.trainingIssues.data.trainingIssues = newList;
      state.updateIssue.isLoading = false;
      state.updateIssue.success = true;
      state.updateIssue.data = action.payload;
    },
    [updateTrainingIssue.rejected]: (state, action) => {
      state.updateIssue.isLoading = false;
      state.updateIssue.success = false;
      state.updateIssue.errMsg = action.payload;
    },

    [AddTrainingAppointment.pending]: (state) => {
      state.addAppointment.isLoading = true;
      state.addAppointment.errMsg = null;
      state.addAppointment.success = false;
    },
    [AddTrainingAppointment.fulfilled]: (state, action) => {
      state.addAppointment.isLoading = false;
      state.addAppointment.success = true;
      state.addAppointment.data = action.payload;
    },
    [AddTrainingAppointment.rejected]: (state, action) => {
      state.addAppointment.isLoading = false;
      state.addAppointment.success = false;
      state.addAppointment.errMsg = action.payload;
    },
  },
});
export default AgentTrainingIssues.reducer;
export * from "./addTrainingIssue";
export * from "./getTrainingIssues";
export * from "./getTrainingIssueDetail";
export * from "./addComment";
export * from "./updateTrainingIssue";
export * from "./addAppointment";
export * from "./deleteAppointment";
