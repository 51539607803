export const styleSheet = (theme) => ({
  saveButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  paymentModalHeading: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  modalHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalHeadingDes: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 6px  !important",
  },
  activityList: {
    height: "600px",
    overflow: "auto",
  },
  activityListItem: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    gap: "0px 6px  !important",
    borderBottom: "1px solid lightgrey  !important",
    padding: "6px !important",
    flexDirection: "row !important",
  },
  activityListItemTime: {
    width: "90px !important",
    color: "rgb(147, 165, 178) !important",
  },
  activityListItemDes: {
    flex: 1,
    "& .MuiTypography-root": {
      fontFamily: "AvenirNext  !important",
    },
    marginBottom: "-5px !important",
  },
  activityListItemOuter: {
    padding: "20px  !important",
  },
  activityListItemOuterHeading: {
    fontFamily: "AvenirNext  !important",
    fontSize: "18px !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
  activityListItemImage: {
    height: "47px !important",
    width: "80px !important",
    "& img": {
      cursor: "pointer",
      width: "100% !important",
      height: "100% !important",
      objectFit: "cover !important",
      borderRadius: "5px !important",
    },
  },
});
