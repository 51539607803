import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const updateLeadAppointmentAPI = createAsyncThunk(
  "lead/updateLeadAppointmentAPI",
  async (data, thunkAPI) => {
    const { id, schema,handleSuccess,handleError } = data;
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/leads/appointments/${id}`,
        schema,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Context-Type": "application/json",
          },
        }
      );
      if(handleSuccess){
        handleSuccess()
      }
      return resp?.data;
    } catch (error) {
      if (error.response) {
        if(handleError){
          handleError(error.response.data.message)
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if(handleError){
          handleError(error.message)
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
