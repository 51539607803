import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItemText,
  Paper,
  Typography,
  Chip,
  IconButton,
  Stack,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import flowerIcon from "../../../../assets/images/agents/flowerIcon.png";
import flowerIconWhite from "../../../../assets/images/agents/flowerIconWhite.png";
import AttachMLSNumber from "../../modals/attachMLS";
import UpdateListing from "../../modals/updateListing";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AvatarCustom from "../../../../components/uiElements/avatar/index";
import { ListingColors } from "../../../../utils/listingStatusColor";
const ListingHeaderCard = (props) => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  let { ListingData, classes } = props;
  const [imageFlower, setImageFlower] = useState(flowerIcon);
  const [mlsData, setMlsData] = useState({});

  return (
    <Paper sx={{ backgroundColor: "white" }} className={classes.pageRoot}>
      <Grid container direction="row">
        <Grid
          md={8}
          lg={9}
          xs={12}
          className={classes.TransactionUserInfoLeftPanel}
        >
          <Avatar
            variant="rounded"
            sx={{ "& img": { width: "auto" } }}
            src={
              mlsData.property_img
                ? mlsData?.property_img
                : ListingData.property_img
                ? ListingData.property_img
                : process.env.REACT_APP_NO_PROPERTY_IMAGE
            }
          />
          <Box>
            <Typography classes={{ root: classes.TransactionUserInfoLeftName }}>
              {ListingData.street}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                classes={{ root: classes.userInfoDetailPhone }}
                variant="subtitle1"
              >
                {ListingData.city}, {ListingData.state} {ListingData.zipcode}
              </Typography>
            </Box>
            <Box sx={{ mt: "10px" }}>
              <Chip
                label={ListingData.status}
                size="small"
                color="success"
                sx={{
                  backgroundColor: ListingColors[ListingData.status] || "black",
                  color: "white",
                  textTransform: "capitalize",
                  minWidth: "100px",
                }}
              />
            </Box>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{ mt: "30px" }}
            >
              <Grid item>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      ${new Intl.NumberFormat().format(ListingData.price)}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Price
                    </Typography>
                  }
                />
              </Grid>
              <Grid item>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.bedrooms || mlsData.bedrooms || "0"}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Bedrooms
                    </Typography>
                  }
                />
              </Grid>
              <Grid item>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.baths || mlsData.baths || "0"}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Bathrooms
                    </Typography>
                  }
                />
              </Grid>
              <Grid item>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.sq_feet || mlsData.sq_feet || "0"}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Sq. Feet
                    </Typography>
                  }
                />
              </Grid>
              <Grid item>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.mls_id
                        ? ListingData.mls_id
                        : ListingData.fmls_number
                        ? ListingData.fmls_number
                        : mlsData
                        ? mlsData.mlsId
                        : ""}
                      {(ListingData.mls_id ||
                        ListingData.fmls_number ||
                        mlsData.mlsId) && (
                        <IconButton
                          onClick={() => setOpen(true)}
                          className={classes.editMLlIcon}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                      )}

                      {ListingData.mls_id ||
                      ListingData.fmls_number ||
                      mlsData.mlsId ? null : (
                        <Button
                          variant="outlined"
                          onClick={() => setOpen(true)}
                          size="small"
                          color="inherit"
                        >
                          Attach
                        </Button>
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      MLS Number
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid md={4} lg={3} xs={12} className={classes.userInfoRightPanel}>
          <Stack
            sx={{ mb: "15px" }}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                marginRight: "22px",
                fontSize: "14px",
              }}
            >
              Listed By
            </Typography>
            <Typography
              sx={{ fontSize: "14px", textAlign: "left", minWidth: "10ch" }}
            >
              {ListingData.agent?.first_name} <br />{" "}
              {ListingData.agent?.last_name}{" "}
            </Typography>
            {ListingData?.agent ? (
              <AvatarCustom
                sx={{ height: "45px", width: "45px", mr: "10px", ml: "15px" }}
                variant="rounded"
                src={ListingData?.agent?.profile_images?.profile_img_thumbnail}
              >
                {`${ListingData?.agent.first_name?.slice(
                  0,
                  1
                )}${ListingData?.agent.last_name?.slice(0, 1)}`}
              </AvatarCustom>
            ) : null}
          </Stack>
          <Button
            onClick={() => window.open("tel:" + ListingData?.agent?.phone)}
            variant="outlined"
          >
            <PhoneIcon />
          </Button>
          {ListingData?.agent?.admin_channel_id ? (
            <Button
              onMouseEnter={() => {
                setImageFlower(flowerIconWhite);
              }}
              onMouseLeave={() => {
                setImageFlower(flowerIcon);
              }}
              variant="outlined"
              onClick={() =>
                window.open(
                  `slack://channel?team=${ListingData?.agent?.slack_team_id}&id=${ListingData?.agent?.admin_channel_id}`
                )
              }
            >
              <img
                style={{ width: "24px" }}
                src={imageFlower}
                alt="flower icon"
              />
            </Button>
          ) : null}
          <Button
            onClick={() => window.open("mailto:" + ListingData?.agent?.email)}
            variant="outlined"
          >
            <AlternateEmailRoundedIcon />
          </Button>
          <br />{" "}
          {/* <Box className={classes.editableLockArea}>
            <IconButton className={classes.editableLock} onClick={() => setIsEditAble(!isEditAble)} aria-label="locked">
              {isEditAble ? <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} /> : <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />}
            </IconButton>
          </Box> */}
        </Grid>
      </Grid>
      {open && (
        <AttachMLSNumber
          // isUpdate={ListingData.fmls_number || mlsData.mlsId}
          ListingData={ListingData}
          setMlsData={setMlsData}
          open={open}
          id={ListingData.id}
          setOpenUpdate={setOpenUpdate}
          setOpen={setOpen}
        />
      )}
      <UpdateListing
        open={openUpdate}
        id={ListingData.id}
        setOpen={setOpenUpdate}
      />
    </Paper>
  );
};

export default withStyles(styleSheet, { name: "ListingHeaderCardStyle" })(
  ListingHeaderCard
);
