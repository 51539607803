import { withStyles } from "@mui/styles";
import React, { useEffect, useState, useRef } from "react";
import { styleSheet } from "./style";
import {
  CallTimeline,
  EmailTimeline,
  MessageTimeline,
  NoteTimeline,
  AssignmentTimeline,
  ActivityTimeline,
} from "./timelineItems";
import { Timeline } from "@mui/lab";
import { theme } from "../../../../config/theme";
import { useDispatch, useSelector } from "react-redux";
import { getTimelinesAgentsRecruitment } from "../../../../redux/agents/agentRecruitment/getTimelinesAgentsRecruitment";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Button from "../../../../components/uiElements/button";
import { useParams } from "react-router-dom";
import { updateTimeLineFavorite } from "../../../../redux/agents/agentRecruitment/updateTimeLineFavorite";
const AgentRecruitingTimeline = ({ usersList, classes, agentProfile }) => {
  const dispatch = useDispatch();
  const routeId = useParams();
  const [num, setNum] = useState(10);
  const myTimer = useRef(null);

  const { data, count } = useSelector((item) => item.timelines);
  const { currentTab } = useSelector((item) => item.agentProfile);
  const fetchFunc = () => {
    const queries = `?sortBy=createdAt&sortOrder=desc&pageNumber=1&pageSize=${num}&agentRecruitmentId=${routeId?.id}`;
    dispatch(getTimelinesAgentsRecruitment(queries));
  };

  useEffect(() => {
    fetchFunc();
  }, [currentTab?.data, currentTab, num]);

  const startTimer = () => {
    myTimer.current = setInterval(() => {
      fetchFunc();
    }, 1000 * 5);
  };

  useEffect(() => {
    startTimer();
    return () => myTimer.current != null && clearInterval(myTimer.current);
  }, [num]);

  const loadMore = () => {
    setNum((num) => num + 5);
  };
  const showLess = () => {
    setNum(10);
  };
  const handleUpdateFavorite = (timeline_id, flag) => {
    dispatch(
      updateTimeLineFavorite({
        is_starred: flag,
        timeline_id: timeline_id,
      })
    );
  };
  return (
    <>
      <Timeline position="right" sx={{ paddingLeft: "25px" }}>
        {data && data.length ? (
          data.map((item, index) => {
            switch (item.type) {
              case "email":
                return (
                  <Box key={index}>
                    <EmailTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.sky}
                      agentProfile={agentProfile}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                    />
                  </Box>
                );
              case "email_thread":
                return (
                  <Box key={index}>
                    <EmailTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.sky}
                      agentProfile={agentProfile}
                      isEmailThread
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                    />
                  </Box>
                );
              case "text_message":
                return (
                  <Box key={index}>
                    <MessageTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.purple}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                    />
                  </Box>
                );
              case "note":
                return (
                  <Box key={index}>
                    <NoteTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.yellow}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                    />
                  </Box>
                );
              case "call":
                return (
                  <Box key={index}>
                    <CallTimeline
                      item={item}
                      usersList={usersList}
                      classes={classes}
                      short={index === 0 ? true : false}
                      color={theme.palette.timelineDots.green}
                      handleUpdateFavorite={handleUpdateFavorite}
                      key={item.id}
                    />
                  </Box>
                );
              case "assignment":
                return (
                  <AssignmentTimeline
                    item={item}
                    usersList={usersList}
                    classes={classes}
                    color={"rgb(109, 130, 145)"}
                    handleUpdateFavorite={handleUpdateFavorite}
                    key={item.id}
                    short={index === 0 ? true : false}
                  />
                );
              case "activity":
                return (
                  <ActivityTimeline
                    item={item}
                    usersList={usersList}
                    classes={classes}
                    color={"rgb(255, 173, 129)"}
                    handleUpdateFavorite={handleUpdateFavorite}
                    key={item.id}
                    short={index === 0 ? true : false}
                  />
                );
              default:
                return null;
            }
          })
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              component="p"
              variant="subtitle1"
              sx={{ color: "#bdbdbd" }}
            >
              No timeline history
            </Typography>
          </Box>
        )}
        {data?.length ? (
          data.length >= 10 && count > num ? (
            <Button onClick={loadMore}>load more</Button>
          ) : data.length >= 10 && count < num ? (
            <Button onClick={showLess}>show less</Button>
          ) : null
        ) : null}
      </Timeline>
    </>
  );
};
export default withStyles(styleSheet, { name: "agentRecruitingTimeline" })(
  AgentRecruitingTimeline
);
