import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  ListItemButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Stack,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import Avatar from "../../../../../components/uiElements/avatar/index";
import shield1 from "../../../../../assets/images/shield1.png";
import shield2 from "../../../../../assets/images/shield2.png";
import shield3 from "../../../../../assets/images/shield3.png";
import shield4 from "../../../../../assets/images/shield4.png";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import StarIcon from "@mui/icons-material/Star";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getPointsPreviousRanking } from "../../../../../redux/agents/agentRosterDetail/points";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
function LeaderBoardStats(props) {
  let { classes } = props;
  const [viewItem, setViewItem] = useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const previousRanking = useSelector(
    (state) => state.agentRoster.points.previousRanking
  );
  useEffect(() => {
    dispatch(getPointsPreviousRanking());
  }, []);

  return (
    <Box>
      {previousRanking.isLoading ? (
        <Skeleton
          sx={{ height: "72px" }}
          variant="rounded"
          className={classes.userInfoCard}
        ></Skeleton>
      ) : (
        <Card variant="rounded" evaluation={0} className={classes.userInfoCard}>
          <CardHeader
            classes={{
              title: classes.userInfoCardHeading,
              subheader: classes.userInfoCardDes,
            }}
            avatar={
              <Avatar
                src={previousRanking.data?.user?.profile_images?.profile_img}
                sx={{ width: "40px", height: "40px", fontSize: "16px" }}
                aria-label="profile"
              >
                {`${previousRanking.data?.user?.full_name?.slice(0, 1) || ""}${
                  previousRanking.data?.user?.full_name
                    ?.split(" ")[1]
                    ?.slice(0, 1) || ""
                }`}
              </Avatar>
            }
            action={
              <Typography className={classes.userInfoCardDate}>
                {previousRanking.data?.user?.join_date
                  ? ` Joined ${moment(
                      previousRanking.data?.user?.join_date
                    ).format("MMM 'YY")}`
                  : ""}{" "}
              </Typography>
            }
            title={previousRanking.data?.user?.full_name}
            subheader={previousRanking.data?.user?.license_type}
          />
        </Card>
      )}

      <Card
        variant="rounded"
        evaluation={0}
        className={classes.currentStatCard}
      >
        <CardHeader
          className={classes.currentStatCardHeader}
          classes={{
            title: classes.currentStatCardHeading,
          }}
          title="Current Stats"
        />
        <CardContent className={classes.currentStatCardContent}>
          <Box className={classes.currentStatCardContentArea}>
            <Box>
              {previousRanking.isLoading ? (
                <Skeleton
                  sx={{ width: "172px !important", height: "115px !important" }}
                  animation="wave"
                ></Skeleton>
              ) : (
                <Card className={classes.pointsTopLeftCard1}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ lineHeight: "30px" }}
                    spacing={4}
                  >
                    <StarIcon fontSize="small" sx={{ mr: "7px" }} />{" "}
                    {previousRanking.data?.currentMonthrank?.point}
                  </Stack>
                  <Typography sx={{ fontSize: "12px" }}>
                    Points Earned This Month
                  </Typography>
                </Card>
              )}
              <Typography
                className={classes.viewHistoryPoints}
                // onClick={() => navigate(props.isUser ? `/users/${id}/points` : `/agent_roster/${id}/points`)}
                onClick={() => navigate(`/dashboard/points`)}
              >
                View Full Points History{" "}
                <EastRoundedIcon fontSize="small" sx={{ ml: "6px" }} />{" "}
              </Typography>
            </Box>
            <Box className={classes.leaderBoardCurrentRank}>
              <Typography className={classes.leaderBoardCurrentRankText}>
                Current Month’s Rank
              </Typography>

              <CustomTooltip
                title="Check back tomorrow for updated rankings."
                placement="top"
              >
                <Box className={classes.leaderBoardRightShields}>
                  {previousRanking.isLoading ? (
                    <Skeleton
                      sx={{
                        width: "46px !important",
                        height: "100px !important",
                        clipPath:
                          "polygon(0% 0%, 100% 0, 100% 62%, 50% 100%, 0 61%)",
                      }}
                      animation="wave"
                    ></Skeleton>
                  ) : (
                    <>
                      <Box className={classes.leaderBoardRightShieldValue}>
                        {previousRanking.data?.currentMonthrank?.point > 0
                          ? previousRanking.data?.currentMonthrank?.rank || "-"
                          : "-"}
                      </Box>
                      <img
                        src={
                          previousRanking.data?.currentMonthrank?.point === 0
                            ? shield1
                            : previousRanking.data?.currentMonthrank?.rank === 1
                            ? shield3
                            : previousRanking.data?.currentMonthrank?.rank === 2
                            ? shield4
                            : previousRanking.data?.currentMonthrank?.rank === 3
                            ? shield2
                            : shield1
                        }
                        alt="point shield"
                      />
                    </>
                  )}
                </Box>
              </CustomTooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card
        variant="rounded"
        evaluation={0}
        className={classes.previousRankingCard}
      >
        <CardHeader
          className={classes.currentStatCardHeader}
          classes={{
            title: classes.currentStatCardHeading,
          }}
          title="Previous Rankings"
          action={
            <Stack alignItems={"center"} direction="row" spacing={0}>
              {" "}
              <Typography sx={{ fontSize: "13px", mt: "4px", mr: "4px" }}>
                {new Date().getFullYear()}
              </Typography>{" "}
              <ArrowDropDownIcon />{" "}
            </Stack>
          }
        />
        <CardContent
          className={classes.currentStatCardContent}
          sx={{ padding: "0px" }}
        >
          <List disablePadding dense>
            {previousRanking?.isLoading
              ? Array.from(Array(10).keys()).map(() => (
                  <ListItem disablePadding>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1.3rem", width: "100%" }}
                    />
                  </ListItem>
                ))
              : ""}
            {!previousRanking?.isLoading &&
            previousRanking.data?.monthlyRanking?.length
              ? previousRanking.data?.monthlyRanking.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{ display: item.rank && item.point > 0 ? "" : "none" }}
                    disablePadding
                    divider
                  >
                    <ListItemButton disablePadding>
                      <ListItemText
                        primary={moment(item.month, "YYYY-MM").format(
                          "MMMM YYYY"
                        )}
                      />
                      <ListItemSecondaryAction>
                        {item.rank && item.point > 0 ? (
                          <Stack
                            alignItems={"center"}
                            direction="row"
                            spacing={0}
                          >
                            {" "}
                            <Typography sx={{ fontSize: "12px", mr: "4px" }}>
                              {item.rank === 1
                                ? "1st Place"
                                : item.rank === 2
                                ? "2nd Place"
                                : item.rank === 3
                                ? "3rd Place"
                                : item.rank
                                ? `${item.rank}th Place`
                                : ""}{" "}
                            </Typography>{" "}
                            <CircleIcon
                              sx={{
                                fontSize: "12px",
                                color:
                                  item.rank === 1
                                    ? "#F2CE4E"
                                    : item.rank === 2
                                    ? "#C4C4C4"
                                    : item.rank === 3
                                    ? "#C67225"
                                    : `linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, #000000, #000000), #000000`,
                              }}
                            />{" "}
                          </Stack>
                        ) : null}
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  </ListItem>
                ))
              : null}
          </List>
          <Box className={classes.previousIconViewArea}>
            {previousRanking.data?.monthlyRanking?.length > 5 &&
            previousRanking.data?.monthlyRanking?.length > viewItem ? (
              <Button
                onClick={() => setViewItem(12)}
                variant="text"
                className={classes.previousIconViewButton}
                endIcon={<KeyboardArrowDownIcon />}
              >
                View More
              </Button>
            ) : null}
            {previousRanking.data?.monthlyRanking?.length > 5 &&
            viewItem === 12 ? (
              <Button
                onClick={() => setViewItem(5)}
                variant="text"
                className={classes.previousIconViewButton}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Show Less
              </Button>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "LeaderBoardStatsStyle",
})(LeaderBoardStats);
