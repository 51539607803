import { Chip } from "@mui/material";

const COLORS = {
    'Sunday': '#248EA6',
    'Monday': '#25C7D9',
    'Tuesday': '#F2D338',
    'Wednesday': '#F2762E',
    'Thursday': '#F23030',
    'Friday': '#F20587',
    'Saturday': '#45C4B0',
};

function DayChip({ value }) {
    return ( 
        <Chip 
            label={value}
            sx={{
                backgroundColor: `${COLORS[value]}`,
                color: '#fff'
            }}
        />
    );
}

export default DayChip;