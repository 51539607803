import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const updateAgentTab = createAsyncThunk("AgentTabs/updateAgentTab", async (data, thunkAPI) => {
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}api/users/tabs/${data.id}`, data.formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { ...resp.data, updateId: data.id, amount: data.amount, debit: data.debit, uploadedFiles: data.uploadedFiles };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
