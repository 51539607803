import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import { deleteListing } from "../../../redux/listings/listingDetail/deleteListing";
import { useNavigate } from "react-router-dom";
function DeleteLIsting(props) {
  let { open, setOpen, classes, id } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deletedListing = useSelector((state) => state.listings.listingDetail.deletedListing);

  useEffect(() => {
    if (deletedListing.success && !deletedListing.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully terminated  this contract",
        errorType: "success",
        isOpen: true,
      });

      setOpen(false);
      navigate(`/listings/active`);
    } else if (!deletedListing.isLoading && deletedListing.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(deletedListing.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deletedListing.success]);

  const handleDeleteListing = () => {
    dispatch(deleteListing({ id: id }));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-title">
          Delete Listing
        </DialogTitle>
        <DialogContent sx={{ width: "400px" }}>
          Are you sure you want to delete this listing?
          <br />
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleDeleteListing()}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={deletedListing.isLoading}
            loadingPosition="start"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "DeleteLIstingStyle" })(DeleteLIsting);
