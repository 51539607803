import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const markAsLost = createAsyncThunk(
  "InventoryList/markAsLost",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/inventory/mark-as-lost/${data.inventory_occupied_id}?type=${data.type}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.handleMarkSuccess) {
        data.handleMarkSuccess();
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        data.handleMarkError(
          error.response.data.errorMessage || error.response.data.message
        );
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        data.handleMarkError(error.message);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
