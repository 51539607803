import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./container/login";
import PrivateRoutes from "./components/authRoutes";
import MainDashBoard from "./container/mainDashboard";
import KnowledgeBase from "./container/knowledge";
import ManageLibraryMain from "./container/knowledge/manageLibrary";
import Docs from "./container/docs";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import { logoutUser } from "./redux/auth";
import { getAdminDetail } from "./redux/auth";
import useEffectOnce from "./customHooks/useEffectOnce";
import ComingSoon from "./components/comingSoon";
import NotFound from "./components/notFound";
import "./App.css";
import InventoryPage from "./container/inventory";
import PreferencePage from "./container/preferences";
import axios from "axios";
import Project from "./container/projects";
import AgentProject from "./container/projects/projects/agent/agentProjects";
import AgentRoster from "./container/agentRoster";
import TemplatesPage from "./container/templates";
import { getAllUsersTeamsListToUseInAllSections } from "./redux/users/getAllUsersListToUseInAllSections";
import Transactions from "./container/Transactions";
import Listings from "./container/listings";
import Finance from "./container/FinanceV2";
import Users from "./container/users";
import NewSearchProperty from "./container/listings/listingDatasetDetail/newSearch";
import Tools from "./container/tools";
import { toast } from "react-toastify";
import Leads from "./container/leads";
import Vendors from "./container/vendors";
import { LicenseInfo } from "@mui/x-license-pro";
import { requestForToken } from "./firebase";
import { postData } from "./utils/postData";
import Teams from "./container/teams";
import PhoneConfiguration from "./container/phoneConfiguration";
import EventsAndAlerts from "./container/eventsAndAlerts";
import DashboardPoints from "./container/mainDashboard/points";
import DashboardLeaderBoards from "./container/mainDashboard/leaderBoard";
import AgentRequestTicketManagement from "./container/agentRequestTicketManagement";
import LeadRoutingPage from "./container/leads/leadRouting/index";
import SavedSearches from "./container/leads/savedSearches";
import MarketingCenterPage from "./container/marketingCenter/index";
import CmaReport from "./container/cmaReport";
import SendEmailAs from "./container/sendEmails";
import Ponds from "./container/ponds";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY);

function App() {
  const dispatch = useDispatch();
  const [isTokenFound, setTokenFound] = useState(false);
  const adminAuth = useSelector((state) => state.adminAuth);

  const handleGetAndSetToken = async () => {
    let data;
    data = await requestForToken(setTokenFound);
    if (data) {
      if (localStorage.webpush_token !== data) {
        postData(
          `${process.env.REACT_APP_BASE_URL}api/users/update-webpush-token`,
          { webpush_token: data }
        );
      }
      localStorage.setItem("webpush_token", data);
    }
  };

  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return (
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };

  useEffect(() => {
    if (!isMobile.any()) {
      handleGetAndSetToken();
    }
  }, [setTokenFound]);

  useEffectOnce(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      const decode = jwt_decode(localStorage.token);
      dispatch(getAdminDetail());
      dispatch(getAllUsersTeamsListToUseInAllSections());
      const currentTime = Date.now() / 1000;
      if (decode.exp < currentTime) {
        dispatch(logoutUser());
        window.location.reload("/");
      }
    }
  });

  axios.interceptors.response.use(
    (response) => {
      let currentVersion = localStorage.getItem("version");
      if (currentVersion && currentVersion !== response.headers.version) {
        localStorage.setItem("version", response.headers.version);
        window.location.reload();
      }
      return response;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        if (
          (error?.response?.status === 401 ||
            error?.response?.status === 440) &&
          localStorage.token
        ) {
          dispatch(logoutUser());
          console.log(error?.response, "error?.response");
          toast.error(error?.response?.data?.message, { theme: "colored" });
          // setTimeout(() => {
          //    window.location.reload();
          // }, 5000);
          reject(error);
          return;
        }
        reject(error);
      });
    }
  );

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {adminAuth?.adminDetail?.role?.role_permissions?.find(
            (it) => it.permission.slug == "projects" && !it.mobile_only
          ) && (
            <Route
              path="/projects"
              element={
                <PrivateRoutes>
                  <Project />
                </PrivateRoutes>
              }
            />
          )}
          <Route
            path="main/dashboard"
            element={
              <PrivateRoutes>
                <MainDashBoard />
              </PrivateRoutes>
            }
          />
          <Route
            path="dashboard/points"
            element={
              <PrivateRoutes>
                <DashboardPoints />
              </PrivateRoutes>
            }
          />
          <Route
            path="dashboard/leaderboard"
            element={
              <PrivateRoutes>
                <DashboardLeaderBoards />
              </PrivateRoutes>
            }
          />
          <Route
            path="/docs/*"
            element={
              <PrivateRoutes docs={true}>
                <Docs />
              </PrivateRoutes>
            }
          />
          <Route
            path="/agent_roster/*"
            element={
              <PrivateRoutes>
                <AgentRoster />
              </PrivateRoutes>
            }
          />
          <Route
            path="/users/*"
            element={
              <PrivateRoutes>
                <Users />
              </PrivateRoutes>
            }
          />
          <Route
            path="/send_email_as"
            element={
              <PrivateRoutes>
                <SendEmailAs />
              </PrivateRoutes>
            }
          />

          <Route
            path="/lead_routing/*"
            element={
              <PrivateRoutes>
                <LeadRoutingPage />
              </PrivateRoutes>
            }
          />
          <Route
            path="/teams"
            element={
              <PrivateRoutes>
                <Teams />
              </PrivateRoutes>
            }
          />
          <Route
            path="/agent_tickets/*"
            element={
              <PrivateRoutes>
                <AgentRequestTicketManagement />
              </PrivateRoutes>
            }
          />
          <Route
            path="/marketing_center/*"
            element={
              <PrivateRoutes>
                <MarketingCenterPage />
              </PrivateRoutes>
            }
          />
          <Route
            path="/search"
            element={
              <PrivateRoutes>
                <NewSearchProperty isShowSubHeader />
              </PrivateRoutes>
            }
          />
          <Route
            path="/inventory/*"
            element={
              <PrivateRoutes>
                <InventoryPage />
              </PrivateRoutes>
            }
          />

          <Route
            path="/agent-projects"
            element={
              <PrivateRoutes>
                <AgentProject />
              </PrivateRoutes>
            }
          />
          <Route
            path="/templates/*"
            element={
              <PrivateRoutes>
                <TemplatesPage />
              </PrivateRoutes>
            }
          />
          <Route
            path="/vendors/*"
            element={
              <PrivateRoutes>
                <Vendors />
              </PrivateRoutes>
            }
          />
          <Route
            path="/ponds"
            element={
              <PrivateRoutes>
                <Ponds />
              </PrivateRoutes>
            }
          />
          <Route
            path="/agent-projects/details/:projectID"
            element={
              <PrivateRoutes>
                <AgentProject />
              </PrivateRoutes>
            }
          />
          <Route
            path="/transaction/*"
            element={
              <PrivateRoutes>
                <Transactions />
              </PrivateRoutes>
            }
          />
          <Route
            path="/listings/*"
            element={
              <PrivateRoutes>
                <Listings />
              </PrivateRoutes>
            }
          />

          <Route
            path="/common"
            element={
              <PrivateRoutes>
                <ComingSoon />
              </PrivateRoutes>
            }
          />
          <Route
            path="/knowledge-base/manage-library/*"
            element={
              <PrivateRoutes>
                <ManageLibraryMain />
              </PrivateRoutes>
            }
          />
          <Route
            path="/knowledge-base/*"
            element={
              <PrivateRoutes>
                <KnowledgeBase />
              </PrivateRoutes>
            }
          />

          <Route
            path="/tools/*"
            element={
              <PrivateRoutes>
                <Tools />
              </PrivateRoutes>
            }
          />
          <Route
            path="/events_and_alerts/*"
            element={
              <PrivateRoutes>
                <EventsAndAlerts />
              </PrivateRoutes>
            }
          />
          <Route
            path="/phone-configuration/*"
            element={
              <PrivateRoutes>
                <PhoneConfiguration />
              </PrivateRoutes>
            }
          />

          <Route
            path="/leads/*"
            element={
              <PrivateRoutes>
                <Leads />
              </PrivateRoutes>
            }
          />
          <Route
            path="/preferences"
            element={
              <PrivateRoutes>
                <PreferencePage />
              </PrivateRoutes>
            }
          />
          <Route
            path="/finance/*"
            element={
              <PrivateRoutes>
                <Finance />
              </PrivateRoutes>
            }
          />
          <Route
            exact
            path="/leads/saved-searches"
            element={<SavedSearches />}
          />
          <Route exact path="/view-cma/:id" element={<CmaReport />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route
            path="*"
            element={
              <PrivateRoutes type="404">
                <NotFound />
              </PrivateRoutes>
            }
          />
          <Route
            exact
            path="/"
            element={<Navigate replace to="main/dashboard" />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
