import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import { useSelector, useDispatch } from "react-redux";
import { NumberFormat } from "../../../../../utils/numberFormat";
import { formatAddress } from "../../../../../utils/propertyData.js";
import PropertyDetail from "./propertyDetail";
import { getListingDetail } from "../../../../../redux/tools/cmas.js";

const columns = [
  {
    title: "",
    field: "UnparsedAddress",
  },
  {
    title: "STATUS",
    field: "MlsStatus",
  },
  { title: "PRICE", field: "ListPrice" },
  { title: "ADJ PRICE", field: "OriginalListPrice" },
];

const InitialColumns = (props) => {
  const [open, setOpen] = useState(false);
  let { classes } = props;
  const dispatch = useDispatch();
  const singleCma = useSelector((state) => state.allCmas.singleCma);
  const adjustmentData = useSelector((state) => state.allCmas.adjustmentData);
  const listingDetail = useSelector((state) => state.allCmas.listingDetail);
  const handleCalculatePrice = (type) => {
    let comparables = [...singleCma.cma_comparables];
    let priceList = comparables?.map((item) => {
      if (item.MlsStatus === "Closed") {
        return Number(item.ClosePrice);
      } else {
        return Number(item.ListPrice);
      }
    });
    const sortedArr = priceList.sort((a, b) => a - b);

    // Find the lowest, medium, and highest numbers
    const lowest = sortedArr[0];
    const medium = sortedArr[Math.floor(sortedArr.length / 2)];
    const highest = sortedArr[sortedArr.length - 1];

    if (type === "low") {
      return NumberFormat({
        number: lowest,
        maximumFractionDigits: 0,
      });
    } else if (type === "median") {
      return NumberFormat({
        number: medium,
        maximumFractionDigits: 0,
      });
    } else if (type === "average") {
      const sum = priceList.reduce((acc, curr) => acc + curr, 0);
      const average = sum / priceList.length;
      return NumberFormat({
        number: average,
        maximumFractionDigits: 0,
      });
    } else {
      return NumberFormat({
        number: highest,
        maximumFractionDigits: 0,
      });
    }
  };
  const handleCalculateAdjPrice = (type) => {
    let comparables = [...singleCma.cma_comparables];
    let priceList = comparables?.map((item) => {
      return Number(
        adjustmentData?.cma_comparables?.find(
          (dat) => dat["id"]?.value === item?.id
        )?.TotalAdjValue?.value || 0
      );
    });
    const sortedArr = priceList.sort((a, b) => a - b);

    // Find the lowest, medium, and highest numbers
    const lowest = sortedArr[0];
    const medium = sortedArr[Math.floor(sortedArr.length / 2)];
    const highest = sortedArr[sortedArr.length - 1];

    if (type === "low") {
      return NumberFormat({
        number: lowest,
        maximumFractionDigits: 0,
      });
    } else if (type === "median") {
      return NumberFormat({
        number: medium,
        maximumFractionDigits: 0,
      });
    } else if (type === "average") {
      const sum = priceList.reduce((acc, curr) => acc + curr, 0);
      const average = sum / priceList.length;
      return NumberFormat({
        number: average,
        maximumFractionDigits: 0,
      });
    } else {
      return NumberFormat({
        number: highest,
        maximumFractionDigits: 0,
      });
    }
  };
  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#D9D9D9" }}>
              {columns.map((column, index) => (
                <TableCell
                  key={column.field}
                  style={{
                    borderLeft:
                      index === 0 ? "1px solid rgba(0, 0, 0, 0.30)" : "",
                    padding: "9px 7px 7px 7px",
                  }}
                  className={classes.tableHeaderColumn1}
                >
                  {column?.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ backgroundColor: "rgba(217, 217, 217, 0.30)" }}>
              <TableCell
                className={classes.tableHeaderColumn1}
                sx={{
                  borderLeft: "1px solid #e0e0e0",
                  borderRight: "1px solid #e0e0e0",
                  padding: "7px",
                }}
                colSpan={4}
              >
                FEATURE VALUE
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: "rgba(217, 217, 217, 0.30)" }}>
              <TableCell
                className={classes.tableHeaderColumn1}
                sx={{
                  borderLeft: "1px solid #e0e0e0",
                  borderRight: "1px solid #e0e0e0",
                  padding: "7px",
                }}
                colSpan={4}
              >
                {formatAddress(singleCma)}
              </TableCell>
            </TableRow>
            {singleCma?.cma_comparables?.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{
                      borderLeft: index === 0 ? "1px solid #e0e0e0" : "",
                      borderRight:
                        index === columns?.length - 1
                          ? "1px solid #e0e0e0"
                          : "",
                      textAlign: "left",
                      color:
                        column.field === "UnparsedAddress" ? "#0053D1" : "",
                      fontWeight: "600 !important",
                      fontSize: "13px !important",
                      fontFamily: "AvenirNext !important",
                      textTransform: "uppercase !important",
                      padding: "9px",
                      cursor:
                        column.field === "UnparsedAddress" ? "pointer" : "",
                      "&:hover": {
                        textDecoration:
                          column.field === "UnparsedAddress" ? "underline" : "",
                      },
                    }}
                    onClick={() => {
                      if (column.field === "UnparsedAddress") {
                        if (listingDetail?.data?.ListingId !== row.ListingId) {
                          dispatch(
                            getListingDetail({
                              ListingId: row.ListingId,
                              core: `${row.ListingType}_Property`,
                            })
                          );
                        }
                        setOpen(true);
                      }
                    }}
                  >
                    {column.field === "ListPrice"
                      ? NumberFormat({
                          number:
                            row[
                              row.MlsStatus === "Closed"
                                ? "ClosePrice"
                                : column.field
                            ],
                          maximumFractionDigits: 0,
                        })
                      : column.field === "OriginalListPrice"
                      ? NumberFormat({
                          number: adjustmentData?.cma_comparables?.find(
                            (dat) => dat["id"]?.value === row?.id
                          )?.TotalAdjValue?.value,
                          maximumFractionDigits: 0,
                        })
                      : column.field === "UnparsedAddress"
                      ? formatAddress(row)
                      : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                sx={{
                  borderLeft: "1px solid #e0e0e0",
                  borderBottom: "1px solid transparent",
                  paddingTop: "18px  !important",
                  paddingLeft: "9px !important ",
                }}
                className={classes.tableHeaderColumn1}
                colSpan={2}
              >
                low
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid transparent",
                  paddingTop: "18px  !important",
                }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculatePrice("low")}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #e0e0e0",
                  borderBottom: "1px solid transparent",
                  paddingTop: "18px  !important",
                }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculateAdjPrice("low")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  borderLeft: "1px solid #e0e0e0",
                  borderBottom: "1px solid transparent",
                  paddingLeft: "9px !important ",
                }}
                className={classes.tableHeaderColumn1}
                colSpan={2}
              >
                median
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px solid transparent" }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculatePrice("median")}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #e0e0e0",
                  borderBottom: "1px solid transparent",
                }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculateAdjPrice("median")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  borderLeft: "1px solid #e0e0e0",
                  borderBottom: "1px solid transparent",
                  paddingLeft: "9px !important ",
                }}
                className={classes.tableHeaderColumn1}
                colSpan={2}
              >
                average
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px solid transparent" }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculatePrice("average")}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #e0e0e0",
                  borderBottom: "1px solid transparent",
                }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculateAdjPrice("average")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  borderLeft: "1px solid #e0e0e0",
                  paddingBottom: "17px !important",
                  paddingLeft: "9px !important ",
                }}
                className={classes.tableHeaderColumn1}
                colSpan={2}
              >
                high
              </TableCell>
              <TableCell
                sx={{ paddingBottom: "17px !important" }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculatePrice("highest")}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #e0e0e0",
                  paddingBottom: "17px !important",
                }}
                className={classes.tableHeaderColumn2}
              >
                {handleCalculateAdjPrice("highest")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <PropertyDetail open={open} setOpen={setOpen} {...props} />
    </div>
  );
};
export default withStyles(styleSheet, { name: "InitialColumnsStyle" })(
  InitialColumns
);
