import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import Input from "../../../../components/uiElements/input/Input";
import Dialog from "../../../../components/uiElements/modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../../../../components/uiElements/button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import BlockIcon from "@mui/icons-material/Block";
import { useParams } from "react-router-dom";
import { updateLeadProfileAPI } from "../../../../redux/leads/updateLeadProfileAPI";
import ResponseAlert from "../../../../components/responseAlert";
export const EditPhoneModal = ({ closeModal, open, isLoading }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState([
    {
      value: "",
      type: "",
      isPrimary: false,
      bad_number: false,
      isEditable: true,
    },
  ]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const setPhoneNumberFunc = (param) => {
    setPhoneNumber((prev) => [...prev, param]);
  };

  const updateNumbersFunc = (param) => {
    const data = phoneNumber.filter((_, index) => index !== param);
    setPhoneNumber(data);
  };

  const {
    leadProfile: {
      list: {
        data: { phones },
      },
    },
  } = useSelector((item) => item);

  useEffect(() => {
    if (phones && phones?.length && open) {
      const newArray = phones
        .filter((item) => item.value)
        .map(({ value, type, isPrimary, bad_number, isEditable }) => ({
          value,
          type,
          isPrimary,
          bad_number,
          isEditable,
        }));
      setPhoneNumber(newArray);
    }
  }, [phones, open]);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the phones",
      errorType: "success",
      isOpen: true,
    });
    closeModal();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const onSave = () => {
    dispatch(
      updateLeadProfileAPI({
        schema: { phones: phoneNumber },
        leadId: params?.id,
        handleSuccess,
        handleError,
      })
    );
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        loading={isLoading}
        dialogHead={<DialogHead />}
        onSave={onSave}
        dialogBody={
          <DialogBody
            phoneNumber={phoneNumber}
            setPhoneNumberFunc={setPhoneNumberFunc}
            updateNumbersFunc={updateNumbersFunc}
            setPhoneNumber={setPhoneNumber}
            leadPhones={phones}
          />
        }
        buttonWidth="100px"
        dialogFooter={<DialogFooter />}
        state={open}
        fullWidth
        maxWidth="md"
        closeModal={closeModal}
      />{" "}
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
};

// Components
const PhoneNumberForm = ({
  phoneNumberLabel,
  mobileLabel,
  pnLabel,
  bnLabel,
  mb,
  updateNumbers,
  id,
  phoneNumber,
  setPhoneNumber,
}) => {
  const changeNumberFunc = (e) => {
    const number = [...phoneNumber];
    const re = /^[0-9\b-]+$/;
    const onlyNumber = number[id].value.replace("-", "");
    if (
      (onlyNumber.length <= 10 || e.target.value.replace("-", "")?.length<=10) &&
      number[id].isEditable &&
      (e.target.value === "" || re.test(e.target.value))
    ) {
      number[id].value = e.target.value;
      setPhoneNumber(number);
    }
  };
  const changeTypeFunc = (e) => {
    const number = [...phoneNumber];
    if (number[id].isEditable) {
      number[id].type = e.target.value;
      setPhoneNumber(number);
    }
  };
  const changePNFunc = (e) => {
    const number = [...phoneNumber];
    if (number[id].isEditable) {
      number[id].isPrimary = !number[id].isPrimary;
      setPhoneNumber(number);
    }
  };
  const changeBNFunc = (e) => {
    const number = [...phoneNumber];
    if (number[id].isEditable) {
      number[id].bad_number = !number[id].bad_number;
      setPhoneNumber(number);
    }
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{ flexGrow: 1, marginBottom: mb || 0 }}
    >
      <Grid item sm={5} sx={{ mb: "15px" }}>
        <Input
          size="small"
          onChange={changeNumberFunc}
          value={phoneNumber[id].value}
          label={phoneNumberLabel || false}
          placeholder="123-456-6789"
        />
      </Grid>
      <Grid item sm={4} sx={{ mb: "15px" }}>
        {mobileLabel ? (
          <InputLabel
            sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", mb: "5px" }}
          >
            Label
          </InputLabel>
        ) : null}
        <TextField
          select
          fullWidth
          sx={{ "& .MuiSelect-select ": { padding: "11px 15px" } }}
          onChange={(e) => changeTypeFunc(e)}
          value={phoneNumber[id].type}
          placeholder="Mobile"
        >
          <MenuItem value="mobile">mobile</MenuItem>
          <MenuItem value="work">work</MenuItem>
          <MenuItem value="home">home</MenuItem>
          <MenuItem value="spouse">spouse</MenuItem>
          <MenuItem value="other">other</MenuItem>
        </TextField>
      </Grid>
      <Grid
        item
        sm={1}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormControlLabel
          value="top"
          checked={phoneNumber[id].isPrimary}
          control={<Checkbox />}
          onChange={(e) => changePNFunc(e)}
          label={pnLabel || false}
          labelPlacement="top"
          sx={{
            "& .MuiFormControlLabel-label": {
              textAlign: "center",
              mt: bnLabel ? "-40px" : "-15px",
              fontSize: "14px",
            },
          }}
        />
      </Grid>
      <Grid
        item
        sm={1}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label={bnLabel || false}
          labelPlacement="top"
          checked={phoneNumber[id].bad_number}
          onChange={(e) => changeBNFunc(e)}
          sx={{
            "& .MuiFormControlLabel-label": {
              textAlign: "center",
              mt: bnLabel ? "-40px" : "-15px",
              fontSize: "14px",
            },
          }}
        />
      </Grid>
      <Grid item sm={1} sx={{ textAlign: "center" }}>
        {phoneNumber[id].isEditable ? (
          <IconButton
            aria-label="delete"
            sx={{ mt: bnLabel ? "0px" : "-15px" }}
            onClick={() => updateNumbers(id)}
          >
            <CancelIcon sx={{ color: "black" }} />
          </IconButton>
        ) : (
          <IconButton sx={{ mt: bnLabel ? "0px" : "-15px" }}>
            <BlockIcon sx={{ color: "black" }} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

const DialogHead = () => (
  <DialogTitle sx={{ mt: "10px" }}>
    <Typography component="h5" variant="h5">
      Edit Phone Numbers
    </Typography>
  </DialogTitle>
);

const DialogBody = ({
  phoneNumber,
  setPhoneNumberFunc,
  updateNumbersFunc,
  setPhoneNumber,
  leadPhones,
}) => (
  <DialogContent sx={{ mt: "30px", overflow: "visible" }}>
    {phoneNumber && phoneNumber.length ? (
      phoneNumber.map((number, index) => {
        if (index === 0) {
          return (
            <PhoneNumberForm
              leadPhones={leadPhones}
              phoneNumberLabel={"Phone Number"}
              mobileLabel="Label"
              pnLabel="Primary Number"
              bnLabel="Bad Number"
              updateNumbers={updateNumbersFunc}
              id={index}
              mb="15px"
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              number={number}
            />
          );
        } else {
          return (
            <>
              <PhoneNumberForm
                leadPhones={leadPhones}
                mb="15px"
                id={index}
                updateNumbers={updateNumbersFunc}
                setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber}
                number={number}
              />
            </>
          );
        }
      })
    ) : (
      <Typography
        variant="subtitle1"
        component="p"
        sx={{
          display: "block",
          color: "#adadad",
          textAlign: "center",
        }}
      >
        No Phone Numbers Found!
      </Typography>
    )}

    <br />
    <Button
      onClick={() =>
        setPhoneNumberFunc({
          value: "",
          type: "",
          isPrimary: false,
          bad_number: false,
          isEditable: true,
        })
      }
      variant="text"
      themeVersion
      startIcon={<AddCircleIcon />}
      sx={{
        textTransform: "capitalize",
        fontFamily: "AvenirNext-400",
        mt: "-15px",
      }}
    >
      Add another phone
    </Button>
  </DialogContent>
);

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);
