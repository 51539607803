import React from "react";
import { TableContainer, Table, TableRow, TableCell, Skeleton } from "@mui/material";
function CommissionPlansTable(props) {
  let { count = 10 } = props;
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        {Array.from(Array(count).keys()).map(() => (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell sx={{ width: "120px" }}>
              <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
            </TableCell>
            <TableCell sx={{ width: "250px" }}>
              <Skeleton animation="wave" height={50} width="250px" sx={{ padding: "0px" }} />
            </TableCell>
            <TableCell sx={{ width: "120px" }}>
              <Skeleton animation="wave" height={50} width="120px" sx={{ padding: "0px" }} />
            </TableCell>
            <TableCell sx={{ width: "100px" }}>
              <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
            </TableCell>

            <TableCell sx={{ width: "70px" }}>
              <Skeleton animation="wave" height={50} width="70px" sx={{ padding: "0px" }} />
            </TableCell>
            <TableCell sx={{ width: "100px" }}>
              <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
            </TableCell>
            <TableCell sx={{ width: "100px" }}>
              <Skeleton animation="wave" height={50} width="100px" sx={{ padding: "0px" }} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
}
export default CommissionPlansTable;
