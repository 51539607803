import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CircleIcon from "@mui/icons-material/Circle";
import ChatIcon from "@mui/icons-material/Chat";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Avatar from "../uiElements/avatar/index";
import TextMessagesSkelton from "./skeltons/textMessages.js";
import { StyledBadge } from "./index";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { getTextMessages } from "../../redux/messageAndAlerts";
import { updateData } from "../../utils/updateData";
import momentTZ from "moment-timezone";
import Linkify from "react-linkify";
import { useNavigate } from "react-router-dom";
import { markReadNotificationsAll } from "../../redux/messageAndAlerts/markReadAll.js";
function TextMessages(props) {
  let {
    classes,
    openTextMessage,
    setOpenTextMessage,
    unreadMessageCount,
    setUnreadMessageCount,
  } = props;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
  });
  const [scrollFlag, setScrollFlag] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.MessagesAndAlerts.messages);
  const adminAuth = useSelector((state) => state.adminAuth);

  useEffect(() => {
    if (openTextMessage) {
      dispatch(
        getTextMessages({
          ...filters,
          isNewMessage: unreadMessageCount > 0 ? true : false,
        })
      );
      if (unreadMessageCount > 0) {
        dispatch(markReadNotificationsAll());
        setTimeout(() => {
          setUnreadMessageCount(false);
        }, 2000);
      }
    }
  }, [openTextMessage, filters.pageNumber]);

  const handleScrollEvent = (e) => {
    var bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    if (
      bottom &&
      !messages.isLoading &&
      scrollFlag &&
      messages.count > filters.pageNumber * filters.pageSize
    ) {
      setScrollFlag(false);
      setFilters({ ...filters, pageNumber: filters.pageNumber + 1 });
      setTimeout(() => {
        setScrollFlag(true);
      }, 3000);
    }
  };
  const handleArchive = (id) => {
    updateData(
      `${process.env.REACT_APP_BASE_URL}api/recruitment/textMessages/archiveChat?userId=${adminAuth?.adminDetail?.id}&lead_id=${id}`,
      {}
    );
  };

  const handleCalculateOnline = () => {
    let leadsTotal = messages.data.filter((item) => item?.text_message?.lead);
    let agentsTotal = messages.data.filter(
      (item) => item?.text_message?.realtor_agent
    );
    let leadsOnline = leadsTotal
      .filter(
        (item) =>
          item?.text_message?.lead?.lastActivity &&
          momentTZ().diff(
            momentTZ(item?.text_message?.lead?.lastActivity),
            "minutes"
          ) < 5
      )
      .reduce((accumulator, currentValue) => accumulator + 1, 0);
    let agentsOnline = agentsTotal
      .filter(
        (item) =>
          item?.text_message?.realtor_agent?.lastActivity &&
          momentTZ().diff(
            momentTZ(item?.text_message?.realtor_agent?.lastActivity),
            "minutes"
          ) < 5
      )
      .reduce((accumulator, currentValue) => accumulator + 1, 0);

    return leadsOnline + agentsOnline;
  };

  const handleRedirect = (messageDetail) => {
    if (messageDetail?.message_id) {
      navigate(`/leads/inbox`, {
        state: {
          message_id: messageDetail?.message_id,
          messageDetail: messageDetail?.text_message,
        },
      });
    }
    setOpenTextMessage(false);
  };

  return (
    <Box className={classes.TextMessageArea}>
      <Box className={classes.TextMessageAreaHeader}>
        <Typography>Messages</Typography>
        <Stack direction={"row"} spacing={1}>
          {messages.isLoading ? null : (
            <IconButton size="small" sx={{ padding: "2px" }}>
              <HighlightOffRoundedIcon
                sx={{ fontSize: "20px" }}
                fontSize="medium"
              />
            </IconButton>
          )}
          {messages.isLoading ? (
            <Box className={classes.unreadBadgeText}>
              <Skeleton animation="wave" height={20} width="60%" />
            </Box>
          ) : (
            <Box className={classes.unreadBadgeText}>
              {unreadMessageCount || 0}{" "}
              Unread
            </Box>
          )}
          {messages.isLoading ? (
            <Box className={classes.readBadgeText}>
              <Skeleton animation="wave" height={20} width="60%" />
            </Box>
          ) : messages?.data?.length ? (
            <Box className={classes.readBadgeText}>
              {" "}
              <CircleIcon
                sx={{ fontSize: "11px", mr: "4px" }}
                fontSize="small"
              />
              {messages?.data?.length ? handleCalculateOnline() : "0"}
            </Box>
          ) : null}
        </Stack>
      </Box>
      <List
        onScroll={handleScrollEvent}
        disablePadding
        className={classes.textMessagesList}
      >
        {messages?.data?.length
          ? messages?.data.map((item, index) => (
              <ListItem key={index} divider disablePadding>
                <ListItemButton
                  sx={{
                    paddingRight: "4px",
                    "&:hover": {
                      "& .closeActionItem": { display: "block !important" },
                    },
                  }}
                >
                  <ListItemAvatar
                    onClick={() => handleRedirect(item)}
                    sx={{ minWidth: "40px" }}
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                        color: "#2E7D32",
                      }}
                      variant={"dot"}
                      color={"success"}
                      customColor={
                        item?.text_message?.lead?.lastActivity
                          ? momentTZ().diff(
                              momentTZ(item?.text_message?.lead?.lastActivity),
                              "minutes"
                            ) < 5
                            ? "#2E7D32"
                            : "transparent"
                          : "transparent"
                      }
                      disableShadow={
                        item?.text_message?.lead?.lastActivity
                          ? momentTZ().diff(
                              momentTZ(item?.text_message?.lead?.lastActivity),
                              "minutes"
                            ) < 5
                            ? false
                            : true
                          : true
                      }
                    >
                      <Avatar sx={{ fontSize: "13px" }}>
                        {item.text_message?.lead?.name
                          ? `${item.text_message?.lead?.firstName?.slice(
                              0,
                              1
                            )}${item.text_message?.lead?.lastName?.slice(0, 1)}`
                          : item.text_message?.realtor_agent?.MemberFullName
                          ? `${item.text_message?.realtor_agent?.MemberFirstName?.slice(
                              0,
                              1
                            )}${item.text_message?.realtor_agent?.MemberLastName?.slice(
                              0,
                              1
                            )}`
                          : item.text_message?.realtor_agent?.name
                          ? `${item.text_message?.realtor_agent?.name?.slice(
                              0,
                              1
                            )}${item.text_message?.realtor_agent?.name?.slice(
                              0,
                              1
                            )}`
                          : `${item.text_message?.firstName?.slice(
                              0,
                              1
                            )}${item.text_message?.lastName?.slice(0, 1)}`}
                      </Avatar>
                    </StyledBadge>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ width: "80%" }}
                    onClick={() => handleRedirect(item)}
                    classes={{ secondary: classes.textMessagesListItemContent }}
                    primary={
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        {" "}
                        <Typography
                          className={classes.textMessagesListItemHeading}
                        >
                          {item.text_message?.lead?.name
                            ? item.text_message?.lead?.name
                            : item.text_message?.realtor_agent
                                ?.MemberFullName ||
                              item.text_message?.realtor_agent?.name
                            ? item.text_message?.realtor_agent
                                ?.MemberFullName ||
                              item.text_message?.realtor_agent?.name
                            : item.text_message?.firstName +
                              " " +
                              item.text_message?.lastName}
                        </Typography>{" "}
                        {/* This is chip area for shoing the chip  */}
                        <Chip
                          label={
                            item.text_message?.realtor_agent?.MemberFullName
                              ? "Recruitment"
                              : item.type || "Lead"
                          }
                          className={classes.textMessagesListItemChip}
                          size="small"
                          variant="outlined"
                        />{" "}
                        <Typography
                          className={classes.textMessagesListItemTime}
                        >
                          {momentTZ(item.updatedAt).fromNow()}
                        </Typography>
                      </Stack>
                    }
                    secondary={
                      <Linkify
                        properties={{ target: "_blank" }}
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key
                        ) => (
                          <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      >
                        {item.body}
                      </Linkify>
                    }
                  />
                  <ListItemSecondaryAction
                    className="closeActionItem"
                    sx={{ mr: "-10px", display: "none" }}
                  >
                    <IconButton
                      onClick={() => handleArchive(item.text_message?.lead_id)}
                      size="small"
                    >
                      <CloseRoundedIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            ))
          : null}
        {messages.isLoading ? <TextMessagesSkelton /> : null}
        {!messages?.data?.length && !messages.isLoading ? (
          <Box className={classes.TextMessageAreaNoExist}>
            <center>
              <ChatIcon
                sx={{ color: "rgba(0, 0, 0, 0.4)", fontSize: "60px" }}
              />
              <Typography>No Unread Messages</Typography>
            </center>
          </Box>
        ) : null}
      </List>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "TextMessagesWidgetsStyle" })(
  TextMessages
);
