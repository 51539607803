import React from "react";
import { Box, Card, Skeleton, Stack, Grid } from "@mui/material";
import UserHeaderCardSkelton from "./cardSkelton";
function UserMainSkelton() {
  return (
    <Box>
      <UserHeaderCardSkelton />
      <Card variant="outlined" sx={{ padding: "20px", pt: "30px" }}>
        <Skeleton variant="rounded" width="200px" height="40px" />
        <Grid sx={{ mt: "10px" }} container spacing={4}>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
        <Grid sx={{ mt: "0px" }} container spacing={4}>
          <Grid item sm={12} md={4}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={1}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
        <Grid sx={{ mt: "0px" }} container spacing={4}>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
        <Grid sx={{ mt: "0px" }} container spacing={4}>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
      </Card>
      <Card variant="outlined" sx={{ padding: "20px", mt: "15px" }}>
        <Skeleton variant="rounded" width="200px" height="40px" />
        <Grid sx={{ mt: "10px" }} container spacing={4}>
          <Grid item sm={12}>
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Grid>
        </Grid>
      </Card>
      <Card variant="outlined" sx={{ padding: "20px", mt: "15px",mb:'15px' }}>
        <Skeleton variant="rounded" width="200px" height="40px" />
        <Grid sx={{ mt: "10px" }} container spacing={4}>
          <Grid item sm={12} md={4}>
            <Skeleton variant="rounded" width="100%" height="200px" />
          </Grid>
          <Grid item sm={12} md={4}>
            <Skeleton variant="rounded" width="100%" height="200px" />
          </Grid>
          <Grid item sm={12} md={4}>
            <Skeleton variant="rounded" width="100%" height="200px" />
          </Grid>
        </Grid>
      </Card>
      <Card variant="outlined" sx={{ padding: "20px", pt: "30px" }}>
        <Skeleton variant="rounded" width="200px" height="40px" />
        <Grid sx={{ mt: "10px" }} container spacing={4}>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
        <Grid sx={{ mt: "0px" }} container spacing={4}>
          <Grid item sm={12} md={4}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={1}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
        <Grid sx={{ mt: "0px" }} container spacing={4}>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
        <Grid sx={{ mt: "0px" }} container spacing={4}>
          <Grid item sm={12} md={3}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
          <Grid item sm={12} md={2}>
            <Skeleton variant="rounded" width="100%" height="30px" />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
export default UserMainSkelton;
