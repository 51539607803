import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  InputLabel,
  Grid,
  Box,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddTrainingEnrollment } from "../../../../redux/agents/agentRoster/addEnroll";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { dateFormat } from "../../../../utils/dateFormat";
import Avatar from "../../../../components/uiElements/avatar/index";
import momentTZ from "moment-timezone";
function EnrollMemberShipModal(props) {
  let { open, setOpen, classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const mentors = useSelector(
    (item) => item.agentRoster.agentRosterProfile.mentors
  );
  const profile = useSelector(
    (item) => item.agentRoster?.agentRosterProfile?.profile
  );
  const addEnroll = useSelector(
    (item) => item.agentRoster.agentRosterProfile.addEnroll
  );
  const [enrollDetail, setEnrollDetail] = useState({
    user_id: id,
    enrollment_date: "",
    mentor: "",
  });

  const handleUpdateDetail = (field, value) => {
    setEnrollDetail({ ...enrollDetail, [field]: value });
    setErrMsg({});
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!enrollDetail.mentor) {
      isFormValid = false;
      errorMsg["mentor"] = "Please select the mentor first";
      setErrorAlert({
        errorMsg: "Please select the mentor first",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!enrollDetail.enrollment_date) {
      isFormValid = false;
      errorMsg["enrollment_date"] = "Please select the enrollment date";
      setErrorAlert({
        errorMsg: "Please select the enrollment date",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully enrolled",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleError = (errMsg) => {
    setErrorAlert({
      errorMsg: JSON.stringify(errMsg),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSubmitEnroll = () => {
    if (handleValidate()) {
      let obj = {
        schema: {
          user_id: id,
          mentor_id: enrollDetail?.mentor?.id,
          enrollment_date: enrollDetail?.enrollment_date,
        },
        mentor:enrollDetail?.mentor,
        handleSuccess,
        handleError,
      };
      dispatch(AddTrainingEnrollment(obj));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEnrollDetail({
      user_id: id,
      enrollment_date: "",
      mentor: "",
    });
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={{
          "& .MuiPaper-root": { overflow: "visible !important" },
          "& .MuiDialog-container": { mt: "30px" },
        }}
      >
        <DialogTitle
          className={classes.appointmentModalHeading}
          id="Enroll-dialog-title"
        >
          Enroll in Mentorship Program
          <IconButton onClick={() => handleClose()}>
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: "-15px" }}
          >
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.fieldLabelEnroll}>
                Mentee:
              </InputLabel>

              {profile?.id ? (
                <Box className={classes.manteeArea}>
                  <Avatar
                    src={profile?.profile_images?.profile_img}
                    sx={{
                      width: "25px",
                      height: "25px",
                      mr: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    {`${profile?.first_name?.slice(
                      0,
                      1
                    )}${profile?.last_name?.slice(0, 1)}`}
                  </Avatar>
                  <Typography>
                    {profile?.first_name} {profile?.last_name}
                  </Typography>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.fieldLabelEnroll}>
                Mentor:
              </InputLabel>
              <Autocomplete
                disableClearable
                options={mentors}
                getOptionLabel={(option) => option.full_name}
                onChange={(e, option) => {
                  handleUpdateDetail("mentor", option);
                }}
                value={enrollDetail?.mentor ? enrollDetail?.mentor : undefined}
                sx={{ mt: "12px" }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Avatar
                      src={option?.profile_images?.profile_img}
                      sx={{
                        width: "20px",
                        height: "20px",
                        mr: "8px",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {`${option.full_name?.slice(0, 1)}${option.full_name
                        ?.split(" ")[1]
                        ?.slice(0, 1)}`}
                    </Avatar>
                    {option.full_name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Start Typing or Select"
                    aria-describedby="stepper-linear-event_types"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errMsg.mentor}
                    helperText={errMsg.mentor}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12} sx={{ zIndex: "1" }}>
              <InputLabel className={classes.fieldLabelEnroll}>
                Enrollment Date:
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  fullWidth
                  value={dateFormat(enrollDetail?.enrollment_date)}
                  onChange={(newValue) =>
                    handleUpdateDetail("enrollment_date", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      error={errMsg.enrollment_date}
                      helperText={errMsg.enrollment_date}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: "14px",
            justifyContent: "flex-start",
          }}
        >
          <LoadingButton
            onClick={() => handleSubmitEnroll()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addEnroll.isLoading}
          >
            Enroll
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "EnrollMemberShipModalStyle" })(
  EnrollMemberShipModal
);
