import React, { useState } from "react";
import { Card, CardContent, CardHeader, ListItemText, Button, Grid, Stack, Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import ProjectApproval from "../../modals/projectApproval";
import { NumberFormat } from "../../../../utils/numberFormat";
function MakeOverProjectDetail(props) {
  let { classes, projectDetail } = props;
  const [openApprove, setOpenApprove] = useState(false);
  
  return (
    <Card sx={{ backgroundColor: "white", mt: "20px" }} className={classes.TransactionSalesRoot}>
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title={"Makeover Project Details"}
        action={
          <Stack direction={"row"} spacing={2}>
            {projectDetail ? (
              projectDetail.status === "approved" || projectDetail.status === "unapproved" ? (
                <Button onClick={() => setOpenApprove(true)} variant="outlined" color="inherit" size="small">
                  Update
                </Button>
              ) : (
                <Stack direction={"row"} spacing={1}>
                  <Button onClick={() => setOpenApprove("approve")} variant="outlined" color="success" size="small">
                    Approve
                  </Button>
                  <Button onClick={() => setOpenApprove("deny")} variant="outlined" color="error" size="small">
                    Deny
                  </Button>
                </Stack>
              )
            ) : null}
          </Stack>
        }
      />
      {projectDetail ? (
        <CardContent>
          <Grid container spacing={0} sx={{ paddingTop: "0px" }}>
            <Grid item md={2}>
              <ListItemText
                classes={{ primary: classes.makeOverListingHeading }}
                primary="Seller(s):"
                secondary={`${projectDetail?.seller0_first_name} ${projectDetail?.seller0_last_name} ${
                  projectDetail?.seller1_first_name || projectDetail?.seller1_last_name ? "&" : ""
                } ${projectDetail?.seller1_first_name || ""} ${projectDetail?.seller1_last_name || ""}`}
              />
            </Grid>
            <Grid item md={9} sx={{ paddingLeft: "40px" }}>
              <Stack direction={"row"} justifyContent={"flex-end"} flexWrap={"wrap"}>
                <ListItemText
                  classes={{ primary: classes.makeOverListingHeading }}
                  primary="Amount Requested:"
                  secondary={NumberFormat({ number: projectDetail?.amount_requested, maximumFractionDigits: 2 })}
                />
                <ListItemText
                  classes={{ primary: classes.makeOverListingHeading }}
                  primary="Amount Approved:"
                  secondary={
                    projectDetail.status === "unapproved" ? (
                      <Box sx={{ color: "red" }}>DENIED</Box>
                    ) : projectDetail?.max_budget ? (
                      NumberFormat({ number: projectDetail?.max_budget, maximumFractionDigits: 2 })
                    ) : (
                      projectDetail.status || "Pending"
                    )
                  }
                />
                <ListItemText
                  classes={{ primary: classes.makeOverListingHeading }}
                  primary="Amount Spent:"
                  secondary={
                    projectDetail?.invoice_paid_amount
                      ? NumberFormat({ number: projectDetail?.invoice_paid_amount, maximumFractionDigits: 2 })
                      : "$0.00"
                  }
                />
                <ListItemText
                  classes={{ primary: classes.makeOverListingHeading }}
                  primary="Amount Remaining:"
                  secondary={
                    projectDetail?.invoice_paid_amount && projectDetail?.max_budget
                      ? NumberFormat({
                          number: Number(projectDetail?.max_budget || 0) - Number(projectDetail?.invoice_paid_amount || 0),
                          maximumFractionDigits: 2,
                        })
                      : projectDetail?.max_budget ? (
                        NumberFormat({ number: projectDetail?.max_budget, maximumFractionDigits: 2 })
                      ):"$0.00"
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={0} sx={{ paddingTop: "15px" }}>
            <Grid item md={2}>
              <ListItemText
                classes={{ primary: classes.makeOverListingHeading }}
                primary="Est. Mortgage Balance:"
                secondary={NumberFormat({ number: projectDetail?.mortgage_balance, maximumFractionDigits: 2 })}
              />
            </Grid>
            <Grid item md={9} sx={{ paddingLeft: "40px" }}>
              <ListItemText
                classes={{ primary: classes.makeOverListingHeading }}
                primary="Work To Be Completed:"
                secondary={projectDetail?.description}
              />
            </Grid>
          </Grid>
        </CardContent>
      ) : null}

      <ProjectApproval open={openApprove} makeoverDetail={projectDetail} setOpen={setOpenApprove} {...props} />
    </Card>
  );
}
export default withStyles(styleSheet, {
  name: "MakeOverProjectDetailStyle",
})(MakeOverProjectDetail);
