export const styleSheet = () => ({
    UnauthorizedPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
    },
    UnauthorizedPageHeading:{
        fontSize: "20px",
        fontWeight: '600',
        margin: 0,
        textAlign: 'center'
    },
    UnauthorizedPageContent:{
        margin: 0,
        textAlign: 'center'
    },
  });