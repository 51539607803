import { createSlice } from "@reduxjs/toolkit";
import { getLeadTagsAPI } from "../leads/getLeadTagsAPI";

const initialState = {
  data: [],
  status: {
    pending: false,
    success: false,
    error: false,
  },
};

const leadTags = createSlice({
  name: "leadTags",
  initialState,
  reducers: {},
  extraReducers: {
    [getLeadTagsAPI.pending]: (state) => {
      state.status.pending = true;
    },
    [getLeadTagsAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.data = action.payload.tags;
      }
      state.status.pending = false;
    },
    [getLeadTagsAPI.rejected]: (state, action) => {
      state.status.pending = false;
      state.status.error = true;
    },
  },
});

export default leadTags.reducer;
