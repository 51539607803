import axios from "axios";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  addAgentRecruitmentSmartList,
  updateAgentRecruitmentSmartList,
} from "../agents/agentRecruitment/addUpdateAgentRecruitmentSmartList";
import { getAgentRecruitmentSmartList } from "../agents/agentRecruitment/getAllSmartLists";
import { addRecruitAgent, getMarkets } from "./addRecruitAgent";

const initialState = {
  loading: "",
  markets: "",
  search: "",
  agentList: [],
  smartList: [],
  totalAgentsCount: 0,
  sorting: {},
  filters: [],
  columnsOrder: [
    "MemberFullName",
    "Market",
    "MemberStateLicense",
    "MemberPreferredPhone",
    "tags",
    "lastCommunicationTimestamp",
    "stageName",
    "totalVolume12Months_FMLS",
    "totalVolume12Months_GAMLS",
    "activeZips_FMLS",
    "activeZips_GAMLS",
    "activeCities_FMLS",
    "activeCities_GAMLS",
    "totalSales_STELLAR",
    "activeCities_Stellar",
    "activeZips_Stellar",
    "totalVolume12Months_STELLAR",
  ],
  columnsVisibility: {},
  allFilteredAgentsIds: [],
  addRecruit: {
    loading: false,
    success: null,
    errMsg: null,
  },
  deleteList: {
    loading: false,
    success: null,
    errMsg: null,
  },
  errMsg: null,
  modalActions: {
    loading: false,
    success: false,
    errMsg: null,
  },
};

export const getAgentRecruitingList = createAsyncThunk(
  "agentListData/getAgentRecruitingList",
  async (data, thunkAPI) => {
    // const param = `?pageSize=${data?.pageSize || ""}&pageNumber=${data?.pageNumber || ""}&sortBy=${data?.sortBy || ""}&sortOrder=${
    //   data?.sortOrder || ""
    // }&search=${data?.search || ""}&filters=${data?.filters || ""}`;

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/recruitment`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
          params: {
            pageSize: data?.pageSize || "",
            pageNumber: data?.pageNumber || "",
            sortBy: data?.sortBy || "",
            sortOrder: data?.sortOrder,
            search: data?.search || "",
            filters: data?.filters || "",
          },
        }
      );

      return { ...resp.data, resetList: data?.resetList, search: data?.search };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getAgentRecruitingIdsAfterFilter = createAsyncThunk(
  "agentListData/getAgentRecruitingIdsAfterFilter",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/agents-all-ids-filtered`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
          params: {
            pageSize: data?.pageSize || "",
            pageNumber: data?.pageNumber || "",
            sortBy: data?.sortBy || "",
            sortOrder: data?.sortOrder,
            search: data?.search || "",
            filters: data?.filters || "",
          },
        }
      );

      return { ...resp.data, resetList: data?.resetList };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteSmartList = createAsyncThunk(
  "agentListData/deleteSmartList",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/smartlists/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );

      return { id: data.id, smartList: data.smartList, ...resp.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const agentList = createSlice({
  name: "agentListData",
  initialState,
  reducers: {
    modalSubmitSuccess: (state, action) => {
      state.modalActions = { ...initialState.modalActions };
      state.deleteList = { ...initialState.deleteList };
    },
    setRecruitmentFilter: (state, action) => {
      state.filters = action.payload || [];
    },
    setRecruitmentSort: (state, action) => {
      state.sorting = action.payload;
    },
    setRecruitmentColumnsOrder: (state, action) => {
      state.columnsOrder = action.payload;
    },
    setRecruitmentColumnsVisibility: (state, action) => {
      state.columnsVisibility = action.payload;
    },
    successAddAgentRecruit: (state, action) => {
      state.addRecruit = initialState.addRecruit;
    },
  },
  extraReducers: {
    [getAgentRecruitingList.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRecruitingList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.resetList) {
        state.agentList = [...action.payload.data];
      } else {
        state.agentList = [...state.agentList.concat(action.payload.data)];
      }
      state.search = action.payload?.search || "";
      state.totalAgentsCount = action.payload._metadata.total;
    },
    [getAgentRecruitingList.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },

    [deleteSmartList.pending]: (state) => {
      state.deleteList.loading = true;
    },
    [deleteSmartList.fulfilled]: (state, action) => {
      state.deleteList.loading = false;
      state.deleteList.success = true;
      state.smartList = action.payload.smartList.filter(
        (item) => item.id !== action.payload.id
      );
    },
    [deleteSmartList.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },

    [getAgentRecruitingIdsAfterFilter.fulfilled]: (state, action) => {
      state.allFilteredAgentsIds = action.payload.ids;
    },
    [getAgentRecruitmentSmartList.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRecruitmentSmartList.fulfilled]: (state, action) => {
      state.loading = false;
      state.smartList = action.payload;
    },
    [getAgentRecruitmentSmartList.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },

    [getMarkets.fulfilled]: (state, action) => {
      state.markets = action.payload.markets || [];
    },
    [addRecruitAgent.pending]: (state, action) => {
      state.addRecruit.loading = true;
    },
    [addRecruitAgent.fulfilled]: (state, action) => {
      state.addRecruit.loading = false;
      state.addRecruit.success = true;
      state.errMsg = null;
    },
    [addRecruitAgent.rejected]: (state, action) => {
      state.addRecruit.loading = false;
      state.addRecruit.success = false;
      state.addRecruit.errMsg = action.payload;
    },

    [addAgentRecruitmentSmartList.pending]: (state, action) => {
      state.modalActions.loading = true;
    },
    [addAgentRecruitmentSmartList.fulfilled]: (state, action) => {
      state.modalActions.loading = false;
      state.modalActions.success = true;
      state.smartList = [...state.smartList, action.payload.smartlist];
      state.errMsg = null;
    },
    [addAgentRecruitmentSmartList.rejected]: (state, action) => {
      state.modalActions.errMsg = action.payload;
    },

    [updateAgentRecruitmentSmartList.pending]: (state, action) => {
      state.modalActions.loading = true;
    },
    [updateAgentRecruitmentSmartList.fulfilled]: (state, action) => {
      state.modalActions.loading = false;
      state.modalActions.success = true;
      state.smartList = state.smartList.map((r) => {
        if (r.id == action.payload.id) {
          return action.payload.smartList;
        }
        return r;
      });
      state.errMsg = null;
    },
    [updateAgentRecruitmentSmartList.rejected]: (state, action) => {
      state.modalActions.errMsg = action.payload;
    },
  },
});

export const {
  setRecruitmentSort,
  modalSubmitSuccess,
  setRecruitmentColumnsOrder,
  setRecruitmentColumnsVisibility,
  setRecruitmentFilter,
  successAddAgentRecruit,
} = agentList.actions;
export default agentList.reducer;
