import { createSlice } from "@reduxjs/toolkit";
import { getParticularContract } from "./getParticularContract";
import { closeTransactionContract } from "./closeContract";
import { terminateTransactionContract } from "./terminateContract";
import { addTransactionDocument } from "./addTransactionDocument";
import { uploadDocument } from "./uploadDocument";
import { updateDocument } from "./updateDocument";
import { rejectDocument } from "./rejectDocument";
import { AddTransactionNote } from "./addNote";
import { getTransactionLeads } from "./getTransactionLeads";
import { updateTransactionContract } from "./updateTransactionContract";
import { deleteContractNote } from "./deleteNote";
import { updateContactNote } from "./updateNote";

const initialState = {
  ContractData: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  closeContract: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  terminateContract: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addDocument: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  uploadDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  rejectDoc: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addNote: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  leads: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateContract: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  updateNote: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  deleteNote:{
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const TransactionsContract = createSlice({
  name: "TransactionsContract",
  initialState,
  reducers: {
    ResetDocumentState: (state, action) => {
      state.addDocument.errMsg = null;
      state.addDocument.success = false;
      state.uploadDoc.errMsg = null;
      state.uploadDoc.success = false;
      state.updateDoc.errMsg = null;
      state.updateDoc.success = false;
      state.rejectDoc.errMsg = null;
      state.rejectDoc.success = false;
    },
  },
  extraReducers: {
    [getParticularContract.pending]: (state) => {
      state.ContractData.isLoading = true;
      state.ContractData.errMsg = null;
      state.ContractData.success = false;
      state.closeContract.errMsg = null;
      state.closeContract.success = false;
      state.terminateContract.errMsg = null;
      state.terminateContract.success = false;
    },
    [getParticularContract.fulfilled]: (state, action) => {
      state.ContractData.success = true;
      state.ContractData.isLoading = false;
      state.ContractData.data = action.payload;
    },
    [getParticularContract.rejected]: (state, action) => {
      state.ContractData.isLoading = false;
      state.ContractData.success = false;
      state.ContractData.errMsg = action.payload;
    },
    [closeTransactionContract.pending]: (state) => {
      state.closeContract.isLoading = true;
      state.closeContract.errMsg = null;
      state.closeContract.success = false;
    },
    [closeTransactionContract.fulfilled]: (state, action) => {
      state.closeContract.success = true;
      state.closeContract.isLoading = false;
      state.closeContract.data = action.payload;
    },
    [closeTransactionContract.rejected]: (state, action) => {
      state.closeContract.isLoading = false;
      state.closeContract.success = false;
      state.closeContract.errMsg = action.payload;
    },

    [terminateTransactionContract.pending]: (state) => {
      state.terminateContract.isLoading = true;
      state.terminateContract.errMsg = null;
      state.terminateContract.success = false;
    },
    [terminateTransactionContract.fulfilled]: (state, action) => {
      state.terminateContract.success = true;
      state.terminateContract.isLoading = false;
      state.terminateContract.data = action.payload;
    },
    [terminateTransactionContract.rejected]: (state, action) => {
      state.terminateContract.isLoading = false;
      state.terminateContract.success = false;
      state.terminateContract.errMsg = action.payload;
    },

    [addTransactionDocument.pending]: (state) => {
      state.addDocument.isLoading = true;
      state.addDocument.errMsg = null;
      state.addDocument.success = false;
    },
    [addTransactionDocument.fulfilled]: (state, action) => {
      state.addDocument.success = true;
      state.addDocument.isLoading = false;
      state.addDocument.data = action.payload;
      let newData = { ...action.payload, document: { type: action.meta.arg.get("type"), name: action.payload.name } };
      let documentData = state.ContractData.data.transaction_documents ? [...state.ContractData.data.transaction_documents] : [];
      documentData.push(newData);
      state.ContractData.data.transaction_documents = documentData;
    },
    [addTransactionDocument.rejected]: (state, action) => {
      state.addDocument.isLoading = false;
      state.addDocument.success = false;
      state.addDocument.errMsg = action.payload;
    },

    [uploadDocument.pending]: (state) => {
      state.uploadDoc.isLoading = true;
      state.uploadDoc.errMsg = null;
      state.uploadDoc.success = false;
    },
    [uploadDocument.fulfilled]: (state, action) => {
      state.uploadDoc.success = true;
      state.uploadDoc.isLoading = false;
      state.uploadDoc.data = action.payload;
      let newData = action.payload;
      let documentData = state.ContractData.data.transaction_documents ? [...state.ContractData.data.transaction_documents] : [];
      let index = documentData.findIndex((item) => item.document_id === action.payload.document_id);
      if (index > -1) {
        documentData.splice(index, 1, newData);
        state.ContractData.data.transaction_documents = documentData;
      }
    },
    [uploadDocument.rejected]: (state, action) => {
      state.uploadDoc.isLoading = false;
      state.uploadDoc.success = false;
      state.uploadDoc.errMsg = action.payload;
    },

    [updateDocument.pending]: (state) => {
      state.updateDoc.isLoading = true;
      state.updateDoc.errMsg = null;
      state.updateDoc.success = false;
    },
    [updateDocument.fulfilled]: (state, action) => {
      state.updateDoc.success = action.meta?.arg?.type;
      state.updateDoc.isLoading = false;
      state.updateDoc.data = action.payload;
      let newData = action.payload;
      let documentData = state.ContractData.data.transaction_documents ? [...state.ContractData.data.transaction_documents] : [];
      let index = documentData.findIndex((item) => item.document_id === action.payload.document_id);
      if (index > -1) {
        documentData.splice(index, 1, newData);
        state.ContractData.data.transaction_documents = documentData;
      }
    },
    [updateDocument.rejected]: (state, action) => {
      state.updateDoc.isLoading = false;
      state.updateDoc.success = false;
      state.updateDoc.errMsg = action.payload;
    },

    [rejectDocument.pending]: (state) => {
      state.rejectDoc.isLoading = true;
      state.rejectDoc.errMsg = null;
      state.rejectDoc.success = false;
    },
    [rejectDocument.fulfilled]: (state, action) => {
      state.rejectDoc.success = true;
      state.rejectDoc.isLoading = false;
      state.rejectDoc.data = action.payload;
      let newData = action.payload;
      let documentData = state.ContractData.data.transaction_documents ? [...state.ContractData.data.transaction_documents] : [];
      let index = documentData.findIndex((item) => item.document_id === action.payload.document_id);
      if (index > -1) {
        documentData.splice(index, 1, newData);
        state.ContractData.data.transaction_documents = documentData;
      }
    },
    [rejectDocument.rejected]: (state, action) => {
      state.rejectDoc.isLoading = false;
      state.rejectDoc.success = false;
      state.rejectDoc.errMsg = action.payload;
    },

    [AddTransactionNote.pending]: (state) => {
      state.addNote.isLoading = true;
      state.addNote.errMsg = null;
      state.addNote.success = false;
    },
    [AddTransactionNote.fulfilled]: (state, action) => {
      state.addNote.success = true;
      state.addNote.isLoading = false;
      state.addNote.data = action.payload;
      let notes = state.ContractData.data.notes ? [...state.ContractData.data.notes] : [];
      notes.push(action.payload.contractNote);
      state.ContractData.data.notes = notes;
    },
    [AddTransactionNote.rejected]: (state, action) => {
      state.addNote.isLoading = false;
      state.addNote.success = false;
      state.addNote.errMsg = action.payload;
    },
    // update note
    [updateContactNote.pending]: (state) => {
      state.updateNote.isLoading = true;
      state.updateNote.errMsg = null;
      state.updateNote.success = false;
    },
    [updateContactNote.fulfilled]: (state, action) => {
      state.updateNote.success = true;
      state.updateNote.isLoading = false;
      state.updateNote.data = action.payload;
      let notes = state.ContractData.data.notes ? [...state.ContractData.data.notes] : [];
      let index = notes.findIndex((item) => item.id === action.payload.noteId);
      notes[index] = { ...notes[index], ...action.payload };
      state.ContractData.data.notes = notes;
    },
    [updateContactNote.rejected]: (state, action) => {
      state.updateNote.isLoading = false;
      state.updateNote.success = false;
      state.updateNote.errMsg = action.payload;
    },

    // delete note
    [deleteContractNote.pending]: (state) => {
      state.deleteNote.isLoading = true;
      state.deleteNote.errMsg = null;
      state.deleteNote.success = false;
    },
    [deleteContractNote.fulfilled]: (state, action) => {
      state.deleteNote.success = true;
      state.deleteNote.isLoading = false;
      state.deleteNote.data = action.payload;
      let notes = state.ContractData.data.notes ? [...state.ContractData.data.notes] : [];
      let index = notes.findIndex((item) => item.id === action.payload.noteId);
      notes.splice(index, 1);
      state.ContractData.data.notes = notes;
    },
    [deleteContractNote.rejected]: (state, action) => {
      state.deleteNote.isLoading = false;
      state.deleteNote.success = false;
      state.deleteNote.errMsg = action.payload;
    },

    [getTransactionLeads.pending]: (state) => {
      state.leads.isLoading = true;
      state.leads.errMsg = null;
      state.leads.success = false;
    },
    [getTransactionLeads.fulfilled]: (state, action) => {
      state.leads.success = true;
      state.leads.isLoading = false;
      state.leads.data = action.payload;
    },
    [getTransactionLeads.rejected]: (state, action) => {
      state.leads.isLoading = false;
      state.leads.success = false;
      state.leads.errMsg = action.payload;
    },

    [updateTransactionContract.pending]: (state) => {
      state.updateContract.isLoading = true;
      state.updateContract.errMsg = null;
      state.updateContract.success = false;
    },
    [updateTransactionContract.fulfilled]: (state, action) => {
      state.updateContract.success = true;
      state.updateContract.isLoading = false;
      state.ContractData.data = { ...action.payload, transaction_documents: state.ContractData.data.transaction_documents };
    },
    [updateTransactionContract.rejected]: (state, action) => {
      state.updateContract.isLoading = false;
      state.updateContract.success = false;
      state.updateContract.errMsg = action.payload;
    },
  },
});
export const { ResetDocumentState } = TransactionsContract.actions;
export default TransactionsContract.reducer;
