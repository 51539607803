import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  docDetails: {},
  errMsg: null,
};

export const getDocDetails = createAsyncThunk(
  "docs/getDocDetails",
  async (data, thunkAPI) => {
    try {
      const url = `api/docs/folder/${data}` + '?fields=all';
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const docDetails = createSlice({
  name: "docDetails",
  initialState,
  reducers: {
    updateDataDocs: (state, action) => {
      state.docDetails = action.payload;
    },
  },
  extraReducers: {
    [getDocDetails.pending]: (state) => {
      state.loading = true;
    },
    [getDocDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.docDetails = action.payload.data;
    },
    [getDocDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});
export const { updateDataDocs } = docDetails.actions;
export default docDetails.reducer;
