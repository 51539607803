import React, { useState } from "react";
import {
  Menu,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import Avatar from "../../../../../components/uiElements/avatar/index";
function AssignedTo(props) {
  const {
    openAssignedTo,
    setOpenAssignedTo,
    pondsList,
    usersList,
    handleSelectUser,
    handleSelectPond,
  } = props;
  const [search, setSearch] = useState("");

  const handleReturnUserOptions = () => {
    if (search?.length > 0) {
      let options = [];

      usersList
        ?.filter((u) => {
          return u.role.name !== "Lender";
        })
        .map((item, index) => {
          if (item.full_name.toLowerCase()?.startsWith(search.toLowerCase())) {
            options.push(item);
          }
        });

      return options;
    } else {
      return [...usersList];
    }
  };

  const handleReturnPondsOptions = () => {
    if (search?.length > 0) {
      let options = [];

      pondsList.map((item, index) => {
        if (item.name.toLowerCase()?.startsWith(search.toLowerCase())) {
          options.push(item);
        }
      });

      return options;
    } else {
      return [...pondsList];
    }
  };

  return (
    <Menu
      anchorEl={openAssignedTo}
      id="account-menu"
      open={Boolean(openAssignedTo)}
      onClose={() => {
        setOpenAssignedTo(false);
      }}
      sx={{ zIndex: 10000000 }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 40,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <Box
        sx={{
          padding: "5px 15px",
          "& .MuiInputBase-root": { fontSize: "13px", height: "35px" },
        }}
      >
        <TextField
          placeholder="Search"
          fullWidth
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Typography
        sx={{
          bgcolor: "#F7F9FA",
          fontSize: "12px",
          fontWeight: "500",
          color: "#8B9CA8",
          padding: "5px 15px",
        }}
      >
        {" "}
        Users
      </Typography>
      {usersList?.length ? (
        <List
          dense
          sx={{
            width: 270,
            maxHeight: "220px",
            overflow: "auto",
            bgcolor: "background.paper",
          }}
        >
          {handleReturnUserOptions()?.map((user) => {
            return (
              <ListItem
                onClick={() => handleSelectUser(user)}
                key={user.id}
                disablePadding
              >
                <ListItemButton>
                  <ListItemAvatar sx={{ minWidth: "40px" }}>
                    <Avatar
                      alt={user.full_name}
                      src={
                        user?.profile_images?.profile_img_thumbnail ||
                        user?.profile_images?.profile_img
                      }
                      sx={{ width: "30px", height: "30px", fontSize: "13px" }}
                    >
                      {`${user.first_name?.slice(0, 1)}${user.last_name?.slice(
                        0,
                        1
                      )}`}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText id={user.id} primary={user.full_name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : null}

      <Typography
        sx={{
          bgcolor: "#F7F9FA",
          fontSize: "12px",
          fontWeight: "500",
          color: "#8B9CA8",
          padding: "5px 15px",
        }}
      >
        {" "}
        PONDS
      </Typography>
      <List
        dense
        sx={{
          width: 270,
          maxHeight: "220px",
          overflow: "auto",
          bgcolor: "background.paper",
        }}
      >
        {handleReturnPondsOptions()?.map((item) => {
          return (
            <ListItem
              onClick={() => handleSelectPond(item)}
              key={item.id}
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  sx={{ "& .MuiTypography-root": { fontSize: "13px" } }}
                  id={item.id}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Menu>
  );
}

export default AssignedTo;
