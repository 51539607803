import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import { useNavigate } from "react-router-dom";
import { PhoneCell } from "./TableCellsVendor";
import { Typography, Container, Button } from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import AddIcon from "@mui/icons-material/Add";
import AddVendorModal from "../modals/addVendor";
import { useDispatch, useSelector } from "react-redux";
import { getAllVendors } from "../../../redux/vendors/vendorList";
import TableSkeleton from "../../../components/loader/tableLoader";

const VendorsList = ({ classes, loading }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    sortBy: "",
    filterByType: "",
    search: "",
  });
  const VendorsData = useSelector(
    (state) => state.vendors.VendorsList.VendorsData
  );
  const columns = [
    {
      field: "companyName",
      headerName: "Company",
      renderCell: ({ row }) => {
        return row.name ? row.name : "--";
      },
      width: 220,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2?.name),
      editable: false,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      renderCell: ({ row }) => {
        return (
          (row.street || "") +
          " " +
          (row.city || "") +
          " " +
          (row.state || "") +
          " " +
          (row.postal_code || "")
        );
      },
      width: 350,
      editable: false,
      sortable: false,
    },
    {
      field: "category",
      headerName: "Category",
      renderCell: ({ row }) => {
        return row.category ? row.category : "--";
      },
      width: 120,
      editable: false,
      sortable: false,
    },
    {
      field: "contact_number",
      headerName: "Phone",

      renderCell: ({ row }) => {
        return row.phone ? <PhoneCell number={row.contact_number} /> : "--";
      },
      width: 220,
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
      editable: false,
      sortable: true,
    },
    {
      field: null,
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <>
            <TaskIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </>
        );
      },
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];

  useEffect(() => {
    dispatch(getAllVendors(filters));
  }, []);

  // Detail view trigger for agent
  const onRowClick = ({ id }) => {
    navigate(`/vendors/${id}`);
  };

  return (
    <Container maxWidth="xl" className={classes.pageRoot}>
      <Box sx={{ margin: "10px 5px" }} className={classes.vendorTableHeader}>
        <Typography
          classes={{ root: classes.vendorTableHeading }}
          variant="subtitle1"
          component="p"
        >
          {VendorsData.data?.count} Vendors
        </Typography>
        <Button
          onClick={() => setOpen(true)}
          className={classes.vendorAddButton}
          variant="contained"
          startIcon={<AddIcon />}
        >
          ADD VENDOR
        </Button>
      </Box>
      <Box className={classes.tableRoot}>
        <DataGridPro
          columns={columns}
          rows={VendorsData.data?.vendors || []}
          loading={VendorsData.isLoading ? true : false}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowsScrollEnd={(e) => {
            if (
              VendorsData.data?.count > e.virtualRowsCount &&
              !VendorsData.isLoading
            ) {
              dispatch(
                getAllVendors({
                  ...filters,
                  pageNumber: Number(e.virtualRowsCount / 25) + 1,
                  pageSize: 25,
                })
              );
            }
          }}
          onRowClick={(param) => onRowClick(param)}
        />
      </Box>
      <AddVendorModal open={open} setOpen={setOpen} />
    </Container>
  );
};

export default withStyles(styleSheet, { name: "VendorsListStyle" })(
  VendorsList
);
