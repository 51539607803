import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import { getData } from "../../../../utils/getData";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../../components/loader/tableLoader";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import momentTZ from "moment-timezone";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useSelector } from "react-redux";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import CheckIcon from "@mui/icons-material/Check";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import VisibilityRounded from "@mui/icons-material/VisibilityRounded";
import UpdateTaskStatus from "../../modals/updateTaskStatus";
import ArchiveAndUnArchiveTask from "../../modals/archiveAndUnArchive";
import { useNavigate } from "react-router-dom";

function ContractsTasksTab(props) {
  let { classes } = props;
  const [loading, setLoading] = useState(false);
  const [pendingData, setPendingData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const [tab, setTab] = useState("today");
  const [currentTask, setCurrentTask] = useState({});
  const navigate = useNavigate();

  const adminAuth = useSelector((state) => state.adminAuth);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const handleGetPendingTasks = async () => {
    setLoading(true);
    setPendingData([]);
    // for get tasks overdue with in  48 hours
    let dateNow, dateEnd;

    if (tab === "tomorrow") {
      dateNow = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      dateEnd = moment.utc().add(1, "days").format("YYYY-MM-DD HH:mm:ss");
    }
    if (tab === "today") {
      dateNow = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      dateEnd = moment.utc().format("YYYY-MM-DD HH:mm:ss");
    }
    if (tab === "overdue") {
      dateNow = moment
        .utc()
        .subtract(730, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      dateEnd = moment.utc().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
    }

    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/transactions/contracts/with-pending-tasks?startingDueDate=${dateNow}&endingDueDate=${dateEnd}`
    );
    if (result.status === 200) {
      let newData = result.data?.length ? [...result.data] : [];
      let taskPendingData = [];
      newData?.map((contact) => {
        contact.contract_tasks_v2s?.map((task) => {
          taskPendingData.push({ ...task, contractData: contact });
        });
      });
      setPendingData(taskPendingData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPendingTasks();
  }, [tab]);

  const handleCompleteTask = (id, id1) => {
    let pending_new = [...pendingData];
    let index = pending_new?.findIndex((item) => item.id === id1);
    if (index > -1) {
      pending_new.splice(index, 1);
      setPendingData(pending_new);
    }
  };

  const handleArchiveTask = (id, id1, flag) => {
    let pending_new = [...pendingData];
    let index = pending_new?.findIndex((item) => item.id === id1);
    if (index > -1) {
      pending_new[index].archived = flag;
      setPendingData(pending_new);
    }
  };

  const columns = [
    {
      field: "street",
      headerName: "Transaction",
      renderCell: (params) => {
        return (
          <Box className={classes.TransactionAddressSec}>
            <img
              src={params?.row?.contractData?.property_img}
              alt={"property_img"}
            />
            <ListItemText
              sx={{
                "& .MuiTypography-root": { fontSize: "14px" },
                "& .MuiListItemText-secondary": { ntSize: "12px" },
              }}
              primary={params?.row?.contractData?.street}
              secondary={`${params?.row?.contractData?.city}, ${params?.row?.contractData?.state}  ${params?.row?.contractData?.zipcode}`}
            />
          </Box>
        );
      },
      width: 180,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "Agent",
      headerName: "Agent",
      sortComparator: (v1, v2) => v1?.first_name.localeCompare(v2?.first_name),
      renderCell: (params) => {
        // console.log(params);
        return (
          <Box className={classes.agentSection}>
            <Avatar
              src={
                params.row?.contractData?.agent?.profile_images
                  ?.profile_img_thumbnail
              }
              sx={{
                width: 20,
                height: 20,
                textTransform: "uppercase",
                fontWeight: "400",
                fontSize: "12px",
              }}
              variant="circle"
            >
              {params?.row?.contractData?.agent?.first_name?.slice(0, 1) +
                params?.row?.contractData?.agent?.last_name?.slice(0, 1)}
            </Avatar>
            <Typography
              sx={{ fontSize: "14px" }}
              variant="body2"
              color="inherit"
            >
              {params?.row?.contractData?.agent?.full_name}
            </Typography>
          </Box>
        );
      },
      width: 170,
      editable: false,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Task Name",
      align: "left",
      flex: 0.8,
      // renderCell: (params) => {
      //   return (
      //     <CustomTooltip title={params.row.status}>
      //       <Chip
      //         sx={{
      //           backgroundColor: "#000000",
      //           minWidth: "100px",
      //           textTransform: "capitalize",
      //         }}
      //         label={params.row.status}
      //         color="success"
      //         size="small"
      //       />
      //     </CustomTooltip>
      //   );
      // },
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      renderCell: ({ row }) => {
        // console.log(params);
        return (
          <Box>
            {row?.status === "completed" ? (
              row.complete_date ? (
                <Box
                  sx={{
                    color: "#02C12C",
                  }}
                  className={classes.dateArea}
                >
                  {" "}
                  <CheckRoundedIcon fontSize="small" sx={{ mt: "-3px" }} />{" "}
                  <Typography sx={{ fontSize: "14px" }}>
                    {momentTZ
                      .tz(
                        row.complete_date,
                        adminAuth?.adminDetail?.market?.timezone ||
                          process.env.REACT_APP_TIMEZONE
                      )
                      .format("L")}
                  </Typography>
                </Box>
              ) : null
            ) : (
              <Box
                sx={{
                  color:
                    momentTZ
                      .tz(
                        row.due_date,
                        adminAuth?.adminDetail?.market?.timezone ||
                          process.env.REACT_APP_TIMEZONE
                      )
                      .diff(
                        momentTZ.tz(
                          new Date(),
                          adminAuth?.adminDetail?.market?.timezone ||
                            process.env.REACT_APP_TIMEZONE
                        ),
                        "days"
                      ) < 0
                      ? "#EE0000"
                      : momentTZ
                          .tz(
                            row.due_date,
                            adminAuth?.adminDetail?.market?.timezone ||
                              process.env.REACT_APP_TIMEZONE
                          )
                          .diff(
                            momentTZ.tz(
                              new Date(),
                              adminAuth?.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            ),
                            "days"
                          ) < 3
                      ? "#EE8F00"
                      : "black",
                }}
                className={classes.dateArea}
              >
                {" "}
                {momentTZ
                  .tz(
                    row.due_date,
                    adminAuth?.adminDetail?.market?.timezone ||
                      process.env.REACT_APP_TIMEZONE
                  )
                  .diff(
                    momentTZ.tz(
                      new Date(),
                      adminAuth?.adminDetail?.market?.timezone ||
                        process.env.REACT_APP_TIMEZONE
                    ),
                    "days"
                  ) < 0 ? (
                  <ErrorRoundedIcon fontSize="small" sx={{ mt: "-3px" }} />
                ) : (
                  <AccessTimeRoundedIcon fontSize="small" sx={{ mt: "-3px" }} />
                )}{" "}
                <Typography sx={{ fontSize: "14px" }}>
                  {momentTZ
                    .tz(
                      row.due_date,
                      adminAuth?.adminDetail?.market?.timezone ||
                        process.env.REACT_APP_TIMEZONE
                    )
                    .format("L")}
                </Typography>
              </Box>
            )}{" "}
          </Box>
        );
      },
      width: 130,
      editable: false,
      sortable: false,
    },
    {
      field: "assigned_to",
      headerName: "Assigned to",
      flex: 0.6,
      renderCell: ({ row }) => {
        // console.log(params);
        return (
          <Box>
            {" "}
            {row?.assigned_to &&
            agentRosterList?.list.find(
              (item) => item.id === Number(row?.assigned_to)
            ) ? (
              <Box className={classes.dateArea}>
                <Avatar
                  src={
                    agentRosterList?.list.find(
                      (item) => item.id === Number(row?.assigned_to)
                    )?.profile_images?.profile_img
                  }
                  sx={{
                    width: 30,
                    height: 28,
                    textTransform: "uppercase",
                    fontWeight: "400",
                  }}
                  variant="circle"
                >
                  {agentRosterList?.list
                    .find((item) => item.id === Number(row?.assigned_to))
                    ?.first_name?.slice(0, 1)}
                </Avatar>
                <Typography sx={{ fontSize: "14px" }}>
                  {
                    agentRosterList?.list.find(
                      (item) => item.id === Number(row?.assigned_to)
                    )?.full_name
                  }
                </Typography>
              </Box>
            ) : (
              <Box className={classes.dateArea}>
                {" "}
                <WorkRoundedIcon sx={{ color: "#757575" }} />
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  Transactions Dept
                </Typography>
              </Box>
            )}
          </Box>
        );
      },
      width: 100,
      type: "dateTime",
      editable: true,
      sortable: true,
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.6,
      renderCell: ({ row }) => {
        return (
          <Box>
            {row.status === "completed" ? (
              <CustomTooltip title="Already Completed">
                <IconButton>
                  <CheckRoundedIcon sx={{ color: "#02C12C" }} />
                </IconButton>
              </CustomTooltip>
            ) : (
              <CustomTooltip title="Complete Task">
                <IconButton
                  onClick={() => {
                    setCurrentTask(row);
                    setOpen(true);
                  }}
                >
                  <CheckIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </IconButton>
              </CustomTooltip>
            )}
            <IconButton
              onClick={() =>
                navigate(
                  `/transaction/contracts/contract-detail/${row.contractData.id}/tasks`
                )
              }
            >
              <VisibilityRounded sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </IconButton>
            {row.archived ? (
              <CustomTooltip
                // onClick={() => {
                //   setOpenArchive("unArchive");
                //   setCurrentTask(row);
                // }}
                title="Unarchive Task"
              >
                <IconButton>
                  <UnarchiveIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </IconButton>
              </CustomTooltip>
            ) : (
              <CustomTooltip title="Archive Task">
                <IconButton
                  onClick={() => {
                    setOpenArchive("archive");
                    setCurrentTask(row);
                  }}
                >
                  <ArchiveRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </IconButton>
              </CustomTooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box className={classes.tabsSection}>
        <Box className={classes.tabs}>
          <Box
            className={
              tab === "today" ? classes.tabsItemActive : classes.tabsItem
            }
            onClick={() => setTab("today")}
          >
            {" "}
            DUE TODAY
          </Box>
          <Box
            className={
              tab === "tomorrow" ? classes.tabsItemActive : classes.tabsItem
            }
            onClick={() => setTab("tomorrow")}
          >
            {" "}
            DUE TOMORROW
          </Box>
          <Box
            className={
              tab === "overdue" ? classes.tabsItemActive : classes.tabsItem
            }
            onClick={() => setTab("overdue")}
          >
            {" "}
            OVERDUE
          </Box>
        </Box>
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={pendingData?.length ? pendingData : []}
          columns={columns}
          loading={loading}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          rowHeight={56}
          disableColumnFilter
          disableColumnMenu
        />
      </Paper>
      <UpdateTaskStatus
        handleCompleteTask={handleCompleteTask}
        contractId={currentTask?.contractData?.id}
        open={open}
        id={currentTask?.id}
        setOpen={setOpen}
      />
      <ArchiveAndUnArchiveTask
        open={openArchive}
        id={currentTask?.id}
        handleArchiveTask={handleArchiveTask}
        contractId={currentTask?.contractData?.id}
        setOpen={setOpenArchive}
        taskDetail={currentTask}
      />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "ContractsTasksTabStyle" })(
  ContractsTasksTab
);
