import { Button as MuiButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { styleSheet } from "./style";

const Button = ({
  children,
  visible,
  classes,
  variant,
  themeVersion,
  ...props
}) => {
  const buttonType =
    themeVersion && variant && variant === "contained"
      ? classes.containedBlack
      : themeVersion && variant && variant === "outlined"
      ? classes.outlinedBlack
      : themeVersion && variant && variant === "text" ? classes.textBlack : "";
  return (
    <MuiButton
      variant={variant}
      className={buttonType}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default withStyles(styleSheet, { name: "ButtonStyle1" })(Button);
