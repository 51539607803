import React, { useState, useEffect, useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Stack } from "@mui/material";
import RecentCalls from "./recent";
import Contacts from "./contacts";
import KeypadPanel from "./keyPadPanel";
import { useDispatch, useSelector } from "react-redux";
import { getRecentCalls } from "../../redux/calls/getRecentCalls";
import { getContactList } from "../../redux/calls/getContactList";
import { withStyles } from "@mui/styles";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import { styleSheet } from "./style.js";
import { AppContext } from "../callPanel/callContext";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

function CallPanel(props) {
  let { classes, value, setValue } = props;
  const [search, setSearch] = useState(false);
  const [openAddContact, setOpenAddContact] = useState(false);
  const dispatch = useDispatch();
  const { missedCallFlag, setMissedCallFlag } = useContext(AppContext);
  const recentCalls = useSelector((state) => state.recentCalls);
  const contactList = useSelector((state) => state.contactList);
  const adminAuth = useSelector((state) => state.adminAuth);
  useEffect(() => {
    if (
      props.adminDetail?.twilio_call_extension?.id &&
      !recentCalls.isLoading &&
      (recentCalls.callData === undefined ||
        (recentCalls.offset > 0 && recentCalls.callData?.length && recentCalls.offset + 1 !== recentCalls.callData?.length / 10) ||
        missedCallFlag) &&
      adminAuth?.adminDetail?.permissions?.find((item) => item === "phone")
    ) {
      dispatch(
        getRecentCalls({
          id: props.adminDetail.twilio_call_extension.id,
          offset: recentCalls.offset,
        })
      );
      setMissedCallFlag(false);
    }
  }, [recentCalls.offset]);

  useEffect(() => {
    if (search || search === "") {
      dispatch(
        getContactList({
          pageNumber: 1,
          search: search ? search : "",
        })
      );
    } else if (!search) {
      if (
        !contactList.isLoading &&
        (contactList.contacts === undefined ||
          (contactList.pageNumber > 1 && contactList.contacts?.length && contactList.pageNumber !== contactList.contacts?.length / 9))
      ) {
        dispatch(
          getContactList({
            pageNumber: contactList.pageNumber,
            search: search ? search : "",
          })
        );
      }
    }
  }, [contactList.pageNumber, search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="call-panel-area">
      {adminAuth?.adminDetail?.permissions?.find((item) => item === "phone") && adminAuth?.adminDetail?.twilio_call_extension ? (
        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs value={value} onChange={handleChange} textColor="inherit" indicatorColor="secondary" aria-label="secondary tabs example">
              <Tab disabled={openAddContact} label="Recent" {...a11yProps(0)} tabIndex={0} />
              <Tab label="Contacts" {...a11yProps(1)} tabIndex={0} />
              <Tab disabled={openAddContact} label="Keypad" {...a11yProps(2)} tabIndex={0} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <RecentCalls handleCloseCall={props.handleCloseCall} adminAuth={adminAuth} {...props} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Contacts
              setSearch={setSearch}
              handleCloseCall={props.handleCloseCall}
              openAddContact={openAddContact}
              setOpenAddContact={setOpenAddContact}
              search={search}
              {...props}
            />
          </TabPanel>
          <TabPanel sx={{ pt: 3 }} value={value} index={2}>
            <KeypadPanel adminDetail={props.adminDetail} handleCloseCall={props.handleCloseCall} {...props} />
          </TabPanel>
        </Box>
      ) : (
        <Box className={classes.PhoneCallNotAllowArea}>
          <Box>
            Need calling enabled?
            <br />
            <br />
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
              <PhoneRoundedIcon sx={{ fontSize: "88px", color: "rgba(0, 0, 0, 0.54)" }} />
              <QuestionMarkRoundedIcon sx={{ fontSize: "88px", color: "rgba(0, 0, 0, 0.54)" }} />
            </Stack>
            <br />
            Ask your admins to turn it on!
          </Box>
        </Box>
      )}
    </div>
  );
}
export default withStyles(styleSheet, { name: "CallPanelStyle" })(CallPanel);
