import React, { useState } from "react";
import { Container, Grid, Box, Card, CardHeader, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { styleSheet } from "./style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddPhoneExtension from "../modals/addExtension";
import { getAllPhoneExtensions } from "../../../redux/phoneConfiguration/extensions";
import TableSkeleton from "../../../components/loader/tableLoader";  
import UpdatePhoneExtension from "../modals/updateExtension"

const PhoneExtensions = (props) => {
  let { classes, search } = props;
  const dispatch = useDispatch();
  const [openAddExtension, setOpenAddExtension] = useState(false);
  const [openUpdateExtension, setOpenUpdateExtension] = useState(false);

  const extensionsList = useSelector((item) => item.phoneConfiguration.PhoneExtensions.extensionsList);

  const { isLoading } = extensionsList;

  useEffect(() => {
    if (extensionsList?.data?.length === 0 && !extensionsList.isLoading) {
      dispatch(getAllPhoneExtensions({}));
    }
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Extension",
      type:"number",
      renderCell: ({ row }) => {
        return <Box>{row?.name}</Box>;
      },
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "users_v2.first_name",
      headerName: "User",
      renderCell: ({ row }) => {
        return <Box>{row?.users_v2 ? row?.users_v2?.first_name + " " + row?.users_v2?.last_name : ""}</Box>;
      },
      width: 220,
      sortComparator: (v1, v2) => v1.first_name.localeCompare(v2?.first_name),
      editable: false,
      sortable: true,
    },

    {
      field: "Direct Number",
      headerName: "Direct Number",
      renderCell: ({ row }) => {
        return <Box>{row.twilio_number?.number}</Box>;
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "Last Seen / Response Time",
      headerName: "Last Seen / Response Time",
      renderCell: ({ row }) => {
        return row?.last_call?.fromNumber ? (
          <Box>
            {moment(row.updatedAt).format("l")} {moment(row.updatedAt).format("LT")} from {row?.last_call?.fromNumber}
          </Box>
        ) : (
          ""
        );
      },
      width: 350,
      editable: false,
      sortable: true,
    },
    {
      field: "Action",
      headerName: "Action",
      renderCell: ({ row }) => {
        return (
          <Button
            variant="contained"
            size="small"
            color="inherit"
            sx={{
              backgroundColor: "white",
              color: "#000000",
              fontWeight: "400",
            }}
            onClick={()=>setOpenUpdateExtension(row)}
          >
            Edit Extension
          </Button>
        );
      },
      width: 250,
     type:"actions",
      editable: false,
      sortable: false,
    },
  ];
  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.pageRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card>
                <CardHeader
                  classes={{ title: classes.pageHeading }}
                  title={
                    <Box className={classes.pageHeading}>
                      Extensions{" "}
                      <Button onClick={() => setOpenAddExtension(true)} classes={{ root: classes.addPhoneButton }}>
                        Add extension
                      </Button>
                    </Box>
                  }
                />
                <Box className={classes.tableRoot}>
                  <DataGridPro
                     components={{
                      LoadingOverlay: TableSkeleton
                    }}
                  columns={columns} rows={extensionsList.data ? extensionsList.data : []} loading={isLoading} rowHeight={56} />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <UpdatePhoneExtension open={openUpdateExtension}setOpen={setOpenUpdateExtension} />
      <AddPhoneExtension open={openAddExtension} setOpen={setOpenAddExtension} />
    </>
  );
};

export default withStyles(styleSheet, { name: "PhoneExtensionsStyle" })(PhoneExtensions);
