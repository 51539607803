import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const addPhoneAudio = createAsyncThunk("PhoneAudios/addPhoneAudio", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/phone-config/audio`, data, {
      headers: {
        'Accept': 'application/json',
       'Content-Type': 'multipart/form-data',
     },
    });
    return resp.data?.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
