import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const sendEmailLead = createAsyncThunk("Leads/sendEmailLeads", async (data, thunkAPI) => {
  let isCommon = data.isCommon;
  delete data.isCommon;
  try {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/${isCommon ? "common/send-email" : "leads/sendEmail"}`, data, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
