import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createNoteLead } from "../../../../../redux/leads/addNote";
import ResponseAlert from "../../../../../components/responseAlert";
import {useSelector} from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "./mentions/defaultStyle.js";
import defaultMentionStyle from "./mentions/defaultMentionStyle";
export const NoteTab = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [noteText, setNoteText] = useState("");
  const [userIds, setUserIds] = useState([]);

  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const adminAuth = useSelector((state) => state.adminAuth);
  const createNoteFunc = async () => {
    if (noteText && noteText.replaceAll("\n", "")) {
    const schema = {
      personId: id,
      body: noteText,
      userIds:userIds.filter((item,index)=>userIds.indexOf(item)===index)
    };
    if (noteText) {
      setLoading(true);
      let res = await dispatch(createNoteLead({ ...schema }));
      if (res.payload) {
        setLoading(false);
        setErrorAlert({
          errorMsg: "You have created note successfully!",
          errorType: "success",
          isOpen: true,
        });
      }
    } else {
      setErrorAlert({
        isOpen: true,
        errorType: "error",
        errorMsg: "You can't create empty notes!",
      });
    }
    setNoteText("");
    setUserIds([])
  }
  };

  return (
    <>
     <MentionsInput
            value={noteText}
            onChange={(event, newValue, newPlainTextValue, mentions) => {
              setUserIds(mentions?.map((item) => item.id));
              setNoteText(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                createNoteFunc();
                setNoteText("");
              }
            }}
            style={defaultStyle}
          >
            <Mention
              trigger="@"
              data={
                agentRosterList?.list?.length
                  ? agentRosterList?.list
                      ?.filter((it) => it.id !== adminAuth?.adminDetail?.id)
                      ?.map((item) => ({
                        id: item.id,
                        display: item?.first_name + " " + item.last_name,
                      }))
                  : []
              }
              style={defaultMentionStyle}
              markup={"@[__display__^__id__]"}
              // renderSuggestion={this.renderUserSuggestion}
            />
          </MentionsInput>
   <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        <LoadingButton loadingPosition="start" loading={loading} onClick={createNoteFunc} variant="contained">
          Create note
        </LoadingButton>
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </>
  );
};
