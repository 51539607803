import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getPowerSearchData = createAsyncThunk("powerSearch/getPowerSearchData", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/common/power-search?search=${data.search ? data.search : ""}&skip=${data.skip ? data.skip : ""}&see_all=${
        data.see_all || ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (resp.data?.data) {
      return resp.data?.data;
    } else {
      return resp.data;
    }
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
