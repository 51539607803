import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Avatar from "../../components/uiElements/avatar";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import { getEmails, handleOpenEmail } from "../../redux/sendEmailAs";
import { useDispatch, useSelector } from "react-redux";
import momentTZ from "moment-timezone";
import { CustomTooltip } from "../../components/shared/tooltip";
import EmailListSkelton from "./skelton/emailList";
import ChatIcon from "@mui/icons-material/Chat";
function EmailList(props) {
  let { classes, openedEmail } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const emailsList = useSelector((state) => state.SendEmailAs.emailsList);
  const adminDetail = useSelector((state) => state.adminAuth.adminDetail);

  const handleGetEmails = (params) => {
    dispatch(getEmails(params));
  };
  const handleScrollEvent = (e) => {
    //console.log(e);
    let bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2;
    if (bottom && !emailsList.isLoading) {
      if (emailsList?.data?.length < emailsList.count) {
        let currentPageNumber = Math.ceil(emailsList?.data?.length / 20);

        handleGetEmails({
          pageNumber: currentPageNumber + 1,
        });
      }
    }
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(
        () => handleGetEmails({ pageNumber: 1, reset: true, search }),
        1000
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const handleReturnDate = (date) => {
    const createdAtMoment = momentTZ.tz(
      date,
      adminDetail?.market?.timezone || process.env.REACT_APP_TIMEZONE
    );

    const currentYear = momentTZ().year();
    const emailYear = createdAtMoment.year();

    const formattedDate = createdAtMoment.format("MMM Do");

    // Conditionally add the year if it's different from the current year
    const displayDate =
      emailYear === currentYear
        ? formattedDate
        : `${createdAtMoment.format("MMM Do YY")}`;

    return displayDate;
  };

  return (
    <Box>
      {" "}
      <TextField
        placeholder="Search"
        fullWidth
        size="small"
        type="search"
        onChange={(e) => setSearch(e.target.value)}
        className={classes.searchInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small">
                <SearchIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box className={classes.emailsListArea}>
        <Box className={classes.emailsList} onScroll={handleScrollEvent}>
          {emailsList?.data?.length ? (
            emailsList.data.map((email, index) => (
              <Box
                onClick={() => dispatch(handleOpenEmail(email))}
                className={classes.emailsListItem}
                sx={{
                  background: openedEmail.id === email.id ? "" : "#F0F0F0",
                  border:
                    openedEmail.id === email.id ? "" : "1px solid #F0F0F0",
                }}
              >
                <Box className={classes.itemHeader}>
                  <Box className={classes.itemHeaderLeft}>
                    <Avatar
                      src={
                        email?.sentAs?.profile_images?.profile_img ||
                        email?.sentAs?.profile_images?.profile_img_thumbnail
                      }
                      variant="circle"
                      sx={{ width: "36px", height: "36px", fontSize: "12px" }}
                    >
                      {`${email?.sentAs?.full_name?.slice(
                        0,
                        1
                      )}${email?.sentAs?.full_name
                        ?.split(" ")?.[1]
                        ?.slice(0, 1)}`}
                    </Avatar>
                    <Box className={classes.profileDetail}>
                      <Typography className={classes.profileDetailName}>
                        {email?.sentAs?.full_name}
                      </Typography>
                      <Typography className={classes.profileDetailAddress}>
                        {email.subject}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className={classes.itemHeaderRight}>
                    {momentTZ
                      .tz(
                        email?.createdAt,
                        adminDetail?.market?.timezone ||
                          process.env.REACT_APP_TIMEZONE
                      )
                      .format("LT")}{" "}
                    {handleReturnDate(email?.createdAt)}
                  </Typography>
                </Box>
                <Box className={classes.itemBottomDetail}>
                  <Box className={classes.itemSubjectArea}>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: email.body
                          ?.replaceAll("<br/>", "")
                          ?.replaceAll("<br>", "")
                          ?.split("<table")[0],
                      }}
                      className={classes.itemMessage}
                    ></Typography>
                  </Box>
                  <Box className={classes.itemAttachmentArea}>
                    {email?.attachments?.length ? (
                      <IconButton size="small">
                        <AttachFileRoundedIcon fontSize="small" />
                      </IconButton>
                    ) : null}
                    <CustomTooltip
                      title={`Sent by ${email?.sentBy?.full_name}`}
                      placement="top"
                    >
                      <Box>
                        {" "}
                        <Avatar
                          variant="circle"
                          src={
                            email?.sentBy?.profile_images?.profile_img ||
                            email?.sentBy?.profile_images?.profile_img_thumbnail
                          }
                          sx={{
                            width: "28px",
                            height: "28px",
                            fontSize: "13px",
                          }}
                        >
                          {`${email?.sentBy?.full_name?.slice(
                            0,
                            1
                          )}${email?.sentBy?.full_name
                            ?.split(" ")?.[1]
                            ?.slice(0, 1)}`}
                        </Avatar>
                      </Box>
                    </CustomTooltip>
                  </Box>
                </Box>
              </Box>
            ))
          ) : !emailsList.isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
              }}
            >
              <center>
                <ChatIcon sx={{ fontSize: "100px", color: "lightgray" }} />
                <br />
                Not Exists
              </center>
            </Box>
          ) : null}
          {emailsList?.isLoading ? <EmailListSkelton count={8} /> : ""}
        </Box>
      </Box>
    </Box>
  );
}

export default EmailList;
