import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  useMediaQuery,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Switch from "@mui/material/Switch";
import ProjectGrid from "./projects";
import Dialog from "@mui/material/Dialog";
import CreateProject from "./projects/CreateProject";
import TaskGrid from "./tasks/index";
import {
  getDepartmentList,
  setSelectedTab,
  setSearchTextField,
  changeKanbanView,
} from "../../redux/projects/common";
import { setProjectCurrentPageNo } from "../../redux/projects/projects";
import { setTaskCurrentPageNo } from "../../redux/projects/tasks";
import DepartmentMenu from "./DepartmentMenu";
import debounce from "lodash.debounce";
import Clear from "@mui/icons-material/Clear";
import TaskKanbanView from "./tasks/KanbanView";
import ProjectKanbanView from "./projects/KanbanView";
import ProjectDetails from "./projects/ProjectDetails";
import {
  closeProjectDetails,
  closeTaskDetails,
  openProjectDetails,
  openTaskDetails,
} from "../../redux/projects/project";
import TaskDetails from "./tasks/TaskDetails";
import { Search } from "@mui/icons-material";
import { useStyles } from "./style";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: "100%", height: "100%", paddingBottom: "30px" }}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0B0909",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#0B0909",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Project = () => {
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [openProjectForm, setOpenProjectForm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openComments, setOpenComments] = useState(true);
  const hideSearchBar = useMediaQuery("(min-width:1100px)");

  const {
    isProjectDetailsOpen,
    isTaskDetailsOpen,
    selectedProjectId,
    selectedTaskId,
  } = useSelector((state) => state.project);
  const kanbanView = useSelector((state) => state.project.kanbanView);
  const departments = useSelector((state) => state.project.departments);
  const selectedTab = useSelector((state) => state.project.selectedTab);

  const [isKanbanView, setIsKanbanView] = useState(
    kanbanView === "true" ? true : false
  );
  /* End Region: Variables and States
   */
  /**
   * Helpers
   */
  const setOpenTaskDetails = (flag) => {
    flag ? dispatch(openTaskDetails(true)) : dispatch(closeTaskDetails(false));
  };

  const setOpenProjectDetails = (flag) => {
    flag
      ? dispatch(openProjectDetails(true))
      : dispatch(closeProjectDetails(false));
  };

  const handleViewChange = (event) => {
    setIsKanbanView(event.target.checked);
    dispatch(changeKanbanView(event.target.checked));
  };

  const handleChange = (event, newValue) => {
    dispatch(setSelectedTab(newValue));
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const debouncedDispatch = useCallback(
    debounce((s) => dispatch(setSearchTextField(s)), 500),
    []
  );
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  useEffect(() => {
    dispatch(getDepartmentList());
  }, []);

  useEffect(() => {
    dispatch(setTaskCurrentPageNo(1));
    dispatch(setProjectCurrentPageNo(1));
    debouncedDispatch(searchText);
  }, [searchText]);

  
  useEffect(() => {
    if (state?.task_id) {
      dispatch(setSelectedTab(1));
    }
    if (state?.project_id) {
      dispatch(setSelectedTab(0));
    }
  }, [state?.task_id,state?.project_id]);

  /**
   * End Region: useEffect Hooks
   */

  return (
    <Box>
      {isProjectDetailsOpen && selectedProjectId && (
        <ProjectDetails
          projectID={selectedProjectId}
          setOpenComments={setOpenComments}
          openComments={openComments}
          open={isProjectDetailsOpen}
          setOpen={setOpenProjectDetails}
        />
      )}
      {isTaskDetailsOpen && selectedTaskId && (
        <TaskDetails
          setOpenComments={setOpenComments}
          openComments={openComments}
          taskID={selectedTaskId}
          open={isTaskDetailsOpen}
          setOpen={setOpenTaskDetails}
        />
      )}

      <AppBar
        position="sticky"
        className={classes.appbarRoot}
        sx={{ position: "sticky", top: "65px", zIndex: "100" }}
        elevation={0}
      >
        <div className={classes.appbar}>
          <div className={classes.appbarLeft}>
            <Box className={classes.UsersTabsUI}>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                sx={{
                  paddingTop: "6px !important",
                  "& .Mui-selected": {
                    color: "#0B0909 !important",
                  },
                  "& .MuiTab-root": {
                    fontSize: "13px !important",
                    minHeight: "40px !important",
                  },
                  "& .MuiTabs-root": {
                    minHeight: "40px  !important",
                  },
                  "& .MuiTabs-scroller": {
                    height: "36px  !important",
                  },
                }}
                aria-label="project tabs"
              >
                <Tab
                  disableRipple={true}
                  disableFocusRipple={true}
                  label="PROJECTS"
                />
                <Tab
                  disableRipple={true}
                  disableFocusRipple={true}
                  label="ALL TASKS"
                />
                <Tab
                  disableRipple={true}
                  disableFocusRipple={true}
                  label="ASSIGNED TO ME"
                />
              </Tabs>
              <DepartmentMenu
                departments={departments}
                handleDepartmentChange={handleChange}
              />
            </Box>
          </div>
          <div className={classes.appbarRight}>
            <FormControlLabel
              sx={{
                color: "#0B0909",
                gap: "7px",
                "& .MuiTypography-root": { whiteSpace: "nowrap" },
              }}
              control={
                <IOSSwitch
                  checked={isKanbanView}
                  onChange={handleViewChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Kanban View"
            />

            {openProjectForm && (
              <Dialog
                disablePortal
                open={openProjectForm}
                onClose={() => setOpenProjectForm(false)}
                fullWidth={true}
                maxWidth={"lg"}
                sx={{
                  "& .MuiDialog-container": { height: "95vh", mt: "50px" },
                }}
              >
                {" "}
                <CreateProject
                  open={openProjectForm}
                  setOpen={setOpenProjectForm}
                  projectModule={true}
                />
              </Dialog>
            )}
            <Button
              size="small"
              className={classes.button}
              onClick={() => setOpenProjectForm(true)}
            >
              <CreateNewFolderIcon sx={{ marginRight: "5px" }} /> New Project
            </Button>
            {hideSearchBar && (
              <TextField
                size="small"
                placeholder="Search"
                value={searchText}
                onChange={(e) => handleSearchTextChange(e)}
                sx={{
                  marginLeft: "15px!important",
                  "& .MuiInputBase-root": {
                    paddingRight: "0!important",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      sx={{
                        visibility:
                          searchText.length > 0 ? "visible" : "hidden",
                      }}
                      onClick={() => setSearchText("")}
                    >
                      <Clear />
                    </IconButton>
                  ),
                }}
              />
            )}
          </div>
        </div>
      </AppBar>
      <Container maxWidth={isKanbanView ? "" : "xl"}>
        <div className={classes.pageRoot}>
          <TabPanel
            value={selectedTab}
            index={0}
            className={classes.tabSection}
          >
            {isKanbanView && <ProjectKanbanView />}
            {!isKanbanView && <ProjectGrid />}
          </TabPanel>

          <TabPanel
            value={selectedTab}
            index={1}
            className={classes.tabSection}
          >
            {isKanbanView && <TaskKanbanView value={selectedTab} />}
            {!isKanbanView && <TaskGrid value={selectedTab} />}
          </TabPanel>

          <TabPanel
            value={selectedTab}
            index={2}
            className={classes.tabSection}
          >
            {isKanbanView && <TaskKanbanView value={selectedTab} />}
            {!isKanbanView && <TaskGrid value={selectedTab} />}
          </TabPanel>

          {departments.map((department, idx) => {
            return (
              <TabPanel
                value={selectedTab}
                index={idx + 3}
                className={classes.tabSection}
              >
                {isKanbanView && (
                  <TaskKanbanView
                    value={selectedTab}
                    departmentID={department.id}
                  />
                )}
                {!isKanbanView && (
                  <TaskGrid value={selectedTab} departmentID={department.id} />
                )}
              </TabPanel>
            );
          })}

          <TabPanel // Completed Project List
            value={selectedTab}
            index={departments.length + 3}
            className={classes.tabSection}
          >
            {isKanbanView && <ProjectKanbanView value={selectedTab} />}
            {!isKanbanView && <ProjectGrid value={selectedTab} />}
          </TabPanel>

          <TabPanel // Canceled Project List
            value={selectedTab}
            index={departments.length + 4}
            className={classes.tabSection}
          >
            {isKanbanView && <ProjectKanbanView value={selectedTab} />}
            {!isKanbanView && <ProjectGrid value={selectedTab} />}
          </TabPanel>
        </div>
      </Container>
    </Box>
  );
};

export default Project;
