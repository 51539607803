import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Divider,
  Typography,
  Stack,
  TextField,
  InputLabel,
  IconButton,
  Button,
  Autocomplete as MuiAutocomplete,
  Container,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Loading from "../../../../components/shared/loading";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SubHeader from "../subHeader";
import PropertySearchMap from "./map";
import { GetPropertyFmlsSearch, getSearchSuggestionData } from "../../../../redux/listings/listingFmlsDetail";
import { useDispatch, useSelector } from "react-redux";
import ListingsTable from "./list";
import GoogleMapReact from "google-map-react";
import Autocomplete from "react-google-autocomplete";
import ListingUISkelton from "./skeltons/search"

const mlsStatusData = [
  { status: "Active", range: "" },
  { status: "Active Under Contract", range: "" },
  { status: "Pending", range: "180" },
  { status: "Hold", range: "" },
  { status: "Withdrawn", range: "180" },
  { status: "Expired", range: "180" },
  { status: "Closed", range: "180" },
];

function NewSearchProperty(props) {
  let { classes, isShowSubHeader } = props;
  const [checked, setChecked] = useState([0]);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("criteria");
  const [notValues, setNotValues] = useState([]);
  const [isThreeDecimalPrice, setIsThreeDecimalPrice] = useState(false);
  const [searchDetail, setSearchDetail] = useState({
    ListingId: "",
    propertyAddress: "",
    MlsStatus: [],
    PropertySubType: "",
    mlsNumber: "",
    Price: "",
    BedroomsTotal: "",
    BathroomsFull: "",
    YearBuilt: "",
    LotSizeAcres: "",
    BuildingAreaTotal: "",
    Miles: 0.5,
    Country: "US",
    CountryNot: "",
    StateOrProvince: "",
    StateOrProvinceNot: "",
    City: "",
    CityNot: "",
    PostalCode: "",
    PostalCodeNot: "",
    ElementarySchool: "",
    ElementarySchoolNot: "",
    HighSchool: "",
    HighSchoolNot: "",
    MiddleOrJuniorSchool: "",
    MiddleOrJuniorSchoolNot: "",
    Latitude: "",
    Longitude: "",
    WaterBodyName: "",
  });
  const listDetail = useSelector((state) => state.listings?.FMLSListDetail?.newSearchDetail);
  const suggestions = useSelector((state) => state.listings?.FMLSListDetail?.suggestions);
  const adminAuth = useSelector((state) => state.adminAuth);
  useEffect(() => {
    if (!suggestions.data?.schools) {
      dispatch(getSearchSuggestionData({ id: adminAuth?.adminDetail?.market?.id }));
    }
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleUpdateDetail = (field, value) => {
    setSearchDetail({ ...searchDetail, [field]: value });
  };

  const handleReturnSubProperties = () => {
    if (suggestions.data?.property_types) {
      let propertyData = [];
      suggestions.data?.property_types?.map((item, index) => {
        if (checked?.length) {
          if (checked.includes(index)) {
            propertyData = propertyData.concat(suggestions.data?.property_types[index].sub_type);
          }
        } else {
          propertyData = propertyData.concat(suggestions.data?.property_types[index].sub_type);
        }
      });

      return propertyData;
    } else {
      return [];
    }
  };
  
  const handleUpdateNotValues = (checked, field) => {
    if (checked) {
      let new_notValues = [...notValues];
      new_notValues.push(field);
      setNotValues(new_notValues);
    } else {
      let new_notValues = [...notValues];
      setNotValues(new_notValues.filter((item) => item !== field));
    }
  };

  const handleUpdateMlsStatusValue = (status) => {
    let new_mlsStatus = [...searchDetail.MlsStatus];
    if (searchDetail.MlsStatus?.find((ite) => ite.name === status)) {
      setSearchDetail({ ...searchDetail, MlsStatus: new_mlsStatus.filter((item) => item.name !== status) });
    } else {
      let obj = {
        name: status,
        range: "",
      };
      new_mlsStatus.push(obj);
      setSearchDetail({ ...searchDetail, MlsStatus: new_mlsStatus });
    }
  };

  const handleUpdateMlsStatusRangeValue = (value, status, range) => {
    if ((value > 0 && value < range) || value === "") {
      let new_mlsStatus = [...searchDetail.MlsStatus];
      if (searchDetail.MlsStatus?.find((ite) => ite.name === status)) {
        let mlsData = searchDetail.MlsStatus?.find((ite) => ite.name === status);
        mlsData.range = value;
        setSearchDetail({ ...searchDetail, MlsStatus: new_mlsStatus });
      } else {
        let obj = {
          name: status,
          range: value,
        };
        new_mlsStatus.push(obj);
        setSearchDetail({ ...searchDetail, MlsStatus: new_mlsStatus });
      }
    }
  };

  const handleGetSearchResult = () => {
    setActiveTab("result");
    let obj = {
      ...searchDetail,
      PropertyType: checked?.length ? checked.join(", ") : "",
      Price: isThreeDecimalPrice ? Number(searchDetail.Price + "000") : searchDetail.Price,
      Country: notValues.includes("country") ? undefined : searchDetail.Country,
      CountryNot: notValues.includes("country") ? searchDetail.Country : undefined,
      StateOrProvince: notValues.includes("StateOrProvince") ? undefined : searchDetail.StateOrProvince,
      StateOrProvinceNot: notValues.includes("StateOrProvince") ? searchDetail.StateOrProvince : undefined, //
      City: notValues.includes("City") ? undefined : searchDetail.City,
      CityNot: notValues.includes("City") ? searchDetail.City : undefined, //
      PostalCode: notValues.includes("PostalCode") ? undefined : searchDetail.PostalCode,
      PostalCodeNot: notValues.includes("PostalCode") ? searchDetail.PostalCode : undefined, //
      ElementarySchool: notValues.includes("ElementarySchool") ? undefined : searchDetail.ElementarySchool,
      ElementarySchoolNot: notValues.includes("ElementarySchool") ? searchDetail.ElementarySchool : undefined, //
      HighSchool: notValues.includes("HighSchool") ? undefined : searchDetail.HighSchool,
      HighSchoolNot: notValues.includes("HighSchool") ? searchDetail.HighSchool : undefined, //
      MiddleOrJuniorSchool: notValues.includes("MiddleOrJuniorSchool") ? undefined : searchDetail.MiddleOrJuniorSchool,
      MiddleOrJuniorSchoolNot: notValues.includes("MiddleOrJuniorSchool") ? searchDetail.MiddleOrJuniorSchool : undefined, //
    };
    if (!searchDetail.Longitude && !searchDetail.Latitude) {
      delete obj["Miles"];
    }
    Object.keys(obj).map((item) => {
      if (!obj[item]) {
        delete obj[item];
      }
    });
    if (searchDetail?.MlsStatus?.length === 0) {
      delete obj["MlsStatus"];
    }

    dispatch(GetPropertyFmlsSearch(obj));
  };
  return (
    <Box>
      {isShowSubHeader && <SubHeader {...props} />}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDDyzxuxXFRmkI7ttiKguogSIh8sJmDb9g",
          libraries: ["places"],
        }}
      />
      <Container maxWidth="lg">
        <Box className={classes.newSearchPropertyDetail}>
          {suggestions.isLoading ? (
             <ListingUISkelton />
          ) : (
            <Grid container spacing={2}>
              <Grid item md={3} sm={12}></Grid>
              <Grid item md={6} sm={12}></Grid>
              <Grid item md={3} sm={12}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Button
                    onClick={() => setActiveTab("criteria")}
                    className={activeTab === "criteria" ? classes.resultActiveButton : classes.resultDefaultButton}
                    color="inherit"
                    size="small"
                  >
                    Criteria
                  </Button>
                  <Button
                    color="inherit"
                    onClick={() => setActiveTab("map")}
                    className={activeTab === "map" ? classes.resultActiveButton : classes.resultDefaultButton}
                  >
                    Map
                  </Button>
                  <Button
                    color="inherit"
                    onClick={() => handleGetSearchResult()}
                    className={activeTab === "result" ? classes.resultActiveButton : classes.resultDefaultButton}
                  >
                    Results
                  </Button>
                </Stack>
              </Grid>

              <Grid hidden={activeTab !== "map"} item sm={12}>
                <PropertySearchMap {...props} />
              </Grid>
              <Grid hidden={activeTab !== "result"} item sm={12}>
                <ListingsTable loading={listDetail.isLoading} {...props} />
              </Grid>

              <Grid hidden={activeTab !== "criteria"} item md={3} sm={12}>
                <Card variant="outlined">
                  <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                    {suggestions.data?.property_types?.map((item, value) => {
                      const labelId = `checkbox-list-label-${value}`;
                      return (
                        <ListItem key={value} disablePadding>
                          <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${item.type}`} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Divider />
                  <Box className={classes.propertySubType}>
                    <Typography className={classes.propertySubTypeHead}>Property Subtype</Typography>
                    <Card variant="outlined">
                      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                        {handleReturnSubProperties().map((item, value) => {
                          return (
                            <ListItem
                              selected={searchDetail.PropertySubType === item}
                              onClick={() => handleUpdateDetail("PropertySubType", item)}
                              key={value}
                              disablePadding
                            >
                              <ListItemButton role={undefined} dense>
                                <ListItemText primary={`${item}`} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Card>
                  </Box>
                  <Divider />
                  <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                    {mlsStatusData.map((item, value) => {
                      const labelId = `checkbox-list-property-label-${value}`;
                      return (
                        <ListItem
                          key={value}
                          disablePadding
                          secondaryAction={
                            <Stack direction={"row"} alignItems="center" spacing={1}>
                              <TextField
                                value={searchDetail?.MlsStatus?.find((data) => data.name === item.status)?.range}
                                onChange={(e) => handleUpdateMlsStatusRangeValue(e.target.value, item.status, Number(item.range))}
                                type={"number"}
                                placeholder={`${item.range ? "0-" : ""}${item.range}`}
                                sx={{ width: "91px" }}
                                size="small"
                              />
                              <IconButton sx={{ padding: "2px" }}>
                                <DateRangeRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                              </IconButton>
                              {/*  <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Custom input"
                            value={date}
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <TextField ref={inputRef} {...inputProps} sx={{ width: "91px" }} size="small" />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider> */}
                            </Stack>
                          }
                        >
                          <ListItemButton role={undefined} onClick={() => handleUpdateMlsStatusValue(item.status)} dense>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              <Checkbox
                                edge="start"
                                checked={searchDetail.MlsStatus?.find((ite) => ite.name === item.status) ? true : false}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${item.status === "Active Under Contract" ? "ActiveUC" : item.status}`} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Card>
              </Grid>
              <Grid hidden={activeTab !== "criteria"} item md={6} sm={12}>
                <Card variant="outlined" className={classes.newSearchMiddleArea}>
                  {" "}
                  <Typography className={classes.propertySubTypeHead}>Map Search</Typography>
                  <Grid container spacing={2} sx={{ mt: "13px" }}>
                    <Grid item sm={8}>
                      <Autocomplete
                        apiKey={"AIzaSyDDyzxuxXFRmkI7ttiKguogSIh8sJmDb9g"}
                        onPlaceSelected={(place) => {
                          setSearchDetail({
                            ...searchDetail,
                            Latitude: place.geometry?.location?.lat(),
                            Longitude: place.geometry?.location?.lng(),
                          });
                        }}
                        options={{
                          types: ["(regions)"],
                          componentRestrictions: { country: "us" },
                        }}
                        placeholder="Enter a property address"
                        style={{ width: "100%",border:"none",borderBottom:"2px solid lightgrey",padding:"9px 2px",outline:"none" }}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <ButtonGroups
                        minWidth="110px"
                        size="small"
                        borderColor="lightgrey"
                        bgColor="white"
                        bgColor1="rgba(33, 33, 33, 0.04)"
                        bgColorHover="rgba(33, 33, 33, 0.04)"
                        color="#0B0909"
                        height="35px"
                        fontSize="11px"
                        placeholder={"Distance"}
                        value={searchDetail.Miles}
                        options={[
                          { title: "0.1 Mile", value: 0.1 },
                          { title: "0.25 MIle", value: 0.25 },
                          { title: "0.5 Mile", value: 0.5 },
                          { title: "1 Mile", value: 0.5 },
                          { title: "1.5 Mile", value: 1 },
                          { title: "2 Mile", value: 2 },
                          { title: "5 Mile", value: 5 },
                          { title: "10 Mile", value: 10 },
                          { title: "15 Mile", value: 15 },
                          { title: "20 Mile", value: 20 },
                        ]}
                        onChangeMenu={(value) => handleUpdateDetail("Miles", value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: "13px" }}>
                    <Grid item sm={5}>
                      <InputLabel className={classes.searchInputLabel}>Country</InputLabel>
                      <TextField value={"United States"} size="small" variant="outlined" fullWidth />
                      <b>Or </b>{" "}
                      <FormControlLabel
                        sx={{ ml: "6px" }}
                        control={
                          <Switch onChange={(e) => handleUpdateNotValues(e.target.checked, "country")} checked={notValues.includes("country")} />
                        }
                        label="Not"
                      />
                    </Grid>
                    <Grid item sm={1} sx={{ paddingLeft: "0px !important" }}>
                      <IconButton sx={{ mt: "17px" }}>
                        <ListAltRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "35px" }} />
                      </IconButton>
                    </Grid>
                    <Grid item sm={5}>
                      <InputLabel className={classes.searchInputLabel}>State</InputLabel>
                      <TextField
                        onChange={(e) => handleUpdateDetail("StateOrProvince", e.target.value)}
                        value={searchDetail?.StateOrProvince}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                      <b>Or </b>{" "}
                      <FormControlLabel
                        sx={{ ml: "6px" }}
                        control={
                          <Switch
                            onChange={(e) => handleUpdateNotValues(e.target.checked, "StateOrProvince")}
                            checked={notValues.includes("StateOrProvince")}
                          />
                        }
                        label="Not"
                      />
                    </Grid>
                    <Grid item sm={1} sx={{ paddingLeft: "0px !important" }}>
                      <IconButton sx={{ mt: "17px" }}>
                        <ListAltRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "35px" }} />
                      </IconButton>
                    </Grid>

                    <Grid item sm={5}>
                      <InputLabel className={classes.searchInputLabel}>city</InputLabel>
                      <MuiAutocomplete
                        freeSolo
                        options={suggestions.data?.cities?.map((option) => option.name)}
                        value={searchDetail.City}
                        onChange={(event, newValue) => {
                          handleUpdateDetail("City", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                      <b>Or </b>{" "}
                      <FormControlLabel
                        sx={{ ml: "6px" }}
                        control={<Switch onChange={(e) => handleUpdateNotValues(e.target.checked, "City")} checked={notValues.includes("City")} />}
                        label="Not"
                      />
                    </Grid>
                    <Grid item sm={1} sx={{ paddingLeft: "0px !important" }}>
                      <IconButton sx={{ mt: "17px" }}>
                        <ListAltRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "35px" }} />
                      </IconButton>
                    </Grid>
                    <Grid item sm={6}>
                      <InputLabel className={classes.searchInputLabel}>Zip Code</InputLabel>
                      <MuiAutocomplete
                        freeSolo
                        options={suggestions.data?.zipCodes?.map((option) => option.name)}
                        value={searchDetail.PostalCode}
                        onChange={(event, newValue) => {
                          handleUpdateDetail("PostalCode", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                      <b>Or </b>{" "}
                      <FormControlLabel
                        sx={{ ml: "6px" }}
                        control={
                          <Switch
                            onChange={(e) => handleUpdateNotValues(e.target.checked, "PostalCode")}
                            checked={notValues.includes("PostalCode")}
                          />
                        }
                        label="Not"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <InputLabel className={classes.searchInputLabel}>Subdivision / Complex</InputLabel>
                      <TextField
                        onChange={(e) => handleUpdateDetail("SubdivisionName", e.target.value)}
                        value={searchDetail?.SubdivisionName}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: "50px" }}>
                    <Grid item sm={5}>
                      <InputLabel className={classes.searchInputLabel}>Elementary School</InputLabel>
                      <MuiAutocomplete
                        freeSolo
                        options={suggestions.data?.schools ? suggestions.data?.schools["Elementary School"]?.map((option) => option.name) : {}}
                        value={searchDetail.ElementarySchool}
                        onChange={(event, newValue) => {
                          handleUpdateDetail("ElementarySchool", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                      <b>Or </b>{" "}
                      <FormControlLabel
                        sx={{ ml: "6px" }}
                        control={
                          <Switch
                            onChange={(e) => handleUpdateNotValues(e.target.checked, "ElementarySchool")}
                            checked={notValues.includes("ElementarySchool")}
                          />
                        }
                        label="Not"
                      />
                    </Grid>
                    <Grid item sm={1} sx={{ paddingLeft: "0px !important" }}>
                      <IconButton sx={{ mt: "17px" }}>
                        <ListAltRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "35px" }} />
                      </IconButton>
                    </Grid>
                    <Grid item sm={5}>
                      <InputLabel className={classes.searchInputLabel}>Middle School</InputLabel>
                      <MuiAutocomplete
                        freeSolo
                        options={suggestions.data?.schools ? suggestions.data?.schools["Middle School"]?.map((option) => option.name) : []}
                        value={searchDetail.MiddleOrJuniorSchool}
                        onChange={(event, newValue) => {
                          handleUpdateDetail("MiddleOrJuniorSchool", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                      <b>Or </b>{" "}
                      <FormControlLabel
                        sx={{ ml: "6px" }}
                        control={
                          <Switch
                            onChange={(e) => handleUpdateNotValues(e.target.checked, "MiddleOrJuniorSchool")}
                            checked={notValues.includes("MiddleOrJuniorSchool")}
                          />
                        }
                        label="Not"
                      />
                    </Grid>
                    <Grid item sm={1} sx={{ paddingLeft: "0px !important" }}>
                      <IconButton sx={{ mt: "17px" }}>
                        <ListAltRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "35px" }} />
                      </IconButton>
                    </Grid>

                    <Grid item sm={5}>
                      <InputLabel className={classes.searchInputLabel}>High School School</InputLabel>
                      <MuiAutocomplete
                        freeSolo
                        options={suggestions.data?.schools ? suggestions.data?.schools["High School"]?.map((option) => option.name) : []}
                        value={searchDetail.HighSchool}
                        onChange={(event, newValue) => {
                          handleUpdateDetail("HighSchool", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                      <b>Or </b>{" "}
                      <FormControlLabel
                        sx={{ ml: "6px" }}
                        control={
                          <Switch
                            onChange={(e) => handleUpdateNotValues(e.target.checked, "HighSchool")}
                            checked={notValues.includes("HighSchool")}
                          />
                        }
                        label="Not"
                      />
                    </Grid>
                    <Grid item sm={1} sx={{ paddingLeft: "0px !important" }}>
                      <IconButton sx={{ mt: "17px" }}>
                        <ListAltRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "35px" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid hidden={activeTab !== "criteria"} item md={3} sm={12}>
                <Card variant="outlined">
                  <Box className={classes.searchRightBlock}>
                    <InputLabel className={classes.searchInputLabel}>MLS #</InputLabel>
                    <TextField
                      onChange={(e) => handleUpdateDetail("mlsNumber", e.target.value)}
                      value={searchDetail?.mlsNumber}
                      size="small"
                      variant="outlined"
                      sx={{ width: "145px", mb: "15px" }}
                    />
                  </Box>
                  <Divider />
                  <Box className={classes.searchRightBlock}>
                    <InputLabel className={classes.searchInputLabel}>Price</InputLabel>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        onChange={(e) => handleUpdateDetail("Price", e.target.value)}
                        value={searchDetail?.Price}
                        size="small"
                        variant="outlined"
                        sx={{ width: "145px" }}
                      />
                      <FormControlLabel
                        control={<Checkbox onChange={(e) => setIsThreeDecimalPrice(e.target.checked)} checked={isThreeDecimalPrice} />}
                        label="(000)s"
                      />
                    </Stack>
                    <br />
                  </Box>
                  <Divider />
                  <Box className={classes.searchRightBlock}>
                    <InputLabel className={classes.searchInputLabel}>Total Bedrooms</InputLabel>
                    <TextField
                      onChange={(e) => handleUpdateDetail("BathroomsFull", e.target.value)}
                      value={searchDetail?.BathroomsFull}
                      size="small"
                      variant="outlined"
                      sx={{ width: "145px", mb: "15px" }}
                    />
                    <InputLabel className={classes.searchInputLabel}>Total Full Baths</InputLabel>
                    <TextField
                      onChange={(e) => handleUpdateDetail("BedroomsTotal", e.target.value)}
                      value={searchDetail?.BedroomsTotal}
                      size="small"
                      variant="outlined"
                      sx={{ width: "145px", mb: "15px" }}
                    />
                    <InputLabel className={classes.searchInputLabel}>Square Footage</InputLabel>
                    <TextField
                      onChange={(e) => handleUpdateDetail("BuildingAreaTotal", e.target.value)}
                      value={searchDetail?.BuildingAreaTotal}
                      size="small"
                      variant="outlined"
                      sx={{ width: "145px", mb: "15px" }}
                    />

                    <InputLabel className={classes.searchInputLabel}>Acres</InputLabel>
                    <TextField
                      onChange={(e) => handleUpdateDetail("LotSizeAcres", e.target.value)}
                      value={searchDetail?.LotSizeAcres}
                      size="small"
                      variant="outlined"
                      sx={{ width: "145px", mb: "15px" }}
                    />

                    <InputLabel className={classes.searchInputLabel}>Year Built</InputLabel>
                    <TextField
                      onChange={(e) => handleUpdateDetail("YearBuilt", e.target.value)}
                      value={searchDetail?.YearBuilt}
                      size="small"
                      variant="outlined"
                      sx={{ width: "145px", mb: "15px" }}
                    />
                  </Box>
                  <Box className={classes.propertySubType}>
                    <Typography className={classes.propertySubTypeHead}>Lake Name</Typography>
                    <Card variant="outlined">
                      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                        {[0, 1, 2].map((value) => {
                          return (
                            <ListItem key={value} disablePadding>
                              <ListItemButton role={undefined} dense>
                                <ListItemText primary={`Menu item ${value + 1}`} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Card>
                  </Box>
                  <br />
                  <br />
                </Card>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "NewSearchPropertyListingStyles" })(NewSearchProperty);
