import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Container,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import SearchKnowledgeSkelton from "../skelton/searchKnowledgeBase";
import Typography from "@mui/material/Typography";
import Software from "../../../assets/MenuImage.svg";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { isArrayCheck } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  getAllKnowledgeBasedDataList,
  getAllKnowledgeBasedDataListUsingSearch,
} from "../../../redux/knowledge/knowledgeSearch";
import { debounce } from "lodash";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import momentTZ from "moment-timezone";

const useStyles = makeStyles((_theme) => ({
  paper: {
    minHeight: "100vh",
    padding: "15px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
  },
  searchTitle: {
    fontFamily: "AvenirNext !important",
    fontWeight: "400 !important",
    fontSize: "48px !important",
    textAlign: "center !important",
  },
  searchField: {
    marginTop: "3rem !important",
  },
}));

function KnowledgeBaseMain(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const muiClasses = useStyles();

  const [searchText, setSearchText] = useState("");

  const { allKnowledgeBaseData, loading, searchData } = useSelector(
    (state) => state.knowledgeBase.search
  );
  const { recentlyAdded, categories } = allKnowledgeBaseData;
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const debouncedSearch = useRef(
    debounce(async (val) => {
      dispatch(getAllKnowledgeBasedDataListUsingSearch(val));
    }, 300)
  ).current;

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 0) debouncedSearch(e.target.value);
  };

  useEffect(() => {
    if (searchText.length === 0) dispatch(getAllKnowledgeBasedDataList());
  }, [searchText]);

  return (
    <Container maxWidth="xl">
      <div className={classes.mainDashboardPage}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper
              elevation={2}
              className={
                searchText.length === 0
                  ? classes.methodWeatherBox
                  : muiClasses.paper
              }
            >
              <Grid
                container
                mt={5}
                mb={9}
                spacing={2}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid sx={{ width: "50%" }}>
                  <Typography variant="h2" className={muiClasses.searchTitle}>
                    Search Knowledge Base
                  </Typography>

                  <TextField
                    fullWidth
                    label="Enter Search Terms"
                    className={muiClasses.searchField}
                    onChange={onChangeSearchText}
                    value={searchText}
                  />
                </Grid>
              </Grid>

              {loading && <SearchKnowledgeSkelton />}

              {!loading && (
                <>
                  <div className={classes.methodWeatherTop}>
                    <h1 className={classes.methodWeatherTopH1}>
                      {searchText.length !== 0
                        ? "Search Results"
                        : "Recently Added"}
                    </h1>
                  </div>
                  <Box sx={{ flexGrow: 1, mt: 5 }}>
                    <Grid
                      container
                      spacing={2}
                      display="flex"
                      justifyContent="center"
                      marginBottom="10px"
                    >
                      {isArrayCheck(
                        searchText.length !== 0 ? searchData : recentlyAdded
                      ) &&
                        (searchText.length !== 0
                          ? searchData
                          : recentlyAdded
                        ).map((data) => (
                          <Grid
                            className={classes.mainCardArea}
                            item
                            xl={4}
                            lg={4}
                            md={6}
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                          >
                            <div
                              className={classes.videoTextImg}
                              onClick={() =>
                                navigate("/knowledge-base/main/" + data?.id, {
                                  state: data,
                                })
                              }
                            >
                              <img
                                alt="knowledge-base-course"
                                className={classes.imgCourses}
                                src={data?.thumbnail}
                              />
                              <div
                                className={`${classes.upperHoverUI} hoveredBlock IsHovered`}
                              >
                                <div style={{ width: "100%" }}>
                                  <div className={classes.hoverText}>
                                    <h4 className={classes.hoverTextMargin}>
                                      {data?.title}
                                    </h4>
                                    <p className={classes.customListingChip}>
                                      {categories.find(
                                        (it) => it.id === data?.category_id
                                      )?.title || "NA"}
                                    </p>
                                  </div>
                                  <div className={classes.hoverText2}>
                                    <p className={classes.hoverTextMargin}>
                                      By:{" "}
                                      {data?.author_id
                                        ? agentRosterList?.list?.find(
                                            (item) =>
                                              item.id === data?.author_id
                                          )?.full_name || ""
                                        : ""}
                                    </p>
                                    <p className={classes.hoverTextMargin}>
                                      Added on:{" "}
                                      {momentTZ
                                        .tz(data.createdAt, "America/New_York")
                                        ?.format("L")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </>
              )}
            </Paper>
          </Grid>
          {searchText.length === 0 &&
            categories &&
            categories.map((cat, index) => {
              return (
                <Grid key={index} item xs={12} md={6}>
                  <Paper elevation={2} className={classes.methodWeatherBox}>
                    <div className={classes.methodWeatherTop}>
                      <div className={classes.dFlex}>
                        <img
                          className={classes.methodWeatherBoxImg}
                          src={Software}
                          alt=""
                        />
                        <h1 className={classes.methodWeatherTopH1}>
                          {cat.title}
                        </h1>
                      </div>
                    </div>
                    <List
                      disablePadding
                      sx={{ mt: "12px" }}
                      className={classes.methodWeatherBoxul}
                    >
                      {isArrayCheck(cat?.posts) &&
                        cat?.posts.map((data) => (
                          <ListItem
                            className={classes.methodWeatherBoxli}
                            onClick={() =>
                              navigate("/knowledge-base/main/" + data?.id, {
                                state: data,
                              })
                            }
                            disablePadding
                          >
                            <ListItemButton disablePadding>
                              <ListItemIcon sx={{ minWidth: "40px" }}>
                                {data?.type === "video" ? (
                                  <PlayArrowRoundedIcon
                                    sx={{ fontSize: "29px" }}
                                  />
                                ) : (
                                  <DescriptionOutlinedIcon
                                    sx={{ color: "black", mt: "-2px" }}
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText primary={data?.title} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                    </List>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </Container>
  );
}

export default withStyles(styleSheet, { name: "KnowledgeBaseMainStyle" })(
  KnowledgeBaseMain
);
