import * as React from "react";
import { Card, Stack, Grid, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
function ListingUISkelton() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={3} sm={12}></Grid>
        <Grid item md={6} sm={12}></Grid>
        <Grid item md={3} sm={12}>
          {" "}
          <Stack direction={"row"} spacing={2}>
            <Skeleton animation="wave" height={50} width="70px" />
            <Skeleton animation="wave" height={50} width="70px" />
            <Skeleton animation="wave" height={50} width="70px" />
          </Stack>
        </Grid>
        <Grid item md={3} sm={12}>
          <Card variant="outlined">
            <Box sx={{ padding: "15px" }}>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  sx={{ padding: "5px" }}
                  avatar={<Skeleton animation="wave" variant="rounded" width={20} height={20} />}
                  title={<Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 4 }} />}
                />
              </Card>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  sx={{ padding: "5px" }}
                  avatar={<Skeleton animation="wave" variant="rounded" width={20} height={20} />}
                  title={<Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 4 }} />}
                />
              </Card>
            </Box>
            <Divider />
            <Box sx={{ padding: "15px" }}>
              <Skeleton animation="wave" height={30} width="60%" style={{ marginBottom: 4 }} />
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader sx={{ padding: "5px" }} title={<Skeleton animation="wave" height={25} width="80%" style={{ marginBottom: 4 }} />} />
              </Card>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader sx={{ padding: "5px" }} title={<Skeleton animation="wave" height={25} width="80%" style={{ marginBottom: 4 }} />} />
              </Card>
            </Box>{" "}
            <Divider />
            <Box sx={{ padding: "15px" }}>
              {Array.from(Array(7).keys()).map(() => (
                <Card sx={{ boxShadow: "none" }}>
                  <CardHeader
                    sx={{ padding: "5px" }}
                    avatar={<Skeleton animation="wave" variant="rounded" width={20} height={20} />}
                    title={<Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 4 }} />}
                    action={<Skeleton animation="wave" sx={{ mt: "-4px" }} height={50} width="60px" />}
                  />
                </Card>
              ))}
            </Box>
            <Divider />
          </Card>
        </Grid>
        <Grid item md={6} sm={12}>
          {" "}
          <Card variant="outlined">
            <Box sx={{ padding: "15px" }}>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader sx={{ padding: "5px" }} title={<Skeleton animation="wave" height={42} width="40%" />} />
              </Card>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  sx={{ padding: "5px" }}
                  title={<Skeleton animation="wave" height={50} width="95%" />}
                  action={<Skeleton animation="wave" height={50} width="150px" style={{ marginTop: 4 }} />}
                />
              </Card>
            </Box>{" "}
            <Divider />
            <Box sx={{ padding: "15px" }}>
              <Grid container spacing={1}>
                {Array.from(Array(5).keys()).map((i, index) => (
                  <Grid item md={6} sm={12}>
                    <Card sx={{ boxShadow: "none" }}>
                      <CardHeader
                        sx={{ padding: "10px" }}
                        title={<Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: -8 }} />}
                        subheader={
                          <Stack>
                            <Skeleton animation="wave" height={50} width="100%" />
                            {index !== 4 ? (
                              <Stack direction={"row"} spacing={2}>
                                {" "}
                                <Skeleton animation="wave" height={20} width="40px" /> <Skeleton animation="wave" height={20} width="40px" />{" "}
                                <Skeleton animation="wave" height={20} width="40px" />
                              </Stack>
                            ) : null}
                          </Stack>
                        }
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box sx={{ padding: "15px" }}>
              <Grid container spacing={1}>
                {Array.from(Array(5).keys()).map(() => (
                  <Grid item md={6} sm={12}>
                    <Card sx={{ boxShadow: "none" }}>
                      <CardHeader
                        sx={{ padding: "10px" }}
                        title={<Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: -8 }} />}
                        subheader={
                          <Stack>
                            <Skeleton animation="wave" height={50} width="100%" />
                            <Stack direction={"row"} spacing={2}>
                              {" "}
                              <Skeleton animation="wave" height={20} width="40px" /> <Skeleton animation="wave" height={20} width="40px" />{" "}
                              <Skeleton animation="wave" height={20} width="40px" />
                            </Stack>
                          </Stack>
                        }
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item md={3} sm={12}>
          {" "}
          <Card variant="outlined">
            <Box sx={{ padding: "15px" }}>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  sx={{ padding: "5px" }}
                  title={<Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: -8 }} />}
                  subheader={<Skeleton animation="wave" height={50} width="80%" style={{ marginBottom: 4 }} />}
                />
              </Card>
            </Box>
            <Divider />
            <Box sx={{ padding: "15px" }}>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  sx={{ padding: "5px" }}
                  title={<Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: -8 }} />}
                  subheader={<Skeleton animation="wave" height={50} width="80%" style={{ marginBottom: 4 }} />}
                  action={<Skeleton animation="wave" height={50} width="50px" style={{ marginTop: 17 }} />}
                />
              </Card>
            </Box>{" "}
            <Divider />
            <Box sx={{ padding: "15px" }}>
              {Array.from(Array(7).keys()).map(() => (
                <Card sx={{ boxShadow: "none" }}>
                  <CardHeader
                    sx={{ padding: "5px" }}
                    title={<Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: -8 }} />}
                    subheader={<Skeleton animation="wave" height={50} width="80%" style={{ marginBottom: 4 }} />}
                  />
                </Card>
              ))}
            </Box>
            <Divider />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
export default ListingUISkelton;
