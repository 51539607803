export const styleSheet = () => ({
  root: {
    minHeight: "100vh",
    padding: "20px 0px",
  },
  agentActivitiesScreenTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  agentActivitiesScreenTopFilter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  agentActivitiesReportHeading: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
  },
  agentActivitiesChartGraph: {
    background: "white",
    margin: "25px 0px",
    minHeight: "250px",
    padding:"15px"
  },
  agentActivitiesStatContent: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "uppercase",
  },
  agentActivitiesStatValue: {
    fontSize: "22px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",
  },
  agentActivitiesStatsArea: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  graphBottomDates:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root":{
      fontSize: "12px !important",
      fontWeight: "500 !important",
      fontFamily: "AvenirNext  !important",
    }
  },
  agentActivitiesStatsItem: {
    width: "150px",
    height: "130px",
    marginRight: "15px",
    marginBottom: "15px",
    background: "white",
    cursor:"pointer",
    borderBottom:"none !important",
    borderBottomRightRadius:"0px !important",
    borderBottomLeftRadius:"0px !important",
  },
  agentActivitiesStatsItemTop:{
    padding: "10px",
    paddingBottom:'37.8px'
  },
  exportButton: {
    border: "1px solid rgb(215, 222, 226) !important",
    borderRadius: "4px",
    background: "linear-gradient(rgb(255, 255, 255), rgb(232, 236, 240))",
    color: "rgb(109, 130, 145) !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize  !important",
    marginRight:'15px !important',
    padding:"6px 10px !important",
    minWidth:"40px !important",
  },
  listItemForSelection:{
    height:"40px !important",
    "& 	.MuiListItemText-primary":{
      fontSize: "12px !important",
      fontWeight: "500 !important",
      fontFamily: "AvenirNext  !important",
      color:"#9FB1BF !important"
    },
    "& .MuiListItemText-secondary":{
      fontSize: "12px !important",
      fontWeight: "500 !important",
      fontFamily: "AvenirNext  !important",
    },
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    lineHeight:"30px"
  },
});
