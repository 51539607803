import { createSlice } from "@reduxjs/toolkit";
import {getAllPropertyList} from './getAllPropertyListing'

const initialState = {
  list: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const propertyList = createSlice({
  name: "propertyList",
  initialState,
  extraReducers: {
     [getAllPropertyList.pending]: (state) => {
      state.list.isLoading = true;
      state.list.errMsg = null;
      state.list.success = false;
    },
    [getAllPropertyList.fulfilled]: (state, action) => {
      state.list.success = true;
      state.list.isLoading = false;
      state.list.data = action.payload;
    },
    [getAllPropertyList.rejected]: (state, action) => {
      state.list.isLoading = false;
      state.list.success = false;
      state.list.errMsg = action.payload;
    },
  },
});
export default propertyList.reducer;
export * from './getAllPropertyListing'
