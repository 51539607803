import React from "react";
import { Grid } from "@mui/material";
import TransactionsDetailImportantNotes from "./importantNotes";
import TransactionsDetailImportantPeoples from "./importantPeople";
import TransactionsDetailDates from "./dates";
import TransactionsDetailMoney from "./earnestMoney";
function TransactionsDetailOverview(props) {
  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row">
        <Grid item xs={8}>
          <TransactionsDetailImportantPeoples {...props} />
          <TransactionsDetailImportantNotes {...props} />
        </Grid>
        <Grid item xs={4}>
          <TransactionsDetailDates {...props} />
          <TransactionsDetailMoney {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default TransactionsDetailOverview;
