import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const uploadVendorDoc = createAsyncThunk(
  "VendorDetail/uploadVendorDoc",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/vendors/${data.id}`,
        data.formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if(data.success){
        data.success({w9_form:resp.data?.data,id:data?.data?.id})
      }
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.errorMessage || error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

