import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import { updateTaskStatus } from "../../../redux/transactions/updateTask";
function UpdateTaskStatus(props) {
  let { open, setOpen, classes, id, handleCompleteTask, contractId } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();
  const updateTask = useSelector(
    (state) => state.transactions.Tasks.updateTask
  );

  useEffect(() => {
    if (updateTask.success && !updateTask.isLoading && open) {
      if (handleCompleteTask) {
        handleCompleteTask(contractId, id);
      }
      setErrorAlert({
        errorMsg: "You have successfully completed this task",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
    } else if (!updateTask.isLoading && updateTask.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateTask.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateTask.success]);

  const handleCloseContract = () => {
    dispatch(updateTaskStatus({ id: id, status: "completed" }));
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          className={classes.CloseContractHeading}
          id="alert-dialog-title"
        >
          Complete Task
        </DialogTitle>
        <DialogContent sx={{ width: "400px" }}>
          Confirm below to Complete the Task
          <br />
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCloseContract()}
            className={classes.saveModalButton}
            color="inherit"
            autoFocus
            loading={updateTask.isLoading}
            loadingPosition="start"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "UpdateTaskStatusStyle" })(
  UpdateTaskStatus
);
