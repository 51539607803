import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, TextField } from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { getData } from "../../../../utils/getData.js";
import Autocomplete from "@mui/material/Autocomplete";

const ClosingAttorney = (props) => {
  const [Attorneys, setAttorneys] = useState([]);
  const [isDisableOthers, setIsDisableOthers] = useState(false);
  let { classes, updateContractDetail, contractDetail, errMsg, setContractDetail } = props;

  useEffect(() => {
    handleGetClosingAttorneys();
  }, []);

  const handleGetClosingAttorneys = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}api/transactions/contracts/closing-attornies`;
    const result = await getData(url);
    if (result?.status === 200) {
      setAttorneys(result.data);
    }
  };
  const handleUpdateValueAttorney = (newValue) => {
    if (newValue) {
      let obj = {
        ...newValue,
        closing_attorney_id: newValue.id,
      };
      delete obj.id;
      delete obj.label;
      delete obj.closing_attorney_office_location;
      setContractDetail({
        ...contractDetail,
        ...obj,
      });
      setIsDisableOthers(true);
    } else {
      setIsDisableOthers(false);
      setContractDetail({
        ...contractDetail,
        closing_attorney_id: "",
        closing_attorney_company: "",
        closing_attorney_email: "",
        closing_attorney_first_name: "",
        closing_attorney_last_name: "",
        closing_attorney_phone: "",
      });
    }
  };

  return (
    <Card variant="outlined" className={classes.transactionFormCard} sx={{ overflow: "visible" }}>
      <Typography classes={{ root: classes.transactionFormCardHeading }}>Closing Attorney</Typography>

      <Grid container spacing={4} className={classes.transactionFormArea}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            onChange={(event, newValue) => {
              handleUpdateValueAttorney(newValue);
            }}
            id="closing-attorneysdd"
            options={Attorneys.sort((a, b) => a.closing_attorney_company.localeCompare(b.closing_attorney_company)).map((item) => ({
              ...item,
              label: (item.closing_attorney_company || "") + " | " + (item.closing_attorney_office_location || ""),
            }))}
            fullWidth
            renderInput={(params) => <TextField fullWidth {...params} variant="standard" helperText="Select Existing Attorney" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={contractDetail.closing_attorney_company}
            onChange={(e) => updateContractDetail("closing_attorney_company", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Closing Attorney Company Name"
            id="closing_attorney_company"
            error={errMsg["closing_attorney_company"]}
            disabled={isDisableOthers}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.closing_attorney_first_name}
            onChange={(e) => updateContractDetail("closing_attorney_first_name", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Closer First Name"
            id="closing_attorney_first_name"
            error={errMsg["closing_attorney_first_name"]}
            disabled={isDisableOthers}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.closing_attorney_last_name}
            onChange={(e) => updateContractDetail("closing_attorney_last_name", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Closer Last Name"
            id="closing_attorney_last_name"
            error={errMsg["closing_attorney_last_name"]}
            disabled={isDisableOthers}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.closing_attorney_phone}
            onChange={(e) => updateContractDetail("closing_attorney_phone", e.target.value)}
            fullWidth
            variant="standard"
            helperText="Closer Phone"
            name="phone"
            id="closing_attorney_phone"
            error={errMsg["closing_attorney_phone"]}
            disabled={isDisableOthers}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={contractDetail.closing_attorney_email}
            onChange={(e) => updateContractDetail("closing_attorney_email", e.target.value)}
            fullWidth
            variant="standard"
            type="email"
            helperText="Closer Email"
            name="email"
            error={errMsg["closing_attorney_email"]}
            id="closing_attorney_email"
            disabled={isDisableOthers}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
export default withStyles(styleSheet, { name: "ClosingAttorneyStyle" })(ClosingAttorney);
