import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyDSEBJ_Vdgr0P0cpuj4aOiMFFbUw5Zy0RI",
  authDomain: "method-sandbox.firebaseapp.com",
  projectId: "method-sandbox",
  storageBucket: "method-sandbox.appspot.com",
  messagingSenderId: "693312413235",
  appId: "1:693312413235:web:812efa8840be2388b30d46",
  measurementId: "G-VDVB492MQ6",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async (setTokenFound) => {
  return await getToken(messaging, { vapidKey: "BGvpJTIIbJRZ0nY1V2xORKFNMn0ahjRVH9N53x84SAIKNMa1FVxFB09J87B5lf8VXNXm6f2twkRxgDq-hTnn0I0" })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        return currentToken;
        // Perform any other necessary action with the token
      } else {
        setTokenFound(false);
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
