import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Card,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LocalPoliceRoundedIcon from "@mui/icons-material/LocalPoliceRounded";
import LeaderBoardList from "./leaderBoardList";
import { getLastMonths } from "../points/pointsHistory";
import LeaderBoardStats from "./leaderBoardStats";
import LeaderBoardPointKeys from "./pointKeys";
import { useDispatch, useSelector } from "react-redux";
import {
  getPointsLeaderBoards,
  getPointsLeaderBoardsPrevious,
} from "../../../../../redux/agents/agentRosterDetail/points";

const moment = require("moment-timezone");
function PointsLeaderBoards(props) {
  const [month, setMonth] = useState("current");
  let { classes } = props;
  const dispatch = useDispatch();
  const leaderBoards = useSelector(
    (state) => state.agentRoster.points.leaderBoards
  );
  const leaderBoardsPrevious = useSelector(
    (state) => state.agentRoster.points.leaderBoardsPrevious
  );
  useEffect(() => {
    if (month === "current") {
      let startDate =
        moment().startOf("month").format().slice(0, 11) + "00:00:01";
      let endDate = moment().endOf("month").format().slice(0, 11) + "23:23:59";
      dispatch(
        getPointsLeaderBoards({
          startDate,
          endDate,
          previousResult: month === "current" ? false : true,
        })
      );
    } else {
      let startDate =
        moment(month).startOf("month").format().slice(0, 11) + "00:00:01";
      let endDate =
        moment(month).endOf("month").format().slice(0, 11) + "23:23:59";
      dispatch(
        getPointsLeaderBoards({
          startDate,
          endDate,
          previousResult:
            moment().month() !== moment(month).month() ? true : false,
        })
      );
    }
    if (month === "current") {
      let startDate =
        moment().startOf("month").subtract(1, "month").format().slice(0, 11) +
        "00:00:01";
      let endDate =
        moment().endOf("month").subtract(1, "month").format().slice(0, 11) +
        "23:23:59";
      dispatch(
        getPointsLeaderBoardsPrevious({
          startDate,
          endDate,
          previousResult: month === "current" ? false : true,
        })
      );
    } else {
      let startDate =
        moment(month)
          .startOf("month")
          .subtract(1, "month")
          .format()
          .slice(0, 11) + "00:00:01";
      let endDate =
        moment(month)
          .endOf("month")
          .subtract(1, "month")
          .format()
          .slice(0, 11) + "23:23:59";
      dispatch(
        getPointsLeaderBoardsPrevious({
          startDate,
          endDate,
          previousResult:
            moment().month() !== moment(month).month() ? true : false,
        })
      );
    }
  }, [month]);

  return (
    <Grid
      container
      sx={{ mt: "20px", minHeight: "calc(100vh - 180px)" }}
      spacing={2}
    >
      <Grid item sm={12} lg={7.5} xl={8}>
        <Box className={classes.leaderBoardLeftTop}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <LocalPoliceRoundedIcon fontSize="large" />
            <Typography className={classes.leaderBoardLeftTopHeading}>
              Points Leaderboard
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography
              className={classes.leaderBoardDaysLeft}
              sx={{ color: month === "current" ? "" : "#FF0000" }}
            >
              {month === "current"
                ? `${moment().daysInMonth() - new Date().getDate()} Days Left`
                : "FINAL RESULTS"}{" "}
            </Typography>
            <Button
              classes={{ root: classes.leaderBoardYears }}
              size="small"
              color="inherit"
            >
              {month === "current"
                ? moment().format("MMMM YYYY")
                : moment(month).format("MMMM YYYY")}
            </Button>
            <TextField
              value={month}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              select
              size="small"
              sx={{
                minWidth: "170px",
                bgcolor: "white",
                "& .MuiSelect-outlined": {
                  padding: "5.5px 14px",
                  color: "rgba(0, 0, 0, 0.3)",
                  fontSize: "14px",
                },
              }}
            >
              {getLastMonths(
                month === "current" ? "See Previous Results" : ""
              ).map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Box>
        <Card variant="outlined" className={classes.leaderBoardLeftCard}>
          <LeaderBoardList
            leaderBoardData={leaderBoards.data}
            leaderBoardsPrevious={leaderBoardsPrevious.data}
            leaderBoards={leaderBoards}
            month={month}
            {...props}
          />
        </Card>
      </Grid>
      <Grid item sm={12} lg={4.5} xl={4} sx={{ position: "relative" }}>
        <Box
          sx={{ maxWidth: "405px" }}
          className={classes.leaderBoardStatsRightArea}
        >
          <Box className={classes.leaderBoardStatsHeading}>
            <Typography className={classes.leaderBoardStatsCard}>
              Your Stats
            </Typography>{" "}
          </Box>
          <Card
            variant="outlined"
            sx={{
              padding: "20px 15px",
            }}
          >
            <LeaderBoardStats {...props} />
          </Card>
          <br />
          <Box sx={{ position: "relative" }}>
            <Box className={classes.leaderBoardPointsKeyHeading}>
              <Typography className={classes.leaderBoardStatsCard}>
                Points Key
              </Typography>{" "}
            </Box>
            <Card
              variant="outlined"
              sx={{
                padding: "20px 15px 10px 15px",
                mb: "30px",
              }}
            >
              <LeaderBoardPointKeys {...props} />
            </Card>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, {
  name: "PointsLeaderBoardsStyle",
})(PointsLeaderBoards);
