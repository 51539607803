import { createSlice } from "@reduxjs/toolkit";
import { getTextMessageAPI } from "./getTextMessageAPI";

const initialState = {
  textMessages: {
    data: [],
    status: {
      pending: false,
      success: false,
      error: false,
    },
  },
};

const textMessageList = createSlice({
  name: "leadList",
  initialState,
  reducers: {},
  extraReducers: {
    [getTextMessageAPI.pending]: (state) => {
      state.textMessages.status.pending = true;
    },
    [getTextMessageAPI.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.textMessages.data = action.payload.textMessages;
      }
      state.textMessages.status.pending = false;
    },
    [getTextMessageAPI.rejected]: (state, action) => {
      state.textMessages.status.pending = false;
      state.textMessages.status.error = true;
    },

  },
});

export default textMessageList.reducer;
