import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, InputLabel, Grid, Box, MenuItem } from "@mui/material";
import ResponseAlert from "../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { addPhoneAudio } from "../../../redux/phoneConfiguration/audios";
import UploadFileIcon from "@mui/icons-material/UploadFile";

function AddPhoneAudio(props) {
  let { open, setOpen, classes } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const [audioDetail, setAudioDetail] = useState({
    audio_file: "",
    file_name: "",
    category: "",
  });
  const addAudio = useSelector((item) => item.phoneConfiguration.PhoneAudios.addAudio);
  const handleUpdateDetail = (field, value) => {
    setAudioDetail({ ...audioDetail, [field]: value });
    setErrMsg({});
  };

  useEffect(() => {
    if (addAudio.success && !addAudio.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the audio",
        errorType: "success",
        isOpen: true,
      });
      setOpen(false);
      setAudioDetail({
        name: "",
        user_id: "",
      });
    } else if (!addAudio.isLoading && addAudio.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addAudio.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addAudio.success]);

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!audioDetail.audio_file) {
      errorMsg["audio_file"] = "Please select the audio file";
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the audio file",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!audioDetail.category) {
      isFormValid = false;
      errorMsg["category"] = "Please select the category";
      setErrorAlert({
        errorMsg: "Please select the category",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const handleSaveAudio = () => {
    if (handleValidate()) {
      const Data = new FormData();
      Data.append("file_name", audioDetail?.file_name);
      Data.append("audio-file", audioDetail?.audio_file);
      Data.append("category", audioDetail?.category);
      dispatch(addPhoneAudio(Data));
    }
  };

  const handleFileChange = (e) => {
    setAudioDetail({
      ...audioDetail,
      file_name: e.target.files[0]?.name,
      audio_file: e.target.files[0],
    });
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        sx={{ "& .MuiDialog-container": { mt: "30px" }}}
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.AddPhoneHeading} sx={{ mt: "10px", mb: "15px" }} id="alert-dialog-title">
          <Box> Add Audio </Box>
        </DialogTitle>
        <DialogContent sx={{ width: "600px", overflow: "visible" }}>
          <Grid container direction="row" spacing={4} sx={{ overflow: "visible" }}>
            <Grid item lg={6} xs={12}>
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                classes={{ root: classes.documentTableButton }}
                startIcon={<UploadFileIcon />}
                component="label"
                sx={{
                  mt: "7.5px",
                  justifyContent: "flex-start",
                  width: "100%",
                  border: "none",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                  borderRadius: "0px",
                  pb: "7px",
                  pt: "10px",
                }}
              >
                {audioDetail?.file_name || "Select File"}
                <input onChange={(e) => handleFileChange(e)} hidden accept=".mp3,.mp4,.wav,.wma" multiple type="file" />
              </Button>
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Category
              </InputLabel>
              <TextField
                value={audioDetail.category}
                onChange={(e) => handleUpdateDetail("category", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                variant="standard"
                size="small"
                select
                error={errMsg.category}
                name="phone"
                autoComplete={false}
                id="user-phone"
                helperText={errMsg.category}
              >
                {[
                  { title: "voice Email", value: "voicemail greet" },
                  { title: "Hold Music", value: "hold music" },
                ]?.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: "30px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleSaveAudio()}
            classes={{ root: classes.AddPhoneButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addAudio.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddPhoneAudioStyle" })(AddPhoneAudio);
