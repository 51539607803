import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateAgentRosterProfile } from "./updateProfile";
import { updateAgentNotes } from "../agentRosterDetail/notes";
import { AddTrainingEnrollment } from "./addEnroll";
import { updateTrainingEnrollment } from "./updateEnroll";
import { getTrainingMentors } from "./getMentors";
const initialState = {
  loading: true,
  profile: {},
  errMsg: null,
  updateLoading: false,
  updateErrMsg: null,
  success: false,
  type: "",
  addEnroll: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  updateEnroll: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  mentors: [],
};

export const getAgentRosterProfile = createAsyncThunk(
  "agentRosterProfile/getAgentRosterProfile",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/users/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
          params: { agent_only: data.agent_only },
        }
      );
      thunkAPI.dispatch(updateAgentNotes(resp.data?.data?.notes || []));
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const agentRosterProfile = createSlice({
  name: "agentRosterProfile",
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
  },
  extraReducers: {
    [getAgentRosterProfile.pending]: (state) => {
      state.loading = true;
    },
    [getAgentRosterProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.profile = action.payload.data;
    },
    [getAgentRosterProfile.rejected]: (state, action) => {
      state.loading = false;
      state.updateErrMsg = action.payload;
    },
    [updateAgentRosterProfile.pending]: (state, action) => {
      state.updateLoading = action.meta?.arg?.type;
      state.type = action.meta?.arg?.type;
      state.success = false;
    },
    [updateAgentRosterProfile.fulfilled]: (state, action) => {
      state.updateLoading = false;
      state.success = true;
      state.profile = { ...state.profile, ...action.payload };
    },
    [updateAgentRosterProfile.rejected]: (state, action) => {
      state.updateLoading = false;
      state.success = false;
      state.updateErrMsg = action.payload;
    },
    [AddTrainingEnrollment.pending]: (state) => {
      state.addEnroll.isLoading = true;
      state.addEnroll.errMsg = null;
      state.addEnroll.success = false;
    },
    [AddTrainingEnrollment.fulfilled]: (state, action) => {
      state.addEnroll.success = true;
      state.addEnroll.isLoading = false;
      state.addEnroll.data = action.payload;
      let mentorship_enrollment = action.payload;
      state.profile = { ...state.profile, mentorship_enrollment };
    },
    [AddTrainingEnrollment.rejected]: (state, action) => {
      state.addEnroll.isLoading = false;
      state.addEnroll.success = false;
      state.addEnroll.errMsg = action.payload;
    },
    // update enroll
    [updateTrainingEnrollment.pending]: (state) => {
      state.updateEnroll.isLoading = true;
      state.updateEnroll.errMsg = null;
      state.updateEnroll.success = false;
    },
    [updateTrainingEnrollment.fulfilled]: (state, action) => {
      state.updateEnroll.success = true;
      state.updateEnroll.isLoading = false;
      state.updateEnroll.data = action.payload;
      let mentorship_enrollment = state.profile.mentorship_enrollment;
      state.profile = {
        ...state.profile,
        mentorship_enrollment: {
          ...mentorship_enrollment,
          ...action.payload,
        },
      };
    },
    [updateTrainingEnrollment.rejected]: (state, action) => {
      state.updateEnroll.isLoading = false;
      state.updateEnroll.success = false;
      state.updateEnroll.errMsg = action.payload;
    },
    // get training mentors
    [getTrainingMentors.fulfilled]: (state, action) => {
      state.mentors = action.payload.data;
    },
  },
});
export const { updateProfile } = agentRosterProfile.actions;
export default agentRosterProfile.reducer;
