import React, { useState } from "react";
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import Input from "../../../../components/uiElements/input/Input";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../../../../components/uiElements/button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Dialog from "../../../../components/uiElements/modal";

export const EditAddressModal = ({ closeModal, state, ...props }) => {
  const [address, setAddress] = useState([
    {
      street: "",
      label: "",
      city: "",
      state: "",
      zip: "",
    },
  ]);

  const setAddressFunc = (param) => {
    setAddress((prev) => [...prev, param]);
  };
  const updateAddressFunc = (param) => {
    const data = address.filter((_, index) => index !== param);
    setAddress(data);
  };

  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" }}}
      dialogHead={<DialogHead />}
      onSave={() => console.log("editAddressesModalState", address)}
      dialogBody={<DialogBody address={address} setAddressFunc={setAddressFunc} updateAddressFunc={updateAddressFunc} setAddress={setAddress} />}
      dialogFooter={<DialogFooter />}
      state={state}
      closeModal={closeModal}
      maxWidth="md"
      fullWidth={true}
    />
  );
};

// Components
const AddressForm = ({ mb, updateNumbers, id, address, setAddress }) => {
  const changeStreetFunc = (e) => {
    const addressData = [...address];
    addressData[id].street = e.target.value;
    setAddress(addressData);
  };
  const changeLabelFunc = (e) => {
    const addressData = [...address];
    addressData[id].label = e.target.value;
    setAddress(addressData);
  };
  const changeCityFunc = (e) => {
    const addressData = [...address];
    addressData[id].city = e.target.value;
    setAddress(addressData);
  };
  const changeStateFunc = (e) => {
    const addressData = [...address];
    addressData[id].state = e.target.value;
    setAddress(addressData);
  };
  const changeZipFunc = (e) => {
    const addressData = [...address];
    addressData[id].zip = e.target.value;
    setAddress(addressData);
  };
  return (
    <>
      <Box sx={{ display: "block", marginBottom: "10px" }}>
        <label>Address {id + 1}</label>
      </Box>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ flexGrow: 1, marginBottom: mb || 0 }}>
        <Grid item sm={7}>
          <Input onChange={(e) => changeStreetFunc(e)} value={address[id].street} placeholder="123 Sample Street" />
        </Grid>
        <Grid item sm={4}>
          <Input onChange={(e) => changeLabelFunc(e)} value={address[id].label} placeholder="Label e.g. home" />
        </Grid>

        <Grid item sm={1} sx={{ textAlign: "center" }}>
          <IconButton aria-label="delete" onClick={() => updateNumbers(id)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="left" alignItems="center" spacing={1} sx={{ flexGrow: 1, marginTop: "3px" }}>
        <Grid item sm={5}>
          <Input onChange={(e) => changeCityFunc(e)} value={address[id].city} placeholder="City" />
        </Grid>
        <Grid item sm={3}>
          <Input onChange={(e) => changeStateFunc(e)} value={address[id].state} placeholder="State" />
        </Grid>
        <Grid item sm={3}>
          <Input onChange={(e) => changeZipFunc(e)} value={address[id].zip} placeholder="Zip" />
        </Grid>
      </Grid>
    </>
  );
};

const DialogHead = () => (
  <DialogTitle>
    <Typography component="h5" variant="h5">
      Edit Addresses
    </Typography>
  </DialogTitle>
);

const DialogBody = ({ address, setAddressFunc, updateAddressFunc, setAddress }) => (
  <DialogContent>
    {address && address.length ? (
      address.map((_, index) => {
        return (
          <>
            <AddressForm pnaddress="Primary address" updateNumbers={updateAddressFunc} id={index} setAddress={setAddress} address={address} />
            <br />
          </>
        );
      })
    ) : (
      <Typography
        variant="subtitle1"
        component="p"
        sx={{
          display: "block",
          color: "#adadad",
          textAlign: "center",
        }}
      >
        No Addresses Found!
      </Typography>
    )}
    <br />
    <Button
      onClick={() =>
        setAddressFunc({
          street: "",
          label: "",
          city: "",
          state: "",
          zip: "",
        })
      }
      variant="text"
      themeVersion
      startIcon={<AddCircleIcon />}
    >
      Add another Address
    </Button>
    x{" "}
  </DialogContent>
);

const DialogFooter = () => (
  <DialogActions>
    <Typography component="p" variant="subtitle1">
      Footer Area
    </Typography>
  </DialogActions>
);
