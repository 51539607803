import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleCma } from "../../redux/tools/cmas";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Typography,
  Grid,
  Box,
  Button,
  List,
  ListItem,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useStyles } from "./style.js";
import carry from "../../assets/images/viewcma/carry-profile.png";
import star from "../../assets/images/viewcma/star.svg";
import bganalys from "../../assets/images/viewcma/bganalys.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SingleBedIcon from "@mui/icons-material/SingleBed";
import BathtubIcon from "@mui/icons-material/Bathtub";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import MailIcon from "@mui/icons-material/Mail";
import media from "../../assets/images/viewcma/media.png";
import CallIcon from "@mui/icons-material/Call";
import GoogleMapReact from "google-map-react";
import { Bar } from "react-chartjs-2";
import ComparableTable from "./ComparablesSummary/ComparableTable.js";
import HomeIcon from "@mui/icons-material/Home";
import CmaReportLightLogo from "../../assets/light-logo.svg";
import SubjectProperty from "../../components/SubjectProperty/index.js";
import Property from "../../components/Property/index.js";
import CmaReportLogo from "../../assets/method-cma-logo.svg";

const AnyReactComponent = ({ text }) => (
  <Box
    sx={{
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      border: "5px red solid",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: "600",
      backgroundColor: "white",
    }}
  >
    {text}
  </Box>
);

function CmaReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cmaData = useSelector((state) => state.allCmas.singleCma);
  const [chartData, setChartData] = useState(null);
  const { id: cmaId } = useParams();
  const matches = useMediaQuery("(max-width:390px)");
  const matchesMd = useMediaQuery("(max-width:899px)");

  console.log("cmaData => ", cmaData);

  // Calculate the total count of Bathrooms
  const totalCount =
    (parseInt(cmaData?.BathroomsFull, 10) || 0) +
    (parseInt(cmaData?.BathroomsHalf) / 2 || 0);

  useEffect(() => {
    if (cmaId) {
      dispatch(getSingleCma({ id: cmaId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmaId]);

  useEffect(() => {
    let data = [];
    if (cmaData?.cma_comparables) {
      data = cmaData.cma_comparables.filter((item) =>
        ["sold", "closed"].includes(String(item.MlsStatus).toLowerCase())
      );
      const labels = data.map((property, i) => i + 1);
      const datasets = [
        {
          label: "Sold Price".toUpperCase(),
          backgroundColor: "#AFAFAF",
          borderColor: "#AFAFAF",
          barPercentage: 0.8,
          data: data.map((property) => property.ClosePrice),
        },
        {
          label: "List Price".toUpperCase(),
          backgroundColor: "#636363",
          borderColor: "#636363",
          barPercentage: 0.8,
          data: data.map((property) => property.ListPrice),
        },
      ];
      const chartOptions = {
        responsive: true, // Enable responsiveness
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 14,
                color: "black",
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                const formattedValue = `$${value.toLocaleString()}`;
                return formattedValue.split("").join("");
              },
              font: {
                size: 14,
                color: "black",
              },
              autoSkip: false,
              maxTicksLimit: 7,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: "SOLD PROPERTIES", // Your chart title
            position: "top",
            align: "start",
            fullSize: true,
            font: {
              size: 18,
              color: "#000",
              weight: "bold",
            },
          },
          legend: {
            display: true,
            position: "top",
            align: "end",
            labels: {
              font: {
                size: 14,
              },
              boxWidth: 25,
              boxHeight: 25,
            },
          },
        },
      };
      setChartData({ data: { labels, datasets }, options: chartOptions });
    }
  }, [cmaData]);

  let avgListPrice = 0;
  let avgSoldPrice = 0;
  let avgMarketDay = 0;
  let avgpricePerSqFt = 0;

  cmaData?.cma_comparables?.map((item, i) => {
    if (item?.ListPrice && parseInt(item?.ListPrice)) {
      avgListPrice += parseInt(item?.ListPrice);
    }
    if (item?.ClosePrice && parseInt(item?.ClosePrice)) {
      avgSoldPrice += parseInt(item?.ClosePrice);
    }
    if (item?.DaysOnMarket && parseInt(item?.DaysOnMarket)) {
      avgMarketDay += parseInt(item?.DaysOnMarket);
    }
    if (item?.pricePerSqFt && parseInt(item?.pricePerSqFt)) {
      avgpricePerSqFt += parseInt(item?.pricePerSqFt);
    }
    return true;
  });

  return (
    <>
      {/* cma header */}
      <div className={classes.navContainer}>
        <img src={CmaReportLogo} className="cma-logo" alt="Method CMA Logo" />
      </div>
      {/* hero Banner */}
      <div className={classes.section}>
        <div className={classes.herobg} />
        <Container
          maxWidth="lg"
          className={`${classes.container} ${classes.smallContainer}`}
        >
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid
              item
              md={7}
              className={classes?.analys}
              mb={{ xs: 4, md: 4 }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Typography variant="h1">Comparative Market Analysis</Typography>
              <div className="before" />
              <Box>
                <Typography variant="body1" className="body1" mb={1}>
                  {cmaData?.StreetNumber || ""} {cmaData?.StreetName || ""}
                </Typography>
                <Typography variant="body2" className="body2">
                  {cmaData?.City || ""},{cmaData?.StateOrProvince || ""}{" "}
                  {cmaData?.PostalCode || ""}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={5}
              className={classes?.prepared}
              mb={{ xs: 4, md: 4 }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box mb={{ xs: 6, md: 10 }}>
                <Typography variant="h4">Prepared by</Typography>
                <Box
                  mt={{ xs: 3, md: 4 }}
                  display="flex"
                  gap={{ xs: 3, md: 4 }}
                >
                  <img
                    src={cmaData?.agent?.profile_images?.profile_img || carry}
                    alt="profile"
                  />
                  <Box ms={2}>
                    <Typography variant="h5" mb={1}>
                      {cmaData?.CreatedBy}
                    </Typography>
                    <Typography variant="body2" className="body2" mb={1}>
                      {String(cmaData?.agent?.role_id) === "3"
                        ? "Principal"
                        : cmaData?.agent?.license_type != null &&
                          cmaData?.agent?.license_type === "Associate Broker"
                        ? "Associate Broker"
                        : "Realtor®"}
                    </Typography>
                    <Button
                      className="agent-contact block-sm"
                      variant="outlined"
                      component={"a"}
                      href={`mailto:${cmaData?.agent?.email}`}
                      endIcon={<ArrowRightAltIcon />}
                    >
                      contact
                    </Button>
                    <Box display="flex" className="contactIcons">
                      <Box
                        mr={2}
                        width="60px"
                        height={"35px"}
                        border={1}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        component={"a"}
                        href={`tel:${cmaData?.agent?.phone}`}
                      >
                        <CallIcon fontSize="small" />
                      </Box>
                      <Box
                        width="60px"
                        height={"35px"}
                        border={1}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        component={"a"}
                        href={`mailto:${cmaData?.agent?.email}`}
                      >
                        <MailIcon fontSize="small" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography variant="h4" mb={{ xs: 2, md: 1 }}>
                  Prepared for
                </Typography>
                <Typography variant="body2" className="agent_name">
                  {cmaData?.client0_firstName || ""}
                  {cmaData?.client1_firstName
                    ? " & " + cmaData?.client1_firstName
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <img src={cmaData?.MediaURL || bganalys} alt={"analys"} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.propertybg} />
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* Property Details */}
      <div className="overflow-hidden">
        <Container
          maxWidth="lg"
          className={`${classes.container} ${classes.smallContainer} pt-10`}
        >
          <div className={classes.sectiontitle}>Property details</div>
          <Grid container className="relative" width="100%">
            <Box
              className={classes.boxStack}
              display={"flex"}
              justifyContent={{ xs: "center", md: "end" }}
              alignItems={"center"}
            >
              <Box
                mx={{
                  xs: 1,
                  md: 2,
                }}
                className="bg-black relative flex justify-center items-center"
                width={{ xs: "70px", md: "80px" }}
                height={{ xs: "70px", md: "80px" }}
              >
                <SingleBedIcon color="primary" fontSize={"large"} />
                <span className="badge">{cmaData?.BedroomsTotal || 0}</span>
              </Box>
              <Box
                mx={{
                  xs: 1,
                  md: 2,
                }}
                className="bg-black relative flex justify-center items-center"
                width={{ xs: "70px", md: "80px" }}
                height={{ xs: "70px", md: "80px" }}
              >
                <BathtubIcon color="primary" fontSize={"large"} />
                <span className="badge">{totalCount || 0}</span>
              </Box>
              <Box
                mx={{
                  xs: 1,
                  md: 2,
                }}
                className="bg-black relative flex justify-center items-center"
                width={{ xs: "70px", md: "80px" }}
                height={{ xs: "70px", md: "80px" }}
              >
                <DirectionsCarFilledIcon color="primary" fontSize={"25px"} />
                <span className="badge">{cmaData?.ParkingTotal || 0}</span>
              </Box>
            </Box>
          </Grid>
          <Box className="relative">
            <div className={classes.propertydbg} />
            <Grid
              container
              pt={5}
              pb={{ xs: 0, md: 5 }}
              alignItems={"center"}
              justifyContent={{ xs: "center", md: "start" }}
            >
              <Grid item md={6} className="w-full">
                <List className={classes.propertyList}>
                  <ListItem
                    className="bg-black"
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Property Type</span>
                    <span>{cmaData?.PropertyType || "0"}</span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Year Built</span>
                    <span>{cmaData?.YearBuilt || "0"}</span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Square Footage</span>
                    <span>{cmaData?.BuildingAreaTotal || "0"}</span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Lot Size</span>
                    <span>
                      {cmaData?.LotSizeAcres
                        ? Math.round(cmaData?.LotSizeAcres * 10) / 10
                        : 0 || "0"}
                    </span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Annual Taxes</span>
                    <span>{cmaData?.TaxAnnualAmount || "0"}</span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>County</span>
                    <span>{cmaData?.CountyOrParish || "0"}</span>
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6} mt={{ xs: 4, md: 0 }}>
                <img
                  src={
                    (cmaData?.configurations_data?.page ===
                    "property_profile_SFR"
                      ? cmaData?.configurations_data
                          .the_property_page_property_photo
                      : cmaData?.MediaURL) || media
                  }
                  alt="media"
                  width={"100%"}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      {/* standout features */}
      {cmaData?.configurations_data?.length ? (
        <div className={classes.section}>
          <Container maxWidth="lg" px={{ xs: 0, md: 4 }} mb={10}>
            <Grid
              container
              spacing={{ xs: 0, md: 2 }}
              className="standout_features"
            >
              <Grid item xs={12} py={2} className={classes.mainTitle}>
                standout features
              </Grid>

              {cmaData?.configurations_data.page === "property_profile_SFR"
                ? cmaData?.configurations_data.standout_features?.map(
                    (itemData) => (
                      <Grid
                        item
                        xs={12}
                        md={3}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Button variant="outlined" width="100%" py={2}>
                          Updated Ranch
                        </Button>
                      </Grid>
                    )
                  )
                : null}
            </Grid>
          </Container>
        </div>
      ) : null}

      {/* Map Section */}
      <Container maxWidth={"lg"} className={classes.container}>
        <div className={classes.mapSec}>
          <div className={classes.sectiontitle}>
            The area, the stats {matches ? null : ", & The recent comparables"}
          </div>
          <div className={classes.mapWrapper}>
            <div className={classes.mapComponent}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCDqh1uJXV0q8ltkiA8Ijp5B9cE9oZ2WsM",
                }}
                defaultCenter={{
                  lat: parseFloat(34.0632049),
                  lng: parseFloat(-84.395413),
                }}
                defaultZoom={16}
              >
                <AnyReactComponent
                  lat={34.0632049}
                  lng={-84.395413}
                  text="Property"
                />
              </GoogleMapReact>
            </div>
            <div className={classes.indicatorsWrap}>
              <div>
                <span className={classes.firstIndicator}></span>
                <p>SOLD</p>
              </div>
              <div>
                <span className={classes.midIndicator}></span>
                <p>PENDING</p>
              </div>
              <div>
                <span className={classes.lastIndicator}></span>
                <p>ACTIVE</p>
              </div>
            </div>
          </div>
          <div className={classes.tableWrapper}>
            <TableContainer component={Paper} className={classes.areaTable}>
              <Table
                sx={{ minWidth: 650 }}
                // size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>MLS</TableCell>
                    {matches ? (
                      <>
                        <TableCell>
                          Beds <br />
                          Baths
                        </TableCell>
                        <TableCell>
                          Sqft <br />
                          Price
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Beds</TableCell>
                        <TableCell>Baths</TableCell>
                        <TableCell>Sqft</TableCell>
                        <TableCell>Price</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cmaData?.cma_comparables?.map((row, i) => (
                    <TableRow key={i} sx={{ "td, th": { border: 0 } }}>
                      <TableCell className={classes?.tableRows}>
                        <div
                          className={`round ${
                            row?.MlsStatus.toLowerCase() || "closed"
                          } ${classes.mapTableCircles}`}
                        >
                          {i === 0 ? (
                            <img src={star} width="70%" alt="" />
                          ) : (
                            <span>{i}</span>
                          )}
                        </div>
                        <div className={classes.mapTablePoints}>
                          {row?.StreetNumber} {row?.StreetName}
                        </div>
                      </TableCell>
                      <TableCell>{row?.ListingId || 0}</TableCell>
                      {matches ? (
                        <>
                          <TableCell>
                            {row?.BedroomsTotal || 0} <br />
                            {row?.BathroomsFull || 0}
                          </TableCell>
                          <TableCell>
                            {row?.pricePerSqFt || 0}
                            <br />
                            {row?.ListPrice || 0}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{row?.BedroomsTotal || 0}</TableCell>
                          <TableCell>{row?.BathroomsFull || 0}</TableCell>
                          <TableCell>{row?.pricePerSqFt || 0}</TableCell>
                          <TableCell>{row?.ListPrice || 0}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Container>
      {/* Average */}
      <div className={classes.section}>
        <Container maxWidth="lg" className={classes.container}>
          <Box className="relative">
            <div className={classes.propertydbgXRev} />
            <Grid
              container
              py={{ xs: 4, md: 5 }}
              flexDirection={{ xs: "column-reverse", md: "row" }}
              alignItems={"center"}
            >
              <Grid item md={6} mt={{ xs: 4, md: 0 }}>
                <img
                  src={
                    (cmaData?.configurations_data?.page === "base_CMA_package"
                      ? cmaData?.configurations_data.number_page_property_photo
                      : cmaData?.MediaURL) || media
                  }
                  alt="media"
                  height={"100%"}
                  width={"100%"}
                />
              </Grid>
              <Grid item md={6} className="w-full">
                <List className={classes.propertyList}>
                  <ListItem
                    className="bg-black"
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Average days on market</span>
                    <span>
                      {" "}
                      {(
                        avgMarketDay / cmaData?.cma_comparables?.length
                      ).toFixed(0)}
                    </span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Average price per sqft</span>
                    <span>
                      {" "}
                      $
                      {(
                        avgpricePerSqFt / cmaData?.cma_comparables?.length
                      ).toFixed(2)}
                    </span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Average LIST PRICE/SALE PRICE</span>
                    <span>
                      $
                      {(
                        (avgSoldPrice / cmaData?.cma_comparables?.length +
                          avgListPrice / cmaData?.cma_comparables?.length) /
                        2
                      ).toFixed(2)}
                    </span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Average SALE PRICE</span>
                    <span>
                      {" "}
                      $
                      {(
                        avgSoldPrice / cmaData?.cma_comparables?.length
                      ).toFixed(2)}
                    </span>
                  </ListItem>
                  <ListItem
                    className="bg-black"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    fontSize="large"
                  >
                    <span>Average LIST PRICE</span>
                    <span>
                      $
                      {(
                        avgListPrice / cmaData?.cma_comparables?.length
                      ).toFixed(2)}
                    </span>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      {/* Comparables summary */}
      <div className={`${classes.section} ${classes.comparableSumSec}`}>
        <Container className={classes.container}>
          <Typography className={classes.sectiontitle}>
            Comparables summary
          </Typography>
          <Box pt={{ xs: 4, sm: 5 }}>
            <div className="subTitle">
              <div />
              <div>Active</div>
              <div />
            </div>
            <Box>
              <ComparableTable
                rows={cmaData?.cma_comparables?.filter(
                  (item) =>
                    item?.MlsStatus === "Active" ||
                    item?.MlsStatus === "Coming Soon"
                )}
                status="active"
              />
            </Box>
          </Box>
          <Box pt={{ xs: 4, sm: 5 }}>
            <div className="subTitle">
              <div />
              <div style={{ whiteSpace: "nowrap" }}>UNDER CONTRACT</div>
              <div />
            </div>
            <Box>
              <ComparableTable
                rows={cmaData?.cma_comparables?.filter(
                  (item) =>
                    item?.MlsStatus === "Pending" ||
                    item?.MlsStatus === "Under Contract" ||
                    item?.MlsStatus === "Active Under Contract"
                )}
                status="under-contract"
              />
            </Box>
          </Box>
          <Box pt={{ xs: 4, sm: 5 }}>
            <div className="subTitle">
              <div />
              <div style={{ whiteSpace: "nowrap" }}>SOLD</div>
              <div />
            </div>
            <Box>
              <ComparableTable
                rows={cmaData?.cma_comparables?.filter(
                  (item) =>
                    item?.MlsStatus === "Sold" || item?.MlsStatus === "Closed"
                )}
                status="sold"
              />
            </Box>
          </Box>
        </Container>
      </div>
      {/* Comparables Details */}
      <div className={classes.section}>
        <Container className={classes.container}>
          <Typography className={classes.sectiontitle}>
            Comparables Details
          </Typography>
          <Grid
            className={classes.propertyDetailsGrid}
            container
            spacing={matchesMd ? 2 : 0}
          >
            <Grid
              item
              container
              xs={12}
              spacing={matchesMd ? 2 : 0}
              rowGap={{ xs: 2, md: 10 }}
            >
              {cmaData?.cma_comparables?.map((cmaItem, i) => (
                <>
                  {i === 0 || i % 3 === 0 ? (
                    <>
                      {!matchesMd && (
                        <Grid item xs={3}>
                          <SubjectProperty data={cmaData} pr={{ md: 2 }} />
                        </Grid>
                      )}
                      <Grid
                        item
                        container
                        xs={matchesMd ? 12 : 3}
                        pl={{ md: 2 }}
                      >
                        {matchesMd ? (
                          <Grid item xs={6}>
                            <SubjectProperty data={cmaData} pr={{ md: 2 }} />
                          </Grid>
                        ) : null}
                        <Grid item xs={matchesMd ? 6 : 12}>
                          <Property data={cmaItem} />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid item container xs={matchesMd ? 12 : 3}>
                      {matchesMd ? (
                        <Grid item xs={6}>
                          <SubjectProperty data={cmaData} pr={{ md: 2 }} />
                        </Grid>
                      ) : null}
                      <Grid item xs={matchesMd ? 6 : 12}>
                        <Property data={cmaItem} />
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* Chart Section */}
      <div className={classes.chartSection}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.sectiontitle}>
            the numbers {matches ? null : "| a closer look at sold prices"}
          </div>
          <Grid container mt={5} className={classes.chartContainer}>
            <Grid item md={8} className={classes.leftPart}>
              {chartData ? (
                <Bar data={chartData?.data} options={chartData?.options} />
              ) : null}
              <Grid
                container
                xs={12}
                py={5}
                className={`${classes.chartDetailsBoxesWrapper} ${classes.normalView}`}
              >
                <Grid item xs={12} md={4} p={1}>
                  <div className={classes.chartDetailsBoxes}>
                    <Typography variant="h5" component="h5">
                      Avg List Price:
                    </Typography>
                    <Typography variant="body">
                      $
                      {(
                        avgListPrice / cmaData?.cma_comparables?.length
                      ).toFixed(2)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} p={1}>
                  <div className={classes.chartDetailsBoxes}>
                    <Typography variant="h5" component="h5">
                      Avg Sold Price:
                    </Typography>
                    <Typography variant="body">
                      $
                      {(
                        avgSoldPrice / cmaData?.cma_comparables?.length
                      ).toFixed(2)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} p={1}>
                  <div className={classes.chartDetailsBoxes}>
                    <Typography variant="h5" component="h5">
                      Avg days on mkt:
                    </Typography>
                    <Typography variant="body">
                      $
                      {(
                        avgMarketDay / cmaData?.cma_comparables?.length
                      ).toFixed(0)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} className={classes.rightPart}>
              <h1>
                <HomeIcon />
                Addresses
              </h1>
              <List sx={{ listStyle: "decimal" }} py={10}>
                {cmaData?.cma_comparables?.length
                  ? cmaData?.cma_comparables?.map((cmaItem, i) => (
                      <ListItem>
                        <span>{i + 1}</span>
                        {cmaItem?.StreetNumber} {cmaItem?.StreetName}
                      </ListItem>
                    ))
                  : null}
              </List>
              <Grid
                container
                xs={12}
                py={5}
                className={`${classes.chartDetailsBoxesWrapper} ${classes.responsiveView}`}
              >
                <Grid item xs={12} md={4} p={1}>
                  <div className={classes.chartDetailsBoxes}>
                    <Typography variant="h5" component="h5">
                      Avg List Price:
                    </Typography>
                    <Typography variant="body">
                      {" "}
                      $
                      {(
                        avgListPrice / cmaData?.cma_comparables?.length
                      ).toFixed(2)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} p={1}>
                  <div className={classes.chartDetailsBoxes}>
                    <Typography variant="h5" component="h5">
                      Avg Sold Price:
                    </Typography>
                    <Typography variant="body">
                      {" "}
                      $
                      {(
                        avgSoldPrice / cmaData?.cma_comparables?.length
                      ).toFixed(2)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} p={1}>
                  <div className={classes.chartDetailsBoxes}>
                    <Typography variant="h5" component="h5">
                      Avg days on mkt:
                    </Typography>
                    <Typography variant="body">
                      $
                      {(
                        avgMarketDay / cmaData?.cma_comparables?.length
                      ).toFixed(0)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* The Price */}
      <div className={classes.section}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.sectiontitle}>
            the price {matches ? null : "| recommended pricing strategy"}
          </div>
          <Box className="relative" mt={{ xs: 0, md: 7 }}>
            <div className={classes.propertydbgYRev} />
            <Grid
              container
              py={{ xs: 5 }}
              className={classes.propertyBgInner}
              flexDirection={{ xs: "column-reverse", md: "row" }}
            >
              <Grid
                item
                md={6}
                px={{ xs: 0, md: 5 }}
                className={classes.thePriceDeatils}
                display="flex"
                justifyContent={"space-between"}
                flexDirection={"column"}
              >
                <Typography variant="body2">
                  After analyzing your property, the comparable properties on
                  the market now, as well as recent sales, I conclude that in
                  the current market, your property is{" "}
                  <span>most likely to sell between:</span>
                </Typography>
                <Typography variant="h6" py={4}>
                  $890,000 - $930,000
                </Typography>
                <Box
                  width={{ md: "90%" }}
                  className={classes.chartDetailsBoxes}
                >
                  <Typography variant="h5" component="h5">
                    recommended list price:
                  </Typography>
                  <Typography variant="body" className="num">
                    ${cmaData?.ListPrice}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} mt={{ xs: 4, md: 0 }}>
                <img
                  src={cmaData?.MediaURL || ""}
                  alt="media"
                  height={"100%"}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      {/* The Makeover */}
      <div className={classes.makeover}>
        <Container maxWidth="lg" className={classes.container} pb={5}>
          <Box
            border={1}
            borderColor={"white"}
            padding={{ xs: "24px 25px 50px", md: "0px 30px 35px" }}
            mx={{ xs: "0", xsm: "30px", md: 0 }}
            className={"makeoverDetails"}
          >
            <h4 className={classes.mainTitle}>
              Could your home use a <span>makeover</span>?
            </h4>
            <Typography variant="body2" pt={{ xs: 1, xsm: 2, md: 0 }}>
              The Method Makeover program is designed to prepare your home for
              market. From deep-cleaning to painting, carpet, lighting,
              countertops… you name it, we will work together to assess
              opportunities to elevate your homes value.
            </Typography>
            <Typography variant="body2" pt={2}>
              The Method Makeover program fronts all of the costs associated
              with updating your home, and payment for the work is collected at
              closing, with NO interest and NO fees to the Seller, ever.
            </Typography>
            <Box display="flex" justifyContent={"center"} mt={3}>
              <Button variant="contained">learn more</Button>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={{ xs: "start", md: "center" }}
            flexDirection={{ xs: "column", md: "row" }}
            className={classes?.prepared}
            mt={{ xs: 8, md: 10 }}
            mb={{ xs: 0, md: 5 }}
            mx={{ xs: "0", xsm: "30px", md: 0 }}
          >
            <Box display="flex" gap={{ xs: 3, md: 4 }} mb={{ xs: 4, md: 0 }}>
              <img
                src={cmaData?.agent?.profile_images?.profile_img || carry}
                alt="profile"
              />
              <Box ms={2}>
                <Typography variant="h5" mb={1}>
                  {cmaData?.CreatedBy}
                </Typography>
                <Typography variant="body2" className="body2" mb={1}>
                  {cmaData?.agent?.role_id == "3"
                    ? "Principal"
                    : cmaData?.agent?.license_type != null &&
                      cmaData?.agent?.license_type == "Associate Broker"
                    ? "Associate Broker"
                    : "Realtor®"}
                </Typography>
                <Button
                  className="agent-contact block-sm"
                  component={"a"}
                  href={`mailto:${cmaData?.agent?.email}`}
                  variant="outlined"
                  componen
                  endIcon={<ArrowRightAltIcon />}
                >
                  contact
                </Button>
                <Box display="flex" className="contactIcons">
                  <Box
                    mr={2}
                    width="60px"
                    height={"35px"}
                    border={1}
                    display={"flex !important"}
                    justifyContent={"center !important"}
                    alignItems={"center !important"}
                    component={"a"}
                    href={`tel:${cmaData?.agent?.phone}`}
                  >
                    <CallIcon fontSize="small" />
                  </Box>
                  <Box
                    width="60px"
                    height={"35px"}
                    border={1}
                    display={"flex !important"}
                    justifyContent={"center !important"}
                    alignItems={"center !important"}
                    component={"a"}
                    href={`mailto:${cmaData?.agent?.email}`}
                  >
                    <MailIcon fontSize="small" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <img
                src={CmaReportLightLogo}
                className="cma-logo"
                alt="Method CMA Logo"
                width="100%"
              />

              <Typography
                variant="body2"
                component={"a"}
                href={`tel:${cmaData?.agent?.phone}`}
                className="text-right"
                mb={1}
              >
                {cmaData?.agent?.phone}
              </Typography>
              <Typography
                variant="body2"
                component={"a"}
                href={`mailto:${cmaData?.agent?.email}`}
                className={"text-right"}
                mb={1}
              >
                {cmaData?.agent?.email}
              </Typography>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}
export default CmaReport;
