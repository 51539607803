import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { styleSheet } from "./style";
import { getTextTemplatesAgentsRecruitment } from "../../redux/agents/agentRecruitment/getTextTemplatesAgentRecruitment";
import { mergeTextTemplateAgentRecruitment } from "../../redux/agents/agentRecruitment/mergeTextTemplateAgentRecruitment";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  Typography,
  Popper,
} from "@mui/material";

const SendTextModal = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [templates, setTemplates] = useState([]);
  const [routeId, setRouteId] = useState(null);
  const templateList = useSelector((item) => item.textTemplates);

  const setListData = async () => {
    dispatch(getTextTemplatesAgentsRecruitment({}));
    setTemplates(templateList?.textTemplates || []);
  };

  const fillTemp = async (payload) => {
    const resp = await dispatch(mergeTextTemplateAgentRecruitment(payload));
    if (resp?.payload) {
      props.selectedTemplate(resp.payload);
      props.closeModal();
    }
  };
  const selectTemplate = (param) => () => {
    let payload;
    if (props.type === "lead") {
      payload = {
        templateId: param?.id,
        personId: routeId,
      };
    } else {
      payload = {
        templateId: param?.id,
        mergeAgentId: routeId,
      };
    }
    fillTemp(payload);
    props.closeModal();
  };

  useEffect(() => {
    setListData();
    setRouteId(Number(params?.id));
    setTemplates(templateList?.textTemplates || []);
  }, []);

  useEffect(() => {
    if (templateList) {
      setTemplates(templateList?.textTemplates || []);
    }
  }, [templateList]);

  useEffect(() => {
    if (templateList) {
      if (props.EditorValue) {
        const enteredKeyword = props.EditorValue.trim().toLowerCase();

        if (templateList.textTemplates === undefined) {
          setTemplates(templateList?.textTemplates || []);
        } else {
          const filteredItems = templateList.textTemplates.filter((item) => {
            return item.name
              .toLowerCase()
              .startsWith(enteredKeyword.toLowerCase());
          });
          setTemplates(filteredItems);
        }
      }
      return () => {
        setTemplates(templateList?.textTemplates || []);
      };
    }
  }, [templateList, props.EditorValue]);

  return (
    <>
      <Popper
        open={props.openStatus}
        anchorEl={document.getElementById("displayPopModal")}
        style={{ zIndex: "9999" }}
        placement="bottom-start"
      >
        <Box
          sx={{
            marginTop: 8,
            marginRight: 9,
            zIndex: 999,
            bgcolor: "background.paper",
          }}
        >
          {templateList?.loading ? (
            <Fragment />
          ) : (
            <List
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
                border: "1px solid #e0e0e0",
                marginTop: "20px",
                borderRadius: "3px",
                padding: 0,
              }}
            >
              {templates && templates.length ? (
                templates.map((temp, index) => (
                  <>
                    <ListItemButton key={index} onClick={selectTemplate(temp)}>
                      <Box sx={{ display: "block" }}>
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{ fontSize: "16px" }}
                        >
                          {temp?.name}
                        </Typography>
                        <Typography component="p" variant="subtitle1">
                          {temp.message.length > 70
                            ? `${temp.message.substring(0, 50)}...`
                            : temp.message}
                        </Typography>
                      </Box>
                    </ListItemButton>
                    {templates.length === index + 1 ? null : <Divider />}
                  </>
                ))
              ) : (
                <Typography>No Templates found</Typography>
              )}
            </List>
          )}
        </Box>
      </Popper>
    </>
  );
};

export default SendTextModal;
