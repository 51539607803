import { createSlice } from "@reduxjs/toolkit";
import { getAllPonds } from "./getPonds";
import { AddPond } from "./createPond";
import { updatePond } from "./updatePond";
import { deletePond } from "./deletePond";
const initialState = {
  pondsList: {
    success: false,
    data: [],
    metadata: {},
    isLoading: false,
    errMsg: null,
  },
  createPond: {
    isLoading: false,
    errMsg: null,
    data: {},
  },
  editPond: {
    isLoading: false,
    errMsg: null,
    data: {},
  },
  deletePond: {
    isLoading: false,
    errMsg: null,
    data: {},
  },
};
const Ponds = createSlice({
  name: "Ponds",
  initialState,
  extraReducers: {
    [getAllPonds.pending]: (state) => {
      state.pondsList.isLoading = true;
      state.pondsList.errMsg = null;
      state.pondsList.success = false;
    },
    [getAllPonds.fulfilled]: (state, action) => {
      state.pondsList.success = true;
      state.pondsList.isLoading = false;
      if (state.pondsList?.data?.length && action.meta?.arg?.pageNumber > 1) {
        state.pondsList.data = [
          ...state.pondsList.data,
          ...action.payload.ponds,
        ];
      } else {
        state.pondsList.data = action.payload.ponds;
        state.pondsList.metadata = action.payload._metadata;
      }
    },
    [getAllPonds.rejected]: (state, action) => {
      state.pondsList.isLoading = false;
      state.pondsList.success = false;
      state.pondsList.errMsg = action.payload;
    },
    // create pond
    [AddPond.pending]: (state) => {
      state.createPond.isLoading = true;
      state.createPond.errMsg = null;
      state.createPond.success = false;
    },
    [AddPond.fulfilled]: (state, action) => {
      state.createPond.success = true;
      state.createPond.isLoading = false;
      state.createPond.data = action.payload;
      let newPondList = [...state.pondsList.data];
      newPondList.unshift(action.payload);
      state.pondsList.data = newPondList;
    },
    [AddPond.rejected]: (state, action) => {
      state.createPond.isLoading = false;
      state.createPond.success = false;
      state.createPond.errMsg = action.payload;
    },
    // update pond
    [updatePond.pending]: (state) => {
      state.editPond.isLoading = true;
      state.editPond.errMsg = null;
      state.editPond.success = false;
    },
    [updatePond.fulfilled]: (state, action) => {
      state.editPond.success = true;
      state.editPond.isLoading = false;
      state.editPond.data = action.payload;
      let newPondList = [...state.pondsList.data];
      let index = newPondList.findIndex(
        (item) => item.id === action.payload.id
      );
      newPondList.splice(index, 1, { ...action.payload });
      state.pondsList.data = newPondList;
    },
    [updatePond.rejected]: (state, action) => {
      state.editPond.isLoading = false;
      state.editPond.success = false;
      state.editPond.errMsg = action.payload;
    },
    // delete pond
    [deletePond.pending]: (state) => {
      state.deletePond.isLoading = true;
      state.deletePond.errMsg = null;
      state.deletePond.success = false;
    },
    [deletePond.fulfilled]: (state, action) => {
      state.deletePond.success = true;
      state.deletePond.isLoading = false;
      state.deletePond.data = action.payload;
      let newPondList = [...state.pondsList.data];
      let index = newPondList.findIndex(
        (item) => item.id === action.payload.id
      );
      newPondList.splice(index, 1);
      state.pondsList.data = newPondList;
    },
    [deletePond.rejected]: (state, action) => {
      state.deletePond.isLoading = false;
      state.deletePond.success = false;
      state.deletePond.errMsg = action.payload;
    },
  },
});

export default Ponds.reducer;
export * from "./getPonds";
export * from "./createPond";
export * from "./updatePond";
export * from "./deletePond";
