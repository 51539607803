import React, { useEffect, Fragment } from "react";
import { Box, Container, Card, Tab, Tabs } from "@mui/material";
import { TabContext } from "@mui/lab";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { useSelector, useDispatch } from "react-redux";
import { getAgentRequests } from "../../redux/agentRequests";
import { Link, useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import AgentRequestTicketManagementRoot from "./ticketsRoot.js";
function AgentRequestTicketManagement(props) {
  let { classes } = props;
  const location = useLocation();

  const adminAuth = useSelector((state) => state.adminAuth);
  const dispatch = useDispatch();

  const handleValidateUrl = (path) => {
    if (path === "assigned" || path === "open" || path === "closed") {
      return true;
    } else {
      return false;
    }
  };

  const handleGetAgentRequests = (object) => {
    let obj = {
      userId:
        location.pathname?.split("/")[2] === "assigned"
          ? adminAuth?.adminDetail?.id
          : "",
      teamId:
        location.pathname?.split("/")[2] === "open" &&
        adminAuth?.adminDetail?.role.name !== "Admin"
          ? adminAuth?.adminDetail?.team_id
          : "",
      status:
        location.pathname?.split("/")[2] === "assigned"
          ? ""
          : location.pathname?.split("/")[2],
      ...object,
    };
    dispatch(getAgentRequests(obj));
  };

  useEffect(() => {
    if (
      adminAuth?.adminDetail?.id &&
      handleValidateUrl(location.pathname?.split("/")[2])
    ) {
      handleGetAgentRequests({ pageNumber: 1 });
    }
  }, [adminAuth?.adminDetail?.id, location.pathname]);

  return (
    <Fragment>
      <Card
        variant="outlined"
        sx={{ position: "sticky", top: "64px", zIndex: "100" }}
      >
        {" "}
        <Box className={classes.TopSubNavBarArea}>
          <Box className={classes.TopSubNavBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TopSubNavTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{
                      sx: { background: "black", color: "black" },
                    }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                  >
                    <Tab
                      label="Open Tickets"
                      value={`/agent_tickets/open`}
                      to={`/agent_tickets/open`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />
                    <Tab
                      label="Assigned to me"
                      value={`/agent_tickets/assigned`}
                      to={`/agent_tickets/assigned`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />
                    <Tab
                      label="Closed Tickets"
                      value={`/agent_tickets/closed`}
                      to={`/agent_tickets/closed`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                    />{" "}
                  </Tabs>
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopSubNavBarAreaLeft}></Box>
        </Box>
      </Card>
      <Container maxWidth="xl">
        <Routes>
          <Route
            path="/open"
            element={
              <AgentRequestTicketManagementRoot
                handleGetAgentRequests={handleGetAgentRequests}
                {...props}
              />
            }
          />
          <Route
            path="/assigned"
            element={
              <AgentRequestTicketManagementRoot
                handleGetAgentRequests={handleGetAgentRequests}
                IsAssigned
                {...props}
              />
            }
          />
          <Route
            path="/closed"
            element={
              <AgentRequestTicketManagementRoot
                handleGetAgentRequests={handleGetAgentRequests}
                isDraft={true}
                {...props}
              />
            }
          />
          <Route
            path="/:id"
            element={
              <AgentRequestTicketManagementRoot
                handleGetAgentRequests={handleGetAgentRequests}
                isDraft={true}
                {...props}
              />
            }
          />
        </Routes>
      </Container>
    </Fragment>
  );
}
export default withStyles(styleSheet, {
  name: "AgentRequestTicketManagementStyle",
})(AgentRequestTicketManagement);
