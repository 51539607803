import React from "react";
import {
  Box,
  ListItemButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import listingIcon from "../../../../../assets/images/listingIcon.png";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
const pointKeys = [
  {
    title: "Leads",
    icon: <PersonRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    subItems: [
      {
        title: "Call Connected (> 60 sec)",
        description: "Daily Max: 30",
        points: 2,
      },
      {
        title: "Call Connected (> 90 sec)",
        description: "Daily Max: 30",
        points: 4,
      },
      {
        title: "Call Connected (> 4 min)",
        description: "",
        points: 10,
      },
      {
        title: "Text Sent",
        description: "Daily Max: 20",
        points: 1,
      },
      {
        title: "Text Replied",
        description: "Daily Max: 50 - 1 Per Lead",
        points: 3,
      },
      {
        title: "Follow Up Email Sent",
        description: "Daily Max: 20",
        points: 1,
      },
      {
        title: "New Agent Sourced Lead Added",
        description: "Monthly Max: 50",
        points: 2,
        isTooltip: true,
      },
    ],
  },
  {
    title: "Transactions",
    icon: <WorkRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    subItems: [
      {
        title: "Offer Submitted",
        description: "",
        points: 5,
      },
      {
        title: "Binding Contract Signed",
        description: "",
        points: 30,
      },
      {
        title: "Transaction Closed",
        description: "",
        points: 100,
      },
      {
        title: "Transaction Closed w/ Company Lead Older than 12 Mo",
        description: "",
        points: 15,
        isTooltip: true,
      },
      {
        title: "Contract Terminated",
        description: "",
        points: -10,
      },
    ],
  },
  {
    title: "Marketing",
    icon: <CampaignRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    subItems: [
      {
        title: "Postcards Mailed ",
        description: "",
        points: 5,
      },
    ],
  },
  {
    title: "Training",
    icon: <HelpRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    subItems: [
      {
        title: "Knowledge Base Video Viewed",
        description: "Daily Max: 5 (1 Per Video)",
        points: 1,
        isTooltip: true,
      },
      {
        title: "CE Class Completed",
        description: "",
        points: 5,
      },
      {
        title: "Attend Meeting/Training Virtually",
        description: "",
        points: 5,
      },
      {
        title: "Attend Meeting/Training In-Person",
        description: "",
        points: 10,
      },
    ],
  },
  {
    title: "Listings",
    icon: (
      <img
        src={listingIcon}
        style={{ width: "17px", marginLeft: "5px", marginRight: "5px" }}
        alt="listingIcon"
      />
    ),
    subItems: [
      {
        title: "Seller CMA Run",
        description: "Daily Max: 2",
        points: 1,
      },
      {
        title: "Listing Agreement Signed",
        description: "",
        points: 30,
      },
      {
        title: "Hold Open House",
        description: "",
        points: 20,
      },
      {
        title: "Withdrawn/Expired/Lost Listing",
        description: "",
        points: -7,
      },
    ],
  },
];
function LeaderBoardPointKeys(props) {
  let { classes } = props;
  return (
    <Box sx={{ "& .MuiAccordion-root": { boxShadow: "none" } }}>
      {pointKeys.map((item, index) => (
        <Accordion key={index} className={classes.keyPointsItem}>
          <AccordionSummary
            className={classes.keyPointsHeadings}
            expandIcon={<ExpandMoreRoundedIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.keyPointsHeadingsText}>
              {" "}
              {item.icon} {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px" }}>
            <List disablePadding dense>
              {item.subItems.map((item1, index1) => (
                <ListItem key={`5600${index1}`} disablePadding divider>
                  {item1.isTooltip ? (
                    <CustomTooltip title={item1.title}>
                      <ListItemButton disablePadding>
                        <ListItemText
                          primary={
                            <Box className={classes.keyPointsItemText}>
                              <Box
                                className={classes.keyPointsItemTitleMain}
                                sx={{
                                  maxWidth: item1.description ? "55%" : "75%",
                                }}
                              >
                                {item1.title}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "9px",
                                  display: "inline-block",
                                  ml: "10px",
                                }}
                              >
                                {item1.description}
                              </Box>
                            </Box>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: item1.points > 0 ? "#2E7D32" : "#C62828",
                            }}
                          >
                            {item1.points > 0 ? "+" : ""} {item1.points}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </CustomTooltip>
                  ) : (
                    <ListItemButton disablePadding>
                      <ListItemText
                        primary={
                          <Box className={classes.keyPointsItemText}>
                            <Box
                              className={classes.keyPointsItemTitleMain}
                              sx={{
                                maxWidth: item1.description ? "55%" : "75%",
                              }}
                            >
                              {item1.title}
                            </Box>
                            <Box
                              sx={{
                                fontSize: "9px",
                                display: "inline-block",
                                ml: "10px",
                              }}
                            >
                              {item1.description}
                            </Box>
                          </Box>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: item1.points > 0 ? "#2E7D32" : "#C62828",
                          }}
                        >
                          {item1.points > 0 ? "+" : ""} {item1.points}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "LeaderBoardPointKeysStyle",
})(LeaderBoardPointKeys);
