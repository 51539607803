import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ResponseAlert from "../../../../components/responseAlert";
import { addGroupRouting } from "../../../../redux/leads/leadRouting/groups";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { CardActionArea, InputLabel } from "@mui/material";

function AddGroupModal(props) {
  let { open, setOpen, classes } = props;
  const [searchText, setSearchText] = useState();
  const [distributionType, setDistributionType] = React.useState("round_robin");
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [assigningUser, setAssigningUser] = useState("");
  const [claimAfter, setClaimAfter] = useState(15);
  const nameRef = useRef();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const addGroup = useSelector((item) => item.leads.LeadRoutingGroups.addGroup);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  useEffect(() => {
    if (addGroup.success && !addGroup.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully create the lead group",
        errorType: "success",
        isOpen: true,
      });
      setSelectedUsers([]);
      setOpen(false);
    } else if (!addGroup.isLoading && addGroup.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addGroup.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addGroup.isLoading]);

  const handleValidate = () => {
    let isFormValid = true;
    if (!nameRef?.current?.value) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the group name",
        errorType: "warning",
        isOpen: true,
      });
    } else if (selectedUsers?.length === 0) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please select the user first",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!claimAfter || claimAfter < 1) {
      isFormValid = false;
      setErrorAlert({
        errorMsg: "Please enter the not claimed time limit ",
        errorType: "warning",
        isOpen: true,
      });
    } 
    return isFormValid;
  };

  const handleAddGroup = () => {
    if (handleValidate()) {
      let obj = {
        name: nameRef.current.value,
        distribution_type: distributionType,
        members: selectedUsers,
        not_claimed_time_limit: claimAfter,
        assigned_to: assigningUser?.id,
      };
      dispatch(addGroupRouting(obj));
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selectedUsers.indexOf(value);
    const newChecked = [...selectedUsers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedUsers(newChecked);
  };
  const filteredList = (userList) => {
    if (searchText) {
      return userList.filter((item) =>
        item.full_name
          .toLowerCase()
          ?.slice(0, searchText.length)
          ?.includes(searchText)
      );
    } else {
      return userList;
    }
  };
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    let admins = users.filter((item) => item?.role?.name === "Admin");
    let teamLeader = users.filter((item) => item?.role?.name === "Team Leader");
    let otherUsers = users.filter(
      (item) =>
        item?.role?.name !== "Team Leader" &&
        item?.role?.name !== "Admin" &&
        item?.role?.name !== "Agents"
    );
    return [
      ...teamLeader,
      ...admins,
      ...agents,
      ...otherUsers?.filter((item) => item.group !== undefined),
    ];
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{
          position: "absolute",
          "& .MuiDialog-container": { height: "95vh", mt: "0px" },
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          className={classes.paymentModalHeading}
          id="alert-dialog-title"
        >
          Add Group
        </DialogTitle>
        <DialogContent sx={{ width: "700px" }}>
          <TextField
            type={"text"}
            fullWidth
            placeholder="Group name"
            inputRef={nameRef}
            size="small"
            sx={{ mb: "15px" }}
          />
          <OutlinedInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            size="small"
            placeholder="Search user"
          />
          <Card variant="outlined" sx={{ mt: "15px" }}>
            <List
              dense
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                height: "280px",
                overflow: "auto",
              }}
            >
              {CUstomSortUsers(filteredList(storedListOfUsers.list)).map(
                (item, value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(item.id)}
                          checked={selectedUsers.indexOf(item.id) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar src={item?.profile_images?.profile_img}>
                            {item.first_name?.slice(0, 1)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          primary={`${item.first_name} ${item.last_name}`}
                        />
                      </ListItemButton>
                      <Divider />
                    </ListItem>
                  );
                }
              )}
            </List>
          </Card>
          <Card variant="outlined" sx={{ display: "flex", mt: "15px" }}>
            <Box className={classes.groupOptionSelectionLeft}>
              <CardActionArea
                onClick={() => setDistributionType("round_robin")}
                sx={{ height: "100%" }}
              >
                <CardHeader
                  avatar={
                    <Radio
                      checked={distributionType === "round_robin"}
                      readOnly
                    />
                  }
                  sx={{ padding: "10px 5px 10px 0px" }}
                  title="Round Robin"
                  subheader="Evenly distributes leads to agents in sequential order"
                />
              </CardActionArea>
            </Box>
            <Box className={classes.groupOptionSelectionRight}>
              <CardActionArea
                onClick={() => setDistributionType("first_to_claim")}
                sx={{ height: "100%" }}
              >
                <CardHeader
                  avatar={
                    <Radio
                      readOnly
                      checked={distributionType === "first_to_claim"}
                      sx={{
                        padding: "10px 5px 10px 0px",
                        alignItems: "flex-start",
                      }}
                    />
                  }
                  title="First to Claim"
                  subheader="Multiple agents contacted, first to claim lead wins"
                />
              </CardActionArea>
            </Box>
          </Card>
          <Grid container spacing={3} sx={{ mt: "0px" }}>
            <Grid xs={12} md={6} item>
              <InputLabel className={classes.inputLabelStyle}>
                If not claimed after
              </InputLabel>
              <TextField
                value={claimAfter}
                type="number"
                onChange={(e) => setClaimAfter(e.target.value)}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputLabel className={classes.inputLabelStyle}>
                Assign to
              </InputLabel>
              <Autocomplete
                disableClearable
                options={
                  agentRosterList?.list?.length > 0
                    ? agentRosterList?.list
                        ?.filter((it) => it.id)
                        .sort((a, b) => a.last_name.localeCompare(b.last_name))
                    : []
                }
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={(e, option) => {
                  setAssigningUser(option);
                }}
                value={assigningUser?.id ? assigningUser : null}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select fallback..."
                    aria-describedby="stepper-linear-event_types"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleAddGroup()}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addGroup.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddGroupModalStyle" })(
  AddGroupModal
);
