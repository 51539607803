export const styleSheet = (theme) => ({
    pageRoot: {
      padding: "30px 30px 9px 30px",
      marginBottom: "16px",
    },
    loadingArea:{
      height:"50vh",
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    }
  });
  