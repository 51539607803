import React from "react";
import { Box, Grid, Card, CardHeader } from "@mui/material";
import Avatar from "../../uiElements/avatar/index";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
function Users(props) {
  let { classes, data, setOpenSearch } = props;
  const navigate = useNavigate();
  return (
    <Box className={classes.peoplesArea}>
      <Grid container spacing={1}>
        {data?.map((data, index) => (
          <Grid key={index} item md={6}>
            <Card
              onClick={() => {
                setOpenSearch(false);
                navigate(`/users/${data.id}/details`);
              }}
              /* elevation={0} */ sx={{ cursor: "pointer", "&:hover": { background: "#F0F0F0" } }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ width: "40px  !important", height: "40px !important" }}
                    variant="rounded"
                    src={data?.profile_images?.profile_img}
                    aria-label="recipe"
                  >
                    {`${data.first_name?.slice(0, 1)}${data.last_name?.slice(0, 1)}`}
                  </Avatar>
                }
                title={data.first_name + " " + data.last_name}
                subheader={`${data.role?.name || "Role here"} | ${data.state || data.market?.name || "state name"}`}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "UsersStyle" })(Users);
