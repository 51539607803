import React, { useState, useRef, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Grid, Container, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./style.js";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import document_img from "../../../../assets/document.png";
import dragindicator from "../../../../assets/dragindicator.png";
import GlobalModal from "../../../../components/globalModal";
import { styleSheet } from "./style.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  height: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Printer = ({ open, handleClose, modalWidht, classes, formData, documentID }) => {
  // const list = [{ "primary_txt": "F201 - Condominium Purchase and Sale Agreement", "secondary_txt": "8 pages" }, { "primary_txt": "F201 - Condominium Purchase and Sale Agreement", "secondary_txt": "8 pages" }]

  const dragItem = useRef();
  const dragOverItem = useRef();
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState();

  const handleCancel = () => {
    handleClose();
  };

  const getPdfToPrint = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = process.env.REACT_APP_BASE_URL + `api/docs/folder/merge-pdf/${documentID}?form_ids=${list.map((g) => g.id).join(",")}`;
    await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/octet-stream",
      },
    })
      .then((data) => {
        if (!data.ok) {
          return data.status.toString();
        }
        data.blob().then((blob) => {
          const file = new Blob([blob], { type: "application/pdf" });
          let fileURL = window.URL.createObjectURL(file);

          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = fileURL;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
          setLoading(false);
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log("GET_DATA_ERROR: ", error);
      });
    handleClose();
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
  };

  useEffect(() => {
    setList(formData);
  }, [formData]);

  return (
    <GlobalModal open={open} handleClose={handleClose} modalWidht={modalWidht} showHeader={false}>
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" noWrap component="h3" className={classes.mTitle} sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}>
              Print Documents
            </Typography>
            <Box className={classes.mBorder}>
              {list?.map((val, index) => (
                <Card
                  key={index}
                  className={classes.mCard}
                  draggable
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                >
                  <CardContent className={classes.mCardContent}>
                    <List sx={{ width: "100%", maxWidth: 600 }} disablePadding>
                      <ListItem>
                        <img style={{width:"20px"}} src={dragindicator} />
                        <img src={document_img} className={classes.mImgStyle} />
                        <ListItemText primary={val.pdf_form.name} secondary={val.pdf_form.pngs.length + " pages "} />
                        <Grid>
                          <ClearIcon className={classes.mIconStyle} />
                        </Grid>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>
        </Grid>
        {loading ? (
          <Box mt={2} sx={{textAlign:"center"}}>
            <CircularProgress size={"20px"} />
          </Box>
        ) : (
          <Box mt={2}>
            <Grid item xs={12} md={12} className={classes.mJustifyContent}>
              <Button className={classes.mCancel} onClick={() => handleCancel()}>
                cancel
              </Button>
              <Button className={classes.mCreateButton} onClick={getPdfToPrint}>
                print
              </Button>
            </Grid>
          </Box>
        )}
      </Box>
    </GlobalModal>
  );
};

// export default Printer;
export default withStyles(styleSheet, { name: "PrinterModalStyle" })(Printer);
