import * as React from "react";
import {Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
function ContactsSkelton(props) {
  let { count = 9 } = props;
  return (
    <Stack direction={"row"} justifyContent="flex-start" alignItems={"center"} flexWrap="wrap" spacing={0}>
      {Array.from(Array(count).keys()).map(() => (
        <Box sx={{mr:'15px',mb:"0px"}}>
          <Skeleton animation="wave"  width={65} height={100} sx={{mb:"-15px"}} />
          <Skeleton animation="wave"width={65} height={20} />
        </Box>
      ))}
    </Stack>
  );
}
export default ContactsSkelton;
