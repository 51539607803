import React from "react";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Box } from "@mui/material";

import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
function DataNotFound({ classes, icon, title, fontSize, color,component,minHeight }) {
  return (
    <Box className={classes.NotFoundRoot} component={component?component:""} sx={{minHeight:minHeight?minHeight:""}}>
      <Box className={classes.NotFoundContent}>
        {icon ? icon : <ErrorOutlineRoundedIcon sx={{ fontSize: "60px", color: "#BDBDBD" }} fontSize="large" />}
        <br />
        <Box sx={{ color: color ? color : "", fontSize: fontSize ? fontSize : "" }}> {title ? title : "Not Exists"}</Box>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "DataNotFoundStyle" })(DataNotFound);
