import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  allACH: [],
  errMsg: null,
  processLoading:false
};

export const getProcessedACH = createAsyncThunk("finance/getProcessedACH", async (data, thunkAPI) => {
  try {
    const url = "/api/finance/payments?payment_preference=ach";
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      method: "get",
      url,
    };

    return new Promise((resolve, reject) => {
      axios(options)
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          return err;
        });
    });
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const processACH = createAsyncThunk("finance/processACH", async (data, thunkAPI) => {
  try {
    let headers = {
      Authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    };

    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/finance/process-ach`, data, { headers });

    return res.status === 200 ? res.data : res.response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const processedACH = createSlice({
  name: "processedACH",
  initialState,
  reducers: {
    updateACHStore: (state, action) => {
      state.allACH = action.payload;
    },
  },
  extraReducers: {
    [getProcessedACH.pending]: (state) => {
      state.loading = true;
    },
    [getProcessedACH.fulfilled]: (state, action) => {
      state.loading = false;
      state.allACH = action.payload.data.map((r) => ({ ...r, needToProcess: true }));
    },
    [getProcessedACH.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
    [processACH.pending]: (state) => {
      state.processLoading = true;
    },
    [processACH.fulfilled]: (state, action) => {
      state.processLoading = false;
    },
    [processACH.rejected]: (state, action) => {
      state.processLoading = false;
    },
  },
});
export const { updateACHStore } = processedACH.actions;

export default processedACH.reducer;
