import React from "react";
import { Box } from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import Listings from "./listing";
import Transactions from "./transaction";
import CMAs from "./cma";
import Peoples from "./peoples";
import Users from "./users";
import KnowledgeBase from "./knowledgebase";
import Documents from "./docs";
import { useSelector, useDispatch } from "react-redux";

import { getPowerSearchData, getSearchPropertyData } from "../../../redux/powerSearch/index";
function PowerSearch(props) {
  let { classes, search } = props;
  const dispatch = useDispatch();
  const powerSearch = useSelector((state) => state.powerSearch);
  return (
    <Box sx={{ maxHeight: "86vh", overflowY: "auto" }}>
      {/*    Listing area  */}
      <Box className={classes.searchHeading}>
        Listings (
        {powerSearch?.searchPropertyData?.properties?.value?.length && powerSearch?.searchPropertyData?.properties
          ? powerSearch?.searchPropertyData?.properties["@odata.count"] || "0"
          : "0"}
        )
        {powerSearch?.searchPropertyData?.properties &&
        powerSearch?.searchPropertyData?.properties["@odata.count"] > 6 &&
        powerSearch?.searchPropertyData?.properties?.value?.length < powerSearch?.searchPropertyData?.properties["@odata.count"] - 6 ? (
          <Box
            onClick={() => {
              dispatch(getSearchPropertyData({ search: search, skip: 6, top: powerSearch?.searchPropertyData?.properties["@odata.count"] }));
            }}
            sx={{ ml: "15px", cursor: "pointer", textDecoration: "underline" }}
          >
            See All Results
          </Box>
        ) : (
          ""
        )}{" "}
      </Box>
      {powerSearch?.searchPropertyData?.properties?.value.map((data, index) => (
        <Listings data={data} key={index} {...props} />
      ))}
      {/*    Transaction area  */}
      <Box className={classes.searchHeading}>
        Transactions ({powerSearch?.searchData?.metadata?.transactions_count || "0"})
        {powerSearch?.searchData?.metadata?.transactions_count > 6 && (
          <Box
            onClick={() => {
              dispatch(getPowerSearchData({ search: search, see_all: "transaction", seeAllFlag:"transactions" }));
            }}
            sx={{ ml: "15px", cursor: "pointer", textDecoration: "underline" }}
          >
            See All Results
          </Box>
        )}
      </Box>
      {powerSearch?.searchData?.transactions?.map((item, index) => (
        <Transactions data={item} key={index} {...props} />
      ))}
      {/*    Peoples area  */}
      <Box className={classes.searchHeading}>People ({powerSearch?.searchData?.metadata?.people_count || "0"})
      {powerSearch?.searchData?.metadata?.people_count > 6 && (
          <Box
            onClick={() => {
              dispatch(getPowerSearchData({ search: search, see_all: "people", seeAllFlag:"people" }));
            }}
            sx={{ ml: "15px", cursor: "pointer", textDecoration: "underline" }}
          >
            See All Results
          </Box>
        )}
       </Box>
      {powerSearch?.searchData?.people?.length ? <Peoples data={powerSearch?.searchData?.people} {...props} /> : ""}
      {/* Knowledge Base area  */}
      <Box className={classes.searchHeading}>
        Documents ({powerSearch?.searchData?.metadata?.docs_count || "0"}){" "}
        {(powerSearch?.searchData?.metadata?.docs_count > 6 && powerSearch?.searchData?.docs?.length!==powerSearch?.searchData?.metadata?.docs_count) && (
          <Box
            onClick={() => {
              dispatch(getPowerSearchData({ search: search, see_all: "document", seeAllFlag:"docs" }));
            }}
            sx={{ ml: "15px", cursor: "pointer", textDecoration: "underline" }}
          >
            See All Results
          </Box>
        )}
      </Box>
      {powerSearch?.searchData?.docs?.map((data, index) => (
        <Documents data={data} key={index} {...props} />
      ))}
      {/*    Transaction area  */}
      <Box className={classes.searchHeading}>
        CMAs ({powerSearch?.searchData?.metadata?.cma_count || "0"})
        {(powerSearch?.searchData?.metadata?.cma_count > 6 && powerSearch?.searchData?.metadata?.cma_count!==powerSearch?.searchData?.cma?.length) && (
          <Box
            onClick={() => {
              dispatch(getPowerSearchData({ search: search, see_all: "cma", seeAllFlag:"cma"  }));
            }}
            sx={{ ml: "15px", cursor: "pointer", textDecoration: "underline" }}
          >
            See All Results
          </Box>
        )}
      </Box>
      {powerSearch?.searchData?.cma?.map((data, index) => (
        <CMAs data={data} key={index} {...props} />
      ))}
      {/*    users area  */}
      <Box className={classes.searchHeading}>
        Users ({powerSearch?.searchData?.metadata?.users_count || "0"})
        {(powerSearch?.searchData?.metadata?.users_count > 6 || powerSearch?.searchData?.metadata?.users_count!==powerSearch?.searchData?.users?.length) && (
          <Box
            onClick={() => {
              dispatch(getPowerSearchData({ search: search, seeAllFlag:"users", see_all: "users" }));
            }}
            sx={{ ml: "15px", cursor: "pointer", textDecoration: "underline" }}
          >
            See All Results
          </Box>
        )}
      </Box>
      {powerSearch?.searchData?.users?.length ? <Users data={powerSearch?.searchData?.users} {...props} /> : ""}

      {/* Knowledge Base area  */}
      <Box className={classes.searchHeading}>
        Knowledge Base ({powerSearch?.searchData?.metadata?.knowledgebase_count})
        {(powerSearch?.searchData?.metadata?.knowledgebase_count > 6 && powerSearch?.searchData?.knowledgebase?.length!==powerSearch?.searchData?.metadata?.knowledgebase_count) && (
          <Box
            onClick={() => {
              dispatch(getPowerSearchData({ search: search, see_all: "knowledgebase", seeAllFlag:"knowledgebase" }));
            }}
            sx={{ ml: "15px", cursor: "pointer", textDecoration: "underline" }}
          >
            See All Results
          </Box>
        )}
      </Box>
      {powerSearch?.searchData?.knowledgebase?.map((data, index) => (
        <KnowledgeBase data={data} key={index} {...props} />
      ))}
    </Box>
  );
}
export default withStyles(styleSheet, { name: "PowerSearchStyle" })(PowerSearch);
