import React, { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Stack,
  Menu,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import moment from "moment";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import AddNoteModal from "../../modals/addNote";
import NoteIcon from "@mui/icons-material/Note";
import { useSelector, useDispatch } from "react-redux";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import editPencil from "../../../../assets/editPencil.png";
import deleteIcon from "../../../../assets/deleteIcon.png";
import ConfirmModal from "../../../../components/globalModal/ConfirmModal";
import ResponseAlert from "../../../../components/responseAlert";
import { deleteContractNote } from "../../../../redux/transactions/deleteNote";
import Linkify from "react-linkify";

function TransactionsDetailImportantNotes(props) {
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [noteDetail, setNoteDetail] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const deleteNote = useSelector((state) => state.transactions.TransactionsContract.deleteNote);
  let { classes, id, ContractData } = props;

  const handleSuccess = () => {
    setErrorAlert({ errorMsg: `You have successfully deleted  the note`, errorType: "success", isOpen: true });
    setOpenDelete(false);
  };
  const handleError = (error) => {
    setOpenDelete(false);
    setErrorAlert({ errorMsg: JSON.stringify(error), errorType: "error", isOpen: true });
  };

  const handleConfirm = () => {
    if (noteDetail.id) {
      let obj = {
        noteId: noteDetail.id,
        handleSuccess,
        handleError,
      };
      dispatch(deleteContractNote(obj));
    }
  };
  return (
    <Card className={classes.overViewTabCardMain}>
      <CardHeader
        classes={{ title: classes.overViewTabCardHeading }}
        action={
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#0B0909",
              "&:hover": { backgroundColor: "#0f0a0a" },
              color: "white",
              fontWeight: "400",
              mr: "10px",
              mt: "10px",
            }}
            onClick={() => setOpen(true)}
            startIcon={<AddRoundedIcon />}
          >
            Add Note
          </Button>
        }
        sx={{ paddingLeft: "0px", paddingRight: "0px", mb: "12px" }}
        title="Important Notes"
      />
      {ContractData?.notes?.length > 0 &&
        ContractData?.notes?.map((item, index) => (
          <Card variant="outlined" key={index} className={classes.importantNotesCard}>
            <CardHeader
              sx={{ padding: "0px", paddingTop: "3px" }}
              classes={{
                title: classes.importantNotesHeader,
                subheader: classes.importantNotesDes,
              }}
              title={item.title}
              action={
                <CardHeader
                  classes={{
                    title: classes.noteDatesText,
                  }}
                  avatar={
                    <Avatar
                      sx={{ width: 22, height: 22 }}
                      alt={agentRosterList?.list.find((it) => it.id === Number(item.createdById))?.full_name}
                      classes={{ root: classes.customerNoteCardAvatarImg }}
                      src={agentRosterList?.list.find((it) => it.id === Number(item.createdById))?.profile_images?.profile_img}
                    />
                  }
                  title={`${agentRosterList?.list.find((it) => it.id === Number(item.createdById))?.full_name}  on ${moment(
                    item.createdAt || new Date()
                  ).format("L")}`}
                />
              }
            />
            <CardContent className={classes.importantNotesContent}>
              <Linkify
                properties={{ target: "_blank" }}
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {item.body}
              </Linkify>
            </CardContent>
            <Stack direction="row" sx={{ mt: "-10px" }} justifyContent="flex-end" alignItems="center" spacing={2}>
              <IconButton
                onClick={(e) => {
                  setNoteDetail(item);
                  setOpenMenu(e.currentTarget);
                }}
                size="small"
                sx={{ mr: "-15px", padding: "2px" }}
              >
                <MoreHorizRoundedIcon sx={{ color: "#CBC8C8" }} />
              </IconButton>
            </Stack>{" "}
          </Card>
        ))}
      {(ContractData?.notes?.length === 0 || !ContractData?.notes) && (
        <DataNotFound
          color="#BDBDBD"
          fontSize="15px"
          minHeight="120px"
          icon={<NoteIcon sx={{ fontSize: "70px", color: "#BDBDBD" }} fontSize="large" />}
          title="No Notes Exist"
        />
      )}
      <Menu
        anchorEl={openMenu}
        id="power-search-menu"
        open={Boolean(openMenu)}
        onClose={() => {
          setOpenMenu(false);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List disablePadding>
          <ListItem dense disablePadding size="small">
            <ListItemButton
              onClick={() => {
                setOpen("update");
                setOpenMenu(false);
              }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <img src={editPencil} width="18px" alt={"editPencil"} />
              </ListItemIcon>
              <ListItemText className={classes.menuListText} primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem dense disablePadding>
            <ListItemButton
              onClick={() => {
                setOpenDelete(true);
                setOpenMenu(false);
              }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <img src={deleteIcon} style={{ marginLeft: "-2px" }} width="20px" alt="deleteIcon" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <AddNoteModal open={open} id={id} setOpen={setOpen} detail={noteDetail} />
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        loading={deleteNote.isLoading}
        title="Delete Confirmation"
        content="Are you sure you want to delete this note?"
        handleConfirm={handleConfirm}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Card>
  );
}
export default withStyles(styleSheet, { name: "TransactionsDetailImportantNotesStyle" })(TransactionsDetailImportantNotes);
